import React, { useMemo } from 'react';
import { PosIconClock } from '../../../assets/icons/PosIconClock';
import { PosIconStore } from '../../../assets/icons/PosIconStore';
import { PosIconBag } from '../../../assets/icons/PosIconBag';
import { PosIconCheckmarkCircle } from '../../../assets/icons/PosIconCheckmarkCircle';
import { PosIconLeftChevron } from '../../../assets/icons/PosIconLeftChevron';
import Button from '../../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import useMutation from '../../../customHooks/useMutation';

export const DeliveryDriverSingleItem = ({
  driverData,
  currentDriverId,
  showAssignButton,
}) => {
  const currentUrl = window.location.href;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    assignDriver,
    selectedDriverForDeliveries,
    selectedUnAssignedOrders,
  } = useSelector((state) => state.driversReducer);
  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );
  const [addDeliveryOrder] = useMutation(
    `${nextOrderConfigURL}/addDelivery`,
    {}
  );

  const handleChange = () => {
    dispatch({
      type: 'setSelectedDriverForDeliveries',
      payload: driverData,
    });
  };

  const showReadMoreButton = !showAssignButton
    ? true
    : driverData.activeOrders?.length && !assignDriver;

  const elapsedTime = useMemo(() => {
    if (driverData.status === 0) {
      if (driverData.isActive) {
        
        const timeInMins = Math.floor(
          (new Date().getTime() - driverData.lastUpdatedTimestamp) / (60 * 1000)
        ) || 0;
        if (timeInMins >= 60) {
          return `${Math.floor(timeInMins / 60)} hour ${Math.floor(
            timeInMins % 60
          )} mins`;
        }
        return `${timeInMins} mins`;
      } else {
        return '';
      }
    }
    if (driverData.status === 2) {
      return driverData.arrivingToStoreTime;
    }
    if (driverData.status === 1) {
      const onTheWayOrder = driverData.activeOrders.find(
        (ao) => ao.orderStatus === '3'
      );
      return onTheWayOrder
        ? onTheWayOrder.arrivingToDestinationTime
        : driverData.arrivingToStoreTime;
    }
  }, [
    driverData.status,
    driverData.lastUpdatedTimestamp,
    driverData.arrivingToStoreTime,
    driverData.activeOrders,
    driverData.isActive,
  ]);

  const handleAssignDriver = () => {
    selectedUnAssignedOrders.forEach((o) => {
      addDeliveryOrder(
        JSON.stringify({
          driverId: driverData._id,
          deliveryCode: o.deliveryCode,
          timestampStart: new Date().getTime(),
          shiftId: driverData.shiftId,
          storeId: driverData.storeId,
          restaurantId: driverData.restaurantId,
        })
      );
    });
  };

  const getInitials = (driver) => {
    const initials = driver.firstName[0] + driver.lastName[0];
    return initials;
  };

  const orderCount = useMemo(() => {
    if (driverData.status === 1 && driverData.activeOrders?.length) {
      return `${
        driverData.activeOrders.filter((ao) => ao.orderStatus === '4').length
      }/${driverData.activeOrders.length}`;
    }
    return driverData.activeOrders?.length || 0;
  }, [driverData.status, driverData.activeOrders]);

  const isAllOrdersDelivered =
    driverData.status === 1 &&
    driverData.activeOrders?.length ===
      driverData.activeDeliveredOrders?.length;

  const handleReadMoreClick = () => {
    if (currentUrl.indexOf('/drivers/deliveries') !== -1) {
      dispatch({
        type: 'setSelectedDriverForDeliveries',
        payload:
          selectedDriverForDeliveries?._id === driverData._id
            ? null
            : driverData,
      });
      history.push(`/drivers/deliveries/driver/${driverData._id}`);
    } else {
      dispatch({
        type: 'setCurrentDriver',
        payload: driverData,
      });
    }
  };

  return (
    <li className={driverData._id === currentDriverId ? 'active' : ''}>
      <div
        className="driver-single-box"
        onClick={showReadMoreButton ? handleReadMoreClick : () => {}}
      >
        <div className="initials-coll">
          {assignDriver && driverData.isActive ? (
            <div className="single-details-input-box">
              <input
                type="checkbox"
                name="unassigned_item"
                value={driverData._id}
                checked={selectedDriverForDeliveries?._id === driverData._id}
                onChange={handleChange}
              />
              <span className="checkstyle" />
            </div>
          ) : (
            <span className={'driver-initials-box ' + driverData.colorTheme}>
              <span>{getInitials(driverData)}</span>
            </span>
          )}
        </div>
        <div className="name-coll">
          <h3 className="driver-name">
            {driverData.firstName + ' ' + driverData.lastName}
          </h3>
        </div>
        <div className="time-coll">
          <span className="icon-box">
            {
              driverData.status === 0 ? (
                <PosIconClock mainColor="#363636" darkModeColor="#ffffff" />
              ) : driverData.status === 2 ? (
                <PosIconStore mainColor="#363636" darkModeColor="#ffffff" />
              ) : driverData.status === 1 ? (
                <PosIconStore mainColor="#363636" darkModeColor="#ffffff" />
              ) : (
                ''
              )
              // dine_icon-bag
            }
          </span>
          <span className="drivers-time">{elapsedTime}</span>
        </div>
        {!showAssignButton || !!driverData.activeOrders?.length ? (
          <div className="progress-coll">
            {!!driverData.activeOrders?.length && (
              <>
                <span
                  className={clsx('icon-box', { finish: isAllOrdersDelivered })}
                >
                  {isAllOrdersDelivered ? (
                    <PosIconCheckmarkCircle
                      mainColor="#363636"
                      darkModeColor="#ffffff"
                    />
                  ) : (
                    <PosIconBag mainColor="#363636" darkModeColor="#ffffff" />
                  )}
                </span>
                <span className="count">{orderCount}</span>
              </>
            )}
          </div>
        ) : !assignDriver ? (
          <div className="assign-coll">
            <Button
              title="Assign"
              disabled={!selectedUnAssignedOrders.length}
              className="btn"
              onClick={(event) => handleAssignDriver(event, driverData._id)}
            >
              Assign
            </Button>
          </div>
        ) : (
          <div className="progress-coll" />
        )}
        {showReadMoreButton ? (
          <div className="read-more-coll">
            <Button
              type="button"
              title="Read More"
              // onClick={handleReadMoreClick}
              className="driver-read-more"
            >
              <PosIconLeftChevron
                mainColor="#b2b2b2"
                darkModeColor="#b2b2b2"
                lineThickness={0.5}
              />
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    </li>
  );
};
