import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModal from 'hooks/useModal';
import { useHistory } from 'react-router-dom';
import CustomerNameEffect from 'pages/WalkIn/CustomerNameEffect';
import {
  setIsEditPhoneNumber,
  updateCurrentOrder,
} from 'pages/Dashboard/action';

function CustomerPhoneEffect() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const askNameAndMobileAtEnd = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.askNameAndMobileAtEnd
  );
  const customerDetails = useSelector(
    (s) => s.dashboardReducer.acceptedCall?.customerDetails
  );
  const [phoneModalOpen, { openModal }] = useModal('customerPhoneModal');

  useEffect(() => {
    if (customerDetails && Object.keys(customerDetails).length > 0) {
      updateCurrentOrder({ ...currentOrder, ...customerDetails });
    }
  }, [customerDetails?.mobileNumber]);

  useEffect(() => {
    if (!currentOrder?._id && !askNameAndMobileAtEnd) {
      if (
        (!currentOrder?.mobileNumber ||
          currentOrder?.mobileNumber === 'xxx-xxx-xxx') &&
        !phoneModalOpen &&
        !customerDetails?.mobileNumber
      ) {
        if (currentOrder?.isOrderSwitched) {
          dispatch(setIsEditPhoneNumber(true));
        }
        openModal();
      }
    }

    if (askNameAndMobileAtEnd) {
      if (currentOrder?.firstName === '' && currentOrder?.mobileNumber === '') {
        if (
          currentOrder?.orderType === '1' ||
          currentOrder?.orderType === '2'
        ) {
          // Pickup and Delivery
          dispatch(
            updateCurrentOrder({
              ...currentOrder,
              firstName: 'xxxx',
              lastName: '',
              mobileNumber: 'xxx-xxx-xxx',
              userId: 'xxx-xxx-xxx',
            })
          );
        }
      }
    }
  }, [
    history,
    phoneModalOpen,
    currentOrder,
    currentOrder?._id,
    currentOrder?.mobileNumber,
    openModal,
  ]);

  return currentOrder.isCustomerNameFetched ? <CustomerNameEffect /> : null;
}

export default CustomerPhoneEffect;
