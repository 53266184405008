// interface schema {
//   zoom?: number;
//   height?: number | string;
//   // size?: number;
//   coordinates: [number, number][];
//   toolTips?: {
//     field: string;
//     headerName: string;
//     addition?: string;
//   }[];
//   showEditControl?: true;
//   polygons?: {
//     pathOptions?: {
//       color?: string;
//       fillColor?: string;
//     };
//     positions: any[];
//   }[];
//   showMarker?: boolean;
import { useMemo } from 'react';
import MapModalWrapper from 'components/MapModalWrapper';
import LeafletDeliveryBatches from 'components/Tracking/LeafletDeliveryBatches';
import { unAssignedOrderListSelector } from 'pages/Drivers/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { storeConfigSelector } from 'pages/Dashboard/selectors';

export function DeliveryUnassignedOrderBatchesMap() {
  const storeConfig = useSelector(storeConfigSelector);
  const unAssignedOrders = useSelector(unAssignedOrderListSelector);
  const dispatch = useDispatch();

  const batchOrders = useSelector(
    (state) => state.batchOrdersReducer.batchOrders
  );
  const unAssignedOrdersList = useMemo(() => {
    const batchedOrders = batchOrders
      .filter((batch) => !batch.isDeleted)
      .map((batch) => batch.orders ?? [])
      .flat();

    return (unAssignedOrders ?? []).filter(
      (order) =>
        !!order.address &&
        !batchedOrders.some((batchOrder) => batchOrder.id === order._id)
    );
  }, [unAssignedOrders, batchOrders]);

  const locations = useMemo(
    () =>
      unAssignedOrdersList.map((order) => ({
        ...order.addressLocation,
        id: order._id,
        deliveryDateTimestamp: order.deliveryDateTimestamp,
        address: order.address,
      })),
    [unAssignedOrdersList]
  );

  const onMarkerClick = (id) => {
    const order = unAssignedOrders.find((order) => order._id === id);

    if (!order) return;

    dispatch({
      type: 'toggleSelectedUnAssignedOrdersForDeliveryBatches',
      payload: order,
    });
  };

  return (
    <div className="driver-main">
      <MapModalWrapper iframeHeight={'100%'} iframeWidth={'100%'}>
        <LeafletDeliveryBatches
          key={locations.length}
          addressLocation={{
            lat: storeConfig.addressLocation?.lat,
            lng: storeConfig.addressLocation?.long,
          }}
          locations={locations}
          onMarkerClick={onMarkerClick}
        />
      </MapModalWrapper>
    </div>
  );
}
