import { useMemo, useRef, useState } from 'react';

export default function useOrderTouched(currentOrder) {
  const [newItemsExist, setNewItemsExist] = useState(false);
  const prevOrder = useRef(currentOrder);

  const orderTouched =
    useMemo(() => {
      const compareNotes =
        (prevOrder.current.notes ?? '') !== (currentOrder.notes ?? '');

      const compareCourseId =
        (prevOrder?.current?.courseId ?? '') !== (currentOrder?.courseId ?? '');

      const compareServiceCharge =
        (prevOrder.current.serviceCharge ?? '') !==
        (currentOrder.serviceCharge ?? '');
      const compareIds =
        prevOrder.current.menuItems?.reduce((str, m) => (str += m._id), '') !==
        currentOrder.menuItems?.reduce((str, m) => (str += m._id), '');
      const compareIsVoided =
        prevOrder.current.menuItems?.reduce(
          (str, m) => (str += m.isVoided),
          ''
        ) !==
        currentOrder.menuItems?.reduce((str, m) => (str += m.isVoided), '');

      const compareDiscountId =
        (prevOrder.current.discountId ?? '') !==
        (currentOrder.discountId ?? '');

      const compareMenuItemQuantities =
        prevOrder.current.menuItems?.reduce(
          (str, m) => (str += m.quantity),
          ''
        ) !==
        currentOrder.menuItems?.reduce((str, m) => (str += m.quantity), '');

      const compareMenuItemSpecificDiscount =
        prevOrder.current.menuItems?.reduce(
          (str, m) => (str += JSON.stringify(m?.selectedDiscount) || ''),
          ''
        ) !==
        currentOrder.menuItems?.reduce(
          (str, m) => (str += JSON.stringify(m?.selectedDiscount) || ''),
          ''
        );

      // const compareMenuItemCourseStatus =
      //   prevOrder.current.menuItems?.reduce(
      //     (str, m) => (str += JSON.stringify(m?.courseStatus) || ''),
      //     ''
      //   ) !==
      //   currentOrder.menuItems?.reduce(
      //     (str, m) => (str += JSON.stringify(m?.courseStatus) || ''),
      //     ''
      //   );

      return (
        compareNotes ||
        compareIds ||
        compareIsVoided ||
        compareMenuItemQuantities ||
        compareDiscountId ||
        compareMenuItemSpecificDiscount ||
        compareServiceCharge ||
        // compareMenuItemCourseStatus ||
        compareCourseId
      );
    }, [currentOrder]) || newItemsExist;

  return [orderTouched, setNewItemsExist];
}
