import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useModal from 'hooks/useModal';
import { useHistory } from 'react-router-dom';
import CustomerNameEffect from 'pages/WalkIn/CustomerNameEffect';
import { updateCurrentOrder } from 'pages/Dashboard/action';

function CustomerPhoneEffect() {
  const history = useHistory();
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const customerDetails = useSelector(
    (s) => s.dashboardReducer.acceptedCall?.customerDetails
  );
  const [phoneModalOpen, { openModal }] = useModal('customerPhoneModal');

  useEffect(() => {
    if (customerDetails && Object.keys(customerDetails).length > 0) {
      updateCurrentOrder({ ...currentOrder, ...customerDetails });
    }
  }, [customerDetails?.mobileNumber]);

  useEffect(() => {
    if (!currentOrder?._id) {
      if (
        (!currentOrder?.mobileNumber ||
          currentOrder?.mobileNumber === 'xxx-xxx-xxx') &&
        !phoneModalOpen &&
        !customerDetails?.mobileNumber
      ) {
        openModal();
      }
    }
  }, [
    history,
    phoneModalOpen,
    currentOrder,
    currentOrder?._id,
    currentOrder?.mobileNumber,
    openModal,
  ]);

  return currentOrder.isCustomerNameFetched ? <CustomerNameEffect /> : null;
}

export default CustomerPhoneEffect;
