export const getCartItemKey = (menuItem) => {
  const isHalfHalf = Array.isArray(menuItem);
  const isFourQuarter = menuItem.isFourQuarter;

  let mainKey = '';
  let sizeKey = '';
  let variantKey = '';
  let halfIndex = '';
  let selectedExtraIngredients = '';
  const itemArr = isHalfHalf ? menuItem : isFourQuarter ? menuItem?.items : [];
  itemArr.forEach((item) => {
    mainKey += `-${item._id}`;
    if (isHalfHalf) {
      const { sizeId, variantId } = getSizeAndVariantKey(item);
      sizeKey = sizeId;
      variantKey = variantId;
      halfIndex = item.halfIndex;
      selectedExtraIngredients =
        item.selectedExtraIngredients?.length > 0 &&
        item.selectedExtraIngredients
          .map((ingr) => {
            return ingr._id;
          })
          .join('');
    }
  });
  if (!isHalfHalf && !isFourQuarter) {
    mainKey = `-${menuItem._id}`;
  }
  if (!isHalfHalf) {
    const { sizeId, variantId } = getSizeAndVariantKey(menuItem);
    sizeKey = sizeId;
    variantKey = variantId;
    halfIndex = menuItem.halfIndex;
    selectedExtraIngredients =
      menuItem.selectedExtraIngredients?.length > 0 &&
      menuItem.selectedExtraIngredients
        .map((item) => {
          return item._id;
        })
        .join('');
  }

  return `cartItem-${
    menuItem?.orderIndex
  }${mainKey}-${sizeKey}-${halfIndex}-${selectedExtraIngredients}${
    variantKey !== '' ? `-${variantKey}` : ''
  }`;
};

const getSizeAndVariantKey = (item) => {
  const { selectedSizes } = item;
  const sizeId =
    (selectedSizes && selectedSizes[0] && selectedSizes[0]._id) || '';
  const { variants } = (selectedSizes && selectedSizes[0]) || {};
  const variantId = (variants && variants[0] && variants[0]?._id) || '';
  return { sizeId, variantId };
};
