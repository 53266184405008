export default function AppErrorFallback() {
  const handleRefreshButtonClick = () => {
    window.location.href = '/';
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '4rem',
      }}
    >
      Oops, an error occurred.
      <div>
        <button
          className="order-back-modal-button order-back-modal-button-cancel"
          onClick={handleRefreshButtonClick}
        >
          Please click here to refresh
        </button>
      </div>
    </div>
  );
}
