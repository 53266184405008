import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import { detectMergeRequired } from '../orderEngine/utilities';
import { isObjectNotEmpty } from '../util/utils';

export function performMerge(newItem, existingItem) {
  let existingItemObj = { ...existingItem };
  if (newItem.itemType === 1) {
    existingItemObj = {
      ...existingItem,
      selectedSizes: existingItem.selectedSizes?.map((size) => {
        let newItmHasIt = newItem.selectedSizes?.find((s) => {
          return s._id === size._id;
        });
        if (newItmHasIt?.quantity) {
          return {
            ...size,
            quantity:
              Number(size.quantity ? size.quantity : 0) +
              Number(newItmHasIt.quantity),
            variants: size.variants?.map((v) => {
              let newItemVariant = newItmHasIt.variants?.find(
                (nv) => nv.quantity
              );
              return {
                ...v,
                quantity:
                  Number(v.quantity || 0) +
                  Number(newItemVariant?.quantity || 0),
              };
            }),
          };
        }
        return { ...size };
      }),
      selectedModifiers: existingItem.selectedModifiers?.map((m) => {
        const newItemSelectedModifier = newItem.selectedModifiers?.find(
          (modifier) => modifier._id === m._id
        );
        return {
          ...m,
          subModifiers: m.subModifiers?.map((s) => {
            const newItemSubModifier =
              newItemSelectedModifier?.subModifiers?.find(
                (sm) => sm._id === s._id
              );
            return {
              ...s,
              quantity:
                Number(s.quantity ? s.quantity : 0) +
                Number(newItemSubModifier?.quantity || 0),
              variants: s.variants?.map((v) => {
                const newItemVariant = newItemSubModifier?.variants?.find(
                  (nv) => nv._id === v._id
                );
                return {
                  ...v,
                  quantity:
                    Number(v.quantity || 0) +
                    Number(newItemVariant?.quantity || 0),
                };
              }),
              prices: s.prices?.map((price) => {
                const newItemPrice = newItemSubModifier?.prices?.find(
                  (np) => np._id === price._id
                );
                return {
                  ...price,
                  quantity:
                    Number(price.quantity || 0) +
                    Number(newItemPrice?.quantity || 0),
                  variants: price.variants?.map((pv) => {
                    const newPriceVariant = newItemPrice?.variants?.find(
                      (npv) => npv._id === pv._id
                    );
                    return {
                      ...pv,
                      quantity:
                        Number(pv.quantity || 0) +
                        Number(newPriceVariant?.quantity || 0),
                    };
                  }),
                };
              }),
            };
          }),
        };
      }),
    };
  } else {
    existingItemObj = {
      ...existingItem,
      selectedSizes: existingItem.selectedSizes?.map((size) => {
        let newItmHasIt = newItem.selectedSizes?.find((s) => {
          return s._id === size._id;
        });
        if (newItmHasIt?.quantity) {
          return {
            ...size,
            quantity:
              Number(size.quantity ? size.quantity : 0) +
              Number(newItmHasIt.quantity),
            variants: size.variants?.map((v) => {
              let newItemVariant = newItmHasIt.variants?.find(
                (nv) => nv.quantity
              );
              return {
                ...v,
                quantity:
                  Number(v.quantity || 0) +
                  Number(newItemVariant?.quantity || 0),
              };
            }),
          };
        }
        return { ...size };
      }),
    };
    // irrelevant - not using for now
    if (newItem.selectedSizes) {
      newItem.selectedSizes.map((size) => {
        let newItmHasIt = existingItemObj.selectedSizes.filter((s) => {
          return s._id === size._id;
        });
        if (newItmHasIt && newItmHasIt.length > 0 && newItmHasIt[0].quantity) {
          //size.quantity = Number(size.quantity ? size.quantity : 0) + Number(newItmHasIt[0].quantity);
        } else {
          existingItemObj.selectedSizes.push(size);
        }
        return size;
      });
    }

    if (newItem.specialId) {
      existingItemObj.specialId = newItem.specialId;
    }
  }

  // update discount object only if its exist and not equal
  if (
    isObjectNotEmpty(newItem.selectedDiscount) &&
    isObjectNotEmpty(existingItemObj.selectedDiscount) &&
    existingItemObj.selectedDiscount._id !== newItem.selectedDiscount._id
  ) {
    existingItemObj.selectedDiscount = newItem.selectedDiscount;
  }

  existingItemObj.itemPrice = newItem.itemPrice;
  existingItemObj.extraPrice = newItem.extraPrice;
  existingItemObj.payablePrice = newItem.payablePrice;
  existingItemObj.quantity = existingItemObj.quantity + newItem.quantity;

  return existingItemObj;
}

export function performMergeToModify(newItem, existingItem) {
  let existingItemObj = { ...existingItem };
  if (newItem.itemType === 1) {
    existingItemObj = {
      ...existingItem,
      selectedIngredients: existingItem.selectedIngredients?.map(
        (ingredient) => {
          let newItmHasIt = newItem.removedIngredients?.find((si) => {
            return si._id === ingredient._id;
          });
          if (newItmHasIt) {
            return { ...ingredient, isRemoved: true };
          }
          return ingredient;
        }
      ),
      selectedExtraIngredients: existingItem.selectedExtraIngredients?.map(
        (ingredient) => {
          let newItmHasIt = newItem.selectedExtraIngredients?.find((si) => {
            return si._id === ingredient._id;
          });
          if (newItmHasIt?.quantity && newItmHasIt?.quantity > 0) {
            return {
              ...ingredient,
              quantity: newItmHasIt.quantity,
              price: newItmHasIt?.price,
              selected: true,
            };
          }
          return ingredient;
        }
      ),
      selectedSizes: existingItem.selectedSizes?.map((size) => {
        let newItmHasIt = newItem.selectedSizes?.find((s) => {
          return s._id === size._id;
        });
        if (newItmHasIt?.quantity) {
          return {
            ...size,
            quantity:
              Number(size.quantity ? size.quantity : 0) +
              Number(newItmHasIt.quantity),
            variants: size.variants?.map((v) => {
              let newItemVariant = newItmHasIt.variants?.find(
                (nv) => nv.quantity
              );
              return {
                ...v,
                quantity:
                  Number(v.quantity || 0) +
                  Number(newItemVariant?.quantity || 0),
              };
            }),
          };
        }
        return { ...size };
      }),
      selectedModifiers: existingItem.selectedModifiers?.map((m) => {
        const newItemSelectedModifier = newItem.selectedModifiers?.find(
          (modifier) => modifier._id === m._id
        );
        return {
          ...m,
          subModifiers: m.subModifiers?.map((s) => {
            const newItemSubModifier =
              newItemSelectedModifier?.subModifiers?.find(
                (sm) => sm._id === s._id
              );
            return {
              ...s,
              quantity:
                Number(s.quantity ? s.quantity : 0) +
                Number(newItemSubModifier?.quantity || 0),
              variants: s.variants?.map((v) => {
                const newItemVariant = newItemSubModifier?.variants?.find(
                  (nv) => nv._id === v._id
                );
                return {
                  ...v,
                  quantity:
                    Number(v.quantity || 0) +
                    Number(newItemVariant?.quantity || 0),
                };
              }),
              prices: s.prices?.map((price) => {
                const newItemPrice = newItemSubModifier?.prices?.find(
                  (np) => np._id === price._id
                );
                return {
                  ...price,
                  quantity:
                    Number(price.quantity || 0) +
                    Number(newItemPrice?.quantity || 0),
                  variants: price.variants?.map((pv) => {
                    const newPriceVariant = newItemPrice?.variants?.find(
                      (npv) => npv._id === pv._id
                    );
                    return {
                      ...pv,
                      quantity:
                        Number(pv.quantity || 0) +
                        Number(newPriceVariant?.quantity || 0),
                    };
                  }),
                };
              }),
            };
          }),
        };
      }),
    };
  } else {
    existingItemObj = {
      ...existingItem,
      selectedIngredients: existingItem.selectedIngredients?.map(
        (ingredient) => {
          let newItmHasIt = newItem.removedIngredients?.find((si) => {
            return si._id === ingredient._id;
          });
          if (newItmHasIt) {
            return { ...ingredient, isRemoved: true };
          }
          return ingredient;
        }
      ),
      selectedExtraIngredients: existingItem.selectedExtraIngredients?.map(
        (ingredient) => {
          let newItmHasIt = newItem.selectedExtraIngredients?.find((si) => {
            return si._id === ingredient._id;
          });
          if (newItmHasIt?.quantity && newItmHasIt?.quantity > 0) {
            return {
              ...ingredient,
              quantity: newItmHasIt.quantity,
              price: newItmHasIt?.price,
              selected: true,
            };
          }
          return ingredient;
        }
      ),
      selectedSizes: existingItem.selectedSizes?.map((size) => {
        let newItmHasIt = newItem.selectedSizes?.find((s) => {
          return s._id === size._id;
        });
        if (newItmHasIt?.quantity) {
          return {
            ...size,
            quantity:
              Number(size.quantity ? size.quantity : 0) +
              Number(newItmHasIt.quantity),
            variants: size.variants?.map((v) => {
              let newItemVariant = newItmHasIt.variants?.find(
                (nv) => nv.quantity
              );
              if (newItemVariant?._id === v?._id) {
                return {
                  ...v,
                  quantity:
                    Number(v.quantity || 0) +
                    Number(newItemVariant?.quantity || 0),
                };
              }
              return {
                ...v,
              };
            }),
          };
        }
        return { ...size };
      }),
    };
    // irrelevant - not using for now
    if (newItem.selectedSizes) {
      newItem.selectedSizes.map((size) => {
        let newItmHasIt = existingItemObj.selectedSizes.filter((s) => {
          return s._id === size._id;
        });
        if (newItmHasIt && newItmHasIt.length > 0 && newItmHasIt[0].quantity) {
          //size.quantity = Number(size.quantity ? size.quantity : 0) + Number(newItmHasIt[0].quantity);
        } else {
          existingItemObj.selectedSizes.push(size);
        }
        return size;
      });
    }
    if (newItem.specialId) {
      existingItemObj.specialId = newItem.specialId;
    }
  }

  // TODO: provide better way of keeping those fields
  existingItemObj.selectedDiscount = newItem.selectedDiscount;
  existingItemObj.itemPrice = newItem.itemPrice;
  existingItemObj.payablePrice = newItem.payablePrice;
  existingItemObj.quantity = newItem.quantity;

  return existingItemObj;
}

export function detectAndMergeHalfAndHalf(halfAndHalfItems, currentMenuItems) {
  if (halfAndHalfItems.every((halfItem) => halfItem.isHalf)) {
    // For merge logic
    const allOtherHalfItems = groupBy(
      currentMenuItems,
      (item) => item?.halfIndex
    );
    const allOtherHalfItemsHalfIndexes = Object.keys(allOtherHalfItems);
    let halfIndexToMerge = -1;
    const shouldMergeHalfAndHalfItems = allOtherHalfItemsHalfIndexes.some(
      (halfIndex) => {
        if (
          allOtherHalfItems[halfIndex].every((otherHalfItem, index) => {
            return (
              otherHalfItem._id === halfAndHalfItems?.[index]?._id &&
              detectMergeRequired(otherHalfItem, halfAndHalfItems?.[index])
            );
          })
        ) {
          halfIndexToMerge = halfIndex;
          return true;
        }
        return false;
      }
    );

    if (shouldMergeHalfAndHalfItems && halfIndexToMerge > -1) {
      let mergedHalfAndHalfItems = halfAndHalfItems.map(
        (halfAndHalfItem, index) =>
          performMerge(
            halfAndHalfItem,
            allOtherHalfItems[halfIndexToMerge][index]
          )
      );
      allOtherHalfItems[halfIndexToMerge] = mergedHalfAndHalfItems;

      const allMenuItems = allOtherHalfItemsHalfIndexes
        .map((halfItemsIndex) => allOtherHalfItems[halfItemsIndex])
        .flat();

      return allMenuItems;
    }

    return [...currentMenuItems, ...halfAndHalfItems];
  }

  return [...currentMenuItems, ...halfAndHalfItems];
}

export function detectAndMergeQuarters(quarterItems, currentMenuItems) {
  if (quarterItems.every((quarterItem) => quarterItem.isQuarter)) {
    // For merge logic
    const allOtherQuarterItems = groupBy(
      currentMenuItems,
      (item) => item?.fourQuarterIndex
    );
    const allOtherQuarterItemsIndexes = Object.keys(allOtherQuarterItems);
    let quarterIndexToMerge = -1;
    const shouldMergeQuarterItems = allOtherQuarterItemsIndexes.some(
      (quarterIndex) => {
        if (
          allOtherQuarterItems[quarterIndex].every(
            (otherQuarterItem, index) =>
              otherQuarterItem._id === quarterItems?.[index]?._id &&
              detectMergeRequired(otherQuarterItem, quarterItems?.[index])
          )
        ) {
          quarterIndexToMerge = quarterIndex;
          return true;
        }
        return false;
      }
    );

    if (shouldMergeQuarterItems && quarterIndexToMerge > -1) {
      let mergedHalfAndHalfItems = quarterItems.map((halfAndHalfItem, index) =>
        performMerge(
          halfAndHalfItem,
          allOtherQuarterItems[quarterIndexToMerge][index]
        )
      );
      allOtherQuarterItems[quarterIndexToMerge] = mergedHalfAndHalfItems;

      const allMenuItems = allOtherQuarterItemsIndexes
        .map((halfItemsIndex) => allOtherQuarterItems[halfItemsIndex])
        .flat();

      return allMenuItems;
    }

    return [...currentMenuItems, ...quarterItems];
  }

  return [...currentMenuItems, ...quarterItems];
}

export function notCurrentMenuItemOrderIndex(currentMenuItem, menuItem) {
  if (currentMenuItem.isHalfHalf || currentMenuItem.isFourQuarters) {
    const someWithSameOrderIndex = currentMenuItem.items.every((item) => {
      return item.orderIndex !== menuItem.orderIndex;
    });

    if (someWithSameOrderIndex) {
      return true;
    }
  } else if (menuItem.orderIndex !== currentMenuItem.orderIndex) {
    return true;
  }

  return false;
}

export function removeUnwantedProperties(item, removeProps) {
  const result = omit(item, removeProps);

  return result;
}

export function findPriceOfMostExpensiveItem(items) {
  let price = 0;
  //Find out price for more expensive item
  items.forEach((item) => {
    if (item) {
      if (item.itemType === 1) {
        //Complex Item
        item.selectedModifiers.forEach((modifier) => {
          if (modifier._id === item.baseModifierId) {
            modifier.subModifiers.forEach((subModifier) => {
              if (
                subModifier.quantity > 0 &&
                Number(subModifier.price) > price
              ) {
                price = Number(subModifier.price);
              }
            });
          }
        });
      } else {
        //Simple Item
        item.selectedSizes.forEach((size) => {
          if (size.quantity > 0 && Number(size.price) > price) {
            price = Number(size.price);
          }
        });
      }
    }
  });
  return price;
}

export function updateItemsWithNewPrice(items, price) {
  items.forEach((item) => {
    if (item) {
      if (item.itemType === 1) {
        //Complex Item
        item.selectedModifiers.forEach((modifier) => {
          if (modifier._id === item.baseModifierId) {
            modifier.subModifiers.forEach((subModifier) => {
              if (subModifier.quantity > 0) {
                subModifier.price = Number(price).toFixed(2);
              }
            });
          }
        });
      }
      item.selectedSizes.forEach((size) => {
        if (size.quantity > 0) {
          size.price = Number(price).toFixed(2);
        }
        return size;
      });
    }
  });
  return items;
}
