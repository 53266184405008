import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
  storeIdSelector,
  restaurantIdSelector,
} from '../Dashboard/selectors';
import updatePaymentTypeForDelivery from './apis/updatePaymentTypeForDelivery';

const ViewDriverDeliveries = ({
  orders = [],
  isLoading = false,
  driverId = '',
}) => {
  const history = useHistory();
  const [hasUserInteracted, setHasUserInteracted] = React.useState(false);
  const [ordersList, setOrdersList] = React.useState(orders);
  const [confirmOrderButtonLoading, setConfirmOrderButtonLoading] =
    React.useState(false);

  useEffect(() => {
    setOrdersList(orders);
  }, [orders]);

  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const storeId = useSelector(storeIdSelector);
  const restaurantId = useSelector(restaurantIdSelector);

  const handlePaymentTypeClick = (newPaymentType, index) => {
    if (!hasUserInteracted) setHasUserInteracted(true);
    const newOrderObject = ordersList[index];
    const { paymentType = '' } = newOrderObject;
    if (paymentType !== newPaymentType) {
      setOrdersList([
        ...ordersList.slice(0, index),
        {
          ...newOrderObject,
          paymentType: newPaymentType,
        },
        ...ordersList.slice(index + 1),
      ]);
    }
  };

  const handleSkipClick = () => {
    history.push(`/drivers/${driverId}/cash-float/`);
  };

  const handleConfirmOrder = async () => {
    setConfirmOrderButtonLoading(true);
    const ordersObject = ordersList.map(({ orderId, paymentType }) => ({
      orderId,
      paymentType,
    }));
    const success = await updatePaymentTypeForDelivery(
      driverId,
      storeId,
      restaurantId,
      ordersObject
    );
    setConfirmOrderButtonLoading(false);
    if (success) {
      history.push(`/drivers/${driverId}/cash-float/`);
    }
  };

  const canEnableConfirmOrder =
    checkIfOrdersHaveValidPaymentType(ordersList) && hasUserInteracted;

  const canEnableSkipButton =
    checkIfOrdersHaveValidPaymentType(ordersList) && !hasUserInteracted;

  return (
    <div className="div-view-driver-deliveries">
      <div className="drivers-table-wrapper-div">
        <table className="drivers-table">
          <thead className="drivers-table-head">
            <tr>
              <th className="drivers-table-head-th">Delivery Code</th>
              <th className="drivers-table-head-th">Address</th>
              <th className="drivers-table-head-th">Customer Name</th>
              <th className="drivers-table-head-th">Order Total</th>
              <th className="drivers-table-head-th">Payment Method</th>
            </tr>
          </thead>
          <tbody className="drivers-table-body">
            {isLoading ? (
              <tr className={'drivers-table-body-tr-odd'}>
                <td
                  className="drivers-table-body-td centered text-gray-500"
                  colSpan="42"
                >
                  Loading...
                </td>
              </tr>
            ) : null}
            {!isLoading && ordersList.length === 0 ? (
              <tr className={'drivers-table-body-tr-odd'}>
                <td
                  className="drivers-table-body-td centered text-gray-500"
                  colSpan="42"
                >
                  No Orders for this driver
                </td>
              </tr>
            ) : null}
            {ordersList.map((order, index) => (
              <tr
                key={order.orderId}
                className={
                  index % 2 === 0
                    ? 'drivers-table-body-tr-odd'
                    : 'drivers-table-body-tr-even'
                }
              >
                <td className="drivers-table-body-td font-weight-500 text-gray-900">
                  {order.deliveryCode}
                </td>
                <td className="drivers-table-body-td text-gray-500">
                  {order?.address}
                </td>
                <td className="drivers-table-body-td text-gray-500">
                  {order?.firstName} {order?.lastName || ''}
                </td>
                <td className="drivers-table-body-td text-gray-500">
                  {getAmountWithCountryCurrency({
                    amount: Number(order.payableAmount),
                    locale: `en-${storeCountryIdentifier}`,
                    currencyCode,
                  })}
                </td>
                <td className="drivers-table-body-td">
                  {order.paymentType === ('2' || '3') ? (
                    <button
                      className="order-payment-type-btn font-weight-500 selected"
                      disabled={order.paymentType === ('2' || '3')}
                    >
                      {order.paymentType === '2'
                        ? 'Paid Online'
                        : 'Paid in store'}
                    </button>
                  ) : (
                    <>
                      <button
                        className={`order-payment-type-btn font-weight-500${
                          order.paymentType === '4' ? ' selected' : ''
                        }`}
                        onClick={() => handlePaymentTypeClick('4', index)}
                      >
                        Card
                      </button>
                      <button
                        className={`order-payment-type-btn font-weight-500${
                          order.paymentType === '1' ? ' selected' : ''
                        }`}
                        onClick={() => handlePaymentTypeClick('1', index)}
                      >
                        Cash
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
            {!isLoading ? (
              <tr className="drivers-table-body-tr-odd drivers-table-footer">
                <td
                  className="drivers-table-body-td text-gray-500"
                  colSpan="42"
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: canEnableSkipButton
                        ? 'space-between'
                        : 'flex-end',
                    }}
                  >
                    {canEnableSkipButton ? (
                      <button
                        className="skip-orders-button font-weight-500"
                        onClick={handleSkipClick}
                      >
                        Skip
                      </button>
                    ) : null}
                    <button
                      className="confirm-orders-button font-weight-500"
                      disabled={
                        !canEnableConfirmOrder || confirmOrderButtonLoading
                      }
                      onClick={handleConfirmOrder}
                    >
                      {confirmOrderButtonLoading ? (
                        <PosIconLoading mainColor="#ffffff" size={25} />
                      ) : (
                        'Confirm Orders'
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewDriverDeliveries;

const checkIfOrdersHaveValidPaymentType = (orders) => {
  const hasSome = orders?.some(
    (order) => order.paymentType === '0' || !order.paymentType
  );
  return !hasSome;
};
