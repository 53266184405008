import Cohere from 'cohere-js';
import DriveThru from 'pages/Drive-Thru';
import Payment from 'pages/Payment';
import Kiosk from 'pages/Kiosk';
import Reports from 'pages/Reports';
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  cashier_route,
  custom_icons_route,
  delivery_route,
  dinein_route,
  drivers_route,
  drive_thru_route,
  index_route,
  kiosk_route,
  login_route,
  markup_route,
  menu_route,
  money_count_route,
  music_route,
  orders_route,
  pickup_route,
  reports_route,
  reviews_route,
  settings_route,
  settle_route,
  shifts_clocked_in_route,
  shifts_clock_in_route,
  shifts_confirmation_route,
  shifts_select_role_route,
  shifts_view_shifts_route,
  table_arrangement_route,
  walkin_route,
} from './constants/routesConsts';
import Cashier from './pages/Cashier';
import Delivery from './pages/Delivery';
import DineIn from './pages/DineIn';
import Drivers from './pages/Drivers';
import Home from './pages/Home';
import Markup from './pages/Markup';
import Menu from './pages/Menu/Menu';
import { MoneyCount } from './pages/MoneyCount';
import Music from './pages/Music/Music';
import { CustomIconList } from './pages/OnlyForDev/CustomIconList';
import Orders from './pages/Orders';
import OrderListProvider from './pages/Orders/components/Providers/OrderList/OrderList';
import OrdersGlobalStateProvider from './pages/Orders/components/Providers/OrdersGlobalState/OrdersGlobalState';
import Pickup from './pages/Pickup';
import Reviews from './pages/Reviews/Reviews';
import Settings from './pages/Settings';
import { Settle } from './pages/Settle';
import ClockedInOptionsScreen from './pages/Shifts/ClockedInOptionsScreen';
import ClockInClockOutConfirmation from './pages/Shifts/ClockInClockOutConfirmation';
import ClockInScreen from './pages/Shifts/ClockInScreen';
import ShiftsStateProvider from './pages/Shifts/Context/ShiftsState';
import SelectRole from './pages/Shifts/SelectRole';
import ViewShiftsScreen from './pages/Shifts/ViewShiftsScreen';
import SignIn from './pages/SignIn/SignIn';
import { useAuth } from './pages/SignIn/useProvideAuth';
import TableArrangement from './pages/TableArrangement';
import WalkIn from './pages/WalkIn';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { PrinterProvider } from 'context/PrinterContext';

const queryClient = new QueryClient();

function PrivateRoute({
  component: Component,
  authed,
  checkIfIsStaffAuthorised = false,
  isStaffAuthorised = false,
  ...rest
}) {
  if (checkIfIsStaffAuthorised && !isStaffAuthorised) {
    return <Redirect to={index_route} />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authed === true) {
          return (
            <>
              <PrinterProvider>
                <Component {...props} />
              </PrinterProvider>
            </>
          );
        } else {
          return (
            <Redirect
              to={{ pathname: login_route, state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

const Routes = () => {
  const { user, loading } = useAuth();

  const authed = !!user;

  const isStaffAuthorised = useSelector(
    (state) => state.dashboardReducer.isStaffAuthorised
  );

  const storeConfig = useSelector(
    (state) => state.dashboardReducer.storeConfig
  );

  const ldClient = useLDClient();

  useEffect(() => {
    ldClient.identify({ key: storeConfig?.storeId });
  }, [storeConfig?.storeId]);

  useEffect(() => {
    if (user && authed && !window.location.host.includes('localhost')) {
      Cohere.init('PNGkX0cRtssQYCscUia1vnI4');
      Cohere.identify(
        user?.uid || '', // Required: can be any unique ID
        {
          displayName: user?.email || '', // Optional
          email: user?.email || '', // Optional
        }
      );
    }
  }, [user, authed]);

  if (loading && !authed) {
    // return <div>Loading…</div>;
    // Put a better loader here instead of returning null
    return null;
  }

  return (
    <Suspense fallback={<div />}>
      <OrdersGlobalStateProvider>
        <QueryClientProvider client={queryClient}>
          <OrderListProvider storeId={storeConfig?.storeId}>
            <Switch>
              <Route path={login_route} component={SignIn} />

              <PrivateRoute
                authed={authed}
                path="/payment/:orderId"
                component={Payment}
              />

              <PrivateRoute
                authed={authed}
                exact
                path={index_route}
                component={Home}
              />
              <PrivateRoute
                authed={authed}
                path={orders_route}
                exact
                component={Orders}
              />
              <PrivateRoute
                authed={authed}
                path={`${orders_route}/:orderDateFilter/:orderTypeFilter`}
                component={Orders}
              />
              <PrivateRoute
                authed={authed}
                path={cashier_route}
                component={Cashier}
              />
              <PrivateRoute
                authed={authed}
                path={settle_route}
                component={Settle}
              />
              <PrivateRoute
                authed={authed}
                path={settings_route}
                component={Settings}
              />
              <PrivateRoute
                authed={authed}
                path={reports_route}
                component={Reports}
              />
              <PrivateRoute
                authed={authed}
                path={drivers_route}
                component={Drivers}
              />
              <PrivateRoute
                authed={authed}
                path={custom_icons_route}
                component={CustomIconList}
              />
              <PrivateRoute
                authed={authed}
                path={menu_route}
                component={Menu}
              />

              <PrivateRoute
                authed={authed}
                path={reviews_route}
                component={Reviews}
              />

              <PrivateRoute
                authed={authed}
                path={music_route}
                component={Music}
              />

              <PrivateRoute
                authed={authed}
                path={table_arrangement_route}
                component={TableArrangement}
              />

              <PrivateRoute
                authed={authed}
                isStaffAuthorised={isStaffAuthorised}
                checkIfIsStaffAuthorised={true}
                path={pickup_route}
                component={Pickup}
              />
              <PrivateRoute
                authed={authed}
                isStaffAuthorised={isStaffAuthorised}
                checkIfIsStaffAuthorised={true}
                path={delivery_route}
                component={Delivery}
              />
              <PrivateRoute
                authed={authed}
                isStaffAuthorised={isStaffAuthorised}
                checkIfIsStaffAuthorised={true}
                path={walkin_route}
                component={WalkIn}
              />
              <PrivateRoute
                authed={authed}
                isStaffAuthorised={isStaffAuthorised}
                checkIfIsStaffAuthorised={true}
                path={drive_thru_route}
                component={DriveThru}
              />
              <PrivateRoute
                authed={authed}
                isStaffAuthorised={isStaffAuthorised}
                checkIfIsStaffAuthorised={true}
                path={kiosk_route}
                component={Kiosk}
              />
              <PrivateRoute
                authed={authed}
                // isStaffAuthorised={isStaffAuthorised}
                // checkIfIsStaffAuthorised={true}
                path={dinein_route}
                component={DineIn}
              />
              <PrivateRoute
                authed={authed}
                isStaffAuthorised={isStaffAuthorised}
                checkIfIsStaffAuthorised={true}
                path={`${money_count_route}/:drawerId`}
                component={MoneyCount}
              />

              <PrivateRoute
                authed={authed}
                path={markup_route}
                component={Markup}
              />

              <ShiftsStateProvider>
                <PrivateRoute
                  authed={authed}
                  isStaffAuthorised={isStaffAuthorised}
                  checkIfIsStaffAuthorised={true}
                  path={shifts_clock_in_route}
                  component={ClockInScreen}
                />

                <PrivateRoute
                  authed={authed}
                  isStaffAuthorised={isStaffAuthorised}
                  checkIfIsStaffAuthorised={true}
                  path={shifts_select_role_route}
                  component={SelectRole}
                />

                <PrivateRoute
                  authed={authed}
                  isStaffAuthorised={isStaffAuthorised}
                  checkIfIsStaffAuthorised={true}
                  path={shifts_confirmation_route}
                  component={ClockInClockOutConfirmation}
                />

                <PrivateRoute
                  authed={authed}
                  isStaffAuthorised={isStaffAuthorised}
                  checkIfIsStaffAuthorised={true}
                  path={shifts_clocked_in_route}
                  component={ClockedInOptionsScreen}
                />

                <PrivateRoute
                  authed={authed}
                  isStaffAuthorised={isStaffAuthorised}
                  checkIfIsStaffAuthorised={true}
                  path={shifts_view_shifts_route}
                  component={ViewShiftsScreen}
                />
              </ShiftsStateProvider>

              <Redirect to={index_route} />
            </Switch>
          </OrderListProvider>
        </QueryClientProvider>
      </OrdersGlobalStateProvider>
    </Suspense>
  );
};

export default Routes;
