import React from 'react';
import { PosIconSilverware } from '../../assets/icons/PosIconSilverware';
import { PosIconRepeat } from '../../assets/icons/PosIconRepeat';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import { PosIconWalletCash } from '../../assets/icons/PosIconWalletCash';
import { PosIconEmailInverse } from '../../assets/icons/PosIconEmailInverse';
import { PosIconShapeMan } from '../../assets/icons/PosIconShapeMan';
import { PosIconPrint } from '../../assets/icons/PosIconPrint';
import { PosIconItemSold } from '../../assets/icons/PosIconItemSold';
import { PosIconOrderPrice } from '../../assets/icons/PosIconOrderPrice';
import { PosIconChatBubbles } from '../../assets/icons/PosIconChatBubbles';
import { PosIconBike } from '../../assets/icons/PosIconBike';
import { PosTransferIcon } from 'assets/icons/PosTransferIcon';
import { ScooterIcon } from 'nexticons/outline';

function getIconComponent(iconName) {
  if (!iconName) return false;

  switch (iconName) {
    case 'shape-man':
      return <PosIconShapeMan mainColor="#494b4c" />;
    case 'wallet-cash':
      return <PosIconWalletCash mainColor="#494b4c" />;
    case 'print':
      return <PosIconPrint mainColor="#494b4c" />;
    case 'silverware':
      return <PosIconSilverware mainColor="#494b4c" />;
    case 'item-sold':
      return <PosIconItemSold mainColor="#494b4c" />;
    case 'repeat':
      return <PosIconRepeat mainColor="#494b4c" lineThickness={0.25} />;
    case 'order-price':
      return <PosIconOrderPrice mainColor="#494b4c" />;
    case 'close':
      return <PosIconClose mainColor="#494b4c" />;
    case 'message':
      return <PosIconEmailInverse mainColor="#494b4c" />;
    case 'chat-bubbles':
      return <PosIconChatBubbles mainColor="#494b4c" />;
    case 'bike':
      return <PosIconBike mainColor="#494b4c" />;
    case 'transfer':
      return <PosTransferIcon mainColor="#494b4c" />;
    case 'scooter':
      return <ScooterIcon mainColor="#494b4c" strokeWidth={0.5} />;
    default:
      break;
  }
}

export const SingleOrderIcon = ({ iconName }) => {
  return <>{getIconComponent(iconName)}</>;
};
