import React from 'react';
import './bem-bill-check.scss';
import PropTypes from 'prop-types';
import { BasePartUI } from '../index';

export const BillCheckUI = ({ children, style }) => {
  return (
    <BasePartUI additionalClass="bem-bill-check" style={style}>
      {children}
    </BasePartUI>
  );
};

BillCheckUI.defaultProps = {
  children: null,
  style: {},
};

BillCheckUI.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

function Header({ children }) {
  return <BasePartUI.Header>{children}</BasePartUI.Header>;
}

BillCheckUI.Header = Header;

BillCheckUI.Header.defaultProps = {
  children: null,
};

BillCheckUI.Header.propTypes = {
  children: PropTypes.node,
};

function Body({ children }) {
  return <BasePartUI.Body>{children}</BasePartUI.Body>;
}

BillCheckUI.Body = Body;

BillCheckUI.Body.defaultProps = {
  children: null,
};

BillCheckUI.Body.propTypes = {
  children: PropTypes.node,
};

function Footer({ children }) {
  return <BasePartUI.Footer>{children}</BasePartUI.Footer>;
}

BillCheckUI.Footer = Footer;

BillCheckUI.Footer.defaultProps = {
  children: null,
};

BillCheckUI.Footer.propTypes = {
  children: PropTypes.node,
};

function ActionButtons({ children }) {
  return <div className="bem-bill-check__action-buttons">{children}</div>;
}

BillCheckUI.ActionButtons = ActionButtons;

BillCheckUI.ActionButtons.defaultProps = {
  children: null,
};

BillCheckUI.ActionButtons.propTypes = {
  children: PropTypes.node,
};
