import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';

export function getItemPriceForSpecialDiscount(itemObj) {
  let itemPrice = 0;

  itemObj.selectedSizes &&
    itemObj.selectedSizes.forEach((item) => {
      if (Number(item.quantity) > 0) {
        itemPrice = itemPrice + Number(item.quantity) * Number(item.price);
      }
    });
  if (itemObj.isHalf || itemObj.isQuarter) {
    itemObj.selectedSizesHalfOrFourQuarter &&
      itemObj.selectedSizesHalfOrFourQuarter.forEach((item) => {
        if (Number(item.quantity) > 0) {
          itemPrice = itemPrice + item.quantity * item.price;
        }
      });
  }
  return itemPrice.toFixed(2);
}

export function getSpecialDetectionCount(
  frame,
  rowItems,
  specialCount,
  specialItemsObj
) {
  let ct = 0;
  let s = JSON.parse(JSON.stringify(frame));
  let rItems = JSON.parse(JSON.stringify(rowItems));
  rItems = orderBy(rItems, ['primaryKey'], ['asc']);
  const slotsWithMenuItems = s.slots
    .filter((i) => i.menuItems.length > 0)
    .sort((one, other) => one?.menuItems?.length - other?.menuItems?.length);
  const slotsWithoutMenuItems = s.slots.filter((i) => !i.menuItems?.length);
  s.slots = [...slotsWithMenuItems, ...slotsWithoutMenuItems];
  let itemsUsedForS = [];
  if (s.slots && s.slots.length > 0) {
    s.slots = s.slots.map((i) => {
      let itemExistForMatch = rowItems.filter((r) => {
        return (
          i.categoryKeys.indexOf(r.categoryId) >= 0 &&
          (i.sizeKeys.indexOf(r.sizeKey) >= 0 ||
            i.modifierKeys.indexOf(r.sizeKey) >= 0) &&
          i.menuItems.indexOf(r._id) < 0
        );
      });
      if (itemExistForMatch.length > 0) {
        for (let l = 0; l < itemExistForMatch.length; l++) {
          if (
            !includes(itemsUsedForS, itemExistForMatch[l].primaryKey) &&
            !includes(window.itemsUsed, itemExistForMatch[l].primaryKey) &&
            !i.isSlotOccupied
          ) {
            i.isSlotOccupied = true;
            i.itemId = itemExistForMatch[l]._id;
            i.primaryKey = itemExistForMatch[l].primaryKey;
            i.orderIndex = itemExistForMatch[l].orderIndex;
            i.name = itemExistForMatch[l].name;
            i.isHalf = itemExistForMatch[l].isHalf;
            i.isSecondHalf = itemExistForMatch[l].isSecondHalf;
            i.isQuarter = itemExistForMatch[l].isQuarter;
            i.isSecondQuarter = itemExistForMatch[l].isSecondQuarter;
            i.isThirdQuarter = itemExistForMatch[l].isThirdQuarter;
            i.isFourthQuarter = itemExistForMatch[l].isFourthQuarter;
            i.removedIngredients = itemExistForMatch[l].removedIngredients;
            i.selectedExtraIngredients =
              itemExistForMatch[l].selectedExtraIngredients;
            i.sizeKeyMatched = itemExistForMatch[l].sizeKey;
            itemsUsedForS.push(itemExistForMatch[l].primaryKey);
            ct = ct + 1;
          }
        }
      }
      return i;
    });
    if (ct === s.slots.length) {
      s.isComplete = true;
      window.itemsUsed = [...window.itemsUsed, ...itemsUsedForS];
      specialItemsObj.push({
        _id: s._id,
        name: s.name,
        slots: s.slots,
      });
      if (s.slots.length <= rowItems.length - window.itemsUsed.length)
        getSpecialDetectionCount(
          frame,
          rowItems,
          specialCount + 1,
          specialItemsObj
        );
    }
  }
}

export function detectMergeRequired(
  newItem,
  existingItem,
  isFromSpecial = false
) {
  let diffCount = 0;

  if (newItem.itemType === 1) {
    if (newItem.baseModifierId !== existingItem.baseModifierId) {
      diffCount++;
    }
    if (newItem.selectedModifiers && existingItem.selectedModifiers) {
      let x = newItem.selectedModifiers;
      let y = existingItem.selectedModifiers;
      if (!isArrayEqualModifiers(x, y)) {
        diffCount++;
      }
    }
    if (newItem.removedIngredients && existingItem.removedIngredients) {
      let x = newItem.removedIngredients;
      let y = existingItem.removedIngredients;
      if (!isArrayEqualIngredients(x, y)) {
        diffCount++;
      }
    }
    if (
      newItem.selectedExtraIngredients &&
      existingItem.selectedExtraIngredients
    ) {
      let x = newItem.selectedExtraIngredients;
      let y = existingItem.selectedExtraIngredients;
      if (!isArrayEqualExtras(x, y)) {
        diffCount++;
      }
    }
    if (!isFromSpecial && newItem.iteration !== existingItem.iteration) {
      diffCount++;
    }
    if (newItem.activeSeatId !== existingItem.activeSeatId) {
      diffCount++;
    }
  } else {
    if (newItem.removedIngredients && existingItem.removedIngredients) {
      let x = newItem.removedIngredients;
      let y = existingItem.removedIngredients;
      if (!isArrayEqualIngredients(x, y)) {
        diffCount++;
      }
    }
    if (newItem.selectedSizes && existingItem.selectedSizes) {
      let x = newItem.selectedSizes;
      let y = existingItem.selectedSizes;
      if (!isArrayEqualSizes(x, y)) {
        diffCount++;
      }
    }
    if (
      newItem.selectedExtraIngredients &&
      existingItem.selectedExtraIngredients
    ) {
      let x = newItem.selectedExtraIngredients;
      let y = existingItem.selectedExtraIngredients;
      if (!isArrayEqualExtras(x, y)) {
        diffCount++;
      }
    }
    if (
      (newItem.isHalf && !existingItem.isHalf) ||
      (!newItem.isHalf && existingItem.isHalf)
    ) {
      diffCount++;
    }
    if (
      (newItem.isQuarter && !existingItem.isQuarter) ||
      (!newItem.isQuarter && existingItem.isQuarter)
    ) {
      diffCount++;
    }
    if (
      !isFromSpecial &&
      !isNaN(Number(newItem.iteration)) &&
      newItem.iteration !== existingItem.iteration
    ) {
      diffCount++;
    }

    if (!!newItem?.activeSeatId && !!existingItem?.activeSeatId) {
      if (newItem?.activeSeatId !== existingItem?.activeSeatId) {
        diffCount++;
      }
    }
  }

  if (diffCount !== 0) {
    return false;
  }

  return true;
}

export function detectHalfMergeRequired(
  newItemFH,
  newItemSH,
  existingItemFH,
  existingItemSH
) {
  let diffCount = 0;
  if (newItemFH.removedIngredients && existingItemFH.removedIngredients) {
    let x = newItemFH.removedIngredients;
    let y = existingItemFH.removedIngredients;
    if (!this.isArrayEqualIngredients(x, y)) {
      diffCount++;
    }
  }
  if (newItemFH.selectedSizes && existingItemFH.selectedSizes) {
    let x = newItemFH.selectedSizes;
    let y = existingItemFH.selectedSizes;
    if (!this.isArrayEqualSizes(x, y)) {
      diffCount++;
    }
  }
  if (
    newItemFH.selectedExtraIngredients &&
    existingItemFH.selectedExtraIngredients
  ) {
    let x = newItemFH.selectedExtraIngredients;
    let y = existingItemFH.selectedExtraIngredients;
    if (!this.isArrayEqualExtras(x, y)) {
      diffCount++;
    }
  }
  if (
    (newItemFH.isHalf && !existingItemFH.isHalf) ||
    (!newItemFH.isHalf && existingItemFH.isHalf)
  ) {
    diffCount++;
  }
  if (newItemSH.removedIngredients && existingItemSH.removedIngredients) {
    let x = newItemSH.removedIngredients;
    let y = existingItemSH.removedIngredients;
    if (!this.isArrayEqualIngredients(x, y)) {
      diffCount++;
    }
  }
  if (newItemSH.selectedSizes && existingItemSH.selectedSizes) {
    let x = newItemSH.selectedSizes;
    let y = existingItemSH.selectedSizes;
    if (!this.isArrayEqualSizes(x, y)) {
      diffCount++;
    }
  }
  if (newItemSH.selectedSizes && existingItemSH.selectedSizes) {
    let x = newItemSH.selectedSizes;
    let y = existingItemSH.selectedSizes;
    if (!this.isArrayEqualSizes(x, y)) {
      diffCount++;
    }
  }
  if (
    newItemSH.selectedExtraIngredients &&
    existingItemSH.selectedExtraIngredients
  ) {
    let x = newItemSH.selectedExtraIngredients;
    let y = existingItemSH.selectedExtraIngredients;
    if (!this.isArrayEqualExtras(x, y)) {
      diffCount++;
    }
  }
  if (
    (newItemSH.isHalf && !existingItemSH.isHalf) ||
    (!newItemSH.isHalf && existingItemSH.isHalf)
  ) {
    diffCount++;
  }
  if (diffCount !== 0) {
    return false;
  }
  return true;
}

export function isArrayEqualSizes(x, y) {
  if (x.length !== y.length) return false;
  else {
    let newArrLength = x.length;
    let oldArrEqilength = 0;
    x.map((i) => {
      let isINy = y.filter((m) => {
        let diff = 0;

        if (m._id === i._id) {
          const ivariants = equalizeValue(i.variants);
          const mvariants = equalizeValue(m.variants);

          if (ivariants && mvariants) {
            if (i.variants.length === m.variants.length) {
              i.variants.map((iv) => {
                if (m.variants) {
                  let matchingVariants = m.variants.filter((mv) => {
                    return iv._id === mv._id;
                  });
                  if (matchingVariants.length <= 0) {
                    diff = diff + 1;
                  }
                } else {
                  diff = diff + 1;
                }

                return iv;
              });
            } else {
              diff = diff + 1;
            }
          }
        } else {
          diff = diff + 1;
        }

        return diff === 0;
      });

      if (isINy.length > 0) {
        oldArrEqilength = oldArrEqilength + 1;
      }
      return i;
    });

    if (newArrLength === oldArrEqilength) return true;
    else return false;
  }
}

function equalizeValue(val) {
  return val !== undefined && val !== '' && val !== null ? val : '';
}

export function isArrayEqualExtras(x, y) {
  if (x.length !== y.length) return false;
  else {
    let newArrLength = x.length;
    let oldArrEqilength = 0;
    x.map((i) => {
      let isINy = y.filter((m) => {
        if (m && i) {
          const m_id = equalizeValue(m._id);
          const i_id = equalizeValue(i._id);
          const msizeId = equalizeValue(m.sizeId);
          const isizeId = equalizeValue(i.sizeId);
          const mvariantId = equalizeValue(m.variantId);
          const ivariantId = equalizeValue(i.variantId);
          const mquantity = equalizeValue(m.quantity);
          const iquantity = equalizeValue(i.quantity);

          return (
            m_id === i_id &&
            msizeId === isizeId &&
            mvariantId === ivariantId &&
            mquantity === iquantity
          );
        } else {
          return false;
        }
      });
      if (isINy.length > 0) {
        oldArrEqilength = oldArrEqilength + 1;
      }
      return i;
    });
    if (newArrLength === oldArrEqilength) return true;
    else return false;
  }
}

export function isArrayEqualIngredients(x, y) {
  if (x.length !== y.length) return false;
  else {
    let newArrLength = x.length;
    let oldArrEqilength = 0;
    x.map((i) => {
      let isINy = y.filter((m) => {
        if (m && i) {
          return m._id === i._id;
        }
        return false;
      });
      if (isINy.length > 0) {
        oldArrEqilength = oldArrEqilength + 1;
      }
      return i;
    });
    if (newArrLength === oldArrEqilength) return true;
    else return false;
  }
}

export function isArrayEqualModifiers(x, y) {
  if (x.length !== y.length) return false;
  else {
    let xx = orderBy(JSON.parse(JSON.stringify(x)), 'displayOrder');
    let yy = orderBy(JSON.parse(JSON.stringify(y)), 'displayOrder');
    let xString = '',
      yString = '';
    xx.map((i) => {
      xString = xString + i.name;
      i.subModifiers &&
        i.subModifiers.map((s) => {
          xString = xString + s.name;
          s.variants &&
            s.variants.map((v) => {
              xString = xString + v.name;
              return v;
            });
          return s;
        });
      return i;
    });
    yy.map((i) => {
      yString = yString + i.name;
      i.subModifiers &&
        i.subModifiers.map((s) => {
          yString = yString + s.name;
          s.variants &&
            s.variants.map((v) => {
              yString = yString + v.name;
              return v;
            });
          return s;
        });
      return i;
    });
    if (
      xString.length !== 0 &&
      yString.length !== 0 &&
      xString.toLowerCase() === yString.toLowerCase()
    )
      return true;
    else return false;
  }
}

export function getItemPrice(itemObj, productSetup) {
  let quantity = 0;
  let itemPrice = 0;
  let extraPrice = 0;
  let payablePrice = 0;
  let originalPrice = 0;
  let currentItem = JSON.parse(JSON.stringify(itemObj));

  const additionalCharges =
    (currentItem.isHalf
      ? productSetup?.halfHalfAdditionalCharges
      : productSetup?.fourQuarterAdditionalCharges) || 0;

  if (!currentItem.itemType || currentItem.itemType === 0) {
    currentItem.selectedSizes &&
      currentItem.selectedSizes.map((item) => {
        if (item.quantity > 0) {
          quantity = quantity + item.quantity;
          itemPrice = itemPrice + item.quantity * Number(item.price);
          originalPrice = originalPrice + Number(item.price);
        }
        if (item.variants) {
          item.variants.map((v) => {
            if (v.quantity && Number(v.quantity) > 0) {
              itemPrice = itemPrice + v.quantity * Number(v.price);
              originalPrice = originalPrice + Number(v.price);
            }
            return v;
          });
        }
        return item;
      });
    if (quantity > 0) {
      currentItem.selectedExtraIngredients &&
        currentItem.selectedExtraIngredients.map((item) => {
          if (item.quantity > 0) {
            extraPrice =
              extraPrice + Number(item.quantity) * Number(item.price);
          }
          return item;
        });

      if (currentItem.isHalf || currentItem.isQuarter) {
        itemPrice = itemPrice + additionalCharges * quantity;
        originalPrice = originalPrice + additionalCharges;
      }

      payablePrice =
        extraPrice *
          (productSetup?.chargeFullHalfExtraPrice
            ? Math.max(quantity, 1)
            : quantity) +
        itemPrice;
    }
  } else {
    currentItem.selectedModifiers &&
      currentItem.selectedModifiers.forEach((m) => {
        m.subModifiers.forEach((s) => {
          if (m._id === currentItem.baseModifierId) {
            quantity = s.quantity;
            itemPrice = itemPrice + Number(s.quantity) * Number(s.price);
            originalPrice = originalPrice + Number(s.price);
            if (s.variants) {
              s.variants.forEach((v) => {
                itemPrice = itemPrice + Number(v.quantity) * Number(v.price);
                originalPrice = originalPrice + Number(v.price);
              });
            }
          } else {
            if (s.prices) {
              s.prices.forEach((p) => {
                itemPrice = itemPrice + Number(p.quantity) * Number(p.price);
                originalPrice = originalPrice + Number(p.price);
                if (p.variants) {
                  p.variants.forEach((v) => {
                    itemPrice =
                      itemPrice + Number(v.quantity) * Number(v.price);
                    originalPrice = originalPrice + Number(v.price);
                  });
                }
              });
            }
          }
        });
      });
    if (quantity > 0) {
      currentItem.selectedExtraIngredients &&
        currentItem.selectedExtraIngredients.map((item) => {
          if (item.quantity > 0) {
            extraPrice =
              extraPrice + Number(item.quantity) * Number(item.price);
          }
          return item;
        });

      if (currentItem.isHalf || currentItem.isQuarter) {
        itemPrice = itemPrice + additionalCharges * quantity;
        originalPrice = originalPrice + additionalCharges;
      }

      payablePrice =
        extraPrice *
          (productSetup?.chargeFullHalfExtraPrice
            ? Math.max(quantity, 1)
            : quantity) +
        itemPrice;
    }
  }

  return { itemPrice, extraPrice, payablePrice, originalPrice };
}
