import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  updateCurrentOrder,
  updateCurrentHalfHalfData,
  updateCurrentFourQuarterData,
  resetCurrentFourQuarterData,
  resetCurrentHalfHalfData,
} from 'pages/Dashboard/action';

import { currentOrderSelector } from '../../pages/Dashboard/selectors';

import {
  prepareItemForOrder,
  incrementItemQuantitySingleSize,
} from 'itemProcessor';

import addItemsToOrder from 'cartProcessor/addItemsToOrder';
import addItemToOrder from 'cartProcessor/addItemToOrder';
import addHalfHalfToOrder from 'cartProcessor/addHalfHalfToOrder';
import addFourQuarterToOrder from 'cartProcessor/addFourQuarterToOrder';

import { OrderMenuItem } from 'components/OrderMenuItem/OrderMenuItem';
import { PosIconReload } from 'assets/icons/PosIconReload';

import { getDayNameOrDateAndTime } from 'util/dateHelper';

import './_orders-list.scss';

export const OrdersList = ({
  orderParams,
  parentPage,
  orders,
  price = '',
  isSticky = false,
  hideRepeat = false,
  activeCategoryId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrder = useSelector(currentOrderSelector);

  const handleAddItemToOrder = (menuItem) => {
    const isHalfHalf = Array.isArray(menuItem) && menuItem.length === 2;
    const isFourQuarter = Array.isArray(menuItem) && menuItem.length === 4;
    const inactive =
      isHalfHalf || isFourQuarter
        ? menuItem.some((item) => !item.isPublished)
        : !menuItem.isPublished;

    // skip the item if its unpublished
    if (inactive) return;

    // Add item to order with single size
    if (!isHalfHalf && !isFourQuarter) {
      dispatch(
        updateCurrentOrder(
          addItemToOrder(
            currentOrder,
            prepareItemForOrder(
              incrementItemQuantitySingleSize(menuItem),
              currentOrder
            ),
            orderParams.specials,
            orderParams.orderSetup,
            orderParams.productSetup,
            orderParams.suburbs,
            orderParams.publicHolidays,
            orderParams.storeConfig
          )
        )
      );
    }

    // Add HalfHalf item to order
    if (isHalfHalf) {
      dispatch(updateCurrentHalfHalfData(activeCategoryId));

      const currentHalfHalfData = {
        halfHalfs: [],
      };

      // increment the halfIndex
      const lastItemHalfIndex =
        currentOrder.menuItems.filter((mi) => mi.isHalf).slice(-1)[0]
          ?.halfIndex || 0;

      const newMenuItem = [...menuItem].map((item) => {
        return {
          ...item,
          halfIndex: lastItemHalfIndex + 1,
        };
      });

      dispatch(
        updateCurrentOrder(
          addHalfHalfToOrder(
            currentOrder,
            currentHalfHalfData,
            newMenuItem,
            orderParams.activeSpecials,
            orderParams.orderSetup,
            orderParams.productSetup,
            orderParams.suburbs,
            orderParams.publicHolidays,
            orderParams.storeConfig
          )
        )
      );

      dispatch(resetCurrentHalfHalfData());
    }

    // Add FourQuarter item to order
    if (isFourQuarter) {
      dispatch(updateCurrentFourQuarterData(activeCategoryId));

      const currentFourQuarterData = {
        fourQuarters: [],
      };

      // increment the halfIndex
      const lastItemFourQuarterIndex =
        currentOrder.menuItems.filter((mi) => mi.fourQuarterIndex).slice(-1)[0]
          ?.halfIndex || 0;

      const newMenuItem = [...menuItem].map((item) => {
        return {
          ...item,
          fourQuarterIndex: lastItemFourQuarterIndex + 1,
        };
      });

      dispatch(
        updateCurrentOrder(
          addFourQuarterToOrder(
            currentOrder,
            currentFourQuarterData,
            newMenuItem,
            orderParams.specials,
            orderParams.orderSetup,
            orderParams.productSetup,
            orderParams.suburbs,
            orderParams.publicHolidays,
            orderParams.storeConfig
          )
        )
      );

      dispatch(resetCurrentFourQuarterData());
    }

    history.push(`${parentPage}/order`);
  };

  const handleRepeatOrderClick = (order) => {
    const lastItemHalfIndex =
      currentOrder.menuItems.filter((mi) => mi.isHalf).slice(-1)[0]
        ?.halfIndex || 0;

    // check for increment the halfIndex
    const newMenuItems = [...order.menuItems].map((menuItem) => {
      if (Array.isArray(menuItem)) {
        return menuItem.map((item) => {
          if (item.halfIndex === lastItemHalfIndex) {
            return {
              ...item,
              halfIndex: item.halfIndex + 1,
            };
          } else {
            return item;
          }
        });
      } else {
        return menuItem;
      }
    });

    dispatch(
      updateCurrentOrder(
        addItemsToOrder(
          currentOrder,
          newMenuItems.flat(), // array needs to flattered for the next step
          orderParams.specials,
          orderParams.orderSetup,
          orderParams.productSetup,
          orderParams.suburbs,
          orderParams.publicHolidays,
          orderParams.storeConfig
        )
      )
    );

    history.push(`${parentPage}/order`);
  };

  const headerColors = ['green', 'orange', 'pink'];
  let headerColorIndex = 0;

  return (
    <div className="orders-list">
      {orders?.map((order, idx) => {
        // for coloring order headers
        if (headerColorIndex <= 1) {
          headerColorIndex++;
        } else {
          headerColorIndex = 0;
        }

        const orderHeaderCssClass = clsx(
          [
            'orders-list__header',
            `orders-list__header--theme-${headerColors[headerColorIndex]}`,
          ],
          {
            'orders-list__header--sticky': isSticky,
          }
        );

        return (
          <div className="orders-list__item" key={order.id + '_' + idx}>
            <div className={orderHeaderCssClass}>
              <span className="orders-list__title orders-list__title--size-big">
                {getDayNameOrDateAndTime(order.data)}
              </span>

              {price && <span className="orders-list__title">{price}</span>}

              {!hideRepeat && (
                <button
                  className="orders-list__repeat-order"
                  onClick={() => handleRepeatOrderClick(order)}
                >
                  <span className="orders-list__title orders-list__title--size-big">
                    Repeat Order
                  </span>
                  <PosIconReload
                    mainColor="#ffffff"
                    darkModeColor="#ffffff"
                    className="pos-icon__reload--smaller"
                  />
                </button>
              )}
            </div>

            {order.menuItems?.map((item, idx) => (
              <OrderMenuItem
                key={item._id + '_' + idx}
                menuItem={item}
                onItemClick={handleAddItemToOrder}
                hideControls={false}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

OrdersList.propTypes = {
  orderParams: PropTypes.object.isRequired,
  parentPage: PropTypes.string,
  orders: PropTypes.array,
  price: PropTypes.string,
  isSticky: PropTypes.bool,
  hideRepeat: PropTypes.bool,
  activeCategoryId: PropTypes.string.isRequired,
};
OrdersList.defaultProps = {
  parentPage: '',
  orders: [],
  price: '',
  isSticky: false,
  hideRepeat: false,
};
