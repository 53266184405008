import groupBy from 'lodash/groupBy';
import { useState, useEffect } from 'react';
import { OrdersList } from '../OrdersList/OrdersList';
import { getDayNameOrDateAndTime } from 'util/dateHelper';

import './_previous-orders.scss';
import { ScrollableBoxUI } from '../../UI/components';

function normaliseMenuItemsInOrders(orders) {
  if (orders === null) {
    return false;
  }

  if (orders && orders.length > 0) {
    return orders.map((order) => {
      let menuItems = order.menuItems.map((item) => {
        return {
          ...item,
          orderFromKeys: item.orderFromKeys ?? [],
          orderTypeKeys: item.orderTypeKeys ?? [],
          removedIngredients: item.removedIngredients ?? [],
          selectedExtraIngredients: item.selectedExtraIngredients ?? [],
          selectedIngredients: item.selectedIngredients ?? [],
          selectedModifiers: item.selectedModifiers ?? [],
          selectedSizes: item.selectedSizes ?? [],
          stores: item.stores ?? [],
          isProcessedInKitchen: false,
          isComplete: false,
        };
      });

      let halfAndHalfItems = menuItems.filter((item) => item?.isHalf);
      let quarterItems = menuItems.filter((item) => item.isQuarter);
      let normalItems = menuItems.filter((item) => !item.isHalf && !item?.isQuarter);

      const groupedHalfItems =
        halfAndHalfItems.length > 0 &&
        Object.values(groupBy(halfAndHalfItems, (item) => item?.halfIndex));

      const groupedQuarterItems =
        quarterItems.length > 0 &&
        Object.values(groupBy(quarterItems, (item) => item?.fourQuarterIndex));

      const halfAndHalfItemsValues = groupedHalfItems.length > 0 ? groupedHalfItems : null;

      const quarterItemsValues = groupedQuarterItems.length > 0 ? groupedQuarterItems : null;

      const newMenuItems = normalItems
        .concat(halfAndHalfItemsValues, quarterItemsValues)
        .filter((item) => item !== null);

      return {
        ...order,
        menuItems: newMenuItems,
      };
    });
  }
}

const PreviousOrders = ({ ordersHistory, orderParams, parentPage, activeCategoryId }) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (ordersHistory && ordersHistory.length) {
      setOrders(normaliseMenuItemsInOrders(ordersHistory));
    }
  }, [ordersHistory]);

  const totalSpend = () => {
    const total = orders.reduce((acc, cur) => (acc += Number(cur.payableAmount)), 0);
    return `$${total.toFixed(2)}`;
  };

  return (
    <div className="previous-orders">
      <div className="main-area-header previous-orders__header">
        <h3 className="previous-orders__title">
          {orders && !orders.length > 0 && 'No '}Previous Orders
        </h3>

        {orders.length > 0 && (
          <div className="previous-orders__grid">
            <span className="previous-orders__text">
              Last Ordered {orders[0]?.data && getDayNameOrDateAndTime(orders[0]?.data, false)}
            </span>
            <span className="previous-orders__text">{totalSpend()} Total Spend</span>
          </div>
        )}
      </div>

      <div className="main-area-body-wrap">
        <ScrollableBoxUI>
          <div className="main-area-body main-area-body--no-padding">
            <div className="food-cards food-cards--no-margin">
              {orders.length > 0 && (
                <div className="previous-orders__body">
                  <div className="previous-orders__body-inner">
                    <OrdersList
                      orderParams={orderParams}
                      parentPage={parentPage}
                      orders={orders}
                      activeCategoryId={activeCategoryId}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </ScrollableBoxUI>
      </div>
    </div>
  );
};

export default PreviousOrders;
