import { useMemo } from 'react';
import { useSortByDisplayOrder } from './useSortByDisplayOrder';

const useRouteByMenuItemType = (path, menuItem) => {
  const modifiers = useSortByDisplayOrder(menuItem?.selectedModifiers || []);
  return useMemo(() => {
    return getRouteByMenuType({ menuItem, path, modifiers });
  }, [menuItem, path, modifiers]);
};

export function getRouteByMenuType({ menuItem, path, modifiers }) {
  if (menuItem && path) {
    if (menuItem?.isHalfHalf) {
      const { categoryId } = menuItem?.items[0];
      return `${path}/category/${categoryId}/half-half/`;
    } else if (menuItem?.itemType === 0) {
      return `${path}/menuItem/${menuItem?._id}/size/size-xxx`;
    } else {
      return `${path}/menuItem/${menuItem?._id}/modifiers/${modifiers[0]?._id}`;
    }
  }
}
export default useRouteByMenuItemType;
