import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconReceiveMoney = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-receive-money': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M11.778 9.106a.581.581 0 0 0 .825 0l3.485-3.48-.825-.824-2.497 2.494V0h-1.162v7.296L9.12 4.802l-.825.824zm.402 11.774a2.9 2.9 0 1 1 0-5.8 2.9 2.9 0 0 1 0 5.8zm-4.06-2.9a4.06 4.06 0 1 0 8.12 0 4.06 4.06 0 0 0-8.12 0zm19.72 9.86H5.8v-3.48h17.98c.32 0 .58-.26.58-.58V17.4h3.48zM1.16 23.2V12.76H23.2V23.2zm27.26-6.96h-4.06v-4.06a.58.58 0 0 0-.58-.58H.58a.58.58 0 0 0-.58.58v11.6c0 .32.26.58.58.58h4.06v4.06c0 .32.26.58.58.58h23.2c.32 0 .58-.26.58-.58v-11.6a.58.58 0 0 0-.58-.58z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconReceiveMoney.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
