import ActionTypes from './constants';
import initState from './initState';

const ordersReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.ordersListFetchStatusUpdate:
      return {
        ...state,
        orderFetchStatus: payload.ordersListFetchStatus,
      };

    case ActionTypes.UPDATE_ORDER_TIME_SORT_ORDER_FILTER:
      return {
        ...state,
        orderTimeSortOrderFilter: payload.orderTimeSortOrderFilter,
      };
    case ActionTypes.orderBarChangeAnim:
      return {
        ...state,
        orderBarChange: payload,
      };
    case ActionTypes.setScrollPosition:
      return {
        ...state,
        scrolledStatus: state.scrolledStatus.map((item) => {
          if (payload.actionType === item.actionType) {
            if (payload.position && payload.position !== item.position) {
              item.position = payload.position;
              item.class = 'shadow';
            } else if (!payload.position) {
              item.position = 0;
              item.class = '';
            }
          }

          return item;
        }),
      };
    case ActionTypes.resetScrollPosition:
      return {
        ...state,
        scrolledStatus: state.scrolledStatus.map((item) => {
          if (payload.actionType === item.actionType) {
            if (
              payload.wrapperRef !== undefined &&
              payload.wrapperRef !== null
            ) {
              // payload.wrapperRef.current.scrollTop({top: 0});
              payload.wrapperRef.current.view.scrollTo({
                top: 0,
                // behavior: 'smooth',
              });
            }
            item.position = 0;
            item.class = '';
          }

          return item;
        }),
      };
    case ActionTypes.retainScrollTop:
      return {
        ...state,
        scrolledStatus: state.scrolledStatus.map((item) => {
          if (payload.actionType === item.actionType) {
            if (
              payload.wrapperRef !== undefined &&
              payload.wrapperRef !== null
            ) {
              // payload.wrapperRef.current.scrollTop({top: 0});
              payload.wrapperRef.current.view.scrollTo({
                top: item.position + payload.offset,
                //behavior: 'smooth',
              });
            }
            // item.position = 0;
            // item.class = '';
          }

          return item;
        }),
      };
    case ActionTypes.changeModalTime:
      return {
        ...state,
        orderWaitingObj: {
          ...state.orderWaitingObj,
          [payload.name]: payload.quantity,
        },
      };
    case ActionTypes.toggleViewOrderTimeModal:
      return {
        ...state,
        viewOrdersActionTimeModal: {
          ...state.viewOrdersActionTimeModal,
          [payload.name]: payload.active,
        },
      };
    case ActionTypes.setActiveSortType:
      return {
        ...state,
        viewOrdersSort: state.viewOrdersSort.map((item) => {
          if (item.type === payload) {
            item.status = true;
          } else {
            item.status = false;
          }

          return item;
        }),
      };
    case ActionTypes.setActiveGuest: {
      let newState = { ...state };
      newState.orderList = [...state.orderList];
      newState.orderList.map((order) => {
        if (order._id === payload) {
          order.active = true;
        } else {
          order.active = false;
        }
        return order;
      });
      return newState;
    }
    case ActionTypes.setOrderType: {
      let newState = { ...state };
      newState.orderType = [...state.orderType];
      newState.orderType = [...payload.orderType];
      return newState;
    }
    case ActionTypes.setSelectedOrder:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          ...payload,
        },
      };
    case ActionTypes.setOrderList:
      return {
        ...state,
        orderList: payload,
      };
    case ActionTypes.setCopyOfOrderList:
      return {
        ...state,
        copyOfOrderList: payload,
      };
    case ActionTypes.setOrderWaitingTimeObj:
      return {
        ...state,
        orderWaitingObj: payload,
      };
    case ActionTypes.setDrivers:
      return {
        ...state,
        drivers: payload,
      };
    case ActionTypes.resetOrdersPageData: {
      let newState = { ...state };
      newState.selectedOrder = { ...state.selectedOrder };
      newState.selectedOrder = {};
      newState.orderList = [...state.orderList];
      // newState.orderList.map((order) => {
      //   if (order.active) {
      //     order.active = false;
      //   }
      //   return order;
      // });

      return newState;
    }
    case ActionTypes.setFilterType:
      return {
        ...state,
        filterType: payload,
      };
    case ActionTypes.togglePinModal:
      return {
        ...state,
        pinModal: payload,
      };
    case ActionTypes.toggleViewOrderModal:
      return {
        ...state,
        viewOrdersActionModal: payload,
      };
    case ActionTypes.toggleDeliveryOrderModal:
      return {
        ...state,
        deliveryOrderModal: payload,
      };
    case ActionTypes.toggleActionsModal:
      return {
        ...state,
        actionsModal: payload,
      };
    case ActionTypes.transferOrderModal:
      return {
        ...state,
        transferOrderModal: payload,
      };
    case ActionTypes.requestUberDriver:
      return {
        ...state,
        requestUberDriver: payload,
      };
    case ActionTypes.toggleRefundOrderModal:
      return {
        ...state,
        refundOrderModal: {
          ...state.refundOrderModal,
          ...payload,
        },
      };
    case ActionTypes.togglePlanBillModal:
      return {
        ...state,
        printBillModal: payload,
      };
    case ActionTypes.setOrderBarType:
      return {
        ...state,
        orderBarType: payload,
      };
    case ActionTypes.setOrderSideBarType:
      return {
        ...state,
        orderSideBarType: payload,
      };
    case ActionTypes.togglePaymentViewModal:
      return {
        ...state,
        paymentViewModal: payload,
      };
    case ActionTypes.SHOW_SPLIT_PAYMENT_MODAL:
      return {
        ...state,
        showSplitPaymentModal: payload,
      };
    case ActionTypes.UPDATE_ORDER_TYPE_FILTER:
      return {
        ...state,
        orderTypeFilter: payload.orderTypeFilter,
      };
    default:
      return state;
  }
};

export default ordersReducer;
