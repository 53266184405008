import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import { getCurrentDayFlag, getDayFlag } from '../util/timeHelper';
import {
  detectMergeRequired,
  getItemPriceForSpecialDiscount,
  getSpecialDetectionCount,
} from './utilities';

// const someFunction = (f) => {};

export const calculateSpecialDiscountForSpecificItem = (specialObj, item) => {
  let specialsDiscountsList = [];
  let specialSpecificDiscount = 0;
  specialObj.rowItems.forEach((rowItem) => {
    if (rowItem.specialId) {
      const noOfSpecialDeal = specialObj.specials.filter(
        (item) => item._id === rowItem.specialId
      ).length;
      const noOfItemswithSameSpecialCategory =
        specialObj.rowItems.filter(
          (item) => item.specialId === rowItem.specialId
        ).length / noOfSpecialDeal;

      const specialDiscountsWithSameId = specialObj.specials.filter(
        (special) => special._id === rowItem.specialId
      );
      const discount =
        specialDiscountsWithSameId.reduce(
          (prevDiscount, currItem) => prevDiscount + Number(currItem.discount),
          0
        ) / specialDiscountsWithSameId.length;

      const alreadyAddedItem = specialsDiscountsList.find(
        (specialItem) =>
          specialItem.menuItemId === rowItem._id &&
          specialItem.specialId === rowItem.specialId
      );

      if (typeof alreadyAddedItem === 'object') {
        const objIndex = specialsDiscountsList.findIndex(
          (item) => item.menuItemId === rowItem._id
        );
        specialsDiscountsList[objIndex].discountPerItem = (
          Number(specialsDiscountsList[objIndex].discountPerItem) +
          Number(discount) / noOfItemswithSameSpecialCategory
        ).toFixed(2);
      } else {
        specialsDiscountsList = [
          ...specialsDiscountsList,
          {
            menuItemId: rowItem._id,
            specialId: rowItem.specialId,
            discountPerItem: (
              Number(discount) / noOfItemswithSameSpecialCategory
            ).toFixed(2),
          },
        ];
      }
    }
  });

  const specialItemDetail = specialsDiscountsList.find(
    (specialItem) => specialItem.menuItemId === item._id
  );
  if (typeof specialItemDetail === 'object') {
    specialSpecificDiscount = Number(specialItemDetail.discountPerItem);
  }

  return specialSpecificDiscount.toFixed(2);
};

const detectSpecial = (
  currentOrder,
  initialrowItems,
  allSpecials,
  orderSetup,
  publicHolidays,
  storeConfig = {}
) => {
  window.itemsUsed = [];

  let specialItemsObj = [];
  let specialObj = { specials: [] };
  let specialFrames = getSpecialFrames(
    currentOrder,
    allSpecials,
    orderSetup,
    publicHolidays,
    storeConfig
  );

  const rowItems = orderSetup?.isSpecialsManuallyDisabled
    ? initialrowItems.filter((item) => item?.isSpecialItem)
    : initialrowItems;
  if (rowItems && rowItems.length > 0 && specialFrames.length > 0) {
    let specialFramesType = specialFrames;

    specialFramesType = sortBy(specialFramesType, 'slotLength').reverse();

    specialFramesType.forEach((s) => {
      getSpecialDetectionCount(s, rowItems, 0, specialItemsObj);
    });

    let detectedSpecials = specialItemsObj;
    if (detectedSpecials && detectedSpecials.length > 0) {
      detectedSpecials.forEach((sd) => {
        let finalSpecial = sd;
        let itemsToBeAddedInSpecials = [];
        finalSpecial.slots.forEach((ss) => {
          let rowMenuItems = JSON.parse(JSON.stringify(currentOrder.menuItems));
          let filteredItem = [];
          if (ss.isHalf) {
            filteredItem = rowMenuItems.filter((ff) => {
              return (
                ff._id === ss.itemId &&
                ff.isSecondHalf === false &&
                ff.orderIndex === ss.orderIndex
              );
            });
          } else {
            filteredItem = rowMenuItems.filter((ff) => {
              return ff._id === ss.itemId && ff.orderIndex === ss.orderIndex;
            });
          }
          if (filteredItem && filteredItem.length > 0) {
            filteredItem.forEach((ftmm) => {
              let ttml = ftmm;
              if (detectMergeRequired(ss, ttml, true)) {
                ttml.selectedSizes = ttml.selectedSizes.filter((ggm) => {
                  return ss.sizeKeyMatched === ggm._id;
                });
                if (ttml.selectedSizes && ttml.selectedSizes.length > 0) {
                  if (ttml.isHalf) {
                    ttml.selectedSizes[0].quantity = 0.5;
                  } else if (ttml.isQuarter) {
                    ttml.selectedSizes[0].quantity = 0.25;
                  } else {
                    ttml.selectedSizes[0].quantity = 1;
                  }
                  if (ttml.isHalf || ttml.isQuarter) {
                    let othersHalfOrFourQuarter = rowMenuItems.filter((m) => {
                      return (
                        (m.halfIndex === ttml.halfIndex &&
                          m.isSecondHalf === true) ||
                        (m.fourQuarterIndex === ttml.fourQuarterIndex &&
                          (m.isSecondQuarter === true ||
                            m.isThirdQuarter === true ||
                            m.isFourthQuarter === true))
                      );
                    });
                    othersHalfOrFourQuarter.length &&
                      othersHalfOrFourQuarter?.forEach((others) => {
                        let ttmll = others;
                        if (ttml.selectedSizesHalfOrFourQuarter) {
                          ttml.selectedSizesHalfOrFourQuarter.push({
                            ...ttmll.selectedSizes[0],
                            quantity: ttml.isHalf ? 0.5 : 0.25,
                          });
                        } else {
                          ttml.selectedSizesHalfOrFourQuarter = [
                            {
                              ...ttmll.selectedSizes[0],
                              quantity: ttml.isHalf ? 0.5 : 0.25,
                            },
                          ];
                        }
                        if (ttml.selectedIngredientsHalfOrFourQuarter) {
                          ttml.selectedIngredientsHalfOrFourQuarter.push(
                            ttmll.selectedIngredients
                          );
                        } else {
                          ttml.selectedIngredientsHalfOrFourQuarter =
                            ttmll.selectedIngredients;
                        }
                        if (ttml.removedIngredientsHalfOrFourQuarter) {
                          ttml.removedIngredientsHalfOrFourQuarter.push(
                            ttmll.removedIngredients
                          );
                        } else {
                          ttml.removedIngredientsHalfOrFourQuarter =
                            ttmll.removedIngredients;
                        }
                        if (ttml.selectedExtraIngredientsHalfOrFourQuarter) {
                          ttml.selectedExtraIngredientsHalfOrFourQuarter.push(
                            ttmll.selectedExtraIngredients
                          );
                        } else {
                          ttml.selectedExtraIngredientsHalfOrFourQuarter =
                            ttmll.selectedExtraIngredients;
                        }
                      });
                  }
                  ttml.isFreeItem = ss.isFreeItem;
                  ttml.discountedPrice = ss.discountedPrice;
                  itemsToBeAddedInSpecials.push(ttml);
                }
              }
            });
          }
          rowItems.forEach((rI) => {
            if (rI.primaryKey === ss.primaryKey) {
              rI.specialId = sd._id;
              rI.itemsUsedInSpecials = true;
            }
          });
        });

        let specialItems = allSpecials
          ? allSpecials.filter((obj) => {
              return obj._id === sd._id;
            })
          : [];
        if (
          itemsToBeAddedInSpecials &&
          itemsToBeAddedInSpecials.length > 0 &&
          specialItems.length > 0
        ) {
          let specialItem = specialItems[0];
          let totalCost = 0,
            specialDiscount = 0,
            upsalePrice = 0;

          itemsToBeAddedInSpecials.forEach((m) => {
            if (specialItem.type === '1') {
              let itemPrice = getItemPriceForSpecialDiscount(m);
              let upsale = getUpsalePrice(m, specialItem);

              upsalePrice = upsalePrice + upsale;
              totalCost = totalCost + Number(itemPrice);
            } else {
              let itemPrice = getItemPriceForSpecialDiscount(m);
              let upsale = getUpsalePrice(m, specialItem);
              upsalePrice = upsalePrice + upsale;
              if (m.isFreeItem === true && m.discountedPrice === '0')
                specialDiscount = specialDiscount + Number(itemPrice);
              else if (m.isFreeItem === true && m.discountedPrice !== '0')
                specialDiscount =
                  specialDiscount +
                  (Number(itemPrice) - Number(m.discountedPrice));
              else totalCost = totalCost + Number(itemPrice);
            }
          });
          if (specialItem.type === '1') {
            specialDiscount = totalCost - Number(specialItem.cost);
          }
          let sObj = {
            _id: specialItem._id,
            name: specialItem.name,
            discount: specialDiscount.toFixed(2),
            cost: specialItem.cost,
            type: specialItem.type,
            upsalePrice: upsalePrice.toFixed(2),
          };

          specialObj.specials.push(sObj);
        }
      });
    }
  }

  specialObj.rowItems = rowItems;
  return specialObj;
};

function getUpsalePrice(menuItem, specialItem) {
  let upsalePrice = 0;
  if (
    specialItem.selectedCategories &&
    specialItem.selectedCategories.length > 0
  ) {
    specialItem.selectedCategories.forEach((m) => {
      if (m.menuItems && m.menuItems.length > 0) {
        let matchedItem = m.menuItems.find((f) => {
          return (
            f._id === menuItem._id &&
            f.categoryId === menuItem.categoryId &&
            f.isActive === true
          );
        });
        if (matchedItem?.price) {
          upsalePrice = Number(matchedItem.price);
        }
      }
    });
  }
  return upsalePrice;
}

function getSpecialFrames(
  currentOrder,
  allSpecials,
  orderSetup,
  publicHolidays,
  storeConfig = {}
) {
  let specialFrames = [];
  if (allSpecials && allSpecials.length > 0) {
    allSpecials.forEach((s) => {
      if (currentOrder.deliveryDate) {
        if (
          s.hasOwnProperty(
            getDayFlag(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').dayText
          ) &&
          s[
            getDayFlag(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').dayText
          ] === true &&
          isSpecialAvaiableAllDay(
            s,
            currentOrder.deliveryDate,
            storeConfig.timeZone
          ) &&
          isSpecialAvaiableOnPublicHoliday(
            s,
            currentOrder.deliveryDate,
            storeConfig.timeZone,
            orderSetup,
            publicHolidays
          ) &&
          s.orderTypeKeys.includes(currentOrder.orderType)
        ) {
          let x = {
            _id: s._id,
            name: s.name,
            type: s.type,
            isComplete: false,
            slots: [],
          };
          s.selectedCategories &&
            s.selectedCategories.forEach((sp) => {
              new Array(Number(sp.quantity)).fill().forEach((_m, i) => {
                let u = {
                  categoryKeys: sp.categories
                    .filter((s) => {
                      return s.isActive;
                    })
                    .map((s) => s._id),
                  sizeKeys: sp.menuSizes
                    ? sp.menuSizes
                        .filter((s) => {
                          return s.isActive;
                        })
                        .map((s) => s._id)
                    : [],
                  modifierKeys: sp.subModifiers
                    ? sp.subModifiers
                        .filter((s) => {
                          return s.isActive;
                        })
                        .map((s) => s._id)
                    : [],
                  menuItems: sp.menuItems
                    .filter((s) => {
                      return !s.isActive;
                    })
                    .map((s) => s._id),
                  id: i + 1,
                  index: sp.isFreeItem ? i + 1 : -50,
                  isSlotOccupied: false,
                  isFreeItem: sp.isFreeItem,
                  discountedPrice: sp.discountedPrice
                    ? sp.discountedPrice
                    : '0',
                };
                x.slots.push(u);
              });
            });
          x.slotLength = x.slots.length;
          specialFrames.push(x);
        }
      } else {
        if (
          s.hasOwnProperty(getCurrentDayFlag(storeConfig.timeZone)?.dayText) &&
          s[getCurrentDayFlag(storeConfig.timeZone)?.dayText] === true &&
          isSpecialAvaiableAllDay(s, undefined, storeConfig.timeZone) &&
          isSpecialAvaiableOnPublicHoliday(
            s,
            undefined,
            storeConfig.timeZone,
            orderSetup,
            publicHolidays
          )
        ) {
          let x = {
            _id: s._id,
            name: s.name,
            type: s.type,
            isAvailablePickup: s.isAvailablePickup,
            isAvailableDelivery: s.isAvailableDelivery,
            isComplete: false,
            slots: [],
          };
          s.selectedCategories &&
            s.selectedCategories.forEach((sp) => {
              new Array(Number(sp.quantity)).fill().forEach((_m, i) => {
                let u = {
                  categoryKeys: sp.categories
                    .filter((s) => {
                      return s.isActive;
                    })
                    .map((s) => s._id),
                  sizeKeys: sp.menuSizes
                    ? sp.menuSizes
                        .filter((s) => {
                          return s.isActive;
                        })
                        .map((s) => s._id)
                    : [],
                  modifierKeys: sp.subModifiers
                    ? sp.subModifiers
                        .filter((s) => {
                          return s.isActive;
                        })
                        .map((s) => s._id)
                    : [],
                  menuItems: sp.menuItems
                    .filter((s) => {
                      return !s.isActive;
                    })
                    .map((s) => s._id),
                  id: i + 1,
                  index: sp.isFreeItem ? i + 1 : -50,
                  isSlotOccupied: false,
                  isFreeItem: sp.isFreeItem,
                  discountedPrice: sp.discountedPrice
                    ? sp.discountedPrice
                    : '0',
                };
                x.slots.push(u);
              });
            });
          x.slotLength = x.slots.length;
          specialFrames.push(x);
        }
      }
    });
  }
  return specialFrames;
}

function isSpecialAvaiableAllDay(special, delDate, timeZone) {
  if (special.isAvailableFullDay) return true;
  else {
    let todayDate = moment(
      moment().tz(timeZone).format('YYYY-MM-DD HH:mm'),
      'YYYY-MM-DD HH:mm'
    );
    if (delDate) {
      todayDate = moment(delDate, 'YYYY-MM-DD HH:mm');
    }
    let startObj = moment(
      `${todayDate.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
        special.startTime
      )}`,
      'YYYY-MM-DD HH:mm'
    );
    let endObj = moment(
      `${todayDate.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
        special.endTime
      )}`,
      'YYYY-MM-DD HH:mm'
    );
    if (startObj.isSameOrBefore(todayDate) && endObj.isSameOrAfter(todayDate)) {
      return true;
    } else {
      return false;
    }
  }
}

function isSpecialAvaiableOnPublicHoliday(
  special,
  delDate,
  timeZone,
  orderSetup,
  publicHolidays
) {
  let todayDate = moment(
    moment().tz(timeZone).format('YYYY-MM-DD HH:mm'),
    'YYYY-MM-DD HH:mm'
  );
  if (delDate) {
    todayDate = moment(delDate, 'YYYY-MM-DD HH:mm');
  }
  if (
    orderSetup &&
    orderSetup.isOptedInForHolidaySurcharge &&
    orderSetup.publicHolidaySurcharge &&
    publicHolidays &&
    publicHolidays.length > 0
  ) {
    let dates = publicHolidays.filter((m) => {
      return m === moment(todayDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
    });
    if (dates.length > 0 && !special.isAvailablePublicHoliday) return false;
    else return true;
  } else return true;
}

function convertSecondsTo12Hour(timeStamp) {
  let hours = timeStamp / 3600;
  let minutes = (timeStamp / 60) % 60;
  let ampm = '';
  if (hours === 24) {
    hours = 23;
    minutes = 59;
  }
  hours >= 12 ? (ampm = 'PM') : (ampm = 'AM');
  return moment(`${hours}:${minutes} ${ampm}`, 'HH:mm A').format('HH:mm');
}

export default detectSpecial;
