import GeneralModal from '../../modals/GeneralModal/index-new';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';

const AlertModal = ({
  modalStatus,
  toggleModal,
  header,
  description,
  proceedText,
  cancelText,
  handleCancel,
  handleProceed,
  enableProceedButtonLoader = false,
  width = '512px',
}) => {
  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="center"
      effect="move"
      overlay=""
      boxWidth={width}
      className="alert-modal"
      closeBtn="outside"
    >
      <div className="order-back-modal-text-div">
        <h3 className="order-back-modal__header">{header}</h3>
        <div className="order-back-modal__text-color">{description}</div>
      </div>
      <div className="order-back-modal-buttons-div">
        <button
          type="button"
          className="order-back-modal-button order-back-modal-button-stay"
          onClick={handleCancel}
          disabled={enableProceedButtonLoader}
        >
          {cancelText}
        </button>
        <button
          type="button"
          className="order-back-modal-button order-back-modal-button-cancel"
          onClick={handleProceed}
          disabled={enableProceedButtonLoader}
        >
          {enableProceedButtonLoader ? (
            <PosIconLoading
              mainColor="#ffffff"
              className="cursor-pointer"
              size="18px"
            />
          ) : (
            proceedText
          )}
        </button>
      </div>
    </GeneralModal>
  );
};

export default AlertModal;
