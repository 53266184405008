import React from 'react';
import './bem-nav-button.scss';
import PropTypes from 'prop-types';
import { getUIClassesFromProps } from '../modifiersMapping';
import clsx from 'clsx';

export const NavButtonUI = ({
  modifierClassName,
  title,
  icon,
  disabled,
  ...props
}) => {
  return (
    <button
      type="button"
      className={getUIClassesFromProps('bem-nav-button', { modifierClassName })}
      disabled={disabled}
      {...props}
    >
      <span className="bem-nav-button__icon-box">{icon}</span>
      <span
        className={clsx('bem-nav-button__title', { ['disable']: disabled })}
      >
        {title}
      </span>
    </button>
  );
};

NavButtonUI.defaultProps = {
  modifierClassName: '',
  title: '',
  icon: false,
};

NavButtonUI.propTypes = {
  modifierClassName: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
};
