import { _auth } from '../../../../firebase';

interface AssignDeliveryProps {
  deliveryCode: string;
  driverId: string;
  restaurantId: string;
  storeId: string;
  timestampStart: Number;
  shiftId: string;
}

export const assignDelivery = async (
  reqObj: AssignDeliveryProps,
  nextOrderConfigURL: string
): Promise<any> => {
  const firebaseAuth: any = _auth;
  const accessToken = await firebaseAuth.currentUser.getIdToken(true);
  const response = await fetch(`${nextOrderConfigURL}/addDelivery`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...reqObj,
    }),
  });
  const result = await response.json();
  return result;
};
