import axios from 'axios';
import { _auth } from '../firebase';
import { API_URL } from '../configuration/apiUrl';
class ApiClient {
  static defaultOptions = {
    apiUrl: API_URL,
    getAuthToken: true,
    cancelMessage: 'canceled',
  };

  constructor(client, options) {
    this.options = { ...this.constructor.defaultOptions, ...options };
    this.client =
      client || axios.create({ baseURL: this.options.apiUrl || '' });
    this.Promise = Promise;
    this.CancelToken = axios.CancelToken;
    this.sourcesCancel = {};
    this.config = null;
    this.isCancel = axios.isCancel;

    // requests requires JWT authentication
    if (this.options.getAuthToken) {
      // if getAuthToken method provided set interceptors
      this.client.interceptors.request.use(
        async (config) => {
          const requestConfig = { headers: {}, ...config };
          // get valid token before make request
          const token = await _auth.currentUser.getIdToken(true).catch((e) => {
            throw new Error(`Authorization error: ${e}`);
          });

          if (token) {
            // set header with token
            requestConfig.headers = {
              Authorization: `Bearer ${token}`,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            };
          }
          return requestConfig;
        },
        (error) => Promise.reject(error)
      );

      this.client.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (
            error.message ||
            (error.response &&
              [400, 401, 403].includes(error.response.status) !== true) ||
            (error.config && error.config.retry)
          ) {
            return Promise.reject(error);
          }
          // if response fail with authentication new token will be requested
          // and retry request
          await this.options.getAuthToken().catch((e) => {
            throw new Error(`Authorization error: ${e}`);
          });
          const newRequest = {
            ...error.config,
            retry: true,
          };
          return this.client(newRequest);
        }
      );
    }
  }

  create(client, options) {
    return this.constructor(client, options);
  }

  /**
   * cancel given or all active requests
   * @method cancel
   * @param {string} request - name of the function that perform request
   */
  cancel(request) {
    const { sourcesCancel } = this;
    const { cancelMessage } = this.options;
    if (request && sourcesCancel[request]) {
      sourcesCancel[request].forEach(
        (cancel) => typeof cancel === 'function' && cancel(cancelMessage)
      );
      delete sourcesCancel[request];
    } else if (!request) {
      Object.keys(sourcesCancel).forEach((key) => {
        if (typeof sourcesCancel[key] === 'object') {
          sourcesCancel[key].forEach(
            (cancel) => typeof cancel === 'function' && cancel(cancelMessage)
          );
        }
      });
      this.sourcesCancel = {};
    }
  }

  /**
   * generates cancel token, store cancel handler for request
   * @method setCancelToken
   * @param {string} name - alias for request to store cancel handler
   * @return {string} - cancel token for request
   */
  setCancelToken(tokenId) {
    const source = this.CancelToken.source();
    if (typeof this.sourcesCancel[tokenId] === 'object') {
      this.sourcesCancel[tokenId].push(source.cancel);
    } else {
      this.sourcesCancel[tokenId] = [source.cancel];
    }
    return source.token;
  }

  /**
   * send POST request
   * @method post
   * @param {string} url - relative path to the api entry point
   * @param {object} data - post data
   * @param {object} [options] - options for request
   * @param {string|number} [options.cancelTokenId] - id that will be used to cancel this request
   * @return {Promise} - request promise
   */
  post(url, data, options = {}) {
    const { cancelTokenId, ...restOptions } = options;
    const opt = {
      cancelToken: this.setCancelToken(cancelTokenId || url),
      ...restOptions,
    };
    return this.client
      .post(url, data, opt)
      .then((res) => {
        delete this.sourcesCancel[cancelTokenId || url];
        return res;
      })
      .then((res) => res.data);
  }

  /**
   * send PUT request
   * @method put
   * @param {string} url - relative path to the api entry point
   * @param {object} data - post data
   * @param {object} [options] - options for request
   * @param {string|number} [options.cancelTokenId] - id that will be used to cancel this request
   * @return {Promise} - request promise
   */
  put(url, data, options = {}) {
    const { cancelTokenId, ...restOptions } = options;
    const opt = {
      cancelToken: this.setCancelToken(cancelTokenId || url),
      ...restOptions,
    };
    return this.client
      .put(url, data, opt)
      .then((res) => {
        delete this.sourcesCancel[cancelTokenId || url];
        return res;
      })
      .then((res) => res.data);
  }

  /**
   * send GET request
   * @method get
   * @param {string} url - relative path to the api entry point
   * @param {object} [options] - options for request
   * @param {string|number} [options.cancelTokenId] - id that will be used to cancel this request
   * @return {Promise} - request promise
   */
  get(url, data, options = {}) {
    const { cancelTokenId, ...restOptions } = options;
    const opt = {
      ...data,
      cancelToken: this.setCancelToken(cancelTokenId || url),
      ...restOptions,
    };
    return this.client
      .get(url, opt)
      .then((res) => {
        delete this.sourcesCancel[cancelTokenId || url];
        return res;
      })
      .then((res) => res.data);
  }

  /**
   * send DELETE request
   * @method delete
   * @param {string} url - relative path to the api entry point
   * @param {object} [options] - options for request
   * @param {string|number} [options.cancelTokenId] - id that will be used to cancel this request
   * @return {Promise} - request promise
   */
  delete(url, options) {
    const { cancelTokenId, ...restOptions } = options || {};
    const opt = {
      cancelToken: this.setCancelToken(cancelTokenId || url),
      ...restOptions,
    };
    return this.client
      .delete(url, opt)
      .then((res) => {
        delete this.sourcesCancel[cancelTokenId || url];
        return res;
      })
      .then((res) => res.data);
  }

  /**
   * send concurrent requests
   * @method all
   * @param {array} requests - array of requests
   * @return {Promise} - request promise
   */
  all(requests) {
    return this.Promise.all(requests);
  }
}

export default new ApiClient();
export { ApiClient };
