import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconLocation = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-location': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="22"
        viewBox="0 0 17 22"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M11.631 8.362c0 1.7-1.402 3.08-3.131 3.08-1.73 0-3.131-1.38-3.131-3.08 0-1.7 1.402-3.08 3.131-3.08 1.73 0 3.131 1.38 3.131 3.08zm-.276 2.8a3.924 3.924 0 0 0 1.18-2.8c0-2.187-1.804-3.96-4.03-3.96s-4.03 1.773-4.03 3.96c0 2.187 1.804 3.96 4.03 3.96a4.068 4.068 0 0 0 2.85-1.16zm2.59-8.204a7.133 7.133 0 0 1 2.17 5.325c0 5.209-6.205 11.35-7.628 12.687C7.055 19.721.896 14.063.896 8.283a7.133 7.133 0 0 1 2.17-5.325A7.393 7.393 0 0 1 8.504.883a7.393 7.393 0 0 1 5.44 2.075zm-5.15 18.928C9.135 21.586 17 14.53 17 8.283a8.07 8.07 0 0 0-2.45-5.926A8.35 8.35 0 0 0 8.5.003a8.35 8.35 0 0 0-6.048 2.354 8.07 8.07 0 0 0-2.45 5.926c0 6.933 7.88 13.347 8.212 13.62a.452.452 0 0 0 .581-.017z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconLocation.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
