import React from 'react';
import PropTypes from 'prop-types';
import { CircleButtonUI } from '../index';
import { ChevronLeftIcon as IconChevronLeft } from 'nexticons/outline';
import { getUIModifierKeys } from '../modifiersMapping';

export const BackButtonCircleUI = ({ onClick, buttonSize }) => {
  return (
    <CircleButtonUI
      onClick={onClick}
      buttonSize={buttonSize}
      icon={
        <IconChevronLeft
          className="bem-circle-btn__icon-back"
          width="50%"
          strokeWidth={3}
        />
      }
      title="Back"
    />
  );
};

BackButtonCircleUI.defaultProps = {
  onClick: () => {},
  buttonSize: 's',
};

BackButtonCircleUI.propTypes = {
  onClick: PropTypes.func,
  buttonSize: PropTypes.oneOf(getUIModifierKeys('buttonSize')),
};
