import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PosIconModernHouse } from '../../assets/icons/PosIconModernHouse';
import { PosIconDownChevron } from '../../assets/icons/PosIconDownChevron';
import { PosIconMoney } from '../../assets/icons/PosIconMoney';
import { NOTES_FOR_MANUALLY_ENTERED_ADDRESS } from '../../constants/text';
import GeneralModal from '../../modals/GeneralModal/index-new';
import orderEngine from '../../orderEngine';
import { updateCurrentOrderDeliveryLocation } from '../../pages/Dashboard/action';
import {
  suburbsSelector,
  useRadiusBasedDeliveryAreaCalulationSelector,
} from '../../pages/Dashboard/selectors';
import Button from '../Button';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

const EnterAddressManuallyModal = ({
  modalStatus,
  toggleModal = () => {},
  handleConfirmClick,
  currentOrder,
  storeConfig,
  specials,
  orderSetup,
  productSetup,
  publicHolidays,
  isEnvokedFromOrderBar = false,
}) => {
  const dispatch = useDispatch();
  const suburbs = useSelector(suburbsSelector);
  const useRadiusBasedDeliveryAreaCalulation = useSelector(
    useRadiusBasedDeliveryAreaCalulationSelector
  );

  const [address, setAddress] = React.useState(() => {
    if (isEnvokedFromOrderBar) {
      return currentOrder.address.replace(`, ${currentOrder.area}`, '');
    }
    return '';
  });
  const [selectedSuburb, setSelectedSuburb] = React.useState(() => {
    const currentSuburb = suburbs.find(
      (suburbForFilter) => suburbForFilter._id === currentOrder.suburbId
    );
    return currentSuburb || suburbs[0] || {};
  });
  const [deliveryCost, setDeliveryCost] = React.useState(() => {
    if (useRadiusBasedDeliveryAreaCalulation) return orderSetup.deliveryMinCost;
    return currentOrder.deliveryCost || '0';
  });

  const handleSuburbChange = (e) => {
    const matchingSuburb = suburbs.find(
      (suburbForFilter) => suburbForFilter._id === e.target.value
    );
    setSelectedSuburb(matchingSuburb);
  };

  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };

  // VIRTUAL_KEYBOARD

  const addressInputRef = React.useRef(null);
  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    inputNames,
    onInitInput,
  } = useVirtualKeyboardContext();
  const addressInputName = inputNames.manualDeliveryAddress;
  const deliveryCostInputName = inputNames.manualDeliveryCost;

  const handleAddressFocus = () => {
    setInputName(addressInputName);
    toggleKeyboard(true);
  };

  const handleDeliveryCostFocus = () => {
    setInputName(deliveryCostInputName);
    toggleKeyboard(true);
  };

  const addressInputValFromVirtualKeyboard = getInputValue(addressInputName);
  const deliveryCostInputValFromVirtualKeyboard = getInputValue(
    deliveryCostInputName
  );

  React.useEffect(() => {
    setAddress(addressInputValFromVirtualKeyboard);
  }, [addressInputValFromVirtualKeyboard]);

  React.useEffect(() => {
    setDeliveryCost(deliveryCostInputValFromVirtualKeyboard);
  }, [deliveryCostInputValFromVirtualKeyboard]);

  React.useEffect(() => {
    onChangeInput(addressInputName, address);
    onChangeInput(deliveryCostInputName, deliveryCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // VIRTUAL_KEYBOARD_ENDS

  const handleAddressChange = (e) => {
    onChangeInput(addressInputName, e.target.value);
  };

  const handleDeliveryCostChange = (e) => {
    if (e.target.value === '') onChangeInput(deliveryCostInputName, '');
    if (Number(e.target.value))
      onChangeInput(deliveryCostInputName, e.target.value);
  };

  const handleSubmitManualAddress = () => {
    if (!address) return;
    const newCurrentOrder = orderEngine(
      {
        ...currentOrder,
        unit: '',
        notes: NOTES_FOR_MANUALLY_ENTERED_ADDRESS,
        name: '',
        address: selectedSuburb.name
          ? `${address}, ${selectedSuburb.name}`
          : address,
        addressLocation: {
          lat: storeConfig.addressLocation?.lat,
          lng: storeConfig.addressLocation?.long,
        },
        suburbId: selectedSuburb._id || '',
        area: selectedSuburb.name ? selectedSuburb.name : '',
        areaCode: '',
        isManualAddress: true,
        ...(useRadiusBasedDeliveryAreaCalulation ? { deliveryCost } : null),
      },
      specials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );

    if (newCurrentOrder.addressObj) delete newCurrentOrder.addressObj;

    onInitInput(inputNames.orderUnit, '');
    onInitInput(inputNames.orderNotes, '');
    onInitInput(inputNames.orderGeosuggest, '');

    dispatch(updateCurrentOrderDeliveryLocation(newCurrentOrder));
    toggleKeyboard(false);

    handleConfirmClick();
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={() => {
        if (modalStatus) {
          toggleKeyboard(false);
        }
        toggleModal();
      }}
      position="center"
      effect="move"
      overlay=""
      boxWidth="514px"
      boxHeight="398px"
      className="denominations-short-modal small-radius"
      keyboardVisibility={keyboardVisibility}
      backBtn="outside"
      backBtnFnc={isEnvokedFromOrderBar ? toggleModal : handleGoBack}
    >
      <div className="denominations-short-modal-box">
        <h2 className="manual-address-modal-title">Manually Enter Address</h2>
        <div
          className="delivery-details-modal-box"
          style={{ padding: '10px 20px 20px 20px' }}
        >
          <div className="delivery-details-form">
            <div className="single-input-box">
              <label htmlFor="unit">Street Address</label>
              <div className="inner-box">
                <span className="icon-box">
                  <PosIconModernHouse mainColor="#494b4c" />{' '}
                </span>
                <input
                  ref={addressInputRef}
                  className="input-style white big"
                  placeholder="Enter Street Address"
                  value={address}
                  onFocus={handleAddressFocus}
                  onChange={handleAddressChange}
                  autoComplete="new-password"
                />
              </div>
            </div>
            {!useRadiusBasedDeliveryAreaCalulation && (
              <div className="single-input-box">
                <label htmlFor="unit">Suburb</label>
                <div className="inner-box">
                  <span className="icon-box">
                    <PosIconModernHouse mainColor="#494b4c" />{' '}
                  </span>
                  <span className="icon-box from-right">
                    <PosIconDownChevron mainColor="#494b4c" />{' '}
                  </span>
                  <select
                    className="input-style white big"
                    value={selectedSuburb._id || ''}
                    onChange={handleSuburbChange}
                  >
                    {suburbs.map((suburb) => (
                      <option key={suburb._id} value={suburb._id}>
                        {suburb.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {useRadiusBasedDeliveryAreaCalulation && (
              <div className="single-input-box">
                <label htmlFor="unit">Delivery Cost</label>
                <div className="inner-box">
                  <span className="icon-box">
                    <PosIconMoney mainColor="#494b4c" />{' '}
                  </span>
                  <input
                    ref={addressInputRef}
                    className="input-style white big"
                    placeholder="Enter Delivery Cost"
                    value={deliveryCost}
                    onFocus={handleDeliveryCostFocus}
                    onChange={handleDeliveryCostChange}
                    autoComplete="new-password"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`denominations-short-btn-box`}>
          <Button
            title="Next"
            className="btn"
            onClick={handleSubmitManualAddress}
            disabled={!address}
          >
            Next
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

export default EnterAddressManuallyModal;
