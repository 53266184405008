import useGetAmountWithCurrency from 'customHooks/useGetAmountWithCurrency';
import { PosIcon } from '../../assets/icons/PosIcon';
import scssVariables from '../../assets/scss/global/_variables.module.scss';

const PaidPaymentStatus = ({ payment }) => {
  const paymentAmountString = useGetAmountWithCurrency(payment.amount);
  const paidPaymentString = `${paymentAmountString} ${payment.paymentType} Received`;

  return (
    <div className="paid-payment-status__item">
      <div className="paid-payment-status__cell">
        <PosIcon
          name="check-circle-solid"
          size="custom"
          className="pos-icon--paid-payment-status"
          customWith={30}
          customHeight={30}
          mainColor={scssVariables.posGreen}
          darkModeColor={scssVariables.posGreen}
        />
      </div>
      <div className="paid-payment-status__cell">
        <span className="paid-payment-status__text">{paidPaymentString}</span>
      </div>
      {/* <div className="paid-payment-status__cell">
        {isStop && (
          <PosIcon
            name="check-minus-solid"
            size="custom"
            className="pos-icon--paid-payment-status"
            customWith={30}
            customHeight={30}
            mainColor={scssVariables.posRed}
            darkModeColor={scssVariables.posRed}
          />
        )}
      </div> */}
    </div>
  );
};

export default PaidPaymentStatus;
