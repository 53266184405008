import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconCall = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-call': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M16.074 11.25l-3.705-2.015a.831.831 0 0 0-.985.147l-1.81 1.843a.185.185 0 0 1-.237 0A17.378 17.378 0 0 1 7.11 9.452a18.982 18.982 0 0 1-1.849-2.304.186.186 0 0 1 0-.236L7.13 5.119a.832.832 0 0 0 .147-.985L5.255.428A.825.825 0 0 0 4.231.057L1.36 1.17a1.331 1.331 0 0 0-.851 1.39 15.645 15.645 0 0 0 4.011 8.864l.557.557A15.633 15.633 0 0 0 13.943 16h.154a1.33 1.33 0 0 0 1.235-.851l1.113-2.874a.832.832 0 0 0-.371-1.024z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconCall.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
