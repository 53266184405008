import React from 'react';
import { DriversList } from './content/DriversList';
import { ScrollableBoxUI } from '../../UI/components';

export const DriversSidebar = () => {
  return (
    <div className="driver-sidebar">
      <div className="nav-bar-header">
        <div className="top-box">
          {/* <ScheduledDropdown title="Status" /> */}
          <h1 className="nav-bar-title">Drivers</h1>
        </div>
      </div>

      <div className="nav-bar-details">
        <ScrollableBoxUI>
          <div className="details-tab-wrap unassigned">
            <DriversList />
          </div>
        </ScrollableBoxUI>
      </div>
    </div>
  );
};
