import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ScheduledDropdown } from '../../ScheduledDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { SearchBox } from './SearchBox';
import {
  setOrderList,
  setCopyOfOrderList,
} from '../../../pages/Orders/actions';
import SearchInput from '../../../pages/Orders/components/SearchInput/SearchInput';
import AllAndUpaidFilter from './AllAndUpaidFilter';

export const UpcomingSidebarHeader = () => {
  const dispatch = useDispatch();
  const dropDownArr = [
    { id: 1, text: 'Expected Time', key: 'deliveryDateTimestamp' },
    { id: 2, text: 'Placed Time', key: 'dateTimestamp' },
  ];
  const [showDropDown, toggleDropDown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(dropDownArr[0]);
  const { scrolledStatus, orderList } = useSelector(
    (state) => state.ordersReducer
  );

  const navCls = classNames({
    'nav-bar-header': true,
    shadow: scrolledStatus[16].position,
  });

  useEffect(() => {
    sortOrdersBasedOnTimeStamp(selectedFilter);
  }, [orderList]);

  const onItemClick = (selectedItem) => {
    setSelectedFilter(selectedItem);
    toggleDropDown(false);
    sortOrdersBasedOnTimeStamp(selectedItem);
  };

  const sortOrdersBasedOnTimeStamp = (selectedItem) => {
    orderList.sort((order1, order2) => {
      return order2[selectedItem.key] - order1[selectedItem.key];
    });
    dispatch(setOrderList(orderList));
    dispatch(setCopyOfOrderList(orderList));
  };

  return (
    <div className={navCls}>
      <div
        className="top-box"
        // style={{
        //   display: 'flex',
        //   alignItems: 'center',
        //   margin: '0 0 10px 0',
        //   justifyContent: 'space-between',
        // }}
      >
        <h1
          className="nav-bar-title"
          // style={{
          //   margin: 0,
          // }}
        >
          Future
        </h1>
        {/* <AllAndUnPaidFilter /> */}
        <AllAndUpaidFilter />
        {/* <ScheduledDropdown
          title={selectedFilter.text}
          showDropDown={showDropDown}
          clickHandler={() => toggleDropDown(!showDropDown)}
        >
          {dropDownArr.map((item) => {
            return (
              <div
                className={`item hover-highlight ${
                  item.id === selectedFilter.id ? ' selected' : ''
                } `}
                onClick={() => onItemClick(item)}
                key={item.id}
              >
                {item.text}
              </div>
            );
          })}
        </ScheduledDropdown> */}
      </div>

      {/* <SearchBox /> */}
      <SearchInput />
    </div>
  );
};
