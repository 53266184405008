import { Transition } from 'react-transition-group';
import './bem-courses-popup.scss';
import { transitionOverlayStyles, transitionStyles } from './contants';
import CoursesCard from './CoursesCard';

function CoursesPopup({
  handlePay,
  coursesPopupOpen,
  setCloseCoursesPopup,
  orderTouched,
  currentOrder,
  isAddingToTable,
  menuItemsWithCourseStatus,
}) {
  return (
    <Transition in={coursesPopupOpen} timeout={400}>
      {(state) => (
        <>
          <div
            className="CoursesPopup bem-courses-popup"
            style={{
              transition: 'all .15s cubic-bezier(0.85, 0, 0.15, 1)',
              ...transitionStyles[state],
            }}
          >
            {/(entering|entered|exiting)/.test(state) && (
              <CoursesCard
                handlePay={handlePay}
                setCloseCoursesPopup={setCloseCoursesPopup}
                orderTouched={orderTouched}
                currentOrder={currentOrder}
                isAddingToTable={isAddingToTable}
                menuItemsWithCourseStatus={menuItemsWithCourseStatus}
              />
            )}
          </div>
          <div
            style={{
              transition:
                'opacity 150ms cubic-bezier(0.85, 0, 0.15, 1), transform 0ms',
              ...transitionOverlayStyles[state],
            }}
            className="bem-courses-popup__overlay"
            onClick={setCloseCoursesPopup}
          />
        </>
      )}
    </Transition>
  );
}

export default CoursesPopup;
