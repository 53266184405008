import React, { useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuItem } from '../../pages/Dashboard/action';
import { updateModifier } from '../../itemProcessor';
import { SubModifier } from './SubModifier';
import { useHistory } from 'react-router';
import {
  getModifiersAfterSortByDisplay,
  useSortByDisplayOrder,
} from '../../customHooks/useSortByDisplayOrder';
import { currentHalfHalfDataSelector } from '../../pages/Dashboard/selectors';
import useIsKiosk from 'hooks/useIsKiosk';
import { showCustomization } from 'util/showCustomization';

export const SubModifiers = ({
  modifierId,
  itemBaseSubModifierId,
  currentItem,
  navigateTo,
  basePath,
  autoShowCustomizeOrderScreenForKiosk,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sortedModifiers = useSortByDisplayOrder(currentItem.selectedModifiers);
  const currentHalfHalfData = useSelector(currentHalfHalfDataSelector);

  const isKiosk = useIsKiosk();
  const variantSelected = currentHalfHalfData?.variantSelected;

  const subModifiers = useMemo(() => {
    const selectedModifier = sortedModifiers.find((f) => f._id === modifierId);

    if (selectedModifier && selectedModifier.subModifiers) {
      return getModifiersAfterSortByDisplay(selectedModifier.subModifiers);
    }
    return selectedModifier?.subModifiers || [];
  }, [sortedModifiers, modifierId]);

  const getNextModifier = useCallback(() => {
    let nextModifier = null;
    sortedModifiers.forEach((modifier, index) => {
      if (modifier._id === modifierId && index < sortedModifiers.length) {
        nextModifier = sortedModifiers[index + 1];
      }
    });
    return nextModifier;
  }, [sortedModifiers, modifierId]);

  const selectSingleSubModifierByDefault = useCallback(() => {
    let nextModifier = getNextModifier();
    const itemBaseSubModifierObject = sortedModifiers
      ?.find((modifier) => modifier._id === currentItem.baseModifierId)
      ?.subModifiers?.find((subModifier) => subModifier.quantity > 0);

    if (nextModifier) {
      if (subModifiers.length === 1 && !subModifiers[0].quantity) {
        let currentItemObj = updateModifier(
          currentItem,
          subModifiers[0]._id,
          subModifiers[0].modifierId,
          null,
          itemBaseSubModifierObject?._id
        );
        dispatch(updateMenuItem(currentItemObj));
        // move to next modifier after select
        history.push(`${navigateTo}/${nextModifier._id}`);
      }
    }
  }, [
    subModifiers,
    currentItem,
    dispatch,
    history,
    navigateTo,
    getNextModifier,
  ]);

  const modifier = sortedModifiers.find((f) => f._id === modifierId);

  useEffect(() => {
    selectSingleSubModifierByDefault();
  }, [selectSingleSubModifierByDefault]);

  const handleChange = (
    _event,
    subModifierId,
    modifierId,
    variantId,
    itemBaseSubModifierId
  ) => {
    let currentItemObj = updateModifier(
      currentItem,
      subModifierId,
      modifierId,
      variantId,
      itemBaseSubModifierId
    );
    dispatch(updateMenuItem(currentItemObj));
    let nextModifier = getNextModifier();
    if (nextModifier) {
      history.push(`${navigateTo}/${nextModifier._id}`);
    }
    //NOTE: Only for kiosk screens
    else if (
      autoShowCustomizeOrderScreenForKiosk &&
      isKiosk &&
      !nextModifier &&
      showCustomization(currentItemObj)
    ) {
      history.push(`${basePath}/menuitem/${currentItemObj._id}/customization`);
    }
  };

  return (
    <React.Fragment>
      <h2 className="main-title">
        {sortedModifiers
          .filter((f) => f._id === modifierId)
          .map((m) => m.name)
          .join(',')}
      </h2>
      <form
        action=""
        className="customization-form detail-form"
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="item-detail-cards">
          {currentItem?.baseModifierId === modifierId &&
            subModifiers?.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {!variantSelected && (
                    <SubModifier
                      key={item._id}
                      subModifierId={item._id}
                      modifierId={modifierId}
                      variantId="xxx"
                      name={item.name}
                      quantity={item.quantity || 0}
                      variantQuantity={
                        item.variants && item.variants.length > 0
                          ? item.variants.filter((f) => f.quantity > 0).length >
                            0
                            ? 1
                            : 0
                          : 0
                      }
                      price={parseFloat(item.price)}
                      prices={item.prices || []}
                      handleChange={handleChange}
                      itemBaseSubModifierId={itemBaseSubModifierId}
                      isBaseSection={true}
                      isSoldOut={!!item?.isSoldOut}
                    />
                  )}

                  {item?.variants
                    ?.filter((f) => f.isSelected)
                    .map((variant) => {
                      if (
                        !modifier?.name?.toLowerCase().includes('size') &&
                        !variantSelected
                      ) {
                        return null;
                      }

                      return (
                        <SubModifier
                          key={item._id + variant._id}
                          subModifierId={item._id}
                          modifierId={modifierId}
                          variantId={variant._id}
                          name={`${item.name} (${variant.name})`}
                          quantity={item.quantity || 0}
                          variantQuantity={variant.quantity || 0}
                          price={
                            parseFloat(item.price) + parseFloat(variant.price)
                          }
                          prices={item.prices ? item.prices : []}
                          handleChange={handleChange}
                          itemBaseSubModifierId={itemBaseSubModifierId}
                          isBaseSection={true}
                          isSoldOut={!!variant?.isSoldOut}
                        />
                      );
                    })}
                </React.Fragment>
              );
            })}
          {currentItem?.baseModifierId !== modifierId &&
            subModifiers?.map((item) => {
              return (
                <React.Fragment key={item._id}>
                  <SubModifier
                    key={`sub-modifier-${item._id}`}
                    subModifierId={item._id}
                    modifierId={modifierId}
                    variantId="xxx"
                    name={item.name}
                    quantity={
                      item.prices
                        ? item.prices.reduce((sum, p) => sum + p.quantity, 0)
                        : 0
                    }
                    variantQuantity={0}
                    price={parseFloat(item.price)}
                    prices={item.prices || []}
                    handleChange={handleChange}
                    itemBaseSubModifierId={itemBaseSubModifierId}
                    isBaseSection={false}
                    isSoldOut={!!item?.isSoldOut}
                  />
                </React.Fragment>
              );
            })}
        </div>
      </form>
    </React.Fragment>
  );
};
