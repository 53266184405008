import React from 'react';
import { useSelector } from 'react-redux';
import { getRoleNameFromId } from '../../../util/shifts-screen-utils';
import { activeStaffMemberSelector } from '../../Dashboard/selectors';
import { ShiftsActionsEnum } from './constants';
import { shiftsStateReducer } from './reducer';

const ShiftsStateContext = React.createContext();
const ShiftsStateDispatchContext = React.createContext();

export default function ShiftsStateProvider({ children }) {
  const activeStaffMember = useSelector(activeStaffMemberSelector);

  const [state, dispatch] = React.useReducer(
    shiftsStateReducer,
    activeStaffMember
  );

  return (
    <ShiftsStateContext.Provider value={state}>
      <ShiftsStateDispatchContext.Provider value={dispatch}>
        {children}
      </ShiftsStateDispatchContext.Provider>
    </ShiftsStateContext.Provider>
  );
}

export function useShiftsState() {
  const context = React.useContext(ShiftsStateContext);
  if (context === undefined) {
    throw new Error('useShiftsState must be used within a ShiftsStateProvider');
  }

  return context;
}

export function useShiftsStateDispatch() {
  const dispatch = React.useContext(ShiftsStateDispatchContext);
  if (dispatch === undefined) {
    throw new Error(
      'useShiftsStateDispatch must be used within a ShiftsStateProvider'
    );
  }

  const startShift = (newShift) =>
    dispatch({ type: ShiftsActionsEnum.UPDATE_SHIFT, payload: { newShift } });

  return { dispatch, startShift };
}

export function useStaffMemberName() {
  const staffMember = useShiftsState();
  const { firstName, lastName } = staffMember;
  const staffMemberName = `${firstName} ${lastName}`;

  // const [staffMemberName, setStaffMemberName] = React.useState('');

  // React.useEffect(() => {
  //   setStaffMemberName(`${firstName} ${lastName}`);
  // }, [firstName, lastName]);

  return staffMemberName;
}

export function useStaffMemberActiveShift() {
  const staffMember = useShiftsState();
  const activeShift = staffMember?.openShifts?.length
    ? staffMember.openShifts[0]
    : {};

  const { roleId: activeRoleId = '', breaks = [] } = activeShift;

  const activeRoleName = getRoleNameFromId(activeRoleId, staffMember);

  const activeBreak = breaks && breaks[0] ? breaks[0] : null;
  const activeBreakStartTime = activeBreak ? activeBreak.startTime : 0;

  return { activeShift, activeRoleName, activeBreakStartTime };
}
