import React from 'react';
import { useHistory } from 'react-router';
import GeneralModal from '../../../modals/GeneralModal/index-new';
import DeliveryImage from '../../../assets/img/home-new/delivery-icon_big.png';
import PickupImage from '../../../assets/img/home-new/Pickup_img_big.png';
import WalkInImage from '../../../assets/img/home-new/Walk_in_Person_big.png';
import { Link } from 'react-router-dom';
import './kiosk-splash.scss';
import { kiosk_order_route } from 'constants/routesConsts';

const KioskOrderTypeModal = ({ show, toggleModal, setKioskFlowType }) => {
  const history = useHistory();
  const handleClickWalkIn = () => {
    setKioskFlowType('kioskWalkIn');
  };

  const handleClickPickup = () => {
    setKioskFlowType('kioskPickup');
  };

  const handleClickDelivery = () => {
    setKioskFlowType('kioskDelivery');
  };

  const onModalClose = () => {
    toggleModal();
  };

  const homeMainMenu = [
    {
      id: 1,
      title: 'Walk In',
      link: '/kiosk/order',
      customClass: 'red',
      icon: WalkInImage,
      iconCls: 'walk-in',
      action: handleClickWalkIn,
    },
    {
      id: 2,
      title: 'Pick Up',
      link: '/kiosk/pickup/order',
      customClass: 'blue-light',
      icon: PickupImage,
      iconCls: 'pick',
      action: handleClickPickup,
    },
    {
      id: 3,
      title: 'Delivery',
      link: '/kiosk/delivery/order',
      icon: DeliveryImage,
      customClass: 'orange',
      action: handleClickDelivery,
      iconCls: 'delivery',
    },
  ];
  return (
    <GeneralModal
      className="bem-add-driver-modal"
      modalStatus={show}
      toggleModal={onModalClose}
      position={'center'}
      effect="fade"
      overlay={'default'}
      boxWidth="100%"
      boxHeight="200px"
      closeBtn="outside"
      paperContianerStyle={{ background: 'transparent', boxShadow: 'none' }}
      isResponsiveHeight
    >
      <div className="kiosk-home-screen-box">
        <div className="kiosk-home-screen-inner">
          <nav className="kiosk-main-nav-box">
            <div
              className="kiosk-main-inner-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {homeMainMenu.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={item.link}
                    className={'nav-btn large ' + item.customClass}
                    title={item.title}
                    onClick={(e) => {
                      item.action();
                    }}
                  >
                    <span className={'icon-box ' + item.iconCls}>
                      <img src={item.icon} alt={item.title} />
                    </span>
                    <span className="name" title={item.title}>
                      {item.title}
                    </span>
                  </Link>
                );
              })}
            </div>
          </nav>
        </div>
      </div>
    </GeneralModal>
  );
};

export default KioskOrderTypeModal;
