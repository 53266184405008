import React from 'react';
import { useSelector } from 'react-redux';
import IncomingCallNotification from './IncomingCallNotification';
import ModalPortal from 'components/ModalPortal';

const AllIncomingCallNotification = () => {
  const incomingCallsNotifications = useSelector(
    (state) => state.dashboardReducer.incomingCallsNotifications
  );

  const acceptedCall = useSelector(
    (state) => state.dashboardReducer.acceptedCall
  );

  return (
    <ModalPortal>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: 20,
          top: 20,
          zIndex: 9999999,
          // width: '100%',
        }}
      >
        {incomingCallsNotifications &&
          !acceptedCall?.id &&
          incomingCallsNotifications.length > 0 &&
          incomingCallsNotifications
            .filter((incomingCall) => incomingCall.status === 0)
            .map((call) => {
              const { id } = call;
              return <IncomingCallNotification key={id} call={call} />;
            })}
      </div>
    </ModalPortal>
  );
};

export default AllIncomingCallNotification;
