import { getQueryForOrderDateFilter } from 'pages/Orders/components/Providers/OrderList/utils';
import { setOrders, setOrdersLoading } from 'pages/Orders/newOrdersReducer';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cap from 'util/cap';
import getStoreConfig from 'util/getStoreConfig';
import normalize from 'util/normalize';

export default function useOrdersData(orderType) {
  const { orders, filters, loading } = useSelector(
    (state) => state.newOrdersReducer
  );
  const config = useMemo(() => getStoreConfig(), []);
  const { storeConfig } = useSelector((state) => state.dashboardReducer);

  const dispatch = useDispatch();

  const { date, type } = filters;

  let selectedType = orderType || type;

  const { allOrdersMap, allOrdersList } = orders;

  const storeId = config?.storeId ?? storeConfig?.storeId;

  useEffect(() => {
    if (!storeId || !date || !selectedType) return;

    const currentQuery = getQueryForOrderDateFilter(
      date,
      selectedType,
      storeId
    );

    const subscribe = currentQuery.onSnapshot(onListen);

    return () => {
      subscribe();
    };
    // eslint-disable-next-line
  }, [date, selectedType, storeId]);

  const onListen = (querySnapshot) => {
    if (loading) {
      dispatch(setOrdersLoading(true));
    }

    const data = [];
    querySnapshot.forEach((doc) => {
      const { orderStatus } = doc.data();
      if (orderStatus !== '-1' && orderStatus !== '11') {
        data.push({ _id: doc.id, ...doc.data() });
      }
    });

    const { incomingList, incomingMap } = normalize(data, 'incoming');

    dispatch(
      setOrders({
        allOrdersList: Array.from(new Set([...allOrdersList, ...incomingList])),
        allOrdersMap: {
          ...allOrdersMap,
          ...incomingMap,
        },
        [`${date}${cap(selectedType)}List`]: incomingList,
      })
    );
  };
}
