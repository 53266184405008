import React from 'react';

export const DropdownPrinter = ({ options, onChange, selected, style }) => {
  return (
    <select
      onChange={onChange}
      className="bem-print-order-modal__select"
      value={selected}
      style={{ ...style }}
    >
      {!!options &&
        options.map(({ nickName, _id }) => (
          <option key={`DropdownPrinter-${_id}`} value={_id}>
            {nickName}
          </option>
        ))}
    </select>
  );
};
