import React from 'react';
import './bem-counter.scss';
import {
  MinusCircleIcon as MinusOutline,
  PlusCircleIcon as PlusOutline,
} from 'nexticons/outline';
import {
  MinusCircleIcon as MinusSolid,
  PlusCircleIcon as PlusSolid,
} from 'nexticons/solid';
import PropTypes from 'prop-types';

const iconOptions = {
  width: 34,
  strokeWidth: 1,
  className: 'bem-counter__icon',
};

export const CounterUI = ({
  quantity,
  title,
  onDecrement,
  onIncrement,
  iconType,
  style,
  maxLimit,
}) => {
  return (
    <div className="bem-counter" style={style}>
      <button
        type="button"
        className="bem-counter__button"
        onClick={onDecrement}
        disabled={quantity <= 1}
      >
        {iconType === 'solid' ? (
          <MinusSolid {...iconOptions} />
        ) : (
          <MinusOutline {...iconOptions} />
        )}
      </button>

      <span className="bem-counter__quantity">
        {quantity ? quantity + ' ' + title : ''}
      </span>
      <button
        type="button"
        className="bem-counter__button"
        onClick={onIncrement}
        disabled={maxLimit && quantity === maxLimit}
      >
        {iconType === 'solid' ? (
          <PlusSolid {...iconOptions} />
        ) : (
          <PlusOutline {...iconOptions} />
        )}
      </button>
    </div>
  );
};

CounterUI.defaultProps = {
  quantity: 0,
  title: '',
  onDecrement: () => {},
  onIncrement: () => {},
  iconType: 'outline',
  style: {},
};
CounterUI.propTypes = {
  quantity: PropTypes.number,
  title: PropTypes.string,
  onDecrement: PropTypes.func,
  onIncrement: PropTypes.func,
  iconType: PropTypes.oneOf(['outline', 'solid']),
  style: PropTypes.object,
};
