import React, { createContext, useState } from 'react';

export const DriverBatchesContext = createContext({
  showAssignDriverModal: false,
  setShowAssignDriverModal: () => {},
});

export default function DriverBatchesProvider({ children }) {
  const [showAssignDriverModal, setShowAssignDriverModal] = useState(false);
  return (
    <DriverBatchesContext.Provider
      value={{
        showAssignDriverModal,
        setShowAssignDriverModal,
      }}
    >
      {children}
    </DriverBatchesContext.Provider>
  );
}
