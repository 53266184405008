import React from 'react';
import { SingleOrderItem } from './SingleOrderItem';
import OtherOrderItem from './OtherOrderItem';
import { useSelector } from 'react-redux';
import HalfAndHalfItem from './HalfAndHalfItem';
import { getSegregatedMenuItemsByType } from '../../util/half-and-half-utils';
import { ScrollableBoxUI } from '../../UI/components';

export const SingleOrderList = ({
  showPrice,
  lightMode,
  fullScreen,
  currentOrder,
  basePath,
}) => {
  // let refScrollBar = useRef();

  lightMode = lightMode !== undefined && lightMode ? true : false;
  fullScreen = fullScreen !== undefined && fullScreen ? true : false;
  // useEffect(() => {
  //   let prefectScrollHeight = refScrollBar.current.getScrollHeight();
  //   refScrollBar.current.view.scrollTo({
  //     top: prefectScrollHeight,
  //     behavior: 'smooth',
  //   });
  // }, [currentOrder]);
  const productSetup =
    useSelector((state) => state.dashboardReducer.productSetup) || {};

  const segregatedMenuItemsByType = getSegregatedMenuItemsByType(
    currentOrder?.menuItems
  );

  return (
    <div
      className="seats-list-wrap single"
      style={{ height: 'auto', flexGrow: 1 }}
    >
      <ScrollableBoxUI
      // actionType="scrollSeatList"
      // ref={refScrollBar}
      >
        <div className="seat-items-wrap">
          {currentOrder?.otherItems?.length > 0 &&
            currentOrder?.otherItems?.map((otherItem) => (
              <OtherOrderItem otherItem={otherItem} showPrice={showPrice} />
            ))}
          {(segregatedMenuItemsByType.full || []).map((menuItem, index) => (
            <SingleOrderItem
              key={`cartItem-${menuItem?.orderIndex || index}`}
              lightMode={lightMode}
              fullScreen={fullScreen}
              showPrice={showPrice}
              menuItem={menuItem}
              basePath={basePath}
              firedCourse={currentOrder.calledCourses?.includes(
                menuItem.courseId
              )}
            />
          ))}

          {Object.keys(segregatedMenuItemsByType.halfAndHalf).map(
            (halfAndHalfOrderIndex, index) => {
              const halfAndHalfMenuItems =
                segregatedMenuItemsByType.halfAndHalf[halfAndHalfOrderIndex];

              return (
                halfAndHalfOrderIndex !== 'undefined' && (
                  <HalfAndHalfItem
                    key={`halfhalf-${index}`}
                    // lightMode={lightMode}
                    // fullScreen={fullScreen}
                    // showPrice={showPrice}
                    halfAndHalfMenuItems={halfAndHalfMenuItems}
                    productSetup={productSetup}
                  />
                )
              );
            }
          )}
          {Object.keys(segregatedMenuItemsByType.fourQuarters).map(
            (fourQuartersOrderIndex, index) => {
              const fourQuartersMenuItems =
                segregatedMenuItemsByType.fourQuarters[fourQuartersOrderIndex];

              return (
                fourQuartersOrderIndex !== 'undefined' && (
                  <HalfAndHalfItem
                    key={`fourQuarters-${index}`}
                    // lightMode={lightMode}
                    // fullScreen={fullScreen}
                    // showPrice={showPrice}
                    halfAndHalfMenuItems={fourQuartersMenuItems}
                    productSetup={productSetup}
                  />
                )
              );
            }
          )}
        </div>
      </ScrollableBoxUI>
    </div>
  );
};
