import CashAmountSelectedModal from 'components/CashAmountSelectedModal';
import CustomerNameModal from 'components/CustomerName';
import DisableSpecialDiscountModal from 'components/DisableSpecialDiscountModal';
import FourQuarterMenuItemsWrapper from 'components/FourQuarterMenuItems/FourQuarterMenuItemsWrapper';
import FourQuarterViewWrapper from 'components/FourQuarterView';
import HalfHalfMenuItemsWrapper from 'components/HalfHalfMenuItems/HalfHalfMenuItemsWrapper';
import HalfHalfViewWrapper from 'components/HalfHalfView';
import { ItemView } from 'components/ItemView';
import { ItemViewCustomise } from 'components/ItemViewCustomise';
import { MenuItems } from 'components/MenuItems';
import { NavigationBar } from 'components/NavigationBar';
import OrderBackModal from 'components/OrderBackModal';
import { OrderBar } from 'components/OrderBar';
import CartItemActionsModal from 'components/OrderBar/ItemViewModal/CartItemActionsModal';
import PaymentModal from 'components/PaymentModal';
import PaymentViewModal from 'components/PaymentViewModal';
import SpecialsView from 'components/SpecialsView';
import SpecialsFourQuarterMenuItems from 'components/SpecialsView/SpecialsFourQuarterMenuItems';
import SpecialsFourQuarterView from 'components/SpecialsView/SpecialsFourQuarterView';
import SpecialsHalfHalfMenuItems from 'components/SpecialsView/SpecialsHalfHalfMenuItems';
import SpecialsHalfHalfView from 'components/SpecialsView/SpecialsHalfHalfView';
import SpecialsMenuItems from 'components/SpecialsView/SpecialsMenuItems';
import {
  MENU_ORDER_FROM,
  OrderTypeBasepathEnum,
  OrderTypeIdEnum,
  OrderTypeTitleEnum,
} from 'configuration/enums';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import SplitPaymentsRoot from '../../components/SplitPayments';
import { ItemDetailsModal } from '../../modals/ItemDetailsModal';
import {
  authenticateStaff,
  resetCurrentFourQuarterData,
  resetCurrentHalfHalfData,
  resetMenuItem,
  setCashAmountChange,
  setCashPaid,
  setDisableSpecialDiscountModal,
  setOrderBackModal,
  setOrderTypeConfig,
  updateCurrentOrder,
} from '../../pages/Dashboard/action';
import { getOrderTimeArray } from '../../util/getTimeSlots';
import CustomerNameEffect from '../WalkIn/CustomerNameEffect';

class Kiosk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basePath: '/kiosk',
      isShowPaymentModal: false,
      isShowSplitPaymentModal: false,
      paymentModalType: 'paymentModal',
    };
  }

  componentDidMount() {
    const storeConfig = this.props.storeConfig;
    this.props.setOrderTypeConfig({
      basePath: OrderTypeBasepathEnum.KIOSK,
      title: OrderTypeTitleEnum.KIOSK,
      orderTypeId: OrderTypeIdEnum.WALK_IN,
    });

    this.props.updateCurrentOrder({
      orderType: OrderTypeIdEnum.WALK_IN,

      storeId: storeConfig?.storeId,
      orderFrom: MENU_ORDER_FROM.kiosk,

      restaurantId: storeConfig.restaurantId,
      addressLocation: storeConfig.addressLocation,
      name: storeConfig.name,
      address: storeConfig.address,
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.state.pickUpTimes && this.props.shifts && this.props.orderSetup) {
      let pickUpTimes = getOrderTimeArray(
        this.props.orderSetup ? this.props.orderSetup : {},
        this.props.storeConfig,
        OrderTypeIdEnum.WALK_IN
      );
      this.setState({ pickUpTimes });
    }
  }

  handleOpenPaymentModal = () => {
    this.setState({
      isPaymentLoading: true,
    });
    if (this.props.currentOrder.isSplit) {
      this.handleSplitPaymentClick();
    } else if (
      this.props.currentOrder.menuItems?.length &&
      this.props.currentOrder.payableAmount
    ) {
      this.setState({
        isShowPaymentModal: true,
        paymentModalType: 'paymentViewModal',
      });
    }
  };

  handlePaymentModalClose = () => {
    if (this.state.paymentModalType !== 'cashAmountSelectedModal') {
      this.setState({
        isShowPaymentModal: false,
        paymentModalType: '',
        isPaymentLoading: false,
      });
    }
  };

  handleCashAmountSelectedModal = (orderId = '') => {
    this.setState({
      paymentModalType: 'cashAmountSelectedModal',
      selectedOrderId: orderId,
    });
  };

  handleSplitPaymentClick = () => {
    this.setState({
      isShowSplitPaymentModal: true,
      isShowPaymentModal: false,
      paymentModalType: '',
    });
  };

  handleSplitPaymentModalClose = () => {
    this.setState({
      isShowSplitPaymentModal: false,
      isShowPaymentModal: false,
      paymentModalType: '',
      isPaymentLoading: false,
    });
  };

  onBackClick = () => {};

  render() {
    return (
      <>
        <NavigationBar basePath={this.state.basePath} />
        <Switch>
          <Route exact path="/kiosk/menuitem/:itemId/:itemType/:modifierId">
            {/* View item image, name and ingredient in large view */}
            <ItemDetailsModal />
            {/* Item details view opened based on item selected in right side of view */}
            <ItemView parentPage={this.state.basePath} />
          </Route>
          <Route exact path="/kiosk/specials/:specialsId">
            {/* Item details view opened based on item selected in right side of view */}
            <SpecialsView parentPage={this.state.basePath} />
          </Route>
          <Route exact path="/kiosk/specials/:specialsId/:categoryIndex">
            {/* Item details view opened based on item selected in right side of view */}
            <SpecialsMenuItems parentPage={this.state.basePath} />
          </Route>
          <Route
            exact
            path="/kiosk/specials/:specialsId/:categoryIndex/half-half"
          >
            {/* Item details view opened based on item selected in right side of view */}
            <SpecialsHalfHalfView parentPage={this.state.basePath} />
          </Route>
          <Route
            exact
            path="/kiosk/specials/:specialsId/:categoryIndex/half-half/:halfIndex"
          >
            {/* Item details view opened based on item selected in right side of view */}
            <SpecialsHalfHalfMenuItems parentPage={this.state.basePath} />
          </Route>
          <Route
            exact
            path="/kiosk/specials/:specialsId/:categoryIndex/four-quarter"
          >
            {/* Item details view opened based on item selected in right side of view */}
            <SpecialsFourQuarterView parentPage={this.state.basePath} />
          </Route>
          <Route
            exact
            path="/kiosk/specials/:specialsId/:categoryIndex/four-quarter/:fourQuarterIndex"
          >
            {/* Item details view opened based on item selected in right side of view */}
            <SpecialsFourQuarterMenuItems parentPage={this.state.basePath} />
          </Route>
          <Route exact path="/kiosk/category/:categoryId/half-half">
            {/* Item details view opened based on item selected in right side of view */}
            <HalfHalfViewWrapper parentPage={this.state.basePath} />
          </Route>
          <Route exact path="/kiosk/category/:categoryId/half-half/:halfIndex">
            {/* Item details view opened based on item selected in right side of view */}
            <HalfHalfMenuItemsWrapper parentPage={this.state.basePath} />
          </Route>
          <Route exact path="/kiosk/category/:categoryId/four-quarter">
            {/* Item details view opened based on item selected in right side of view */}
            <FourQuarterViewWrapper parentPage={this.state.basePath} />
          </Route>
          <Route
            exact
            path="/kiosk/category/:categoryId/four-quarter/:fourQuarterIndex"
          >
            {/* Item details view opened based on item selected in right side of view */}
            <FourQuarterMenuItemsWrapper parentPage={this.state.basePath} />
          </Route>
          <Route
            exact
            path="/kiosk/menuitem/:itemId/customization/ingredient/:customizeSlug"
          >
            <ItemViewCustomise parentPage={this.state.basePath} />
          </Route>
          <Route exact path="/kiosk/order">
            <MenuItems parentPage={this.state.basePath} />
          </Route>
        </Switch>
        <OrderBar
          extendedClassName="kiosk-screen__order-bar"
          orderPayments={this.props.orderPayments}
          orderTimes={this.state.pickUpTimes}
          parentPage={this.state.basePath}
          onPayClick={this.handleOpenPaymentModal}
          orderSetup={this.props.orderSetup}
          storeConfig={this.props.storeConfig}
          title="Kiosk"
          isOrderTypeWalkin
          isPaymentLoading={this.state.isPaymentLoading}
        />

        <CartItemActionsModal basePath={this.state.basePath} />
        {this.state.isShowPaymentModal && (
          <PaymentModal
            modalStatus={this.state.isShowPaymentModal}
            toggleModal={() => {
              this.setState({
                isShowPaymentModal: false,
                isPaymentLoading: false,
              });
            }}
            openPaymentViewModal={this.handleOpenPaymentModal}
            currentOrder={this.props.currentOrder}
            orderPayments={this.props.orderPayments}
            storeConfig={this.props.storeConfig}
            type="kiosk"
          />
        )}
        {this.state.isShowPaymentModal &&
          this.state.paymentModalType === 'paymentViewModal' && (
            <PaymentViewModal
              toggleModal={this.handlePaymentModalClose}
              currentOrder={this.props.currentOrder}
              orderTimes={this.state.pickUpTimes}
              openCashAmountPaidModal={this.handleCashAmountSelectedModal}
              handleCashAmountChange={this.props.handleCashAmountChange}
              handleCashPaid={this.props.handleCashPaid}
              handleSplitPaymentClick={this.handleSplitPaymentClick}
            />
          )}
        {this.state.isShowPaymentModal &&
          this.state.paymentModalType === 'cashAmountSelectedModal' && (
            <CashAmountSelectedModal
              orderId={this.state.selectedOrderId}
              toggleModal={this.handlePaymentModalClose}
              modalStatus={
                this.state.isShowPaymentModal &&
                this.state.paymentModalType === 'cashAmountSelectedModal'
              }
              cashAmountChange={this.props.cashAmountChange}
              cashPaid={this.props.cashPaid}
              title={this.props.title}
            />
          )}
        {this.props.orderBackModal ? (
          <OrderBackModal
            modalStatus={this.props.orderBackModal}
            toggleModal={this.props.handleOrderExitModalClose}
            redirectUrl={this.props.currentOrder.iteration ? '/orders' : ''}
          />
        ) : null}
        {this.state.isShowSplitPaymentModal ? (
          <SplitPaymentsRoot
            currentOrder={this.props.currentOrder}
            closeFlowStatus={this.handleSplitPaymentModalClose}
          />
        ) : null}
        <CustomerNameModal
          parentPage={this.state.basePath}
          isUserRegistrationRequired={false}
          orderType={OrderTypeIdEnum.WALK_IN}
        />
        <CustomerNameEffect />
        {this.props.disableSpecialDicountModal ? (
          <DisableSpecialDiscountModal
            modalStatus={this.props.disableSpecialDicountModal}
            toggleModal={this.props.handleDisableSpecialDiscountExitModalClose}
          />
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    storeConfig: state.dashboardReducer.storeConfig,
    currentOrder: state.dashboardReducer.currentOrder,
    shifts: state.dashboardReducer.shifts,
    orderSetup: state.dashboardReducer.orderSetup,
    menuItems: state.dashboardReducer.menuItems,
    orderPayments: state.dashboardReducer.orderPayments,
    cashAmountChange: state.dashboardReducer.cashAmountChange,
    title: state.dashboardReducer.title,
    cashPaid: state.dashboardReducer.cashPaid,
    orderBackModal: state.dashboardReducer.orderBackModal,
    disableSpecialDicountModal:
      state.dashboardReducer.disableSpecialDicountModal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOrderTypeConfig: (orderTypeConfig) =>
      dispatch(setOrderTypeConfig(orderTypeConfig)),
    updateCurrentOrder: (currentOrder) =>
      dispatch(updateCurrentOrder(currentOrder)),
    authenticateStaff: (flag) => dispatch(authenticateStaff(flag)),
    handleCashAmountChange: (cashAmountChange) => {
      dispatch(setCashAmountChange(cashAmountChange));
    },
    handleCashPaid: (cashPaid) => {
      dispatch(setCashPaid(cashPaid));
    },
    handleCategoryChange: () => {
      dispatch(resetCurrentHalfHalfData());
      dispatch(resetCurrentFourQuarterData());
      dispatch(resetMenuItem());
    },
    handleOrderExitModalClose: () => {
      dispatch(setOrderBackModal(false));
    },
    handleDisableSpecialDiscountExitModalClose: () => {
      dispatch(setDisableSpecialDiscountModal(false));
    },
  };
}

const KioskRoutes = connect(mapStateToProps, mapDispatchToProps)(Kiosk);

function MemoizedKioskRoutes() {
  return <KioskRoutes />;
}

export default memo(MemoizedKioskRoutes, () => true);
