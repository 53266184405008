import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PosIcon } from './PosIcon';

export const PosIconPrint = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-print': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <title>print</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M24 14.154h3.692v1.231h-3.692zM30.154 10.708c0-0.123-0.123-0.246-0.369-0.246h-27.692c-0.123 0-0.246 0.123-0.246 0.246v15.508c0 0.123 0.123 0.246 0.246 0.246h4.677v-6.154h-2.462v-1.231h23.385v1.231h-2.462v6.154h4.677c0.123 0 0.246-0.123 0.246-0.246zM24 20.308h-16v9.846h16zM8 9.231h16v-7.385h-16zM31.385 10.708v15.508c0 0.738-0.615 1.477-1.477 1.477h-4.677v3.077c0 0.369-0.246 0.615-0.615 0.615h-17.231c-0.369 0-0.615-0.246-0.615-0.615v-3.077h-4.677c-0.738 0-1.477-0.738-1.477-1.6v-15.385c0-0.738 0.738-1.477 1.477-1.477h4.677v-8c0-0.369 0.246-0.615 0.615-0.615h17.231c0.369 0 0.615 0.246 0.615 0.615v8h4.677c0.738 0 1.477 0.738 1.477 1.477zM10.462 26.462h11.077v1.231h-11.077zM10.462 22.769h11.077v1.231h-11.077z"
        />
      </svg>
    </span>
  );
};

PosIconPrint.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
