import './bem-base-part.scss';
import { ScrollableBoxUI } from '../ScrollableBoxUI';
import PropTypes from 'prop-types';
import { useRef } from 'react';

export const BasePartUI = ({ children, style, additionalClass = '' }) => {
  return (
    <div className={`bem-base-part ${additionalClass}`} style={style}>
      <div className="bem-base-part__inner">{children}</div>
    </div>
  );
};

BasePartUI.defaultProps = {
  children: null,
  style: {},
  additionalClass: '',
};

BasePartUI.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  additionalClass: PropTypes.string,
};

function Header({ children }) {
  return <div className="bem-base-part__header">{children}</div>;
}

BasePartUI.Header = Header;

BasePartUI.Header.defaultProps = {
  children: null,
};

BasePartUI.Header.propTypes = {
  children: PropTypes.node,
};

function Body({ children, scrollShadow }) {
  const ref = useRef();

  return (
    <div className="bem-base-part__body">
      <ScrollableBoxUI ref={ref} scrollShadow={scrollShadow}>
        <div className="bem-base-part__body-inner">{children}</div>
      </ScrollableBoxUI>
    </div>
  );
}

BasePartUI.Body = Body;

BasePartUI.Body.defaultProps = {
  children: null,
  scrollShadow: false,
};

BasePartUI.Body.propTypes = {
  children: PropTypes.node,
  scrollShadow: PropTypes.bool,
};

function Footer({ children }) {
  return <div className="bem-base-part__footer">{children}</div>;
}

BasePartUI.Footer = Footer;

BasePartUI.Footer.defaultProps = {
  children: null,
};

BasePartUI.Footer.propTypes = {
  children: PropTypes.node,
};
