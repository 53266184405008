import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconNewPlus = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos-icon pos-icon__plus': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
      >
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M939.2,439.2H560.8V60.8C560.8,27.2,533.6,0,500,0h0c-33.6,0-60.8,27.2-60.8,60.8v378.5H60.8C27.2,439.2,0,466.4,0,500v0  c0,33.6,27.2,60.8,60.8,60.8h378.5v378.5c0,33.6,27.2,60.8,60.8,60.8h0c33.6,0,60.8-27.2,60.8-60.8V560.8h378.5  c33.6,0,60.8-27.2,60.8-60.8v0C1000,466.4,972.8,439.2,939.2,439.2z"
        />
      </svg>
    </span>
  );
};

PosIconNewPlus.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
