import React from 'react';

import { TableConfig } from 'components/TableConfig';
import { InsertShapes } from 'components/InsertShapes';
import { DineInOptions } from 'components/DineInOptions';

import IconEmptyTable from 'assets/icons/IconEmptyTable';

export const SidePanel = ({
  editMode,
  selectedShape,
  objectsToRender,
  currentLayout,
  setSelectedFloorId,
  handleShapeInsert,
  handleTableUpdate,
  handleShapeSelect,
  handleModeToggle,
  handleShapeRemove,
  handleClearSelectedShape,
  handleStartOccupancy,
  handleMove,
  endOccupancy,
  availableTableNums,
  handleUpdateAvailableTableNums,
}) => {
  return (
    <>
      {!editMode && !selectedShape && (
        <div className="side-panel__wrapper">
          <div className="side-panel__empty">
            <IconEmptyTable />
            <p className="side-panel__empty-text">Select a Table</p>
          </div>
        </div>
      )}

      {editMode && selectedShape && (
        <TableConfig
          selectedShape={selectedShape}
          objectsToRender={objectsToRender}
          handleTableUpdate={handleTableUpdate}
          handleShapeRemove={handleShapeRemove}
          handleClearSelectedShape={handleClearSelectedShape}
          availableTableNums={availableTableNums}
          handleUpdateAvailableTableNums={handleUpdateAvailableTableNums}
        />
      )}

      {!editMode && selectedShape && (
        <DineInOptions
          handleMove={handleMove}
          availableTableNums={availableTableNums}
          currentLayout={currentLayout}
          objectsToRender={objectsToRender}
          selectedShape={selectedShape}
          setSelectedFloorId={setSelectedFloorId}
          handleClearSelectedShape={handleClearSelectedShape}
          handleModeToggle={handleModeToggle}
          handleStartOccupancy={handleStartOccupancy}
          handleShapeSelect={handleShapeSelect}
          handleTableUpdate={handleTableUpdate}
          endOccupancy={endOccupancy}
        />
      )}

      {editMode && (
        <InsertShapes
          handleShapeInsert={handleShapeInsert}
          handleModeToggle={handleModeToggle}
        />
      )}
    </>
  );
};
