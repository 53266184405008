import EmptyScreenBackgroundFill from '../Temporary/EmptyScreenBackgroundFill';
import { LogoButton } from '../../components/LogoButton';

const Music = () => {
  return (
    <EmptyScreenBackgroundFill>
      <LogoButton />
    </EmptyScreenBackgroundFill>
  );
};

export default Music;
