import React from 'react';
import clsx from 'clsx';
import './bem-input.scss';
import PropTypes from 'prop-types';

export const InputUI = ({
  label,
  labelRequired,
  disabled,
  name,
  placeholder,
  value,
  onChange,
  type,
  error,
  containerStyle,
  ...rest
}) => {
  return (
    <div className="bem-input-box" style={containerStyle}>
      {labelRequired && label && (
        <label htmlFor={name} className="bem-input-box__label">
          {label}
        </label>
      )}
      <input
        id={name}
        name={name}
        placeholder={placeholder ? placeholder : ''}
        type={type}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={clsx('bem-input-box__input', {
          'bem-input-box__input--error': error,
        })}
        {...rest}
      />
      {error && <span className="bem-input-box__error-text">{error}</span>}
    </div>
  );
};

InputUI.defaultProps = {
  label: '',
  labelRequired: false,
  disabled: false,
  name: '',
  placeholder: '',
  value: '',
  onChange: () => {},
  type: 'text',
  error: '',
};

InputUI.propTypes = {
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['text', 'tel', 'email']),
  error: PropTypes.string,
};
