import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  getHalfAndHalfTitle,
  getUniqueExtraIngredientsForHalfHalfItems,
  getUniqueRemovedExtraIngredientsForHalfHalfItems,
} from 'util/half-and-half-utils';

import { getInstructionString } from '../../util/getInstructionString';

import { PosIconNewPlus } from 'assets/icons/PosIconNewPlus';
import { PosIconWarningSolid } from 'assets/icons/PosIconWarningSolid';

import './_order-menu-item.scss';

// TODO: split that into normal and half
export const OrderMenuItem = ({
  menuItem,
  onItemClick,
  isImage = false,
  mode = 'list', // or cart
}) => {
  const [isHalfOrFourQuarters] = useState(Array.isArray(menuItem));
  const inactive = isHalfOrFourQuarters
    ? menuItem.some((item) => !item.isPublished)
    : !menuItem.isPublished;

  const title = isHalfOrFourQuarters
    ? getHalfAndHalfTitle(menuItem)
    : menuItem.name;

  let uniqueExtraIngredients = '';
  let uniqueRemoveExtraIngredients = '';
  let extraIngredientsString = '';
  let extraRemoveIngredientsString = '';

  if (isHalfOrFourQuarters) {
    uniqueExtraIngredients =
      getUniqueExtraIngredientsForHalfHalfItems(menuItem);
    uniqueRemoveExtraIngredients =
      getUniqueRemovedExtraIngredientsForHalfHalfItems(menuItem);
    extraIngredientsString = uniqueExtraIngredients?.join(', ');
    extraRemoveIngredientsString = uniqueRemoveExtraIngredients?.join(', ');
  }
  const instructions = getInstructionString(menuItem);

  const handleClick = (menuItem) => {
    if (!inactive) {
      onItemClick(menuItem);
    }
  };

  const itemCssCls = clsx('order-menu-item', {
    'order-menu-item--inactive': inactive,
  });

  return (
    <div className={itemCssCls}>
      <div className="order-menu-item__info-box">
        {isImage && <img src="" alt="" />}
        <h4 className="order-menu-item__title order-menu-item__title--size-big">
          {title}
        </h4>
        {isHalfOrFourQuarters && (
          <>
            {!!uniqueExtraIngredients.length && (
              <span
                className="order-menu-item__ingredients order-menu-item__ingredients--size-big"
                title={extraIngredientsString}
              >
                {extraIngredientsString &&
                  extraIngredientsString !== '' &&
                  `Add ${extraIngredientsString}`}
              </span>
            )}

            {!!uniqueRemoveExtraIngredients.length && (
              <span
                className="order-menu-item__ingredients order-menu-item__ingredients--size-big"
                title={extraRemoveIngredientsString}
              >
                {extraRemoveIngredientsString &&
                  extraRemoveIngredientsString !== '' &&
                  `Remove ${extraRemoveIngredientsString}`}
              </span>
            )}
          </>
        )}

        {(instructions || menuItem?.itemNotes) && (
          <span
            className="order-menu-item__ingredients order-menu-item__ingredients--size-big"
            title={instructions}
          >
            {`${
              menuItem?.itemNotes ? `Notes: ${menuItem.itemNotes}. ` : ''
            }${instructions}`}
          </span>
        )}
      </div>

      <div className="order-menu-item__controls">
        {!inactive ? (
          <>
            {mode === 'list' && (
              <button
                type="button"
                className="order-menu-item__cta"
                onClick={() => handleClick(menuItem)}
              >
                <PosIconNewPlus
                  className="order-menu-item__icon"
                  mainColor="rgba(131,131, 131, 0.7)"
                  darkModeColor="#ffffff"
                />
              </button>
            )}
          </>
        ) : (
          <PosIconWarningSolid
            className="order-menu-item__icon"
            mainColor="rgba(131,131, 131, 0.7)"
            darkModeColor="#ffffff"
          />
        )}
      </div>
    </div>
  );
};

OrderMenuItem.propTypes = {
  menuItem: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  hideControls: PropTypes.bool,
  isBigText: PropTypes.bool,
  isImage: PropTypes.bool,
};
OrderMenuItem.defaultProps = {
  hideControls: false,
  isBigText: false,
  isImage: false,
};
