export const mapIds = (array, key = '_id') => array.map((i) => i[key]);
export const buildMap = (array, key = '_id') =>
  array.reduce((acc, i) => ({ ...acc, [i[key]]: i }), {});

export default function normalize(array, name, key = '_id') {
  const ids = mapIds(array, key);
  const map = buildMap(array, key);
  return {
    [`${name}List`]: ids,
    [`${name}Map`]: map,
  };
}
