import { forwardRef, useState } from 'react';

import { getPercentAmount } from '../../util/utils';

import {
  BadgeUI,
  ManualAmountUI,
  SwitchButtonsGroupUI,
} from '../../UI/components';

import './bem-item-manual-amount.scss';

const ItemManualDiscountAmount = forwardRef(
  (
    { originalPrice, onBack, onApply, currencySymbol, id, sendDetails },
    ref
  ) => {
    const [typeId, setTypeId] = useState(0);
    const [moneyAmount, setMoneyAmount] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [manualValue, setManualValue] = useState('');
    const [discountError, setDiscountError] = useState('');

    const discountTypes = [
      {
        title: 'Percent %',
        displayOrder: 0,
        type: 'percentage',
        id: 0,
      },
      {
        title: `Amount ${currencySymbol}`,
        displayOrder: 1,
        type: 'money',
        id: 1,
      },
    ];

    const handleValueChange = (value, type) => {
      const currentType = type !== undefined ? type : typeId;
      const percentage =
        currentType === 0
          ? value
          : Math.round(getPercentAmount(value, originalPrice));
      const money = currentType === 0 ? (originalPrice / 100) * value : value;

      setDiscountPercentage(percentage);
      setMoneyAmount(money);
      setManualValue(value);

      setDiscountError(
        (money || value) > originalPrice
          ? 'Discount applied exceed item price'
          : ''
      );
    };

    const handleApplyDiscount = () => {
      const isType0 = typeId === 0;
      const newSelectedDiscount = {
        discount: moneyAmount,
        discountName: isType0
          ? `${discountPercentage}%`
          : `${currencySymbol}${moneyAmount}`,
        discountType: '3',
        discountPercentage,
        ...(sendDetails
          ? {
              amount: isType0 ? discountPercentage : moneyAmount,
              symbol: isType0 ? '%' : currencySymbol,
            }
          : {}),
      };

      onApply(newSelectedDiscount);
    };

    const handleDiscountTypeSelection = (type) => {
      setTypeId(Number(type));
      handleValueChange(manualValue, type);
    };

    return (
      <ManualAmountUI
        id={id}
        ref={ref}
        title="Enter Discount Value"
        manualType={discountTypes[typeId].type}
        manualValue={manualValue}
        setManualValue={handleValueChange}
        submitBtnLabel="Apply Discount"
        submitBtnStyleType="success"
        handleSubmit={handleApplyDiscount}
        backFunc={onBack}
        currencySymbol={currencySymbol}
        disableApply={Boolean(discountError)}
      >
        {/* TODO: add margin bottom to all UI components */}
        {discountError ? (
          <p style={{ marginBottom: 10 }}>
            <BadgeUI styleType="danger-light" title={discountError} />
          </p>
        ) : (
          <SwitchButtonsGroupUI
            switchList={discountTypes}
            activeSwitchIndex={typeId}
            setActiveSwitchIndex={handleDiscountTypeSelection}
            buttonModifierClassName="dine-in-buttons"
            spanStyleForTabs={{ style: { padding: '3px 14px' } }}
            currencySymbol={currencySymbol}
          />
        )}
      </ManualAmountUI>
    );
  }
);

export default ItemManualDiscountAmount;
