import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import { ScrollableBoxUI } from '../../UI/components';
import Button from '../Button';
import FourQuarterSection from './FourQuarterSection';

const FourQuarterView = ({
  fourQuarterData,
  onClose,
  onSectionClick,
  onSubmit,
  onSizeClick,
  submitLabel,
  parentPage,
  toggleItemEditMode,
}) => {
  const mainAreaRef = useRef();
  const mainAreaHeaderRef = useRef();
  const FourQuarterRef = useRef();
  const sizesRef = useRef();
  const [circleMaxWidth, setCircleMaxWidth] = useState('');
  const selectedSizes = fourQuarterData.fourQuarters
    ? fourQuarterData.fourQuarters[0]?.selectedSizes
    : [];

  const uniqueSizesForDisplay = selectedSizes.reduce((uniqueSizes, size) => {
    if (!uniqueSizes.some((s) => s.pairId === size.pairId)) {
      return [...uniqueSizes, size];
    }
    return uniqueSizes;
  }, []);

  const areAllQuarterFilled = fourQuarterData.fourQuarters?.every(
    (h) => h.currentMenuItem?._id
  );

  const onResize = useCallback(() => {
    const diff = areAllQuarterFilled ? 50 + 80 : 50;
    const circleWidth =
      mainAreaRef.current.offsetHeight -
      mainAreaHeaderRef.current.offsetHeight -
      sizesRef.current.offsetHeight -
      diff;
    const wrapWidth = mainAreaRef.current.offsetWidth - 80;

    const maxWidth = circleWidth > wrapWidth ? wrapWidth : circleWidth;

    setCircleMaxWidth(maxWidth);
  }, [areAllQuarterFilled]);

  const handleResize = throttle(() => {
    onResize();
  }, 350);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onResize, handleResize]);

  useEffect(() => {
    onResize();
  }, [onResize]);

  return (
    <div className="main-area" id="main-area">
      <div className="main-area-inner" ref={mainAreaRef}>
        <div className="main-half-area-header" ref={mainAreaHeaderRef}>
          <Button
            title="Close"
            className="half-area-btn-close"
            onClick={onClose}
          >
            <PosIconClose
              mainColor="#494b4c"
              darkModeColor="#fff"
              lineThickness={1.25}
            />
          </Button>
          <h1 className="category-title">Four Quarters</h1>

          {/* check from where to get this filter
        <div className="half-header-btn-box">
          <Button title="Gluten Free" className='btn round with-icon'><PosIconPlus mainColor='#ffffff'/> Gluten Free</Button>
        </div> */}
        </div>
        <div
          ref={FourQuarterRef}
          className={clsx('main-half-body-wrap-four-quarter', {
            'padding-80': areAllQuarterFilled,
          })}
        >
          <ScrollableBoxUI>
            <div className="main-half-area-body">
              <div
                className="half-half-cards four-quarters" //for Four Quarters Pizza need add class - "four-quarters"
                style={{
                  maxWidth: circleMaxWidth + 'px',
                }}
              >
                {fourQuarterData.fourQuarters?.map((item, i) => (
                  <FourQuarterSection
                    key={i}
                    item={item}
                    onClick={(event) => onSectionClick(event, item)}
                    parentPage={parentPage}
                    toggleItemEditMode={toggleItemEditMode}
                  />
                ))}
              </div>
              <div ref={sizesRef} className="half-half-sizes-box">
                <h3 className="half-half-sizes-title">Select a Size</h3>
                <div className="half-half-sizes-wrap">
                  {uniqueSizesForDisplay?.map((item, i) => {
                    return (
                      <div className="half-half-sizes-btn-wrap" key={i}>
                        <Button
                          title={item.name}
                          className={clsx('btn round grey', {
                            inverse: !item.isDefault,
                          })}
                          onClick={() => onSizeClick(item)}
                        >
                          {item.name}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </ScrollableBoxUI>
          {areAllQuarterFilled && (
            <div className="main-half-area-btn-box">
              <Button
                title="Add to Order"
                className="btn"
                onClick={onSubmit}
                enableThemeColor
                style={{
                  borderRadius: 8
                }}
              >
                {submitLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FourQuarterView;
