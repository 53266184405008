import React from 'react';
import { DriversNav } from './DriversNav';
import { UnassignedList } from './content/UnassignedList';
import classNames from 'classnames';
import { ScrollableBoxUI } from '../../UI/components';
// import { useSelector } from 'react-redux';

export const DeliveriesSidebar = () => {
  //   const { state } = useContext(Context);
  // const { scrolledStatus } = useSelector((state) => state.driversReducer);
  const cls = classNames({
    'nav-bar-header ': true,
    // shadow: scrolledStatus.position,
  });

  return (
    <div className="driver-sidebar">
      <div className={cls}>
        <div className="top-box">
          <h1 className="nav-bar-title">Unassigned</h1>
        </div>
      </div>

      <div className="nav-bar-details">
        <ScrollableBoxUI
        // actionType="scrollUnassignedList"
        >
          <div className="details-tab-wrap unassigned">
            <UnassignedList />
          </div>
        </ScrollableBoxUI>
      </div>

      <DriversNav />
    </div>
  );
};
