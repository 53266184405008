import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
  useReducer,
  createContext,
} from 'react';
import { useSelector } from 'react-redux';
const { keys } = Object;

const VirtualKeyboardContext = createContext({});

const inputNames = {
  customerName: 'customerName',
  orderNotes: 'notes',
  orderUnit: 'unit',
  orderPostCode: 'postCode',
  orderMobile: 'orderMobile',
  orderGuest: 'orderGuest',
  orderGeosuggest: 'orderGeosuggest',
  searchMenuItem: 'searchMenuItem',
  searchIngredients: 'searchIngredients',
  searchOrders: 'searchOrders',
  itemNotes: 'itemNotes',
  drawerName: 'drawerName',
  pairUserName: 'userName',
  pairPassword: 'password',
  pairPairCode: 'pairCode',
  pairTerminalName: 'terminalName',
  deliverySearchAddress: 'deliverySearchAddress',
  manualDeliveryAddress: 'manualDeliveryAddress',
  manualDeliveryCost: 'manualDeliveryCost',
  floorModalName: 'floorModalName',
  refundMessage: 'refundMessage',
  windcaveUser: 'windcaveUser',
  windcaveKey: 'windcaveKey',
  windcaveRestUser: 'windcaveRestUser',
  windcaveRestKey: 'windcaveRestKey',
};

export const VirtualKeyboardProvider = ({ children }) => {
  const [inputs, setState] = useReducer(
    (s, a) => ({ ...s, ...a }),
    keys(inputNames).reduce((ac, v) => ({ ...ac, [v]: '' }))
  );

  const selector = useCallback((cb) => cb(inputs), [inputs]);
  const inputValueSelector = useCallback(
    (inputName) => selector((s) => s[inputName]),
    [selector]
  );
  const virtualKeyboardGlobal = useSelector(
    (st) => st.dashboardReducer.virtualKeyboard
  );
  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const [floatingMode, setFloatingMode] = useState(false);
  const [layout, setLayout] = useState('default');
  const [inputName, setInputName] = useState('');
  const keyboardWrapRef = useRef();
  const keyboardRef = useRef();
  const move = useRef(false);
  const movePosition = useRef({ x: 0, y: 0, offsetY: 0, offsetX: 0 });

  const toggleKeyboard = useCallback(
    (status) => {
      if (virtualKeyboardGlobal) {
        setKeyboardVisibility(status);
        if (status && document.activeElement) {
          document.activeElement.blur();
        }
      }
    },
    [virtualKeyboardGlobal]
  );
  const onChangeInput = useCallback(
    (inputName, inputVal) => {
      setState({ [inputName]: inputVal });

      if (virtualKeyboardGlobal) {
        keyboardRef.current?.setInput(inputVal, inputName);
      }
    },
    [virtualKeyboardGlobal]
  );
  const onChangeFromKeyboard = useCallback(
    (input) => {
      onChangeInput(inputName, input);
    },
    [inputName, onChangeInput]
  );
  const onKeyPress = useCallback(
    (button) => {
      if (button.includes('{') && button.includes('}')) {
        let currentLayout = layout;
        let layoutName;

        switch (button) {
          case '{shift}':
          case '{shiftactivated}':
          case '{capslock}':
          case '{default}':
            layoutName = currentLayout === 'default' ? 'shift' : 'default';
            break;
          case '{alt}':
          case '{altright}':
            layoutName = currentLayout === 'alt' ? 'default' : 'alt';
            break;
          case '{smileys}':
            layoutName = currentLayout === 'smileys' ? 'default' : 'smileys';
            break;
          case '{floating}':
            setFloatingMode(!floatingMode);
            break;
          case '{downkeyboard}':
            setKeyboardVisibility(false);
            break;
          default:
            break;
        }

        setLayout(layoutName);
      }
    },
    [floatingMode, layout]
  );
  const onInitInput = useCallback(
    (inputName, inputVal) => {
      setState({ [inputName]: inputVal });

      if (virtualKeyboardGlobal) {
        keyboardRef.current?.setInput(inputVal, inputName);
      }
    },
    [virtualKeyboardGlobal]
  );
  const getInputValue = useCallback(
    (inputName) => {
      return inputValueSelector(inputName);
    },
    [inputValueSelector]
  );
  const handleMouseMove = useCallback(
    (event) => {
      if (floatingMode && move.current) {
        calcKeyboardPosition(event, movePosition);

        setKeyboardWrapPosition(
          keyboardWrapRef.current,
          movePosition.current.x,
          movePosition.current.y
        );
      }
    },
    [floatingMode]
  );
  const handleMouseDown = useCallback(
    (event) => {
      if (event.type !== 'touchstart') {
        event.preventDefault();
      }

      if (floatingMode && !move.current) {
        move.current = true;

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleMouseMove);

        calcKeyboardOffset(event, keyboardWrapRef, movePosition);
        calcKeyboardPosition(event, movePosition);

        setKeyboardWrapPosition(
          keyboardWrapRef.current,
          movePosition.current.x,
          movePosition.current.y
        );
      }
    },
    [floatingMode, handleMouseMove]
  );
  const handleMouseUp = useCallback(
    (event) => {
      if (floatingMode && move.current) {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('touchmove', handleMouseMove);
        move.current = false;
      }
    },
    [floatingMode, handleMouseMove]
  );

  useEffect(() => {
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
    };
  }, [handleMouseMove]);
  useEffect(() => {
    if (!floatingMode && virtualKeyboardGlobal) {
      removeKeyboardWrapPosition(keyboardWrapRef.current, movePosition);
    } else {
    }
  }, [floatingMode, virtualKeyboardGlobal]);
  useEffect(() => {
    if (!keyboardVisibility) {
      setLayout('default');
    }

    if (!virtualKeyboardGlobal && keyboardVisibility) {
      setKeyboardVisibility(false);
    }
  }, [virtualKeyboardGlobal, keyboardVisibility]);

  return (
    <VirtualKeyboardContext.Provider
      value={{
        virtualKeyboardGlobal,
        keyboardVisibility,
        setKeyboardVisibility,
        toggleKeyboard,
        keyboardRef,
        keyboardWrapRef,
        layout,
        getInputValue,
        setInputName,
        onChangeInput,
        onInitInput,
        inputName,
        onKeyPress,
        inputValueSelector,
        inputs,
        inputNames,
        onChangeFromKeyboard,
        floatingMode,
        setFloatingMode,
        handleMouseDown,
        handleMouseUp,
        handleMouseMove,
        setLayout,
      }}
    >
      {children}
    </VirtualKeyboardContext.Provider>
  );
};

export function useVirtualKeyboardContext() {
  return useContext(VirtualKeyboardContext);
}

function calcKeyboardOffset(event, keyboardWrapRef, movePosition) {
  let rect = keyboardWrapRef.current.getBoundingClientRect();
  let keyboardX =
    event.type === 'mousedown'
      ? event.clientX
      : event.changedTouches[0].clientX;
  let keyboardY =
    event.type === 'mousedown'
      ? event.clientY
      : event.changedTouches[0].clientY;

  movePosition.current.offsetX = keyboardX - rect.left;
  movePosition.current.offsetY = keyboardY - rect.top;
}

function calcKeyboardPosition(event, movePosition) {
  let keyboardX =
    event.type === 'mousedown' || event.type === 'mousemove'
      ? event.clientX
      : event.changedTouches[0].clientX;
  let keyboardY =
    event.type === 'mousedown' || event.type === 'mousemove'
      ? event.clientY
      : event.changedTouches[0].clientY;
  let currentX = keyboardX;
  let currentY = keyboardY - movePosition.current.offsetY;

  movePosition.current.x = currentX;
  movePosition.current.y = currentY;
}

function setKeyboardWrapPosition(el, x, y) {
  el.style.left = `${x}px`;
  el.style.top = `${y}px`;
  el.style.bottom = 'auto';
}

function removeKeyboardWrapPosition(el, movePosition) {
  movePosition.current.offsetY = 0;
  movePosition.current.offsetX = 0;
  el.style.left = '';
  el.style.top = '';
  el.style.bottom = '';
}
