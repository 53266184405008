import { PosIcon } from '../assets/icons/PosIcon';
import { paymentTypeEnum } from 'configuration/enums';

export const ITEM_CUSTOMIZATION_OPTIONS = [
  {
    id: 1,
    title: 'Add',
    slug: 'add',
    actionType: 'addIngredient',
    arrayName: 'selectedExtraIngredients',
  },
  {
    id: 2,
    title: 'Remove',
    slug: 'remove',
    actionType: 'removeIngredient',
    arrayName: 'selectedIngredients',
  },
  {
    id: 3,
    title: 'Light',
    slug: 'light',
    actionType: 'lightIngredient',
    arrayName: '',
  },
  {
    id: 4,
    title: 'Swap',
    slug: 'swap',
    actionType: 'swapIngredient',
    arrayName: '',
  },
  {
    id: 5,
    title: 'Custom',
    slug: 'custom',
    actionType: 'customIngredient',
    arrayName: '',
  },
];
export const PAYMENT_TYPES = [
  {
    id: '1',
    color: 'blue',
    iconComponent: (
      <PosIcon
        className="pos-icon--order-paid-status"
        name="credit-card"
        size="custom"
        customHeight={26}
        customWith={34}
        currentColor={true}
      />
    ),
    title: 'Add Card Payment',
    name: 'Card',
    type: paymentTypeEnum.CARD_IN_STORE,
  },
  {
    id: '2',
    color: 'green',
    iconComponent: (
      <PosIcon
        className="pos-icon--order-paid-status"
        name="dollar-circle"
        size="custom"
        customHeight={34}
        customWith={34}
        currentColor={true}
      />
    ),
    title: 'Add Cash Payment',
    name: 'Cash',
    type: paymentTypeEnum.CASH,
  },
  // {
  //   id: '3',
  //   color: 'orange',
  //   iconComponent: (
  //     <PosIcon
  //       className="pos-icon--order-paid-status"
  //       name="gift"
  //       size="custom"
  //       customHeight={26}
  //       customWith={36}
  //       currentColor={true}
  //     />
  //   ),
  //   title: 'Add Voucher Payment',
  //   type: 'custom',
  // },
  // {
  //   id: '4',
  //   color: 'purple',
  //   iconComponent: (
  //     <PosIcon
  //       className="pos-icon--order-paid-status"
  //       name="question"
  //       size="custom"
  //       customHeight={32}
  //       customWith={32}
  //       currentColor={true}
  //     />
  //   ),
  //   title: 'Add Custom Payment',
  //   type: 'cash',
  // },
];
