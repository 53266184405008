import { useSelect } from 'downshift';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderTimeSortOrderFilter } from '../../Orders/actions';
import { orderTimeSortOrderFilterSelector } from '../../Orders/selectors';
import DownwardArrow from './DownwardArrow';

export const items = [{
  text: 'Expected Time',
  value: 'expectedTime'
}, {
  text: 'Placed Time',
  value: 'placedTime'
}]

export const orderTimeSortFilterEnum = items.reduce((acc, currentVal) => {
  acc[currentVal.value] = currentVal.value
  return acc;
})

function DropdownSelect() {
  const {
    isOpen,
    // selectedItem,
    getToggleButtonProps,
    // getLabelProps,
    getMenuProps,
    // highlightedIndex,
    getItemProps,
  } = useSelect({ items });

  const dispatch = useDispatch()
  const orderTimeSortOrderFilterFromRedux = useSelector(orderTimeSortOrderFilterSelector);

  const buttonTextVal = items.find(item => item.value === orderTimeSortOrderFilterFromRedux)?.text

  return (
    <div>
      <button
        className="hover-highlight scheduled-dropdown"
        type="button"
        {...getToggleButtonProps()}
      >
        {buttonTextVal}

        <span className="pos_icon-down-chevron">
          <DownwardArrow />
        </span>
      </button>
      <ul {...getMenuProps()} className="settings-orders">
        {isOpen &&
          items.map((item, index) => {
            const selected = orderTimeSortOrderFilterFromRedux === item.value;
            return (
              <li
                className={`${selected ? 'selected': ''}`}
                key={`${item.value}${index}`}
                {...getItemProps({
                  item, index,
                  onClick() {
                    dispatch(updateOrderTimeSortOrderFilter(item.value))
                  }
                })}
              >
                {item.text}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default function OrdersTimeSortFilter() {
  return <DropdownSelect />;
}
