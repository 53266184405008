import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconCash = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-cash': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
        <title>Cash</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M24.5,5H.5a.5.5,0,0,0-.5.5v14a.5.5,0,0,0,.5.5h24a.5.5,0,0,0,.5-.5V5.5A.5.5,0,0,0,24.5,5ZM1,6H4A3,3,0,0,1,1,9ZM1,19V16a3,3,0,0,1,3,3Zm23,0H21a3,3,0,0,1,3-3Zm0-4a4,4,0,0,0-4,4H5a4,4,0,0,0-4-4V10A4,4,0,0,0,5,6H20a4,4,0,0,0,4,4Zm0-6a3,3,0,0,1-3-3h3ZM11,11a1,1,0,0,0,1,1h1a2,2,0,0,1,0,4v1H12V16a2,2,0,0,1-2-2h1a1,1,0,0,0,1,1h1a1,1,0,0,0,0-2H12a2,2,0,0,1,0-4V8h1V9a2,2,0,0,1,2,2H14a1,1,0,0,0-1-1H12A1,1,0,0,0,11,11Z"
        />
      </svg>
    </span>
  );
};

PosIconCash.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
