import { createSlice } from '@reduxjs/toolkit'

export const MODALS = [
  'staffPinModal',
  'itemNotesModal',
  'addressModal',
  'customerNameModal',
  'paymentModal',
  'paymentMethodModal',
  'customerPhoneModal',
  'splitPaymentsModal',
  'discountModal',
  'timeModal',
  'search',
  'sideDrawer',
  'alertModal',
  'voucherModal',
];

const initialState = {
  ...(MODALS.reduce((acc, modalName) => ({...acc, [`${modalName}Open`]: false }), {}))
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setState: (state, action) => ({ ...state, ...action.payload }),
    toggleModal: (state, { payload: modalName }) => ({ ...state, [`${modalName}Open`]: !state[`${modalName}Open`] }),
    closeModal: (state, { payload: modalName }) => ({ ...state, [`${modalName}Open`]: false }),
    openModal: (state, { payload: modalName }) => ({ ...state, [`${modalName}Open`]: true }),
  },
})

export const { setState, toggleModal, closeModal, openModal } = modalsSlice.actions;

export default modalsSlice.reducer;
