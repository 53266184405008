import './bem-manual-amount.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  ChevronLeftIcon as IconChevronLeft,
  PhoneIcon as IconPhone,
  PlusRoundedIcon as IconPlusRounded,
} from 'nexticons/outline';
import { CircleIcon as IconCircle } from 'nexticons/solid';
import { BackButtonCircleUI, ButtonGroupUI, ButtonUI, NumericKeyboardUI } from '../index';
import React, { forwardRef, useEffect, useState } from 'react';
import { getUIClassesFromProps, getUIModifierKeys } from '../modifiersMapping';

const keyList = [
  { code: '1', content: '1', title: '1' },
  { code: '2', content: '2', title: '2' },
  { code: '3', content: '3', title: '3' },
  { code: '4', content: '4', title: '4' },
  { code: '5', content: '5', title: '5' },
  { code: '6', content: '6', title: '6' },
  { code: '7', content: '7', title: '7' },
  { code: '8', content: '8', title: '8' },
  { code: '9', content: '9', title: '9' },
  {
    code: 'dot',
    content: <IconCircle width="12" />,
    title: 'Dot',
  },
  {
    code: 'plus',
    content: <IconPlusRounded width="24" strokeWidth="2.5" />,
    title: 'Plus',
  },
  { code: 'empty', content: '', title: '' },
  { code: '0', content: '0', title: '0' },
  {
    code: 'clear',
    content: <IconChevronLeft width="26" strokeWidth="2.5" />,
    title: 'Clear',
  },
];

export const ManualAmountUI = forwardRef(
  (
    {
      id,
      title,
      manualType,
      manualValue,
      setManualValue,
      submitBtnLabel,
      submitBtnStyleType,
      children,
      backFunc,
      handleSubmit,
      currencySymbol,
      disableApply,
      decimalSeparator,
      textColorStyle,
      reset
    },
    ref
  ) => {
    const [keyFilteredList, setFilteredList] = useState([]);

    const handleKeyDown = (e) => {
      if (e.keyCode !== undefined) {
        let code;
        const keyCode = e.keyCode || e.which;
        const keyDotList = [110, 190];
        const keyPlusList = [187, 107];
        const keyDelList = [8, 46];
        const keyNumList = [
          48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105,
        ];

        if (keyDelList.includes(Number(keyCode))) {
          code = 'clear';
        } else if (keyDotList.includes(Number(keyCode))) {
          code = 'dot';
        } else if (keyPlusList.includes(Number(keyCode))) {
          code = 'plus';
        } else if (keyNumList.includes(Number(keyCode))) {
          code = e.key;
        }

        if (code) {
          writeNumber(code);
        }
      }
    };

    const writeNumber = (code) => {
      let arr = !reset ? [...String(manualValue)] : [];
      if (
        code !== 'clear' &&
        arr.includes('.') &&
        arr.length <= arr.indexOf('.') + parseInt(decimalSeparator)
      ) {
        arr.push(code);
      } else if (
        code !== 'clear' &&
        code !== 'dot' &&
        code !== 'plus' &&
        code !== 'empty' &&
        !arr.includes('.')
      ) {
        arr.push(code);
      } else if (arr.length && code === 'clear') {
        arr.splice(-1, 1);
      } else if (
        code === 'dot' &&
        (manualType === 'money' || manualType === 'percentage') &&
        manualValue.indexOf('.') === -1 &&
        manualValue.length
      ) {
        arr.push('.');
      } else if (code === 'plus' && manualType === 'phone' && manualValue.length === 0) {
        arr.push('+');
      }

      setManualValue(arr.join(''));
    };

    useEffect(() => {
      let filteredArr = keyList.filter((item) => {
        if (manualType === 'phone' && item.code !== 'dot' && item.code !== 'empty') {
          return item;
        } else if (manualType === 'number' && item.code !== 'dot' && item.code !== 'plus') {
          return item;
        } else if (
          (manualType === 'money' || manualType === 'percentage') &&
          item.code !== 'plus' &&
          item.code !== 'empty'
        ) {
          return item;
        }
      });

      setFilteredList(filteredArr);
    }, [manualType]);


    return (
      <div className="bem-manual-amount" id={id} ref={ref}>
        <div className="bem-manual-amount__inner">
          <div className="bem-manual-amount__header">
            {typeof backFunc === 'function' && (
              <ButtonGroupUI style={{ position: 'absolute', left: 15, top: 15 }}>
                <BackButtonCircleUI onClick={backFunc} />
              </ButtonGroupUI>
            )}
            <h3
              className={clsx(
                'bem-manual-amount__title',
                typeof backFunc !== 'function' && `bem-manual-amount__title--big`
              )}
            >
              {title}
            </h3>
            {manualType === 'phone' ? (
              <div className="bem-manual-amount__phone-box">
                <IconPhone />
                <div
                  className="bem-manual-amount__phone"
                  style={{ fontSize: calcFontSize(manualValue) }}
                >
                  {manualValue}
                </div>
              </div>
            ) : (
              <div
                className={getUIClassesFromProps('bem-manual-amount__value', { textColorStyle })}
              >
                {manualValue !== 0 && manualValue && manualType === 'money' && currencySymbol}
                {manualValue !== 0 && manualValue}
                {manualValue !== 0 && manualValue && manualType === 'percentage' && '%'}
              </div>
            )}
            <div className="bem-manual-amount__content">{children}</div>
          </div>
          <div className="bem-manual-amount__body">
            <NumericKeyboardUI
              onKeyDown={handleKeyDown}
              onPressNumericKey={writeNumber}
              keyList={keyFilteredList}
              tabIndex="0"
            />
          </div>
          <div className="bem-manual-amount__footer">
            <ButtonUI
              fullWidth
              styleType={submitBtnStyleType}
              sizeType="xxl"
              rounded="none"
              disabled={disableApply}
              onClick={handleSubmit}
            >
              {submitBtnLabel}
            </ButtonUI>
          </div>
        </div>
      </div>
    );
  }
);

function calcFontSize(val) {
  let size = 34;

  if (val.length > 12) {
    size = size - (val.length - 12) * 1.5;
  }

  return size;
}

ManualAmountUI.defaultProps = {
  title: '',
  manualType: 'number',
  manualValue: '',
  submitBtnLabel: '',
  submitBtnStyleType: 'primary',
  children: null,
  setManualValue: () => {},
  backFunc: null,
  handleSubmit: () => {},
  disableApply: false,
  decimalSeparator: 2,
  textColorStyle: 'default',
};

ManualAmountUI.propTypes = {
  title: PropTypes.string.isRequired,
  manualType: PropTypes.oneOf(['money', 'percentage', 'phone', 'number']).isRequired,
  manualValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  submitBtnLabel: PropTypes.string,
  submitBtnStyleType: PropTypes.oneOf(['primary', 'success', 'danger']),
  children: PropTypes.node,
  setManualValue: PropTypes.func,
  backFunc: PropTypes.func,
  handleSubmit: PropTypes.func,
  disableApply: PropTypes.bool,
  decimalSeparator: PropTypes.number,
  textColorStyle: PropTypes.oneOf(getUIModifierKeys('textColorStyle')),
};
