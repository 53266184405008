import {
  addObjectListsToFloorLayout,
  getFloorLayoutOnCreate,
  removeFloorLayoutObject,
  removeObjectsFromFloorLayout,
  updateObjectListsInFloorLayout,
} from './utils';

export const notificationInitialState = {
  floorLayouts: [],
  dineInObjects: [],
  dineInOccupancies: [],
  totalNumPeople: 0,
};

export const initNotificationState = (initState) => {
  return initState;
};

export const actionTypes = {
  createFloor: 'CREATE_FLOOR',
  createFloorLayoutObjects: 'CREATE_FLOOR_LAYOUT_OBJECTS',
  updateFloorLayoutObjects: 'UPDATE_FLOOR_LAYOUT_OBJECTS',
  deleteFloorLayoutObject: 'DELETE_FLOOR_LAYOUT_OBJECT',
  deleteFloorLayout: 'DELETE_FLOOR_LAYOUT',
  resetNotificationsState: 'RESET_NOTIFICATIONS_STATE',
  updateDineInObjectStatus: 'UPDATE_DINE_IN_OBJECT_STATUS',
  updateDineInOccupanciesStatus: 'UPDATE_DINE_IN_OCCUPANCIES_STATUS',
};

export default function notificationReducer(
  state = notificationInitialState,
  action
) {
  switch (action.type) {
    case actionTypes.createFloor: {
      const floor = action.payload;
      const floorLayouts = getFloorLayoutOnCreate(state.floorLayouts, floor);
      return { ...state, floorLayouts };
    }
    case actionTypes.createFloorLayoutObjects: {
      const { floorLayoutInfo, objectListsToAdd } = action.payload;
      const floorLayouts = addObjectListsToFloorLayout(
        state.floorLayouts,
        floorLayoutInfo,
        objectListsToAdd
      );
      return { ...state, floorLayouts };
    }
    case actionTypes.updateFloorLayoutObjects: {
      const { floorLayoutInfo, objectListsToAdd } = action.payload;
      const updatedFloorLayouts = updateObjectListsInFloorLayout(
        state.floorLayouts,
        floorLayoutInfo,
        objectListsToAdd
      );
      return { ...state, floorLayouts: updatedFloorLayouts };
    }
    case actionTypes.deleteFloorLayoutObject: {
      const { floorLayoutInfo, objectToBeRemoved, floorLayoutObjectType } =
        action.payload;
      const updatedFloorLayouts = removeObjectsFromFloorLayout(
        state.floorLayouts,
        floorLayoutInfo,
        objectToBeRemoved,
        floorLayoutObjectType
      );
      return { ...state, floorLayouts: updatedFloorLayouts };
    }
    case actionTypes.deleteFloorLayout: {
      const { floorLayoutInfo } = action.payload;
      const floorLayoutAfterDeletion = removeFloorLayoutObject(
        state.floorLayouts,
        floorLayoutInfo
      );
      return { ...state, floorLayouts: floorLayoutAfterDeletion };
    }
    case actionTypes.resetNotificationsState: {
      return initNotificationState(notificationInitialState);
    }
    case actionTypes.updateDineInObjectStatus: {
      const dineInObjects = action.payload;
      return { ...state, dineInObjects };
    }
    case actionTypes.updateDineInOccupanciesStatus: {
      const dineInOccupancies = action.payload;
      return {
        ...state,
        dineInOccupancies,
        totalNumPeople: dineInOccupancies.reduce(
          (num, obj) => (num += obj.numPeople ?? 0),
          0
        ),
      };
    }
    default:
      return state;
  }
}
