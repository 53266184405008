export const matchAddressWithSuburbs = (
  addressObject,
  suburbs,
  isRadiusDeliveryCost = false
) => {
  if (typeof addressObject === 'undefined') {
    return null;
  }
  const { context = [], locationText } = addressObject;
  let currentSuburb = '';
  let area = '';
  let areaCode = '';
  let street = '';
  let state = '';
  let country = '';
  for (let i = 0; i < context.length; i++) {
    const { id = '', text = '' } = context[i];
    if (
      (id.includes('locality') ||
        id.includes('district') ||
        id.includes('place') ||
        id.includes('route') ||
        id.includes('postal_town')) &&
      currentSuburb === '' &&
      area === ''
    ) {
      currentSuburb = text;
      area = text;
    }
    if (id.includes('postcode')) {
      areaCode = text;
    }
    if (id.includes('region')) {
      state = text;
    }
    if (id.includes('country')) {
      country = text;
    }
  }

  if (isRadiusDeliveryCost) return { area, areaCode, street, state, country };

  if (suburbs.length > 0) {
    let supportedSubs = suburbs.filter((s) => {
      return currentSuburb.toLocaleLowerCase() === s.name?.toLowerCase();
    });
    if (supportedSubs && supportedSubs.length > 0) {
      const addressWithoutSuburbArray = locationText
        ?.replace(`, ${area}`, '')
        .split(',');
      street =
        addressWithoutSuburbArray[addressWithoutSuburbArray.length - 1]?.trim();
      return { ...supportedSubs[0], area, areaCode, street, state, country };
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getMapboxApiResponseFormatted = (searchResultsFromApi) => {
  return searchResultsFromApi
    ?.filter((result) => result.relevance > 0.5)
    ?.map((searchResult) => {
      const { context = [], center } = searchResult;
      let postCode = '';
      let region = '';
      let country = '';
      context?.forEach((contextItem) => {
        const { id = '', text = '' } = contextItem;
        if (id.includes('postcode')) {
          postCode = text;
        } else if (id.includes('region')) {
          region = text;
        } else if (id.includes('country')) {
          country = text;
        }
      });
      const textToRemoveInAddress = ` ${region} ${postCode}, ${country}`;
      let locationText = searchResult.place_name?.replace(
        textToRemoveInAddress,
        ''
      );
      if (locationText[locationText.length - 1] === ',') {
        locationText = locationText.slice(0, locationText.length - 1);
      }
      return {
        ...searchResult,
        locationText: locationText?.trim() || '',
        location: { lat: center[1], lng: center[0] },
      };
    });
};
