import { useMemo, useEffect, useReducer, useState } from 'react';
import moment from 'moment-timezone';
import {
  itemHash,
  dehash,
  itemPreq,
  getItemInstruction,
  countTotalItem,
  getOrderIndexHashLength,
  getSliceOffset,
} from './util';
import {
  BasePartUI,
  BillCheckInfoUI,
  BillCheckItemUI,
  BillCheckUI,
  ButtonGroupUI,
  ButtonUI,
  SelectItemUI,
} from 'UI/components';
import ShareItemsModal from './ShareItemsModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentOrder } from 'pages/Dashboard/action';
import {
  saveSharedStatusOfAllItems,
  createSplitPerItemChecks,
} from './SplitPerItem.service';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { addPrinterCommandApi } from '../../services/ordersServices';
import {
  selectedDrawerPrinterIdSelector,
  storeConfigSelector,
  storeTimeZoneSelector,
  restaurantIdSelector,
} from '../../pages/Dashboard/selectors';
import { deleteChecks } from './SplitPerItem.service';
import { _auth, _firestore } from '../../firebase';
import { API_URL } from 'configuration/apiUrl';

const { values } = Object;

const SplitPerItem = ({
  setSelectedCheck,
  guests,
  togglePaymentModal,
  selectedCheck,
  setChecks,
  checks,
  setState,
  currentOrder,
  setIsEditCheckInProcess,
  printer,
  accessToken,
}) => {
  let totalDiscount = 0;
  const restaurantId = useSelector(restaurantIdSelector);
  const timeZone = useSelector(storeTimeZoneSelector);
  const storeConfig = useSelector(storeConfigSelector);
  const [isloading, setIsLoading] = useState(false);
  const [isPrinterApiLoading, setIsPrinterApiLoading] = useState(false);
  const [shareItemsOpen, toggleShareItemsModal] = useReducer(
    (st) => !st,
    false
  );
  const [isEditCheck, setIsEditCheck] = useState({
    checkNumber: -1,
    value: false,
  });

  const dispatch = useDispatch();
  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );

  const [selection, toggleItem] = useReducer(
    (st, a) => {
      if (a === `clear`) {
        return {
          selectedItemIds: new Set(),
        };
      }
      let newItems = new Set(st.selectedItemIds);

      return {
        selectedItemIds: newItems.delete(a) ? newItems : newItems.add(a),
      };
    },
    { selectedItemIds: new Set() }
  );

  const [shared, setShared] = useState(currentOrder.sharedItemsStatus || {});

  const halfhalfItemDetail = ({ hash, ...item }) => {
    const partsCount = hash.split('+').length;

    if (partsCount > 1) {
      let items = [];
      if (partsCount === 2) {
        items = currentOrder.menuItems.filter(
          (i) => i.halfIndex === +hash.slice(-3)[0]
        );
      }
      if (partsCount === 4) {
        items = currentOrder.menuItems.filter((i) => {
          return i.fourQuarterIndex === +hash.slice(-3)[0];
        });
      }
      let isVoided = false;
      items.forEach((item) => {
        if (item?.isVoided) {
          isVoided = true;
        }
      });

      return {
        itemId: hash,
        name: items.map((i) => i.name).join('/'),
        items,
        quantity: 1,
        isVoided: isVoided,
        payablePrice:
          items.reduce((acc, v) => (acc += parseFloat(v.payablePrice)), 0) /
          (shared[hash.slice(0, -1)] || 1),
        totalDiscountAmt:
          items.reduce(
            (total, item) => total + (item?.totalDiscountAmt || 0),
            0
          ) * item.share,
      };
    }

    const i = currentOrder.menuItems.find(itemPreq(hash));
    const share =
      shared[hash.slice(0, -1)] || (item?.share ? 1 / item.share : 1);

    return {
      ...i,
      itemId: hash,
      quantity: 1 / share,
      payablePrice: i.payablePrice / share / i.quantity,
      selectedSizes: [{ ...i.selectedSizes[0], quantity: 1 / share }],
    };
  };

  const getTotalSumOfCheck = (selectedCheck, selectedItems) => {
    let totalSumOfCheck = 0;
    selectedCheck?.items?.map((checkitem, index) => {
      let itemDetails = {};
      const ishalfHalf = checkitem.itemId.includes('+');
      if (ishalfHalf) {
        itemDetails = selectedItems.find(
          (menuItem) => menuItem.itemId === checkitem.itemId
        );
        let isHalfHalfVoided = false;
        itemDetails.items.forEach((item) => {
          if (item?.isVoided) {
            isHalfHalfVoided = true;
          }
        });

        itemDetails = { ...itemDetails, isVoided: isHalfHalfVoided };

        totalDiscount =
          totalDiscount +
            itemDetails?.items.reduce((total, item) => {
              return total + item.totalDiscountAmt * checkitem.share;
            }, 0) || 0;
      } else {
        const { id } = dehash(checkitem.itemId);
        itemDetails = currentOrder.menuItems.find(
          (menuItem) =>
            menuItem._id === id &&
            checkitem.orderIndexes[0] === menuItem.orderIndex
        );
        totalDiscount =
          totalDiscount +
          (itemDetails?.totalDiscountAmt * checkitem.share) /
            (itemDetails.quantity || 1);
      }

      if (ishalfHalf) {
        totalSumOfCheck = Number(
          (
            totalSumOfCheck +
            itemDetails.payablePrice / itemDetails.quantity
          ).toFixed(2)
        );
      } else {
        totalSumOfCheck = Number(
          (
            totalSumOfCheck +
            (itemDetails.payablePrice / itemDetails.quantity) * checkitem.share
          ).toFixed(2)
        );
      }
    });
    return totalSumOfCheck;
  };

  const itemIdsAlreadyInChecks = useMemo(
    () =>
      checks
        .flatMap((c) =>
          (c.items || []).map((i) => {
            let itemDetails = {};
            if (i.itemId.includes('+')) {
              itemDetails = halfhalfItemDetail({ hash: i.itemId, ...i });
            } else {
              const { id } = dehash(i.itemId);

              itemDetails = currentOrder.menuItems.find(
                (menuItem) => menuItem._id === id
              );
            }

            if (!itemDetails.isVoided) {
              return i.itemId;
            }
          })
        )
        .filter((i) => i),
    [checks]
  );

  const selectedItemsPrice = useMemo(() => {
    const { selectedItemIds } = selection;

    const total = Array.from(selectedItemIds).flatMap((id) => {
      const partsCount = id.split('+').length;
      if (partsCount > 1) {
        if (partsCount === 2) {
          return (
            currentOrder.menuItems
              .filter((i) => i.halfIndex === +id.slice(-3)[0])
              .reduce(
                (acc, v) => (acc += v.payablePrice - (v.totalDiscountAmt || 0)),
                0
              ) / (shared[id.slice(0, -1)] || 1)
          );
        }
        return (
          currentOrder.menuItems
            .filter((i) => {
              return i.fourQuarterIndex === +id.slice(-3)[0];
            })
            .reduce(
              (acc, v) => (acc += v.payablePrice - (v.totalDiscountAmt || 0)),
              0
            ) / (shared[id.slice(0, -1)] || 1)
        );
      }

      const k = currentOrder.menuItems.find(itemPreq(id));

      return (
        (k.payablePrice - (k.totalDiscountAmt || 0)) /
        ((shared[
          id
            .slice(0, getSliceOffset(k.orderIndex))
            .concat(
              id.substring(
                id.length - getOrderIndexHashLength(k.orderIndex),
                id.length
              )
            )
        ] || 1) *
          k.quantity)
      );
    });

    return total.reduce((acc, v) => (acc += v), 0);
  }, [selection, currentOrder.menuItems, shared]);

  const { selectedItemIds } = selection;
  const sharedItemIds = useMemo(
    () => Object.keys(shared).filter((s) => !!shared[s]),
    [shared]
  );
  const totalItemsCount = countTotalItem(
    currentOrder.menuItems.filter((i) => !i.isVoided),
    shared
  );

  useEffect(() => {
    let empytChecksCount = 0;
    checks.forEach((check) => {
      if (check?.items?.length === 0 || !check?.items) {
        empytChecksCount = empytChecksCount + 1;
      }
    });

    if (
      totalItemsCount > itemIdsAlreadyInChecks.length &&
      empytChecksCount === 0
    ) {
      const next = {
        number: `${checks.length}`,
        numGuests: 1,
        payableAmount: 0,
        payments: [],
      };
      setChecks([...checks, next]);
    }
  }, [totalItemsCount, checks]);

  useEffect(() => {
    if (totalItemsCount === itemIdsAlreadyInChecks.length) {
      // setSelectedCheck(0);
    } else if (totalItemsCount < itemIdsAlreadyInChecks.length) {
      const grouped = itemIdsAlreadyInChecks.reduce(
        (acc, v) => ({
          ...acc,
          [v.slice(0, -2)]: itemIdsAlreadyInChecks.filter((k) =>
            k.startsWith(v.slice(0, -2))
          ).length,
        }),
        {}
      );

      setShared(grouped);
    }
  }, [totalItemsCount, itemIdsAlreadyInChecks, setSelectedCheck]);

  const callSaveSharedStatusApi = async () => {
    const response = await saveSharedStatusOfAllItems(
      { ...currentOrder, sharedItemsStatus: shared },
      nextOrderConfigURL
    );
  };

  useEffect(() => {
    callSaveSharedStatusApi();
  }, [shared]);

  useEffect(() => {
    const totalPayableAmount = Number(currentOrder.payableAmount);
    const totalPayableAmountInChecks = checks.reduce(
      (total, check) => (total = total + check?.payableAmount || 0),
      0
    );

    if (Number(totalPayableAmountInChecks.toFixed(2)) > totalPayableAmount) {
      const difference = Number(
        (
          Number(totalPayableAmountInChecks.toFixed(2)) -
          Number(totalPayableAmount.toFixed(2))
        ).toFixed(2)
      );

      if (difference > 0 && difference < 0.05) {
        const firstUnpaidInQueue = checks.find(
          (check) => check.paidAmount === 0 || check.paidAmount === undefined
        );

        const updateChecks = checks.map((check) => {
          if (firstUnpaidInQueue.number === check.number) {
            return {
              ...check,
              payableAmount: Number(
                (check.payableAmount - difference).toFixed(2)
              ),
            };
          }
          return check;
        });

        setChecks(updateChecks);
      }
    }
  }, [JSON.stringify(checks), selectedItemIds]);

  useEffect(async () => {
    let isOrderContainVoidedItem = false;

    currentOrder.menuItems.forEach((item) => {
      if (item.isVoided) {
        isOrderContainVoidedItem = true;
      }
    });

    if (isOrderContainVoidedItem) {
      const updateChecks = checks.map((check) => {
        if (check.paidAmount === 0 || !check?.paidAmount) {
          let voidedAmount = 0;
          let payableAmount = 0;

          if (check?.items?.length > 0) {
            check.items.forEach((item) => {
              let itemDetails = {};
              if (item.itemId.includes('+')) {
                itemDetails = halfhalfItemDetail({
                  hash: item.itemId,
                  ...item,
                });
                if (itemDetails?.isVoided) {
                  voidedAmount =
                    voidedAmount + itemDetails.payablePrice * item.share;
                }
                payableAmount =
                  payableAmount +
                  itemDetails.items.reduce(
                    (total, item) => total + item.payablePrice,
                    0
                  );
              } else {
                const { id } = dehash(item.itemId);

                itemDetails = currentOrder.menuItems.find(
                  (menuItem) => menuItem._id === id
                );

                if (itemDetails?.isVoided) {
                  voidedAmount =
                    voidedAmount + itemDetails.payablePrice * item.share;
                }
                payableAmount = payableAmount + itemDetails.payableAmount;
              }
            });
          }

          return {
            ...check,
            payableAmount: Number((payableAmount - voidedAmount).toFixed(2)),
          };
        }

        return check;
      });

      const splitPerItemPaymentPayload = {
        checks: updateChecks,
        numChecks: updateChecks.length || 0,
        orderId: currentOrder._id,
      };
      setIsLoading(true);
      const createCheckApi = await createSplitPerItemChecks(
        splitPerItemPaymentPayload,
        'per_item',
        accessToken,
        currentOrder
      );
      if (createCheckApi?.errors?.length === 0) {
        setChecks(createCheckApi?.data?.splitPayment?.checks || []);
      }
      setIsLoading(false);
    }
  }, []);

  const handlePrintCommand = async () => {
    setIsPrinterApiLoading(true);
    const printCommandRequestObj = {
      checkId: selectedCheck.id,
      printerId: printer?._id,
      orderId: currentOrder._id,
      storeId: storeConfig?.storeId,
      restaurantId,
      printReceipt: true,
      receiptType: 12,
      openCashDrawer: false,
      timeZone,
    };

    if (printer?._id) {
      await addPrinterCommandApi(printCommandRequestObj);
    }
    setIsPrinterApiLoading(false);
  };

  const renderItems = (selectedCheck, selectedItems) => {
    return selectedCheck?.items?.map((checkitem, index) => {
      let itemDetails = {};
      const ishalfHalf = checkitem.itemId.includes('+');
      if (ishalfHalf) {
        itemDetails = selectedItems.find(
          (menuItem) => menuItem.itemId === checkitem.itemId
        );
        let isHalfHalfVoided = false;
        itemDetails.items.forEach((item) => {
          if (item?.isVoided) {
            isHalfHalfVoided = true;
          }
        });

        itemDetails = { ...itemDetails, isVoided: isHalfHalfVoided };

        totalDiscount =
          totalDiscount +
            itemDetails?.items.reduce((total, item) => {
              return total + item.totalDiscountAmt * checkitem.share;
            }, 0) || 0;
      } else {
        const { id } = dehash(checkitem.itemId);
        itemDetails = currentOrder.menuItems.find(
          (menuItem) =>
            menuItem._id === id &&
            checkitem.orderIndexes[0] === menuItem.orderIndex
        );
        totalDiscount =
          totalDiscount +
          (itemDetails?.totalDiscountAmt * checkitem.share) /
            (itemDetails.quantity || 1);
      }

      return (
        <div>
          {ishalfHalf ? (
            <BillCheckItemUI
              key={itemDetails.itemId + index}
              title={`${itemDetails?.name}`}
              isVoided={itemDetails?.isVoided}
              instruction={''}
              price={`${storeConfig?.currency || '$'}${(
                itemDetails.payablePrice / itemDetails.quantity
              ).toFixed(2)}`}
            />
          ) : (
            <BillCheckItemUI
              key={itemDetails._id + index}
              isVoided={itemDetails?.isVoided}
              title={`${itemDetails?.name}`}
              instruction={getItemInstruction(itemDetails)}
              price={`${storeConfig?.currency || '$'}${(
                (itemDetails.payablePrice / itemDetails.quantity) *
                checkitem.share
              ).toFixed(2)}`}
            />
          )}
        </div>
      );
    });
  };

  const { selectedDiscount } = currentOrder;

  if (
    // totalItemsCount <= itemIdsAlreadyInChecks.length
    selectedCheck?.items?.length > 0 &&
    !isEditCheck.value
  ) {
    const selectedItems = selectedCheck.items.flatMap(
      ({ itemId: hash, ...item }) => {
        const partsCount = hash.split('+').length;
        if (partsCount > 1) {
          let items = [];
          if (partsCount === 2) {
            items = currentOrder.menuItems.filter(
              (i) => i.halfIndex === +hash.slice(-3)[0]
            );
          }
          if (partsCount === 4) {
            items = currentOrder.menuItems.filter(
              (i) => i.fourQuarterIndex === +hash.slice(-3)[0]
            );
          }
          const share =
            shared[hash.slice(0, -1)] || (item?.share ? 1 / item.share : 1);

          return {
            itemId: hash,
            name: items.map((i) => i.name).join('/'),
            items,
            quantity: 1,
            payablePrice:
              items.reduce((acc, v) => (acc += parseFloat(v.payablePrice)), 0) /
              share,
          };
        }

        const i = currentOrder.menuItems.find(itemPreq(hash));
        const share =
          shared[hash.slice(0, -1)] || (item?.share ? 1 / item.share : 1);

        return {
          ...i,
          itemId: hash,
          quantity: 1 / share,
          payablePrice: i.payablePrice / share / i.quantity,
          selectedSizes: [{ ...i.selectedSizes[0], quantity: 1 / share }],
        };
      }
    );

    const checksWithItemsLength =
      checks.filter((check) => check.payableAmount > 0)?.length || 0;

    const checkPaid =
      selectedCheck.paidAmount &&
      selectedCheck.payableAmount &&
      selectedCheck.paidAmount.toFixed(2) ===
        selectedCheck.payableAmount.toFixed(2);

    return (
      <div className="bem-split-equally">
        <div />
        <BillCheckUI
          style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        >
          <BillCheckUI.Header>
            <BillCheckInfoUI.Title
              borderSide={'bottom'}
              title={'POS - Dine In'}
              description={`Table ${currentOrder.tableNumber}`}
            />
          </BillCheckUI.Header>
          <BillCheckUI.Body>
            <BillCheckInfoUI
              borderSide={'bottom'}
              title={'Placed:'}
              description={moment(currentOrder.date).calendar()}
            />
            {currentOrder.notes && (
              <BillCheckInfoUI
                borderSide={'bottom'}
                title={'Notes:'}
                description={currentOrder.notes}
              />
            )}

            {selectedCheck?.items?.length > 0 &&
              renderItems(selectedCheck, selectedItems)}
          </BillCheckUI.Body>
          <BillCheckUI.Footer>
            {totalDiscount > 0 ? (
              <BillCheckInfoUI
                title={'Discount'}
                description={`-${
                  storeConfig?.currency || '$'
                }${totalDiscount.toFixed(2)}`}
              />
            ) : null}

            {currentOrder?.serviceCharge > 0 ? (
              <BillCheckInfoUI
                title={'Service Charge'}
                description={`+${storeConfig?.currency || '$'}${(
                  Number(currentOrder?.serviceCharge?.toFixed(2)) /
                  checksWithItemsLength
                ).toFixed(2)}`}
              />
            ) : null}

            {Number(currentOrder?.publicHolidaySurcharge) > 0 ? (
              <BillCheckInfoUI
                title={'Public Holiday Surcharge'}
                description={`+${storeConfig?.currency || '$'}${(
                  getTotalSumOfCheck(selectedCheck, selectedItems) *
                  (Number(currentOrder?.publicHolidaySurchargePer) / 100)
                ).toFixed(2)}`}
              />
            ) : null}
            <BillCheckInfoUI.Total
              title={'Amount Paid'}
              description={`${storeConfig?.currency || '$'}${(
                selectedCheck?.paidAmount || 0
              ).toFixed(2)}`}
            />

            <BillCheckInfoUI.Total
              title={'Total'}
              description={`${
                storeConfig?.currency || '$'
              }${selectedCheck.payableAmount.toFixed(2)}`}
            />
            <BillCheckUI.ActionButtons>
              <ButtonUI
                styleType="success"
                sizeType="l"
                rounded="m"
                fullWidth
                disabled={checkPaid}
                onClick={() => {
                  dispatch(
                    updateCurrentOrder({
                      ...currentOrder,
                      billType: 'per_item',
                    })
                  );
                  togglePaymentModal(selectedCheck.payableAmount);
                }}
              >
                Pa{checkPaid ? `id` : `y`}
              </ButtonUI>
              <ButtonUI
                styleType="primary"
                sizeType="l"
                rounded="m"
                fullWidth
                onClick={handlePrintCommand}
                disabled={!printer?._id || isPrinterApiLoading}
                icon={
                  isPrinterApiLoading && (
                    <PosIconLoading
                      mainColor="white"
                      style={{
                        marginLeft: 16,
                        width: '18px',
                        height: '18px',
                      }}
                    />
                  )
                }
              >
                Print
              </ButtonUI>
            </BillCheckUI.ActionButtons>
          </BillCheckUI.Footer>
        </BillCheckUI>

        {/* START - for Spilt Per Item screen */}
        <ButtonUI
          sizeType="l"
          rounded="m"
          style={{
            height: 40,
            width: 170,
          }}
          disabled={checkPaid}
          onClick={() => {
            selectedCheck.items?.forEach((item) => toggleItem(item.itemId));
            setIsEditCheckInProcess(true);
            setIsEditCheck({
              value: true,
              checkNumber: selectedCheck.number,
            });
          }}
        >
          Add / Edit Items
        </ButtonUI>
        {/* END */}
      </div>
    );
  }

  if (!selectedCheck) return null;
  return (
    <div className="bem-split-per-item">
      <div className="bem-split-per-item__row">
        <h2 className="bem-split-per-item__title">
          Select Items for Check {+selectedCheck.number + 1}
        </h2>
        <ButtonGroupUI justifyContent="right">
          <ButtonUI
            icon={
              sharedItemIds.length > 0 ? (
                <span className="bem-split-per-item__share-items-count">
                  {sharedItemIds.length}
                </span>
              ) : null
            }
            fontSize="l"
            rounded="m"
            onClick={() => toggleShareItemsModal()}
          >
            Share Items
          </ButtonUI>
          {shareItemsOpen && (
            <ShareItemsModal
              guests={guests}
              shared={shared}
              setState={setState}
              currentOrder={currentOrder}
              toggleModal={toggleShareItemsModal}
              itemIdsAlreadyInChecks={itemIdsAlreadyInChecks}
              shareItems={(items) => {
                toggleShareItemsModal();
                setShared(items);
              }}
            />
          )}
        </ButtonGroupUI>
      </div>
      <BasePartUI additionalClass="bem-split-per-item__select-items">
        <BasePartUI.Body>
          <div className="bem-split-per-item__select-items-grid">
            {currentOrder.menuItems
              .filter((item) => !item?.isVoided)
              .flatMap(
                (i) =>
                  !i.isHalf &&
                  !i.isQuarter &&
                  Array(parseInt(i.quantity, 10))
                    .fill(0)
                    .map((_, index) => {
                      return Array(shared[itemHash(i, index)] || 1)
                        .fill(0)
                        .map((__, shareIndex) => {
                          const itemIdsInSelectedCheck =
                            selectedCheck?.items?.map((item) => item.itemId);

                          if (
                            isEditCheck.value &&
                            isEditCheck.checkNumber === selectedCheck.number &&
                            itemIdsInSelectedCheck.includes(
                              itemHash(i, index, shareIndex)
                            )
                          ) {
                            return (
                              <SelectItemUI
                                key={itemHash(i, index, shareIndex)}
                                value={i._id}
                                title={i.name}
                                instruction={getItemInstruction(i)}
                                price={`${storeConfig?.currency || '$'}${(
                                  i.payablePrice /
                                  ((shared[itemHash(i, index)] || 1) *
                                    i.quantity)
                                ).toFixed(2)}`}
                                checked={selectedItemIds.has(
                                  itemHash(i, index, shareIndex)
                                )}
                                onChange={() =>
                                  toggleItem(itemHash(i, index, shareIndex))
                                }
                              />
                            );
                          } else if (
                            !itemIdsAlreadyInChecks.includes(
                              itemHash(i, index, shareIndex)
                            )
                          ) {
                            return (
                              <SelectItemUI
                                key={itemHash(i, index, shareIndex)}
                                value={i._id}
                                title={i.name}
                                instruction={getItemInstruction(i)}
                                price={`${storeConfig?.currency || '$'}${(
                                  i.payablePrice /
                                  ((shared[itemHash(i, index)] || 1) *
                                    i.quantity)
                                ).toFixed(2)}`}
                                checked={selectedItemIds.has(
                                  itemHash(i, index, shareIndex)
                                )}
                                onChange={() =>
                                  toggleItem(itemHash(i, index, shareIndex))
                                }
                              />
                            );
                          }
                        });
                    })
              )}
            {values(
              currentOrder.menuItems
                .filter((i) => i.isQuarter && !i?.isVoided)
                .reduce(
                  (acc, k) => ({
                    ...acc,
                    [k.fourQuarterIndex]: [
                      ...(acc[k.fourQuarterIndex] || []),
                      k,
                    ],
                  }),
                  {}
                )
            ).map((item, index) => {
              const partedIndex =
                item.map((i) => i._id).join('+') + item[0].fourQuarterIndex + 1;
              const quantity = item.reduce(
                (quantity, item) => (quantity = quantity + item.quantity),
                0
              );
              const name = `${item.map((i) => i.name).join('/')} ${
                quantity > 1 &&
                (shared[partedIndex] === 1 || !shared[partedIndex])
                  ? `x ${quantity}`
                  : ''
              }`;
              const payablePrice = item.reduce(
                (acc, i) => (acc += i.payablePrice),
                0
              );
              return Array(shared[partedIndex] || 1)
                .fill(0)
                .map((_, shareIndex) => {
                  const itemIdsInSelectedCheck = selectedCheck?.items?.map(
                    (item) => item.itemId
                  );
                  if (
                    isEditCheck.value &&
                    isEditCheck.checkNumber === selectedCheck.number &&
                    itemIdsInSelectedCheck.includes(partedIndex + shareIndex)
                  ) {
                    return (
                      <SelectItemUI
                        key={partedIndex + shareIndex}
                        value={partedIndex + shareIndex}
                        title={name}
                        price={`${storeConfig?.currency || '$'}${(
                          payablePrice / (shared[partedIndex] || 1)
                        ).toFixed(2)}`}
                        checked={selectedItemIds.has(partedIndex + shareIndex)}
                        onChange={() => toggleItem(partedIndex + shareIndex)}
                      />
                    );
                  } else if (
                    !itemIdsAlreadyInChecks.includes(partedIndex + shareIndex)
                  ) {
                    return (
                      <SelectItemUI
                        key={partedIndex + shareIndex}
                        value={partedIndex + shareIndex}
                        title={name}
                        price={`${storeConfig?.currency || '$'}${(
                          payablePrice / (shared[partedIndex] || 1)
                        ).toFixed(2)}`}
                        checked={selectedItemIds.has(partedIndex + shareIndex)}
                        onChange={() => toggleItem(partedIndex + shareIndex)}
                      />
                    );
                  }
                });
            })}
            {values(
              currentOrder.menuItems
                .filter((i) => i.isHalf && !i?.isVoided)
                .reduce(
                  (acc, k) => ({
                    ...acc,
                    [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                  }),
                  {}
                )
            ).map((item, index) => {
              const partedIndex =
                item.map((i) => i._id).join('+') + item[0].halfIndex + 1;
              const quantity = item.reduce(
                (quantity, item) => (quantity = quantity + item.quantity),
                0
              );
              const name = `${item.map((i) => i.name).join('/')} ${
                quantity > 1 &&
                (shared[partedIndex] === 1 || !shared[partedIndex])
                  ? `x ${quantity}`
                  : ''
              }`;
              const payablePrice = item.reduce(
                (acc, i) => (acc += i.payablePrice),
                0
              );

              return Array(shared[partedIndex] || 1)
                .fill(0)
                .map((_, shareIndex) => {
                  const itemIdsInSelectedCheck = selectedCheck?.items?.map(
                    (item) => item.itemId
                  );
                  if (
                    isEditCheck.value &&
                    isEditCheck.checkNumber === selectedCheck.number &&
                    itemIdsInSelectedCheck.includes(partedIndex + shareIndex)
                  ) {
                    return (
                      <SelectItemUI
                        key={partedIndex + shareIndex}
                        value={partedIndex + shareIndex}
                        title={name}
                        price={`${storeConfig?.currency || '$'}${(
                          payablePrice / (shared[partedIndex] || 1)
                        ).toFixed(2)}`}
                        checked={selectedItemIds.has(partedIndex + shareIndex)}
                        onChange={() => toggleItem(partedIndex + shareIndex)}
                      />
                    );
                  } else if (
                    !itemIdsAlreadyInChecks.includes(partedIndex + shareIndex)
                  ) {
                    return (
                      <SelectItemUI
                        key={partedIndex + shareIndex}
                        value={partedIndex + shareIndex}
                        title={name}
                        price={`${storeConfig?.currency || '$'}${(
                          payablePrice / (shared[partedIndex] || 1)
                        ).toFixed(2)}`}
                        checked={selectedItemIds.has(partedIndex + shareIndex)}
                        onChange={() => toggleItem(partedIndex + shareIndex)}
                      />
                    );
                  }
                });
            })}
          </div>
        </BasePartUI.Body>
        <BasePartUI.Footer>
          <ButtonUI
            style={{ height: 68 }}
            sizeType="xl"
            fullWidth
            rounded="none"
            icon={
              isloading && (
                <PosIconLoading
                  mainColor="white"
                  style={{
                    marginLeft: 16,
                    width: '18px',
                    height: '18px',
                  }}
                />
              )
            }
            onClick={async () => {
              setIsLoading(true);
              let finalChecks = [];
              const newChecks = checks.map((i) => {
                let publicHolidaySurcharge = 0;
                let serviceCharge = 0;
                if (currentOrder?.publicHolidaySurcharge > 0) {
                  publicHolidaySurcharge =
                    (Number(
                      Number(parseFloat(selectedItemsPrice.toFixed(2))).toFixed(
                        2
                      )
                    ) * Number(currentOrder?.publicHolidaySurchargePer) || 0) /
                    100;
                }

                if (currentOrder?.serviceCharge > 0) {
                  serviceCharge =
                    Number(currentOrder?.serviceCharge) / checks.length;
                }

                if (i.number === selectedCheck.number) {
                  return {
                    ...i,
                    payableAmount: Number(
                      (
                        Number(
                          Number(
                            parseFloat(selectedItemsPrice.toFixed(2))
                          ).toFixed(2)
                        ) +
                        publicHolidaySurcharge +
                        serviceCharge
                      ).toFixed(2)
                    ),
                    items: Array.from(selectedItemIds).map((hash) => {
                      if (hash.includes('+')) {
                        const { id, parts, partedIndex } = dehash(hash);
                        const partIds = id.split('+');
                        const inx = `${
                          parts === 2 ? 'half' : 'fourQuarter'
                        }Index`;

                        const itemParts = partIds.map((itemId) => {
                          const item = currentOrder.menuItems.find((m) => {
                            return (
                              // m.quantity === 1 / parts &&
                              m[inx] === partedIndex - 1 && m._id === itemId
                            );
                          });

                          return item;
                        });

                        return {
                          itemId: hash,
                          orderIndexes: itemParts.map(
                            (item) => item.orderIndex
                          ),
                          name: itemParts.map((i) => i.name).join('/'),
                          share: 1 / (shared[hash.slice(0, -1)] || 1),
                        };
                      }
                      const menuItem = currentOrder.menuItems.find(
                        itemPreq(hash)
                      );

                      return {
                        itemId: hash,
                        name: menuItem.name,
                        orderIndexes: [menuItem.orderIndex],
                        share:
                          1 /
                          (shared[
                            hash
                              .slice(0, getSliceOffset(menuItem.orderIndex))
                              .concat(
                                hash.substring(
                                  hash.length -
                                    getOrderIndexHashLength(
                                      menuItem.orderIndex
                                    ),
                                  hash.length
                                )
                              )
                          ] || 1),
                      };
                    }),
                  };
                } else {
                  if (
                    i?.items &&
                    i.items?.length > 0 &&
                    i.paidAmount !== i.payableAmount
                  ) {
                    let itemDetails = {};
                    let updatedPayableAmount = 0;

                    i?.items?.forEach((item) => {
                      if (item.itemId.includes('+')) {
                        itemDetails = halfhalfItemDetail({
                          hash: item.itemId,
                          ...item,
                        });

                        updatedPayableAmount =
                          updatedPayableAmount +
                          Number(
                            (
                              (itemDetails.payablePrice -
                                (itemDetails.totalDiscountAmt || 0)) /
                              itemDetails.quantity
                            ).toFixed(2)
                          );
                      } else {
                        const { id, orderIndex } = dehash(item.itemId);

                        itemDetails = currentOrder.menuItems.find(
                          (menuItem) =>
                            menuItem.orderIndex === Number(orderIndex)
                        );

                        updatedPayableAmount =
                          updatedPayableAmount +
                          Number(
                            (
                              ((itemDetails.payablePrice -
                                (itemDetails.totalDiscountAmt || 0)) /
                                itemDetails.quantity) *
                              item.share
                            ).toFixed(2)
                          );
                      }
                    });

                    return {
                      ...i,
                      payableAmount: Number(
                        (
                          Number(updatedPayableAmount.toFixed(2)) +
                          Number(updatedPayableAmount.toFixed(2)) *
                            ((currentOrder?.publicHolidaySurchargePer || 0) /
                              100) +
                          Number(serviceCharge.toFixed(2))
                        ).toFixed(2)
                      ),
                    };
                  }
                }
                return i;
              });

              if (
                totalItemsCount >
                itemIdsAlreadyInChecks.length + selectedItemIds.size
              ) {
                const next = {
                  number: `${newChecks.length}`,
                  numGuests: 1,
                  payableAmount: 0,
                  payments: [],
                };

                let empytChecksCount = 0;

                newChecks.forEach((check) => {
                  if (!check?.items || check?.items?.length === 0) {
                    empytChecksCount = empytChecksCount + 1;
                  }
                });

                if (empytChecksCount >= 1) {
                  finalChecks = newChecks;
                } else {
                  finalChecks = [...newChecks, next];

                  // setTimeout(() => {
                  //   setSelectedCheck(newChecks.length);
                  // }, 0);
                }
              } else {
                const itemsInCheck =
                  itemIdsAlreadyInChecks.length +
                  selectedItemIds.size -
                  (selectedCheck?.items?.length || 0);

                if (totalItemsCount === itemsInCheck) {
                  const updateChecks = newChecks.filter(
                    (check) => check?.items?.length > 0
                  );
                  const totalPayableAmount = Number(currentOrder.payableAmount);

                  const totalPayableAmountInChecks = checks.reduce(
                    (total, check) =>
                      (total = total + check?.payableAmount || 0),
                    0
                  );
                  if (
                    Number(totalPayableAmountInChecks.toFixed(2)) +
                      Number(selectedItemsPrice.toFixed(2)) +
                      (totalPayableAmountInChecks + selectedItemsPrice) *
                        ((currentOrder?.publicHolidaySurchargePer || 0) /
                          100) !==
                    totalPayableAmount
                  ) {
                    let difference = 0;
                    if (
                      Number(totalPayableAmountInChecks.toFixed(2)) !==
                      Number(totalPayableAmount.toFixed(2))
                    ) {
                      difference = Number(
                        (
                          Number(totalPayableAmountInChecks.toFixed(2)) +
                          Number(selectedItemsPrice.toFixed(2)) +
                          Number(
                            selectedItemsPrice *
                              ((currentOrder?.publicHolidaySurchargePer || 0) /
                                100)
                          ) -
                          Number(totalPayableAmount.toFixed(2))
                        ).toFixed(2)
                      );
                    }
                    const firstUnpaidInQueue = checks.find(
                      (check) =>
                        check.paidAmount === 0 || check.paidAmount === undefined
                    );

                    const updateDeviationChecks = updateChecks.map((check) => {
                      if (firstUnpaidInQueue.number === check.number) {
                        if (difference > 0 && difference <= 0.9) {
                          return {
                            ...check,
                            payableAmount: Number(
                              (
                                Math.floor(
                                  (check.payableAmount - difference) * 100
                                ) / 100
                              ).toFixed(2)
                            ),
                          };
                        } else if (difference < 0 && difference >= -0.9) {
                          return {
                            ...check,
                            payableAmount: Number(
                              (
                                Math.floor(
                                  (check.payableAmount + Math.abs(difference)) *
                                    100
                                ) / 100
                              ).toFixed(2)
                            ),
                          };
                        }
                      }
                      return check;
                    });

                    finalChecks = updateDeviationChecks;
                  } else {
                    finalChecks = updateChecks;
                  }
                } else {
                  finalChecks = newChecks;
                }
              }

              // const finalChecksWithItems = finalChecks.filter(
              //   (check) => check.payableAmount > 0
              // );
              const splitPerItemPaymentPayload = {
                checks: finalChecks,
                numChecks: finalChecks.length || 0,
                orderId: currentOrder._id,
              };

              // check if you have to delete any empty checks

              await Promise.all(
                checks?.map(async (check) => {
                  if (!finalChecks.map((c) => c.id).includes(check.id)) {
                    return await fetch(
                      `${API_URL}/orders/v1/splitPayments/checks?orderId=${currentOrder._id}&checkId=${check.id}`,
                      {
                        method: 'delete',
                        headers: {
                          Authorization: `Bearer ${accessToken}`,
                          'Access-Control-Allow-Origin': '*',
                          'Content-Type': 'application/json',
                        },
                      }
                    );
                  }
                  return true;
                })
              );

              const createCheckApi = await createSplitPerItemChecks(
                splitPerItemPaymentPayload,
                'per_item',
                accessToken,
                currentOrder
              );
              if (createCheckApi?.errors?.length === 0) {
                setChecks(createCheckApi?.data?.splitPayment?.checks || []);
                toggleItem(`clear`);
                setIsEditCheck({ checkNumber: -1, value: false });
                setIsEditCheckInProcess(false);
                setIsLoading(false);
                setState({ concluded: true });
              }
            }}
            disabled={selectedItemsPrice === 0 || isloading}
          >
            Add Items to Check {+selectedCheck.number + 1}{' '}
            {selectedItemsPrice > 0 &&
              `- ${storeConfig?.currency || '$'}${selectedItemsPrice.toFixed(
                2
              )}`}
          </ButtonUI>
        </BasePartUI.Footer>
      </BasePartUI>
    </div>
  );
};

export default SplitPerItem;
