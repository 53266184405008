import React from 'react';

const PosUberIcon = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 1000 137"
    enable-background="new 0 0 1000 137"
    style={{
      width: width,
    }}
  >
    <g>
      <g>
        <path
          fill="#FFFFFF"
          d="M621.45,0.01h19.26c0,0,0,66.62,0,81.33s10.05,33.01,30.74,33.01s32.77-16.63,32.77-30.74s0-83.6,0-83.6    h19.5v128.93h-19.26V117.1c0,0-12.08,14.11-34.09,14.11c-22.01,0-48.92-17.7-48.92-47.12S621.45,0.01,621.45,0.01z"
        />
        <path
          fill="#FFFFFF"
          d="M791.53,32.9c-13.4,0-25.48,5.38-34.33,13.99V0.01h-18.78v128.81h18.78V117.1    c8.85,8.61,20.93,13.99,34.33,13.99c27.15,0,49.04-22.01,49.04-49.16C840.69,54.91,818.68,32.9,791.53,32.9z M789.14,114.59    c-15.67,0-28.71-11-31.82-25.72c-0.48-2.15-0.72-4.43-0.72-6.82s0.24-4.54,0.72-6.7c3.11-14.71,16.15-25.72,31.82-25.72    c17.94,0,32.53,14.59,32.53,32.53C821.67,100,807.08,114.59,789.14,114.59z"
        />
        <path
          fill="#FFFFFF"
          d="M897.26,114.59c-16.63,0-30.26-11.72-31.93-26.55h77.5c0-1.91-0.12-3.95-0.12-5.98    c0-3.23-0.24-6.34-0.84-9.45c-4.07-22.61-22.37-39.59-46.29-39.59c-27.15,0-49.04,22.01-49.04,49.04    c0,27.15,22.01,49.16,49.04,49.16c16.27,0,32.89-7.77,41.98-19.97l-13.28-10.53C918.79,108.97,908.74,114.59,897.26,114.59z     M894.75,49.52c11.12,0,20.93,6.22,25.95,15.31c1.32,2.39,2.27,5.02,2.87,7.77h-57.65C868.91,59.33,880.63,49.52,894.75,49.52z"
        />
        <path
          fill="#FFFFFF"
          d="M1000,33.74V51.2h-8.97c-9.69,0-19.5,8.49-19.5,21.29v56.33h-19.02V34.81h19.02v10.76    c4.54-6.7,12.8-11.72,22.61-11.72C997.61,33.74,1000,33.74,1000,33.74z"
        />
      </g>
      <g>
        <path
          fill="#848484"
          d="M0,116.13V45.19h27.96c4.92,0,8.68,0.23,11.27,0.68c3.64,0.58,6.69,1.69,9.15,3.31    c2.46,1.63,4.44,3.91,5.94,6.85c1.5,2.93,2.25,6.16,2.25,9.68c0,6.03-2.01,11.14-6.02,15.32c-4.01,4.18-11.25,6.27-21.73,6.27    H9.81v28.84H0z M9.81,78.92h19.16c6.34,0,10.83-1.13,13.5-3.39c2.66-2.26,3.99-5.44,3.99-9.53c0-2.97-0.78-5.51-2.35-7.62    c-1.57-2.11-3.63-3.51-6.19-4.19c-1.65-0.42-4.7-0.63-9.15-0.63H9.81V78.92z"
        />
        <path
          fill="#848484"
          d="M64.5,90.43c0-9.52,2.76-16.57,8.29-21.15c4.62-3.81,10.24-5.71,16.88-5.71c7.38,0,13.41,2.32,18.1,6.95    s7.03,11.03,7.03,19.19c0,6.61-1.04,11.82-3.11,15.61c-2.07,3.79-5.09,6.74-9.05,8.83c-3.96,2.09-8.28,3.14-12.96,3.14    c-7.52,0-13.59-2.3-18.23-6.92C66.82,105.76,64.5,99.11,64.5,90.43z M73.86,90.43c0,6.58,1.5,11.51,4.5,14.79    c3,3.27,6.78,4.91,11.32,4.91c4.51,0,8.27-1.65,11.27-4.94c3-3.29,4.5-8.31,4.5-15.05c0-6.36-1.51-11.17-4.53-14.44    c-3.02-3.27-6.76-4.91-11.25-4.91c-4.55,0-8.33,1.63-11.32,4.89C75.35,78.94,73.86,83.85,73.86,90.43z"
        />
        <path
          fill="#848484"
          d="M135.38,116.13l-16.43-51.39h9.4l8.54,29.66l3.18,11.04c0.13-0.55,1.06-4.08,2.78-10.6l8.54-30.1h9.35    l8.04,29.81l2.68,9.82l3.08-9.92l9.2-29.71h8.85l-16.78,51.39h-9.46l-8.54-30.78l-2.07-8.76l-10.87,39.54H135.38z"
        />
        <path
          fill="#848484"
          d="M236.98,99.58l9.4,1.11c-1.48,5.26-4.23,9.34-8.24,12.24c-4.01,2.91-9.13,4.36-15.37,4.36    c-7.85,0-14.08-2.31-18.68-6.94c-4.6-4.63-6.9-11.12-6.9-19.48c0-8.64,2.33-15.36,6.98-20.13c4.65-4.78,10.68-7.16,18.1-7.16    c7.17,0,13.04,2.34,17.59,7.02c4.55,4.68,6.83,11.26,6.83,19.75c0,0.51-0.02,1.29-0.05,2.32H206.6c0.34,5.65,2,9.97,5.01,12.97    c3,3,6.74,4.5,11.22,4.5c3.34,0,6.19-0.84,8.55-2.52C233.73,105.94,235.6,103.26,236.98,99.58z M207.11,85.5h29.97    c-0.4-4.32-1.55-7.57-3.44-9.73c-2.9-3.35-6.66-5.03-11.27-5.03c-4.18,0-7.69,1.34-10.54,4.02    C208.98,77.44,207.41,81.01,207.11,85.5z"
        />
        <path
          fill="#848484"
          d="M257.71,116.13V64.74h8.19v7.79c2.09-3.64,4.02-6.05,5.79-7.21c1.77-1.16,3.71-1.74,5.84-1.74    c3.07,0,6.19,0.94,9.35,2.81l-3.13,8.08c-2.23-1.26-4.45-1.89-6.68-1.89c-1.99,0-3.77,0.57-5.36,1.72    c-1.58,1.15-2.71,2.74-3.39,4.77c-1.01,3.1-1.52,6.49-1.52,10.16v26.91H257.71z"
        />
        <path
          fill="#848484"
          d="M329.04,99.58l9.4,1.11c-1.48,5.26-4.23,9.34-8.24,12.24c-4.01,2.91-9.13,4.36-15.37,4.36    c-7.85,0-14.08-2.31-18.68-6.94c-4.6-4.63-6.9-11.12-6.9-19.48c0-8.64,2.33-15.36,6.98-20.13c4.65-4.78,10.68-7.16,18.1-7.16    c7.17,0,13.04,2.34,17.59,7.02c4.55,4.68,6.83,11.26,6.83,19.75c0,0.51-0.02,1.29-0.05,2.32h-40.04c0.34,5.65,2,9.97,5.01,12.97    c3,3,6.74,4.5,11.22,4.5c3.34,0,6.19-0.84,8.55-2.52C325.78,105.94,327.66,103.26,329.04,99.58z M299.16,85.5h29.97    c-0.4-4.32-1.55-7.57-3.44-9.73c-2.9-3.35-6.66-5.03-11.27-5.03c-4.18,0-7.69,1.34-10.54,4.02    C301.04,77.44,299.46,81.01,299.16,85.5z"
        />
        <path
          fill="#848484"
          d="M384.68,116.13v-6.49c-3.4,5.1-8.4,7.65-15.01,7.65c-4.28,0-8.22-1.13-11.8-3.39    c-3.59-2.26-6.37-5.41-8.34-9.46c-1.97-4.05-2.95-8.7-2.95-13.96c0-5.13,0.89-9.78,2.68-13.96c1.79-4.18,4.47-7.38,8.04-9.61    c3.57-2.23,7.57-3.34,11.98-3.34c3.24,0,6.11,0.65,8.64,1.96c2.53,1.31,4.58,3.01,6.17,5.11V45.19h9.05v70.95H384.68z     M355.92,90.49c0,6.58,1.45,11.5,4.35,14.76c2.9,3.26,6.32,4.89,10.26,4.89c3.98,0,7.35-1.56,10.14-4.67    c2.78-3.11,4.17-7.86,4.17-14.25c0-7.03-1.42-12.2-4.25-15.49s-6.32-4.94-10.46-4.94c-4.05,0-7.42,1.58-10.14,4.74    C357.28,78.69,355.92,83.68,355.92,90.49z"
        />
        <path
          fill="#848484"
          d="M444.59,116.13h-8.44V45.19h9.1V70.5c3.84-4.61,8.75-6.92,14.71-6.92c3.3,0,6.42,0.64,9.38,1.91    c2.95,1.28,5.38,3.06,7.28,5.37c1.9,2.3,3.4,5.09,4.47,8.35c1.08,3.26,1.62,6.74,1.62,10.45c0,8.81-2.27,15.61-6.82,20.42    c-4.55,4.81-10.01,7.21-16.39,7.21c-6.34,0-11.3-2.53-14.91-7.59V116.13z M444.5,90.05c0,6.16,0.87,10.61,2.62,13.36    c2.87,4.48,6.75,6.73,11.63,6.73c3.98,0,7.41-1.66,10.31-4.96c2.9-3.31,4.35-8.24,4.35-14.79c0-6.71-1.39-11.66-4.17-14.86    c-2.78-3.2-6.14-4.79-10.08-4.79c-3.98,0-7.42,1.66-10.31,4.96C445.94,79.01,444.5,83.79,444.5,90.05z"
        />
        <path
          fill="#848484"
          d="M493.38,135.93l-1.01-8.18c1.99,0.51,3.72,0.78,5.2,0.78c2.03,0,3.64-0.32,4.85-0.97s2.21-1.55,2.99-2.71    c0.57-0.87,1.5-3.03,2.78-6.49c0.17-0.48,0.44-1.19,0.81-2.13l-20.37-51.49h9.8L509.6,94.5c1.45,3.77,2.74,7.74,3.89,11.9    c1.05-4,2.3-7.9,3.75-11.71l11.47-29.96h9.1L517.39,117c-2.19,5.65-3.9,9.53-5.11,11.66c-1.62,2.87-3.47,4.98-5.56,6.32    c-2.09,1.34-4.59,2.01-7.48,2.01C497.49,136.99,495.53,136.64,493.38,135.93z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default PosUberIcon;
