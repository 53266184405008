import { capitalizeString } from './utils';

/**
 * type = either full or half and half
 * returns in the shape of
 * {
 *    full: array of single order objects,
 *    halfAndHalf: {
 *       [indexNo]: array of half and half menu items of a half an half order
 *       [anotherIndexNo]: array of half and half menu items of a half another half order
 *    }
 * }
 */
export function getSegregatedMenuItemsByType(menuItems = []) {
  const halfAndHalfSegregatedOrders = menuItems.reduce(
    (acc, currentVal) => {
      if (!currentVal.isHalf && !currentVal.isQuarter) {
        acc.full.push(currentVal);
        return acc;
      }

      if (!acc.halfAndHalf[currentVal.halfIndex]) {
        acc.halfAndHalf[currentVal.halfIndex] = [];
      }

      acc.halfAndHalf[currentVal.halfIndex].push(currentVal);

      if (!acc.fourQuarters[currentVal.fourQuarterIndex]) {
        acc.fourQuarters[currentVal.fourQuarterIndex] = [];
      }

      acc.fourQuarters[currentVal.fourQuarterIndex].push(currentVal);

      return acc;
    },
    {
      full: [],
      halfAndHalf: {},
      fourQuarters: {},
    }
  );

  return halfAndHalfSegregatedOrders;
}

/**
 * input: [
 *  menuItemObj,
 *  menuItemObj (h1/h1), menuItemObj(h1/h1),
 *  menuItemObj,
 *  menuItemObj
 *  menuItemObj (h2/h2), menuItemObj(h2/h2),
 * ]
 *
 * output: [
 *  menuItemObj,
 *  [ menuItemObj (h1/h1), menuItemObj(h1/h1) ], // --------> notice this
 *  menuItemObj,
 *  menuItemObj
 *  [ menuItemObj (h2/h2), menuItemObj(h2/h2) ], // --------> notice this
 * ]
 *
 */
export function getSegregatedMenuItemTypesWithInsertOrderIntact(
  menuItems = []
) {
  const retVal = menuItems.reduce((acc, currentVal) => {
    const isHalfAndHalfItem = currentVal.isHalf;
    const isQuartersItem = currentVal.isQuarter;
    const isSimpleItem = !(isHalfAndHalfItem || isQuartersItem);

    if (isSimpleItem) {
      acc.push(currentVal);
      return acc;
    }

    if (isHalfAndHalfItem) {
      const fn = (item) =>
        Array.isArray(item) &&
        item.every(
          (halfAndHalfItem) =>
            halfAndHalfItem.halfIndex === currentVal.halfIndex
        );

      const incomingHalfHalfItemHasPreviousHalfItemAlreadyAddedInTheListAsATuple =
        acc.find(fn);

      if (
        !incomingHalfHalfItemHasPreviousHalfItemAlreadyAddedInTheListAsATuple
      ) {
        acc.push([currentVal]);
        return acc;
      }

      const indexToReplace = acc.findIndex(fn);
      const newAcc = acc.slice(0, indexToReplace);

      newAcc.push(
        incomingHalfHalfItemHasPreviousHalfItemAlreadyAddedInTheListAsATuple.concat(
          [currentVal]
        )
      );

      newAcc.concat(acc.slice(indexToReplace + 1, acc.length));

      return newAcc;
    }

    if (isQuartersItem) {
      const fn = (item) =>
        Array.isArray(item) &&
        item.every(
          (quarterItem) =>
            quarterItem.fourQuarterIndex === currentVal.fourQuarterIndex
        );

      const incomingFourQuartersItemHasPreviousHalfItemAlreadyAddedInTheListAsATuple =
        acc.find(fn);

      if (
        !incomingFourQuartersItemHasPreviousHalfItemAlreadyAddedInTheListAsATuple
      ) {
        acc.push([currentVal]);
        return acc;
      }

      const indexToReplace = acc.findIndex(fn);
      const newAcc = acc.slice(0, indexToReplace);

      newAcc.push(
        incomingFourQuartersItemHasPreviousHalfItemAlreadyAddedInTheListAsATuple.concat(
          [currentVal]
        )
      );

      newAcc.concat(acc.slice(indexToReplace + 1, acc.length));

      return newAcc;
    }

    return acc;
  }, []);

  return retVal;
}

// ----------------------------------
export function getHalfAndHalfTitle(halfAndHalfMenuItems = []) {
  return halfAndHalfMenuItems.reduce((acc, currentVal, index) => {
    const isLast = index === halfAndHalfMenuItems.length - 1;
    const isFirst = index === 0;
    const selectedSizes = currentVal.selectedSizes?.filter((f) => f.quantity);
    const selectedQuantity = isLast
      ? halfAndHalfMenuItems?.reduce((prevQuantity, currentItem) => {
          return (
            prevQuantity +
            (currentItem?.selectedSizes?.reduce(
              (prevItemQuantity, prevSize) => {
                return prevItemQuantity + (prevSize?.quantity || 0);
              },
              0
            ) || 0)
          );
        }, 0)
      : 0;

    const { title, size } = getTitleForMenuItem(currentVal.name, selectedSizes);
    const quantityText = isLast
      ? ` - ${size} ${selectedQuantity > 1 ? ` X${selectedQuantity}` : ''}`
      : '';
    const separator = isFirst ? '' : ' / ';

    return `${acc}${separator}${title}${quantityText}`;
  }, '');
}

export function getUniqueExtraIngredientsForHalfHalfItems(
  halfOrderWithEachHalfInTuple
) {
  const uniqueCombinedExtraIngredientsListFromBothHalf = Array.from(
    new Set(
      halfOrderWithEachHalfInTuple.reduce((collector, eachHalfAndHalfObj) => {
        let extraIngredientsForThisPart = [];

        eachHalfAndHalfObj?.selectedExtraIngredients?.forEach(
          (extraIngredient) => {
            extraIngredientsForThisPart.push(
              extraIngredient.name || extraIngredient.ingredientName
            );
          }
        );

        return collector.concat(extraIngredientsForThisPart);
      }, [])
    )
  );

  return uniqueCombinedExtraIngredientsListFromBothHalf;
}

export function getUniqueRemovedExtraIngredientsForHalfHalfItems(
  halfOrderWithEachHalfInTuple
) {
  const uniqueCombinedRemovedExtraIngredientsListFromBothHalf = Array.from(
    new Set(
      halfOrderWithEachHalfInTuple.reduce((collector, eachHalfAndHalfObj) => {
        let removedIngredientsForThisPart = [];

        eachHalfAndHalfObj?.removedIngredients?.forEach((removedIngredient) => {
          removedIngredientsForThisPart.push(
            removedIngredient.name || removedIngredient.ingredientName
          );
        });
        return collector.concat(removedIngredientsForThisPart);
      }, [])
    )
  );

  return uniqueCombinedRemovedExtraIngredientsListFromBothHalf;
}

export function getHalfHalfImagesData(halfOrderWithEachHalfInTuple) {
  return halfOrderWithEachHalfInTuple
    .map((item) => ({
      src: item.urlS3,
      alt: item.name,
    }))
    .reverse();
}

// --------------------------------------

function getTitleForMenuItem(name, selectedSizes) {
  const title = name;
  const size = selectedSizes.length
    ? selectedSizes
        .map(
          (m) =>
            `${
              m.variants?.length
                ? m.name +
                  '(' +
                  m.variants
                    .map((v) =>
                      v.name
                        .split(' ')
                        .map((vname) => capitalizeString(vname))
                        .join(' ')
                    )
                    .join(',') +
                  ')'
                : m.name
            }`
        )
        .join(',')
    : '';

  const sizeFirstCharUppercased = size.charAt(0).toUpperCase() + size.slice(1);

  const quantity = selectedSizes.filter((size) => size.quantity > 1).length
    ? ' x' + selectedSizes.find((size) => size.quantity).quantity
    : '';

  return {
    title,
    quantity,
    size: sizeFirstCharUppercased,
  };
}

export const getHalfHalfVariants = (halfHalfMenuItems) => {
  const variantsAvailable = {};
  halfHalfMenuItems.forEach((menuItem) => {
    const { selectedSizes } = menuItem;
    selectedSizes.forEach((selectedSize) => {
      const { name: sizeName, variants } = selectedSize;
      variants &&
        variants?.length > 0 &&
        variants.forEach((variant) => {
          const { name: variantName, isSelected, _id } = variant;
          if (isSelected) {
            if (!variantsAvailable[_id]) {
              const variantDisplayName = variantName
                .split(' ')
                .map((preVariant) => capitalizeString(preVariant))
                .join(' ');
              variantsAvailable[_id] = {
                variantNameToCompare: variantName,
                variantDisplayName,
                sizes: [sizeName?.toLowerCase()],
              };
            } else if (
              !variantsAvailable[_id].sizes.includes(sizeName?.toLowerCase())
            ) {
              variantsAvailable[_id].sizes.push(sizeName?.toLowerCase());
            }
          }
        });
    });
  });
  return variantsAvailable;
};
