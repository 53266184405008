import React from 'react';
import { useLocation } from 'react-router';
import { PresentationalBackButton } from '../../BackBtn';
import { HeaderUI, ButtonUI, ButtonGroupUI } from '../../../UI/components';
import { CashRegisterIcon as IconCashRegister } from 'nexticons/outline';
import { LogoButton } from '../../LogoButton';

export const MoneyCountHeader = ({ backLink, viewDeliveriesURL }) => {
  const { pathname } = useLocation();

  return (
    <HeaderUI>
      {backLink ? <PresentationalBackButton link={backLink} /> : <LogoButton />}
      {pathname.includes('/drivers') && viewDeliveriesURL && (
        <ButtonGroupUI gap="xl">
          <ButtonUI
            rounded="none"
            styleType="outline-success"
            link={viewDeliveriesURL}
            iconPosition="left"
            icon={<IconCashRegister width={29} />}
          >
            View Deliveries
          </ButtonUI>
        </ButtonGroupUI>
      )}
    </HeaderUI>
  );
};
