import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import DineInImage from '../../assets/img/home-new/Chairs_Icon_big.png';
import DeliveryImage from '../../assets/img/home-new/delivery-icon_big.png';
import PickupImage from '../../assets/img/home-new/Pickup_img_big.png';
import WalkInImage from '../../assets/img/home-new/Walk_in_Person_big.png';
import DriveThruImage from '../../assets/img/home/drive-thru.png';
import KioskImage from '../../assets/img/home/Kiosk.png';
import ShiftsImage from '../../assets/img/home/shifts.png';

import StaffPinModal from '../../components/StaffPinModal';

import { setState } from 'reducers/order';
import {
  activeStaffMemberIsOnABreakSelector,
  activeStaffMemberOpenShiftsSelector,
  isAuthDisabledForPOSSelector,
  orderSetupSelector,
  refreshToUpdatePosVersionSelector,
  storeConfigSelector,
} from '../../pages/Dashboard/selectors';
import dayjs from 'dayjs';

import {
  authenticateStaff,
  resetCurrentFourQuarterData,
  resetCurrentHalfHalfData,
  resetCurrentOrder,
  resetCurrentSpecialsItem,
  setGoToRouteAfterAuth,
  setOrderPayments,
  setSplitOrderSessionId,
  setSplitOrderId,
  toggleBlurEffect,
  storeAccessToken,
} from '../Dashboard/action';

import DateTimeBox from '../../components/DateTimeBox';
import WeatherBox from '../../components/WeatherBox/WeatherBox';
import { homeSubMenu } from '../../constants/home';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import AccessDeniedModal from '../../modals/AccessDeniedModal';
import ClockInClockOutModal from '../../modals/ClockInClockOutModal';
import { ShiftsEnum } from '../Shifts/constants';

import CobrowseIO from 'cobrowse-sdk-js';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
import { drive_thru_order_route, kiosk_route } from 'constants/routesConsts';
import * as ac from 'reducers/modals';
import { updateCurrentOrderWithOrderEngine } from 'pages/Dashboard/thunks';
import AllIncomingCallNotification from 'components/IncomingCallNotification/AllIncomingCallNotification';
import { _firestore, _auth } from '../../firebase';
import axios from 'axios';
import { ModalPortal } from 'components';
import { WindCaveTransactionModal } from './WindCaveTransactionModal';

CobrowseIO.license = nextOrderConfig.coBrowseLicenseKey;

const Home = ({ authRequired = true }) => {
  const [incompleteOrder, setIncompleteOrder] = useState({});
  const [isApiLoading, setIsApiLoading] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);
  const isAuthDisabledForPOS = useSelector(isAuthDisabledForPOSSelector);
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const customerDetails = useSelector(
    (state) => state.dashboardReducer?.acceptedCall?.customerDetails
  );

  const closeModal = useCallback(
    () => dispatch(ac.closeModal('staffPinModal')),
    [dispatch]
  );
  const openModal = useCallback(
    () => dispatch(ac.openModal('staffPinModal')),
    [dispatch]
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [wiggleEffect, setWiggleEffect] = useState(false);

  const [accessDenied, setShowAccessDenied] = useState(false);
  const [isClockedIn, setIsClockedIn] = useState(false);
  const [showClockInOutModal, setShowClockInOutModal] = useState(false);
  const [isOnBreak, setIsOnBreak] = useState(false);
  const [openWindCaveTerminalModal, setOpenWindCaveTerminalModal] =
    useState(false);

  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken || ''
  );
  const isStaffAuthorised = useSelector(
    (state) => state.dashboardReducer.isStaffAuthorised
  );
  const goToRouteAfterAuth = useSelector(
    (state) => state.dashboardReducer.goToRouteAfterAuth
  );

  const activeStaffMemberOpenShifts = useSelector(
    activeStaffMemberOpenShiftsSelector
  );
  const activeStaffMemberIsOnABreak = useSelector(
    activeStaffMemberIsOnABreakSelector
  );
  const orderSetup = useSelector(orderSetupSelector);

  const refreshToUpdatePosVersion = useSelector(
    refreshToUpdatePosVersionSelector
  );

  const { toggleKeyboard, onInitInput } = useVirtualKeyboardContext();

  const windCaveTransactionReference = localStorage.getItem(
    'transactionReference'
  );

  const getAccesToken = async () => {
    const response = await _auth.currentUser.getIdToken(true);
    if (response) {
      dispatch(storeAccessToken(response));
    }
  };

  const getWindcaveTransactionDetails = async (transactionReference) => {
    setIsApiLoading(true);
    const orderData = await axios.get(
      `${nextOrderConfig.baseUrl}/windcave/v1/payment/getOrder?transactionId=${transactionReference}`
    );
    if (orderData?.data?.data?.id) {
      setIncompleteOrder({ ...orderData?.data?.data });
    }

    setIsApiLoading(false);
  };

  useEffect(() => {
    !accessToken && getAccesToken();
    toggleKeyboard(false);
    dispatch(toggleBlurEffect(false));
    dispatch(authenticateStaff(false));
    dispatch(setSplitOrderSessionId(''));
    dispatch(setSplitOrderId(''));

    if (process.env.NODE_ENV === 'production') {
      CobrowseIO.customData = {
        restaurant_id: storeConfig?.restaurantId,
        store_id: storeConfig?.storeId,
        name: storeConfig?.name,
        address: storeConfig?.address,
      };
      CobrowseIO.start();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refreshToUpdatePosVersion) {
      localStorage.setItem('posVersion', refreshToUpdatePosVersion);
      window.location.reload();
    }
  }, [refreshToUpdatePosVersion]);

  useEffect(() => {
    if (windCaveTransactionReference) {
      setOpenWindCaveTerminalModal(true);
      getWindcaveTransactionDetails(windCaveTransactionReference);
    }
  }, [windCaveTransactionReference]);

  useEffect(() => {
    onInitInput('orderGuest', '');
    onInitInput('notes', '');
    onInitInput('unit', '');
    onInitInput('postCode', '');
    onInitInput('orderMobile', '');
    onInitInput('orderGuest', '');
    onInitInput('orderGeosuggest', '');
    onInitInput('searchMenuItem', '');
    onInitInput('searchIngredients', '');
    onInitInput('searchOrders', '');
    onInitInput('itemNotes', '');
    onInitInput('drawerName', '');
    onInitInput('userName', '');
    onInitInput('password', '');
    onInitInput('pairCode', '');
    onInitInput('terminalName', '');
    onInitInput('deliverySearchAddress', '');
    onInitInput('manualDeliveryAddress', '');
    onInitInput('manualDeliveryCost', '');
    onInitInput('floorModalName', '');
    onInitInput('refundMessage', '');

    dispatch(resetCurrentOrder());
    dispatch(resetCurrentSpecialsItem());
    dispatch(resetCurrentHalfHalfData());
    dispatch(resetCurrentFourQuarterData());
    return () => {
      dispatch(setState({ selectedCategoryId: null }));
    };
  }, [dispatch]);

  const clearOrderPayments = () => {
    dispatch(setOrderPayments([]));
  };

  const handleClickPickup = (event) => {
    event.preventDefault();
    clearOrderPayments();
    if (customerDetails) {
      Object.keys(customerDetails).length &&
        dispatch(
          updateCurrentOrderWithOrderEngine({
            ...currentOrder,
            ...customerDetails,
            isCustomerNameFetched: true,
          })
        );
    }
    const pathname = '/pickup/order';
    if (!isAuthDisabledForPOS) {
      openModal();
      dispatch(setGoToRouteAfterAuth(pathname));
    } else {
      dispatch(authenticateStaff(true));
      toggleKeyboard(false);
      history.push(pathname);
    }
  };

  const handleClickDelivery = (event) => {
    event.preventDefault();
    if (customerDetails) {
      Object.keys(customerDetails).length &&
        dispatch(
          updateCurrentOrderWithOrderEngine({
            ...currentOrder,
            ...customerDetails,
            isCustomerNameFetched: true,
          })
        );
    }

    clearOrderPayments();
    const pathname = '/delivery/order';
    if (!isAuthDisabledForPOS) {
      openModal();
      dispatch(setGoToRouteAfterAuth(pathname));
    } else {
      dispatch(authenticateStaff(true));
      toggleKeyboard(false);
      history.push(pathname);
    }
  };

  const handleClickWalkIn = (event) => {
    event.preventDefault();
    clearOrderPayments();
    const pathname = '/walkin/order';
    if (!isAuthDisabledForPOS) {
      openModal();
      dispatch(setGoToRouteAfterAuth(pathname));
    } else {
      dispatch(authenticateStaff(true));
      if (!orderSetup?.isEnableOrderNumberForWalkIn) {
        toggleKeyboard(true);
      }
      history.push(pathname);
    }
  };

  const handleClickKiosk = (event) => {
    event.preventDefault();
    clearOrderPayments();

    if (!isAuthDisabledForPOS) {
      openModal();
      dispatch(setGoToRouteAfterAuth(kiosk_route));
    } else {
      dispatch(authenticateStaff(true));
      if (!orderSetup?.isEnableOrderNumberForWalkIn) {
        toggleKeyboard(true);
      }
      history.push(kiosk_route);
    }
  };

  const handleClickDineIn = (event) => {
    event.preventDefault();
    clearOrderPayments();
    const pathname = '/table-arrangement';

    // disable Auth for DineIn
    dispatch(authenticateStaff(true));
    history.push(pathname);
  };

  const handleClickShifts = (event) => {
    event.preventDefault();
    const link = '/shifts/clock-in';
    openModal();
    // setIsShowStaffPinModal(true);
    dispatch(setGoToRouteAfterAuth(link));
  };

  const handleClickDriveThru = (event) => {
    event.preventDefault();
    clearOrderPayments();
    if (!isAuthDisabledForPOS) {
      openModal();
      dispatch(setGoToRouteAfterAuth(drive_thru_order_route));
    } else {
      dispatch(authenticateStaff(true));
      if (!orderSetup?.isEnableOrderNumberForWalkIn) {
        toggleKeyboard(true);
      }
      history.push(drive_thru_order_route);
    }
  };

  const homeMainMenu = [
    {
      id: 1,
      title: 'Walk In',
      link: '',
      customClass: 'red',
      icon: WalkInImage,
      iconCls: 'walk-in',
      action: handleClickWalkIn,
    },
    {
      id: 2,
      title: 'Pick Up',
      link: '',
      customClass: 'blue-light',
      icon: PickupImage,
      iconCls: 'pick',
      action: handleClickPickup,
    },
    {
      id: 3,
      title: 'Delivery',
      link: '',
      icon: DeliveryImage,
      customClass: 'orange',
      action: handleClickDelivery,
      iconCls: 'delivery',
    },
    ...(!orderSetup?.dineInDisabled
      ? [
          {
            id: 4,
            title: 'Dine-in',
            link: '',
            icon: DineInImage,
            customClass: 'white',
            iconCls: 'dine',
            action: handleClickDineIn,
          },
        ]
      : []),
    ...(orderSetup?.isDriveThruEnabled
      ? [
          {
            id: 4,
            title: 'Drive-Thru',
            link: '',
            icon: DriveThruImage,
            customClass: 'green',
            action: handleClickDriveThru,
            iconCls: 'pick',
          },
        ]
      : []),
  ];

  const subMenuOptions = useMemo(
    () =>
      [
        {
          id: 13,

          title: 'Kiosk',
          link: '',
          icon: KioskImage,
          customClass: 'black',
          iconCls: 'kiosk',
          action: handleClickKiosk,
        },
        {
          id: 6,
          title: 'Shifts',
          link: '',
          icon: ShiftsImage,
          customClass: 'black',
          iconCls: 'shifts',
          action: handleClickShifts,
        },
        ...homeSubMenu,
      ].sort((a, b) => a.id - b.id),
    // eslint-disable-next-line
    []
  );

  const gotoShiftsPage = (path) => {
    setShowClockInOutModal(false);

    history.push(path);

    // close any remaining open modals overlay
    dispatch(toggleBlurEffect(false));
  };

  useEffect(() => {
    closeModal();
  }, [closeModal]);

  // TODO: Can't perform a React state update on an unmounted component.
  useEffect(() => {
    if (!isStaffAuthorised || !goToRouteAfterAuth) return;

    if (!showClockInOutModal) {
      const clockInPath = goToRouteAfterAuth === '/shifts/clock-in';

      if (clockInPath && activeStaffMemberOpenShifts > 0) {
        const shiftsUrl = `/shifts/clocked-in${
          activeStaffMemberIsOnABreak ? '?status=' + ShiftsEnum.ON_A_BREAK : ''
        }`;
        history.push(shiftsUrl);
      } else {
        if (
          goToRouteAfterAuth.includes('walkin') &&
          !orderSetup?.isEnableOrderNumberForWalkIn
        ) {
          toggleKeyboard(true);
        } else {
          toggleKeyboard(false);
        }
        history.push(goToRouteAfterAuth);
      }
    }
  }, [
    toggleKeyboard,
    orderSetup?.isEnableOrderNumberForWalkIn,
    goToRouteAfterAuth,
    isStaffAuthorised,
    history,
    activeStaffMemberOpenShifts,
    activeStaffMemberIsOnABreak,
    showClockInOutModal,
  ]);

  useEffect(() => {
    if (accessDenied) {
      // close StaffPinModal
      closeModal();
    }
  }, [accessDenied, closeModal]);

  return (
    <>
      <AllIncomingCallNotification />
      <StaffPinModal
        setIsClockedIn={setIsClockedIn}
        storeConfig={storeConfig}
        orderSetup={orderSetup}
        setShowClockInOutModal={setShowClockInOutModal}
        setIsOnBreak={setIsOnBreak}
        errorMessage={errorMessage}
        setShowAccessDenied={setShowAccessDenied}
        setErrorMessage={setErrorMessage}
        wiggleEffect={wiggleEffect}
        setWiggleEffect={setWiggleEffect}
      />

      {accessDenied && (
        <AccessDeniedModal
          handleModalClose={setShowAccessDenied}
          message={errorMessage}
        />
      )}

      {showClockInOutModal && (
        <ClockInClockOutModal
          isClockedIn={isClockedIn}
          isOnBreak={isOnBreak}
          handleModalClose={setShowClockInOutModal}
          shouldGoToShiftsPage={gotoShiftsPage}
        />
      )}

      <div className="home-screen-box">
        <div className="home-screen-inner">
          <div className="home-top-box">
            <DateTimeBox />
            <WeatherBox />
          </div>

          <nav className="main-nav-box">
            <div
              className="main-inner-box"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {homeMainMenu.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={item.link}
                    className={'nav-btn large ' + item.customClass}
                    title={item.title}
                    onClick={(e) => item.action?.(e)}
                  >
                    <span className={'icon-box ' + item.iconCls}>
                      <img src={item.icon} alt={item.title} />
                    </span>
                    <span className="name" title={item.title}>
                      {item.title}
                    </span>
                  </Link>
                );
              })}
            </div>
          </nav>

          <nav className="other-buttons">
            {subMenuOptions.map((item, i) => {
              return (
                <Link
                  key={i}
                  to={item.link}
                  className={'nav-btn small ' + item.customClass}
                  title={item.title}
                  onClick={(e) => item.action?.(e)}
                >
                  <span className={'icon-box ' + item.iconCls}>
                    <img src={item.icon} alt={item.title} />
                  </span>
                  <span className="name" title={item.title}>
                    {item.title}
                  </span>
                </Link>
              );
            })}
          </nav>
        </div>
      </div>

      {openWindCaveTerminalModal && (
        <ModalPortal>
          <WindCaveTransactionModal
            show={openWindCaveTerminalModal}
            toggleModal={() => {
              localStorage.removeItem('transactionReference');
              setOpenWindCaveTerminalModal(!openWindCaveTerminalModal);
            }}
            isApiLoading={isApiLoading}
            incompleteOrder={incompleteOrder}
            windCaveTransactionReference={windCaveTransactionReference}
          />
        </ModalPortal>
      )}
    </>
  );
};

export default Home;
