import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { allStoresSelector } from 'pages/Dashboard/selectors';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SingleOrderIcon } from '../../components/Orders/SingleOrderIcon';
import { ScrollableBoxUI } from '../../UI/components';
import { getTimestampStartOfTheDay } from '../../util/timeHelper';
import GeneralModal from '../GeneralModal';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import actionTypes from 'pages/Dashboard/constants';

export const ActionsModal = ({
  modalStatus,
  toggleModal,
  currentGuest,
  selectedOrder,
  handlePinModalOpen,
  handleCancelOrderClick = () => {},
}) => {
  const { newSplitPaymentFlow } = useFlags();

  const history = useHistory();
  const dispatch = useDispatch();
  const allStoresLength = useSelector(allStoresSelector)?.length ?? 0;
  const isUberDriveEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.isUberDriveEnabled
  );

  const actions = useMemo(
    () => [
      {
        id: 1,
        title: 'Customer Profile',
        icon: 'shape-man',
        actionType: '',
      },
      {
        id: 16,
        title:
          selectedOrder?.discountId || selectedOrder?.voucherId
            ? 'Remove Discount'
            : 'Apply Discount',
        actionType: 'discount',
      },
      {
        id: 2,
        title: 'Settle',
        icon: 'wallet-cash',
        actionType: 'togglePaymentViewModal',
      },
      ...(selectedOrder.orderType === `3` && newSplitPaymentFlow
        ? [
            {
              id: 1111,
              title: 'Split Bill',
              icon: 'wallet-cash',
              actionType: 'goToPayment',
            },
          ]
        : []),
      {
        id: 3,
        title: 'Print Bill',
        icon: 'print',
        actionType: 'togglePlanBillModal',
      },
      {
        id: 4,
        title: 'Edit Order',
        icon: 'silverware',
        actionType: 'editOrder',
      },
      {
        id: 8,
        title: 'Cancel Order',
        icon: 'close',
        actionType: 'cancelOrder',
      },
      ...(isUberDriveEnabled &&
      selectedOrder.orderType === '2' &&
      selectedOrder.paymentStatus === 1
        ? [
            {
              id: 15,
              title: selectedOrder.uberDriveDeliveryCreated
                ? 'View Uber Drive Delivery'
                : 'Request Uber Driver',
              icon: 'scooter',
              actionType: 'requestUberDriver',
            },
          ]
        : []),
      ...(allStoresLength > 1
        ? [
            {
              id: 14,
              title: 'Transfer Order',
              icon: 'transfer',
              actionType: 'transferOrderModal',
            },
          ]
        : []),
      //{
      //  id: 9,
      //  title: 'Email Invoice',
      //  // icon: "dine_icon-message",
      //  icon: 'message',
      //  actionType: '',
      //},
      //{
      //  id: 10,
      //  title: 'SMS Invoice',
      //  // icon: "dine_icon-chat-bubbles-inverse",
      //  icon: 'chat-bubbles',
      //  actionType: '',
      //},
      //{
      //  id: 11,
      //  title: 'Delivery Order',
      //  // icon: "dine_icon-bike",
      //  icon: 'bike',
      //  actionType: 'toggleDeliveryOrderModal',
      //},
      ...(selectedOrder?.refundStatus !== 1 &&
      selectedOrder.payments.some(
        ({ paymentType, merchantCode }) =>
          paymentType === '2' ||
          (paymentType === '3' &&
            (merchantCode === 500 || merchantCode === 600))
      )
        ? [
            {
              id: 12,
              title: 'Refund Order',
              // icon: "dine_icon-silverware",
              icon: 'wallet-cash',
              actionType: 'toggleRefundOrderModal',
            },
          ]
        : []),
      ...(selectedOrder.paymentType !== '0'
        ? [
            {
              id: 13,
              title: 'Issue Voucher',
              // icon: "dine_icon-silverware",
              icon: 'wallet-cash',
              actionType: 'toggleIssueVoucherModal',
            },
          ]
        : []),
    ],
    [selectedOrder.payments, selectedOrder.paymentType, allStoresLength]
  );

  const modalHeaderCls = classNames({
    'action-modal-header': true,
  });

  const handleClick = (e, actionType) => {
    // let tableId = currentGuest.table ? currentGuest.table : 32;
    switch (actionType) {
      case 'togglePaymentViewModal':
        dispatch({
          type: 'togglePaymentViewModal',
          payload: true,
        });
        break;
      case 'goToPayment':
        history.push(`/payment/${selectedOrder._id}`);
        break;
      case 'transferOrderModal':
        dispatch({
          type: 'transferOrderModal',
          payload: true,
        });
        break;
      case 'editOrder':
        handlePinModalOpen(selectedOrder);
        break;
      case 'togglePlanBillModal':
        dispatch({
          type: 'togglePlanBillModal',
          payload: true,
        });
        break;
      case 'toggleDeliveryOrderModal':
        dispatch({
          type: 'toggleDeliveryOrderModal',
          payload: true,
        });
        break;
      case 'cancelOrder':
        handleCancelOrderClick();
        break;
      case 'toggleRefundOrderModal':
        dispatch({
          type: 'toggleRefundOrderModal',
          payload: {
            refundType: 'refund',
            showModal: true,
            onModalClose: () =>
              dispatch({
                type: 'toggleRefundOrderModal',
                payload: {
                  showModal: false,
                },
              }),
          },
        });
        break;
      case 'toggleIssueVoucherModal':
        dispatch({
          type: 'toggleRefundOrderModal',
          payload: {
            refundType: 'voucher',
            showModal: true,
            onModalClose: () =>
              dispatch({
                type: 'toggleRefundOrderModal',
                payload: {
                  showModal: false,
                },
              }),
          },
        });
        break;

      case 'requestUberDriver':
        dispatch({
          type: 'requestUberDriver',
          payload: true,
        });
        break;
      case 'discount':
        dispatch({ type: actionTypes.toggleSplitDiscountModal, payload: true });

      default:
        break;
    }

    dispatch({
      type: 'toggleViewOrderModal',
      payload: false,
    });

    toggleModal(e, false);

    /* XXX: is it needed? (was inside editOrder case)
       dispatch({
        type: 'toggleViewOrderModal',
        payload: false,
      });

      dispatch({
        type: 'setBackLink',
        payload: window.location.pathname,
      });

      dispatch({
        type: 'setCurrentGuestTableId',
        payload: tableId,
      });

      if (currentGuest.deliveryId) {
        dispatch({
          type: 'setOrderBarType',
          payload: 'delivery',
        });

        dispatch({
          type: 'setDeliveryName',
          payload: currentGuest.name,
        });
      } else if (currentGuest.pickupId) {
        dispatch({
          type: 'setOrderBarType',
          payload: 'pickup',
        });

        dispatch({
          type: 'setPickUpName',
          payload: currentGuest.name,
        });
      } else if (currentGuest.table) {
        dispatch({
          type: 'setOrderBarType',
          payload: 'table-view',
        });
      }

      let customTimeout = setTimeout(function () {
        history.push('/pickup/order');
        clearTimeout(customTimeout);
      }, 50); */
  };

  const timestampStartOfTheDay = getTimestampStartOfTheDay();

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="right-bottom"
      effect="move"
      overlay=""
      boxWidth="327px"
      boxHeight="calc(100% - 21px)"
      className="actions-modal radius-none"
      closeBtn="inside"
    >
      <div className="actions-modal-box">
        <div className={modalHeaderCls}>
          <h2 className="actions-modal-title">Actions </h2>
        </div>
        <div className="actions-modal-body">
          <ScrollableBoxUI>
            <ul className="sidebar-action-list">
              {actions.map((action, i) => {
                if (
                  action.id === 2 && // intent to show settle cta
                  selectedOrder.paymentType !== '0' // order is already settled
                ) {
                  return null;
                }

                if (
                  action.id === 4 &&
                  (selectedOrder.deliveryDateTimestamp <
                    timestampStartOfTheDay ||
                    !['1', '2'].includes(selectedOrder.orderFrom))
                ) {
                  return null;
                }
                if (
                  action.id === 16 &&
                  (selectedOrder.deliveryDateTimestamp <
                    timestampStartOfTheDay ||
                    !['1', '2'].includes(selectedOrder.orderFrom) ||
                    selectedOrder.paymentStatus === 1)
                ) {
                  return null;
                }

                if (
                  action.id === 8 &&
                  (!['0', '1'].includes(selectedOrder.paymentType) ||
                    !['1', '2'].includes(selectedOrder.orderFrom) ||
                    ['0', '11'].includes(selectedOrder.orderStatus))
                ) {
                  return null;
                }

                if (
                  action.id !== 11 ||
                  (action.id === 11 && currentGuest.deliveryId)
                ) {
                  return (
                    <li key={action.id}>
                      <button
                        type="button"
                        className="single-sidebar-action"
                        onClick={(event) =>
                          handleClick(event, action.actionType)
                        }
                        title={action.title}
                      >
                        <span className="inner-box">
                          {i === 0 ? (
                            <>
                              <span className="sidebar-action-subtitle">
                                {action.title}
                              </span>
                              <h3 className="sidebar-action-title">
                                {`${currentGuest?.firstName || ''} ${
                                  currentGuest?.lastName || ''
                                }`}
                              </h3>
                            </>
                          ) : (
                            <h3 className="sidebar-action-title">
                              {action.title}
                            </h3>
                          )}
                        </span>
                        <span className="icon-box">
                          <SingleOrderIcon iconName={action.icon} />
                        </span>
                      </button>
                    </li>
                  );
                } else {
                  return '';
                }
              })}
            </ul>
          </ScrollableBoxUI>
        </div>
      </div>
    </GeneralModal>
  );
};
