import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import useMutation from '../../customHooks/useMutation';
import {
  selectedDrawerIdSelector,
  storeConfigSelector,
} from '../Dashboard/selectors';
import { MoneyCount } from '../MoneyCount';
import { filteredDriversSelector } from './selectors';

const DriverCashFloat = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { driverId } = useParams();
  const storeConfig = useSelector(storeConfigSelector);
  const {
    driversWaitingOnStore = [],
    driversOnDelivery = [],
    driversReturningToStore = [],
    clockedOutDrivers = [],
  } = useSelector(filteredDriversSelector) || {};

  const currentDriver =
    [
      ...driversWaitingOnStore,
      ...driversOnDelivery,
      ...driversReturningToStore,
      ...clockedOutDrivers,
    ]?.find((driver) => driver._id === driverId) || {};

  const [totalState, totalDispatch] = useReducer(
    (state, action) => {
      if (action.type === 'setTotal')
        return { ...state, total: action.payload };
    },
    { total: 0 }
  );
  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );
  const [addCashTransaction, { data = {} }] = useMutation(
    `${nextOrderConfigURL}/addDriverCashTransaction`,
    {}
  );

  const selectedDrawerId = useSelector(selectedDrawerIdSelector);

  const [
    isLoadingAddDriverCashTransaction,
    setIsLoadingAddDriverCashTransaction,
  ] = useState(false);

  const handleSave = async (reason) => {
    const status = currentDriver.cashBalance?.status;
    setIsLoadingAddDriverCashTransaction(true);
    await addCashTransaction(
      JSON.stringify({
        driverId,
        countryIdentifier: storeConfig.countryIdentifier,
        storeId: storeConfig?.storeId,
        restaurantId: storeConfig.restaurantId,
        firstName: currentDriver.firstName,
        lastName: currentDriver.lastName,
        mobileNumber: currentDriver.mobileNumber,
        amount: status === '1' ? -totalState.total : totalState.total,
        status: status === '1' ? '0' : '1',
        drawerId: selectedDrawerId,
        reason: reason || '',
      })
    );
    setIsLoadingAddDriverCashTransaction(false);
  };
  const handleConfirm = (onExcess, onShort) => {
    const status = currentDriver.cashBalance?.status;
    const totalBalance = currentDriver.cashBalance?.balance;
    const isBalanceEqual = totalState.total === totalBalance;
    if (status === '0' || isBalanceEqual) {
      handleSave();
    } else if (status === '1' && !isBalanceEqual) {
      if (totalBalance < totalState.total) {
        onExcess(totalState.total, totalBalance);
      } else {
        onShort(totalState.total, totalBalance);
      }
    }
  };

  useEffect(() => {
    if (data.success) {
      dispatch({
        type: 'setCurrentDriver',
        payload: null,
      });
      history.push('/drivers/all');
    }
  }, [data, history, dispatch]);

  const handleTotalChange = useCallback(
    (newTotal) => {
      totalDispatch({
        type: 'setTotal',
        payload: newTotal,
      });
    },
    [totalDispatch]
  );

  return (
    <MoneyCount
      initialTotal={currentDriver.cashBalance?.balance || 0}
      manual={false}
      onConfirm={handleConfirm}
      onConfirmShort={handleSave}
      onTotalChange={handleTotalChange}
      backLink="/drivers/all"
      viewDeliveriesURL={
        currentDriver.cashBalance?.status === '1'
          ? `${pathname}view-deliveries`
          : ''
      }
      isLoadingAddDriverCashTransaction={isLoadingAddDriverCashTransaction}
    />
  );
};

export default DriverCashFloat;
