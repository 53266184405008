import { orderTypesMap } from 'configuration/enums';
import { useLocation } from 'react-router-dom';

function useOrderType() {
  const { pathname } = useLocation();

  const orderType = pathname.match(/^\/([a-z]*)\//)?.[1];

  const orderTypeId = orderTypesMap[orderType];


  return [orderType, orderTypeId];
}

export default useOrderType;
