import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuItem } from '../../pages/Dashboard/action';
import { updateSize, resetExtraIngredient } from '../../itemProcessor';
import { Size } from './Size';
import {
  // currencyCodeSelector,
  storeCountryIdentifierSelector,
  currencySymbolSelector,
} from '../../pages/Dashboard/selectors';
import useIsKiosk from 'hooks/useIsKiosk';
import { showCustomization } from 'util/showCustomization';
import { useHistory } from 'react-router';

export const Sizes = ({
  currentItem,
  basePath,
  autoShowCustomizeOrderScreenForKiosk,
}) => {
  const dispatch = useDispatch();
  const isKiosk = useIsKiosk();
  const history = useHistory();

  const handleChange = (_event, sizeId, variantId) => {
    let currentItemObj = updateSize(currentItem, sizeId, variantId);
    dispatch(updateMenuItem(resetExtraIngredient(currentItemObj)));

    //NOTE: Only for kiosk screens, assuming sizes would have only one modifier
    if (
      autoShowCustomizeOrderScreenForKiosk &&
      isKiosk &&
      showCustomization(currentItemObj)
    ) {
      history.push(`${basePath}/menuitem/${currentItemObj._id}/customization`);
    }
  };

  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  return (
    <React.Fragment>
      <h2 className="main-title">What size would you like?</h2>
      <form
        action=""
        className="customization-form detail-form"
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="item-detail-cards">
          {currentItem.selectedSizes?.length &&
            currentItem.selectedSizes.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Size
                    key={item._id}
                    sizeId={item._id}
                    variantId="xxx"
                    name={item.name}
                    quantity={item.quantity ? item.quantity : 0}
                    variantQuantity={
                      item.variants && item.variants.length > 0
                        ? item.variants.filter((f) => f.quantity > 0).length > 0
                          ? 1
                          : 0
                        : 0
                    }
                    price={parseFloat(item.price)}
                    handleChange={handleChange}
                    currencyCode={currencyCode}
                    storeCountryIdentifier={storeCountryIdentifier}
                    isSoldOut={!!item?.isSoldOut}
                  />
                  {item.variants &&
                    item.variants.length > 0 &&
                    item.variants
                      .filter((f) => f.isSelected)
                      .map((variant) => {
                        return (
                          <Size
                            key={item._id + variant._id}
                            sizeId={item._id}
                            variantId={variant._id}
                            name={`${item.name} (${variant.name})`}
                            quantity={item.quantity ? item.quantity : 0}
                            variantQuantity={
                              variant.quantity ? variant.quantity : 0
                            }
                            price={
                              parseFloat(item.price) + parseFloat(variant.price)
                            }
                            handleChange={handleChange}
                            currencyCode={currencyCode}
                            storeCountryIdentifier={storeCountryIdentifier}
                            isSoldOut={!!variant?.isSoldOut}
                          />
                        );
                      })}
                </React.Fragment>
              );
            })}
        </div>
      </form>
    </React.Fragment>
  );
};
