import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconWarningSolid = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-arrow': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1000 913"
      >
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M983.9,729.2L607.2,62.6c-47.1-83.4-167.2-83.4-214.4,0L16.1,729.2c-46.4,82.1,13,183.7,107.2,183.7h753.4
        C971,912.9,1030.3,811.3,983.9,729.2z M500,259.8c33.1,0,59.8,26.8,59.8,59.8l-8.2,210.4c0,28.5-23.1,51.7-51.7,51.7
        c-28.5,0-51.7-23.1-51.7-51.7l-8.2-210.4C440.2,286.6,467,259.8,500,259.8z M500,749.2c-35.4,0-64.1-26.2-64.1-58.6
        c0-32.3,28.7-58.6,64.1-58.6s64.1,26.2,64.1,58.6C564.1,722.9,535.4,749.2,500,749.2z"
        />
      </svg>
    </span>
  );
};

PosIconWarningSolid.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
