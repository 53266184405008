import { getAmountWithCountryCurrency } from './currencyFormat';

export default function orderAndPayPrice({
  currentOrder,
  isMatchesMinOrderValue,
  alreadyPaidAmount,
  storeCountryIdentifier,
  currencySymbol,
  minOrderValue,
}) {
  return currentOrder?.menuItems?.length && currentOrder?.payableAmount
    ? `${getAmountWithCountryCurrency({
        amount: Number(Number(currentOrder.payableAmount) - alreadyPaidAmount),
        locale: `en-${storeCountryIdentifier}`,
        currencyCode: currencySymbol,
      })} ${
        !isMatchesMinOrderValue
          ? '(Min. ' +
            getAmountWithCountryCurrency({
              amount: Number(minOrderValue),
              locale: `en-${storeCountryIdentifier}`,
              currencyCode: currencySymbol,
            }) +
            ')'
          : ''
      }`
    : '0';
}
