import './bem-add-driver-modal.scss';
import GeneralModal from '../../modals/GeneralModal/index-new';
import { BasePartUI, ButtonUI, InputUI } from '../../UI/components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { storeConfigSelector } from '../../pages/Dashboard/selectors';
import PhoneModal from 'modals/PhoneModal';
import useModal from 'hooks/useModal';
import { ModalPortal } from 'components';
import { addDriver } from './AddDriverModal.Service';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

export const AddDriverModal = ({ show, toggleModal }) => {
  const storeConfig = useSelector(storeConfigSelector);
  const { restaurantId, storeId, countryIdentifier } = storeConfig;
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneModalOpen, { openModal, closeModal }] =
    useModal('customerPhoneModal');

  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    onInitInput,
  } = useVirtualKeyboardContext();

  const firstNameValue = getInputValue('firstName');
  const lastNameValue = getInputValue('lastName');

  useEffect(() => {
    onInitInput('firstName', '');
    onInitInput('lastName', '');
  }, []);

  useEffect(() => {
    setFirstName(firstNameValue || '');
    setLastName(lastNameValue || '');
  }, [firstNameValue, lastNameValue]);

  const onModalClose = () => {
    toggleModal();
    setFirstName('');
    setLastName('');
    setMobileNumber('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const response = await addDriver({
      firstName,
      lastName,
      mobileNumber,
      storeId,
      restaurantId,
    });
    setLoading(false);

    if (response.success) {
      onModalClose();
    }
  };

  const handleSubmitMobileNumber = (mobileNumebr) => {
    setMobileNumber(mobileNumebr);
    closeModal();
  };

  const handleOnFocus = (event, fieldName) => {
    setInputName(fieldName);
    toggleKeyboard(true);
  };

  return (
    <>
      <GeneralModal
        className="bem-add-driver-modal"
        modalStatus={show}
        toggleModal={onModalClose}
        position={'center'}
        effect="fade"
        overlay={'default'}
        boxWidth="435px"
        boxHeight="536px"
        closeBtn="outside"
        isResponsiveHeight
        keyboardVisibility={keyboardVisibility}
      >
        <form
          action=""
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            height: '100%',
          }}
        >
          <BasePartUI>
            <BasePartUI.Header>
              <h3 className="bem-add-driver-modal__title">Add Driver</h3>
            </BasePartUI.Header>
            <BasePartUI.Body scrollShadow>
              <InputUI
                name="firstName"
                label="First Name"
                labelRequired
                disabled={loading}
                value={firstName}
                onFocus={(event) => handleOnFocus(event, 'firstName')}
                onChange={(event) => {
                  setFirstName(event.target.value);
                  onChangeInput(event.target.value);
                }}
              />
              <InputUI
                name="lastName"
                label="Last Name"
                disabled={loading}
                labelRequired
                value={lastName}
                onFocus={(event) => handleOnFocus(event, 'lastName')}
                onChange={(event) => {
                  setLastName(event.target.value);
                  onChangeInput(event.target.value);
                }}
              />
              <InputUI
                name="mobileNumber"
                label="Mobile Number"
                type="tel"
                disabled={loading}
                labelRequired
                value={mobileNumber}
                onFocus={() => {
                  toggleKeyboard(false);
                  openModal();
                }}
              />
            </BasePartUI.Body>
            <BasePartUI.Footer>
              <ButtonUI
                type="submit"
                style={{ height: 55 }}
                sizeType="xl"
                rounded="none"
                fullWidth
                onClick={handleSubmit}
                loading={loading}
                disabled={!firstName || !lastName || !mobileNumber}
              >
                Add Driver
              </ButtonUI>
            </BasePartUI.Footer>
          </BasePartUI>
        </form>
      </GeneralModal>
      <ModalPortal>
        <PhoneModal
          title="Driver Mobile Number"
          modalStatus={phoneModalOpen}
          onNextClick={handleSubmitMobileNumber}
          onModalClose={() => {
            closeModal();
          }}
          countryIdentifier={countryIdentifier}
        />
      </ModalPortal>
    </>
  );
};
