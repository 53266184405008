import GeneralModal from 'modals/GeneralModal/index-new';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RefundSelectItem from 'modals/RefundReasonModal/RefundSelectItem';
import CustomVoidedReasonTextModal from './CustomVoidedReasonTextModal';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { getVoidedReasons } from './VoidedReasonModal.service';

const VoidedReasonModal = ({ modalStatus, onModalClose, onSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [voidedReasons, setVoidedReasons] = useState([]);
  const [checkedId, setCheckedId] = useState('');
  const [customVoidedReason, setCustomVoidedReason] = useState('');
  const [showCustomReasonModal, setShowCustomeReason] = useState(false);
  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken || ''
  );

  useEffect(async () => {
    const voidedReasonsData = await getVoidedReasons(accessToken);
    setVoidedReasons(voidedReasonsData);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (checkedId === '0') {
      setShowCustomeReason(true);
    } else {
      setCustomVoidedReason('');
    }
  }, [checkedId]);

  return (
    <>
      <GeneralModal
        modalStatus={modalStatus}
        toggleModal={onModalClose}
        position="center"
        overlay="blur"
        effect=""
        boxWidth="435px"
        boxHeight="649px"
        className="staff-pin-modal"
        closeBtn="outside"
      >
        <div className="general-modal__inner">
          <div className="general-modal__header general-modal__header--shadow ">
            <div className="general-modal__title-box">
              <h3 className="general-modal__title general-modal__title--size-small">
                Select the Voided Reason
              </h3>
            </div>
            <div className="general-modal__desc general-modal__desc--size-small">
              <p>Select the reason for removing the item.</p>
            </div>
          </div>

          <div className="general-modal__body">
            {loading ? (
              <div className="voided-reasons-loading">
                <PosIconLoading mainColor="#5eb602" />
              </div>
            ) : (
              <div className="general-modal__body-inner">
                <div className="refund-select-list">
                  {voidedReasons
                    .sort((a, b) => a.index - b.index)
                    .map((r) => (
                      <RefundSelectItem
                        key={r.id}
                        label={r.reason}
                        value={r.id}
                        checked={r.id === checkedId}
                        onChange={(id) => {
                          setCheckedId(id);
                        }}
                      />
                    ))}
                </div>
                {customVoidedReason && (
                  <div className="other-label">
                    <p>Other : {customVoidedReason}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <button
            onClick={() => {
              onSubmit({
                voidedReason: checkedId,
                customVoidedReason: customVoidedReason,
              });
            }}
            disabled={!checkedId}
            className="button button--rounded-none button--align-center button--size-biggest button--fullwidth button--theme-green"
            title="Next"
            type="button"
          >
            Submit
          </button>
        </div>
      </GeneralModal>
      {showCustomReasonModal && (
        <CustomVoidedReasonTextModal
          modalStatus={showCustomReasonModal}
          onModalClose={() => {
            setShowCustomeReason(false);
            if (!customVoidedReason) {
              setCheckedId('');
            }
          }}
          customVoidedReason={customVoidedReason}
          setCustomVoidedReason={setCustomVoidedReason}
        />
      )}
    </>
  );
};

export default VoidedReasonModal;
