import { actionTypes } from './storeWebsiteLoaderDataConstants';

const initialState = {
  addressLocality: '',
  baseURL: '',
  baseURLs: [],
  faviconLink: '',
  globalSiteTagId: '',
  menuURL: '',
  pixelId: '',
  primaryThemeColour: "#0B3066",
  secondaryThemeColour: "#0B3066",
  servedVersion: '',
  servesCuisine: '',
  storeIcon: '',
  storeId: '',
  title: '',
  websiteDescription: '',
};

export default function storeWebsiteLoaderDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.SET_STORE_WEBSITE_LOADER_DATA:
      return action.payload;
    default:
      return state;
  }
}
