import { _auth } from '../../firebase';
import { API_URL } from 'configuration/apiUrl';

const typeMap = {
  full: 1,
  equal: 2,
  per_item: 3,
  custom: 4,
  split_per_seat: 5,
};

export const saveSharedStatusOfAllItems = async (
  selectedOrder,
  nextOrderConfigURL
) => {
  const allPaymentTotal = selectedOrder.payments.reduce(
    (total, payment) => (total = total + payment.amount),
    0
  );
  const requestObj = {
    order: {
      ...selectedOrder,
      isEditOrder: true,
      paymentType: '0',
      doNotUpdateOrderStatus: true,
    },
    payment: {
      type: '0',
      amount: (Number(selectedOrder.payableAmount) - allPaymentTotal) * 100,
    },
  };
  const response = await fetch(`${nextOrderConfigURL}/placeOrder3DSecure`, {
    method: 'post',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...requestObj,
    }),
  });

  const result = await response.json();
  return result;
};

export const createSplitPerItemChecks = async (
  splitPerItemPayment,
  type,
  accessToken,
  currentOrder
) => {
  const isCheckContainId = splitPerItemPayment.checks.some((check) => check.id);
  const response = await fetch(`${API_URL}/orders/v1/splitPayments`, {
    method:
      currentOrder.payments?.length > 0 || isCheckContainId ? 'put' : 'post',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...splitPerItemPayment,
      type: typeMap[type],
    }),
  });

  const result = await response.json();
  return result;
};

export const getSplitPaymentsData = async (orderId, accessToken) => {
  const response = await fetch(
    `${API_URL}/orders/v1/splitPayments?orderId=${orderId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    }
  );

  const result = await response.json();
  return result;
};

export const deleteChecks = async (orderId, checkId) => {
  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await fetch(
    `${API_URL}/orders/v1/splitPayments/checks?orderId=${orderId}&checkId=${checkId}`,
    {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    }
  );
  const result = await response.json();
  if (result.data.code === 200) {
    return true;
  }
  return false;
};
