import { memo } from 'react';

const OrderBarTextField = memo(({
  value,
  onBlur,
  onChange,
  ...rest
}) => {
  return (
    <input
      type="text"
      // name="pickup-notes"
      className="input-style"
      value={value}
      onChange={onChange}
      autoComplete="off"
      onBlur={onBlur}
      {...rest}
    />
  )
})

export default OrderBarTextField;
