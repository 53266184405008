import { validateDiscount } from '../util/voucherHelper';
import { sortBy } from 'lodash';
import { getDayFlag, getCurrentDayFlag } from '../util/timeHelper';
import { isObjectNotEmpty } from '../util/utils';
import detectSpecial from './detectSpecial';
import {
  getItemPriceForSpecialDiscount,
  getSpecialDetectionCount,
  detectMergeRequired,
} from './utilities';

const getDiscount = (
  currentOrder,
  payableAmount,
  rowItems,
  specialDiscount,
  allSpecials,
  orderSetup,
  publicHolidays,
  storeConfig
) => {
  let matchingDiscounts = [];
  if (
    currentOrder.discountId &&
    currentOrder.discountId !== '' &&
    currentOrder.menuItems.length > 0
  ) {
    if (isObjectNotEmpty(currentOrder.selectedDiscount)) {
      let myDiscounts = currentOrder.selectedDiscount
        ? [currentOrder.selectedDiscount]
        : [];

      matchingDiscounts = myDiscounts.filter((v) => {
        return v._id === currentOrder.discountId;
      });

      if (matchingDiscounts && matchingDiscounts.length > 0) {
        let selectedDiscount = matchingDiscounts[0];

        if (
          validateDiscount(
            selectedDiscount,
            currentOrder,
            payableAmount,
            storeConfig
          ).success
        ) {
          let discountDiscountObj = getDiscountedAmount(
            selectedDiscount,
            currentOrder,
            rowItems,
            specialDiscount,
            allSpecials,
            orderSetup,
            publicHolidays,
            storeConfig
          );
          if (discountDiscountObj.isDiscountValid) {
            if (
              selectedDiscount.type === '3' &&
              !selectedDiscount.isAvailableWithSpecials
            ) {
            } else {
              return {
                discountFound: true,
                discountDiscountObj: discountDiscountObj,
                selectedDiscount: selectedDiscount,
              };
            }
          } else {
            return {
              discountFound: false,
            };
          }
        } else {
          return {
            discountFound: false,
          };
        }
      } else {
        return {
          discountFound: false,
        };
      }
    } else {
      const discountInfo = {
        _id: currentOrder.discountId,
        discount: currentOrder.discount,
        discountName: currentOrder.discountName,
      };
      return {
        discountFound: true,
        discountDiscountObj: discountInfo,
        selectedDiscount: discountInfo,
      };
    }
  } else {
    return {
      discountFound: false,
    };
  }
};

function getDiscountedAmount(
  selectedDiscount,
  currentOrder,
  rowItems,
  specialDiscount,
  allSpecials,
  orderSetup,
  publicHolidays,
  storeConfig = {}
) {
  let resObj = {
    discount: 0,
    isDiscountValid: false,
    isItemOccupiedWithSpecials: false,
  };
  if (
    currentOrder &&
    selectedDiscount &&
    (selectedDiscount.type === '2' || selectedDiscount.type === '1')
  ) {
    resObj.isDiscountValid = true;
    if (selectedDiscount.discountType === '1') {
      resObj.discount = (
        (Number(currentOrder.payableAmount) *
          Number(selectedDiscount.discount)) /
        100
      ).toFixed(2);
      return resObj;
    } else {
      resObj.discount = selectedDiscount.discount;
      return resObj;
    }
  } else if (
    currentOrder &&
    selectedDiscount &&
    selectedDiscount.type === '3'
  ) {
    let discountWithoutFlag = detectPersonalizedSpecial(
      currentOrder,
      selectedDiscount,
      rowItems,
      false,
      storeConfig
    );
    if (!selectedDiscount.isAvailableWithSpecials) {
      let discountFlag = detectPersonalizedSpecial(
        currentOrder,
        selectedDiscount,
        rowItems,
        true,
        storeConfig
      );
      if (
        discountWithoutFlag.discountDiscount !== 0 &&
        discountFlag.discountDiscount === 0
      ) {
        rowItems = sortBy(rowItems.filter((f) => {
          return !discountWithoutFlag.usedIndexes.includes(f.primaryKey);
        }), 'price').reverse();
        let specialObj = detectSpecial(
          currentOrder,
          rowItems,
          allSpecials,
          orderSetup,
          publicHolidays,
          storeConfig
        );

        let specials = specialObj.specials;
        //Calculate speical's discount
        let discount = 0;
        specials.map((s) => {
          discount = discount + Number(s.discount);
          return s;
        });
        if (
          Number(specialDiscount) - discount >
          discountWithoutFlag.discountDiscount
        ) {
          resObj.isItemOccupiedWithSpecials = false;
          resObj.isDiscountValid = false;
          resObj.discount = discountFlag.discountDiscount;
        } else {
          resObj.isItemOccupiedWithSpecials = true;
          resObj.isDiscountValid = true;
          resObj.specials = specials;
          resObj.discount = discountWithoutFlag.discountDiscount;
        }
      } else {
        resObj.discount = discountFlag.discountDiscount;
        resObj.isDiscountValid = true;
      }
    } else {
      resObj.isDiscountValid = true;
      resObj.discount = discountWithoutFlag.discountDiscount;
    }
    return resObj;
  } else return resObj;
}

function detectPersonalizedSpecial(
  currentOrder,
  discount,
  rowItems,
  filterOutUsedItems,
  storeConfig = {}
) {
  window.itemsUsed = [];
  let specialItemsObj = [];
  let specialFrames = getPersonalizedSpecialFrames(
    currentOrder,
    discount,
    storeConfig
  );
  if (filterOutUsedItems) {
    rowItems = rowItems.filter((s) => {
      return !s.itemsUsedInSpecials;
    });
  }
  let discountDiscountObj = { discountDiscount: 0, usedIndexes: [] };
  if (rowItems && rowItems.length > 0 && specialFrames.length > 0) {
    getSpecialDetectionCount(specialFrames[0], rowItems, 0, specialItemsObj);
    let detectedSpecials = [...specialItemsObj];
    if (detectedSpecials.length) {
      let finalSpecial = detectedSpecials[0];
      let itemsToBeAddedInSpecials = [];
      finalSpecial.slots.map((ss) => {
        let rowMenuItems = [...currentOrder.menuItems];
        let filteredItem = [];
        if (ss.isHalf) {
          filteredItem = rowMenuItems.filter((ff) => {
            return (
              ff._id === ss.itemId &&
              ff.isSecondHalf === false &&
              ff.orderIndex === ss.orderIndex
            );
          });
        } else {
          filteredItem = rowMenuItems.filter((ff) => {
            return ff._id === ss.itemId && ff.orderIndex === ss.orderIndex;
          });
        }
        if (filteredItem && filteredItem.length > 0) {
          filteredItem.forEach((ftmm) => {
            let ttml = ftmm;
            if (detectMergeRequired(ss, ttml)) {
              ttml.selectedSizes = ttml.selectedSizes.filter((ggm) => {
                return ss.sizeKeyMatched === ggm._id;
              });
              if (ttml.selectedSizes && ttml.selectedSizes.length > 0) {
                ttml.selectedSizes[0].quantity = ttml.isHalf ? 0.5 : 1;
                if (ttml.isHalf) {
                  let othersHalf = rowMenuItems.filter((m) => {
                    return (
                      m.halfIndex === ttml.halfIndex && m.isSecondHalf === true
                    );
                  });
                  if (othersHalf.length > 0) {
                    let ttmll = othersHalf[0];
                    ttml.urlHalf = ttmll.urlS3;
                    ttml._idHalf = ttmll._id;
                    ttml.nameHalf = ttmll.name;
                    ttml.selectedSizesHalf = ttmll.selectedSizes;
                    ttml.selectedIngredientsHalf = ttmll.selectedIngredients;
                    ttml.removedIngredientsHalf = ttmll.removedIngredients;
                    ttml.selectedExtraIngredientsHalf =
                      ttmll.selectedExtraIngredients;
                    ttml.selectedSizesHalf = ttmll.selectedSizes.filter(
                      (ggm) => {
                        return (
                          ss.sizeKeys.indexOf(ggm._id) >= 0 ||
                          ss.modifierKeys.indexOf(ggm._id) >= 0
                        );
                      }
                    );
                    ttml.selectedSizesHalf[0].quantity = 0.5;
                  }
                }
                itemsToBeAddedInSpecials.push(ttml);
              }
            }
          });
        }
        discountDiscountObj.usedIndexes.push(ss.primaryKey);
        return ss;
      });
      if (itemsToBeAddedInSpecials && itemsToBeAddedInSpecials.length > 0) {
        let totalCost = 0;
        itemsToBeAddedInSpecials.map((m) => {
          let itemPrice = getItemPriceForSpecialDiscount(m);
          totalCost = totalCost + Number(itemPrice);
          return m;
        });
        discountDiscountObj.discountDiscount =
          totalCost - Number(discount.discountedPrice);
      }
    }
  }
  return discountDiscountObj;
}

function getPersonalizedSpecialFrames(currentOrder, discount, storeConfig) {
  let specialFrames = [];
  if (
    (currentOrder.orderType === '1' && discount.isAvailablePickup) ||
    (currentOrder.orderType === '2' && discount.isAvailableDelivery)
  ) {
    if (currentOrder.deliveryDate) {
      if (
        discount.hasOwnProperty(
          getDayFlag(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').dayText
        ) &&
        discount[
          getDayFlag(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').dayText
        ] === true
      ) {
        let x = {
          _id: discount._id,
          name: discount.discountCode,
          type: discount.type,
          isAvailablePickup: discount.isAvailablePickup,
          isAvailableDelivery: discount.isAvailableDelivery,
          isComplete: false,
          slots: [],
        };
        discount.selectedCategories &&
          discount.selectedCategories.map((sp) => {
            new Array(Number(sp.quantity)).fill().map((_m, i) => {
              let u = {
                categoryKeys: sp.categories
                  .filter((s) => {
                    return s.isActive;
                  })
                  .map((s) => s._id),
                sizeKeys: sp.menuSizes
                  ? sp.menuSizes
                      .filter((s) => {
                        return s.isActive;
                      })
                      .map((s) => s._id)
                  : [],
                modifierKeys: sp.subModifiers
                  ? sp.subModifiers
                      .filter((s) => {
                        return s.isActive;
                      })
                      .map((s) => s._id)
                  : [],
                menuItems: sp.menuItems
                  .filter((s) => {
                    return !s.isActive;
                  })
                  .map((s) => s._id),
                id: i + 1,
                isSlotOccupied: false,
              };
              x.slots.push(u);
              return _m;
            });
            return sp;
          });
        x.slotLength = x.slots.length;
        specialFrames.push(x);
      }
    } else {
      if (
        discount.hasOwnProperty(
          getCurrentDayFlag(storeConfig.timeZone)?.dayText
        ) &&
        discount[getCurrentDayFlag(storeConfig.timeZone)?.dayText] === true
      ) {
        let x = {
          _id: discount._id,
          name: discount.discountCode,
          type: discount.type,
          isAvailablePickup: discount.isAvailablePickup,
          isAvailableDelivery: discount.isAvailableDelivery,
          isComplete: false,
          slots: [],
        };
        discount.selectedCategories &&
          discount.selectedCategories.map((sp) => {
            new Array(Number(sp.quantity)).fill().map((_m, i) => {
              let u = {
                categoryKeys: sp.categories
                  .filter((s) => {
                    return s.isActive;
                  })
                  .map((s) => s._id),
                sizeKeys: sp.menuSizes
                  ? sp.menuSizes
                      .filter((s) => {
                        return s.isActive;
                      })
                      .map((s) => s._id)
                  : [],
                modifierKeys: sp.subModifiers
                  ? sp.subModifiers
                      .filter((s) => {
                        return s.isActive;
                      })
                      .map((s) => s._id)
                  : [],
                menuItems: sp.menuItems
                  .filter((s) => {
                    return !s.isActive;
                  })
                  .map((s) => s._id),
                id: i + 1,
                isSlotOccupied: false,
              };
              x.slots.push(u);
              return _m;
            });
            return sp;
          });
        x.slotLength = x.slots.length;
        specialFrames.push(x);
      }
    }
  }
  return specialFrames;
}

export default getDiscount;
