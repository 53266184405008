import { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector
} from '../../pages/Dashboard/selectors';

import ItemPriceIncrease from '../ItemPriceIncrease';

import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { getPercentAmount } from '../../util/utils';

import { InfoThickIcon as IconInfoThick } from 'nexticons/solid';
import { BadgeUI, ButtonUI, ManualAmountUI } from '../../UI/components';

import './bem-item-manual-amount.scss';

const ItemManualPriceAmount = forwardRef(
  (
    {
      price,
      originalPrice,
      onBack,
      onApply,
      currencySymbol,
      id,
      increaseAllowed,
      title,
      applyButtonText,
      originalTextLabel
    },
    ref
  ) => {
    const currencyCode = useSelector(currencySymbolSelector);
    const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

    const [discountPercentage, setDiscountPercentage] = useState();
    const [discountError, setDiscountError] = useState(false);
    const [priceIncreaseModal, showPriceIncreaseModal] = useState(false);
    const [manualValue, setManualValue] = useState(Number(price).toFixed(2));

    const [reset, setReset] = useState(true);

    const handleValueChange = (value) => {
      setReset(false);
      const percentage = Math.round(getPercentAmount(price - value, price));
      setDiscountPercentage(percentage);
      setManualValue(value);
    };

    const difference = Number(originalPrice) - Number(manualValue);

    const handleApplyDiscount = () => {
      let newSelectedDiscount = '';

      if(difference===0){
        onApply({updatedValue:manualValue});
      }

      
      if ((difference < 0 && increaseAllowed) || difference > 0) {
        if (difference) {
          newSelectedDiscount = {
            discount: Number(difference),
            discountName: `%${discountPercentage}`,
            discountType: '3',
            discountPercentage,
            updatedValue:manualValue
          };
        }

        onApply(newSelectedDiscount);
      }
    };

    useEffect(() => {
      if (difference < 0 && !increaseAllowed) {
        setDiscountError('Increase not allowed');
      } else if (difference >= 0) {
        setDiscountError(false);
      }
    }, [difference, increaseAllowed]);

    useEffect(() => {
      setDiscountError(false);
      showPriceIncreaseModal(false);

      setManualValue(Number(price).toFixed(2));
      setReset(true);
    }, [price]);

    const onBackClick = () => {
      setReset(true);
      onBack();
    };

    return (
      <>
        <ManualAmountUI
          id={id}
          ref={ref}
          title={title||"Enter Price Value"}
          manualType="money"
          manualValue={manualValue}
          setManualValue={handleValueChange}
          submitBtnLabel={applyButtonText||"Apply Item Price"}
          submitBtnStyleType="success"
          handleSubmit={handleApplyDiscount}
          backFunc={onBackClick}
          currencySymbol={currencySymbol}
          disableApply={Boolean(discountError)}
          textColorStyle={Boolean(discountError) ? 'danger' : 'default'}
          reset={reset}
        >
          {/* TODO: add margin bottom to all UI components */}
          {discountError ? (
            <>
              <ButtonUI
                styleType="danger-light"
                icon={<IconInfoThick width="24" color="var(--dangerColor)" />}
                iconPosition="left"
                sizeType="xs"
                fontSize="xl"
                onClick={showPriceIncreaseModal}
              >
                {discountError}
              </ButtonUI>
            </>
          ) : (
            <BadgeUI
              styleType="primary-light"
              title={`${originalTextLabel||"Original Price"} ${getAmountWithCountryCurrency({
                amount: Number(originalPrice),
                locale: `en-${storeCountryIdentifier}`,
                currencyCode,
              })}`}
            />
          )}
        </ManualAmountUI>
        {priceIncreaseModal && (
          <ItemPriceIncrease onClose={() => showPriceIncreaseModal(false)} />
        )}
      </>
    );
  }
);

export default ItemManualPriceAmount;
