import React from 'react';
import { PosIcon } from '../../assets/icons/PosIcon';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';

const ShiftsButton = ({
  title,
  type = 'button',
  handleOnClick = () => {},
  iconName = '',
  buttonTheme = '',
  isLoading = false,
  isActiveRole = false,
}) => {
  if (isActiveRole) return null;
  const buttonThemeClass = buttonTheme
    ? `clock-in-out-button__icon-box--color-theme-${buttonTheme}`
    : '';
  return (
    <button
      className="clock-in-out-button"
      title={title}
      type={type}
      onClick={handleOnClick}
    >
      <span className="clock-in-out-button__text">{title}</span>
      <span className={`clock-in-out-button__icon-box ${buttonThemeClass}`}>
        {isLoading ? (
          <PosIconLoading mainColor="#ffffff" />
        ) : (
          <PosIcon
            name={iconName}
            mainColor="#ffffff"
            darkModeColor="#ffffff"
          />
        )}
      </span>
    </button>
  );
};

export default React.memo(ShiftsButton);
