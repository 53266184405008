import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  isItemWithSingleSize,
  incrementItemQuantitySingleSize,
  doesItemHaveModifiers,
  updateModifier,
  updateSize,
} from '../../itemProcessor';
import {
  openMenuItem,
  updateSpecialsCurrentHalfHalfItem,
} from '../../pages/Dashboard/action';
import { useLayoutEffect } from 'react';
import HalfHalfMenuItems from '../HalfHalfMenuItems';
import { getSingleSizedItemWithMeta } from '../HalfHalfMenuItems/HalfHalfMenuItemsWrapper';

const SpecialsHalfHalfMenuItems = ({ parentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSpecialsItem = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem
  );
  const { categoryIndex, specialsId, halfIndex } = useParams();
  const currentHalfHalfData =
    currentSpecialsItem.selectedCategories[categoryIndex]
      ?.currentHalfHalfData || {};
  const halfHalf =
    currentHalfHalfData.halfHalfs?.find(
      (h) => h.halfIndex === Number(halfIndex)
    ) || {};

  const { variantSelected } = currentHalfHalfData;

  const backLink = `${parentPage}/specials/${specialsId}/${categoryIndex}/half-half`;
  // auto select single-sized item if menuItem.length === 1
  useLayoutEffect(() => {
    if (specialsId) {
      if (halfHalf.menuItems?.length === 1) {
        const item = halfHalf.menuItems[0];
        if (isItemWithSingleSize(item)) {
          dispatch(
            updateSpecialsCurrentHalfHalfItem(parseInt(categoryIndex), {
              ...incrementItemQuantitySingleSize(item, 0.5),
              halfIndex: parseInt(halfIndex),
              isHalf: true,
              isSecondHalf: parseInt(halfIndex) === 2,
            })
          );
          history.push(backLink);
        }
      }
    }
  }, [
    halfHalf.menuItems,
    history,
    backLink,
    dispatch,
    halfIndex,
    parentPage,
    specialsId,
    categoryIndex,
  ]);

  const handleMenuItemClick = (_event, item) => {
    if (doesItemHaveModifiers(item)) {
      const linkToModify = `${parentPage}/menuitem/${item?._id}/modifiers/${item?.selectedModifiers[0]?._id}`;
      const sizeModifier = item?.selectedModifiers?.filter(
        (modifier) => modifier.name === 'size'
      );
      const updatedItem = updateModifier(
        { ...item, isHalf: true },
        sizeModifier[0]?.subModifiers[0]?._id,
        sizeModifier[0]?._id,
        variantSelected
          ? sizeModifier[0]?.subModifiers[0]?.variants[0]?._id
          : null
      );
      history.push(linkToModify);
      dispatch(
        updateSpecialsCurrentHalfHalfItem(parseInt(categoryIndex), {
          ...updatedItem,
          halfIndex: parseInt(halfIndex),
          isHalf: true,
          isSecondHalf: parseInt(halfIndex) === 2,
        })
      );
      dispatch(
        openMenuItem(
          {
            ...updatedItem,
            halfIndex: parseInt(halfIndex),
            isHalf: true,
            isSecondHalf: parseInt(halfIndex) === 2,
          },
          false
        )
      );
    } else if (variantSelected) {
      const { selectedSizes } = item;
      const updatedItem = updateSize(
        { ...item, isHalf: true },
        selectedSizes[0]?._id,
        selectedSizes[0]?.variants[0]?._id
      );
      dispatch(
        updateSpecialsCurrentHalfHalfItem(parseInt(categoryIndex), {
          ...updatedItem,
          halfIndex: parseInt(halfIndex),
          isHalf: true,
          isSecondHalf: parseInt(halfIndex) === 2,
        })
      );
      history.push(backLink);
      return;
    } else {
      const upItem = getSingleSizedItemWithMeta(item, halfIndex, true);
      dispatch(
        updateSpecialsCurrentHalfHalfItem(parseInt(categoryIndex), upItem)
      );
      history.push(backLink);
    }
  };

  return (
    <HalfHalfMenuItems
      halfHalfData={halfHalf}
      backLink={backLink}
      onItemClick={handleMenuItemClick}
    />
  );
};

export default SpecialsHalfHalfMenuItems;
