import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { PosIconBagInverse } from '../../assets/icons/PosIconBagInverse';
import { DividerUI, ScrollableBoxUI } from '../../UI/components';
import { getCartItemKey } from '../../util/cartItemUtils';
import { getSegregatedMenuItemTypesWithInsertOrderIntact } from '../../util/half-and-half-utils';
import { CartItem } from './CartItem';
import { updateCurrentOrder } from 'pages/Dashboard/action';

const CartItems = ({
  showPrice,
  lightMode,
  fullScreen,
  currentOrder,
  basePath,
  setNewItemsExist,
  customStyle,
  cartId = '',
  customEmptyState = null,
  disableDragAndDrop = false,
  provided,
}) => {
  // const dispatch = useDispatch();

  // const onDropMenuItem = (menuItem) => {
  //   let updatedMenuItem = [];
  //   if (Array.isArray(menuItem)) {
  //     updatedMenuItem = currentOrder?.menuItems?.map((item) => {
  //       let updatedItem = { ...item };
  //       menuItem.forEach((mItem) => {
  //         if (item.orderIndex === mItem.orderIndex) {
  //           updatedItem = { ...updatedItem, activeSeatId: cartId };
  //         }
  //       });
  //       return updatedItem;
  //     });
  //   } else {
  //     updatedMenuItem = currentOrder?.menuItems?.map((item) => {
  //       let updatedActiveSeatId = item?.activeSeatId || '';
  //       if (menuItem?.orderIndex === item?.orderIndex) {
  //         updatedActiveSeatId = cartId;
  //       }
  //       return {
  //         ...item,
  //         activeSeatId: updatedActiveSeatId,
  //       };
  //     });
  //   }

  //   setNewItemsExist(true);
  //   dispatch(
  //     updateCurrentOrder({
  //       ...currentOrder,
  //       menuItems: updatedMenuItem,
  //     })
  //   );
  // };

  let refScrollBar = useRef();
  const hasItems = !!currentOrder.menuItems?.length;
  const hasSpecificSeatCartItem = !!currentOrder.menuItems?.find(
    (item) => item?.activeSeatId === cartId
  );
  const applyHasSpecificSeatCartItemFilter = !!cartId
    ? hasSpecificSeatCartItem
    : true;
  const location = useLocation();
  const isShowOrderButton = hasItems && location.pathname.endsWith('/order');
  const listCls = clsx({
    'seats-list-wrap single': true,
    empty: !isShowOrderButton,
  });
  const { iteration: currentIteration } = currentOrder;
  const { prevIteration } = currentOrder;

  const productSetup =
    useSelector((state) => state.dashboardReducer.productSetup) || {};

  const allOrdersWithHalfAndHalfSegregatedInTuples =
    getSegregatedMenuItemTypesWithInsertOrderIntact(
      currentOrder?.menuItems || []
    );

  lightMode = lightMode !== undefined && lightMode ? true : false;
  fullScreen = fullScreen !== undefined && fullScreen ? true : false;

  const prevItemsInCart = allOrdersWithHalfAndHalfSegregatedInTuples.filter(
    (menuItem) => {
      const item = Array.isArray(menuItem) ? menuItem[0] : menuItem;
      const seatFilter = item?.activeSeatId === cartId;
      const applySeatFilter = !!cartId ? seatFilter : true;
      return item.iteration < currentIteration && applySeatFilter;
    }
  );

  const newItemsInCart = allOrdersWithHalfAndHalfSegregatedInTuples.filter(
    (menuItem) => {
      const item = Array.isArray(menuItem) ? menuItem[0] : menuItem;
      const seatFilter = item?.activeSeatId === cartId;
      const applySeatFilter = !!cartId ? seatFilter : true;
      return item.iteration === currentIteration && applySeatFilter;
    }
  );

  const itemsLength = useRef(0);
  useEffect(() => {
    if (newItemsInCart.length > itemsLength.current) {
      setNewItemsExist(true);
      itemsLength.current = newItemsInCart.length;
    } else {
      setNewItemsExist(false);
    }
  }, [newItemsInCart.length, setNewItemsExist]);

  return (
    <div
      className={clsx(listCls, cartId)}
      style={customStyle}
      ref={provided.innerRef}
    >
      {hasItems && applyHasSpecificSeatCartItemFilter ? (
        <ScrollableBoxUI ref={refScrollBar}>
          {prevItemsInCart.map((menuItem) => (
            <CartItem
              key={getCartItemKey(menuItem)}
              lightMode={lightMode}
              fullScreen={fullScreen}
              showPrice={showPrice}
              menuItem={menuItem}
              firedCourse={currentOrder.calledCourses?.includes(
                menuItem.courseId
              )}
              basePath={basePath}
              placeholderFourQuarterImage={
                productSetup.fourQuarterPlaceHolderImage || ''
              }
              productSetup={productSetup}
              wrapRef={refScrollBar}
              disableDragAndDrop={disableDragAndDrop}
            />
          ))}

          {typeof prevIteration !== 'undefined' &&
          prevIteration < currentIteration &&
          newItemsInCart.length > 0 ? (
            <DividerUI title="New items" />
          ) : null}

          {newItemsInCart.map((menuItem) => (
            <CartItem
              key={getCartItemKey(menuItem)}
              lightMode={lightMode}
              fullScreen={fullScreen}
              showPrice={showPrice}
              menuItem={menuItem}
              basePath={basePath}
              placeholderFourQuarterImage={
                productSetup.fourQuarterPlaceHolderImage || ''
              }
              productSetup={productSetup}
              wrapRef={refScrollBar}
              disableDragAndDrop={disableDragAndDrop}
            />
          ))}
          {provided?.placeholder}
        </ScrollableBoxUI>
      ) : !!customEmptyState ? (
        customEmptyState
      ) : (
        <div className="seat-items-empty">
          <PosIconBagInverse mainColor="#d1d1d1" darkModeColor="#d1d1d1" />
          <p className="text">Order is empty</p>
        </div>
      )}
    </div>
  );
};

export default CartItems;
