import { API_URL } from 'configuration/apiUrl';
import { _auth } from '../firebase';

const checkIfInsideRadiusApi = async (requestObj) => {
  const URL = `${API_URL}/deliveryLocations/v1/checkIfInsideRadius`;
  const urlParams = new URLSearchParams(requestObj);
  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await fetch(`${URL}?${urlParams.toString()}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();
  return data;
};

export const getDeliveryCostFromApi = async (storeId, locationCoordinates) => {
  const requestObj = {
    storeId,
    destinationLat: locationCoordinates.lat,
    destinationLong: locationCoordinates.lng,
  };
  const result = await checkIfInsideRadiusApi(requestObj);
  const { data, errors = [] } = result;
  if (errors.length > 0) {
    return false;
  }
  const { deliveryInfo } = data;
  const { deliveryCost, isDeliverable, minOrderValue } = deliveryInfo;
  return { isDeliverable, deliveryCost, minOrderValue };
};

export const createSplitPayment = async (requestObj) => {
  const URL = `${API_URL}/splitPayments`;
  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await fetch(`${URL}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestObj),
  });
  const data = await response.json();

  return data;
};

export const updateSplitPayment = async (requestObj) => {
  const URL = `${API_URL}/splitPayments`;
  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await fetch(`${URL}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestObj),
  });
  const data = await response.json();

  return data;
};

export const updateSplitPaymentPartial = async (requestObj) => {
  const URL = `${API_URL}/splitPayments`;
  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await fetch(`${URL}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestObj),
  });
  const data = await response.json();

  return data;
};

export const getSplitPaymentState = async (orderId) => {
  const URL = `${API_URL}/splitPayments`;
  const urlParams = new URLSearchParams({ orderId });
  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await fetch(`${URL}?${urlParams.toString()}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();
  return data;
};
