import { DollarSquareIcon, ChairIcon } from 'nexticons/solid';
import * as Icon from 'nexticons/outline';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
import JaneAppleSeed from 'assets/img/Jane_Appleseed.png';
import { useSelector } from 'react-redux';
import React, {
  useMemo,
  useState,
  useReducer,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  ButtonGroupUI,
  ButtonUI,
  CounterUI,
  LogoNavButtonUI,
  NavBarUI,
  NavButtonUI,
  TabsNavigationUI,
} from 'UI/components';
import { useDispatch } from 'react-redux';
import { ModalPortal, AlertModal, SupportNav } from 'components';
import { useOrdersData } from 'hooks';
import SplitBillDiscountModal from 'modals/SplitBillDiscountModal';
import SplitPerItem from './SplitPerItem';
import CustomAmountModal from './CustomAmountModal';
import PaymentModal from './PaymentModal';
import SplitEqually from './SplitEqually';
import SplitChecksEqually from './SplitChecksEqually';
import SplitPerSeat from './SplitPerSeat';
import { _auth, _firestore } from '../../firebase';
import { setOrders } from '../Orders/newOrdersReducer';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { getSplitPaymentsData } from './SplitPerItem.service';
import { DropdownPrinter } from '../../modals/PrintOrderModal/DropdownPrinter';
import { fetchAllPrinters } from 'services/ordersServices';
import { PrinterContext } from 'context/PrinterContext';
import { getTextWidth } from './util';
import {
  getDiscountAction,
  getVoucherAction,
} from '../../pages/Dashboard/action';

const typeMap = {
  full: 1,
  equal: 2,
  per_item: 3,
  custom: 4,
  split_per_seat: 5,
};

const billTypeMap = {
  1: 'full',
  2: 'equal',
  3: 'per_item',
  4: 'custom',
  5: 'split_per_seat',
};

const updateOrder = async (id, payload, storeConfig, accessToken) => {
  let requestObj = {
    order: {
      ...payload,
      paymentType: '0',
      storeId: storeConfig?.storeId,
      restaurantId: storeConfig.restaurantId,
      countryIdentifier: storeConfig.countryIdentifier,
      doNotUpdateOrderStatus: false,
    },
    payment: { type: '0', amount: 0 },
  };
  return fetch(`${nextOrderConfig.cloudFunctionUrl}/placeOrder3DSecure`, {
    method: 'post',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...requestObj }),
  });
};

const navList = [
  {
    id: 'full',
    name: 'Pay Full Bill',
    icon: (
      <DollarSquareIcon
        width={26}
        color="var(--successColor)"
        strokeWidth={2}
      />
    ),
  },
  {
    id: 'equal',
    name: 'Split Equally',
    icon: (
      <Icon.EquallyIcon
        width={24}
        color="var(--primaryColor)"
        strokeWidth={2}
      />
    ),
  },

  {
    id: 'per_item',
    name: 'Split Per Item',
    icon: (
      <Icon.PerItemIcon
        width={24}
        color="var(--warningColor)"
        strokeWidth={2}
      />
    ),
  },
  {
    id: 'split_per_seat',
    name: 'Split Per Seat',
    icon: <ChairIcon width={26} color="#d12aec" strokeWidth={2} />,
  },
  // Will be use in future
  // {
  //   id: 'custom',
  //   name: 'Custom Amount',
  //   icon: (
  //     <Icon.QuestionSquareIcon
  //       width={26}
  //       color="var(--dangerColor)"
  //       strokeWidth={2}
  //     />
  //   ),
  // },
];

const Payment = ({
  match: {
    params: { orderId },
  },
  history,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDeviationLoading, setIsDeviatonLoading] = useState(false);
  const [billType, setBillType] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [printerWidth, setPrinterWidth] = useState(100);

  useOrdersData();
  // const { data, isLoading } = useQuery(
  //   [`splitPayments`],
  //   () =>
  //     fetch(`${API_URL}/orders/v1/splitPayments?orderId=${orderId}`).then(
  //       (res) => res.json(),
  //       (err) => err
  //     ),
  //   {
  //     refetchOnMount: false,
  //     keepPreviousData: false,
  //   }
  // );

  const dispatch = useDispatch();
  const {
    print,
    printers,
    deviceStatus,
    setPrinters,
    setPrinter,
    printer,
    setDefaultPrinter,
    getDefaultPrinter,
  } = useContext(PrinterContext);

  const storeConfig = useSelector((st) => st.dashboardReducer.storeConfig);
  const staffMember =
    useSelector((state) => state.dashboardReducer.activeStaffMember) || {};
  const { orders } = useSelector((state) => state.newOrdersReducer);
  const appliedVoucher = useSelector(
    (state) => state.dashboardReducer?.appliedVoucher
  );

  const { dineInOccupancies } = useSelector((st) => st.notificationsReducer);
  const [guestCounter, setGuestCounter] = useState(0);

  const [openSplitEquallyFlow, setOpenSplitEquallyFlow] = useState(false);
  const [openSplitPerSeatFlow, setOpenSplitPerSeatFlow] = useState(false);

  const currentOrder = useMemo(
    () => orders.allOrdersMap[orderId],
    [orders.allOrdersMap, orderId, appliedVoucher]
  );

  const currentOrderVoucher = currentOrder?.selectedVoucher;

  const selectedVoucher = currentOrderVoucher?._id
    ? currentOrderVoucher
    : appliedVoucher;

  const voidedItems = currentOrder?.menuItems.filter(
    (item) => item?.isVoided === true
  );

  const currentOccupancy = useMemo(
    () =>
      dineInOccupancies.find(
        (o) => currentOrder && o.id === currentOrder.occupancyId
      ),
    [currentOrder, dineInOccupancies]
  );

  const fullCheck = useMemo(
    () => ({
      number: `0`,
      numGuests: 1,
      payableAmount: parseFloat(currentOrder?.payableAmount),
      payments: [],
    }),
    [currentOrder?.payableAmount]
  );

  const [state, setState] = useReducer((st, action) => ({ ...st, ...action }), {
    orderId,
    numChecks: 1,
    type: `full`, // 1-full,2-eq,3-per_item,4-custom
    checks: currentOrder ? [fullCheck] : [],
    concluded: false,
    alert: false,
    selectedCheckNumber: 0,
    paymentModalOpen: false,
    amountModalOpen: false,
    guests: currentOccupancy?.numPeople || 1,
    initialChecks: [],
    deleteChecksList: [],
  });

  const {
    alert,
    selectedCheckNumber,
    numChecks,
    type,
    concluded,
    paymentModalOpen,
    guests,
    amountModalOpen,
    initialChecks,
    deleteChecksList,
  } = state;

  const [isCheckEditInProcess, setIsEditCheckInProcess] = useState(false);

  const onPrinterChange = (e) => {
    const selectedId = e.target.value;
    printers.forEach((item) => {
      if (item._id === selectedId) {
        item.isPrimaryPrinter = true;
        setPrinter(item);
        setDefaultPrinter(item);
      } else {
        item.isPrimaryPrinter = false;
      }
    });
  };

  const getAllPrinters = async () => {
    const defaultPrinterId = getDefaultPrinter();
    const { printers } = await fetchAllPrinters(storeConfig);

    setPrinters(printers);
    const defaultSelectedPrinter =
      printers.find((item) => {
        return item._id === defaultPrinterId;
      }) || printers[0];
    setPrinter(defaultSelectedPrinter);
    setDefaultPrinter(defaultSelectedPrinter);
  };

  const orderPaymentsMap = useMemo(() => {
    return (currentOrder?.payments || []).reduce(
      (a, v) => ({ ...a, [v._id]: v }),
      {}
    );
  }, [JSON.stringify(currentOrder?.payments)]);

  const checks = useMemo(
    () =>
      state.checks.map((check) => {
        let paidAmount = check.paidAmount;
        if (!paidAmount && Array.isArray(check.payments)) {
          const payments = check.payments.map((id) => orderPaymentsMap[id]);
          paidAmount = payments
            .filter((i) => i)
            .reduce((a, v) => (a += v.amount), 0);
          paidAmount = parseFloat(
            Math.min(check.payableAmount, paidAmount).toFixed(2)
          );
        }
        return { ...check, paidAmount };
      }),
    [state.checks, orderPaymentsMap]
  );

  const selectedCheck = useMemo(() => {
    return checks.find((check) => Number(check.number) === selectedCheckNumber);
  }, [checks, selectedCheckNumber]);

  const totalCustomAmount = useMemo(
    () =>
      type === `custom`
        ? checks.reduce((a, v) => (a += v.payableAmount), 0)
        : 0,
    [type, checks]
  );

  const selectCheck = useCallback(
    (n) => {
      setState({ selectedCheckNumber: n });
    },
    [setState]
  );
  const setGuests = useCallback((guests) => setState({ guests }), [setState]);
  const toggleAmountModal = useCallback(
    (v) => setState({ amountModalOpen: !amountModalOpen }),
    [amountModalOpen]
  );
  const togglePaymentModal = useCallback(
    (v) => setState({ paymentModalOpen: v }),
    []
  );

  const creatingChecks = async (
    splitEquallyPaymentPayload,
    oldChecks,
    type
  ) => {
    const checksWithCheckId = oldChecks.filter((check) => check.id) || [];

    if (currentOrder.payments.length === 0) {
      setState({
        numChecks: splitEquallyPaymentPayload?.checks.length,
        checks: splitEquallyPaymentPayload?.checks || [],
      });
    }
  };

  useEffect(async () => {
    getAllPrinters();

    const token = await _auth.currentUser.getIdToken(true);
    setAccessToken(token);
  }, []);

  // If order status is paid show order settled modal
  useEffect(() => {
    if (currentOrder?.paymentStatus === 1) {
      setState({ concluded: true, alert: 'This order is settled' });
    }
  }, [currentOrder?.paymentStatus]);

  // If order does not exist go back
  useEffect(() => {
    const fetchOrder = async () => {
      const orderRef = _firestore.collection('Orders').doc(orderId);

      const doc = await orderRef.get();

      if (doc.exists) {
        dispatch(
          setOrders({
            allOrdersMap: {
              ...orders.allOrdersMap,
              [orderId]: { id: orderId, ...doc.data() },
            },
            allOrdersList: [...orders.allOrdersList, orderId],
          })
        );
      } else {
        history.goBack();
      }
    };

    if (!orderId || typeof currentOrder === `undefined`) {
      fetchOrder();
    }
  }, [
    orderId,
    currentOrder,
    orders.allOrdersList,
    orders.allOrdersMap,
    dispatch,
    history,
  ]);

  // If split type changed reinitialize checks
  useEffect(async () => {
    if (accessToken) {
      setIsLoading(true);

      const responseData = currentOrder.isSplitPayment
        ? await getSplitPaymentsData(orderId, accessToken)
        : null;

      // when the Order exists
      if (
        responseData?.data?.splitPayment &&
        responseData?.data?.splitPayment?.orderId === orderId &&
        responseData?.data?.splitPayment?.checks?.length > 0
      ) {
        const { splitPayment } = responseData.data;

        setState({
          ...splitPayment,
          concluded: true,
          type: [``, `full`, `equal`, `per_item`, `custom`, 'split_per_seat'][
            splitPayment.type
          ],
          initialChecks: splitPayment.checks,
        });
        setBillType(
          [``, `full`, `equal`, `per_item`, `custom`, 'split_per_seat'][
            splitPayment.type
          ]
        );
        setGuests(
          guestCounter ||
            splitPayment.checks.reduce((a, c) => (a += c.numGuests), 0)
        );
      }

      // when the order is updated, we need to update the checks using iteration
      if (currentOrder?.iteration > 0) {
        //Edit Flow If checks type is split Equally
        if (checks.length > 1 && type === 'equal') {
          const totalChecksAmount = Number(
            checks
              .reduce((amount, check) => amount + check.payableAmount, 0)
              .toFixed(2)
          );
          if (currentOrder.payableAmount > totalChecksAmount) {
            const paidChecksNum = checks.filter((check) => {
              return (
                Number(check.payableAmount.toFixed(2)) === check.paidAmount
              );
            }).length;

            let iterationList = currentOrder.menuItems?.map((item) => {
              return item.iteration;
            });

            const latestIteration =
              iterationList.length > 0 ? Math.max(...iterationList) : 0;
            const updatedChecks = [];

            const newMenuItems = currentOrder.menuItems.filter(
              (item) => item.iteration === latestIteration
            );

            const parsedNewMenuItems = newMenuItems.map((item) => {
              return {
                itemId: item._id,
                name: item.name,
                orderIndexes: [item.orderIndex],
                share: 1 / (checks.length - paidChecksNum),
              };
            });

            const newMenuItemsPrice = newMenuItems.reduce(
              (total, item) => total + item.payablePrice,
              0
            );
            if (checks.length > 1 && latestIteration > 0) {
              checks.forEach((check) => {
                if (
                  Number(check.payableAmount.toFixed(2)) === check.paidAmount
                ) {
                  updatedChecks.push({
                    ...check,
                  });
                } else {
                  updatedChecks.push({
                    ...check,
                    items: [...check?.items, ...parsedNewMenuItems],
                    payableAmount:
                      check.payableAmount +
                      newMenuItemsPrice * (1 / (checks.length - paidChecksNum)),
                  });
                }
              });

              const checksTotal = updatedChecks.reduce(
                (acc, c) => (acc += Number(c.payableAmount.toFixed(2))),
                0
              );

              const deviation = parseFloat(
                (parseFloat(currentOrder.payableAmount) - checksTotal).toFixed(
                  2
                )
              );

              if (Math.abs(deviation) > 0 && Math.abs(deviation) <= 1) {
                // there is only one possible deviation for 2 points precision

                const newChecks = [
                  {
                    ...updatedChecks[updatedChecks.length - 1],
                    payableAmount: Number(
                      (
                        updatedChecks[updatedChecks.length - 1].payableAmount +
                        deviation
                      ).toFixed(2)
                    ),
                  },
                  ...updatedChecks.slice(0, updatedChecks.length - 1),
                ];

                setState({ checks: newChecks });
              } else {
                setState({ checks: updatedChecks });
              }
            }
          }
          setOpenSplitEquallyFlow(true);
        }
        //Edit Flow If checks type is split per Seat
        if (checks.length > 1 && type === 'split_per_seat') {
          let totalChecksAmount = Number(
            checks
              .reduce((amount, check) => amount + check.payableAmount, 0)
              .toFixed(2)
          );

          if (currentOrder.payableAmount > totalChecksAmount) {
            const paidChecksNum = checks.filter((check) => {
              return (
                Number(check.payableAmount.toFixed(2)) === check.paidAmount
              );
            }).length;

            let iterationList = currentOrder.menuItems?.map((item) => {
              return item.iteration;
            });

            const latestIteration =
              iterationList.length > 0 ? Math.max(...iterationList) : 0;
            const updatedChecks = [];

            // const discountData = currentOrder?.selectedDiscount;

            if (checks.length > 1 && latestIteration > 0) {
              const parsedMenuItemsData = currentOrder?.menuItems.map(
                (item) => {
                  // share will be 1 if item is not in shared section
                  let share = 1;

                  if (item?.activeSeatId === 'Shared') {
                    const totalSeats = currentOrder?.allSeatsData?.length;
                    share = 1 / totalSeats;
                  }

                  return {
                    itemId: item._id,
                    orderIndexes: [item.orderIndex],
                    name: item.name,
                    share: Number(share.toFixed(3)),
                    activeSeatId: item?.activeSeatId,
                    itemPayablePrice: item?.payablePrice,
                  };
                }
              );

              const sharedMenuItemsOldandNew = parsedMenuItemsData.filter(
                (item) => item?.activeSeatId === 'Shared'
              );

              const discountData = currentOrder?.selectedDiscount;
              const newInitialChecks = currentOrder?.allSeatsData.map(
                (seat, index) => {
                  if (
                    !!checks?.find(
                      (check) => check?.activeSeatId === seat?.seatId
                    )?.id
                  ) {
                    return checks?.find(
                      (check) => check?.activeSeatId === seat?.seatId
                    );
                  } else {
                    return {
                      activeSeatId: seat?.seatId,
                      numGuests: 1,
                      number: `${index}`,
                      paidAmount: undefined,
                      items: [],
                      payments: [],
                    };
                  }
                }
              );

              newInitialChecks.forEach((check) => {
                const checkItemsOldandNew = parsedMenuItemsData.filter(
                  (menuItem) => menuItem?.activeSeatId === check?.activeSeatId
                );

                const seatMenuItemAndSharedItems = [
                  ...checkItemsOldandNew,
                  ...sharedMenuItemsOldandNew,
                ];

                let seatMenuItemsPrice = seatMenuItemAndSharedItems.reduce(
                  (total, seatItem) => {
                    const isItemVoided = voidedItems.find(
                      (item) =>
                        item._id === seatItem.itemId &&
                        seatItem?.orderIndexes?.includes(item.orderIndex)
                    );
                    if (isItemVoided?._id) {
                      return total;
                    }
                    return (
                      total +
                      seatItem.itemPayablePrice *
                        Number(seatItem?.share.toFixed(3))
                    );
                  },
                  0
                );
                if (
                  currentOrder?.discountId &&
                  Number(currentOrder?.discount || 0) > 0
                ) {
                  if (discountData?.discountType === '1') {
                    seatMenuItemsPrice =
                      seatMenuItemsPrice *
                      (Number(discountData?.discount) / 100);
                  } else if (discountData?.discountType === '2') {
                    seatMenuItemsPrice =
                      seatMenuItemsPrice -
                      Number(discountData?.discount) /
                        Number(currentOrder.allSeatsData?.length);
                  }
                }
                // Calculate voucher
                if (
                  currentOrder?.voucherId &&
                  Number(currentOrder?.voucherDiscount || 0) > 0
                ) {
                  if (selectedVoucher?.type === '1') {
                    seatMenuItemsPrice =
                      seatMenuItemsPrice *
                      (Number(selectedVoucher?.discount) / 100);
                  } else if (selectedVoucher?.type === '2') {
                    seatMenuItemsPrice =
                      seatMenuItemsPrice -
                      Number(selectedVoucher?.discount) /
                        Number(currentOrder.allSeatsData?.length);
                  }
                }

                // Calculate public holiday surcharge
                if (Number(currentOrder?.publicHolidaySurcharge) > 0) {
                  seatMenuItemsPrice =
                    seatMenuItemsPrice +
                    seatMenuItemsPrice *
                      (Number(currentOrder?.publicHolidaySurchargePer) / 100);
                }
                // Calculate special discount
                if (Number(currentOrder?.specialDiscount) > 0) {
                  seatMenuItemsPrice =
                    seatMenuItemsPrice -
                    Number(currentOrder?.specialDiscount) /
                      Number(currentOrder.allSeatsData?.length);
                }

                // Calculate Serivce Charge
                if (Number(currentOrder?.serviceCharge) > 0) {
                  seatMenuItemsPrice =
                    seatMenuItemsPrice +
                    Number(currentOrder?.serviceCharge) /
                      currentOrder?.allSeatsData.length;
                }

                updatedChecks.push({
                  ...check,
                  items: seatMenuItemAndSharedItems,
                  payableAmount: parseFloat(seatMenuItemsPrice.toFixed(2)),
                });
              });

              const checksTotal = updatedChecks.reduce(
                (acc, c) => (acc += Number(c.payableAmount.toFixed(2))),
                0
              );

              const deviation = parseFloat(
                (parseFloat(currentOrder.payableAmount) - checksTotal).toFixed(
                  2
                )
              );

              if (Math.abs(deviation) > 0 && Math.abs(deviation) <= 1) {
                // there is only one possible deviation for 2 points precision

                const newChecks = [
                  {
                    ...updatedChecks[updatedChecks.length - 1],
                    payableAmount: Number(
                      (
                        updatedChecks[updatedChecks.length - 1].payableAmount +
                        deviation
                      ).toFixed(2)
                    ),
                  },
                  ...updatedChecks.slice(0, updatedChecks.length - 1),
                ];

                setState({ checks: newChecks });
              } else {
                setState({ checks: updatedChecks });
              }
            }
          }
        }
      }

      if (
        !responseData?.data ||
        responseData?.data?.splitPayment?.checks?.length === 0 ||
        guestCounter
        // ||
        // data?.data?.splitPayment?.type === 3
      ) {
        switch (type) {
          case `full`:
            setState({
              numChecks: 1,
              checks: [fullCheck],
            });

            selectCheck(0);
            if (!responseData?.data) {
              setBillType('full');
            }

            break;
          case `per_item`:
            if (responseData?.data?.splitPayment.type !== 3) {
              setState({
                numChecks: 1,
                checks: [{ ...fullCheck, items: [] }],
              });

              selectCheck(0);
              setOpenSplitEquallyFlow(false);
              setOpenSplitPerSeatFlow(false);
              setBillType('per_item');
            }

            break;
          case `equal`:
            let newChecks = [];

            let menuItems = currentOrder?.menuItems.map((item) => {
              return {
                itemId: item._id,
                orderIndexes: [item.orderIndex],
                name: item.name,
                share: Number((1 / (guestCounter || guests)).toFixed(3)),
              };
            });
            for (let i = 0; i < (guestCounter || guests); i++) {
              newChecks.push({
                number: `${i}`,
                numGuests: 1,
                payableAmount: parseFloat(
                  (
                    parseFloat(fullCheck.payableAmount) /
                    (guestCounter || guests)
                  ).toFixed(2)
                ),
                payments: [],
                items: menuItems,
              });
            }

            const splitEquallyPaymentPayload = {
              checks: newChecks,
              numChecks: newChecks.length || 0,
              orderId: currentOrder._id,
            };

            await creatingChecks(splitEquallyPaymentPayload, checks, 'equal');

            selectCheck(0);
            setOpenSplitEquallyFlow(true);
            setBillType('equal');

            break;
          case 'split_per_seat':
            let allSeatChecks = [];

            const discountData = currentOrder?.selectedDiscount;
            const parsedMenuItemsData = currentOrder?.menuItems.map((item) => {
              // share will be 1 if item is not in shared section
              let share = 1;

              if (item?.activeSeatId === 'Shared') {
                const totalSeats = currentOrder?.allSeatsData?.length;
                share = 1 / totalSeats;
              }

              return {
                itemId: item._id,
                orderIndexes: [item.orderIndex],
                name: item.name,
                share: Number(share.toFixed(3)),
                activeSeatId: item?.activeSeatId,
                itemPayablePrice: item?.payablePrice,
              };
            });

            const sharedMenuItems = parsedMenuItemsData.filter(
              (item) => item?.activeSeatId === 'Shared'
            );

            currentOrder?.allSeatsData.forEach((seat, index) => {
              const seatMenuItems = parsedMenuItemsData.filter(
                (item) => item?.activeSeatId === seat?.seatId
              );

              const seatMenuItemAndSharedItems = [
                ...seatMenuItems,
                ...sharedMenuItems,
              ];

              let seatMenuItemsPrice = seatMenuItemAndSharedItems.reduce(
                (total, seatItem) => {
                  const isItemVoided = voidedItems.find(
                    (item) =>
                      item._id === seatItem.itemId &&
                      seatItem?.orderIndexes?.includes(item.orderIndex)
                  );
                  if (isItemVoided?._id) {
                    return total;
                  }
                  return (
                    total +
                    seatItem.itemPayablePrice *
                      Number(seatItem?.share.toFixed(3))
                  );
                },
                0
              );
              // Calculate discount
              if (
                currentOrder?.discountId &&
                Number(currentOrder?.discount || 0) > 0
              ) {
                if (discountData?.discountType === '1') {
                  seatMenuItemsPrice =
                    seatMenuItemsPrice * (Number(discountData?.discount) / 100);
                } else if (discountData?.discountType === '2') {
                  seatMenuItemsPrice =
                    seatMenuItemsPrice -
                    Number(discountData?.discount) /
                      Number(currentOrder.allSeatsData?.length);
                }
              }
              // Calculate voucher
              if (
                currentOrder?.voucherId &&
                Number(currentOrder?.voucherDiscount || 0) > 0
              ) {
                if (selectedVoucher?.type === '1') {
                  seatMenuItemsPrice =
                    seatMenuItemsPrice *
                    (Number(selectedVoucher?.discount) / 100);
                } else if (selectedVoucher?.type === '2') {
                  seatMenuItemsPrice =
                    seatMenuItemsPrice -
                    Number(selectedVoucher?.discount) /
                      Number(currentOrder.allSeatsData?.length);
                }
              }

              // Calculate public holiday surcharge
              if (Number(currentOrder?.publicHolidaySurcharge) > 0) {
                seatMenuItemsPrice =
                  seatMenuItemsPrice +
                  seatMenuItemsPrice *
                    (Number(currentOrder?.publicHolidaySurchargePer) / 100);
              }
              // Calculate special discount
              if (Number(currentOrder?.specialDiscount) > 0) {
                seatMenuItemsPrice =
                  seatMenuItemsPrice -
                  Number(currentOrder?.specialDiscount) /
                    Number(currentOrder.allSeatsData?.length);
              }

              // Calculate Serivce Charge
              if (Number(currentOrder?.serviceCharge) > 0) {
                seatMenuItemsPrice =
                  seatMenuItemsPrice +
                  Number(currentOrder?.serviceCharge) /
                    currentOrder?.allSeatsData.length;
              }

              allSeatChecks.push({
                number: `${index}`,
                numGuests: 1,
                payableAmount: parseFloat(seatMenuItemsPrice.toFixed(2)),
                payments: [],
                items: seatMenuItemAndSharedItems,
                activeSeatId: seat?.seatId,
              });
            });

            setState({
              numChecks: allSeatChecks.length,
              checks: allSeatChecks || [],
            });

            setBillType('split_per_seat');
            setOpenSplitPerSeatFlow(true);
            break;

          case `custom`:
            setState({ numChecks: 0, checks: [] });
            toggleAmountModal();
            break;
          default:
            break;
        }
      }
      setIsLoading(false);
    }
  }, [
    type,
    guests,
    currentOrder?.payableAmount,
    guestCounter,
    accessToken,
    currentOrder?.voucherId,
    selectedVoucher,
  ]);

  useEffect(() => {
    setPrinterWidth(getTextWidth(printer?.nickName, '18px sans-serif') + 135);
  }, [printer?.deviceName]);

  // If there is floating precision error, adjust the first check for deviation
  useEffect(async () => {
    if (accessToken) {
      setIsDeviatonLoading(true);

      if (
        currentOrder?.payableAmount &&
        Object.keys(orderPaymentsMap).length === 0
      ) {
        const checksTotal = checks.reduce(
          (acc, c) => (acc += c.payableAmount),
          0
        );
        const deviation = parseFloat(
          (parseFloat(currentOrder.payableAmount) - checksTotal).toFixed(2)
        );

        if (Math.abs(deviation) > 0 && Math.abs(deviation) <= 0.9) {
          // there is only one possible deviation for 2 points precision
          const newChecks = [
            {
              ...checks[0],
              payableAmount: Number(
                (checks[0].payableAmount + deviation).toFixed(2)
              ),
            },
            ...checks.slice(1),
          ];
          const splitPaymentPayload = {
            checks: newChecks,
            numChecks: newChecks.length || 0,
            orderId: currentOrder._id,
          };
          if (!currentOrder?.payments?.length > 0) {
            await creatingChecks(splitPaymentPayload, checks, billType);
          }
        }
      }
      setIsDeviatonLoading(false);
    }
  }, [checks, checks.length, currentOrder?.payableAmount, accessToken]);

  if (!currentOrder) return null;

  const isCheckPartiallyPaid = () => {
    let isCheckPartialPaid = false;

    checks?.forEach((check) => {
      if (check.payments !== null && check.payments.length > 0) {
        isCheckPartialPaid = true;
      }
    });

    return isCheckPartialPaid;
  };

  return (
    <div className="bem-split-page">
      <NavBarUI>
        <NavBarUI.Header>
          <LogoNavButtonUI
            onClick={() => history.goBack()}
            style={{ width: '100%', paddingLeft: 14 }}
          />
        </NavBarUI.Header>
        {accessToken && !isLoading && !isDeviationLoading && (
          <NavBarUI.Body>
            {navList?.map((item) => {
              return (
                <NavButtonUI
                  key={item.id}
                  title={item.name}
                  icon={item.icon}
                  disabled={
                    isCheckPartiallyPaid() ||
                    concluded ||
                    currentOrder?.payments?.length ||
                    isLoading ||
                    isDeviationLoading ||
                    (!currentOrder?.allSeatsData?.length > 0 &&
                      item.id === 'split_per_seat')
                  }
                  onClick={async () => {
                    !isCheckPartiallyPaid() && setState({ type: item.id });
                    setBillType(item.id);
                  }}
                  modifierClassName={type === item.id ? 'active' : ''}
                />
              );
            })}
          </NavBarUI.Body>
        )}
        <NavBarUI.Footer>
          <SupportNav staffMember={{ ...staffMember, imgSrc: JaneAppleSeed }} />
        </NavBarUI.Footer>
      </NavBarUI>
      <div className="bem-split-page__main">
        <div className="bem-split-page__header">
          <div className="bem-split-page__header-top">
            <h2 className="bem-split-page__header-title">
              Table {currentOrder.tableNumber}
            </h2>
            <ButtonGroupUI gap="xl" justifyContent="right">
              {!concluded && type !== 'split_per_seat' && (
                <CounterUI
                  title={'Guests'}
                  style={{ color: 'var(--purpleColor)' }}
                  quantity={guests}
                  onIncrement={() => {
                    setGuests(guests + 1);
                    setGuestCounter(guests + 1);
                  }}
                  onDecrement={() => {
                    setGuests(guests - 1);
                    setGuestCounter(guests - 1);
                  }}
                />
              )}
              {!concluded && (
                <ButtonUI
                  styleType="secondary"
                  iconPosition="left"
                  fontWeight="medium"
                  buttonInnerSpacing="xs"
                  style={{
                    color: 'var(--successColor)',
                    boxShadow: '0 0 8px 0 rgba(0,0,0,0.11)',
                    paddingRight: 16,
                  }}
                  icon={<Icon.DollarIcon strokeWidth={2.25} width={20} />}
                  onClick={() => {
                    dispatch({
                      type: 'TOGGLE_SPLIT_DISCOUNT_MODAL',
                    });
                  }}
                >
                  Discount
                </ButtonUI>
              )}

              <DropdownPrinter
                options={printers}
                onChange={onPrinterChange}
                selected={printer?._id}
                style={{
                  height: 35,
                  width: printerWidth,
                }}
              />
            </ButtonGroupUI>
          </div>
          <div className="bem-split-page__header-bottom">
            {!isDeviationLoading && !isLoading && (
              <>
                <TabsNavigationUI
                  tabsList={checks
                    .sort((a, b) => +a.number - +b.number)
                    .map((c) => ({
                      ...c,
                      title:
                        numChecks === 1
                          ? `Full Check`
                          : `Check ${+c.number + 1}`,
                    }))}
                  successList={checks.map((c) => {
                    if (!c.paidAmount || !c.payableAmount) return false;
                    return (
                      c.paidAmount.toFixed(2) === c.payableAmount.toFixed(2)
                    );
                  })}
                  disabled={isCheckEditInProcess}
                  activeTabIndex={selectedCheckNumber}
                  setActiveTabIndex={(i) => selectCheck(i)}
                  style={{ marginLeft: -21, marginRight: -21 }}
                />
              </>
            )}
          </div>
        </div>
        <div className="bem-split-page__body">
          {isDeviationLoading || isLoading ? (
            <div style={{ alignSelf: 'center' }}>
              <PosIconLoading mainColor="#5eb602" />
            </div>
          ) : (
            <>
              {[`full`, `custom`].includes(type) && (
                <SplitEqually
                  type={type}
                  concluded={concluded}
                  currentOrder={currentOrder}
                  selectedCheck={selectedCheck}
                  setChecks={(checks) => {
                    setState({ checks });
                  }}
                  togglePaymentModal={togglePaymentModal}
                  checks={checks}
                  guests={guests}
                  printer={printer}
                />
              )}
              {type === `equal` && (
                <SplitChecksEqually
                  currentOrder={currentOrder}
                  selectedCheck={selectedCheck}
                  guests={guests}
                  togglePaymentModal={togglePaymentModal}
                  checks={checks}
                  setState={setState}
                  initialChecksData={initialChecks}
                  deleteChecksList={deleteChecksList}
                  creatingChecks={creatingChecks}
                  setIsLoading={setIsLoading}
                  printer={printer}
                  accessToken={accessToken}
                />
              )}
              {type === `per_item` &&
                !openSplitEquallyFlow &&
                !openSplitPerSeatFlow &&
                billType === 'per_item' && (
                  <SplitPerItem
                    togglePaymentModal={togglePaymentModal}
                    currentOrder={currentOrder}
                    selectedCheck={selectedCheck}
                    setSelectedCheck={selectCheck}
                    setIsEditCheckInProcess={setIsEditCheckInProcess}
                    setChecks={(checks) => {
                      setState({ checks, numChecks: checks.length });
                    }}
                    setState={setState}
                    checks={checks}
                    guests={guests}
                    printer={printer}
                    accessToken={accessToken}
                  />
                )}

              {type === 'split_per_seat' && billType === 'split_per_seat' && (
                <SplitPerSeat
                  currentOrder={currentOrder}
                  selectedCheck={selectedCheck}
                  togglePaymentModal={togglePaymentModal}
                  printer={printer}
                  voidedItems={voidedItems}
                  setState={setState}
                  checks={checks}
                  accessToken={accessToken}
                />
              )}
            </>
          )}
        </div>

        {amountModalOpen && (
          <CustomAmountModal
            toggleModal={() => {
              if (totalCustomAmount === 0) {
                setState({ type: 'full' });
              } else if (
                totalCustomAmount < parseFloat(currentOrder.payableAmount)
              ) {
                setState({
                  checks: [
                    ...checks,
                    {
                      number: `${checks.length}`,
                      numGuests: 1,
                      payableAmount:
                        parseFloat(currentOrder.payableAmount) -
                        totalCustomAmount,
                      payments: [],
                    },
                  ],
                });
              }
              toggleAmountModal();
            }}
            totalCustomAmount={totalCustomAmount}
            currentOrder={currentOrder}
            submitCustomAmount={(amount) => {
              const newCheck = {
                number: `${checks.length}`,
                numGuests: 1,
                payableAmount: amount,
                payments: [],
              };
              setState({ checks: [...checks, newCheck] });
              if (
                parseFloat(currentOrder.payableAmount) ===
                totalCustomAmount + amount
              ) {
                toggleAmountModal();
                if (checks.length === 0) {
                  setState({ type: 'full' });
                }
              }
            }}
          />
        )}
      </div>
      {paymentModalOpen !== false && (
        <ModalPortal>
          <PaymentModal
            splitState={{ ...state, checks }}
            setState={setState}
            selectedCheck={selectedCheck}
            selectCheck={selectCheck}
            orderPaymentsMap={orderPaymentsMap}
            toggleModal={togglePaymentModal}
            currentOrder={currentOrder}
            orderId={orderId}
            payableAmount={paymentModalOpen}
            openCashAmountPaidModal={() => {}}
            deleteChecksList={deleteChecksList}
          />
        </ModalPortal>
      )}
      <ModalPortal>
        <SplitBillDiscountModal
          cb={async (order) => {
            dispatch(
              setOrders({
                allOrdersMap: {
                  ...orders.allOrdersMap,
                  [order._id]: order,
                },
                allOrdersList: [...orders.allOrdersList, order._id],
              })
            );
            // borrowed this nicecity from order baR
            return updateOrder(order._id, order, storeConfig, accessToken);
          }}
          order={currentOrder}
          isPerSeatOrder={type === 'split_per_seat'}
        />
      </ModalPortal>
      {alert && (
        <AlertModal
          modalStatus
          toggleModal={() => setState({ alert: false })}
          header="This order is fully settled."
          description="Do you want to stay on page?"
          proceedText="No"
          cancelText="Yes"
          handleProceed={() => history.goBack()}
          handleCancel={() => setState({ alert: false })}
        />
      )}
    </div>
  );
};

export default Payment;
