import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconModernHouse = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-modern-house': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="20"
        viewBox="0 0 22 20"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M14.007 7.25l1.719.9V10h-1.719zm-.43 3.583h2.578a.42.42 0 0 0 .43-.416V7.9a.422.422 0 0 0-.223-.367l-2.578-1.35a.443.443 0 0 0-.421.009.41.41 0 0 0-.206.358v3.867c-.009.233.18.416.42.416zm.43 2.5h1.719v2.5h-1.719zm-.43 3.334h2.578a.42.42 0 0 0 .43-.417v-3.333a.42.42 0 0 0-.43-.417h-2.577a.42.42 0 0 0-.43.417v3.333a.42.42 0 0 0 .43.417zM7.134 10c.473 0 .86.375.86.833 0 .459-.387.834-.86.834-.472 0-.859-.375-.859-.834 0-.458.387-.833.86-.833zm0 2.5c.946 0 1.719-.75 1.719-1.667 0-.916-.773-1.666-1.719-1.666-.945 0-1.718.75-1.718 1.666 0 .917.773 1.667 1.718 1.667zm12.03 6.685H11.43V1.842l7.733 4.627zm-12.889-.008v-3.344h1.719v3.344zM2.837 9.019l7.733-3.727v13.893H8.852v-3.761a.42.42 0 0 0-.43-.417H5.845a.42.42 0 0 0-.43.417v3.761H2.837zM15.726.833h1.708v3.609l-1.708-1.025zM21.5 6.875l-3.196-1.917V.417a.42.42 0 0 0-.43-.417h-2.578a.42.42 0 0 0-.43.417V2.9L11.43.842V.833h-.018L10.03.008l-.447.709.988.591v3.034L.5 9.2l.387.75 1.1-.533v10.166a.42.42 0 0 0 .43.417H19.6a.42.42 0 0 0 .43-.417V6.967l1.03.616z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconModernHouse.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
