import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PayMaster from 'assets/img/pay_master.svg';
import { useHistory } from 'react-router-dom';
import { useGlobalModalContext } from 'modals/GlobalModalContext/useGlobalModalContext';
import { setShowSplitPaymentModal } from 'pages/Orders/actions';
import {
  SpecialIcon,
  DiscountIcon,
  ScooterIcon,
  CloudIcon,
  StarIcon,
} from 'nexticons/solid';
import coupon from '../../../assets/img/coupon.svg';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import { TotalPriceBox } from './TotalPriceBox';
import { MENU_ORDER_FROM } from 'configuration/enums';
import palmTree from '../../../assets/img/palmTree.svg';
import { getNameAlphabetsForAvatar } from 'util/utils';
import {
  orderSetupCashDrawerReminderSelector,
  selectedDrawerStatusIsActiveSelector,
  storeCountryIdentifierSelector,
  currencySymbolSelector,
} from 'pages/Dashboard/selectors';

function BottomBar({
  orderStatus,
  orderPayment,
  staffName,
  specialDiscount,
  discount,
  deliveryCost,
  orderFrom,
  voucherDiscount,
  publicHolidaySurcharge,
  newPayableAmount,
  payableAmount,
  loyaltyDiscount,
  payments,
  isSplit,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const cashDrawerReminder = useSelector(orderSetupCashDrawerReminderSelector);
  // const orderPaymentsTotalPaid = useSelector(orderPaymentsTotalPaidSelector);
  const selectedDrawerStatusIsActive = useSelector(
    selectedDrawerStatusIsActiveSelector
  );

  const totalAmountPaid = payments.reduce((prevAmount, current) => {
    return prevAmount + current.amount;
  }, 0);

  const shouldShow =
    orderStatus === `unpaid` ||
    orderStatus === `partially-paid` ||
    orderPayment === `card` ||
    orderPayment === `cash`;

  const getOutstandingAmount = (payableAmount, totalPaid) => {
    const outstandingAmount = Number(payableAmount).toFixed(2) - totalPaid;
    return outstandingAmount.toFixed(2);
  };

  const handleTogglePaymentView = () => {
    if (isSplit) {
      dispatch(setShowSplitPaymentModal(true));
    } else {
      dispatch({
        type: 'togglePaymentViewModal',
        payload: true,
      });
    }
  };

  const gotoCashierPage = () => {
    history.push('/cashier');
  };

  const { handleGlobalModal } = useGlobalModalContext();

  const handleClick = async () => {
    // check also for the "cashDrawerReminder" flag
    if (!selectedDrawerStatusIsActive && cashDrawerReminder) {
      handleGlobalModal({
        title: 'You need to start the Connected Cash Draw',
        message:
          'To keep proper track of transactions, please go to Cashier and start the connected drawer. If there is no drawer yet selected, please select your drawer using the Select Drawer function at the top of the page.',
        actionButtonLabel: 'Open Cashier',
        actionButtonHandler: gotoCashierPage,
        dismissButtonHandler: handleTogglePaymentView,
      });
    } else {
      handleTogglePaymentView();
    }
  };

  if (!shouldShow) return null;

  return (
    <>
      {((staffName !== '' && staffName !== undefined) ||
        orderFrom === MENU_ORDER_FROM.web ||
        Number(loyaltyDiscount) > 0 ||
        Number(publicHolidaySurcharge) > 0 ||
        Number(voucherDiscount) > 0 ||
        Number(specialDiscount) > 0 ||
        Number(discount) > 0 ||
        Number(deliveryCost) > 0) && (
        <div className="order-info-detail-footer">
          {orderFrom === MENU_ORDER_FROM.web && (
            <div className="order-info-detail-item">
              <CloudIcon width={25} color="#067aff" />
              <div className="order-info-detail-item-text">
                <p>Online Order</p>
              </div>
            </div>
          )}
          {staffName !== '' && staffName !== undefined && (
            <div className="order-info-detail-item">
              <div className="circle">
                <p>{getNameAlphabetsForAvatar(staffName)}</p>
              </div>
              <div className="order-info-detail-item-text">
                <p> Server - {staffName}</p>
              </div>
            </div>
          )}
          {Number(publicHolidaySurcharge) > 0 && (
            <div className="order-info-detail-item">
              <img src={palmTree} alt="publicHolidaySurcharge" />
              <div className="order-info-detail-item-text">
                <p>
                  Public Holiday Surcharge {currencyCode}
                  {publicHolidaySurcharge}
                </p>
              </div>
            </div>
          )}
          {Number(specialDiscount) > 0 && (
            <div className="order-info-detail-item">
              <SpecialIcon width={25} color="#fd6c00" />
              <div className="order-info-detail-item-text">
                <p>
                  Specials -{currencyCode}
                  {specialDiscount}
                </p>
              </div>
            </div>
          )}
          {Number(discount) > 0 && (
            <div className="order-info-detail-item">
              <DiscountIcon width={25} color="#5eb602" />
              <div className="order-info-detail-item-text">
                <p>
                  Discounts -{currencyCode}
                  {discount}
                </p>
              </div>
            </div>
          )}
          {Number(deliveryCost) > 0 && (
            <div className="order-info-detail-item">
              <ScooterIcon width={25} color="#b620e0" />
              <div className="order-info-detail-item-text">
                <p>
                  Delivery Fee {currencyCode}
                  {deliveryCost}
                </p>
              </div>
            </div>
          )}
          {Number(voucherDiscount) > 0 && (
            <div className="order-info-detail-item">
              <img src={coupon} alt="voucher" />
              <div className="order-info-detail-item-text">
                <p>
                  Voucher -{currencyCode}
                  {voucherDiscount}
                </p>
              </div>
            </div>
          )}

          {Number(loyaltyDiscount) > 0 && (
            <div className="order-info-detail-item">
              <StarIcon width={25} color="#F4D03F" />
              <div className="order-info-detail-item-text">
                <p>
                  Loyalty -{currencyCode}
                  {loyaltyDiscount}
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      <div
        className="view-order-main-footer"
        style={{
          position: 'relative',
          minHeight: 'unset',
          height: 57,
          flexShrink: 0,
          left: 'unset',
          bottom: 'unset',
        }}
      >
        {orderStatus === 'cancelled' && (
          <div className="order-total-paid-box card-payment">
            <div className="price-wrap">
              <span
                className="price-desc"
                style={{ color: 'red', fontWeight: 500 }}
              >
                Cancelled
              </span>
              <TotalPriceBox />
            </div>
          </div>
        )}
        {/(partially-paid|unpaid)/.test(orderStatus) &&
          Number(
            getOutstandingAmount(
              payableAmount,
              Number(totalAmountPaid.toFixed(2))
            )
          ) !== 0 && (
            <div className="current-order-status-box orange">
              <span className="desc">
                {getAmountWithCountryCurrency({
                  amount: Number(
                    getOutstandingAmount(
                      payableAmount,
                      Number(totalAmountPaid.toFixed(2))
                    )
                  ),
                  locale: `en-${storeCountryIdentifier}`,
                  currencyCode,
                })}
                {` `}Outstanding
              </span>
              <button
                className="order-status-btn hover-highlight"
                type="button"
                title="Pay Now"
                onClick={(e) => handleClick(e, true)}
              >
                <span className="inner-btn-box">Pay Now</span>
              </button>
            </div>
          )}
        {(orderStatus === `paid` ||
          Number(
            getOutstandingAmount(
              payableAmount,
              Number(totalAmountPaid.toFixed(2))
            )
          ) === 0) && (
          <div
            className={`order-total-paid-box ${
              orderPayment === `cash` ? 'cash-payment' : 'card-payment'
            }`}
          >
            {orderPayment === `card` && (
              <div className="paycard-box master">
                <img src={PayMaster} alt="Master Card" />
              </div>
            )}
            <div className="price-wrap">
              <span className="price-desc">Total Paid</span>
              <TotalPriceBox />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BottomBar;
