import React from 'react';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

export const SearchIngredients = ({ setFilterString, filterString }) => {
  const {
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    inputNames,
    inputs,
  } = useVirtualKeyboardContext();
  const inputName = inputNames.searchIngredients;

  const handleFocus = () => {
    setInputName(inputName);
    toggleKeyboard(true);
  };

  const handleBlur = () => {
    toggleKeyboard(false);
  };

  React.useEffect(() => {
    const searchStr = getInputValue(inputName);

    setFilterString(searchStr);
  }, [inputs[inputName]]);

  return (
    <div className="search-box">
      <form
        className="search-form"
        action=""
        onSubmit={(event) => {
          event.preventDefault();
        }}
        autoComplete="off"
      >
        <input
          id={inputName}
          name={inputName}
          type="text"
          // name="s"
          placeholder="Search ingredients"
          className="input-style"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(event) => {
            onChangeInput(inputName, event.target.value);
            setFilterString(event.target.value)
          }}
          value={getInputValue(inputName)}
          // value={filterString}
          // onClick={handleClick}
        />
        <button type="submit" title="Search" />
      </form>
    </div>
  );
};
