import React, { useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  paymentTypeObj,
  orderTypeObj,
  orderFromObj,
} from '../../../configuration/constants';
import { GlobalStateActionEnum } from '../../../pages/Orders/components/Providers/OrdersGlobalState/constants';
import { loadOrderPayments } from '../../../pages/Dashboard/action';
import { useOrdersGlobalDispatch } from '../../../pages/Orders/components/Providers/OrdersGlobalState/OrdersGlobalState';
import { getRemainingTime } from '../../../util/dateHelper';
import { getAmountWithCountryCurrency } from '../../../util/currencyFormat';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../../pages/Dashboard/selectors';

import deliveroo from '../../../assets/img/deliveroo.svg';
import doorDash from '../../../assets/img/doordash.png';
import menuLog from '../../../assets/img/menuLog.svg';
import uberEats from '../../../assets/img/uberEats.svg';
import googleIcon from '../../../assets/img/googleIcon.svg';

import { setState } from 'pages/Orders/newOrdersReducer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MENU_ORDER_FROM, MENU_ORDER_STATUS } from 'configuration/enums';
import { FaRobot } from 'react-icons/fa';

const hubRiseStyle = {
  marginLeft: '3px',
  marginRight: '-3px',
  marginTop: '-2.5px',
};

const doorDashIconStyle = {
  marginLeft: '3px',
  marginRight: '3px',
  marginBottom: '10px',
};

const googleIconStyle = {
  marginLeft: '10px',
  marginRight: '3px',
};
const GuestOrder = memo(({ itemId }) => {
  const { enableSelectedOrderLog } = useFlags();
  const item = useSelector(
    (state) => state.newOrdersReducer.orders.allOrdersMap[itemId]
  );

  const dispatch = useDispatch();

  const currencyCode = useSelector(currencySymbolSelector);

  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  const ordersGlobalDispatch = useOrdersGlobalDispatch();

  const handleClick = (event, normalisedOrderDocument) => {
    event.preventDefault();

    !!enableSelectedOrderLog &&
      console.log('selected order', normalisedOrderDocument);
    dispatch(setState({ selectedOrderId: normalisedOrderDocument._id }));

    ordersGlobalDispatch({
      type: GlobalStateActionEnum.UPDATE_SELECTED_ORDER,
      payload: {
        selectedOrder: normalisedOrderDocument,
      },
    });

    dispatch(loadOrderPayments(normalisedOrderDocument._id));
  };

  const orderFrom = orderFromObj[item?.orderFrom] || {};

  const orderType = orderTypeObj[item?.orderType] || {};

  const paymentType = paymentTypeObj[item?.paymentType] || {};

  const isOrderUnpaid = useMemo(
    () =>
      item?.paymentStatusDisplay &&
      item.paymentStatusDisplay.toLowerCase() !== 'paid',
    [item]
  );

  const unpaidItem = paymentType.type === 'unpaid';

  if (item?.orderFrom === MENU_ORDER_FROM.deliverect) {
    if (item.fromChannel) orderFrom.title = item.fromChannel;
  }

  const renderHubRise = (fromChannel) => {
    if (fromChannel === 'DELIVEROO') {
      return <img src={deliveroo} alt="HubRise-deliveroo" />;
    }
    if (fromChannel === 'MENULOG') {
      return <img src={menuLog} alt="HubRise-menuLog" />;
    }
    if (fromChannel === 'UBER_EATS') {
      return <img src={uberEats} alt="HubRise-uberEats" />;
    }
    return null;
  };

  const totalAmountPaid = item?.payments?.reduce((prevAmount, current) => {
    return prevAmount + current.amount;
  }, 0);

  const getOutstandingAmount = () => {
    const outstandingAmount = Number(item.payableAmount) - totalAmountPaid;
    return outstandingAmount.toFixed(2);
  };

  const isOutstandingAmountIsZero =
    Number(getOutstandingAmount(item.payableAmount, totalAmountPaid)) === 0;

  const renderOrderFromIcons = (item, orderFromObject) => {
    if (item.orderFrom === MENU_ORDER_FROM.hubrise) {
      return (
        <span className="status-btn" style={hubRiseStyle}>
          {renderHubRise(item.fromChannel)}
        </span>
      );
    } else if (item.orderFrom === MENU_ORDER_FROM.doordash) {
      return (
        <span className={'status-btn doorDash'}>
          <img src={doorDash} alt="doorDash" style={{ width: '20px' }} />
        </span>
      );
    } else if (item.orderFrom === MENU_ORDER_FROM.google) {
      return (
        <span className={'status-btn google'}>
          <img src={googleIcon} alt="google" style={{ width: '15px' }} />
        </span>
      );
    } else if (item.orderFrom === MENU_ORDER_FROM.bot) {
      return (
        <span className={'status-btn bot'}>
          <FaRobot size={15} />
        </span>
      );
    } else {
      return (
        <span className={`status-btn ${orderFromObject.type || ''}`}>
          {orderFromObject.title || ''}
        </span>
      );
    }
  };

  return (
    <div
      className="single-detail-box"
      onClick={(event) => handleClick(event, item)}
    >
      <div className="detail-row row-1">
        <div className="detail-cell">
          <h3
            className="name"
            style={{
              ...(item?.orderStatus === MENU_ORDER_STATUS.cancelled
                ? { textDecoration: 'line-through' }
                : {}),
            }}
          >
            {item.tableNumber
              ? `Table ${item?.tableNumber || ''}`
              : item.firstName + ' ' + item.lastName}
            {item.vip ? <span className="status-btn vip">VIP</span> : ''}
          </h3>
        </div>
        <div className="detail-cell">
          <span className="quantity-box">
            {item.people ? (
              <span className="booking-count-box people">
                <span className="dine_icon-peoples" />
                <span className="count">{item.people}</span>
              </span>
            ) : (
              ''
            )}
            {item.paymentType ? (
              <span className={`booking-count-box ${paymentType.class || ''}`}>
                {!unpaidItem && (
                  <span className={`dine_icon-${paymentType.class || ''}`} />
                )}
                <span
                  className={clsx(
                    'count',
                    isOrderUnpaid && !isOutstandingAmountIsZero && 'unpaid'
                  )}
                >
                  {isOrderUnpaid && !isOutstandingAmountIsZero && (
                    <span>{item.paymentStatusDisplay} </span>
                  )}
                  {isOrderUnpaid && !isOutstandingAmountIsZero
                    ? getAmountWithCountryCurrency({
                        amount: Number(
                          // item.newPayableAmount || item.payableAmount
                          getOutstandingAmount(
                            item.payableAmount,
                            totalAmountPaid
                          )
                        ),
                        locale: `en-${storeCountryIdentifier}`,
                        currencyCode,
                      })
                    : getAmountWithCountryCurrency({
                        amount: Number(item.payableAmount),
                        locale: `en-${storeCountryIdentifier}`,
                        currencyCode,
                      })}
                </span>
              </span>
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
      <div className="detail-row row-2">
        <div className="detail-cell">
          {item.deliveryDateTimestamp && (
            <div className={`text-status `}>
              {getRemainingTime(item.deliveryDate, item.deliveryDateTimestamp)}
            </div>
          )}
        </div>
        <div className="detail-cell">
          <span className="status-box">
            {item.tableNumber && (
              <span className="status-btn table-id">
                Table {item.tableNumber}
              </span>
            )}
            {item.deliveryCode && (
              <span className={`status-btn ${orderType?.class || ''}`}>
                {`${orderType?.text || ''} #${item.deliveryCode}`}
              </span>
            )}
            {item?.refundStatus === 1 && (
              <span className={`status-btn refunded`}>{`Refunded`}</span>
            )}
            {item.orderFrom ? renderOrderFromIcons(item, orderFrom) : null}
          </span>
        </div>
      </div>
    </div>
  );
});

export default GuestOrder;
