import React from 'react';
import { useSelector } from 'react-redux';

import GeneralModal from '../../modals/GeneralModal/index-new';
import AddPaymentType from './AddPaymentType';
import { PosIcon } from '../../assets/icons/PosIcon';
import PaidPaymentStatus from './PaidPaymentStatus';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { PosIconCheckmarkCircle } from 'assets/icons/PosIconCheckmarkCircle';

import { paymentTypesForOrderTypeSelector } from '../../pages/Dashboard/selectors';
import useGetAmountWithCurrency from '../../customHooks/useGetAmountWithCurrency';
import { PAYMENT_TYPES } from '../../constants/order-flow';
import { paymentTypeObj } from 'configuration/constants';
import { ApiStatusEnum, paymentTypeEnum } from 'configuration/enums';

const SplitPaymentsModal = ({
  modalStatus,
  toggleModal,
  payableAmount,
  orderPayments = [],
  changeAmount = '',
  handleSplitPaymentOptionClick,
  hasOrderBeenCompleted = false,
  printingLoadingStatus,
  handleFinishSaleClick,
  handlePrintReceiptClick,
  loading = false,
  showPrintReceiptButton = true,
}) => {
  const payableAmountString = useGetAmountWithCurrency(payableAmount);
  const changeAmountString = useGetAmountWithCurrency(changeAmount);
  const paymentTypesForOrderType = useSelector(
    paymentTypesForOrderTypeSelector
  );

  const customPaymentTypesArray = React.useMemo(() => {
    return paymentTypesForOrderType.map((paymentType) => {
      return {
        id: paymentType._id,
        color: 'purple',
        colorCode: paymentType.color,
        iconComponent: (
          <PosIcon
            className="pos-icon--order-paid-status"
            name="question"
            size="custom"
            customHeight={32}
            customWith={32}
            currentColor={true}
          />
        ),
        title: `Add ${paymentType.name} Payment`,
        name: paymentType.name,
        type: paymentTypeEnum.CUSTOM,
      };
    });
  }, [paymentTypesForOrderType]);

  const paidPaymentArray = React.useMemo(
    () =>
      orderPayments.map((orderPayment) => ({
        amount: orderPayment.amount,
        paymentType:
          paymentTypeObj[orderPayment.paymentType]?.displayName || '',
      })),
    [orderPayments]
  );

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="bottom"
      effect="move"
      overlay=""
      boxWidth="435px"
      boxHeight="630px"
      className="general-modal grey-bg"
      closeBtn="outside"
    >
      {loading ? (
        <div className="voided-reasons-loading">
          <PosIconLoading mainColor="#5eb602" />
        </div>
      ) : (
        <div className="general-modal__inner">
          <div className="general-modal__header general-modal__header--top-offset  general-modal__header--space-between-big">
            <div className="general-modal__title-box">
              <h3 className="general-modal__title general-modal__title--size-big">
                {hasOrderBeenCompleted ? 'Order Paid' : payableAmountString}
              </h3>
            </div>
            <div className="general-modal__desc general-modal__desc--size-big">
              {hasOrderBeenCompleted && changeAmountString ? (
                <p>{`${changeAmountString} Change`}</p>
              ) : null}

              <p>
                {hasOrderBeenCompleted
                  ? 'Nothing Remaining to Pay'
                  : 'Split - Remaining to Pay'}
              </p>
            </div>
          </div>

          <div className="general-modal__body">
            <div className="general-modal__body-inner">
              <div className="paid-payment-status">
                {paidPaymentArray.map((paidPayment, index) => (
                  <PaidPaymentStatus key={index} payment={paidPayment} />
                ))}
              </div>
              {!hasOrderBeenCompleted ? (
                <div className="add-payment-type">
                  {[...PAYMENT_TYPES, ...customPaymentTypesArray].map(
                    (paymentType) => (
                      <AddPaymentType
                        paymentType={paymentType}
                        key={paymentType.id}
                        handleSplitPaymentOptionClick={
                          handleSplitPaymentOptionClick
                        }
                      >
                        {paymentType.iconComponent}
                      </AddPaymentType>
                    )
                  )}
                </div>
              ) : null}
            </div>
          </div>

          {hasOrderBeenCompleted ? (
            <div className="general-modal__footer general-modal__footer--offset general-modal__footer--grey-bg">
              <div className="general-modal__actions-group">
                <button
                  className="general-modal__action"
                  title="Finish Sale"
                  type="button"
                  onClick={handleFinishSaleClick}
                >
                  Finish Sale
                </button>
                {showPrintReceiptButton && (
                  <button
                    className="general-modal__action general-modal__action--theme-blue"
                    title="Print Receipt"
                    type="button"
                    onClick={handlePrintReceiptClick}
                  >
                    {printingLoadingStatus === ApiStatusEnum.LOADING && (
                      <PosIconLoading
                        mainColor="#067aff"
                        className="cursor-pointer"
                        size="36px"
                      />
                    )}
                    {printingLoadingStatus === ApiStatusEnum.RESOLVED && (
                      <PosIconCheckmarkCircle
                        mainColor="#067aff"
                        className="cursor-pointer"
                        size="36px"
                      />
                    )}
                    {printingLoadingStatus === ApiStatusEnum.IDLE &&
                      'Print Receipt'}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </GeneralModal>
  );
};
export default SplitPaymentsModal;
