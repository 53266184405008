import orderEngine from '../orderEngine/index';

const addDiscountToOrder = ({
  currentOrder,
  selectedDiscount,
  activeSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig,
}) => {
  let currentOrderObj = { ...currentOrder };

  currentOrderObj.selectedDiscount = selectedDiscount;
  currentOrderObj.discountId = selectedDiscount._id;
  currentOrderObj.selectedVoucher = {};
  currentOrderObj.voucherId = '';

  let newOrder = orderEngine(
    currentOrderObj,
    activeSpecials,
    orderSetup,
    productSetup,
    allSuburbs,
    publicHolidays,
    storeConfig
  );

  return newOrder;
};

export default addDiscountToOrder;
