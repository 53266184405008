import orderEngine from '../orderEngine';

const deleteItemFromOrder = (
  currentOrder,
  item,
  allSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig
) => {
  const newCurrentOrder = {
    ...currentOrder,
    menuItems: currentOrder.menuItems.filter((menuItem) =>
      typeof item.orderIndex === 'number'
        ? menuItem.orderIndex !== item.orderIndex
        : !item.items?.map((i) => i.orderIndex).includes(menuItem.orderIndex)
    ),
  };
  return orderEngine(
    newCurrentOrder,
    allSpecials,
    orderSetup,
    productSetup,
    allSuburbs,
    publicHolidays,
    storeConfig
  );
};

export default deleteItemFromOrder;
