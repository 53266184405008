import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  authenticateStaff,
  setOrderBackModal,
} from '../../pages/Dashboard/action';
import { resetOrdersPageData } from '../../pages/Orders/actions';
import AlertModal from '../AlertModal';

const OrderBackModal = ({ modalStatus, toggleModal, redirectUrl }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const handleCloseModal = () => {
    dispatch(setOrderBackModal(false));
  };

  const handleExitScreen = () => {
    dispatch(resetOrdersPageData());
    dispatch(authenticateStaff(false));
    dispatch(setOrderBackModal(false));
    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      history.push('/');
    }
  };

  return (
    <AlertModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      header="Cancel order?"
      description="Once cancelled, you will not be able to recover it."
      proceedText="Yes"
      cancelText="No"
      handleCancel={handleCloseModal}
      handleProceed={handleExitScreen}
    />
  );
};

export default OrderBackModal;
