import React from 'react';
import ReactDOM from 'react-dom';

class ModalPortal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.className = 'modal-wrap';
  }

  componentDidMount() {
    const modalRoot = document.getElementById('modal-root');

    modalRoot.appendChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

export default ModalPortal;
