import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleItemLargePhotoView } from '../../pages/Dashboard/action';

export const ItemDetails = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const currentItem = props.currentItem;
  const toggleItemModal = () => {
    dispatch(toggleItemLargePhotoView());
  };

  return (
    <div ref={ref} className="main-area-header ">
      <div className="current-food-box">
        <div className="current-food-body">
          {currentItem.name ? (
            <h1 className="food-title">{currentItem.name}</h1>
          ) : (
            ''
          )}
          {currentItem.description ? (
            <p className="food-ingredients">{currentItem.description}</p>
          ) : (
            ''
          )}
        </div>
        <button
          className="current-img-box hover-highlight"
          type="button"
          onClick={toggleItemModal}
        >
          <img src={currentItem.urlS3} alt={currentItem.name} />
        </button>
      </div>
    </div>
  );
});
