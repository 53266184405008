import React from 'react';
import { SearchIcon as IconSearch } from 'nexticons/outline';
import './bem-search-button.scss';
import PropTypes from 'prop-types';
import { getUIClassesFromProps } from '../modifiersMapping';

export const SearchButtonUI = ({ modifierClassName, onClick }) => {
  return (
    <button
      type="button"
      title="Search"
      className={getUIClassesFromProps('bem-search-button', {
        modifierClassName,
      })}
      onClick={onClick}
    >
      <IconSearch strokeWidth={1.75} width={28} />
    </button>
  );
};

SearchButtonUI.defaultProps = {
  modifierClassName: '',
  onClick: () => {},
};

SearchButtonUI.propTypes = {
  modifierClassName: PropTypes.string,
  onClick: PropTypes.func,
};
