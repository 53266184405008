import { PosIconClose } from '../../assets/icons/PosIconClose';
import { PosIconLeftChevron } from '../../assets/icons/PosIconLeftChevron';
import Button from '../../components/Button';
import useIsKiosk from 'hooks/useIsKiosk';

export default function CloseButton({ close, onClick }) {
  const isKiosk = useIsKiosk();
  const mainColor = !isKiosk ? '#363636' : '#ffffff';

  return (
    <Button
      className="generalModal-2-close-btn outside"
      title="Close"
      onClick={onClick}
      enableThemeColor
    >
      {close ? (
        <PosIconClose
          mainColor={mainColor}
          lineThickness={1.5}
          darkModeColor="#ffffff"
        />
      ) : (
        <PosIconLeftChevron mainColor={mainColor} darkModeColor="#ffffff" />
      )}
    </Button>
  );
}
