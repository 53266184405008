import { useDebounce } from 'hooks/useDebounce';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { PresentationalBackButton } from '../BackBtn';

import {
  ButtonActionsUI,
  ButtonGroupUI,
  HeaderUI,
  SwitchButtonsGroupUI,
} from '../../UI/components';
import { LogoButton } from '../LogoButton';

import { setFilters, setState } from 'pages/Orders/newOrdersReducer';

const orderTypes = ['all', 'pickup', 'delivery', 'dine-in', 'drive-thru'];

export const ViewOrdersHeader = memo(({ isSettlePage = false }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const filterType = useSelector((state) => state.ordersReducer.filterType);
  const switchTypeArr = useSelector(
    (state) => state.ordersReducer.viewOrdersSort
  );

  const {
    filters: { date, type },
  } = useSelector((state) => state.newOrdersReducer);

  // const orderList = useSelector((state) => state.ordersReducer.orderList);
  //

  const { orders, selectedOrderId } = useSelector(
    (state) => state.newOrdersReducer
  );

  const selectedOrder = useMemo(
    () => orders.allOrdersMap[selectedOrderId] || null,
    [selectedOrderId, orders.allOrdersMap]
  );

  const currentGuest = selectedOrder;

  const [activeSwitchIndex, setActiveSwitchIndex] = useState(
    orderTypes.findIndex((t) => t === type)
  );
  const [indexDebounced] = useDebounce(activeSwitchIndex, 100);

  useEffect(() => {
    if (orderTypes[indexDebounced]) {
      if (
        location.state?.backLink !== '/table-arrangement' &&
        !location.state?.orderID
      ) {
        dispatch(setState({ selectedOrderId: null }));
      }
      dispatch(setFilters({ type: orderTypes[indexDebounced] }));
      const path = `/orders/${date}/${orderTypes[indexDebounced]}`;
      history.push(path, location.state);
    }
  }, [indexDebounced, dispatch]);

  const handleOpenModal = () => {
    dispatch({
      type: 'toggleActionsModal',
      payload: true,
    });
  };

  return (
    <HeaderUI>
      {location.state?.backLink ? (
        <PresentationalBackButton link={location.state?.backLink} />
      ) : (
        <LogoButton />
      )}

      <SwitchButtonsGroupUI
        switchList={switchTypeArr}
        activeSwitchIndex={activeSwitchIndex}
        setActiveSwitchIndex={setActiveSwitchIndex}
      />

      <ButtonGroupUI gap="xl" justifyContent="right" style={{ minWidth: 150 }}>
        {currentGuest ? <ButtonActionsUI onClick={handleOpenModal} /> : ''}
      </ButtonGroupUI>
    </HeaderUI>
  );
});
