import React, { useState, useEffect, useRef } from 'react';
// import '../../../assets/scss/settle/_settle-sidebar.scss';
import classNames from 'classnames';
import { OpenOrdersList } from '../OpenOrdersList';
import { ScheduledDropdown } from '../../ScheduledDropdown';
import { useSelector, useDispatch } from 'react-redux';
import {
  setOrderList,
  setCopyOfOrderList,
  resetScrollPosition,
} from '../../../pages/Orders/actions';
import { SearchBox } from '../../Orders/sidebar/SearchBox';
import { ScrollableBoxUI } from '../../../UI/components';

export const SettleSidebar = ({ openOrdersList }) => {
  const dispatch = useDispatch();
  const dropDownArr = [
    { id: 1, text: 'Expected Time', key: 'deliveryDateTimestamp' },
    { id: 2, text: 'Placed Time', key: 'dateTimestamp' },
  ];
  const scrollActionType = 'sidebarSettleScroll';
  const shadow = useSelector((state) => state.ordersReducer.scrolledStatus[20].position);
  const listWrapRef = useRef();
  const [showDropDown, toggleDropDown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(dropDownArr[0]);
  const { orderList, orderType } = useSelector((state) => state.ordersReducer);
  const navCls = classNames({
    'nav-bar-header': true,
    shadow,
  });

  useEffect(() => {
    sortOrdersBasedOnTimeStamp(selectedFilter);
  }, [orderList]);

  useEffect(() => {
    // listWrapRef.current.scrollTop({top: 0});
    dispatch(resetScrollPosition({ actionType: scrollActionType, wrapperRef: listWrapRef }));
    // dispatch(setScrollPosition({ actionType: scrollActionType, position: 0 }));
  }, [orderType]); // eslint-disable-line react-hooks/exhaustive-deps

  const onItemClick = (selectedItem) => {
    setSelectedFilter(selectedItem);
    toggleDropDown(false);
    sortOrdersBasedOnTimeStamp(selectedItem);
  };

  const sortOrdersBasedOnTimeStamp = (selectedItem) => {
    orderList.sort((order1, order2) => {
      return order2[selectedItem.key] - order1[selectedItem.key];
    });
    dispatch(setOrderList(orderList));
    dispatch(setCopyOfOrderList(orderList));
  };

  return (
    <div className="settle-sidebar">
      <div className={navCls}>
        <div className="top-box">
          <h1 className="nav-bar-title">Open Orders</h1>
          <ScheduledDropdown
            title={selectedFilter.text}
            showDropDown={showDropDown}
            clickHandler={() => toggleDropDown(!showDropDown)}
          >
            {dropDownArr.map((item) => {
              return (
                <div
                  className={`item hover-highlight ${
                    item.id === selectedFilter.id ? ' selected' : ''
                  } `}
                  onClick={() => onItemClick(item)}
                  key={item.id}
                >
                  {item.text}
                </div>
              );
            })}
          </ScheduledDropdown>
        </div>

        <SearchBox isSettlePage={true} />
      </div>
      <div className="nav-bar-order-details">
        <ScrollableBoxUI
        // ref={listWrapRef}
        // actionType={scrollActionType}
        >
          <div className="details-tab-wrap upcoming">
            <OpenOrdersList ordersList={openOrdersList} />
          </div>
        </ScrollableBoxUI>
      </div>
    </div>
  );
};
