import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useIconColor from '../../customHooks/useIconColor';

export const PosTransferIcon = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-bike': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor ? iconColor : '#000'}
        stroke={iconColor ? iconColor : '#000'}
        strokeWidth={lineThickness ? lineThickness : 0}
      >
        <g clipPath="url(#clip0_1_2)">
          <path
            d="M24.72 0.179999C24.49 -0.0200011 24.16 -0.0600011 23.9 0.0799989L0.569998 11.61C0.239998 11.78 0.0199982 12.11 -1.75321e-06 12.5C-0.0200018 12.88 0.159998 13.25 0.469998 13.45C0.479998 13.46 0.489998 13.46 0.499998 13.47L7.12 17.18C7.05 17.27 7 17.38 7 17.5V24.5C7 24.71 7.13 24.9 7.33 24.97C7.39 24.99 7.44 25 7.5 25C7.65 25 7.79 24.94 7.89 24.82L11.97 19.9L16.93 22.68C17.1 22.79 17.29 22.85 17.48 22.85C17.59 22.85 17.7 22.83 17.81 22.79C18.12 22.68 18.35 22.44 18.45 22.11L24.96 1.02C25.06 0.719999 24.96 0.389999 24.72 0.179999ZM1.02 12.51L22.62 1.83L7.85 16.45L0.989998 12.6L1.02 12.51ZM8 23.11V17.7L8.02 17.68L11.08 19.39L8 23.11ZM17.44 21.82L8.76 16.95L23.5 2.36L17.44 21.82Z"
            fill="#494B4C"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

PosTransferIcon.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
