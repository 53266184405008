import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconCheck = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-check': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="32"
        viewBox="0 0 26 32"
      >
        <title>Check</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M5.472 20.338h11.148v1.263h-11.149zM24.053 26.603h-16.104v1.115c-0.006 1.012-0.375 1.936-0.983 2.65l0.005-0.006h14.604c1.369 0 2.477-1.122 2.477-2.506zM6.71 25.35h13.626v-21.667l-1.858 1.879-2.477-2.505-2.477 2.506-2.477-2.506-2.477 2.506-2.477-2.506-2.477 2.506-1.858-1.88v24.173c-0.002 0.039-0.004 0.084-0.004 0.13 0 0.656 0.263 1.25 0.69 1.683l-0-0c0.424 0.43 1.014 0.697 1.665 0.697 0.044 0 0.088-0.001 0.132-0.004l-0.006 0c0.704-0.018 1.335-0.322 1.781-0.8l0.001-0.001c0.465-0.495 0.715-1.159 0.695-1.843zM25.289 25.35v2.506c0 2.077-1.664 3.759-3.716 3.759h-17.34c-0.033 0.001-0.071 0.002-0.11 0.002-0.998 0-1.902-0.409-2.551-1.068l-0-0c-0.653-0.662-1.056-1.572-1.056-2.576 0-0.041 0.001-0.082 0.002-0.123l-0 0.006v-27.206l3.097 3.133 2.477-2.506 2.477 2.506 2.475-2.506 2.477 2.506 2.477-2.506 2.477 2.506 3.097-3.133v24.7zM5.472 12.819h11.148v1.253h-11.149zM15.382 10.312h-9.911v-1.253h9.91zM15.382 17.831h-9.911v-1.253h9.91z"
        ></path>
      </svg>
    </span>
  );
};

PosIconCheck.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
