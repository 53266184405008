import { Route, Switch, withRouter } from 'react-router-dom';
import { HistorySidebarHeader } from './sidebar/HistorySidebarHeader';
import { ReadySidebar } from './sidebar/ReadySidebar';
import { ReadySidebarHeader } from './sidebar/ReadySidebarHeader';
import { UpcomingSidebarHeader } from './sidebar/UpcomingSidebarHeader';
import { ViewOrdersNav } from './ViewOrdersNav';

const AnimatedHeaderSwitch = withRouter(({ location }) => (
  <Switch location={location}>
    <Route path="/orders/future" component={UpcomingSidebarHeader} />
    <Route path="/orders/current" component={ReadySidebarHeader} />
    <Route path="/orders/history" component={HistorySidebarHeader} />
    <Route path="/orders" component={HistorySidebarHeader} />
  </Switch>
));

export const ViewOrdersSidebar = (props) => {
  return (
    <div className="view-order-sidebar-inner">
      <AnimatedHeaderSwitch />
      <ReadySidebar />
      <ViewOrdersNav />
    </div>
  );
};
