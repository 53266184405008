import axios from 'axios';
import { _auth } from '../firebase';
import { store } from '../redux-store';
import { storeAccessToken } from '../pages/Dashboard/action';

const axiosWithInterceptor = axios.create();

axiosWithInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const response = await _auth.currentUser.getIdToken(true);

        if (response) {
          store.dispatch(storeAccessToken(response));
        }

        // Update the access token in your Axios instance
        axiosWithInterceptor.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${response}`;

        // Retry the failed request
        error.config.headers['Authorization'] = `Bearer ${response}`;
        return axiosWithInterceptor(error.config);
      } catch (refreshError) {
        throw refreshError;
      }
    }

    // Handle other errors
    return Promise.reject(error);
  }
);

export default axiosWithInterceptor;
