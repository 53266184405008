import IconInfo from 'assets/icons/IconInfo';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export const TableScaleControl = ({
  title,
  paramName,
  paramValue,
  min,
  max,
  step,
  desc = '',
  className = '',
  handleTableValueChange,
}) => {
  const handleChange = (newValue) => {
    handleTableValueChange(paramName, newValue);
  };

  return (
    <div className={className + ' table-config-control'}>
      {title && <h2 className="table-config-control__title">{title}</h2>}

      <div>
        <Slider
          value={paramValue || 1}
          min={min}
          max={max}
          step={step}
          handleStyle={{
            border: '2px solid #ffffff',
            backgroundColor: 'var(--successColor)',
            height: '26px',
            width: '26px',
          }}
          trackStyle={{
            backgroundColor: 'rgba(94,182,2,.5)',
            height: '13px',
          }}
          railStyle={{
            backgroundColor: '#fff',
            height: '13px',
            boxShadow: '0 0 5px rgba(0,0,0,.5)',
          }}
          onChange={handleChange}
        />
      </div>
      {desc && (
        <div className="table-config-control__info">
          <IconInfo className="table-config-control__info-icon" />
          <p>{desc}</p>
        </div>
      )}
    </div>
  );
};
