import React from 'react';
import { useDispatch } from 'react-redux';
import { updateIngredient } from '../../itemProcessor';
import { updateMenuItem } from '../../pages/Dashboard/action';
import { RemoveIngredient } from './RemoveIngredient';
import { ScrollableBoxUI } from '../../UI/components';

export const RemoveIngredients = ({
  ingredients,
  currentItem,
  actionType,
  setScrollPosition,
  shadowStatus,
}) => {
  const dispatch = useDispatch();
  const handleChange = (ingredientId) => {
    dispatch(updateMenuItem(updateIngredient(currentItem, ingredientId)));
  };
  return (
    <ScrollableBoxUI
    // setScrollPosition={setScrollPosition}
    // shadowStatus={shadowStatus}
    >
      <div className="main-area-body">
        <form
          action=""
          className="customization-form form-remove-item"
          onSubmit={(event) => {
            event.preventDefault();
          }}
          autoComplete="off"
        >
          <div className="item-cards">
            {ingredients && ingredients.length > 0
              ? ingredients.map((item, i) => {
                  return (
                    <RemoveIngredient
                      key={i}
                      actionType={actionType}
                      handleChange={handleChange}
                      {...item}
                    />
                  );
                })
              : ''}
          </div>
        </form>
      </div>
    </ScrollableBoxUI>
  );
};
