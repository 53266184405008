import React from 'react';
import PropTypes from 'prop-types';
import { CircleButtonUI } from '../index';
import { TrashIcon as IconTrash } from 'nexticons/solid';
import { getUIModifierKeys } from '../modifiersMapping';

export const RemoveButtonCircleUI = ({ onClick, buttonSize }) => {
  return (
    <CircleButtonUI
      onClick={onClick}
      buttonSize={buttonSize}
      styleType="danger-light"
      icon={<IconTrash width="64%" />}
      title="Remove"
    />
  );
};

RemoveButtonCircleUI.defaultProps = {
  onClick: () => {},
  buttonSize: 's',
};

RemoveButtonCircleUI.propTypes = {
  onClick: PropTypes.func,
  buttonSize: PropTypes.oneOf(getUIModifierKeys('buttonSize')),
};
