import React from 'react';

import { SidePanelHeader } from '../SidePanelHeader';
import { SidePanelDetails } from '../SidePanelDetails';
import { TableNumberControl } from '../TableNumberControl';
import { TableNameControl } from '../TableNameControl';
import { TableSeatsControl } from '../TableSeatsControl';
import { TableOrientationControl } from '../TableOrientationControl';
import IconRemove from 'assets/icons/IconRemove';

import { TableScaleControl } from '../TableScaleControl';

const LAYOUT_OBJECT_NAMES_MAP = {
  PottedPlant: 'Potted Plant',
};

export const TableConfig = ({
  selectedShape,
  handleTableUpdate,
  handleShapeRemove,
  handleClearSelectedShape,
  availableTableNums,
}) => {
  const { number, name, numSeats, orientationAngle, scale } = selectedShape;

  const isDineInObject = selectedShape?.objectType === 'dineInObjects';
  const title = isDineInObject
    ? 'Table Configuration'
    : LAYOUT_OBJECT_NAMES_MAP[selectedShape.type] || '';

  const handleTableValueChange = (param, value) => {
    if (['numSeats', 'orientationAngle', 'scale'].includes(param)) {
      handleOnUpdate(param, value);
      return;
    }
    handleOnUpdate(param, String(value));
  };

  const handleOnUpdate = (param, value) => {
    const updatedTableData = {
      ...selectedShape,
      [param]: value,
    };

    handleTableUpdate(updatedTableData);
  };

  return (
    <section className="side-panel__section side-panel__section--table-menu">
      <div className="side-panel__header-wrapper">
        <SidePanelHeader
          title={title}
          handlePanelClose={handleClearSelectedShape}
        />

        <button className="side-panel__remove-btn" onClick={handleShapeRemove}>
          <IconRemove />
        </button>
      </div>

      <SidePanelDetails>
        <div className="table-configuration">
          {isDineInObject && (
            <>
              <TableSeatsControl
                className="table-configuration__control-item"
                title="Number of Seats"
                desc="Select the number of seats"
                handleTableValueChange={handleTableValueChange}
                paramName="numSeats"
                paramValue={numSeats}
                min={1}
                max={30}
              />
              <TableNumberControl
                className="table-configuration__control-item"
                title="Table Number"
                desc="Select a unique number for the table"
                handleTableValueChange={handleOnUpdate}
                paramName="number"
                paramValue={number}
                availableTableNums={availableTableNums}
              />
              <TableNameControl
                className="table-configuration__control-item"
                title="Table Name"
                desc="Type in the name of the table"
                handleTableValueChange={handleTableValueChange}
                paramName="name"
                paramValue={name}
              />
            </>
          )}
          <TableOrientationControl
            className="table-configuration__control-item"
            title="Angle"
            desc="Adjust the angle of the table"
            handleTableValueChange={handleTableValueChange}
            paramName="orientationAngle"
            paramValue={orientationAngle}
            min={0}
            max={360}
            step={15}
          />
          <TableScaleControl
            className="table-configuration__control-item slider"
            title="Scale"
            desc="Adjust the slider to change the size of the table"
            handleTableValueChange={handleTableValueChange}
            paramName="scale"
            paramValue={scale || 1}
            min={0.5}
            max={2}
            step={0.1}
          />
          {/* 
                for the next version
                <TableConfigurationControl
                  className="table-configuration__control-item"
                  title="Angle"
                  desc="Adjust the angle of the table"
                  handleValueChange={handleValueChange}
                  paramName="orientationAngle"
                  paramValue={orientationAngle}
                  min={0}
                  max={360}
                  step={45}
                />
                  <TableConfigurationControl
                  className="table-configuration__control-item"
                  title="Scale"
                  desc="Adjust the slider to change the size of the table"
                  handleValueChange={handleValueChange}
                  paramName="scale"
                  paramValue={scale}
                /> 
              */}
        </div>
      </SidePanelDetails>
    </section>
  );
};
