import Button from 'components/Button';
import './count-bubble.scss';

export const CountBubble = ({ quantity }) => {
  return (
    <span className="img-box">
      <Button className="count-box" enableThemeColor isCommonStyle>
        {quantity}
      </Button>
    </span>
  );
};
