import { _auth } from '../../firebase';

interface UberDeliveryResponseType {
  code: number;
  data: any;
  errors: string[];
}
export const getUberDeliveryDetails = async (
  orderId: string
): Promise<UberDeliveryResponseType> => {
  const firebaseAuth: any = _auth;
  const accessToken = await firebaseAuth.currentUser.getIdToken(true);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/uberDrive/v1/delivery?orderId=${orderId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    }
  );
  const result = await response.json();
  return result;
};

export const createUberQoute = async (
  orderId: string,
  storeId: string
): Promise<UberDeliveryResponseType> => {
  const firebaseAuth: any = _auth;
  const accessToken = await firebaseAuth.currentUser.getIdToken(true);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/uberDrive/v1/quote`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId,
        storeId,
      }),
    }
  );
  const result = await response.json();
  return result;
};

export const createUberDelivery = async (
  orderId: string,
  storeId: string
): Promise<UberDeliveryResponseType> => {
  const firebaseAuth: any = _auth;
  const accessToken = await firebaseAuth.currentUser.getIdToken(true);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/uberDrive/v1/delivery`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId,
        storeId,
      }),
    }
  );
  const result = await response.json();
  return result;
};

export const cancelUberDelivery = async (
  orderId: string
): Promise<UberDeliveryResponseType> => {
  const firebaseAuth: any = _auth;
  const accessToken = await firebaseAuth.currentUser.getIdToken(true);
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/uberDrive/v1/delivery/cancel`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId,
      }),
    }
  );
  const result = await response.json();
  return result;
};
