import React, { useState, useEffect } from 'react';

import IconInfo from 'assets/icons/IconInfo';

export const TableNameControl = ({
  title,
  paramName,
  paramValue,
  desc,
  className,
  handleTableValueChange,
}) => {
  const [value, setValue] = useState(paramValue);
  const handleChange = (newValue) => {
    setValue(newValue);
    handleTableValueChange(paramName, String(newValue));
  };

  useEffect(() => {
    setValue(paramValue);
  }, [paramValue]);

  return (
    <div className={className + ' table-config-control'}>
      {title && <h2 className="table-config-control__title">{title}</h2>}
      <input
        type="text"
        name="name"
        value={value}
        placeholder="Type table name"
        onChange={(e) => handleChange(e.target.value)}
        className="table-config-control__input"
      />
      {desc && (
        <div className="table-config-control__info">
          <IconInfo className="table-config-control__info-icon" />
          <p>{desc}</p>
        </div>
      )}
    </div>
  );
};
