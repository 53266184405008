import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleHalfSelectedSize,
  toggleHalfHalfEditMode,
  updateCurrentOrder,
  resetCurrentHalfHalfData,
  resetMenuItem,
  setShowPizzaActionButton,
} from '../../pages/Dashboard/action';
import addHalfHalfToOrder from '../../cartProcessor/addHalfHalfToOrder';
import HalfHalfView from './HalfHalfView';

import {
  activeSpecialsSelector,
  storeConfigSelector,
} from '../../pages/Dashboard/selectors';
import updateHalfHalfToOrder from '../../cartProcessor/updateHalfHalfToOrder';
import { findPriceOfMostExpensiveItem } from '../../cartProcessor/utilities';
import generateHalfHalfMenuItems from 'util/generateHalfHalfMenuItems';
import useOrder from 'hooks/useOrder';
import useCategories from 'hooks/useCategories';

const HalfHalfViewWrapper = ({ parentPage }) => {
  const dispatch = useDispatch();
  const categories = useCategories();
  const storeConfig = useSelector(storeConfigSelector);
  let history = useHistory();
  const currentHalfHalfData = useSelector(
    (state) => state.dashboardReducer.currentHalfHalfData
  );
  const activeCategoryId = currentHalfHalfData.activeCategoryId;
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const activeSpecials = useSelector(activeSpecialsSelector);
  const orderSetup = useSelector((state) => state.dashboardReducer.orderSetup);
  const productSetup = useSelector(
    (state) => state.dashboardReducer.productSetup
  );
  const suburbs = useSelector((state) => state.dashboardReducer.suburbs);
  const publicHolidays = useSelector(
    (state) => state.dashboardReducer.publicHolidays
  );
  const courseAutoAssignmentEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.courseAutoAssignmentEnabled
  );
  const [{ selectedCategoryId }] = useOrder();

  const handleClickBtn = () => {
    dispatch(resetCurrentHalfHalfData());
    dispatch(resetMenuItem());
    dispatch(setShowPizzaActionButton(false));
    history.push(`${parentPage}/order`);
  };

  const handleItemClick = (_event, item) => {
    dispatch(setShowPizzaActionButton(false));
    dispatch(toggleHalfHalfEditMode(item.halfIndex));
    history.push(
      `${parentPage}/category/${activeCategoryId}/half-half/${item.halfIndex}`
    );
  };

  const handleAddOrder = () => {
    const { itemNotes } = currentHalfHalfData;
    let currentMenuItems =
      currentHalfHalfData.halfHalfs?.map((cat) => {
        if (cat.halfIndex === 1) {
          return { ...cat.currentMenuItem, itemNotes };
        }
        return cat.currentMenuItem;
      }) || [];

    if (productSetup.chargeExpensiveHalf) {
      let newPrice = findPriceOfMostExpensiveItem(currentMenuItems);
      // currentMenuItems = updateItemsWithNewPrice(currentMenuItems, newPrice);
      currentMenuItems = generateHalfHalfMenuItems(
        currentMenuItems,
        newPrice,
        productSetup.chargeExpensiveHalf
      );
    }

    const { isInModifyMode = false } = currentHalfHalfData;

    const selectedCategory = categories.filter((category) =>
      currentMenuItems?.some((item) => item.categoryId === category._id)
    );

    if (
      selectedCategory?.length > 0 &&
      selectedCategory?.some((cat) => !!cat?.courseId) &&
      !currentMenuItems?.every((item) => !!item.courseId)
    ) {
      const selectedCategoryWithCourse = selectedCategory?.find(
        (cat) => !!cat?.courseId
      );

      const menutItemsWithCourse = currentMenuItems?.map((item) => {
        return {
          ...item,
          courseId: selectedCategoryWithCourse?.courseId,
          courseName: selectedCategoryWithCourse?.courseName,
        };
      });

      currentMenuItems = menutItemsWithCourse;
    }

    // Remove Course Id If orderType is not equal to dine-in
    if (currentOrder?.orderType !== '3' || !courseAutoAssignmentEnabled) {
      currentMenuItems?.forEach((item) => {
        delete item.courseId;
        delete item.courseName;
      });
    }

    if (isInModifyMode) {
      dispatch(
        updateCurrentOrder(
          updateHalfHalfToOrder(
            currentOrder,
            currentMenuItems,
            activeSpecials,
            orderSetup,
            productSetup,
            suburbs,
            publicHolidays,
            storeConfig
          )
        )
      );
    } else {
      dispatch(
        updateCurrentOrder(
          addHalfHalfToOrder(
            currentOrder,
            currentHalfHalfData,
            currentMenuItems,
            activeSpecials,
            orderSetup,
            productSetup,
            suburbs,
            publicHolidays,
            storeConfig
          )
        )
      );
    }
    dispatch(resetCurrentHalfHalfData());
    dispatch(resetMenuItem());
    dispatch(setShowPizzaActionButton(false));
    history.push(`${parentPage}/order`);
  };

  const handleSizeClick = (size, variantSelected) => {
    !size.isDefault &&
      dispatch(
        toggleHalfSelectedSize({
          size,
          variantSelected,
          history,
          parentPage,
          selectedCategoryId,
        })
      );
  };

  const handleVariantClick = (size, variantSelected) => {
    dispatch(
      toggleHalfSelectedSize({
        size,
        variantSelected,
        history,
        parentPage,
        selectedCategoryId,
      })
    );
  };

  return (
    <HalfHalfView
      halfHalfData={currentHalfHalfData}
      onClose={handleClickBtn}
      onSizeClick={handleSizeClick}
      onVariantClick={handleVariantClick}
      onSubmit={handleAddOrder}
      onSectionClick={handleItemClick}
      submitLabel="Add to Order"
      parentPage={parentPage}
      toggleItemEditMode={(halfIndex) =>
        dispatch(toggleHalfHalfEditMode(halfIndex))
      }
    />
  );
};

export default HalfHalfViewWrapper;
