import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconMoney = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-money': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 32 27"
      >
        <g>
          <g>
            <g>
              <g>
                <path
                  className="st0"
                  fill="none"
                  d="M22.8,20.7v0.8c0,0.4,1.4,1.2,3.7,1.2c2.3,0,3.7-0.8,3.7-1.2v0v-0.8c-1.2,0.6-2.4,0.8-3.7,0.8      C25.3,21.6,24,21.3,22.8,20.7z"
                />
                <path
                  className="st0"
                  fill="none"
                  d="M22.8,18.3v0.8c0,0.4,1.4,1.2,3.7,1.2c2.3,0,3.7-0.8,3.7-1.2v-0.8c-1.2,0.6-2.4,0.8-3.7,0.8      C25.3,19.1,24,18.8,22.8,18.3z"
                />
                <path
                  className="st0"
                  fill="none"
                  d="M22.8,23.2V24c0,0.4,1.4,1.2,3.7,1.2c2.3,0,3.7-0.8,3.7-1.2v-0.8c-1.2,0.6-2.4,0.8-3.7,0.8      C25.3,24.1,24,23.8,22.8,23.2z"
                />
                <path
                  className="st0"
                  fill="none"
                  d="M26.5,15.4c-2.3,0-3.7,0.8-3.7,1.2c0,0.4,1.4,1.2,3.7,1.2c2.3,0,3.7-0.8,3.7-1.2      C30.3,16.2,28.8,15.4,26.5,15.4z"
                />
                <rect
                  x="3"
                  y="3"
                  fill={iconColor ? iconColor : '#000'}
                  stroke={iconColor ? iconColor : '#000'}
                  strokeWidth={lineThickness ? lineThickness : 0}
                  width="22.3"
                  height="1.2"
                />
                <rect
                  x="5.5"
                  y="0.5"
                  fill={iconColor ? iconColor : '#000'}
                  stroke={iconColor ? iconColor : '#000'}
                  strokeWidth={lineThickness ? lineThickness : 0}
                  width="17.4"
                  height="1.2"
                />
                <path
                  fill={iconColor ? iconColor : '#000'}
                  stroke={iconColor ? iconColor : '#000'}
                  strokeWidth={lineThickness ? lineThickness : 0}
                  d="M14.1,7.9c-2.7,0-5,2.2-5,5c0,2.7,2.2,5,5,5c2.7,0,5-2.2,5-5c0-1.3-0.5-2.6-1.5-3.5      C16.7,8.5,15.5,7.9,14.1,7.9z M14.1,16.6c-2.1,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7S16.2,16.6,14.1,16.6z"
                />
                <path
                  fill={iconColor ? iconColor : '#000'}
                  stroke={iconColor ? iconColor : '#000'}
                  strokeWidth={lineThickness ? lineThickness : 0}
                  d="M27.8,14.2V6.1c0-0.3-0.3-0.6-0.6-0.6h-26c-0.3,0-0.6,0.3-0.6,0.6v13.6c0,0.3,0.3,0.6,0.6,0.6h20.5V24      c0,1.6,2.5,2.5,5,2.5c2.5,0,5-0.9,5-2.5v-7.4C31.5,15.2,29.8,14.5,27.8,14.2z M21.6,16.6v2.5H1.7V6.7h24.8v7.4      C24.1,14.1,21.6,14.9,21.6,16.6z M30.3,24c0,0.4-1.4,1.2-3.7,1.2c-2.3,0-3.7-0.8-3.7-1.2v-0.8c1.2,0.6,2.4,0.8,3.7,0.8      c1.3,0,2.6-0.2,3.7-0.8V24z M30.3,21.6L30.3,21.6c0,0.4-1.4,1.2-3.7,1.2c-2.3,0-3.7-0.8-3.7-1.2v-0.8c1.2,0.6,2.4,0.8,3.7,0.8      c1.3,0,2.6-0.2,3.7-0.8V21.6z M30.3,19.1c0,0.4-1.4,1.2-3.7,1.2c-2.3,0-3.7-0.8-3.7-1.2v-0.8c1.2,0.6,2.4,0.8,3.7,0.8      c1.3,0,2.6-0.2,3.7-0.8V19.1z M26.5,17.8c-2.3,0-3.7-0.8-3.7-1.2c0-0.4,1.4-1.2,3.7-1.2c2.3,0,3.7,0.8,3.7,1.2      C30.3,17,28.8,17.8,26.5,17.8z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      {/*<svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="27"
        viewBox="0 0 32 27"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M30.26 19.071v-.804a8.095 8.095 0 0 1-3.72.804 8.095 8.095 0 0 1-3.72-.804v.804c0 .434 1.414 1.239 3.72 1.239s3.72-.805 3.72-1.239zm0 2.48v-.808a8.062 8.062 0 0 1-3.72.809 8.062 8.062 0 0 1-3.72-.81v.81c0 .435 1.414 1.244 3.72 1.244s3.72-.809 3.72-1.244zm0 2.473v-.805a8.095 8.095 0 0 1-3.72.805 8.095 8.095 0 0 1-3.72-.805v.805c0 .433 1.414 1.238 3.72 1.238s3.72-.805 3.72-1.238zm-8.68-4.953v-2.476c0-1.659 2.48-2.476 4.96-2.476V6.69H1.74v12.381zm4.96-3.714c-2.306 0-3.72.802-3.72 1.233 0 .432 1.414 1.233 3.72 1.233s3.72-.801 3.72-1.233c0-.431-1.414-1.233-3.72-1.233zm4.96 1.238v7.429c0 1.622-2.48 2.476-4.96 2.476s-4.96-.854-4.96-2.476V20.31H1.12a.62.62 0 0 1-.62-.62V6.071a.62.62 0 0 1 .62-.619h26.04a.62.62 0 0 1 .62.62v8.158c1.996.236 3.72 1.016 3.72 2.365zM22.82 1.738H5.46V.5h17.36zm2.48 2.466H2.98V2.976H25.3zm-8.53 11.303a3.711 3.711 0 0 0-2.63-6.34 3.717 3.717 0 0 0-3.72 3.714 3.723 3.723 0 1 0 6.35 2.626zm.877-6.128a4.949 4.949 0 0 1 1.453 3.502 4.956 4.956 0 0 1-4.96 4.952 4.956 4.956 0 0 1-4.96-4.952 4.956 4.956 0 0 1 4.96-4.952c1.315 0 2.577.521 3.507 1.45z"
              />
            </g>
          </g>
        </g>
      </svg>*/}
    </span>
  );
};

PosIconMoney.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
