import React from 'react';
import './CartTag.css';
import { XRoundedIcon } from 'nexticons/solid';
import clsx from 'clsx';

const CartTag = ({
  customIcon,
  label,
  isActive,
  onClick,
  customClass,
  customSvgClass,
}) => {
  return (
    <div
      key={label}
      className={clsx('cart-tag', {
        [`${customClass}`]: isActive,
      })}
      onClick={onClick}
    >
      {isActive ? (
        <XRoundedIcon width={12} className={`${customSvgClass}`} color="red" />
      ) : (
        customIcon
      )}
      <span
        className={clsx(
          { ['cart-tag-text']: !isActive },
          {
            [`${customClass}`]: isActive,
          }
        )}
      >
        {label}
      </span>
    </div>
  );
};

export default CartTag;
