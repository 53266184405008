import React from 'react';
import { useSelector } from 'react-redux';

import {
  storeCountryIdentifierSelector,
  currencySymbolSelector,
} from '../../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';

export const SubModifier = ({
  subModifierId,
  modifierId,
  variantId,
  name,
  quantity,
  variantQuantity,
  price,
  prices,
  handleChange,
  itemBaseSubModifierId,
  isBaseSection,
  isSoldOut,
}) => {
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  const filterFunction = (f) => f._id === itemBaseSubModifierId;

  let isDisableOption = isBaseSection
    ? false
    : prices
    ? prices.filter(filterFunction).length > 0
      ? prices.filter(filterFunction)[0].isSelected === false
        ? true
        : false
      : false
    : false;

  return (
    !isDisableOption && (
      <label className="item-detail-card">
        <h3 className="detail-card-title">
          {name}
          {isSoldOut && <span className="soldout-size-tag">Sold Out</span>}
        </h3>
        <p className="detail-card-price">
          {isBaseSection
            ? getAmountWithCountryCurrency({
                amount: Number(price),
                locale: `en-${storeCountryIdentifier}`,
                currencyCode,
              })
            : prices
            ? prices.filter(filterFunction).length > 0
              ? Number(prices.filter(filterFunction)[0].price) !== 0
                ? getAmountWithCountryCurrency({
                    amount: Number(prices.filter(filterFunction)[0].price),
                    locale: `en-${storeCountryIdentifier}`,
                    currencyCode,
                  })
                : ''
              : ''
            : ''}
        </p>

        <span className="detail-card-input-box">
          <input
            type="radio"
            name={`${subModifierId}-${subModifierId}-${variantId}`}
            id={`${subModifierId}-${subModifierId}-${variantId}`}
            value={name}
            checked={
              variantId === 'xxx'
                ? variantQuantity === 0 && quantity > 0
                : variantQuantity > 0
            }
            onChange={(event) =>
              handleChange(
                event,
                subModifierId,
                modifierId,
                variantId,
                itemBaseSubModifierId
              )
            }
          />
          <span className="checkstyle" />
        </span>
      </label>
    )
  );
};
