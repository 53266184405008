const HEADERS = [
  'Day',
  'Role',
  'Clocked In',
  'Closed Out',
  'Paid Hours',
  'Break Time',
];

const TableHeadCell = ({ content }) => (
  <th className="table-main__head-cell">{content}</th>
);

const TableBodyCell = ({ content }) => (
  <td className="table-main__body-cell">{content}</td>
);

const TableBodyRow = ({ shift }) => {
  return (
    <tr className="table-main__body-row">
      <TableBodyCell content={shift.day} />
      <TableBodyCell content={shift.role} />
      <TableBodyCell content={shift.clockedIn} />
      <TableBodyCell content={shift.clockedOut} />
      <TableBodyCell content={shift.paidHours} />
      <TableBodyCell content={shift.breakTime} />
    </tr>
  );
};

const ShiftsTable = ({ shifts }) => {
  return (
    <table className="table-main">
      <thead className="table-main__head">
        <tr className="table-main__head-row">
          {HEADERS.map((content) => (
            <TableHeadCell key={content} content={content} />
          ))}
        </tr>
      </thead>
      <tbody className="table-main__body">
        {shifts?.map((shift) => (
          <TableBodyRow key={shift.id} shift={shift} />
        ))}
      </tbody>
    </table>
  );
};

export default ShiftsTable;
