import { API_URL } from '../../../configuration/apiUrl';
import { _auth } from '../../../firebase';
import axiosWithInterceptor from '../../../util/interceptor';

export const callCourses = async (accessToken, courseIds = [], orderId) => {
  let success = false;
  // const accessToken = await _auth.currentUser.getIdToken(true);

  await axiosWithInterceptor
    .post(
      `${API_URL}/orders/v1/courses/call`,
      {
        courseIds,
        orderId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      if (response?.status === 204) {
        success = true;
      }
    });

  return success;
};

export const updateMenuItemCourseStatus = async (menuItems, orderId) => {
  let success = false;

  await axiosWithInterceptor
    .post(
      `${API_URL}/orders/v1/order/updateCourseStatus`,
      {
        menuItems,
        orderId,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      if (response?.status === 204 || response?.status === 200) {
        success = true;
      }
    });

  return success;
};
