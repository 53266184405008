import React, { useMemo, useState } from 'react';

import { PosIconLeftChevron } from '../../../assets/icons/PosIconLeftChevron';
import applePayImg from '../../../assets/img/pay_apple.svg';
import visaPayImg from '../../../assets/img/pay_visa.svg';
import masterPayImg from '../../../assets/img/pay_master.svg';

import Button from '../../Button';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import {
  getTimestampEndOfTheDay,
  getTimestampStartOfTheDay,
} from '../../../util/timeHelper';
import { getAmountWithCountryCurrency } from '../../../util/currencyFormat';
import { useSelector } from 'react-redux';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../../pages/Dashboard/selectors';
import { ButtonUI } from 'UI/components';
import ModalPortal from 'components/ModalPortal';
import MarkDeliveryOrderCompleteModal from './MarkDeliveryOrderCompleteModal';
import { markAsDelivered } from './DriverOrderList.service';
import clsx from 'clsx';

const getOrderDateFilter = (timestamp) => {
  if (
    timestamp >= getTimestampStartOfTheDay() &&
    timestamp < getTimestampEndOfTheDay()
  ) {
    return 'current';
  } else if (timestamp < getTimestampStartOfTheDay()) {
    return 'history';
  }
  return 'future';
};

export const DriverSingleOrderItem = ({ order, currentDriverData }) => {
  const [isMarkAsDeliveredApiLoading, setIsMarkAsDeliveredApiLoading] =
    useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const [openMarkDeliveryOrderComplete, setOpenMarkDeliveryOrderComplete] =
    useState(false);

  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  // const { orderList } = useSelector(driversReducerData);

  // order to get other order's information like payableAmount, customer first name and etc

  // const currentOrder = orderList.find(o=>o._id === order.orderId) || {};

  const { orderStatus, orderStatusClassName, duration } = useMemo(() => {
    const timeDifference = Math.floor(
      (order.deliveryDateTimestamp - new Date().getTime()) / 60000
    );
    if (order.orderStatus === '3') {
      return {
        orderStatus: 'On Delivery',
        orderStatusClassName: 'red',
        duration:
          timeDifference > 0
            ? `${timeDifference} mins away`
            : `${timeDifference} mins late`,
      };
    }
    if (order.orderStatus === '4') {
      return {
        orderStatus: 'Delivered',
        orderStatusClassName: 'green',
      };
    }
    return {
      orderStatus: 'Waiting on Store',
      orderStatusClassName: 'orange',
      duration:
        timeDifference > 0
          ? `Due in ${timeDifference} mins`
          : `${Math.abs(timeDifference)} mins late`,
    };
  }, [order.orderStatus, order.deliveryDateTimestamp]);

  const deliveryTime = moment(order.deliveryDate).format('hh:mma');

  const handleClick = () => {
    let filter = getOrderDateFilter(order.deliveryDateTimestamp);
    history.push({
      pathname: `/orders/${filter}/all`,
      state: {
        orderID: order.orderId,
        backLink: pathname,
      },
    });
  };

  const handleModal = (order) => {
    setOpenMarkDeliveryOrderComplete(true);
  };

  const handleMarkAsDelivered = async () => {
    const { shiftId, _id, restaurantId, storeId } = currentDriverData;
    setIsMarkAsDeliveredApiLoading(true);
    await markAsDelivered(
      _id,
      storeId,
      restaurantId,
      shiftId,
      order?.orderId,
      order?.isOtherDelivery
    );
    setIsMarkAsDeliveredApiLoading(false);
    setOpenMarkDeliveryOrderComplete(!openMarkDeliveryOrderComplete);
  };
  return (
    <>
      <div
        key={order.id}
        className={clsx('single-detail-box', {
          ['single-detail-box-completed']: order?.orderStatus === '4',
        })}
      >
        <div className="detail-row row-1">
          <div className="detail-cell">
            <h3 className="name">{order.address}</h3>
          </div>

          <div className="detail-cell">
            <div className="status-box">
              <div className={'status-btn order-id ' + orderStatusClassName}>
                #{order.deliveryCode}
              </div>
              <span className="booking-count-box cash">
                <span className={'pay-img-wrap ' + order.payMethod}>
                  <img
                    src={
                      order.payMethod === 'apple'
                        ? applePayImg
                        : order.payMethod === 'visa'
                        ? visaPayImg
                        : masterPayImg
                    }
                    alt={order.payMethod}
                  />
                </span>
                <span className="count">
                  {getAmountWithCountryCurrency({
                    amount: Number(order.payableAmount || 0),
                    locale: `en-${storeCountryIdentifier}`,
                    currencyCode,
                  })}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="detail-row row-2">
          <div className="detail-cell">
            <div>
              <span className={'text-status ' + orderStatusClassName}>
                {orderStatus}
                {' ' + deliveryTime}
              </span>{' '}
              - {duration || 'On Time'}
            </div>
          </div>
          <div className="detail-cell">
            <span className="client-name-box">
              <span className="client-name" style={{ paddingRight: '10px' }}>
                {order.firstName || ''} {order.lastName || ''}
              </span>
              <span className="dine_icon-shape_man" />
            </span>
          </div>
        </div>

        {order?.orderStatus !== '4' && (
          <div className="deliver-mark-completed">
            <ButtonUI
              styleType="primary"
              sizeType="m"
              fontSize="10px"
              style={{ backgroundColor: '#fd6c00', borderColor: '#fd6c00' }}
              onClick={() => handleModal()}
            >
              <span>{`Mark as Delivered`}</span>
            </ButtonUI>
          </div>
        )}

        <Button
          onClick={handleClick}
          title="Read More"
          className="driver-read-more"
        >
          <PosIconLeftChevron
            mainColor="#b2b2b2"
            darkModeColor="#b2b2b2"
            lineThickness={0.5}
          />
        </Button>
      </div>
      {openMarkDeliveryOrderComplete && (
        <ModalPortal>
          <MarkDeliveryOrderCompleteModal
            show={openMarkDeliveryOrderComplete}
            onModalClose={() =>
              setOpenMarkDeliveryOrderComplete(!openMarkDeliveryOrderComplete)
            }
            handleMarkAsDelivered={handleMarkAsDelivered}
            isMarkAsDeliveredApiLoading={isMarkAsDeliveredApiLoading}
            order={order}
          />
        </ModalPortal>
      )}
    </>
  );
};
