import React from 'react';
import { useHistory } from 'react-router';

import ShiftsButton from './ShiftsButton';
import ShiftsPageContainer from './Containers/ShiftsPageContainer';
import ShiftsBlockContainer from './Containers/ShiftsBlockContainer';

import {
  useShiftsState,
  useShiftsStateDispatch,
  useStaffMemberActiveShift,
} from './Context/ShiftsState';

import { getCurrentTime } from '../../util/dateUtils';
import { getParamValue } from '../../util/utils';
import { ShiftsEnum } from './constants';
import { clockInUser, clockOutUser } from '../../services/shiftsServices';

const SelectRole = () => {
  const history = useHistory();

  const statusValue = getParamValue('status');
  const isSwitchRoles = statusValue === ShiftsEnum.SWITCH_ROLES;

  const staffMember = useShiftsState();
  const { activeShift: staffMemberActiveShift } = useStaffMemberActiveShift();

  const { startShift } = useShiftsStateDispatch();

  const { roles: staffMemberRoles, _id: staffMemberId } = staffMember;

  const [loadingRole, setLoadingRole] = React.useState('');

  const handleRoleClick = async (roleId) => {
    setLoadingRole(roleId);
    if (isSwitchRoles) {
      await clockOutUser(staffMemberActiveShift.id);
    }
    const { data } = await clockInUser(roleId, staffMemberId);
    const { shift } = data;
    startShift(shift);
    setLoadingRole('');
    history.push(
      `/shifts/confirmation?status=${
        isSwitchRoles ? ShiftsEnum.SWITCHED_ROLES : ShiftsEnum.CLOCKED_IN
      }`
    );
  };

  const selectRoleText = `Select your ${isSwitchRoles ? 'new ' : ''} Role`;

  return (
    <ShiftsPageContainer>
      <ShiftsBlockContainer>
        <div className="clock-in-out__header-group">
          <h2 className="clock-in-out__title">{getCurrentTime()}</h2>
          <p className="clock-in-out__desc clock-in-out__desc--size-big">
            {selectRoleText}
          </p>
        </div>
        <div className="clock-in-out__btn-group">
          {staffMemberRoles.map((role) => (
            <ShiftsButton
              key={role.id}
              title={role?.name}
              handleOnClick={() => handleRoleClick(role.id)}
              iconName="waiter"
              isLoading={role.id === loadingRole}
              isActiveRole={role.id === staffMemberActiveShift.roleId}
            />
          ))}
          {/* <ShiftsButton
            title="Waiter"
            // handleOnClick={handleClockInClick}
            iconName="waiter"
          />
          <ShiftsButton
            title="Driver"
            // handleOnClick={handleClockInClick}
            iconName="scooter"
            buttonTheme="orange"
          />
          <ShiftsButton
            title="Chef"
            // handleOnClick={handleClockInClick}
            iconName="chef"
            buttonTheme="purple"
          />
          <ShiftsButton
            title="Dishwasher"
            // handleOnClick={handleClockInClick}
            iconName="dishwasher"
            buttonTheme="pink"
          /> */}
        </div>
      </ShiftsBlockContainer>
    </ShiftsPageContainer>
  );
};

export default SelectRole;
