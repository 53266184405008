import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleSpecialsHalfSelectedSize,
  toggleSpecialsHalfHalfEditMode,
  resetSpecialsCurrentHalfHalfData,
  updateCurrentSpecialsCategoryItem,
  newToggleSpecialsHalfSelectedSize,
} from '../../pages/Dashboard/action';
import HalfHalfView from '../HalfHalfView/HalfHalfView';
import { setActiveCategoryIndex } from '../../pages/Dashboard/action';
import useCategories from 'hooks/useCategories';

const SpecialsHalfHalfView = ({ parentPage }) => {
  const dispatch = useDispatch();
  const categories = useCategories();
  let history = useHistory();
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const courseAutoAssignmentEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.courseAutoAssignmentEnabled
  );
  const currentSpecialsItem = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem
  );
  const { categoryIndex, specialsId } = useParams();
  const currentHalfHalfData =
    currentSpecialsItem.selectedCategories[categoryIndex]
      ?.currentHalfHalfData || {};
  const handleClickBtn = () => {
    dispatch(resetSpecialsCurrentHalfHalfData());
    history.push(`${parentPage}/order`);
  };

  const handleItemClick = (_event, item) => {
    const isEditHalfItem = Boolean(
      currentHalfHalfData?.halfHalfs?.find(
        (cat) => cat?.halfIndex === item.halfIndex
      )?.currentMenuItem
    );
    if (isEditHalfItem)
      dispatch(
        toggleSpecialsHalfHalfEditMode(Number(categoryIndex), item.halfIndex)
      );
    history.push(
      `${parentPage}/specials/${specialsId}/${categoryIndex}/half-half/${item.halfIndex}`
    );
  };

  useEffect(() => {
    if (categoryIndex) {
      dispatch(setActiveCategoryIndex(categoryIndex));
    }
  }, [categoryIndex]);

  const handleAddToSpecials = () => {
    let currentMenuItems =
      currentHalfHalfData.halfHalfs.map((cat) => {
        return { ...cat.currentMenuItem, isSpecialItem: true };
      }) || [];

    const selectedCategory = categories.filter((category) =>
      currentMenuItems?.some((item) => item.categoryId === category._id)
    );
    if (
      selectedCategory?.length > 0 &&
      selectedCategory?.some((cat) => !!cat?.courseId) &&
      !currentMenuItems?.every((item) => !!item.courseId)
    ) {
      const selectedCategoryWithCourse = selectedCategory?.find(
        (cat) => !!cat?.courseId
      );
      const menutItemsWithCourse = currentMenuItems?.map((item) => {
        return {
          ...item,
          courseId: selectedCategoryWithCourse?.courseId,
          courseName: selectedCategoryWithCourse?.courseName,
        };
      });

      currentMenuItems = menutItemsWithCourse;
    }

    // Remove Course Id If orderType is not equal to dine-in
    if (currentOrder?.orderType !== '3' || !courseAutoAssignmentEnabled) {
      currentMenuItems?.forEach((item) => {
        delete item.courseId;
        delete item.courseName;
      });
    }

    dispatch(
      updateCurrentSpecialsCategoryItem(parseInt(parseInt(categoryIndex)), [
        ...currentMenuItems,
      ])
    );
    dispatch(resetSpecialsCurrentHalfHalfData());
    history.push(`${parentPage}/specials/${specialsId}`);
  };

  const handleVariantClick = (size, variantSelected) => {
    dispatch(
      newToggleSpecialsHalfSelectedSize({
        categoryIndex: Number(categoryIndex),
        size,
        variantSelected,
      })
    );
  };

  const handleSizeClick = (size) => {
    !size.isDefault && dispatch(toggleSpecialsHalfSelectedSize(size));
  };

  return (
    <HalfHalfView
      halfHalfData={currentHalfHalfData}
      onClose={handleClickBtn}
      onSizeClick={handleSizeClick}
      onVariantClick={handleVariantClick}
      onSubmit={handleAddToSpecials}
      onSectionClick={handleItemClick}
      submitLabel="Add to Specials"
      parentPage={parentPage}
      toggleItemEditMode={(halfIndex) =>
        dispatch(
          toggleSpecialsHalfHalfEditMode(Number(categoryIndex), halfIndex)
        )
      }
      isSpecialsHalfHalf={true}
    />
  );
};

export default SpecialsHalfHalfView;
