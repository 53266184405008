import React, { useContext } from 'react';
import Lottie from 'react-lottie';
import refoundErrorFailed from '../../assets/lotties/19230-payment-failed.json';
import GeneralModal from '../GeneralModal/index-new';
import RefundContext from '../../context/RefundContext';
import { useOrdersGlobalState } from '../../pages/Orders/components/Providers/OrdersGlobalState/OrdersGlobalState';

const capitalize = (s) => `${s.substr(0, 1).toUpperCase()}${s.substr(1)}`;

const RefundErrorModal = ({ showModal, onModalClose, refundType }) => {
  const [state, setState] = useContext(RefundContext);
  const { selectedOrder } = useOrdersGlobalState();

  return (
    <GeneralModal
      modalStatus={showModal && state.step === 'error'}
      toggleModal={onModalClose}
      position="center"
      effect="move"
      overlay=""
      boxWidth="542px"
      boxHeight="563px"
      className="general-modal general-modal--theme-action grey-bg "
    >
      {showModal && state.step === 'error' && (
        <div className="general-modal__inner general-modal__inner--space-between-biggest ">
          <div className="general-modal__header general-modal__header--space-between-biggest ">
            <div className="general-modal__title-box">
              <h3 className="general-modal__title general-modal__title--spacing-large">
                Couldn't process the {capitalize(refundType)}
              </h3>
            </div>
            <div className="general-modal__icon-box">
              <div
                style={{
                  position: 'relative',
                  zIndex: 10,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  height: 160,
                }}
              >
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: refoundErrorFailed,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  width={320}
                  height={320}
                  eventListeners={[
                    {
                      eventName: 'complete',
                      callback: () => {},
                    },
                  ]}
                />
              </div>
            </div>
            <div className="general-modal__desc general-modal__desc--small-width general-modal__desc--size-big">
              <p>
                There was an unexpected error while processing this {refundType}
              </p>
            </div>
          </div>

          <div className="general-modal__footer">
            <div className="general-modal__actions-group">
              <button
                className="general-modal__action"
                title="Close"
                type="button"
                onClick={onModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </GeneralModal>
  );
};

export default RefundErrorModal;
