import React from 'react';

function IconPlus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <path
        fill="#363636"
        d="M17.15 25.132c.296 0 .55-.105.76-.316.211-.21.316-.46.316-.75V18.21h5.848c.29 0 .54-.104.751-.312.21-.208.316-.46.316-.756 0-.297-.105-.55-.316-.76a1.022 1.022 0 00-.75-.317h-5.849V10.22c0-.291-.105-.542-.316-.752a1.037 1.037 0 00-.76-.316 1.02 1.02 0 00-.755.316c-.208.21-.312.461-.312.752v5.846h-5.855c-.291 0-.542.106-.753.316a1.04 1.04 0 00-.316.76c0 .297.106.549.316.757.21.208.462.312.753.312h5.855v5.856c0 .29.104.54.312.75.207.211.459.316.755.316z"
        opacity="0.7"
        transform="translate(-103 -245) translate(-2.5 21) translate(1.5 155.5) translate(13 15) translate(0 43) translate(82 2)"
      ></path>
    </svg>
  );
}

export default IconPlus;
