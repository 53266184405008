import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InnerModal from '../InnerModal';
import Button from '../../components/Button';
import SelectDeliveryTime from '../../components/SelectDeliveryTime';

const InnerSelectTimeModal = ({
  modalStatus,
  toggleModal,
  onConfirmClick,
  setTimeIndex,
  timeIndex,
  timeText,
  orderTimes,
}) => {
  const TimeSelect = useMemo(() => {
    return (
      <SelectDeliveryTime
        setDeliveryTimeData={setTimeIndex}
        orderTimes={orderTimes}
        timeIndex={timeIndex}
      />
    );
  }, [timeIndex, setTimeIndex, orderTimes]);

  const handleClick = () => {
    onConfirmClick();
  };

  return (
    <InnerModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="bottom"
      effect="move"
      boxWidth="100%"
      boxHeight="341px"
      customClass="select-time-modal radius-none"
      closeBtn="outside"
    >
      <div className="select-time-modal-box">
        <div className="select-time-wrap">{TimeSelect}</div>
        <div className="select-time-btn-box">
          <Button
            className="btn full-width"
            title={`Confirm Delivery Time - ${timeText}`}
            onClick={handleClick}
          >
            {timeText && `Confirm Delivery Time - ${timeText}`}
          </Button>
        </div>
      </div>
    </InnerModal>
  );
};

InnerSelectTimeModal.propTypes = {
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default InnerSelectTimeModal;
