import React from 'react';
import classNames from 'classnames';

function IconViewOrder({ className, ...props }) {
  const iconCls = classNames({
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
        x="0px"
        y="0px"
      >
        <title>{'List-2'}</title>
        <path
          d="M2.5 22A2.5 2.5 0 1 1 5 19.5 2.5 2.5 0 0 1 2.5 22Zm0-4A1.5 1.5 0 1 0 4 19.5 1.5 1.5 0 0 0 2.5 18ZM25 20H7v-1h18ZM2.5 15A2.5 2.5 0 1 1 5 12.5 2.5 2.5 0 0 1 2.5 15Zm0-4A1.5 1.5 0 1 0 4 12.5 1.5 1.5 0 0 0 2.5 11ZM23 13H7v-1h16ZM2.5 8A2.5 2.5 0 1 1 5 5.5 2.5 2.5 0 0 1 2.5 8Zm0-4A1.5 1.5 0 1 0 4 5.5 1.5 1.5 0 0 0 2.5 4ZM25 6H7V5h18Z"
          style={{
            fill: '#494b4c',
          }}
        />
      </svg>
    </span>
  );
}

export default IconViewOrder;
