import { getCurrentTime, getCurrentDate } from "../../util/dateUtils";
import { useState, useEffect } from "react";

const DateTimeBox = () => {
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  
  useEffect(()=>{
    const interval = setInterval(()=>{
      setCurrentTime(getCurrentTime())
      setCurrentDate(getCurrentDate());
    }, 500)
    return () =>{
      clearInterval(interval);
    }
  }, []);

  return (
    <div className="date-time-box">
      <div className="time">{currentTime}</div>
      <div className="date">{currentDate}</div>
    </div>
  )
};

export default DateTimeBox;