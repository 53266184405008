import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setState } from 'pages/TableArrangement/reducer';
import { useFloorLayouts } from 'notifications/context/NotificationsState';
import './bem-move-table.scss';
import { BasePartUI, TableCardUI } from '../../UI/components';
import { ChevronLeftIcon } from 'nexticons/outline';

const MoveTable = ({ handleMove, currentLayoutId, availableTables }) => {
  const [floorLayouts] = useFloorLayouts();
  const dispatch = useDispatch();

  const floorLayoutsMap = floorLayouts.reduce(
    (a, k) => ({ ...a, [k.id]: k }),
    {}
  );

  const close = useCallback(
    () =>
      dispatch(
        setState({
          movingOrder: false,
        })
      ),
    [dispatch]
  );

  const tables = [
    ...availableTables.filter(({ layoutId }) => layoutId === currentLayoutId),
    ...availableTables.filter(({ layoutId }) => layoutId !== currentLayoutId),
  ];

  return (
    <section className="side-panel__section side-panel__section--table-menu bem-move-table">
      <BasePartUI>
        <BasePartUI.Header>
          <div className="bem-move-table__top-box">
            <ChevronLeftIcon width={25} strokeWidth={2.5} onClick={close} />
          </div>
          <h2 className="bem-move-table__title">Available Tables</h2>
        </BasePartUI.Header>
        <BasePartUI.Body scrollShadow>
          {tables.map(({ name, number, numSeats, id, layoutId }) => (
            <TableCardUI
              key={layoutId + '_' + id}
              tableName={name || `Table ${number}`}
              floorName={floorLayoutsMap[layoutId].name}
              seats={numSeats}
              onClick={() =>
                handleMove({
                  dineInObjectId: id,
                  floorLayoutId: layoutId,
                  dineInObjectNumber: number,
                })
              }
              onMouseEnter={() => dispatch(setState({ hoverTableId: id }))}
              onMouseLeave={() => dispatch(setState({ hoverTableId: null }))}
            />
          ))}
        </BasePartUI.Body>
      </BasePartUI>
    </section>
  );
};

export default MoveTable;
