import { API_URL } from '../configuration/apiUrl';
import { paymentTypeObj } from '../configuration/constants';
import { nextOrderConfig } from '../configuration/nextOrderConfig';
import { _auth } from '../firebase';

export const updatePickUpDeliveryTime = (type, time, storeConfig) => {
  //type : 1-pickup, 2- delivery
  fetch(`${nextOrderConfig.cloudFunctionUrl}/updateOrderWaitingTime`, {
    method: 'post',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      storeId: storeConfig?.storeId,
      type,
      time,
    }),
  }).then((response) => {});
};

export const fetchAllPrinters = async (storeConfig) => {
  const response = await fetch(
    `${nextOrderConfig.cloudFunctionUrl}/getPrinters
  `,
    {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        storeId: storeConfig?.storeId,
      }),
    }
  );
  return response.json();
};

export const printBillCommand = async ({
  token,
  configUrl,
  printerId,
  orderId,
  restaurantId,
  storeId,
  receiptType,
  openCashDrawer = true,
  printReceipt = true,
  timeZone,
  isReprinting = false,
  checkId,
}) => {
  const response = await fetch(
    `${configUrl ?? nextOrderConfig.cloudFunctionUrl}/addPrinterCommand`,
    {
      method: 'post',
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        printerId,
        orderId,
        restaurantId,
        storeId,
        receiptType,
        printReceipt,
        openCashDrawer,
        timeZone: timeZone ?? 'Australia/Melbourne',
        isReprinting,
        checkId,
      }),
    }
  );
  return response.json();
};

export const getOrdersHistory = async ({
  userId,
  storeId,
  paymentType,
  occupancyId,
  limit,
}) => {
  if ((storeId && userId) || paymentType || occupancyId) {
    const accessToken = await _auth.currentUser.getIdToken(true);
    const paymentTypes = Object.values(paymentTypeObj);
    const paramsString = new URLSearchParams({
      'filter.userId': userId || '',
      'filter.storeId': storeId || '',
      'filter.paymentType':
        paymentTypes.find((obj) => obj.type === paymentType)?.id || '',
    });

    if (!occupancyId) {
      paramsString.append('sortField', 'dateTimestamp');
      paramsString.append('sortOrder', 'desc');
    } else {
      paramsString.append('filter.occupancyId', occupancyId);
    }

    if (limit) {
      paramsString.append('filter.limit', limit);
    }

    const response = await fetch(
      `${API_URL}/orders/v1/orders?` + paramsString,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    );

    return response.json();
  } else {
    console.error('storeId and paymentType or occupancyId are required');
  }
};

export const placeOrderApi = async (requestObj) => {
  const response = await fetch(
    `${nextOrderConfig.cloudFunctionUrl}/placeOrder`,
    {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestObj),
    }
  );
  const data = await response.json();
  return data;
  // .then((response) => {
  //   return response.json();
  // })
  // .catch((err) => {
  //   console.log(err);
  // });
};

export const settleOrderApi = async (requestObj) => {
  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await fetch(
    `${nextOrderConfig.cloudFunctionUrl}/settleOrder`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestObj),
    }
  );
  const data = await response.json();
  return data;
};

export const addPrinterCommandApi = async (requestObj) => {
  const token = await _auth.currentUser.getIdToken(true);

  const data = await printBillCommand({
    token,
    configUrl: nextOrderConfig.cloudFunctionUrl,
    openCashDrawer: false,
    printReceipt: false,
    ...requestObj,
  });
  return data;
};
