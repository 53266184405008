import React from 'react';
import clsx from 'clsx';

import { PosIconPeople } from 'assets/icons/PosIconPeople';

const SVG_ASSET_URL = 'https://assets.nextorder.co/icons/';

const SVG_FILENAME_MAP_TYPE = {
  SquareTable: 'T-',
  RoundTable: 'TCS-',
  RectangularTable: 'TS-',
  RhombusTable: 'TRH-',
  LineDivider: 'LineDivider',
  PottedPlant: 'PottedPlant',
};

export const Shape = ({
  type,
  objectType,
  name,
  number,
  numSeats,
  status,
  orientationAngle,
  isOccupied,
}) => {
  const occupiedFill = isOccupied ? '#fd6c00' : '#fff';

  const containerRef = React.useRef(null);

  const svgURL = `
    ${SVG_ASSET_URL}${SVG_FILENAME_MAP_TYPE[type]}${
    numSeats ? (numSeats < 10 ? `0${numSeats}` : numSeats) : ''
  }.svg`;

  const mainCssClassName = 'dine-in-object';
  const numberCss = clsx(`${mainCssClassName}__number`, {
    'dine-in-object__number--no-status': !status,
    'dine-in-object__number--occupied': isOccupied,
  });

  return (
    <div className={mainCssClassName}>
      <div className={`${mainCssClassName}__box`}>
        {objectType === 'dineInObjects' && (
          <div className={`${mainCssClassName}__inner`}>
            <div
              className="svg-status-box"
              style={{
                backgroundColor: occupiedFill,
                borderColor: occupiedFill,
              }}
              ref={containerRef}
            >
              <div
                className={`${mainCssClassName}__inner-wrapper`}
                style={
                  orientationAngle
                    ? { transform: `rotate(-${orientationAngle}deg)` }
                    : {}
                }
              >
                <div
                  className={numberCss}
                  style={
                    containerRef.current?.offsetWidth && orientationAngle
                      ? {
                          maxWidth: `${
                            containerRef.current?.offsetWidth / 2
                          }px`,
                        }
                      : {}
                  }
                >
                  {name || number}
                </div>

                {isOccupied && (
                  <PosIconPeople
                    mainColor="#fff"
                    darkModeColor="#000"
                    className="dine-in-object__icon"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {/* 
        will be added later
        {status !== 'free' && (
            <div className="dine-in-object__progress-bar">
              <div
                className="dine-in-object__progress-line"
                style={{ width: `${progress}%` }}
              />
            </div>
          )}
        */}
      </div>
      <img src={svgURL} className={type} alt={name} />
    </div>
  );
};
