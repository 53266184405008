import { SupportButtonUI } from '../../UI/components';
import React from 'react';

const SupportStaffNavItem = ({ staffMember }) => {
  const initials =
    staffMember.firstName && staffMember.lastName
      ? staffMember.firstName.charAt(0) + staffMember.lastName.charAt(0)
      : '';
  const img = staffMember.imgSrc;
  const icon = initials ? (
    <span className="icon-box orange">
      <span className="initials">{initials}</span>
    </span>
  ) : (
    ''
  );

  const title = `${staffMember.firstName || ''} ${staffMember.lastName || ''}`;
  if (initials) {
    return (
      <SupportButtonUI
        icon={initials}
        title={title}
        iconBgColor="var(--warningColor)"
      />
    );
  } else {
    return (
      <SupportButtonUI icon={<img src={img} alt={title} />} title={title} />
    );
  }
};

export default SupportStaffNavItem;
