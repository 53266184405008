const checkIfMultipleOfHundred = num => {
	return num % 100 === 0;
}

const getNextLargestDenom = val => {
	let initialDenom = 50;
	while (initialDenom) {
		if (val < initialDenom) {
			return initialDenom;
		}
		initialDenom += 50;
	}
}

const getTheNextValue = amount => {
	if (amount % 10 === 0) {
		return getNextLargestDenom(amount);
	} else if (amount % 5 === 0) {
		return amount + 5;
	} else if (amount % 1 !== 0) {
		return Math.ceil(amount);
	} else {
		return Math.ceil(amount / 5) * 5;
	}
}

export const getCashOptions = (payableAmount, iterations) => {
	/*
		1st option - same amount
		2nd option -
			if pa % 1 != 0 - round of ceil/floor
			else to nearest divisible by 5
		3rd option -
			if 2nd option % 5 != 0 - nearest divisible by 5
			else if 2nd option % 10 != 0 - nearest divisible by 10
			else - + 10
	*/
	let payAmount = Number(payableAmount);
	const easyCashOptions = [];
	for (let i = 0; i < iterations; i++) {
		if (i !== 0) {
			payAmount = getTheNextValue(payAmount);
		}
		easyCashOptions.push(payAmount);
		if (checkIfMultipleOfHundred(payAmount)) {
			return easyCashOptions;
		}
	}
	return easyCashOptions;
};
