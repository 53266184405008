import React from 'react';
import { useSelector } from 'react-redux';

import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../util/currencyFormat';

const useGetAmountWithCurrency = (amount) => {
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  const [amountWithCurrency, setAmountWithCurrency] = React.useState('');

  React.useEffect(() => {
    if (amount) {
      setAmountWithCurrency(
        getAmountWithCountryCurrency({
          amount: Number(amount),
          locale: `en-${storeCountryIdentifier}`,
          currencyCode,
        })
      );
    } else {
      setAmountWithCurrency('');
    }
  }, [amount, currencyCode, storeCountryIdentifier]);

  return amountWithCurrency;
};

export default useGetAmountWithCurrency;
