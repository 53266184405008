import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AsYouType } from 'libphonenumber-js';

import GeneralModal from 'modals/GeneralModal/index-new';
import Button from 'components/Button';
import { PosIconPlus } from 'assets/icons/PosIconPlus';
import { PosIconLeftChevronSecond } from 'assets/icons/PosIconLeftChevronSecond';
import { PosIconPhoneInverse } from 'assets/icons/PosIconPhoneInverse';
import { isPhoneNumberValid } from 'util/phone-number-utils';
import { useSelector } from 'react-redux';
import { storeConfigSelector } from 'pages/Dashboard/selectors';

const errorMessage = 'Invalid Phone Number';

const keyList = [
  { id: 1, name: '1', val: '1' },
  { id: 2, name: '2', val: '2' },
  { id: 3, name: '3', val: '3' },
  { id: 4, name: '4', val: '4' },
  { id: 5, name: '5', val: '5' },
  { id: 6, name: '6', val: '6' },
  { id: 7, name: '7', val: '7' },
  { id: 8, name: '8', val: '8' },
  { id: 9, name: '9', val: '9' },
  {
    id: 10,
    name: <PosIconPlus mainColor="#515151" lineThickness={0.25} />,
    val: '+',
  },
  { id: 11, name: '0', val: '0' },
  {
    id: 13,
    name: <PosIconLeftChevronSecond mainColor="#515151" />,
    val: 'Clear',
  },
]

const CustomerPhoneModal = ({
  modalStatus,
  onModalClose,
  onNextClick,
  isFetching,
  countryIdentifier,
  position = 'bottom',
  ...props
}) => {
  const storeConfig = useSelector(storeConfigSelector);
  const dialingCode = storeConfig.dialingCode;
  const [customerPhone, setCustomerPhone] = useState('');
  const [activeKeyId, setActiveKeyId] = useState('');
  const [displayPhone, setDisplayPhone] = useState(customerPhone);
  const [wiggleEffect, setWiggleEffect] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);

  const wiggleTimeout = useRef(0);

  const mainRef = useRef();
  const timeout = useRef(null);

  const writePhone = (val) => {
    let arr = Array.from(customerPhone);
    let newVal = '';

    if (arr.length === 0 && val !== '+' && val !== '0' && val !== 'clear') {
      setCustomerPhone(`${dialingCode}${val}`);
      return;
    }

    if (val.toLowerCase() === 'clear') {
      if (customerPhone.startsWith(dialingCode) && arr.length === 3) {
        arr = [];
      } else {
        arr.splice(-1, 1);
      }
    } else if (val.toLowerCase() === '+' && !arr[0]) {
      arr.push(val);
    } else if (val.toLowerCase() !== '+') {
      arr.push(val);
    }

    newVal = arr.join('');
    setCustomerPhone(newVal);
  };

  const handleKeyDown = (e) => {
    if (e !== undefined && e.keyCode !== undefined) {
      let keyVal = '';
      const keyPlusList = [107, 187];
      const keyDelList = [8, 46];
      const keyNumList = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];

      if (keyDelList.includes(Number(e.keyCode || e.which))) {
        keyVal = 'clear';
      } else if (keyPlusList.includes(Number(e.keyCode || e.which))) {
        keyVal = '+';
      } else if (keyNumList.includes(Number(e.keyCode || e.which))) {
        keyVal = e.key;
      }

      if (keyVal) {
        if (keyList.length > 0) {
          keyList.forEach((item, i) => {
            if (item.val.toString() === keyVal.toString()) {
              setActiveKeyId(item.id);
            }
          });
        }

        writePhone(keyVal);
      }
    }
  };

  useEffect(() => {
    modalStatus && mainRef.current.focus();
    if (modalStatus && window.innerHeight < 900) {
      document.getElementById('customer-phone-modal').scrollTo({
        top: 2000,
        left: 0,
        type: 'smooth',
      });
    }
  }, [modalStatus]);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setActiveKeyId('');
    }, 100);
    let numberAsUserType = new AsYouType(countryIdentifier).input(
      customerPhone
    );

    const { error } = isPhoneNumberValid(numberAsUserType, countryIdentifier);

    if (error) {
      setInvalidPhoneNumber(true);
    } else {
      setInvalidPhoneNumber(false);
    }
    if (numberAsUserType.startsWith(`(${dialingCode})`)) {
      numberAsUserType = numberAsUserType.replace(`(${dialingCode})`, '');
    } else if (numberAsUserType.startsWith(dialingCode)) {
      numberAsUserType = numberAsUserType.replace(dialingCode, '');
    }
    setDisplayPhone(numberAsUserType);
  }, [countryIdentifier, customerPhone, dialingCode]);

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    if (wiggleEffect) {
      wiggleTimeout.current = setTimeout(() => {
        setWiggleEffect(false);
      }, 1000);
    }

    return () => clearTimeout(wiggleTimeout.current);
  }, [wiggleEffect, setWiggleEffect]);

  const handleNext = useCallback(() => {
    if (invalidPhoneNumber) {
      setWiggleEffect(true);
    } else {
      onNextClick(customerPhone);
    }
  }, [customerPhone, invalidPhoneNumber, onNextClick]);

  const getDisplayNumberFontSize = (phoneNumber) => {
    let size = 3.4;
    if (phoneNumber.length > 11) {
      size = size - (phoneNumber.length - 11) * 0.2;
    }
    return {
      fontSize: `${size}rem`,
    };
  };

  const numberStyle = useMemo(() => {
    return {
      ...getDisplayNumberFontSize(displayPhone),
      ...(invalidPhoneNumber && wiggleEffect ? { color: '#ff0000' } : {}),
    };
  }, [displayPhone, invalidPhoneNumber, wiggleEffect]);

  const phoneLineStyle = useMemo(
    () =>
      invalidPhoneNumber && wiggleEffect ? { margin: '0 auto 66.5px' } : {},
    [invalidPhoneNumber, wiggleEffect]
  );
  return (
    <GeneralModal
      id="customer-phone-modal"
      modalStatus={modalStatus}
      toggleModal={() => {}}
      position={position}
      effect="move"
      overlay="blur"
      boxWidth="435px"
      boxHeight="727px"
      className="customer-phone-modal"
      backBtn="outside"
      backBtnFnc={onModalClose}
      errorEffect={wiggleEffect}
      {...props}
    >
      <div
        ref={mainRef}
        onKeyDown={handleKeyDown}
        tabIndex="0"
        className="customer-phone-modal-box"
      >
        <div className="customer-phone-header">
          <h2 className="customer-phone-title">Customer Phone Number</h2>
          <div className="customer-phone-line" style={phoneLineStyle}>
            <div className="customer-phone-inner">
              {invalidPhoneNumber && wiggleEffect ? (
                <PosIconPhoneInverse
                  mainColor="#ff0000"
                  darkModeColor="#ff0000"
                />
              ) : (
                <PosIconPhoneInverse
                  mainColor={'#5eb602'}
                  darkModeColor={'#5eb602'}
                />
              )}
              <span className="number" style={numberStyle}>
                {displayPhone}
              </span>
            </div>
          </div>
          {invalidPhoneNumber && wiggleEffect && (
            <p style={{ color: '#ff0000' }}>{errorMessage}</p>
          )}
        </div>
      <div className="customer-phone-keypad-box">
        {keyList.map((key, i) => (
          <div key={i} className="customer-phone-key-wrap">
            <Button
              disabled={
                ((i) === 12 && !customerPhone.length) ||
                  ((i) === 9 && customerPhone.length)
              }
              title={key.name}
              className={`customer-phone-key hover-highlight key-${key.val}`}
              onClick={(e) => writePhone(key.val)}
            >
              {key.name}
            </Button>
          </div>
        ))}
        </div>
        <div className="customer-phone-btn-box">
          <Button
            disabled={isFetching}
            className="btn"
            title="Next"
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

CustomerPhoneModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  onModalClose: PropTypes.func,
  openNextModal: PropTypes.func,
  openSearchModal: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default CustomerPhoneModal;
