const actionTypes = {
  addDrawer: 'addDrawer',
  deleteDrawer: 'deleteDrawer',
  getDrawers: 'getDrawers',
  getPrinters: 'getPrinters',
  setActiveDrawer: 'setActiveDrawer',
  setTerminalSettingsLoaderStatus: 'setTerminalSettingsLoaderStatus',
};

export default actionTypes;
