import clsx from 'clsx';
import React, { memo, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PosIconReload } from '../../assets/icons/PosIconReload';
import PizzaImg from '../../assets/img/food/size_230/pizza/pizza.png';
import { useLocation } from 'react-router';

const navLinkIconsMap = {
  IconReload: <PosIconReload mainColor="#000" darkModeColor="#fff" />,
};

export const Category = memo(
  ({ _id, urlS3, name, icon, onClick, active, isKiosk }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    // const location = useLocation();
    const cls = clsx({
      'nav-link': true,
      active,
      small: isMobile && name.length >= 14,
      smaller: isMobile && name.length >= 16,
      smallest: isMobile && name.length >= 20,
    });
    const useCustomSpecialTitle = useSelector(
      (state) => state.dashboardReducer?.orderSetup?.useCustomSpecialTitle
    );
    const customSpecialTitle = useSelector(
      (state) => state.dashboardReducer?.orderSetup?.customSpecialTitle
    );
    const { enableAllOrderTypeKiosk } = useSelector(
      (state) => state.dashboardReducer?.orderSetup
    );

    const categoryName =
      useCustomSpecialTitle && _id === 'specials' ? customSpecialTitle : name;

    const handleClick = useCallback(() => {
      onClick(_id);

      let mainUrl = '/' + window.location.pathname.split('/')[1] + '/order';
      if (pathname !== '/kiosk/order' && isKiosk && enableAllOrderTypeKiosk) {
        mainUrl =
          '/' +
          window.location.pathname.split('/')[1] +
          '/' +
          window.location.pathname.split('/')[2] +
          '/order';
      }

      if (window.location.pathname !== mainUrl) {
        history.push(mainUrl);
      }
      // if (!(/^\/(walkin\/order|pickup\/order|delivery\/order|dinein\/order)/.test(window.location.pathname))) {
      // history.goBack()
      // if (window.location.pathname.split('/').length === 6) {
      // history.goBack()
      // }
      // }
    }, [_id, onClick]);

    return (
      <button
        key={_id}
        title={name.toLowerCase()}
        className={cls}
        onClick={handleClick}
      >
        <span className="img-box">
          {navLinkIconsMap[icon] || (
            <img src={urlS3 ? urlS3 : PizzaImg} alt={name.toLowerCase()} />
          )}
        </span>
        <span style={{ color: 'black' }}>{categoryName}</span>
      </button>
    );
  }
);
