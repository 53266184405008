import React, {
  memo,
  useEffect,
  useReducer,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AsYouType } from 'libphonenumber-js';

import GeneralModal from 'modals/GeneralModal/index-new';
import Button from 'components/Button';
import { PosIconPlus } from 'assets/icons/PosIconPlus';
import { PosIconLeftChevronSecond } from 'assets/icons/PosIconLeftChevronSecond';
import { PosIconPhoneInverse } from 'assets/icons/PosIconPhoneInverse';
import { isPhoneNumberValid } from 'util/phone-number-utils';
import { useSelector } from 'react-redux';
import { storeConfigSelector } from 'pages/Dashboard/selectors';

const errorMessage = 'Invalid Phone Number';

const keyList = [
  ...Array(9)
    .fill(0)
    .map((_, i) => ({ id: i + 1, name: '' + (i + 1), val: '' + (i + 1) })),
  {
    id: 10,
    name: <PosIconPlus mainColor="#515151" lineThickness={0.25} />,
    val: '+',
  },
  { id: 11, name: '0', val: '0' },
  {
    id: 13,
    name: <PosIconLeftChevronSecond mainColor="#515151" />,
    val: 'Clear',
  },
];

const Key = memo(({ name, val, disabled, type }) => (
  <div key={val} className="customer-phone-key-wrap">
    <Button
      disabled={disabled}
      title={val}
      className={`customer-phone-key hover-highlight key-${val}`}
      onClick={() => type(val)}
    >
      {name}
    </Button>
  </div>
));

const KeyPad = memo(({ phoneEmpty, type }) => (
  <div className="customer-phone-keypad-box">
    {keyList.map(({ id, val, name }) => (
      <Key
        key={val}
        name={name}
        val={val}
        type={type}
        disabled={phoneEmpty ? id === 13 : id === 10}
      />
    ))}
  </div>
));

const Header = memo(({ invalidPhoneNumber, displayPhone, title }) => {
  const displayNumberFontSize = useMemo(() => {
    if (!displayPhone) return { fontSize: '3.4rem' };
    let size = 3.4;
    if (displayPhone.length > 11) {
      size = size - (displayPhone.length - 11) * 0.2;
    }
    return {
      fontSize: `${size}rem`,
    };
  }, [displayPhone]);

  const numberStyle = useMemo(() => {
    return {
      ...displayNumberFontSize,
      ...(invalidPhoneNumber ? { color: '#ff0000' } : {}),
    };
  }, [displayNumberFontSize, invalidPhoneNumber]);

  const phoneColor = useMemo(
    () => (invalidPhoneNumber ? '#ff0000' : '#5eb602'),
    [invalidPhoneNumber]
  );

  return (
    <div className="customer-phone-header">
      <h2 className="customer-phone-title">
        {title || 'Customer Phone Number'}
      </h2>
      <div className="custome-phone-line" style={{ margin: '0 auto 66.5px' }}>
        <div className="customer-phone-inner">
          <PosIconPhoneInverse
            mainColor={phoneColor}
            darkModeColor={phoneColor}
          />
          <span className="number" style={numberStyle}>
            {displayPhone}
          </span>
        </div>
      </div>
      {invalidPhoneNumber && <p style={{ color: '#ff0000' }}>{errorMessage}</p>}
    </div>
  );
});

const CustomerPhoneModal = ({
  modalStatus,
  onModalClose,
  onNextClick,
  isFetching,
  countryIdentifier,
  position = 'bottom',
  title,
  isKiosk,
  ...props
}) => {
  const storeConfig = useSelector(storeConfigSelector);
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const dialingCode = storeConfig.dialingCode;
  const [customerPhone, type] = useReducer(
    (s, a) => (a === 'Clear' ? s.slice(0, -1) : (s += a)),
    currentOrder?.mobileNumber === 'xxx-xxx-xxx'
      ? ''
      : currentOrder?.mobileNumber || ''
  );

  const [displayPhone, setDisplayPhone] = useState(customerPhone);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const phoneEmpty = useMemo(
    () => customerPhone.length === 0,
    [customerPhone.length]
  );
  const mainRef = useRef();

  useEffect(() => {
    modalStatus && mainRef.current.focus();
    if (modalStatus && window.innerHeight < 900) {
      document.getElementById('customer-phone-modal').scrollTo({
        top: 2000,
        left: 0,
        type: 'smooth',
      });
    }
  }, [modalStatus]);
  useEffect(() => {
    let numberAsUserType = new AsYouType(countryIdentifier).input(
      customerPhone
    );

    if (numberAsUserType.startsWith(`(${dialingCode})`)) {
      numberAsUserType = numberAsUserType.replace(`(${dialingCode})`, '');
    } else if (numberAsUserType.startsWith(dialingCode)) {
      numberAsUserType = numberAsUserType.replace(dialingCode, '');
    }

    setDisplayPhone(numberAsUserType);
  }, [countryIdentifier, customerPhone, dialingCode, invalidPhoneNumber]);
  useEffect(() => {
    let timeout = null;

    if (invalidPhoneNumber) {
      timeout = setTimeout(() => {
        setInvalidPhoneNumber(false);
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [setInvalidPhoneNumber, invalidPhoneNumber]);

  const handleNext = useCallback(() => {
    const { error } = isPhoneNumberValid(
      customerPhone.replace(/[^\d]/g, ''),
      countryIdentifier
    );
    if (error) {
      setInvalidPhoneNumber(true);
    } else {
      let phoneToSave = customerPhone;

      if (phoneToSave.length === 8) {
        if (dialingCode) {
          phoneToSave = `${dialingCode}${phoneToSave}`;
        } else {
          phoneToSave = `03${phoneToSave}`;
        }
      }

      onNextClick(phoneToSave);
    }
  }, [customerPhone, dialingCode, onNextClick, countryIdentifier]);
  const handleKeyDown = useCallback((e) => {
    if (e !== undefined && e.keyCode !== undefined) {
      let keyVal = '';
      const keyPlusList = [107, 187];
      const keyDelList = [8, 46];
      const keyNumList = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];

      if (keyDelList.includes(Number(e.keyCode || e.which))) {
        keyVal = 'Clear';
      } else if (keyPlusList.includes(Number(e.keyCode || e.which))) {
        keyVal = '+';
      } else if (keyNumList.includes(Number(e.keyCode || e.which))) {
        keyVal = e.key;
      }

      if (keyVal) type(keyVal);
    }
  }, []);

  return (
    <GeneralModal
      id="customer-phone-modal"
      modalStatus={modalStatus}
      position={position}
      effect="move"
      overlay="blur"
      boxWidth="435px"
      boxHeight="727px"
      className="customer-phone-modal"
      backBtn="outside"
      backBtnFnc={onModalClose}
      errorEffect={invalidPhoneNumber}
    >
      <div
        ref={mainRef}
        onKeyDown={handleKeyDown}
        tabIndex="0"
        className="customer-phone-modal-box"
      >
        <Header
          displayPhone={displayPhone}
          invalidPhoneNumber={invalidPhoneNumber}
          title={title || ''}
        />
        <KeyPad phoneEmpty={phoneEmpty} type={type} />
        <div className="customer-phone-btn-box">
          <Button
            disabled={isFetching}
            className="btn"
            title="Next"
            onClick={handleNext}
            style={
              isKiosk
                ? {
                    backgroundColor: 'rgb(0, 0, 0)',
                    border: '0.2rem solid rgb(0, 0, 0)',
                  }
                : {}
            }
          >
            Next
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

CustomerPhoneModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  onModalClose: PropTypes.func,
  openNextModal: PropTypes.func,
  openSearchModal: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default CustomerPhoneModal;
