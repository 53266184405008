import { activeSpecialsSelector } from 'pages/Dashboard/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCategoryList } from 'util/order-flow-utils';

import useOrderType from './useOrderType';

function useCategories() {
  const [_, orderTypeId] = useOrderType();
  const categories = useSelector((s) => s.dashboardReducer.categories);
  const menuItems = useSelector((s) => s.dashboardReducer.menuItems);
  const activeSpecials = useSelector(activeSpecialsSelector);
  const categoriesList = useMemo(
    () =>
      getCategoryList({
        categories,
        menuItems,
        activeSpecials,
        orderTypeId,
      }),
    [categories, menuItems, activeSpecials, orderTypeId]
  );

  return categoriesList;
}

export default useCategories;
