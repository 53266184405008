import { useEffect, useState } from 'react';

export const useDebounce = (value, delay) => {
  const [debounceValue, setDebounceValue] = useState('');

  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [value, delay]);

  return [debounceValue];
};
