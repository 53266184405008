// import './bem-select-items-share-modal.scss';
import { ModalPortal } from 'components';
import GeneralModal from 'modals/GeneralModal/index-new';
import { itemHash } from './util';
import { useReducer, useCallback } from 'react';
import { BasePartUI, ButtonUI, SelectShareItemUI } from 'UI/components';

const { values } = Object;

const ShareItemsModal = ({
  shareItems,
  itemIdsAlreadyInChecks,
  toggleModal,
  currentOrder,
  guests,
  setState,
  ...props
}) => {
  const [shared, shareItem] = useReducer(
    (st, a) => ({ ...st, ...a }),
    props.shared
  );

  const setShare = useCallback(
    (i, index, direction = 'inc', partedIndex) => {
      let hash = '';

      if (partedIndex) {
        hash = partedIndex;
      } else {
        hash = itemHash(i, index);
      }

      const newValue =
        direction === 'inc' ? shared[hash] + 1 : Math.max(shared[hash] - 1, 1);

      shareItem({ [hash]: newValue });
    },
    [shareItem, shared, guests, setState]
  );

  const increment = useCallback(
    (i, index, partedIndex) => () => {
      setShare(i, index, 'inc', partedIndex);
    },
    [setShare]
  );
  const decrement = useCallback(
    (i, index, partedIndex) => () => setShare(i, index, 'desc', partedIndex),
    [setShare]
  );

  return (
    <ModalPortal>
      <GeneralModal
        modalStatus
        toggleModal={toggleModal}
        position={'center'}
        className="bem-select-items-share-modal"
        overlay={'default'}
        boxWidth="644px"
        boxHeight="642px"
        closeBtn="outside"
        isResponsiveHeight
      >
        <BasePartUI additionalClass="bem-split-per-item__select-items">
          <BasePartUI.Header>
            <h3 className="bem-select-items-share-modal__title">
              Select Items to Share
            </h3>
          </BasePartUI.Header>
          <BasePartUI.Body>
            <div className="bem-split-per-item__select-items-grid">
              {currentOrder.menuItems
                .filter((i) => i.quantity >= 1 && !i.isVoided)
                .flatMap(
                  (i) =>
                    !i.isHalf &&
                    !i.isQuarter &&
                    Array(i.quantity)
                      .fill(0)
                      .map((_, index) =>
                        !itemIdsAlreadyInChecks.some(
                          (k) => {
                            return k
                              .slice(0, -3)
                              .concat(k.substring(k.length - 2, k.length))
                              .startsWith(itemHash(i, index));
                          }
                          // k.startsWith(itemHash(i, index))
                        ) ? (
                          <SelectShareItemUI
                            key={itemHash(i, index)}
                            value={i._id}
                            title={i.name}
                            price={`$${(
                              Number(i.payablePrice.toFixed(2)) / i.quantity
                            ).toFixed(2)}`}
                            onChange={() =>
                              !shared[itemHash(i, index)]
                                ? shareItem({ [itemHash(i, index)]: guests })
                                : shareItem({ [itemHash(i, index)]: null })
                            }
                            checked={!!shared[itemHash(i, index)]}
                            quantity={shared[itemHash(i, index)]}
                            onIncrement={increment(i, index)}
                            onDecrement={decrement(i, index)}
                          />
                        ) : null
                      )
                )}
              {values(
                currentOrder.menuItems
                  .filter((i) => i.isQuarter && !i.isVoided)
                  .reduce(
                    (acc, k) => ({
                      ...acc,
                      [k.fourQuarterIndex]: [
                        ...(acc[k.fourQuarterIndex] || []),
                        k,
                      ],
                    }),
                    {}
                  )
              )
                .filter((item, index) => {
                  const quantity = item.reduce(
                    (quantity, item) => (quantity = quantity + item.quantity),
                    0
                  );
                  const partedIndex =
                    item.map((i) => i._id).join('+') +
                    item[0].fourQuarterIndex +
                    1;

                  return !itemIdsAlreadyInChecks.some((k) => {
                    return k.slice(0, -1).startsWith(partedIndex);
                  });
                })
                .map((item, index) => {
                  const partedIndex =
                    item.map((i) => i._id).join('+') +
                    item[0].fourQuarterIndex +
                    1;
                  const quantity = item.reduce(
                    (quantity, item) => (quantity = quantity + item.quantity),
                    0
                  );
                  const name = `${item.map((i) => i.name).join('/')} ${
                    quantity > 1 ? `x ${quantity}` : ''
                  }`;
                  const payablePrice = item.reduce(
                    (acc, i) => (acc += i.payablePrice),
                    0
                  );

                  return (
                    <SelectShareItemUI
                      key={partedIndex}
                      value={partedIndex}
                      title={name}
                      price={`$${payablePrice.toFixed(2)}`}
                      onChange={() => {
                        !shared[partedIndex]
                          ? shareItem({ [partedIndex]: guests })
                          : shareItem({ [partedIndex]: null });
                      }}
                      checked={!!shared[partedIndex]}
                      quantity={shared[partedIndex]}
                      onIncrement={increment(0, index, partedIndex)}
                      onDecrement={decrement(0, index, partedIndex)}
                    />
                  );
                })}
              {values(
                currentOrder.menuItems

                  .filter((i) => {
                    return i.isHalf && !i.isVoided;
                  })
                  .reduce(
                    (acc, k) => ({
                      ...acc,
                      [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                    }),
                    {}
                  )
              )
                .filter((item, index) => {
                  const quantity = item.reduce(
                    (quantity, item) => (quantity = quantity + item.quantity),
                    0
                  );
                  const partedIndex =
                    item.map((i) => i._id).join('+') + item[0].halfIndex + 1;

                  return !itemIdsAlreadyInChecks.some((k) => {
                    return k.slice(0, -1).startsWith(partedIndex);
                  });
                })
                .map((item, index) => {
                  const quantity = item.reduce(
                    (quantity, item) => (quantity = quantity + item.quantity),
                    0
                  );
                  const partedIndex =
                    item.map((i) => i._id).join('+') + item[0].halfIndex + 1;
                  const name = `${item.map((i) => i.name).join('/')} ${
                    quantity > 1 ? `x ${quantity}` : ''
                  }`;

                  const payablePrice = item.reduce(
                    (acc, i) => (acc += i.payablePrice),
                    0
                  );
                  return (
                    <SelectShareItemUI
                      key={partedIndex}
                      value={partedIndex}
                      title={name}
                      price={`$${payablePrice.toFixed(2)}`}
                      onChange={() =>
                        !shared[partedIndex]
                          ? shareItem({ [partedIndex]: guests })
                          : shareItem({ [partedIndex]: null })
                      }
                      checked={!!shared[partedIndex]}
                      quantity={shared[partedIndex]}
                      onIncrement={increment(0, index, partedIndex)}
                      onDecrement={decrement(0, index, partedIndex)}
                    />
                  );
                })}
            </div>
          </BasePartUI.Body>
          <BasePartUI.Footer>
            <ButtonUI
              style={{ height: 68 }}
              sizeType="xl"
              fullWidth
              rounded="none"
              onClick={() => {
                shareItems(shared);
              }}
            >
              Share Items
            </ButtonUI>
          </BasePartUI.Footer>
        </BasePartUI>
      </GeneralModal>
    </ModalPortal>
  );
};

export default ShareItemsModal;
