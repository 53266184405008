import React, { useContext } from 'react';
import { ScrollableBoxUI } from 'UI/components';
import { DeliveryBatchesSidebarFooter } from './DeliveryBatchesSidebarFooter';
import BatchedOrdersList from './content/BatchedOrdersList';
import { useSelector } from 'react-redux';
import { DriverBatchesContext } from 'pages/Orders/components/Providers/DriverBatchesProvider';

export default function DeliveryBatchesCreated() {
  const { batchOrders, selectedBatchOrderId, loading } = useSelector(
    (state) => state.batchOrdersReducer
  );
  const { setShowAssignDriverModal } = useContext(DriverBatchesContext);

  const isDisabled = selectedBatchOrderId === null || loading;
  return (
    <>
      <div
        className="nav-bar-details"
        style={{
          margin: 0,
          padding: 0,
          height: 'calc(100% - 115px)',
        }}
      >
        <ScrollableBoxUI>
          <div className="details-tab-wrap unassigned">
            <BatchedOrdersList
              batchOrders={batchOrders}
              selectedBatchOrderId={selectedBatchOrderId}
            />
          </div>
        </ScrollableBoxUI>
      </div>

      <DeliveryBatchesSidebarFooter
        loading={loading}
        disabled={isDisabled}
        onClick={() => setShowAssignDriverModal(true)}
        buttonText="Assign Driver"
        totalSelected={selectedBatchOrderId ? 1 : 0}
      />
    </>
  );
}
