import { GroupPeopleIcon } from 'nexticons/outline';
import { PosIconPlus } from 'assets/icons/PosIconPlus';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { ButtonGroupUI, ButtonUI, HeaderUI } from '../../UI/components';
import { LogoButton } from 'components/LogoButton';

export const Header = ({
  pending,
  editMode,
  hasNoFloors,
  handleModeToggle,
  handleAddFloorClick,
  handleSaveLayout,
  totalNumPeople = 0,
}) => {
  const modeButtonLabel = editMode ? 'Save Layout' : 'Edit Layout';
  const modeButtonColor = editMode ? 'success' : 'primary';

  return (
    <HeaderUI modifierClassName='dine-in'>
      <div className='bem-logo-container'>
        <LogoButton onClick={editMode ? handleModeToggle : undefined} />

        <div className='bem-logo-container__guests'>
          <GroupPeopleIcon strokeWidth={1} width={22} />
          <span>{totalNumPeople}</span>
        </div>
      </div>

      <ButtonGroupUI gap="xl">
        {editMode && (
          <>
            <ButtonUI
              styleType="danger"
              onClick={handleModeToggle}
              disabled={pending}
            >
              Cancel Changes
            </ButtonUI>
            {!hasNoFloors && (
              <ButtonUI
                icon={
                  <PosIconPlus
                    mainColor="#ffffff"
                    size="15px"
                    className="add-floor-icon"
                  />
                }
                iconPosition="left"
                onClick={handleAddFloorClick}
                disabled={pending || hasNoFloors}
              >
                Add Floor
              </ButtonUI>
            )}
          </>
        )}
        <ButtonUI
          icon={
            pending &&
            editMode && (
              <PosIconLoading
                mainColor="white"
                style={{ width: 18, height: 18 }}
              />
            )
          }
          iconPosition="left"
          styleType={modeButtonColor}
          onClick={editMode ? handleSaveLayout : handleModeToggle}
          disabled={pending}
        >
          {modeButtonLabel}
        </ButtonUI>
      </ButtonGroupUI>
    </HeaderUI>
  );
};
