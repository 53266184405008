const modifiersClassNames = {
  styleType: [
    'primary',
    'secondary',
    'success',
    'warning',
    'danger',
    'primary-light',
    'secondary-light',
    'success-light',
    'warning-light',
    'danger-light',
    'primary-outline',
    'secondary-outline',
    'success-outline',
    'warning-outline',
    'danger-outline',
  ],
  textColorStyle: [
    'primary',
    'secondary',
    'success',
    'warning',
    'danger',
    'default',
  ],
  sizeType: {
    xxs: 'size-xxs',
    xs: 'size-xs',
    s: 'size-s',
    m: 'size-m',
    l: 'size-l',
    xl: 'size-xl',
    xxl: 'size-xxl',
    xxxl: 'size-xxxl',
  },
  buttonSize: {
    // size modifiers for circle button 38x38 , 42x42, 46x46
    s: 'circle-size-s',
    m: 'circle-size-m',
    l: 'circle-size-l',
  },
  buttonInnerSpacing: {
    xxs: 'inner-spacing-xxs',
    xs: 'inner-spacing-xs',
    s: 'inner-spacing-s',
    m: 'inner-spacing-m',
    l: 'inner-spacing-l',
    xl: 'inner-spacing-xl',
    xxl: 'inner-spacing-xxl',
  },
  gap: {
    xs: 'gap-xs',
    s: 'gap-s',
    m: 'gap-m',
    l: 'gap-l',
    xl: 'gap-xl',
  },
  fontWeight: {
    light: 'fw-light',
    regular: 'fw-regular',
    medium: 'fw-medium',
    bold: 'fw-bold',
  },
  fontSize: {
    xs: 'fs-xs',
    s: 'fs-s',
    m: 'fs-m',
    l: 'fs-l',
    xl: 'fs-xl',
  },
  rounded: {
    none: 'r-none',
    xs: 'r-xs',
    s: 'r-s',
    m: 'r-m',
    l: 'r-l',
    xl: 'r-xl',
    full: 'r-full',
  },
  iconPosition: {
    //flex-direction
    left: 'ip-left',
    right: 'ip-right',
  },
  justifyContent: {
    left: 'jc-left',
    center: 'jc-center',
    between: 'jc-between',
    right: 'jc-right',
  },
  alignItems: {
    start: 'ai-start',
    center: 'ai-center',
    end: 'ai-end',
  },
  borderSide: {
    top: 'b-top',
    right: 'b-right',
    bottom: 'b-bottom',
    left: 'b-left',
  },
  fullWidth: {
    true: 'full-width',
  },
  disabled: {
    true: 'disabled',
  },
  isSecondary: {
    true: 'secondary',
  },
  invisibleScrollbar: {
    true: 'invisible-scrollbar',
  },
  uppercase: {
    true: 'uppercase',
  },
  shadow: {
    true: 'scroll-shadow',
  },
  loading: {
    true: 'loading',
  },
};

export function getUIClassesFromProps(base, props) {
  if (!base) return false;

  let arr = [base];
  const customModifiersArr =
    props.modifierClassName && typeof props.modifierClassName === 'string'
      ? props.modifierClassName.split(' ')
      : [];

  Object.entries(props).forEach(([key, value]) => {
    if (
      value &&
      (typeof value == 'boolean' ||
        typeof value === 'string' ||
        typeof value === 'number') &&
      modifiersClassNames[key]
    ) {
      if (key === 'styleType') {
        arr.push(`st-${value}`);
      } else if (key === 'textColorStyle') {
        arr.push(`tc-${value}`);
      } else {
        arr.push(modifiersClassNames[key][value]);
      }
    }
  });

  if (customModifiersArr.length) {
    customModifiersArr.forEach((item) => arr.push(item));
  }

  return arr.join(` ${base}--`);
}

export function getUIModifierKeys(modifier) {
  if (!modifier || !modifiersClassNames[modifier]) return [];

  return modifier === 'styleType' || modifier === 'textColorStyle'
    ? modifiersClassNames[modifier]
    : Object.keys(modifiersClassNames[modifier]);
}

export function getUIStorybookArgTypes(modifier) {
  if (!modifier) return null;

  return {
    table: {
      type: {
        summary: getUIModifierKeys(modifier),
      },
    },
    type: {
      name: 'enum',
      value: getUIModifierKeys(modifier),
    },
  };
}
