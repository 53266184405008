import React from 'react';

const useStoreNewFloorObjects = () => {
  const [newFloorObjects, setNewFloorObjects] = React.useState([]);

  const addNewFloorObjects = React.useCallback((newObject) => {
    setNewFloorObjects((currentNewFloorObjects) => [
      ...currentNewFloorObjects,
      newObject,
    ]);
  }, []);

  const overWriteNewFloorObjects = (newObjects) => {
    setNewFloorObjects(newObjects);
  };

  const updateNewFloorObjects = React.useCallback(
    (objectToBeUpdated) => {
      const index = newFloorObjects.findIndex(
        (newObject) => newObject.id === objectToBeUpdated.id
      );
      setNewFloorObjects((currentNewFloorObjects) => [
        ...currentNewFloorObjects.slice(0, index),
        objectToBeUpdated,
        ...currentNewFloorObjects.slice(index + 1),
      ]);
    },
    [newFloorObjects]
  );

  const deleteFromNewFloorObjects = React.useCallback(
    (objectToBeDeleted) => {
      const index = newFloorObjects.findIndex(
        (newObject) => newObject.id === objectToBeDeleted.id
      );
      setNewFloorObjects((currentNewFloorObjects) => [
        ...currentNewFloorObjects.slice(0, index),
        ...currentNewFloorObjects.slice(index + 1),
      ]);
    },
    [newFloorObjects]
  );

  const resetNewFloorObjects = React.useCallback(() => {
    setNewFloorObjects([]);
  }, []);

  return [
    newFloorObjects,
    addNewFloorObjects,
    overWriteNewFloorObjects,
    updateNewFloorObjects,
    deleteFromNewFloorObjects,
    resetNewFloorObjects,
  ];
};

export default useStoreNewFloorObjects;
