import React from 'react';
// import PayMaster from '../../../assets/img/pay_master.svg';
import { SingleOrderList } from '../../OrderBar/SingleOrderList';
// import { TotalPriceBox } from '../../orders/content/TotalPriceBox';
// import { OrderTimer } from '../../orders/content/OrderTimer';
import classNames from 'classnames';
import { PosIconStore } from '../../../assets/icons/PosIconStore';
// import { PosIconWriteMessage } from '../../../assets/icons/PosIconWriteMessage';
// import { PosIconChatBubbles } from '../../../assets/icons/PosIconChatBubbles';
import { PosIconSingleChat } from '../../../assets/icons/PosIconSingleChat';
import Button from '../../Button';
import { getRemainingTime } from '../../../util/dateHelper';
import { useDispatch, useSelector } from 'react-redux';

import { storeConfigSelector } from '../../../pages/Dashboard/selectors';

export const SettleDeliveryView = ({ currentGuest, togglePaymentModal }) => {
  // const shadowCls = state.scrolledStatus[7].position ? "shadow" : "";
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);
  const shadowCls = classNames({
    // shadow: state.scrolledStatus[7].position
  });

  const handleClick = (e, status) => {
    dispatch({
      type: 'togglePaymentViewModal',
      payload: true,
    });
    togglePaymentModal(e, status);
  };

  return (
    <>
      <div className="guest-location-row">
        <div className="location-box">
          <span className="icon-box">
            <PosIconStore />
          </span>
          <span className="desc">{currentGuest.address}</span>
        </div>
      </div>

      <div className={'order-status-row ' + shadowCls}>
        <div className="order-status-box dropdown">
          <span className="icon-box">
            <span className="dine_icon-clock" />
          </span>
          {currentGuest.deliveryDateTimestamp ? (
            <span className={'desc'}>{`Expected ${getRemainingTime(
              currentGuest.deliveryDate,
              currentGuest.deliveryDateTimestamp
            )}`}</span>
          ) : (
            //selectedOrder.textStatus.secondText
            ''
          )}
          {/*    <span className="desc">
            <span className="green">Expected in 26 mins</span>&nbsp;- 7:34pm
          </span>*/}
          {/* <span className="desc">
            {currentGuest.textStatus.secondClass ? (
              <span className={currentGuest.textStatus.secondClass}>
                {currentGuest.textStatus.secondText}
              </span>
            ) : (
              currentGuest.textStatus.secondText
            )}
            &nbsp;- 7:34pm
          </span> */}
        </div>
        {!currentGuest.pickupId ? (
          <div className="order-status-box">
            <span className="icon-box">
              <PosIconSingleChat mainColor="#494b4c" />
            </span>
            <span className="desc">
              <span className="grey">Notify it’s ready</span>
            </span>
          </div>
        ) : (
          ''
        )}
      </div>

      <SingleOrderList
        showPrice={true}
        currentOrder={currentGuest}
        basePath={''}
        fullScreen={true}
      />

      <div className="view-order-main-footer">
        <div className="current-order-status-box orange">
          {currentGuest.payableAmount ? (
            <span className="desc">
              {storeConfig.currency}
              {currentGuest.payableAmount} Outstanding
            </span>
          ) : (
            ''
          )}
          <Button
            className="order-status-btn"
            title="Pay Now"
            onClick={(e) => handleClick(e, true)}
          >
            Pay Now
          </Button>
        </div>
      </div>
    </>
  );
};
