import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getItemPrice } from '../../orderEngine/utilities';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { getInstructionString } from '../../util/getInstructionString';

export const PrintBillSingleItem = ({ menuItem }) => {
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const instruction = getInstructionString(menuItem);

  const selectedSizes = menuItem.selectedSizes.filter((f) => f.quantity);

  const titleWithQuantity = useMemo(() => {
    const title = `${menuItem.name} - ${
      selectedSizes.length ? selectedSizes.map((m) => m.name).join(',') : ''
    }`;
    const quantity = selectedSizes.filter((size) => size.quantity > 1).length
      ? ' x' + selectedSizes.find((size) => size.quantity).quantity
      : '';

    return `${title}${quantity}`;
  }, [selectedSizes, menuItem.name]);

  return (
    <li>
      <div className="print-single-item">
        <div className="cell">
          <h3 className="name">{titleWithQuantity}</h3>
          {instruction.length ? (
            <div className="single-actions">
              <span title={instruction}>{instruction}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="cell">
          <span className="price">
            {getAmountWithCountryCurrency({
              amount: Number(getItemPrice(menuItem).payablePrice),
              locale: `en-${storeCountryIdentifier}`,
              currencyCode,
            })}
          </span>
        </div>
      </div>
    </li>
  );
};
