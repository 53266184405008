import { useMemo } from 'react';
import GeneralModal from '../GeneralModal/index-new';
import { ShiftsEnum } from '../../pages/Shifts/constants';

export default function ClockInClockOutModal({
  isClockedIn,
  isOnBreak,
  handleModalClose,
  shouldGoToShiftsPage,
}) {
  const handleActionButton = () => {
    const shiftsUrl = isClockedIn
      ? `/shifts/clocked-in${
          isOnBreak ? '?status=' + ShiftsEnum.ON_A_BREAK : ''
        }`
      : '/shifts/clock-in';
    shouldGoToShiftsPage(shiftsUrl);
  };

  const handleDismiss = () => {
    handleModalClose(false);
  };

  const modalMessage = `Please ${
    isClockedIn ? 'clock-out' : 'clock-in'
  } for your shift starting time to be recorded`;

  const modalTitle = useMemo(() => {
    if (isOnBreak) {
      return 'You forgot to end your break';
    } else {
      return isClockedIn
        ? 'Yesterday, you forgot to clockout'
        : 'You have not clocked in yet';
    }
  }, [isOnBreak, isClockedIn]);

  return (
    <GeneralModal
      modalStatus={true}
      position="center"
      effect="move"
      overlay=""
      boxWidth="487px"
      className="general-modal general-modal--theme-action grey-bg radius-big"
    >
      <div className="general-modal__inner general-modal__inner--space-between-big ">
        <div className="general-modal__header general-modal__header--space-between-big">
          <div className="general-modal__title-box">
            <h3 className="general-modal__title">{modalTitle}</h3>
          </div>
          <div className="general-modal__desc">
            <p>{modalMessage}</p>
          </div>
        </div>
        <div className="general-modal__footer">
          <div className="general-modal__actions-group">
            <button
              className="general-modal__action"
              title="Clock Out"
              type="button"
              onClick={handleActionButton}
            >
              {!isOnBreak && (isClockedIn ? 'Clock Out' : 'Clock In')}
              {isOnBreak && 'End break'}
            </button>
            <button
              className="general-modal__action general-modal__action--theme-blue"
              title="Dismiss"
              type="button"
              onClick={handleDismiss}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </GeneralModal>
  );
}
