import { GlobalStateActionEnum } from './constants';

export const filterNames = {
  searchInput: 'searchInput',
  orderType: 'orderType',
  allAndUnpaid: 'allAndUnpaid',
};

export const orderFilterNames = {
  all: 'all',
  pickup: 'pickup',
  delivery: 'delivery',
  dineIn: 'dinein',
  walkin: 'walkin',
};

export const allAndUnpaidFilterEnum = {
  all: 'all',
  unpaid: 'unpaid',
};

const getFilterAfterSetting = ({ state, filterName, filterValue, reset }) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      [filterName]: reset ? initGlobalState.filters[filterName] : filterValue,
    },
  };
};

export function globalStateReducer(state, action) {
  switch (action.type) {
    // Selected order
    case GlobalStateActionEnum.UPDATE_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: action.payload.selectedOrder,
      };
    case GlobalStateActionEnum.RESET_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: initGlobalState.selectedOrder,
      };

    // Search input
    case GlobalStateActionEnum.UPDATE_SEARCH_INPUT:
      return getFilterAfterSetting({
        state,
        filterName: filterNames.searchInput,
        filterValue: action.payload[filterNames.searchInput],
      });

    case GlobalStateActionEnum.RESET_SEARCH_INPUT:
      return getFilterAfterSetting({
        state,
        filterName: filterNames.searchInput,
        reset: true,
      });

    // Order type filter
    case GlobalStateActionEnum.UPDATE_ORDER_TYPE_FILTER:
      return getFilterAfterSetting({
        state,
        filterName: filterNames.orderType,
        filterValue: action.payload[filterNames.orderType],
      });

    case GlobalStateActionEnum.RESET_ORDER_TYPE_FILTER:
      return getFilterAfterSetting({
        state,
        filterName: filterNames.orderType,
        filterValue: action.payload[filterNames.orderType],
      });

    // All and unpaid filter
    case GlobalStateActionEnum.UPDATE_ALL_AND_UNPAID_FILTER:
      return getFilterAfterSetting({
        state,
        filterName: filterNames.allAndUnpaid,
        filterValue: action.payload[filterNames.allAndUnpaid],
      });

    case GlobalStateActionEnum.UPDATE_ORDER_FROM_DRIVERS_PAGE:
      return {
        ...state,
        selectedOrder: action.payload.item,
        driverBackLink: action.payload.backLink,
      };
    default:
      return state;
  }
}

export const initGlobalState = {
  selectedOrder: null,
  filters: {
    [filterNames.orderType]: orderFilterNames.all,
    [filterNames.searchInput]: '',
    [filterNames.allAndUnpaid]: allAndUnpaidFilterEnum.all,
  },
};
