import { HeaderUI } from '../../../UI/components';
import { LogoButton } from '../../../components/LogoButton';

const ShiftsPageContainer = ({ children }) => {
  return (
    <div className="clock-in-out-page">
      <HeaderUI>
        <LogoButton />
      </HeaderUI>
      <div className="clock-in-out-page__body">
        <div className="clock-in-out-page__inner">
          <div className="clock-in-out-page__body-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ShiftsPageContainer;
