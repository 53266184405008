import React from 'react';
import clsx from 'clsx';
import { toggleItemLargePhotoView } from '../../pages/Dashboard/action';
import { useDispatch, useSelector } from 'react-redux';

export const ItemDetailsModal = () => {
  const dispatch = useDispatch();
  const currentItem = useSelector(s => s.dashboardReducer.currentMenuItem);
  const isItemLargePhotoView = useSelector(
    (state) => state.dashboardReducer.isItemLargePhotoView
  );
  const cls = clsx({ show: isItemLargePhotoView });
  const toggleItemModal = () => {
    dispatch(toggleItemLargePhotoView());
  };

  return (
    <div id="item-detail-modal" className={cls}>
      <div className="dine-modal-wrap">
        <div className="dine-modal-box">
          <button
            type="button"
            className="close-modal-btn hover-highlight"
            onClick={toggleItemModal}
          />
          <div className="dine-modal-body">
            <div className="dine-img-box">
              <img src={currentItem.urlS3} alt={currentItem.name} />
            </div>
          </div>
          <div className="dine-modal-text-box">
            {currentItem.name ? (
              <h2 className="dine-modal-title">{currentItem.name}</h2>
            ) : (
              ''
            )}
            {currentItem.description ? (
              <p className="dine-modal-desc">{currentItem.description}</p>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="dine-modal-overlay" onClick={toggleItemModal} />
    </div>
  );
};
