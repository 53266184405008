import { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOrderSideBarType } from 'pages/Orders/actions';
import Tabs from '../../Tabs';
import { setFilters } from 'pages/Orders/newOrdersReducer';

export const filterEnumList = [
  {
    text: 'All',
    uniqueCode: 'all',
  },
  {
    text: 'Unpaid',
    uniqueCode: 'unpaid',
  },
];

function AllAndUpaidFilterCopy() {
  const dispatch = useDispatch();
  const { type, allAndUnpaid } = useSelector(state => state.newOrdersReducer.filters);
  const onTabSelect = useCallback(
    (tab) => {
      // reset the side bar orders filter if it was previously set
      if (type && type !== '') {
        dispatch(setOrderSideBarType(''));
      }

      dispatch(setFilters({ allAndUnpaid: tab.uniqueCode }));
    },
    [type, dispatch]
  );

  const tabTextExtractor = useCallback(t => t.text, [])
  const uniqueTabIdExtractor = useCallback(t => t.text, [])
  const uniqueCodeExtractor = useCallback(t => t.uniqueCode, [])

  return (
    <Tabs
      allTabs={filterEnumList}
      selectedTabCode={allAndUnpaid}
      onChange={onTabSelect}
      tabTextExtractor={tabTextExtractor}
      uniqueTabIdExtractor={uniqueTabIdExtractor}
      uniqueCodeExtractor={uniqueCodeExtractor}
    />
  );
}

export default memo(AllAndUpaidFilterCopy);
