import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import { updateCurrentOrder } from '../../pages/Dashboard/action';
import OrderBarTextField from '../OrderBarTextField';

export const OrderBarNotes = ({ currentOrder }) => {
  const dispatch = useDispatch();

  const virtualKeyboard = useSelector(
    (state) => state.dashboardReducer.virtualKeyboard
  );

  const {
    toggleKeyboard,
    onChangeInput,
    onInitInput,
    getInputValue,
    setInputName,
  } = useVirtualKeyboardContext();

  const notes = getInputValue('notes') || '';

  useEffect(() => {
    setInputName('notes');
    onInitInput('notes', currentOrder.notes || '');
  }, [currentOrder.notes, onInitInput]);

  const handleFocusNote = () => {
    setInputName('notes');
    toggleKeyboard(true);
  };

  const handleChangeNote = (event) => {
    const inputVal = event.target.value;
    onChangeInput('notes', inputVal);
  };

  const handleOnBlur = useCallback(() => {
    dispatch(
      updateCurrentOrder({
        ...currentOrder,
        notes,
      })
    );
  }, [dispatch, currentOrder, notes, toggleKeyboard]);

  useEffect(() => {
    if (notes && virtualKeyboard) {
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          notes,
        })
      );
    }
  }, [notes]);

  return (
    <label className="single-pickup-details input-box">
      <OrderBarTextField
        id="notes"
        name="notes"
        onBlur={handleOnBlur}
        onChange={handleChangeNote}
        onFocus={handleFocusNote}
        placeholder="Notes"
        value={notes}
      />

      <div className="icon-box">
        <span className="dine_icon-note" />
      </div>
    </label>
  );
};
