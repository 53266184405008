import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const usePickupDeliveryShiftStatus = () => {
  const [isPickUpOpen, toggleisPickUpOpen] = useState(false);
  const [isDeliveryOpen, toggleisDeliveryOpen] = useState(false);
  const shifts = useSelector((state) => state.dashboardReducer.shifts || []);

  useEffect(() => {
    let isPickUp = false;
    let isDelivery = false;
    shifts.forEach((item) => {
      if (item.isCurrent) {
        toggleisPickUpOpen(true);
        isPickUp = true;
      }
      if (item.isDeliveryOpen) {
        toggleisDeliveryOpen(true);
        isDelivery = true;
      }
    });
    if (!isPickUp) {
      toggleisPickUpOpen(false);
    }
    if (!isDelivery) {
      toggleisDeliveryOpen(false);
    }
  }, [shifts]);

  return {isPickUpOpen, isDeliveryOpen};
};

export default usePickupDeliveryShiftStatus;