import HalfHalfMenuItems from '.';
import { useParams, useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import {
  isItemWithSingleSize,
  incrementItemQuantitySingleSize,
  updateSize,
  doesItemHaveModifiers,
  updateModifier,
} from '../../itemProcessor';
import {
  openMenuItem,
  updateCurrentHalfHalfItem,
} from '../../pages/Dashboard/action';
import { useLayoutEffect } from 'react';

const HalfHalfMenuItemsWrapper = ({ parentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { halfIndex } = useParams();
  const currentHalfHalfData =
    useSelector((state) => state.dashboardReducer.currentHalfHalfData) || {};
  const { activeCategoryId, variantSelected } = currentHalfHalfData;
  const halfHalf =
    currentHalfHalfData.halfHalfs?.find(
      (h) => h.halfIndex === Number(halfIndex)
    ) || {};
  // auto select single-sized item if menuItem.length === 1
  useLayoutEffect(() => {
    if (activeCategoryId) {
      if (halfHalf.menuItems?.length === 1) {
        const item = halfHalf.menuItems[0];
        if (isItemWithSingleSize(item)) {
          dispatch(
            updateCurrentHalfHalfItem({
              ...incrementItemQuantitySingleSize(item, 0.5),
              halfIndex: parseInt(halfIndex),
              isHalf: true,
              isSecondHalf: parseInt(halfIndex) === 2,
            })
          );
          history.push(`${parentPage}/category/${activeCategoryId}/half-half`);
        }
      }
    }
  }, [
    halfHalf.menuItems,
    history,
    dispatch,
    halfIndex,
    parentPage,
    activeCategoryId,
  ]);

  const handleMenuItemClick = (_event, item) => {
    const { currentMenuItem } = halfHalf;
    if (doesItemHaveModifiers(item)) {
      const sizeModifier = item?.selectedModifiers?.filter(
        (modifier) => modifier.name === 'size'
      );
      const updatedItem = updateModifier(
        { ...currentMenuItem, ...item, isHalf: true },
        sizeModifier[0]?.subModifiers[0]?._id,
        sizeModifier[0]?._id,
        variantSelected
          ? sizeModifier[0]?.subModifiers[0]?.variants[0]?._id
          : null
      );
      dispatch(
        openMenuItem(
          {
            ...updatedItem,
            halfIndex: parseInt(halfIndex),
            isHalf: true,
            isSecondHalf: parseInt(halfIndex) === 2,
          },
          false
        )
      );
    } else if (variantSelected) {
      const { selectedSizes } = item;
      const updatedItem = updateSize(
        { ...currentMenuItem, ...item, isHalf: true },
        selectedSizes[0]?._id,
        currentHalfHalfData.variantSelected
      );
      dispatch(
        updateCurrentHalfHalfItem({
          ...updatedItem,
          halfIndex: parseInt(halfIndex),
          isHalf: true,
          isSecondHalf: parseInt(halfIndex) === 2,
        })
      );
      history.push(`${parentPage}/category/${activeCategoryId}/half-half`);
      return;
    } else {
      const upItem = getSingleSizedItemWithMeta(
        { ...currentMenuItem, ...item },
        halfIndex,
        true
      );
      dispatch(updateCurrentHalfHalfItem(upItem));
      history.push(`${parentPage}/category/${activeCategoryId}/half-half`);
    }
  };

  return (
    <HalfHalfMenuItems
      halfHalfData={halfHalf}
      backLink={`${parentPage}/category/${activeCategoryId}/half-half`}
      onItemClick={handleMenuItemClick}
      parentPage={parentPage}
    />
  );
};

export default HalfHalfMenuItemsWrapper;

export function getSingleSizedItemWithMeta(
  item,
  halfIndex,
  ignoreSizeVariant,
  prevItem = {}
) {
  const {
    orderIndex = false,
    selectedExtraIngredients = false,
    selectedIngredients = false,
  } = prevItem;
  return {
    ...incrementItemQuantitySingleSize(item, 0.5, ignoreSizeVariant),
    halfIndex: parseInt(halfIndex),
    isHalf: true,
    isSecondHalf: parseInt(halfIndex) === 2,
    ...(orderIndex !== false ? { orderIndex } : null),
    ...(selectedExtraIngredients !== false
      ? { selectedExtraIngredients }
      : null),
    ...(selectedIngredients !== false ? { selectedIngredients } : null),
  };
}
