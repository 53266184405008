import React from 'react';
import { XIcon as IconX } from 'nexticons/outline';
import { NavigationButtonUI } from './index';
import PropTypes from 'prop-types';

export const CancelNavButtonUI = ({ link, onClick }) => {
  return (
    <NavigationButtonUI
      title="Cancel"
      link={link}
      icon={<IconX width={18} strokeWidth="4" color="var(--whiteColor)" />}
      onClick={onClick}
      modifierClassName="danger"
    />
  );
};

CancelNavButtonUI.defaultProps = {
  link: '',
  onClick: () => {},
};

CancelNavButtonUI.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
};
