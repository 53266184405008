import React from 'react';
import classNames from 'classnames';
import { ScheduledDropdown } from '../../ScheduledDropdown';
import { useSelector } from 'react-redux';
import SearchInput from '../../../pages/Orders/components/SearchInput/SearchInput';

export const HistorySidebarHeader = () => {
  const shadow = useSelector(
    (state) => state.ordersReducer.scrolledStatus[19].position
  );
  const navCls = classNames({
    'nav-bar-header': true,
    shadow,
  });

  return (
    <div className={navCls}>
      <div className="top-box">
        <h1 className="nav-bar-title">History</h1>
        <ScheduledDropdown title="Time Completed" />
      </div>

      <SearchInput />
    </div>
  );
};
