import orderEngine from '../orderEngine/index';
import { prepareItemForOrder } from '../itemProcessor';
import { detectAndMergeHalfAndHalf } from './utilities';

function addHalfHalfToOrder(
  currentOrder,
  halfHalfData,
  currentMenuItems,
  allSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig
) {
  if (halfHalfData && halfHalfData.halfHalfs && currentMenuItems) {
    return addItemsToOrder(
      currentOrder,
      currentMenuItems,
      allSpecials,
      orderSetup,
      productSetup,
      allSuburbs,
      publicHolidays,
      storeConfig
    );
  }
}

function addItemsToOrder(
  currentOrder,
  items,
  allSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig
) {
  let currentOrderObj = { ...currentOrder };
  let finalItems = [];

  items.forEach((item) => {
    finalItems.push(prepareItemForOrder(item, currentOrder, productSetup));
  });

  currentOrderObj = {
    ...currentOrderObj,
    menuItems: detectAndMergeHalfAndHalf(finalItems, currentOrderObj.menuItems),
  };

  let newOrder = orderEngine(
    currentOrderObj,
    allSpecials,
    orderSetup,
    productSetup,
    allSuburbs,
    publicHolidays,
    storeConfig
  );

  return newOrder;
}

export default addHalfHalfToOrder;
