import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import ShiftsButton from './ShiftsButton';
import ShiftsPageContainer from './Containers/ShiftsPageContainer';
import ShiftsBlockContainer from './Containers/ShiftsBlockContainer';

import {
  useStaffMemberActiveShift,
  useStaffMemberName,
  useShiftsState,
} from './Context/ShiftsState';

import {
  loadDrawers,
  loadTerminalSettings,
} from '../../pages/Dashboard/action';

import { getCurrentTime } from '../../util/dateUtils';
import {
  addBreak,
  clockOutUser,
  endBreak,
} from '../../services/shiftsServices';
import { ShiftsEnum } from './constants';
import { getParamValue } from '../../util/utils';
import { getTimePassedString } from '../../util/shifts-screen-utils';
import { useRouteToHomePage } from '../../customHooks/useRouteToHomePage';

import {
  selectedDrawerStatusIsActiveSelector,
  orderSetupClockInReminderSelector,
} from 'pages/Dashboard/selectors';
import { useGlobalModalContext } from 'modals/GlobalModalContext/useGlobalModalContext';

const ClockedInTimePassed = ({ isStaffOnABreak, startingTimestamp = 0 }) => {
  const statusText = isStaffOnABreak ? 'On Current Break' : 'On Shift';
  return (
    <p className="clock-in-out__desc">
      {`${getTimePassedString(
        startingTimestamp,
        isStaffOnABreak
      )} - ${statusText}`}
    </p>
  );
};

const ClockedInOptionsScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDrawers());
    dispatch(loadTerminalSettings());
  }, [dispatch]);

  const { handleGlobalModal } = useGlobalModalContext();
  const selectedDrawerStatusIsActive = useSelector(
    selectedDrawerStatusIsActiveSelector
  );
  const clockInReminder = useSelector(orderSetupClockInReminderSelector);

  const statusValue = getParamValue('status');
  const isStaffOnABreak = statusValue === ShiftsEnum.ON_A_BREAK;

  useRouteToHomePage(isStaffOnABreak ? 30000 : false);

  const staffMemberName = useStaffMemberName();
  const {
    activeShift: staffMemberActiveShift,
    activeRoleName: staffMemberActiveRoleName,
    activeBreakStartTime: staffMemberActiveBreakStartTime,
  } = useStaffMemberActiveShift();

  const [loadingButton, setLoadingButton] = React.useState('');

  const staffMember = useShiftsState();

  const gotoCashierPage = () => {
    history.push('/cashier');
  };

  const handlelockOut = async () => {
    setLoadingButton(ShiftsEnum.CLOCKED_OUT);
    await clockOutUser(staffMemberActiveShift.id);
    setLoadingButton('');

    history.push(`/shifts/confirmation?status=${ShiftsEnum.CLOCKED_OUT}`);
  };

  const handleClockOutClick = async () => {
    if (staffMemberActiveShift && staffMemberActiveShift.id) {
      if (selectedDrawerStatusIsActive && clockInReminder) {
        handleGlobalModal({
          title: 'Yesterday, someone forgot to close the Cash Drawer',
          message:
            'To ensure we can keep proper track of transactions, please go to Cashier and close the drawer.',
          actionButtonLabel: 'Open Cashier',
          actionButtonHandler: gotoCashierPage,
          dismissButtonHandler: handlelockOut,
        });
      } else {
        handlelockOut();
      }
    }
  };

  const handleTakeABreak = async () => {
    setLoadingButton(ShiftsEnum.START_BREAK);
    await addBreak(staffMemberActiveShift.id);
    setLoadingButton('');
    history.push(`/shifts/confirmation?status=${ShiftsEnum.START_BREAK}`);
  };

  const handleSwitchRoles = async () => {
    history.push(`/shifts/select-role?status=${ShiftsEnum.SWITCH_ROLES}`);
  };

  const handleEndBreak = async () => {
    setLoadingButton(ShiftsEnum.END_BREAK);
    await endBreak(staffMemberActiveShift.id);
    setLoadingButton('');
    history.push(`/shifts/confirmation?status=${ShiftsEnum.END_BREAK}`);
  };

  return (
    <ShiftsPageContainer>
      <ShiftsBlockContainer>
        <div className="clock-in-out__header-group">
          <h2 className="clock-in-out__title">{staffMemberName}</h2>
          <h2 className="clock-in-out__title">{getCurrentTime()}</h2>
          <ClockedInTimePassed
            isStaffOnABreak={isStaffOnABreak}
            startingTimestamp={
              isStaffOnABreak
                ? staffMemberActiveBreakStartTime
                : staffMemberActiveShift.startTime
            }
          />
        </div>
        <div className="clock-in-out__btn-group">
          {isStaffOnABreak ? (
            <ShiftsButton
              title="End Break"
              handleOnClick={handleEndBreak}
              iconName="rock"
              buttonTheme="red"
              isLoading={loadingButton === ShiftsEnum.END_BREAK}
            />
          ) : (
            <>
              <ShiftsButton
                title="Clock Out"
                handleOnClick={handleClockOutClick}
                iconName="clock-five"
                buttonTheme="blue"
                isLoading={loadingButton === ShiftsEnum.CLOCKED_OUT}
              />
              <ShiftsButton
                title="Take a Break"
                handleOnClick={handleTakeABreak}
                iconName="coffee"
                isLoading={loadingButton === ShiftsEnum.START_BREAK}
              />
              {staffMember?.roles?.filter(role=>
                role.id && role.isActive
              ).length > 1 && <ShiftsButton
                title="Switch Roles"
                handleOnClick={handleSwitchRoles}
                iconName="switch"
                buttonTheme="orange"
                // isLoading={loadingButton === ShiftsEnum.END_BREAK}
              />}
            </>
          )}
        </div>
        {!isStaffOnABreak && (
          <p className="clock-in-out__desc clock-in-out__desc--move-up">
            {`Clocked in as ${staffMemberActiveRoleName}`}
          </p>
        )}
      </ShiftsBlockContainer>
    </ShiftsPageContainer>
  );
};

export default ClockedInOptionsScreen;
