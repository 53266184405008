import mobiscroll from '@mobiscroll/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { PosIconDownChevron } from '../../assets/icons/PosIconDownChevron';
import '../../assets/scss/modals/_add-drawer-modal.scss';
import Button from '../../components/Button';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import GeneralModal from '../../modals/GeneralModal/index-new';

export const AddDrawerModal = ({
  modalStatus,
  toggleModal,
  printers,
  addDrawerHandler,
  drawerNames,
  drawerPrinterIds,
}) => {
  // const [drawerName, setDrawerName] = useState('');
  const [printerId, setPrinterId] = useState('');
  const [nameError, setNameError] = useState('');
  const [printerIdError, setPrinterIdError] = useState('');
  const [printersList, setPrinterList] = useState([]);

  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    inputNames,
  } = useVirtualKeyboardContext();
  const inputName = inputNames.drawerName;

  useEffect(() => {
    if (printers && printers.length > 0) {
      setPrinterList(
        printers.map((p) => ({
          value: p._id,
          text: p.nickName,
          html: `<p style="text-decoration: ${
            drawerPrinterIds.indexOf(p._id) > -1 ? 'line-through' : 'none'
          }">${p.nickName}</p>`,
        }))
      );
    }
  }, [printers, drawerPrinterIds]);

  const handleClick = (event) => {
    event.preventDefault();
    if (drawerNames.indexOf(getInputValue(inputName).toLowerCase()) > -1) {
      return setNameError('Drawer with selected printer already exists');
    }

    // setDrawerName('');
    onChangeInput(inputName, '');
    setPrinterId('');
    addDrawerHandler(getInputValue(inputName), printerId);
    // addDrawerHandler(drawerName, printerId);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleChangeName = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (drawerNames.indexOf(value.toLowerCase()) > -1) {
      setNameError('Drawer with selected printer already exists');
    } else {
      setNameError('');
    }
    // setDrawerName(event.target.value);

    onChangeInput(inputName, value);
  };

  const handlePrinterChange = (event, inst) => {
    const selectedPrinterId = inst.getVal();

    if (drawerPrinterIds.indexOf(selectedPrinterId) > -1) {
      let selectedPrinter = printers.find((f) => f._id === selectedPrinterId);

      return setPrinterIdError(
        `The connected drawer is already connected to ${selectedPrinter.nickName}. Please delete the drawer or select a different printer.`
      );
    }
    setPrinterIdError('');
    setPrinterId(selectedPrinterId);
  };

  const handleFocus = () => {
    setInputName(inputName);

    toggleKeyboard(true);
  };
  const handleBlur = () => {
    toggleKeyboard(false);
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="bottom"
      effect="move"
      overlay=""
      boxWidth="514px"
      boxHeight="380px"
      className="add-drawer-modal"
      closeBtn="inside"
      keyboardVisibility={keyboardVisibility}
    >
      <div className="add-drawer-modal-box">
        <div className="add-drawer-modal-box-inner">
          <h2 className="add-drawer-title">Add Drawer</h2>
          <form
            action=""
            className="add-drawer-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="single-input-box">
              <input
                id={inputName}
                name={inputName}
                // id="drawer-name"
                // name="drawer-name"
                className="input-style white big"
                placeholder="Drawer Name"
                // value={drawerName}
                value={getInputValue(inputName)}
                onFocus={handleFocus}
                // onBlur={handleBlur}
                onChange={handleChangeName}
              />
              <div className="add-drawer-error-note">{nameError}</div>
            </div>
            <div className="single-input-box">
              <PosIconDownChevron mainColor="#979797" lineThickness={0.5} />
              <mobiscroll.Select
                display="center"
                animate="fade"
                touchUi={false}
                filter={true}
                data={printersList}
                className="white"
                placeholder="Select Connected Printer..."
                value={printerId}
                onChange={handlePrinterChange}
              />
              <div className="add-drawer-error-note">{printerIdError}</div>
              <div className="add-drawer-note">
                <b>Connected Printer</b> is the printer that triggers the Cash
                Drawer.
              </div>
            </div>
          </form>
        </div>
        <div className="add-drawer-btn-box">
          <Button
            disabled={
              nameError.length > 0 ||
              printerIdError.length > 0 ||
              (printerId && printerId.length <= 0) ||
              (getInputValue(inputName) && getInputValue(inputName).length <= 0)
            }
            /* disabled={
              (printerId && printerId.length <= 0) ||
              (drawerName && drawerName.length <= 0)
            }*/
            className="btn"
            title="Add Drawer"
            onClick={handleClick}
          >
            Add Drawer
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

AddDrawerModal.propTypes = {
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};
