/**
 * @fileoverview gRPC-Web generated client stub for notification
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: src/notifications/proto/notifications.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.notification = require('./notifications_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.notification.DispatcherClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.notification.DispatcherPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification.NotificationRequest,
 *   !proto.notification.Notifications>}
 */
const methodDescriptor_Dispatcher_GetNotifications = new grpc.web.MethodDescriptor(
  '/notification.Dispatcher/GetNotifications',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.notification.NotificationRequest,
  proto.notification.Notifications,
  /**
   * @param {!proto.notification.NotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notification.Notifications.deserializeBinary
);


/**
 * @param {!proto.notification.NotificationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.notification.Notifications>}
 *     The XHR Node Readable Stream
 */
proto.notification.DispatcherClient.prototype.getNotifications =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/notification.Dispatcher/GetNotifications',
      request,
      metadata || {},
      methodDescriptor_Dispatcher_GetNotifications);
};


/**
 * @param {!proto.notification.NotificationRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.notification.Notifications>}
 *     The XHR Node Readable Stream
 */
proto.notification.DispatcherPromiseClient.prototype.getNotifications =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/notification.Dispatcher/GetNotifications',
      request,
      metadata || {},
      methodDescriptor_Dispatcher_GetNotifications);
};


module.exports = proto.notification;

