import React from 'react';

const RefundSelectItem = ({
  value,
  label,
  price = '',
  children,
  onChange,
  partiallyChecked,
  checked,
}) => (
  <div>
    <label
      className="refund-select-list__label refund-select-list__label--with-price"
      title={label}
    >
      <span className="refund-select-list__item-cell">
        <input
          id={`refund-reason_${value}`}
          type="checkbox"
          className={`refund-select-list__input-checkbox refund-select-list__input-checkbox--${partiallyChecked && !checked ? 'partially-checked' : ''}`}
          name="refund-reason"
          checked={checked}
          onChange={() => onChange(value)}
        />
      </span>
      <span className="refund-select-list__item-cell">
        <span className="refund-select-list__text">{label}</span>
      </span>
      <span className="refund-select-list__item-cell refund-select-list__item-cell--align-right">
        <span className="refund-select-list__text">{price}</span>
      </span>
    </label>
    {children}
  </div>
);

export default RefundSelectItem;
