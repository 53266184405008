import { useMemo } from 'react';

export const useMenuItemsFilterByString = ({
  totalItems,
  menuItems,
  searchString,
}) => {
  const filteredMenuItems = useMemo(() => {
    if (searchString) {
      const searchStr = searchString.toLowerCase();
      return totalItems.filter(
        (item) =>
          item.name?.toLowerCase().includes(searchStr) ||
          item.keywords?.includes(searchStr)
      );
    } else {
      return menuItems ?? [];
    }
  }, [totalItems, menuItems, searchString]);

  return filteredMenuItems;
};
