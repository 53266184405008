import React, { useEffect } from 'react';
// import '../../../assets/scss/settle/_settle-content.scss';
// import Context from '../../../Context';
import Button from '../../Button';
import { PosIconPrinter } from '../../../assets/icons/PosIconPrinter';
import { SettleDeliveryView } from '../SettleDeliveryView';
import { SettleTableView } from '../SettleTableView';
import { PosIconBagInverse } from '../../../assets/icons/PosIconBagInverse';
import useCurrentGuest from '../../../customHooks/useCurrentGuest';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';

export const SettleContent = ({ togglePaymentModal }) => {
  // const tags = state.tableView.tags;
  const dispatch = useDispatch();
  const selectedOrder = useSelector(
    (state) => state.ordersReducer.selectedOrder
  );
  const preparingList = useSelector((state) => state.ordersReducer.orderList);
  const currentGuest = useCurrentGuest(preparingList, selectedOrder._id);
  // const selectedTags = currentGuest.tags;

  const handleClickPrintBill = () => {
    // togglePlanBillModal
    dispatch({
      type: 'togglePlanBillModal',
      payload: true,
    });
  };

  return (
    <div className="settle-main">
      <div className={clsx('view-order-main', { empty: !currentGuest })}>
        {currentGuest && currentGuest._id && selectedOrder.storeId ? (
          <>
            <div className="booking-inner-table">
              <div className="inner-cell">
                <h2 className="modal-title">
                  {selectedOrder.firstName + ' ' + selectedOrder.lastName}
                </h2>
              </div>
              <div className="inner-cell">
                <ul className="booking-actions-list">
                  <li>
                    <Button
                      title="Print Bill "
                      className="booking-action-btn green"
                      onClick={handleClickPrintBill}
                    >
                      <span className="icon-box">
                        <PosIconPrinter mainColor="#ffffff" />
                      </span>
                      <span className="name">Print Bill </span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
            {selectedOrder.deliveryCode || selectedOrder.pickupId ? (
              <SettleDeliveryView
                togglePaymentModal={togglePaymentModal}
                currentGuest={selectedOrder}
              />
            ) : (
              ''
              // <SettleTableView
              //   togglePaymentModal={togglePaymentModal}
              //   currentGuest={currentGuest}
              // />
            )}
          </>
        ) : (
          <div className="view-order-main-empty">
            <PosIconBagInverse mainColor="#d1d1d1" darkModeColor="#d1d1d1" />
            <p className="text">Select an Order to view</p>
          </div>
        )}
      </div>
    </div>
  );
};
