import CustomerNameModal from 'components/CustomerName';
import { ItemView } from 'components/ItemView';
import { ItemViewCustomise } from 'components/ItemViewCustomise';
import { MenuItems } from 'components/MenuItems';
import { NavigationBar } from 'components/NavigationBar';
import { OrderBar } from 'components/OrderBar';
import { OrderProvider } from 'hooks/useOrder';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ItemDetailsModal } from '../../modals/ItemDetailsModal';
import SelectPaymentMethodModal from 'modals/SelectPaymentMethodModal';
import {
  loadDrawers,
  loadPinpads,
  loadTerminalSettings,
  resetCurrentFourQuarterData,
  resetCurrentHalfHalfData,
  resetMenuItem,
  setCashAmountChange,
  setCashPaid,
  setDisableSpecialDiscountModal,
  setOrderBackModal,
  setOrderTypeConfig,
  updateCurrentOrder,
} from '../../pages/Dashboard/action';
import { getOrderTimeArray } from '../../util/getTimeSlots';

import CartItemActionsModal from 'components/OrderBar/ItemViewModal/CartItemActionsModal';

import SpecialsView from 'components/SpecialsView';
import SpecialsFourQuarterMenuItems from 'components/SpecialsView/SpecialsFourQuarterMenuItems';
import SpecialsFourQuarterView from 'components/SpecialsView/SpecialsFourQuarterView';
import SpecialsHalfHalfMenuItems from 'components/SpecialsView/SpecialsHalfHalfMenuItems';
import SpecialsHalfHalfView from 'components/SpecialsView/SpecialsHalfHalfView';
import SpecialsMenuItems from 'components/SpecialsView/SpecialsMenuItems';

import HalfHalfMenuItemsWrapper from 'components/HalfHalfMenuItems/HalfHalfMenuItemsWrapper';
import HalfHalfViewWrapper from 'components/HalfHalfView';

import CashAmountSelectedModal from 'components/CashAmountSelectedModal';
import CustomerPaymentDetailsModal from 'components/CustomerPaymentDetailsModal';
import PaymentModal from 'components/PaymentModal';
import PaymentViewModal from 'components/PaymentViewModal';

import FourQuarterMenuItemsWrapper from 'components/FourQuarterMenuItems/FourQuarterMenuItemsWrapper';
import FourQuarterViewWrapper from 'components/FourQuarterView';
import CustomerPhoneEffect from './CustomerPhoneEffect';

import OrderBackModal from 'components/OrderBackModal';
import { loadPaymentTypes } from '../Dashboard/thunks';

import DisableSpecialDiscountModal from 'components/DisableSpecialDiscountModal';
import PhoneModal from 'components/PhoneNumber';
import SplitPaymentsRoot from 'components/SplitPayments';
import {
  MENU_ORDER_FROM,
  OrderTypeBasepathEnum,
  OrderTypeIdEnum,
  OrderTypeTitleEnum,
} from 'configuration/enums';
import { getOrdersHistory } from '../../services/ordersServices';

class Pickup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guestModal: false,
      dataPickerModal: false,
      basePath: '/pickup',
      isShowPaymentModal: false,
      paymentModalType: 'paymentModal',
      callOrdersHistory: true,
      ordersHistory: [],
    };
  }

  componentDidMount() {
    const storeConfig = this.props.storeConfig;
    this.props.setOrderTypeConfig({
      basePath: OrderTypeBasepathEnum.PICK_UP,
      title: OrderTypeTitleEnum.PICK_UP,
      orderTypeId: OrderTypeIdEnum.PICK_UP,
    });
    this.props.updateCurrentOrder({
      orderType: OrderTypeIdEnum.PICK_UP,
      orderFrom:
        this.props.currentOrder?.orderFrom === '1'
          ? MENU_ORDER_FROM.web
          : MENU_ORDER_FROM.pos,

      storeId: storeConfig?.storeId,
      restaurantId: storeConfig.restaurantId,
    });
  }

  componentDidUpdate() {
    if (!this.state.pickUpTimes && this.props.shifts && this.props.orderSetup) {
      let pickUpTimes = getOrderTimeArray(
        this.props.orderSetup ? this.props.orderSetup : {},
        this.props.storeConfig,
        OrderTypeIdEnum.PICK_UP
      );
      this.setState({ pickUpTimes });
    }
    // request order history
    if (this.state.callOrdersHistory) {
      const storeConfig = this.props.storeConfig;
      const currentOrder = this.props.currentOrder;
      this.handleOrdersHistory(currentOrder.userId, storeConfig?.storeId);
    }
  }

  handleOrdersHistory = async (userId, storeId) => {
    if (userId !== 'xxx-xxx-xxx' && !this.state.ordersHistory.length > 0) {
      // set flag
      this.setState({ callOrdersHistory: false });

      // perform the request
      const ordersHistory = await getOrdersHistory({ userId, storeId });
      const orders =
        ordersHistory && ordersHistory?.data && ordersHistory?.data?.orders;

      // check and set data
      if (orders && orders.length > 0) {
        this.setState({
          ordersHistory: orders,
        });
      }
    }
  };

  toggleGuestModal = () => {
    this.setState({
      guestModal: !this.state.guestModal,
    });
  };

  toggleDataPickerModal = () => {
    this.setState({
      dataPickerModal: !this.state.dataPickerModal,
    });
  };

  handleOpenPaymentModal = () => {
    this.setState({
      isPaymentLoading: true,
    });
    if (this.props.currentOrder.isSplit) {
      this.handleSplitPaymentClick();
    } else if (
      this.props.currentOrder.menuItems?.length &&
      this.props.currentOrder.payableAmount
    ) {
      this.setState({ isShowPaymentModal: true });
    }
  };

  handleSelectPaymentMethodModal = () => {
    this.setState({ paymentModalType: 'selectPaymentMethodModal' });
  };

  handleOpenPaymentViewModal = () => {
    this.setState({ paymentModalType: 'paymentViewModal' });
  };

  handleCustomerPaymentDetailsModal = () => {
    this.setState({ paymentModalType: 'customerPaymentDetailsModal' });
  };

  handlePaymentModalClose = () => {
    if (this.state.paymentModalType !== 'cashAmountSelectedModal') {
      this.setState({
        isShowPaymentModal: false,
        paymentModalType: '',
        isPaymentLoading: false,
      });
    }
  };

  handleCashAmountSelectedModal = (orderId = '') => {
    this.setState({
      paymentModalType: 'cashAmountSelectedModal',
      selectedOrderId: orderId,
    });
  };

  handleSplitPaymentClick = () => {
    this.setState({
      isShowSplitPaymentModal: true,
      isShowPaymentModal: false,
      paymentModalType: '',
    });
  };

  handleSplitPaymentModalClose = () => {
    this.setState({
      isShowSplitPaymentModal: false,
      isShowPaymentModal: false,
      paymentModalType: '',
      isPaymentLoading: false,
    });
  };

  render() {
    return (
      <OrderProvider>
        <CustomerPhoneEffect />
        <div className="main-area-wrap">
          <NavigationBar basePath={this.state.basePath} />
          <Switch>
            <Route exact path="/pickup/menuitem/:itemId/:itemType/:modifierId">
              {/* View item image, name and ingredient in large view */}
              <ItemDetailsModal />
              {/* Item details view opened based on item selected in right side of view */}
              <ItemView parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/pickup/specials/:specialsId">
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsView parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/pickup/specials/:specialsId/:categoryIndex">
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsMenuItems parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/pickup/specials/:specialsId/:categoryIndex/half-half"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsHalfHalfView parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/pickup/specials/:specialsId/:categoryIndex/half-half/:halfIndex"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsHalfHalfMenuItems parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/pickup/specials/:specialsId/:categoryIndex/four-quarter"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsFourQuarterView parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/pickup/specials/:specialsId/:categoryIndex/four-quarter/:fourQuarterIndex"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsFourQuarterMenuItems parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/pickup/category/:categoryId/half-half">
              {/* Item details view opened based on item selected in right side of view */}
              <HalfHalfViewWrapper parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/pickup/category/:categoryId/half-half/:halfIndex"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <HalfHalfMenuItemsWrapper parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/pickup/category/:categoryId/four-quarter">
              {/* Item details view opened based on item selected in right side of view */}
              <FourQuarterViewWrapper parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/pickup/category/:categoryId/four-quarter/:fourQuarterIndex"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <FourQuarterMenuItemsWrapper parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/pickup/menuitem/:itemId/customization/ingredient/:customizeSlug"
            >
              <ItemViewCustomise parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/pickup/order">
              <MenuItems
                parentPage={this.state.basePath}
                ordersHistory={this.state.ordersHistory}
              />
            </Route>
          </Switch>
          <OrderBar
            orderPayments={this.props.orderPayments}
            orderTimes={this.state.pickUpTimes}
            parentPage={this.state.basePath}
            onPayClick={this.handleOpenPaymentModal}
            orderSetup={this.props.orderSetup}
            storeConfig={this.props.storeConfig}
            title="Pickup"
            isPaymentLoading={this.state.isPaymentLoading}
          />
          <CartItemActionsModal basePath={this.state.basePath} />
          {this.state.isShowPaymentModal && (
            <PaymentModal
              modalStatus={this.state.isShowPaymentModal}
              toggleModal={() => {
                this.setState({
                  isShowPaymentModal: false,
                  isPaymentLoading: false,
                });
              }}
              openPaymentViewModal={this.handleOpenPaymentViewModal}
              openCustomerPaymentDetailsModal={
                this.handleCustomerPaymentDetailsModal
              }
              openSelectPaymentMethodModal={this.handleSelectPaymentMethodModal}
              orderTimes={this.state.pickUpTimes}
              currentOrder={this.props.currentOrder}
              orderPayments={this.props.orderPayments}
              storeConfig={this.props.storeConfig}
              orderSetup={this.props.orderSetup}
              type="pickup"
            />
          )}
          {this.state.isShowPaymentModal &&
            this.state.paymentModalType === 'paymentViewModal' && (
              <PaymentViewModal
                toggleModal={this.handlePaymentModalClose}
                openPaymentViewModal={this.handleOpenPaymentViewModal}
                currentOrder={this.props.currentOrder}
                orderTimes={this.state.pickUpTimes}
                openCashAmountPaidModal={this.handleCashAmountSelectedModal}
                handleCashAmountChange={this.props.handleCashAmountChange}
                handleCashPaid={this.props.handleCashPaid}
                handleSplitPaymentClick={this.handleSplitPaymentClick}
              />
            )}
          {this.state.isShowPaymentModal &&
            this.state.paymentModalType === 'customerPaymentDetailsModal' && (
              <CustomerPaymentDetailsModal
                toggleModal={this.handlePaymentModalClose}
                modalStatus={
                  this.state.isShowPaymentModal &&
                  this.state.paymentModalType === 'customerPaymentDetailsModal'
                }
                currentOrder={this.props.currentOrder}
                orderPayments={this.props.orderPayments}
                orderTimes={this.state.pickUpTimes}
              />
            )}
          {this.state.isShowPaymentModal &&
            this.state.paymentModalType === 'cashAmountSelectedModal' && (
              <CashAmountSelectedModal
                orderId={this.state.selectedOrderId}
                toggleModal={this.handlePaymentModalClose}
                modalStatus={
                  this.state.isShowPaymentModal &&
                  this.state.paymentModalType === 'cashAmountSelectedModal'
                }
                cashAmountChange={this.props.cashAmountChange}
                cashPaid={this.props.cashPaid}
                title={this.props.title}
              />
            )}
          {this.state.isShowPaymentModal &&
            this.state.paymentModalType === 'selectPaymentMethodModal' && (
              <SelectPaymentMethodModal
                toggleModal={this.handlePaymentModalClose}
                modalStatus={
                  this.state.isShowPaymentModal &&
                  this.state.paymentModalType === 'selectPaymentMethodModal'
                }
                openPaymentViewModal={this.handleOpenPaymentViewModal}
                openCustomerPaymentDetailsModal={
                  this.handleCustomerPaymentDetailsModal
                }
              />
            )}
          {this.props.currentOrder.iteration === 0 && <PhoneModal />}
          <CustomerNameModal
            parentPage={this.state.basePath}
            orderType={OrderTypeIdEnum.PICK_UP}
          />
          {this.props.orderBackModal ? (
            <OrderBackModal
              modalStatus={this.props.orderBackModal}
              toggleModal={this.props.handleOrderExitModalClose}
              redirectUrl={this.props.currentOrder.iteration ? '/orders' : ''}
            />
          ) : null}
          {this.state.isShowSplitPaymentModal ? (
            <SplitPaymentsRoot
              currentOrder={this.props.currentOrder}
              closeFlowStatus={this.handleSplitPaymentModalClose}
            />
          ) : null}
          {this.props.disableSpecialDicountModal ? (
            <DisableSpecialDiscountModal
              modalStatus={this.props.disableSpecialDicountModal}
              toggleModal={
                this.props.handleDisableSpecialDiscountExitModalClose
              }
            />
          ) : null}
        </div>
      </OrderProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.dashboardReducer.categories || [],
    customerDetails: state.dashboardReducer.acceptedCall?.customerDetails || {},
    storeConfig: state.dashboardReducer.storeConfig,
    currentOrder: state.dashboardReducer.currentOrder,
    shifts: state.dashboardReducer.shifts,
    orderSetup: state.dashboardReducer.orderSetup,
    menuItems: state.dashboardReducer.menuItems,
    title: state.dashboardReducer.title,
    orderPayments: state.dashboardReducer.orderPayments,
    cashAmountChange: state.dashboardReducer.cashAmountChange,
    cashPaid: state.dashboardReducer.cashPaid,
    orderBackModal: state.dashboardReducer.orderBackModal,
    disableSpecialDicountModal:
      state.dashboardReducer.disableSpecialDicountModal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOrderTypeConfig: (orderTypeConfig) =>
      dispatch(setOrderTypeConfig(orderTypeConfig)),
    loadPinpads: (storeId) => dispatch(loadPinpads(storeId)),
    loadDrawers: (storeId) => dispatch(loadDrawers(storeId)),
    loadTerminalSettings: (storeId) => dispatch(loadTerminalSettings(storeId)),
    loadPaymentTypes: () => dispatch(loadPaymentTypes()),
    updateCurrentOrder: (currentOrder) =>
      dispatch(updateCurrentOrder(currentOrder)),
    handleCashAmountChange: (cashAmountChange) => {
      dispatch(setCashAmountChange(cashAmountChange));
    },
    handleCashPaid: (cashPaid) => {
      dispatch(setCashPaid(cashPaid));
    },
    handleCategoryChange: () => {
      dispatch(resetCurrentHalfHalfData());
      dispatch(resetCurrentFourQuarterData());
      dispatch(resetMenuItem());
    },
    handleOrderExitModalClose: () => {
      dispatch(setOrderBackModal(false));
    },
    handleDisableSpecialDiscountExitModalClose: () => {
      dispatch(setDisableSpecialDiscountModal(false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Pickup);
