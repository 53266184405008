import React from 'react';

export const ItemDetails = React.forwardRef((props, ref) => {
  const currentItem = props.currentItem;
  return (
    <div ref={ref} className="main-area-header">
      <div className="current-food-box">
        <div className="current-img-box">
          <img
            src={currentItem.urlS3 ? currentItem.urlS3 : ''}
            alt={currentItem.name ? currentItem.name : ''}
          />
        </div>
        <div className="current-food-body">
          <h1 className="food-title">
            {currentItem.name ? currentItem.name : ''}
          </h1>
          <p className="food-ingredients">
            {currentItem.description ? currentItem.description : ''}
          </p>
        </div>
      </div>
    </div>
  );
});
