import useOrderTouched from 'hooks/useOrderTouched';
import GeneralModal from 'modals/GeneralModal';
import { handleAddOrderToTable } from 'pages/DineIn/actions';
import CoursesCard from './CoursesCard';

export default function SidebarCourcesPopup({
  coursesPopupOpen,
  setCloseCoursesPopup,
  chosenOrder,
}) {
  const [orderTouched] = useOrderTouched(chosenOrder, true);

  const onPayClick = (onSuccess, { hasNotChanged } = {}) => {
    handleAddOrderToTable({
      currentOrder: chosenOrder,
      callback: setCloseCoursesPopup,
      onSuccess,
      hasNotChanged,
    });
  };

  const handlePay = async (cb, extraOptions) => {
    const isDineIn = chosenOrder.orderType === '3';

    if (isDineIn) {
      if (!orderTouched) {
        return;
      }
      onPayClick((result) => {
        if (typeof cb === 'function') {
          cb(result);
        }
      }, extraOptions);
    }
  };

  return (
    <GeneralModal
      id="digits-input-modal"
      modalStatus={coursesPopupOpen}
      toggleModal={setCloseCoursesPopup}
      position="center"
      overlay="blur"
      boxWidth="435px"
      className="digits-input-modal"
      backBtn="outside"
      backBtnFnc={setCloseCoursesPopup}
    >
      <CoursesCard
        handlePay={handlePay}
        setCloseCoursesPopup={setCloseCoursesPopup}
        orderTouched={orderTouched}
        currentOrder={chosenOrder}
        callback={setCloseCoursesPopup}
        hideCloseButton
        menuItemsWithCourseStatus={chosenOrder?.menuItems}
      />
    </GeneralModal>
  );
}
