import React, { useEffect, useState } from 'react';

export const useActiveCategoryById = (categoriesList, categoryId) => {
  const [currentCategory, setCurrentCategory] = useState({});

  useEffect(() => {
    if (categoryId && categoriesList && categoriesList.length) {
      for (let i = 0; i < categoriesList.length; i++) {
        if (categoriesList[i]._id === categoryId) {
          setCurrentCategory(categoriesList[i]);
          break;
        }
      }
    }
  }, [categoriesList, categoryId]);

  return currentCategory;
};
