import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Shape } from '../Shape';

export const DragShape = ({
  item,
  editMode,
  constraintsRef,
  handleShapeSelect,
  handleDragEnd,
  handleMove,
  isSelected,
  layoutLoading,
  selectedFloorId,
}) => {
  const movingOrder = useSelector(state => state.tableArrangementReducer.movingOrder)
  const hoverTableId = useSelector(state => state.tableArrangementReducer.hoverTableId)
  const [shouldAnimate, setShouldAnimate] = React.useState(() => {
    if (typeof item.id === 'number') return true;
    return false;
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldAnimate(true);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const isDragging = useRef(false);

  const handleOnDragEnd = (e, info) => {
    handleDragEnd(item, info);

    // clear drag ref to prevent click to be triggered
    setTimeout(() => {
      isDragging.current = false;
    }, 150);
  };

  const handleOnSelect = () => {
    if (movingOrder) {
      const params = {
        dineInObjectId: item.id,
        dineInObjectNumber: item.number,
        floorLayoutId: selectedFloorId,
      };

      handleMove(params)
    } else if (!isDragging.current) {
      handleShapeSelect(item);
    }
  };

  const motionObject = {
    x: item.positionX,
    y: item.positionY,
    rotate: item.orientationAngle,
    scale: item.scale || 1,
  };

  return (
    <motion.div
      className={clsx(
        `shape ${item.type} ${item.type}--num-seats-${item.numSeats}`,
        {
          'shape--is-available': movingOrder && !(hoverTableId === item.id) && !item.isOccupied && item.objectType === 'dineInObjects',
          'shape--is-selected': (movingOrder && hoverTableId === item.id) || isSelected,
          'shape--is-loading': layoutLoading,
        }
      )}
      drag={editMode}
      dragConstraints={constraintsRef}
      dragElastic={false}
      dragMomentum={false}
      animate={shouldAnimate ? motionObject : null}
      initial={!shouldAnimate ? motionObject : null}
      transition={{ delay: 0, ease: 'linear' }}
      onDragStart={() => {
        isDragging.current = true;
      }}
      onDragEnd={handleOnDragEnd}
      onClick={handleOnSelect}
    >
      <Shape {...item} />
    </motion.div>
  );
};
