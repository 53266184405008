import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconWalletCash = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-wallet-cash': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 26 23.9"
      >
        <title>Wallet-Cash</title>
        <circle
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          strokeMiterlimit="10"
          cx="19.5"
          cy="15.4"
          r="1"
        />
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          // strokeMiterlimit="10"
          d="M24.5,12.4V8.6c0-0.7-0.6-1.3-1.3-1.3h-0.8V4.6c0-0.7-0.6-1.3-1.3-1.3h-2.4l-1-2.7L12,3.4H3
            c-1.4,0-2.5,1.1-2.5,2.5v15c0,1.4,1.1,2.5,2.5,2.5h20.3c0.7,0,1.3-0.6,1.3-1.3v-3.8h-0.3c0.7,0,1.3-0.6,1.3-1.3v-3.5
            C25.5,13,25.1,12.5,24.5,12.4z M21.3,4.4c0.1,0,0.3,0.1,0.3,0.3v2.8h-1.1l-1.2-3H21.3z M19.3,7.4H5.7L17.2,2L19.3,7.4z M1.9,4.8
            C2.2,4.5,2.6,4.4,3,4.4h6.8l-6.5,3H3c-0.4,0-0.7-0.2-1-0.4C1.7,6.7,1.5,6.3,1.5,5.9C1.5,5.5,1.7,5.1,1.9,4.8z M23.5,22.1
            c0,0.1-0.1,0.3-0.3,0.3H3c-0.8,0-1.5-0.7-1.5-1.5v-13c0.4,0.3,1,0.5,1.5,0.5h20.3c0.1,0,0.3,0.1,0.3,0.3v3.8h-4c-1.7,0-3,1.3-3,3
            s1.3,3,3,3h4V22.1z M24.5,17.1c0,0.1-0.1,0.3-0.3,0.3h-4.8c-1.1,0-2-0.9-2-2s0.9-2,2-2h4.8c0.1,0,0.3,0.1,0.3,0.3V17.1z"
        />
      </svg>
    </span>
  );
};

PosIconWalletCash.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
