import React from 'react';
import { useDispatch } from 'react-redux';
import { updateMenuItem } from '../../pages/Dashboard/action';
import {
  incrementItemQuantity,
  decrementItemQuantity,
} from '../../itemProcessor';

import { CounterBoxUI } from '../../UI/components';

export const ItemQuantity = ({
  itemQuantity,
  currentItem,
  onChange,
  isSecondary,
}) => {
  const dispatch = useDispatch();

  const handleOnChange = (type) => {
    const updatedMenuItem =
      type === '-'
        ? decrementItemQuantity(currentItem)
        : incrementItemQuantity(currentItem);

    if (onChange) {
      onChange(updatedMenuItem, type);
    } else {
      dispatch(updateMenuItem(updatedMenuItem));
    }
  };

  return (
    <CounterBoxUI
      isSecondary={isSecondary}
      value={itemQuantity}
      onChange={handleOnChange}
    />
  );
};
