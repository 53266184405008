export const isMacOSOrWindowsDevice = () => {
  if (navigator) {
    const isIOSOrAndroid =
      navigator.userAgent.includes('Mobi') ||
      navigator.userAgent.includes('iPad') ||
      navigator.maxTouchPoints;
    return (
      !isIOSOrAndroid &&
      (navigator.userAgent.includes('Mac') ||
        navigator.userAgent.includes('Win'))
    );
  }
  // not in a browser
  return false;
};
