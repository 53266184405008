import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMutation from '../customHooks/useMutation';

export default function useAssignDrivers({
  driverId,
  shiftId,
  restaurantId,
  storeId,
  deliveryCodes,
}) {
  const dispatch = useDispatch();
  const [isAssignDriverApiLoading, setIsAssignDriverApiLoading] =
    useState(false);
  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );
  const [addDeliveryOrder, { data }] = useMutation(
    `${nextOrderConfigURL}/addDelivery`,
    {}
  );

  useEffect(() => {
    data &&
      dispatch({
        type: 'resetSelectedOrdersAndDriver',
      });
  }, [data, dispatch]);

  const handleAssignDriver = async () => {
    setIsAssignDriverApiLoading(true);
    if (!driverId) {
      setIsAssignDriverApiLoading(false);
      return;
    }

    dispatch({
      type: 'toggleDeliveriesDriver',
      payload: driverId,
    });

    for (const code of deliveryCodes) {
      await addDeliveryOrder(
        JSON.stringify({
          driverId,
          deliveryCode: code, // o.deliveryCode,
          timestampStart: new Date().getTime(),
          shiftId,
          storeId,
          restaurantId,
        })
      );
    }

    // await Promise.all(
    //   deliveryCodes.map((code) => {
    //     return new Promise(async (resolve, reject) => {
    //       console.log('test');
    //       await addDeliveryOrder(
    //         JSON.stringify({
    //           driverId,
    //           deliveryCode: code, // o.deliveryCode,
    //           timestampStart: new Date().getTime(),
    //           shiftId,
    //           storeId,
    //           restaurantId,
    //         })
    //       );
    //       resolve(true);
    //     });
    //   })
    // );

    setIsAssignDriverApiLoading(false);
  };

  return { handleAssignDriver, isAssignDriverApiLoading };
}
