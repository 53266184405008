import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconPause = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-pause': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 17.5 19.5"
      >
        <title>Pause</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          strokeMiterlimit="10"
          d="M16,19.3h-3.5c-0.7,0-1.3-0.6-1.3-1.3V1.6c0-0.7,0.6-1.3,1.3-1.3H16c0.6,0,1.2,0.6,1.2,1.3V18
	C17.3,18.6,16.6,19.3,16,19.3z M12.5,1.3c-0.2,0-0.3,0.1-0.3,0.3V18c0,0.1,0.1,0.3,0.3,0.3H16c0.1,0,0.3-0.1,0.3-0.3V1.6
	c-0.1-0.2-0.2-0.3-0.3-0.3H12.5z M5.1,19.3H1.6c-0.7,0-1.3-0.6-1.3-1.2V1.6c0-0.7,0.6-1.3,1.3-1.3h3.5c0.6,0,1.2,0.6,1.2,1.3V18
	C6.3,18.6,5.6,19.3,5.1,19.3z M1.6,1.3c-0.2,0-0.3,0.1-0.3,0.3V18c0,0.1,0.1,0.2,0.3,0.2h3.5c0.1,0,0.2-0.1,0.2-0.2V1.6
	c0-0.2-0.1-0.3-0.2-0.3H1.6z"
        />
      </svg>
    </span>
  );
};

PosIconPause.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
