import { useCallback, useEffect } from 'react';
import { useVirtualKeyboardContext } from '../../../../context/VirtualKeyboardContext';
import { useDebounce } from 'hooks/useDebounce';
import * as actions from 'pages/Orders/newOrdersReducer';
import { useDispatch } from 'react-redux';


function SearchInput() {
  const dispatch = useDispatch();
  /**
   * @Note
   * the following would be needed if ever
   * have to switch the source of input's value
   * from local state to orders global context state
   */
    // const globalState = useOrdersGlobalState();
    // const searchInputValue = globalState?.filter?.searchInput || '';

  /** END OF NOTE */

  const {
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    inputNames,
    inputs
  } = useVirtualKeyboardContext();

  const inputName = inputNames.searchOrders;

  const handleFocus = useCallback(() => {
    setInputName(inputName);
    toggleKeyboard(true);
  }, [setInputName, toggleKeyboard, inputName]);

  const handleBlur = useCallback(() => {
    toggleKeyboard(false);
  }, [toggleKeyboard]);

  const onChange = useCallback((event) => {
    onChangeInput(inputName, event.target.value);
    // setSearchInputValue(event.target.value);
  }, [inputName, onChangeInput]);

  const [inputValue] = useDebounce(inputs[inputName], 100);

  useEffect(() => {
    dispatch(actions.setFilters({ search: inputValue }));
  }, [inputValue, dispatch])

  useEffect(() => () => onChangeInput(inputValue, ''), [])

  return (
    <div className="nav-bar-search-box">
    <input
      id={inputName}
      name={inputName}
      type="text"
      className="input-style"
      // name="search"
      value={getInputValue(inputName)}
      // value={searchInputValue}
      onChange={onChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      placeholder="Search"
    />
  </div>
  )
}

export default SearchInput;
