import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModal from 'hooks/useModal';
import { updateCurrentOrder } from 'pages/Dashboard/action';

function CustomerNameEffect() {
  const askNameAndMobileAtEnd = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.askNameAndMobileAtEnd
  );
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const [nameModalOpen, { openModal, closeModal }] =
    useModal('customerNameModal');
  const [phoneModalOpen] = useModal('customerPhoneModal');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentOrder?._id && !askNameAndMobileAtEnd) {
      if (
        (!currentOrder?.firstName || currentOrder?.firstName === '') &&
        !nameModalOpen &&
        !phoneModalOpen
      ) {
        openModal();
      } else if (currentOrder?.firstName && (nameModalOpen || phoneModalOpen)) {
        closeModal();
      }
    }

    if (askNameAndMobileAtEnd) {
      // Walkin
      if (currentOrder?.orderType === '0') {
        if (currentOrder?.firstName === '') {
          dispatch(
            updateCurrentOrder({
              ...currentOrder,
              firstName: 'xxxx',
              lastName: '',
              mobileNumber: 'xxx-xxx-xxx',
              userId: 'xxx-xxx-xxx',
            })
          );
        } else if (
          currentOrder?.firstName &&
          currentOrder?.firstName !== 'xxxx'
        ) {
          closeModal();
        }
      }
      // Pickup and Delivery
      if (currentOrder?.orderType === '1' || currentOrder?.orderType === '2') {
        // Open Name modal after you entered phoneNumber
        if (
          currentOrder?.firstName === 'xxxx' &&
          currentOrder?.mobileNumber &&
          currentOrder?.mobileNumber !== 'xxx-xxx-xxx'
        ) {
          openModal();
        } else if (
          currentOrder?.firstName &&
          currentOrder?.firstName !== 'xxxx'
        ) {
          closeModal();
        }
      }
    }
  }, [
    nameModalOpen,
    currentOrder?._id,
    currentOrder?.firstName,
    openModal,
    closeModal,
    phoneModalOpen,
    currentOrder?.isCustomerNameFetched,
  ]);

  return null;
}

export default CustomerNameEffect;
