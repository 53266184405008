import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconEmailInverse = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-email-inverse': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="32"
        viewBox="0 0 40 32"
      >
        <title>Message</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M1.981 2.366h35.657c0.219 0 0.396 0.178 0.396 0.401l-18.225 18.51-18.225-18.446c-0.003-0.019-0.005-0.041-0.005-0.064 0-0.1 0.037-0.192 0.097-0.263l-0 0.001c0.073-0.085 0.18-0.139 0.3-0.139 0.002 0 0.003 0 0.005 0h-0zM26.481 16.802l11.554-11.692v23.386zM14.263 17.925l4.992 5.059c0.143 0.146 0.342 0.236 0.562 0.236s0.419-0.090 0.562-0.236l0-0 4.977-5.044 11.554 11.709h-34.2zM1.585 28.495v-23.386l11.554 11.692zM37.638 0.762h-35.657c-1.094 0-1.981 0.899-1.981 2.005v26.466c0 1.108 0.887 2.005 1.981 2.005h35.657c1.094 0 1.981-0.899 1.981-2.005v-26.466c0-1.108-0.887-2.005-1.981-2.005z"
        />
      </svg>
    </span>
  );
};

PosIconEmailInverse.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
