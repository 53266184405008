import { prepareItemForOrder } from '../itemProcessor';
import orderEngine from '../orderEngine';

const updateHalfHalfToOrder = (
  currentOrder,
  items,
  allSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig
) => {
  const newCurrentOrder = {
    ...currentOrder,
    menuItems: currentOrder.menuItems.map((menuItem) => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (menuItem?.orderIndex === item?.orderIndex && menuItem.halfIndex === item.halfIndex) {
          return prepareItemForOrder(item, currentOrder);
        }
      }
      return { ...menuItem };
    }),
  };
  return orderEngine(
    newCurrentOrder,
    allSpecials,
    orderSetup,
    productSetup,
    allSuburbs,
    publicHolidays,
    storeConfig
  );
};

export default updateHalfHalfToOrder;
