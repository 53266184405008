import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  drawersSelector,
  pinPadsSelector,
  selectedDrawerIdSelector,
  selectedDrawerPrinterIdSelector,
  selectedPinPadIdSelector,
} from '../../../pages/Dashboard/selectors';
import {
  loadDrawers,
  loadPinpads,
  loadTerminalSettings,
} from '../../../pages/Dashboard/action';
import handleAddTerminalSetting from '../../../pages/Dashboard/apis/handleAddTerminalSetting';
import { isTerminalSettingsSelectorDisabledSelector } from '../../../pages/Cashier/selectors';
import {
  ButtonUI,
  ButtonGroupUI,
  HeaderUI,
  RadixSelectUI,
} from '../../../UI/components';
import { PlusIcon as IconPlus } from 'nexticons/outline';
import { LogoButton } from '../../LogoButton';
import { windcaveCountriesIdentifier } from '../../../util/utils';

export const CashierHeader = ({
  handlePiarTerminalModal,
  handleWindCaveUserModal,
  handleWindCavePairTerminalModal,
  toggleModal,
  setOpenWindCavePairTerminalModal,
}) => {
  const dispatch = useDispatch();
  const [radixSelectIdentifier, setRadixSelectIdentifier] = useState('');
  const windcaveUserlist = useSelector(
    (state) => state.dashboardReducer?.windcaveUserList || []
  );
  const pinPads = useSelector(pinPadsSelector);
  const drawers = useSelector(drawersSelector);
  const isTerminalSettingsSelectorDisabled = useSelector(
    isTerminalSettingsSelectorDisabledSelector
  );

  useEffect(() => {
    dispatch(loadPinpads());
    dispatch(loadDrawers());
    dispatch(loadTerminalSettings());
  }, [dispatch]);

  const storeConfig = useSelector(
    (state) => state.dashboardReducer?.storeConfig || {}
  );
  const selectedPinPadId = useSelector(selectedPinPadIdSelector);
  const selectedDrawerId = useSelector(selectedDrawerIdSelector);
  const selectedDrawerPrinterId = useSelector(selectedDrawerPrinterIdSelector);
  const selectedWindcaveUser = useSelector(
    (state) => state.dashboardReducer?.terminalSettings?.user || 'None'
  );

  //useEffect(() => {
  //  const currPinPadId = localStorage.getItem('currentPinId');
  //  const currDrawerId = localStorage.getItem('currentDrawerId');
  //  if (currPinPadId !== selectedPinPadId) {
  //    localStorage.setItem('currentPinId', selectedPinPadId)
  //  }
  //  if (currDrawerId !== selectedDrawerId) {
  //    localStorage.setItem('currentDrawerId', selectedDrawerId)
  //  }
  //}, [selectedPinPadId, selectedDrawerId])

  const handlePinPadChange = async (value) => {
    setRadixSelectIdentifier('terminal');
    if (value) {
      dispatch(handleAddTerminalSetting({ pinPadId: value }));
    }
  };

  const handleUserChange = async (value) => {
    setRadixSelectIdentifier('user');
    if (value) {
      dispatch(
        handleAddTerminalSetting({
          user: value,
        })
      );
    }
  };

  const handlePairTerminal = async (value) => {
    if (value === 'adyen') {
      handlePiarTerminalModal();
    } else if (value === 'windcave') {
      handleWindCavePairTerminalModal();
    }
  };

  const handleDrawerChange = async (value) => {
    setRadixSelectIdentifier('drawer');
    if (value) {
      dispatch(handleAddTerminalSetting({ drawerId: value }));
    }
  };

  const drawerOptions = useMemo(
    () => [
      ...(drawers?.map((drawer) => ({
        label: drawer.name,
        value: drawer._id,
      })) || []),
      ...[{ label: 'Manual', value: '000-000-000' }],
    ],
    [drawers]
  );

  const windcaveUserlistOptions = useMemo(
    () => [
      ...(windcaveUserlist?.map((windCaveUser) => ({
        label: windCaveUser?.user,
        value: windCaveUser?.user,
      })) || []),
      ...[{ label: 'None', value: 'None' }],
    ],
    [windcaveUserlist]
  );

  const pinPadOptions = useMemo(
    () => [
      ...(pinPads?.map((pinPad) => ({
        label: pinPad.terminalName,
        value: pinPad._id,
      })) || []),
      ...[{ label: 'Manual', value: '000-000-000' }],
    ],
    [pinPads]
  );

  const pairTerminalListOptions = useMemo(
    () => [
      { label: 'Adyen', value: 'adyen' },
      { label: 'Windcave', value: 'windcave' },
      { label: '', value: '' },
    ],
    [windcaveUserlist]
  );

  return (
    <HeaderUI>
      <LogoButton />
      <ButtonGroupUI
        gap="xl"
        style={{
          width: 'calc(100% - 280px)',
          padding: '10px 0px',
          justifyContent: 'flex-end',
        }}
      >
        <div className="bem-btn bem-btn--st-secondary bem-btn--r-m bem-btn--size-m bem-btn--fw-regular bem-btn--cursor-default">
          <span className="bem-btn__text">Select Drawer:</span>
          <RadixSelectUI
            options={drawerOptions}
            value={
              selectedDrawerPrinterId && selectedDrawerId
                ? selectedDrawerId
                : '000-000-000'
            }
            onValueChange={handleDrawerChange}
            disabled={
              isTerminalSettingsSelectorDisabled &&
              radixSelectIdentifier === 'drawer'
            }
          />
        </div>

        <div className="bem-btn bem-btn--st-secondary bem-btn--r-m bem-btn--size-m bem-btn--fw-regular bem-btn--cursor-default">
          <span className="bem-btn__text">Select Terminal:</span>
          <RadixSelectUI
            options={pinPadOptions}
            value={selectedPinPadId || '000-000-000'}
            onValueChange={handlePinPadChange}
            disabled={
              isTerminalSettingsSelectorDisabled &&
              radixSelectIdentifier === 'terminal'
            }
          />
        </div>

        {windcaveCountriesIdentifier?.includes(
          storeConfig?.countryIdentifier
        ) &&
          windcaveUserlistOptions.length > 1 && (
            <div className="bem-btn bem-btn--st-secondary bem-btn--r-m bem-btn--size-m bem-btn--fw-regular bem-btn--cursor-default">
              <span className="bem-btn__text">Select Windcave User:</span>
              <RadixSelectUI
                options={windcaveUserlistOptions}
                value={selectedWindcaveUser}
                onValueChange={handleUserChange}
                disabled={
                  isTerminalSettingsSelectorDisabled &&
                  radixSelectIdentifier === 'user'
                }
              />
            </div>
          )}

        {windcaveCountriesIdentifier?.includes(
          storeConfig?.countryIdentifier
        ) ? (
          <div className="bem-btn bem-btn--st-secondary bem-btn--r-m bem-btn--size-m bem-btn--fw-regular bem-btn--cursor-default">
            <span className="bem-btn__text">Pair Terminal</span>
            <RadixSelectUI
              options={pairTerminalListOptions}
              value={''}
              onValueChange={handlePairTerminal}
            />
          </div>
        ) : (
          <ButtonUI
            styleType="secondary"
            fontWeight="regular"
            rounded="m"
            icon={
              <IconPlus strokeWidth={3} style={{ width: 16, height: 16 }} />
            }
            onClick={(e) => handlePiarTerminalModal(e, true)}
            iconPosition="left"
          >
            Pair Terminal
          </ButtonUI>
        )}

        {windcaveCountriesIdentifier?.includes(
          storeConfig?.countryIdentifier
        ) && (
          <ButtonUI
            styleType="secondary"
            fontWeight="regular"
            rounded="m"
            icon={
              <IconPlus strokeWidth={3} style={{ width: 16, height: 16 }} />
            }
            onClick={(e) => handleWindCaveUserModal(e, true)}
            iconPosition="left"
          >
            Add Windcave User
          </ButtonUI>
        )}

        <ButtonUI
          styleType="secondary"
          fontWeight="regular"
          rounded="m"
          onClick={(e) => toggleModal(e, true)}
          icon={<IconPlus strokeWidth={3} style={{ width: 16, height: 16 }} />}
          iconPosition="left"
        >
          Add Drawer
        </ButtonUI>
      </ButtonGroupUI>
    </HeaderUI>
  );
};
