import AlertModal from '../AlertModal';

const DeleteDrawerAlertModal = ({
  modalStatus,
  toggleModal,
  drawerName = '',
  handleCancel = () => {},
  handleProceed = () => {},
}) => {
  return (
    <AlertModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      header={`Delete Drawer: ${drawerName}?`}
      description="Once deleted, you will not be able to recover it."
      proceedText="Yes"
      cancelText="No"
      handleCancel={handleCancel}
      handleProceed={handleProceed}
    />
  );
};

export default DeleteDrawerAlertModal;
