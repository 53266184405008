import React, { useContext, useState } from 'react';
import GeneralModal from '../GeneralModal';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import animationData from '../../assets/lotties/782-check-mark-success.json';
import Lottie from 'react-lottie';
import Context from '../../context';

export const CashAmountSelectedModal = ({ modalStatus, toggleModal }) => {
  let history = useHistory();
  const { state, dispatch } = useContext(Context);
  const [paymentAnim, setPaymentAnim] = useState(false);

  const handleTerminalAnimation = (event) => {
    let el = event.target.classList;

    el.add('active');

    setPaymentAnim(true);

    let timer1 = setTimeout(function () {
      let timer2 = setTimeout(function () {
        toggleModal(event, false);

        setTimeout(() => {
          if (state.orderBarType) {
            setPaymentAnim(false);
            dispatch({
              type: 'setOrderBarType',
              payload: '',
            });
            history.push('/');
          }
        }, 350);

        el.remove('active');

        clearTimeout(timer2);
      }, 700);

      clearTimeout(timer1);
    }, 2000);
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="center"
      effect="move"
      overlay=""
      boxWidth="768px"
      boxHeight="506px"
      className="payment-modal cash-amount-selected"
      closeBtn="inside"
    >
      <div className="payment-box">
        <div className="payment-box-header">
          <h2 className="payment-box-title">$16.00 change</h2>
          <p className="payment-box-desc">Out of $80.00</p>
        </div>
        <div className="payment-box-body">
          <div className="lottie-box">
            <Lottie
              options={defaultOptions}
              height={180}
              width={180}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => {},
                },
              ]}
            />
          </div>
          <div className="payment-box-methods">
            <div className="payment-box-method-wrap">
              <Button
                title="Pay Now"
                onClick={handleTerminalAnimation}
                className="finish-sale"
              >
                <span className="payment-method-name green">Finish Sale</span>
              </Button>
            </div>
            <div className="payment-box-method-wrap">
              <Button
                title="Print Bill"
                onClick={handleTerminalAnimation}
                className="print-bill"
              >
                <span className="payment-method-name blue">Print Bill</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </GeneralModal>
  );
};

CashAmountSelectedModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};
