import React from 'react';
import useDarkMode from 'use-dark-mode';
import ContentLoader from 'react-content-loader';

/**
 * should be greater than the width that you want to support
 * doesn't matter how bigger than that width because you anyway
 * will wrap this in a container with width/max-width and paddings
 */
const width = 400;
const height = 68;

const OrderSideBarShimmer = (props) => {
  const { foregroundColor, backgroundColor } = props;

  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={'100%'}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={backgroundColor || '#f3f3f3'}
      foregroundColor={foregroundColor || '#ecebeb'}
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width={'100%'} height={'100%'} />
    </ContentLoader>
  );
};

export default OrderSideBarShimmer;

export function OrdersSideBardCardRepeatShimmer({ repeat }) {
  const darkMode = useDarkMode(false);

  if (!repeat) {
    return null;
  }

  return (
    <div className="details-list">
      {new Array(repeat).fill(1).map((_, index) => {
        const isEven = index % 2 === 0;
        const isOdd = index % 2 !== 0;
        const conditionalProps = {};
        const evenItemColor = darkMode.value ? '#1c1c1e' : '#f3f3f3';
        const evenItemForegroundColor = darkMode.value ? '#2f2f2f' : '#ecebeb';
        const oddItemColor = darkMode.value ? '#000' : '#fff';
        const oddItemForegroundColor = darkMode.value ? '#000' : '#fff';
        
        if (isEven) {
          conditionalProps.backgroundColor = evenItemColor;
          conditionalProps.foregroundColor = evenItemForegroundColor;
        }

        if (isOdd) {
          conditionalProps.backgroundColor = oddItemColor;
          conditionalProps.foregroundColor = oddItemForegroundColor;
        }

        return (
          <li style={{ display: 'flex', maxHeight: '84px' }} key={index}>
            <OrderSideBarShimmer {...conditionalProps} />
          </li>
        );
      })}
    </div>
  );
}
