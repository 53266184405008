import React from 'react';

export const UberIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // xlinkHref="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      version="1.1"
      id="Layer_2"
      x="0px"
      y="0px"
      width="43px"
      height="20px"
      viewBox="0 0 480 230"
      // style="enable-background:new 0 0 480 230;"
    >
      <g>
        <path d="M38.5,5h403C460,5,475,20,475,38.5v153c0,18.5-15,33.5-33.5,33.5h-403C20,225,5,210,5,191.5v-153C5,20,20,5,38.5,5z" />
        <g>
          <path
            fill="#FFFFFF"
            d="M81.8,60.3h16.1c0,0,0,55.7,0,68s8.4,27.6,25.7,27.6s27.4-13.9,27.4-25.7c0-11.8,0-69.9,0-69.9h16.3v107.8    h-16.1v-9.9c0,0-10.1,11.8-28.5,11.8s-40.9-14.8-40.9-39.4S81.8,60.3,81.8,60.3z"
          />
          <path
            fill="#FFFFFF"
            d="M224,87.8c-11.2,0-21.3,4.5-28.7,11.7V60.3h-15.7V168h15.7v-9.8c7.4,7.2,17.5,11.7,28.7,11.7    c22.7,0,41-18.4,41-41.1C265.1,106.2,246.7,87.8,224,87.8z M222,156.1c-13.1,0-24-9.2-26.6-21.5c-0.4-1.8-0.6-3.7-0.6-5.7    s0.2-3.8,0.6-5.6c2.6-12.3,13.5-21.5,26.6-21.5c15,0,27.2,12.2,27.2,27.2C249.2,143.9,237,156.1,222,156.1z"
          />
          <path
            fill="#FFFFFF"
            d="M312.4,156.1c-13.9,0-25.3-9.8-26.7-22.2h64.8c0-1.6-0.1-3.3-0.1-5c0-2.7-0.2-5.3-0.7-7.9    c-3.4-18.9-18.7-33.1-38.7-33.1c-22.7,0-41,18.4-41,41c0,22.7,18.4,41.1,41,41.1c13.6,0,27.5-6.5,35.1-16.7l-11.1-8.8    C330.4,151.4,322,156.1,312.4,156.1z M310.3,101.7c9.3,0,17.5,5.2,21.7,12.8c1.1,2,1.9,4.2,2.4,6.5h-48.2    C288.7,109.9,298.5,101.7,310.3,101.7z"
          />
          <path
            fill="#FFFFFF"
            d="M398.3,88.5v14.6h-7.5c-8.1,0-16.3,7.1-16.3,17.8V168h-15.9V89.4h15.9v9c3.8-5.6,10.7-9.8,18.9-9.8    C396.3,88.5,398.3,88.5,398.3,88.5z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
