import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconLeftChevron = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-left-chevron': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      {/*  <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Слой_1"
        x="0px"
        y="0px"
        viewBox="0 0 47.8 84.8"
      >
        <g id="Regular-M" transform="matrix(1 0 0 1 1413.52 1126)">
          <path
            fill={iconColor ? iconColor : '#000'}
            stroke={iconColor ? iconColor : '#000'}
            strokeWidth={lineThickness ? lineThickness : 0}
            d="M-1413.5-1083.6c0,1.2,0.4,2.3,1.4,3.2l38.7,37.8c0.8,0.9,1.9,1.3,3.2,1.3c2.5,0,4.5-1.9,4.5-4.4c0-1.3-0.5-2.3-1.3-3.2   l-35.5-34.8l35.5-34.8c0.8-0.8,1.3-2,1.3-3.2c0-2.5-2-4.4-4.5-4.4c-1.3,0-2.3,0.4-3.2,1.3l-38.7,37.9   C-1413.1-1086-1413.5-1084.8-1413.5-1083.6z"
          />
        </g>
      </svg>*/}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="23"
        viewBox="0 0 14 23"
      >
        <g>
          <g transform="rotate(90 7 11.5)">
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M6.99 17a.544.544 0 0 1-.372-.152L-4 6.719-3.246 6 7 15.774 17.246 6l.754.72L7.382 16.847A.544.544 0 0 1 6.99 17z"
            />
            <path
              fill="none"
              stroke={iconColor ? iconColor : '#000'}
              strokeMiterlimit="20"
              strokeWidth={lineThickness ? lineThickness * 0.8 : 0.8}
              d="M6.99 17a.544.544 0 0 1-.372-.152L-4 6.719v0L-3.246 6v0L7 15.774v0L17.246 6v0l.754.72v0L7.382 16.847A.544.544 0 0 1 6.99 17z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconLeftChevron.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
