import clsx from 'clsx';
import { PosIconClock } from '../../assets/icons/PosIconClock';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentSpecialsItem } from '../../pages/Dashboard/action';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const getAllDays = (obj) => {
  const daysFlag = [];
  for (const [key, value] of Object.entries(obj)) {
    const dayIndexOf = value ? days.indexOf(key) : -1;
    if (dayIndexOf > -1) {
      daysFlag.push(dayIndexOf);
    }
  }
  return daysFlag.sort((a, b) => a - b);
};

let animCls = clsx({
  'card-item': true,
  'card-item-half': false,
});

const SpecialsItem = ({ item, parentPage }) => {
  const dispatch = useDispatch();
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const offerDays = getAllDays(item);
  const specialsDate = useMemo(() => {
    if (item) {
      const daysEnum = ['SUN', 'MON', 'TUES', 'WED', 'THURS', 'FRI', 'SAT'];
      const offerLength = offerDays.length;
      if (!offerDays.length) {
        return '';
      }
      if (offerLength === 1) {
        return `${daysEnum[offerDays[0]]}`;
      }
      if (offerLength === 2) {
        return `${daysEnum[offerDays[0]]}, ${daysEnum[offerDays[1]]}`;
      }
      if (offerLength !== daysEnum.length) {
        let last = offerDays[0];
        let start = 0;
        for (let i = 1; i < offerLength; i++) {
          if (offerDays[i] - last > 1) {
            start = offerDays[i];
            break;
          } else {
            last = offerDays[i];
          }
        }
        return `${daysEnum[start]} To ${daysEnum[last]}`;
      }
      return `${daysEnum[0]} To ${daysEnum[6]}`;
    }
    return '';
  }, [item, offerDays]);

  const handleClick = () => {
    dispatch(
      updateCurrentSpecialsItem({
        ...item,
        specialsDate,
      })
    );
  };

  return (
    <div className={animCls} key={item._id}>
      <Link
        to={`${parentPage}/specials/${item._id}`}
        className=""
        title={item.name}
        onClick={(event) => handleClick(event, item)}
      >
        <span className="card-inner-box">
          <span className="card-img-wrap">
            <img src={item.urlS3} alt={item.name} />
            {offerDays.length < 7 && (
              <span className="card-special-date">
                <PosIconClock mainColor="#fff" />
                {specialsDate}
              </span>
            )}
            {item.cost ? (
              <span className="card-special-price">
                {getAmountWithCountryCurrency({
                  amount: Number(item.cost),
                  locale: `en-${storeCountryIdentifier}`,
                  currencyCode,
                })}
              </span>
            ) : (
              ''
            )}
            <span className="card-special-name">{item.name}</span>
          </span>
          <span className="card-name">
            {item.displayName || item.description}
          </span>
        </span>
      </Link>
    </div>
  );
};

export default SpecialsItem;
