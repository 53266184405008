import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconCashRegister = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-cash-register': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M15 7.527h4v.999h-4zm5 1.498V7.028c0-.3-.2-.5-.5-.5h-5c-.3 0-.5.2-.5.5v1.997c0 .3.2.499.5.499h5c.3 0 .5-.2.5-.5zM8 12.519H5v.998h3zm4 0H9v.998h3zm4 0h-3v.998h3zm4 0h-3v.998h3zM8 14.515H4v.989h4zm4 0H9v.989h3zm4 0h-3v.989h3zm5 0h-4v.989h4zm-4 4.992H8v.998h9zM7 2.735l.1.1c.2.3.6.3.8 0l.6-.898.6.898c.1.1.3.2.4.2.2 0 .3-.1.4-.2l.1-.1V6.53H7zM4 5.73c0-.1.1-.2.2-.2H6v.999H5v.998h7V6.53h-1V5.53h9.8c.1 0 .2.1.2.2v4.791H4zm-.2 5.79h17.4l2.5 4.992H1.3zm20.1 5.99v4.792c0 .1-.1.2-.2.2H1.2c-.1 0-.2-.1-.2-.2V17.51zm1.1 4.592v-5.19c0-.1-.1-.1-.1-.2l-2.9-5.89V5.73c0-.599-.6-1.198-1.2-1.198H11V1.038c0-.2-.1-.399-.2-.399-.2-.1-.5 0-.6.2l-.6.798L9 .74c-.2-.3-.6-.3-.8 0l-.6.898L7 .74c-.2-.2-.3-.3-.6-.2-.3.1-.4.3-.4.5v3.493H4.2c-.6 0-1.2.6-1.2 1.198v5.191l-2.9 5.99c-.1 0-.1.1-.1.2v5.191c0 .599.6 1.198 1.2 1.198h22.6c.6 0 1.2-.599 1.2-1.398z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconCashRegister.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
