import GeneralModal from 'modals/GeneralModal/index-new';
import { useSelector } from 'react-redux';
import { BasePartUI, ButtonUI, InputUI } from '../../../UI/components';
import { useVirtualKeyboardContext } from 'context/VirtualKeyboardContext';
import { useEffect } from 'react';

const CustomVoidedReasonTextModal = ({
  modalStatus,
  onModalClose,
  customVoidedReason,
  setCustomVoidedReason,
  handleSubmit,
}) => {
  const {
    keyboardVisibility,
    toggleKeyboard,
    onChangeInput,
    setInputName,
    onInitInput,
    getInputValue,
  } = useVirtualKeyboardContext();

  const virtualKeyboard = useSelector(
    (state) => state.dashboardReducer.virtualKeyboard
  );

  const customVoidedReasonText = getInputValue('customVoidedReason');

  useEffect(() => {
    if (customVoidedReasonText && virtualKeyboard) {
      onInitInput('customVoidedReason', customVoidedReasonText);
      setCustomVoidedReason(customVoidedReasonText);
    }
  }, [customVoidedReasonText]);

  const handleFocus = () => {
    setInputName('customVoidedReason');
    toggleKeyboard(true);
  };
  return (
    <GeneralModal
      className="bem-add-driver-modal"
      modalStatus={modalStatus}
      toggleModal={onModalClose}
      position={'center'}
      effect="fade"
      overlay={'default'}
      boxWidth="435px"
      boxHeight="306px"
      closeBtn="outside"
      isResponsiveHeight
      showCloseBtn={false}
      keyboardVisibility={keyboardVisibility}
    >
      <div className="general-modal__inner">
        <div className="general-modal__header general-modal__header--shadow ">
          <div className="general-modal__title-box">
            <h3 className="general-modal__title general-modal__title--size-small">
              Others
            </h3>
          </div>
        </div>

        <div className="other-input-container">
          <div className="input-label-text">
            <p>Enter Reason</p>
          </div>
          <InputUI
            onFocus={handleFocus}
            name="customVoidedReason"
            value={customVoidedReason}
            onChange={(event) => {
              setCustomVoidedReason(event.target.value);
              onChangeInput(event.target.value);
            }}
          />
        </div>

        <BasePartUI.Footer>
          <ButtonUI
            type="submit"
            style={{ height: 55 }}
            sizeType="xl"
            rounded="none"
            fullWidth
            onClick={onModalClose}
            disabled={!customVoidedReason}
          >
            Submit
          </ButtonUI>
        </BasePartUI.Footer>
      </div>
    </GeneralModal>
  );
};

export default CustomVoidedReasonTextModal;
