import React, { useRef } from 'react';
import { useVirtual } from 'react-virtual';
import clsx from 'clsx';
import useDarkMode from 'use-dark-mode';

const VirtualList = ({
  data = [],
  renderListItem = (itemId) => <>{itemId}</>,
  selectedItemId = null,
  estimateSize = null,
}) => {
  const darkMode = useDarkMode(false);
  const listRef = useRef();

  const rowVirtualizer = useVirtual({
    size: data.length,
    parentRef: listRef,
    ...(estimateSize ? { estimateSize } : {}),
  });

  return (
    <div ref={listRef} className="nav-bar-details">
      <div
        className="details-list"
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => (
          <div
            key={virtualRow.index}
            ref={virtualRow.measureRef}
            className={clsx(
              virtualRow.index % 2
                ? 'ListItemOdd'
                : 'ListItemEven even-index-child',
              data[virtualRow.index] === selectedItemId ? 'active' : ''
            )}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${virtualRow.start}px)`,
              ...(darkMode.value &&
                data[virtualRow.index] !== selectedItemId && {
                  background:
                    virtualRow.index % 2 === 0 ? '#010001' : '#1c1c1e',
                }),
            }}
          >
            {renderListItem({ itemId: data[virtualRow.index] })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VirtualList;
