import React from 'react';
import { DeliveryOrderBatchesMap } from './content/DeliveryOrderBatchesMap';
import { DeliveryUnassignedOrderBatchesMap } from './content/DeliveryUnassignedOrderBatchesMap';

export default function BatchesMapView({ selectedTabCode }) {
  switch (selectedTabCode) {
    case 'unassigned':
      return <DeliveryUnassignedOrderBatchesMap />;
    case 'batches':
      return <DeliveryOrderBatchesMap />;
    default:
      return null;
  }
}
