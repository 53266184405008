import React from 'react';

export const RemoveIngredient = ({
  _id,
  name,
  ingredientName,
  isRemoved,
  actionType,
  handleChange,
}) => {
  return (
    <div
      onClick={() => handleChange(_id)}
      className="item-card item-card--remove"
    >
      <input
        id={`${actionType}-${_id}`}
        type="checkbox"
        name={`${actionType}-${_id}`}
        value={_id}
        checked={isRemoved}
        onChange={() => {}}
      />
      <label htmlFor={'item_' + _id}>
        <div className="inner-box">
          <h3 className="card-title">
            {name ? name : ingredientName ? ingredientName : ''}
          </h3>
        </div>
      </label>
    </div>
  );
};
