import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleSpecialsFourQuarterSelectedSize,
  toggleSpecialsFourQuarterEditMode,
  updateCurrentSpecialsCategoryItem,
  resetSpecialsCurrentFourQuarterData,
} from '../../pages/Dashboard/action';
// import HalfHalfView from "../HalfHalfView/HalfHalfView";
import FourQuarterView from '../FourQuarterView/FourQuarterView';
import useCategories from 'hooks/useCategories';

const SpecialsFourQuarterView = ({ parentPage }) => {
  const categories = useCategories();
  const dispatch = useDispatch();
  let history = useHistory();
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const currentSpecialsItem = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem
  );
  const courseAutoAssignmentEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.courseAutoAssignmentEnabled
  );
  const { categoryIndex, specialsId } = useParams();
  const currentFourQuarterData =
    currentSpecialsItem.selectedCategories[categoryIndex]
      ?.currentFourQuarterData || {};
  const handleClickBtn = () => {
    dispatch(resetSpecialsCurrentFourQuarterData());
    history.push(`${parentPage}/order`);
  };

  const handleItemClick = (_event, item) => {
    const isEditQuarterItem = Boolean(
      currentFourQuarterData?.fourQuarters?.find(
        (cat) => cat?.fourQuarterIndex === item.fourQuarterIndex
      )?.currentMenuItem
    );

    if (isEditQuarterItem)
      dispatch(
        toggleSpecialsFourQuarterEditMode(
          Number(categoryIndex),
          item.fourQuarterIndex
        )
      );
    history.push(
      `${parentPage}/specials/${specialsId}/${categoryIndex}/four-quarter/${item.fourQuarterIndex}`
    );
  };
  const handleAddToSpecials = () => {
    let currentMenuItems =
      currentFourQuarterData.fourQuarters.map((cat) => {
        return { ...cat.currentMenuItem, isSpecialItem: true };
      }) || [];

    const selectedCategory = categories.filter((category) =>
      currentMenuItems?.some((item) => item.categoryId === category._id)
    );

    if (
      selectedCategory?.length > 0 &&
      selectedCategory?.some((cat) => !!cat?.courseId) &&
      !currentMenuItems?.every((item) => !!item.courseId)
    ) {
      const selectedCategoryWithCourse = selectedCategory?.find(
        (cat) => !!cat?.courseId
      );
      const menutItemsWithCourse = currentMenuItems?.map((item) => {
        return {
          ...item,
          courseId: selectedCategoryWithCourse?.courseId,
          courseName: selectedCategoryWithCourse?.courseName,
        };
      });

      currentMenuItems = menutItemsWithCourse;
    }

    // Remove Course Id If orderType is not equal to dine-in
    if (currentOrder?.orderType !== '3' || !courseAutoAssignmentEnabled) {
      currentMenuItems?.forEach((item) => {
        delete item?.courseId;
        delete item?.courseName;
      });
    }

    dispatch(
      updateCurrentSpecialsCategoryItem(parseInt(parseInt(categoryIndex)), [
        ...currentMenuItems,
      ])
    );
    dispatch(resetSpecialsCurrentFourQuarterData());
    history.push(`${parentPage}/specials/${specialsId}`);
  };

  const handleSizeClick = (size) => {
    !size.isDefault && dispatch(toggleSpecialsFourQuarterSelectedSize(size));
  };

  return (
    <FourQuarterView
      fourQuarterData={currentFourQuarterData}
      onClose={handleClickBtn}
      onSizeClick={handleSizeClick}
      onSubmit={handleAddToSpecials}
      onSectionClick={handleItemClick}
      submitLabel="Add to Specials"
      parentPage={parentPage}
      toggleItemEditMode={(fourQuarterIndex) =>
        dispatch(
          toggleSpecialsFourQuarterEditMode(
            Number(categoryIndex),
            fourQuarterIndex
          )
        )
      }
    />
  );
};

export default SpecialsFourQuarterView;
