import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as ac from 'reducers/modals';

function useModal(modalName) {
  const dispatch = useDispatch();
  const modalStatus = useSelector(s => s.modalsReducer[`${modalName}Open`]);
  const setState = useCallback((payload) => dispatch(ac.setState(payload)), [dispatch]);
  const toggleModal = useCallback(() => dispatch(ac.toggleModal(modalName)), [dispatch, modalName]);
  const closeModal = useCallback(() => dispatch(ac.closeModal(modalName)), [dispatch, modalName]);
  const openModal = useCallback(() => dispatch(ac.openModal(modalName)), [dispatch, modalName]);

  return [modalStatus, { setState, toggleModal, closeModal, openModal }];
}

export default useModal;
