const calculateDeliveryFee = (
  currentOrder,
  orderSetup,
  payableAmount,
  allSuburbs
) => {
  if (
    orderSetup?.isDeliveryFreeOver &&
    Number(orderSetup?.deliveryFreeOver) < payableAmount
  ) {
    return {
      isDeliveryFeeApplicable: false,
    };
  } else {
    if (orderSetup.useRadiusBasedDeliveryAreaCalulation) {
      if (
        orderSetup?.isFirstDeliveryFree &&
        currentOrder?.totalDeliveryOrders &&
        Number(currentOrder?.totalDeliveryOrders) <= 0
      ) {
        return {
          isDeliveryFeeApplicable: false,
        };
      }
      return { isDeliveryFeeApplicable: true };
    }
    if (allSuburbs && currentOrder.suburbId) {
      let suburb = allSuburbs.find((s) => {
        return s._id === currentOrder.suburbId;
      });
      if (suburb) {
        if (
          orderSetup &&
          orderSetup.isFirstDeliveryFree &&
          currentOrder.totalDeliveryOrders &&
          Number(currentOrder.totalDeliveryOrders) <= 0
        ) {
          return {
            isDeliveryFeeApplicable: false,
          };
        }
        return {
          isDeliveryFeeApplicable: true,
          suburb,
        };
      }
      return {
        isDeliveryFeeApplicable: false,
      };
    }
    if(currentOrder.proccedOutsideDeliveryArea){
      return {
        isDeliveryFeeApplicable: true,
      };
    }
    return {
      isDeliveryFeeApplicable: false,
    };
  }
};

export default calculateDeliveryFee;
