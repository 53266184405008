import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import { PosIconDiscount } from '../../assets/icons/PosIconDiscount';
import { PosIconSilverware } from '../../assets/icons/PosIconSilverware';
import { PosIconWriteMessage } from '../../assets/icons/PosIconWriteMessage';
import JaneAppleSeed from '../../assets/img/Jane_Appleseed.png';
import useCurrentlyInCustomiseSection from '../../customHooks/useCurrentlyInCustomiseSection';
import useSelectedIngredientsCount from '../../customHooks/useSelectedIngredientsCount';
import ItemNotesModal from '../../modals/ItemNotesModal';
import {
  setCurrentHalfHalfNotes,
  setCurrentMenuItemNotes,
  setIsSingleHalfFlow,
  setOpenItemNotesModal,
} from '../../pages/Dashboard/action';
import {
  currentMenuItemNotesSelector,
  isCurrentHalfHalfDataEmptySelector,
  openItemNotesModalSelector,
} from '../../pages/Dashboard/selectors';
import { showCustomization } from '../../util/showCustomization';
import { CountBubble } from '../CountBubble';
import { SupportNav } from '../SupportNav';
import { toggleSpecialsHalfHalfEditMode } from '../../pages/Dashboard/action';

const showDiscountSection = () => {
  return false;
};

const BottomNav = ({ currentItem, basePath, isKiosk }) => {
  const selectedCustomizationCount = useSelectedIngredientsCount(currentItem);
  const staffMember =
    useSelector((state) => state.dashboardReducer.activeStaffMember) || {};
  const showPizzaActionButton = useSelector(
    (state) => state.dashboardReducer.showPizzaActionButton
  );
  const currentlyInCustomisationSection = useCurrentlyInCustomiseSection();
  const dispatch = useDispatch();

  const isCurrentHalfHalfDataEmpty = useSelector(
    isCurrentHalfHalfDataEmptySelector
  );
  const activeCategoryIndex = useSelector(
    (state) => state.dashboardReducer.activeCategoryIndex
  );
  const currentMenuItemNotes = useSelector(currentMenuItemNotesSelector);

  const openItemNotesModal = useSelector(openItemNotesModalSelector);

  const [notesToShow, setNotesToShow] = useState('');

  // useEffect(() => {
  // if (!isCurrentMenuItemEmpty && notesToShow !== currentMenuItemNotes) {
  // setNotesToShow(currentMenuItemNotes);
  // } else if (!isCurrentHalfHalfDataEmpty && notesToShow !== currentHalfHalfNotes) {
  // setNotesToShow(currentHalfHalfNotes);
  // } else if (notesToShow !== '') {
  // setNotesToShow('');
  // }
  // }, [
  // notesToShow,
  // currentMenuItemNotes,
  // currentHalfHalfNotes,
  // isCurrentMenuItemEmpty,
  // isCurrentHalfHalfDataEmpty,
  // ]);

  const handleNotesSubmission = (newNotes) => {
    if (!isCurrentHalfHalfDataEmpty) {
      dispatch(setCurrentHalfHalfNotes(newNotes || ''));
    } else {
      dispatch(setCurrentMenuItemNotes(newNotes || ''));
    }
  };

  const handleItemNotesModal = (itemNotesModalStatus) => {
    dispatch(setOpenItemNotesModal(itemNotesModalStatus));
  };

  return (
    <>
      <div className="bottom-nav-box">
        <nav className="category-nav flex-style">
          <Switch>
            <Route path={basePath + '/menuitem/:itemId'}>
              {showCustomization(currentItem) &&
                !currentlyInCustomisationSection && (
                  <Link
                    to={`${basePath}/menuitem/${currentItem._id}/customization`}
                    className="nav-link with-icon"
                    onClick={() => {
                      activeCategoryIndex &&
                        dispatch(
                          toggleSpecialsHalfHalfEditMode(
                            Number(activeCategoryIndex),
                            currentItem?.halfIndex
                          )
                        );
                      showPizzaActionButton &&
                        dispatch(setIsSingleHalfFlow(true));
                    }}
                  >
                    <span className="nav-title">Customise</span>
                    {selectedCustomizationCount ? (
                      <CountBubble quantity={selectedCustomizationCount} />
                    ) : (
                      <span className="img-box">
                        <PosIconSilverware mainColor="#484B4C" />
                      </span>
                    )}
                  </Link>
                )}
              {!currentlyInCustomisationSection && (
                <div
                  className="nav-link item-notes with-icon"
                  onClick={() => {
                    handleItemNotesModal(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <span className="nav-title">
                    {currentMenuItemNotes || 'Item Notes'}
                  </span>
                  <span className="img-box">
                    <PosIconWriteMessage mainColor="#484B4C" />
                  </span>
                </div>
              )}
              {showDiscountSection() && (
                <Link
                  to={`${basePath}/menuitem/${currentItem._id}/discount`}
                  className="nav-link with-icon"
                >
                  <span>Discount</span>
                  <span className="img-box">
                    <PosIconDiscount mainColor="#484B4C" />
                  </span>
                </Link>
              )}
            </Route>
            <Route path={basePath + '/menuitem/:itemId/customization'}>
              {showDiscountSection() && (
                <Link
                  to={`${basePath}/menuitem/${currentItem._id}/discount`}
                  className="nav-link with-icon"
                >
                  <span>Discount</span>
                  <span className="img-box">
                    <PosIconDiscount mainColor="#484B4C" />
                  </span>
                </Link>
              )}
            </Route>
          </Switch>
        </nav>

        {!isKiosk && (
          <SupportNav staffMember={{ ...staffMember, imgSrc: JaneAppleSeed }} />
        )}
      </div>
      {openItemNotesModal && (
        <ItemNotesModal
          itemNotes={notesToShow}
          modalStatus={openItemNotesModal}
          handleNotesSubmission={handleNotesSubmission}
          toggleModal={() => {
            handleItemNotesModal(false);
          }}
        />
      )}
    </>
  );
};

export default BottomNav;
