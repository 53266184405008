/* routes */
export const index_route = '/';

export const login_route = '/login';
export const orders_route = '/orders';
export const menu_route = '/menu';
export const cashier_route = '/cashier';
export const settle_route = '/settle';
export const settings_route = '/settings';
export const reports_route = '/reports';
export const drivers_route = '/drivers';
export const custom_icons_route = '/custom-icons';
export const reviews_route = '/reviews';
export const music_route = '/music';
export const table_arrangement_route = '/table-arrangement';
export const pickup_route = '/pickup';
export const delivery_route = '/delivery';
export const dinein_route = '/dinein';
export const money_count_route = '/money-count';
export const markup_route = '/markup';
export const open_cash_drawer_route = '/open-cash-drawer';

// walkin
export const walkin_route = '/walkin';
export const walkin_order_route = `${walkin_route}/order`;
export const walkin_customer_route = `${walkin_route}/customer`;
export const walkin_customer_name_route = `${walkin_customer_route}/name`;
export const walkin_specials_route = `${walkin_route}/specials`;

// Kiosk
export const kiosk_route = '/kiosk';
export const kiosk_continue_route = '/kiosk/continue-order';
export const kiosk_order_route = `${kiosk_route}/order`;
export const kiosk_customer_route = `${kiosk_route}/customer`;
export const kiosk_customer_name_route = `${kiosk_customer_route}/name`;
export const kiosk_specials_route = `${kiosk_route}/specials`;

// Kiosk
export const drive_thru_route = '/drivethru';
export const drive_thru_order_route = `${drive_thru_route}/order`;
export const drive_thru_customer_route = `${drive_thru_route}/customer`;
export const drive_thru_customer_name_route = `${drive_thru_customer_route}/name`;
export const drive_thru_specials_route = `${drive_thru_route}/specials`;


// shifts
export const shifts_route = '/shifts';
export const shifts_clock_in_route = `${shifts_route}/clock-in`;
export const shifts_select_role_route = `${shifts_route}/select-role`;
export const shifts_confirmation_route = `${shifts_route}/confirmation`;
export const shifts_clocked_in_route = `${shifts_route}/clocked-in`;
export const shifts_view_shifts_route = `${shifts_route}/view-shifts`;
