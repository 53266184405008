import clsx from 'clsx';
import Button from 'components/Button';
// import { ITEM_CUSTOMIZATION_OPTIONS } from 'constants/order-flow';
import useOrder from 'hooks/useOrder';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import addItemToOrder from '../../cartProcessor/addItemToOrder';
import updateItemToOrder from '../../cartProcessor/updateItemToOrder';
// import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import useGoBackToPathName from '../../customHooks/useGoBackToPathName';
import { useSelectedIngredients } from '../../customHooks/useSelectedIngredients';
import useSpecialsCategoryIndex from '../../customHooks/useSpecialsCategoryIndex';
import {
  // getCustomizationTitle,
  incrementItemQuantitySingleSize,
  prepareItemForOrder,
} from '../../itemProcessor';
import {
  resetMenuItem,
  updateCurrentFourQuarterItem,
  updateCurrentHalfHalfItem,
  updateCurrentOrder,
  updateCurrentSpecialsCategoryItem,
  updateSpecialsCurrentHalfHalfItem,
  setHalfHalfMenuItems,
  updateCurrentHalfHalfData,
  resetCurrentHalfHalfData,
  updateMenuItem,
  setIsSingleHalfFlow,
} from '../../pages/Dashboard/action';
import {
  activeSpecialsSelector,
  orderSetupSelector,
  productSetupSelector,
  publicHolidaysSelector,
  // specialsSelector,
  storeConfigSelector,
  suburbsSelector,
} from '../../pages/Dashboard/selectors';
import { SearchIngredients } from '../SearchIngredients';
import { AddIngredients } from './AddIngredients';
import { ItemDetails } from './ItemDetails';
import { RemoveIngredients } from './RemoveIngredients';

const map = { add: 'selectedExtraIngredients', remove: 'selectedIngredients' };

export const ItemViewCustomise = ({ parentPage }) => {
  const [{ selectedCategoryId }] = useOrder();
  const [showActionButtons, setShowActionButton] = useState(false);
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  // const specials = useSelector(specialsSelector);
  const activeSpecials = useSelector(activeSpecialsSelector);
  const orderSetup = useSelector(orderSetupSelector);
  const publicHolidays = useSelector(publicHolidaysSelector);
  const suburbs = useSelector(suburbsSelector);
  const productSetup = useSelector(productSetupSelector);
  const storeConfig = useSelector(storeConfigSelector);
  const currentItem = useSelector((s) => ({
    ...s.dashboardReducer.currentMenuItem,
    iteration: currentOrder.iteration,
  }));

  const goBackTo = useGoBackToPathName(currentItem, parentPage);

  // const { onInitInput } = useVirtualKeyboardContext();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [pizzaStatus, setPizzaStatus] = useState(
    currentItem?.pizzaStatus || 'whole'
  );
  const isSingleHalfFlow = useSelector(
    (state) => state.dashboardReducer.isSingleHalfFlow
  );

  const currentSpecialsItemId = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem?._id
  );
  const currentSpecialsCategoryIndex = useSpecialsCategoryIndex();
  const isNotSpecials =
    currentSpecialsCategoryIndex === -1 || selectedCategoryId !== 'specials';
  const currentHalfHalfData = useSelector(
    (state) => state.dashboardReducer.currentHalfHalfData
  );
  const currentFourQuarterData = useSelector(
    (state) => state.dashboardReducer.currentFourQuarterData
  );
  const halfHalfMenuItems = useSelector(
    (state) => state.dashboardReducer.halfHalfMenuItems
  );
  const halfIndex = currentHalfHalfData?.halfHalfs?.find(
    (h) => h.isInEditMode
  )?.halfIndex;
  const fourQuarterIndex = currentFourQuarterData?.fourQuarters?.find(
    (f) => f.isInEditMode
  )?.fourQuarterIndex;

  const customizationStatus = useSelectedIngredients(currentItem);
  const { customizeSlug } = useParams();

  const [actionType] = useState(customizeSlug);
  // const [title, setTitle] = useState(
  //   getCustomizationTitle(ITEM_CUSTOMIZATION_OPTIONS, customizeSlug)
  // );
  const [filterString, setFilterString] = useState('');
  // const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [shadowStatus, setShadowStatus] = useState(0);

  const [customHeight] = useState(157);
  let itemDetailsRef = useRef();

  const ingredients = useMemo(
    () => currentItem[map[customizeSlug]],
    [customizeSlug, currentItem]
  );

  const filteredIngredients = useMemo(() => {
    if (filterString) {
      return ingredients.filter((item) => {
        if (
          item.name &&
          item.name.trim().toLowerCase().indexOf(filterString.toLowerCase()) !==
            -1
        ) {
          return true;
        } else if (
          item.ingredientName &&
          item.ingredientName
            .trim()
            .toLowerCase()
            .indexOf(filterString.toLowerCase()) !== -1
        ) {
          return true;
        } else return false;
      });
    }
    return ingredients;
  }, [ingredients, filterString]);

  const handleAddSpecials = () => {
    const categoryIndex = !isNotSpecials
      ? currentSpecialsCategoryIndex
      : undefined;
    if (currentItem.isHalf) {
      dispatch(
        updateSpecialsCurrentHalfHalfItem(parseInt(categoryIndex), {
          ...currentItem,
          halfIndex: currentItem.isSecondHalf ? 2 : 1,
        })
      );
      history.push(
        `${parentPage}/specials/${currentSpecialsItemId}/${categoryIndex}/half-half`,
        location.state
      );
    } else {
      const hasSelectedSize =
        currentItem?.selectedSizes?.length > 0 &&
        currentItem?.selectedSizes?.every((size) => size?.quantity > 0);

      if (!hasSelectedSize) {
        history.push(goBackTo, location.state);
        return;
      }

      dispatch(
        updateCurrentSpecialsCategoryItem(categoryIndex, {
          ...currentItem,
          isSpecialItem: true,
        })
      );
      history.push(
        `${parentPage}/specials/${currentSpecialsItemId}`,
        location.state
      );
    }
  };

  const handleAddHalfHalf = () => {
    dispatch(
      updateCurrentHalfHalfItem({
        ...currentItem,
        halfIndex: parseInt(halfIndex),
        isHalf: true,
        isSecondHalf: parseInt(halfIndex) === 2,
      })
    );
    history.push(
      `${parentPage}/category/${halfHalfCategoryId}/half-half`,
      location.state
    );
  };

  const handleAddOnlyInFirstHalf = () => {
    dispatch(
      updateCurrentHalfHalfItem({
        ...currentItem,
        selectedSizes: currentItem.selectedSizes.map((size) => {
          if (size.quantity > 0) {
            return {
              ...size,
              quantity: 0.5,
            };
          }
          return {
            ...size,
          };
        }),
        halfIndex: 1,
        isHalf: true,
        isSecondHalf: false,
      })
    );
    history.push(goBackTo, location.state);
  };

  const handleAddFourQuarter = () => {
    dispatch(
      updateCurrentFourQuarterItem({
        ...incrementItemQuantitySingleSize(currentItem, 0.25),
        fourQuarterIndex: parseInt(fourQuarterIndex),
        isQuarter: true,
        isSecondQuarter: parseInt(fourQuarterIndex) === 2,
        isThirdQuarter: parseInt(fourQuarterIndex) === 3,
        isFourthQuarter: parseInt(fourQuarterIndex) === 4,
      })
    );
    history.push(
      `${parentPage}/category/${fourQuarterCategoryId}/four-quarter`,
      location.state
    );
  };

  const handleAddOrder = () => {
    const hasSelectedSize =
      currentItem?.selectedSizes?.length > 0 &&
      currentItem?.selectedSizes?.every((size) => size?.quantity > 0);

    const shouldReturnHome =
      history.location.search.includes('returnHome') &&
      goBackTo.includes('/menuitem');

    if (!hasSelectedSize && !shouldReturnHome) {
      history.push(goBackTo, location.state);
      return;
    } else {
      const notPreparedItem = {
        ...currentItem,
        isCurrentMenuItemInEditMode: undefined,
      };

      const somePreparedItem = prepareItemForOrder(
        notPreparedItem,
        currentOrder
      );

      let newCurrentOrder = !currentItem.isCurrentMenuItemInEditMode
        ? addItemToOrder(
            currentOrder,
            prepareItemForOrder(currentItem, currentOrder),
            activeSpecials,
            orderSetup,
            productSetup,
            suburbs,
            publicHolidays,
            storeConfig
          )
        : updateItemToOrder(
            currentOrder,
            somePreparedItem,
            activeSpecials,
            orderSetup,
            productSetup,
            suburbs,
            publicHolidays,
            storeConfig
          );
      dispatch(updateCurrentOrder(newCurrentOrder));
      dispatch(resetMenuItem());
      history.push(`${parentPage}/order`, location.state);
    }
  };

  const getTitle = () => {
    const preText = customizeSlug === 'add' ? 'Add to' : 'Remove from';
    if (currentItem) {
      return `${preText} ${currentItem?.name || ''}`;
    }

    return `${preText} Order`;
  };

  const boxCls = clsx({
    'main-area-body': true,
    shadow: shadowStatus,
  });

  /* useEffect(() => {
    // setShadowStatus(0);
    // setFilterString('');
    // onInitInput('searchIngredients', '');
    // setActionType(customizeSlug);
    // setIngredients(
      // getIngredientArraysForSlug(currentItem, customizeSlug)
    // );
    // setTitle(
      // getCustomizationTitle(ITEM_CUSTOMIZATION_OPTIONS, customizeSlug)
    // );
  }, [customizeSlug, currentItem, onInitInput]);

  useEffect(() => {
    const newIngredients = ingredients.sort((a, b) =>
      a.name
      ? a.name.toString()
      : ''.localeCompare(b.name ? b.name.toString() : '', 'en', {
        numeric: true,
      })
    );

    if (!_.isEqual(newIngredients, filteredIngredients)) setFilteredIngredients(newIngredients);
  }, [ingredients, filteredIngredients]);

  useEffect(() => {
    const ingredients = getIngredientArraysForSlug({ selectedIngredients: currentItem.selectedIngredients, selectedExtraIngredients: currentItem.selectedExtraIngredients }, customizeSlug)
    setIngredients(ingredients);
  }, [currentItem.selectedIngredients, currentItem.selectedExtraIngredients, customizeSlug]);

  useEffect(() => {
    if (ingredients && filterString) {
      let tempArr = ingredients.filter((item) => {
        if (
          item.name &&
          item.name.trim().toLowerCase().indexOf(filterString.toLowerCase()) !==
            -1
        ) {
          return true;
        } else if (
          item.ingredientName &&
          item.ingredientName
            .trim()
            .toLowerCase()
            .indexOf(filterString.toLowerCase()) !== -1
        ) {
          return true;
        } else return false;
      });
      setFilteredIngredients(tempArr);
    } else if (filterString === '') {
      setFilteredIngredients(ingredients);
    }
  }, [currentItem.selectedExtraIngredients, currentItem.selectedIngredients, setIngredients, customizeSlug, filterString, ingredients, filteredIngredients]);

  useEffect(() => {
    if (itemDetailsRef !== undefined) {
      if (itemDetailsRef.current.clientHeight) {
        setCustomHeight(itemDetailsRef.current.clientHeight + 101);
      }
    }
  }, [itemDetailsRef]); */

  const setScrollPosition = useCallback(
    (newVal) => {
      if (shadowStatus !== newVal) {
        setShadowStatus(newVal);
      }
    },
    [shadowStatus]
  );

  const halfHalfCategoryId = currentHalfHalfData?.activeCategoryId;
  const fourQuarterCategoryId = currentFourQuarterData?.activeCategoryId;

  const handleClick = (e) => {
    e?.preventDefault();
    if (!isNotSpecials) {
      handleAddSpecials();
    } else if (!!halfHalfCategoryId && pizzaStatus !== 'half') {
      handleAddHalfHalf();
    } else if (!!fourQuarterCategoryId) {
      handleAddFourQuarter();
    } else if (!!halfHalfCategoryId && pizzaStatus === 'half') {
      handleAddOnlyInFirstHalf();
    } else {
      handleAddOrder();
      dispatch(setIsSingleHalfFlow(false));
    }
  };

  useEffect(() => {
    if (selectedCategoryId && selectedCategoryId !== 'specials') {
      dispatch(setHalfHalfMenuItems(selectedCategoryId));
    } else if (selectedCategoryId === 'specials') {
      // will be use in future
      // const specialItemCategoryId =
      //   currentSpecialsItem.selectedCategories[currentSpecialsCategoryIndex]
      //     .categoryId;
      // dispatch(setHalfHalfMenuItems(specialItemCategoryId));
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    if (halfHalfMenuItems && halfHalfMenuItems.length > 0) {
      halfHalfMenuItems.forEach((item) => {
        if (currentItem._id === item._id) {
          setShowActionButton(true);
        }
      });
    }
  }, [JSON.stringify(halfHalfMenuItems)]);

  useEffect(() => {
    if (isSingleHalfFlow) {
      if (pizzaStatus === 'half') {
        dispatch(
          updateMenuItem({
            ...currentItem,
            pizzaStatus: 'half',
          })
        );

        dispatch(updateCurrentHalfHalfData(selectedCategoryId));
      } else if (pizzaStatus === 'whole') {
        dispatch(
          updateMenuItem({
            ...currentItem,
            pizzaStatus: 'whole',
            quantity: 1,
            isHalf: false,
            halfIndex: null,
            isSecondHalf: null,
          })
        );
        dispatch(resetCurrentHalfHalfData());
      }
    }
  }, [pizzaStatus]);

  return (
    <div className="main-area food-style" id="main-area">
      <div className="main-area-inner">
        <ItemDetails ref={itemDetailsRef} currentItem={currentItem} />
        <div className={boxCls}>
          <div className="extraHeaderSection">
            <h2 className="extraHeaderLabel">{customizeSlug}</h2>
            {showActionButtons && isSingleHalfFlow && (
              <>
                <button
                  className={clsx('extraHeaderButton', {
                    ['selectedButton']: pizzaStatus === 'half',
                  })}
                  onClick={() => {
                    setPizzaStatus('half');
                  }}
                >
                  Half Pizza
                </button>
                <button
                  className={clsx('extraHeaderButton', {
                    ['selectedButton']: pizzaStatus === 'whole',
                  })}
                  onClick={() => {
                    setPizzaStatus('whole');
                  }}
                >
                  Whole Pizza
                </button>
              </>
            )}
          </div>
          <SearchIngredients
            setFilterString={setFilterString}
            filterString={filterString}
          />
        </div>

        <div
          className={clsx('main-area-body-wrap', {
            'has-bottom-btn': customizationStatus,
          })}
          style={{
            height: 'calc(100% - ' + customHeight + 'px)',
          }}
        >
          {customizeSlug === 'add' ? (
            <AddIngredients
              ingredients={filteredIngredients}
              actionType={actionType}
              setScrollPosition={setScrollPosition}
              shadowStatus={shadowStatus}
              filterString={filterString}
            />
          ) : customizeSlug === 'remove' ? (
            <RemoveIngredients
              ingredients={filteredIngredients}
              currentItem={currentItem}
              actionType={actionType}
              setScrollPosition={setScrollPosition}
              shadowStatus={shadowStatus}
            />
          ) : (
            ''
          )}
          {customizationStatus ? (
            <div className="main-bottom-btn-box">
              <Button
                title={getTitle()}
                onClick={handleClick}
                className="btn big hover-highlight"
                enableThemeColor
                style={{
                  borderRadius: 8,
                }}
              >
                {getTitle()}
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
