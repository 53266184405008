import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconPeople = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-people': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <title>People</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M30.187 28.444c0 0.273-0.059 1.185-0.875 1.185h-25.448c-0.828 0-0.888-0.889-0.888-1.185v-0.356c0-4.35 3.49-5.736 6.85-7.111l1.089-0.439c0.249-0.107 0.485-0.213 0.698-0.32 1.214 1.39 2.989 2.264 4.969 2.264s3.755-0.874 4.962-2.256l0.007-0.008c0.213 0.107 0.437 0.213 0.686 0.32l1.076 0.439c3.372 1.339 6.862 2.726 6.862 7.111zM12.178 13.535c-1.287-1.054-2.101-2.643-2.101-4.422 0-0.083 0.002-0.165 0.005-0.248l-0 0.012c-0.001-4.136 2.367-6.507 6.523-6.507s6.501 2.37 6.501 6.507c0.004 0.079 0.006 0.171 0.006 0.264 0 1.755-0.806 3.322-2.069 4.351l-0.010 0.008c-0.913 0.828-1.484 2.018-1.484 3.343 0 0.075 0.002 0.149 0.005 0.223l-0-0.010c-0.010 0.091-0.016 0.197-0.016 0.304 0 0.894 0.394 1.696 1.018 2.241l0.003 0.003c-0.989 1.070-2.4 1.738-3.967 1.738s-2.978-0.668-3.964-1.734l-0.003-0.004c0.631-0.563 1.027-1.378 1.027-2.286 0-0.121-0.007-0.241-0.021-0.359l0.001 0.014c0.001-0.042 0.002-0.092 0.002-0.142 0-1.307-0.567-2.481-1.468-3.291l-0.004-0.004zM23.781 19.899l-1.067-0.427c-1.976-0.806-1.976-1.564-1.976-2.524-0.001-0.026-0.001-0.057-0.001-0.088 0-1.015 0.459-1.923 1.18-2.527l0.005-0.004c1.457-1.26 2.374-3.111 2.374-5.176 0-0.101-0.002-0.202-0.007-0.302l0 0.014c0-4.729-2.949-7.68-7.708-7.68s-7.686 2.951-7.686 7.692c-0.004 0.091-0.007 0.198-0.007 0.306 0 2.071 0.917 3.927 2.366 5.186l0.008 0.007c0.728 0.609 1.188 1.518 1.188 2.534 0 0.051-0.001 0.101-0.003 0.152l0-0.007c0 0.865 0 1.612-1.978 2.37l-1.077 0.439c-3.386 1.399-7.602 3.058-7.602 8.225v0.356c-0.009 0.074-0.015 0.161-0.015 0.248 0 1.16 0.93 2.103 2.086 2.122l0.002 0h25.467c1.152-0.024 2.076-0.963 2.076-2.119 0-0.089-0.005-0.176-0.016-0.262l0.001 0.010v-0.356c0-5.167-4.228-6.839-7.614-8.19z"
        />
      </svg>
    </span>
  );
};

PosIconPeople.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
