import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconWriteMessage = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-write-message': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M18.182 22.477a.205.205 0 0 1-.205.205H2.023a.205.205 0 0 1-.205-.205V6.523c0-.113.092-.205.205-.205h10.432V5.5H2.023C1.458 5.5 1 5.958 1 6.523v15.954c0 .565.458 1.023 1.023 1.023h15.954c.565 0 1.023-.458 1.023-1.023V12.045h-.818z"
              />
              <path
                fill="none"
                stroke={iconColor ? iconColor : '#000'}
                strokeMiterlimit="20"
                strokeWidth={lineThickness ? lineThickness * 0.3 : 0.3}
                d="M18.182 22.477a.205.205 0 0 1-.205.205H2.023a.205.205 0 0 1-.205-.205V6.523c0-.113.092-.205.205-.205h10.432v0V5.5v0H2.023C1.458 5.5 1 5.958 1 6.523v15.954c0 .565.458 1.023 1.023 1.023h15.954c.565 0 1.023-.458 1.023-1.023V12.045v0h-.818v0z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M21.095 5.128l-1.72-1.722.954-.955a.46.46 0 0 1 .65 0l1.071 1.07c.179.18.179.471 0 .651zm-9 8.996l-2.148.437.436-2.175 8.407-8.42 1.745 1.739zM22.627 2.937l-1.062-1.063a1.284 1.284 0 0 0-1.811 0L9.708 11.892a.412.412 0 0 0-.115.214l-.585 2.9a.412.412 0 0 0 .404.494h.082l2.882-.585a.412.412 0 0 0 .206-.115L22.626 4.79a1.286 1.286 0 0 0 0-1.812z"
              />
              <path
                fill="none"
                stroke={iconColor ? iconColor : '#000'}
                strokeMiterlimit="20"
                strokeWidth={lineThickness ? lineThickness * 0.3 : 0.3}
                d="M21.095 5.128l-1.72-1.722.954-.955a.46.46 0 0 1 .65 0l1.071 1.07c.179.18.179.471 0 .651zm-9 8.996l-2.148.437.436-2.175 8.407-8.42 1.745 1.739zM22.627 2.937l-1.062-1.063a1.284 1.284 0 0 0-1.811 0L9.708 11.892a.412.412 0 0 0-.115.214l-.585 2.9a.412.412 0 0 0 .404.494h.082l2.882-.585a.412.412 0 0 0 .206-.115L22.626 4.79a1.286 1.286 0 0 0 0-1.812z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconWriteMessage.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
