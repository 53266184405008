import React from 'react';

export const BicycleIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width={width || '24px'}
      height={height || '24px'}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      fill={fill}
    >
      <path d="M19.5,14.1c-0.1,0-0.2,0-0.2,0l-2.5-10c-0.1-0.2-0.3-0.4-0.5-0.4h-2.8c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h2.4l1.8,7.1  l-4.4,4.8H7.5c-0.2-1.4-1.5-2.5-3-2.5c-1.7,0-3,1.4-3,3c0,1.7,1.4,3,3,3c1.5,0,2.7-1.1,3-2.5h6c0.1,0,0.3-0.1,0.4-0.2l4.1-4.5  l0.3,1.4c-1.1,0.5-1.8,1.5-1.8,2.8c0,1.7,1.4,3,3,3s3-1.4,3-3C22.5,15.5,21.1,14.1,19.5,14.1z M4.5,19.2c-1.1,0-2-0.9-2-2s0.9-2,2-2  c0.9,0,1.7,0.6,1.9,1.5H5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.2,0,0.4-0.1,0.5-0.2h1.4  C6.2,18.5,5.5,19.2,4.5,19.2z M19.5,19.2c-1.1,0-2-0.9-2-2c0-0.8,0.4-1.4,1.1-1.8l0.4,1.4c-0.1,0.1-0.1,0.2-0.1,0.4  c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.3-0.1-0.5-0.4-0.6l-0.4-1.4c1.1,0,2,0.9,2,2C21.5,18.3,20.6,19.2,19.5,19.2z" />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
