import React from 'react';
import PropTypes from 'prop-types';
import { ButtonUI } from './index';
import { BurgerIcon as IconBurger } from 'nexticons/outline';

export const ButtonActionsUI = ({ onClick }) => {
  return (
    <ButtonUI
      styleType="secondary"
      fontWeight="regular"
      rounded="l"
      onClick={onClick}
      icon={<IconBurger strokeWidth={1.5} style={{ width: 26, height: 26 }} />}
    >
      Actions
    </ButtonUI>
  );
};

ButtonActionsUI.defaultProps = {
  onClick: () => {},
};

ButtonActionsUI.propTypes = {
  onClick: PropTypes.func,
};
