export const itemHash = (i, index, shareIndex = '') => {
  const modHash = (i?.selectedModifiers || []).reduce(
    (a, m) => (a += (m.subModifiers || []).map((i) => i._id).join()),
    '|'
  );
  const sizeHash = i.selectedSizes?.reduce((a, m) => (a += m._id), '|');
  const ingHash = i.selectedExtraIngredients.reduce(
    (a, i) => (a += i._id),
    '|'
  );
  const rmHash = i.removedIngredients.reduce((a, i) => (a += i._id), '|');
  const ret =
    i._id +
    sizeHash +
    modHash +
    ingHash +
    rmHash +
    `|${i.itemNotes || ''}|` +
    i.orderIndex +
    index +
    shareIndex +
    `|${i.orderIndex}`;
  return ret;
};

export const dehash = (hash) => {
  const index = hash.slice(-1);
  const shareIndex = hash.slice(-2)[0];
  if (hash.includes('+')) {
    const parts = hash.split('').filter((i) => i === '+').length + 1;
    const slices = hash.slice(0, -3).match(/(.{1,20})\+(.{1,20})/gm);
    const itemIds = slices.slice(0, parts / 2);
    const customizeIds = slices.slice(parts);
    return {
      id: itemIds.join('+'),
      customizeIds,
      parts,
      index,
      shareIndex,
      partedIndex: +hash.slice(-3)[0] + 1,
    };
  }
  const [id, sizeHash, modHash, ingHash, rmHash, notes, indexHash, orderIndex] =
    hash.split('|');
  return {
    id,
    notes,
    customizeIds: {
      sizeIds: sizeHash?.match(/.{1,20}/g) || [],
      subModifierIds: modHash?.match(/.{1,20}/g) || [],
      selectedExtraIngredientIds: ingHash?.match(/.{1,20}/g) || [],
      removedIngredientIds: rmHash?.match(/.{1,20}/g) || [],
    },
    parts: 1,
    index: indexHash[0],
    shareIndex: indexHash[1],
    orderIndex,
  };
};

const arrayEq = (a, b) =>
  a.every((i) => b.includes(i)) && b.every((j) => a.includes(j));
const getId = (a) => a._id;

export const itemPreq = (hash) => (i) => {
  const {
    id,
    customizeIds: {
      sizeIds,
      subModifierIds,
      selectedExtraIngredientIds,
      removedIngredientIds,
    },
    orderIndex,
  } = dehash(hash);
  const preq =
    id === i._id &&
    Number(orderIndex) === i.orderIndex &&
    arrayEq(
      i.selectedSizes.map((s) => s._id),
      sizeIds
    ) &&
    arrayEq(
      (i?.selectedModifiers || []).flatMap((k) =>
        (k?.subModifiers || []).map(getId)
      ),
      subModifierIds
    ) &&
    arrayEq(i.removedIngredients.map(getId), removedIngredientIds) &&
    arrayEq(i.selectedExtraIngredients.map(getId), selectedExtraIngredientIds);
  return preq;
};

export const getItemInstruction = (i) => `${(
  i?.selectedModifiers || []
).flatMap((l) => (l?.subModifiers || []).map((n) => n.name))} ${
  i.selectedExtraIngredients.length > 0 ? 'Add' : ''
} \
${i.selectedExtraIngredients.map((ing) => ing.ingredientName || ing.name)} \
${i.removedIngredients.length > 0 ? '\nRemove' : ''} ${i.removedIngredients.map(
  (i) => i.ingredientName || i.name
)} \
${i.itemNotes ? `Notes:${i.itemNotes}` : ''}`;

export const countTotalItem = (menuItems, sharedItems) => {
  let intialSharedState = {};
  const { values } = Object;

  //HALF HALF
  values(
    menuItems
      .filter((i) => i.isHalf)
      .reduce(
        (acc, k) => ({
          ...acc,
          [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
        }),
        {}
      )
  ).map((item, index) => {
    const partedIndex =
      item.map((i) => i._id).join('+') + item[0].halfIndex + 1;
    intialSharedState = { ...intialSharedState, [partedIndex]: 1 };
  });

  //Four Quarters
  values(
    menuItems
      .filter((i) => i.isQuarter)
      .reduce(
        (acc, k) => ({
          ...acc,
          [k.fourQuarterIndex]: [...(acc[k.fourQuarterIndex] || []), k],
        }),
        {}
      )
  ).map((item, index) => {
    const partedIndex =
      item.map((i) => i._id).join('+') + item[0].fourQuarterIndex + 1;
    intialSharedState = { ...intialSharedState, [partedIndex]: 1 };
  });

  //All Simple Items
  menuItems
    .filter((i) => i.quantity >= 1)
    .flatMap(
      (i) =>
        !i.isHalf &&
        !i.isQuarter &&
        Array(i.quantity)
          .fill(0)
          .map((_, index) => {
            intialSharedState = {
              ...intialSharedState,
              [itemHash(i, index)]: 1,
            };
          })
    );

  const updateShareState = { ...intialSharedState, ...sharedItems };

  let nullCount = 0;
  Object.values(updateShareState).forEach((val) => {
    if (val === null) nullCount++;
  });

  return (
    Object.values(updateShareState).reduce(
      (total, val) => (total = total + val),
      0
    ) + nullCount
  );
};

export const getTextWidth = (text, fontSize, fontFamily) => {
  // Create a temporary element
  var el = document.createElement('span');

  // Set its font size and font family
  el.style.fontSize = fontSize;
  el.style.fontFamily = fontFamily;

  // Set its text content to the input text
  el.textContent = text;

  // Add the element to the DOM but hide it from view
  el.style.position = 'absolute';
  el.style.visibility = 'hidden';
  document.body.appendChild(el);

  // Measure the width of the element and remove it from the DOM
  var width = el.offsetWidth;
  document.body.removeChild(el);

  return width;
};

export const getOrderIndexHashLength = (orderIndex) => {
  if (orderIndex >= 0 && orderIndex <= 9) {
    return 2;
  } else if (orderIndex >= 10 && orderIndex <= 99) {
    return 3;
  } else if (orderIndex >= 100 && orderIndex <= 999) {
    return 4;
  }
};

export const getSliceOffset = (orderIndex) => {
  if (orderIndex >= 0 && orderIndex <= 9) {
    return -3;
  } else if (orderIndex >= 10 && orderIndex <= 99) {
    return -4;
  } else if (orderIndex >= 100 && orderIndex <= 999) {
    return -5;
  }
};
