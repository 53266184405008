import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GeneralModal from '../../../../modals/GeneralModal/index-new';
import { filteredDriversSelector } from '../../../../pages/Drivers/selectors';
import './SelectDriverModal.css';
import { XRoundedIcon } from 'nexticons/solid';
import clsx from 'clsx';
import { LoadingIcon } from 'nexticons/outline';
import { assignDelivery } from './SelectDriverModal.service';
import {
  fetchActiveDriverCashBalance,
  fetchActiveDriverLocations,
  fetchActiveDriverRunOrders,
  fetchActiveDriverRuns,
  fetchActiveDrivers,
  fetchActiveDriverShifts,
  fetchUnAssignedOrders,
} from '../../../../pages/Drivers/actions';

interface SelectDriverModalProps {
  toggleModal: () => void;
  selectedOrder: any;
}

interface DriverType {
  activeDeliveredOrders: any[];
  activeOrders: any[];
  cashBalance: {
    _id: string;
    lastUpdated: string;
    dateTimestamp: number;
    lastUpdatedBy: string;
    lastName: string;
  };
  currentLocation: any;
  firstName: string;
  isActive: boolean;
  isSelected: boolean;
  lastName: string;
  lastUpdatedTimestamp: number;
  mobileNumber: string;
  previousLocation: any;
  restaurantId: string;
  shiftId: string | undefined;
  status: number;
  storeId: string;
  _id: string;
}

const getInitialsAlphabets = (firstName: string, lastName: string) => {
  const firstInitial = firstName.charAt(0);
  const lastInitial = lastName.charAt(0);
  return firstInitial + lastInitial;
};

const SelectDriverModal: React.FunctionComponent<SelectDriverModalProps> = ({
  toggleModal,
  selectedOrder,
}) => {
  const dispatch = useDispatch();
  const filteredDrivers = useSelector(filteredDriversSelector) || {};
  const { driversWaitingOnStore } = filteredDrivers || {};
  const [selectedDriver, setSelectedDriver] = useState<DriverType | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const nextOrderConfigURL = useSelector(
    (state: any) => state.dashboardReducer.nextOrderConfigURL
  );

  const onModalClose = () => {
    toggleModal();
  };

  const handleAllDriverDispatchCalls = () => {
    dispatch(fetchActiveDrivers());
    dispatch(fetchActiveDriverLocations());
    dispatch(fetchActiveDriverRuns());
    dispatch(fetchActiveDriverShifts());
    dispatch(fetchActiveDriverRunOrders());
    dispatch(fetchUnAssignedOrders());
    dispatch(fetchActiveDriverCashBalance());
  };

  const handleDriverOnClick = (driver: any) => {
    setSelectedDriver(driver);
  };

  const handleAssignDelivery = async () => {
    setIsLoading(true);
    const reqObj = {
      deliveryCode: selectedOrder?.deliveryCode || '',
      driverId: selectedDriver?._id || '',
      restaurantId: selectedDriver?.restaurantId || '',
      storeId: selectedDriver?.storeId || '',
      timestampStart: new Date().getTime(),
      shiftId: selectedDriver?.shiftId || '',
    };
    const response = await assignDelivery(reqObj, nextOrderConfigURL);
    setIsLoading(false);
    onModalClose();
  };

  const generateColorCode = (inputText: string) => {
    // Convert input text to a hexadecimal string
    let hexString = '';
    for (let i = 0; i < inputText.length; i++) {
      hexString += inputText.charCodeAt(i).toString(16);
    }
    // Use the first 6 characters of the hexadecimal string as the color code
    return '#' + hexString.slice(0, 6);
  };

  useEffect(() => {
    handleAllDriverDispatchCalls();
  }, []);

  return (
    <GeneralModal
      style={{ borderRadius: 28 }}
      modalStatus={true}
      toggleModal={onModalClose}
      position="center"
      overlay="blur"
      boxWidth="462px"
      boxHeight="594px"
      containerStyle={{}}
      closeBtn="outside"
      overlayStyle={{}}
      backBtnFnc={undefined}
      errorEffect={false}
      keyboardVisibility={false}
      isResponsiveHeight
      minBoxHeight
      showCloseBtn={true}
    >
      <>
        <div className="headerContainer">
          <button className="customCloseButton" onClick={onModalClose}>
            <XRoundedIcon width={17} strokeWidth={1} />
          </button>
          <span className="mainDriverHeadingText">Select a driver</span>
          <span className="subTextDriverText">
            Delivery will be assigned to the selected driver and appear in Next
            Order - Driver App.
          </span>
        </div>

        <div className="driverListContainer">
          {driversWaitingOnStore?.length > 0 &&
            driversWaitingOnStore.map((driver) => {
              return (
                <div
                  className={clsx('driverItemContainer', {
                    ['active']: driver._id === selectedDriver?._id,
                  })}
                  key={driver._id}
                  onClick={() => handleDriverOnClick(driver)}
                >
                  <div
                    className={clsx('driverAvatar', {
                      ['activeAvatar']: driver._id === selectedDriver?._id,
                    })}
                    style={{
                      backgroundColor:
                        driver._id === selectedDriver?._id
                          ? 'white'
                          : generateColorCode(
                              `${driver.firstName} ${driver.lastName}`
                            ),
                    }}
                  >
                    {getInitialsAlphabets(
                      driver.firstName,
                      driver.lastName
                    ).toUpperCase()}
                  </div>
                  <span
                    className={clsx('driverNameText', {
                      ['active']: driver._id === selectedDriver?._id,
                    })}
                  >
                    {driver.firstName} {driver.lastName}
                  </span>
                </div>
              );
            })}

          {(driversWaitingOnStore?.length === 0 || null) && (
            <LoadingIcon width={35} height={35} strokeWidth={2} />
          )}
        </div>

        <div className="footerContainer">
          <button
            className="assignButton"
            onClick={handleAssignDelivery}
            disabled={!selectedDriver?._id}
          >
            {isLoading ? (
              <LoadingIcon width={35} height={35} strokeWidth={2} />
            ) : (
              `Assign Delivery to ${selectedDriver?.firstName}`
            )}
          </button>
        </div>
      </>
    </GeneralModal>
  );
};

export default SelectDriverModal;
