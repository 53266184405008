import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import usePickupDeliveryShiftStatus from '../../customHooks/usePickupDeliveryShiftStatus';
import useAssignDrivers from '../../hooks/useAssignDrivers';

export const DriversNav = () => {
  const dispatch = useDispatch();
  const { driverId } = useParams();
  const { pickUpTime, deliveryTime } = useSelector(
    (state) => state.ordersReducer.orderWaitingObj
  );

  const { isPickUpOpen, isDeliveryOpen } = usePickupDeliveryShiftStatus();
  const {
    assignDriver,
    selectedDriverForDeliveries,
    selectedUnAssignedOrders,
  } = useSelector((state) => state.driversReducer);

  const currentUrl = window.location.href;

  const deliveryCodes = useMemo(
    () => (selectedUnAssignedOrders ?? []).map((o) => o.deliveryCode),
    [selectedUnAssignedOrders]
  );
  const selectedDriver = selectedDriverForDeliveries ?? {};
  const { handleAssignDriver, isAssignDriverApiLoading } = useAssignDrivers({
    driverId: selectedDriver._id,
    shiftId: selectedDriver.shiftId,
    storeId: selectedDriver.storeId,
    restaurantId: selectedDriver.restaurantId,
    deliveryCodes,
  });

  const handleToggleAssign = () => {
    if (currentUrl.indexOf('deliveries/driver/') !== -1 && driverId) {
      handleAssignDriver();
    } else {
      dispatch({
        type: 'toggleAssignDriver',
        payload: true,
      });
    }
  };

  const handleOpenCollectionModal = () => {
    if (isPickUpOpen) {
      dispatch({
        type: 'toggleViewOrderTimeModal',
        payload: {
          name: 'pickUpTime',
          active: true,
        },
      });
    }
  };

  const handleOpenDeliveryModal = () => {
    if (isDeliveryOpen) {
      dispatch({
        type: 'toggleViewOrderTimeModal',
        payload: {
          name: 'deliveryTime',
          active: true,
        },
      });
    }
  };

  useEffect(() => {
    // when selectedUnAssignedOrders' length is zero, reset assignDriver and selectedDriverForDeliveries
    !selectedUnAssignedOrders.length &&
      dispatch({
        type: 'toggleAssignDriver',
        payload: false,
      });
  }, [selectedUnAssignedOrders.length, dispatch]);

  return (
    <div className="order-sidebar-bottom-nav">
      <div className="order-time-btn-box">
        <button
          type="button"
          title="Collection Time"
          className="hover-highlight"
          onClick={handleOpenCollectionModal}
        >
          <span className="text-wrap">
            <span className="sub-text">Collection Time</span>
            {isPickUpOpen ? (
              <span>{pickUpTime} mins</span>
            ) : (
              <span>Closed</span>
            )}
          </span>
          <span className="icon-box" />
        </button>
        <button
          type="button"
          title="Delivery Time"
          onClick={handleOpenDeliveryModal}
          className="hover-highlight"
        >
          <span className="text-wrap">
            <span className="sub-text">Delivery Time</span>
            {isDeliveryOpen ? (
              <span>{deliveryTime} mins</span>
            ) : (
              <span>Closed</span>
            )}
          </span>
          <span className="icon-box" />
        </button>
      </div>

      <div className="order-actions-btn-box">
        {!assignDriver && selectedUnAssignedOrders.length ? (
          <button
            type="button"
            title="1 deliveries Selected"
            className="btn-action width-100 blue has-selected hover-highlight"
            onClick={handleToggleAssign}
          >
            <span className="inner-btn-box">
              <span className="count">
                <span>{selectedUnAssignedOrders.length}</span>
              </span>
              <span className="text">Deliveries Selected</span>
              <span className="btn-dummy">Assign</span>
            </span>
          </button>
        ) : assignDriver &&
          selectedUnAssignedOrders.length &&
          !selectedDriverForDeliveries?._id ? (
          <button
            type="button"
            title="Select Driver to Assign"
            className="btn-action width-100 select-driver blue hover-highlight"
          >
            <span className="inner-btn-box">
              <span className="back-icon-box" />
              <span className="text">Select Driver to Assign</span>
            </span>
          </button>
        ) : assignDriver &&
          selectedUnAssignedOrders.length &&
          !!selectedDriverForDeliveries ? (
          <button
            type="button"
            title="Assign Driver"
            className={`btn-action width-100 blue hover-highlight`}
            onClick={handleAssignDriver}
            disabled={isAssignDriverApiLoading}
          >
            <span className="inner-btn-box">Assign</span>
          </button>
        ) : (
          <button
            type="button"
            title="Assign Deliveries"
            className="btn-action width-100 blue hover-highlight"
          >
            <span className="inner-btn-box">Assign Deliveries</span>
          </button>
        )}
      </div>
    </div>
  );
};
