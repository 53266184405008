import axios from 'axios';
import { nextOrderConfig } from '../../../configuration/nextOrderConfig';

const notifyCustomerApi = async (orderId) => {
  const notifyCustomerResponse = await axios.post(
    `${nextOrderConfig?.cloudFunctionUrl}/notifyCustomer`,
    { orderId, type: '0' }
  );
  return notifyCustomerResponse;
};

export default notifyCustomerApi;
