import React, { useState } from 'react';

import classNames from 'classnames';

import style from './Dropdown.module.scss';

export const Dropdown = ({ options, initialValue, onChange }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className={classNames(style.Dropdown, isOpened && style.opened)}>
      <select onChange={onChange}>
        {!!options &&
          options.map(({ nickName, isPrimaryPrinter, _id }) => (
            <option value={_id} selected={isPrimaryPrinter}>
              {nickName}
            </option>
          ))}
      </select>
    </div>
  );
};
