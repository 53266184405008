import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const InnerModal = ({
  children,
  modalStatus,
  toggleModal,
  position,
  effect,
  boxWidth,
  boxHeight,
  customClass,
}) => {
  let modalRef = useRef(null);

  useEffect(() => {
    let timeout = 0;

    if (modalStatus) {
      modalRef.current.classList.add('show-inner');
      // if (overlay === "blur") {
      //   document.getElementById("root").classList.add("blurEffect");
      // }
    }

    if (!modalStatus) {
      if (modalRef.current !== undefined) {
        modalRef.current.classList.add('hide-anim-inner');
        // document.getElementById("root").classList.remove("blurEffect");
      }

      timeout = setTimeout(() => {
        if (modalRef.current !== undefined) {
          modalRef.current.classList.remove('hide-anim-inner');
          modalRef.current.classList.remove('show-inner');
        }
      }, 350);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [modalStatus]);

  const modalCls = clsx({
    innerModal: true,
    [`${customClass}`]: true,
    [`${position}`]: position,
    [`${effect}-effect`]: effect,
  });

  const handleClick = (e) => {
    toggleModal(e, false);
  };

  return (
    <div className={modalCls} ref={modalRef}>
      <div
        className="innerModal-wrap"
        style={{
          width: boxWidth,
          height: boxHeight,
        }}
      >
        <div className="innerModal-innerWrap">
          <div className="innerModal-box">{children}</div>
        </div>
      </div>
      <div className="innerModal-overlay" onClick={handleClick} />
    </div>
  );
};

InnerModal.propTypes = {
  children: PropTypes.any,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  position: PropTypes.string,
  effect: PropTypes.string,
  boxWidth: PropTypes.string,
  boxHeight: PropTypes.string,
  customClass: PropTypes.any,
};

InnerModal.defaultProps = {
  children: '',
  modalStatus: false,
  toggleModal: undefined,
  position: 'center',
  effect: 'move',
  boxWidth: '400px',
  boxHeight: '600px',
  customClass: '',
};

export default InnerModal
