import KioskOrderScreen from 'components/Kiosk/KioskOrderScreen';
import { useState } from 'react';
import { kiosk_continue_route, kiosk_route } from 'constants/routesConsts';
import { useLocation } from 'react-router-dom';
import KioskContinueScreen from '../../components/Kiosk/splash/KioskContinueScreen';
import KioskStartScreen from '../../components/Kiosk/splash/KioskStartScreen';
import './_kiosk.scss';

export default function Kiosk() {
  const { pathname } = useLocation();
  const [kioskFlowType, setKioskFlowType] = useState('');

  const isStartScreen = pathname === kiosk_route;
  const isContinueScreen = pathname === kiosk_continue_route;

  return isStartScreen ? (
    <KioskStartScreen setKioskFlowType={setKioskFlowType} />
  ) : isContinueScreen ? (
    <KioskContinueScreen kioskFlowType={kioskFlowType} />
  ) : (
    <KioskOrderScreen kioskFlowType={kioskFlowType} />
  );
}
