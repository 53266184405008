import './bem-step-modal.scss';
import { Children, cloneElement, useState } from 'react';
import GeneralModal from '../GeneralModal/index-new';

function StepModalStep({ children, ...props }) {
  const { index, totalSteps, activeStep, setActiveStep } = props;
  let translateX = 0;

  let diff = index - activeStep;

  translateX = diff * 100;

  return (
    <div
      className="StepModalStep"
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        transform: `translateX(${translateX}%)`,
        opacity: index === activeStep ? 1 : 0,
        transition: 'all .1s linear',
      }}
    >
      {Children.toArray(children).map((child) =>
        cloneElement(child, { stepModalProps: props })
      )}
    </div>
  );
}

function StepModal({ children, onModalClose, showModal }) {
  const [activeStep, setActiveStep] = useState(0);
  const childArray = Children.toArray(children).filter((child) => {
    return child.type === StepModalStep;
  });
  const totalSteps = childArray.length;

  return (
    <GeneralModal
      modalStatus={showModal}
      toggleModal={() => (activeStep > 0 ? undefined : onModalClose())}
      backBtnFnc={() =>
        activeStep > 0 ? setActiveStep(activeStep - 1) : undefined
      }
      position="center"
      effect="move"
      overlay=""
      boxWidth="542px"
      boxHeight="700px"
      closeBtn={activeStep > 0 ? '' : 'outside'}
      backBtn={activeStep > 0 ? 'outside' : ''}
      className="bem-step-modal"
      isResponsiveHeight
    >
      {childArray.map((child, index) => {
        return cloneElement(child, {
          index,
          totalSteps,
          activeStep,
          setActiveStep,
        });
      })}
    </GeneralModal>
  );
}

StepModal.Step = StepModalStep;

export default StepModal;
