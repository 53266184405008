import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import useValidatePhoneNumber from '../../customHooks/useValidatePhoneNumber';
import { updateCurrentOrder } from '../../pages/Dashboard/action';
import OrderBarTextField from '../OrderBarTextField';

const OrderBarMobile = ({ currentOrder }) => {
  const dispatch = useDispatch();
  const {
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    onInitInput,
    inputNames,
    inputs,
    setLayout,
  } = useVirtualKeyboardContext();

  const orderMobile = getInputValue('orderMobile');

  // const [mobileNumber, setMobileNumber] = useState('');
  const { hasError } = useValidatePhoneNumber(getInputValue('orderMobile'));

  useEffect(() => {
    setInputName('orderMobile');
    onInitInput('orderMobile', currentOrder.mobileNumber || '');
  }, [currentOrder.mobileNumber]);

  const handleFocus = () => {
    setInputName('orderMobile');
    setLayout('alt');
    toggleKeyboard(true);
  };

  const handleChange = (event) => {
    const inputVal = event.target.value;
    onChangeInput('orderMobile', inputVal);
    // setMobileNumber(event.target.value);
  };

  const handleOnBlur = useCallback(() => {
    if (!hasError) {
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          mobileNumber: orderMobile,
        })
      );
    } else {
      onChangeInput('orderMobile', currentOrder.mobileNumber);
      // setMobileNumber(currentOrder.mobileNumber);
    }

    toggleKeyboard(false);
  }, [dispatch, currentOrder, inputs, hasError]);

  return (
    <label className="single-pickup-details input-box">
      <OrderBarTextField
        id="orderMobile"
        name="orderMobile"
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleOnBlur}
        placeholder="Mobile Number"
        value={orderMobile}
        // value={mobileNumber}
      />
      <div className="icon-box">
        <span className="dine_icon-phone-inverse" />
      </div>
    </label>
  );
};

export default OrderBarMobile;
