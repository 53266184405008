const actionTypes = {
  orderBarChangeAnim: 'orderBarChangeAnim',
  setScrollPosition: 'setScrollPosition',
  resetScrollPosition: 'resetScrollPosition',
  changeModalTime: 'changeModalTime',
  toggleViewOrderTimeModal: 'toggleViewOrderTimeModal',
  setOrderBarType: 'setOrderBarType',
  setOrderSideBarType: 'setOrderSideBarType',
  setActiveSortType: 'setActiveSortType',
  setActiveGuest: 'setActiveGuest',
  setOrderType: 'setOrderType',
  setSelectedOrder: 'setSelectedOrder',
  setOrderList: 'setOrderList',
  setUnSettledOrderList: 'setUnSettledOrderList',
  setCopyOfOrderList: 'setCopyOfOrderList',
  setOrderObject: 'setOrderObject',
  setOrderWaitingTimeObj: 'setOrderWaitingTimeObj',
  setDrivers: 'setDrivers',
  resetOrdersPageData: 'resetOrdersPageData',
  setFilterType: 'setFilterType',
  toggleViewOrderModal: 'toggleViewOrderModal',
  toggleDeliveryOrderModal: 'toggleDeliveryOrderModal',
  toggleActionsModal: 'toggleActionsModal',
  toggleRefundOrderModal: 'toggleRefundOrderModal',
  togglePlanBillModal: 'togglePlanBillModal',
  togglePaymentViewModal: 'togglePaymentViewModal',
  togglePinModal: 'togglePinModal',
  retainScrollTop: 'retainScrollTop',
  transferOrderModal: 'transferOrderModal',
  ordersListFetchStatusUpdate: 'ordersListFetchStatusUpdate',
  UPDATE_ORDER_TYPE_FILTER: 'UPDATE_ORDER_TYPE_FILTER',
  UPDATE_ORDER_TIME_SORT_ORDER_FILTER: 'UPDATE_ORDER_TIME_SORT_ORDER_FILTER',
  SHOW_SPLIT_PAYMENT_MODAL: 'SHOW_SPLIT_PAYMENT_MODAL',
  requestUberDriver: 'requestUberDriver',
};

export default actionTypes;
