import './bem-circle-button.scss';
import PropTypes from 'prop-types';
import { getUIClassesFromProps, getUIModifierKeys } from '../modifiersMapping';

export const CircleButtonUI = ({ title, icon, style, onClick, ...props }) => {
  return (
    <button
      type="button"
      title={title}
      className={getUIClassesFromProps('bem-circle-btn', { ...props })}
      style={style}
      onClick={onClick}
    >
      {icon}
    </button>
  );
};

CircleButtonUI.defaultProps = {
  title: '',
  styleType: 'secondary-light',
  buttonSize: 's',
  modifierClassName: '',
  icon: false,
  style: {},
  onClick: () => {},
};

CircleButtonUI.propTypes = {
  title: PropTypes.string,
  styleType: PropTypes.oneOf(getUIModifierKeys('styleType')),
  buttonSize: PropTypes.oneOf(getUIModifierKeys('buttonSize')),
  modifierClassName: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]).isRequired,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
