import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconNarrow = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-narrow': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 26 27"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M17.1,10l6.9,0l0-2.3l-4.1,0L26,1.6L24.4,0l-6.1,6.1l0-4.1l-2.3,0l0,6.9C16,9.5,16.5,10,17.1,10z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M18.3,20.9l6,6.1l1.6-1.6l-6-6.1l4.1,0l0-2.3l-6.9,0c-0.6,0-1.2,0.5-1.2,1.1l0,6.9l2.3,0L18.3,20.9z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M1.6,27l6.1-6.1l0,4.1l2.3,0l0-6.9c0-0.6-0.5-1.1-1.2-1.1l-6.9,0l0,2.3l4.1,0L0,25.4L1.6,27z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M2,7.7L2,10l6.9,0C9.5,10,10,9.5,10,8.9L10,2L7.7,2l0,4.1L1.6,0L0,1.6l6.1,6.1L2,7.7z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconNarrow.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
