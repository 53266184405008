import classNames from 'classnames';
import { paymentTypeEnum } from 'configuration/enums';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
import { kiosk_route } from 'constants/routesConsts';
import useIsKiosk from 'hooks/useIsKiosk';
import { useEffect, useMemo, useState, useRef } from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { printBillCommand } from 'services/ordersServices';
import { getServiceID } from 'util/utils';
import { v4 as uuidv4 } from 'uuid';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';
import terminalPng from '../../assets/img/terminal.png';
import checkMarkSuccess from '../../assets/lotties/782-check-mark-success.json';
import { _auth, _firestore } from '../../firebase';
import {
  authenticateStaff,
  setIsCancelTransactionApiLoading,
  toggleBlurEffect,
  updateCashModalTitle,
} from '../../pages/Dashboard/action';
import {
  currencySymbolSelector,
  isPrintCustomerReceiptAutomaticallyOn,
  nextOrderConfigURLSelector,
  orderPaymentsSelector,
  paymentTypesSelector,
  pinPadsSelector,
  printCustomerReceiptAutomaticallyOnPOSSelector,
  selectedDrawerIdSelector,
  selectedDrawerPrinterIdSelector,
  selectedPinPadIdSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { getCashOptions } from '../../util/paymentViewUtils';
import CashAmountModal from '../CashAmountModal';
import PaymentAmountOption from './PaymentAmountOption';
import PaymentModalFailScreen from './PaymentModalFailScreen';
import { getOrderStatus } from './PaymentViewModal.service';
import { WarningIcon, XRoundedIcon, CheckIcon } from 'nexticons/solid';
import { LoadingIcon } from 'nexticons/outline';
import clsx from 'clsx';

const PaymentViewModal = ({
  toggleModal,
  currentOrder,
  orderTimes,
  isOrdersPage = false,
  openCashAmountPaidModal,
  handleCashAmountChange,
  handleCashPaid,
  handleSplitPaymentClick,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const isPrintReceiptAutomatically = useSelector(
    isPrintCustomerReceiptAutomaticallyOn
  );

  const storeConfig = useSelector(storeConfigSelector);

  const [isMounted, setIsMounted] = useState(true);
  const isKiosk = useIsKiosk();
  const currencySymbol = useSelector(currencySymbolSelector);
  const openDrawerForCardTransactions = useSelector(
    (state) => state.dashboardReducer.orderSetup?.openDrawerForCardTransactions
  );
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const selectedPinPadId = useSelector(selectedPinPadIdSelector);
  const selectedDrawerId = useSelector(selectedDrawerIdSelector);
  const selectedDrawerPrinterId = useSelector(selectedDrawerPrinterIdSelector);
  const pinPads = useSelector(pinPadsSelector);

  const windCaveCompleteStatusRef = useRef(0);
  const [windcaveErrorMessage, setWindcaveErrorMessage] = useState('');
  const [windcaveInfoMessage, setWindcaveInfoMessage] = useState('');
  const [transactionStatusId, setTransactionStatusId] = useState(-1);
  const [windcaveButtonOne, setWindcaveButtonOne] = useState('');
  const [windcaveButtonTwo, setWindcaveButtonTwo] = useState('');
  const [windcaveActionButtonPressed, setWindcaveActionButtonPressed] =
    useState(false);
  const [windcaveActionButtonPressedName, setWindcaveActionButtonPressedName] =
    useState('');
  const [windcaveTransactionRef, setWindcaveTransactionRef] = useState('');
  const windcaveUser = useSelector(
    (state) => state.dashboardReducer.terminalSettings?.user || 'None'
  );
  const printReceipt = useSelector(
    printCustomerReceiptAutomaticallyOnPOSSelector
  );

  const { nextOrderConfigURL, nextOrderConfigURLSydney } = useSelector(
    nextOrderConfigURLSelector
  );

  const currentSelectedTerminal = useMemo(
    () => pinPads?.find((pinPad) => pinPad._id === selectedPinPadId),
    [pinPads, selectedPinPadId]
  );

  const [isProcessTerminalPayment, setIsProcessTerminalPayment] =
    useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [orderNumber, setOrderNumber] = useState(0);
  const [paymentAnimation, setPaymentAnimation] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [customHide, setCustomHide] = useState(false);
  const [sessionId, setSessionId] = useState('0');
  const [serviceId, setServiceId] = useState();
  const [isTerminalOrderInProgress, setIsTerminalOrderInProgress] =
    useState(false);
  const [isPayingByCash, setIsPayingByCash] = useState(false);
  const [selectedCustomPaymentType, setSelectedCustomPaymentType] =
    useState(false);
  const [payableAmount, setPayableAmount] = useState(0);
  const [isRefund, setRefundFlag] = useState(false);
  const [shouldAutoPay, setShouldAutoPay] = useState(false);
  const [isCancelledByPayByCash, setIsCancelledByPayByCash] = useState(false);
  const [
    failedLinklyByCancelPaymentResponse,
    setFailedLinklyByCancelPaymentResponse,
  ] = useState(false);
  const isCancelTransactionApiLoading = useSelector(
    (state) => state.dashboardReducer.isCancelTransactionApiLoading
  );

  useEffect(() => {
    // This effect will run when the component mounts
    // Set isMounted to true when the component is mounted
    setIsMounted(true);

    // This return function will run when the component unmounts
    return () => {
      // Set isMounted to false when the component is unmounted
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const uniqueSessionId = uuidv4();
    setSessionId(uniqueSessionId);

    setServiceId(getServiceID(uniqueSessionId));
    setShouldAutoPay(true);
  }, []);

  const paymentTypesForOrderType = useMemo(() => {
    return (
      paymentTypes?.filter((paymentType) =>
        paymentType?.orderTypeKeys?.includes(currentOrder.orderType)
      ) || []
    );
  }, [currentOrder.orderType, paymentTypes]);

  useEffect(() => {
    let alreadyPaidAmount = 0;

    if (currentOrder.payments && currentOrder.payments.length > 0) {
      currentOrder.payments.forEach((payment) => {
        alreadyPaidAmount = alreadyPaidAmount + Number(payment.amount);
      });
    }
    if (Number(currentOrder.payableAmount) < alreadyPaidAmount) {
      setRefundFlag(true);
      setPayableAmount(alreadyPaidAmount - Number(currentOrder.payableAmount));
    } else {
      setPayableAmount(Number(currentOrder.payableAmount) - alreadyPaidAmount);
    }
  }, [currentOrder, currentOrder.payments]);

  useEffect(() => {
    if (
      pinPads?.length > 0 &&
      selectedPinPadId &&
      selectedPinPadId !== '000-000-000' &&
      shouldAutoPay
    ) {
      setIsProcessTerminalPayment(true);
    }
    // eslint-disable-next-line
  }, [shouldAutoPay]);

  useEffect(() => {
    if (failedLinklyByCancelPaymentResponse) {
      if (!isCancelledByPayByCash) {
        setPaymentAnimation(2);
      }
      setFailedLinklyByCancelPaymentResponse(false);
    }
  }, [failedLinklyByCancelPaymentResponse, isCancelledByPayByCash]);

  const modalCls = classNames({
    'booking-modal': true,
    show: true,
    slideDown: customHide,
  });

  const disableButtonStyle = isPlacingOrder ? { opacity: 0.6 } : {};

  const checkMarkSuccessLottie = {
    loop: false,
    autoplay: true,
    animationData: checkMarkSuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getEasyCashOptions = () => {
    return getCashOptions(payableAmount, 4);
  };

  const getCustomPaymentOptions = () => {
    return getCashOptions(payableAmount, 1);
  };

  const handleCancelTransaction = () => {
    if (selectedPinPadId !== '000-000-000') {
      dispatch(setIsCancelTransactionApiLoading(true));
      _auth.currentUser.getIdToken(true).then((accessToken) => {
        fetch(`${nextOrderConfigURLSydney}/cancelTransaction`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            storeId: storeConfig?.storeId,
            countryIdentifier: storeConfig.countryIdentifier,
            storePinPadId: selectedPinPadId,
            sessionId: sessionId,
            serviceId: serviceId,
            merchantCode: currentSelectedTerminal?.merchantCode,
            key: '0',
          }),
        })
          .then((response) => {
            dispatch(setIsCancelTransactionApiLoading(false));
            return response.json();
          })
          .then((result) => {
            console.log(result);
          });
      });
    }
  };

  const handleClosePaymentViewModal = () => {
    if (
      currentSelectedTerminal?.merchantCode === 600 &&
      !isOrdersPage &&
      windcaveTransactionRef
    ) {
      handleWindcaveButton('CANCEL', 'B2', windcaveTransactionRef);
    }
    if (isTerminalOrderInProgress) {
      // cancel request
      handleCancelTransaction();
    }
    !isPlacingOrder &&
      !isCancelTransactionApiLoading &&
      isMounted &&
      toggleModal(false);
  };

  const handleWindcaveButton = async (
    value,
    buttonName,
    transactionReference
  ) => {
    setWindcaveActionButtonPressed(true);
    setWindcaveActionButtonPressedName(buttonName);
    const requestObj = {
      txnId: transactionReference,
      station: selectedPinPadId,
      user: windcaveUser,
      val: value,
      name: buttonName,
    };
    const windCaveActionResponse = await fetch(
      `${nextOrderConfig?.baseUrl}/windcave/v1/buttonAction`,
      {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestObj),
      }
    );

    const windCaveActionResponseJson = await windCaveActionResponse.json();

    if (windCaveActionResponseJson?.code === 201) {
      //windave inprogress
    }

    setWindcaveActionButtonPressed(false);
    setWindcaveActionButtonPressedName('');
  };

  const handleOrderSubmit = async ({
    event,
    paymentType,
    isCashAmount = false,
    changeAmount = null,
    cashAmount = null,
    paymentTypeId = '',
    paymentTypeName = '',
  }) => {
    //Place order here now
    //props.orderTimes
    const isAutoTerminalPayment =
      paymentType === paymentTypeEnum.CARD_IN_STORE &&
      selectedPinPadId !== '000-000-000';
    let payment = {
      storePinPadId: selectedPinPadId,
      merchantCode: 400,
    };
    if (isAutoTerminalPayment && currentSelectedTerminal) {
      payment = {
        ...payment,
        merchantCode: currentSelectedTerminal?.merchantCode,
      };
    }
    if (isPlacingOrder) {
      return;
    }
    if (currentOrder && orderTimes) {
      let currentOd = {
        ...currentOrder,
      };
      if (isAutoTerminalPayment) setIsTerminalOrderInProgress(true);
      setPaymentAnimation(0);
      setErrorMessage('');
      setIsPlacingOrder(true);

      currentOd.paymentType = paymentType;

      if (paymentTypeId) {
        currentOd.paymentTypeId = paymentTypeId;
      }
      if (paymentTypeName) {
        currentOd.paymentTypeName = paymentTypeName;
      }

      currentOd.drawerId = selectedDrawerId;

      // if (currentOd.selectedVoucher) delete currentOd.selectedVoucher;
      // if (currentOd.selectedDiscount) delete currentOd.selectedDiscount;

      let requestObj = {
        order: {
          ...currentOd,
          storeId: storeConfig?.storeId,
          countryIdentifier: storeConfig.countryIdentifier,
          restaurantId: storeConfig.restaurantId,
          doNotUpdateOrderStatus: false,
        },
        payment: {
          ...payment,
          type: paymentType,
          amount: parseInt((Number(payableAmount) * 100).toFixed(2), 10),
          isRefund,
          sessionId,
          serviceId,
        },
      };

      if (
        paymentType === paymentTypeEnum.CASH &&
        selectedDrawerId !== '000-000-000'
      ) {
        const printObj = {
          configUrl: nextOrderConfigURL,
          printerId: selectedDrawerPrinterId,
          storeId: storeConfig?.storeId,
          restaurantId: storeConfig.restaurantId,
          openCashDrawer: true,
          timeZone: storeConfig.timeZone,
          printReceipt: false,
        };
        printBillCommand(printObj);
      }
      let el = event?.target?.classList;
      if (el) el.add('active');
      let fetchOrderPaymentUnsubscribe;
      if (requestObj?.order['idempotent-key']) {
        delete requestObj?.order['idempotent-key'];
      }
      const idempotentKey = currentOd['idempotent-key'];
      fetch(`${nextOrderConfig?.cloudFunctionUrl}/placeOrder3DSecure`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'idempotent-key': idempotentKey,
        },
        body: JSON.stringify(requestObj),
      })
        .then(async (response) => {
          const result = await response.json();
          console.log('placeOrder3dSecure completed');
          console.log('order id', result?.orderId);
          if (result.orderId && paymentType !== paymentTypeEnum.CASH) {
            const orderRef = _firestore
              .collection('Orders')
              .doc(result.orderId);
            fetchOrderPaymentUnsubscribe = orderRef.onSnapshot(
              async (querySnapshot) => {
                let obj = querySnapshot.data();

                const dataFromCache = !!querySnapshot.metadata.fromCache;
                console.log('listener data', obj);
                if (
                  !dataFromCache &&
                  (obj?.isError || obj?.orderStatus !== '-1')
                ) {
                  fetchOrderPaymentUnsubscribe();
                  if (obj?.isError || !result?.orderId) result.success = false;
                  await postOrderProcess(
                    result,
                    event,
                    result.orderId,
                    paymentType,
                    isCashAmount,
                    changeAmount,
                    cashAmount
                  );
                }
              }
            );
          } else {
            await postOrderProcess(
              result,
              event,
              result.orderId,
              paymentType,
              isCashAmount,
              changeAmount,
              cashAmount
            );
          }
        })
        .catch(async (e) => {
          console.log('catch method', e);
          await postOrderProcess(
            false,
            event,
            '',
            paymentType,
            isCashAmount,
            changeAmount,
            cashAmount
          );
        });
    } else if (currentOrder && isOrdersPage) {
      const settleOrderRequestObject = {
        ...payment,
        orderId: currentOrder._id,
        paymentType,
        drawerId: selectedDrawerId,
        amount: Number(payableAmount.toFixed(2)),
        isRefund,
        storeId: storeConfig?.storeId,
        countryIdentifier: storeConfig.countryIdentifier,
        currencyCode: storeConfig.currencyCode,
        sessionId,
        serviceId,
      };
      if (paymentTypeId) {
        settleOrderRequestObject.paymentTypeId = paymentTypeId;
      }
      if (paymentTypeName) {
        settleOrderRequestObject.paymentTypeName = paymentTypeName;
      }
      let el = event?.target?.classList;
      if (el) el.add('active');

      if (isAutoTerminalPayment) setIsTerminalOrderInProgress(true);
      setPaymentAnimation(0);
      setErrorMessage('');
      setIsPlacingOrder(true);

      if (
        paymentType === paymentTypeEnum.CASH &&
        selectedDrawerId !== '000-000-000'
      ) {
        const printObj = {
          configUrl: nextOrderConfigURL,
          printerId: selectedDrawerPrinterId,
          orderId: currentOrder._id,
          storeId: storeConfig?.storeId,
          restaurantId: storeConfig.restaurantId,
          openCashDrawer: true,
          timeZone: storeConfig.timeZone,
          printReceipt: false,
        };
        await printBillCommand(printObj);
      }
      fetch(`${nextOrderConfig?.cloudFunctionUrl}/settleOrder`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(settleOrderRequestObject),
      }).then(async (response) => {
        const result = await response.json();

        await postOrderProcess(
          result,
          event,
          currentOrder._id,
          paymentType,
          isCashAmount,
          changeAmount,
          cashAmount
        );
      });
    }
  };

  const handlePostCashOrderProcess = (changeAmount, cashAmount, orderId) => {
    handleCashAmountChange(changeAmount.toFixed(2));
    handleCashPaid(Number(cashAmount).toFixed(2));
    openCashAmountPaidModal(orderId);
    setIsTerminalOrderInProgress(false);
  };

  const postOrderProcess = async (
    result,
    event,
    orderId,
    paymentType,
    isCashAmount,
    changeAmount,
    cashAmount
  ) => {
    if (result.success) {
      if (selectedDrawerPrinterId) {
        setOrderNumber(result.orderNumber ? result.orderNumber : 0);
        const printObj = {
          configUrl: nextOrderConfigURL,

          printerId: selectedDrawerPrinterId,
          orderId: orderId,
          storeId: storeConfig?.storeId,
          restaurantId: storeConfig.restaurantId,
          openCashDrawer: !!openDrawerForCardTransactions,
          timeZone: storeConfig.timeZone,
          printReceipt,
        };

        const { success } =
          (printReceipt ||
            paymentType === paymentTypeEnum.CASH ||
            !!openDrawerForCardTransactions) &&
          (await printBillCommand(printObj));

        if (success) {
          if (isCashAmount) {
            handlePostCashOrderProcess(changeAmount, cashAmount, orderId);
          } else {
            if (isPrintReceiptAutomatically) {
              handleTerminalAnimation(event);
            } else {
              const paymentName = selectedCustomPaymentType
                ? selectedCustomPaymentType.name
                : 'Credit Card';
              dispatch(
                updateCashModalTitle(`${paymentName} Payment Successful`)
              );
              openCashAmountPaidModal(orderId);
            }
          }
        } else {
          handleTerminalAnimation(event);
        }
      } else {
        if (isCashAmount) {
          handlePostCashOrderProcess(changeAmount, cashAmount, orderId);
        } else {
          if (isPrintReceiptAutomatically) {
            handleTerminalAnimation(event);
          } else {
            const printObj = {
              configUrl: nextOrderConfigURL,

              printerId: selectedDrawerPrinterId,
              orderId: orderId,
              storeId: storeConfig?.storeId,
              restaurantId: storeConfig.restaurantId,
              openCashDrawer: true,
              timeZone: storeConfig.timeZone,
              printReceipt: false,
            };

            openDrawerForCardTransactions && (await printBillCommand(printObj));
            dispatch(updateCashModalTitle('Credit Card Payment Successful'));
            openCashAmountPaidModal(orderId);
          }
        }
      }
    } else {
      // Failed placing or settling order
      let el = event?.target?.classList;
      if (el) el.remove('active');

      const uniqueSessionId = uuidv4();
      setSessionId(uniqueSessionId);
      setServiceId(getServiceID(uniqueSessionId));
      setIsPlacingOrder(false);

      if (!result?.message?.includes('OPERATOR CANCELLED')) {
        setPaymentAnimation(2);
      } else {
        setFailedLinklyByCancelPaymentResponse(true);
      }
      setIsTerminalOrderInProgress(false);
      setErrorMessage(result.message || '');
    }

    localStorage.removeItem('transactionReference');
  };

  const handleTerminalAnimation = (event) => {
    let el = event?.target?.classList;

    if (el) el.add('active');

    setPaymentAnimation(1);

    let timer1 = setTimeout(() => {
      setCustomHide(false);
      setPaymentAnimation(0);

      if (el) el.remove('active');

      isMounted && toggleModal();
      if (!isKiosk) {
        if (currentOrder.isEditOrder || currentOrder.iteration) {
          history.push('/orders');
        } else {
          dispatch(authenticateStaff(false));
        }
      } else {
        history.push(kiosk_route);
      }

      setIsPlacingOrder(false);

      clearTimeout(timer1);
    }, 3000);
  };

  const handleRetryIntegratedTerminal = () => {
    setPaymentAnimation(0);
    handleMarkAsPaidByCard();
  };

  const [showCashAmountModal, setShowCashAmountModal] = useState(false);

  const handleAmountClick = () => {
    setShowCashAmountModal(true);
  };

  const handleCloseCashAmountModal = () => {
    dispatch(toggleBlurEffect(false));
    setShowCashAmountModal(false);
  };

  const handleTenderCash = (cashAmount) => {
    handleCloseCashAmountModal();
    handleCashOptionsClick({ cashAmount });
  };

  const handleCustomPaymentModalSubmit = (cashAmount) => {
    handleCloseCashAmountModal();
    handleCustomPaymentOptionsClick({ cashAmount });
  };

  const handleCashOptionsClick = ({ event, cashAmount }) => {
    const changeAmount = Number(cashAmount) - Number(payableAmount);
    handleOrderSubmit({
      event,
      paymentType: '1',
      isCashAmount: true,
      changeAmount,
      cashAmount,
    });
  };

  const handleCustomPaymentOptionsClick = (event, cashAmount = null) => {
    let changeAmount = null;
    if (cashAmount) {
      changeAmount = Number(cashAmount) - Number(payableAmount);
    }
    handleOrderSubmit({
      event,
      paymentType: selectedCustomPaymentType.type,
      paymentTypeId: selectedCustomPaymentType._id,
      paymentTypeName: selectedCustomPaymentType.name,
      ...(cashAmount ? { cashAmount } : null),
      changeAmount,
    });
  };

  const handleMarkAsPaidByCard = (event) => {
    if (!isPlacingOrder) {
      if (currentSelectedTerminal?.merchantCode === 600 && !isOrdersPage) {
        //windcave payment

        if (
          selectedPinPadId &&
          selectedPinPadId !== '000-000-000' &&
          !!windcaveUser &&
          windcaveUser !== 'None'
        ) {
          setIsPlacingOrder(true);
          setTransactionStatusId(-1);
          setWindcaveErrorMessage('');
          setWindcaveInfoMessage('');
          setWindcaveButtonOne('');
          setWindcaveButtonTwo('');
          let payment = {
            storePinPadId: selectedPinPadId,
            merchantCode: 600,
            type: '3',
            user: windcaveUser,
            isRefund: false,
          };

          if (currentOrder) {
            let currentOd = { ...currentOrder };
            currentOd.paymentType = '3';
            currentOd.drawerId = selectedDrawerId;

            let requestObj = {
              order: {
                ...currentOd,
                storeId: storeConfig?.storeId,
                countryIdentifier: storeConfig.countryIdentifier,
                restaurantId: storeConfig.restaurantId,
                doNotUpdateOrderStatus: false,
              },
              payment: {
                ...payment,
                amount: parseInt((Number(payableAmount) * 100).toFixed(2), 10),
                sessionId,
                serviceId,
              },
            };

            if (requestObj?.order['idempotent-key']) {
              delete requestObj?.order['idempotent-key'];
            }

            const idempotentKey = currentOd['idempotent-key'];
            fetch(`${nextOrderConfig?.cloudFunctionUrl}/placeOrder3DSecure`, {
              method: 'post',
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'idempotent-key': idempotentKey,
              },
              body: JSON.stringify(requestObj),
            }).then(async (response) => {
              const result = await response.json();

              const transactionReference =
                result?.payload?.preOrder?.transactionReference;
              let listenOrderPaymentStatusUnsubscribe;
              let listenWindcaveStatusUnsubscribe;
              if (result.orderId) {
                if (!!transactionReference) {
                  localStorage.setItem(
                    'transactionReference',
                    transactionReference
                  );
                  //listen to windcave status

                  setWindcaveTransactionRef(transactionReference);
                  const windCaveStatusRef = _firestore
                    .collection('WindcaveStatus')
                    .doc(transactionReference);

                  listenWindcaveStatusUnsubscribe =
                    windCaveStatusRef.onSnapshot((querySnapshot) => {
                      let windCaveStatusObj = querySnapshot.data();

                      const dataFromCache = !!querySnapshot.metadata.fromCache;

                      if (!dataFromCache) {
                        if (windCaveStatusObj?.transactionReference) {
                          setWindcaveTransactionRef(
                            windCaveStatusObj.transactionReference
                          );
                          setTransactionStatusId(
                            windCaveStatusObj?.transactionStatusId
                          );
                          setWindcaveInfoMessage(
                            `${windCaveStatusObj['displayLine1'] || ''} ${
                              windCaveStatusObj['displayLine2'] || ''
                            }`
                          );

                          setWindcaveButtonOne(
                            windCaveStatusObj['button1'] || ''
                          );
                          setWindcaveButtonTwo(
                            windCaveStatusObj['button2'] || ''
                          );

                          if (windCaveStatusObj?.complete === 1) {
                            //payment is complete
                            listenWindcaveStatusUnsubscribe();
                            //listen to order data
                            const orderRef = _firestore
                              .collection('Orders')
                              .doc(result.orderId);

                            listenOrderPaymentStatusUnsubscribe =
                              orderRef.onSnapshot(async (querySnapshot) => {
                                let obj = querySnapshot.data();

                                const dataFromCache =
                                  !!querySnapshot.metadata.fromCache;
                                if (
                                  !dataFromCache &&
                                  (obj?.isError || obj?.orderStatus !== '-1')
                                ) {
                                  listenOrderPaymentStatusUnsubscribe();
                                  if (obj?.isError || !result?.orderId) {
                                    result.success = false;
                                    setIsPlacingOrder(false);
                                  }
                                  await postOrderProcess(
                                    result,
                                    event,
                                    result.orderId,
                                    '3',
                                    false,
                                    null,
                                    null
                                  );
                                }
                              });
                          }

                          if (!windCaveStatusObj?.station) {
                            // windcave transaction cancelled
                            listenWindcaveStatusUnsubscribe();
                            listenOrderPaymentStatusUnsubscribe();
                            setWindcaveErrorMessage(
                              'Windcave Transaction Cancelled'
                            );
                            setIsPlacingOrder(false);
                            setWindcaveButtonTwo('');
                          }
                        }
                      }
                    });
                } else {
                  setIsPlacingOrder(false);
                  setWindcaveErrorMessage(
                    'Windcave Transaction Reference not found'
                  );
                }
              } else {
                //PlaceOrder3dSecure failed
                await postOrderProcess(
                  result,
                  event,
                  '',
                  '3',
                  false,
                  null,
                  null
                );
              }
            });
          } else {
            setIsPlacingOrder(false);
            setWindcaveErrorMessage('Order not found');
          }
        } else {
          setIsPlacingOrder(false);
          setWindcaveErrorMessage('Windcave User or Pinpad not found');
        }
      } else if (
        currentSelectedTerminal?.merchantCode === 600 &&
        isOrdersPage
      ) {
        //Settle order Api on Order list screen
        let payment = {
          storePinPadId: selectedPinPadId,
          merchantCode: 600,
          user: windcaveUser,
        };

        if (
          selectedPinPadId &&
          selectedPinPadId !== '000-000-000' &&
          !!windcaveUser &&
          windcaveUser !== 'None'
        ) {
          if (currentOrder) {
            const settleOrderRequestObject = {
              ...payment,
              orderId: currentOrder._id,
              paymentType: '3',
              drawerId: selectedDrawerId,
              amount: Number(payableAmount.toFixed(2)),
              isRefund,
              storeId: storeConfig?.storeId,
              countryIdentifier: storeConfig.countryIdentifier,
              currencyCode: storeConfig.currencyCode,
              sessionId,
              serviceId,
            };

            let el = event?.target?.classList;
            if (el) el.add('active');

            setIsTerminalOrderInProgress(true);
            setPaymentAnimation(0);
            setErrorMessage('');
            setIsPlacingOrder(true);
            setTransactionStatusId(-1);
            setWindcaveErrorMessage('');
            setWindcaveInfoMessage('');
            setWindcaveButtonOne('');
            setWindcaveButtonTwo('');

            fetch(`${nextOrderConfig?.cloudFunctionUrl}/settleOrder`, {
              method: 'post',
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settleOrderRequestObject),
            })
              .then(async (response) => {
                const result = await response.json();
                const transactionReference = result?.transactionReference;

                if (result?.success && !!transactionReference) {
                  //Settle Order API Successful

                  let listenWindcaveStatusUnsubscribe;

                  //Save TransactionReference in Local Storage for recovery Mode
                  localStorage.setItem(
                    'transactionReference',
                    transactionReference
                  );
                  setWindcaveTransactionRef(transactionReference);

                  //Listen to windcave status object
                  const windCaveStatusRef = _firestore
                    .collection('WindcaveStatus')
                    .doc(transactionReference);

                  listenWindcaveStatusUnsubscribe =
                    windCaveStatusRef.onSnapshot(async (querySnapshot) => {
                      let windCaveStatusObj = querySnapshot.data();

                      const dataFromCache = !!querySnapshot.metadata.fromCache;

                      if (!dataFromCache) {
                        if (windCaveStatusObj?.transactionReference) {
                          setWindcaveTransactionRef(
                            windCaveStatusObj.transactionReference
                          );
                          setTransactionStatusId(
                            windCaveStatusObj?.transactionStatusId
                          );
                          setWindcaveInfoMessage(
                            `${windCaveStatusObj['displayLine1'] || ''} ${
                              windCaveStatusObj['displayLine2'] || ''
                            }`
                          );

                          setWindcaveButtonOne(
                            windCaveStatusObj['button1'] || ''
                          );
                          setWindcaveButtonTwo(
                            windCaveStatusObj['button2'] || ''
                          );

                          if (windCaveStatusObj?.complete === 1) {
                            //payment is complete
                            windCaveCompleteStatusRef.current = 1;

                            if (windCaveStatusObj?.AP === 1) {
                              //Transaction SuccessFull
                              listenWindcaveStatusUnsubscribe();

                              //listen to order data
                              const orderRef = _firestore
                                .collection('Orders')
                                .doc(currentOrder?._id);

                              setTimeout(async () => {
                                const doc = await orderRef.get();
                                const obj = doc.data();

                                const paidAmount = obj?.payments?.reduce(
                                  (accu, currValue) => {
                                    return accu + currValue?.amount;
                                  },
                                  0
                                );
                                const payableAmount = Number(
                                  obj?.payableAmount
                                );

                                const isOrderPaidCompletely =
                                  Number(paidAmount.toFixed(2)) ===
                                  Number(payableAmount.toFixed(2));

                                if (obj?.isError || isOrderPaidCompletely) {
                                  windCaveCompleteStatusRef.current = 0;

                                  if (obj?.isError) {
                                    result.success = false;
                                    setIsPlacingOrder(false);
                                  }

                                  await postOrderProcess(
                                    result,
                                    event,
                                    currentOrder?._id,
                                    '3',
                                    false,
                                    null,
                                    null
                                  );
                                }
                              }, 3000);
                            } else if (windCaveStatusObj?.AP === 0) {
                              //Transaction Failed
                              listenWindcaveStatusUnsubscribe();
                              setIsPlacingOrder(false);
                              await postOrderProcess(
                                { success: false },
                                event,
                                currentOrder?._id,
                                '3',
                                false,
                                null,
                                null
                              );
                            }
                          }

                          if (!windCaveStatusObj?.station) {
                            // windcave transaction cancelled
                            listenWindcaveStatusUnsubscribe();

                            setWindcaveErrorMessage(
                              'Windcave Transaction Cancelled'
                            );
                            setIsPlacingOrder(false);
                            setWindcaveButtonTwo('');
                          }
                        }
                      }
                    });
                } else {
                  //Settle Order API Failed
                  await postOrderProcess(
                    { success: false },
                    event,
                    '',
                    '3',
                    false,
                    null,
                    null
                  );
                }
              })
              .catch(async (e) => {
                //Settle Order API Failed
                await postOrderProcess(
                  { success: false },
                  event,
                  '',
                  '3',
                  false,
                  null,
                  null
                );
              });
          }
        }
      } else {
        //it could be Mark as paid by card or Adyen Payment
        handleOrderSubmit({
          event,
          paymentType: paymentTypeEnum.CARD_IN_STORE,
        });
      }
    }
  };

  const handlePayByCashButtonClick = () => {
    setIsPayingByCash(true);
    if (isTerminalOrderInProgress) {
      setIsCancelledByPayByCash(true);
      setIsPlacingOrder(false);
      handleCancelTransaction();
    }
  };

  const handleCustomPaymentTypeClick = (paymentType) => {
    setSelectedCustomPaymentType(paymentType);
    if (isTerminalOrderInProgress) {
      setIsCancelledByPayByCash(true);
      setIsPlacingOrder(false);
      handleCancelTransaction();
    }
  };

  const handleCashAmountModalButtonStatus = (total) => {
    if (Number(total) >= Number(payableAmount)) {
      return true;
    }
    return false;
  };

  const handleOnSplitClick = () => {
    if (isTerminalOrderInProgress) {
      handleCancelTransaction();
    }
    !isPlacingOrder && handleSplitPaymentClick();
  };

  return (
    <div id="payment-view-modal" className={modalCls}>
      <div className="modal-wrap">
        <div
          className={classNames('modal-box', {
            'center-position':
              !isPayingByCash && !selectedCustomPaymentType && isKiosk,
          })}
        >
          <div className="modal-body">
            <button
              type="button"
              className="close-modal-btn hover-highlight"
              onClick={() => handleClosePaymentViewModal()}
              title="Close"
            />
            {!isKiosk && (
              <button
                type="button"
                className="btn round split-btn hover-highlight"
                title="Split"
                onClick={handleOnSplitClick}
                style={
                  isPlacingOrder ? { opacity: 0.6, pointerEvents: 'none' } : {}
                }
              >
                <span className="inner-btn-box">Split Payments</span>
              </button>
            )}
            <h3 className="terminal-total-price">{`${
              payableAmount
                ? getAmountWithCountryCurrency({
                    amount: Number(payableAmount),
                    locale: `en-${storeCountryIdentifier}`,
                    currencyCode: currencySymbol,
                  })
                : ''
            }`}</h3>
            {paymentAnimation === 2 ? (
              <PaymentModalFailScreen
                pinPads={pinPads}
                selectedPinPadId={selectedPinPadId}
                handlePinPadChange={() => {}}
                setModeToManual={() => {}}
                handleRetryIntegratedTerminal={handleRetryIntegratedTerminal}
              />
            ) : (
              <div className="terminal-wrap">
                <p className="terminal-text">
                  {paymentAnimation === 0
                    ? `${isRefund ? 'Refund' : 'Ready to Pay'}`
                    : ''}
                  {paymentAnimation === 1 ? 'Order Placed' : ''}
                  {orderNumber !== 0 && `(order#${orderNumber})`}
                  {paymentAnimation === 2 && errorMessage ? errorMessage : ''}
                </p>
                <div className="terminal-box">
                  <img
                    src={terminalPng}
                    alt="Terminal"
                    className={paymentAnimation !== 0 ? 'hide' : ''}
                  />
                  <div
                    className="terminal-action-box"
                    disabled={isPlacingOrder}
                    style={disableButtonStyle}
                  >
                    {paymentAnimation === 1 ? (
                      <Lottie
                        style={{
                          position: 'relative',
                          top: '-20px',
                          left: '-15px',
                        }}
                        options={checkMarkSuccessLottie}
                        height={192}
                        width={192}
                        eventListeners={[
                          {
                            eventName: 'complete',
                            callback: () => {},
                          },
                        ]}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            )}

            {paymentAnimation === 0 ? (
              <div className="payment-services-box">
                {!windcaveButtonOne && !windcaveButtonTwo && (
                  <button
                    type="button"
                    onClick={(event) => handleMarkAsPaidByCard(event)}
                  >
                    {isPlacingOrder ? (
                      <PosIconLoading mainColor="#5eb602" />
                    ) : (
                      <span>
                        {isProcessTerminalPayment
                          ? 'Press to trigger payment terminal'
                          : 'Mark as paid by card'}
                      </span>
                    )}
                  </button>
                )}

                {windcaveButtonOne && (
                  <button
                    type="button"
                    onClick={() =>
                      handleWindcaveButton(
                        windcaveButtonOne,
                        'B1',
                        windcaveTransactionRef
                      )
                    }
                    className="windcaveButton"
                    disabled={windcaveActionButtonPressed}
                  >
                    <div className="windcaveButtonItem">
                      {windcaveActionButtonPressed &&
                      windcaveActionButtonPressedName === 'B1' ? (
                        <LoadingIcon width={20} color="#5eb602" />
                      ) : (
                        <CheckIcon width={25} />
                      )}
                      <span className="windcaveButtonText">
                        {windcaveButtonOne}
                      </span>
                    </div>
                  </button>
                )}

                {windcaveButtonTwo && (
                  <button
                    type="button"
                    onClick={() =>
                      handleWindcaveButton(
                        windcaveButtonTwo,
                        'B2',
                        windcaveTransactionRef
                      )
                    }
                    className={clsx('windcaveButton', {
                      ['windcaveButtonDisabled']: windcaveActionButtonPressed,
                    })}
                    disabled={windcaveActionButtonPressed}
                  >
                    <div className="windcaveButtonItem">
                      {windcaveActionButtonPressed &&
                      windcaveActionButtonPressedName === 'B2' ? (
                        <LoadingIcon width={20} color="#f40000" />
                      ) : (
                        <XRoundedIcon width={20} className="windcaveItemIcon" />
                      )}

                      <span
                        className="windcaveButtonText"
                        style={{ color: '#f40000' }}
                      >
                        {windcaveButtonTwo}
                      </span>
                    </div>
                  </button>
                )}
              </div>
            ) : null}
          </div>

          <div
            className={classNames('modal-pre-footer', {
              hide: !isPayingByCash && !selectedCustomPaymentType && isKiosk,
            })}
          >
            {isPlacingOrder &&
              !windcaveErrorMessage &&
              windcaveInfoMessage !== 'Approved' && (
                <div className="paymentWarningContainer">
                  {!!windcaveInfoMessage && transactionStatusId !== 8 ? (
                    <PosIconLoading mainColor="#f39c12" size={25} />
                  ) : (
                    <WarningIcon width={25} />
                  )}

                  <span style={{ marginLeft: '10px' }}>
                    {!!windcaveInfoMessage
                      ? windcaveInfoMessage
                      : ` Payment is in process. Please don't close the modal.`}
                  </span>
                </div>
              )}
            {!!windcaveErrorMessage && (
              <div className="paymentWarningContainer">
                <WarningIcon width={25} color="#f40000" />
                <span style={{ marginLeft: '10px', color: '#f40000' }}>
                  {windcaveErrorMessage}
                </span>
              </div>
            )}
            <h2
              className="modal-title"
              style={
                !isPayingByCash && !selectedCustomPaymentType
                  ? { visibility: 'hidden' }
                  : null
              }
            >
              {selectedCustomPaymentType ? selectedCustomPaymentType.name : ''}
              {isPayingByCash ? 'Cash' : ''}
            </h2>
            {!isKiosk && (
              <>
                {!isPayingByCash && !selectedCustomPaymentType ? (
                  <div className="payment-types-container">
                    <div
                      className="retry-side-div cursor-pointer"
                      onClick={handlePayByCashButtonClick}
                    >
                      Pay by cash
                    </div>
                    {paymentTypesForOrderType?.map((paymentType) => (
                      <div
                        className="retry-side-div cursor-pointer"
                        onClick={() =>
                          handleCustomPaymentTypeClick(paymentType)
                        }
                        key={paymentType._id}
                      >
                        {paymentType.name}
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {isCancelTransactionApiLoading ? (
                      <div className="voided-reasons-loading">
                        <PosIconLoading mainColor="#5eb602" />
                      </div>
                    ) : (
                      <div className="cash-cards">
                        {Number(payableAmount) > 0 &&
                          isPayingByCash &&
                          getEasyCashOptions(payableAmount)?.map(
                            (cashOption, index) => {
                              return (
                                <PaymentAmountOption
                                  currency={storeConfig.currency}
                                  handlePaymentOptionClick={
                                    handleCashOptionsClick
                                  }
                                  disabled={isPlacingOrder}
                                  style={disableButtonStyle}
                                  key={index}
                                  cashOption={cashOption}
                                />
                              );
                            }
                          )}
                        {Number(payableAmount) > 0 &&
                          selectedCustomPaymentType &&
                          getCustomPaymentOptions(payableAmount)?.map(
                            (cashOption, index) => {
                              return (
                                <PaymentAmountOption
                                  currency={storeConfig.currency}
                                  handlePaymentOptionClick={
                                    handleCustomPaymentOptionsClick
                                  }
                                  disabled={isPlacingOrder}
                                  style={disableButtonStyle}
                                  key={index}
                                  cashOption={cashOption}
                                />
                              );
                            }
                          )}
                        <button
                          type="button"
                          className="cash-card cash-amount-btn"
                          disabled={isPlacingOrder}
                          style={{ ...disableButtonStyle, marginLeft: 'auto' }}
                          onClick={handleAmountClick}
                        >
                          <span className="title">Amount</span>
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal-overlay"
        onClick={() => {
          handleClosePaymentViewModal();
        }}
      />
      {showCashAmountModal ? (
        <CashAmountModal
          modalStatus={showCashAmountModal}
          toggleModal={handleCloseCashAmountModal}
          handleTenderClick={
            selectedCustomPaymentType
              ? handleCustomPaymentModalSubmit
              : handleTenderCash
          }
          showAmountInTenderButton={selectedCustomPaymentType}
          handleShouldShowButton={handleCashAmountModalButtonStatus}
        />
      ) : null}
    </div>
  );
};

export default PaymentViewModal;
