import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconConfirmed = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-confirmed': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M10.818 16.576l-3.404-3.402-.773.772 3.786 3.793a.544.544 0 0 0 .772 0l9.257-9.25-.773-.772zm13.585 7.555c0 .15-.122.272-.272.272H1.86a.272.272 0 0 1-.272-.272V1.86c0-.15.122-.272.272-.272H24.13c.15 0 .272.122.272.272zM24.141.5H1.86C1.109.5.5 1.108.5 1.859V24.14A1.359 1.359 0 0 0 1.859 25.5H24.14a1.359 1.359 0 0 0 1.359-1.359V1.86A1.359 1.359 0 0 0 24.141.5z"
            />
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness * 0.4 : 0.4}
              strokeMiterlimit="20"
              d="M10.818 16.576l-3.404-3.402-.773.772 3.786 3.793a.544.544 0 0 0 .772 0l9.257-9.25-.773-.772zm13.585 7.555c0 .15-.122.272-.272.272H1.86a.272.272 0 0 1-.272-.272V1.86c0-.15.122-.272.272-.272H24.13c.15 0 .272.122.272.272zM24.141.5H1.86C1.109.5.5 1.108.5 1.859V24.14A1.359 1.359 0 0 0 1.859 25.5H24.14a1.359 1.359 0 0 0 1.359-1.359V1.86A1.359 1.359 0 0 0 24.141.5z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default PosIconConfirmed.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
