export const getIndexByValue = (val, arr) => {
  let newIndex = 0;

  if (!arr.length || !val) return Number(newIndex);

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === val) {
      newIndex = i;
    }
  }

  return Number(newIndex);
};
