import React from 'react';
import './UberDriverModal.css';

export const HeaderSkeleton = () => {
  return (
    <div className="skeletonContainer">
      <div className="headingMainTextSkeleton" />
      <div className="headingSubTextSkeleton" />
      <div className="headingSecondSubTextSkeleton" />
    </div>
  );
};
