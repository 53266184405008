import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PosIcon } from './PosIcon';

export const PosIconCook = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-cook': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="29"
        viewBox="0 0 25 29"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M14.636 22.87l.948.637a3.797 3.797 0 0 1-3.103 1.59 3.797 3.797 0 0 1-3.102-1.59l.937-.637a2.658 2.658 0 0 0 2.16 1.117 2.658 2.658 0 0 0 2.16-1.117zm7.575-4.895c-.003-.947-.75-1.733-1.717-1.808a.565.565 0 0 1-.573-.558v-.703a14.949 14.949 0 0 1-7.44 1.841 14.952 14.952 0 0 1-7.44-1.786v.704a.565.565 0 0 1-.573.558c-.946.073-1.685.827-1.717 1.752.055.997.91 1.772 1.934 1.752.316 0 .573.25.573.558.155 3.808 3.366 6.817 7.274 6.817 3.909 0 7.12-3.009 7.275-6.817 0-.308.256-.558.572-.558.996-.006 1.807-.782 1.832-1.752zM5.039 11.532v2.1a13.384 13.384 0 0 0 7.437 2.055 13.384 13.384 0 0 0 7.437-2.055v-2.1h-.206a4.845 4.845 0 0 1-2.083-.458l.504-1.005c1.58.725 3.465.24 4.474-1.15a3.456 3.456 0 0 0-.373-4.503 3.67 3.67 0 0 0-4.605-.435.582.582 0 0 1-.72-.1 5.524 5.524 0 0 0-4.428-2.198c-1.751 0-3.397.817-4.428 2.198a.582.582 0 0 1-.721.1 3.67 3.67 0 0 0-4.604.465 3.456 3.456 0 0 0-.339 4.507c1.02 1.383 2.91 1.854 4.485 1.116l.504 1.005c-.662.32-1.39.484-2.128.48zm18.316 6.445c-.018 1.4-1.058 2.59-2.472 2.826-.284 4.328-3.964 7.697-8.408 7.697-4.444 0-8.124-3.369-8.408-7.697-1.357-.238-2.36-1.367-2.41-2.711-.05-1.345.868-2.54 2.204-2.874v-1.284h.034v-2.592C1.635 10.69.211 8.517.55 6.242.889 3.967 2.89 2.28 5.246 2.283a4.907 4.907 0 0 1 2.198.48A6.697 6.697 0 0 1 12.481.5c1.937 0 3.777.827 5.036 2.264a4.9 4.9 0 0 1 4.684.17c1.425.847 2.295 2.357 2.299 3.985.006 2.07-1.395 3.894-3.434 4.468v3.775c1.328.324 2.268 1.48 2.29 2.815zm-6.868 1.547c0 .62-.512 1.122-1.145 1.122a1.134 1.134 0 0 1-1.144-1.122c0-.62.512-1.122 1.144-1.122.633 0 1.145.502 1.145 1.122zM9.62 20.646a1.134 1.134 0 0 1-1.145-1.122c0-.62.513-1.122 1.145-1.122s1.145.502 1.145 1.122c0 .62-.513 1.122-1.145 1.122z"
            />
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness * 0.2 : 0.2}
              strokeMiterlimit="20"
              d="M14.636 22.87l.948.637a3.797 3.797 0 0 1-3.103 1.59 3.797 3.797 0 0 1-3.102-1.59l.937-.637a2.658 2.658 0 0 0 2.16 1.117 2.658 2.658 0 0 0 2.16-1.117zm7.575-4.895c-.003-.947-.75-1.733-1.717-1.808a.565.565 0 0 1-.573-.558v-.703a14.949 14.949 0 0 1-7.44 1.841 14.952 14.952 0 0 1-7.44-1.786v.704a.565.565 0 0 1-.573.558c-.946.073-1.685.827-1.717 1.752.055.997.91 1.772 1.934 1.752.316 0 .573.25.573.558.155 3.808 3.366 6.817 7.274 6.817 3.909 0 7.12-3.009 7.275-6.817 0-.308.256-.558.572-.558.996-.006 1.807-.782 1.832-1.752zM5.039 11.532v2.1a13.384 13.384 0 0 0 7.437 2.055 13.384 13.384 0 0 0 7.437-2.055v-2.1h-.206a4.845 4.845 0 0 1-2.083-.458l.504-1.005c1.58.725 3.465.24 4.474-1.15a3.456 3.456 0 0 0-.373-4.503 3.67 3.67 0 0 0-4.605-.435.582.582 0 0 1-.72-.1 5.524 5.524 0 0 0-4.428-2.198c-1.751 0-3.397.817-4.428 2.198a.582.582 0 0 1-.721.1 3.67 3.67 0 0 0-4.604.465 3.456 3.456 0 0 0-.339 4.507c1.02 1.383 2.91 1.854 4.485 1.116l.504 1.005c-.662.32-1.39.484-2.128.48zm18.316 6.445c-.018 1.4-1.058 2.59-2.472 2.826-.284 4.328-3.964 7.697-8.408 7.697-4.444 0-8.124-3.369-8.408-7.697-1.357-.238-2.36-1.367-2.41-2.711-.05-1.345.868-2.54 2.204-2.874v-1.284h.034v-2.592C1.635 10.69.211 8.517.55 6.242.889 3.967 2.89 2.28 5.246 2.283a4.907 4.907 0 0 1 2.198.48A6.697 6.697 0 0 1 12.481.5c1.937 0 3.777.827 5.036 2.264a4.9 4.9 0 0 1 4.684.17c1.425.847 2.295 2.357 2.299 3.985.006 2.07-1.395 3.894-3.434 4.468v3.775c1.328.324 2.268 1.48 2.29 2.815zm-6.868 1.547c0 .62-.512 1.122-1.145 1.122a1.134 1.134 0 0 1-1.144-1.122c0-.62.512-1.122 1.144-1.122.633 0 1.145.502 1.145 1.122zM9.62 20.646a1.134 1.134 0 0 1-1.145-1.122c0-.62.513-1.122 1.145-1.122s1.145.502 1.145 1.122c0 .62-.513 1.122-1.145 1.122z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconCook.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
