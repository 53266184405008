import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getUIModifierKeys, getUIClassesFromProps } from '../modifiersMapping';
import './bem-button.scss';
import { PosIconLoading } from '../../../assets/icons/PosIconLoading';

export const ButtonUI = forwardRef(
  (
    { link, children, onClick, style, icon, disabled, loading, ...props },
    ref
  ) => {
    const btnCls = getUIClassesFromProps('bem-btn', {
      disabled,
      loading,
      ...props,
    });

    return (
      <>
        {link ? (
          <NavLink
            ref={ref}
            to={link}
            title={children}
            className={btnCls}
            onClick={onClick}
            style={style}
            disabled={disabled}
            data-position={props.iconPosition}
          >
            <ButtonContent icon={icon} title={children} loading={loading} />
          </NavLink>
        ) : (
          <button
            ref={ref}
            type="button"
            title={children}
            className={btnCls}
            disabled={disabled}
            onClick={onClick}
            style={style}
            data-position={props.iconPosition}
          >
            <ButtonContent icon={icon} title={children} loading={loading} />
          </button>
        )}
      </>
    );
  }
);

function ButtonContent({ icon, title, loading }) {
  return (
    <>
      {icon}
      <span className="bem-btn__text">{title}</span>
      {loading && <PosIconLoading />}
    </>
  );
}

ButtonUI.defaultProps = {
  children: '',
  link: '',
  icon: false,
  iconPosition: 'right',
  styleType: 'primary',
  sizeType: 'm',
  buttonInnerSpacing: undefined,
  fontSize: undefined,
  fontWeight: 'medium',
  rounded: 'full',
  fullWidth: false,
  disabled: false,
  loading: false,
  modifierClassName: '',
  style: {},
  onClick: () => {},
};

ButtonUI.propTypes = {
  children: PropTypes.string.isRequired,
  link: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  iconPosition: PropTypes.oneOf(getUIModifierKeys('iconPosition')),
  styleType: PropTypes.oneOf(getUIModifierKeys('styleType')),
  sizeType: PropTypes.oneOf(getUIModifierKeys('sizeType')),
  buttonInnerSpacing: PropTypes.oneOf(getUIModifierKeys('buttonInnerSpacing')),
  fontSize: PropTypes.oneOf(getUIModifierKeys('fontSize')),
  fontWeight: PropTypes.oneOf(getUIModifierKeys('fontWeight')),
  rounded: PropTypes.oneOf(getUIModifierKeys('rounded')),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  modifierClassName: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
