import { kiosk_continue_route } from 'constants/routesConsts';
import { OrderProvider } from 'hooks/useOrder';
import KioskRoutes from 'pages/Kiosk/KioskRoutes';
import KioskPickupRoutes from 'pages/Kiosk/KioskPickupRoutes';
import KioskDeliveryRoutes from 'pages/Kiosk/KioskDeliveryRoutes';
import { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

export default function KioskOrderScreen({ kioskFlowType }) {
  const history = useHistory();
  const [isIdle, setIsIdle] = useState();

  const [zoom, setZoom] = useState(1);

  const { enableAllOrderTypeKiosk } = useSelector(
    (state) => state.dashboardReducer?.orderSetup
  );

  const timer = useRef(null);
  useLayoutEffect(() => {
    const handleResize = () => {
      const { innerHeight, innerWidth } = window;

      if (innerHeight > 1000 && innerWidth > 1280) {
        let height = innerHeight / 1000;
        height = height < 1.3 ? height : 1.3;
        setZoom(height);
      } else {
        setZoom(1);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timeOut = timer.current;

    timeOut = setTimeout(() => {
      history.push(kiosk_continue_route);
    }, 60000); // need restore

    return () => {
      clearTimeout(timeOut);
    };
    // eslint-disable-next-line
  }, [isIdle]);

  const resetTimer = () => {
    setIsIdle((prevState) => !prevState);
  };

  return (
    <OrderProvider>
      <div
        style={{
          zoom,
        }}
        className="main-area-wrap kiosk-screen"
        onKeyDown={resetTimer}
        onClick={resetTimer}
        onMouseMove={resetTimer}
        onTouchStart={resetTimer}
        onTouchMove={resetTimer}
      >
        {(kioskFlowType === 'kioskWalkIn' || !enableAllOrderTypeKiosk) && (
          <KioskRoutes />
        )}
        {kioskFlowType === 'kioskPickup' && enableAllOrderTypeKiosk && (
          <KioskPickupRoutes />
        )}
        {kioskFlowType === 'kioskDelivery' && enableAllOrderTypeKiosk && (
          <KioskDeliveryRoutes />
        )}
      </div>
    </OrderProvider>
  );
}
