import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DriversHeader } from '../../components/Drivers/DriversHeader';
import { DeliveriesBox } from '../../components/Drivers/DeliveriesBox';
import { DriversBox } from '../../components/Drivers/DriversBox';
import { DriversMap } from '../../components/Drivers/DriversMap';
import { DriverBatches } from '../../components/Drivers/DriverBatches';
import DriverDeliveriesDetails from '../../components/Drivers/DriverDeliveriesDetails';
import { Route, Switch } from 'react-router-dom';

import { fetchOrderWaitingObj } from '../Orders/actions';
import {
  fetchActiveDriverCashBalance,
  fetchActiveDriverLocations,
  fetchActiveDriverRunOrders,
  fetchActiveDriverRuns,
  fetchActiveDrivers,
  fetchActiveDriverShifts,
  fetchUnAssignedOrders,
} from './actions';
import DriverCashFloat from './DriverCashFloat';
import DriverCashFloatViewDeliveries from './DriverCashFloatViewDeliveries';
import {
  loadDrawers,
  loadPinpads,
  loadTerminalSettings,
} from '../Dashboard/action';
import { _firestore } from '../../firebase';
import { setBatchOrders } from 'store/batchOrders/actions';

const Drivers = () => {
  const dispatch = useDispatch();
  const storeId = useSelector(
    (state) => state.dashboardReducer.storeConfig?.storeId
  );
  useEffect(() => {
    dispatch(fetchOrderWaitingObj());
    dispatch(fetchActiveDrivers());
    dispatch(fetchActiveDriverLocations());
    dispatch(fetchActiveDriverRuns());
    dispatch(fetchActiveDriverShifts());
    dispatch(fetchActiveDriverRunOrders());
    dispatch(fetchUnAssignedOrders());
    dispatch(fetchActiveDriverCashBalance());
    dispatch(loadPinpads());
    dispatch(loadDrawers());
    dispatch(loadTerminalSettings());
  }, [dispatch]);

  useEffect(() => {
    if (!storeId) return;
    const from_date = new Date();
    from_date.setHours(0, 0, 0, 0);
    const to_date = new Date();
    to_date.setHours(23, 59, 59, 999);

    const unsubscribe = _firestore
      .collection('OrderDeliveryBatches')
      .where('storeId', '==', storeId)
      .where('createdAt', '>=', from_date)
      .where('createdAt', '<=', to_date)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        const dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ ...doc.data(), id: doc.id });
        });
        dispatch(setBatchOrders(dataArray));
      });

    return () => {
      unsubscribe?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]);
  return (
    <>
      <Switch>
        <Route exact path="/drivers/deliveries">
          <DriversHeader />
          <DeliveriesBox />
        </Route>
        <Route exact path="/drivers/all">
          <DriversHeader />
          <DriversBox />
        </Route>
        <Route exact path="/drivers/batches">
          <DriversHeader />
          <DriverBatches />
        </Route>
        <Route exact path="/drivers/map">
          <DriversHeader />
          <DriversMap />
        </Route>
        <Route exact path="/drivers">
          <DriversHeader />
          <DeliveriesBox />
        </Route>
        <Route exact path="/drivers/deliveries/driver/:driverId">
          <DriversHeader />
          <DriverDeliveriesDetails />
        </Route>
        <Route exact path="/drivers/:driverId/cash-float">
          <DriverCashFloat />
        </Route>
        <Route exact path="/drivers/:driverId/cash-float/view-deliveries">
          <DriverCashFloatViewDeliveries />
        </Route>
      </Switch>
    </>
  );
};

export default Drivers;
