import React from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { MenuItem } from '../MenuItems/MenuItem';
import { PosIconLeftChevron } from '../../assets/icons/PosIconLeftChevron';
import { ScrollableBoxUI } from '../../UI/components';

const FourQuarterMenuItems = ({ fourQuarterData, parentPage, backLink, onItemClick }) => {
  const boxCls = clsx({
    'main-area-header': true,
    'hide-shadow specials-items': true,
    'hide-shadow specials': true,
  });

  const itemsWrapCls = clsx({
    'main-area-body-wrap': true,
    'specials-items': true,
  });
  return (
    <div className="main-area" id="main-area">
      <div className="main-area-inner">
        <div className={boxCls}>
          <Link to={backLink} title="Back" className="specials-items-back-btn">
            <PosIconLeftChevron mainColor="#363636" darkModeColor="#ffffff" />
          </Link>
          <h1 className="category-title">Select a quarter pizza</h1>
        </div>

        <div className={itemsWrapCls}>
          <ScrollableBoxUI>
            <div className="main-area-body">
              <div className="food-cards">
                {fourQuarterData.menuItems?.map((item, i) => (
                  <MenuItem key={i} item={item} parentPage={parentPage} onItemClick={onItemClick} />
                ))}
              </div>
            </div>
          </ScrollableBoxUI>
        </div>
      </div>
    </div>
  );
};

export default FourQuarterMenuItems;
