const applyAutoVoucher = (currentOrder, autoVouchers) => {
  if (!(currentOrder.voucherId && currentOrder.voucherId !== '')) {
    if (
      autoVouchers && //this.props.home.autoVouchers &&
      autoVouchers.length > 0 //this.props.home.autoVouchers.length > 0
    ) {
      let selectedVoucher = autoVouchers[0];
      currentOrder.voucherId = selectedVoucher._id;
      currentOrder.selectedVoucher = selectedVoucher;
    }
  }
  return currentOrder;
};

export default applyAutoVoucher;
