import CashAmountSelectedModal from 'components/CashAmountSelectedModal';
import DisableSpecialDiscountModal from 'components/DisableSpecialDiscountModal';
import FourQuarterMenuItemsWrapper from 'components/FourQuarterMenuItems/FourQuarterMenuItemsWrapper';
import FourQuarterViewWrapper from 'components/FourQuarterView';
import HalfHalfMenuItemsWrapper from 'components/HalfHalfMenuItems/HalfHalfMenuItemsWrapper';
import HalfHalfViewWrapper from 'components/HalfHalfView';
import { ItemView } from 'components/ItemView';
import { ItemViewCustomise } from 'components/ItemViewCustomise';
import { MenuItems } from 'components/MenuItems';
import { NavigationBar } from 'components/NavigationBar';
import OrderBackModal from 'components/OrderBackModal';
import { OrderBar } from 'components/OrderBar';
import CartItemActionsModal from 'components/OrderBar/ItemViewModal/CartItemActionsModal';
import PaymentModal from 'components/PaymentModal';
import PaymentViewModal from 'components/PaymentViewModal';
import SpecialsView from 'components/SpecialsView';
import SpecialsFourQuarterMenuItems from 'components/SpecialsView/SpecialsFourQuarterMenuItems';
import SpecialsFourQuarterView from 'components/SpecialsView/SpecialsFourQuarterView';
import SpecialsHalfHalfMenuItems from 'components/SpecialsView/SpecialsHalfHalfMenuItems';
import SpecialsHalfHalfView from 'components/SpecialsView/SpecialsHalfHalfView';
import SpecialsMenuItems from 'components/SpecialsView/SpecialsMenuItems';
import {
  MENU_ORDER_FROM,
  OrderTypeBasepathEnum,
  OrderTypeIdEnum,
  OrderTypeTitleEnum,
} from 'configuration/enums';
import { OrderProvider } from 'hooks/useOrder';
import { ItemDetailsModal } from 'modals/ItemDetailsModal';
import {
  authenticateStaff,
  loadDrawers,
  loadPinpads,
  loadTables,
  loadTerminalSettings,
  resetCurrentFourQuarterData,
  resetCurrentHalfHalfData,
  resetMenuItem,
  setCashAmountChange,
  setCashPaid,
  setDisableSpecialDiscountModal,
  setOrderBackModal,
  setOrderTypeConfig,
  updateCurrentOrder,
} from 'pages/Dashboard/action';
import SplitPaymentsRoot from 'components/SplitPayments';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getOrderTimeArray } from 'util/getTimeSlots';
import { _auth } from '../../firebase';
import { loadPaymentTypes } from '../Dashboard/thunks';
import { setOrders } from 'pages/Orders/newOrdersReducer';
import { hi } from 'date-fns/esm/locale';

class DineIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basePath: '/dinein',
      isShowPaymentModal: false,
      isShowSplitPaymentModal: false,
      paymentModalType: 'paymentModal',
      isAddingToTable: false,
    };
  }

  componentDidMount() {
    const storeConfig = this.props.storeConfig;
    this.props.setOrderTypeConfig({
      basePath: OrderTypeBasepathEnum.DINE_IN,
      title: OrderTypeTitleEnum.DINE_IN,
      orderTypeId: OrderTypeIdEnum.DINE_IN,
    });

    if (!this.props.currentOrder.tableNumber) {
      this.props.history.push('/table-arrangement');
    }

    this.props.updateCurrentOrder({
      orderType: OrderTypeIdEnum.DINE_IN,
      orderFrom:
        this.props.currentOrder?.orderFrom === '1'
          ? MENU_ORDER_FROM.web
          : MENU_ORDER_FROM.pos,

      addressLocation: storeConfig.addressLocation,
      name: storeConfig.name,
      address: storeConfig.address,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.pickUpTimes && this.props.shifts && this.props.orderSetup) {
      let pickUpTimes = getOrderTimeArray(
        this.props.orderSetup ? this.props.orderSetup : {},
        this.props.storeConfig,
        OrderTypeIdEnum.DINE_IN
      );
      this.setState({ pickUpTimes });
    }
  }

  handleOpenPaymentModal = () => {
    this.setState({
      isPaymentLoading: true,
    });
    if (this.props.currentOrder.isSplit) {
      this.handleSplitPaymentClick();
    } else if (
      this.props.currentOrder.menuItems?.length &&
      this.props.currentOrder.payableAmount
    ) {
      this.setState({
        isShowPaymentModal: true,
        paymentModalType: 'paymentViewModal',
      });
    }
  };

  handleAddOrderToTable = (
    onSuccess = () => {},
    { hasNotChanged, calledCourses } = {}
  ) => {
    const currentOrder = this.props.currentOrder;
    const orderPayments = this.props.orderPayments;
    let alreadyPaidAmount = 0;
    if (orderPayments && orderPayments.length > 0) {
      orderPayments.forEach((payment) => {
        alreadyPaidAmount = alreadyPaidAmount + Number(payment.amount);
      });
    }
    let payableAmount = Number(currentOrder.payableAmount) - alreadyPaidAmount;
    const orderTimes = this.state.pickUpTimes;
    const storeConfig = this.props.storeConfig;
    const nextOrderConfigURL = this.props.nextOrderConfigURL;
    if (currentOrder.menuItems?.length && currentOrder.payableAmount) {
      this.setState({ isAddingToTable: true });
      if (currentOrder && orderTimes) {
        const { floorLayoutId } = currentOrder;
        let currentOd = {
          ...currentOrder,
        };
        const updatedMenuItem = currentOd?.menuItems?.map((item) => {
          return {
            ...item,
            courseStatus: item?.courseStatus || 'send',
          };
        });

        currentOd.paymentType = '0';
        currentOd.paymentCollectionType = '1';
        if (currentOd.selectedVoucher) delete currentOd.selectedVoucher;
        // if (currentOd.selectedDiscount) delete currentOd.selectedDiscount;

        if (hasNotChanged) {
          currentOd.iteration = currentOd.iteration - 1;
          currentOd.prevIteration = currentOd.prevIteration - 1 || undefined;
        }

        let requestObj = {
          order: {
            ...currentOd,
            storeId: storeConfig.storeId,
            countryIdentifier: storeConfig.countryIdentifier,
            restaurantId: storeConfig.restaurantId,
            menuItems: updatedMenuItem,
            // doNotUpdateOrderStatus: false,
          },
          payment: {
            type: '0',
            amount: parseInt((Number(payableAmount) * 100).toFixed(2), 10),
          },
        };

        if (requestObj?.order['idempotent-key']) {
          delete requestObj?.order['idempotent-key'];
        }

        const idempotentKey = currentOd['idempotent-key'];
        // Add To Table
        fetch(`${nextOrderConfigURL}/placeOrder3DSecure`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'idempotent-key': idempotentKey,
          },
          body: JSON.stringify({
            ...requestObj,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then(async (result) => {
            if (floorLayoutId) {
              localStorage.setItem('dineInLayoutToDefaultTo', floorLayoutId);
            }

            const updateOrder = {
              ...requestObj.order,
              _id: result?.orderId,
              isOrderProcessing: true,
            };
            const updatedAllOrdersMap = {
              ...this.props?.orders?.allOrdersMap,
              [result?.orderId]: {
                ...updateOrder,
              },
            };

            this.props.dispatch(
              setOrders({
                allOrdersMap: {
                  ...updatedAllOrdersMap,
                },
              })
            );

            await onSuccess(result);
            // if (result.success) {
            //   handleTerminalAnimation(event);
            // } else {
            //   el.remove('active');
            //   setIsPlacingOrder(false);
            // }
          })
          .finally(() => {
            this.setState({ isAddingToTable: false });
            this.props.history.push('/table-arrangement');
          });
      }
    }
  };

  handlePaymentModalClose = () => {
    if (this.state.paymentModalType !== 'cashAmountSelectedModal') {
      this.setState({ isShowPaymentModal: false, paymentModalType: '' });
      this.props.history.push('/table-arrangement');
    }
  };

  handleCashAmountSelectedModal = (orderId = '') => {
    this.setState({
      paymentModalType: 'cashAmountSelectedModal',
      selectedOrderId: orderId,
    });
  };

  handleSplitPaymentClick = () => {
    this.setState({
      isShowSplitPaymentModal: true,
      isShowPaymentModal: false,
      paymentModalType: '',
    });
  };

  handleSplitPaymentModalClose = () => {
    this.setState({
      isShowSplitPaymentModal: false,
      isShowPaymentModal: false,
      paymentModalType: '',
      isPaymentLoading: false,
    });
  };

  render() {
    const { payForDineinWhenOrderding } = this.props.orderSetup;
    return (
      <OrderProvider>
        <div className="main-area-wrap">
          <NavigationBar basePath={this.state.basePath} />
          <Switch>
            <Route exact path="/dinein/menuitem/:itemId/:itemType/:modifierId">
              {/* View item image, name and ingredient in large view */}
              <ItemDetailsModal />
              {/* Item details view opened based on item selected in right side of view */}
              <ItemView parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/dinein/specials/:specialsId">
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsView parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/dinein/specials/:specialsId/:categoryIndex">
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsMenuItems parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/dinein/specials/:specialsId/:categoryIndex/half-half"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsHalfHalfView parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/dinein/specials/:specialsId/:categoryIndex/half-half/:halfIndex"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsHalfHalfMenuItems parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/dinein/specials/:specialsId/:categoryIndex/four-quarter"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsFourQuarterView parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/dinein/specials/:specialsId/:categoryIndex/four-quarter/:fourQuarterIndex"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <SpecialsFourQuarterMenuItems parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/dinein/category/:categoryId/half-half">
              {/* Item details view opened based on item selected in right side of view */}
              <HalfHalfViewWrapper parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/dinein/category/:categoryId/half-half/:halfIndex"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <HalfHalfMenuItemsWrapper parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/dinein/category/:categoryId/four-quarter">
              {/* Item details view opened based on item selected in right side of view */}
              <FourQuarterViewWrapper parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/dinein/category/:categoryId/four-quarter/:fourQuarterIndex"
            >
              {/* Item details view opened based on item selected in right side of view */}
              <FourQuarterMenuItemsWrapper parentPage={this.state.basePath} />
            </Route>
            <Route
              exact
              path="/dinein/menuitem/:itemId/customization/ingredient/:customizeSlug"
            >
              <ItemViewCustomise parentPage={this.state.basePath} />
            </Route>
            <Route exact path="/dinein/order">
              <MenuItems parentPage={this.state.basePath} />
            </Route>
          </Switch>
          <OrderBar
            orderPayments={this.props.orderPayments}
            orderTimes={this.state.pickUpTimes}
            parentPage={this.state.basePath}
            onPayClick={
              payForDineinWhenOrderding
                ? this.handleOpenPaymentModal
                : this.handleAddOrderToTable
            }
            orderSetup={this.props.orderSetup}
            storeConfig={this.props.storeConfig}
            title="Dine-In"
            isOrderTypeDinein
            isAddingToTable={this.state.isAddingToTable}
          />
          {this.props.currentOrder?.menuItems.length > 0 && (
            <CartItemActionsModal basePath={this.state.basePath} />
          )}
          {this.state.isShowPaymentModal && (
            <PaymentModal
              modalStatus={this.state.isShowPaymentModal}
              toggleModal={() => {
                this.setState({ isShowPaymentModal: false });
              }}
              openPaymentViewModal={this.handleOpenPaymentViewModal}
              currentOrder={this.props.currentOrder}
              orderPayments={this.props.orderPayments}
              storeConfig={this.props.storeConfig}
              type="dinein"
            />
          )}
          {this.state.isShowPaymentModal &&
            this.state.paymentModalType === 'paymentViewModal' && (
              <PaymentViewModal
                toggleModal={this.handlePaymentModalClose}
                // openPaymentViewModal={this.handleOpenPaymentViewModal}
                currentOrder={this.props.currentOrder}
                orderTimes={this.state.pickUpTimes}
                openCashAmountPaidModal={this.handleCashAmountSelectedModal}
                handleCashAmountChange={this.props.handleCashAmountChange}
                handleCashPaid={this.props.handleCashPaid}
                handleSplitPaymentClick={this.handleSplitPaymentClick}
              />
            )}
          {this.state.isShowPaymentModal &&
            this.state.paymentModalType === 'cashAmountSelectedModal' && (
              <CashAmountSelectedModal
                orderId={this.state.selectedOrderId}
                toggleModal={this.handlePaymentModalClose}
                modalStatus={
                  this.state.isShowPaymentModal &&
                  this.state.paymentModalType === 'cashAmountSelectedModal'
                }
                cashAmountChange={this.props.cashAmountChange}
                cashPaid={this.props.cashPaid}
              />
            )}
          {this.state.isShowSplitPaymentModal ? (
            <SplitPaymentsRoot
              currentOrder={this.props.currentOrder}
              closeFlowStatus={this.handleSplitPaymentModalClose}
            />
          ) : null}
          {this.props.orderBackModal ? (
            <OrderBackModal
              modalStatus={this.props.orderBackModal}
              toggleModal={this.props.handleOrderExitModalClose}
              redirectUrl="/table-arrangement"
            />
          ) : null}
          {this.props.disableSpecialDicountModal ? (
            <DisableSpecialDiscountModal
              modalStatus={this.props.disableSpecialDicountModal}
              toggleModal={
                this.props.handleDisableSpecialDiscountExitModalClose
              }
            />
          ) : null}
        </div>
      </OrderProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    storeConfig: state.dashboardReducer.storeConfig,
    currentOrder: state.dashboardReducer.currentOrder,
    shifts: state.dashboardReducer.shifts,
    orderSetup: state.dashboardReducer.orderSetup,
    menuItems: state.dashboardReducer.menuItems,
    orderPayments: state.dashboardReducer.orderPayments,
    cashAmountChange: state.dashboardReducer.cashAmountChange,
    cashPaid: state.dashboardReducer.cashPaid,
    orderBackModal: state.dashboardReducer.orderBackModal,
    disableSpecialDicountModal:
      state.dashboardReducer.disableSpecialDicountModal,
    nextOrderConfigURL: state.dashboardReducer.nextOrderConfigURL,
    orders: state.newOrdersReducer?.orders,
    filters: state.newOrdersReducer?.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    setOrderTypeConfig: (orderTypeConfig) =>
      dispatch(setOrderTypeConfig(orderTypeConfig)),
    loadPinpads: (storeId) => dispatch(loadPinpads(storeId)),
    loadDrawers: (storeId) => dispatch(loadDrawers(storeId)),
    loadTerminalSettings: (storeId) => dispatch(loadTerminalSettings(storeId)),
    loadPaymentTypes: () => dispatch(loadPaymentTypes()),
    updateCurrentOrder: (currentOrder) =>
      dispatch(updateCurrentOrder(currentOrder)),
    authenticateStaff: (flag) => dispatch(authenticateStaff(flag)),
    handleCashAmountChange: (cashAmountChange) =>
      dispatch(setCashAmountChange(cashAmountChange)),
    handleCashPaid: (cashPaid) => dispatch(setCashPaid(cashPaid)),
    handleCategoryChange: () => {
      dispatch(resetCurrentHalfHalfData());
      dispatch(resetCurrentFourQuarterData());
      dispatch(resetMenuItem());
    },
    loadTables: (storeId) => dispatch(loadTables(storeId)),
    handleOrderExitModalClose: () => dispatch(setOrderBackModal(false)),
    handleDisableSpecialDiscountExitModalClose: () =>
      dispatch(setDisableSpecialDiscountModal(false)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DineIn);
