import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScrollableBoxUI } from '../../UI/components';

function ItemCourse({
  setUpdatedItem,
  menuItem: possibleHalfItemOrCommonItem,
  coursesSorted,
}) {
  const menuItem = possibleHalfItemOrCommonItem.isHalfHalf
    ? possibleHalfItemOrCommonItem.items[0]
    : possibleHalfItemOrCommonItem;

  const [touched, setTouched] = useState(false);

  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );

  const firstCourse = coursesSorted[0] || null;

  const handlePress = useCallback(
    ({ _id, name }) => {
      if (!touched && firstCourse) {
        setTouched(true);
      }
      if (menuItem.isHalfHalf || menuItem.isFourQuarters) {
        setUpdatedItem({
          ...possibleHalfItemOrCommonItem,
          items: menuItem.items.map((i) => ({
            ...i,
            courseId: _id,
            courseName: name,
          })),
          iteration: currentOrder.iteration,
        });
      } else {
        if (menuItem?.courseId === _id) {
          setUpdatedItem({
            ...menuItem,
            courseId: null,
            courseName: null,
            iteration: currentOrder.iteration,
          });
        } else {
          setUpdatedItem({
            ...menuItem,
            courseId: _id,
            courseName: name,
            iteration: currentOrder.iteration,
          });
        }
      }
    },
    // eslint-disable-next-line
    [currentOrder.iteration, setUpdatedItem, menuItem, touched, firstCourse]
  );

  const hasCourses = useMemo(
    () => currentOrder.menuItems.some(({ courseId }) => !!courseId),
    [currentOrder.menuItems]
  );

  useEffect(() => {
    if (hasCourses && !touched) {
      setTouched(true);
    }
  }, [hasCourses, touched]);

  return currentOrder?.orderType === '3' ? (
    <div className="bem-item-view-modal__group">
      <div className="bem-item-view-modal__title-row">
        <div className="bem-item-view-modal__group-title">Select Course</div>
      </div>
      <div
        className="bem-item-view-modal__row"
        style={{ overflow: 'hidden', maxHeight: 110 }}
      >
        <ScrollableBoxUI>
          <div className="bem-item-view-modal__course">
            {coursesSorted.map((item, index) => {
              return (
                <button
                  key={item._id}
                  className={clsx('bem-item-view-modal__course-button', {
                    'bem-item-view-modal__course-button--active':
                      menuItem?.courseId === item._id,
                  })}
                  onClick={() => handlePress(item)}
                >
                  {item.name}
                </button>
              );
            })}
          </div>
        </ScrollableBoxUI>
      </div>
    </div>
  ) : null;
}

export default ItemCourse;
