import './bem-button-group.scss';
import PropTypes from 'prop-types';
import { getUIClassesFromProps, getUIModifierKeys } from '../modifiersMapping';

export const ButtonGroupUI = ({ style, children, ...props }) => {
  return (
    <div
      className={getUIClassesFromProps('bem-button-group', { ...props })}
      style={style}
    >
      {children}
    </div>
  );
};

ButtonGroupUI.defaultProps = {
  style: {},
  justifyContent: 'left',
  alignItems: 'center',
  gap: 'm',
  modifierClassName: '',
  children: null,
};

ButtonGroupUI.propTypes = {
  style: PropTypes.object,
  justifyContent: PropTypes.oneOf(getUIModifierKeys('justifyContent')),
  alignItems: PropTypes.oneOf(getUIModifierKeys('alignItems')),
  gap: PropTypes.oneOf(getUIModifierKeys('gap')),
  modifierClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};
