import { useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useRouteByMenuItemType from '../../customHooks/useRouteByMenuItemType';
import {
  doesItemHaveModifiers,
  isItemWithSingleSize,
} from '../../itemProcessor';
import clsx from 'clsx';

export const MenuItem = ({
  item: initialItem,
  parentPage,
  onItemClick,
  isHalfHalf = false,
}) => {
  let item = { ...initialItem };
  const location = useLocation();
  const activeSeatId = useSelector(
    (state) => state.dashboardReducer?.activeSeatId || ''
  );
  const currentOrder = useSelector(
    (state) => state.dashboardReducer?.currentOrder || null
  );

  if (currentOrder?.orderType === '3') {
    //Dine in Order
    item = { ...item, activeSeatId: activeSeatId };
  }
  const history = useHistory();
  const [isSingleSizedItemClicked, setIsSingleSizedItemClicked] =
    useState(false);
  const link = useRouteByMenuItemType(parentPage, item);
  let animCls = clsx('card-item ', {
    'pulse-anim': isSingleSizedItemClicked,
  });

  const isSingleSizedItem = useMemo(() => isItemWithSingleSize(item), [item]);

  const handleClick = (event, item, link) => {
    if (isSingleSizedItem) {
      setIsSingleSizedItemClicked(true);
      let customTimeout = setTimeout(function () {
        setIsSingleSizedItemClicked(false);
        clearTimeout(customTimeout);
      }, 200);
    }
    !item?.isSoldOut && history.push(link);
    onItemClick(event, item, link, isSingleSizedItem);
  };

  const shouldItemBeLink = () => {
    if (isHalfHalf) {
      if (doesItemHaveModifiers(item)) {
        return true;
      }
      return false;
    } else if (isSingleSizedItem) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className={animCls} key={item._id}>
        {!shouldItemBeLink() ? (
          <span
            className=""
            title={item.name}
            onClick={(event) => handleClick(event, item, link)}
          >
            <span className="card-inner-box">
              <div style={{ position: 'relative' }}>
                <span className={'card-img-wrap'}>
                  <img src={item.urlS3} alt={item.name} />
                  {!!item?.isSoldOut && (
                    <div className="overlay">
                      <div className="soldout-tag">Sold Out</div>
                    </div>
                  )}
                </span>
                {
                  // boolean of NaN is false
                  Boolean(Number(item.upsalePrice)) && (
                    <span className="card-upsale-price">{`+$${Number(
                      item.upsalePrice
                    ).toFixed(2)}`}</span>
                  )
                }
              </div>
              <span className="card-name">{item.name}</span>
            </span>
          </span>
        ) : (
          <div onClick={(event) => handleClick(event, item, link)}>
            <span className="card-inner-box">
              <div style={{ position: 'relative' }}>
                <span className={'card-img-wrap'}>
                  <img src={item.urlS3} alt={item.name} />
                  {!!item?.isSoldOut && (
                    <div className="overlay">
                      <div className="soldout-tag">Sold Out</div>
                    </div>
                  )}
                </span>
                {
                  // boolean of NaN is false
                  Boolean(Number(item.upsalePrice)) && (
                    <span className="card-upsale-price">{`+$${Number(
                      item.upsalePrice
                    ).toFixed(2)}`}</span>
                  )
                }
              </div>
              <span className="card-name">{item.name}</span>
            </span>
          </div>
        )}
      </div>
    </>
  );
};
