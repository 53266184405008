import React from 'react';
// import '../../../assets/scss/money-count/_money-count-sidebar.scss';
import PropTypes from 'prop-types';
import { MoneyCountDenominationsMode } from '../MoneyCountDenominationsMode';
import { MoneyCountManuallyMode } from '../MoneyCountManuallyMode';

export const MoneyCountSidebar = ({
  total,
  setTotal,
  setQuantity,
  moneyCount,
  manuallyMode,
  modalStatus,
  handleToggleModal,
  toggleManuallyMode,
  activeIndex,
  setActiveDenomination,
}) => {
  return (
    <div className="money-count-sidebar">
      {manuallyMode ? (
        <MoneyCountManuallyMode
          manuallyMode={manuallyMode}
          toggleManuallyMode={toggleManuallyMode}
          total={total}
          setTotal={setTotal}
          handleToggleModal={handleToggleModal}
        />
      ) : (
        <MoneyCountDenominationsMode
          toggleManuallyMode={toggleManuallyMode}
          total={total}
          setQuantity={setQuantity}
          moneyCount={moneyCount}
          activeIndex={activeIndex}
          setActiveDenomination={setActiveDenomination}
          handleToggleModal={handleToggleModal}
        />
      )}

      {/*<div className="denominations-modal-box">
        <div className="denominations-header">
          <h2 className="denominations-title">Total</h2>
          <p className='denominations-price'>
            {
              total ?
                '$'+total.toFixed(2) :
                ''
            }
          </p>
          <Button
            title='Enter Manually'
            className='btn grey inverse'
            onClick={toggleManuallyMode}
          >Enter Manually</Button>
        </div>
        <div className="denominations-keypad-box">
          {keyList.current.map((item) => {
            const itemCls = classNames({
              "denominations-key": true,
              "hover-highlight": true,
              [`key-${item.val.toLowerCase()}`]: true,
            });

            return (
              <div key={item.id} className="denominations-key-wrap">
                <Button
                  title={item.val}
                  className={itemCls}
                  onClick={e => handleClick(e, item.val)}
                >{item.name}</Button>
              </div>
            );
          })}
        </div>
        <div className="denominations-btn-box">
          <Button className='btn' title="Finish " >Finish </Button>
        </div>
      </div>*/}
    </div>
  );
};

MoneyCountSidebar.propTypes = {
  total: PropTypes.number,
};

MoneyCountSidebar.defaultProps = {
  total: 0,
};
