import React from 'react';
import SwitchFloorTabs from '../SwitchFloorTabs';
import { SwitchButtonsGroupUI } from 'UI/components';

import { getFloorLayoutIndex } from 'notifications/utils';

const FloorLayoutTabs = ({
  editMode,
  floorsList,
  selectedFloorId,
  handleFloorTabSelection,
  handleTabSelectionInEditMode,
  handleTabDoubleSelectionInEditMode,
  handleReorder,
  handleDeleteFloorLayout,
}) => {
  const floorIndex = React.useMemo(() => {
    const floorIndex = getFloorLayoutIndex(floorsList, selectedFloorId);
    return floorIndex === -1 ? undefined : floorIndex;
  }, [floorsList, selectedFloorId]);

  if (editMode) {
    return (
      <SwitchFloorTabs
        floors={floorsList}
        selectedFloorId={selectedFloorId}
        onTabClick={handleTabSelectionInEditMode}
        onTabDoubleClick={handleTabDoubleSelectionInEditMode}
        handleReorder={handleReorder}
        handleDeleteFloorLayout={handleDeleteFloorLayout}
      />
    );
  }

  return (
    <div className="floor-layout-tabs">
      <SwitchButtonsGroupUI
        switchList={floorsList}
        activeSwitchIndex={floorIndex}
        setActiveSwitchIndex={handleFloorTabSelection}
        buttonModifierClassName="dine-in-buttons"
        spanStyleForTabs={{ style: { padding: '3px 14px' } }}
      />
    </div>
  );
};

export default FloorLayoutTabs;
