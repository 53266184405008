import React from 'react';

const UndoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    xmlSpace="preserve"
  >
    <path d="M15.2 8.3H4.8l5-4.8c.3-.3.3-.8 0-1.1C9.5 2 9 2 8.6 2.3L2.2 8.6c-.1.1-.2.3-.2.5s.1.4.2.6l6.4 6.4c.2.2.4.2.6.2.2 0 .4-.1.6-.2.3-.3.3-.8 0-1.1l-5-5.1h10.5c2.8 0 5.2 2.3 5.2 5.2s-2.3 5.2-5.2 5.2h-3c-.4 0-.8.4-.8.8s.4.8.8.8h3c3.7 0 6.8-3 6.8-6.8S19 8.3 15.2 8.3z" />
  </svg>
);

export default UndoIcon;
