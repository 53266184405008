import React, { useEffect, useRef } from 'react';

const MapModalWrapper = ({ iframeWidth, iframeHeight, children }) => {
  let refIframe = useRef();
  useEffect(() => {
    refIframe.current.classList.add('show');
  }, []);

  return (
    <div style={{ weight: iframeWidth, height: iframeHeight }} ref={refIframe}>
      {children}
    </div>
  );
};

export default MapModalWrapper;
