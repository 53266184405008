import React, { useRef } from 'react';
import clsx from 'clsx';
import PriceCell from './PriceCell';

const OtherOrderItem = ({ otherItem, lightMode, showPrice }) => {
  const itemRef = useRef();

  const itemCls = clsx({
    'single-item': true,
  });

  const titleWithQuantity = `${otherItem.name}${
    otherItem.quantity > 1 ? `- ${otherItem.quantity}` : ''
  } `;

  return (
    <div className={itemCls} key={otherItem._id} ref={itemRef}>
      <div className={`single-item-table`}>
        {!lightMode && (
          <div className="single-item-cell img-cell">
            <div className="single-img-wrap">
              <img
                src={otherItem?.imageUrl ? otherItem.imageUrl : ''}
                alt={otherItem.name}
              />
            </div>
          </div>
        )}

        <div className="single-item-cell info-cell">
          <div className="single-body">
            <h4 className="single-title">
              {titleWithQuantity}{' '}
              <span className="other-item-tag">Other Item</span>
            </h4>
            {otherItem?.notes && (
              <div className="single-actions">
                <span title={otherItem?.notes || ''}>
                  {otherItem?.notes ? `Notes: ${otherItem?.notes}.` : ''}
                </span>
              </div>
            )}

            {otherItem?.options?.length > 0 &&
              otherItem?.options?.map((option) => {
                let instructions = `${option?.name}: `;

                if (option?.options?.length > 0) {
                  option?.options?.forEach((option, index) => {
                    instructions = `${instructions}${index === 0 ? '' : ','} ${
                      option?.name
                    }`;
                  });
                }
                return (
                  <div className="single-actions">
                    <span title={option?.name || ''}>
                      {instructions ? ` ${instructions}.` : ''}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="single-item-price-group">
          <div className="single-item-price-group__inner">
            {showPrice && (
              <PriceCell
                payablePrice={otherItem?.price}
                price={otherItem?.price}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherOrderItem;
