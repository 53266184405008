
export default function SplitBillOption({
  onClick,
  item,
  isChecked,
  getTitle,
  getDescription,
  id = 'id',
  subClass,
  disabled,
  buttonStyle,
}) {
  const title = getTitle(item);
  const name = item[id] ?? title;
  return (
    <div className={'item-card' + (subClass ?? '')} onClick={onClick}>
      <input
        id={name}
        type="radio"
        name={name}
        value={name}
        checked={isChecked(item)}
        onChange={() => {}}
        disabled={disabled}
      />
      <label htmlFor={name}>
        <span className="inner-btn-box" style={buttonStyle}>
          <span className="inner-box">
            <h3 className="card-title">{title}</h3>
            <span className="card-discount">{getDescription(item)}</span>
          </span>
        </span>
      </label>
    </div>
  );
}
