import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PosIcon } from './PosIcon';

export const PosIconDiscount = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-discount': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 25 25"
      >
        <g>
          <path
            fill={iconColor ? iconColor : '#000'}
            stroke={iconColor ? iconColor : '#000'}
            strokeWidth={lineThickness ? lineThickness : 0}
            d="M8.438,17.331l-0.75-0.662l7.938-9l0.75,0.661L8.438,17.331z M10.999,10c0-1.103-0.897-2-2-2s-2,0.897-2,2
			s0.897,2,2,2S10.999,11.103,10.999,10z M9.999,10c0,0.551-0.449,1-1,1s-1-0.449-1-1s0.449-1,1-1S9.999,9.449,9.999,10z M16.999,15
			c0-1.103-0.897-2-2-2s-2,0.897-2,2s0.897,2,2,2S16.999,16.103,16.999,15z M15.999,15c0,0.552-0.448,1-1,1s-1-0.448-1-1
			s0.448-1,1-1S15.999,14.448,15.999,15z M14.019,23.572c0.399-0.467,0.812-0.95,1.185-1.068c0.34-0.109,0.939,0.041,1.518,0.188
			c0.876,0.224,1.867,0.475,2.591-0.045l0,0c0.723-0.519,0.799-1.539,0.866-2.438c0.044-0.592,0.09-1.204,0.303-1.494
			c0.231-0.315,0.822-0.553,1.393-0.782c0.86-0.346,1.75-0.704,2.017-1.514c0.271-0.819-0.234-1.64-0.724-2.434
			c-0.32-0.519-0.65-1.055-0.648-1.436c0.002-0.372,0.335-0.899,0.657-1.409c0.502-0.794,1.021-1.615,0.754-2.447
			c-0.257-0.8-1.134-1.162-1.982-1.512c-0.577-0.238-1.174-0.484-1.41-0.812c-0.235-0.328-0.28-0.97-0.322-1.591
			c-0.063-0.915-0.128-1.86-0.806-2.357c-0.706-0.518-1.651-0.287-2.562-0.065c-0.587,0.143-1.192,0.292-1.549,0.174
			c-0.363-0.119-0.768-0.603-1.158-1.07C13.54,0.744,12.922,0.005,12.059,0c-0.003,0-0.006,0-0.008,0c-0.85,0-1.47,0.726-2.07,1.427
			c-0.399,0.467-0.812,0.95-1.185,1.069C8.452,2.606,7.854,2.455,7.277,2.309C6.403,2.087,5.41,1.835,4.688,2.353
			C3.965,2.872,3.889,3.892,3.821,4.792c-0.044,0.592-0.09,1.205-0.304,1.495C3.286,6.601,2.696,6.838,2.125,7.068
			c-0.86,0.346-1.75,0.704-2.017,1.514c-0.27,0.819,0.236,1.64,0.725,2.434c0.319,0.519,0.65,1.055,0.648,1.436
			c-0.002,0.373-0.335,0.899-0.657,1.41c-0.502,0.793-1.021,1.614-0.753,2.446c0.256,0.8,1.133,1.162,1.981,1.512
			c0.577,0.238,1.173,0.484,1.41,0.812s0.281,0.971,0.323,1.592c0.063,0.914,0.128,1.859,0.806,2.356
			c0.704,0.515,1.647,0.286,2.562,0.064c0.587-0.143,1.194-0.289,1.55-0.174c0.362,0.119,0.767,0.603,1.158,1.07
			c0.598,0.715,1.216,1.454,2.079,1.459c0.002,0,0.005,0,0.007,0C12.797,25,13.418,24.274,14.019,23.572z M6.916,21.673
			c-0.658,0.161-1.405,0.341-1.734,0.101c-0.303-0.223-0.354-0.964-0.399-1.618c-0.052-0.762-0.106-1.549-0.509-2.107
			c-0.403-0.56-1.133-0.861-1.84-1.152c-0.607-0.251-1.296-0.535-1.411-0.893c-0.124-0.388,0.285-1.036,0.646-1.608
			c0.397-0.629,0.808-1.279,0.812-1.938c0.004-0.667-0.403-1.328-0.797-1.966C1.333,9.92,0.934,9.273,1.058,8.896
			c0.122-0.37,0.823-0.651,1.44-0.9c0.701-0.282,1.425-0.573,1.825-1.116c0.384-0.522,0.44-1.28,0.496-2.013
			c0.054-0.715,0.109-1.454,0.452-1.701c0.345-0.247,1.065-0.063,1.761,0.112C7.744,3.459,8.482,3.646,9.1,3.449
			c0.644-0.205,1.151-0.798,1.641-1.372C11.172,1.572,11.662,1,12.051,1c0,0,0.001,0,0.002,0c0.399,0.002,0.887,0.585,1.318,1.101
			c0.481,0.575,0.979,1.17,1.613,1.378c0.626,0.205,1.375,0.024,2.098-0.152c0.658-0.16,1.404-0.342,1.734-0.1
			c0.304,0.222,0.354,0.964,0.399,1.619c0.053,0.761,0.106,1.548,0.51,2.108c0.402,0.559,1.133,0.86,1.839,1.151
			c0.607,0.251,1.297,0.535,1.411,0.893c0.125,0.387-0.285,1.036-0.646,1.607c-0.397,0.629-0.809,1.28-0.812,1.938
			c-0.004,0.668,0.403,1.328,0.797,1.967c0.352,0.57,0.751,1.217,0.626,1.595c-0.122,0.369-0.822,0.651-1.44,0.899
			c-0.7,0.282-1.425,0.573-1.825,1.117c-0.383,0.521-0.439,1.279-0.494,2.013c-0.054,0.715-0.109,1.454-0.453,1.7l0,0
			c-0.345,0.248-1.064,0.064-1.761-0.112c-0.713-0.181-1.45-0.367-2.068-0.171c-0.644,0.206-1.15,0.799-1.641,1.372
			C12.826,23.428,12.337,24,11.947,24c0,0-0.001,0-0.002,0c-0.399-0.002-0.887-0.586-1.318-1.101
			c-0.481-0.575-0.979-1.17-1.613-1.378c-0.198-0.065-0.408-0.092-0.626-0.092C7.917,21.43,7.411,21.553,6.916,21.673z"
          />
        </g>
      </svg>
    </span>
  );
};

PosIconDiscount.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
