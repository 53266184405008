/* eslint-disable eqeqeq */
import { OrderTypeIdEnum } from 'configuration/enums';
import { sortBy } from 'lodash';
import { applyItemDiscountAndVoucher } from '../itemProcessor';
import { store } from '../redux-store';
import applyAutoVoucher from './applyAutoVoucher';
import calculateDeliveryFee from './calculateDeliveryFee';
import calculatePublicHolidaySurcharge from './calculatePublicHolidaySurcharge';
import detectSpecial, {
  calculateSpecialDiscountForSpecificItem,
} from './detectSpecial';
import getDiscount from './getDiscount';
import getLoyaltyDiscount from './getLoyaltyDiscount';
import getOrderAmount from './getOrderAmount';
import getRowItems from './getRowItems';
import getVoucherDiscount from './getVoucherDiscount';
// import { isObjectNotEmpty } from 'util/utils';

const orderEngine = (
  orderObj,
  allSpecials,
  orderSetup = {},
  productSetup = {},
  allSuburbs = [],
  publicHolidays = [],
  storeConfig
) => {
  /* XXX: orderEngine is causing BAD SIDE EFFECTS
   * it is now patched with JSON reparse method
   * we should consider REMOVING THIS
   * let currentOrder = orderObj;
   */

  let currentOrder = JSON.parse(JSON.stringify(orderObj));
  let loyaltyDiscount = 0;
  let rewards = [];
  let upsalePrice = 0;
  let specialDiscount = 0;
  let voucherDiscount = 0;
  let discount = 0;
  let deliveryCost = 0;
  let tax = 0;
  let creditCardCharges = orderObj.creditCardCharges || 0;
  let serviceCharge = 0;
  let serviceChargeId = '';
  const minOrderValue = currentOrder?.minOrderValue || 0;

  // Check and apply menu items discount and/or voucher
  if (Array.isArray(currentOrder.menuItems)) {
    let menuItems = currentOrder.menuItems.map((menuItem) =>
      applyItemDiscountAndVoucher(menuItem, productSetup)
    );

    currentOrder.menuItems = menuItems;
  } else {
    return;
  }

  // Pass Half/Half Setup from Props to consider half/half additional charges
  let { payableAmount, totalCost, voidedAmount } = getOrderAmount(
    currentOrder,
    productSetup
  );

  // Get Row Items
  let rowItems;
  rowItems = getRowItems(currentOrder);

  // filter rowItems
  rowItems = sortBy(
    rowItems.filter((f) => {
      return !(
        f.isSecondHalf ||
        f.isSecondQuarter ||
        f.isThirdQuarter ||
        f.isFourthQuarter ||
        f.isUsedForReward
      );
    }),
    'price'
  ).reverse();

  // detect specials
  let specialObj = detectSpecial(
    currentOrder,
    rowItems,
    allSpecials,
    orderSetup,
    publicHolidays,
    storeConfig
  );
  rowItems = specialObj.rowItems;

  // apply discount for specials
  let specials = specialObj.specials;
  if (specials && !currentOrder?.noSpecialDiscount) {
    let sDiscount = 0;
    specials.forEach((s) => {
      sDiscount = sDiscount + Number(s.discount);
      upsalePrice = upsalePrice + Number(s.upsalePrice);
    });

    if (sDiscount > 0) {
      payableAmount = Number(
        (payableAmount - (Number(sDiscount) - Number(upsalePrice))).toFixed(2)
      );
      specialDiscount = parseFloat(
        Number(sDiscount) - Number(upsalePrice)
      ).toFixed(2);
    } else {
      specials = [];
    }
  }

  currentOrder.payableAmount = payableAmount;
  currentOrder.totalCost = totalCost;
  currentOrder.voidedAmount = voidedAmount;

  // autoVouchers to be obtained from props
  let autoVouchers = [];
  currentOrder = applyAutoVoucher(currentOrder, autoVouchers);

  let itemsUsedForReward = [];
  // offers and pathway to offers to be obtained from props
  let offers = [];
  let pathwayToOffers = [];
  let lDiscount = getLoyaltyDiscount(
    currentOrder,
    rowItems,
    offers,
    pathwayToOffers,
    itemsUsedForReward,
    rewards
  );

  //apply surcharge
  if (parseFloat(creditCardCharges) > 0) {
    payableAmount = payableAmount + Number(creditCardCharges);
  }

  // apply loyalty lDiscount
  if (lDiscount > 0) {
    payableAmount = Number((payableAmount - Number(lDiscount)).toFixed(2));
    loyaltyDiscount = parseFloat(lDiscount).toFixed(2);
    rowItems = rowItems.map((p) => {
      if (itemsUsedForReward.includes(p.primaryKey)) p.isUsedForReward = true;
      else p.isUsedForReward = false;
      return p;
    });
  } else {
    rewards = [];
  }

  // User Details to be obtained from props
  let vouchersObj = getVoucherDiscount(
    currentOrder,
    payableAmount,
    rowItems,
    specialDiscount,
    allSpecials,
    orderSetup,
    publicHolidays,
    storeConfig
  );

  // no voucherFound
  if (!vouchersObj.voucherFound) {
    currentOrder.voucherId = '';
    currentOrder.voucherDiscount = '0';
    currentOrder.voucherName = '';
    currentOrder.selectedVoucher = {};
  } else {
    // Voucher Found
    let voucherDiscountObj = vouchersObj.voucherDiscountObj;
    let selectedVoucher = vouchersObj.selectedVoucher;
    voucherDiscount = Number(voucherDiscountObj.discount).toFixed(2);

    if (voucherDiscountObj.isItemOccupiedWithSpecials) {
      // Find matching special
      specials = voucherDiscountObj.specials;
      if (specials && !currentOrder?.noSpecialDiscount) {
        let discount = 0;
        payableAmount = Number(totalCost.toFixed(2));
        specials.forEach((s) => {
          discount = discount + Number(s.discount);
        });
        if (discount > 0) {
          payableAmount = Number((payableAmount - Number(discount)).toFixed(2));
          specialDiscount = parseFloat(discount).toFixed(2);
        } else {
          specials = [];
        }
      }
    }

    if (payableAmount >= Number(voucherDiscount)) {
      payableAmount = payableAmount - voucherDiscount;
    } else {
      voucherDiscount = payableAmount.toFixed(2);
      payableAmount = 0;
    }

    currentOrder.voucherDiscount = voucherDiscount.toString();
    currentOrder.voucherName = selectedVoucher.voucherCode
      ? selectedVoucher.voucherCode
      : '';

    // Reset voucher if discount is zero
    if (voucherDiscount <= 0) {
      currentOrder.voucherDiscount = '0';
      currentOrder.voucherId = '';
      currentOrder.voucherName = '';
      currentOrder.selectedVoucher = {};
    }
  }

  let discountObj = getDiscount(
    currentOrder,
    payableAmount,
    rowItems,
    specialDiscount,
    allSpecials,
    orderSetup,
    publicHolidays,
    storeConfig
  );
  // Add total discount amount to specific MenuItem

  // no discountFound
  if (!discountObj.discountFound) {
    currentOrder.discountId = '';
    currentOrder.discount = '0';
    currentOrder.discountName = '';
    currentOrder.selectedDiscount = {};
  } else {
    // Voucher Found
    let discountDiscountObj = discountObj.discountDiscountObj;
    let selectedDiscount = discountObj.selectedDiscount;
    discount = Number(discountDiscountObj.discount).toFixed(3);
    if (discountDiscountObj.isItemOccupiedWithSpecials) {
      // Find matching special
      specials = discountDiscountObj.specials;
      if (specials && !currentOrder?.noSpecialDiscount) {
        let discount = 0;
        payableAmount = Number(totalCost.toFixed(3));
        specials.forEach((s) => {
          discount = discount + Number(s.discount);
        });
        if (discount > 0) {
          payableAmount = Number((payableAmount - Number(discount)).toFixed(3));
          specialDiscount = parseFloat(discount).toFixed(3);
        } else {
          specials = [];
        }
      }
    }

    if (payableAmount >= Number(discount)) {
      payableAmount = payableAmount - discount;
    } else {
      discount = payableAmount.toFixed(3);
      payableAmount = 0;
    }

    currentOrder.discount = discount.toString();
    currentOrder.discountName = selectedDiscount.discountName
      ? selectedDiscount.discountName
      : '';

    // Reset voucher if discount is zero
    if (discount <= 0) {
      currentOrder.discountId = '';
      currentOrder.discount = '0';
      currentOrder.discountName = '';
      currentOrder.selectedDiscount = {};
    }
  }

  // Calculate Delivery Fee
  if (orderSetup.useRadiusBasedDeliveryAreaCalulation) {
    deliveryCost = currentOrder.deliveryCost || '0';
  }

  if (currentOrder.orderType === '2' && currentOrder.menuItems.length) {
    let deliveryObj = calculateDeliveryFee(
      currentOrder,
      orderSetup,
      payableAmount,
      allSuburbs
    );
    if (!deliveryObj.isDeliveryFeeApplicable) {
      deliveryCost = 0;
    } else {
      if (orderSetup.useRadiusBasedDeliveryAreaCalulation) {
        payableAmount = payableAmount + Number(currentOrder.deliveryCost);
        deliveryCost = currentOrder.deliveryCost;
      } else if (currentOrder.proccedOutsideDeliveryArea && allSuburbs[0]) {
        payableAmount = (
          Number(payableAmount) +
          Number(parseFloat(allSuburbs[0].deliveryCost).toFixed(2))
        ).toFixed(2);
        // setting first address delivery cost as default delivery cost
        deliveryCost = parseFloat(allSuburbs[0].deliveryCost).toFixed(2);
      } else if (deliveryObj.suburb) {
        let suburb = deliveryObj.suburb;
        payableAmount = payableAmount + Number(suburb.deliveryCost);
        deliveryCost = suburb.deliveryCost;
      } else {
        deliveryCost = orderSetup?.deliveryMinCost || 0;
      }
    }
  }

  // Calculate Public Holiday Surcharge
  let publicHolidaySurchargeObj = calculatePublicHolidaySurcharge(
    currentOrder,
    orderSetup,
    publicHolidays
  );

  if (!publicHolidaySurchargeObj.holidaySurchargeApplicable) {
    currentOrder.publicHolidaySurcharge = '0';
    currentOrder.publicHolidaySurchargePer = '0';
  } else {
    let publicHolidaySurcharge = 0;
    publicHolidaySurcharge =
      (payableAmount * Number(orderSetup.publicHolidaySurcharge)) / 100;
    payableAmount = payableAmount + publicHolidaySurcharge;
    currentOrder.publicHolidaySurcharge = parseFloat(
      publicHolidaySurcharge
    ).toFixed(2);
    currentOrder.publicHolidaySurchargePer = orderSetup.publicHolidaySurcharge;
  }

  // Calculate GST
  if (orderSetup && orderSetup.isDisplayGST && orderSetup.isPriceIncludesGST) {
    tax = payableAmount / 11;
    currentOrder.isDisplayGST = true;
    currentOrder.isPriceIncludesGST = true;
  } else if (
    orderSetup &&
    orderSetup.isDisplayGST &&
    !orderSetup.isPriceIncludesGST
  ) {
    tax = (payableAmount * 10) / 100;
    payableAmount = payableAmount + tax;
    currentOrder.isDisplayGST = true;
    currentOrder.isPriceIncludesGST = false;
  } else {
    currentOrder.isDisplayGST = false;
  }

  // Credit Card Processing
  // creditCards to be obtained from props
  let creditCards = [];
  if (
    orderSetup &&
    orderSetup.isCustomerLiableForOnlineCharges &&
    creditCards &&
    creditCards.length > 0 &&
    currentOrder.cardType &&
    currentOrder.paymentType === '2'
  ) {
    let matchingCards = creditCards.filter((f) => {
      return f.cardType.toString() === currentOrder.cardType.toString();
    });
    if (matchingCards.length > 0) {
      let matchingCard = matchingCards[0];
      if (currentOrder.isInternationCard) {
        creditCardCharges =
          payableAmount * (matchingCard.processingFeesInternationCard / 100) +
          matchingCard.additionalOnlineProcessingChargeInternationCard;
        payableAmount = payableAmount + creditCardCharges;
      } else {
        creditCardCharges =
          payableAmount * (matchingCard.processingFees / 100) +
          matchingCard.additionalOnlineProcessingCharge;
        payableAmount = payableAmount + creditCardCharges;
      }
    } else {
      // Charge for AMEX
      let matchingCards = creditCards.filter((f) => {
        return f.cardType.toString() === '2';
      });
      if (matchingCards.length > 0) {
        let matchingCard = matchingCards[0];
        if (currentOrder.isInternationCard) {
          creditCardCharges =
            payableAmount * (matchingCard.processingFeesInternationCard / 100) +
            matchingCard.additionalOnlineProcessingChargeInternationCard;
          payableAmount = payableAmount + creditCardCharges;
        } else {
          creditCardCharges =
            payableAmount * (matchingCard.processingFees / 100) +
            matchingCard.additionalOnlineProcessingCharge;
          payableAmount = payableAmount + creditCardCharges;
        }
      }
    }
  }

  /* =====> Calculate service charge <==== */
  if (
    (currentOrder.appliedServiceCharge ?? -1) > -1 ||
    currentOrder?.serviceChargeId
  ) {
    //manual service charge
    if (currentOrder?.serviceChargeId) {
      const selectedServiceCharge = store
        .getState()
        ?.serviceChargesReducer?.find(
          (s) => s.id === currentOrder?.serviceChargeId
        );
      serviceCharge =
        payableAmount * (Number(selectedServiceCharge?.charge) / 100);
      payableAmount = payableAmount + serviceCharge;
      serviceChargeId = currentOrder?.serviceChargeId;
    } else {
      serviceCharge =
        payableAmount * (Number(currentOrder.appliedServiceCharge) / 100);
      payableAmount = payableAmount + serviceCharge;
      serviceChargeId = currentOrder?.serviceChargeId;
      delete currentOrder.appliedServiceCharge;
    }
  } else if (
    currentOrder.orderType == OrderTypeIdEnum.DINE_IN &&
    currentOrder.dineInObjectId
  ) {
    // dinein service charge
    const numPeople =
      store
        .getState()
        ?.notificationsReducer?.dineInOccupancies?.find((occupancy) =>
          occupancy.dineInObjectIds?.includes(currentOrder.dineInObjectId)
        )?.numPeople ?? 0;

    if (numPeople) {
      const selectedServiceCharge = store
        .getState()
        ?.serviceChargesReducer?.find(
          (s) =>
            s.type === 'basedOnPartySize' &&
            numPeople > s.minPartySize &&
            s.orderTypeKeys?.includes(currentOrder.orderType)
        );

      if (selectedServiceCharge) {
        serviceCharge =
          payableAmount * (Number(selectedServiceCharge?.charge ?? 0) / 100);
        payableAmount = payableAmount + serviceCharge;
        serviceChargeId = selectedServiceCharge?.id;
      }
    }
  } else {
    // automatically include service charge
    const selectedServiceCharge = store
      .getState()
      ?.serviceChargesReducer?.find(
        (s) =>
          s.type === 'perOrder' &&
          s.orderTypeKeys?.includes(currentOrder.orderType)
      );

    if (selectedServiceCharge) {
      serviceCharge =
        payableAmount * (Number(selectedServiceCharge?.charge ?? 0) / 100);
      payableAmount = payableAmount + serviceCharge;
      serviceChargeId = selectedServiceCharge?.id;
    }
  }

  // Calculate Discount for Each Menu Items

  if (typeof currentOrder.menuItems === 'undefined')
    currentOrder.menuItems = [];

  const noOfMenuItems = currentOrder.menuItems.length;
  currentOrder.menuItems.forEach((item) => {
    //if discountType===1 then discount is in percentage
    if (
      currentOrder.selectedDiscount?.discountType === '1' &&
      currentOrder.selectedDiscount?.discount
    ) {
      //if item?.selectedDiscount?.discountType === '1'(discount is in percentage) then item does also contain its own specific discount with overall discount
      if (
        item.selectedDiscount?.discountType === '1' &&
        item.selectedDiscount?.discount
      ) {
        const specficItemDiscount = (
          (item.itemPrice *
            item.quantity *
            Number(item.selectedDiscount.discount)) /
          100
        ).toFixed(3);

        const discountOnAllItem = (
          (Number(specficItemDiscount) *
            Number(currentOrder.selectedDiscount.discount)) /
          100
        ).toFixed(3);
        // General percentage discount , specific percentage discount and special discount applied
        if (specialObj.specials.length > 0) {
          item.totalDiscountAmt = Number(
            (
              Number(specficItemDiscount) +
              Number(discountOnAllItem) +
              Number(calculateSpecialDiscountForSpecificItem(specialObj, item))
            ).toFixed(3)
          );
        } else {
          item.totalDiscountAmt = Number(
            (Number(specficItemDiscount) + Number(discountOnAllItem)).toFixed(3)
          );
        }
      }
      //if item?.selectedDiscount?.discountType === '2'(discount is in fixed amount) then item does also contain its own specific discount with overall discount
      else if (
        item.selectedDiscount?.discountType === '2' &&
        item.selectedDiscount?.discount
      ) {
        const specficItemDiscount =
          item.itemPrice * item.quantity -
          Number(item.selectedDiscount.discount);

        const discountOnAllItem = (
          (Number(specficItemDiscount) *
            Number(currentOrder.selectedDiscount.discount)) /
          100
        ).toFixed(3);
        // General percentage discount , specific fixed amount discount and special discount applied
        if (specialObj.specials.length > 0) {
          item.totalDiscountAmt = Number(
            (
              Number(item.selectedDiscount.discount) +
              Number(discountOnAllItem) +
              Number(calculateSpecialDiscountForSpecificItem(specialObj, item))
            ).toFixed(3)
          );
        } else {
          item.totalDiscountAmt = Number(
            (
              Number(item.selectedDiscount.discount) + Number(discountOnAllItem)
            ).toFixed(3)
          );
        }
      }
      // special and percentage discount applied
      else if (specialObj.specials.length > 0) {
        const itemDiscount = (
          (item.payablePrice * Number(currentOrder.selectedDiscount.discount)) /
          100
        ).toFixed(3);
        item.totalDiscountAmt = Number(
          (
            Number(itemDiscount) +
            Number(calculateSpecialDiscountForSpecificItem(specialObj, item))
          ).toFixed(3)
        );
      }
      // only percentage discount is applied
      else {
        const itemDiscount = (
          (item.payablePrice * Number(currentOrder.selectedDiscount.discount)) /
          100
        ).toFixed(3);
        item.totalDiscountAmt = Number(itemDiscount);
      }
    }
    //if discountType===2 then discount is in fixed amount
    else if (
      currentOrder.selectedDiscount?.discountType === '2' &&
      currentOrder.selectedDiscount?.discount
    ) {
      //if item?.selectedDiscount?.discountType === '1'(discount is in percentage) then item does also contain its own specific discount with overall discount
      if (
        item.selectedDiscount?.discountType === '1' &&
        item.selectedDiscount?.discount
      ) {
        const specficItemDiscount = (
          (item.itemPrice *
            item.quantity *
            Number(item.selectedDiscount.discount)) /
          100
        ).toFixed(3);

        const discountOnAllItem = (
          Number(currentOrder.selectedDiscount.discount) / noOfMenuItems
        ).toFixed(3);
        // General Fixed amount , specific percentage amount and special discount applied
        if (specialObj.specials.length > 0) {
          item.totalDiscountAmt = Number(
            (
              Number(specficItemDiscount) +
              Number(discountOnAllItem) +
              Number(calculateSpecialDiscountForSpecificItem(specialObj, item))
            ).toFixed(3)
          );
        } else {
          item.totalDiscountAmt = Number(
            (Number(specficItemDiscount) + Number(discountOnAllItem)).toFixed(3)
          );
        }
      }
      //if item?.selectedDiscount?.discountType === '2'(discount is in fixed amount) then item does also contain its own specific discount with overall discount
      else if (
        item.selectedDiscount?.discountType === '2' &&
        item.selectedDiscount?.discount
      ) {
        const specficItemDiscount = Number(item.selectedDiscount.discount);

        const discountOnAllItem = (
          Number(currentOrder.selectedDiscount.discount) / noOfMenuItems
        ).toFixed(3);
        //General Fixed amount and specific fix amount discount and special discount applied
        if (specialObj.specials.length > 0) {
          item.totalDiscountAmt = Number(
            (
              Number(specficItemDiscount) +
              Number(discountOnAllItem) +
              Number(calculateSpecialDiscountForSpecificItem(specialObj, item))
            ).toFixed(3)
          );
        } else {
          item.totalDiscountAmt = Number(
            (Number(specficItemDiscount) + Number(discountOnAllItem)).toFixed(3)
          );
        }
      } else if (specialObj.specials.length > 0) {
        const itemDiscount = (
          Number(currentOrder.selectedDiscount.discount) / noOfMenuItems
        ).toFixed(3);
        item.totalDiscountAmt = Number(
          (
            Number(itemDiscount) +
            Number(calculateSpecialDiscountForSpecificItem(specialObj, item))
          ).toFixed(3)
        );
      }
      // only fixed amount discount is applied
      else {
        const itemDiscount = (
          Number(currentOrder.selectedDiscount.discount) / noOfMenuItems
        ).toFixed(3);
        item.totalDiscountAmt = Number(itemDiscount);
      }
    }

    // Discount is applied to specific item ,not on whole order (item?.selectedDiscount?.discountType === '1' means discount is in percentage)
    else if (
      item.selectedDiscount?.discountType === '1' &&
      item.selectedDiscount?.discount
    ) {
      const specficItemDiscount = (
        (item.itemPrice *
          item.quantity *
          Number(item.selectedDiscount.discount)) /
        100
      ).toFixed(3);
      // specific item percentage Discount and special item discount applied
      if (specialObj.specials.length > 0) {
        item.totalDiscountAmt = Number(
          (
            Number(specficItemDiscount) +
            Number(calculateSpecialDiscountForSpecificItem(specialObj, item))
          ).toFixed(3)
        );
      } else {
        item.totalDiscountAmt = Number(specficItemDiscount);
      }
    }
    // Discount is applied to specific item ,not on whole order (item?.selectedDiscount?.discountType === '1' means discount is in fixed amount)
    else if (
      item.selectedDiscount?.discountType === '2' &&
      item.selectedDiscount?.discount
    ) {
      const specficItemDiscount = Number(
        item.selectedDiscount.discount
      ).toFixed(3);

      if (specialObj.specials.length > 0) {
        item.totalDiscountAmt = Number(
          (
            Number(specficItemDiscount) +
            Number(calculateSpecialDiscountForSpecificItem(specialObj, item))
          ).toFixed(3)
        );
      } else {
        item.totalDiscountAmt = Number(specficItemDiscount);
      }
    } else if (specialObj.specials.length > 0) {
      item.totalDiscountAmt = Number(
        calculateSpecialDiscountForSpecificItem(specialObj, item)
      );
    }
  });

  // Order's payable amount
  serviceCharge = parseFloat(serviceCharge).toFixed(2);
  payableAmount = parseFloat(payableAmount).toFixed(2);
  totalCost = parseFloat(totalCost).toFixed(2);

  currentOrder.totalCost = totalCost;
  currentOrder.payableAmount = payableAmount;
  currentOrder.voidedAmount = voidedAmount;
  currentOrder.creditCardCharges = Number(creditCardCharges).toFixed(2);
  currentOrder.gst = Number(tax).toFixed(2);
  currentOrder.deliveryCost = deliveryCost.toString();
  currentOrder.minOrderValue = minOrderValue.toString();
  currentOrder.specialDiscount = specialDiscount.toString();
  currentOrder.upsalePrice = parseFloat(upsalePrice).toFixed(2);
  currentOrder.loyaltyDiscount = loyaltyDiscount.toString();
  currentOrder.specials = specials;
  currentOrder.rewards = rewards;
  currentOrder.offers = offers;
  currentOrder.serviceCharge = parseFloat(serviceCharge);

  return currentOrder;
};

export default orderEngine;
