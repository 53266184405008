import GeneralModal from 'modals/GeneralModal/index-new';
import { BillMenuSingleItem } from 'modals/PrintOrderModal/BillMenuSingleItem';
import { ReceiptItem } from 'modals/PrintOrderModal/ReceiptItem';
import React from 'react';
import { BillCheckItemUI, ButtonUI } from 'UI/components';
import { getShortenedDateTime } from 'util/dateUtils';
import { BasePartUI, ScrollableBoxUI } from '../../../../UI/components';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import { useSelector } from 'react-redux';
import { storeConfigSelector } from 'pages/Dashboard/selectors';
import './MarkDeliveryOrderCompleteModal.css';
import { getItemInstruction } from 'pages/Payment/util';
const ORDER_TYPES = ['Walk In', 'Pick Up', 'Delivery', 'Dine-in'];

const MarkDeliveryOrderCompleteModal = ({
  show,
  onModalClose,
  order,
  handleMarkAsDelivered,
  isMarkAsDeliveredApiLoading,
}) => {
  const storeConfig = useSelector(storeConfigSelector);
  const getAmount = (amount) => {
    return getAmountWithCountryCurrency({
      amount: Number(amount),
      locale: `en-${storeConfig?.storeCountryIdentifier}`,
      currencyCode: storeConfig?.currency,
    });
  };
  return (
    <GeneralModal
      className="payment-modal"
      boxWidth="455px"
      modalStatus={show}
      toggleModal={onModalClose}
      position={'center'}
      effect="fade"
      overlay={'default'}
      boxHeight="655px"
      closeBtn="outside"
    >
      <BasePartUI>
        <BasePartUI.Body>
          <ScrollableBoxUI>
            <div>
              <ReceiptItem title={'Invoice'} isHeader isSingleItem />
              <ReceiptItem
                title={`POS - ${ORDER_TYPES[Number(order?.orderType)]}`}
                description={
                  Number(order?.orderType) === 3 &&
                  `Table ${order?.tableNumber}`
                }
                isHeader
              />
              <ReceiptItem
                title="Placed:"
                description={getShortenedDateTime(order?.dateTimestamp)}
                isTotal
              />
              {Number(order?.orderType) !== 3 && (
                <ReceiptItem
                  title="Customer:"
                  description={`${order?.firstName} ${order?.lastName}`}
                  isTotal
                />
              )}

              {order?.address !== '' && (
                <ReceiptItem
                  title="Address:"
                  description={`${order?.address}`}
                  isTotal
                />
              )}

              {order.menuItems
                .filter((i) => i.quantity >= 1)
                .map((i, index) => {
                  return (
                    <BillCheckItemUI
                      key={i._id + index}
                      isVoided={i.isVoided}
                      title={`${i.name} ${
                        i.quantity > 1 ? `x ${i.quantity}` : ``
                      }`}
                      // instruction={getItemInstruction(i)}
                      price={`$${i.payablePrice.toFixed(2)}`}
                    />
                  );
                })}

              {Object.values(
                order.menuItems
                  .filter((i) => i.quantity === 0.5)
                  .reduce(
                    (acc, k) => ({
                      ...acc,
                      [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                    }),
                    {}
                  )
              ).map((item, index) => {
                const partedIndex = item.map((i) => i._id).join('+') + index;
                const name = item.map((i) => i.name).join('/');
                const payablePrice = item.reduce(
                  (acc, i) => (acc += i.payablePrice),
                  0
                );
                const instruction = item.map(
                  (i) =>
                    `${
                      getItemInstruction(i).trim() !== '' ? `${i.name}:` : ''
                    } ${getItemInstruction(i)}`
                );

                return (
                  <BillCheckItemUI
                    key={partedIndex}
                    title={name}
                    instruction={instruction}
                    price={`$${payablePrice}`}
                  />
                );
              })}

              {Object.values(
                order.menuItems
                  .filter((i) => i.isQuarter)
                  .reduce((acc, k) => {
                    return {
                      ...acc,
                      [k.fourQuarterIndex]: [
                        ...(acc[k.fourQuarterIndex] || []),
                        k,
                      ],
                    };
                  }, {})
              ).map((item, index) => {
                const partedIndex = item.map((i) => i._id).join('+');
                const name = item.map((i) => i.name).join('/');
                const payablePrice = item.reduce(
                  (acc, i) => (acc += i.payablePrice),
                  0
                );
                const instruction = item.map((i) => getItemInstruction(i));
                return (
                  <BillCheckItemUI
                    key={partedIndex}
                    title={name}
                    instruction={instruction}
                    price={`$${payablePrice.toFixed(2)}`}
                  />
                );
              })}

              {Number(order?.specialDiscount) > 0 && (
                <ReceiptItem
                  title="Specials Discount:"
                  description={`-${getAmount(order.specialDiscount)}`}
                  isFooter
                />
              )}
              {Number(order?.deliveryCost) > 0 && (
                <ReceiptItem
                  title="Delivery Charges:"
                  description={getAmount(order.deliveryCost)}
                  isFooter
                  isTotal
                />
              )}
              <ReceiptItem
                title="Total :"
                description={getAmount(order?.payableAmount)}
                isTotal
              />
            </div>
          </ScrollableBoxUI>
        </BasePartUI.Body>
        <BasePartUI.Footer>
          <div className="markDeliveryOrderCompleteModalfooterContainer">
            <div className="warningTextContainer">
              <span>Do you want to manually marked this order delivered ?</span>
            </div>
            <div className="confirmButtonContainer">
              <ButtonUI
                styleType="primary"
                sizeType="m"
                onClick={handleMarkAsDelivered}
                disabled={isMarkAsDeliveredApiLoading}
                loading={isMarkAsDeliveredApiLoading}
              >
                Mark as Delivered
              </ButtonUI>
            </div>
          </div>
        </BasePartUI.Footer>
      </BasePartUI>
    </GeneralModal>
  );
};

export default MarkDeliveryOrderCompleteModal;
