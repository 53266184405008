const RefundSelectSubItem = ({ value, label, price = '', checked, onChange }) => {
  return (
    <div className="refund-select-list__item">
      <label
        className="refund-select-list__label refund-select-list__label--with-price refund-select-list__label--small"
        title={label}
      >
        <span className="refund-select-list__item-cell">
          <input
            id={`refund-reason_${value}`}
            type="checkbox"
            className="refund-select-list__input-checkbox refund-select-list__input-checkbox--small"
            name="refund-reason"
            checked={checked}
            onChange={() => onChange(value)}
          />
        </span>
        <span className="refund-select-list__item-cell">
          <span className="refund-select-list__text refund-select-list__text--small">
            {label}
          </span>
        </span>
        <span className="refund-select-list__item-cell refund-select-list__item-cell--align-right">
          <span className="refund-select-list__text  refund-select-list__text--small  refund-select-list__text--theme-grey">
            {price}
          </span>
        </span>
      </label>
    </div>
  );
}

export default RefundSelectSubItem;
