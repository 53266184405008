import { createSlice } from '@reduxjs/toolkit'

const initialDataState = {
  categoriesMap: {},
  categoriesList: [],
  menuItemsMap: {},
  menuItemsList: [],
  specialsMap: {},
  specialsList: [],
  discountsMap: {},
  discountsList: [],
  ordersHistoryMap: {},
  ordersHistoryList: [],
}

const selectedState = {
  selectedQuantities: {},
  selectedSizes: {},
  selectedVariants: {},
  selectedModifiers: {},
  selectedExtraIngredients: {},
  selectedRemovedIngredients: {},
  selectedSpecialItems: {},
  selectedPartedItems: {},
  selectedItemNotes: {},
  selectedPartedSizePair: null,
  selectedSpecialBoxIndex: null,
  selectedPartedVariant: null,
  selectedPartedBoxIndex: null,
  selectedCategoryId: null,
  selectedMenuItemId: null,
  selectedSpecialId: null,
  selectedPartedItemView: null,
  selectedMenuItemHash: null,
}

const orderState = {
  menuItems: [],
  quantities: {},
  sizes: {},
  variants: {},
  modifiers: {},
  extraIngredients: {},
  removedIngredients: {},
  specials: [],
  specialItems: {},
  itemNotes: {},
  partedItems: {},
  discounts: {},
  customItemPrices: {},
  customerName: null,
  customerPhone: null,
  address: null,
  notes: null,
}

const initialUIState = {
  addressConfirmed: false,
  activeModifierId: null,
  popupMenuItemId: null,
  customiseViewOpen: null,
}

const initialState = {
  ...initialDataState,
  ...initialUIState,
  ...selectedState,
  specialsMatrixSearch: null,
  currentOrder: orderState,
};

/* Main data reducer for order abstraction
 * provides storage and simple manipulation actions
  * */
export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setState: (state, action) => ({ ...state, ...action.payload }),
    setOrder: (state, ac) => ({ ...state, currentOrder: { ...state.currentOrder, ...ac.payload } }),
    resetUI: (state) => ({ ...state, ...initialUIState }),
    resetSelection: (state) => ({ ...state, ...selectedState }),
    resetOrder: (state) => ({ ...state, currentOrder: orderState }),
    resetState: s => ({ ...s, currentOrder: orderState, lookupResponse: null, ...initialUIState, ...selectedState }),
  },
})

export const { setState, setOrder, resetUI, resetSelection, resetState, resetOrder } =
  orderSlice.actions

export default orderSlice.reducer
