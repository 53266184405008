import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconMoon = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-moon': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M25.362 16.027a.496.496 0 0 0-.57 0c-5.03 3.1-11.63 1.54-14.74-3.49-2.12-3.45-2.12-7.81.02-11.25.16-.23.1-.54-.12-.7a.512.512 0 0 0-.45-.06c-6.74 2.08-10.51 9.23-8.43 15.97s9.24 10.51 15.98 8.42c4.03-1.25 7.18-4.4 8.43-8.42.05-.16 0-.34-.12-.47z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconMoon.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
