import React from 'react';
import Lottie from 'react-lottie';

import ShiftsBlockContainer from './Containers/ShiftsBlockContainer';
import ShiftsPageContainer from './Containers/ShiftsPageContainer';

import { useStaffMemberActiveShift } from './Context/ShiftsState';

import { getCurrentTime } from '../../util/dateUtils';
import { getParamValue } from '../../util/utils';
import { ShiftsEnum } from './constants';
import { useRouteToHomePage } from '../../customHooks/useRouteToHomePage';
import checkMarkSuccess from '../../assets/lotties/782-check-mark-success.json';

const ClockInClockOutConfirmation = () => {
  useRouteToHomePage(3000);

  const statusValue = getParamValue('status');

  const { activeRoleName: staffMemberActiveRoleName } =
    useStaffMemberActiveShift();

  const renderConfirmationText = () => {
    switch (statusValue) {
      case ShiftsEnum.SWITCHED_ROLES:
        return `Switched to ${staffMemberActiveRoleName}`;
      case ShiftsEnum.CLOCKED_IN:
        return `Clocked in as ${staffMemberActiveRoleName}`;

      case ShiftsEnum.CLOCKED_OUT:
        return 'Clocked Out';

      case ShiftsEnum.START_BREAK:
        return 'Break Started';

      case ShiftsEnum.END_BREAK:
        return 'Break Ended';

      default:
        return '';
    }
  };

  const checkMarkSuccessLottie = {
    loop: false,
    autoplay: true,
    animationData: checkMarkSuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <ShiftsPageContainer>
      <ShiftsBlockContainer isBig={true}>
        <div className="clock-in-out__header-group">
          <h2 className="clock-in-out__title">{getCurrentTime()}</h2>
        </div>
        <div className="clock-in-out__status-group">
          <Lottie
            style={{
              top: '-20px',
              left: '-15px',
            }}
            options={checkMarkSuccessLottie}
            height={192}
            width={192}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => {},
              },
            ]}
          />
        </div>
        <p className="clock-in-out__desc clock-in-out__desc--size-big">
          {renderConfirmationText()}
        </p>
      </ShiftsBlockContainer>
    </ShiftsPageContainer>
  );
};

export default ClockInClockOutConfirmation;
