import React from 'react';
import { useHistory } from 'react-router';
import { updateMenuItem, resetMenuItem } from '../../pages/Dashboard/action';
import { useDispatch } from 'react-redux';
import { resetAllIngredient } from '../../itemProcessor';
import useGoBackToPathName from '../../customHooks/useGoBackToPathName';
import { CancelNavButtonUI } from '../../UI/components';

export const CancelBtn = ({ currentItem, basePath }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const goBackTo = useGoBackToPathName(currentItem, basePath);

  const shouldReturnHome =
    history.location.search.includes('returnHome') &&
    goBackTo.includes('/menuitem');
  const splitPath = history.location.pathname.split('menuitem/');
  const orderPath = Array.isArray(splitPath) ? `${splitPath[0]}order` : false;

  const handleClick = () => {
    if (shouldReturnHome && orderPath) {
      dispatch(resetMenuItem());
    } else {
      dispatch(updateMenuItem(resetAllIngredient(currentItem)));
    }
  };

  return (
    <CancelNavButtonUI
      link={shouldReturnHome && orderPath ? orderPath : goBackTo}
      onClick={handleClick}
    />
  );
};

export const PresentationalCancelButton = ({ link }) => {
  return <CancelNavButtonUI link={link} />;
};
