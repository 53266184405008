import React from 'react';

export const OrderDataPicker = ({ onClick, displayText }) => {
  return (
    <div className="single-pickup-details" onClick={onClick}>
      <p className="text">{displayText}</p>
      <div className="icon-box">
        <span className="dine_icon-clock" />
      </div>
    </div>
  );
};
