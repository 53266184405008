import React, { useState, useEffect, useCallback } from 'react';
import { useModal } from 'hooks';
import { useHistory, useLocation } from 'react-router';
import { ActionsModal } from '../../modals/ActionsModal';
import SideBarModalPrintBill from '../../modals/SideBarModalPrintBill';
import { getCurrentGuest } from '../../util/getCurrentGuest';
import { useSelector, useDispatch } from 'react-redux';
import PaymentViewModal from '../../components/PaymentViewModal';
import { PrinterProvider } from '../../context/PrinterContext';
import * as actions from 'pages/Dashboard/action';
import { orderType } from 'configuration/constants';
import { setGoToRouteAfterAuth } from 'pages/Dashboard/action';

const ModalsWrapper = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { orderList, actionsModal, paymentViewModal, selectedOrder } =
    useSelector((state) => state.ordersReducer);
  const [currentGuest, setCurrentGuest] = useState(false);

  useEffect(() => {
    setCurrentGuest(getCurrentGuest(orderList));
  }, [orderList]);

  const handlePaymentModalClose = useCallback(() => {
    dispatch({
      type: 'togglePaymentViewModal',
      payload: false,
    });
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handlePaymentModalClose();
    };
  }, [handlePaymentModalClose]);

  const handleToggleModal = (e, status) => {
    dispatch({
      type: 'toggleActionsModal',
      payload: status,
    });
  };

  const isAuthDisabledForPOS = useSelector(
    (state) => state.dashboardReducer.orderSetup.isAuthDisabledForPOS
  );

  const [_, { openModal: openPinModal }] = useModal('staffPinModal');

  const handlePinModalOpen = (order) => {
    if (isAuthDisabledForPOS) {
      const selectedOrderTypeRoute =
        orderType.find((o) => o.id === order?.orderType)?.route || '';
      dispatch(actions.authenticateStaff(true));
      dispatch(
        actions.updateCurrentOrder({
          ...order,
          iteration: order?.iteration + 1 || 1,
          prevIteration: order?.iteration || 0,
          editOrderHideOrderButton: true,
          isEditOrder: true,
          menuItems: order.menuItems.map((menuItem) =>
            typeof menuItem.iteration === 'undefined'
              ? { ...menuItem, iteration: order.iteration || 0 }
              : menuItem
          ),
        })
      );
      dispatch(actions.loadOrderPayments(order._id));
      history.push(`${selectedOrderTypeRoute}`, location.state);
    } else {
      const selectedOrderTypeRoute =
        orderType.find((o) => o.id === order?.orderType)?.route || '';
      dispatch(setGoToRouteAfterAuth(selectedOrderTypeRoute));
      openPinModal();
      // actions.setShowPinModal(true);
    }
  };

  const handleOpenPaymentViewModal = () => {};
  return (
    <>
      <ActionsModal
        modalStatus={actionsModal}
        handlePinModalOpen={handlePinModalOpen}
        toggleModal={handleToggleModal}
        currentGuest={currentGuest}
      />
      <PrinterProvider>
        <SideBarModalPrintBill additionalClass="view-orders" />
      </PrinterProvider>
      {paymentViewModal && (
        <PaymentViewModal
          toggleModal={handlePaymentModalClose}
          openPaymentViewModal={handleOpenPaymentViewModal}
          currentOrder={selectedOrder}
          orderTimes={''}
          isOrdersPage={true}
        />
      )}
    </>
  );
};

export default ModalsWrapper;
