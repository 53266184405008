import { createSlice } from '@reduxjs/toolkit';

const dateFilters = ['current', 'future', 'history'];
const typeFilters = ['all', 'pickup', 'delivery', 'dine-in'];

const orderMatrix = dateFilters.reduce(
  (acc, df) => ({ ...acc, [df]: typeFilters }),
  []
);

const initialState = {
  orderMatrix,
  selectedOrderId: null,
  orders: {
    allOrdersList: [],
    allOrdersMap: {}
  },
  filters: {
    date: dateFilters[0],
    type: typeFilters[0],
    allAndUnpaid: 'all',
    search: '',
  },
  loading: false,
};

export const ordersSlice = createSlice({
  name: 'newOrders',
  initialState,
  reducers: {
    setState: (state, action) => ({ ...state, ...action.payload }),
    setOrders: (state, action) => ({
      ...state,
      orders: { ...state.orders, ...action.payload,  },
      loading: false
    }),
    updateOrder: (state, action) => ({
      ...state,
      orders: {
        ...state.orders,
        allOrdersMap: {
          ...state.orders.allOrdersMap,
          [action.payload._id]: { 
            ...state.orders.allOrdersMap[action.payload._id],
            ...action.payload,
          }
        }
      }
    }),
    setSelectedOrder: (state, action) => ({
      ...state,
      selectedOrderId: action.payload,
      loading: false,
    }),
    setFilters: (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.payload,  },
      loading: false
    }),
    resetFilters: (state) => ({
      ...state,
      filters: {
        ...initialState.filters,
        type: state.filters.type,
      },
      loading: false,
    }),
    setOrdersLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    updateAllMapOrder: (state, action) => {
      const { orders, id, deliveryDate, dateTimestamp } = action?.payload
      let updatedOrder = {
        ...orders?.allOrdersMap[id],
        deliveryDate: deliveryDate,
        deliveryDateTimestamp:dateTimestamp,
        
      }
      return ({
        ...state,
        orders: {
          ...orders,
          allOrdersMap: {
              ...orders.allOrdersMap,
              [id]: updatedOrder
            
          }
        }
      })
    }
  },
});

export const { setState, updateOrder, setOrders, setSelectedOrder, setFilters, resetFilters, updateAllMapOrder,setOrdersLoading } = ordersSlice.actions

export default ordersSlice.reducer;
