import React, { useContext, useMemo, useState } from 'react';
import { BasePartUI, ButtonUI, NavButtonUI } from 'UI/components';
import GeneralModal from 'modals/GeneralModal/index-new';
import { DriverBatchesContext } from 'pages/Orders/components/Providers/DriverBatchesProvider';
import { useSelector, useDispatch } from 'react-redux';
import { filteredDriversSelector } from 'pages/Drivers/selectors';
import { assignDriverToBatchOrder } from 'store/batchOrders/actions';
import useAssignDrivers from 'hooks/useAssignDrivers';

function Initials({ name }) {
  const n = name
    .split(' ')
    .map((n) => n[0])
    .join('');

  // Need to return in a circle
  return (
    <div
      className="initials-coll"
      style={{
        backgroundColor: '#fd6c00',
        width: 30,
        height: 30,
        borderRadius: 30,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        color: '#fff',
      }}
    >
      <span>{n}</span>
    </div>
  );
}

function ModalContent({ hideModal }) {
  const dispatch = useDispatch();
  const [selectedDriver, setSelectedDriver] = useState({});
  const { batchOrders, selectedBatchOrderId, loading } = useSelector(
    (state) => state.batchOrdersReducer
  );
  const filteredDrivers = useSelector(filteredDriversSelector);
  const { driversWaitingOnStore } = filteredDrivers || {};

  const selectedBatch = useMemo(
    () => batchOrders.find((order) => selectedBatchOrderId === order.id),
    [batchOrders, selectedBatchOrderId]
  );

  const deliveryCodes = useMemo(
    () => (selectedBatch?.orders ?? []).map((o) => o.deliveryCode),
    [selectedBatch?.orders]
  );

  const { handleAssignDriver, isAssignDriverApiLoading } = useAssignDrivers({
    driverId: selectedDriver._id,
    shiftId: selectedDriver.shiftId,
    storeId: selectedDriver.storeId,
    restaurantId: selectedDriver.restaurantId,
    deliveryCodes,
  });

  const assignBatch = () => {
    const hasDriver =
      selectedDriver._id &&
      selectedDriver.shiftId &&
      selectedDriver.storeId &&
      selectedDriver.restaurantId &&
      deliveryCodes.length > 0;

    if (!hasDriver || !selectedDriver._id || !selectedBatchOrderId) return;

    dispatch(assignDriverToBatchOrder(selectedDriver, selectedBatchOrderId));
    handleAssignDriver();
    hideModal();
  };

  return (
    <BasePartUI
      style={{
        width: '435px',
        height: '536px',
      }}
    >
      <BasePartUI.Header>
        <h3 className="bem-add-driver-modal__title">
          Select A Driver - {selectedBatch?.name}
        </h3>
      </BasePartUI.Header>
      <BasePartUI.Body scrollShadow>
        {driversWaitingOnStore
          ?.filter((driver) => !!driver.shiftId)
          .map((driver) => {
            const title = `${driver.firstName} ${driver.lastName}`;
            return (
              <NavButtonUI
                key={driver._id}
                title={title}
                icon={<Initials name={title} />}
                modifierClassName={
                  selectedDriver._id === driver._id ? 'active' : ''
                }
                onClick={() => setSelectedDriver(driver)}
              />
            );
          })}
      </BasePartUI.Body>
      <BasePartUI.Footer>
        <ButtonUI
          type="submit"
          style={{ height: 55 }}
          sizeType="xl"
          rounded="none"
          fullWidth
          onClick={assignBatch}
          loading={loading}
          disabled={!selectedDriver._id || loading || isAssignDriverApiLoading}
        >
          Assign Batch
        </ButtonUI>
      </BasePartUI.Footer>
    </BasePartUI>
  );
}

export default function DeliveryBatchesAssignDriverModal() {
  const { showAssignDriverModal, setShowAssignDriverModal } =
    useContext(DriverBatchesContext);

  return showAssignDriverModal ? (
    <GeneralModal
      className="bem-add-driver-modal"
      modalStatus={showAssignDriverModal}
      toggleModal={() => setShowAssignDriverModal(!showAssignDriverModal)}
      position={'center'}
      effect="fade"
      overlay={'default'}
      boxWidth="435px"
      boxHeight="536px"
      closeBtn="outside"
      isResponsiveHeight
    >
      <ModalContent hideModal={() => setShowAssignDriverModal(false)} />
    </GeneralModal>
  ) : null;
}
