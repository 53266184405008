import React from 'react';
import useGoBackToPathName from '../../customHooks/useGoBackToPathName';
import {
  authenticateStaff,
  resetMenuItem,
  resetCurrentHalfHalfData,
  resetCurrentOrder,
  setIsSingleHalfFlow,
  setShowPizzaActionButton,
  setHalfHalfMenuItems,
} from '../../pages/Dashboard/action';
import { useDispatch } from 'react-redux';
import { BackNavButtonUI } from '../../UI/components';

export const BackBtn = ({ currentItem, basePath }) => {
  const dispatch = useDispatch();
  const goBackTo = useGoBackToPathName(currentItem, basePath);

  const handleClick = () => {
    if (goBackTo.includes('/order')) {
      dispatch(resetCurrentHalfHalfData());
      dispatch(resetMenuItem());
      dispatch(setIsSingleHalfFlow(false));
      dispatch(setShowPizzaActionButton(false));
      dispatch(setHalfHalfMenuItems([]));
    }
    if (goBackTo === '/') {
      dispatch(authenticateStaff(false));
      dispatch(resetCurrentOrder());
      dispatch(resetMenuItem());
    }
  };

  return <BackNavButtonUI link={goBackTo} onClick={handleClick} />;
};

export const PresentationalBackButton = ({ link }) => {
  return <BackNavButtonUI link={link} />;
};
