import { useEffect, useState } from 'react';

// export const useSortByDisplayOrder = (itemsList) => {
//   const [tempArr, setTempArr] = useState(itemsList);
//   useEffect(async() => {
//     if (itemsList && itemsList.length) {
//       let newArr = await itemsList.sort((a, b) => {
//         return Number(a.displayOrder) - Number(b.displayOrder);
//       });
//       setTempArr(newArr);
//     }
//   }, [itemsList]);
//   return tempArr || [];
// };

export const useSortByDisplayOrder = (itemsList) => {
  const [sortedArr, setSortedArr] = useState(itemsList);

  useEffect(() => {
    if (itemsList && itemsList.length) {
      // Create a copy of the itemsList before sorting
      const newArr = [...itemsList];

      // Sort the copied array by displayOrder
      newArr.sort((a, b) => {
        return Number(a.displayOrder) - Number(b.displayOrder);
      });

      // Update the state with the sorted array
      setSortedArr(newArr);
    }
  }, [itemsList]);

  return sortedArr;
};

export const getModifiersAfterSortByDisplay = (itemsList) => {
  if (itemsList && itemsList.length) {
    let newArr = [...itemsList];
    newArr.sort((a, b) => {
      return Number(a.displayOrder) - Number(b.displayOrder);
    });
    return newArr;
  }

  return [];
};
