import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DriversSidebar } from './DriversSidebar';
import classNames from 'classnames';
import { DriversMapMaxModal } from '../../modals/DriversMapMaxModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useMutation from '../../customHooks/useMutation';
import { currentDriverSelector } from '../../pages/Drivers/selectors';
import DriverInfo from './DriverInfo';
import { isMobileTerminalEnabledSelector } from '../../pages/Dashboard/selectors';

export const DriversBox = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'setCurrentDriver',
      payload: null,
    });
  }, [dispatch]);

  const [modalStatus, setModalStatus] = useState(false);
  const { scrolledStatus } = useSelector((state) => state.ordersReducer);
  const currentDriver = useSelector(currentDriverSelector);
  const isMobileTerminalEnabled = useSelector(isMobileTerminalEnabledSelector);

  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );
  const [clockOutDriver, { data = {} }] = useMutation(
    `${nextOrderConfigURL}/clockoutDriver`,
    {}
  );

  const { status, statusCls } = useMemo(() => {
    if (currentDriver.status === 0) {
      if (currentDriver.isActive) {
        return { status: 'Waiting on Store', statusCls: 'green' };
      }
      return { status: 'Clocked Out', statusCls: 'grey' };
    }
    if (currentDriver.status === 1) {
      return { status: 'On Delivery', statusCls: 'red' };
    }
    if (currentDriver.status === 2) {
      return { status: 'Returning to Store', statusCls: 'orange' };
    }
    return { status: '', statusCls: '' };
  }, [currentDriver.status, currentDriver.isActive]);

  const cls = classNames({
    'current-driver-details-header': true,
    [`${statusCls}`]: true,
    shadow: scrolledStatus[11].position,
  });

  const handleCashFloatClick = useCallback(() => {
    if (currentDriver._id) {
      if (
        currentDriver?.cashBalance?.cashFloat === 'End' &&
        isMobileTerminalEnabled
      ) {
        history.push(
          `/drivers/${currentDriver?._id}/cash-float/view-deliveries`
        );
      } else {
        history.push(`/drivers/${currentDriver?._id}/cash-float/`);
      }
    }
  }, [currentDriver, history, isMobileTerminalEnabled]);

  const handleDriverClockOut = () => {
    if (currentDriver._id) {
      clockOutDriver(
        JSON.stringify({
          driverId: currentDriver._id,
          storeId: currentDriver.storeId,
        })
      );
    }
  };

  useEffect(() => {
    data.success &&
      dispatch({
        type: 'setCurrentDriver',
        payload: null,
      });
  }, [data.success, dispatch]);

  const handleOpenModal = () => {
    setModalStatus(true);
    // dispatch({
    //   type: 'toggleDriverMapModal',
    //   payload: true,
    // });
  };

  const handleToggleModal = (e, status) => {
    setModalStatus(status);
  };

  return (
    <>
      <div className="driver-main-wrap all-driver-style">
        <DriversSidebar />

        <div className="driver-main ">
          {currentDriver?._id ? (
            <DriverInfo
              currentDriver={currentDriver}
              handleOpenModal={handleOpenModal}
              cls={cls}
              status={status}
              handleCashFloatClick={handleCashFloatClick}
              handleDriverClockOut={handleDriverClockOut}
            />
          ) : null}
        </div>
      </div>
      <DriversMapMaxModal
        toggleModal={handleToggleModal}
        modalStatus={modalStatus}
        driver={currentDriver}
      />
    </>
  );
};
