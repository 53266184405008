import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconUpcoming = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-upcoming': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M23.467 22.423c0 .576-.468 1.044-1.044 1.044H2.587a1.044 1.044 0 0 1-1.044-1.044V2.587c0-.576.468-1.044 1.044-1.044h19.836c.576 0 1.044.468 1.044 1.044zM22.413.5H2.587A2.087 2.087 0 0 0 .5 2.587v19.826c0 1.153.934 2.087 2.087 2.087h19.826a2.087 2.087 0 0 0 2.087-2.087V2.587A2.087 2.087 0 0 0 22.413.5z"
              />
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness / 5 : 0.2}
                strokeMiterlimit="20"
                d="M23.467 22.423c0 .576-.468 1.044-1.044 1.044H2.587a1.044 1.044 0 0 1-1.044-1.044V2.587c0-.576.468-1.044 1.044-1.044h19.836c.576 0 1.044.468 1.044 1.044zM22.413.5H2.587A2.087 2.087 0 0 0 .5 2.587v19.826c0 1.153.934 2.087 2.087 2.087h19.826a2.087 2.087 0 0 0 2.087-2.087V2.587A2.087 2.087 0 0 0 22.413.5z"
              />
            </g>
            <g>
              <path
                fill="#000000"
                d="M12.134 5.66L7.5 10.505l.731.765L11.98 7.34V19.5h1.03V7.34l3.759 3.931.731-.765-4.634-4.847A.504.504 0 0 0 12.5 5.5a.504.504 0 0 0-.366.16z"
              />
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness / 5 : 0.2}
                strokeMiterlimit="20"
                d="M12.134 5.66L7.5 10.505v0l.731.765v0L11.98 7.34V19.5h1.03v0V7.34v0l3.759 3.931v0l.731-.765v0L12.866 5.66A.504.504 0 0 0 12.5 5.5a.504.504 0 0 0-.366.16z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconUpcoming.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
