import { setDisableSpecialDiscountModal } from 'pages/Dashboard/action';
import {
  currencyCodeSelector,
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from 'pages/Dashboard/selectors';
import { updateCurrentOrderWithOrderEngine } from 'pages/Dashboard/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import AlertModal from '../AlertModal';

const DisableSpecialDiscountModal = ({ modalStatus, toggleModal }) => {
  const dispatch = useDispatch();
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const currentOrder = useSelector(
    (state) => state.dashboardReducer?.currentOrder
  );
  const specialDiscount = currentOrder?.specialDiscount || 0;
  const specialDiscountText = getAmountWithCountryCurrency({
    amount: Number(specialDiscount),
    locale: `en-${storeCountryIdentifier}`,
    currencyCode,
  });

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const handleSubmit = () => {
    const newCurrentOrder = { ...currentOrder, noSpecialDiscount: true };
    dispatch(updateCurrentOrderWithOrderEngine(newCurrentOrder));
    dispatch(setDisableSpecialDiscountModal(false));
  };

  return (
    <AlertModal
      height="185px"
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      header={`Remove applied specials discount of ${specialDiscountText}?`}
      description="Once removed, the customer will pay the total price for the menu items without special discounts."
      proceedText="Yes"
      cancelText="No"
      handleCancel={handleCloseModal}
      handleProceed={handleSubmit}
    />
  );
};

export default DisableSpecialDiscountModal;
