import orderEngine from '../orderEngine/index';
import { isObjectNotEmpty } from '../util/utils';
import { notCurrentMenuItemOrderIndex } from './utilities';

const addVoucherToMenuItem = ({
  currentOrder,
  currentMenuItem,
  selectedVoucher,
  activeSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig,
}) => {
  const updatedCurrentOrder = {
    ...currentOrder,
    menuItems: currentOrder.menuItems.map((menuItem) => {
      // if not the current menu item index skip
      if (notCurrentMenuItemOrderIndex(currentMenuItem, menuItem)) {
        return menuItem;
      }

      const activeVoucher =
        isObjectNotEmpty(menuItem.selectedVoucher) && menuItem.selectedVoucher;

      const shouldApplyNewVoucher = () => {
        // check whether discount has been already applied for the menu item
        if (activeVoucher && activeVoucher._id !== '') {
          // check whether it is the same discount or not
          return activeVoucher._id !== selectedVoucher._id;
        } else {
          return true;
        }
      };

      // apply changes only to the current menu item
      return {
        ...menuItem,
        selectedVoucher: shouldApplyNewVoucher() // check if we need to apply new discount object
          ? selectedVoucher
          : activeVoucher // otherwise check if we should keep the existing object or clear it
          ? activeVoucher
          : {},
      };
    }),
  };

  let newOrder = orderEngine(
    updatedCurrentOrder,
    activeSpecials,
    orderSetup,
    productSetup,
    allSuburbs,
    publicHolidays,
    storeConfig
  );

  return newOrder;
};

export default addVoucherToMenuItem;
