import React, { useContext } from 'react';
import GeneralModal from '../GeneralModal/index-new';
import { PosIcon } from '../../assets/icons/PosIcon';
import RefundContext from '../../context/RefundContext';
import { useOrdersGlobalState } from '../../pages/Orders/components/Providers/OrdersGlobalState/OrdersGlobalState';

const capitalize = (s) => `${s.substr(0, 1).toUpperCase()}${s.substr(1)}`;

const RefundIssuedModal = ({ showModal, onModalClose, refundType }) => {
  const [state, setState] = useContext(RefundContext);
  const { selectedOrder } = useOrdersGlobalState();

  return (
    <GeneralModal
      modalStatus={showModal && state.step === 'issued'}
      toggleModal={onModalClose}
      position="center"
      effect="move"
      overlay=""
      boxWidth="542px"
      boxHeight="563px"
      className="general-modal general-modal--theme-action grey-bg "
    >
      {showModal && state.step === 'issued' && (
        <div className="general-modal__inner general-modal__inner--space-between-biggest ">
          {' '}
          <div className="general-modal__header general-modal__header--space-between-biggest ">
            {' '}
            <div className="general-modal__title-box">
              {' '}
              <h3 className="general-modal__title general-modal__title--spacing-large">
                {capitalize(refundType)} Issued
              </h3>
            </div>
            <div className="general-modal__icon-box">
              <PosIcon
                name="check-circle-solid"
                size="custom"
                customHeight={144}
                customWith={144}
                mainColor="#5eb602"
                darkModeColor="#5eb602"
              />
            </div>
            <div className="general-modal__desc general-modal__desc--small-width general-modal__desc--size-big">
              <p>
                A text message has been sent to {selectedOrder.firstName}{' '}
                {selectedOrder.lastName} with confirmation of the {refundType}
              </p>
            </div>
          </div>
          <div className="general-modal__footer">
            <div className="general-modal__actions-group">
              <button
                className="general-modal__action"
                title="Close"
                type="button"
                onClick={onModalClose}
              >
                Close
              </button>
              <button
                className="general-modal__action general-modal__action--theme-blue"
                title="Print Details"
                type="button"
              >
                Print Details
              </button>
            </div>
          </div>
        </div>
      )}
    </GeneralModal>
  );
};

export default RefundIssuedModal;
