import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  storeCountryIdentifierSelector,
  storeLocationCoordinatesSelector,
} from '../pages/Dashboard/selectors';
import { REACT_APP_POST_CODE_GET_ADDRESS_KEY } from '../configuration/mapboxKey';

export const usePostCodeApi = (searchText, shouldCallApi) => {
  const storeLocationCoordinates = useSelector(
    storeLocationCoordinatesSelector
  );
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const parsePosrCodeData = (data) => {
    const { addresses, latitude, longitude, postcode } = data;
    const parsedData = [];
    if (addresses.length > 0) {
      addresses.forEach((address) => {
        let locationText = '';
        const context = [
          { id: 'postcode', text: postcode },
          { id: 'locality', text: address?.locality || '' },
          { id: 'region', text: address.county || '' },
          { id: 'place', text: address.town_or_city || '' },
          { id: 'country', text: address.country || '' },
        ];
        address.formatted_address.forEach((addressText) => {
          if (addressText) {
            locationText = locationText + ` ${addressText}`;
          }
        });

        parsedData.push({
          location: { lat: latitude, lng: longitude },
          locationText,
          context,
          id: locationText,
          type: 'Feature',
        });
      });
    }

    return {
      features: parsedData,
    };
  };

  useEffect(() => {
    async function fetchUrl() {
      const postCodeApiUrl = `https://api.getAddress.io/find/${searchText}?api-key=${REACT_APP_POST_CODE_GET_ADDRESS_KEY}&expand=true`;

      try {
        setLoading(true);
        const response = await axios.get(postCodeApiUrl);
        setData(parsePosrCodeData(response.data));
      } catch (error) {
        console.error(error);
        setData({ features: [] });
      } finally {
        setLoading(false);
      }
    }
    if (shouldCallApi && searchText && searchText.length >= 3) {
      fetchUrl();
    } else {
      setData({ features: [] });
    }
  }, [
    shouldCallApi,
    searchText,
    storeCountryIdentifier,
    storeLocationCoordinates,
  ]);
  return [data, loading];
};
