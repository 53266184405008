import React from 'react';
import BatchedOrderItem from './BatchedOrderItem';
import { useDispatch } from 'react-redux';
import { setSelectedBatchOrder } from 'store/batchOrders/actions';

export default function BatchedOrdersList({
  batchOrders,
  selectedBatchOrderId,
}) {
  const dispatch = useDispatch();
  const handleChange = (orderId) => {
    dispatch(setSelectedBatchOrder(orderId));
  };

  return (
    <ul className="details-list">
      {batchOrders
        .filter((batchOrder) => !batchOrder.isDeleted && !batchOrder.isAssigned)
        .map((batchOrder) => {
          return (
            <li key={batchOrder.id}>
              <BatchedOrderItem
                batchOrder={batchOrder}
                handleChange={handleChange}
                selected={selectedBatchOrderId === batchOrder.id}
              />
            </li>
          );
        })}
    </ul>
  );
}
