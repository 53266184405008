import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import GeneralModal from '../../../../modals/GeneralModal/index-new';
import './SelectDeliveryMethodModal.css';
import { UberIcon } from '../../../../assets/icons/UberIcon';
import { BicycleIcon } from '../../../../assets/icons/BicycleIcon';

interface SelectDeliveryMethodModalProps {
  toggleModal: () => void;
  setOpenSelectDriverModal: (value: boolean) => void;
  isUberDriveEnabled: boolean;
}

const SelectDeliveryMethodModal: React.FunctionComponent<
  SelectDeliveryMethodModalProps
> = ({ toggleModal, setOpenSelectDriverModal, isUberDriveEnabled }) => {
  const dispatch = useDispatch();

  const onModalClose = () => {
    toggleModal();
  };

  const handleInStoreClick = () => {
    setOpenSelectDriverModal(true);
    onModalClose();
  };

  const handleUberDriverClick = () => {
    dispatch({
      type: 'requestUberDriver',
      payload: true,
    });
    onModalClose();
  };

  return (
    <GeneralModal
      style={{ borderRadius: 28 }}
      modalStatus={true}
      toggleModal={onModalClose}
      position="center"
      overlay="blur"
      boxWidth="338px"
      boxHeight="318px"
      containerStyle={{}}
      closeBtn="outside"
      overlayStyle={{}}
      backBtnFnc={undefined}
      errorEffect={false}
      keyboardVisibility={false}
      isResponsiveHeight
      minBoxHeight
      showCloseBtn={true}
    >
      <div className="selectDeliveryContainer">
        <span className="modalHeading">Assign a Driver</span>

        <div className="deliveryMethodItemContainer">
          <div className="deliveryMethodItem" onClick={handleInStoreClick}>
            <div>
              <span className="inStore">In-Store </span>
              <span className="subText">Select from your drivers</span>
            </div>
            <div>
              <BicycleIcon fill="#5eb602" width="44px" height="35px" />
            </div>
          </div>

          {isUberDriveEnabled && (
            <div className="deliveryMethodItem" onClick={handleUberDriverClick}>
              <div>
                <span className={clsx('inStore', { ['uberDriver']: true })}>
                  Uber Driver{' '}
                </span>
                <span className="subText">Use on-demand network</span>
              </div>
              <div>
                <UberIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    </GeneralModal>
  );
};

export default SelectDeliveryMethodModal;
