import React from 'react';

function IconMinus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="3"
      viewBox="0 0 17 3"
    >
      <path
        fill="#363636"
        d="M23.643 18.225c.19 0 .366-.048.528-.145.162-.097.291-.227.387-.39.096-.163.145-.343.145-.54 0-.193-.049-.37-.145-.534a1.088 1.088 0 00-.387-.389 1.015 1.015 0 00-.528-.144H9.796a.986.986 0 00-.519.144c-.16.096-.29.226-.39.389-.1.163-.15.34-.15.533 0 .198.05.378.15.541.1.163.23.293.39.39.16.097.333.145.52.145h13.846z"
        opacity="0.7"
        transform="translate(-20 -250) translate(-2.5 21) translate(1.5 155.5) translate(13 15) translate(0 43)"
      ></path>
    </svg>
  );
}

export default IconMinus;
