import React from 'react';
import useDarkMode from 'use-dark-mode';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { PosIconMoon} from "../../assets/icons/PosIconMoon";

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <div className="dark-mode-btn-wrap">
      <PosIconMoon mainColor="#494b4c" darkModeColor="#494b4c" />
      <button className="btn-title" type="button" onClick={darkMode.toggle}>
        Dark Mode
      </button>
      <Toggle
        checked={darkMode.value}
        onChange={darkMode.toggle}
        icons={false}
      />
    </div>
  );
};

export default DarkModeToggle;
