import React from 'react';
import useIconColor from "../../customHooks/useIconColor";
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconOnlineCreditCard = ({
    mainColor,
    darkModeColor,
    lineThickness,
    className,
    ...props
}) => {
    const iconColor = useIconColor(mainColor, darkModeColor);
    const iconCls = classNames({
        'pos_icon-online-credit-card': true,
        [`${className}`]: className,
    });

    /*
    fill={iconColor ? iconColor : "#000"}
    stroke={iconColor ? iconColor : "#000"}
    strokeWidth={lineThickness ? lineThickness : 0}
    */
    return (
        <span className={iconCls} {...props} >
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 25 25">
                <title>Credit-Card</title>
                <path
                    fill={iconColor ? iconColor : '#000'}
                    stroke={iconColor ? iconColor : '#000'}
                    strokeWidth={lineThickness ? lineThickness : 0}
                    d="M10,16H3V15h7Zm6,1.5v-3a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-5A.5.5,0,0,1,16,17.5Zm1-.5h4V15H17ZM25,5.25v15.5A1.25,1.25,0,0,1,23.75,22H1.25A1.25,1.25,0,0,1,0,20.75V5.25A1.25,1.25,0,0,1,1.25,4h22.5A1.25,1.25,0,0,1,25,5.25ZM24,11H1v9.75a.25.25,0,0,0,.25.25h22.5a.25.25,0,0,0,.25-.25Zm0-3H1v2H24Zm0-2.75A.25.25,0,0,0,23.75,5H1.25A.25.25,0,0,0,1,5.25V7H24ZM12,17H3v1h9Z" />
            </svg>
        </span>
    );
};

PosIconOnlineCreditCard.propTypes = {
    mainColor: PropTypes.string,
    darkModeColor: PropTypes.string,
    lineThickness: PropTypes.number,
    className: PropTypes.string,
};
