import clsx from 'clsx';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { getItemPrice } from '../../orderEngine/utilities';
import { setItemActionsModalConfig } from '../../pages/Dashboard/action';
import {
  getInstructionAddString,
  getInstructionRemoveString,
} from '../../util/getInstructionString';
import {
  getHalfAndHalfTitle,
  getHalfHalfImagesData,
  getUniqueExtraIngredientsForHalfHalfItems,
  getUniqueRemovedExtraIngredientsForHalfHalfItems,
} from '../../util/half-and-half-utils';
import { isEmpty } from '../../util/utils';
import PriceCell from './PriceCell';
import './styles.scss';
import { SingleCartItemUI } from '../../UI/components';
import { Draggable } from 'react-beautiful-dnd';
import { mergeRefs } from 'react-merge-refs';

function useAddExitAnimationState({ title }) {
  const [addItemAction, setAddItemAction] = useState(false);
  const [deleteItemAction, setDeleteItemAction] = useState(false);

  useLayoutEffect(() => {
    setAddItemAction(true);
  }, [title]);

  useEffect(() => {
    let customTimeout = 0;

    if (addItemAction) {
      setTimeout(() => {
        setAddItemAction(false);
      }, 150);
    }
    return () => {
      clearTimeout(customTimeout);
    };
  }, [addItemAction]);

  useEffect(() => {
    let customTimeout = 0;

    if (deleteItemAction) {
      setTimeout(() => {
        setDeleteItemAction(false);
      }, 365);
    }

    return () => {
      clearTimeout(customTimeout);
    };
  }, [deleteItemAction]);

  return {
    addItemAction,
    deleteItemAction,
  };
}

function useScrollToItem(addItemAction, wrapRef, itemRef) {
  useEffect(() => {
    if (addItemAction && itemRef.current && wrapRef.current) {
      const elemOffset = itemRef?.current.offsetTop;
      const elemHeight = itemRef?.current.clientHeight;
      const lastViewScrollTop = wrapRef.current.scrollTop;
      const wrapHeight = wrapRef.current.clientHeight;

      if (lastViewScrollTop >= elemOffset) {
        itemRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else if (lastViewScrollTop + wrapHeight <= elemOffset + elemHeight) {
        itemRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }, [addItemAction, itemRef, wrapRef]);
}

export const CartItem = ({
  showPrice,
  lightMode,
  menuItem,
  firedCourse,
  basePath,
  placeholderFourQuarterImage,
  productSetup,
  wrapRef,
  disableDragAndDrop,
}) => {
  if (Array.isArray(menuItem)) {
    let halfHalfDraggableId = '';
    menuItem.forEach((item, index) => {
      if (index === 0) {
        halfHalfDraggableId = item.orderIndex;
      } else {
        halfHalfDraggableId = `${halfHalfDraggableId}-${item.orderIndex}`;
      }
    });
    return (
      <Draggable
        key={menuItem[0].orderIndex}
        draggableId={halfHalfDraggableId}
        index={menuItem[0].orderIndex}
      >
        {(provided) => (
          <HalfHalfOrderItem
            provided={provided}
            // dragRef={disableDragAndDrop ? null : drag}
            menuItem={menuItem}
            basePath={basePath}
            lightMode={lightMode}
            showPrice={showPrice}
            placeholderFourQuarterImage={placeholderFourQuarterImage}
            productSetup={productSetup}
            wrapRef={wrapRef}
          />
        )}
      </Draggable>
    );
  }

  return (
    <>
      <Draggable
        key={menuItem.orderIndex}
        draggableId={`${menuItem.orderIndex}`}
        index={menuItem.orderIndex}
        isDragDisabled={disableDragAndDrop}
      >
        {(provided, snapshot) => (
          <SingleOrderItem
            provided={provided}
            // dragRef={disableDragAndDrop ? null : provided.innerRef}
            menuItem={menuItem}
            basePath={basePath}
            lightMode={lightMode}
            showPrice={showPrice}
            wrapRef={wrapRef}
            firedCourse={firedCourse}
          />
        )}
      </Draggable>
    </>
  );
};

function SingleOrderItem({
  firedCourse,
  menuItem,
  basePath,
  lightMode,
  wrapRef,
  dragRef,
  provided,
}) {
  const dispatch = useDispatch();
  const itemRef = useRef();

  const {
    payablePrice,
    selectedDiscount,
    selectedVoucher,
    selectedSizes,
    selectedModifiers,
    courseName,
    courseId,
  } = menuItem;

  const price = getItemPrice(menuItem)?.payablePrice;
  const payPrice = !isEmpty(payablePrice) && payablePrice;
  const discountAmount = selectedDiscount && selectedDiscount.discount;
  const voucherCode = selectedVoucher && selectedVoucher.voucherCode;
  const selectedSizesWithQnty =
    selectedSizes && selectedSizes.filter((f) => f.quantity);

  let size = selectedSizesWithQnty.length
    ? `${selectedSizesWithQnty
        .map(
          (m) =>
            `${
              m.variants?.length
                ? m.name + '(' + m.variants.map((v) => v.name).join(',') + ')'
                : m.name
            }`
        )
        .join(',')}`
    : '';

  const titleWithQuantity = useMemo(() => {
    let title = `${menuItem.name}`;
    // for simple item just join the selectedSizes otherwise join the submodifiers
    let modifier = menuItem.itemType
      ? ', ' +
        selectedModifiers
          .filter((m) => m._id !== menuItem.baseModifierId)
          .map((m) => m.subModifiers.map((sm) => sm.name))
          .join(', ')
      : '';

    let quantity = menuItem.quantity > 1 ? ' x' + menuItem.quantity : '';

    return `${title}${modifier}${quantity}`;
  }, [
    menuItem.name,
    menuItem.quantity,
    selectedModifiers,
    menuItem.itemType,
    menuItem.baseModifierId,
  ]);

  const { addItemAction, deleteItemAction } = useAddExitAnimationState({
    title: titleWithQuantity,
  });

  const instructionAdd = getInstructionAddString(menuItem);
  const instructionRemove = getInstructionRemoveString(menuItem);
  const checkInstruction = !!(
    instructionAdd ||
    instructionRemove ||
    menuItem?.itemNotes
  );

  const handleClick = () => {
    let elementData = itemRef.current.getBoundingClientRect();

    dispatch(
      setItemActionsModalConfig({
        show: true,
        menuItem,
        basePath,
        buttonData: elementData,
      })
    );
  };

  const { isVoided = false } = menuItem;

  useScrollToItem(addItemAction, wrapRef, itemRef);

  return (
    <SingleCartItemUI
      onClick={handleClick}
      provided={provided}
      ref={mergeRefs([itemRef, dragRef])}
      dragRef={dragRef}
      title={titleWithQuantity}
      price={
        price ? (
          <PriceCell
            firedCourse={firedCourse}
            courseName={courseName}
            courseId={courseId}
            discountAmount={discountAmount}
            voucherCode={voucherCode}
            payablePrice={payPrice}
            price={price}
          />
        ) : null
      }
      size={size}
      notes={menuItem?.itemNotes}
      addInstruction={instructionAdd}
      removeInstruction={instructionRemove}
      checkInstruction={checkInstruction}
      additionalClasses={clsx({
        'add-item': addItemAction,
        'delete-item': deleteItemAction,
        voided: isVoided,
      })}
    />
  );
}

function HalfHalfOrderItem({
  menuItem: halfHalfMenuItemsTuple,
  basePath,
  lightMode,
  placeholderFourQuarterImage,
  productSetup,
  wrapRef,
  dragRef,
  provided,
}) {
  const itemRef = useRef();
  const {
    itemPrice,
    payablePrice,
    selectedDiscount,
    selectedVoucher,
    quantity,
  } = halfHalfMenuItemsTuple.reduce(
    (acc, menuItem) => {
      return {
        itemPrice: menuItem.itemPrice + acc.itemPrice,
        payablePrice: menuItem.payablePrice + acc.payablePrice,
        selectedDiscount: menuItem.selectedDiscount,
        selectedVoucher: menuItem.selectedVoucher,
        quantity: menuItem.quantity + acc.quantity,
      };
    },
    {
      itemPrice: 0,
      payablePrice: 0,
      selectedDiscount: {},
      selectedVoucher: {},
      quantity: 0,
    }
  );

  const price =
    itemPrice || getItemPrice(halfHalfMenuItemsTuple, productSetup)?.itemPrice;
  const payPrice = !isEmpty(payablePrice) && payablePrice;
  const discountAmount = selectedDiscount && selectedDiscount.discount;
  const voucherCode = selectedVoucher && selectedVoucher.voucherCode;

  const dispatch = useDispatch();
  const title = getHalfAndHalfTitle(halfHalfMenuItemsTuple);
  const uniqueExtraIngredients = getUniqueExtraIngredientsForHalfHalfItems(
    halfHalfMenuItemsTuple
  );
  const uniqueRemoveExtraIngredients =
    getUniqueRemovedExtraIngredientsForHalfHalfItems(halfHalfMenuItemsTuple);
  const halfHalfImagesData = getHalfHalfImagesData(halfHalfMenuItemsTuple);
  const extraIngredientsString = uniqueExtraIngredients?.join(', ');
  const extraRemoveIngredientsString = uniqueRemoveExtraIngredients?.join(', ');
  const halfHalfNotes = halfHalfMenuItemsTuple[0]?.itemNotes || '';

  const handleClick = () => {
    let elementData = itemRef.current.getBoundingClientRect();

    /**
     * copy pasted from `src/components/OrderBar/CartItems.js`
     */
    const menuItem = {
      name: title,
      isHalfHalf: halfHalfMenuItemsTuple.length === 2,
      isFourQuarters: halfHalfMenuItemsTuple.length === 4,
      selectedSizes: halfHalfMenuItemsTuple[1]?.selectedSizes?.map((size) => ({
        ...size,
        quantity: 1,
      })),
      selectedModifiers: [],
      urlS3: halfHalfImagesData[0].src,
      items: halfHalfMenuItemsTuple,
      iteration: halfHalfMenuItemsTuple[1].iteration,
      ...(halfHalfMenuItemsTuple[1]?.isVoided ? { isVoided: true } : null),
    };
    const instruction = extraIngredientsString + extraRemoveIngredientsString;

    dispatch(
      setItemActionsModalConfig({
        show: true,
        menuItem,
        basePath,
        instruction,
        buttonData: elementData,
      })
    );
  };

  const { addItemAction, deleteItemAction } = useAddExitAnimationState({
    title,
  });

  const isVoided = halfHalfMenuItemsTuple[1]?.isVoided || false;
  const checkInstruction = !!(
    halfHalfNotes ||
    !!uniqueExtraIngredients.length ||
    !!uniqueRemoveExtraIngredients.length
  );
  useScrollToItem(addItemAction, wrapRef, itemRef);

  return (
    <SingleCartItemUI
      provided={provided}
      dragRef={dragRef}
      onClick={handleClick}
      ref={itemRef}
      title={title}
      price={
        price && (
          <PriceCell
            courseName={halfHalfMenuItemsTuple[0].courseName}
            courseId={halfHalfMenuItemsTuple[0].courseId}
            discountAmount={discountAmount}
            voucherCode={voucherCode}
            payablePrice={payPrice}
            price={price}
            quantity={quantity}
          />
        )
      }
      checkInstruction={checkInstruction}
      notes={halfHalfNotes}
      addInstruction={extraIngredientsString}
      removeInstruction={extraRemoveIngredientsString}
      additionalClasses={clsx({
        'add-item': addItemAction,
        'delete-item': deleteItemAction,
        voided: isVoided,
      })}
    />
  );
}
