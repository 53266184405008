import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconLoading = ({
  mainColor,
  darkModeColor,
  lineThickness,
  size,
  className,
  style,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-loading': true,
    'profile-main-loader': true,
    [`${className}`]: className,
  });

  /*
  fill={iconColor ? iconColor : "#000"}
  stroke={iconColor ? iconColor : "#000"}
  strokeWidth={lineThickness ? lineThickness : 0}
  */
  return (
    <span
      className={iconCls}
      style={{
        width: size ? size : 40,
        height: size ? size : 40,
        ...style
      }}
      {...props}
    >
      <svg className="circular-loader" viewBox="25 25 50 50">
        <circle
          className="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          // stroke="#70c542"
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 5}
        />
      </svg>
    </span>
  );
};

PosIconLoading.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  width: PropTypes.any,
  className: PropTypes.string,
};
