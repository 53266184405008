import { createContext } from 'react';

export const OrdersListContext = createContext({});

const OrderListProvider = ({ storeId, orderDateFilter: defaultOrderDateFilter = 'current', orderTypeFilter: defaultOrderTypeFilter = 'all', children }) => {
  return (
    <OrdersListContext.Provider value={{}}>
      {children}
    </OrdersListContext.Provider>
  );
};

export function useOrdersListData() {
  /* const context = useContext(OrdersListContext);
  if (context === undefined) {
    throw new Error('useOrdersList must be used within a OrderListProvider');
  }

  const {
    ordersByDateFilter,
    orders,
    currentDateFilterOrderListLoading,
    currentDateFilterOrderListError,
    orderDateFilter,
    orderTypeFilter,
    setState,
  } = context;

  const getOrderByDateFilter = useCallback(
    (orderDateFilter) => {
      return ordersByDateFilter[orderDateFilter];
    },
    [ordersByDateFilter]
  );

  const ordersList = useMemo(() => {
    const list = orders[`${orderDateFilter}${cap(orderTypeFilter).replace('-', '')}OrdersList`];

    if (list) {
      return list.map(key => orders.allOrdersMap[key]).filter(Boolean)
    }
    return [];
  }, [orderDateFilter, orderTypeFilter, orders])

  return {
    ordersList,
    // methods
    getOrderByDateFilter,

    // loading and error state of current fetch
    currentDateFilterOrderListLoading,
    currentDateFilterOrderListError,

    // convenience re export
    orders,
    orderDateFilter,
    orderTypeFilter,
    setState,
  }; */

  return {};
}

export default OrderListProvider;
