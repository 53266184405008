import orderEngine from '../orderEngine/index';
import { isObjectNotEmpty } from '../util/utils';

const addVoucherToOrder = ({
  currentOrder,
  selectedVoucher,
  activeSpecials,
  orderSetup,
  productSetup,
  suburbs,
  publicHolidays,
  storeConfig,
}) => {
  let currentOrderObj = { ...currentOrder };

  currentOrderObj.selectedVoucher = isObjectNotEmpty(selectedVoucher)
    ? selectedVoucher
    : {};
  currentOrderObj.voucherId =
    isObjectNotEmpty(selectedVoucher) && selectedVoucher._id
      ? selectedVoucher._id
      : '';

  let newOrder = orderEngine(
    currentOrderObj,
    activeSpecials,
    orderSetup,
    productSetup,
    suburbs,
    publicHolidays,
    storeConfig
  );

  return newOrder;
};

export default addVoucherToOrder;
