import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconUpSquare = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-up-square': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="26"
        viewBox="0 0 25 26"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M23.467 22.923c0 .576-.468 1.044-1.044 1.044H2.587a1.044 1.044 0 0 1-1.044-1.044V3.087c0-.576.468-1.044 1.044-1.044h19.836c.576 0 1.044.468 1.044 1.044zM22.413 1H2.587A2.087 2.087 0 0 0 .5 3.087v19.826C.5 24.066 1.434 25 2.587 25h19.826a2.087 2.087 0 0 0 2.087-2.087V3.087A2.087 2.087 0 0 0 22.413 1z"
              />
              <path
                fill="none"
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness * 0.2 : 0.2}
                strokeMiterlimit="20"
                d="M23.467 22.923c0 .576-.468 1.044-1.044 1.044H2.587a1.044 1.044 0 0 1-1.044-1.044V3.087c0-.576.468-1.044 1.044-1.044h19.836c.576 0 1.044.468 1.044 1.044zM22.413 1H2.587A2.087 2.087 0 0 0 .5 3.087v19.826C.5 24.066 1.434 25 2.587 25h19.826a2.087 2.087 0 0 0 2.087-2.087V3.087A2.087 2.087 0 0 0 22.413 1z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M12.134 6.16L7.5 11.005l.731.765L11.98 7.84V20h1.03V7.84l3.759 3.931.731-.765-4.634-4.847A.504.504 0 0 0 12.5 6a.504.504 0 0 0-.366.16z"
              />
              <path
                fill="none"
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness * 0.2 : 0.2}
                strokeMiterlimit="20"
                d="M12.134 6.16L7.5 11.005v0l.731.765v0L11.98 7.84V20h1.03v0V7.84v0l3.759 3.931v0l.731-.765v0L12.866 6.16A.504.504 0 0 0 12.5 6a.504.504 0 0 0-.366.16z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconUpSquare.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
