const actionTypes = {
  toggleDeliveriesDriver: 'toggleDeliveriesDriver',
  setAssignDriver: 'setAssignDriver',
  toggleAssignDriver: 'toggleAssignDriver',
  toggleUnAssignedOrder: 'toggleUnAssignedOrder',
  setActiveDriver: 'setActiveDriver',
  FetchActiveDrivers: 'FETCH_ACTIVE_DRIVERS',
  FetchActiveDriverLocations: 'FETCH_ACTIVE_DRIVER_LOCATIONS',
  FetchActiveDriverShifts: 'FETCH_ACTIVE_DRIVER_SHIFTS',
  FetchActiveDriverRuns: 'FETCH_ACTIVE_DRIVER_RUNS',
  FetchActiveDriverRunOrders: 'FETCH_ACTIVE_DRIVER_RUN_ORDERS',
  FetchDriverCashBalance: 'FETCH_DRIVER_CASH_BALANCE',
  setSelectedDriverForDeliveries: 'setSelectedDriverForDeliveries',
  setFilteredDrivers: 'setFilteredDrivers',
  setCurrentDriver: 'setCurrentDriver',
  Initialize: 'Initialize',
  toggleSelectedUnAssignedOrder: 'toggleSelectedUnAssignedOrder',
  toggleSelectedUnAssignedOrdersForDeliveryBatches:
    'toggleSelectedUnAssignedOrdersForDeliveryBatches',
  resetSelectedOrdersAndDriver: 'resetSelectedOrdersAndDriver',
  resetSelectedUnAssignedOrdersForDeliveryBatches:
    'resetSelectedUnAssignedOrdersForDeliveryBatches',
  ordersListFetchStatusUpdate: 'ordersListFetchStatusUpdate',
  setOrderList: 'setOrderList',
};

export default actionTypes;
