import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { nextOrderConfig } from '../../configuration/nextOrderConfig';
import useFetch from '../../hooks/useFetch';
import { storeIdSelector } from '../Dashboard/selectors';
import { currentDriverDataForCashFloatSelector } from './selectors';
import ViewDriverDeliveries from './ViewDriverDeliveries';
import { ScrollableBoxUI } from '../../UI/components';

const DriverCashFloatViewDeliveries = () => {
  const storeId = useSelector(storeIdSelector);
  const currentDriver = useSelector(currentDriverDataForCashFloatSelector);
  const { driverId = '', firstName = '', lastName = '' } = currentDriver;

  const [data, loading] = useFetch(
    `${nextOrderConfig.cloudFunctionUrl}/getDeliveryListForCashFloat`,
    JSON.stringify({
      driverId,
      storeId,
    }),
    null,
    'POST'
  );

  return (
    <div className="driver-main single-driver-style">
      <div className="driver-inner-box">
        <ScrollableBoxUI>
          <div className="single-driver-header">
            <Link to="/drivers/all/" className="back-modal-btn hover-highlight" title="Back" />

            {/* <button
            type="button"
            className="modal-delivery-action-btn hover-highlight"
          ></button> */}

            <div className="booking-inner-table">
              <div className="inner-cell">
                <h2 className="modal-title">{`${firstName || ''} ${lastName || ''}`}</h2>
              </div>
              {/* <div className="inner-cell">
              <ul className="booking-actions-list">
                <li>
                  <button
                    className="booking-action-btn hover-highlight"
                    type="button"
                    title="Call"
                  >
                    <span className="icon-box">
                      <span className="dine_icon-phone" />
                    </span>
                    <span className="name">Call</span>
                  </button>
                </li>
                <li>
                  <button
                    className="booking-action-btn hover-highlight"
                    type="button"
                    title="Message"
                  >
                    <span className="icon-box">
                      <span className="dine_icon-chat_bubble" />
                    </span>
                    <span className="name">Message</span>
                  </button>
                </li>
              </ul>
            </div> */}
            </div>
          </div>
          <ViewDriverDeliveries
            orders={data?.orders || []}
            isLoading={loading}
            driverId={driverId}
          />
          {/* <div className="driver-orders-wrap">
            <div className="details-tab-wrap unassigned">
              <DriverOrderList orders={data.orders || []} />
            </div>
        </div> */}
        </ScrollableBoxUI>
      </div>
    </div>
  );
};

export default DriverCashFloatViewDeliveries;
