import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import GeneralModal from 'modals/GeneralModal/index-new';
import Button from 'components/Button';
import { PosIconLeftChevronSecond } from 'assets/icons/PosIconLeftChevronSecond';

export const EnterNumberGuests = ({
  modalStatus,
  onNextClick,
  onModalClose,
  selectedShape,
}) => {
  const [guestsNumber, setGuestsNumber] = useState(0);
  const [activeKeyId, setActiveKeyId] = useState('');
  const mainRef = useRef();
  const keyList = useRef([
    { id: 1, name: '1', val: '1' },
    { id: 2, name: '2', val: '2' },
    { id: 3, name: '3', val: '3' },
    { id: 4, name: '4', val: '4' },
    { id: 5, name: '5', val: '5' },
    { id: 6, name: '6', val: '6' },
    { id: 7, name: '7', val: '7' },
    { id: 8, name: '8', val: '8' },
    { id: 9, name: '9', val: '9' },
    {
      id: 10,
      name: '',
      val: '',
    },
    { id: 11, name: '0', val: '0' },
    {
      id: 13,
      name: <PosIconLeftChevronSecond mainColor="#515151" />,
      val: 'Clear',
    },
  ]);

  const handleKeyDown = (e) => {
    if (e !== undefined && e.keyCode !== undefined) {
      let keyVal = '';
      const keyDelList = [8, 46];
      const keyNumList = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];

      if (keyDelList.includes(Number(e.keyCode || e.which))) {
        keyVal = 'Clear';
      } else if (keyNumList.includes(Number(e.keyCode || e.which))) {
        keyVal = e.key;
      }

      if (keyVal) {
        if (keyList.current.length > 0) {
          keyList.current.forEach((item, i) => {
            if (item.val.toString() === keyVal.toString()) {
              setActiveKeyId(item.id);
            }
          });
        }

        writeNumber(keyVal);
      }
    }
  };

  const writeNumber = (val) => {
    let arr = Array.from(guestsNumber);
    let newVal = '';

    if (arr.length === 0 && val !== 'Clear') {
      setGuestsNumber(val);
      return;
    }

    if (val.toLowerCase() === 'clear') {
      arr.splice(-1, 1);
    } else {
      arr.push(val);
    }

    newVal = arr.join('');
    setGuestsNumber(newVal);
  };

  useEffect(() => {
    modalStatus && mainRef.current.focus();
  }, [modalStatus]);

  const handleNext = () => {
    onNextClick(guestsNumber);
    setGuestsNumber(0);
  };

  const handleClose = () => {
    onModalClose();
    setGuestsNumber(0);
    mainRef.current.classList.remove('loaded');
  };

  return (
    <GeneralModal
      id="digits-input-modal"
      modalStatus={modalStatus}
      toggleModal={onModalClose}
      position="bottom"
      overlay="blur"
      boxWidth="435px"
      boxHeight="728px"
      className="digits-input-modal"
      backBtn="outside"
      backBtnFnc={handleClose}
    >
      <div
        ref={mainRef}
        onKeyDown={handleKeyDown}
        tabIndex="0"
        className="digits-input-modal-box"
      >
        <div className="digits-input-header">
          <h2 className="digits-input-title">Enter Number of Guests</h2>
          <div className="digits-input-line">
            <div className="digits-input-inner">{guestsNumber}</div>
            <div className="digits-input-selected">
              <span>
                {selectedShape?.name || `Table ${selectedShape?.number}`}
              </span>
            </div>
          </div>
        </div>
        <div className="digits-input-keypad-box">
          {keyList.current.map((item) => {
            const itemCls = clsx({
              'digits-input-key': true,
              'hover-highlight': true,
              active: activeKeyId === item.id,
              [`key-${item.val.toLowerCase()}`]: true,
            });

            return (
              <div key={item.id} className="digits-input-key-wrap">
                <Button
                  title={item.val}
                  className={itemCls}
                  onClick={(e) => writeNumber(item.val)}
                >
                  {item.name}
                </Button>
              </div>
            );
          })}
        </div>
        <div className="digits-input-btn-box">
          <Button className="btn" title="Next" onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

EnterNumberGuests.propTypes = {
  modalStatus: PropTypes.bool,
  onNextClick: PropTypes.func,
  onModalClose: PropTypes.func,
  selectedShape: PropTypes.object,
};
