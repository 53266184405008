import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconPhoneInverse = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-phone-inverse': true,
    [`${className}`]: className,
  });

  /*
  fill={iconColor ? iconColor : "#000"}
  stroke={iconColor ? iconColor : "#000"}
  strokeWidth={lineThickness ? lineThickness : 0}
  */
  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 95.5 95.5"
      >
        <g id="Regular-M" transform="matrix(1 0 0 1 1390.86 1126)">
          <path
            fill={iconColor ? iconColor : '#000'}
            stroke={iconColor ? iconColor : '#000'}
            strokeWidth={lineThickness ? lineThickness : 0}
            d="M-1319.9-1030.5c8.5,0,14.2-2.3,19.1-7.9c0.4-0.4,0.8-0.8,1.1-1.2c2.9-3.3,4.3-6.5,4.3-9.6c0-3.5-2-6.8-6.4-9.8l-14.2-9.9   c-4.4-3-9.5-3.4-13.6,0.7l-3.8,3.8c-1.1,1.1-2.1,1.2-3.2,0.5c-2.6-1.7-8-6.3-11.6-9.9c-3.8-3.8-7.5-8-9.4-11   c-0.6-1.1-0.6-2.1,0.5-3.2l3.7-3.8c4.1-4.1,3.8-9.3,0.7-13.6l-9.9-14.2c-3-4.3-6.3-6.3-9.8-6.4c-3.1,0-6.3,1.4-9.6,4.3   c-0.4,0.4-0.8,0.7-1.2,1.1c-5.6,5-7.9,10.6-7.9,19c0,14,8.6,31,24.4,46.7C-1350.8-1039.2-1333.8-1030.5-1319.9-1030.5z    M-1319.8-1038c-12.5,0.2-28.4-9.3-41.1-21.9c-12.7-12.7-22.8-29.2-22.5-41.7c0.1-5.4,2-10,5.8-13.3c0.3-0.3,0.6-0.5,0.9-0.8   c1.5-1.3,3-2,4.4-2s2.7,0.5,3.6,2l9.5,14.2c1,1.5,1.1,3.2-0.4,4.7l-4.3,4.3c-3.4,3.4-3.1,7.5-0.7,10.7c2.8,3.8,7.6,9.2,11.4,12.9   c3.7,3.8,9.6,9,13.4,11.9c3.3,2.4,7.4,2.7,10.7-0.7l4.3-4.3c1.5-1.5,3.2-1.4,4.7-0.4l14.2,9.5c1.5,1,2,2.2,2,3.7c0,1.4-0.7,3-2,4.4   c-0.3,0.3-0.5,0.6-0.8,0.9C-1309.8-1040-1314.4-1038.1-1319.8-1038z"
          />
        </g>
      </svg>
    </span>
  );
};

PosIconPhoneInverse.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
