import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PosIcon } from './PosIcon';

export const PosIconSearch = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-search': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
      >
        <title>Search</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M25,23.56l-8.11-8.11a9.5,9.5,0,1,0-1.41,1.42L23.59,25ZM9.5,17A7.5,7.5,0,1,1,17,9.5,7.51,7.51,0,0,1,9.5,17Z"
        />
      </svg>
    </span>
  );
};

PosIconSearch.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
