import React, { useState } from 'react';
import GeneralModal from '../GeneralModal/index-new';
import PropTypes from 'prop-types';
import { currencyFormat } from '../../util/currencyFormat';
import Button from '../../components/Button';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';

export const DenominationsShortModal = ({
  modalStatus,
  toggleModal,
  totalPrice,
  confirmForm,
  isShort,
  isLoading = false,
}) => {
  const [reason, setReason] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    event.preventDefault();
    setReason(event.target.value);
  };

  const onConfirmForm = () => {
    confirmForm(reason);
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="center"
      effect="move"
      overlay=""
      boxWidth="514px"
      boxHeight="381px"
      className="denominations-short-modal small-radius"
    >
      <div className="denominations-short-modal-box">
        <h2 className="denominations-short-title">
          {totalPrice
            ? `${isShort ? '(Short) ' : '(OverDue) '} ${currencyFormat(
                totalPrice
              )}`
            : ''}
        </h2>

        <form
          action=""
          className="denominations-short-form"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="single-input-box">
            <label htmlFor="discrepancy-reason">Discrepancy Reason</label>
            <textarea
              id="discrepancy-reason"
              name="discrepancy-reason"
              className="input-style white"
              value={reason}
              onChange={handleChange}
            />
          </div>
        </form>

        <div className="denominations-short-btn-box">
          <Button title="Confirm" className="btn" onClick={onConfirmForm}>
            {!isLoading ? (
              'Confirm'
            ) : (
              <PosIconLoading mainColor="#ffffff" size={20} />
            )}
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

DenominationsShortModal.propTypes = {
  totalPrice: PropTypes.any,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  confirmForm: PropTypes.func,
};
