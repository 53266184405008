import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getMenuItemForInstore,
  getMinifiedMenuItemForInstore,
} from 'pricingEngine';
import useOrderType from './useOrderType';
import { useFlags } from 'launchdarkly-react-client-sdk';

function useMenuItems() {
  const [_, orderType] = useOrderType();
  const { fetchMinifiedMenuItemSizesAndSubModifiers } = useFlags();
  const menuItemSizes = useSelector((s) => s.dashboardReducer.menuItemSizes);
  const menuItemSizesFiltered = useMemo(
    () => menuItemSizes.filter((s) => s.orderType === orderType),
    [menuItemSizes, orderType]
  );

  const menuItemModifiers = useSelector(
    (s) => s.dashboardReducer.menuItemModifiers
  );

  const menuItemModifiersFiltered = useMemo(
    () => menuItemModifiers.filter((s) => s.orderType === orderType),
    [menuItemModifiers, orderType]
  );

  const menuItemSubModifiers = useSelector(
    (s) => s.dashboardReducer.menuItemSubModifiers
  );

  const menuItemSubModifiersFiltered = useMemo(
    () => menuItemSubModifiers.filter((s) => s.orderType === orderType),
    [menuItemSubModifiers, orderType]
  );

  const menuItems = useSelector((s) => s.dashboardReducer.menuItems);

  const menuItemsFiltered = useMemo(() => {
    const filtered = menuItems.filter(
      (m) =>
        m.orderFromKeys.includes('2') &&
        m.orderTypeKeys.includes(orderType) &&
        m.isPublished
    );

    const mapped = filtered.map((m) => {
      return fetchMinifiedMenuItemSizesAndSubModifiers
        ? getMinifiedMenuItemForInstore(
            m,
            menuItemSizes.filter((s) => s.menuItemId === m._id),
            menuItemModifiers.filter((s) => s.menuItemId === m._id),
            menuItemSubModifiers.filter((s) => s.menuItemId === m._id),
            orderType
          )
        : getMenuItemForInstore(
            m,
            menuItemSizesFiltered.filter((s) => s.menuItemId === m._id),
            menuItemModifiersFiltered.filter((s) => s.menuItemId === m._id),
            menuItemSubModifiersFiltered.filter((s) => s.menuItemId === m._id)
          );
    });

    return mapped;
  }, [
    menuItemSizesFiltered,
    orderType,
    menuItemModifiersFiltered,
    menuItemSubModifiersFiltered,
    menuItems,
  ]);

  return menuItemsFiltered;
}

export default useMenuItems;
