import React from 'react';
import PropTypes from 'prop-types';
import GeneralModal from '../GeneralModal/index-new';
import { DriversMapIframe } from '../../components/Drivers/DriversMapIframe';

export const DriversMapMaxModal = ({ modalStatus, toggleModal, driver={} }) => {
  
  const orderData = {};

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="bottom"
      effect="move"
      overlay=""
      boxWidth="886px"
      boxHeight="705px"
      className="drivers-map-max-modal"
      closeBtn="inside"
    >
      <div className="drivers-map-max-box">
        <div className="drivers-map-max-header">
          <h3
            className={'drivers-map-max-modal-title ' + orderData.priorityClass}
          >
            <span>On Delivery {orderData.deliveryTime}</span> -{' '}
            {orderData.timeDifference}
          </h3>
        </div>
        <div className="drivers-map-max-body">
          <div className="driver-map-max-wrap">
            <DriversMapIframe
              iframeWidth={890}
              iframeHeight={900}
              driver={driver}
              id="map-modal"
            />
          </div>
        </div>
      </div>
    </GeneralModal>
  );
};

DriversMapMaxModal.propTypes = {
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};
