import groupBy from 'lodash/groupBy';
import { prepareItemForOrder } from '../itemProcessor';
import addItemToOrder from './addItemToOrder';
import {
  detectAndMergeHalfAndHalf,
  detectAndMergeQuarters,
  removeUnwantedProperties,
} from './utilities';
import orderEngine from '../orderEngine/index';

const addItemsToOrder = (
  currentOrder,
  items,
  specials,
  orderSetup,
  productSetup,
  suburbs,
  publicHolidays,
  storeConfig
) => {
  let newOrder = { ...currentOrder };
  const finalItems = [];

  items.forEach((item) => {
    finalItems.push(
      prepareItemForOrder(
        removeUnwantedProperties(item, [
          'isCurrentMenuItemInEditMode',
          'categoryIndex',
        ]),
        currentOrder
      )
    );
  });

  const currentOrderLastItemHalfIndex =
    newOrder.menuItems.filter((mi) => mi.isHalf).slice(-1)[0]?.halfIndex || 1;
  const currentOrderLastItemFourQuarterIndex =
    newOrder.menuItems.filter((mi) => mi.isHalf).slice(-1)[0]
      ?.fourQuarterIndex || 1;

  let halfAndHalfItems = finalItems.filter((item) => item.isHalf);

  // increment the halfIndex for Halfs
  halfAndHalfItems = halfAndHalfItems.map((item, idx) => {
    const lastItemHalfIndex =
      Math.floor(currentOrderLastItemHalfIndex + idx / 2) + 1 || 1;
    return {
      ...item,
      halfIndex: lastItemHalfIndex,
    };
  });

  const groupedHalfItems = groupBy(halfAndHalfItems, (item) => item?.halfIndex);
  const halfItemsIndexes = Object.keys(groupedHalfItems);

  let quarterItems = finalItems.filter((item) => item.isQuarter);

  // increment the halfIndex for Quarters
  quarterItems = quarterItems.map((item, idx) => {
    const lastItemHalfIndex =
      Math.floor(currentOrderLastItemFourQuarterIndex + idx / 4) + 1 || 1;
    return {
      ...item,
      halfIndex: lastItemHalfIndex,
    };
  });

  const groupedQuarterItems = groupBy(
    quarterItems,
    (item) => item?.fourQuarterIndex
  );
  const quarterItemsIndexes = Object.keys(groupedQuarterItems);

  const normalItems = finalItems.filter(
    (item) => !item.isHalf && !item?.isQuarter
  );

  halfItemsIndexes.forEach((halfItemIndex) => {
    newOrder = {
      ...newOrder,
      menuItems: detectAndMergeHalfAndHalf(
        groupedHalfItems[halfItemIndex],
        newOrder.menuItems
      ),
    };
  });

  if (halfItemsIndexes.length > 0) {
    newOrder = orderEngine(
      newOrder,
      specials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );
  }

  quarterItemsIndexes.forEach((quarterItemIndex) => {
    newOrder = {
      ...newOrder,
      menuItems: detectAndMergeQuarters(
        groupedQuarterItems[quarterItemIndex],
        newOrder.menuItems
      ),
    };
  });

  if (quarterItemsIndexes.length > 0) {
    newOrder = orderEngine(
      newOrder,
      specials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );
  }

  normalItems.forEach((item) => {
    newOrder = {
      ...addItemToOrder(
        newOrder,
        item,
        specials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      ),
    };
  });

  return newOrder;
};

export default addItemsToOrder;
