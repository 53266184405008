import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  storeCountryIdentifierSelector,
  storeLocationCoordinatesSelector,
} from '../pages/Dashboard/selectors';
import { MAPBOX_KEY } from '../configuration/mapboxKey';

export const useFetchMapboxApi = (searchText, shouldCallApi) => {
  const storeLocationCoordinates = useSelector(
    storeLocationCoordinatesSelector
  );
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchUrl() {
      const bboxLowerLat = storeLocationCoordinates.lat - 1;
      const bboxUpperLat = storeLocationCoordinates.lat + 1;
      const bboxLowerLong = storeLocationCoordinates.long - 1;
      const bboxUpperLong = storeLocationCoordinates.long + 1;

      const mapboxApiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        searchText
      )}.json?country=${storeCountryIdentifier}&proximity=${
        storeLocationCoordinates.long
      },${
        storeLocationCoordinates.lat
      }&bbox=${bboxLowerLong},${bboxLowerLat},${bboxUpperLong},${bboxUpperLat}&access_token=${MAPBOX_KEY}`;
      try {
        const response = await axios.get(mapboxApiUrl);
        setData(response.data);
      } catch (error) {
        console.error(error);
        setData({});
      }
    }
    if (shouldCallApi && searchText && searchText.length >= 3) {
      fetchUrl();
    } else {
      setData({});
    }
  }, [
    shouldCallApi,
    searchText,
    storeCountryIdentifier,
    storeLocationCoordinates,
  ]);
  return [data];
};
