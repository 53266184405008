export const getFilteredDrivers = (
  drivers,
  driverLocations,
  driverShifts,
  driverRunOrders,
  driverCashBalances
) => {
  let finalList = [];
  drivers.forEach((driver) => {
    let obj = {
      _id: driver.driverId,
      firstName: driver.firstName,
      lastName: driver.lastName,
      mobileNumber: driver.mobileNumber,
      storeId: driver.storeId,
      restaurantId: driver.restaurantId,
      isSelected: false,
    };
    //Find Location data for the driver
    let matchingLocations = driverLocations.filter((f) => {
      return f.driverId === driver.driverId && driver.storeId === f.storeId;
    });
    if (matchingLocations.length > 0) {
      let matchingLocation = matchingLocations[0];
      obj.status = matchingLocation.status;
      obj.isActive = matchingLocation.isActive;
      obj.storeId = matchingLocation.storeId;
      obj.restaurantId = matchingLocation.restaurantId;
      obj.shiftId = matchingLocation.currentShiftId;
      obj.currentLocation = matchingLocation.currentLocation;
      obj.previousLocation = matchingLocation.previousLocation;
      obj.lastUpdatedTimestamp = matchingLocation.lastUpdatedTimestamp;
    } else {
      obj.status = 0;
      obj.isActive = false;
    }
    //Fimd Shift data for the driver
    let matchingShifts =
      driverShifts && driverShifts.length > 0
        ? driverShifts.filter((f) => {
            return (
              f.driverId === driver.driverId && driver.storeId === f.storeId
            );
          })
        : [];
    if (matchingShifts.length > 0) {
      let matchingShift = matchingShifts[0];
      obj.arrivingToStoreTime = matchingShift.arrivingToStoreTime;
      obj.arrivingToStoreTimestamp = matchingShift.arrivingToStoreTimestamp;
    }

    //Find Active run orders of driver
    obj.activeOrders =
      driverRunOrders && driverRunOrders.length
        ? driverRunOrders.filter(
            (f) =>
              f.driverId === driver.driverId && driver.storeId === f.storeId
          )
        : [];

    //Find Active but delivered orders
    obj.activeDeliveredOrders =
      driverRunOrders && driverRunOrders.length
        ? driverRunOrders.filter(
            (f) =>
              f.driverId === driver.driverId &&
              driver.storeId === f.storeId &&
              f.orderStatus === '4'
          )
        : [];

    //Find cash balance for the driver
    let matchingCashBalances =
      driverCashBalances && driverCashBalances.length > 0
        ? driverCashBalances.filter((f) => {
            return (
              f.driverId === driver.driverId && driver.storeId === f.storeId
            );
          })
        : [];
    if (matchingCashBalances.length > 0) {
      obj.cashBalance = matchingCashBalances[0];
      if (obj.cashBalance.status === '0') {
        obj.cashBalance.cashFloat = 'Start';
      } else if (obj.cashBalance.status === '1') {
        obj.cashBalance.cashFloat = 'End';
      }
    } else {
      obj.cashBalance = { status: '0', cashFloat: 'Start' };
    }
    finalList.push(obj);
  });
  //Filter based on filter key
  return finalList;
};

export const driverStatusList = [
  {
    id: 0,
    title: 'Waiting on Store',
    colorTheme: 'green',
    key: 0,
    activeflag: true,
  }, // 0 - Waiting in Store
  {
    id: 2,
    title: 'Returning to Store',
    colorTheme: 'orange',
    key: 2,
    activeflag: true,
  }, // 2 -  Returning to Store
  { id: 1, title: 'On Delivery', colorTheme: 'red', key: 1, activeflag: true }, // 1 - On Delivery
  {
    id: 3,
    title: 'Clocked Out',
    colorTheme: 'grey',
    key: 0,
    activeflag: false,
  }, // 3 - clockedOut
];
