import clsx from 'clsx';
import { ModalPortal } from 'components';
import { useModal } from 'hooks';
import { setActiveStaffMember, toggleBlurEffect } from 'pages/Dashboard/action';
import PropTypes from 'prop-types';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isForgotToClockOutOrOnABreak } from 'util/shifts-screen-utils';
import GeneralModal from '../../modals/GeneralModal/index-new';
import { authenticateStaffByAccessCode } from '../../services/homeServices';
import { hasRightPermission } from '../../util/access-code-utils';
import { isObjectNotEmpty } from 'util/utils';
import Button from '../Button';

const KEYS = [
  ...new Array(9)
    .fill(0)
    .map((_, i) => ({ id: i + 1, name: `${i + 1}`, val: i + 1 })),
  { id: 10, name: '0', val: 0 },
  { id: 11, name: 'Cancel', val: 'delete' },
];
const PIN_LEN = 4;
const Key = memo(
  ({ item, onClick, accessCodeEmpty }) => {
    const itemName = useMemo(
      () => (item.val === 'delete' && !accessCodeEmpty ? 'Delete' : item.name),
      [accessCodeEmpty, item.name, item.val]
    );
    return (
      <Button
        className={`numeric-key key-${item.val}`}
        title={item.name}
        onClick={onClick(item.val)}
      >
        <span className="btn-text">{itemName}</span>
      </Button>
    );
  },
  (prev, next) => prev.item.val !== 'delete'
);
const StaffPinModalWrapper = (props) => {
  const [modalStatus, { closeModal }] = useModal('staffPinModal');
  if (!modalStatus) return null;
  return (
    <ModalPortal>
      <StaffPinModal
        modalStatus={modalStatus}
        closeModal={closeModal}
        {...props}
      />
    </ModalPortal>
  );
};
const StaffPinModal = ({
  onComplete,
  setIsClockedIn,
  setShowAccessDenied,
  setErrorMessage,
  orderSetup,
  modalStatus,
  setIsOnBreak,
  closeModal,
  errorMessage,
  wiggleEffect,
  setWiggleEffect,
  setShowClockInOutModal,
  handleCustomCloseModal,
  isSettleOrder,
  onSuccess,
  saveStaffData = true,
}) => {
  const storeConfig = useSelector((s) => s.dashboardReducer.storeConfig);
  const dispatch = useDispatch();
  const mainRef = useRef();
  // const [tabIndex, setTabIndex] = useState(111)
  const wiggleTimeout = useRef(0);
  const goToRouteAfterAuth = useSelector(
    (state) => state.dashboardReducer.goToRouteAfterAuth
  );
  const [accessCode, write] = useReducer(
    (s, a) => (a === 'delete' ? s.slice(0, -1) : a === 'clear' ? '' : (s += a)),
    ''
  );
  const accessCodeEmpty = useMemo(
    () => accessCode.length === 0,
    [accessCode.length]
  );
  const exitModal = () => {
    dispatch(toggleBlurEffect(false));
    closeModal();
  };
  const onModalClose = useCallback(() => {
    if (typeof handleCustomCloseModal === 'function') {
      handleCustomCloseModal();
    }
    exitModal();
    write('clear');
    dispatch(toggleBlurEffect(false));
    // eslint-disable-next-line
  }, [exitModal, dispatch, write]);

  const handleKeyDown = (e) => {
    if (e !== undefined && e.keyCode !== undefined) {
      let keyVal = '';
      const keyEsc = [27];
      const keyDelList = [8, 46];
      const keyNumList = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];

      if (keyDelList.includes(Number(e.keyCode || e.which))) {
        keyVal = 'delete';
      } else if (keyEsc.includes(Number(e.keyCode || e.which))) {
        keyVal = 'delete';
      } else if (keyNumList.includes(Number(e.keyCode || e.which))) {
        keyVal = e.key;
      }
      if (keyVal) {
        write(keyVal);
      }
    }
  };
  const checkClockInClockOut = useCallback(
    (currentShift) => {
      const showModal = currentShift
        ? isForgotToClockOutOrOnABreak(currentShift, orderSetup)
        : true;
      setShowClockInOutModal(showModal);
    },
    [orderSetup, setShowClockInOutModal]
  );
  const handleComplete = useCallback(
    async (accessCode, cb) => {
      // make api call to authenticate the staff
      if (storeConfig?.storeId && accessCode) {
        const data = await authenticateStaffByAccessCode(
          storeConfig?.storeId,
          accessCode
        );
        if (data.error === '' && data.staffMember != null) onSuccess?.();

        if (data.permissions) {
          if (
            goToRouteAfterAuth &&
            hasRightPermission(goToRouteAfterAuth, data.permissions)
          ) {
            exitModal();

            // check for the "clockInReminder" flag first
            if (orderSetup?.clockInReminder) {
              const currentShift =
                data?.openShifts?.length > 0 && data?.openShifts[0];
              if (goToRouteAfterAuth !== '/shifts/clock-in') {
                setIsOnBreak(currentShift.onABreak);
                setIsClockedIn(Boolean(currentShift));
                checkClockInClockOut(currentShift, goToRouteAfterAuth);
              }
            }
            if (saveStaffData) {
              dispatch(
                setActiveStaffMember({
                  ...data?.staffMember,
                  roles: data?.roles,
                  numOpenShifts: data?.numOpenShifts,
                  openShifts: data?.openShifts,
                })
              );
            }

            if (cb) {
              cb(data);
            }
          } else if (
            cb &&
            isSettleOrder &&
            isObjectNotEmpty(data.staffMember)
          ) {
            exitModal();
            cb(data);
          } else if (
            cb &&
            !saveStaffData &&
            isObjectNotEmpty(data.staffMember)
          ) {
            exitModal();
            cb(data);
          } else if (cb) {
            dispatch(
              setActiveStaffMember({
                ...data?.staffMember,
                roles: data?.roles,
                numOpenShifts: data?.numOpenShifts,
                openShifts: data?.openShifts,
              })
            );
            exitModal();
            cb(data);
          } else {
            setErrorMessage(
              errorMessage || 'You do not have a permission to place an order.'
            );
            setShowAccessDenied(true);
            exitModal();
            dispatch(toggleBlurEffect(false));
          }
        } else if (data.error) {
          write('clear');
          setErrorMessage(data.error);
          setWiggleEffect(true);
        }
      }
    },
    // eslint-disable-next-line
    [
      goToRouteAfterAuth,
      dispatch,
      storeConfig,
      checkClockInClockOut,
      orderSetup?.clockInReminder,
      closeModal,
      setErrorMessage,
      setIsClockedIn,
      setIsOnBreak,
      setShowAccessDenied,
      setWiggleEffect,
    ]
  );
  //switch clear/delete and check passcode
  useEffect(() => {
    if (accessCode.length === PIN_LEN) {
      handleComplete(accessCode, onComplete);
    }
  }, [handleComplete, accessCode, accessCode.length, onComplete, dispatch]);

  //when entered wrong passcode need use wiggle effect
  useEffect(() => {
    if (wiggleEffect) {
      wiggleTimeout.current = setTimeout(() => {
        write('clear');
        setWiggleEffect(false);
      }, 850);
    }
    return () => clearTimeout(wiggleTimeout.current);
  }, [wiggleEffect, setWiggleEffect]);

  const handleClick = useCallback(
    (value) => {
      if (value === 'delete' && accessCode.length === 0) return onModalClose;
      return () => write(value);
    },
    [accessCode.length, onModalClose]
  );
  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={onModalClose}
      position="center"
      overlay="blur"
      effect=""
      boxWidth="435px"
      boxHeight="649px"
      className="staff-pin-modal"
      closeBtn="outside"
      errorEffect={wiggleEffect}
    >
      <div
        ref={mainRef}
        // tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        className="staff-pin-modal-box"
      >
        <h2 className="staff-pin-title">Enter Access Code</h2>
        <p className="staff-pin-desc">
          {errorMessage || 'Your access code is required to place an order'}
        </p>
        <div className="staff-pin-line">
          {Array(PIN_LEN)
            .fill(0)
            .map((_, i) => {
              const item = accessCode[i];
              const itemCls = clsx({
                'staff-pin-circle': true,
                selected: item,
              });
              return <span className={itemCls} key={i} />;
            })}
        </div>
        <div className="numeric-keypad-box">
          {KEYS.map((item, index) => (
            <Key
              key={index}
              item={item}
              onClick={handleClick}
              accessCodeEmpty={accessCodeEmpty}
            />
          ))}
        </div>
      </div>
    </GeneralModal>
  );
};
StaffPinModal.propTypes = {
  toggleModal: PropTypes.func,
};
export default StaffPinModalWrapper;
