import React, { useState } from 'react';
import './_markup.scss';
import Toggle from 'react-toggle';
import useDarkMode from 'use-dark-mode';
/* import {
  ButtonGroupUI,
  ButtonUI,
  HeaderUI,
  ScrollableBoxUI,
} from '../../UI/components';
import { LogoButton } from '../../components/LogoButton';
import { NEW_MARKUP_Split } from './NEW_MARKUP_Split';
import { NEW_MARKUP_SplitEqually } from './NEW_MARKUP_SplitEqually';
import { NEW_MARKUP_SplitPerItem } from './NEW_MARKUP_SplitPerItem';
import { NEW_MARKUP_SelectItemsShareModal } from './NEW_MARKUP_SelectItemsShareModal';
import { NEW_MARKUP_AddCustomAmountModal } from './NEW_MARKUP_AddCustomAmountModal';
import { PlusRoundedThickIcon } from 'nexticons/outline';

import { NEW_MARKUP__DineIn } from './NEW_MARKUP__DineIn'; */

const Markup = () => {
  const darkMode = useDarkMode(false);
  const [showModal, toggleModal] = useState(false);
  const [showAmountModal, toggleAmountModal] = useState(false);
  const [showAddDriverModal, toggleAddDriverModal] = useState(false);

  return (
    <>
    </>
  );
};

export default Markup;
