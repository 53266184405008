import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleFourQuarterSelectedSize,
  toggleFourQuarterEditMode,
  updateCurrentOrder,
  resetCurrentFourQuarterData,
  resetMenuItem,
} from '../../pages/Dashboard/action';
// import addHalfHalfToOrder from "../../cartProcessor/addHalfHalfToOrder";
import addFourQuarterToOrder from '../../cartProcessor/addFourQuarterToOrder';
// import HalfHalfView from "./HalfHalfView";

import {
  activeSpecialsSelector,
  storeConfigSelector,
} from '../../pages/Dashboard/selectors';
// import FourQuarterViewWrapper from "../HalfHalfView";
import FourQuarterView from './FourQuarterView';
import {
  findPriceOfMostExpensiveItem,
  updateItemsWithNewPrice,
} from '../../cartProcessor/utilities';
import useCategories from 'hooks/useCategories';

const FourQuarterViewWrapper = ({ parentPage }) => {
  const categories = useCategories();
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);
  let history = useHistory();
  const currentFourQuarterData = useSelector(
    (state) => state.dashboardReducer.currentFourQuarterData
  );
  const activeCategoryId = currentFourQuarterData.activeCategoryId;
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const courseAutoAssignmentEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.courseAutoAssignmentEnabled
  );
  const activeSpecials = useSelector(activeSpecialsSelector);
  const orderSetup = useSelector((state) => state.dashboardReducer.orderSetup);
  const productSetup = useSelector(
    (state) => state.dashboardReducer.productSetup
  );
  const suburbs = useSelector((state) => state.dashboardReducer.suburbs);
  const publicHolidays = useSelector(
    (state) => state.dashboardReducer.publicHolidays
  );
  const handleClickBtn = () => {
    dispatch(resetCurrentFourQuarterData());
    dispatch(resetMenuItem());
    history.push(`${parentPage}/order`);
  };

  const handleItemClick = (_event, item) => {
    dispatch(toggleFourQuarterEditMode(item.fourQuarterIndex));
    history.push(
      `${parentPage}/category/${activeCategoryId}/four-quarter/${item.fourQuarterIndex}`
    );
  };
  const handleAddOrder = () => {
    let currentMenuItems =
      currentFourQuarterData.fourQuarters.map((cat) => cat.currentMenuItem) ||
      [];
    if (productSetup.chargeExpensiveQuarter) {
      let newPrice = findPriceOfMostExpensiveItem(currentMenuItems);
      currentMenuItems = updateItemsWithNewPrice(currentMenuItems, newPrice);
    }

    const selectedCategory = categories.filter((category) =>
      currentMenuItems?.some((item) => item.categoryId === category._id)
    );

    if (
      selectedCategory?.length > 0 &&
      selectedCategory?.some((cat) => !!cat?.courseId) &&
      !currentMenuItems?.every((item) => !!item.courseId)
    ) {
      const selectedCategoryWithCourse = selectedCategory?.find(
        (cat) => !!cat?.courseId
      );
      const menutItemsWithCourse = currentMenuItems?.map((item) => {
        return {
          ...item,
          courseId: selectedCategoryWithCourse?.courseId,
          courseName: selectedCategoryWithCourse?.courseName,
        };
      });

      currentMenuItems = menutItemsWithCourse;
    }

    // Remove Course Id If orderType is not equal to dine-in
    if (currentOrder?.orderType !== '3' || !courseAutoAssignmentEnabled) {
      currentMenuItems?.forEach((item) => {
        delete item?.courseId;
        delete item?.courseName;
      });
    }

    dispatch(
      updateCurrentOrder(
        addFourQuarterToOrder(
          currentOrder,
          currentFourQuarterData,
          currentMenuItems,
          activeSpecials,
          orderSetup,
          productSetup,
          suburbs,
          publicHolidays,
          storeConfig
        )
      )
    );
    dispatch(resetCurrentFourQuarterData());
    dispatch(resetMenuItem());
    history.push(`${parentPage}/order`);
  };

  const handleSizeClick = (size) => {
    !size.isDefault && dispatch(toggleFourQuarterSelectedSize(size));
  };

  return (
    <FourQuarterView
      fourQuarterData={currentFourQuarterData}
      onClose={handleClickBtn}
      onSizeClick={handleSizeClick}
      onSubmit={handleAddOrder}
      onSectionClick={handleItemClick}
      submitLabel="Add to Order"
      parentPage={parentPage}
      toggleItemEditMode={(fourQuarterIndex) =>
        dispatch(toggleFourQuarterEditMode(fourQuarterIndex))
      }
    />
  );
};

export default FourQuarterViewWrapper;
