import { PosIconLoading } from 'assets/icons/PosIconLoading';
import React from 'react';

export const DeliveryBatchesSidebarFooter = ({
  loading,
  disabled,
  totalSelected,
  onClick,
  buttonText,
}) => {
  return (
    <div className="order-sidebar-bottom-nav">
      <div className="order-actions-btn-box">
        <button
          type="button"
          title="1 deliveries Selected"
          className="btn-action width-100 blue has-selected hover-highlight"
          disabled={disabled}
        >
          <span className="inner-btn-box">
            <span className="count">
              {loading && <PosIconLoading mainColor="#ffffff" />}
              <span>{totalSelected}</span>
            </span>
            <span className="text">Selected</span>
            <span
              className="btn-dummy"
              style={{
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? 'not-allowed' : 'pointer',
              }}
              onClick={!disabled ? onClick : undefined}
            >
              {buttonText}
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};
