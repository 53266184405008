import React, { useEffect } from 'react';
import GeneralModal from '../../modals/GeneralModal/index-new';
import Button from '../../components/Button';
import '../../assets/scss/modals/_add-drawer-modal.scss';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

export const WindCavePairTerminalModal = ({
  modalStatus,
  toggleModal,
  windcavePairTerminalHandler,
  isWindcaveApiLoading,
}) => {
  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    onInitInput,
    inputNames,
  } = useVirtualKeyboardContext();
  const inputTerminalId = inputNames?.inputTerminalId || '';

  const handleClick = (event) => {
    event.preventDefault();
    windcavePairTerminalHandler(getInputValue(inputTerminalId));
    onInitInput(inputTerminalId, '');
    toggleKeyboard(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleFocus = (e, name) => {
    setInputName(name);

    toggleKeyboard(true);
  };

  const handleChange = (e, name) => {
    const inputVal = e.target.value || '';

    onChangeInput(name, inputVal);
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={() => {
        if (modalStatus) toggleKeyboard(false);
        toggleModal();
      }}
      position="bottom"
      effect="move"
      overlay=""
      boxWidth="514px"
      boxHeight="480px"
      className="add-drawer-modal"
      closeBtn="inside"
      keyboardVisibility={keyboardVisibility}
    >
      <div className="add-drawer-modal-box">
        <div className="add-drawer-modal-box-inner">
          <h2 className="add-drawer-title">Pair Windcave Terminal</h2>
          <form
            action=""
            className="add-drawer-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="single-input-box">
              <input
                id={inputTerminalId}
                name={inputTerminalId}
                onFocus={(e) => handleFocus(e, inputTerminalId)}
                className="input-style white big"
                placeholder="Terminal Id"
                value={getInputValue(inputTerminalId || '')}
                onChange={(e) => handleChange(e, inputTerminalId)}
              />
            </div>
          </form>
        </div>
        <div className="add-drawer-btn-box">
          <Button
            disabled={!getInputValue(inputTerminalId) || isWindcaveApiLoading}
            className="btn"
            title="Pair Terminal"
            onClick={handleClick}
          >
            Pair Terminal
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};
