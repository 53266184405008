import axios from 'axios';
import { serviceChargesApi } from 'constants/endpoints';
import getStoreConfig from 'util/getStoreConfig';
import { _auth } from '../../firebase';

import { actionTypes } from './serviceChargesConstants';

export function setServiceCharges(payload) {
  return {
    type: actionTypes.setServiceCharges,
    payload,
  };
}

export function fetchServiceCharges() {
  return async (dispatch, getState) => {

    const accessToken = await _auth.currentUser?.getIdToken()

    const storeConfig = getStoreConfig();
    
    const storeId = getState().dashboardReducer?.storeConfig?.storeId;

    const id = storeConfig?.storeId ?? storeId;
    if (!id) return;

    try {
      const res = await axios.get(serviceChargesApi(id), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        setServiceCharges(
          res.data?.data?.serviceCharges?.filter((s) => s.isActive) ?? []
        )
      );
    } catch (err) {
      console.error(err);
    }
  };
}
