const ShiftsBlockContainer = ({
  children,
  isOuterLarger = false,
  isInnerLarger = false,
}) => {
  return (
    <div
      className={`clock-in-out${
        isOuterLarger ? ' clock-in-out--wide-ex-large' : ''
      }`}
    >
      <div
        className={`clock-in-out__inner${
          isInnerLarger ? ' clock-in-out__inner--space-between-big' : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ShiftsBlockContainer;
