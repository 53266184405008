import { createContext, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'reducers/order';

const OrderContext = createContext([{}, {}]);

export const OrderProvider = ({ children }) => {
  const dispatch = useDispatch();
  const selectedCategoryId = useSelector(
    (state) => state.orderReducer.selectedCategoryId
  );
  const setState = useCallback(
    (payload) => dispatch(actions.setState(payload)),
    [dispatch]
  );

  return (
    <OrderContext.Provider value={[{ selectedCategoryId }, { setState }]}>
      {children}
    </OrderContext.Provider>
  );
};

const useOrder = () => useContext(OrderContext);

export default useOrder;
