export const prettyCardNumber = (cardNumber, gaps) => {
  if (cardNumber && gaps) {
    let number = cardNumber.replace(/ /g, '');
    let offsets = [].concat(0, gaps, number.length);
    let components = [];

    for (let i = 0; offsets[i] < number.length; i++) {
      let start = offsets[i];
      let end = Math.min(offsets[i + 1], number.length);
      components.push(number.substring(start, end));
    }

    return components.join(' ');
  }

  return cardNumber;
};
