import { useState } from 'react';

const useMutation = (endpoint, { header = {}, onError = () => {} }) => {
  const [data, setData] = useState();
  const apiCall = async (body) => {
    try {
      const response = await fetch(endpoint, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          ...header,
        },
        body,
      });
      const respData = await response.json();
      setData(respData);
      return;
    } catch (error) {
      onError(error);
    }
  };
  return [apiCall, { data }];
};

export default useMutation;
