import { PosIconLoading } from 'assets/icons/PosIconLoading';
import clsx from 'clsx';
import useIsKiosk from 'hooks/useIsKiosk';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Button = forwardRef(
  (
    {
      link,
      title,
      children,
      className,
      disabled,
      spanStyleProps = {},
      loading,
      enableThemeColor,
      kioskStyle,
      isCommonStyle,
      ...props
    },
    ref
  ) => {
    const { primaryThemeColour } = useSelector(
      (state) => state.storeWebsiteLoaderDataReducer
    );
    const isDisabled = disabled || loading;
    const isKiosk = useIsKiosk();

    const disabledStyle = isDisabled
      ? {
          backgroundColor: '#d6d6d6',
        }
      : {};
    const style = {
      ...(enableThemeColor && isKiosk && !isDisabled
        ? {
            backgroundColor: primaryThemeColour,
            borderColor: primaryThemeColour,
            color: '#fff',
            ...kioskStyle
          }
        : {}),
      ...disabledStyle,
      ...props.style,
    };

    const btnCls = clsx({
      'hover-highlight': true,
      [`${className}`]: true,
    });

    return (
      <>
        {link ? (
          <NavLink
            ref={ref}
            to={link}
            title={title}
            className={btnCls}
            {...props}
            style={isCommonStyle ? style : props.style}
          >
            <span className="inner-btn-box" {...spanStyleProps}>
              {children}
            </span>
          </NavLink>
        ) : (
          <button
            ref={ref}
            title={title}
            className={btnCls}
            disabled={isDisabled}
            {...props}
            style={isCommonStyle ? style : props.style}
          >
            <span className="inner-btn-box" {...spanStyleProps} style={style}>
              {children}
              {loading && (
                <PosIconLoading
                  mainColor="#067aff"
                  className="cursor-pointer"
                  size="36px"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%,-50%)',
                  }}
                />
              )}
            </span>
          </button>
        )}
      </>
    );
  }
);

export default Button;
