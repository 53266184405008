import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MoneyCountHeader } from '../../components/MoneyCount/MoneyCountHeader';
import { MoneyCountContent } from '../../components/MoneyCount/MoneyCountContent';
import { MoneyCountSidebar } from '../../components/MoneyCount/MoneyCountSidebar';
import classNames from 'classnames';
import { MoneyCountModal } from '../../modals/MoneyCountModal';
import { DenominationsShortModal } from '../../modals/DenominationsShortModal';
import { settleDrawer } from '../../services/cashierServices';
import { useSelector } from 'react-redux';

import { storeConfigSelector } from '../../pages/Dashboard/selectors';

export const MoneyCount = ({
  initialTotal,
  manual,
  onConfirm,
  onConfirmShort,
  onTotalChange = () => {},
  backLink,
  viewDeliveriesURL,
  isLoadingAddDriverCashTransaction,
}) => {
  let history = useHistory();
  const storeConfig = useSelector(storeConfigSelector);

  const { activeDrawer } = useSelector((state) => state.cashierReducer);
  const quantityRegexp = /^[0-9\b]+$/;
  const [modalStatus, setModalStatus] = useState(false);
  const [modalShortStatus, setModalShortStatus] = useState(false);
  const [manuallyMode, setManuallyMode] = useState(false);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(initialTotal);
  }, [initialTotal]);

  useEffect(() => {
    setManuallyMode(manual);
  }, [manual]);

  const [differenceAmount, setDifferenceAmount] = useState(0);
  const [isShort, setIsShort] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [moneyCount, setMoneyCount] = useState([
    {
      id: 1,
      active: false,
      denomination: 100,
      denominationLabel: '$100.00',
      quantity: 0,
      total: 0,
    },
    {
      id: 2,
      active: false,
      denomination: 50,
      denominationLabel: '$50.00',
      quantity: 0,
      total: 0,
    },
    {
      id: 3,
      active: false,
      denomination: 20,
      denominationLabel: '$20.00',
      quantity: 0,
      total: 0,
    },
    {
      id: 4,
      active: false,
      denomination: 10,
      denominationLabel: '$10.00',
      quantity: 0,
      total: 0,
    },
    {
      id: 5,
      active: false,
      denomination: 5,
      denominationLabel: '$5.00',
      quantity: 0,
      total: 0,
    },
    {
      id: 6,
      active: false,
      denomination: 2,
      denominationLabel: '$2.00',
      quantity: 0,
      total: 0,
    },
    {
      id: 7,
      active: false,
      denomination: 1,
      denominationLabel: '$1.00',
      quantity: 0,
      total: 0,
    },
    {
      id: 8,
      active: false,
      denomination: 0.5,
      denominationLabel: '50c',
      quantity: 0,
      total: 0,
    },
    {
      id: 9,
      active: false,
      denomination: 0.2,
      denominationLabel: '20c',
      quantity: 0,
      total: 0,
    },
    {
      id: 10,
      active: false,
      denomination: 0.1,
      denominationLabel: '10c',
      quantity: 0,
      total: 0.0,
    },
    {
      id: 11,
      active: false,
      denomination: 0.05,
      denominationLabel: '5c',
      quantity: 0,
      total: 0,
    },
    {
      id: 12,
      active: false,
      denomination: 0.01,
      denominationLabel: '1c',
      quantity: 0,
      total: 0,
    },
  ]);
  const [isLoadingSettleDrawer, setIsLoadingSettleDrawer] = useState(false);
  const timeout = useRef(0);

  const overlayCls = classNames({
    'money-count-overlay': true,
    show: manuallyMode,
  });

  const handleToggleModal = (e, status) => {
    if (total) {
      setModalStatus(status);
    }
  };

  const handleConfirm = async () => {
    const onExcess = (cashtotal, drawerBalance) => {
      setDifferenceAmount(cashtotal - drawerBalance);
      setIsShort(false);
      openShortOverDueModal();
    };

    const onShort = (cashtotal, drawerBalance) => {
      setIsShort(true);
      setDifferenceAmount(drawerBalance - cashtotal);
      openShortOverDueModal();
    };

    if (onConfirm) {
      onConfirm(onExcess, onShort);
    } else {
      const {
        sales,
        openingBalance,
        deposit = 0,
        withdrawal = 0,
        _id,
        status,
      } = activeDrawer;
      const totalBalanceInDrawer =
        sales + openingBalance + deposit - withdrawal;
      const isBalanceEqual = totalBalanceInDrawer === total;
      if (status === '0' || isBalanceEqual) {
        setIsLoadingSettleDrawer(true);
        const response = await settleDrawer({
          drawerId: _id,
          cashAmount: total,
          staffId: '5mkqH5tPmLqGr745xqyx',
          storeConfig,
        });
        setIsLoadingSettleDrawer(false);
        if (response.success) {
          history.push('/cashier');
          setModalStatus(false);
        }
      } else if (status === '1' && !isBalanceEqual) {
        if (totalBalanceInDrawer < total) {
          onExcess(total, totalBalanceInDrawer);
        } else {
          onShort(total, totalBalanceInDrawer);
        }
      }
    }
  };

  const openShortOverDueModal = () => {
    setModalStatus(false);
    timeout.current = setTimeout(() => {
      setModalShortStatus(true);
    }, 450);
    return () => {
      clearTimeout(timeout.current);
    };
  };

  const handleConfirmShort = async (reason) => {
    if (onConfirmShort) {
      onConfirmShort(reason);
    } else {
      setIsLoadingSettleDrawer(true);
      const response = await settleDrawer({
        drawerId: activeDrawer._id,
        cashAmount: total,
        staffId: '5mkqH5tPmLqGr745xqyx',
        reason,
        storeConfig,
      });
      setIsLoadingSettleDrawer(false);
      if (response.success) {
        history.push('/cashier');
        setModalShortStatus(false);
      }
    }
  };

  const handleToggleShortModal = (e, status) => {
    if (total) {
      setModalShortStatus(status);
    }
  };

  const toggleManuallyMode = () => {
    setManuallyMode(!manuallyMode);
  };

  const handleFocus = (e, id) => {
    let newData = moneyCount.map((item, i) => {
      if (item.id === id) {
        setActiveIndex(i);
      }

      item.active = item.id === id;

      return item;
    });

    setMoneyCount(newData);
  };

  const handleChange = (e, id) => {
    let newData = [];

    if (quantityRegexp.test(e.target.value)) {
      newData = moneyCount.map((item) => {
        if (item.id === id) {
          if (String(item.quantity).length < 5) {
            item.quantity = Number(e.target.value);
          } else {
            item.quantity = 0;
          }

          item.total = item.quantity * item.denomination;
        }
        return item;
      });
    } else {
      newData = moneyCount.map((item) => {
        if (item.id === id) {
          item.quantity = 0;
          item.total = 0;
        }
        return item;
      });
    }

    setMoneyCount(newData);
  };

  const calcTotal = () => {
    const total = moneyCount.reduce((sum, current) => {
      if (current.total) {
        sum += current.total;
      }

      return sum;
    }, 0);
    setTotal(total);
  };

  const setActiveDenomination = (index) => {
    if (index > -1 && index < moneyCount.length && moneyCount[index]) {
      setActiveIndex(index);
      let newData = moneyCount.map((item, i) => {
        item.active = i === index;
        return item;
      });

      setMoneyCount(newData);
    }
  };

  const setQuantity = (index, val) => {
    let newData = moneyCount.map((item, i) => {
      if (index === i) {
        if (val === '-') {
          const itemQuantityString = item.quantity.toString();
          const newQuantity = itemQuantityString.substring(
            0,
            itemQuantityString.length - 1
          );
          if (newQuantity.length === 0) item.quantity = 0;
          item.quantity = Number(newQuantity);
        } else if (String(item.quantity).length < 5) {
          let newQuantity = item.quantity ? item.quantity + val : val;
          item.quantity = Number(newQuantity);
        } else {
          item.quantity = 0;
        }

        item.total = item.quantity * item.denomination;
      }

      return item;
    });

    setMoneyCount(newData);
  };

  useEffect(() => {
    setActiveDenomination(activeIndex);
  }, []);

  useEffect(() => {
    calcTotal();
  }, [moneyCount]);

  useEffect(() => {
    if (manuallyMode) {
      let newData = moneyCount.map((item) => {
        item.quantity = 0;
        item.total = 0;

        return item;
      });

      setMoneyCount(newData);
      // setActiveIndex(-1);
    }
    setTotal(0);
    setDifferenceAmount(0);
  }, [manuallyMode]);

  useEffect(() => {
    onTotalChange(total);
  }, [total, onTotalChange]);

  const isDriversCashFloatScreen = onConfirm || onConfirmShort ? true : false;

  return (
    <>
      <MoneyCountHeader
        backLink={backLink}
        viewDeliveriesURL={viewDeliveriesURL}
      />
      <div className="money-count-main-wrap">
        <MoneyCountContent
          activeIndex={activeIndex}
          moneyCount={moneyCount}
          handleFocus={handleFocus}
          handleChange={handleChange}
        />
        <MoneyCountSidebar
          total={total}
          activeIndex={activeIndex}
          setQuantity={setQuantity}
          setActiveDenomination={setActiveDenomination}
          toggleManuallyMode={toggleManuallyMode}
          moneyCount={moneyCount}
          manuallyMode={manuallyMode}
          setTotal={setTotal}
          modalStatus={modalStatus}
          handleToggleModal={handleToggleModal}
        />

        <div className={overlayCls} />
      </div>

      {total ? (
        <>
          <MoneyCountModal
            totalPrice={total}
            modalStatus={modalStatus}
            confirmForm={handleConfirm}
            toggleModal={handleToggleModal}
            isLoading={
              isDriversCashFloatScreen
                ? isLoadingAddDriverCashTransaction
                : isLoadingSettleDrawer
            }
          />
          <DenominationsShortModal
            totalPrice={differenceAmount}
            modalStatus={modalShortStatus}
            confirmForm={handleConfirmShort}
            toggleModal={handleToggleShortModal}
            isShort={isShort}
            isLoading={
              isDriversCashFloatScreen
                ? isLoadingAddDriverCashTransaction
                : isLoadingSettleDrawer
            }
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};
