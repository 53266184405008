import React, { useMemo } from 'react';
import Button from 'components/Button';

import { SidePanelHeader } from '../SidePanelHeader';
import { SidePanelDetails } from '../SidePanelDetails';

import { PosIconPlus } from 'assets/icons/PosIconPlus';

const SVG_ASSET_URL = 'https://assets.nextorder.co/icons/';

const SHAPE_OBJECTS = [
  {
    name: '',
    number: 0,
    type: 'SquareTable',
    className: 'SquareTable',
    objectType: 'dineInObjects',
    numSeats: 4,
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'T-04.svg',
  },
  {
    name: '',
    number: 0,
    type: 'RoundTable',
    className: 'RoundTable',
    objectType: 'dineInObjects',
    numSeats: 8,
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'TCS-08.svg',
  },
  {
    name: '',
    number: 4,
    type: 'RhombusTable',
    className: 'RhombusTable',
    objectType: 'dineInObjects',
    numSeats: 4,
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'TRH-04.svg',
  },
  {
    name: '',
    number: 0,
    type: 'RectangularTable',
    className: 'RectangularTable',
    objectType: 'dineInObjects',
    numSeats: 6,
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'TS-06.svg',
  },
  {
    name: '',
    type: 'PottedPlant',
    className: 'PottedPlant',
    objectType: 'nonDineInObjects',
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'PottedPlant.svg',
  },
  // NEX-395: Remove the wall element from the options for now
  // {
  //   name: '',
  //   type: 'LineDivider',
  //   className: 'LineDivider',
  //   startX: 0,
  //   startY: 0,
  //   endX: 0,
  //   endY: 0,
  //   colour: '',
  //   svgImg: 'LineDivider.svg',
  //   objectType: 'layoutDividers',
  // },
];

export const InsertShapes = ({ handleShapeInsert }) => {
  const ShapeNavItem = ({ item }) => {
    const shapeBoxCls = 'shapes-list__item-shape-box';

    return (
      <li className="shapes-list__item">
        <div className={`${shapeBoxCls} ${shapeBoxCls}--${item.className}`}>
          {item.svgCmp || (
            <img src={`${SVG_ASSET_URL}${item.svgImg}`} alt={item.type} />
          )}
        </div>
        <div className="shapes-list__item-wrap">
          <div className="shapes-list__item-name">{item.name}</div>
          <Button
            className="btn blue btn--more-round btn--small"
            onClick={() => {
              handleShapeInsert(item);
            }}
          >
            <PosIconPlus mainColor="#fff" /> ADD
          </Button>
        </div>
      </li>
    );
  };

  const objectsList = useMemo(
    () =>
      SHAPE_OBJECTS.map((item, i) => (
        <ShapeNavItem key={item.type + i} item={item} />
      )),
    []
  );

  return (
    <section className="side-panel__section side-panel__section--insert-shapes">
      <SidePanelHeader title="Insert Shapes" />

      <SidePanelDetails>
        <ul className="shapes-list">{objectsList}</ul>
      </SidePanelDetails>
    </section>
  );
};
