import clsx from 'clsx';
import React from 'react';

export const ReceiptItem = ({
  title,
  description,
  isHeader,
  isFooter,
  isTotal,
  isSingleItem,
}) => {
  return (
    <>
      {isHeader ? (
        <div
          className={clsx('bem-print-order-modal__order-header-row', {
            'bem-print-order-modal__order-header-single-item': isSingleItem,
          })}
        >
          <h3 className="bem-print-order-modal__order-title">{title}</h3>
          {!isSingleItem && (
            <h4 className="bem-print-order-modal__order-title">
              {description}
            </h4>
          )}
        </div>
      ) : (
        <div
          className={clsx({
            'bem-print-order-modal__order-header-row': !isFooter && !isTotal,
            'bem-print-order-modal__order-footer-row': isFooter || isTotal,
          })}
        >
          <span
            className={clsx({
              'bem-print-order-modal__order-header-text': !isFooter && !isTotal,
              'bem-print-order-modal__order-footer-text': isFooter,
              'bem-print-order-modal__order-total-text': isTotal,
            })}
          >
            {title}
          </span>
          <span
            className={clsx({
              'bem-print-order-modal__order-header-text': !isFooter && !isTotal,
              'bem-print-order-modal__order-footer-text': isFooter,
              'bem-print-order-modal__order-total-text': isTotal,
            })}
          >
            {description}
          </span>
        </div>
      )}
    </>
  );
};
