import React from 'react';
import { PosIconDollarBills } from '../../../assets/icons/PosIconDollarBills';
import { getRemainingTime } from '../../../util/dateHelper';
import { fetchOrder } from '../../../pages/Orders/actions';
import { useDispatch, useSelector } from 'react-redux';

export const OpenOrdersList = ({ ordersList }) => {
  const dispatch = useDispatch();
  const { selectedOrder } = useSelector((state) => state.ordersReducer);
  const handleClick = (event, itemId) => {
    event.preventDefault();
    dispatch(fetchOrder(itemId));
    // dispatch({
    //   type: 'setActiveGuest',
    //   payload: itemId,
    // });
  };

  const orderType = [
    {
      id: '1',
      type: 'confined pickup',
      class: 'green',
      text: 'PickUp',
    },
    {
      id: '2',
      type: 'out for delivery',
      class: 'orange',
      text: 'Delivery',
    },
  ];

  const getOrderType = (id, address) => {
    const orderStatusObj = orderType.find((item) => {
      return item.id === id;
    });
    if (id == '2') {
      orderStatusObj.text = address;
    }
    return orderStatusObj;
  };

  return (
    <ul className="orders-details-list">
      {ordersList.map((item, i) => {
        return item.paymentType == '0' ? (
          <li
            key={item._id}
            className={item._id == selectedOrder._id ? 'active' : ''}
          >
            <button
              type="button"
              className="order-detail-box"
              onClick={(event) => handleClick(event, item._id)}
            >
              <div className="detail-row row-1">
                <div className="detail-cell">
                  <h3 className="name-box">
                    {' '}
                    {item.firstName + ' ' + item.lastName}
                  </h3>
                  {item.vip ? <span className="status-btn vip">VIP</span> : ''}
                </div>
                <div className="detail-cell">
                  <span className="total-cash-box">
                    {item.payableAmount ? (
                      <>
                        <PosIconDollarBills mainColor="#494b4c" />$
                        {item.payableAmount}
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
              <div className="detail-row row-2">
                <div className="detail-cell">
                  {item.deliveryCode && (
                    <p
                      className={`order-info-box ${
                        getOrderType(item.orderType, item.address)?.class
                      }`}
                    >
                      {getOrderType(item.orderType, item.address)?.text}
                    </p>
                  )}
                  {/* {item.pickupId ? (
                      <p className="order-info-box green">Pickup</p>
                    ) : item.deliveryId && item.location ? (
                      <p className="order-info-box orange">{item.location}</p>
                    ) : item.table ? (
                      <p className="order-info-box purple">
                        Table {item.table}
                      </p>
                    ) : (
                      ''
                    )} */}
                </div>
                <div className="detail-cell">
                  <span className="time-box">
                    {item.deliveryDateTimestamp && item.deliveryCode
                      ? getRemainingTime(
                          item.deliveryDate,
                          item.deliveryDateTimestamp
                        )
                      : ''}
                  </span>
                </div>
              </div>
            </button>
          </li>
        ) : (
          ''
        );
      })}
    </ul>
  );
};
