import { _firestore } from '../../firebase';
import { actionTypes } from './storeWebsiteLoaderDataConstants';

function setStoreWebsiteLoaderData(payload) {
  return {
    type: actionTypes.SET_STORE_WEBSITE_LOADER_DATA,
    payload,
  };
}

export function fetchStoreWebsiteLoaderData(storeId) {
  return async (dispatch) => {
    if (!storeId) return;
    try {
      const querySnapshot = await _firestore
        .collection('StoreWebsiteLoaderData')
        .where('storeId', '==', storeId)
        .limit(1)
        .get();


      querySnapshot.docs.forEach((doc) => {
        dispatch(setStoreWebsiteLoaderData(doc.data()));
      });
      return {};
    } catch (err) {
      console.log(err);
    }
  };
}
