import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  isItemWithSingleSize,
  incrementItemQuantitySingleSize,
} from '../../itemProcessor';
import {
  openMenuItem,
  updateSpecialsCurrentFourQuarterItem,
} from '../../pages/Dashboard/action';
import { useLayoutEffect } from 'react';
import FourQuarterMenuItems from '../FourQuarterMenuItems';

const SpecialsFourQuarterMenuItems = ({ parentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSpecialsItem = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem
  );
  const { categoryIndex, specialsId, fourQuarterIndex } = useParams();
  const currentFourQuarterData =
    currentSpecialsItem.selectedCategories[categoryIndex]
      ?.currentFourQuarterData || {};
  const fourQuarters =
    currentFourQuarterData.fourQuarters?.find(
      (h) => h.fourQuarterIndex === Number(fourQuarterIndex)
    ) || {};

  const backLink = `${parentPage}/specials/${specialsId}/${categoryIndex}/four-quarter`;
  // auto select single-sized item if menuItem.length === 1
  useLayoutEffect(() => {
    if (specialsId) {
      if (fourQuarters.menuItems?.length === 1) {
        const item = fourQuarters.menuItems[0];
        if (isItemWithSingleSize(item)) {
          dispatch(
            updateSpecialsCurrentFourQuarterItem(parseInt(categoryIndex), {
              ...incrementItemQuantitySingleSize(item, 0.25),
              fourQuarterIndex: parseInt(fourQuarterIndex),
              isQuarter: true,
              isSecondQuarter: parseInt(fourQuarterIndex) === 2,
              isThirdQuarter: parseInt(fourQuarterIndex) === 3,
              isFourthQuarter: parseInt(fourQuarterIndex) === 4,
            })
          );
          history.push(backLink);
        }
      }
    }
  }, [
    fourQuarters.menuItems,
    history,
    backLink,
    dispatch,
    fourQuarterIndex,
    parentPage,
    specialsId,
    categoryIndex,
  ]);

  const handleMenuItemClick = (_event, item) => {
    if (!isItemWithSingleSize(item)){
      dispatch(openMenuItem({ ...item, isQuarter: true,quarterIndex:fourQuarterIndex }, false));
    }
    else {
      dispatch(
        updateSpecialsCurrentFourQuarterItem(parseInt(categoryIndex), {
          ...incrementItemQuantitySingleSize(item, 0.25),
          fourQuarterIndex: parseInt(fourQuarterIndex),
          isQuarter: true,
          isSecondQuarter: parseInt(fourQuarterIndex) === 2,
          isThirdQuarter: parseInt(fourQuarterIndex) === 3,
          isFourthQuarter: parseInt(fourQuarterIndex) === 4,
        })
      );
      history.push(backLink);
    }
  };

  return (
    <FourQuarterMenuItems
      fourQuarterData={fourQuarters}
      backLink={backLink}
      onItemClick={handleMenuItemClick}
      parentPage={parentPage}
    />
  );
};

export default SpecialsFourQuarterMenuItems;
