import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PosIconMinusCircle = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-circle-plus': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props} style={{width: 25, height: 25}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
      >
        <title>Minus-Circle</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.51,12.51,0,0,1,12.5,25Zm0-24A11.5,11.5,0,1,0,24,12.5,11.51,11.51,0,0,0,12.5,1ZM18,13H7V12H18Z"
        />
      </svg>
    </span>
  );
};

PosIconMinusCircle.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};

export default PosIconMinusCircle;