import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  changeModalTime,
  toggleViewOrderTimeModal,
} from '../pages/Orders/actions';
import { updatePickUpDeliveryTime } from '../services/ordersServices';
import Button from '../components/Button';
import { PosIconMinus } from '../assets/icons/PosIconMinus';
import { PosIconPlus } from '../assets/icons/PosIconPlus';

import { storeConfigSelector } from '../pages/Dashboard/selectors';

const ModalViewOrdersTime = ({
  timeType,
  averageVal,
  modalTitle,
  id,
  className,
}) => {
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);

  let currentTime = useSelector(
    (state) => state.ordersReducer.orderWaitingObj[timeType]
  );
  const show = useSelector(
    (state) => state.ordersReducer.viewOrdersActionTimeModal[timeType]
  );

  /* let modalCls = "sidebar-modal sidebar-modal-view-orders-time ";
  modalCls += state.viewOrdersActionTimeModal[timeType] ? "show" : "";*/

  const modalCls = classNames({
    'sidebar-modal': true,
    'sidebar-modal-view-orders-time': true,
    show,
  });

  const handleClickPlus = (event) => {
    let updatedTime = Number(currentTime) + 1;
    dispatch(
      changeModalTime({
        name: timeType,
        quantity: updatedTime,
      })
    );
  };

  const handleClickMinus = () => {
    let updatedTime = Number(currentTime) - 1;
    dispatch(
      changeModalTime({
        name: timeType,
        quantity: updatedTime,
      })
    );
  };

  const handleCloseModal = () => {
    updatePickUpDeliveryTime(id, currentTime, storeConfig);
    dispatch(
      toggleViewOrderTimeModal({
        name: timeType,
        active: false,
      })
    );
  };

  return (
    <div id={'sidebar-modal-view-orders-time ' + timeType} className={modalCls}>
      <div className="sidebar-modal-wrap">
        <div className={`sidebar-modal-box ${className}`}>
          <div className={'sidebar-modal-header'}>
            <button
              type="button"
              className="close-modal-btn hover-highlight"
              onClick={handleCloseModal}
            />
            <h2 className="sidebar-modal-title">{modalTitle}</h2>
          </div>
          <div className="sidebar-modal-body">
            <div className="counter-box">
              <Button
                className="counter-btn"
                disabled={currentTime <= 1}
                onClick={handleClickMinus}
                title="Minus"
              >
                <PosIconMinus mainColor="#067aff" />
              </Button>
              <span className="counter-value">{currentTime} mins</span>
              <Button
                className="counter-btn"
                onClick={handleClickPlus}
                disabled={false}
                title="Plus"
              >
                <PosIconPlus mainColor="#067aff" />
              </Button>
            </div>
            <p className="counter-desc">
              Today’s Average <span>{averageVal}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="sidebar-modal-overlay" onClick={handleCloseModal} />
    </div>
  );
};

export default ModalViewOrdersTime;
