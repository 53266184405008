import ReviewsImage from '../assets/img/home/reviews.png';
import MusicImage from '../assets/img/home/music.png';
import SettingsImage from '../assets/img/home-new/settings-16-01.png';
import OrdersImage from '../assets/img/home-new/dishes.png';
import DriversImage from '../assets/img/home-new/Drive-NAVY-20-03_big.png';
import MenuImage from '../assets/img/home-new/menu-icon_big.png';
import CashierImage from '../assets/img/home-new/casher-icon_big.png';
import ReportsImage from '../assets/img/home-new/reports_icon_big.png';
import { isAndroid } from 'react-device-detect';

const subMenuForAllPlatforms = [
  {
    id: 1,
    title: 'Orders',
    link: '/orders/current/all',
    icon: OrdersImage,
    customClass: 'red',
    action: undefined,
    iconCls: 'orders',
  },
  {
    id: 3,
    title: 'Drivers',
    link: '/drivers/deliveries',
    icon: DriversImage,
    customClass: 'white',
    action: undefined,
    iconCls: 'drivers',
  },
  {
    id: 11,
    title: 'Cashier',
    link: '/cashier',
    icon: CashierImage,
    customClass: 'grey',
    action: undefined,
    iconCls: 'cashier',
  },
  {
    id: 12,
    title: 'Reports',
    link: '/reports',
    icon: ReportsImage,
    customClass: 'grey',
    action: undefined,
    iconCls: 'cashier',
  },
  {
    id: 14,
    title: 'Settings',
    link: '/settings',
    icon: SettingsImage,
    customClass: 'grey',
    action: undefined,
    iconCls: 'settings',
  },
];

const subMenuForAndroid = [
  {
    id: 4,
    title: 'Menu',
    link: '/menu',
    icon: MenuImage,
    customClass: 'brown',
    action: undefined,
    iconCls: 'menu',
  },
  {
    id: 5,
    title: 'Reviews',
    link: '/reviews',
    icon: ReviewsImage,
    customClass: 'yellow',
    action: undefined,
    iconCls: 'reviews',
  },
  {
    id: 9,
    title: 'Music',
    link: '/music',
    icon: MusicImage,
    customClass: '',
    action: undefined,
    iconCls: 'music',
  },
];

export const homeSubMenu = isAndroid
  ? [...subMenuForAllPlatforms, ...subMenuForAndroid]
  : subMenuForAllPlatforms;
