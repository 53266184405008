import './bem-badge.scss';
import PropTypes from 'prop-types';
import { getUIClassesFromProps, getUIModifierKeys } from '../modifiersMapping';

export const BadgeUI = ({ title, icon, style, ...props }) => {
  if (!title) return null;

  return (
    <span
      className={getUIClassesFromProps('bem-badge', { ...props })}
      data-position={props.iconPosition}
      style={style}
    >
      <BadgeContent icon={icon} title={title} />
    </span>
  );
};

function BadgeContent({ icon, title }) {
  return (
    <>
      {icon}
      <span className="bem-badge__text">{title}</span>
    </>
  );
}

BadgeUI.defaultProps = {
  title: '',
  fullWidth: false,
  icon: false,
  iconPosition: 'right',
  sizeType: 's',
  buttonInnerSpacing: undefined,
  styleType: 'primary-light',
  fontSize: undefined,
  fontWeight: 'medium',
  rounded: 's',
  modifierClassName: '',
  style: {},
};

BadgeUI.propTypes = {
  title: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  iconPosition: PropTypes.oneOf(getUIModifierKeys('iconPosition')),
  buttonInnerSpacing: PropTypes.oneOf(getUIModifierKeys('buttonInnerSpacing')),
  sizeType: PropTypes.oneOf(getUIModifierKeys('sizeType')),
  styleType: PropTypes.oneOf(getUIModifierKeys('styleType')),
  fontSize: PropTypes.oneOf(getUIModifierKeys('fontSize')),
  fontWeight: PropTypes.oneOf(getUIModifierKeys('fontWeight')),
  rounded: PropTypes.oneOf(getUIModifierKeys('rounded')),
  modifierClassName: PropTypes.string,
  style: PropTypes.object,
};
