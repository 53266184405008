import React, { useState, useEffect } from 'react';
import { ClockNineIcon } from 'nexticons/outline';
import './TableTimer.css';

function getTimeElapsed(timestamp) {
  const currentTime = new Date().getTime();
  const timeElapsed = currentTime - timestamp;

  // Calculate the time components
  const seconds = Math.floor(timeElapsed / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Return the elapsed time as an object
  return {
    days: days,
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: seconds % 60,
  };
}
const TableTimer = ({ selectedTableOccupancyDetails }) => {
  const [tableTimerText, setTableTimerText] = useState('00:00');

  useEffect(() => {
    if (selectedTableOccupancyDetails?.startedAt) {
      let timerInterval = setInterval(() => {
        const { seconds, minutes, hours } = getTimeElapsed(
          selectedTableOccupancyDetails.startedAt
        );

        let timerString = '';
        if (hours > 0) {
          timerString = timerString + `${hours}`.padStart(2, '0') + ':';
        }

        timerString =
          timerString +
          `${minutes}`.padStart(2, '0') +
          ':' +
          `${seconds}`.padStart(2, '0');
        setTableTimerText(timerString);
      }, 1000);

      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [selectedTableOccupancyDetails?.startedAt]);
  return (
    <div className="timerContainer">
      <ClockNineIcon width={17} color="#5eb602" strokeWidth={1.5} />
      <span className="timerText">{tableTimerText}</span>
    </div>
  );
};

export default TableTimer;
