import { useLocation } from "react-router";
import { useMemo } from "react";

const useCurrentlyInCustomiseSection = () => {
  const location = useLocation();

  return useMemo(()=>{
    return location.pathname.includes('/customization/ingredient/');
  }, [location.pathname]);
}

export default useCurrentlyInCustomiseSection;