import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconExpand = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-email-inverse': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="27"
        viewBox="0 0 26 27"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M24.852 0h-6.889v2.299h4.122L16 8.379 17.619 10l6.085-6.08v4.126H26V1.149C26 .515 25.486 0 24.852 0z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M23.701 23.08L17.621 17 16 18.62l6.08 6.081h-4.126V27h6.897A1.15 1.15 0 0 0 26 25.85v-6.896h-2.299z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M8.38 17l-6.081 6.085v-4.122H0v6.889C0 26.486.515 27 1.15 27h6.896v-2.296H3.92L10 18.63z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M8.037 2.296V0H1.148C.514 0 0 .514 0 1.148v6.889h2.296V3.915L8.37 10 10 8.37 3.915 2.296z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconExpand.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
