import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconCalendar = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-calendar': true,
    [`${className}`]: className,
  });
  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="23"
        // height="26"
        x="0px"
        y="0px"
        viewBox="0 0 23 26"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M11.56 20.5c-1.26 0-2.56-.75-2.56-2H8c0 1.68 1.56 3 3.56 3 1.82.07 3.36-1.35 3.44-3.17.01-.97-.52-1.87-1.38-2.33.86-.46 1.39-1.36 1.38-2.33a3.321 3.321 0 0 0-3.44-3.17c-2 0-3.56 1.32-3.56 3h1c0-1.25 1.3-2 2.56-2 1.27-.07 2.36.9 2.44 2.17 0 1.26-1.23 1.83-2.44 1.83-.28 0-.5.22-.5.5s.22.5.5.5c1.22 0 2.44.57 2.44 1.83a2.32 2.32 0 0 1-2.44 2.17zM22 7.5H1V3.75c0-.14.11-.25.25-.25H4v.75c0 .69.56 1.25 1.25 1.25h1.5C7.44 5.5 8 4.94 8 4.25V3.5h7v.75c0 .69.56 1.25 1.25 1.25h1.5c.69 0 1.25-.56 1.25-1.25V3.5h2.75c.14 0 .25.11.25.25zm0 16.75c0 .14-.11.25-.25.25H1.25c-.14 0-.25-.11-.25-.25V8.5h21zM5 1.75c0-.14.11-.25.25-.25h1.5c.14 0 .25.11.25.25v2.5c0 .14-.11.25-.25.25h-1.5c-.14 0-.25-.11-.25-.25zm11 0c0-.14.11-.25.25-.25h1.5c.14 0 .25.11.25.25v2.5c0 .14-.11.25-.25.25h-1.5c-.14 0-.25-.11-.25-.25zm5.75.75H19v-.75C19 1.06 18.44.5 17.75.5h-1.5C15.56.5 15 1.06 15 1.75v.75H8v-.75C8 1.06 7.44.5 6.75.5h-1.5C4.56.5 4 1.06 4 1.75v.75H1.25C.56 2.5 0 3.06 0 3.75v20.5c0 .69.56 1.25 1.25 1.25h20.5c.69 0 1.25-.56 1.25-1.25V3.75c0-.69-.56-1.25-1.25-1.25z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconCalendar.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
