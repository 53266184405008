import ActionTypes from './constants';

const orderItemReducer = (
  state = {
    newOrderItem: {
      id: null,
      itemData: {
        quantity: 1,
      },
      categoryData: {},
      hasOptions: false,
      customOptions: {
        selectedSizes: {
          optionId: '',
          variantId: '',
          name: '',
        },
        selectedModifiers: {},
        nextModifiersSlug: [],
      },
      ingredientStatus: {
        addIngredient: [],
        removeIngredient: [],
        lightIngredient: [],
        swapIngredient: [],
        customIngredient: [],
      },
    },
    guestInfo: {
      name: '',
      phone: '',
      phoneStatus: false,
    },
    pickupDate: {
      day: '',
      time: '',
    },
    notes: '',
    itemDetailsModal: false,
  },
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.InitNewOrderItem:
      let selectedModifiersTemp = {};
      let nextModifiersSlugs = [
        {
          itemId: payload.itemData._id,
          optionSlug: 'size',
          status: false,
        },
      ];

      if (
        payload.itemData.selectedModifiers !== undefined &&
        payload.itemData.selectedModifiers.length > 0
      ) {
        let modifiersArr = payload.itemData.selectedModifiers;

        for (let i = 0; i < modifiersArr.length; i++) {
          if (modifiersArr[i]._id !== undefined) {
            nextModifiersSlugs.push({
              itemId: payload.itemData._id,
              optionSlug: modifiersArr[i]._id,
              status: false,
            });

            selectedModifiersTemp[modifiersArr[i]._id] = {
              optionId: '',
              variantId: '',
              name: '',
            };
          }
        }
      }

      return {
        ...state,
        newOrderItem: {
          id: null,
          itemData: {
            ...state.newOrderItem.itemData,
            quantity: 1,
            ...payload.itemData,
          },
          categoryData: payload.categoryData,
          hasOptions: false,
          customOptions: {
            selectedSizes: {
              optionId: '',
              variantId: '',
              name: '',
            },
            selectedModifiers: selectedModifiersTemp,
            nextModifiersSlug: nextModifiersSlugs,
          },
          ingredientStatus: {
            addIngredient: [],
            removeIngredient: [],
            lightIngredient: [],
            swapIngredient: [],
            customIngredient: [],
          },
        },
        guestInfo: {
          name: '',
          phone: '',
          phoneStatus: false,
        },
        pickupDate: {
          day: '',
          time: '',
        },
        notes: '',
        itemDetailsModal: false,
      };

    case ActionTypes.updateItemSizesOption:
      return {
        ...state,
        newOrderItem: {
          ...state.newOrderItem,
          customOptions: {
            ...state.newOrderItem.customOptions,
            selectedSizes: {
              optionId: payload.optionId,
              variantId: payload.variantId,
              name: payload.name,
            },
            nextModifiersSlug: state.newOrderItem.customOptions.nextModifiersSlug.map(
              (item) => {
                if (item.optionSlug === payload.optionSlug) {
                  item.status = true;
                }

                return item;
              }
            ),
          },
        },
      };
    case ActionTypes.updateItemOtherOption:
      return {
        ...state,
        newOrderItem: {
          ...state.newOrderItem,
          customOptions: {
            ...state.newOrderItem.customOptions,
            selectedModifiers: {
              ...state.newOrderItem.customOptions.selectedModifiers,
              [payload.optionSlug]: {
                optionId: payload.optionId,
                variantId: payload.variantId,
                name: payload.name,
              },
            },
            nextModifiersSlug: state.newOrderItem.customOptions.nextModifiersSlug.map(
              (item) => {
                if (item.optionSlug === payload.optionSlug) {
                  item.status = true;
                }

                return item;
              }
            ),
          },
        },
      };
    case ActionTypes.changeItemQuantity:
      return {
        ...state,
        newOrderItem: {
          ...state.newOrderItem,
          itemData: {
            ...state.newOrderItem.itemData,
            quantity: Number(payload),
          },
        },
      };
    case ActionTypes.changeItemGuestInfo:
      return {
        ...state,
        guestInfo: {
          ...state.guestInfo,
          name: payload.name,
          phone: payload.phone,
          phoneStatus: true,
        },
      };
    case ActionTypes.changeItemNotes:
      return {
        ...state,
        notes: payload,
      };
    case ActionTypes.changePickupTime:
      return {
        ...state,
        pickupDate: {
          ...state.pickupDate,
          day: payload.pickupDay,
          time: payload.pickupTime,
        },
      };
    case ActionTypes.updateIngredientStatus:
      let tempArr = state.newOrderItem.ingredientStatus[payload.actionType];
      const elemIndex = tempArr.indexOf(payload.ingredientId);

      if (elemIndex !== -1) {
        tempArr.splice(elemIndex, 1);
      } else {
        tempArr.push(payload.ingredientId);
      }
      return {
        ...state,
        newOrderItem: {
          ...state.newOrderItem,
          ingredientStatus: {
            ...state.newOrderItem.ingredientStatus,
            [payload.actionType]: tempArr,
          },
        },
      };
    default:
      return state;
  }
};

export default orderItemReducer;
