import { useState } from 'react';
import { useSelector } from 'react-redux';
import AlertModal from '../../../AlertModal';
import { removeDriver } from './RemoveDriverModal.service';

const RemoveDriverModal = ({
  modalStatus,
  toggleModal,
  driverName = '',
  driverId,
  selectedOrder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );

  const handleProceed = async () => {
    setIsLoading(true);
    const reqObj = {
      driverId: driverId || '',
      storeId: selectedOrder?.storeId || '',
      restaurantId: selectedOrder?.restaurantId || '',
      orderId: selectedOrder?._id || '',
      isOtherDelivery: selectedOrder?.isOtherDelivery,
    };

    const resp = await removeDriver(reqObj, nextOrderConfigURL);
    setIsLoading(false);
    toggleModal();
  };
  return (
    <AlertModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      header={`Remove Driver ${driverName}?`}
      description="Are you sure you want to remove this driver?"
      proceedText="Yes"
      cancelText="No"
      enableProceedButtonLoader={isLoading}
      handleCancel={toggleModal}
      handleProceed={handleProceed}
    />
  );
};

export default RemoveDriverModal;
