import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import parse from 'date-fns/parse';
import millisecondsToHours from 'date-fns/millisecondsToHours';
import millisecondsToMinutes from 'date-fns/millisecondsToMinutes';

// https://date-fns.org/v2.16.1/docs/format

export const getTimeString = (date) => {
  return format(date, 'hh:mm a');
};

export const getDayString = (date) => format(date, 'EEEE, do MMMM');

export const getCurrentTime = () => {
  return getTimeString(new Date());
};

export const getCurrentDate = () => {
  return format(new Date(), `EEEE MMMM, dd`);
};

export const getCurrentShortenedDate = () => {
  return format(new Date(), `E MMM do`);
};

export const getShortenedDate = (date) => {
  try {
    return format(new Date(date), `E d MMM`);
  } catch (error) {
    return format(new Date(), `E d MMM`);
  }
};

export const getShortenedDateTime = (date) => {
  try {
    return format(new Date(date), `E d MMM - hh:mm a`);
  } catch (error) {
    return format(new Date(), `E d MMM - hh:mm a`);
  }
};

export const getFutureTime = (date, minutes) => {
  try {
    const newDate = addMinutes(new Date(date), minutes);
    return getTimeString(newDate);
  } catch (error) {
    return getTimeString(new Date());
  }
};

export const getHourFormat = (date) => {
  try {
    return format(new Date(date), `hh`);
  } catch (error) {
    return format(
      new Date(parse(date, 'yyyy-MM-dd hh:mm a', new Date())),
      'hh'
    );
  }
};

export const getMinuteFormat = (date) => {
  try {
    return format(new Date(date), `mm`);
  } catch (error) {
    return format(
      new Date(parse(date, 'yyyy-MM-dd hh:mm a', new Date())),
      'mm'
    );
  }
};

export const getMerdianFormat = (date) => {
  try {
    return format(new Date(date), `a`);
  } catch (error) {
    return format(new Date(parse(date, 'yyyy-MM-dd hh:mm a', new Date())), 'a');
  }
};
export const getYearFormat = (date) => {
  try {
    return format(new Date(date), `yyyy`);
  } catch (error) {
    return format(
      new Date(parse(date, 'yyyy-MM-dd hh:mm a', new Date())),
      'yyyy'
    );
  }
};

export const getDateTimeFormatForOrder = (date) => {
  try {
    return format(
      new Date(`${date} ${getYearFormat(new Date())}`),
      'yyyy-MM-dd hh:mm a'
    );
  } catch (error) {
    return format(
      new Date(
        parse(
          `${date} ${getYearFormat(new Date())}`,
          'E d MMM hh:mm a yyyy',
          new Date()
        )
      ),
      'yyyy-MM-dd hh:mm a'
    );
  }
};

export const getDayDateMonthTime = (date = new Date()) => {
  return format(date, 'EEEE, do MMMM, p');
};

export const getTimePassedSinceString = (milliSeconds) => {
  const hoursPassed = millisecondsToHours(milliSeconds);
  const minutesPassed = millisecondsToMinutes(
    milliSeconds - hoursPassed * 1000 * 60 * 60
  );
  const timePassedArray = [];
  if (hoursPassed) {
    timePassedArray.push(`${hoursPassed} hours`);
  }
  if (minutesPassed) {
    timePassedArray.push(`${minutesPassed} mins`);
  }
  return timePassedArray.join(' ');
};

export const getTimePassedSinceTimestamp = (pastTimeStamp) => {
  const currentTimeStamp = new Date().getTime();
  const differenceInMilliseconds = currentTimeStamp - pastTimeStamp;
  const timePassedString = getTimePassedSinceString(differenceInMilliseconds);
  if (!timePassedString) {
    return '0 mins';
  }
  return timePassedString;
};

export const getDateFromTimestamp = (timestamp) => {
  return new Date(timestamp);
};
