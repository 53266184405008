import React, { useState } from 'react';
import GeneralModal from '../../../modals/GeneralModal/index-new';
import './CourseMenuItemActionModal.css';
import { HoldIcon, NextIcon } from 'nexticons/solid';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuItemCourseStatus } from '../CoursesModal/CoursesModal.service';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { updateCurrentOrder } from 'pages/Dashboard/action';
import clsx from 'clsx';

const CourseMenuItemActionModal = ({
  show,
  toggleModal,
  dropdownRef,
  menuItem,
  menuItemsWithCourseStatus,
  setMenuItemsWithCourseStatus,
}) => {
  const dispatch = useDispatch();
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const dropdownPostion = dropdownRef.current.getBoundingClientRect();

  const getMenuItemTitle = (item) => {
    let title = '';
    item?.forEach((element, index) => {
      title = title + `${index !== 0 ? ' / ' : ''}${element?.name}`;
    });

    return title;
  };

  const handleSingleMenuItemCourseUpdate = async (menuItem, courseStatus) => {
    setIsApiLoading(true);
    setLoadingStatus(courseStatus);
    const updatedMenuItemsPayload = menuItemsWithCourseStatus?.map((item) => {
      if (menuItem?.find((mitem) => mitem.orderIndex === item.orderIndex)) {
        return {
          id: item._id,
          orderIndex: item.orderIndex,
          courseStatus: courseStatus,
          courseStatusSetAt: new Date().getTime(),
        };
      }

      return {
        id: item._id,
        orderIndex: item.orderIndex,
        courseStatus: item?.courseStatus || 'send',
        courseStatusSetAt: item?.courseStatusSetAt || new Date().getTime(),
      };
    });

    const updatedMenuItems = menuItemsWithCourseStatus?.map((item) => {
      if (menuItem?.find((mitem) => mitem.orderIndex === item.orderIndex)) {
        return {
          ...item,
          courseStatus: courseStatus,
          courseStatusSetAt: new Date().getTime(),
        };
      }

      return {
        ...item,
        courseStatus: item?.courseStatus || 'send',
        courseStatusSetAt: item?.courseStatusSetAt || new Date().getTime(),
      };
    });

    setMenuItemsWithCourseStatus(updatedMenuItems);

    if (currentOrder?.iteration === 0) {
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          menuItems: updatedMenuItems,
        })
      );
    } else {
      const res = await updateMenuItemCourseStatus(
        updatedMenuItemsPayload,
        currentOrder?._id
      );
    }
    setLoadingStatus('');
    setIsApiLoading(false);
    toggleModal();
  };

  return (
    <>
      <GeneralModal
        modalStatus={show}
        toggleModal={toggleModal}
        effect="fade"
        overlay={'default'}
        boxWidth="180px"
        boxHeight="fit-content"
        closeBtn="outside"
        isResponsiveHeight={true}
        paperContianerStyle={{
          position: 'absolute',
          top: `${dropdownPostion?.top}px`,
          left: `${dropdownPostion.left - 180}px`,
          borderRadius: '9px',
          height: 'fit-content',
        }}
      >
        <div className="actionHeader">{getMenuItemTitle(menuItem)}</div>
        <div
          className={clsx('actionButton', {
            ['disableActionButton']: !currentOrder?._id,
          })}
          onClick={() =>
            currentOrder?._id &&
            handleSingleMenuItemCourseUpdate(menuItem, 'hold')
          }
        >
          {isApiLoading && loadingStatus === 'hold' ? (
            <PosIconLoading mainColor="#067aff" size={15} />
          ) : (
            <HoldIcon strokeWidth={1.5} style={{ width: 18 }} color="#067aff" />
          )}
          <span className="actionButtonText">Hold</span>
        </div>
        <div
          className={clsx('actionButton', {
            ['disableActionButton']: !currentOrder?._id,
          })}
          onClick={() =>
            currentOrder?._id &&
            handleSingleMenuItemCourseUpdate(menuItem, 'rush')
          }
        >
          {isApiLoading && loadingStatus === 'rush' ? (
            <PosIconLoading mainColor="#fd6c00" size={15} />
          ) : (
            <NextIcon strokeWidth={1.5} style={{ width: 18 }} color="#fd6c00" />
          )}
          <span className="actionButtonText">Rush</span>
        </div>
      </GeneralModal>
    </>
  );
};

export default CourseMenuItemActionModal;
