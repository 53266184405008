import React, { useRef } from 'react';
import Button from '../../Button';
import classNames from 'classnames';
import { currencyFormat } from '../../../util/currencyFormat';
import PropTypes from 'prop-types';
import { PosIconArrow } from '../../../assets/icons/PosIconArrow';
import { ReactComponent as BackspaceIcon } from '../../../assets/icons/backspace_icon.svg';

export const MoneyCountDenominationsMode = ({
  total,
  setQuantity,
  handleToggleModal,
  moneyCount,
  toggleManuallyMode,
  activeIndex,
  setActiveDenomination,
}) => {
  const keyList = useRef([
    { id: 1, content: '1', val: '1' },
    { id: 2, content: '2', val: '2' },
    { id: 3, content: '3', val: '3' },
    { id: 4, content: '4', val: '4' },
    { id: 5, content: '5', val: '5' },
    { id: 6, content: '6', val: '6' },
    { id: 7, content: '7', val: '7' },
    { id: 8, content: '8', val: '8' },
    { id: 9, content: '9', val: '9' },
    {
      id: 10,
      content: (
        <PosIconArrow
          mainColor="#515151"
          darkModeColor="#ffffff"
          className="rotate-90"
        />
      ),
      val: 'up',
    },
    { id: 11, content: '0', val: '0' },
    {
      id: 13,
      content: (
        <PosIconArrow
          mainColor="#515151"
          darkModeColor="#ffffff"
          className="rotate-270"
        />
      ),
      val: 'down',
    },
  ]);

  const handleClick = (e, val) => {
    let tempIndex = activeIndex;

    if (val === 'up') {
      let newIndex = --tempIndex;
      if (
        newIndex > -1 &&
        newIndex < moneyCount.length &&
        moneyCount[newIndex]
      ) {
        setActiveDenomination(newIndex);
      }
    } else if (val === 'down') {
      let newIndex = ++tempIndex;
      if (
        newIndex > -1 &&
        newIndex < moneyCount.length &&
        moneyCount[newIndex]
      ) {
        setActiveDenomination(newIndex);
      }
    } else {
      setQuantity(activeIndex, val);
    }
  };

  const handleDenominationBackspace = () => {
    setQuantity(activeIndex, '-');
  };

  return (
    <div className="money-count-sidebar">
      <div className="denominations-box">
        <div className="denominations-header">
          <h2 className="denominations-title">Total</h2>
          <p className="denominations-price">
            {total ? currencyFormat(total) : ''}
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button
              title="Enter Manually"
              className="btn grey inverse"
              onClick={toggleManuallyMode}
            >
              Enter Manually
            </Button>
            <Button
              className="btn grey inverse"
              spanStyleProps={{
                style: {
                  padding: '0px',
                  display: 'flex',
                  justifyContent: 'center',
                },
              }}
              onClick={handleDenominationBackspace}
            >
              <BackspaceIcon width="32" height="32" />
            </Button>
          </div>
        </div>
        <div className="denominations-keypad-box">
          {keyList.current.map((item) => {
            const itemCls = classNames({
              'denominations-key': true,
              'hover-highlight': true,
              [`key-${item.val.toLowerCase()}`]: true,
            });

            return (
              <div key={item.id} className="denominations-key-wrap">
                <Button
                  title={item.val}
                  className={itemCls}
                  onClick={(e) => handleClick(e, item.val)}
                >
                  {item.content}
                </Button>
              </div>
            );
          })}
        </div>
        <div className="denominations-btn-box">
          <Button
            className="btn"
            title="Finish "
            onClick={(e) => handleToggleModal(e, true)}
          >
            Finish{' '}
          </Button>
        </div>
      </div>
    </div>
  );
};

MoneyCountDenominationsMode.propTypes = {
  total: PropTypes.number,
};

MoneyCountDenominationsMode.defaultProps = {
  total: 0,
};
