import React from 'react';
import DarkModeToggle from '../DarkModeToggle';
import FullScreenToggle from '../FullScreenToggle';

const AppToggleGroup = () => {
  return (
    <div className="app-toggle-group">
      {/*<FullScreenToggle />*/}
      <DarkModeToggle />
    </div>
  );
};

export default AppToggleGroup;
