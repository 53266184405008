import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { authenticateStaff } from '../pages/Dashboard/action';

export const useRouteToHomePage = (timeToWait = 0) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    timeoutRef.current =
      timeToWait !== false &&
      setTimeout(() => {
        history.push('/');
        dispatch(authenticateStaff(false));
      }, [timeToWait]);
  }, [dispatch, history, timeToWait]);
};
