import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconMove = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-move': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="32"
        viewBox="0 0 28 32"
      >
        <title>Move</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M27.329 8.511l-7.338-7.231-0.818 0.847 6.322 6.218h-2.852c-12.037-0.099-21.882 9.644-22.003 21.773v0.602h1.169v-0.589c0.115-11.485 9.439-20.707 20.835-20.608h2.852l-6.31 6.205 0.819 0.849 7.337-7.219c0.11-0.108 0.179-0.258 0.179-0.424s-0.068-0.316-0.179-0.424l-0-0z"
        />
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          strokeMiterlimit="20"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          // stroke-width="0.64"
          d="M27.329 8.511v0l-7.338-7.231-0.818 0.847 6.322 6.218h-2.852c-12.037-0.099-21.882 9.644-22.003 21.773v0.602h1.169v-0.589c0.115-11.485 9.439-20.707 20.835-20.608h2.852l-6.31 6.205 0.819 0.849 7.337-7.219c0.11-0.108 0.179-0.258 0.179-0.424s-0.068-0.316-0.179-0.424l-0-0z"
        />
      </svg>
    </span>
  );
};

PosIconMove.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
