import AlertModal from '../../../components/AlertModal';

const CancelOrderAlertModal = ({
  modalStatus,
  toggleModal,
  handleCancel = () => {},
  handleProceed = () => {},
  loading = false,
}) => {
  return (
    <AlertModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      header="Cancel Order?"
      description="Once cancelled, you will not be able to recover it."
      proceedText="Yes"
      cancelText="No"
      handleCancel={handleCancel}
      handleProceed={handleProceed}
      enableProceedButtonLoader={loading}
    />
  );
};

export default CancelOrderAlertModal;
