import React, { createRef, useEffect, useRef, useState } from 'react';
import './bem-tab-navigation.scss';
import { getUIClassesFromProps } from '../modifiersMapping';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CheckCircleIcon } from 'nexticons/solid';

export const TabsNavigationUI = ({
  tabsList,
  successList,
  activeTabIndex,
  setActiveTabIndex,
  modifierClassName,
  style,
  disabled,
}) => {
  const scrollRef = useRef();
  const tabsNavigationRef = useRef();
  const TabsRefs = useRef([]);

  if (!tabsList || !tabsList.length) return null;

  return (
    <div
      className={getUIClassesFromProps('bem-tabs-navigation', {
        modifierClassName,
      })}
      style={style}
      ref={tabsNavigationRef}
    >
      <div className="bem-tabs-navigation__inner" ref={scrollRef}>
        {tabsList.map((item, i) => {
          const successCheck =
            successList && successList.length > 0 && !!successList[i];
          const cls = clsx(
            'bem-tabs-navigation__button',
            activeTabIndex === i && 'bem-tabs-navigation__button--active',
            successCheck && 'bem-tabs-navigation__button--success'
          );

          if (item.to) {
            return (
              <NavLink
                key={i}
                ref={(c) => (TabsRefs.current[i] = c)}
                title={item.title}
                className={cls}
                onClick={() => setActiveTabIndex(i)}
                to={item.to}
              >
                {item.title}
              </NavLink>
            );
          } else {
            return (
              <button
                key={i}
                ref={(c) => (TabsRefs.current[i] = c)}
                title={item.title}
                className={cls}
                onClick={() => setActiveTabIndex(i)}
                type="button"
                disabled={disabled}
              >
                {successCheck && (
                  <CheckCircleIcon width={26} style={{ marginRight: 10 }} />
                )}
                {item.title}
              </button>
            );
          }
        })}
        <div
          className="bem-tabs-navigation__active-line"
          style={{
            width: TabsRefs.current[activeTabIndex]?.offsetWidth,
            transform: `translateX(${TabsRefs.current[activeTabIndex]?.offsetLeft}px)`,
          }}
        />
      </div>
    </div>
  );
};

TabsNavigationUI.propTypes = {
  tabsList: [],
  successList: false,
  activeTabIndex: undefined,
  setActiveTabIndex: () => {},
  modifierClassName: '',
  style: {},
};

TabsNavigationUI.propTypes = {
  tabsList: PropTypes.array,
  successList: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  activeTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setActiveTabIndex: PropTypes.func,
  modifierClassName: PropTypes.string,
  style: PropTypes.object,
};
