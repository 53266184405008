import './bem-select-item.scss';

export const SelectItemUI = ({
  value,
  title,
  instruction,
  price,
  checked,
  onChange,
}) => {
  return (
    <label className="bem-select-item">
      <span className="bem-select-item__input-box">
        <input
          type="checkbox"
          className="bem-select-item__input"
          value={value}
          checked={checked}
          name="select-item"
          onChange={onChange}
        />
      </span>
      <span className="bem-select-item__info-box">
        <span className="bem-select-item__title">{title}</span>
        {instruction && (
          <span className="bem-select-item__instruction">{instruction}</span>
        )}
      </span>
      <span className="bem-select-item__price">{price}</span>
    </label>
  );
};
