import React from 'react';
import './GuestDropDown.css';
import { PeopleIcon } from 'nexticons/outline';
import { ChevronDownIcon, ChevronUpIcon } from 'nexticons/outline';
import clsx from 'clsx';

const GuestDropDown = ({ onClick, isModalOpen, isDisabled }) => {
  return (
    <div
      className={clsx('guestDropDownContainer', {
        ['disabled']: isDisabled,
      })}
      onClick={onClick}
    >
      <PeopleIcon width={20} color="#c700e8" strokeWidth={1} />
      <span className="guestDropDownText"> Guests</span>
      <div className="guestDropDownIconContainer">
        {isModalOpen ? (
          <ChevronUpIcon width={12} strokeWidth={3} />
        ) : (
          <ChevronDownIcon width={12} strokeWidth={3} />
        )}
      </div>
    </div>
  );
};

export default GuestDropDown;
