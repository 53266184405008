import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PrintBillSingleItem } from '../components/split-bill/PrintBillSingleItem';
import classNames from 'classnames';
// import useTotalPrice from '../customHooks/useTotalPrice';
// import useActiveSeatPay from '../customHooks/useactive';
// import useSeatsOrders from '../custom-hook/useSeatsOrders';
import { PrinterContext } from '../context/PrinterContext';
import loadingData from '../lotties/9914-loading-success-fail-spinner-stroke-update.json';
import { Dropdown } from '../components/dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllPrinters, printBillCommand } from '../services/ordersServices';
import { useOrdersGlobalState } from '../pages/Orders/components/Providers/OrdersGlobalState/OrdersGlobalState';
import {
  currencySymbolSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
} from '../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../util/currencyFormat';
import { ScrollableBoxUI } from '../UI/components';

const SideBarModalPrintBill = ({ additionalClass }) => {
  let { tableId } = useParams();
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);

  const {
    // selectedOrder,
    scrolledStatus,
    printBillModal,
    orderBarType,
  } = useSelector((state) => state.ordersReducer);

  const globalOrdersState = useOrdersGlobalState();
  const selectedOrder = globalOrdersState.selectedOrder || {};

  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  //   const { state, dispatch } = useContext(Context);
  //   const seatedList = state.tableView.seatedList;
  //   const timeLine = state.tableView.timeLine;
  const [defTableId, setDefTableId] = useState(tableId);
  // const [currentDay, setCurrentDay] = useState("");
  const [currentDayIndex, setCurrentDayIndex] = useState('');
  const orders = selectedOrder;
  const totalPrice = selectedOrder.payableAmount;
  const totalTax = totalPrice / 10;
  const modalName = `${orders.firstName} ${orders.lastName}`;
  //   const activePaySeat = useActiveSeatPay(state.seats);
  //   const seatsOrders = useSeatsOrders(state.seats);
  const { print, printers, deviceStatus, setPrinters, setPrinter, printer } =
    useContext(PrinterContext);

  // let currentPrinter =
  //   printers && printers.find(({ isSelected }) => isSelected);
  // currentPrinter = currentPrinter ? currentPrinter : {};

  // const [preferedIp, setPreferedId] = useState(currentPrinter);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: (() => {
      switch (deviceStatus) {
        case 'failure': {
          return loadingData;
        }
        case 'success': {
          return loadingData;
        }
        case 'pending': {
          return loadingData;
        }
        case 'default': {
          return loadingData;
        }
        default: {
          return loadingData;
        }
      }
    })(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  //   useEffect(() => {
  //     let newTableId = tableId;

  //     for (let i = 0; i < timeLine.length; i++) {
  //       if (timeLine[i].status) {
  //         setCurrentDayIndex(i);
  //         // setCurrentDay(timeLine[i]);
  //         break;
  //       }
  //     }

  //     if (!newTableId) {
  //       if (state.currentGuestId) {
  //         for (let i = 0; i < seatedList.length; i++) {
  //           if (seatedList[i].id === state.currentGuestId) {
  //             newTableId = seatedList[i].table;
  //             break;
  //           }
  //         }
  //       }
  //     }

  //     newTableId = newTableId ? Number(newTableId) : 1;

  //     setDefTableId(newTableId);
  //   }, [state.printBillModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deviceStatus === 'success') {
      handleCloseModal();
    }
  }, [deviceStatus]);

  // useEffect(() => {
  //   console.log('selected Printer', printer);
  // }, [printer]);

  useEffect(() => {
    if (printBillModal) {
      getAllPrinters();
    }
  }, [printBillModal]);

  const getAllPrinters = async () => {
    const { printers } = await fetchAllPrinters(storeConfig);
    setPrinters(printers);
    printers.map((item, index) => {
      return index === 0
        ? (item.isPrimaryPrinter = true)
        : (item.isPrimaryPrinter = false);
    });
    setPrinter(printers[0]);
  };

  const shadowCls = classNames({
    shadow: scrolledStatus[2].position,
  });

  const modalCls = classNames({
    'sidebar-modal': true,
    [`${additionalClass}`]: true,
    show: printBillModal,
  });

  const handleCloseModal = () => {
    dispatch({
      type: 'togglePlanBillModal',
      payload: false,
    });
  };

  const handleSplitBill = () => {
    dispatch({
      type: 'togglePlanBillModal',
      payload: false,
    });

    dispatch({
      type: 'setBackLink',
      payload: window.location.pathname,
    });
  };

  const handlePrintBill = (e, seatId) => {
    dispatch({
      type: 'togglePlanBillModal',
      payload: false,
    });

    dispatch({
      type: 'changeActiveStatus',
      payload: {
        statusId: 8,
        tableId: defTableId,
        dayIndex: currentDayIndex,
      },
    });
  };

  const handleClick = () => {
    dispatch({
      type: 'togglePlanBillModal',
      payload: false,
    });

    dispatch({
      type: 'setBackLink',
      payload: window.location.pathname,
    });
  };

  const onPrinterChange = (e) => {
    // setPreferedId(e.target.value);
    const selectedId = e.target.value;
    printers.map((item) => {
      if (item._id === selectedId) {
        item.isPrimaryPrinter = true;
        setPrinter(item);
      } else {
        item.isPrimaryPrinter = false;
      }
    });
  };

  const printBill = async () => {
    let printObj = {
      printerId: printer._id,
      restaurantId: selectedOrder.restaurantId,
      orderId: selectedOrder._id,
      storeId: storeConfig?.storeId,
    };
    await printBillCommand(printObj);
    print(printer.ipAddress);
    handleCloseModal();
  };

  return (
    <div id="sidebar-modal-print-bill" className={modalCls}>
      <div className="sidebar-modal-wrap">
        <div className="sidebar-modal-box">
          <div className={'sidebar-modal-header ' + shadowCls}>
            <h2 className="sidebar-modal-title">{modalName}</h2>
            {/*<div>{currentPrinter}</div>*/}
            <Dropdown options={printers} onChange={onPrinterChange} />
          </div>
          <div className="search-guest-box">
            <form action="" autoComplete="off">
              <input
                type="text"
                className="input-style big white"
                placeholder="Search Guest"
              />
            </form>
          </div>
          <div className="sidebar-modal-body">
            <div className="print-bill-box">
              <ScrollableBoxUI>
                <div className="print-bill-inner">
                  <ul className="print-bill-list">
                    {orders &&
                      orders.menuItems &&
                      orders.menuItems.map((menuItem, index) => {
                        return (
                          <PrintBillSingleItem
                            key={`cartItem-${menuItem?.orderIndex}-${index}`}
                            menuItem={menuItem}
                          />
                        );
                        //   if (seatsOrders.indexOf(order.id) !== -1) {
                        //     let currentPartTitle = '';
                        //     let currentPrice =
                        //       order.itemData.price * order.quantity;
                        //     // totalPrice += currentPrice;

                        //     return (
                        //       <PrintBillSingleItem
                        //         key={i}
                        //         orderData={order}
                        //         currentPrice={currentPrice}
                        //         currentPartTitle={currentPartTitle}
                        //       />
                        //     );
                        //   } else {
                        //     return '';
                        //   }
                      })}
                  </ul>
                  {totalPrice ? (
                    <div className="print-bill-price-box">
                      <div className="print-bill-price-single">
                        <div className="cell">
                          <span className="name">Taxes</span>
                        </div>
                        <div className="cell">
                          <div className="price">
                            {getAmountWithCountryCurrency({
                              amount: Number(totalTax),
                              locale: `en-${storeCountryIdentifier}`,
                              currencyCode,
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="print-bill-price-single total">
                        <div className="cell">
                          <span className="name">Total</span>
                        </div>
                        <div className="cell">
                          <div className="price">
                            {getAmountWithCountryCurrency({
                              amount: Number(totalPrice),
                              locale: `en-${storeCountryIdentifier}`,
                              currencyCode,
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </ScrollableBoxUI>
            </div>
          </div>

          <div className="sidebar-modal-footer">
            <div className="order-actions-btn-box">
              <div className="btn-box">
                <Link
                  to={
                    orderBarType === 'pickupId'
                      ? '/pick-up/customer-phone'
                      : orderBarType === 'deliveryId'
                      ? '/delivery/customer-phone'
                      : '/split-bill'
                    // : "/table-view/table/" + tableId
                  }
                  className="btn-action width-50 hover-highlight"
                  title="Split Bill"
                  onClick={handleSplitBill}
                >
                  <span className="inner-btn-box">Split Bill</span>
                </Link>

                <Link
                  to={'/view-order/discount/items/1'}
                  className="btn-action width-50 hover-highlight"
                  title="Discount"
                  onClick={handleClick}
                >
                  <span className="inner-btn-box">Discount</span>
                </Link>
                <button
                  className="btn-action width-100 green hover-highlight"
                  onClick={() => printers && printBill()}
                >
                  <span className="inner-btn-box">
                    Print Bill : {deviceStatus}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-modal-overlay" onClick={handleCloseModal} />
    </div>
  );
};

export default SideBarModalPrintBill;
