import React from 'react';
import { getExtraPriceForDisplay } from '../../itemProcessor';
import PosIconPlusCircle from '../../assets/icons/PosIconPlusCircle';
import PosIconMinusCircle from '../../assets/icons/PosIconMinusCircle';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';

const Counter = ({ quantity, onDecrement, onIncrement }) => {
  return (
    <div
      className="bem-ingredient-counter"
      onClick={(event) => event.stopPropagation()}
    >
      <PosIconMinusCircle onClick={onDecrement} />
      {quantity}
      <PosIconPlusCircle onClick={onIncrement} />
    </div>
  );
};

export const AddIngredient = ({
  _id,
  name,
  ingredientName,
  price,
  prices,
  quantity,
  actionType,
  handleChange,
  currentItem,
  selected,
  currencyCode,
  storeCountryIdentifier,
}) => {
  let ingredientPrice = getExtraPriceForDisplay(currentItem, prices, price);
  const updateQuantity = (qty) => {
    handleChange(_id, ingredientPrice, qty);
  };
  const handleIncrement = (event) => {
    event.stopPropagation();
    updateQuantity(quantity + 1);
  };

  const handleDecrement = (event) => {
    event.stopPropagation();
    updateQuantity(quantity - 1);
  };

  return (
    <div
      onClick={() => handleChange(_id, ingredientPrice)}
      className="item-card"
    >
      <input
        id={`${actionType}-${_id}`}
        type="checkbox"
        name={`${actionType}-${_id}`}
        value={_id}
        checked={!!selected}
        onChange={() => {}}
      />
      <label htmlFor={'item_' + _id}>
        <div className="inner-box">
          <h3 className="card-title">{name ? name : ingredientName}</h3>
          {ingredientPrice !== 0 ? (
            <span className="card-price">
              {getAmountWithCountryCurrency({
                amount: Number(ingredientPrice),
                locale: `en-${storeCountryIdentifier}`,
                currencyCode,
              })}
            </span>
          ) : (
            ''
          )}
          {!!selected && !!quantity && (
            <Counter
              quantity={quantity}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
            />
          )}
        </div>
      </label>
    </div>
  );
};
