import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconBagInverse = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-bag-inverse': true,
    [`${className}`]: className,
  });

  /*
  fill={iconColor ? iconColor : "#000"}
  stroke={iconColor ? iconColor : "#000"}
  strokeWidth={lineThickness ? lineThickness : 0}
  */
  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="66"
        height="78"
        viewBox="0 0 66 78"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M11.742 22.274c.149-.143.35-.22.557-.216h3.687v6.16H5.793zm7.33-4.836a13.866 13.866 0 0 1 5.18-10.76A14.011 14.011 0 0 1 35.91 3.886c6.551 1.536 11.14 7.41 11.019 14.107v.985H19.07zm27.857 10.78H19.07v-6.16H46.93zm6.782-6.16a.777.777 0 0 1 .557.216l5.95 5.945H50.023v-6.16zm11.325 6.623l-8.605-8.563a3.879 3.879 0 0 0-2.724-1.14h-3.683v-.862c.16-8.19-5.451-15.377-13.464-17.249a17.118 17.118 0 0 0-14.256 3.41 16.94 16.94 0 0 0-6.328 13.161v1.54h-3.683a3.879 3.879 0 0 0-2.724 1.14L.964 28.68A1.536 1.536 0 0 0 .5 29.759v43.89c0 2.127 1.732 3.851 3.87 3.851H61.63c2.137 0 3.869-1.724 3.869-3.85V29.759a1.536 1.536 0 0 0-.464-1.078z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconBagInverse.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
