import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconBag = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-bag': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="28"
        viewBox="0 0 25 28"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M22.452 25.7c0 .144-.117.26-.262.26H2.81a.261.261 0 0 1-.262-.26V11.4h4.19v2.08h1.048V11.4h9.428v2.08h1.048V11.4h4.19zM5.302 8.353a.263.263 0 0 1 .19-.073h1.246v2.08H3.291zM7.787 6.72c0-2.585 2.108-4.68 4.709-4.68 2.6 0 4.71 2.095 4.71 4.68v.52h-9.42zm9.418 3.64H7.786V8.28h9.418zm2.305-2.08a.263.263 0 0 1 .188.073l2.012 2.007h-3.447V8.28zm3.834 2.236L20.43 7.625a1.304 1.304 0 0 0-.921-.385h-1.247v-.52c0-3.16-2.58-5.72-5.762-5.72-3.182 0-5.762 2.56-5.762 5.72v.52H5.491c-.346.001-.678.14-.921.385l-2.913 2.891a.518.518 0 0 0-.157.364V25.7c0 .718.586 1.3 1.31 1.3h19.38c.724 0 1.31-.582 1.31-1.3V10.88a.518.518 0 0 0-.157-.364z"
            />
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness / 5 : 0.2}
              strokeMiterlimit="20"
              d="M22.452 25.7c0 .144-.117.26-.262.26H2.81a.261.261 0 0 1-.262-.26V11.4h4.19v2.08h1.048V11.4h9.428v2.08h1.048V11.4h4.19zM5.302 8.353a.263.263 0 0 1 .19-.073h1.246v2.08H3.291zM7.787 6.72c0-2.585 2.108-4.68 4.709-4.68 2.6 0 4.71 2.095 4.71 4.68v.52h-9.42zm9.418 3.64H7.786V8.28h9.418zm2.305-2.08a.263.263 0 0 1 .188.073l2.012 2.007h-3.447V8.28zm3.834 2.236L20.43 7.625a1.304 1.304 0 0 0-.921-.385h-1.247v-.52c0-3.16-2.58-5.72-5.762-5.72-3.182 0-5.762 2.56-5.762 5.72v.52H5.491c-.346.001-.678.14-.921.385l-2.913 2.891a.518.518 0 0 0-.157.364V25.7c0 .718.586 1.3 1.31 1.3h19.38c.724 0 1.31-.582 1.31-1.3V10.88a.518.518 0 0 0-.157-.364z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconBag.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
