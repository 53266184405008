import './bem-numeric-keyboard.scss';
import PropTypes from 'prop-types';

export const NumericKeyboardUI = ({
  onKeyDown,
  onPressNumericKey,
  keyList,
}) => {
  if (!keyList) return null;

  return (
    <div className="bem-numeric-keyboard" onKeyDown={onKeyDown}>
      {keyList.map((item) => {
        return (
          <button
            key={item.code}
            className="bem-numeric-keyboard__key"
            type="button"
            title={item.title}
            onClick={() => onPressNumericKey(item.code)}
          >
            {item.content}
          </button>
        );
      })}
    </div>
  );
};

NumericKeyboardUI.defaultProps = {
  onKeyDown: () => {},
  onKeyPress: () => {},
  keyList: [],
};

NumericKeyboardUI.propTypes = {
  onKeyDown: PropTypes.func,
  onPressNumericKey: PropTypes.func,
  keyList: PropTypes.array,
};
