import React from 'react';
import PropTypes from 'prop-types';
import mobiscroll from '@mobiscroll/react';
import { getIndexByValue } from '../../util/getIndexByValue';

const SelectDeliveryTime = ({ setDeliveryTimeData, timeIndex, orderTimes }) => {
  const daysArray = orderTimes ? orderTimes.map((m) => m.display) : [];
  const timesArray =
    orderTimes && orderTimes.length > 0
      ? orderTimes[timeIndex.dayIndex].times.map((m) => m.displayForUse)
      : [];

  // const boxHeight = 280;
  const boxHeight = 300;
  const rows = 5;
  const rowHeight = boxHeight / rows;

  const activeSlideDay = timeIndex.dayIndex;
  const activeSlideTime = timeIndex.timeIndex;

  const wheelNew = [
    [
      {
        key: 'setDay',
        circular: false,
        data: daysArray,
      },
      {
        key: 'setTime',
        circular: false,
        data: timesArray,
      },
    ],
  ];

  const handleInitNew = (event, inst) => {
    inst.setArrayVal([daysArray[activeSlideDay], timesArray[activeSlideTime]]);
  };

  const handleSet = (event, inst) => {
    const newArr = inst._wheelArray;
    const dayIndex = getIndexByValue(newArr[0], daysArray);
    const timeIndex = getIndexByValue(newArr[1], timesArray);
    setDeliveryTimeData({
      dayIndex,
      timeIndex,
    });
  };

  // https://demo.mobiscroll.com/react/scroller/date-range#
  if (orderTimes) {
    return (
      <div className="select-delivery-time-wrap">
        <mobiscroll.Scroller
          display="inline"
          rows={rows}
          circular={[false, false]}
          type="hidden"
          wheels={wheelNew}
          onInit={handleInitNew}
          onSet={handleSet}
          height={rowHeight}
          minWidth={[150, 52, 57, 92]}
        />
      </div>
    );
  }
  return null;
};

SelectDeliveryTime.propTypes = {
  setDeliveryTimeData: PropTypes.func,
  orderTimes: PropTypes.any,
};

export default SelectDeliveryTime;
