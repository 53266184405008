import React, { useMemo } from 'react';
//import useTotalPrice from '../../../customHooks/useTotalPrice';
import { useSelector } from 'react-redux';
import {
 
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../../../util/currencyFormat';

export const TotalPriceBox = () => {
  const { orders, selectedOrderId } = useSelector(state => state.newOrdersReducer)

  const selectedOrder = useMemo(() =>
    orders.allOrdersMap[selectedOrderId] || null,
    [selectedOrderId, orders.allOrdersMap]);

  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  return (
    <span className="price">
      {getAmountWithCountryCurrency({
        amount: parseFloat(selectedOrder.payableAmount),
        locale: `en-${storeCountryIdentifier}`,
        currencyCode,
      })}
    </span>
  );
};
