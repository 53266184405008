import React from 'react';
import GeneralModal from '../GeneralModal/index-new';
import PropTypes from 'prop-types';
import { currencyFormat } from '../../util/currencyFormat';
import Button from '../../components/Button';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';

export const MoneyCountModal = ({
  modalStatus,
  toggleModal,
  confirmForm,
  totalPrice,
  isLoading = false,
}) => {
  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="center"
      effect="move"
      overlay=""
      boxWidth="358px"
      boxHeight="381px"
      className="money-count-modal"
    >
      <div className="money-count-modal-box">
        <div className="money-count-box-inner">
          <h2 className="money-count-desc">Total amount in cash</h2>
          <div className="money-count-box">
            <p className="money-count-total">
              {totalPrice ? currencyFormat(parseFloat(totalPrice)) : ''}
            </p>
          </div>
        </div>
        <div className="money-count-btn-box">
          <Button title="Confirm" onClick={confirmForm} className="btn">
            {!isLoading ? (
              'Confirm'
            ) : (
              <PosIconLoading mainColor="#ffffff" size={20} />
            )}
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

MoneyCountModal.propTypes = {
  totalPrice: PropTypes.any,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  confirmForm: PropTypes.func,
};
