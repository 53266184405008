import { differenceInHours } from 'date-fns';
import {
  getDateFromTimestamp,
  getDayString,
  getTimePassedSinceString,
  getTimePassedSinceTimestamp,
  getTimeString,
} from './dateUtils';

export const getTimePassedString = (
  startingTimestamp,
  isStaffOnABreak = false
) => {
  const timepassedString = getTimePassedSinceTimestamp(startingTimestamp);
  if (timepassedString === '0 mins') {
    if (isStaffOnABreak) {
      return 'Just started';
    }
    return 'Just Clocked In';
  }
  return timepassedString;
};

export const getRoleNameFromId = (roleId, staffMember) => {
  const activeRole =
    staffMember?.roles?.find((role) => role.id === roleId) || {};
  const { name: activeRoleName } = activeRole;
  return activeRoleName;
};

export const parsedShiftsDataForTable = (shifts, staffMember) => {
  return shifts.map((shift) => {
    return {
      id: shift.id,
      day: getDateForShiftsTable(shift.startTime),
      role: getRoleNameFromId(shift.roleId, staffMember),
      clockedIn: getTimeForShiftsTable(shift.startTime),
      clockedOut: getTimeForShiftsTable(shift.endTime),
      paidHours:
        getTimePassedSinceString(shift.totalShiftDurationSec * 1000) || '-',
      breakTime:
        getTimePassedSinceString(shift.totalBreakDurationSec * 1000) || '-',
    };
  });
};

export const getDateForShiftsTable = (timestamp) => {
  const dateObj = getDateFromTimestamp(timestamp);
  return getDayString(dateObj);
};

export const getTimeForShiftsTable = (timestamp) => {
  const dateObj = getDateFromTimestamp(timestamp);
  return getTimeString(dateObj);
};

export const isForgotToClockOutOrOnABreak = (currentShift, orderSetup) => {
  const startTime = new Date(currentShift.startTime);
  const timeNow = new Date();
  const diff = differenceInHours(startTime, timeNow);

  const onABreak = currentShift?.onABreak;
  const hoursPastClockIn = Number(orderSetup?.hoursPastClockIn) || 12; // or default value

  // if clock in start time more than "hours from yesterday" or is break
  return Math.abs(diff) > hoursPastClockIn || onABreak;
};
