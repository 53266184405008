import { createSelector } from 'reselect';

export const ordersReducerData = (state) => state.ordersReducer;

export const ordersListFetchStatusSelector = createSelector(
  ordersReducerData,
  (data) => data.orderFetchStatus
);

export const orderTypeFilterSelector = createSelector(
  ordersReducerData,
  (data) => data.orderTypeFilter || 'all'
);

export const orderTimeSortOrderFilterSelector = createSelector(
  ordersReducerData,
  (data) => data.orderTimeSortOrderFilter
);

export const viewOrdersSortSelector = createSelector(
  ordersReducerData,
  (data) => data.viewOrdersSort
);

export const actionsModalSelector = createSelector(
  ordersReducerData,
  (data) => data.actionsModal
);

export const paymentViewModalSelector = createSelector(
  ordersReducerData,
  (data) => data.paymentViewModal
);

export const refundOrderModalSelector = createSelector(
  ordersReducerData,
  (data) => data.refundOrderModal
);

export const showSplitPaymentModalSelector = createSelector(
  ordersReducerData,
  (data) => data.showSplitPaymentModal
);
export const transferOrderModalSelector = createSelector(
  ordersReducerData,
  (data) => data.transferOrderModal
);

export const orderSideBarTypeSelector = createSelector(
  ordersReducerData,
  (data) => data.orderSideBarType
);

export const requestUberDriverSelector = createSelector(
  ordersReducerData,
  (data) => data.requestUberDriver
);

export const selectOrders = createSelector(
  ordersReducerData,
  (data) => data.orders
);

export const selectOrder = createSelector(
  [ordersReducerData, (_, id) => id],
  (orders, id) => orders[id]
);
