import { useState } from "react"
import GeneralModal from '../../modals/GeneralModal/index-new';
import { PosIconCreditCardReader } from '../../assets/icons/PosIconCreditCardReader';
import { PosIconOnlineCreditCard } from '../../assets/icons/PosIconOnlineCreditCard';
import Button from '../../components/Button';

const SelectPaymentMethodModal = ({
    modalStatus,
    toggleModal,
    openPaymentViewModal,
    openCustomerPaymentDetailsModal
}) => {
    const disableButtonStyle = { width: "80%", };

    return (
        <GeneralModal
            modalStatus={modalStatus}
            toggleModal={toggleModal}
            position="center"
            effect="move"
            overlay=""
            boxWidth="375px"
            boxHeight="336px"
            className="payment-modal"
            closeBtn="inside"
        >
            <div className="payment-box">
                <div className="payment-box-inner">
                    <div className="payment-box-header">
                        <h4 className="payment-box-select-payment-title">{`Select Payment Method`}</h4>
                    </div>

                    <div className="payment-box-body padding-Top">
                        <Button
                            title="inStore"
                            onClick={openPaymentViewModal}
                            className="select-payment-btn white"
                            disabled={false}
                            style={disableButtonStyle}
                        >
                            <div>
                                <span className="btn-title-inStore">In-Store</span>
                                <div className="btn-subtitle-container"><span className="btn-subtitle">Terminal / Cash</span>  </div>
                            </div>
                            <PosIconCreditCardReader
                                mainColor="#5eb602"
                                darkModeColor="#5eb602"
                            />
                        </Button>
                    </div>
                    <div className="payment-box-body padding-Top">
                        <Button
                            title="online"
                            onClick={openCustomerPaymentDetailsModal}
                            className="select-payment-btn white"
                            disabled={false}
                            style={disableButtonStyle}
                        >
                            <div>
                                <span className="btn-title-online">Online</span>
                                <div className="btn-subtitle-container"><span className="btn-subtitle">Enter Card - MOTO</span>  </div>

                            </div>
                            <PosIconOnlineCreditCard
                                mainColor="#0084ff"
                                darkModeColor="#0084ff"
                            />
                        </Button>
                    </div>
                </div>
            </div>

        </GeneralModal>
    )

}

export default SelectPaymentMethodModal