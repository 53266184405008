import axios from 'axios';
import { nextOrderConfig } from '../../../configuration/nextOrderConfig';

export const fetchStoreConfig = async (storeId) => {
  const storeConfigResponse = await axios.post(
    `${nextOrderConfig?.cloudFunctionUrl}/getStoreConfigFile`,
    {
      storeId,
    }
  );
  const { data } = storeConfigResponse;
  const { config: storeConfig, success } = data;

  if (success) {
    return storeConfig;
  }
  return null;
};

export const geConfigs = async (restaurantId) => {
  const configResponse = await axios.post(
    `${nextOrderConfig?.cloudFunctionUrl}/getConfigFile`,
    {
      restaurantId,
    }
  );
  const { data } = configResponse;
  const { configs, success } = data;

  if (success) {
    return configs.map((config) => {
      return {
        label: config.area + ' Store',
        value: config?.storeId,
      };
    });
  }
  return [];
};
