import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import { PosIconPlus } from '../../assets/icons/PosIconPlus';
import { PosIconTick } from '../../assets/icons/PosIconTick';
import { PosIconWriteMessage } from '../../assets/icons/PosIconWriteMessage';
import {
  setHalfHalfSelectedVariant,
  setOpenItemNotesModal,
} from '../../pages/Dashboard/action';
import {
  currentHalfHalfNotesSelector,
  halfHalfVariantsSelector,
  sizesToBeShownForHalfHalfSelector,
} from '../../pages/Dashboard/selectors';
import { ScrollableBoxUI } from '../../UI/components';
import { getHalfHalfVariants } from '../../util/half-and-half-utils';
import Button from '../Button';
import HalfItemSection from './HalfItemSection';

const HalfHalfView = ({
  halfHalfData,
  onClose,
  onSectionClick,
  onSubmit,
  onSizeClick,
  onVariantClick,
  submitLabel,
  parentPage,
  toggleItemEditMode,
  isSpecialsHalfHalf = false,
}) => {
  const dispatch = useDispatch();
  const mainAreaRef = useRef();
  const mainAreaHeaderRef = useRef();
  const halfBoxRef = useRef();
  const sizesRef = useRef();
  const fullScreen = useSelector((state) => state.dashboardReducer.fullScreen);
  const [circleMaxWidth, setCircleMaxWidth] = useState('');
  const selectedSizes = halfHalfData.halfHalfs
    ? halfHalfData.halfHalfs[0]?.selectedSizes
    : [];
  const { isInModifyMode = false } = halfHalfData;

  const subModifiers = halfHalfData.halfHalfs
    ? halfHalfData.halfHalfs[0]?.subModifiers
    : [];
  let halfHalfVariants = useSelector(halfHalfVariantsSelector);
  if (isSpecialsHalfHalf) {
    halfHalfVariants = getHalfHalfVariants(
      halfHalfData?.halfHalfs[0]?.menuItems
    );
  }

  const sizeAndModifierFilteredItemsStatus = useSelector(
    sizesToBeShownForHalfHalfSelector
  );

  const variantSelected = halfHalfData?.variantSelected;

  const uniqueSizesForDisplay = [
    ...selectedSizes,
    ...(isSpecialsHalfHalf ? [] : subModifiers),
  ].reduce((uniqueSizes, size) => {
    if (!uniqueSizes.some((s) => s.pairId === size.pairId)) {
      return [...uniqueSizes, size];
    }
    return uniqueSizes;
  }, []);

  const uniqueSizesForDisplayFilteredWithVariants =
    uniqueSizesForDisplay.filter(
      (size) =>
        variantSelected === '' ||
        halfHalfVariants[variantSelected]?.sizes.includes(
          size.name.toLowerCase()
        )
    );

  const [defaultSize, setDefaultSize] = useState('');

  useEffect(() => {
    if (variantSelected) {
      const hasSizeWithVariantSelected =
        uniqueSizesForDisplayFilteredWithVariants.some(
          (size) => size.name === defaultSize.name
        );
      if (!hasSizeWithVariantSelected && defaultSize) {
        onVariantClick(
          uniqueSizesForDisplayFilteredWithVariants[0],
          variantSelected
        );
      }
    }
  }, [
    defaultSize,
    onVariantClick,
    uniqueSizesForDisplayFilteredWithVariants,
    variantSelected,
  ]);

  useEffect(() => {
    uniqueSizesForDisplayFilteredWithVariants.forEach((size) => {
      if (size.isDefault) setDefaultSize(size);
    });
  }, [halfHalfData, uniqueSizesForDisplayFilteredWithVariants]);

  const areAllHalfFilled = halfHalfData.halfHalfs?.every(
    (h) => h.currentMenuItem?._id
  );

  const onResize = useCallback(() => {
    const diff = areAllHalfFilled ? 50 + 80 : 50;
    const circleWidth =
      mainAreaRef.current.offsetHeight -
      mainAreaHeaderRef.current.offsetHeight -
      sizesRef.current.offsetHeight -
      diff;
    const wrapWidth = mainAreaRef.current.offsetWidth - 80;

    const maxWidth = circleWidth > wrapWidth ? wrapWidth : circleWidth;

    setCircleMaxWidth(maxWidth);
  }, [areAllHalfFilled]);

  const handleResize = throttle(() => {
    onResize();
  }, 350);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onResize, handleResize]);

  useEffect(() => {
    onResize();
  }, [fullScreen, onResize]);

  const handleVariantClick = (variant) => {
    if (variantSelected === variant) {
      dispatch(setHalfHalfSelectedVariant(''));
      onVariantClick(defaultSize, '');
    } else {
      dispatch(setHalfHalfSelectedVariant(variant));
      onVariantClick(defaultSize, variant);
    }
  };

  const handleItemNotesModal = (itemNotesModalStatus) => {
    dispatch(setOpenItemNotesModal(itemNotesModalStatus));
  };

  const currentHalfHalfNotes = useSelector(currentHalfHalfNotesSelector);

  return (
    <div className="main-area" id="main-area">
      <div className="main-area-inner" ref={mainAreaRef}>
        <div className="main-half-area-header" ref={mainAreaHeaderRef}>
          <Button
            title="Close"
            className="half-area-btn-close"
            onClick={onClose}
          >
            <PosIconClose
              mainColor="#494b4c"
              darkModeColor="#fff"
              lineThickness={1.25}
            />
          </Button>
          <div
            className="half-area-notes"
            onClick={() => {
              handleItemNotesModal(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            <span className="notes-title">
              {currentHalfHalfNotes || 'Item Notes'}
            </span>
            <span className="img-box">
              <PosIconWriteMessage mainColor="#484B4C" />
            </span>
          </div>
          <div className="half-header-btn-box">
            {Object.entries(halfHalfVariants).map(([key, variant]) => (
              <Button
                key={key}
                title="Gluten Free"
                className="btn round with-icon"
                onClick={() => handleVariantClick(key)}
                enableThemeColor
                style={{
                  borderRadius: 20,
                }}
              >
                {variantSelected === key ? (
                  <PosIconTick mainColor="#ffffff" />
                ) : (
                  <PosIconPlus mainColor="#ffffff" />
                )}
                {variant.variantDisplayName}
              </Button>
            ))}
          </div>
          <h1 className="category-title">Half / Half</h1>
        </div>
        <div
          ref={halfBoxRef}
          className={clsx('main-half-body-wrap', {
            'padding-80': areAllHalfFilled,
          })}
        >
          <ScrollableBoxUI>
            <div className="main-half-area-body">
              <div
                className="half-half-cards" //for Four Quarters Pizza need add class - "four-quarters"
                style={{
                  maxWidth: circleMaxWidth + 'px',
                }}
              >
                {halfHalfData.halfHalfs?.map((item, i) => (
                  <HalfItemSection
                    key={i}
                    item={item}
                    onClick={(event) => onSectionClick(event, item)}
                    parentPage={parentPage}
                    toggleItemEditMode={toggleItemEditMode}
                    isInModifyMode={isInModifyMode}
                  />
                ))}
              </div>
              <div ref={sizesRef} className="half-half-sizes-box">
                {uniqueSizesForDisplayFilteredWithVariants &&
                uniqueSizesForDisplayFilteredWithVariants.length > 1 ? (
                  <h3 className="half-half-sizes-title">Select a Size</h3>
                ) : null}
                <div className="half-half-sizes-wrap">
                  {uniqueSizesForDisplayFilteredWithVariants
                    ?.filter(
                      (item) => sizeAndModifierFilteredItemsStatus[item.pairId]
                    )
                    .map((item, i) => {
                      return (
                        <div className="half-half-sizes-btn-wrap" key={i}>
                          <Button
                            title={item.name}
                            className={clsx('btn round grey', {
                              inverse: !item.isDefault,
                            })}
                            onClick={() => onSizeClick(item, variantSelected)}
                          >
                            {item.name}
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </ScrollableBoxUI>
          {areAllHalfFilled && (
            <div className="main-half-area-btn-box">
              <Button
                title="Add to Order"
                className="btn"
                onClick={onSubmit}
                enableThemeColor
                style={{
                  borderRadius: 8,
                }}
              >
                {submitLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HalfHalfView;
