import { useSelector } from "react-redux";

function ModalOverlay() {
  const blurEffect = useSelector(s => s.dashboardReducer.blurEffect);

  return (
    <>
      {blurEffect && <div id="modal-blur-overlay"/>}
    </>
  );
}

export default ModalOverlay;
