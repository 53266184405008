import { ITEM_CUSTOMIZATION_OPTIONS } from 'constants/order-flow';
import useCurrentlyInCustomiseSection from 'customHooks/useCurrentlyInCustomiseSection';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ItemCustomizationOption } from './ItemCustomizationOption';

const showCustomizationOption = (option, item) => {
  if (
    option.id === 1 &&
    item &&
    item.selectedExtraIngredients &&
    item.selectedExtraIngredients.length > 0
  )
    return true;
  else if (
    option.id === 2 &&
    item &&
    item.selectedIngredients &&
    item.selectedIngredients.length > 0
  )
    return true;
  else return false;
};

const getQuantityForOption = (option, item) => {
  let quantity = 0;
  if (
    option.id === 1 &&
    item &&
    item.selectedExtraIngredients &&
    item.selectedExtraIngredients.length > 0
  ) {
    quantity = item.selectedExtraIngredients.filter(
      (f) => f.quantity > 0
    ).length;
    return quantity;
  } else if (
    option.id === 2 &&
    item &&
    item.selectedIngredients &&
    item.selectedIngredients.length > 0
  ) {
    quantity = item.selectedIngredients.filter(
      (f) => f.isRemoved === true
    ).length;
    return quantity;
  } else return quantity;
};

export const ItemCustomizationOptions = ({ basePath }) => {
  const history = useHistory();
  const currentItem = useSelector((s) => s.dashboardReducer.currentMenuItem);
  const currentlyInCustomisationSection = useCurrentlyInCustomiseSection();

  React.useEffect(() => {
    if (!currentlyInCustomisationSection) {
      const options =
        ITEM_CUSTOMIZATION_OPTIONS?.filter(
          (opt) => !!showCustomizationOption(opt, currentItem)
        ) || [];

      if (options.length && currentItem._id) {
        history.push(
          `${basePath}/menuitem/${currentItem._id}/customization/ingredient/${options[0].slug}`
        );
      }
    }
  }, [history, basePath, currentItem, currentlyInCustomisationSection]);
  return (
    <div className="category-nav-wrap">
      <nav
        defaultactivekey={`${basePath}/menuitem/${currentItem._id}/customization/ingredient/add`}
        className="category-nav top"
      >
        {ITEM_CUSTOMIZATION_OPTIONS.filter((item) =>
          showCustomizationOption(item, currentItem)
        ).map((item) => {
          return (
            <ItemCustomizationOption
              key={item.id}
              path={`${basePath}/menuitem/${currentItem._id}/customization/ingredient`}
              {...item}
              quantity={getQuantityForOption(item, currentItem)}
            />
          );
        })}
      </nav>
    </div>
  );
};
