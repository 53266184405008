import React, { useRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { AVAILABLE_SHAPES } from './constants';

import { DragShape } from 'components/DragShape';
import FloorLayoutTabs from 'components/FloorLayoutTabs';

export const FloorLayout = ({
  editMode,
  selectedShape,
  layoutLoading,
  objectsToRender,
  handleShapeSelect,
  handleDragEnd,
  handleClearSelectedShape,
  floorsForSwitchTabs,
  selectedFloorId,
  handleFloorTabSelection,
  handleTabSelectionInEditMode,
  handleTabDoubleSelectionInEditMode,
  handleMove,
  handleReorder,
  handleDeleteFloorLayoutClick,
}) => {
  const constraintsRef = useRef(null);

  const movingOrder = useSelector(state => state.tableArrangementReducer.movingOrder);

  const handleDragAreaBlur = () => {
    if (!movingOrder) {
      handleClearSelectedShape();
    }
  };

  const isSelected = useCallback(
    (item) => (selectedShape && item ? selectedShape.id === item.id : false),
    [selectedShape]
  );

  const dragAreaCss = `drag-area drag-area--${editMode ? 'edit' : 'view'}-mode`;

  const dragShapesList = useMemo(
    () =>
      objectsToRender?.length > 0 &&
      objectsToRender.map((item, i) => {
        return AVAILABLE_SHAPES.includes(item.type) ? (
          <DragShape
            key={`${item.type}_${i}_${item.id}`}
            item={item}
            editMode={editMode}
            isSelected={isSelected(item)}
            constraintsRef={constraintsRef}
            handleShapeSelect={handleShapeSelect}
            handleMove={handleMove}
            handleDragEnd={handleDragEnd}
            selectedFloorId={selectedFloorId}
            layoutLoading={layoutLoading}
          />
        ) : null;
      }),
      // eslint-disable-next-line
    [
      editMode,
      handleDragEnd,
      handleShapeSelect,
      layoutLoading,
      constraintsRef,
      isSelected,
      objectsToRender,
    ]
  );

  return (
    <>
      <motion.div
        className={dragAreaCss}
        ref={constraintsRef}
        onTap={handleDragAreaBlur}
      >
        <FloorLayoutTabs
          editMode={editMode}
          floorsList={floorsForSwitchTabs}
          selectedFloorId={selectedFloorId}
          handleFloorTabSelection={handleFloorTabSelection}
          handleTabSelectionInEditMode={handleTabSelectionInEditMode}
          handleTabDoubleSelectionInEditMode={
            handleTabDoubleSelectionInEditMode
          }
          handleReorder={handleReorder}
          handleDeleteFloorLayout={handleDeleteFloorLayoutClick}
        />
      </motion.div>
      <div>{dragShapesList}</div>
    </>
  );
};
