import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import getSlideIndexByID from './utils';

import './bem-modals-carousel.scss';

export const ModalsCarouselUI = ({
  show,
  modalPositionData,
  currentSlide,
  setNextSlide,
  enableArrow,
  children,
  handleClose,
}) => {
  const prevSlide = useRef('');
  const arrowTopRef = useRef();
  const arrowBottomRef = useRef();
  const carouselRef = useRef();
  const carouselContainerRef = useRef();
  const carouselInnerRef = useRef();
  const carouselItemsData = useRef([]);

  // init calculate carousel sizes, and set first slide as active
  useEffect(() => {
    if (
      show &&
      carouselRef.current &&
      carouselContainerRef.current &&
      carouselInnerRef.current
    ) {
      let slidesData = [];
      const { rightPos, topTrigger, halfBthHeight, statusBarOffset, offset } =
        modalPositionData;
      const topPos = topTrigger + halfBthHeight - statusBarOffset;
      const windowHeight = window.innerHeight;
      const slides = carouselInnerRef.current.children;

      if (slides?.length > 0) {
        slides.forEach((item, index) => {
          let translateY;
          const el = item.children[0];
          const slideHeight = el.offsetHeight;

          const modalTopStatus = topPos - slideHeight / 2 < offset;
          const modalBottomStatus =
            topPos + slideHeight / 2 + statusBarOffset > windowHeight - offset;

          if (modalTopStatus) {
            translateY = topPos - statusBarOffset - offset;
          } else if (modalBottomStatus) {
            translateY =
              slideHeight - (windowHeight - topPos - statusBarOffset - offset);
          } else {
            translateY = slideHeight / 2;
          }

          const data = {
            id: el.id,
            containerStyle: {
              height: slideHeight,
              transform: `translateY(-${translateY}px)`,
              transformOrigin: `0 ${translateY}px`,
            },
          };

          //set init styles for slides
          if (index === 0) {
            item.style.cssText = `
            z-index: 105;
            transform: translateX(0);
          `;
          } else {
            item.style.cssText = `
            z-index: 104;
            transform: translateX(100%);
          `;
          }

          slidesData.push(data);
        });

        // set init style for - bem-modals-carousel
        carouselRef.current.style.cssText = `
        right: ${rightPos}px;
      `;

        // set init style for - bem-modals-carousel__container
        carouselContainerRef.current.style.cssText = `
        top: ${topPos}px;
        transform: ${slidesData[0].containerStyle.transform};
        transformOrigin: ${slidesData[0].containerStyle.transformOrigin};
      `;

        // set init style for - bem-modals-carousel__inner
        carouselInnerRef.current.style.cssText = `
        height: ${slidesData[0].containerStyle.height}px
      `;

        // set arrow position
        const arrowStyle = `
        top: ${topPos}px;
        right: ${rightPos - 10}px;
      `;
        arrowTopRef.current.style.cssText = arrowStyle;
        arrowBottomRef.current.style.cssText = arrowStyle;

        carouselItemsData.current = slidesData;

        // set active first slide
        setNextSlide(carouselItemsData?.current[0]?.id);
      }
    }
  }, [show, carouselInnerRef, modalPositionData, setNextSlide]);

  // logic for changing slides
  useEffect(() => {
    let containerEl = carouselContainerRef.current;
    let containerInnerEl = carouselInnerRef.current;

    if (currentSlide && containerInnerEl && prevSlide.current) {
      const oldIndex = prevSlide.current
        ? getSlideIndexByID(prevSlide.current, carouselItemsData.current)
        : 0;
      const newIndex = prevSlide.current
        ? getSlideIndexByID(currentSlide, carouselItemsData.current)
        : 0;

      if (oldIndex !== newIndex) {
        let innerEl = containerInnerEl;
        let newEl = containerInnerEl.children[newIndex];
        let oldEl = containerInnerEl.children[oldIndex];
        const styles = carouselItemsData.current[newIndex].containerStyle;

        // prevent wrong slide direction
        if (Math.abs(oldIndex - newIndex) > 1) {
          containerInnerEl.children.forEach((item, index) => {
            if (
              oldIndex !== index &&
              newIndex !== index &&
              /-/gi.test(item.style.transform)
            ) {
              containerInnerEl.children[index].style.cssText = `
                z-index: 104;
                opacity: 0;
                transform: translateX(100%);
              `;
            }
          });
        }

        // move oldSlide
        if (oldIndex === 0) {
          oldEl.style.cssText = `
            z-index: 104;
            transform: translateX(-100%);
          `;
        } else if (oldIndex > 0 && newIndex > 0) {
          if (oldIndex < newIndex) {
            oldEl.style.cssText = `
            z-index: 104;
            transform: translateX(-100%);
          `;
          } else {
            oldEl.style.cssText = `
            z-index: 104;
            transform: translateX(100%);
          `;
          }
        } else {
          oldEl.style.cssText = `
            z-index: 104;
            transform: translateX(100%);
          `;
        }

        // move newSlide
        newEl.style.cssText = `
            z-index: 105;
            transform: translateX(0);`;

        // update style for - bem-modals-carousel__container (based on newSlide)
        containerEl.style.transform = `${styles.transform}`;
        containerEl.style.transformOrigin = `${styles.transformOrigin}`;

        // update style for - bem-modals-carousel__inner  (based on newSlide)
        innerEl.style.height = `${styles.height}px`;
      }
    }

    prevSlide.current = currentSlide;
  }, [currentSlide]);

  const carouselCss = clsx('bem-modals-carousel', {
    'bem-modals-carousel--show': show,
  });

  return (
    <>
      <div
        className={carouselCss}
        ref={carouselRef}
        onClick={(e) => {
          if (e.target.classList.value.includes('bem-modals-carousel')) {
            handleClose();
          }
        }}
      >
        <div
          className="bem-modals-carousel__container"
          ref={carouselContainerRef}
        >
          <div className="bem-modals-carousel__inner" ref={carouselInnerRef}>
            {children}
          </div>
        </div>
      </div>

      {enableArrow && (
        <>
          <div ref={arrowTopRef} className="bem-modals-carousel__arrow-top" />
          <div
            ref={arrowBottomRef}
            className="bem-modals-carousel__arrow-bottom"
          />
        </>
      )}
    </>
  );
};

export const ModalsCarouselSlideUI = ({ children, ...rest }) => {
  return (
    <div className="bem-modals-carousel__slide" {...rest}>
      {children}
    </div>
  );
};
