import React from 'react';
import { ChevronLeftIcon as IconChevronLeft } from 'nexticons/outline';
import { NavigationButtonUI } from './index';
import PropTypes from 'prop-types';

export const LogoNavButtonUI = ({ onClick, style }) => {
  return (
    <NavigationButtonUI
      title="Next Order"
      icon={<IconChevronLeft style={{ left: -1 }} width="18" strokeWidth="4" />}
      logo={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1641.3 254.4"
          fill="currentColor"
        >
          <title>Next Order</title>
          <polygon points="152.6 187.4 53 15.3 0 15.3 0 251.3 39.5 251.3 39.5 68.5 146.6 251.3 192.1 251.3 192.1 15.3 152.6 15.3 152.6 187.4" />
          <path d="M310.4,74.9c-52,0-86.4,41.5-86.4,89.9,0,52.4,30.7,89.6,85.4,89.6,38.2,0,74.1-14.8,80-55.9H352.9c-7.2,22.3-21.3,26.3-43.5,26.3-32.1,0-44.9-23.7-44.9-50.4H390.8C397.2,123.3,365.4,74.9,310.4,74.9Zm-44.9,72.8c1-25.4,19.2-43.1,45.9-43.1,26,0,39.5,19.4,40.9,43.1Z" />
          <path d="M1435.8,74.9c-52,0-86.4,41.5-86.4,89.9,0,52.4,30.7,89.6,85.4,89.6,38.2,0,74.1-14.8,80-55.9h-36.5c-7.2,22.3-21.3,26.3-43.5,26.3-32.1,0-44.9-23.7-44.9-50.4h126.3C1522.6,123.3,1490.9,74.9,1435.8,74.9Zm-44.9,72.8c1-25.4,19.2-43.1,45.9-43.1,26,0,39.5,19.4,40.9,43.1Z" />
          <polygon points="559.3 78.6 518.8 78.6 482.9 135.2 445.7 78.6 401 78.6 457.2 163 397.4 250.7 437.7 250.7 479.5 189.1 519.8 250.7 565.1 250.7 503.2 161 559.3 78.6" />
          <path d="M634.4,191.1V107.5h38.4V79H634.4V41H594.2V79H571.8v28.5h22.4v93.3c.7,28.8,12.5,53.3,54.5,53.3a81.87,81.87,0,0,0,25.1-4V219.6c-5.6,1.3-10.3,3.2-15.9,3.3C636.8,223.4,634.4,206.9,634.4,191.1Z" />
          <path d="M886,12.7c-72.6,0-119,54.8-119,120.5s46.4,120.5,119,120.5,118-54.8,118-120.5S958.6,12.7,886,12.7Zm0,207.2c-51.9,0-73.6-42.5-73.6-86.6S834.1,46.7,886,46.7s73.6,42.5,73.6,86.6S937.9,219.9,886,219.9Z" />
          <path d="M1118,78.2c-26.2,4.7-38.7,30-41.3,34.2h-.7V78.9h-37.8V251.3h38.2V169.8c0-33.6,6.7-46.8,30.5-53.2,8.8-2.4,20.6-4.2,26.2-3.5V77.2C1129.7,76.9,1127.1,76.6,1118,78.2Z" />
          <path d="M1626.2,78.2c-26.2,4.7-38.7,30-41.3,34.2h-.7V78.9h-37.8V251.3h38.2V169.8c0-33.6,6.7-46.8,30.5-53.2,8.8-2.4,20.6-4.2,26.2-3.5V77.2C1637.8,76.9,1635.2,76.6,1626.2,78.2Z" />
          <path d="M1280.1,104.6h-.7c-12.4-19.1-31.8-29.2-54.7-29.2-35,0-77.1,25.5-77.1,88.2,0,52,24.7,90.6,73.4,90.6,22.9,0,47.9-12.4,58.5-32.2h.7v29.5h39.1V0h-39.2V104.6ZM1235.3,224c-34.9,0-48.5-27.5-48.5-57.4,0-31.2,12.4-61,50.2-61,25,0,44.5,20.8,44.5,59C1281.5,194.8,1266.2,224,1235.3,224Z" />
        </svg>
      }
      onClick={onClick}
      style={style}
    />
  );
};

LogoNavButtonUI.defaultProps = {
  onClick: () => {},
  style: {},
};

LogoNavButtonUI.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
};
