import { Map as MapGL, Marker } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import { useEffect, useRef } from 'react';
import React from 'react';

const MAPTILER_KEY = 'Kaj7hj4spq6OTB0wtwjG';

const MAPTILER_CUSTOM_STYLE =
  'https://api.maptiler.com/maps/c009d368-07c5-496e-8047-e69432e7af2b/style.json';

interface MarkerOptionsType {
  height: number;
  width: number;
}
interface MapTileType {
  lat: number;
  long: number;
  zoom?: number;
  customMarker?: string;
  markerOptions?: MarkerOptionsType;
  [x: string]: any;
}
const MapTiler: React.FunctionComponent<MapTileType> = ({
  lat,
  long,
  zoom = 17,
  customMarker,
  markerOptions = { height: 32.5, width: 30 },
  ...restProps
}) => {
  const mapRef = useRef<any>(null);
  useEffect(() => {
    if (lat && long) {
      mapRef.current?.flyTo({ center: [long, lat], duration: 1000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, long]);
  if (!lat || !long) {
    return <></>;
  }
  return (
    <MapGL
      ref={mapRef}
      mapLib={maplibregl}
      initialViewState={{
        latitude: lat,
        longitude: long,
        zoom,
      }}
      onIdle={(e) => e.target?.resize()}
      mapStyle={`${MAPTILER_CUSTOM_STYLE}?key=${MAPTILER_KEY}`}
      {...restProps}
    >
      <Marker
        latitude={lat}
        longitude={long}
        anchor={customMarker ? 'bottom' : 'center'}
      >
        {customMarker && (
          <img
            src={customMarker}
            height={markerOptions?.height || 35.25}
            width={markerOptions?.width || 30}
            alt="map-pin"
          />
        )}
      </Marker>
    </MapGL>
  );
};
export default MapTiler;
