import { useState, useEffect } from 'react';

import IconMinus from 'assets/icons/IconMinus';
import IconPlus from 'assets/icons/IconPlus';

export const TableSeatsControl = ({
  title,
  paramName,
  paramValue,
  min,
  max,
  className,
  handleTableValueChange,
}) => {
  const [value, setValue] = useState(paramValue);
  const handleChange = (newValue) => {
    setValue(newValue);
    handleTableValueChange(paramName, newValue);
  };

  useEffect(() => {
    setValue(paramValue);
  }, [paramValue]);

  return (
    <div className={className + ' table-config-control'}>
      {title && <h2 className="table-config-control__title">{title}</h2>}

      <div className="table-config-control__counter">
        <button
          className="table-config-control__button"
          onClick={() =>
            handleChange(Number(value) > min ? Number(value) - 1 : 0)
          }
        >
          <IconMinus />
        </button>
        <div className="table-config-control__count">{value}</div>
        <button
          className="table-config-control__button"
          onClick={() =>
            handleChange(Number(value) < max ? Number(value) + 1 : max)
          }
        >
          <IconPlus />
        </button>
      </div>
    </div>
  );
};
