import {
    suburbsSelector,
    useRadiusBasedDeliveryAreaCalulationSelector
} from 'pages/Dashboard/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useMinOrderValue({currentOrder, orderSetup}) {
  const useRadiusBasedDeliveryAreaCalulation = useSelector(
    useRadiusBasedDeliveryAreaCalulationSelector
  );
  const suburbs = useSelector(suburbsSelector);
  const selectedSuburbs = useMemo(
    () => suburbs?.find((suburb) => suburb._id === currentOrder?.suburbId),
    [currentOrder?.suburbId, suburbs]
  );

  let minOrderValue = 0;
  if (useRadiusBasedDeliveryAreaCalulation) {
    if (currentOrder.isManualAddress) {
      minOrderValue = orderSetup.minDeliveryOrderValue ?? 0;
    } else {
      minOrderValue = currentOrder.minOrderValue ?? 0;
    }
  } else {
    minOrderValue = Number(selectedSuburbs?.minOrderValue ?? 0);
  }


  return minOrderValue;
}
