import { useEffect } from 'react';
import useModal from 'hooks/useModal';
import { useSelector } from 'react-redux';
import CustomerPhoneEffect from 'pages/Pickup/CustomerPhoneEffect';

function DeliveryAddressEffect({ addressConfirmed }) {
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const [addressModalOpen, { openModal }] = useModal('addressModal');
  const [customerPhoneModalOpen] = useModal('customerPhoneModal');

  useEffect(() => {
    if (!currentOrder?._id) {
      if (
        !addressConfirmed &&
        !addressModalOpen &&
        !customerPhoneModalOpen &&
        currentOrder?.mobileNumber &&
        currentOrder?.mobileNumber !== 'xxx-xxx-xxx' &&
        currentOrder?.firstName
      ) {
        openModal();
      }
    }
  }, [
    currentOrder?._id,
    addressModalOpen,
    addressConfirmed,
    openModal,
    currentOrder?.mobileNumber,
    customerPhoneModalOpen,
    currentOrder?.firstName,
  ]);

  return <CustomerPhoneEffect />;
}

export default DeliveryAddressEffect;
