import axios from 'axios';
import { API_URL } from 'configuration/apiUrl';

export const updateNumOfGuests = async (numOfGuests, staffId, occupancyId) => {
  const response = await axios.patch(
    `${API_URL}/stores/v1/dineInOccupancies/${occupancyId}`,
    {
      numPeople: numOfGuests,
      staffId: staffId,
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.status === 200 || response.status === 201) {
    return { ...response.data, success: true };
  } else {
    return { success: false };
  }
};
