import clsx from 'clsx';
export const DineInOptionsItem = (props) => {
  const {
    handleClick,
    label,
    icon,
    children,
    isDisabled = false,
    syncText,
  } = props;
  return (
    <li className="side-panel__nav-item">
      <span
        className={clsx('single-nav-item', {
          ['single-nav-item-disabled']: isDisabled,
        })}
        onClick={handleClick}
        style={isDisabled ? { pointerEvents: 'none' } : {}}
      >
        <span className="single-nav-item__name">{label}</span>
        {syncText && <span className="single-nav-item__sync">{syncText}</span>}
        {children}
        <span className="single-nav-item__icon">{icon}</span>
      </span>
    </li>
  );
};
