import React, { memo } from 'react';
import { PlusRoundedThickIcon as IconPlusRoundedThick } from 'nexticons/outline';
import { ButtonGroupUI, ButtonUI } from '../../UI/components';

export const FiltersNav = memo(() => {
  return (
    <ButtonGroupUI gap="xl">
      <ButtonUI
        modifierClassName="uppercase"
        styleType="secondary"
        sizeType="s"
        fontSize="s"
        icon={<IconPlusRoundedThick strokeWidth={4} width={14} />}
        iconPosition="left"
      >
        Gluten free
      </ButtonUI>
      <ButtonUI
        modifierClassName="uppercase"
        styleType="secondary"
        sizeType="s"
        fontSize="s"
        icon={<IconPlusRoundedThick strokeWidth={4} width={14} />}
        iconPosition="left"
      >
        Vegan
      </ButtonUI>

      <ButtonUI
        modifierClassName="uppercase"
        styleType="secondary"
        sizeType="s"
        fontSize="s"
        icon={<IconPlusRoundedThick strokeWidth={4} width={14} />}
        iconPosition="left"
      >
        Vegetarian
      </ButtonUI>
    </ButtonGroupUI>
  );
});
