import React from 'react';
import { InfoThickIcon as IconInfoThick } from 'nexticons/solid';
import { CloseButtonCircleUI } from '../../UI/components';

const ItemPriceIncrease = ({ onClose }) => {
  return (
    <div className="bem-manual-amount__inner-modal">
      <div className="bem-manual-amount__inner-modal-box">
        <div className="bem-manual-amount__inner-modal-header">
          <IconInfoThick
            width="34"
            color="var(--dangerColor)"
            style={{ marginLeft: -2, marginTop: -2 }}
          />
          <CloseButtonCircleUI onClick={onClose} />
        </div>
        <div className="bem-manual-amount__inner-modal-body">
          <h3 className="bem-manual-amount__inner-modal-title">Price increase is disabled</h3>
          <div className="bem-manual-amount__inner-modal-content">
            <p>
              To enable this feature please visit the <b>Control Centre</b> and enable{' '}
              <b>“Manual Price Increase”</b> under <b>“Store Settings”</b> or email{' '}
              <a
                href="mailto:support@nextorder.com"
                target="_blank"
                rel="noopener nofollow noreferrer"
                title="Support"
              >
                support@nextorder.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemPriceIncrease;
