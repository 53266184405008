import './bem-support-button.scss';

export const SupportButtonUI = ({
  icon,
  iconBgColor = 'var(--whiteColor)',
  title,
  onClick,
}) => {
  return (
    <button type="button" className="bem-support-button" onClick={onClick}>
      <span
        className="bem-support-button__box"
        style={{ backgroundColor: iconBgColor }}
      >
        {icon}
      </span>
      <span className="bem-support-button__text">{title}</span>
    </button>
  );
};
