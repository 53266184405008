import { OrderTypeIdEnum, paymentTypeEnum } from 'configuration/enums';

const listOfPaymentsWhereAmountShouldBeLessThanPayable = [
  paymentTypeEnum.CARD_IN_STORE,
  paymentTypeEnum.CUSTOM,
];

export const getCategoryList = ({
  categories,
  menuItems,
  activeSpecials,
  orderTypeId,
}) => {
  // Filter out categories with empty menu items using orderType and category ID
  let categoriesWithItems = categories?.filter((cat) =>
    menuItems?.some(
      (item) =>
        item.isPublished &&
        item.categoryId === cat._id &&
        item.orderTypeKeys?.some((otk) => otk === orderTypeId)
    )
  );

  const showOrderHistory =
    orderTypeId === OrderTypeIdEnum.PICK_UP ||
    orderTypeId === OrderTypeIdEnum.DELIVERY;

  if (showOrderHistory) {
    categoriesWithItems.unshift({
      _id: 'order_history',
      name: 'Order History',
      icon: 'IconReload',
    });
  }

  if (!activeSpecials?.length) return categoriesWithItems;

  return [
    ...categoriesWithItems.slice(0, showOrderHistory ? 2 : 1),
    {
      _id: 'specials',
      name: 'Specials',
    },
    ...categoriesWithItems.slice(showOrderHistory ? 2 : 1),
  ];
};

export const getSplitPaymentsCashAmountModalButtonStatus = (
  amount,
  payableAmount,
  paymentOptionSelected,
  hasOrderBeenCompleted
) => {
  if (!amount) return false;
  if (amount <= 0 || !paymentOptionSelected) return false;
  if (paymentOptionSelected.type === paymentTypeEnum.CASH) return true;
  if (
    listOfPaymentsWhereAmountShouldBeLessThanPayable.includes(
      paymentOptionSelected.type
    )
  ) {
    if (hasOrderBeenCompleted) return true;
    if (amount > Number(payableAmount.toFixed(2))) {
      return { error: 'Entered amount exceeds the payable cost of the order' };
    }
    if (amount <= Number(payableAmount.toFixed(2))) return true;
  }
  return false;
};
