import { nextOrderConfig } from '../configuration/nextOrderConfig';

export const settleDrawer = async (requestData) => {
  const {
    drawerId,
    cashAmount,
    staffId,
    reason = '',
    storeConfig,
  } = requestData;
  const response = await fetch(
    `${nextOrderConfig.cloudFunctionUrl}/settleDrawer`,
    {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        drawerId,
        cashAmount,
        timeZone: storeConfig.timeZone,
        staffId,
        reason,
      }),
    }
  );

  return response.json();
};