import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconPrinter = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-printer': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M16.34 8.42h-2.16v.72h2.16zm-.72 3.6H3.38v.72h1.44v5.04h9.36v-5.04h1.44zM4.82 5.54h9.36V1.22H4.82zm13.68.9v9a.9.9 0 0 1-.9.9h-2.7v1.8a.36.36 0 0 1-.36.36H4.46a.36.36 0 0 1-.36-.36v-1.8H1.4a.9.9 0 0 1-.9-.9v-9a.9.9 0 0 1 .9-.9h2.7V.86A.36.36 0 0 1 4.46.5h10.08a.36.36 0 0 1 .36.36v4.68h2.7a.9.9 0 0 1 .9.9zm-5.76 7.02v.72H6.26v-.72zm0 2.16v.72H6.26v-.72z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconPrinter.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
