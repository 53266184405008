import React from 'react';
import PropTypes from 'prop-types';
import { CircleButtonUI } from '../index';
import { getUIModifierKeys } from '../modifiersMapping';
import UndoIcon from '../../../assets/icons/UndoIcon';

export const UndoButtonCircleUI = ({ onClick, buttonSize }) => {
  return (
    <CircleButtonUI
      onClick={onClick}
      buttonSize={buttonSize}
      styleType="success-light"
      icon={<UndoIcon />}
      title="Remove"
    />
  );
};

UndoButtonCircleUI.defaultProps = {
  onClick: () => {},
  buttonSize: 's',
};

UndoButtonCircleUI.propTypes = {
  onClick: PropTypes.func,
  buttonSize: PropTypes.oneOf(getUIModifierKeys('buttonSize')),
};
