import React from 'react';
import Tabs from '../Tabs';
import DeliveryBatchesCreated from './DeliveryBatchesCreated';
import DeliveryBatchesUnassigned from './DeliveryBatchesUnassigned';

export const DeliveryBatchesSidebar = ({
  selectedTabCode,
  setSelectedTabCode,
  allTabs,
}) => {
  const getTabContent = (tabCode) => {
    switch (tabCode) {
      case 'unassigned':
        return <DeliveryBatchesUnassigned />;
      case 'batches':
        return <DeliveryBatchesCreated />;
      default:
        return null;
    }
  };

  return (
    <div className="driver-sidebar">
      <div
        className={'nav-bar-header'}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div className="top-box">
          <h1 className="nav-bar-title">
            {allTabs.find((f) => f.uniqueCode === selectedTabCode)?.text}
          </h1>
        </div>

        <Tabs
          allTabs={allTabs}
          selectedTabCode={selectedTabCode}
          onChange={(tab) => setSelectedTabCode(tab.uniqueCode)}
          tabTextExtractor={(t) => t.text}
          uniqueTabIdExtractor={(t) => t.text}
          uniqueCodeExtractor={(t) => t.uniqueCode}
        />
      </div>

      {getTabContent(selectedTabCode)}
    </div>
  );
};
