import {
  activeSpecialsSelector,
  orderSetupSelector,
  productSetupSelector,
  publicHolidaysSelector,
  storeConfigSelector,
  storeIdSelector,
  suburbsSelector,
} from './selectors';
import getPaymentTypes from './apis/getPaymentTypes';
import { setPaymentTypes, updateCurrentOrder } from './action';
import orderEngine from 'orderEngine';

export function loadPaymentTypes(initialStoreId) {
  return async (dispatch, getState) => {
    const storeId = initialStoreId || storeIdSelector(getState());
    const paymentTypes = await getPaymentTypes({
      storeId,
    });
    if (paymentTypes) {
      dispatch(setPaymentTypes(paymentTypes));
    }
  };
}

export const updateCurrentOrderWithOrderEngine = (orderObject) => {
  return (dispatch, getState) => {
    const activeSpecials = activeSpecialsSelector(getState());
    const orderSetup = orderSetupSelector(getState());
    const productSetup = productSetupSelector(getState());
    const suburbs = suburbsSelector(getState());
    const publicHolidays = publicHolidaysSelector(getState());
    const storeConfig = storeConfigSelector(getState());

    const newCurrentOrder = orderEngine(
      orderObject,
      activeSpecials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );

    dispatch(updateCurrentOrder(newCurrentOrder));
  };
};
