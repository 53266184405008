import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import './bem-scrollable-box.scss';
import PropTypes from 'prop-types';
import { getUIClassesFromProps } from '../modifiersMapping';
import throttle from 'lodash/throttle';
import clsx from 'clsx';

export const ScrollableBoxUI = forwardRef(
  ({ modifierClassName, invisibleScrollbar, scrollShadow, children }, ref) => {
    const [shadow, setShadow] = useState(false);

    const onScroll = useCallback(
      (event) => {
        setShadow(!!event.target.scrollTop);
      },
      [shadow, setShadow]
    );

    const handleScroll = throttle((e) => onScroll(e), 350);

    useEffect(() => {
      const el = scrollShadow && ref?.current;

      if (el) el.addEventListener('scroll', handleScroll);

      return () => {
        if (el) el.removeEventListener('scroll', handleScroll);
      };
    }, [scrollShadow]);

    return (
      <div
        className={clsx(
          getUIClassesFromProps('bem-scrollable-box', {
            modifierClassName,
            invisibleScrollbar,
            shadow,
          })
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

ScrollableBoxUI.defaultProps = {
  modifierClassName: '',
  invisibleScrollbar: false,
  children: null,
  scrollShadow: false,
};

ScrollableBoxUI.propTypes = {
  modifierClassName: PropTypes.string,
  invisibleScrollbar: PropTypes.bool,
  children: PropTypes.node,
  scrollShadow: PropTypes.bool,
};
