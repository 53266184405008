import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { ModalPortal } from 'components';
import { useModal } from 'hooks';
import { useHistory } from 'react-router-dom';
import { PosIconCashRegisterInverse } from 'assets/icons/PosIconCashRegisterInverse';
import { PosIconReceiveMoney } from 'assets/icons/PosIconReceiveMoney';
import { PosIconClose } from 'assets/icons/PosIconClose';
import { PosIconMoney } from 'assets/icons/PosIconMoney';
import { setActiveDrawer } from 'pages/Cashier/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setGoToRouteAfterAuth } from 'pages/Dashboard/action';
import axios from 'axios';
import { UpSquareIcon } from 'nexticons/outline';

import {
  storeConfigSelector,
  orderSetupAllowNoSaleSelector,
  isAuthDisabledForPOSSelector,
  orderSetupUnpaidOrdersReminderSelector,
} from 'pages/Dashboard/selectors';

import classNames from 'classnames';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { PosIconTrash } from 'assets/icons/PosIconTrash';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import DeleteDrawerAlertModal from 'components/DeleteDrawerAlertModal';
import { authenticateStaff } from 'pages/Dashboard/action/';
import { hasRightPermission } from 'util/access-code-utils';
import StaffPinModal from 'components/StaffPinModal';
import AccessDeniedModal from 'modals/AccessDeniedModal';
import { getOrdersHistory, printBillCommand } from 'services/ordersServices';
import { useGlobalModalContext } from 'modals/GlobalModalContext/useGlobalModalContext';
import { cashierInitialState } from './cashierInitialState';
import cashierReducer from './cashierReducer';
import getColorByIndex from './getColorByIndex';
import { getOrdersFilteredByCurrent } from 'util/ordersHistoryUtils';
import { setOrderSideBarType } from 'pages/Orders/actions';
import { ScrollableBoxUI, ManualAmountUI } from '../../../UI/components';
import GeneralModal from 'modals/GeneralModal/index-new';
import { LoadingIcon } from 'nexticons/outline';
import { WithdrawalDetailModal } from './WithdrawalDetailModal';
import './cashierContent.css';

export const CashierContent = ({ drawers }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const storeConfig = useSelector(storeConfigSelector);
  const isAuthDisabledForPOS = useSelector(isAuthDisabledForPOSSelector);
  const allowNoSale = useSelector(orderSetupAllowNoSaleSelector);
  const unpaidOrdersReminder = useSelector(
    orderSetupUnpaidOrdersReminderSelector
  );

  const { storeId, currency } = storeConfig;

  const { handleGlobalModal } = useGlobalModalContext();

  const [clickStatus, setClickStatus] = useState('default');

  const [drawerData, setDrawerData] = useState();

  const [isDrawerDeleting, setIsDrawerDeleting] = useState(false);

  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false);

  const [isWithdrawalDetailModalOpen, setIsWithdrawalDetailModalOpen] =
    useState(false);

  const [manualValue, setManualValue] = useState(parseFloat(0));
  const [isApiLoading, setApiLoading] = useState(false);

  const [cashierState, cashierDispatch] = useReducer(
    cashierReducer,
    cashierInitialState
  );

  const openCashDrawerViaPrintAPI = useCallback(
    async (item) => {
      cashierDispatch({
        type: 'setDrawerIsOpening',
        payload: { _id: item._id },
      });
      const response = await printBillCommand({
        printerId: item?.printerId,
        restaurantId: storeConfig.restaurantId,
        storeId: storeConfig?.storeId,
        printReceipt: false,
        openCashDrawer: true,
      });
      if (response.success) {
        cashierDispatch({ type: 'setDrawerIsOpening', payload: false });
      }
    },
    [storeConfig]
  );

  const [_, { openModal: openPinModal, closeModal: closePinModal }] =
    useModal('staffPinModal');

  const handlePinModalClose = useCallback(() => {
    closePinModal();
    // dispatch(toggleBlurEffect(false));
    cashierDispatch({ type: 'setIsShowStaffPinModal', payload: false });
  }, [closePinModal, cashierDispatch]);

  const continueToNoSale = useCallback(
    (item) => {
      if (!isAuthDisabledForPOS) {
        handlePinModalClose();
      }

      openCashDrawerViaPrintAPI(item);
    },
    [openCashDrawerViaPrintAPI, isAuthDisabledForPOS, handlePinModalClose]
  );

  const continueToDrawer = useCallback(
    (path, item) => {
      if (!isAuthDisabledForPOS) {
        handlePinModalClose();
      } else {
        dispatch(authenticateStaff(true));
      }

      dispatch(setActiveDrawer(item));
      history.push(path);
    },
    [history, dispatch, isAuthDisabledForPOS, handlePinModalClose]
  );

  const checkUnpaidOrders = useCallback(
    (path, item) => {
      const goToOrdersPage = () => {
        handleGlobalModal(false);
        dispatch(setOrderSideBarType('unpaid')); // TODO: get rid of simple string
        history.push('/orders');
      };

      if (cashierState.unpaidOrders?.length > 0) {
        handlePinModalClose();

        const message = () => {
          return (
            <>
              There are{' '}
              <strong style={{ color: 'red' }}>
                {cashierState.unpaidOrders?.length} open order(s)
              </strong>{' '}
              that have not yet been paid.
              <br />
              This may cause a discrepancy in your reconcilation.
            </>
          );
        };

        handleGlobalModal({
          title: 'There are unpaid orders',
          message: message(),
          actionButtonLabel: 'Settle Orders',
          actionButtonHandler: goToOrdersPage,
          dismissButtonLabel: 'Continue',
          dismissButtonHandler: () => {
            continueToDrawer(path, item);
          },
        });
      } else {
        continueToDrawer(path, item);
      }
    },
    [
      handleGlobalModal,
      handlePinModalClose,
      history,
      continueToDrawer,
      cashierState.unpaidOrders,
      dispatch,
    ]
  );

  const handleDrawerStartClose = (item) => {
    setClickStatus('default');
    dispatch(setGoToRouteAfterAuth(`/money-count/${item._id}`));
    cashierDispatch({
      type: 'setGoToRouteAfterAuth',
      payload: `/money-count/${item._id}`,
    });
    cashierDispatch({ type: 'setCurrentItem', payload: item });

    if (!isAuthDisabledForPOS) {
      handlePinModalOpen(item);
    } else {
      continueToDrawer(`/money-count/${item._id}`, item);
    }
  };

  const handleClickNoSale = (item) => {
    setDrawerData(item);

    if (isAuthDisabledForPOS) {
      dispatch(setGoToRouteAfterAuth('/open-cash-drawer-via-print-api'));
      cashierDispatch({
        type: 'setGoToRouteAfterAuth',
        payload: '/open-cash-drawer-via-print-api',
      });
      cashierDispatch({ type: 'setCurrentItem', payload: item });

      if (allowNoSale) {
        cashierDispatch({ type: 'setIsShowStaffPinModal', payload: true });
      } else {
        continueToNoSale(item);
      }
    } else {
      setClickStatus('noSale');
      handlePinModalOpen(item);
    }
  };

  const handleDeleteIconClick = (drawerItem) => {
    if (drawerItem?._id) {
      setIsDrawerDeleting({ _id: drawerItem._id, name: drawerItem.name });
    }
  };

  const handleDrawerDelete = async () => {
    if (isDrawerDeleting?._id) {
      setIsDrawerDeleting({ ...isDrawerDeleting, loading: true });
      await axios.post(`${nextOrderConfig?.cloudFunctionUrl}/deleteDrawer`, {
        _id: isDrawerDeleting._id,
      });
      setIsDrawerDeleting(false);
    }
  };

  const handleCancelDrawerDelete = () => {
    setIsDrawerDeleting(false);
  };

  const handlePinComplete = useCallback(
    async (data) => {
      // make api call to authenticate the staff
      if (
        hasRightPermission(cashierState.goToRouteAfterAuth, data.permissions)
      ) {
        if (cashierState.goToRouteAfterAuth.startsWith('/money-count')) {
          dispatch(setGoToRouteAfterAuth(cashierState.goToRouteAfterAuth));
          // check current unpaid orders only when closing drawer
          checkUnpaidOrders(
            cashierState.goToRouteAfterAuth,
            cashierState.currentItem
          );
        } else {
          continueToNoSale(cashierState.currentItem);
        }
      } else {
        cashierDispatch({
          type: 'setErrorMessage',
          payload:
            'You do not have a proper permission to perform this action.',
        });
        cashierDispatch({ type: 'setShowAccessDenied', payload: true });
      }
    },
    [
      dispatch,
      cashierState.goToRouteAfterAuth,
      checkUnpaidOrders,
      cashierState.currentItem,
      continueToNoSale,
    ]
  );

  const handlePinModalOpen = (item) => {
    openPinModal();
    // fetch orders history only when closing drawer
    if (item?.status !== '0') {
      cashierDispatch({ type: 'setRunFetchOrders', payload: true });
    }

    cashierDispatch({ type: 'setIsShowStaffPinModal', payload: true });
  };

  const fetchOrdersHistory = useCallback(async () => {
    if (cashierState.runFetchOrders && storeId) {
      const ordersHistoryResponse = await getOrdersHistory({
        storeId,
        paymentType: 'unpaid',
      });

      const ordersHistory = ordersHistoryResponse?.data?.orders;
      const currentUnpaidOrders = getOrdersFilteredByCurrent(ordersHistory);

      cashierDispatch({
        type: 'setUnpaidOrders',
        payload: currentUnpaidOrders || [],
      });
    }
  }, [storeId, cashierState.runFetchOrders]);

  const handleWiggleEffect = (param) => {
    cashierDispatch({ type: 'setWiggleEffect', payload: param });
  };

  const handleAccessDeniedModalClose = () => {
    cashierDispatch({ type: 'setShowAccessDenied', payload: false });
  };

  const handleSubmitAmount = (amount) => {
    setIsPayoutModalOpen(false);
    setIsWithdrawalDetailModalOpen(true);
  };

  const handlePayoutClick = async (item) => {
    setDrawerData(item);
    if (isAuthDisabledForPOS) {
      setApiLoading(true);
      const response = await printBillCommand({
        printerId: item?.printerId,
        restaurantId: storeConfig.restaurantId,
        storeId: storeConfig?.storeId,
        printReceipt: false,
        openCashDrawer: true,
      });
      setApiLoading(false);
      setIsPayoutModalOpen(response.success);
    } else {
      setClickStatus('payout');
      handlePinModalOpen(item);
    }
  };

  const handlePinCompletePayout = async () => {
    setApiLoading(true);
    const response = await printBillCommand({
      printerId: drawerData?.printerId,
      restaurantId: storeConfig.restaurantId,
      storeId: storeConfig?.storeId,
      printReceipt: false,
      openCashDrawer: true,
    });
    setApiLoading(false);
    setIsPayoutModalOpen(response.success);
  };

  const handlePinCompleteNoSale = () => {
    dispatch(setGoToRouteAfterAuth('/open-cash-drawer-via-print-api'));
    cashierDispatch({
      type: 'setGoToRouteAfterAuth',
      payload: '/open-cash-drawer-via-print-api',
    });
    cashierDispatch({ type: 'setCurrentItem', payload: drawerData });

    if (allowNoSale) {
      cashierDispatch({ type: 'setIsShowStaffPinModal', payload: true });
    } else {
      continueToNoSale(drawerData);
    }
  };
  // TODO: move it to a hook
  useEffect(() => {
    if (unpaidOrdersReminder) {
      fetchOrdersHistory();
    }
  }, [fetchOrdersHistory, unpaidOrdersReminder]);

  const mapPinCompleteFunctions = {
    payout: handlePinCompletePayout,
    noSale: handlePinCompleteNoSale,
    default: handlePinComplete,
  };

  return (
    <>
      <ModalPortal>
        {cashierState.isShowStaffPinModal && (
          <StaffPinModal
            modalStatus={cashierState.isShowStaffPinModal}
            onModalClose={handlePinModalClose}
            onComplete={
              mapPinCompleteFunctions[clickStatus] ||
              mapPinCompleteFunctions.default
            }
            errorMessage={cashierState.errorMessage}
            setShowAccessDenied={handleAccessDeniedModalClose}
            setErrorMessage={(errorMessage) =>
              cashierDispatch({
                type: 'setErrorMessage',
                payload: errorMessage,
              })
            }
            wiggleEffect={cashierState.wiggleEffect}
            setWiggleEffect={handleWiggleEffect}
            isVerifyingPin={cashierState.isVerifyingPin}
          />
        )}
      </ModalPortal>

      {cashierState.accessDenied && (
        <AccessDeniedModal
          handleModalClose={handleAccessDeniedModalClose}
          message={cashierState.errorMessage}
        />
      )}

      <div className="cashier-main-wrap">
        <div className="cashier-main">
          <ScrollableBoxUI>
            <div className="cashier-cards-wrap">
              {drawers.length > 0
                ? drawers.map((item, i) => {
                    const cardCls = classNames({
                      'cashier-card': true,
                      [`${getColorByIndex(i)}`]: true,
                    });
                    return (
                      <div className="cashier-card-wrap" key={i}>
                        <div className={cardCls}>
                          <div className="cashier-card-header">
                            <PosIconCashRegisterInverse mainColor="#ffffff" />
                            {item.name ? (
                              <h3 className="cashier-card-title">
                                {item.name}
                              </h3>
                            ) : (
                              ''
                            )}
                            <Button
                              title="Delete Drawer"
                              className="cashier-card-header-btn"
                              onClick={() => handleDeleteIconClick(item)}
                            >
                              {isDrawerDeleting &&
                              isDrawerDeleting?.loading &&
                              isDrawerDeleting?._id === item._id ? (
                                <PosIconLoading
                                  mainColor="#ffffff"
                                  className="cursor-pointer"
                                />
                              ) : (
                                <PosIconTrash
                                  mainColor="#ffffff"
                                  className="cursor-pointer"
                                />
                              )}
                            </Button>
                          </div>
                          <div className="cashier-card-body">
                            <Button
                              title="No Sale"
                              className="btn cashier-card-btn"
                              onClick={() => handleClickNoSale(item)}
                            >
                              No sale
                              {cashierState.drawerIsOpening &&
                              cashierState.drawerIsOpening._id === item._id ? (
                                <PosIconLoading mainColor="#5eb602" />
                              ) : (
                                <PosIconClose mainColor="#494b4c" />
                              )}
                            </Button>
                            {item.status !== '0' && (
                              <Button
                                title="Pay Out"
                                className="payoutCard"
                                style={{ width: '100%' }}
                                onClick={() => handlePayoutClick(item)}
                              >
                                <div className="payoutItemContainer">
                                  <div className="payoutCardText">Pay Out</div>

                                  <div>
                                    {isApiLoading &&
                                    drawerData._id === item._id ? (
                                      <LoadingIcon width={26} />
                                    ) : (
                                      <UpSquareIcon width={26} />
                                    )}
                                  </div>
                                </div>
                              </Button>
                            )}
                            <Button
                              title={`${
                                item.status === '0'
                                  ? 'Start Drawer'
                                  : 'Close Drawer'
                              }`}
                              className="btn cashier-card-btn"
                              onClick={() => handleDrawerStartClose(item)}
                            >
                              {item.status === '0'
                                ? 'Start Drawer'
                                : 'Close Drawer'}
                              {item.status === '0' ? (
                                <PosIconReceiveMoney mainColor="#494b4c" />
                              ) : (
                                <PosIconMoney mainColor="#494b4c" />
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </ScrollableBoxUI>

          {isDrawerDeleting?._id && !isDrawerDeleting?.loading ? (
            <DeleteDrawerAlertModal
              modalStatus={isDrawerDeleting?._id ? true : false}
              toggleModal={handleCancelDrawerDelete}
              drawerName={isDrawerDeleting?.name}
              handleCancel={handleCancelDrawerDelete}
              handleProceed={handleDrawerDelete}
            />
          ) : null}
        </div>
      </div>

      {isPayoutModalOpen && (
        <ModalPortal>
          <GeneralModal
            modalStatus
            toggleModal={() => {
              setIsPayoutModalOpen(false);
              if (!isWithdrawalDetailModalOpen) {
                setManualValue(0);
              }
            }}
            position={'center'}
            // effect="fade"
            className="bem-add-custom-amount-modal"
            overlay={'default'}
            boxWidth="435px"
            // boxHeight="100%"
            // boxHeight="500px"
            boxHeight="673px"
            closeBtn="outside"
            isResponsiveHeight
          >
            <ManualAmountUI
              id="cashier-payout"
              title="Widthdrawal Amount"
              manualType="money"
              manualValue={manualValue || ''}
              setManualValue={(value) => setManualValue(value)}
              submitBtnLabel={`Confirm Withdrawal ${
                manualValue ? `- ${currency}${manualValue}` : ''
              }`}
              submitBtnStyleType="success"
              handleSubmit={() => handleSubmitAmount(manualValue)}
              currencySymbol={currency}
              disableApply={!manualValue}
            />
          </GeneralModal>
        </ModalPortal>
      )}

      {isWithdrawalDetailModalOpen && (
        <ModalPortal>
          <WithdrawalDetailModal
            show={isWithdrawalDetailModalOpen}
            toggleModal={() => {
              setIsWithdrawalDetailModalOpen(false);
              setManualValue(0);
            }}
            amount={manualValue}
            currencySymbol={currency}
            cashDrawerId={drawerData._id}
            onBackClick={() => {
              setIsWithdrawalDetailModalOpen(false);
              setIsPayoutModalOpen(true);
            }}
          />
        </ModalPortal>
      )}
    </>
  );
};

CashierContent.propTypes = {
  drawers: PropTypes.array,
};
