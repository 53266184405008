import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconTrash = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-trash': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 25.5 25.6"
      >
        <title>Trash</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M16.3,19.3h-1v-9h1V19.3z M10.3,10.3h-1v9h1V10.3z M25.3,4.3v1h-3V24c0,0.7-0.6,1.3-1.3,1.3H4.6
	c-0.7,0-1.3-0.6-1.3-1.3V5.3h-3v-1h8V1.5c0-0.7,0.6-1.3,1.3-1.3H16c0.7,0,1.3,0.6,1.3,1.3v2.8L25.3,4.3L25.3,4.3z M9.3,4.3h7V1.5
	c0-0.1-0.1-0.3-0.3-0.3H9.6c-0.1,0-0.3,0.1-0.3,0.3V4.3z M21.3,5.3h-17V24c0,0.1,0.1,0.3,0.3,0.3H21c0.1,0,0.3-0.1,0.3-0.3L21.3,5.3
	L21.3,5.3z"
        />
      </svg>
    </span>
  );
};

PosIconTrash.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
