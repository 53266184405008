import React, { useRef, memo, useEffect } from 'react';
import clsx from 'clsx';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

export const SearchMenuItems = ({
  searchString,
  showSearchBox,
  updateSearchString = () => {},
  toggleSearchBox,
}) => {
  // const { state, dispatch } = useContext(Context);
  const { toggleKeyboard, setInputName, onChangeInput, inputValueSelector } =
    useVirtualKeyboardContext();
  const inputRef = useRef(null);
  const cls = clsx({
    show: showSearchBox,
    'menu-search-box': true,
    'search-menu-items': true,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleClick = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const inputVal = event.target.value;
    onChangeInput('searchMenuItem', inputVal);

    // updateSearchString(inputVal);
  };

  const handleBlur = () => {
    toggleKeyboard(false);
  };

  const handleFocus = () => {
    setInputName('searchMenuItem');
    toggleKeyboard(true);
  };

  const virtualKeyboardSearchValue = inputValueSelector('searchMenuItem');

  useEffect(() => {
    if (
      virtualKeyboardSearchValue &&
      virtualKeyboardSearchValue !== searchString
    ) {
      updateSearchString(virtualKeyboardSearchValue);
    }
  }, [virtualKeyboardSearchValue, searchString, updateSearchString]);

  useEffect(() => {
    if (showSearchBox) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
  }, [showSearchBox]);

  return (
    <div className={cls}>
      <div className="search-box">
        <form
          className="search-form"
          action=""
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <input
            id="searchMenuItem"
            name="searchMenuItem"
            ref={inputRef}
            type="text"
            // name="s"
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Search..."
            className="input-style"
            onChange={handleChange}
            value={virtualKeyboardSearchValue}
            // value={searchString}
            onClick={handleClick}
          />
          <button type="submit" title="Search" />
          <button
            type="reset"
            title="Reset"
            onClick={() => {
              onChangeInput('searchMenuItem', '');
              updateSearchString('');
              toggleSearchBox();
            }}
          >
            <PosIconClose mainColor="#919191" />
          </button>
        </form>
        {/*      <button
          className="btn small hide-search-box"
          type="button"
          title="Hide"
          onClick={toggleSearchBox}
        >
          <span className="inner-btn-box">Hide</span>
        </button>*/}
      </div>
    </div>
  );
};
