import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import smoothscroll from 'smoothscroll-polyfill';
import Dashboard from './pages/Dashboard';

import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './index.scss';
import { GlobalModalProvider } from './modals/GlobalModalContext';
import AppErrorFallback from './pages/AppErrorFallback';
import { AuthProvider } from './pages/SignIn/useProvideAuth';
import { persistor, store } from './redux-store';
import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

if (process.env.NODE_ENV === 'production') {
  //Production
  Sentry.init({
    dsn: 'https://e578be089d0342a58aaecb6e5c2568f4@o217922.ingest.sentry.io/5653793',
    integrations: [new Integrations.BrowserTracing()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const fallbackComponent = <AppErrorFallback />;

smoothscroll.polyfill();
const App = () => {
  const {
    enableDineInSentryLogs,
    fetchMinifiedMenuItemSizesAndSubModifiers,
    firebasePersistenceLogging,
  } = useFlags();
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <BrowserRouter>
            {enableDineInSentryLogs && (
              <Helmet>
                <script type="text/javascript">
                  {`
            window.smartlook ||
            (function (d) {
              var o = (smartlook = function () {
                  o.api.push(arguments);
                }),
                h = d.getElementsByTagName('head')[0];
              var c = d.createElement('script');
              o.api = new Array();
              c.async = true;
              c.type = 'text/javascript';
              c.charset = 'utf-8';
              c.src = 'https://web-sdk.smartlook.com/recorder.js';
              h.appendChild(c);
            })(document);
          smartlook('init', '0ac5ec1221476b1c9501d751f7c0a4cdd994d9b6', {
            region: 'eu',
          });
          smartlook('record', { forms: true, numbers: true, emails: true})
            `}
                </script>
              </Helmet>
            )}
            <Sentry.ErrorBoundary fallback={fallbackComponent}>
              <AuthProvider>
                <GlobalModalProvider>
                  <Dashboard
                    fetchMinifiedMenuItemSizesAndSubModifiers={
                      fetchMinifiedMenuItemSizesAndSubModifiers
                    }
                    firebasePersistenceLogging={firebasePersistenceLogging}
                  />
                </GlobalModalProvider>
                <ToastContainer
                  position="top-center"
                  pauseOnHover
                  autoClose={5000}
                />
              </AuthProvider>
            </Sentry.ErrorBoundary>
          </BrowserRouter>
        </DndProvider>
      </PersistGate>
    </Provider>
  );
};

const renderApp = async () => {
  const storeId = JSON.parse(localStorage.getItem('storeConfig'))?.storeId;
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY,
    user: {
      key: storeId,
    },
  });

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root')
  );
};
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
