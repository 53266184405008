import { ShiftsActionsEnum } from './constants';

export function shiftsStateReducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case ShiftsActionsEnum.UPDATE_SHIFT: {
      return { ...state, openShifts: [payload.newShift, ...state.openShifts] };
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
}
