import Antipasto from './assets/img/food/full/pizza/Antipasto.png';
import Capriciosa from './assets/img/food/full/pizza/Capriciosa.png';
import CheeseBurger from './assets/img/food/full/pizza/Cheese Burger.png';
import ChickenAvocado from './assets/img/food/full/pizza/Chicken & Avocado.png';
import Greek from './assets/img/food/full/pizza/Greek.png';
import Mushroom from './assets/img/food/full/pizza/Mushroom.png';
import PotatoRosemary from './assets/img/food/full/pizza/Potato & Rosemary.png';
import Prawn from './assets/img/food/full/pizza/Prawn.png';
import Prosciutto from './assets/img/food/full/pizza/Prosciutto.png';
import Salmone from './assets/img/food/full/pizza/Salmone.png';
import SatayChicken from './assets/img/food/full/pizza/Satay Chicken.png';
import Seafood from './assets/img/food/full/pizza/Seafood.png';
import Vegetarian from './assets/img/food/full/pizza/Vegetarian.png';
import Verona from './assets/img/food/full/pizza/Verona.png';
import Zucchini from './assets/img/food/full/pizza/Zucchini.png';
const InitializeArg = {
  blurEffect: false,
  appLoaded: false,
  // disableAnimation: false,
  fullScreen: false,
  // isMobile: true,
  mobileMessageModal: false,
  newOrderItemId: false,
  newSpecialOrderItemId: false,
  newHalfHalfOrderItemId: false,
  orderBarChange: false,
  deleteOrderItem: {
    orderId: false,
    seatId: false,
  },
  categories: [
    {
      id: 1,
      slug: 'pizza',
      title: 'Pizza',
      imgSrc: './assets/img/food/size_230/pizza/pizza.png',
      status: true,
      hasHalfHalf: true,
      hasSpecials: true,
      hasOptions: true,
      availableOptions: [
        {
          optionName: 'Size',
          optionSlug: 'size',
          optionQuestion: 'What size would you like?',
          extraPriceMode: false,
          options: [
            { name: 'Small', slug: 'small', price: 12.9 },
            { name: 'Medium', slug: 'medium', price: 16.9 },
            { name: 'Large', slug: 'large', price: 24.9 },
          ],
        },
        {
          optionName: 'Base',
          optionSlug: 'base',
          optionQuestion: 'What base would you like?',
          extraPriceMode: true,
          options: [
            { name: 'Classic Crust', slug: 'classic_crust', price: 0 },
            { name: 'Deep pan', slug: 'deep_pan', price: 2.5 },
            { name: 'Cheesy crust', slug: 'cheesy_crust', price: 3.5 },
          ],
        },
      ],
    },
    {
      id: 8,
      slug: 'specials',
      title: 'Specials',
      imgSrc: './assets/img/food/size_230/pizza/pizza.png',
      status: false,
      hasOptions: false,
      availableOptions: [],
    },
    {
      id: 2,
      slug: 'pasta',
      title: 'Pasta',
      imgSrc: require('./assets/img/food/size_230/pasta/pasta.png'),
      status: false,
      hasHalfHalf: false,
      hasSpecials: false,
      hasOptions: true,
      availableOptions: [
        {
          optionName: 'Size',
          optionSlug: 'size',
          optionQuestion: 'What size would you like?',
          extraPriceMode: false,
          options: [
            { name: 'Entree', slug: 'entree', price: 15.9 },
            { name: 'Main', slug: 'main', price: 24.9 },
          ],
        },
        {
          optionName: 'Pasta Type',
          optionSlug: 'pasta_type',
          optionQuestion: 'What type of pasta would you like?',
          extraPriceMode: true,
          options: [
            { name: 'Spaghetti', slug: 'spaghetti', price: 0 },
            { name: 'Fettucine', slug: 'fettucine', price: 0 },
            { name: 'Pappardelle', slug: 'pappardelle', price: 0 },
            { name: 'Gnocchi', slug: 'gnocchi', price: 3.0 },
            { name: 'Beef Ravioli', slug: 'beef_ravioli', price: 3.0 },
            { name: 'Spinach Ravioli', slug: 'spinach_ravioli', price: 3.0 },
            { name: 'Pumpkin Ravioli', slug: 'pumpkin_ravioli', price: 3.0 },
          ],
        },
      ],
    },
    {
      id: 3,
      slug: 'sushi',
      title: 'Sushi',
      imgSrc: require('./assets/img/food/size_230/sushi/sushi.png'),
      status: false,
      hasHalfHalf: false,
      hasSpecials: false,
      hasOptions: true,
      availableOptions: [
        {
          optionName: 'Pieces',
          optionSlug: 'pieces',
          optionQuestion: 'How many pieces would you like?',
          extraPriceMode: true,
          options: [
            { name: 'Single', slug: 'single', price: 3.3 },
            { name: '3 pieces', slug: 'pieces_3', price: 7.9 },
            { name: '5 pieces', slug: 'pieces_5', price: 12.9 },
          ],
        },
      ],
    },
    {
      id: 4,
      slug: 'curries',
      title: 'Curries',
      imgSrc: require('./assets/img/food/size_230/curries/curries.png'),
      status: false,
      hasHalfHalf: false,
      hasSpecials: false,
      hasOptions: true,
      availableOptions: [
        {
          optionName: 'Size',
          optionSlug: 'size',
          optionQuestion: 'What spice level would you like?',
          extraPriceMode: false,
          options: [
            { name: 'Entree', slug: 'entree', price: 14.9 },
            { name: 'Main', slug: 'main', price: 21.9 },
          ],
        },
        {
          optionName: 'Spice Level',
          optionSlug: 'spice_level',
          optionQuestion: 'How spicy would you like?',
          extraPriceMode: true,
          options: [
            { name: 'Mild', slug: 'mild', price: 0 },
            { name: 'Medium', slug: 'medium', price: 0 },
            { name: 'Hot', slug: 'hot', price: 0 },
            { name: 'Extra hot', slug: 'extra_hot', price: 0 },
          ],
        },
      ],
    },
    {
      id: 5,
      slug: 'sides',
      title: 'Sides',
      imgSrc: require('./assets/img/food/size_230/sides/sides.png'),
      status: false,
      hasHalfHalf: false,
      hasSpecials: false,
      hasOptions: false,
      availableOptions: [],
    },
    {
      id: 6,
      slug: 'drinks',
      title: 'Drinks',
      imgSrc: require('./assets/img/food/size_230/drinks/drinks.png'),
      status: false,
      hasHalfHalf: false,
      hasSpecials: true,
      hasOptions: false,
      availableOptions: [],
    },
    {
      id: 7,
      slug: 'deserts',
      title: 'Dessert',
      imgSrc: require('./assets/img/food/size_230/deserts/deserts.png'),
      status: false,
      hasHalfHalf: false,
      hasSpecials: false,
      hasOptions: false,
      availableOptions: [],
    },
  ],
  items: [
    {
      id: 1,
      title: 'Antipasto',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 4,
      specialsMode: true,
      halfHalfMode: true,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Antipasto.png'),
      imgSrcSmall: Antipasto,
      customIngredients: [12, 2, 44, 56, 11, 111],
    },
    {
      id: 2,
      title: 'Capriciosa',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 3,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Capriciosa.png'),
      imgSrcSmall: Capriciosa,
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 3,
      title: 'Cheese Burger',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 5,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Cheese Burger.png'),
      imgSrcSmall: CheeseBurger,
      customIngredients: [187, 165, 52, 13],
    },
    {
      id: 4,
      title: 'Chicken & Avocado',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 4,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Chicken & Avocado.png'),
      imgSrcSmall: ChickenAvocado,
      customIngredients: [11, 22, 33, 44, 55, 66],
    },
    {
      id: 5,
      title: 'Greek',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 5,
      specialsMode: true,
      halfHalfMode: true,
      extraSpecialsPrice: 1.0,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Greek.png'),
      imgSrcSmall: Greek,
      customIngredients: [199, 122, 133, 144, 155, 166],
    },
    {
      id: 6,
      title: 'Mushroom',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 3,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Mushroom.png'),
      imgSrcSmall: Mushroom,
      customIngredients: [12, 2, 44, 56, 11, 111],
    },
    {
      id: 7,
      title: 'Potato & Rosemary',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 5,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Potato & Rosemary.png'),
      imgSrcSmall: PotatoRosemary,
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 8,
      title: 'Prawn',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 4,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Prawn.png'),
      imgSrcSmall: Prawn,
      customIngredients: [187, 165, 52, 13],
    },
    {
      id: 9,
      title: 'Prosciutto',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 2,
      specialsMode: true,
      halfHalfMode: true,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Prosciutto.png'),
      imgSrcSmall: Prosciutto,
      customIngredients: [11, 22, 33, 44, 55, 66],
    },
    {
      id: 10,
      title: 'Salmone',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      specialsMode: true,
      halfHalfMode: true,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Salmone.png'),
      imgSrcSmall: Salmone,
      customIngredients: [199, 122, 133, 144, 155, 166],
    },
    {
      id: 11,
      title: 'Satay Chicken',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 8,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Satay Chicken.png'),
      imgSrcSmall: SatayChicken,
      customIngredients: [12, 2, 44, 56, 11, 111],
    },
    {
      id: 12,
      title: 'Seafood',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 5,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Seafood.png'),
      imgSrcSmall: Seafood,
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 13,
      title: 'Vegetarian',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      halfHalfMode: true,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Vegetarian.png'),
      imgSrcSmall: Vegetarian,
      customIngredients: [187, 165, 52, 13],
    },
    {
      id: 14,
      title: 'Verona',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 3,
      specialsMode: true,
      halfHalfMode: true,
      itemCategory: 'pizza',
      imgSrcSmall: require('./assets/img/food/size_230/pizza/Verona.png'),
      imgSrc: Verona,
      customIngredients: [11, 22, 33, 44, 55, 66],
    },
    {
      id: 15,
      title: 'Zucchini',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 4,
      itemCategory: 'pizza',
      imgSrc: require('./assets/img/food/full/pizza/Zucchini.png'),
      imgSrcSmall: Zucchini,
      customIngredients: [199, 122, 133, 144, 155, 166],
    },
    {
      id: 16,
      title: 'Arabiata',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 5,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Arabiata.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Arabiata.png'),
      customIngredients: [12, 2, 44, 56, 11, 111],
    },
    {
      id: 17,
      title: 'Bolognese',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Bolognese.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Bolognese.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 18,
      title: 'Carbonara',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Carbonara.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Carbonara.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 19,
      title: 'Chicken & Brandy',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Chicken & Brandy.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Chicken & Brandy.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 20,
      title: 'Chicken Alfredo',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Chicken Alfredo.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Chicken Alfredo.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 21,
      title: 'Creamy Mushroom',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Creamy Mushroom.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Creamy Mushroom.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 22,
      title: 'Lasagne',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Lasagne.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Lasagne.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 23,
      title: 'Linguine Sopressa',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Linguine Sopressa.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Linguine Sopressa.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 24,
      title: 'Mushroom Risotto',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Mushroom Risotto.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Mushroom Risotto.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 25,
      title: 'Napolitana',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Napolitana.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Napolitana.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 26,
      title: 'Ortona',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Ortona.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Ortona.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 27,
      title: 'Pesto',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Pesto.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Pesto.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 28,
      title: 'Puttanesca',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Puttanesca.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Puttanesca.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 29,
      title: 'Roma Tomatoes',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Roma Tomatoes.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Roma Tomatoes.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 30,
      title: 'Seafood Risotto',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Seafood Risotto.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Seafood Risotto.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 31,
      title: 'Seafood',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Seafood.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Seafood.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 32,
      title: 'Smoked Salmon',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'pasta',
      imgSrc: require('./assets/img/food/full/pasta/Smoked Salmon.png'),
      imgSrcSmall: require('./assets/img/food/size_230/pasta/Smoked Salmon.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 33,
      title: 'Bento Box',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sushi',
      imgSrc: require('./assets/img/food/full/sushi/Bento Box.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sushi/Bento Box.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 34,
      title: 'Butterfish Nigiri',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sushi',
      imgSrc: require('./assets/img/food/full/sushi/Butterfish Nigiri.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sushi/Butterfish Nigiri.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 35,
      title: 'Miso Soup',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sushi',
      imgSrc: require('./assets/img/food/full/sushi/Miso Soup.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sushi/Miso Soup.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 36,
      title: 'Salmon Nigiri',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sushi',
      imgSrc: require('./assets/img/food/full/sushi/Salmon Nigiri.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sushi/Salmon Nigiri.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 37,
      title: 'Shrimp Nigiri',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sushi',
      imgSrc: require('./assets/img/food/full/sushi/Shrimp Nigiri.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sushi/Shrimp Nigiri.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 38,
      title: 'Tamago Nigiri',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sushi',
      imgSrc: require('./assets/img/food/full/sushi/Tamago Nigiri.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sushi/Tamago Nigiri.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 39,
      title: 'Tuna Nigiri',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sushi',
      imgSrc: require('./assets/img/food/full/sushi/Tuna Nigiri.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sushi/Tuna Nigiri.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 40,
      title: 'Butter Chicken',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Butter Chicken.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Butter Chicken.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 41,
      title: 'Dal',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Dal.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Dal.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 42,
      title: 'Green Beef Curry',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Green Beef Curry.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Green Beef Curry.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 43,
      title: 'Lamb Rogan Josh',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Lamb Rogan Josh.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Lamb Rogan Josh.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 44,
      title: 'Pad Thai',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Pad Thai.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Pad Thai.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 45,
      title: 'Palak Panner',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Palak Panner.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Palak Panner.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 46,
      title: 'Panang Beef Curry',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Panang Beef Curry.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Panang Beef Curry.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 47,
      title: 'Panner Curry',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Panner Curry.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Panner Curry.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 48,
      title: 'Red Chicken Curry',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Red Chicken Curry.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Red Chicken Curry.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 49,
      title: 'Tadka Dal',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Tadka Dal.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Tadka Dal.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 50,
      title: 'Tom Yum',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'curries',
      imgSrc: require('./assets/img/food/full/curries/Tom Yum.png'),
      imgSrcSmall: require('./assets/img/food/size_230/curries/Tom Yum.png'),
      customIngredients: [156, 123, 111, 34, 65],
    },
    {
      id: 51,
      title: 'Focaccia Anchovie & Capers',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Focaccia Anchovie & Capers.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Focaccia Anchovie & Capers.png'),
    },
    {
      id: 52,
      title: 'Focaccia Garlic & Herbs',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Focaccia Garlic & Herbs.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Focaccia Garlic & Herbs.png'),
    },
    {
      id: 53,
      title: 'Focaccia Mozarella & Sage',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Focaccia Mozarella & Sage.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Focaccia Mozarella & Sage.png'),
    },
    {
      id: 54,
      title: 'Focaccia Olive',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Focaccia Olive.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Focaccia Olive.png'),
    },
    {
      id: 55,
      title: 'Focaccia Tomato&Basil',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Focaccia Tomato&Basil.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Focaccia Tomato&Basil.png'),
    },
    {
      id: 56,
      title: 'Fries',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Fries.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Fries.png'),
    },
    {
      id: 57,
      title: 'Garlic bread',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Garlic bread.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Garlic bread.png'),
    },
    {
      id: 58,
      title: 'Greek Salad',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Greek Salad.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Greek Salad.png'),
    },
    {
      id: 59,
      title: 'Prawn Crackers',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Prawn Crackers.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Prawn Crackers.png'),
    },
    {
      id: 60,
      title: 'Rice',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'sides',
      imgSrc: require('./assets/img/food/full/sides/Rice.png'),
      imgSrcSmall: require('./assets/img/food/size_230/sides/Rice.png'),
    },
    {
      id: 61,
      title: 'Budweiser',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/Budweiser.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/Budweiser.png'),
    },
    {
      id: 62,
      title: 'Corona',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/Corona.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/Corona.png'),
    },
    {
      id: 63,
      title: 'Heineken',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/Heineken.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/Heineken.png'),
    },
    {
      id: 64,
      title: 'San Pellegrino 750ml',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/San Pellegrino 750ml.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/San Pellegrino 750ml.png'),
    },
    {
      id: 65,
      title: 'San Pellegrino Aranciata Rosso',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/San Pellegrino Aranciata Rosso.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/San Pellegrino Aranciata Rosso.png'),
    },
    {
      id: 66,
      title: 'San Pellegrino Chinotto',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/San Pellegrino Chinotto.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/San Pellegrino Chinotto.png'),
    },
    {
      id: 67,
      title: 'San Pellegrino Limonata',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/San Pellegrino Limonata.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/San Pellegrino Limonata.png'),
    },
    {
      id: 68,
      title: 'Schweppes Lemonade 375ml',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/Schweppes Lemonade 375ml.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/Schweppes Lemonade 375ml.png'),
    },
    {
      id: 69,
      title: 'Schweppes Raspberry 375ml',
      desc: '',
      price: 6,
      itemCategory: 'drinks',
      imgSrc: require('./assets/img/food/full/drinks/Schweppes Raspberry 375ml.png'),
      imgSrcSmall: require('./assets/img/food/size_230/drinks/Schweppes Raspberry 375ml.png'),
    },
    {
      id: 70,
      title: 'Chocolate Gelato',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'deserts',
      imgSrc: require('./assets/img/food/full/deserts/Chocolate Gelato.png'),
      imgSrcSmall: require('./assets/img/food/size_230/deserts/Chocolate Gelato.png'),
    },
    {
      id: 71,
      title: 'Mango Gelato',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'deserts',
      imgSrc: require('./assets/img/food/full/deserts/Mango Gelato.png'),
      imgSrcSmall: require('./assets/img/food/size_230/deserts/Mango Gelato.png'),
    },
    {
      id: 72,
      title: 'Mint Chocolate Gelato',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'deserts',
      imgSrc: require('./assets/img/food/full/deserts/Mint Chocolate Gelato.png'),
      imgSrcSmall: require('./assets/img/food/size_230/deserts/Mint Chocolate Gelato.png'),
    },
    {
      id: 73,
      title: 'Nutella Pizza with Banana',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'deserts',
      imgSrc: require('./assets/img/food/full/deserts/Nutella Pizza with Banana.png'),
      imgSrcSmall: require('./assets/img/food/size_230/deserts/Nutella Pizza with Banana.png'),
    },
    {
      id: 74,
      title: 'Nutella Pizza with Strawberries',
      desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
      price: 6,
      itemCategory: 'deserts',
      imgSrc: require('./assets/img/food/full/deserts/Nutella Pizza with Strawberries.png'),
      imgSrcSmall: require('./assets/img/food/size_230/deserts/Nutella Pizza with Strawberries.png'),
    },
    {
      id: 75,
      title: 'Deal 1',
      desc: '1 Large Traditional Pizza, 1 Large Gourmet Pizza & 1.25L Drink',
      price: 21,
      itemCategory: 'specials',
      imgSrc: require('./assets/img/food/specials_1@2x.jpg'),
      imgSrcSmall: require('./assets/img/food/specials_1@2x.jpg'),
      itemDate: 'Thurs To Mon',
      partDate: 'Sunday to Thursday',
      partTitle: '4 Medium Traditional Pizza',
      partSubTitle: 'Traditional Pizza',
      partSize: 4,
    },
    {
      id: 76,
      title: 'Deal 2',
      desc: '2 Large Traditional Pizza, 1 Large Gourmet Pizza & 1.25L Drink',
      price: 27,
      itemCategory: 'specials',
      imgSrc: require('./assets/img/food/specials_2@2x.jpg'),
      imgSrcSmall: require('./assets/img/food/specials_2@2x.jpg'),
      itemDate: 'Tues 6-7PM',
      partDate: 'Sunday to Thursday',
      partTitle: '4 Medium Traditional Pizza',
      partSubTitle: 'Traditional Pizza',
      partSize: 4,
    },
    {
      id: 77,
      title: 'Deal 3',
      desc: '1 Large Traditional Pizza, 1 Large Gourmet Pizza & 1.25L Drink',
      price: 18,
      itemCategory: 'specials',
      imgSrc: require('./assets/img/food/specials_3@2x.jpg'),
      imgSrcSmall: require('./assets/img/food/specials_3@2x.jpg'),
      itemDate: 'Sun',
      partDate: 'Sunday to Thursday',
      partTitle: '4 Medium Traditional Pizza',
      partSubTitle: 'Traditional Pizza',
      partSize: 4,
    },
    {
      id: 78,
      title: 'Deal 4',
      desc: '3 Large Traditional Pizza, 1.25L Drink',
      price: 30,
      itemCategory: 'specials',
      imgSrc: require('./assets/img/food/specials_4@2x.jpg'),
      imgSrcSmall: require('./assets/img/food/specials_4@2x.jpg'),
      itemDate: 'Mon',
      partDate: 'Sunday to Thursday',
      partTitle: '4 Medium Traditional Pizza',
      partSubTitle: 'Traditional Pizza',
      partSize: 4,
    },
  ],
  seats: [
    {
      id: 1,
      seatName: 'Seat 1',
      color: 'red',
      classes: 'seats-id-1',
      activeSeat: true,
      orderItems: [1],
      splitItems: [1],
      activePay: false,
      payStatus: true,
      selectedArr: [],
      selectedSeat: false,
      dragStart: false,
      dragOrderId: false,
    },
    {
      id: 2,
      seatName: 'Seat 2',
      color: 'green',
      classes: 'seats-id-2',
      activeSeat: false,
      orderItems: [2, 3],
      splitItems: [2, 3, 4],
      activePay: false,
      payStatus: false,
      selectedArr: [],
      selectedSeat: false,
      dragStart: false,
      dragOrderId: false,
    },
    {
      id: 3,
      seatName: 'Seat 3',
      color: 'yellow',
      classes: 'seats-id-3',
      activeSeat: false,
      orderItems: [5],
      splitItems: [5, 6],
      activePay: true,
      payStatus: false,
      selectedArr: [],
      selectedSeat: false,
      dragStart: false,
      dragOrderId: false,
    },
    {
      id: 4,
      seatName: 'Seat 4',
      color: 'blue',
      classes: 'seats-id-4',
      activeSeat: false,
      orderItems: [4],
      splitItems: [7],
      activePay: false,
      payStatus: false,
      selectedArr: [],
      selectedSeat: false,
      dragStart: false,
      dragOrderId: false,
    },
  ],
  seatVariants: [
    { id: 1, seatName: 'Seat 1', color: 'red', class: 'seats-id-1' },
    { id: 2, seatName: 'Seat 2', color: 'green', class: 'seats-id-2' },
    { id: 3, seatName: 'Seat 3', color: 'yellow', class: 'seats-id-3' },
    { id: 4, seatName: 'Seat 4', color: 'blue', class: 'seats-id-4' },
    { id: 5, seatName: 'Seat 5', color: 'purple', class: 'seats-id-5' },
    { id: 6, seatName: 'Seat 6', color: 'blue-light', class: 'seats-id-6' },
    { id: 7, seatName: 'Seat 7', color: 'orange', class: 'seats-id-7' },
    { id: 8, seatName: 'Seat 8', color: 'golden', class: 'seats-id-8' },
    { id: 9, seatName: 'Seat 9', color: 'green-light', class: 'seats-id-9' },
    { id: 10, seatName: 'Seat 10', color: 'grey', class: 'seats-id-10' },
    { id: 11, seatName: 'Seat 11', color: 'pink', class: 'seats-id-11' },
    { id: 12, seatName: 'Seat 12', color: 'red', class: 'seats-id-12' },
    { id: 13, seatName: 'Seat 13', color: 'green', class: 'seats-id-13' },
    { id: 14, seatName: 'Seat 14', color: 'yellow', class: 'seats-id-14' },
    { id: 15, seatName: 'Seat 15', color: 'blue', class: 'seats-id-15' },
    { id: 16, seatName: 'Seat 16', color: 'purple', class: 'seats-id-16' },
    { id: 17, seatName: 'Seat 17', color: 'blue-light', class: 'seats-id-17' },
    { id: 18, seatName: 'Seat 18', color: 'orange', class: 'seats-id-18' },
    { id: 19, seatName: 'Seat 19', color: 'golden', class: 'seats-id-19' },
    { id: 20, seatName: 'Seat 20', color: 'green-light', class: 'seats-id-20' },
    { id: 21, seatName: 'Seat 21', color: 'grey', class: 'seats-id-21' },
    { id: 22, seatName: 'Seat 22', color: 'pink', class: 'seats-id-22' },
    { id: 23, seatName: 'Seat 23', color: 'red', class: 'seats-id-23' },
    { id: 24, seatName: 'Seat 24', color: 'green', class: 'seats-id-24' },
  ],
  splitItems: [
    {
      id: 1,
      orderId: 1,
      currentPart: 1,
      totalParts: 3,
      selected: false,
      seatId: 1,
      inTemp: false,
    },
    {
      id: 2,
      orderId: 2,
      currentPart: 1,
      totalParts: 1,
      selected: false,
      seatId: 2,
      inTemp: false,
    },
    {
      id: 3,
      orderId: 3,
      currentPart: 1,
      totalParts: 1,
      selected: false,
      seatId: 2,
      inTemp: false,
    },
    {
      id: 4,
      orderId: 1,
      currentPart: 1,
      totalParts: 3,
      selected: false,
      seatId: 2,
      inTemp: false,
    },
    {
      id: 5,
      orderId: 5,
      currentPart: 1,
      totalParts: 1,
      selected: false,
      seatId: 3,
      inTemp: false,
    },
    {
      id: 6,
      orderId: 1,
      currentPart: 1,
      totalParts: 3,
      selected: false,
      seatId: 3,
      inTemp: false,
    },
    {
      id: 7,
      orderId: 4,
      currentPart: 1,
      totalParts: 1,
      selected: false,
      seatId: 4,
      inTemp: false,
    },
  ],
  shareItemsBuffer: {
    seats: [],
    items: [],
    status: false,
  },
  tempMoveItems: [],
  dndStatus: false,
  dndSplitId: false,
  orders: [
    {
      id: 1,
      quantity: 2,
      // dividedBetweenSeats: [1, 2, 3],
      itemData: {
        id: 1,
        title: 'Antipasto',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 4,
        itemCategory: 'pizza',
        imgSrc: require('./assets/img/food/full/pizza/Antipasto.png'),
        imgSrcSmall: Antipasto,
        customIngredients: [126, 12, 2, 44, 56, 11, 111],
      },
      hasOptions: true,
      customOptions: {
        size: 'small',
        base: 'deep_pan',
      },
      ingredientStatus: {
        addIngredient: [21],
        removeIngredient: [126],
        lightIngredient: [12],
        // addIngredient: [21, 45, 77],
        // removeIngredient: [126, 12],
        // lightIngredient: [12, 111],
      },
    },
    {
      id: 2,
      quantity: 1,
      // dividedBetweenSeats: [2],
      itemData: {
        id: 5,
        title: 'Greek',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 5,
        itemCategory: 'pizza',
        imgSrc: require('./assets/img/food/full/pizza/Greek.png'),
        imgSrcSmall: Greek,
        customIngredients: [126, 199, 122, 133, 144, 155, 166],
      },
      hasOptions: true,
      customOptions: {
        size: 'medium',
        base: 'classic_crust',
      },
      ingredientStatus: {
        addIngredient: [],
        removeIngredient: [126],
        lightIngredient: [],
      },
    },
    {
      id: 3,
      quantity: 1,
      // dividedBetweenSeats: [2],
      itemData: {
        id: 3,
        title: 'Cheese Burger',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 5,
        itemCategory: 'pizza',
        imgSrc: require('./assets/img/food/full/pizza/Cheese Burger.png'),
        imgSrcSmall: require('./assets/img/food/size_230/pizza/Cheese Burger.png'),
        customIngredients: [126, 187, 165, 52, 13],
      },
      hasOptions: true,
      customOptions: {
        size: 'medium',
        base: 'cheesy_crust',
      },
      ingredientStatus: {
        addIngredient: [],
        removeIngredient: [126],
        lightIngredient: [],
      },
    },
    {
      id: 4,
      quantity: 1,
      // dividedBetweenSeats: [4],
      itemData: {
        id: 11,
        title: 'Satay Chicken',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 8,
        itemCategory: 'pizza',
        imgSrc: require('./assets/img/food/full/pizza/Satay Chicken.png'),
        imgSrcSmall: require('./assets/img/food/size_230/pizza/Satay Chicken.png'),
        customIngredients: [12, 2, 44, 56, 11, 111],
      },
      hasOptions: true,
      customOptions: {
        size: 'small',
        base: 'classic_crust',
      },
      ingredientStatus: {
        addIngredient: [126],
        removeIngredient: [2],
        lightIngredient: [],
      },
    },
    {
      id: 5,
      quantity: 1,
      // dividedBetweenSeats: [3],
      itemData: {
        id: 16,
        title: 'Arabiata',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 5,
        itemCategory: 'pasta',
        imgSrc: require('./assets/img/food/full/pasta/Arabiata.png'),
        imgSrcSmall: require('./assets/img/food/size_230/pasta/Arabiata.png'),
        customIngredients: [126, 12, 2, 44, 56, 11, 111],
      },
      hasOptions: true,
      customOptions: {
        size: 'entree',
        pasta_type: 'gnocchi',
      },
      ingredientStatus: {
        addIngredient: [],
        removeIngredient: [126],
        lightIngredient: [],
      },
    },
  ],
  alphabet: [
    { id: 1, letter: 'A', count: 0, active: false },
    { id: 2, letter: 'B', count: 0, active: false },
    { id: 3, letter: 'C', count: 0, active: false },
    { id: 4, letter: 'D', count: 0, active: false },
    { id: 5, letter: 'E', count: 0, active: false },
    { id: 6, letter: 'F', count: 0, active: false },
    { id: 7, letter: 'G', count: 0, active: false },
    { id: 8, letter: 'H', count: 0, active: false },
    { id: 9, letter: 'I', count: 0, active: false },
    { id: 10, letter: 'J', count: 0, active: false },
    { id: 11, letter: 'K', count: 0, active: false },
    { id: 12, letter: 'L', count: 0, active: false },
    { id: 13, letter: 'M', count: 0, active: false },
    { id: 14, letter: 'O', count: 0, active: false },
    { id: 15, letter: 'P', count: 0, active: false },
    { id: 16, letter: 'Q', count: 0, active: false },
    { id: 17, letter: 'R', count: 0, active: false },
    { id: 18, letter: 'S', count: 0, active: false },
    { id: 19, letter: 'T', count: 0, active: false },
    { id: 20, letter: 'U', count: 0, active: false },
    { id: 21, letter: 'V', count: 0, active: false },
    { id: 22, letter: 'W', count: 0, active: false },
    { id: 23, letter: 'X', count: 0, active: false },
    { id: 24, letter: 'Y', count: 0, active: false },
    { id: 25, letter: 'Z', count: 0, active: false },
  ],
  ingredients: [
    { id: 1, name: 'Bok Choy', price: 0.35 },
    { id: 2, name: 'Snake Beans', price: 0.35 },
    { id: 3, name: 'Free Range Chicken', price: 0.35 },
    { id: 4, name: 'Sorrel Leaves', price: 0.35 },
    { id: 5, name: 'Rocket Leaves', price: 0.35 },
    { id: 6, name: 'Drumstick', price: 0.35 },
    { id: 7, name: 'Cherry Tomatoes', price: 0.35 },
    { id: 8, name: 'Kaffir Lime', price: 0.35 },
    { id: 9, name: 'Plantain', price: 0.35 },
    { id: 10, name: 'Turnip', price: 0.35 },
    { id: 11, name: 'Sweet Potatoes', price: 0.35 },
    { id: 12, name: 'Round Gourd', price: 0.35 },
    { id: 13, name: 'Ridge Gourd', price: 0.35 },
    { id: 14, name: 'Pimiento', price: 0.35 },
    { id: 15, name: 'Spinach', price: 0.35 },
    { id: 16, name: 'Onion', price: 0.35 },
    { id: 17, name: 'Mustard Leaves', price: 0.35 },
    { id: 18, name: 'Mushroom', price: 0.35 },
    { id: 19, name: 'Radish', price: 0.35 },
    { id: 21, name: 'Shallots', price: 0.35 },
    { id: 22, name: 'Lettuce', price: 0.35 },
    { id: 23, name: 'Leek', price: 0.35 },
    { id: 24, name: 'Pumpkin', price: 0.35 },
    { id: 25, name: 'Yam', price: 0.35 },
    { id: 26, name: 'Jalapeno', price: 0.35 },
    { id: 27, name: 'Jackfruit', price: 0.35 },
    { id: 28, name: 'Horseradish', price: 0.35 },
    { id: 29, name: 'Spring Onion', price: 0.35 },
    { id: 30, name: 'Green Peas', price: 0.35 },
    { id: 31, name: 'Green Chillies', price: 0.35 },
    { id: 32, name: 'Cluster Beans', price: 0.35 },
    { id: 33, name: 'Gherkins', price: 0.35 },
    { id: 34, name: 'Garlic', price: 0.35 },
    { id: 35, name: 'French Beans', price: 0.35 },
    { id: 36, name: 'Fenugreek', price: 0.35 },
    { id: 37, name: 'Cucumber', price: 0.35 },
    { id: 38, name: 'Zucchini', price: 0.35 },
    { id: 39, name: 'Corn', price: 0.35 },
    { id: 40, name: 'Shiitake Mushroom', price: 0.35 },
    { id: 41, name: 'Celery', price: 0.35 },
    { id: 42, name: 'Cauliflower', price: 0.35 },
    { id: 43, name: 'Carrot', price: 0.35 },
    { id: 44, name: 'Capsicum', price: 0.35 },
    { id: 45, name: 'Capers', price: 0.35 },
    { id: 46, name: 'Broccoli', price: 0.35 },
    { id: 47, name: 'Broad Beans', price: 0.35 },
    { id: 48, name: 'Bottle Gourd', price: 0.35 },
    { id: 49, name: 'Bitter Gourd', price: 0.35 },
    { id: 50, name: 'Lady Finger', price: 0.35 },
    { id: 51, name: 'Lotus Stem', price: 0.35 },
    { id: 52, name: 'Bell Pepper', price: 0.35 },
    { id: 53, name: 'Beetroot', price: 0.35 },
    { id: 54, name: 'Pigweed', price: 0.35 },
    { id: 55, name: 'Cabbage', price: 0.35 },
    { id: 56, name: 'Bamboo Shoot', price: 0.35 },
    { id: 57, name: 'Baby Corn', price: 0.35 },
    { id: 58, name: 'Avocado', price: 0.35 },
    { id: 59, name: 'Eggplant', price: 0.35 },
    { id: 60, name: 'Asparagus', price: 0.35 },
    { id: 61, name: 'Ash Gourd', price: 0.35 },
    { id: 62, name: 'Artichoke', price: 0.35 },
    { id: 63, name: 'Colocasia', price: 0.35 },
    { id: 64, name: 'Potatoes', price: 0.35 },
    { id: 65, name: 'Ginger', price: 0.35 },
    { id: 66, name: 'Honey', price: 0.35 },
    { id: 67, name: 'Jaggery', price: 0.35 },
    { id: 68, name: 'Golden Syrup', price: 0.35 },
    { id: 69, name: 'Sugar', price: 0.35 },
    { id: 70, name: 'Castor Sugar', price: 0.35 },
    { id: 71, name: 'Caramel', price: 0.35 },
    { id: 72, name: 'Cane Sugar', price: 0.35 },
    { id: 73, name: 'Canola Oil', price: 0.35 },
    { id: 74, name: 'Chia Seeds', price: 0.35 },
    { id: 75, name: 'Hazelnut', price: 0.35 },
    { id: 76, name: 'Pine Nuts', price: 0.35 },
    { id: 77, name: 'Mustard Oil', price: 0.35 },
    { id: 78, name: 'Sunflower Seeds', price: 0.35 },
    { id: 79, name: 'Sesame Oil', price: 0.35 },
    { id: 80, name: 'Pistachio', price: 0.35 },
    { id: 81, name: 'Olive Oil', price: 0.35 },
    { id: 82, name: 'Mustard Seeds', price: 0.35 },
    { id: 83, name: 'Poppy Seeds', price: 0.35 },
    { id: 84, name: 'Sesame Seeds', price: 0.35 },
    { id: 85, name: 'Peanuts', price: 0.35 },
    { id: 86, name: 'Chironji', price: 0.35 },
    { id: 87, name: 'Cashew Nuts', price: 0.35 },
    { id: 88, name: 'Blanched Almonds', price: 0.35 },
    { id: 89, name: 'Almonds', price: 0.35 },
    { id: 90, name: 'Walnuts', price: 0.35 },
    { id: 91, name: 'Sea Salt', price: 0.35 },
    { id: 92, name: 'Rice Vinegar', price: 0.35 },
    { id: 93, name: 'White Pepper', price: 0.35 },
    { id: 94, name: 'Yeast', price: 0.35 },
    { id: 95, name: 'Soy Milk', price: 0.35 },
    { id: 96, name: 'Shrimp', price: 0.35 },
    { id: 97, name: 'Tuna Fish', price: 0.35 },
    { id: 98, name: 'Shellfish', price: 0.35 },
    { id: 99, name: 'Shark', price: 0.35 },
    { id: 100, name: 'Hilsa', price: 0.35 },
    { id: 101, name: 'Sardines', price: 0.35 },
    { id: 102, name: 'Salmon', price: 0.35 },
    { id: 103, name: 'Prawns', price: 0.35 },
    { id: 104, name: 'Pomfret', price: 0.35 },
    { id: 105, name: 'Perch', price: 0.35 },
    { id: 106, name: 'Pearl spot', price: 0.35 },
    { id: 107, name: 'Mussels', price: 0.35 },
    { id: 108, name: 'Mullet', price: 0.35 },
    { id: 109, name: 'Squids', price: 0.35 },
    { id: 110, name: 'Haddock', price: 0.35 },
    { id: 111, name: 'Flounder', price: 0.35 },
    { id: 112, name: 'Fish Stock', price: 0.35 },
    { id: 113, name: 'Fish', price: 0.35 },
    { id: 114, name: 'Fish Fillet', price: 0.35 },
    { id: 115, name: 'Cuttle fish', price: 0.35 },
    { id: 116, name: 'Cod', price: 0.35 },
    { id: 117, name: 'Clams', price: 0.35 },
    { id: 118, name: 'Cat fish', price: 0.35 },
    { id: 119, name: 'Mackerel', price: 0.35 },
    { id: 120, name: 'Anchovies', price: 0.35 },
    { id: 121, name: 'Cranberry', price: 0.35 },
    { id: 122, name: 'Kiwi', price: 0.35 },
    { id: 123, name: 'Blueberries', price: 0.35 },
    { id: 124, name: 'Mango', price: 0.35 },
    { id: 125, name: 'Watermelon', price: 0.35 },
    { id: 126, name: 'Tomato', price: 0.35 },
    { id: 127, name: 'Strawberry', price: 0.35 },
    { id: 128, name: 'Water Chestnut', price: 0.35 },
    { id: 129, name: 'Papaya', price: 0.35 },
    { id: 130, name: 'Orange Rind', price: 0.35 },
    { id: 131, name: 'Orange', price: 0.35 },
    { id: 132, name: 'Olives', price: 0.35 },
    { id: 133, name: 'Pear', price: 0.35 },
    { id: 134, name: 'Sultana', price: 0.35 },
    { id: 135, name: 'Mulberry', price: 0.35 },
    { id: 136, name: 'Lychee', price: 0.35 },
    { id: 137, name: 'Lemon Juice', price: 0.35 },
    { id: 138, name: 'Lemon Rind', price: 0.35 },
    { id: 139, name: 'Gruyere Cheese', price: 0.35 },
    { id: 140, name: 'Gouda Cheese', price: 0.35 },
    { id: 141, name: 'Feta Cheese', price: 0.35 },
    { id: 142, name: 'Milk', price: 0.35 },
    { id: 143, name: 'Brie Cheese', price: 0.35 },
    { id: 144, name: 'Cream Cheese', price: 0.35 },
    { id: 145, name: 'Ricotta Cheese', price: 0.35 },
    { id: 146, name: 'Parmesan Cheese', price: 0.35 },
    { id: 147, name: 'Blue Cheese', price: 0.35 },
    { id: 148, name: 'Cheddar Cheese', price: 0.35 },
    { id: 149, name: 'Mascarpone Cheese', price: 0.35 },
    { id: 150, name: 'Cream', price: 0.35 },
    { id: 151, name: 'Provolone Cheese', price: 0.35 },
    { id: 152, name: 'Mozzarella Cheese', price: 0.35 },
    { id: 153, name: 'Khoya', price: 0.35 },
    { id: 154, name: 'Hung Curd', price: 0.35 },
    { id: 155, name: 'Yogurt', price: 0.35 },
    { id: 156, name: 'Cottage Cheese', price: 0.35 },
    { id: 157, name: 'Beef', price: 0.35 },
    { id: 158, name: 'Grass Fed Chicken', price: 0.35 },
    { id: 159, name: 'Free Range Chicken', price: 0.35 },
    { id: 160, name: 'Pork', price: 0.35 },
    { id: 161, name: 'Partridge', price: 0.35 },
    { id: 162, name: 'Meat Stock', price: 0.35 },
    { id: 163, name: 'Keema', price: 0.35 },
    { id: 164, name: 'Mutton Liver', price: 0.35 },
    { id: 165, name: 'Ham', price: 0.35 },
    { id: 166, name: 'Kidney Meat', price: 0.35 },
    { id: 167, name: 'Crab', price: 0.35 },
    { id: 168, name: 'Chicken Stock', price: 0.35 },
    { id: 169, name: 'Chicken Liver', price: 0.35 },
    { id: 170, name: 'Chops', price: 0.35 },
    { id: 171, name: 'Chicken', price: 0.35 },
    { id: 172, name: 'Lamb Meat', price: 0.35 },
    { id: 173, name: 'Quail', price: 0.35 },
    { id: 174, name: 'Mutton', price: 0.35 },
    { id: 175, name: 'Bacon', price: 0.35 },
    { id: 176, name: 'Amaranth', price: 0.35 },
    { id: 177, name: 'Flour', price: 0.35 },
    { id: 178, name: 'Muesli', price: 0.35 },
    { id: 179, name: 'Oats', price: 0.35 },
    { id: 180, name: 'Jowar', price: 0.35 },
    { id: 181, name: 'Brown Rice', price: 0.35 },
    { id: 182, name: 'Arborio Rice', price: 0.35 },
    { id: 183, name: 'Water Chestnut flour', price: 0.35 },
    { id: 184, name: 'Barnyard Millet', price: 0.35 },
    { id: 185, name: 'Tapioca', price: 0.35 },
    { id: 186, name: 'Semolina', price: 0.35 },
    { id: 187, name: 'Finger Millet', price: 0.35 },
    { id: 188, name: 'Puffed Rice', price: 0.35 },
    { id: 189, name: 'Buckwheat', price: 0.35 },
    { id: 190, name: 'Kidney Beans', price: 0.35 },
    { id: 191, name: 'Whole Bengal Gram', price: 0.35 },
    { id: 192, name: 'Green Gram', price: 0.35 },
    { id: 193, name: 'All Purpose Flour', price: 0.35 },
  ],

  filteredAdd: [],
  filteredRemove: [],
  filteredLight: [],
  filteredAddActiveId: false,
  addIngredient: '',
  removeIngredient: '',
  lightIngredient: '',
  actionsModal: {
    isActive: false,
    seatId: 1,
    orderId: 1,
    customClass: '',
    position: {
      right: '40px',
      top: '20px',
    },
  },
  detailModal: {
    isActive: false,
    orderId: '',
    title: '',
    desc: '',
    imgSrc: '',
  },
  newOrder: {
    id: null,
    quantity: 1,
    itemData: {},
    hasOptions: false,
    customOptions: {},
    ingredientStatus: {
      addIngredient: [],
      removeIngredient: [],
      lightIngredient: [],
    },
  },
  newSpecialOrder: {
    id: null,
    quantity: 1,
    itemData: {},
    hasOptions: false,
    customOptions: {},
    ingredientStatus: {
      addIngredient: [],
      removeIngredient: [],
      lightIngredient: [],
    },
    specialsData: undefined,
  },
  newHalfHalfOrder: {
    id: null,
    quantity: 1,
    itemData: {},
    hasOptions: false,
    customOptions: {},
    ingredientStatus: {
      addIngredient: [],
      removeIngredient: [],
      lightIngredient: [],
    },
    halfHalfData: undefined,
  },
  keyboardModal: {
    active: false,
    actionType: '',
  },
  keyboardMode: false,
  firstNameList: [
    'Aaren',
    'Aarika',
    'Abagael',
    'Abagail',
    'Abbe',
    'Abbey',
    'Abbi',
    'Abbie',
    'Abby',
    'Abbye',
    'Abigael',
    'Abigail',
    'Abigale',
    'Abra',
    'Ada',
    'Adah',
    'Adaline',
    'Adan',
    'Adara',
    'Adda',
    'Addi',
    'Addia',
    'Addie',
    'Addy',
    'Adel',
    'Adela',
    'Adelaida',
    'Adelaide',
    'Adele',
    'Adelheid',
    'Adelice',
    'Adelina',
    'Adelind',
    'Adeline',
    'Adella',
    'Adelle',
    'Adena',
    'Adey',
    'Adi',
    'Adiana',
    'Adina',
    'Adora',
    'Adore',
    'Adoree',
    'Adorne',
    'Adrea',
    'Adria',
    'Adriaens',
    'Adrian',
    'Adriana',
    'Adriane',
    'Adrianna',
    'Adrianne',
    'Adriena',
    'Adrienne',
    'Aeriel',
    'Aeriela',
    'Aeriell',
    'Afton',
    'Ag',
    'Agace',
    'Agata',
    'Agatha',
    'Agathe',
    'Aggi',
    'Aggie',
    'Aggy',
    'Agna',
    'Agnella',
    'Agnes',
    'Agnese',
    'Agnesse',
    'Agneta',
    'Agnola',
    'Agretha',
    'Aida',
    'Aidan',
    'Aigneis',
    'Aila',
    'Aile',
    'Ailee',
    'Aileen',
    'Ailene',
    'Ailey',
    'Aili',
    'Ailina',
    'Ailis',
    'Ailsun',
    'Ailyn',
    'Aime',
    'Aimee',
    'Aimil',
    'Aindrea',
    'Ainslee',
    'Ainsley',
    'Ainslie',
    'Ajay',
    'Alaine',
    'Alameda',
    'Alana',
    'Alanah',
    'Alane',
    'Alanna',
    'Alayne',
    'Alberta',
    'Albertina',
    'Albertine',
    'Albina',
    'Alecia',
    'Aleda',
    'Aleece',
    'Aleen',
    'Alejandra',
    'Alejandrina',
    'Alena',
    'Alene',
    'Alessandra',
    'Aleta',
    'Alethea',
    'Alex',
    'Alexa',
    'Alexandra',
    'Alexandrina',
    'Alexi',
    'Alexia',
    'Alexina',
    'Alexine',
    'Alexis',
    'Alfi',
    'Alfie',
    'Alfreda',
    'Alfy',
    'Ali',
    'Alia',
    'Alica',
    'Alice',
    'Alicea',
    'Alicia',
    'Alida',
    'Alidia',
    'Alie',
    'Alika',
    'Alikee',
    'Alina',
    'Aline',
    'Alis',
    'Alisa',
    'Alisha',
    'Alison',
    'Alissa',
    'Alisun',
    'Alix',
    'Aliza',
    'Alla',
    'Alleen',
    'Allegra',
    'Allene',
    'Alli',
    'Allianora',
    'Allie',
    'Allina',
    'Allis',
    'Allison',
    'Allissa',
    'Allix',
    'Allsun',
    'Allx',
    'Ally',
    'Allyce',
    'Allyn',
    'Allys',
    'Allyson',
    'Alma',
    'Almeda',
    'Almeria',
    'Almeta',
    'Almira',
    'Almire',
    'Aloise',
    'Aloisia',
    'Aloysia',
    'Alta',
    'Althea',
    'Alvera',
    'Alverta',
    'Alvina',
    'Alvinia',
    'Alvira',
    'Alyce',
    'Alyda',
    'Alys',
    'Alysa',
    'Alyse',
    'Alysia',
    'Alyson',
    'Alyss',
    'Alyssa',
    'Amabel',
    'Amabelle',
    'Amalea',
    'Amalee',
    'Amaleta',
    'Amalia',
    'Amalie',
    'Amalita',
    'Amalle',
    'Amanda',
    'Amandi',
    'Amandie',
    'Amandy',
    'Amara',
    'Amargo',
    'Amata',
    'Amber',
    'Amberly',
    'Ambur',
    'Ame',
    'Amelia',
    'Amelie',
    'Amelina',
    'Ameline',
    'Amelita',
    'Ami',
    'Amie',
    'Amii',
    'Amil',
    'Amitie',
    'Amity',
    'Ammamaria',
    'Amy',
    'Amye',
    'Ana',
    'Anabal',
    'Anabel',
    'Anabella',
    'Anabelle',
    'Analiese',
    'Analise',
    'Anallese',
    'Anallise',
    'Anastasia',
    'Anastasie',
    'Anastassia',
    'Anatola',
    'Andee',
    'Andeee',
    'Anderea',
    'Andi',
    'Andie',
    'Andra',
    'Andrea',
    'Andreana',
    'Andree',
    'Andrei',
    'Andria',
    'Andriana',
    'Andriette',
    'Andromache',
    'Andy',
    'Anestassia',
    'Anet',
    'Anett',
    'Anetta',
    'Anette',
    'Ange',
    'Angel',
    'Angela',
    'Angele',
    'Angelia',
    'Angelica',
    'Angelika',
    'Angelina',
    'Angeline',
    'Angelique',
    'Angelita',
    'Angelle',
    'Angie',
    'Angil',
    'Angy',
    'Ania',
    'Anica',
    'Anissa',
    'Anita',
    'Anitra',
    'Anjanette',
    'Anjela',
    'Ann',
    'Ann-Marie',
    'Anna',
    'Anna-Diana',
    'Anna-Diane',
    'Anna-Maria',
    'Annabal',
    'Annabel',
    'Annabela',
    'Annabell',
    'Annabella',
    'Annabelle',
    'Annadiana',
    'Annadiane',
    'Annalee',
    'Annaliese',
    'Annalise',
    'Annamaria',
    'Annamarie',
    'Anne',
    'Anne-Corinne',
    'Anne-Marie',
    'Annecorinne',
    'Anneliese',
    'Annelise',
    'Annemarie',
    'Annetta',
    'Annette',
    'Anni',
    'Annice',
    'Annie',
    'Annis',
    'Annissa',
    'Annmaria',
    'Annmarie',
    'Annnora',
    'Annora',
    'Anny',
    'Anselma',
    'Ansley',
    'Anstice',
    'Anthe',
    'Anthea',
    'Anthia',
    'Anthiathia',
    'Antoinette',
    'Antonella',
    'Antonetta',
    'Antonia',
    'Antonie',
    'Antonietta',
    'Antonina',
    'Anya',
    'Appolonia',
    'April',
    'Aprilette',
    'Ara',
    'Arabel',
    'Arabela',
    'Arabele',
    'Arabella',
    'Arabelle',
    'Arda',
    'Ardath',
    'Ardeen',
    'Ardelia',
    'Ardelis',
    'Ardella',
    'Ardelle',
    'Arden',
    'Ardene',
    'Ardenia',
    'Ardine',
    'Ardis',
    'Ardisj',
    'Ardith',
    'Ardra',
    'Ardyce',
    'Ardys',
    'Ardyth',
    'Aretha',
    'Ariadne',
    'Ariana',
    'Aridatha',
    'Ariel',
    'Ariela',
    'Ariella',
    'Arielle',
    'Arlana',
    'Arlee',
    'Arleen',
    'Arlen',
    'Arlena',
    'Arlene',
    'Arleta',
    'Arlette',
    'Arleyne',
    'Arlie',
    'Arliene',
    'Arlina',
    'Arlinda',
    'Arline',
    'Arluene',
    'Arly',
    'Arlyn',
    'Arlyne',
    'Aryn',
    'Ashely',
    'Ashia',
    'Ashien',
    'Ashil',
    'Ashla',
    'Ashlan',
    'Ashlee',
    'Ashleigh',
    'Ashlen',
    'Ashley',
    'Ashli',
    'Ashlie',
    'Ashly',
    'Asia',
    'Astra',
    'Astrid',
    'Astrix',
    'Atalanta',
    'Athena',
    'Athene',
    'Atlanta',
    'Atlante',
    'Auberta',
    'Aubine',
    'Aubree',
    'Aubrette',
    'Aubrey',
    'Aubrie',
    'Aubry',
    'Audi',
    'Audie',
    'Audra',
    'Audre',
    'Audrey',
    'Audrie',
    'Audry',
    'Audrye',
    'Audy',
    'Augusta',
    'Auguste',
    'Augustina',
    'Augustine',
    'Aundrea',
    'Aura',
    'Aurea',
    'Aurel',
    'Aurelea',
    'Aurelia',
    'Aurelie',
    'Auria',
    'Aurie',
    'Aurilia',
    'Aurlie',
    'Auroora',
    'Aurora',
    'Aurore',
    'Austin',
    'Austina',
    'Austine',
    'Ava',
    'Aveline',
    'Averil',
    'Averyl',
    'Avie',
    'Avis',
    'Aviva',
    'Avivah',
    'Avril',
    'Avrit',
    'Ayn',
    'Bab',
    'Babara',
    'Babb',
    'Babbette',
    'Babbie',
    'Babette',
    'Babita',
    'Babs',
    'Bambi',
    'Bambie',
    'Bamby',
    'Barb',
    'Barbabra',
    'Barbara',
    'Barbara-Anne',
    'Barbaraanne',
    'Barbe',
    'Barbee',
    'Barbette',
    'Barbey',
    'Barbi',
    'Barbie',
    'Barbra',
    'Barby',
    'Bari',
    'Barrie',
    'Barry',
    'Basia',
    'Bathsheba',
    'Batsheva',
    'Bea',
    'Beatrice',
    'Beatrisa',
    'Beatrix',
    'Beatriz',
    'Bebe',
    'Becca',
    'Becka',
    'Becki',
    'Beckie',
    'Becky',
    'Bee',
    'Beilul',
    'Beitris',
    'Bekki',
    'Bel',
    'Belia',
    'Belicia',
    'Belinda',
    'Belita',
    'Bell',
    'Bella',
    'Bellanca',
    'Belle',
    'Bellina',
    'Belva',
    'Belvia',
    'Bendite',
    'Benedetta',
    'Benedicta',
    'Benedikta',
    'Benetta',
    'Benita',
    'Benni',
    'Bennie',
    'Benny',
    'Benoite',
    'Berenice',
    'Beret',
    'Berget',
    'Berna',
    'Bernadene',
    'Bernadette',
    'Bernadina',
    'Bernadine',
    'Bernardina',
    'Bernardine',
    'Bernelle',
    'Bernete',
    'Bernetta',
    'Bernette',
    'Berni',
    'Bernice',
    'Bernie',
    'Bernita',
    'Berny',
    'Berri',
    'Berrie',
    'Berry',
    'Bert',
    'Berta',
    'Berte',
    'Bertha',
    'Berthe',
    'Berti',
    'Bertie',
    'Bertina',
    'Bertine',
    'Berty',
    'Beryl',
    'Beryle',
    'Bess',
    'Bessie',
    'Bessy',
    'Beth',
    'Bethanne',
    'Bethany',
    'Bethena',
    'Bethina',
    'Betsey',
    'Betsy',
    'Betta',
    'Bette',
    'Bette-Ann',
    'Betteann',
    'Betteanne',
    'Betti',
    'Bettina',
    'Bettine',
    'Betty',
    'Bettye',
    'Beulah',
    'Bev',
    'Beverie',
    'Beverlee',
    'Beverley',
    'Beverlie',
    'Beverly',
    'Bevvy',
    'Bianca',
    'Bianka',
    'Bibbie',
    'Bibby',
    'Bibbye',
    'Bibi',
    'Biddie',
    'Biddy',
    'Bidget',
    'Bili',
    'Bill',
    'Billi',
    'Billie',
    'Billy',
    'Billye',
    'Binni',
    'Binnie',
    'Binny',
    'Bird',
    'Birdie',
    'Birgit',
    'Birgitta',
    'Blair',
    'Blaire',
    'Blake',
    'Blakelee',
    'Blakeley',
    'Blanca',
    'Blanch',
    'Blancha',
    'Blanche',
    'Blinni',
    'Blinnie',
    'Blinny',
    'Bliss',
    'Blisse',
    'Blithe',
    'Blondell',
    'Blondelle',
    'Blondie',
    'Blondy',
    'Blythe',
    'Bobbe',
    'Bobbee',
    'Bobbette',
    'Bobbi',
    'Bobbie',
    'Bobby',
    'Bobbye',
    'Bobette',
    'Bobina',
    'Bobine',
    'Bobinette',
    'Bonita',
    'Bonnee',
    'Bonni',
    'Bonnibelle',
    'Bonnie',
    'Bonny',
    'Brana',
    'Brandais',
    'Brande',
    'Brandea',
    'Brandi',
    'Brandice',
    'Brandie',
    'Brandise',
    'Brandy',
    'Breanne',
    'Brear',
    'Bree',
    'Breena',
    'Bren',
    'Brena',
    'Brenda',
    'Brenn',
    'Brenna',
    'Brett',
    'Bria',
    'Briana',
    'Brianna',
    'Brianne',
    'Bride',
    'Bridget',
    'Bridgette',
    'Bridie',
    'Brier',
    'Brietta',
    'Brigid',
    'Brigida',
    'Brigit',
    'Brigitta',
    'Brigitte',
    'Brina',
    'Briney',
    'Brinn',
    'Brinna',
    'Briny',
    'Brit',
    'Brita',
    'Britney',
    'Britni',
    'Britt',
    'Britta',
    'Brittan',
    'Brittaney',
    'Brittani',
    'Brittany',
    'Britte',
    'Britteny',
    'Brittne',
    'Brittney',
    'Brittni',
    'Brook',
    'Brooke',
    'Brooks',
    'Brunhilda',
    'Brunhilde',
    'Bryana',
    'Bryn',
    'Bryna',
    'Brynn',
    'Brynna',
    'Brynne',
    'Buffy',
    'Bunni',
    'Bunnie',
    'Bunny',
    'Cacilia',
    'Cacilie',
    'Cahra',
    'Cairistiona',
    'Caitlin',
    'Caitrin',
    'Cal',
    'Calida',
    'Calla',
    'Calley',
    'Calli',
    'Callida',
    'Callie',
    'Cally',
    'Calypso',
    'Cam',
    'Camala',
    'Camel',
    'Camella',
    'Camellia',
    'Cami',
    'Camila',
    'Camile',
    'Camilla',
    'Camille',
    'Cammi',
    'Cammie',
    'Cammy',
    'Candace',
    'Candi',
    'Candice',
    'Candida',
    'Candide',
    'Candie',
    'Candis',
    'Candra',
    'Candy',
    'Caprice',
    'Cara',
    'Caralie',
    'Caren',
    'Carena',
    'Caresa',
    'Caressa',
    'Caresse',
    'Carey',
    'Cari',
    'Caria',
    'Carie',
    'Caril',
    'Carilyn',
    'Carin',
    'Carina',
    'Carine',
    'Cariotta',
    'Carissa',
    'Carita',
    'Caritta',
    'Carla',
    'Carlee',
    'Carleen',
    'Carlen',
    'Carlene',
    'Carley',
    'Carlie',
    'Carlin',
    'Carlina',
    'Carline',
    'Carlita',
    'Carlota',
    'Carlotta',
    'Carly',
    'Carlye',
    'Carlyn',
    'Carlynn',
    'Carlynne',
    'Carma',
    'Carmel',
    'Carmela',
    'Carmelia',
    'Carmelina',
    'Carmelita',
    'Carmella',
    'Carmelle',
    'Carmen',
    'Carmencita',
    'Carmina',
    'Carmine',
    'Carmita',
    'Carmon',
    'Caro',
    'Carol',
    'Carol-Jean',
    'Carola',
    'Carolan',
    'Carolann',
    'Carole',
    'Carolee',
    'Carolin',
    'Carolina',
    'Caroline',
    'Caroljean',
    'Carolyn',
    'Carolyne',
    'Carolynn',
    'Caron',
    'Carree',
    'Carri',
    'Carrie',
    'Carrissa',
    'Carroll',
    'Carry',
    'Cary',
    'Caryl',
    'Caryn',
    'Casandra',
    'Casey',
    'Casi',
    'Casie',
    'Cass',
    'Cassandra',
    'Cassandre',
    'Cassandry',
    'Cassaundra',
    'Cassey',
    'Cassi',
    'Cassie',
    'Cassondra',
    'Cassy',
    'Catarina',
    'Cate',
    'Caterina',
    'Catha',
    'Catharina',
    'Catharine',
    'Cathe',
    'Cathee',
    'Catherin',
    'Catherina',
    'Catherine',
    'Cathi',
    'Cathie',
    'Cathleen',
    'Cathlene',
    'Cathrin',
    'Cathrine',
    'Cathryn',
    'Cathy',
    'Cathyleen',
    'Cati',
    'Catie',
    'Catina',
    'Catlaina',
    'Catlee',
    'Catlin',
    'Catrina',
    'Catriona',
    'Caty',
    'Caye',
    'Cayla',
    'Cecelia',
    'Cecil',
    'Cecile',
    'Ceciley',
    'Cecilia',
    'Cecilla',
    'Cecily',
    'Ceil',
    'Cele',
    'Celene',
    'Celesta',
    'Celeste',
    'Celestia',
    'Celestina',
    'Celestine',
    'Celestyn',
    'Celestyna',
    'Celia',
    'Celie',
    'Celina',
    'Celinda',
    'Celine',
    'Celinka',
    'Celisse',
    'Celka',
    'Celle',
    'Cesya',
    'Chad',
    'Chanda',
    'Chandal',
    'Chandra',
    'Channa',
    'Chantal',
    'Chantalle',
    'Charil',
    'Charin',
    'Charis',
    'Charissa',
    'Charisse',
    'Charita',
    'Charity',
    'Charla',
    'Charlean',
    'Charleen',
    'Charlena',
    'Charlene',
    'Charline',
    'Charlot',
    'Charlotta',
    'Charlotte',
    'Charmain',
    'Charmaine',
    'Charmane',
    'Charmian',
    'Charmine',
    'Charmion',
    'Charo',
    'Charyl',
    'Chastity',
    'Chelsae',
    'Chelsea',
    'Chelsey',
    'Chelsie',
    'Chelsy',
    'Cher',
    'Chere',
    'Cherey',
    'Cheri',
    'Cherianne',
    'Cherice',
    'Cherida',
    'Cherie',
    'Cherilyn',
    'Cherilynn',
    'Cherin',
    'Cherise',
    'Cherish',
    'Cherlyn',
    'Cherri',
    'Cherrita',
    'Cherry',
    'Chery',
    'Cherye',
    'Cheryl',
    'Cheslie',
    'Chiarra',
    'Chickie',
    'Chicky',
    'Chiquia',
    'Chiquita',
    'Chlo',
    'Chloe',
    'Chloette',
    'Chloris',
    'Chris',
    'Chrissie',
    'Chrissy',
    'Christa',
    'Christabel',
    'Christabella',
    'Christal',
    'Christalle',
    'Christan',
    'Christean',
    'Christel',
    'Christen',
    'Christi',
    'Christian',
    'Christiana',
    'Christiane',
    'Christie',
    'Christin',
    'Christina',
    'Christine',
    'Christy',
    'Christye',
    'Christyna',
    'Chrysa',
    'Chrysler',
    'Chrystal',
    'Chryste',
    'Chrystel',
    'Cicely',
    'Cicily',
    'Ciel',
    'Cilka',
    'Cinda',
    'Cindee',
    'Cindelyn',
    'Cinderella',
    'Cindi',
    'Cindie',
    'Cindra',
    'Cindy',
    'Cinnamon',
    'Cissiee',
    'Cissy',
    'Clair',
    'Claire',
    'Clara',
    'Clarabelle',
    'Clare',
    'Claresta',
    'Clareta',
    'Claretta',
    'Clarette',
    'Clarey',
    'Clari',
    'Claribel',
    'Clarice',
    'Clarie',
    'Clarinda',
    'Clarine',
    'Clarissa',
    'Clarisse',
    'Clarita',
    'Clary',
    'Claude',
    'Claudelle',
    'Claudetta',
    'Claudette',
    'Claudia',
    'Claudie',
    'Claudina',
    'Claudine',
    'Clea',
    'Clem',
    'Clemence',
    'Clementia',
    'Clementina',
    'Clementine',
    'Clemmie',
    'Clemmy',
    'Cleo',
    'Cleopatra',
    'Clerissa',
    'Clio',
    'Clo',
    'Cloe',
    'Cloris',
    'Clotilda',
    'Clovis',
    'Codee',
    'Codi',
    'Codie',
    'Cody',
    'Coleen',
    'Colene',
    'Coletta',
    'Colette',
    'Colleen',
    'Collen',
    'Collete',
    'Collette',
    'Collie',
    'Colline',
    'Colly',
    'Con',
    'Concettina',
    'Conchita',
    'Concordia',
    'Conni',
    'Connie',
    'Conny',
    'Consolata',
    'Constance',
    'Constancia',
    'Constancy',
    'Constanta',
    'Constantia',
    'Constantina',
    'Constantine',
    'Consuela',
    'Consuelo',
    'Cookie',
    'Cora',
    'Corabel',
    'Corabella',
    'Corabelle',
    'Coral',
    'Coralie',
    'Coraline',
    'Coralyn',
    'Cordelia',
    'Cordelie',
    'Cordey',
    'Cordi',
    'Cordie',
    'Cordula',
    'Cordy',
    'Coreen',
    'Corella',
    'Corenda',
    'Corene',
    'Coretta',
    'Corette',
    'Corey',
    'Cori',
    'Corie',
    'Corilla',
    'Corina',
    'Corine',
    'Corinna',
    'Corinne',
    'Coriss',
    'Corissa',
    'Corliss',
    'Corly',
    'Cornela',
    'Cornelia',
    'Cornelle',
    'Cornie',
    'Corny',
    'Correna',
    'Correy',
    'Corri',
    'Corrianne',
    'Corrie',
    'Corrina',
    'Corrine',
    'Corrinne',
    'Corry',
    'Cortney',
    'Cory',
    'Cosetta',
    'Cosette',
    'Costanza',
    'Courtenay',
    'Courtnay',
    'Courtney',
    'Crin',
    'Cris',
    'Crissie',
    'Crissy',
    'Crista',
    'Cristabel',
    'Cristal',
    'Cristen',
    'Cristi',
    'Cristie',
    'Cristin',
    'Cristina',
    'Cristine',
    'Cristionna',
    'Cristy',
    'Crysta',
    'Crystal',
    'Crystie',
    'Cthrine',
    'Cyb',
    'Cybil',
    'Cybill',
    'Cymbre',
    'Cynde',
    'Cyndi',
    'Cyndia',
    'Cyndie',
    'Cyndy',
    'Cynthea',
    'Cynthia',
    'Cynthie',
    'Cynthy',
    'Dacey',
    'Dacia',
    'Dacie',
    'Dacy',
    'Dael',
    'Daffi',
    'Daffie',
    'Daffy',
    'Dagmar',
    'Dahlia',
    'Daile',
    'Daisey',
    'Daisi',
    'Daisie',
    'Daisy',
    'Dale',
    'Dalenna',
    'Dalia',
    'Dalila',
    'Dallas',
    'Daloris',
    'Damara',
    'Damaris',
    'Damita',
    'Dana',
    'Danell',
    'Danella',
    'Danette',
    'Dani',
    'Dania',
    'Danica',
    'Danice',
    'Daniela',
    'Daniele',
    'Daniella',
    'Danielle',
    'Danika',
    'Danila',
    'Danit',
    'Danita',
    'Danna',
    'Danni',
    'Dannie',
    'Danny',
    'Dannye',
    'Danya',
    'Danyelle',
    'Danyette',
    'Daphene',
    'Daphna',
    'Daphne',
    'Dara',
    'Darb',
    'Darbie',
    'Darby',
    'Darcee',
    'Darcey',
    'Darci',
    'Darcie',
    'Darcy',
    'Darda',
    'Dareen',
    'Darell',
    'Darelle',
    'Dari',
    'Daria',
    'Darice',
    'Darla',
    'Darleen',
    'Darlene',
    'Darline',
    'Darlleen',
    'Daron',
    'Darrelle',
    'Darryl',
    'Darsey',
    'Darsie',
    'Darya',
    'Daryl',
    'Daryn',
    'Dasha',
    'Dasi',
    'Dasie',
    'Dasya',
    'Datha',
    'Daune',
    'Daveen',
    'Daveta',
    'Davida',
    'Davina',
    'Davine',
    'Davita',
    'Dawn',
    'Dawna',
    'Dayle',
    'Dayna',
    'Ddene',
    'De',
    'Deana',
    'Deane',
    'Deanna',
    'Deanne',
    'Deb',
    'Debbi',
    'Debbie',
    'Debby',
    'Debee',
    'Debera',
    'Debi',
    'Debor',
    'Debora',
    'Deborah',
    'Debra',
    'Dede',
    'Dedie',
    'Dedra',
    'Dee',
    'Dee Dee',
    'Deeann',
    'Deeanne',
    'Deedee',
    'Deena',
    'Deerdre',
    'Deeyn',
    'Dehlia',
    'Deidre',
    'Deina',
    'Deirdre',
    'Del',
    'Dela',
    'Delcina',
    'Delcine',
    'Delia',
    'Delila',
    'Delilah',
    'Delinda',
    'Dell',
    'Della',
    'Delly',
    'Delora',
    'Delores',
    'Deloria',
    'Deloris',
    'Delphine',
    'Delphinia',
    'Demeter',
    'Demetra',
    'Demetria',
    'Demetris',
    'Dena',
    'Deni',
    'Denice',
    'Denise',
    'Denna',
    'Denni',
    'Dennie',
    'Denny',
    'Deny',
    'Denys',
    'Denyse',
    'Deonne',
    'Desdemona',
    'Desirae',
    'Desiree',
    'Desiri',
    'Deva',
    'Devan',
    'Devi',
    'Devin',
    'Devina',
    'Devinne',
    'Devon',
    'Devondra',
    'Devonna',
    'Devonne',
    'Devora',
    'Di',
    'Diahann',
    'Dian',
    'Diana',
    'Diandra',
    'Diane',
    'Diane-Marie',
    'Dianemarie',
    'Diann',
    'Dianna',
    'Dianne',
    'Diannne',
    'Didi',
    'Dido',
    'Diena',
    'Dierdre',
    'Dina',
    'Dinah',
    'Dinnie',
    'Dinny',
    'Dion',
    'Dione',
    'Dionis',
    'Dionne',
    'Dita',
    'Dix',
    'Dixie',
    'Dniren',
    'Dode',
    'Dodi',
    'Dodie',
    'Dody',
    'Doe',
    'Doll',
    'Dolley',
    'Dolli',
    'Dollie',
    'Dolly',
    'Dolores',
    'Dolorita',
    'Doloritas',
    'Domeniga',
    'Dominga',
    'Domini',
    'Dominica',
    'Dominique',
    'Dona',
    'Donella',
    'Donelle',
    'Donetta',
    'Donia',
    'Donica',
    'Donielle',
    'Donna',
    'Donnamarie',
    'Donni',
    'Donnie',
    'Donny',
    'Dora',
    'Doralia',
    'Doralin',
    'Doralyn',
    'Doralynn',
    'Doralynne',
    'Dore',
    'Doreen',
    'Dorelia',
    'Dorella',
    'Dorelle',
    'Dorena',
    'Dorene',
    'Doretta',
    'Dorette',
    'Dorey',
    'Dori',
    'Doria',
    'Dorian',
    'Dorice',
    'Dorie',
    'Dorine',
    'Doris',
    'Dorisa',
    'Dorise',
    'Dorita',
    'Doro',
    'Dorolice',
    'Dorolisa',
    'Dorotea',
    'Doroteya',
    'Dorothea',
    'Dorothee',
    'Dorothy',
    'Dorree',
    'Dorri',
    'Dorrie',
    'Dorris',
    'Dorry',
    'Dorthea',
    'Dorthy',
    'Dory',
    'Dosi',
    'Dot',
    'Doti',
    'Dotti',
    'Dottie',
    'Dotty',
    'Dre',
    'Dreddy',
    'Dredi',
    'Drona',
    'Dru',
    'Druci',
    'Drucie',
    'Drucill',
    'Drucy',
    'Drusi',
    'Drusie',
    'Drusilla',
    'Drusy',
    'Dulce',
    'Dulcea',
    'Dulci',
    'Dulcia',
    'Dulciana',
    'Dulcie',
    'Dulcine',
    'Dulcinea',
    'Dulcy',
    'Dulsea',
    'Dusty',
    'Dyan',
    'Dyana',
    'Dyane',
    'Dyann',
    'Dyanna',
    'Dyanne',
    'Dyna',
    'Dynah',
    'Eachelle',
    'Eada',
    'Eadie',
    'Eadith',
    'Ealasaid',
    'Eartha',
    'Easter',
    'Eba',
    'Ebba',
    'Ebonee',
    'Ebony',
    'Eda',
    'Eddi',
    'Eddie',
    'Eddy',
    'Ede',
    'Edee',
    'Edeline',
    'Eden',
    'Edi',
    'Edie',
    'Edin',
    'Edita',
    'Edith',
    'Editha',
    'Edithe',
    'Ediva',
    'Edna',
    'Edwina',
    'Edy',
    'Edyth',
    'Edythe',
    'Effie',
    'Eileen',
    'Eilis',
    'Eimile',
    'Eirena',
    'Ekaterina',
    'Elaina',
    'Elaine',
    'Elana',
    'Elane',
    'Elayne',
    'Elberta',
    'Elbertina',
    'Elbertine',
    'Eleanor',
    'Eleanora',
    'Eleanore',
    'Electra',
    'Eleen',
    'Elena',
    'Elene',
    'Eleni',
    'Elenore',
    'Eleonora',
    'Eleonore',
    'Elfie',
    'Elfreda',
    'Elfrida',
    'Elfrieda',
    'Elga',
    'Elianora',
    'Elianore',
    'Elicia',
    'Elie',
    'Elinor',
    'Elinore',
    'Elisa',
    'Elisabet',
    'Elisabeth',
    'Elisabetta',
    'Elise',
    'Elisha',
    'Elissa',
    'Elita',
    'Eliza',
    'Elizabet',
    'Elizabeth',
    'Elka',
    'Elke',
    'Ella',
    'Elladine',
    'Elle',
    'Ellen',
    'Ellene',
    'Ellette',
    'Elli',
    'Ellie',
    'Ellissa',
    'Elly',
    'Ellyn',
    'Ellynn',
    'Elmira',
    'Elna',
    'Elnora',
    'Elnore',
    'Eloisa',
    'Eloise',
    'Elonore',
    'Elora',
    'Elsa',
    'Elsbeth',
    'Else',
    'Elset',
    'Elsey',
    'Elsi',
    'Elsie',
    'Elsinore',
    'Elspeth',
    'Elsy',
    'Elva',
    'Elvera',
    'Elvina',
    'Elvira',
    'Elwira',
    'Elyn',
    'Elyse',
    'Elysee',
    'Elysha',
    'Elysia',
    'Elyssa',
    'Em',
    'Ema',
    'Emalee',
    'Emalia',
    'Emelda',
    'Emelia',
    'Emelina',
    'Emeline',
    'Emelita',
    'Emelyne',
    'Emera',
    'Emilee',
    'Emili',
    'Emilia',
    'Emilie',
    'Emiline',
    'Emily',
    'Emlyn',
    'Emlynn',
    'Emlynne',
    'Emma',
    'Emmalee',
    'Emmaline',
    'Emmalyn',
    'Emmalynn',
    'Emmalynne',
    'Emmeline',
    'Emmey',
    'Emmi',
    'Emmie',
    'Emmy',
    'Emmye',
    'Emogene',
    'Emyle',
    'Emylee',
    'Engracia',
    'Enid',
    'Enrica',
    'Enrichetta',
    'Enrika',
    'Enriqueta',
    'Eolanda',
    'Eolande',
    'Eran',
    'Erda',
    'Erena',
    'Erica',
    'Ericha',
    'Ericka',
    'Erika',
    'Erin',
    'Erina',
    'Erinn',
    'Erinna',
    'Erma',
    'Ermengarde',
    'Ermentrude',
    'Ermina',
    'Erminia',
    'Erminie',
    'Erna',
    'Ernaline',
    'Ernesta',
    'Ernestine',
    'Ertha',
    'Eryn',
    'Esma',
    'Esmaria',
    'Esme',
    'Esmeralda',
    'Essa',
    'Essie',
    'Essy',
    'Esta',
    'Estel',
    'Estele',
    'Estell',
    'Estella',
    'Estelle',
    'Ester',
    'Esther',
    'Estrella',
    'Estrellita',
    'Ethel',
    'Ethelda',
    'Ethelin',
    'Ethelind',
    'Etheline',
    'Ethelyn',
    'Ethyl',
    'Etta',
    'Etti',
    'Ettie',
    'Etty',
    'Eudora',
    'Eugenia',
    'Eugenie',
    'Eugine',
    'Eula',
    'Eulalie',
    'Eunice',
    'Euphemia',
    'Eustacia',
    'Eva',
    'Evaleen',
    'Evangelia',
    'Evangelin',
    'Evangelina',
    'Evangeline',
    'Evania',
    'Evanne',
    'Eve',
    'Eveleen',
    'Evelina',
    'Eveline',
    'Evelyn',
    'Evey',
    'Evie',
    'Evita',
    'Evonne',
    'Evvie',
    'Evvy',
    'Evy',
    'Eyde',
    'Eydie',
    'Ezmeralda',
    'Fae',
    'Faina',
    'Faith',
    'Fallon',
    'Fan',
    'Fanchette',
    'Fanchon',
    'Fancie',
    'Fancy',
    'Fanechka',
    'Fania',
    'Fanni',
    'Fannie',
    'Fanny',
    'Fanya',
    'Fara',
    'Farah',
    'Farand',
    'Farica',
    'Farra',
    'Farrah',
    'Farrand',
    'Faun',
    'Faunie',
    'Faustina',
    'Faustine',
    'Fawn',
    'Fawne',
    'Fawnia',
    'Fay',
    'Faydra',
    'Faye',
    'Fayette',
    'Fayina',
    'Fayre',
    'Fayth',
    'Faythe',
    'Federica',
    'Fedora',
    'Felecia',
    'Felicdad',
    'Felice',
    'Felicia',
    'Felicity',
    'Felicle',
    'Felipa',
    'Felisha',
    'Felita',
    'Feliza',
    'Fenelia',
    'Feodora',
    'Ferdinanda',
    'Ferdinande',
    'Fern',
    'Fernanda',
    'Fernande',
    'Fernandina',
    'Ferne',
    'Fey',
    'Fiann',
    'Fianna',
    'Fidela',
    'Fidelia',
    'Fidelity',
    'Fifi',
    'Fifine',
    'Filia',
    'Filide',
    'Filippa',
    'Fina',
    'Fiona',
    'Fionna',
    'Fionnula',
    'Fiorenze',
    'Fleur',
    'Fleurette',
    'Flo',
    'Flor',
    'Flora',
    'Florance',
    'Flore',
    'Florella',
    'Florence',
    'Florencia',
    'Florentia',
    'Florenza',
    'Florette',
    'Flori',
    'Floria',
    'Florida',
    'Florie',
    'Florina',
    'Florinda',
    'Floris',
    'Florri',
    'Florrie',
    'Florry',
    'Flory',
    'Flossi',
    'Flossie',
    'Flossy',
    'Flss',
    'Fran',
    'Francene',
    'Frances',
    'Francesca',
    'Francine',
    'Francisca',
    'Franciska',
    'Francoise',
    'Francyne',
    'Frank',
    'Frankie',
    'Franky',
    'Franni',
    'Frannie',
    'Franny',
    'Frayda',
    'Fred',
    'Freda',
    'Freddi',
    'Freddie',
    'Freddy',
    'Fredelia',
    'Frederica',
    'Fredericka',
    'Frederique',
    'Fredi',
    'Fredia',
    'Fredra',
    'Fredrika',
    'Freida',
    'Frieda',
    'Friederike',
    'Fulvia',
    'Gabbey',
    'Gabbi',
    'Gabbie',
    'Gabey',
    'Gabi',
    'Gabie',
    'Gabriel',
    'Gabriela',
    'Gabriell',
    'Gabriella',
    'Gabrielle',
    'Gabriellia',
    'Gabrila',
    'Gaby',
    'Gae',
    'Gael',
    'Gail',
    'Gale',
    'Gale',
    'Galina',
    'Garland',
    'Garnet',
    'Garnette',
    'Gates',
    'Gavra',
    'Gavrielle',
    'Gay',
    'Gaye',
    'Gayel',
    'Gayla',
    'Gayle',
    'Gayleen',
    'Gaylene',
    'Gaynor',
    'Gelya',
    'Gena',
    'Gene',
    'Geneva',
    'Genevieve',
    'Genevra',
    'Genia',
    'Genna',
    'Genni',
    'Gennie',
    'Gennifer',
    'Genny',
    'Genovera',
    'Genvieve',
    'George',
    'Georgeanna',
    'Georgeanne',
    'Georgena',
    'Georgeta',
    'Georgetta',
    'Georgette',
    'Georgia',
    'Georgiana',
    'Georgianna',
    'Georgianne',
    'Georgie',
    'Georgina',
    'Georgine',
    'Geralda',
    'Geraldine',
    'Gerda',
    'Gerhardine',
    'Geri',
    'Gerianna',
    'Gerianne',
    'Gerladina',
    'Germain',
    'Germaine',
    'Germana',
    'Gerri',
    'Gerrie',
    'Gerrilee',
    'Gerry',
    'Gert',
    'Gerta',
    'Gerti',
    'Gertie',
    'Gertrud',
    'Gertruda',
    'Gertrude',
    'Gertrudis',
    'Gerty',
    'Giacinta',
    'Giana',
    'Gianina',
    'Gianna',
    'Gigi',
    'Gilberta',
    'Gilberte',
    'Gilbertina',
    'Gilbertine',
    'Gilda',
    'Gilemette',
    'Gill',
    'Gillan',
    'Gilli',
    'Gillian',
    'Gillie',
    'Gilligan',
    'Gilly',
    'Gina',
    'Ginelle',
    'Ginevra',
    'Ginger',
    'Ginni',
    'Ginnie',
    'Ginnifer',
    'Ginny',
    'Giorgia',
    'Giovanna',
    'Gipsy',
    'Giralda',
    'Gisela',
    'Gisele',
    'Gisella',
    'Giselle',
    'Giuditta',
    'Giulia',
    'Giulietta',
    'Giustina',
    'Gizela',
    'Glad',
    'Gladi',
    'Gladys',
    'Gleda',
    'Glen',
    'Glenda',
    'Glenine',
    'Glenn',
    'Glenna',
    'Glennie',
    'Glennis',
    'Glori',
    'Gloria',
    'Gloriana',
    'Gloriane',
    'Glory',
    'Glyn',
    'Glynda',
    'Glynis',
    'Glynnis',
    'Gnni',
    'Godiva',
    'Golda',
    'Goldarina',
    'Goldi',
    'Goldia',
    'Goldie',
    'Goldina',
    'Goldy',
    'Grace',
    'Gracia',
    'Gracie',
    'Grata',
    'Gratia',
    'Gratiana',
    'Gray',
    'Grayce',
    'Grazia',
    'Greer',
    'Greta',
    'Gretal',
    'Gretchen',
    'Grete',
    'Gretel',
    'Grethel',
    'Gretna',
    'Gretta',
    'Grier',
    'Griselda',
    'Grissel',
    'Guendolen',
    'Guenevere',
    'Guenna',
    'Guglielma',
    'Gui',
    'Guillema',
    'Guillemette',
    'Guinevere',
    'Guinna',
    'Gunilla',
    'Gus',
    'Gusella',
    'Gussi',
    'Gussie',
    'Gussy',
    'Gusta',
    'Gusti',
    'Gustie',
    'Gusty',
    'Gwen',
    'Gwendolen',
    'Gwendolin',
    'Gwendolyn',
    'Gweneth',
    'Gwenette',
    'Gwenneth',
    'Gwenni',
    'Gwennie',
    'Gwenny',
    'Gwenora',
    'Gwenore',
    'Gwyn',
    'Gwyneth',
    'Gwynne',
    'Gypsy',
    'Hadria',
    'Hailee',
    'Haily',
    'Haleigh',
    'Halette',
    'Haley',
    'Hali',
    'Halie',
    'Halimeda',
    'Halley',
    'Halli',
    'Hallie',
    'Hally',
    'Hana',
    'Hanna',
    'Hannah',
    'Hanni',
    'Hannie',
    'Hannis',
    'Hanny',
    'Happy',
    'Harlene',
    'Harley',
    'Harli',
    'Harlie',
    'Harmonia',
    'Harmonie',
    'Harmony',
    'Harri',
    'Harrie',
    'Harriet',
    'Harriett',
    'Harrietta',
    'Harriette',
    'Harriot',
    'Harriott',
    'Hatti',
    'Hattie',
    'Hatty',
    'Hayley',
    'Hazel',
    'Heath',
    'Heather',
    'Heda',
    'Hedda',
    'Heddi',
    'Heddie',
    'Hedi',
    'Hedvig',
    'Hedvige',
    'Hedwig',
    'Hedwiga',
    'Hedy',
    'Heida',
    'Heidi',
    'Heidie',
    'Helaina',
    'Helaine',
    'Helen',
    'Helen-Elizabeth',
    'Helena',
    'Helene',
    'Helenka',
    'Helga',
    'Helge',
    'Helli',
    'Heloise',
    'Helsa',
    'Helyn',
    'Hendrika',
    'Henka',
    'Henrie',
    'Henrieta',
    'Henrietta',
    'Henriette',
    'Henryetta',
    'Hephzibah',
    'Hermia',
    'Hermina',
    'Hermine',
    'Herminia',
    'Hermione',
    'Herta',
    'Hertha',
    'Hester',
    'Hesther',
    'Hestia',
    'Hetti',
    'Hettie',
    'Hetty',
    'Hilary',
    'Hilda',
    'Hildagard',
    'Hildagarde',
    'Hilde',
    'Hildegaard',
    'Hildegarde',
    'Hildy',
    'Hillary',
    'Hilliary',
    'Hinda',
    'Holli',
    'Hollie',
    'Holly',
    'Holly-Anne',
    'Hollyanne',
    'Honey',
    'Honor',
    'Honoria',
    'Hope',
    'Horatia',
    'Hortense',
    'Hortensia',
    'Hulda',
    'Hyacinth',
    'Hyacintha',
    'Hyacinthe',
    'Hyacinthia',
    'Hyacinthie',
    'Hynda',
    'Ianthe',
    'Ibbie',
    'Ibby',
    'Ida',
    'Idalia',
    'Idalina',
    'Idaline',
    'Idell',
    'Idelle',
    'Idette',
    'Ileana',
    'Ileane',
    'Ilene',
    'Ilise',
    'Ilka',
    'Illa',
    'Ilsa',
    'Ilse',
    'Ilysa',
    'Ilyse',
    'Ilyssa',
    'Imelda',
    'Imogen',
    'Imogene',
    'Imojean',
    'Ina',
    'Indira',
    'Ines',
    'Inesita',
    'Inessa',
    'Inez',
    'Inga',
    'Ingaberg',
    'Ingaborg',
    'Inge',
    'Ingeberg',
    'Ingeborg',
    'Inger',
    'Ingrid',
    'Ingunna',
    'Inna',
    'Iolande',
    'Iolanthe',
    'Iona',
    'Iormina',
    'Ira',
    'Irena',
    'Irene',
    'Irina',
    'Iris',
    'Irita',
    'Irma',
    'Isa',
    'Isabel',
    'Isabelita',
    'Isabella',
    'Isabelle',
    'Isadora',
    'Isahella',
    'Iseabal',
    'Isidora',
    'Isis',
    'Isobel',
    'Issi',
    'Issie',
    'Issy',
    'Ivett',
    'Ivette',
    'Ivie',
    'Ivonne',
    'Ivory',
    'Ivy',
    'Izabel',
    'Jacenta',
    'Jacinda',
    'Jacinta',
    'Jacintha',
    'Jacinthe',
    'Jackelyn',
    'Jacki',
    'Jackie',
    'Jacklin',
    'Jacklyn',
    'Jackquelin',
    'Jackqueline',
    'Jacky',
    'Jaclin',
    'Jaclyn',
    'Jacquelin',
    'Jacqueline',
    'Jacquelyn',
    'Jacquelynn',
    'Jacquenetta',
    'Jacquenette',
    'Jacquetta',
    'Jacquette',
    'Jacqui',
    'Jacquie',
    'Jacynth',
    'Jada',
    'Jade',
    'Jaime',
    'Jaimie',
    'Jaine',
    'Jami',
    'Jamie',
    'Jamima',
    'Jammie',
    'Jan',
    'Jana',
    'Janaya',
    'Janaye',
    'Jandy',
    'Jane',
    'Janean',
    'Janeczka',
    'Janeen',
    'Janel',
    'Janela',
    'Janella',
    'Janelle',
    'Janene',
    'Janenna',
    'Janessa',
    'Janet',
    'Janeta',
    'Janetta',
    'Janette',
    'Janeva',
    'Janey',
    'Jania',
    'Janice',
    'Janie',
    'Janifer',
    'Janina',
    'Janine',
    'Janis',
    'Janith',
    'Janka',
    'Janna',
    'Jannel',
    'Jannelle',
    'Janot',
    'Jany',
    'Jaquelin',
    'Jaquelyn',
    'Jaquenetta',
    'Jaquenette',
    'Jaquith',
    'Jasmin',
    'Jasmina',
    'Jasmine',
    'Jayme',
    'Jaymee',
    'Jayne',
    'Jaynell',
    'Jazmin',
    'Jean',
    'Jeana',
    'Jeane',
    'Jeanelle',
    'Jeanette',
    'Jeanie',
    'Jeanine',
    'Jeanna',
    'Jeanne',
    'Jeannette',
    'Jeannie',
    'Jeannine',
    'Jehanna',
    'Jelene',
    'Jemie',
    'Jemima',
    'Jemimah',
    'Jemmie',
    'Jemmy',
    'Jen',
    'Jena',
    'Jenda',
    'Jenelle',
    'Jeni',
    'Jenica',
    'Jeniece',
    'Jenifer',
    'Jeniffer',
    'Jenilee',
    'Jenine',
    'Jenn',
    'Jenna',
    'Jennee',
    'Jennette',
    'Jenni',
    'Jennica',
    'Jennie',
    'Jennifer',
    'Jennilee',
    'Jennine',
    'Jenny',
    'Jeralee',
    'Jere',
    'Jeri',
    'Jermaine',
    'Jerrie',
    'Jerrilee',
    'Jerrilyn',
    'Jerrine',
    'Jerry',
    'Jerrylee',
    'Jess',
    'Jessa',
    'Jessalin',
    'Jessalyn',
    'Jessamine',
    'Jessamyn',
    'Jesse',
    'Jesselyn',
    'Jessi',
    'Jessica',
    'Jessie',
    'Jessika',
    'Jessy',
    'Jewel',
    'Jewell',
    'Jewelle',
    'Jill',
    'Jillana',
    'Jillane',
    'Jillayne',
    'Jilleen',
    'Jillene',
    'Jilli',
    'Jillian',
    'Jillie',
    'Jilly',
    'Jinny',
    // "Jo",
    // "Jo Ann",
    // "Jo-Ann",
    // "Jo-Anne",
    // "Joan",
    // "Joana",
    // "Joane",
    // "Joanie",
    // "Joann",
    // "Joanna",
    'Joanne',
    // "Joannes",
    // "Jobey",
    // "Jobi",
    // "Jobie",
    // "Jobina",
    // "Joby",
    // "Jobye",
    // "Jobyna",
    // "Jocelin",
    // "Joceline",
    // "Jocelyn",
    // "Jocelyne",
    // "Jodee",
    // "Jodi",
    // "Jodie",
    // "Jody",
    // "Joeann",
    // "Joela",
    // "Joelie",
    // "Joell",
    // "Joella",
    // "Joelle",
    // "Joellen",
    // "Joelly",
    // "Joellyn",
    // "Joelynn",
    // "Joete",
    // "Joey",
    // "Johanna",
    // "Johannah",
    // "Johna",
    // "Johnath",
    // "Johnette",
    // "Johnna",
    // "Joice",
    // "Jojo",
    // "Jolee",
    // "Joleen",
    // "Jolene",
    // "Joletta",
    // "Joli",
    // "Jolie",
    // "Joline",
    // "Joly",
    // "Jolyn",
    // "Jolynn",
    // "Jonell",
    // "Joni",
    // "Jonie",
    // "Jonis",
    // "Jordain",
    // "Jordan",
    // "Jordana",
    // "Jordanna",
    // "Jorey",
    // "Jori",
    // "Jorie",
    // "Jorrie",
    // "Jorry",
    // "Joscelin",
    // "Josee",
    // "Josefa",
    // "Josefina",
    // "Josepha",
    // "Josephina",
    // "Josephine",
    // "Josey",
    // "Josi",
    // "Josie",
    // "Josselyn",
    // "Josy",
    'Josh',
    'John',
    'Jolene',
    // "Jourdan",
    // "Joy",
    // "Joya",
    // "Joyan",
    // "Joyann",
    // "Joyce",
    // "Joycelin",
    // "Joye",
    'Jsandye',
    'Juana',
    'Juanita',
    'Judi',
    'Judie',
    'Judith',
    'Juditha',
    'Judy',
    'Judye',
    'Juieta',
    'Julee',
    'Juli',
    'Julia',
    'Juliana',
    'Juliane',
    'Juliann',
    'Julianna',
    'Julianne',
    'Julie',
    'Julienne',
    'Juliet',
    'Julieta',
    'Julietta',
    'Juliette',
    'Julina',
    'Juline',
    'Julissa',
    'Julita',
    'June',
    'Junette',
    'Junia',
    'Junie',
    'Junina',
    'Justina',
    'Justine',
    'Justinn',
    'Jyoti',
    'Kacey',
    'Kacie',
    'Kacy',
    'Kaela',
    'Kai',
    'Kaia',
    'Kaila',
    'Kaile',
    'Kailey',
    'Kaitlin',
    'Kaitlyn',
    'Kaitlynn',
    'Kaja',
    'Kakalina',
    'Kala',
    'Kaleena',
    'Kali',
    'Kalie',
    'Kalila',
    'Kalina',
    'Kalinda',
    'Kalindi',
    'Kalli',
    'Kally',
    'Kameko',
    'Kamila',
    'Kamilah',
    'Kamillah',
    'Kandace',
    'Kandy',
    'Kania',
    'Kanya',
    'Kara',
    'Kara-Lynn',
    'Karalee',
    'Karalynn',
    'Kare',
    'Karee',
    'Karel',
    'Karen',
    'Karena',
    'Kari',
    'Karia',
    'Karie',
    'Karil',
    'Karilynn',
    'Karin',
    'Karina',
    'Karine',
    'Kariotta',
    'Karisa',
    'Karissa',
    'Karita',
    'Karla',
    'Karlee',
    'Karleen',
    'Karlen',
    'Karlene',
    'Karlie',
    'Karlotta',
    'Karlotte',
    'Karly',
    'Karlyn',
    'Karmen',
    'Karna',
    'Karol',
    'Karola',
    'Karole',
    'Karolina',
    'Karoline',
    'Karoly',
    'Karon',
    'Karrah',
    'Karrie',
    'Karry',
    'Kary',
    'Karyl',
    'Karylin',
    'Karyn',
    'Kasey',
    'Kass',
    'Kassandra',
    'Kassey',
    'Kassi',
    'Kassia',
    'Kassie',
    'Kat',
    'Kata',
    'Katalin',
    'Kate',
    'Katee',
    'Katerina',
    'Katerine',
    'Katey',
    'Kath',
    'Katha',
    'Katharina',
    'Katharine',
    'Katharyn',
    'Kathe',
    'Katherina',
    'Katherine',
    'Katheryn',
    'Kathi',
    'Kathie',
    'Kathleen',
    'Kathlin',
    'Kathrine',
    'Kathryn',
    'Kathryne',
    'Kathy',
    'Kathye',
    'Kati',
    'Katie',
    'Katina',
    'Katine',
    'Katinka',
    'Katleen',
    'Katlin',
    'Katrina',
    'Katrine',
    'Katrinka',
    'Katti',
    'Kattie',
    'Katuscha',
    'Katusha',
    'Katy',
    'Katya',
    'Kay',
    'Kaycee',
    'Kaye',
    'Kayla',
    'Kayle',
    'Kaylee',
    'Kayley',
    'Kaylil',
    'Kaylyn',
    'Keeley',
    'Keelia',
    'Keely',
    'Kelcey',
    'Kelci',
    'Kelcie',
    'Kelcy',
    'Kelila',
    'Kellen',
    'Kelley',
    'Kelli',
    'Kellia',
    'Kellie',
    'Kellina',
    'Kellsie',
    'Kelly',
    'Kellyann',
    'Kelsey',
    'Kelsi',
    'Kelsy',
    'Kendra',
    'Kendre',
    'Kenna',
    'Keri',
    'Keriann',
    'Kerianne',
    'Kerri',
    'Kerrie',
    'Kerrill',
    'Kerrin',
    'Kerry',
    'Kerstin',
    'Kesley',
    'Keslie',
    'Kessia',
    'Kessiah',
    'Ketti',
    'Kettie',
    'Ketty',
    'Kevina',
    'Kevyn',
    'Ki',
    'Kiah',
    'Kial',
    'Kiele',
    'Kiersten',
    'Kikelia',
    'Kiley',
    'Kim',
    'Kimberlee',
    'Kimberley',
    'Kimberli',
    'Kimberly',
    'Kimberlyn',
    'Kimbra',
    'Kimmi',
    'Kimmie',
    'Kimmy',
    'Kinna',
    'Kip',
    'Kipp',
    'Kippie',
    'Kippy',
    'Kira',
    'Kirbee',
    'Kirbie',
    'Kirby',
    'Kiri',
    'Kirsten',
    'Kirsteni',
    'Kirsti',
    'Kirstin',
    'Kirstyn',
    'Kissee',
    'Kissiah',
    'Kissie',
    'Kit',
    'Kitti',
    'Kittie',
    'Kitty',
    'Kizzee',
    'Kizzie',
    'Klara',
    'Klarika',
    'Klarrisa',
    'Konstance',
    'Konstanze',
    'Koo',
    'Kora',
    'Koral',
    'Koralle',
    'Kordula',
    'Kore',
    'Korella',
    'Koren',
    'Koressa',
    'Kori',
    'Korie',
    'Korney',
    'Korrie',
    'Korry',
    'Kris',
    'Krissie',
    'Krissy',
    'Krista',
    'Kristal',
    'Kristan',
    'Kriste',
    'Kristel',
    'Kristen',
    'Kristi',
    'Kristien',
    'Kristin',
    'Kristina',
    'Kristine',
    'Kristy',
    'Kristyn',
    'Krysta',
    'Krystal',
    'Krystalle',
    'Krystle',
    'Krystyna',
    'Kyla',
    'Kyle',
    'Kylen',
    'Kylie',
    'Kylila',
    'Kylynn',
    'Kym',
    'Kynthia',
    'Kyrstin',
    'La Verne',
    'Lacee',
    'Lacey',
    'Lacie',
    'Lacy',
    'Ladonna',
    'Laetitia',
    'Laina',
    'Lainey',
    'Lana',
    'Lanae',
    'Lane',
    'Lanette',
    'Laney',
    'Lani',
    'Lanie',
    'Lanita',
    'Lanna',
    'Lanni',
    'Lanny',
    'Lara',
    'Laraine',
    'Lari',
    'Larina',
    'Larine',
    'Larisa',
    'Larissa',
    'Lark',
    'Laryssa',
    'Latashia',
    'Latia',
    'Latisha',
    'Latrena',
    'Latrina',
    'Laura',
    'Lauraine',
    'Laural',
    'Lauralee',
    'Laure',
    'Lauree',
    'Laureen',
    'Laurel',
    'Laurella',
    'Lauren',
    'Laurena',
    'Laurene',
    'Lauretta',
    'Laurette',
    'Lauri',
    'Laurianne',
    'Laurice',
    'Laurie',
    'Lauryn',
    'Lavena',
    'Laverna',
    'Laverne',
    'Lavina',
    'Lavinia',
    'Lavinie',
    'Layla',
    'Layne',
    'Layney',
    'Lea',
    'Leah',
    'Leandra',
    'Leann',
    'Leanna',
    'Leanor',
    'Leanora',
    'Lebbie',
    'Leda',
    'Lee',
    'Leeann',
    'Leeanne',
    'Leela',
    'Leelah',
    'Leena',
    'Leesa',
    'Leese',
    'Legra',
    'Leia',
    'Leigh',
    'Leigha',
    'Leila',
    'Leilah',
    'Leisha',
    'Lela',
    'Lelah',
    'Leland',
    'Lelia',
    'Lena',
    'Lenee',
    'Lenette',
    'Lenka',
    'Lenna',
    'Lenora',
    'Lenore',
    'Leodora',
    'Leoine',
    'Leola',
    'Leoline',
    'Leona',
    'Leonanie',
    'Leone',
    'Leonelle',
    'Leonie',
    'Leonora',
    'Leonore',
    'Leontine',
    'Leontyne',
    'Leora',
    'Leshia',
    'Lesley',
    'Lesli',
    'Leslie',
    'Lesly',
    'Lesya',
    'Leta',
    'Lethia',
    'Leticia',
    'Letisha',
    'Letitia',
    'Letizia',
    'Letta',
    'Letti',
    'Lettie',
    'Letty',
    'Lexi',
    'Lexie',
    'Lexine',
    'Lexis',
    'Lexy',
    'Leyla',
    'Lezlie',
    'Lia',
    'Lian',
    'Liana',
    'Liane',
    'Lianna',
    'Lianne',
    'Lib',
    'Libbey',
    'Libbi',
    'Libbie',
    'Libby',
    'Licha',
    'Lida',
    'Lidia',
    'Liesa',
    'Lil',
    'Lila',
    'Lilah',
    'Lilas',
    'Lilia',
    'Lilian',
    'Liliane',
    'Lilias',
    'Lilith',
    'Lilla',
    'Lilli',
    'Lillian',
    'Lillis',
    'Lilllie',
    'Lilly',
    'Lily',
    'Lilyan',
    'Lin',
    'Lina',
    'Lind',
    'Linda',
    'Lindi',
    'Lindie',
    'Lindsay',
    'Lindsey',
    'Lindsy',
    'Lindy',
    'Linea',
    'Linell',
    'Linet',
    'Linette',
    'Linn',
    'Linnea',
    'Linnell',
    'Linnet',
    'Linnie',
    'Linzy',
    'Lira',
    'Lisa',
    'Lisabeth',
    'Lisbeth',
    'Lise',
    'Lisetta',
    'Lisette',
    'Lisha',
    'Lishe',
    'Lissa',
    'Lissi',
    'Lissie',
    'Lissy',
    'Lita',
    'Liuka',
    'Liv',
    'Liva',
    'Livia',
    'Livvie',
    'Livvy',
    'Livvyy',
    'Livy',
    'Liz',
    'Liza',
    'Lizabeth',
    'Lizbeth',
    'Lizette',
    'Lizzie',
    'Lizzy',
    'Loella',
    'Lois',
    'Loise',
    'Lola',
    'Loleta',
    'Lolita',
    'Lolly',
    'Lona',
    'Lonee',
    'Loni',
    'Lonna',
    'Lonni',
    'Lonnie',
    'Lora',
    'Lorain',
    'Loraine',
    'Loralee',
    'Loralie',
    'Loralyn',
    'Loree',
    'Loreen',
    'Lorelei',
    'Lorelle',
    'Loren',
    'Lorena',
    'Lorene',
    'Lorenza',
    'Loretta',
    'Lorette',
    'Lori',
    'Loria',
    'Lorianna',
    'Lorianne',
    'Lorie',
    'Lorilee',
    'Lorilyn',
    'Lorinda',
    'Lorine',
    'Lorita',
    'Lorna',
    'Lorne',
    'Lorraine',
    'Lorrayne',
    'Lorri',
    'Lorrie',
    'Lorrin',
    'Lorry',
    'Lory',
    'Lotta',
    'Lotte',
    'Lotti',
    'Lottie',
    'Lotty',
    'Lou',
    'Louella',
    'Louisa',
    'Louise',
    'Louisette',
    'Loutitia',
    'Lu',
    'Luce',
    'Luci',
    'Lucia',
    'Luciana',
    'Lucie',
    'Lucienne',
    'Lucila',
    'Lucilia',
    'Lucille',
    'Lucina',
    'Lucinda',
    'Lucine',
    'Lucita',
    'Lucky',
    'Lucretia',
    'Lucy',
    'Ludovika',
    'Luella',
    'Luelle',
    'Luisa',
    'Luise',
    'Lula',
    'Lulita',
    'Lulu',
    'Lura',
    'Lurette',
    'Lurleen',
    'Lurlene',
    'Lurline',
    'Lusa',
    'Luz',
    'Lyda',
    'Lydia',
    'Lydie',
    'Lyn',
    'Lynda',
    'Lynde',
    'Lyndel',
    'Lyndell',
    'Lyndsay',
    'Lyndsey',
    'Lyndsie',
    'Lyndy',
    'Lynea',
    'Lynelle',
    'Lynett',
    'Lynette',
    'Lynn',
    'Lynna',
    'Lynne',
    'Lynnea',
    'Lynnell',
    'Lynnelle',
    'Lynnet',
    'Lynnett',
    'Lynnette',
    'Lynsey',
    'Lyssa',
    'Mab',
    'Mabel',
    'Mabelle',
    'Mable',
    'Mada',
    'Madalena',
    'Madalyn',
    'Maddalena',
    'Maddi',
    'Maddie',
    'Maddy',
    'Madel',
    'Madelaine',
    'Madeleine',
    'Madelena',
    'Madelene',
    'Madelin',
    'Madelina',
    'Madeline',
    'Madella',
    'Madelle',
    'Madelon',
    'Madelyn',
    'Madge',
    'Madlen',
    'Madlin',
    'Madonna',
    'Mady',
    'Mae',
    'Maegan',
    'Mag',
    'Magda',
    'Magdaia',
    'Magdalen',
    'Magdalena',
    'Magdalene',
    'Maggee',
    'Maggi',
    'Maggie',
    'Maggy',
    'Mahala',
    'Mahalia',
    'Maia',
    'Maible',
    'Maiga',
    'Maighdiln',
    'Mair',
    'Maire',
    'Maisey',
    'Maisie',
    'Maitilde',
    'Mala',
    'Malanie',
    'Malena',
    'Malia',
    'Malina',
    'Malinda',
    'Malinde',
    'Malissa',
    'Malissia',
    'Mallissa',
    'Mallorie',
    'Mallory',
    'Malorie',
    'Malory',
    'Malva',
    'Malvina',
    'Malynda',
    'Mame',
    'Mamie',
    'Manda',
    'Mandi',
    'Mandie',
    'Mandy',
    'Manon',
    'Manya',
    'Mara',
    'Marabel',
    'Marcela',
    'Marcelia',
    'Marcella',
    'Marcelle',
    'Marcellina',
    'Marcelline',
    'Marchelle',
    'Marci',
    'Marcia',
    'Marcie',
    'Marcile',
    'Marcille',
    'Marcy',
    'Mareah',
    'Maren',
    'Marena',
    'Maressa',
    'Marga',
    'Margalit',
    'Margalo',
    'Margaret',
    'Margareta',
    'Margarete',
    'Margaretha',
    'Margarethe',
    'Margaretta',
    'Margarette',
    'Margarita',
    'Margaux',
    'Marge',
    'Margeaux',
    'Margery',
    'Marget',
    'Margette',
    'Margi',
    'Margie',
    'Margit',
    'Margo',
    'Margot',
    'Margret',
    'Marguerite',
    'Margy',
    'Mari',
    'Maria',
    'Mariam',
    'Marian',
    'Mariana',
    'Mariann',
    'Marianna',
    'Marianne',
    'Maribel',
    'Maribelle',
    'Maribeth',
    'Marice',
    'Maridel',
    'Marie',
    'Marie-Ann',
    'Marie-Jeanne',
    'Marieann',
    'Mariejeanne',
    'Mariel',
    'Mariele',
    'Marielle',
    'Mariellen',
    'Marietta',
    'Mariette',
    'Marigold',
    'Marijo',
    'Marika',
    'Marilee',
    'Marilin',
    'Marillin',
    'Marilyn',
    'Marin',
    'Marina',
    'Marinna',
    'Marion',
    'Mariquilla',
    'Maris',
    'Marisa',
    'Mariska',
    'Marissa',
    'Marita',
    'Maritsa',
    'Mariya',
    'Marj',
    'Marja',
    'Marje',
    'Marji',
    'Marjie',
    'Marjorie',
    'Marjory',
    'Marjy',
    'Marketa',
    'Marla',
    'Marlane',
    'Marleah',
    'Marlee',
    'Marleen',
    'Marlena',
    'Marlene',
    'Marley',
    'Marlie',
    'Marline',
    'Marlo',
    'Marlyn',
    'Marna',
    'Marne',
    'Marney',
    'Marni',
    'Marnia',
    'Marnie',
    'Marquita',
    'Marrilee',
    'Marris',
    'Marrissa',
    'Marsha',
    'Marsiella',
    'Marta',
    'Martelle',
    'Martguerita',
    'Martha',
    'Marthe',
    'Marthena',
    'Marti',
    'Martica',
    'Martie',
    'Martina',
    'Martita',
    'Marty',
    'Martynne',
    'Mary',
    'Marya',
    'Maryann',
    'Maryanna',
    'Maryanne',
    'Marybelle',
    'Marybeth',
    'Maryellen',
    'Maryjane',
    'Maryjo',
    'Maryl',
    'Marylee',
    'Marylin',
    'Marylinda',
    'Marylou',
    'Marylynne',
    'Maryrose',
    'Marys',
    'Marysa',
    'Masha',
    'Matelda',
    'Mathilda',
    'Mathilde',
    'Matilda',
    'Matilde',
    'Matti',
    'Mattie',
    'Matty',
    'Maud',
    'Maude',
    'Maudie',
    'Maura',
    'Maure',
    'Maureen',
    'Maureene',
    'Maurene',
    'Maurine',
    'Maurise',
    'Maurita',
    'Maurizia',
    'Mavis',
    'Mavra',
    'Max',
    'Maxi',
    'Maxie',
    'Maxine',
    'Maxy',
    'May',
    'Maybelle',
    'Maye',
    'Mead',
    'Meade',
    'Meagan',
    'Meaghan',
    'Meara',
    'Mechelle',
    'Meg',
    'Megan',
    'Megen',
    'Meggi',
    'Meggie',
    'Meggy',
    'Meghan',
    'Meghann',
    'Mehetabel',
    'Mei',
    'Mel',
    'Mela',
    'Melamie',
    'Melania',
    'Melanie',
    'Melantha',
    'Melany',
    'Melba',
    'Melesa',
    'Melessa',
    'Melicent',
    'Melina',
    'Melinda',
    'Melinde',
    'Melisa',
    'Melisande',
    'Melisandra',
    'Melisenda',
    'Melisent',
    'Melissa',
    'Melisse',
    'Melita',
    'Melitta',
    'Mella',
    'Melli',
    'Mellicent',
    'Mellie',
    'Mellisa',
    'Mellisent',
    'Melloney',
    'Melly',
    'Melodee',
    'Melodie',
    'Melody',
    'Melonie',
    'Melony',
    'Melosa',
    'Melva',
    'Mercedes',
    'Merci',
    'Mercie',
    'Mercy',
    'Meredith',
    'Meredithe',
    'Meridel',
    'Meridith',
    'Meriel',
    'Merilee',
    'Merilyn',
    'Meris',
    'Merissa',
    'Merl',
    'Merla',
    'Merle',
    'Merlina',
    'Merline',
    'Merna',
    'Merola',
    'Merralee',
    'Merridie',
    'Merrie',
    'Merrielle',
    'Merrile',
    'Merrilee',
    'Merrili',
    'Merrill',
    'Merrily',
    'Merry',
    'Mersey',
    'Meryl',
    'Meta',
    'Mia',
    'Micaela',
    'Michaela',
    'Michaelina',
    'Michaeline',
    'Michaella',
    'Michal',
    'Michel',
    'Michele',
    'Michelina',
    'Micheline',
    'Michell',
    'Michelle',
    'Micki',
    'Mickie',
    'Micky',
    'Midge',
    'Mignon',
    'Mignonne',
    'Miguela',
    'Miguelita',
    'Mikaela',
    'Mil',
    'Mildred',
    'Mildrid',
    'Milena',
    'Milicent',
    'Milissent',
    'Milka',
    'Milli',
    'Millicent',
    'Millie',
    'Millisent',
    'Milly',
    'Milzie',
    'Mimi',
    'Min',
    'Mina',
    'Minda',
    'Mindy',
    'Minerva',
    'Minetta',
    'Minette',
    'Minna',
    'Minnaminnie',
    'Minne',
    'Minni',
    'Minnie',
    'Minnnie',
    'Minny',
    'Minta',
    'Miof Mela',
    'Miquela',
    'Mira',
    'Mirabel',
    'Mirabella',
    'Mirabelle',
    'Miran',
    'Miranda',
    'Mireielle',
    'Mireille',
    'Mirella',
    'Mirelle',
    'Miriam',
    'Mirilla',
    'Mirna',
    'Misha',
    'Missie',
    'Missy',
    'Misti',
    'Misty',
    'Mitzi',
    'Modesta',
    'Modestia',
    'Modestine',
    'Modesty',
    'Moina',
    'Moira',
    'Moll',
    'Mollee',
    'Molli',
    'Mollie',
    'Molly',
    'Mommy',
    'Mona',
    'Monah',
    'Monica',
    'Monika',
    'Monique',
    'Mora',
    'Moreen',
    'Morena',
    'Morgan',
    'Morgana',
    'Morganica',
    'Morganne',
    'Morgen',
    'Moria',
    'Morissa',
    'Morna',
    'Moselle',
    'Moyna',
    'Moyra',
    'Mozelle',
    'Muffin',
    'Mufi',
    'Mufinella',
    'Muire',
    'Mureil',
    'Murial',
    'Muriel',
    'Murielle',
    'Myra',
    'Myrah',
    'Myranda',
    'Myriam',
    'Myrilla',
    'Myrle',
    'Myrlene',
    'Myrna',
    'Myrta',
    'Myrtia',
    'Myrtice',
    'Myrtie',
    'Myrtle',
    'Nada',
    'Nadean',
    'Nadeen',
    'Nadia',
    'Nadine',
    'Nadiya',
    'Nady',
    'Nadya',
    'Nalani',
    'Nan',
    'Nana',
    'Nananne',
    'Nance',
    'Nancee',
    'Nancey',
    'Nanci',
    'Nancie',
    'Nancy',
    'Nanete',
    'Nanette',
    'Nani',
    'Nanice',
    'Nanine',
    'Nannette',
    'Nanni',
    'Nannie',
    'Nanny',
    'Nanon',
    'Naoma',
    'Naomi',
    'Nara',
    'Nari',
    'Nariko',
    'Nat',
    'Nata',
    'Natala',
    'Natalee',
    'Natalie',
    'Natalina',
    'Nataline',
    'Natalya',
    'Natasha',
    'Natassia',
    'Nathalia',
    'Nathalie',
    'Natividad',
    'Natka',
    'Natty',
    'Neala',
    'Neda',
    'Nedda',
    'Nedi',
    'Neely',
    'Neila',
    'Neile',
    'Neilla',
    'Neille',
    'Nelia',
    'Nelie',
    'Nell',
    'Nelle',
    'Nelli',
    'Nellie',
    'Nelly',
    'Nerissa',
    'Nerita',
    'Nert',
    'Nerta',
    'Nerte',
    'Nerti',
    'Nertie',
    'Nerty',
    'Nessa',
    'Nessi',
    'Nessie',
    'Nessy',
    'Nesta',
    'Netta',
    'Netti',
    'Nettie',
    'Nettle',
    'Netty',
    'Nevsa',
    'Neysa',
    'Nichol',
    'Nichole',
    'Nicholle',
    'Nicki',
    'Nickie',
    'Nicky',
    'Nicol',
    'Nicola',
    'Nicole',
    'Nicolea',
    'Nicolette',
    'Nicoli',
    'Nicolina',
    'Nicoline',
    'Nicolle',
    'Nikaniki',
    'Nike',
    'Niki',
    'Nikki',
    'Nikkie',
    'Nikoletta',
    'Nikolia',
    'Nina',
    'Ninetta',
    'Ninette',
    'Ninnetta',
    'Ninnette',
    'Ninon',
    'Nissa',
    'Nisse',
    'Nissie',
    'Nissy',
    'Nita',
    'Nixie',
    'Noami',
    'Noel',
    'Noelani',
    'Noell',
    'Noella',
    'Noelle',
    'Noellyn',
    'Noelyn',
    'Noemi',
    'Nola',
    'Nolana',
    'Nolie',
    'Nollie',
    'Nomi',
    'Nona',
    'Nonah',
    'Noni',
    'Nonie',
    'Nonna',
    'Nonnah',
    'Nora',
    'Norah',
    'Norean',
    'Noreen',
    'Norene',
    'Norina',
    'Norine',
    'Norma',
    'Norri',
    'Norrie',
    'Norry',
    'Novelia',
    'Nydia',
    'Nyssa',
    'Octavia',
    'Odele',
    'Odelia',
    'Odelinda',
    'Odella',
    'Odelle',
    'Odessa',
    'Odetta',
    'Odette',
    'Odilia',
    'Odille',
    'Ofelia',
    'Ofella',
    'Ofilia',
    'Ola',
    'Olenka',
    'Olga',
    'Olia',
    'Olimpia',
    'Olive',
    'Olivette',
    'Olivia',
    'Olivie',
    'Oliy',
    'Ollie',
    'Olly',
    'Olva',
    'Olwen',
    'Olympe',
    'Olympia',
    'Olympie',
    'Ondrea',
    'Oneida',
    'Onida',
    'Oona',
    'Opal',
    'Opalina',
    'Opaline',
    'Ophelia',
    'Ophelie',
    'Ora',
    'Oralee',
    'Oralia',
    'Oralie',
    'Oralla',
    'Oralle',
    'Orel',
    'Orelee',
    'Orelia',
    'Orelie',
    'Orella',
    'Orelle',
    'Oriana',
    'Orly',
    'Orsa',
    'Orsola',
    'Ortensia',
    'Otha',
    'Othelia',
    'Othella',
    'Othilia',
    'Othilie',
    'Ottilie',
    'Page',
    'Paige',
    'Paloma',
    'Pam',
    'Pamela',
    'Pamelina',
    'Pamella',
    'Pammi',
    'Pammie',
    'Pammy',
    'Pandora',
    'Pansie',
    'Pansy',
    'Paola',
    'Paolina',
    'Papagena',
    'Pat',
    'Patience',
    'Patrica',
    'Patrice',
    'Patricia',
    'Patrizia',
    'Patsy',
    'Patti',
    'Pattie',
    'Patty',
    'Paula',
    'Paule',
    'Pauletta',
    'Paulette',
    'Pauli',
    'Paulie',
    'Paulina',
    'Pauline',
    'Paulita',
    'Pauly',
    'Pavia',
    'Pavla',
    'Pearl',
    'Pearla',
    'Pearle',
    'Pearline',
    'Peg',
    'Pegeen',
    'Peggi',
    'Peggie',
    'Peggy',
    'Pen',
    'Penelopa',
    'Penelope',
    'Penni',
    'Pennie',
    'Penny',
    'Pepi',
    'Pepita',
    'Peri',
    'Peria',
    'Perl',
    'Perla',
    'Perle',
    'Perri',
    'Perrine',
    'Perry',
    'Persis',
    'Pet',
    'Peta',
    'Petra',
    'Petrina',
    'Petronella',
    'Petronia',
    'Petronilla',
    'Petronille',
    'Petunia',
    'Phaedra',
    'Phaidra',
    'Phebe',
    'Phedra',
    'Phelia',
    'Phil',
    'Philipa',
    'Philippa',
    'Philippe',
    'Philippine',
    'Philis',
    'Phillida',
    'Phillie',
    'Phillis',
    'Philly',
    'Philomena',
    'Phoebe',
    'Phylis',
    'Phyllida',
    'Phyllis',
    'Phyllys',
    'Phylys',
    'Pia',
    'Pier',
    'Pierette',
    'Pierrette',
    'Pietra',
    'Piper',
    'Pippa',
    'Pippy',
    'Polly',
    'Pollyanna',
    'Pooh',
    'Poppy',
    'Portia',
    'Pris',
    'Prisca',
    'Priscella',
    'Priscilla',
    'Prissie',
    'Pru',
    'Prudence',
    'Prudi',
    'Prudy',
    'Prue',
    'Queenie',
    'Quentin',
    'Querida',
    'Quinn',
    'Quinta',
    'Quintana',
    'Quintilla',
    'Quintina',
    'Rachael',
    'Rachel',
    'Rachele',
    'Rachelle',
    'Rae',
    'Raeann',
    'Raf',
    'Rafa',
    'Rafaela',
    'Rafaelia',
    'Rafaelita',
    'Rahal',
    'Rahel',
    'Raina',
    'Raine',
    'Rakel',
    'Ralina',
    'Ramona',
    'Ramonda',
    'Rana',
    'Randa',
    'Randee',
    'Randene',
    'Randi',
    'Randie',
    'Randy',
    'Ranee',
    'Rani',
    'Rania',
    'Ranice',
    'Ranique',
    'Ranna',
    'Raphaela',
    'Raquel',
    'Raquela',
    'Rasia',
    'Rasla',
    'Raven',
    'Ray',
    'Raychel',
    'Raye',
    'Rayna',
    'Raynell',
    'Rayshell',
    'Rea',
    'Reba',
    'Rebbecca',
    'Rebe',
    'Rebeca',
    'Rebecca',
    'Rebecka',
    'Rebeka',
    'Rebekah',
    'Rebekkah',
    'Ree',
    'Reeba',
    'Reena',
    'Reeta',
    'Reeva',
    'Regan',
    'Reggi',
    'Reggie',
    'Regina',
    'Regine',
    'Reiko',
    'Reina',
    'Reine',
    'Remy',
    'Rena',
    'Renae',
    'Renata',
    'Renate',
    'Rene',
    'Renee',
    'Renell',
    'Renelle',
    'Renie',
    'Rennie',
    'Reta',
    'Retha',
    'Revkah',
    'Rey',
    'Reyna',
    'Rhea',
    'Rheba',
    'Rheta',
    'Rhetta',
    'Rhiamon',
    'Rhianna',
    'Rhianon',
    'Rhoda',
    'Rhodia',
    'Rhodie',
    'Rhody',
    'Rhona',
    'Rhonda',
    'Riane',
    'Riannon',
    'Rianon',
    'Rica',
    'Ricca',
    'Rici',
    'Ricki',
    'Rickie',
    'Ricky',
    'Riki',
    'Rikki',
    'Rina',
    'Risa',
    'Rita',
    'Riva',
    'Rivalee',
    'Rivi',
    'Rivkah',
    'Rivy',
    'Roana',
    'Roanna',
    'Roanne',
    'Robbi',
    'Robbie',
    'Robbin',
    'Robby',
    'Robbyn',
    'Robena',
    'Robenia',
    'Roberta',
    'Robin',
    'Robina',
    'Robinet',
    'Robinett',
    'Robinetta',
    'Robinette',
    'Robinia',
    'Roby',
    'Robyn',
    'Roch',
    'Rochell',
    'Rochella',
    'Rochelle',
    'Rochette',
    'Roda',
    'Rodi',
    'Rodie',
    'Rodina',
    'Rois',
    'Romola',
    'Romona',
    'Romonda',
    'Romy',
    'Rona',
    'Ronalda',
    'Ronda',
    'Ronica',
    'Ronna',
    'Ronni',
    'Ronnica',
    'Ronnie',
    'Ronny',
    'Roobbie',
    'Rora',
    'Rori',
    'Rorie',
    'Rory',
    'Ros',
    'Rosa',
    'Rosabel',
    'Rosabella',
    'Rosabelle',
    'Rosaleen',
    'Rosalia',
    'Rosalie',
    'Rosalind',
    'Rosalinda',
    'Rosalinde',
    'Rosaline',
    'Rosalyn',
    'Rosalynd',
    'Rosamond',
    'Rosamund',
    'Rosana',
    'Rosanna',
    'Rosanne',
    'Rose',
    'Roseann',
    'Roseanna',
    'Roseanne',
    'Roselia',
    'Roselin',
    'Roseline',
    'Rosella',
    'Roselle',
    'Rosemaria',
    'Rosemarie',
    'Rosemary',
    'Rosemonde',
    'Rosene',
    'Rosetta',
    'Rosette',
    'Roshelle',
    'Rosie',
    'Rosina',
    'Rosita',
    'Roslyn',
    'Rosmunda',
    'Rosy',
    'Row',
    'Rowe',
    'Rowena',
    'Roxana',
    'Roxane',
    'Roxanna',
    'Roxanne',
    'Roxi',
    'Roxie',
    'Roxine',
    'Roxy',
    'Roz',
    'Rozalie',
    'Rozalin',
    'Rozamond',
    'Rozanna',
    'Rozanne',
    'Roze',
    'Rozele',
    'Rozella',
    'Rozelle',
    'Rozina',
    'Rubetta',
    'Rubi',
    'Rubia',
    'Rubie',
    'Rubina',
    'Ruby',
    'Ruperta',
    'Ruth',
    'Ruthann',
    'Ruthanne',
    'Ruthe',
    'Ruthi',
    'Ruthie',
    'Ruthy',
    'Ryann',
    'Rycca',
    'Saba',
    'Sabina',
    'Sabine',
    'Sabra',
    'Sabrina',
    'Sacha',
    'Sada',
    'Sadella',
    'Sadie',
    'Sadye',
    'Saidee',
    'Sal',
    'Salaidh',
    'Sallee',
    'Salli',
    'Sallie',
    'Sally',
    'Sallyann',
    'Sallyanne',
    'Saloma',
    'Salome',
    'Salomi',
    'Sam',
    'Samantha',
    'Samara',
    'Samaria',
    'Sammy',
    'Sande',
    'Sandi',
    'Sandie',
    'Sandra',
    'Sandy',
    'Sandye',
    'Sapphira',
    'Sapphire',
    'Sara',
    'Sara-Ann',
    'Saraann',
    'Sarah',
    'Sarajane',
    'Saree',
    'Sarena',
    'Sarene',
    'Sarette',
    'Sari',
    'Sarina',
    'Sarine',
    'Sarita',
    'Sascha',
    'Sasha',
    'Sashenka',
    'Saudra',
    'Saundra',
    'Savina',
    'Sayre',
    'Scarlet',
    'Scarlett',
    'Sean',
    'Seana',
    'Seka',
    'Sela',
    'Selena',
    'Selene',
    'Selestina',
    'Selia',
    'Selie',
    'Selina',
    'Selinda',
    'Seline',
    'Sella',
    'Selle',
    'Selma',
    'Sena',
    'Sephira',
    'Serena',
    'Serene',
    'Shae',
    'Shaina',
    'Shaine',
    'Shalna',
    'Shalne',
    'Shana',
    'Shanda',
    'Shandee',
    'Shandeigh',
    'Shandie',
    'Shandra',
    'Shandy',
    'Shane',
    'Shani',
    'Shanie',
    'Shanna',
    'Shannah',
    'Shannen',
    'Shannon',
    'Shanon',
    'Shanta',
    'Shantee',
    'Shara',
    'Sharai',
    'Shari',
    'Sharia',
    'Sharity',
    'Sharl',
    'Sharla',
    'Sharleen',
    'Sharlene',
    'Sharline',
    'Sharon',
    'Sharona',
    'Sharron',
    'Sharyl',
    'Shaun',
    'Shauna',
    'Shawn',
    'Shawna',
    'Shawnee',
    'Shay',
    'Shayla',
    'Shaylah',
    'Shaylyn',
    'Shaylynn',
    'Shayna',
    'Shayne',
    'Shea',
    'Sheba',
    'Sheela',
    'Sheelagh',
    'Sheelah',
    'Sheena',
    'Sheeree',
    'Sheila',
    'Sheila-Kathryn',
    'Sheilah',
    'Shel',
    'Shela',
    'Shelagh',
    'Shelba',
    'Shelbi',
    'Shelby',
    'Shelia',
    'Shell',
    'Shelley',
    'Shelli',
    'Shellie',
    'Shelly',
    'Shena',
    'Sher',
    'Sheree',
    'Sheri',
    'Sherie',
    'Sherill',
    'Sherilyn',
    'Sherline',
    'Sherri',
    'Sherrie',
    'Sherry',
    'Sherye',
    'Sheryl',
    'Shina',
    'Shir',
    'Shirl',
    'Shirlee',
    'Shirleen',
    'Shirlene',
    'Shirley',
    'Shirline',
    'Shoshana',
    'Shoshanna',
    'Siana',
    'Sianna',
    'Sib',
    'Sibbie',
    'Sibby',
    'Sibeal',
    'Sibel',
    'Sibella',
    'Sibelle',
    'Sibilla',
    'Sibley',
    'Sibyl',
    'Sibylla',
    'Sibylle',
    'Sidoney',
    'Sidonia',
    'Sidonnie',
    'Sigrid',
    'Sile',
    'Sileas',
    'Silva',
    'Silvana',
    'Silvia',
    'Silvie',
    'Simona',
    'Simone',
    'Simonette',
    'Simonne',
    'Sindee',
    'Siobhan',
    'Sioux',
    'Siouxie',
    'Sisely',
    'Sisile',
    'Sissie',
    'Sissy',
    'Siusan',
    'Sofia',
    'Sofie',
    'Sondra',
    'Sonia',
    'Sonja',
    'Sonni',
    'Sonnie',
    'Sonnnie',
    'Sonny',
    'Sonya',
    'Sophey',
    'Sophi',
    'Sophia',
    'Sophie',
    'Sophronia',
    'Sorcha',
    'Sosanna',
    'Stace',
    'Stacee',
    'Stacey',
    'Staci',
    'Stacia',
    'Stacie',
    'Stacy',
    'Stafani',
    'Star',
    'Starla',
    'Starlene',
    'Starlin',
    'Starr',
    'Stefa',
    'Stefania',
    'Stefanie',
    'Steffane',
    'Steffi',
    'Steffie',
    'Stella',
    'Stepha',
    'Stephana',
    'Stephani',
    'Stephanie',
    'Stephannie',
    'Stephenie',
    'Stephi',
    'Stephie',
    'Stephine',
    'Stesha',
    'Stevana',
    'Stevena',
    'Stoddard',
    'Storm',
    'Stormi',
    'Stormie',
    'Stormy',
    'Sue',
    'Suellen',
    'Sukey',
    'Suki',
    'Sula',
    'Sunny',
    'Sunshine',
    'Susan',
    'Susana',
    'Susanetta',
    'Susann',
    'Susanna',
    'Susannah',
    'Susanne',
    'Susette',
    'Susi',
    'Susie',
    'Susy',
    'Suzann',
    'Suzanna',
    'Suzanne',
    'Suzette',
    'Suzi',
    'Suzie',
    'Suzy',
    'Sybil',
    'Sybila',
    'Sybilla',
    'Sybille',
    'Sybyl',
    'Sydel',
    'Sydelle',
    'Sydney',
    'Sylvia',
    'Tabatha',
    'Tabbatha',
    'Tabbi',
    'Tabbie',
    'Tabbitha',
    'Tabby',
    'Tabina',
    'Tabitha',
    'Taffy',
    'Talia',
    'Tallia',
    'Tallie',
    'Tallou',
    'Tallulah',
    'Tally',
    'Talya',
    'Talyah',
    'Tamar',
    'Tamara',
    'Tamarah',
    'Tamarra',
    'Tamera',
    'Tami',
    'Tamiko',
    'Tamma',
    'Tammara',
    'Tammi',
    'Tammie',
    'Tammy',
    'Tamqrah',
    'Tamra',
    'Tana',
    'Tandi',
    'Tandie',
    'Tandy',
    'Tanhya',
    'Tani',
    'Tania',
    'Tanitansy',
    'Tansy',
    'Tanya',
    'Tara',
    'Tarah',
    'Tarra',
    'Tarrah',
    'Taryn',
    'Tasha',
    'Tasia',
    'Tate',
    'Tatiana',
    'Tatiania',
    'Tatum',
    'Tawnya',
    'Tawsha',
    'Ted',
    'Tedda',
    'Teddi',
    'Teddie',
    'Teddy',
    'Tedi',
    'Tedra',
    'Teena',
    'TEirtza',
    'Teodora',
    'Tera',
    'Teresa',
    'Terese',
    'Teresina',
    'Teresita',
    'Teressa',
    'Teri',
    'Teriann',
    'Terra',
    'Terri',
    'Terrie',
    'Terrijo',
    'Terry',
    'Terrye',
    'Tersina',
    'Terza',
    'Tess',
    'Tessa',
    'Tessi',
    'Tessie',
    'Tessy',
    'Thalia',
    'Thea',
    'Theadora',
    'Theda',
    'Thekla',
    'Thelma',
    'Theo',
    'Theodora',
    'Theodosia',
    'Theresa',
    'Therese',
    'Theresina',
    'Theresita',
    'Theressa',
    'Therine',
    'Thia',
    'Thomasa',
    'Thomasin',
    'Thomasina',
    'Thomasine',
    'Tiena',
    'Tierney',
    'Tiertza',
    'Tiff',
    'Tiffani',
    'Tiffanie',
    'Tiffany',
    'Tiffi',
    'Tiffie',
    'Tiffy',
    'Tilda',
    'Tildi',
    'Tildie',
    'Tildy',
    'Tillie',
    'Tilly',
    'Tim',
    'Timi',
    'Timmi',
    'Timmie',
    'Timmy',
    'Timothea',
    'Tina',
    'Tine',
    'Tiphani',
    'Tiphanie',
    'Tiphany',
    'Tish',
    'Tisha',
    'Tobe',
    'Tobey',
    'Tobi',
    'Toby',
    'Tobye',
    'Toinette',
    'Toma',
    'Tomasina',
    'Tomasine',
    'Tomi',
    'Tommi',
    'Tommie',
    'Tommy',
    'Toni',
    'Tonia',
    'Tonie',
    'Tony',
    'Tonya',
    'Tonye',
    'Tootsie',
    'Torey',
    'Tori',
    'Torie',
    'Torrie',
    'Tory',
    'Tova',
    'Tove',
    'Tracee',
    'Tracey',
    'Traci',
    'Tracie',
    'Tracy',
    'Trenna',
    'Tresa',
    'Trescha',
    'Tressa',
    'Tricia',
    'Trina',
    'Trish',
    'Trisha',
    'Trista',
    'Trix',
    'Trixi',
    'Trixie',
    'Trixy',
    'Truda',
    'Trude',
    'Trudey',
    'Trudi',
    'Trudie',
    'Trudy',
    'Trula',
    'Tuesday',
    'Twila',
    'Twyla',
    'Tybi',
    'Tybie',
    'Tyne',
    'Ula',
    'Ulla',
    'Ulrica',
    'Ulrika',
    'Ulrikaumeko',
    'Ulrike',
    'Umeko',
    'Una',
    'Ursa',
    'Ursala',
    'Ursola',
    'Ursula',
    'Ursulina',
    'Ursuline',
    'Uta',
    'Val',
    'Valaree',
    'Valaria',
    'Vale',
    'Valeda',
    'Valencia',
    'Valene',
    'Valenka',
    'Valentia',
    'Valentina',
    'Valentine',
    'Valera',
    'Valeria',
    'Valerie',
    'Valery',
    'Valerye',
    'Valida',
    'Valina',
    'Valli',
    'Vallie',
    'Vally',
    'Valma',
    'Valry',
    'Van',
    'Vanda',
    'Vanessa',
    'Vania',
    'Vanna',
    'Vanni',
    'Vannie',
    'Vanny',
    'Vanya',
    'Veda',
    'Velma',
    'Velvet',
    'Venita',
    'Venus',
    'Vera',
    'Veradis',
    'Vere',
    'Verena',
    'Verene',
    'Veriee',
    'Verile',
    'Verina',
    'Verine',
    'Verla',
    'Verna',
    'Vernice',
    'Veronica',
    'Veronika',
    'Veronike',
    'Veronique',
    'Vevay',
    'Vi',
    'Vicki',
    'Vickie',
    'Vicky',
    'Victoria',
    'Vida',
    'Viki',
    'Vikki',
    'Vikky',
    'Vilhelmina',
    'Vilma',
    'Vin',
    'Vina',
    'Vinita',
    'Vinni',
    'Vinnie',
    'Vinny',
    'Viola',
    'Violante',
    'Viole',
    'Violet',
    'Violetta',
    'Violette',
    'Virgie',
    'Virgina',
    'Virginia',
    'Virginie',
    'Vita',
    'Vitia',
    'Vitoria',
    'Vittoria',
    'Viv',
    'Viva',
    'Vivi',
    'Vivia',
    'Vivian',
    'Viviana',
    'Vivianna',
    'Vivianne',
    'Vivie',
    'Vivien',
    'Viviene',
    'Vivienne',
    'Viviyan',
    'Vivyan',
    'Vivyanne',
    'Vonni',
    'Vonnie',
    'Vonny',
    'Vyky',
    'Wallie',
    'Wallis',
    'Walliw',
    'Wally',
    'Waly',
    'Wanda',
    'Wandie',
    'Wandis',
    'Waneta',
    'Wanids',
    'Wenda',
    'Wendeline',
    'Wendi',
    'Wendie',
    'Wendy',
    'Wendye',
    'Wenona',
    'Wenonah',
    'Whitney',
    'Wileen',
    'Wilhelmina',
    'Wilhelmine',
    'Wilie',
    'Willa',
    'Willabella',
    'Willamina',
    'Willetta',
    'Willette',
    'Willi',
    'Willie',
    'Willow',
    'Willy',
    'Willyt',
    'Wilma',
    'Wilmette',
    'Wilona',
    'Wilone',
    'Wilow',
    'Windy',
    'Wini',
    'Winifred',
    'Winna',
    'Winnah',
    'Winne',
    'Winni',
    'Winnie',
    'Winnifred',
    'Winny',
    'Winona',
    'Winonah',
    'Wren',
    'Wrennie',
    'Wylma',
    'Wynn',
    'Wynne',
    'Wynnie',
    'Wynny',
    'Xaviera',
    'Xena',
    'Xenia',
    'Xylia',
    'Xylina',
    'Yalonda',
    'Yasmeen',
    'Yasmin',
    'Yelena',
    'Yetta',
    'Yettie',
    'Yetty',
    'Yevette',
    'Ynes',
    'Ynez',
    'Yoko',
    'Yolanda',
    'Yolande',
    'Yolane',
    'Yolanthe',
    'Yoshi',
    'Yoshiko',
    'Yovonnda',
    'Ysabel',
    'Yvette',
    'Yvonne',
    'Zabrina',
    'Zahara',
    'Zandra',
    'Zaneta',
    'Zara',
    'Zarah',
    'Zaria',
    'Zarla',
    'Zea',
    'Zelda',
    'Zelma',
    'Zena',
    'Zenia',
    'Zia',
    'Zilvia',
    'Zita',
    'Zitella',
    'Zoe',
    'Zola',
    'Zonda',
    'Zondra',
    'Zonnya',
    'Zora',
    'Zorah',
    'Zorana',
    'Zorina',
    'Zorine',
    'Zsa Zsa',
    'Zsazsa',
    'Zulema',
    'Zuzana',
  ],
  filteredArr: [],
  writingMessage: '',
  timeSelectData: {
    monthDayArr: [
      'Wed 1 Jul',
      'Thu 2 Jul',
      'Fri 3 Jul',
      'Sat 4 Jul',
      'Sun 5 Jul',
      'Mon 6 Jul',
      'Tue 7 Jul',
      'Wed 8 Jul',
      'Thu 9 Jul',
      'Fri 10 Jul',
      'Sat 11 Jul',
      'Sun 12 Jul',
      'Mon 13 Jul',
      'Tue 14 Jul',
      'Wed 15 Jul',
      'Thu 16 Jul',
      'Fri 17 Jul',
      'Sat 18 Jul',
      'Sun 19 Jul',
      'Mon 20 Jul',
      'Tue 21 Jul',
      'Wed 22 Jul',
      'Thu 23 Jul',
      'Fri 24 Jul',
      'Sat 25 Jul',
      'Sun 26 Jul',
      'Mon 27 Jul',
      'Tue 28 Jul',
      'Wed 29 Jul',
      'Thu 30 Jul',
      'Fri 31 Jul',
    ],
    hourArr: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    minutesArr: ['15', '30', '45', '00'],
    dayPartArr: ['am', 'pm'],
  },
  pickUp: {
    navBar: [
      { slug: 'phone', name: 'Phone Number', url: '/pick-up/customer-phone' },
      { slug: 'name', name: 'Customer Name', url: '/pick-up/customer-name' },
    ],
    data: {
      // phone: '0429 117 406',
      // phoneStatus: true,
      // name: 'Johny Appleseed',
      phone: '',
      phoneStatus: false,
      name: '',
      notes: '',
      timeUpdate: false,
      timeIndex: {
        monthDay: 5,
        hour: 2,
        minutes: 3,
        dayPart: 1,
      },
    },
    modal: false,
  },
  delivery: {
    navBar: [
      { slug: 'phone', name: 'Phone Number', url: '/delivery/customer-phone' },
      {
        slug: 'address',
        name: 'Delivery Address',
        url: '/delivery/customer-address',
      },
      { slug: 'name', name: 'Customer Name', url: '/delivery/customer-name' },
    ],
    data: {
      phone: '',
      phoneStatus: false,
      name: '',
      location: '',
      tempLocationLabel: '2 Malo',
      initLocation: '2 Malo',
      locationStatus: false,
      floor: 'Floor 2',
      notes: '',
      timeUpdate: false,
      timeIndex: {
        monthDay: 5,
        hour: 2,
        minutes: 3,
        dayPart: 1,
      },
    },
    modal: false,
  },
  walkIn: {
    data: {
      name: '',
      phone: '',
      notes: '',
    },
  },
  tableView: {
    daysArr: [
      { id: 1, dayId: 9, label: 'Today, 9 Jul', status: true },
      { id: 2, dayId: 10, label: '10th of July', status: false },
      { id: 3, dayId: 11, label: '11th of Jul', status: false },
      { id: 4, dayId: 12, label: '12th of Jul', status: false },
      { id: 5, dayId: 13, label: '13th of Jul', status: false },
      { id: 6, dayId: 14, label: '14th of Jul', status: false },
      { id: 7, dayId: 15, label: '15th of Jul', status: false },
      { id: 8, dayId: 16, label: '16th of Jul', status: false },
      { id: 9, dayId: 17, label: '17th of Jul', status: false },
      { id: 10, dayId: 18, label: '18th of Jul', status: false },
      { id: 11, dayId: 19, label: '19th of Jul', status: false },
      { id: 12, dayId: 20, label: '20th of Jul', status: false },
      { id: 13, dayId: 21, label: '21st of Jul', status: false },
      { id: 14, dayId: 22, label: '22nd of Jul', status: false },
      { id: 15, dayId: 23, label: '23rd of Jul', status: false },
      { id: 16, dayId: 24, label: '24th of Jul', status: false },
      { id: 17, dayId: 25, label: '25th of Jul', status: false },
      { id: 18, dayId: 26, label: '26th of Jul', status: false },
      { id: 19, dayId: 27, label: '27th of Jul', status: false },
      { id: 20, dayId: 28, label: '28th of Jul', status: false },
      { id: 21, dayId: 29, label: '29th of Jul', status: false },
      { id: 22, dayId: 30, label: '30th of Jul', status: false },
      { id: 23, dayId: 31, label: '31th of Jul', status: false },
    ],
    currentDate: 'Tuesday 15th June',
    tableList: [
      {
        id: 2,
        row: 1,
        status: true,
        type: 'square',
        seats: 4,
        class: 'green',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 8,
        waiterId: 1,
        location: 'Outdoor Area',
        availableSeated: '',
      },
      {
        id: 3,
        row: 1,
        status: true,
        type: 'square',
        seats: 4,
        class: 'blue',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 4,
        waiterId: 2,
        location: 'Outdoor Area',
        availableSeated: '',
      },
      {
        id: 4,
        row: 1,
        status: false,
        type: 'rectangular seats-6',
        seats: 6,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 3,
        location: 'Outdoor Area',
        availableSeated: '3 - 6',
      },
      {
        id: 5,
        row: 1,
        status: true,
        type: 'square',
        seats: 4,
        class: 'orange',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 1,
        waiterId: 4,
        location: 'Outdoor Area',
        availableSeated: '',
      },
      {
        id: 6,
        row: 1,
        status: true,
        type: 'square',
        seats: 4,
        class: 'pink',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 3,
        waiterId: 5,
        location: 'Outdoor Area',
        availableSeated: '',
      },
      {
        id: 7,
        row: 4,
        status: false,
        type: 'rectangular seats-2',
        seats: 2,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 6,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 8,
        row: 4,
        status: false,
        type: 'rectangular seats-2',
        seats: 2,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 7,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 9,
        row: 4,
        status: false,
        type: 'rectangular seats-2',
        seats: 2,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 8,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 10,
        row: 4,
        status: false,
        type: 'rectangular seats-2',
        seats: 2,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 1,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 11,
        row: 5,
        status: true,
        type: 'rectangular seats-6',
        seats: 6,
        class: 'blue',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 4,
        waiterId: 2,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 12,
        row: 5,
        status: true,
        type: 'round',
        seats: 8,
        class: 'orange',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 1,
        waiterId: 3,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 13,
        row: 5,
        status: true,
        type: 'round',
        seats: 8,
        class: 'pink',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 3,
        waiterId: 4,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 14,
        row: 6,
        status: false,
        type: 'rectangular seats-6',
        seats: 6,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 5,
        location: 'Dining Room',
        availableSeated: '4 - 6',
      },
      {
        id: 15,
        row: 6,
        status: true,
        type: 'rectangular seats-6',
        seats: 6,
        class: 'purple',
        icon: 'dine_icon-dessert',
        progress: 50,
        tableStatusId: 5,
        waiterId: 6,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 16,
        row: 6,
        status: true,
        type: 'square big-size ',
        seats: 4,
        class: 'grey',
        icon: 'dine_icon-cash',
        progress: 0,
        tableStatusId: 9,
        waiterId: 7,
        location: 'Dining Room',
        availableSeated: '',
      },
    ],
    waiterGuestModal: false,
    waiterDropDownList: [
      {
        id: 1,
        name: 'Myrtle Warren',
        shortName: 'Myrtle W',
        style: 'purple',
        iconType: 'initials',
        iconVal: 'MW',
        people: 12,
      },
      {
        id: 2,
        name: 'Oliver Wood',
        shortName: 'Oliver W',
        style: 'blue',
        iconType: 'initials',
        iconVal: 'OW',
        people: 24,
      },
      {
        id: 3,
        name: 'Johnny Appleseed ',
        shortName: 'Johnny A',
        style: 'orange',
        iconType: 'initials',
        iconVal: 'JA',
        people: 8,
      },
      {
        id: 4,
        name: 'Demelza Robins',
        shortName: 'Demelza R',
        style: 'yellow',
        iconType: 'initials',
        iconVal: 'DR',
        people: 16,
      },
      {
        id: 5,
        name: 'Graham Montague',
        shortName: 'Graham M',
        style: 'green',
        iconType: 'initials',
        iconVal: 'GM',
        people: 35,
      },
      {
        id: 6,
        name: 'Marcus Flint',
        shortName: 'Marcus F',
        style: 'pink',
        iconType: 'initials',
        iconVal: 'MF',
        people: 2,
      },
      {
        id: 7,
        name: 'Mary Cattermole',
        shortName: 'Mary C',
        style: 'grey',
        iconType: 'initials',
        iconVal: 'MC',
        people: 47,
      },
      {
        id: 8,
        name: 'Michael Corner',
        shortName: 'Michael C',
        style: 'blue',
        iconType: 'initials',
        iconVal: 'MC',
        people: 25,
      },
    ],
    tableStatusModal: false,
    tableStatusList: [
      {
        id: 1,
        name: 'Partially Seated',
        style: 'orange',
        iconType: 'icon',
        iconVal: 'dine_icon-people',
      },
      {
        id: 2,
        name: 'Seated',
        style: 'orange',
        iconType: 'icon',
        iconVal: 'dine_icon-people-inverse',
      },
      {
        id: 3,
        name: 'Entree',
        style: 'purple',
        iconType: 'icon',
        iconVal: 'dine_icon-silverware',
      },
      {
        id: 4,
        name: 'Main Course',
        style: 'blue',
        iconType: 'icon',
        iconVal: 'dine_icon-dish',
      },
      {
        id: 5,
        name: 'Dessert',
        style: 'pink',
        iconType: 'icon',
        iconVal: 'dine_icon-dessert',
      },
      {
        id: 6,
        name: 'Cleared',
        style: 'yellow',
        iconType: 'icon',
        iconVal: 'dine_icon-cleared',
      },
      {
        id: 7,
        name: 'Check Dropped',
        style: 'green',
        iconType: 'icon',
        iconVal: 'dine_icon-check',
      },
      {
        id: 8,
        name: 'Paid',
        style: 'green',
        iconType: 'icon',
        iconVal: 'dine_icon-cash',
      },
      {
        id: 9,
        name: 'Bussing Needed',
        style: 'grey',
        iconType: 'icon',
        iconVal: 'dine_icon-sprayer',
      },
      {
        id: 10,
        name: 'Finished',
        style: 'blue',
        iconType: 'icon',
        iconVal: 'dine_icon-finish',
      },
      {
        id: 11,
        name: '',
        style: 'empty',
        iconType: '',
        iconVal: '',
      },
    ],
    bookingList: [
      {
        id: 1,
        dayId: 9,
        name: 'Katie Bell',
        initials: 'KB',
        vip: true,
        table: 0,
        people: 2,
        day: 'Tuesday 15th June',
        time: '7:30pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Confirmed', class: 'confirmed' },
          { title: 'Resy', class: 'resy' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 2,
        dayId: 9,
        name: 'Alicia Spinnet',
        initials: 'AS',
        vip: false,
        table: 4,
        people: 2,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Running Late', class: 'running-late' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 3,
        dayId: 9,
        name: 'Romilda Vane',
        initials: 'RV',
        vip: false,
        table: 32,
        people: 6,
        day: 'Tuesday 15th June',
        time: '8:00pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Left Message', class: 'left-message' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 4,
        dayId: 9,
        name: 'Dean Thomas',
        initials: 'DT',
        vip: false,
        table: 6,
        people: 3,
        day: 'Tuesday 15th June',
        time: '8:30pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Arrived', class: 'arrived' },
          { title: 'Web', class: 'web' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Oliver Wood',
        initials: 'OW',
        vip: false,
        table: 10,
        people: 2,
        day: 'Tuesday 15th June',
        time: '8:45pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Booked', class: 'booked' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 6,
        dayId: 9,
        name: 'Corban Yaxely',
        initials: 'CY',
        vip: false,
        table: 0,
        people: 8,
        day: 'Tuesday 15th June',
        time: '9:00pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Cancelled', class: 'cancelled' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 7,
        dayId: 9,
        name: 'Myrtle Warren',
        initials: 'MW',
        vip: false,
        table: 58,
        people: 2,
        day: 'Tuesday 15th June',
        time: '9:30pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: null,
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    waitList: [
      {
        id: 1,
        dayId: 9,
        name: 'Bob Ogden',
        initials: 'BO',
        vip: false,
        table: 0,
        people: 3,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Confirmed', class: 'confirmed' },
          { title: 'Resy', class: 'resy' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 2,
        dayId: 9,
        name: 'Cho Chang',
        initials: 'CC',
        vip: false,
        table: 3,
        people: 2,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Arrived', class: 'arrived blue' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 3,
        dayId: 9,
        name: 'Vincent Crabbe',
        initials: 'VC',
        vip: false,
        table: 0,
        people: 6,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '20 mins',
        duration: '1 hour',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Cancelled', class: 'cancelled' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 4,
        dayId: 9,
        name: 'Arabella Figg',
        initials: 'AF',
        vip: true,
        table: 6,
        people: 3,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '12 mins',
        duration: '1 hour',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Left Message', class: 'left-message' },
          { title: 'Web', class: 'web' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    seatedList: [
      {
        id: 1,
        dayId: 9,
        name: 'Frank Bryce',
        initials: 'FB',
        vip: false,
        table: 2,
        people: 3,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$30.70',
        leftTime: '50 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Paid', class: 'paid' },
          { title: 'Resy', class: 'resy' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 2,
        dayId: 9,
        name: 'Cho Chang',
        initials: 'CC',
        vip: false,
        table: 3,
        people: 2,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$65.30',
        leftTime: '35 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Main', class: 'main' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 3,
        dayId: 9,
        name: 'Colin Creevey',
        initials: 'CC',
        vip: false,
        table: 5,
        people: 6,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$0.00',
        leftTime: '5 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Seated', class: 'seated' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 4,
        dayId: 9,
        name: 'Arabella Figg',
        initials: 'AF',
        vip: true,
        table: 6,
        people: 3,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$26.30',
        leftTime: '12 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Entree', class: 'entree' },
          { title: 'Web', class: 'web' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Lee Jordan',
        initials: 'LJ',
        vip: false,
        table: 16,
        people: 4,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$102.20',
        leftTime: '55 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Bussing', class: 'bussing' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 6,
        dayId: 9,
        name: 'Olympe Maxime',
        initials: 'OM',
        vip: false,
        table: 15,
        people: 8,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$90.40',
        leftTime: '40 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Dessert', class: 'dessert' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 7,
        dayId: 9,
        name: 'Albert Runcorn',
        initials: 'AR',
        vip: false,
        table: 13,
        people: 6,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$90.40',
        leftTime: '40 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Entree', class: 'entree' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 8,
        dayId: 9,
        name: 'Katie Bell',
        initials: 'KB',
        vip: false,
        table: 12,
        people: 6,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$90.40',
        leftTime: '40 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Seated', class: 'seated' },
          { title: 'Web', class: 'web' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 9,
        dayId: 9,
        name: 'Romilda Vane',
        initials: 'RV',
        vip: false,
        table: 11,
        people: 4,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$65.30',
        leftTime: '35 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Main', class: 'main' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    waiterViewModal: false,
    waiterViewModalId: 1,
    bookingViewModal: false,
    bookingViewModalId: 1,
    seatedViewModal: false,
    seatedViewModalId: 1,
    tagModal: false,
    tagGroup: [
      { id: 1, name: 'Occasion', slug: 'occasion', active: true },
      { id: 2, name: 'VIP', slug: 'vip', active: false },
      { id: 3, name: 'Seating', slug: 'seating', active: false },
      { id: 4, name: 'Dietary', slug: 'dietary', active: false },
      { id: 5, name: 'Alert', slug: 'alert', active: false },
    ],
    tags: [
      {
        id: 1,
        name: 'Birthday',
        color: '#0686F6',
        icon: 'dine_icon-birthday-cake',
        tagGroupId: 1,
      },
      {
        id: 2,
        name: 'Anniversary',
        color: '#FCEB04',
        icon: 'dine_icon-champagine',
        tagGroupId: 1,
      },
      {
        id: 3,
        name: 'Date',
        color: '#E02020',
        icon: 'dine_icon-path',
        tagGroupId: 1,
      },
      {
        id: 4,
        name: 'Graduation',
        color: '#000000',
        icon: 'dine_icon-graduation',
        tagGroupId: 1,
      },
      {
        id: 5,
        name: 'Pre theater',
        color: '#F7B500',
        icon: 'dine_icon-theater',
        tagGroupId: 1,
      },
      {
        id: 6,
        name: 'Business',
        color: '#FA6400',
        icon: 'dine_icon-work-briefcase',
        tagGroupId: 1,
      },
      {
        id: 7,
        name: 'VIP',
        color: '#FCEB04',
        icon: 'dine_icon-star',
        tagGroupId: 2,
      },
      {
        id: 8,
        name: 'Investor',
        color: '#5EB602',
        icon: 'dine_icon-us_dolar',
        tagGroupId: 2,
      },
      {
        id: 9,
        name: 'Regulator',
        color: '#FA6400',
        icon: 'dine_icon-warning',
        tagGroupId: 2,
      },
      {
        id: 10,
        name: 'Friend of owner',
        color: '#6236FF',
        icon: 'dine_icon-smile-face',
        tagGroupId: 2,
      },
      {
        id: 11,
        name: 'Friend of employee',
        color: '#F7B500',
        icon: 'dine_icon-laughing-face',
        tagGroupId: 2,
      },
      {
        id: 12,
        name: 'Critic',
        color: '#E02020',
        icon: 'dine_icon-writing-pen',
        tagGroupId: 2,
      },
      {
        id: 13,
        name: 'Blogger',
        color: '#0686F6',
        icon: 'dine_icon-laptop-computer',
        tagGroupId: 2,
      },
      {
        id: 14,
        name: 'Window Seat',
        color: '#000000',
        icon: 'dine_icon-window',
        tagGroupId: 3,
      },
      {
        id: 15,
        name: 'Booth',
        color: '#F7B500',
        icon: 'dine_icon-booth',
        tagGroupId: 3,
      },
      {
        id: 16,
        name: 'Quiet Table',
        color: '#6236FF',
        icon: 'dine_icon-volume-off',
        tagGroupId: 3,
      },
      {
        id: 17,
        name: 'High Chair',
        color: '#0686F6',
        icon: 'dine_icon-baby',
        tagGroupId: 3,
      },
      {
        id: 18,
        name: 'Wheel Chair',
        color: '#FA6400',
        icon: 'dine_icon-handicap',
        tagGroupId: 3,
      },
      {
        id: 19,
        name: 'Smoking',
        color: '#E02020',
        icon: 'dine_icon-cigarette',
        tagGroupId: 3,
      },
      {
        id: 20,
        name: 'Patio Requested',
        color: '#5EB602',
        icon: 'dine_icon-bench',
        tagGroupId: 3,
      },
      {
        id: 21,
        name: 'Gluten Free',
        color: '#FCEB04',
        icon: 'dine_icon-gluten_free',
        tagGroupId: 4,
      },
      {
        id: 22,
        name: 'Allergy',
        color: '#6236FF',
        icon: 'dine_icon-sad-face',
        tagGroupId: 4,
      },
      {
        id: 23,
        name: 'Vegetarian',
        color: '#5EB602',
        icon: 'dine_icon-salad',
        tagGroupId: 4,
      },
      {
        id: 24,
        name: 'Vegan',
        color: '#5EB602',
        icon: 'dine_icon-leaf',
        tagGroupId: 4,
      },
      {
        id: 25,
        name: 'Dairy Free',
        color: '#0686F6',
        icon: 'dine_icon-milk',
        tagGroupId: 4,
      },
      {
        id: 26,
        name: 'Pescatarian',
        color: '#F7B500',
        icon: 'dine_icon-fish',
        tagGroupId: 4,
      },
      {
        id: 27,
        name: 'Jain',
        color: '#FA6400',
        icon: 'dine_icon-hinduism',
        tagGroupId: 4,
      },
      {
        id: 28,
        name: 'Alert Chef',
        color: '#5EB602',
        icon: 'dine_icon-chef',
        tagGroupId: 5,
      },
      {
        id: 29,
        name: 'Alert Manager',
        color: '#6236FF',
        icon: 'dine_icon-business-man',
        tagGroupId: 5,
      },
    ],
    selectedTags: {
      tempTag: '',
      tags: [1],
    },
    messageModal: false,
    directionMessage: false,
    // showAvailableTable: false,
    timeLine: [
      {
        id: 1,
        dayId: 9,
        status: true,
        tableList: [
          {
            id: 2,
            row: 1,
            status: true,
            type: 'square',
            seats: 4,
            class: 'green',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 8,
            waiterId: 1,
            location: 'Outdoor Area',
            availableSeated: '',
          },
          {
            id: 3,
            row: 1,
            status: true,
            type: 'square',
            seats: 4,
            class: 'blue',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 4,
            waiterId: 2,
            location: 'Outdoor Area',
            availableSeated: '',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 3,
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: true,
            type: 'square',
            seats: 4,
            class: 'orange',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 1,
            waiterId: 4,
            location: 'Outdoor Area',
            availableSeated: '',
          },
          {
            id: 6,
            row: 1,
            status: true,
            type: 'square',
            seats: 4,
            class: 'pink',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 3,
            waiterId: 5,
            location: 'Outdoor Area',
            availableSeated: '',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 6,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 7,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 8,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 1,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 11,
            row: 5,
            status: true,
            type: 'rectangular seats-6',
            seats: 6,
            class: 'blue',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 4,
            waiterId: 2,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 12,
            row: 5,
            status: true,
            type: 'round',
            seats: 8,
            class: 'orange',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 1,
            waiterId: 3,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 13,
            row: 5,
            status: true,
            type: 'round',
            seats: 8,
            class: 'pink',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 3,
            waiterId: 4,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 5,
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: true,
            type: 'rectangular seats-6',
            seats: 6,
            class: 'purple',
            icon: 'dine_icon-dessert',
            progress: 50,
            tableStatusId: 5,
            waiterId: 6,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 16,
            row: 6,
            status: true,
            type: 'square big-size ',
            seats: 4,
            class: 'grey',
            icon: 'dine_icon-cash',
            progress: 0,
            tableStatusId: 9,
            waiterId: 7,
            location: 'Dining Room',
            availableSeated: '',
          },
        ],
      },
      {
        id: 2,
        dayId: 10,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 3,
        dayId: 11,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 4,
        dayId: 12,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 5,
        dayId: 13,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 6,
        dayId: 14,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 7,
        dayId: 15,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 8,
        dayId: 16,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 9,
        dayId: 17,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 10,
        dayId: 18,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 11,
        dayId: 19,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 12,
        dayId: 20,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 13,
        dayId: 21,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 14,
        dayId: 22,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 15,
        dayId: 23,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 16,
        dayId: 24,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 17,
        dayId: 25,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 18,
        dayId: 26,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 19,
        dayId: 27,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 20,
        dayId: 28,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 21,
        dayId: 29,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 22,
        dayId: 30,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 23,
        dayId: 31,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
    ],
  },
  scrolledStatus: [
    {
      id: 1,
      actionType: 'scrollSeatList',
      name: 'Order Bar',
      position: 0,
      class: '',
    },
    {
      id: 2,
      actionType: 'scrollNavBarDetailsList',
      name: 'Nav Bar Details',
      position: 0,
      class: '',
    },
    {
      id: 3,
      actionType: 'guestQuantityList',
      name: 'Guest Quantity',
      position: 0,
      class: '',
    },
    {
      id: 4,
      actionType: 'guestWaitList',
      name: 'Guest Waitlisty',
      position: 0,
      class: '',
    },
    {
      id: 5,
      actionType: 'modalBodyScroll',
      name: 'Modal Booking',
      position: 0,
      class: '',
    },
    {
      id: 6,
      actionType: 'scrollSplitPriceList',
      name: 'Split Bill Modal',
      position: 0,
      class: '',
    },
    {
      id: 7,
      actionType: 'scrollViewOrdersSidebar',
      name: 'View Orders Sidebar',
      position: 0,
      class: '',
    },
    {
      id: 8,
      actionType: 'scrollViewOrdersSeatList',
      name: 'View Orders Seat List',
      position: 0,
      class: '',
    },
    {
      id: 9,
      actionType: 'viewOrdersActionModal',
      name: 'View Orders Actions Modal',
      position: 0,
      class: '',
    },
    {
      id: 10,
      actionType: 'scrollUnassignedList',
      name: 'Unassigned List (drivers)',
      position: 0,
      class: '',
    },
    {
      id: 11,
      actionType: 'scrollDeliveryOrders',
      name: 'Delivery Orders (drivers)',
      position: 0,
      class: '',
    },
    {
      id: 12,
      actionType: 'scrollDriverActionsBox',
      name: 'Driver Actions Box (drivers)',
      position: 0,
      class: '',
    },
    {
      id: 13,
      actionType: 'guestAvailableTableList',
      name: 'Available Table',
      position: 0,
      class: '',
    },
    {
      id: 14,
      actionType: 'sidebarWaitListScroll',
      name: 'Sidebar Wait List',
      position: 0,
      class: '',
    },
    {
      id: 15,
      actionType: 'sidebarBookingScroll',
      name: 'Sidebar Booking',
      position: 0,
      class: '',
    },
    {
      id: 16,
      actionType: 'sidebarSeatedScroll',
      name: 'Sidebar Seated',
      position: 0,
      class: '',
    },
    {
      id: 17,
      actionType: 'sidebarUpcomingScroll',
      name: 'Sidebar Upcoming',
      position: 0,
      class: '',
    },
    {
      id: 18,
      actionType: 'sidebarReadyScroll',
      name: 'Sidebar Ready',
      position: 0,
      class: '',
    },
    {
      id: 19,
      actionType: 'sidebarPreparingScroll',
      name: 'Sidebar Preparing',
      position: 0,
      class: '',
    },
    {
      id: 20,
      actionType: 'sidebarHistoryScroll',
      name: 'Sidebar History',
      position: 0,
      class: '',
    },
  ],
  newBookingModal: false,
  personBookingModal: false,
  sidebarModal: false,
  guestQuantity: {
    // activeIndex: 2,
    guestArr: [
      { label: '1 Guest', value: 1 },
      { label: '2 Guests', value: 2 },
      { label: '3 Guests', value: 3 },
      { label: '4 Guests', value: 4 },
      { label: '5 Guests', value: 5 },
      { label: '6 Guests', value: 6 },
      { label: '7 Guests', value: 7 },
      { label: '8 Guests', value: 8 },
    ],
  },
  waitTimeModal: false,
  waitTimeList: {
    // activeIndex: 2,
    waitArr: [
      { id: 1, waitTime: '5 mins', time: '7:35pm' },
      { id: 2, waitTime: '10 mins', time: '7:40pm' },
      { id: 3, waitTime: '15 mins', time: '7:45pm' },
      { id: 4, waitTime: '20 mins', time: '7:50pm' },
      { id: 5, waitTime: '25 mins', time: '7:55pm' },
      { id: 6, waitTime: '30 mins', time: '8:00pm' },
    ],
  },
  paymentViewModal: false,
  printBillModal: false,
  // printBillModalId: 1,
  printSplitBillModal: false,
  // printSplitBillModalId: 1,
  availableTableModal: false,
  guestSearchModal: false,
  guestSearchDropDown: false,
  guestSearch: [
    {
      id: 1,
      name: 'Johnny Appleseed',
      initials: 'JA',
      phone: '0429 117 406',
      email: 'johnny@nextorder.com',
    },
    {
      id: 2,
      name: 'John Banks',
      initials: 'JB',
      phone: '0418 342 400',
      email: '',
    },
    {
      id: 3,
      name: 'John Smith',
      initials: 'JS',
      phone: '0415 432 652',
      email: 'john@smith.com',
    },
  ],
  currentGuest: '',
  bookingNotesType: [
    {
      id: 1,
      name: 'Booking Notes',
      slug: 'bookingNotes',
      icon: 'dine_icon-note1',
    },
    {
      id: 2,
      name: 'Seating Preferences',
      slug: 'seatingPreferences',
      icon: 'dine_icon-table',
    },
    {
      id: 3,
      name: 'Special Relationship',
      slug: 'specialRelationship',
      icon: 'dine_icon-star-inverse',
    },
    {
      id: 4,
      name: 'Food & Drink Preferences',
      slug: 'foodPreferences',
      icon: 'dine_icon-silverware',
    },
  ],
  newGuestListType: 'booking', //wait-list, seated
  newBookingSliders: [
    {
      id: 1,
      slug: 'day',
      class: 'day',
      slideArr: [
        'Today',
        'Tomorrow',
        'September 3',
        'September 4',
        'September 5',
        'September 6',
        'September 7',
        'September 8',
        'September 9',
        'September 10',
        'September 11',
        'September 12',
        'September 13',
        'September 14',
        'September 15',
        'September 16',
        'September 17',
        'September 18',
        'September 19',
        'September 20',
        'September 21',
        'September 22',
        'September 23',
        'September 24',
        'September 25',
        'September 26',
        'September 27',
        'September 28',
        'September 29',
        'September 30',
      ],
      activeIndex: 3,
    },
    {
      id: 2,
      slug: 'people',
      class: 'guest',
      slideArr: [
        '1 Guest',
        '2 Guests',
        '3 Guests',
        '4 Guests',
        '5 Guests',
        '6 Guests',
        '7 Guests',
        '8 Guests',
      ],
      slideArrDef: ['1', '2', '3', '4', '5', '6', '7', '8'],
      activeIndex: 2,
    },
    {
      id: 3,
      slug: 'time',
      class: 'time',
      slideArr: [
        "7:00pm - <span class='green'>5/10</span>",
        "7:15pm - <span class='green'>3/10</span>",
        "7:30pm - <span class='red'>10/10</span>",
        "7:45pm - <span class='green'>0/10</span>",
        "8:00pm - <span class='green'>6/10</span>",
        "8:15pm - <span class='red'>10/10</span>",
        "8:30pm - <span class='green'>3/10</span>",
      ],
      slideArrDef: [
        { time: '7:00pm', status: '<span class="green">5/10</span>' },
        { time: '7:15pm', status: '<span class="green">3/10</span>' },
        { time: '7:30pm', status: '<span class="red">10/10</span>' },
        { time: '7:45pm', status: '<span class="green">0/10</span>' },
        { time: '8:00pm', status: '<span class="green">6/10</span>' },
        { time: '8:15pm', status: '<span class="red">10/10</span>' },
        { time: '8:30pm', status: '<span class="green">3/10</span>' },
      ],
      activeIndex: 3,
    },
    {
      id: 4,
      slug: 'place',
      class: 'place',
      slideArr: ['High Stool', 'Bar', 'Regular', 'Booth'],
      activeIndex: 2,
    },
  ],
  newBooking: {
    id: '',
    name: '',
    initials: '',
    vip: false,
    table: '',
    people: 3,
    day: 'September 4',
    time: '7:45pm',
    leftTime: '15 mins',
    price: '$30.70',
    duration: '1 hour',
    active: false,
    place: '',
    statusTags: null,
    tags: [1],
    notes: {},
    sendToKitchen: false,
    messages: [
      {
        id: 1,
        text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
        sender: 'agent',
      },
      { id: 2, text: 'Yes', sender: 'guest' },
    ],
  },
  locationSuburbs: [
    {
      id: 1,
      data: {
        label: 'Geelong West',
        location: { lat: -38.133333, lng: 144.35 },
      },
      price: '$7',
    },
    {
      id: 2,
      data: {
        label: 'Geelong',
        location: { lat: -38.1499181, lng: 144.3617186 },
      },
      price: '$5',
    },
    {
      id: 3,
      data: {
        label: 'Highton',
        location: { lat: -38.171, lng: 144.318 },
      },
      price: '$9',
    },
    {
      id: 4,
      data: {
        label: 'Waurn Ponds',
        location: { lat: -38.208813, lng: 144.2709146 },
      },
      price: '$3',
    },
    {
      id: 5,
      data: {
        label: 'Rippleside',
        location: { lat: -38.12355489999999, lng: 144.3562807 },
      },
      price: '$6.50',
    },
  ],
  backHistoryUrl: '',
  currentGuestId: '',
  orderBarType: '',
  splitBillDiscountModal: false,
  splitBillDiscountMode: false,
  discount: {
    items: [],
    type: '',
    name: '',
  },
  discountSplit: {
    items: [],
    type: '',
    name: '',
  },
  discountType: [
    {
      id: 1,
      type: 'custom',
      title: 'Custom Discount',
      icon: 'pos_icon-discount',
      // iconThickness: '1',
      // icon: "dine_icon-discount",
      class: 'type-custom-discount',
      active: false,
    },
    {
      id: 2,
      type: 'voucher',
      title: 'Voucher',
      icon: 'pos_icon-cash',
      // iconThickness: '1',
      // icon: "pos-custom-icon-cash",
      class: 'type-voucher',
      active: false,
    },
  ],
  discountList: [
    {
      id: 1,
      slug: 'vip',
      name: 'VIP',
      discountValue: '10%',
      type: 'custom',
      visible: true,
    },
    {
      id: 2,
      slug: 'staff',
      name: 'Staff',
      discountValue: '15%',
      type: 'custom',
      visible: true,
    },
    {
      id: 3,
      slug: 'manager',
      name: 'Manager',
      discountValue: '50%',
      type: 'custom',
      visible: true,
    },
    {
      id: 4,
      slug: 'of_10',
      name: '$10 of',
      discountValue: '$10',
      type: 'voucher',
      visible: true,
    },
    {
      id: 5,
      slug: 'tuesday_20',
      name: '$20 Tuesday',
      discountValue: '$20',
      type: 'voucher',
      visible: true,
    },
  ],
  discountSplitList: [
    {
      id: 1,
      slug: 'vip',
      name: 'VIP',
      discountValue: '10%',
      type: 'custom',
      visible: true,
    },
    {
      id: 2,
      slug: 'staff',
      name: 'Staff',
      discountValue: '15%',
      type: 'custom',
      visible: true,
    },
    {
      id: 3,
      slug: 'manager',
      name: 'Manager',
      discountValue: '50%',
      type: 'custom',
      visible: true,
    },
    {
      id: 4,
      slug: 'tuesday_20',
      name: 'Tuesday',
      discountValue: '$20',
      type: 'voucher',
      visible: true,
    },
    {
      id: 5,
      slug: 'friday_50',
      name: 'Friday',
      discountValue: '$50',
      type: 'voucher',
      visible: true,
    },
    {
      id: 6,
      slug: 'of_10',
      name: '$10 of',
      discountValue: '$10',
      type: 'voucher',
      visible: true,
    },
    {
      id: 7,
      slug: 'custom',
      name: 'Custom',
      discountValue: '',
      type: 'voucher',
      visible: true,
    },
  ],
  viewOrders: {
    upcomingList: [
      {
        id: 1,
        dayId: 9,
        name: 'Frank Bryce',
        initials: 'FB',
        vip: false,
        table: false,
        people: 2,
        pickupId: 2345,
        deliveryId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$62.50',
        payType: 'cash',
        active: false,
        tags: [1],
        statusTags: [{ title: 'Web', class: 'web' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 1, 2 Malop Street, Geelong',
        status: '',
        /*    due: {
          status: true,
          time: '20 mins'
        },*/
        // completed: false,
        textStatus: {
          class: 'red',
          text: 'Due in 20 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 2,
        dayId: 9,
        name: 'Lee Jordan',
        initials: 'LJ',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 5623,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$73.25',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 2, 2 Malop Street, Geelong',
        status: '',
        /*due: {
          status: true,
          time: '35 mins'
        },*/
        // completed: false,
        textStatus: {
          class: 'orange',
          text: 'Due in 35 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 3,
        dayId: 9,
        name: 'Alicia Spinnet',
        initials: 'AS',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 4302,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '20 mins',
        duration: '1 hour',
        price: '$105.10',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: 'Web', class: 'web' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 2, 10 Wharf Street East, Queenscliff',
        status: '',
        /*due: {
          status: true,
          time: '4 hours'
        },*/
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 4 hours',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 4,
        dayId: 9,
        name: 'Albert Runcorm',
        initials: 'AR',
        vip: false,
        table: false,
        people: false,
        pickupId: 6452,
        deliveryId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$94.60',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'uber' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        // due: {
        //   status: true,
        //   time: '6 hours'
        // },
        status: '',
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 6 hours',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Corban Yaxely',
        initials: 'CY',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 9830,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$24.40',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new-2' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        status: '',
        // due: {
        //   status: true,
        //   time: '8 hours'
        // },
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 8 hours',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    preparingList: [
      {
        id: 1,
        dayId: 9,
        name: 'Katie Bell',
        initials: 'KB',
        vip: true,
        table: 32,
        people: 2,
        pickupId: false,
        deliveryId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$62.50',
        payType: 'cash',
        active: false,
        tags: [1],
        statusTags: [{ title: 'Resy', class: 'resy' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 1, 2 Malop Street, Geelong',
        status: '',
        // completed: false,
        textStatus: {
          class: 'orange',
          text: '10 mins Remaining',
          secondClass: '',
          secondText: '10 minutes remaining',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        // due: {
        //   status: true,
        //   time: '20 mins'
        // },
      },
      {
        id: 2,
        dayId: 9,
        name: 'Cho Chang',
        initials: 'CC',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 5623,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$73.25',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 2, 2 Malop Street, Geelong',
        status: '',
        // completed: false,
        textStatus: {
          class: 'orange',
          text: 'Due in 2 mins',
          secondClass: 'orange',
          secondText: 'Expected in 26 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        /*due: {
          status: true,
          time: '35 mins'
        },*/
      },
      {
        id: 3,
        dayId: 9,
        name: 'Johnny Appleseed',
        initials: 'JA',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 4302,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '20 mins',
        duration: '1 hour',
        price: '$105.10',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: 'Web', class: 'web' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        status: '',
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 10 mins',
          secondClass: 'green',
          secondText: 'Expected in 26 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        /*due: {
          status: true,
          time: '4 hours'
        },*/
      },
      {
        id: 4,
        dayId: 9,
        name: 'Romilda Vane',
        initials: 'RV',
        vip: false,
        table: false,
        people: false,
        pickupId: 6452,
        deliveryId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$94.60',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'uber' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        // due: {
        //   status: true,
        //   time: '6 hours'
        // },
        status: '',
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 13 mins',
          secondClass: '',
          secondText: 'Expected in 26 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Lee Jordan',
        initials: 'LJ',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 9830,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$24.40',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new-2' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        status: '',
        /*due: {
          status: true,
          time: '8 hours'
        },*/
        completed: false,
        textStatus: {
          class: '',
          text: 'Due in 22 mins',
          secondClass: '',
          secondText: 'Expected in 26 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    readyList: [
      {
        id: 1,
        dayId: 9,
        name: 'Katie Bell',
        initials: 'KB',
        vip: true,
        table: 32,
        people: 2,
        pickupId: false,
        deliveryId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$62.50',
        payType: 'cash',
        active: false,
        tags: [1],
        statusTags: [{ title: 'Resy', class: 'resy' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 1, 2 Malop Street, Geelong',
        status: '',
        // completed: false,
        textStatus: {
          class: 'red',
          text: 'Overdue 12 mins',
          secondClass: 'red',
          secondText: '10 minutes remaining',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        // due: {
        //   status: true,
        //   time: '20 mins'
        // },
      },
      {
        id: 2,
        dayId: 9,
        name: 'Cho Chang',
        initials: 'CC',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 5623,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$73.25',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 2, 2 Malop Street, Geelong',
        status: '',
        // completed: false,
        textStatus: {
          class: 'orange',
          text: 'Due in 2 mins',
          secondClass: 'orange',
          secondText: 'Expected in 26 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        /*due: {
          status: true,
          time: '35 mins'
        },*/
      },
      {
        id: 3,
        dayId: 9,
        name: 'Johnny Appleseed',
        initials: 'JA',
        vip: false,
        table: false,
        people: false,
        pickupId: 2345,
        deliveryId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '20 mins',
        duration: '1 hour',
        price: '$105.10',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: 'Web', class: 'web' }],
        notes: {},
        sendToKitchen: false,
        location: '2 Collins Street, Melbourne',
        status: '',
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 10 mins',
          secondClass: 'red',
          secondText: 'Collection Expected 20 mins ago',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        /*due: {
          status: true,
          time: '4 hours'
        },*/
      },
      {
        id: 4,
        dayId: 9,
        name: 'Romilda Vane',
        initials: 'RV',
        vip: false,
        table: false,
        people: false,
        pickupId: 6452,
        deliveryId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$94.60',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'uber' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        // due: {
        //   status: true,
        //   time: '6 hours'
        // },
        status: '',
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 13 mins',
          secondClass: '',
          secondText: 'Expected in 26 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Lee Jordan',
        initials: 'LJ',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 9830,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$24.40',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new-2' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        status: '',
        /*due: {
          status: true,
          time: '8 hours'
        },*/
        completed: false,
        textStatus: {
          class: '',
          text: 'Due in 22 mins',
          secondClass: '',
          secondText: 'Expected in 26 mins',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    historyList: [
      {
        id: 1,
        dayId: 9,
        name: 'Oliver Wood',
        initials: 'OW',
        vip: true,
        table: false,
        people: false,
        deliveryId: 7444,
        pickupId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$62.50',
        payType: 'cash',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'phone' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 1, 2 Malop Street, Geelong',
        status: 'delivery',
        // due: {
        //   status: false,
        //   time: '12 mins'
        // },
        textStatus: {
          class: 'red',
          text: 'Overdue in 12 min',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 2,
        dayId: 9,
        name: 'Cho Chang',
        initials: 'CC',
        vip: false,
        table: false,
        people: false,
        deliveryId: 5623,
        pickupId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$73.25',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 2, 2 Malop Street, Geelong',
        status: 'delivery',
        /*due: {
          status: true,
          time: '2 mins'
        },*/
        textStatus: {
          class: 'orange',
          text: 'Due in 2 min',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 3,
        dayId: 9,
        name: 'Frank Bryce',
        initials: 'FB',
        vip: false,
        table: false,
        people: false,
        deliveryId: 4302,
        pickupId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '20 mins',
        duration: '1 hour',
        price: '$105.10',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: 'Web', class: 'web' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        status: 'delivery',
        /*        due: {
          status: true,
          time: '12 mins'
        },*/
        textStatus: {
          class: 'orange',
          text: 'Due in 12 min',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 4,
        dayId: 9,
        name: 'Romilda Vane',
        initials: 'RV',
        vip: false,
        table: 64,
        people: 6,
        deliveryId: false,
        pickupId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$73.50',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'circle' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        // due: {
        //   status: true,
        //   time: '2 mins'
        // },
        status: 'completed',
        // completed: true,
        textStatus: {
          class: 'blue',
          text: 'Completed',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Lee Jordan',
        initials: 'LJ',
        vip: false,
        table: 25,
        people: 9,
        deliveryId: false,
        pickupId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$24.40',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'circle' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        status: 'completed',
        // due: {
        //   status: true,
        //   time: '2 mins'
        // },
        // completed: true,
        textStatus: {
          class: 'blue',
          text: 'Completed',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    openOrdersList: [
      {
        id: 1,
        dayId: 9,
        name: 'Katie Bell',
        initials: 'KB',
        vip: true,
        table: 6,
        people: 2,
        pickupId: false,
        deliveryId: false,
        day: 'Tuesday 15th June',
        seatedTime: '1 hr 30 mins',
        expectedTime: '',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$62.50',
        payType: 'cash',
        active: false,
        tags: [1],
        statusTags: [{ title: 'Resy', class: 'resy' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 1, 2 Malop Street, Geelong',
        status: '',
        // completed: false,
        // Expected 20 mins ago - 6:43pm
        textStatus: {
          class: 'red',
          text: '10 mins Remaining',
          secondClass: 'red',
          secondText: 'Expected 20 mins ago',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        // due: {
        //   status: true,
        //   time: '20 mins'
        // },
      },
      {
        id: 2,
        dayId: 9,
        name: 'Cho Chang',
        initials: 'CC',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 5623,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        seatedTime: '',
        expectedTime: '6:57pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$73.25',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new' }],
        notes: {},
        sendToKitchen: false,
        location: '2 Malop Street, Geelong',
        status: '',
        // completed: false,
        textStatus: {
          class: 'red',
          text: 'Due in 2 mins',
          secondClass: 'red',
          secondText: 'Expected 20 mins ago',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        /*due: {
          status: true,
          time: '35 mins'
        },*/
      },
      {
        id: 3,
        dayId: 9,
        name: 'Johnny Appleseed',
        initials: 'JA',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 4302,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        seatedTime: '',
        expectedTime: '6:43pm',
        leftTime: '20 mins',
        duration: '1 hour',
        price: '$105.10',
        payType: 'card',
        active: true,
        tags: [1],
        statusTags: [{ title: 'Web', class: 'web' }],
        notes: {},
        sendToKitchen: false,
        location: '21A Gheringhap St, Geelong',
        status: '',
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 10 mins',
          secondClass: 'red',
          secondText: 'Expected 20 mins ago',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
        /*due: {
          status: true,
          time: '4 hours'
        },*/
      },
      {
        id: 4,
        dayId: 9,
        name: 'Romilda Vane',
        initials: 'RV',
        vip: false,
        table: false,
        people: false,
        pickupId: 6452,
        deliveryId: false,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        seatedTime: '',
        expectedTime: '6:39pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$94.60',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'uber' }],
        notes: {},
        sendToKitchen: false,
        location: 'Level 3, 2 Malop Street, Geelong',
        // due: {
        //   status: true,
        //   time: '6 hours'
        // },
        status: '',
        // completed: false,
        textStatus: {
          class: '',
          text: 'Due in 13 mins',
          secondClass: 'red',
          secondText: 'Expected 20 mins ago',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Lee Jordan',
        initials: 'LJ',
        vip: false,
        table: false,
        people: false,
        pickupId: false,
        deliveryId: 9830,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        seatedTime: '',
        expectedTime: '6:27pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$24.40',
        payType: 'card',
        active: false,
        tags: [1],
        statusTags: [{ title: '', class: 'custom-new-2' }],
        notes: {},
        sendToKitchen: false,
        location: '6 Bexley Court, Highton',
        status: '',
        /*due: {
          status: true,
          time: '8 hours'
        },*/
        completed: false,
        textStatus: {
          class: '',
          text: 'Due in 22 mins',
          secondClass: 'red',
          secondText: 'Expected 20 mins ago',
        },
        visibleGuest: true,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    collectionTime: 20,
    deliveryTime: 45,
  },
  viewOrdersSort: [
    { id: 1, title: 'All Orders', type: '', status: true },
    { id: 2, title: 'Pickup', type: 'pickupId', status: false },
    { id: 3, title: 'Delivery', type: 'deliveryId', status: false },
    { id: 4, title: 'Dine-in', type: 'table', status: false },
  ],
  viewOrdersActionModal: false,
  viewOrdersActionTimeModal: {
    collectionTime: false,
    deliveryTime: false,
  },
  deliveryOrderModal: false,
  currentTableId: 32,
  driversData: {
    clientList: [
      {
        id: 1,
        name: 'Corban',
      },
      {
        id: 2,
        name: 'Katie',
      },
      {
        id: 3,
        name: 'Alicia',
      },
      {
        id: 4,
        name: 'Lee',
      },
      {
        id: 5,
        name: 'Johnny',
      },
      {
        id: 6,
        name: 'Lee',
      },
      {
        id: 7,
        name: 'Alicia',
      },
      {
        id: 8,
        name: 'Corban',
      },
    ],
    driverList: [
      {
        id: 1,
        name: 'Michael Corner',
        initials: 'MC',
        currentOrders: [1745, 5876],
        colorTheme: 'blue',
        accessibility: 1, // 1 - Waiting in Store, 2 -  Returning to Store, 3 - On Delivery, 4 Clocked Out
        time: '12 mins',
        returningTime: '12 mins',
        onShift: '3 hrs 47 mins',
        cashFloat: 'Deposit',
        progress: {
          total: 2,
          completed: 0,
        },
        checked: false,
        clocked: 1, // 1 - Clocked In, 2 - Clocked Out
        active: false,
      },
      {
        id: 2,
        name: 'Graham Montague',
        initials: 'GM',
        currentOrders: [1745, 5876, 4621],
        colorTheme: 'green',
        accessibility: 1,
        time: '10 mins',
        returningTime: '12 mins',
        onShift: '1 hr 12 mins',
        cashFloat: 'Deposit',
        progress: {
          total: 3,
          completed: 0,
        },
        checked: false,
        clocked: 1,
        active: false,
      },
      {
        id: 3,
        name: 'Demelza Robins',
        initials: 'DR',
        currentOrders: [],
        colorTheme: 'yellow',
        accessibility: 1,
        time: '9 mins',
        returningTime: '12 mins',
        onShift: '4 hrs 13 mins',
        cashFloat: 'Widthdraw',
        progress: {
          total: 0,
          completed: 0,
        },
        checked: false,
        clocked: 1,
        active: true,
      },
      {
        id: 4,
        name: 'Marcus Flint',
        initials: 'MF',
        currentOrders: [1745, 5876, 4621, 5876, 4621],
        colorTheme: 'pink',
        accessibility: 1,
        time: '4 mins',
        returningTime: '12 mins',
        onShift: '5 hrs 22 mins',
        cashFloat: 'Deposit',
        progress: {
          total: 5,
          completed: 0,
        },
        checked: false,
        clocked: 1,
        active: false,
      },
      {
        id: 5,
        name: 'Myrtle Warren',
        initials: 'MW',
        currentOrders: [1745],
        colorTheme: 'orange',
        accessibility: 2,
        time: '6 mins',
        returningTime: '12 mins',
        onShift: '22 mins',
        cashFloat: 'Deposit',
        progress: {
          total: 1,
          completed: 0,
        },
        checked: false,
        clocked: 1,
        active: false,
      },
      {
        id: 6,
        name: 'Mary Cattermole',
        initials: 'MC',
        currentOrders: [],
        colorTheme: 'red',
        accessibility: 2,
        time: '9 mins',
        returningTime: '12 mins',
        onShift: '45 mins',
        cashFloat: 'Deposit',
        progress: {
          total: 0,
          completed: 0,
        },
        checked: false,
        clocked: 1,
        active: false,
      },
      {
        id: 7,
        name: 'Demelza Robins',
        initials: 'DR',
        currentOrders: [1745, 5876, 4621],
        colorTheme: 'grey',
        accessibility: 3,
        time: '14 mins',
        returningTime: '12 mins',
        onShift: '',
        cashFloat: 'Deposit',
        progress: {
          total: 3,
          completed: 2,
        },
        checked: false,
        clocked: 2,
        active: false,
      },
    ],
    orderList: [
      {
        id: 4632,
        location: '2 Malop St, Geelong ',
        clientId: 1,
        price: 1,
        payMethod: '',
        priorityClass: 'red',
        deliveryTime: '7:30pm',
        orderStatus: 1, //1 - due, 2 - on delivery, 3 - delivered
        timeDifference: '12 mins',
        checked: false,
      },
      {
        id: 5458,
        location: '18 Burdekin Rd, Highton',
        clientId: 2,
        price: 1,
        payMethod: '',
        priorityClass: 'red',
        deliveryTime: '7:35pm',
        orderStatus: 1,
        timeDifference: '18 mins',
        checked: false,
      },
      {
        id: 8760,
        location: '22 Eastern Beach Rd, Geelong',
        clientId: 3,
        price: 1,
        payMethod: '',
        priorityClass: 'orange',
        deliveryTime: '7:50pm',
        orderStatus: 1,
        timeDifference: '22 mins',
        checked: false,
      },
      {
        id: 7956,
        location: '1 Wharf Street, Queenscliff',
        clientId: 4,
        price: 1,
        payMethod: '',
        priorityClass: 'orange',
        deliveryTime: '7:58pm',
        orderStatus: 1,
        timeDifference: '25 mins',
        checked: false,
      },
      {
        id: 5575,
        location: '21A Gheringhap St, Geelong',
        clientId: 1,
        price: 1,
        payMethod: '',
        priorityClass: 'green',
        deliveryTime: '9:12pm',
        orderStatus: 1,
        timeDifference: '1 hour',
        checked: false,
      },
      {
        id: 1745,
        location: '1 Wharf Street, Queenscliff',
        clientId: 5,
        price: 73.2,
        payMethod: 'apple',
        priorityClass: 'red',
        deliveryTime: '7:55pm',
        orderStatus: 2,
        timeDifference: '3 mins away',
        checked: false,
      },
      {
        id: 5876,
        location: '22 Eastern Beach Rd, Geelong',
        clientId: 6,
        price: 89.55,
        payMethod: 'visa',
        priorityClass: 'green',
        deliveryTime: '7:40pm',
        orderStatus: 3,
        timeDifference: '4 mins late',
        checked: false,
      },
      {
        id: 4621,
        location: '2 Malop St, Geelong ',
        clientId: 7,
        price: 73.2,
        payMethod: 'mastercard',
        priorityClass: 'green',
        deliveryTime: '7:30pm',
        orderStatus: 3,
        timeDifference: 'On Time',
        checked: false,
      },
    ],
    unAssignedOrders: [4632, 5458, 8760, 7956, 5575],
    assignDriver: false,
    driverMapModal: false,
  },
  nextBtnData: {
    url: '',
    approvedData: [],
    fieldType: '',
  },
  messageAnim: {
    run: false,
    finished: true,
    newPosition: false,
  },
  searchMenuItem: {
    show: false,
    fieldVal: '',
  },
  guestPickupModal: false,
  pickupGuestName: '',
  staffModalStatus: false,
  searchPickupCustomer: '',
  searchDeliveryCustomer: '',
};

export default InitializeArg;
