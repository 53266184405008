import { useRadiusBasedDeliveryAreaCalulationSelector } from 'pages/Dashboard/selectors';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDeliveryCostFromApi } from 'services/orderFlowServices';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import { usePrevious } from '../../customHooks/usePrevious';
import { useDebounce } from '../../hooks/useDebounce';
import Button from '../Button';

const PostCodeSearch = ({
  modalStatus,
  initialLocation = '',
  handleAddressSelect,
  handleResetClick,
  parentPage,
  handleManualAddressClick,
  storeId,
  data,
  isDisabled,
}) => {
  const history = useHistory();
  const prevModalStatus = usePrevious(modalStatus);
  const [searchResults, setSearchResults] = useState([]);
  const [filteredSearchResults, setFilteredSearchResults] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState(initialLocation);
  const [shouldCallApiFlag, setShouldCallApiFlag] = useState(false);
  const [debouncedSearchInputValue] = useDebounce(searchInputValue, 300);
  const [isInputFocsued, setIsInputFocused] = useState(false);

  const useRadiusBasedDeliveryAreaCalulation = useSelector(
    useRadiusBasedDeliveryAreaCalulationSelector
  );

  const parseMapboxApiResponse = (apiResponse) => {
    const showManualAddressOption = apiResponse.length <= 3;
    setSearchResults([
      ...apiResponse,
      ...(showManualAddressOption ? [{ type: 'manual' }] : []),
    ]);
    setFilteredSearchResults([
      ...apiResponse,
      ...(showManualAddressOption ? [{ type: 'manual' }] : []),
    ]);
  };

  useEffect(() => {
    setSearchInputValue(initialLocation);
  }, [initialLocation]);

  useEffect(() => {
    parseMapboxApiResponse(data);
  }, [data]);

  const {
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    inputNames,
  } = useVirtualKeyboardContext();
  const inputName = inputNames.deliverySearchAddress;

  useEffect(() => {
    if (modalStatus && !prevModalStatus) {
      onChangeInput(inputName, initialLocation);
    }
  }, [modalStatus, prevModalStatus, onChangeInput, inputName, initialLocation]);

  const inputValFromVirtualKeyboard = getInputValue(inputName);

  useEffect(() => {
    setSearchInputValue(inputValFromVirtualKeyboard);
  }, [inputValFromVirtualKeyboard]);

  const handleInputAddressChange = async (e) => {
    onChangeInput(inputName, e.target.value);
    const filteredResults = searchResults.filter((item) =>
      item.locationText.toLowerCase().includes(e.target.value.toLowerCase())
    );

    if (e.target.value.trim() === '') {
      setFilteredSearchResults(searchResults);
    } else {
      setFilteredSearchResults(filteredResults);
    }
    // setIsInputFocused(false);
  };

  const handleOnInputFieldFocus = () => {
    setInputName(inputName);
    setIsInputFocused(true);
    toggleKeyboard(true);
    setShouldCallApiFlag(true);
    if (data && data?.features) {
      parseMapboxApiResponse(data);
    }
  };

  const handleInputFieldReset = () => {
    onChangeInput(inputName, '');

    setFilteredSearchResults(searchResults);
    setIsInputFocused(false);
    handleResetClick();
  };

  const handleAddressSelection = async (address) => {
    setShouldCallApiFlag(false);
    onChangeInput(inputName, address.locationText);
    let deliveryInfo = { isRadiusDeliveryCost: false };
    if (useRadiusBasedDeliveryAreaCalulation) {
      const deliveryInfoFromApi = await getDeliveryCostFromApi(
        storeId,
        address.location
      );
      deliveryInfo = { isRadiusDeliveryCost: true, ...deliveryInfoFromApi };
    }
    handleAddressSelect(address, deliveryInfo);
    // setSearchResults([]);
    setIsInputFocused(false);
    toggleKeyboard(false);
  };

  const handleEnterAddressOnClick = () => {
    if (handleManualAddressClick) {
      handleManualAddressClick();
    } else {
      history.push(`${parentPage}/customer/delivery-address/manual`);
    }
  };

  return (
    <div
      className="geosuggest-delivery-wrap"
      style={{
        height: '100%',
      }}
    >
      <div className="geosuggest-delivery-box">
        <div
          className="geosuggest__input-wrapper"
          style={{
            height: '100%',
          }}
        >
          <input
            value={searchInputValue}
            className="delivery-address-search"
            onChange={handleInputAddressChange}
            onFocus={handleOnInputFieldFocus}
            placeholder="Search Address"
            autoComplete="new-password"
            style={{
              height: '100%',
            }}
            disabled={isDisabled}
          />
        </div>
        <Button
          title="Clear"
          className="clear-input-btn hover-highlight"
          onClick={handleInputFieldReset}
        >
          <PosIconClose mainColor="#494b4c" lineThickness={0.5} />
        </Button>
        {isInputFocsued && searchResults && searchResults.length ? (
          <div className="geosuggest__suggests-wrapper">
            <ul>
              {filteredSearchResults.map((searchResult) => {
                if (searchResult.type === 'manual') {
                  return (
                    <li
                      className="li_geosuggest_enter_address_manually"
                      key={'manual'}
                      onClick={handleEnterAddressOnClick}
                    >
                      <div className="enter_address_manually">
                        Enter Address Manually
                      </div>
                    </li>
                  );
                }
                return (
                  <li
                    className="geosuggest__item"
                    key={searchResult.id}
                    onClick={() => handleAddressSelection(searchResult)}
                  >
                    {searchResult?.locationText}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PostCodeSearch;
