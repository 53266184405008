import React from 'react';
import SupportStaffNavItem from './SupportStaffNavItem';
import { IntercomSupport } from './IntercomSupport';

export const SupportNav = ({ staffMember }) => {
  return (
    <>
      <SupportStaffNavItem key="support-1" staffMember={staffMember} />
      <IntercomSupport />
    </>
  );
};
