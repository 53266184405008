import { Notifications } from './proto/notifications_pb';
import { notificationStreamStatusEnum } from 'configuration/enums';

export const setStreamListeners = (
  streamObject,
  notificationHandler,
  handleConnectionStatus
) => {
  streamObject?.on?.('data', (response) => {
    if (response?.array?.length) {
      const notificationTypesList = response.getNotificationTypesList();
      notificationTypesList?.forEach((notification) => {
        notificationHandler[notification]?.(response);
      });
    }
  });

  streamObject.on('status', (status) => {
    if (status.code === 2 || status.code === 16) {
      setTimeout(() => {
        handleConnectionStatus(notificationStreamStatusEnum.DISCONNECTED);
      }, 3000);
    }
  });

  streamObject.on('end', () => {
    setTimeout(() => {
      handleConnectionStatus(notificationStreamStatusEnum.DISCONNECTED);
    }, 3000);
  });
};

export const getParsedNonDineInObject = (nonDineInItem) => ({
  id: nonDineInItem.getId(),
  orientationAngle: nonDineInItem.getOrientationAngle(),
  positionX: nonDineInItem.getPositionX(),
  positionY: nonDineInItem.getPositionY(),
  scale: nonDineInItem.getScale(),
  type: nonDineInItem.getType(),
});

export const getParsedLayoutDividersObject = (layoutDividersItem) => ({
  id: layoutDividersItem.getId(),
  name: layoutDividersItem.getName(),
  startX: layoutDividersItem.getStartX(),
  startY: layoutDividersItem.getStartY(),
  endX: layoutDividersItem.getEndX(),
  endY: layoutDividersItem.getEndY(),
  colour: layoutDividersItem.getColour(),
  type: layoutDividersItem.getType(),
});

export const getParsedDineInItemObject = (dineInItem) => ({
  id: dineInItem.getId(),
  name: dineInItem.getName(),
  numSeats: dineInItem.getNumSeats(),
  number: dineInItem.getNumber(),
  operationType: dineInItem.getOperationType(),
  orientationAngle: dineInItem.getOrientationAngle(),
  positionX: dineInItem.getPositionX(),
  positionY: dineInItem.getPositionY(),
  scale: dineInItem.getScale(),
  type: dineInItem.getType(),
});

export const getParsedDineInStatusObject = (item) => ({
  dineInObjectId: item.getDineInObjectId(),
  isOccupied: item.getIsOccupied(),
  occupancyId: item.getCurrentOccupancy(),
  status: item.getStatus(),
  currentOrderId: item.getCurrentOrder(),
});

export const getParsedIncommingCallsObject = (item) => ({
  id: item.getId(),
  lineId: item.getLineId(),
  lineName: item.getLineName(),
  originatedTime: item.getOriginatedTime(),
  phone: item.getPhone(),
  status: item.getStatus(),
  storeId: item.getStoreId(),
  restaurantId: item.getRestaurantId(),
});

export const shouldParsedObjectBeUpdatedOrDeleted = (item) => {
  let shouldUpdate = false;
  let shouldDelete = false;
  const itemOperationType = item.getOperationType();
  if (itemOperationType === Notifications.OperationType.UPDATED) {
    shouldUpdate = true;
  } else if (itemOperationType === Notifications.OperationType.DELETED) {
    shouldDelete = true;
  }
  return { shouldUpdate, shouldDelete };
};
