import axios from 'axios';
import { nextOrderConfig } from '../../../configuration/nextOrderConfig';

const updatePaymentTypeForDelivery = async (
  driverId,
  storeId,
  restaurantId,
  orders
) => {
  try {
    const response = await axios.post(
      `${nextOrderConfig.cloudFunctionUrl}/updatePaymentTypeForDelivery`,
      { driverId, storeId, restaurantId, orders }
    );
    const { data } = response;
    return data.success;
  } catch (err) {
    return false;
  }
};

export default updatePaymentTypeForDelivery;
