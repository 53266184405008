import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconSingleChat = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-single-chat': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      {/*      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M6.045 19.845l-3.91 1.478 1.26-4.265a.44.44 0 0 0-.044-.352A10.094 10.094 0 0 1 4.77 4.454a10.125 10.125 0 0 1 12.225-1.75c4 2.257 5.975 6.922 4.809 11.36a10.114 10.114 0 0 1-9.774 7.54 10.01 10.01 0 0 1-5.59-1.688.432.432 0 0 0-.247-.07.458.458 0 0 0-.15 0zm-4.428 2.649l4.523-1.707a10.878 10.878 0 0 0 5.886 1.707 10.996 10.996 0 1 0 5.457-20.53A11.001 11.001 0 0 0 4.212 3.731a10.995 10.995 0 0 0-1.733 13.273l-1.452 4.927a.44.44 0 0 0 .44.563c.05.008.1.008.15 0z"
              />

              <path
                fill="none"
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness * 0.5 : 0.5}
                strokeMiterlimit="20"
                d="M6.045 19.845l-3.91 1.478 1.26-4.265a.44.44 0 0 0-.044-.352A10.094 10.094 0 0 1 4.77 4.454a10.125 10.125 0 0 1 12.225-1.75c4 2.257 5.975 6.922 4.809 11.36a10.114 10.114 0 0 1-9.774 7.54 10.01 10.01 0 0 1-5.59-1.688.432.432 0 0 0-.247-.07.458.458 0 0 0-.15 0zm-4.428 2.649l4.523-1.707a10.878 10.878 0 0 0 5.886 1.707 10.996 10.996 0 1 0 5.457-20.53A11.001 11.001 0 0 0 4.212 3.731a10.995 10.995 0 0 0-1.733 13.273l-1.452 4.927a.44.44 0 0 0 .44.563c.05.008.1.008.15 0z"
              />
            </g>
          </g>
        </g>
      </svg>*/}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.92 23.21">
        <path
          d="M11.67.5a10.85,10.85,0,0,0-10.74,11,11.11,11.11,0,0,0,1.12,4.85s0,0,0,0L.53,21.82a.69.69,0,0,0,.92.84l4.7-1.82a10.51,10.51,0,0,0,5.52,1.57A10.85,10.85,0,0,0,22.42,11.46,10.86,10.86,0,0,0,11.67.5Z"
          fill="none"
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness * 1 : 1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

PosIconSingleChat.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
