import React, { useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PosIconApperCircle } from '../../assets/icons/PosIconApperCircle';
import { PosIconBackIcon } from '../../assets/icons/PosIconBackIcon';
import { PosIconBag } from '../../assets/icons/PosIconBag';
import { PosIconBike } from '../../assets/icons/PosIconBike';
import { PosIconBurger } from '../../assets/icons/PosIconBurger';
import { PosIconCalendar } from '../../assets/icons/PosIconCalendar';
import { PosIconCash } from '../../assets/icons/PosIconCash';
import { PosIconChatBubbles } from '../../assets/icons/PosIconChatBubbles';
import { PosIconCheck } from '../../assets/icons/PosIconCheck';
import { PosIconCircles } from '../../assets/icons/PosIconCircles';
import { PosIconClock } from '../../assets/icons/PosIconClock';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import { PosIconConfirmed } from '../../assets/icons/PosIconConfirmed';
import { PosIconCook } from '../../assets/icons/PosIconCook';
import { PosIconDownChevron } from '../../assets/icons/PosIconDownChevron';
import { PosIconEmail } from '../../assets/icons/PosIconEmail';
import { PosIconEmailInverse } from '../../assets/icons/PosIconEmailInverse';
import { PosIconExpand } from '../../assets/icons/PosIconExpand';
import { PosIconExpandFullscreen } from '../../assets/icons/PosIconExpandFullscreen';
import { PosIconHistory } from '../../assets/icons/PosIconHistory';
import { PosIconItemSold } from '../../assets/icons/PosIconItemSold';
import { PosIconLeftChevron } from '../../assets/icons/PosIconLeftChevron';
import { PosIconLocation } from '../../assets/icons/PosIconLocation';
import { PosIconMinus } from '../../assets/icons/PosIconMinus';
import { PosIconMoon } from '../../assets/icons/PosIconMoon';
import { PosIconMove } from '../../assets/icons/PosIconMove';
import { PosIconNarrow } from '../../assets/icons/PosIconNarrow';
import { PosIconOrderPrice } from '../../assets/icons/PosIconOrderPrice';
import { PosIconPause } from '../../assets/icons/PosIconPause';
import { PosIconPeople } from '../../assets/icons/PosIconPeople';
import { PosIconPeoples } from '../../assets/icons/PosIconPeoples';
import { PosIconPlus } from '../../assets/icons/PosIconPlus';
import { PosIconPrint } from '../../assets/icons/PosIconPrint';
import { PosIconRepeat } from '../../assets/icons/PosIconRepeat';
import { PosIconSearch } from '../../assets/icons/PosIconSearch';
import { PosIconShapeMan } from '../../assets/icons/PosIconShapeMan';
import { PosIconSilverware } from '../../assets/icons/PosIconSilverware';
import { PosIconTrash } from '../../assets/icons/PosIconTrash';
import { PosIconUpcoming } from '../../assets/icons/PosIconUpcoming';
import { PosIconWalkIn } from '../../assets/icons/PosIconWalkIn';
import { PosIconWalletCash } from '../../assets/icons/PosIconWalletCash';
import { PosIconWriteMessage } from '../../assets/icons/PosIconWriteMessage';
import { PosIconModernHouse } from '../../assets/icons/PosIconModernHouse';
import { PosIconCashRegister } from '../../assets/icons/PosIconCashRegister';
import { PosIconReceiveMoney } from '../../assets/icons/PosIconReceiveMoney';
import { PosIconMoney } from '../../assets/icons/PosIconMoney';
import { PosIconUpSquare } from '../../assets/icons/PosIconUpSquare';
import { PosIconStore } from '../../assets/icons/PosIconStore';
import { PosIconDollarBills } from '../../assets/icons/PosIconDollarBills';
import { PosIconPrinter } from '../../assets/icons/PosIconPrinter';
import { PosIconSingleChat } from '../../assets/icons/PosIconSingleChat';
import { PosIconCall } from '../../assets/icons/PosIconCall';
import { PosIconCheckmarkCircle } from '../../assets/icons/PosIconCheckmarkCircle';
import { PosIconSendMoney } from '../../assets/icons/PosIconSendMoney';
import { PosIconCashRegisterInverse } from '../../assets/icons/PosIconCashRegisterInverse';
import { PosIconDiscount } from '../../assets/icons/PosIconDiscount';
import { PosIconDot } from '../../assets/icons/PosIconDot';
import { PosIconArrow } from '../../assets/icons/PosIconArrow';
import { PosIconBagInverse } from '../../assets/icons/PosIconBagInverse';
import { PosIconRightChevron } from '../../assets/icons/PosIconRightChevron';
import { PosIconCreditCardReader } from '../../assets/icons/PosIconCreditCardReader';
import { PosIconPhoneInverse } from '../../assets/icons/PosIconPhoneInverse';
import { PosIconQuestionMark } from '../../assets/icons/PosIconQuestionMark';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';
import { PosIconLeftChevronSecond } from '../../assets/icons/PosIconLeftChevronSecond';
// import {} from "./icons/";

import { CashAmountModal } from '../../modals/CashAmountModal';
import { CashAmountSelectedModal } from '../../modals/CashAmountSelectedModal';
import { CustomerPaymentDetailsModal } from '../../modals/CustomerPaymentDetailsModal';

export const CustomIconList = () => {
  const iconList = useRef([
    { id: 1, name: 'PosIconApperCircle', component: <PosIconApperCircle /> },
    { id: 2, name: 'PosIconBackIcon', component: <PosIconBackIcon /> },
    { id: 3, name: 'PosIconBag', component: <PosIconBag /> },
    { id: 4, name: 'PosIconBike', component: <PosIconBike /> },
    { id: 5, name: 'PosIconBurger', component: <PosIconBurger /> },
    { id: 6, name: 'PosIconCalendar', component: <PosIconCalendar /> },
    { id: 7, name: 'PosIconCash', component: <PosIconCash /> },
    { id: 8, name: 'PosIconChatBubbles', component: <PosIconChatBubbles /> },
    { id: 9, name: 'PosIconCheck', component: <PosIconCheck /> },
    { id: 10, name: 'PosIconCircles', component: <PosIconCircles /> },
    { id: 10, name: 'PosIconClock', component: <PosIconClock /> },
    { id: 11, name: 'PosIconClose', component: <PosIconClose /> },
    { id: 12, name: 'PosIconConfirmed', component: <PosIconConfirmed /> },
    { id: 13, name: 'PosIconCook', component: <PosIconCook /> },
    { id: 14, name: 'PosIconDownChevron', component: <PosIconDownChevron /> },
    { id: 15, name: 'PosIconEmail', component: <PosIconEmail /> },
    { id: 16, name: 'PosIconEmailInverse', component: <PosIconEmailInverse /> },
    { id: 17, name: 'PosIconExpand', component: <PosIconExpand /> },
    {
      id: 18,
      name: 'PosIconExpandFullscreen',
      component: <PosIconExpandFullscreen />,
    },
    { id: 19, name: 'PosIconHistory', component: <PosIconHistory /> },
    { id: 20, name: 'PosIconItemSold', component: <PosIconItemSold /> },
    { id: 21, name: 'PosIconLeftChevron', component: <PosIconLeftChevron /> },
    { id: 59, name: 'PosIconRightChevron', component: <PosIconRightChevron /> },
    { id: 22, name: 'PosIconLocation', component: <PosIconLocation /> },
    { id: 23, name: 'PosIconMinus', component: <PosIconMinus /> },
    { id: 24, name: 'PosIconMoon', component: <PosIconMoon /> },
    { id: 25, name: 'PosIconMove', component: <PosIconMove /> },
    { id: 26, name: 'PosIconNarrow', component: <PosIconNarrow /> },
    { id: 27, name: 'PosIconOrderPrice', component: <PosIconOrderPrice /> },
    { id: 28, name: 'PosIconPause', component: <PosIconPause /> },
    { id: 29, name: 'PosIconPeople', component: <PosIconPeople /> },
    { id: 30, name: 'PosIconPeoples', component: <PosIconPeoples /> },
    { id: 31, name: 'PosIconPlus', component: <PosIconPlus /> },
    { id: 32, name: 'PosIconPrint', component: <PosIconPrint /> },
    { id: 33, name: 'PosIconRepeat', component: <PosIconRepeat /> },
    { id: 34, name: 'PosIconSearch', component: <PosIconSearch /> },
    { id: 35, name: 'PosIconShapeMan', component: <PosIconShapeMan /> },
    { id: 36, name: 'PosIconSilverware', component: <PosIconSilverware /> },
    { id: 37, name: 'PosIconTrash', component: <PosIconTrash /> },
    { id: 38, name: 'PosIconUpcoming', component: <PosIconUpcoming /> },
    { id: 39, name: 'PosIconWalkIn', component: <PosIconWalkIn /> },
    { id: 40, name: 'PosIconWalletCash', component: <PosIconWalletCash /> },
    { id: 41, name: 'PosIconWriteMessage', component: <PosIconWriteMessage /> },
    { id: 42, name: 'PosIconModerHouse', component: <PosIconModernHouse /> },
    { id: 43, name: 'PosIconCashRegister', component: <PosIconCashRegister /> },
    { id: 44, name: 'PosIconReceiveMoney', component: <PosIconReceiveMoney /> },
    { id: 45, name: 'PosIconMoney', component: <PosIconMoney /> },
    { id: 46, name: 'PosIconUpSquare', component: <PosIconUpSquare /> },
    { id: 47, name: 'PosIconStore', component: <PosIconStore /> },
    { id: 48, name: 'PosIconDollarBills', component: <PosIconDollarBills /> },
    { id: 49, name: 'PosIconPrinter', component: <PosIconPrinter /> },
    { id: 50, name: 'PosIconSingleChat', component: <PosIconSingleChat /> },
    { id: 51, name: 'PosIconCall', component: <PosIconCall /> },
    {
      id: 52,
      name: 'PosIconCheckmarkCircle',
      component: <PosIconCheckmarkCircle />,
    },
    { id: 53, name: 'PosIconSendMoney', component: <PosIconSendMoney /> },
    {
      id: 54,
      name: 'PosIconCashRegisterInverse',
      component: <PosIconCashRegisterInverse />,
    },
    { id: 55, name: 'PosIconDiscount', component: <PosIconDiscount /> },
    { id: 56, name: 'PosIconDot', component: <PosIconDot /> },
    { id: 57, name: 'PosIconArrow', component: <PosIconArrow /> },
    { id: 58, name: 'PosIconBagInverse', component: <PosIconBagInverse /> },
    {
      id: 60,
      name: 'PosIconCreditCardReader',
      component: <PosIconCreditCardReader />,
    },
    { id: 61, name: 'PosIconPhoneInverse', component: <PosIconPhoneInverse /> },
    { id: 62, name: 'PosIconQuestionMark', component: <PosIconQuestionMark /> },
    { id: 63, name: 'PosIconLoading', component: <PosIconLoading /> },
    {
      id: 64,
      name: 'PosIconLeftChevronSecond',
      component: <PosIconLeftChevronSecond />,
    },
    // {id: 6, name: 'PosIcon', component: </>},
  ]);

  const handleClick = (e, icon) => {
    console.log(icon.name);
  };

  const [cashAmountModalStatus, setCashAmountModalStatus] = useState(false);
  const [cashAmountSelectedModalStatus, setCashAmountSelectedModalStatus] =
    useState(false);

  const [
    customerPaymentDetailsModalStatus,
    setCustomerPaymentDetailsModalStatus,
  ] = useState(false);

  const handleToggleCashAmountModal = (e, status) => {
    setCashAmountModalStatus(status);
  };

  const handleToggleCashAmountSelectedModal = (e, status) => {
    setCashAmountSelectedModalStatus(status);
  };

  const handleToggleCustomerPaymentDetailsModal = (e, status) => {
    setCustomerPaymentDetailsModalStatus(status);
  };

  return (
    <div className="test-icons-wrap">
      <h1>Click icon to copy tag</h1>
      <div className="test-icons">
        {iconList.current.length > 0
          ? iconList.current.map((icon, i) => {
              return (
                <CopyToClipboard key={i} text={`<${icon.name} />`}>
                  <div
                    className="test-icon-box"
                    onClick={(e) => handleClick(e, icon)}
                  >
                    {icon.component}
                    <span className="test-icon-name">{icon.name}</span>
                  </div>
                </CopyToClipboard>
              );
            })
          : ''}
      </div>
      <button
        style={{ margin: '0 0 20px 20px' }}
        className="btn"
        onClick={(e) => handleToggleCashAmountModal(e, true)}
      >
        <span className="inner-btn-box">Cash Amount MODAL</span>
      </button>
      <button
        style={{ margin: '0 0 20px 20px' }}
        className="btn"
        onClick={(e) => handleToggleCashAmountSelectedModal(e, true)}
      >
        <span className="inner-btn-box">Cash Amount Selected MODAL</span>
      </button>
      <button
        style={{ margin: '0 0 20px 20px' }}
        className="btn"
        onClick={(e) => handleToggleCustomerPaymentDetailsModal(e, true)}
      >
        <span className="inner-btn-box">Customer Payment Details MODAL</span>
      </button>

      <CashAmountModal
        modalStatus={cashAmountModalStatus}
        toggleModal={handleToggleCashAmountModal}
      />

      <CashAmountSelectedModal
        modalStatus={cashAmountSelectedModalStatus}
        toggleModal={handleToggleCashAmountSelectedModal}
      />

      {/*<CustomerPaymentDetailsModal*/}
      {/*  modalStatus={customerPaymentDetailsModalStatus}*/}
      {/*  toggleModal={handleToggleCustomerPaymentDetailsModal}*/}
      {/*/>*/}
    </div>
  );
};
