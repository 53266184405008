export const transitionStyles = {
  entering: { transform: 'translateY(0%)' },
  entered: { transform: 'translateY(0%)' },
  exiting: { transform: 'translateY(100%)' },
  exited: { transform: 'translateY(100%)' },
};

export const transitionOverlayStyles = {
  entering: { transform: 'translateY(100%)', opacity: 0 },
  entered: { transform: 'translateY(0%)', opacity: 1 },
  exiting: { transform: 'translateY(100%)', opacity: 0 },
  exited: { transform: 'translateY(100%)', opacity: 0 },
};
