import project from '../../package.json';

const {
  REACT_APP_TV_APP_BASE_URL,
  REACT_APP_CLOUD_FUNCTION_URL,
  REACT_APP_CLOUD_FUNCTION_URL_SYDNEY,
  REACT_APP_CO_BROWSE_LICENSE_KEY,
  REACT_APP_INCOMING_CALLS_API,
  REACT_APP_API_URL,
  REACT_APP_WINDCAVE_SERVICE,
} = process.env;

let nextOrderConfigObj = {
  baseUrl: REACT_APP_API_URL,
  environment: process.env.REACT_APP_ENV,
  release: project.version,
  tvAppBaseUrl: REACT_APP_TV_APP_BASE_URL,
  cloudFunctionUrl: REACT_APP_CLOUD_FUNCTION_URL,
  cloudFunctionUrlSydney: REACT_APP_CLOUD_FUNCTION_URL_SYDNEY,
  coBrowseLicenseKey: REACT_APP_CO_BROWSE_LICENSE_KEY,
  incommingCallApi: REACT_APP_INCOMING_CALLS_API,
  windcaveService: REACT_APP_WINDCAVE_SERVICE,
};

export const nextOrderConfig = nextOrderConfigObj;

export const environment = process.env.REACT_APP_ENV;
