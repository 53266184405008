import groupBy from 'lodash/groupBy';
import {
  MAP_OBJECT_TYPES,
  MAX_SHAPE_SIZE,
  INITIAL_SHAPE_POSITION,
} from './constants';

export const findShapeIndexByID = (arr, id) => {
  return arr.findIndex((item) => item.id === id);
};

export const findShapeIndexByProperty = (arr, property, value) => {
  return arr.findIndex((item) => item[property] === value);
};

export const normaliseData = (arr) => {
  if (arr?.length <= 0) return [];

  return arr.map((item) => {
    // add extra "type" and "objectType" properties
    const newItem = {
      type: item.type || 'LineDivider',
      objectType:
        item.objectType || MAP_OBJECT_TYPES[item.type || 'LineDivider'],
      isOccupied: false,
      occupancyId: null,
      ...item,
    };

    return newItem;
  });
};

export const findNextAvailableTableNumber = (items, value) => {
  let nextNum = false;

  while (!nextNum) {
    const nextValue = value + 1;
    const isAssigned = items.find((item) => item === nextValue);

    if (!isAssigned) {
      nextNum = true;
      return nextValue;
    } else {
      value++;
    }
  }

  return false;
};

export const findShapesWithInitPos = (data) => {
  if (data?.length <= 0) return;

  return data.some((item) => item.positionX === INITIAL_SHAPE_POSITION);
};

export const isNumberAvailable = (num, data) => {
  return data.every((item) => String(item.number) !== String(num));
};

export const getNewPositions = (arr, shape) => {
  const shapeInInitPosition = findShapesWithInitPos(arr);
  const lastShape = arr[arr?.length - 1];
  const positionX =
    shape.positionX + (shapeInInitPosition ? lastShape.positionX + 20 : 0);
  const positionY =
    shape.positionY + (shapeInInitPosition ? lastShape.positionY + 20 : 0);

  return { positionX, positionY };
};

export const checkPosition = (posCoordinate, layoutDimension) => {
  if (posCoordinate < 0) {
    return 0;
  } else if (posCoordinate + MAX_SHAPE_SIZE > layoutDimension) {
    return layoutDimension - MAX_SHAPE_SIZE;
  } else {
    return posCoordinate;
  }
};

export const getCorrectedPositions = (shape, info, layoutSize) => {
  const posX = shape.positionX
    ? shape.positionX + info.offset.x
    : info.offset.x;
  const posY = shape.positionY
    ? shape.positionY + info.offset.y
    : info.offset.y;

  // prevent shape goes beyond the drag area
  const positionX = checkPosition(posX, layoutSize.width);
  const positionY = checkPosition(posY, layoutSize.height);

  return { positionX, positionY };
};

export const prepareLayoutToSave = (layout) => {
  const updatedItems = layout.reduce((acc, currentItem) => {
    if (currentItem.isUpdated) {
      // remove temporary flags
      if (typeof currentItem.id === 'number') {
        delete currentItem.id;
      }
      delete currentItem.className;
      delete currentItem.isUpdated;
      delete currentItem.selected;
      delete currentItem.svgImg;

      acc.push(currentItem);
    }

    return acc;
  }, []);

  if (updatedItems.length > 0) {
    return groupBy(updatedItems, (item) => item?.objectType);
  }
};

export const bulkObjectsUpdate = (
  listToBeUpdated = [],
  objects,
  property,
  propertiesToUpdate,
  propertyForIndex
) => {
  if (listToBeUpdated.length <= 0 || objects?.length <= 0) return [];

  let newObjects = [...listToBeUpdated];
  let newObjectsUpdated = [];

  objects.forEach((object) => {
    const tableIndex = findShapeIndexByProperty(
      newObjects,
      property,
      object[propertyForIndex || property]
    );
    const table = newObjects[tableIndex];
    if (table) {
      let updatedTable = { ...table };

      propertiesToUpdate.forEach((property) => {
        updatedTable[property] = object[property] ?? '';
      });

      newObjects[tableIndex] = updatedTable;
      newObjectsUpdated.push(updatedTable);
    }
  });

  if (newObjectsUpdated.length > 0) {
    return newObjects;
  }
  return listToBeUpdated;
};

export const checkIfLayoutIsEmpty = (layouts) => {
  for (let i = 0; i < layouts.length; i++) {
    const layout = layouts[i];
    if (
      layout.dineInObjects.length ||
      layout.layoutDividers.length ||
      layout.nonDineInObjects.length
    ) {
      return false;
    }
  }
  return true;
};

export const getUpdatedFloorLayoutsAndItems = () => {
  const currentUpdatedFloorLayoutsAndItemsString = localStorage.getItem(
    'updatedFloorLayoutsAndItems'
  );
  const currentUpdatedFloorLayoutsAndItems =
    currentUpdatedFloorLayoutsAndItemsString
      ? JSON.parse(currentUpdatedFloorLayoutsAndItemsString)
      : {};

  return currentUpdatedFloorLayoutsAndItems;
};

export const getUpdatedFloorLayoutsAndItemsForFloorId = (floorId) => {
  const updatedFloorLayoutsAndItems = getUpdatedFloorLayoutsAndItems();

  return updatedFloorLayoutsAndItems[floorId];
};

export const updateFloorLayoutsAndItems = (floorId, updatedItems) => {
  const currentUpdatedFloorLayoutsAndItems = getUpdatedFloorLayoutsAndItems();
  localStorage.setItem(
    'updatedFloorLayoutsAndItems',
    JSON.stringify({
      ...currentUpdatedFloorLayoutsAndItems,
      [floorId]: updatedItems,
    })
  );
};

export const removeSingleFloorLayoutsAndItems = (floorId) => {
  const currentUpdatedFloorLayoutsAndItems = getUpdatedFloorLayoutsAndItems();
  if (currentUpdatedFloorLayoutsAndItems[floorId]) {
    delete currentUpdatedFloorLayoutsAndItems[floorId];
  }
  localStorage.setItem(
    'updatedFloorLayoutsAndItems',
    JSON.stringify(currentUpdatedFloorLayoutsAndItems)
  );
};

export const removeUpdatedFloorLayoutsAndItems = () => {
  localStorage.removeItem('updatedFloorLayoutsAndItems');
};
