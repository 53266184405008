import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeliveryBatchesSidebar } from './DeliveryBatchesSidebar';
import BatchesMapView from './BatchesMapView';
import DeliveryBatchesAssignDriverModal from './DeliveryBatchesAssignDriverModal';
import DriverBatchesProvider from 'pages/Orders/components/Providers/DriverBatchesProvider';

export const filterEnumList = [
  {
    text: 'Unassigned',
    uniqueCode: 'unassigned',
  },
  {
    text: 'Batches',
    uniqueCode: 'batches',
  },
];

export const DriverBatches = () => {
  const [selectedTabCode, setSelectedTabCode] = useState(
    filterEnumList[0].uniqueCode
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'setCurrentDriver',
      payload: null,
    });
  }, [dispatch]);

  // useEffect(() => {
  //   if (!storeId) return;

  //   dispatch(fetchBatchedOrders(storeId));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [storeId]);

  return (
    <DriverBatchesProvider>
      <div className="driver-main-wrap">
        <DeliveryBatchesSidebar
          selectedTabCode={selectedTabCode}
          setSelectedTabCode={setSelectedTabCode}
          allTabs={filterEnumList}
        />

        <BatchesMapView selectedTabCode={selectedTabCode} />
      </div>
      <DeliveryBatchesAssignDriverModal />
    </DriverBatchesProvider>
  );
};
