import { API_URL } from '../configuration/apiUrl';
import { _auth } from '../firebase';
import { getParams } from '../util/utils';

const SHIFTS_URL = `${API_URL}/staffs/v1/shift`;
const ALL_SHIFTS_URL = `${API_URL}/staffs/v1/shifts`;

const fetchPostShiftsApi = async ({ method = 'POST', endPoint, body }) => {
  const accessToken = await _auth.currentUser.getIdToken(true);
  return fetch(`${SHIFTS_URL}/${endPoint}`, {
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body,
  }).then((response) => {
    return response.json();
  });
};

export const getShifts = async (staffId, shiftType = 'all') => {
  const paramsString = getParams({
    'filter.staffId': staffId,
    'filter.shiftType': shiftType,
  });
  const accessToken = await _auth.currentUser.getIdToken(true);
  return fetch(`${ALL_SHIFTS_URL}?${paramsString}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((response) => {
    return response.json();
  });
};

export const clockInUser = (roleId, staffId) => {
  return fetchPostShiftsApi({
    endPoint: 'clockIn',
    body: JSON.stringify({
      roleId,
      staffId,
    }),
  });
};

export const clockOutUser = (shiftId) => {
  return fetchPostShiftsApi({
    endPoint: 'clockOut',
    body: JSON.stringify({
      shiftId,
    }),
  });
};

export const addBreak = (shiftId) => {
  return fetchPostShiftsApi({
    endPoint: 'addBreak',
    body: JSON.stringify({
      shiftId,
    }),
  });
};

export const endBreak = (shiftId) => {
  return fetchPostShiftsApi({
    endPoint: 'endBreak',
    body: JSON.stringify({
      shiftId,
    }),
  });
};
