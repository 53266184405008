import React, { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { toggleBlurEffect, updateCurrentOrder } from 'pages/Dashboard/action';
import { useSelector } from 'react-redux';
import { getTimestampForMomentDate } from 'util/timeHelper';
import PropTypes from 'prop-types';
import { useModal } from 'hooks';
import { useDispatch } from 'react-redux';
import Button from '../../components/Button';
import SelectDeliveryTime from '../../components/SelectDeliveryTime';
import GeneralModal from '../GeneralModal/index-new';
import dayjs from 'dayjs';

const initialTimeIndex = {
  dayIndex: 0,
  timeIndex: 0,
};

const SimpleSelectTimeModalWrapper = (props) => {
  const [modalStatus, { closeModal }] = useModal('timeModal');

  if (!modalStatus) return null;

  return (
    <SimpleSelectTimeModal
      modalStatus={modalStatus}
      toggleModal={closeModal}
      {...props}
    />
  );
};

const SimpleSelectTimeModal = ({
  modalStatus,
  toggleModal,
  orderBarTitle,
  orderTimes,
}) => {
  const storeConfig = useSelector((s) => s.dashboardReducer.storeConfig);
  const dispatch = useDispatch();
  const [timeIndex, setTimeIndex] = useState(initialTimeIndex);
  const intialdeliveryDate = useSelector(
    (s) => s.dashboardReducer?.currentOrder?.deliveryDate
  );
  const [timeText, setTimeText] = useState(
    dayjs(intialdeliveryDate).format('h:mm A')
  );

  const handleClick = () => {
    const activeSlideDay = timeIndex.dayIndex;
    const activeSlideTime = timeIndex.timeIndex;

    const timesArray =
      orderTimes && orderTimes.length > 0
        ? orderTimes[activeSlideDay].times
        : [];

    const defaultTime = timesArray[activeSlideTime];
    const defaultTimeString = `${defaultTime.timeObjFinal} ${defaultTime.displayForUse}`;
    // find the orderTime Object for the selected order time,
    // to get the shiftId and either the order is a pre-order or ASAP
    let deliveryDate = moment(defaultTimeString, 'YYYY-MM-DD HH:mm A').format(
      'YYYY-MM-DD HH:mm'
    );
    let deliveryDateTimestamp = getTimestampForMomentDate(
      moment(defaultTimeString, 'YYYY-MM-DD HH:mm A'),
      storeConfig.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    let shiftId = defaultTime.shiftId;
    let isFutureDeliveryDate = defaultTime.display !== 'ASAP';
    setTimeText(dayjs(deliveryDate).format('h:mm A'));
    const selectedTimeDiffInMins = dayjs(deliveryDate).diff(dayjs(), 'm');
    dispatch(
      updateCurrentOrder({
        deliveryDate,
        deliveryDateTimestamp,
        selectedTimeDiffInMins,
        shiftId,
        isFutureDeliveryDate,
      })
    );
    toggleModal();
    dispatch(toggleBlurEffect(false));
  };

  const [btnText, setBtnText] = useState(`Confirm ${orderBarTitle} time`);
  const TimeSelect = useMemo(() => {
    return (
      <SelectDeliveryTime
        setDeliveryTimeData={setTimeIndex}
        timeIndex={timeIndex}
        orderTimes={orderTimes}
      />
    );
  }, [timeIndex, orderTimes, setTimeIndex]);

  useEffect(() => {
    if (orderBarTitle) {
      let title = '';
      if (
        'pickup' === orderBarTitle.toLowerCase() ||
        'walk-in' === orderBarTitle.toLowerCase()
      ) {
        title = 'Pickup';
      } else {
        title = orderBarTitle;
      }

      setBtnText(`Confirm ${title} Time`);
    }
  }, [timeText, orderBarTitle]);

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={() => {
        toggleModal();
        dispatch(toggleBlurEffect(false));
      }}
      position="center"
      effect="move"
      overlay="blur"
      boxWidth="699px"
      boxHeight="341px"
      closeBtn="outside"
      className="simple-select-time-modal"
    >
      <div className="select-time-modal-box">
        <div className="select-time-wrap">{TimeSelect}</div>
        <div className="select-time-btn-box">
          <Button
            className="btn full-width"
            title={timeText ? timeText : ''}
            onClick={handleClick}
          >
            {timeText ? btnText : ''}
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

SimpleSelectTimeModal.propTypes = {
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  setTimeText: PropTypes.func,
};

export default SimpleSelectTimeModalWrapper;
