import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconApperCircle = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-apper-circle': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="25"
        viewBox="0 0 8 25"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M4 19a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm0 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7zm0-15a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm0 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7zM4 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm0 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconApperCircle.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
