import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSingleOption } from '../../pages/Dashboard/action';

const ToggleButton = ({ id, toggleTitle, toggleCustomCls, toggleOption }) => {
  const dispatch = useDispatch();
  const singleOption = useSelector(
    (state) => state.dashboardReducer[toggleOption]
  );
  const btnTitle = toggleTitle ? toggleTitle : 'Toggle Option';
  const btnClass = clsx('btn-toggle', toggleCustomCls);
  const toggleCls = clsx({
    'btn-toggle-thumb': true,
    active: singleOption,
  });

  const handleClick = () => {
    dispatch(toggleSingleOption(toggleOption));
    localStorage.setItem(`${toggleOption}`, !singleOption);
  };

  return (
    <button
      id={id}
      name={id}
      className={btnClass}
      type="button"
      title={btnTitle}
      onClick={() => handleClick()}
    >
      <span className="btn-title">{btnTitle}</span>
      <span className={toggleCls}>
        <span />
      </span>
    </button>
  );
};

export default ToggleButton;
