import axios from 'axios';
import { nextOrderConfig } from '../../../configuration/nextOrderConfig';

export default async function getPaymentTypes({ storeId }) {
  const paymentTypeResponse = await axios.post(
    `${nextOrderConfig?.cloudFunctionUrl}/getPaymentTypes`,
    {
      storeId,
    }
  );
  const { data } = paymentTypeResponse;
  const { paymentTypes = [] } = data;
  return paymentTypes;
}
