import { useEffect } from 'react';
import { ModalPortal } from 'components';
import GeneralModal from 'modals/GeneralModal/index-new';
import { ButtonUI, ManualAmountUI } from 'UI/components';
import { useState } from 'react';

const CustomAmountModal = ({
  submitCustomAmount,
  toggleModal,
  currentOrder,
  totalCustomAmount,
}) => {
  const [manualValue, setManualValue] = useState(
    parseFloat(currentOrder.payableAmount)
  );

  useEffect(() => {
    // subtracting floats is... floaty...
    if (
      manualValue >
      parseFloat(
        (parseFloat(currentOrder.payableAmount) - totalCustomAmount).toFixed(2)
      )
    ) {
      setManualValue(
        parseFloat(
          (parseFloat(currentOrder.payableAmount) - totalCustomAmount).toFixed(
            2
          )
        )
      );
    }
  }, [manualValue, currentOrder.payableAmount, totalCustomAmount]);

  return (
    <ModalPortal>
      <GeneralModal
        modalStatus
        toggleModal={toggleModal}
        position={'center'}
        // effect="fade"
        className="bem-add-custom-amount-modal"
        overlay={'default'}
        boxWidth="435px"
        // boxHeight="100%"
        // boxHeight="500px"
        boxHeight="673px"
        closeBtn="outside"
        isResponsiveHeight
      >
        {/*<div style={{ height: '100%' }}>1</div>*/}
        <ManualAmountUI
          title="Add Custom Amount"
          manualType="money"
          manualValue={manualValue}
          currencySymbol="$"
          setManualValue={setManualValue}
          submitBtnLabel={`Create Check ${
            manualValue ? 'for $' + manualValue : ''
          }`}
          submitBtnStyleType="success"
          disableApply={!manualValue}
          handleSubmit={() => {
            submitCustomAmount(parseFloat(manualValue));
          }}
        />
      </GeneralModal>
    </ModalPortal>
  );
};

export default CustomAmountModal;
