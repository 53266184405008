import React from 'react';
import clsx from 'clsx';

import './bem-modal-wrapper.scss';

export const ModalWrapperUI = ({ show, handleClose, children }) => {
  const cssWrapper = clsx('bem-modal-wrapper', {
    'bem-modal-wrapper--show': show,
  });

  return (
    <div className={cssWrapper}>
      {children}
      <div className="bem-modal-wrapper__overlay" onClick={handleClose} />
    </div>
  );
};
