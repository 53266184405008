import React from 'react';
import { useSelector } from 'react-redux';
import { storeConfigSelector } from '../../pages/Dashboard/selectors';
import LeafletDriverOnTheWay from '../Tracking/LeafletDriverOnTheWay';
import LeafletGeneric from '../Tracking/LeafletGeneric';
import MapModalWrapper from 'components/MapModalWrapper';

export const DriversMapIframe = ({
  iframeWidth,
  iframeHeight,
  driver = {},
  id = 'map',
}) => {
  const storeConfig = useSelector(storeConfigSelector);

  const currentDeliveringOrder =
    driver.activeOrders?.find((o) => o.orderStatus === '3') || {};
  const deliveryAddress =
    driver.status === 2
      ? {
          ...storeConfig.addressLocation,
          lng: storeConfig.addressLocation.long,
        }
      : currentDeliveringOrder.addressLocation || {};

  return (
    <MapModalWrapper iframeHeight={iframeHeight} iframeWidth={iframeWidth}>
      {(driver.status === 0 || driver.status === 3) && (
        <LeafletGeneric
          location={storeConfig.addressLocation}
          key={`${driver._id}-${id}`}
          id={id}
        />
      )}
      {(driver.status === 1 || driver.status === 2) &&
        typeof deliveryAddress === 'object' &&
        deliveryAddress.lat &&
        deliveryAddress.lng &&
        typeof driver.currentLocation === 'object' &&
        driver.currentLocation.lat &&
        driver.currentLocation.long &&
        typeof driver.previousLocation === 'object' &&
        driver.previousLocation.lat &&
        driver.previousLocation.long && (
          <LeafletDriverOnTheWay
            driver={driver}
            delivery={deliveryAddress || {}}
            key={`${driver._id}-${id}`}
            id={id}
          />
        )}
    </MapModalWrapper>
  );
};
