import Button from 'components/Button';

export const TextBubble = ({ bubbleText }) => {
  if (bubbleText) {
    return (
      <span className="img-box text-style">
        <Button
          className="count-box"
          enableThemeColor
          isCommonStyle
        >
          {bubbleText}
        </Button>
      </span>
    );
  } else {
    return null;
  }
};
