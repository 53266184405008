import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconDollarBills = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-dollar-bills': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="17"
        viewBox="0 0 25 17"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M15.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm0 6a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM7 1h17v9H7zm12 15.5V14h2.5a.5.5 0 0 0 .5-.5V11h2.5a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5h-18a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5H21v2H4V4h2V3H3.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5H18v2H1V7h2V6H.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h18a.5.5 0 0 0 .5-.5z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconDollarBills.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
};
