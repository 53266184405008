import { kiosk_order_route } from 'constants/routesConsts';
import {
  setOpenItemNotesModal,
  toggleBlurEffect,
  toggleOrderModal,
  updateCurrentOrder,
} from 'pages/Dashboard/action';
import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FastAndEasySvg from './FastAndEasySvg';
import './kiosk-splash.scss';
import KioskFooter from './KioskFooter';

import { MENU_ORDER_FROM } from 'configuration/enums';
import { closeModal } from 'reducers/modals';
import initState from '../../../pages/Dashboard/initState';
import { ModalPortal } from 'components';
import KioskOrderTypeModal from './KioskOrderTypeModal';

const defaultCurrentOrder = {
  orderStatus: initState.currentOrder.orderStatus,
  orderFrom: MENU_ORDER_FROM.kiosk,
  orderType: initState.currentOrder.orderType,
  menuItems: initState.currentOrder.menuItems,
  totalCost: initState.currentOrder.totalCost,
  payableAmount: initState.currentOrder.payableAmount,
  mobileNumber: initState.currentOrder.mobileNumber,
  firstName: initState.currentOrder.firstName,
  lastName: initState.currentOrder.lastName,
  userId: initState.currentOrder.userId,
  iteration: initState.currentOrder.iteration,
  voidedAmount: 0,
  voucherId: '',
  voucherDiscount: '0',
  voucherName: '',
  selectedVoucher: {},
  discountId: '',
  discount: '0',
  discountName: '',
  selectedDiscount: {},
  publicHolidaySurcharge: '0',
  publicHolidaySurchargePer: '0',
  isDisplayGST: false,
  creditCardCharges: '0.00',
  gst: '0.00',
  deliveryCost: '0',
  minOrderValue: '0',
  specialDiscount: '0',
  upsalePrice: '0.00',
  loyaltyDiscount: '0',
  specials: [],
  rewards: [],
  offers: [],
  serviceCharge: 0,
};

export default function KioskStartScreen({ setKioskFlowType }) {
  const history = useHistory();
  const { logo } = useSelector((state) => state.dashboardReducer.storeConfig);
  const { primaryThemeColour, secondaryThemeColour } = useSelector(
    (state) => state.storeWebsiteLoaderDataReducer
  );
  const dispatch = useDispatch();
  const [isOrderTypeModalOpen, setIsOrderTypeModalOpen] = useState(false);
  const { enableAllOrderTypeKiosk } = useSelector(
    (state) => state.dashboardReducer?.orderSetup
  );

  useEffect(() => {
    batch(() => {
      dispatch(toggleOrderModal(false));
      dispatch(closeModal('staffPinModal'));
      dispatch(updateCurrentOrder(defaultCurrentOrder));
      dispatch(setOpenItemNotesModal(false));
      dispatch(toggleBlurEffect(false));
    });
    // eslint-disable-next-line
  }, []);

  const handleStartOrder = () => {
    enableAllOrderTypeKiosk
      ? setIsOrderTypeModalOpen(true)
      : history.push('/kiosk/order');
  };

  return (
    <>
      <div
        onClick={handleStartOrder}
        className="splash-container"
        style={{
          backgroundColor: primaryThemeColour,
          cursor: 'pointer',
        }}
      >
        <div className="splash-hub">
          <FastAndEasySvg primaryColor={primaryThemeColour} />
        </div>

        <KioskFooter logo={logo} secondaryColor={secondaryThemeColour} />
      </div>
      {isOrderTypeModalOpen && (
        <ModalPortal>
          <KioskOrderTypeModal
            show={isOrderTypeModalOpen}
            toggleModal={() => {
              setIsOrderTypeModalOpen(false);
            }}
            setKioskFlowType={setKioskFlowType}
          />
        </ModalPortal>
      )}
    </>
  );
}
