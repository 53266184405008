import { useEffect, useState } from 'react';

function useValidatePhoneNumber(number, time) {
  const regNum = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/; // eslint-disable-line
  const [hasError, setHasError] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [phoneValidate, setPhoneValidate] = useState('');
  const phoneNumber = number;
  const timeLength = time ? time : 1000

  /* eslint-disable */
  useEffect(() => {
    if (phoneNumber) {
      let checkString = phoneNumber.replace(/\s+/g, '');
      let checkStringLength = checkString.length;
      let validTimeout = 0;

      if (checkString.match(regNum) === null || checkStringLength !== 10) {
        setHasError(true);
        setPhoneValidate('');
        setErrorMessage('');
      }

      validTimeout = setTimeout(() => {
        if (checkString) {
          if (checkString.match(regNum) === null || checkStringLength !== 10) {
            setHasError(true);
            setPhoneValidate('has-error');
            setErrorMessage(
              'Please enter correct phone number like "0429 117 406"'
            );
          } else {
            setHasError(false);
            setPhoneValidate('valid');
            setErrorMessage('');
          }
        }
      }, timeLength);

      return () => {
        clearTimeout(validTimeout);
      };
    }
  }, [phoneNumber]);
  /* eslint-enable */

  return { errorMessage, phoneValidate, hasError };
}

export default useValidatePhoneNumber;
