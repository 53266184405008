import './bem-select-share-item.scss';
import { CounterUI, SelectItemUI } from '../index';
import clsx from 'clsx';

export const SelectShareItemUI = ({
  value,
  title,
  instruction,
  price,
  checked,
  onChange,
  quantity,
  onIncrement,
  onDecrement,
}) => {
  return (
    <div
      className={clsx('bem-select-share-item', {
        'bem-select-share-item--checked': checked,
      })}
    >
      <SelectItemUI
        price={price}
        title={title}
        instruction={instruction}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <div className="bem-select-share-item__sharing-box">
        <div className="bem-select-share-item__sharing-box-inner">
          <p className="bem-select-share-item__text">Sharing with</p>
          <CounterUI
            title="Guests"
            iconType="solid"
            quantity={quantity}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
          />
        </div>
      </div>
    </div>
  );
};
