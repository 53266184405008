function useCurrentGuest(list, selectedOrderId) {
  let guest = '';

  if (list.length) {
    for (let i = 0; i < list.length; i++) {
      if (list[i]._id == selectedOrderId) {
        guest = list[i];

        break;
      }
    }
  }

  return guest;
}

export default useCurrentGuest;
