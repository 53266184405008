import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconCheckmarkCircle = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  size,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-checkmark-circle': true,
    [`${className}`]: className,
  });

  return (
    <span
      className={iconCls}
      style={{
        width: size ? size : 40,
        height: size ? size : 40,
      }}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M8.983 13.92h-.001-.002zm-.001 0a.38.38 0 0 0 .26-.106l6.474-6.468-.54-.54L8.976 13l-2.382-2.378-.54.54 2.663 2.644a.38.38 0 0 0 .265.114zm-.002 0h-.005.005zm10.26-3.42a8.74 8.74 0 1 1-8.74-8.74 8.748 8.748 0 0 1 8.74 8.74zm.76 0a9.5 9.5 0 1 0-9.5 9.5 9.508 9.508 0 0 0 9.5-9.5z"
              />
              <path
                fill="none"
                strokeMiterlimit="20"
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness * 0.3 : 0.3}
                d="M8.983 13.92h-.001-.002zm-.001 0a.38.38 0 0 0 .26-.106l6.474-6.468-.54-.54L8.976 13l-2.382-2.378-.54.54 2.663 2.644a.38.38 0 0 0 .265.114zm-.002 0h-.005.005zm10.26-3.42a8.74 8.74 0 1 1-8.74-8.74 8.748 8.748 0 0 1 8.74 8.74zm.76 0a9.5 9.5 0 1 0-9.5 9.5 9.508 9.508 0 0 0 9.5-9.5z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconCheckmarkCircle.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
