import { API_URL } from 'configuration/apiUrl';
import { ApiClient } from './apiClient';
import axiosWithInterceptor from '../util/interceptor';
// import { OBJECT_TYPES, LAYOUT_SCHEME } from 'components/DineIn/constants';

const api = new ApiClient(null);
const layoutApiUrl = `${API_URL}/stores/v1/floorLayouts`;
const occupanciesApiUrl = `${API_URL}/stores/v1/dineInOccupancies`;

// PUT (update the layout)
export const updateLayoutAPI = async (updateData) => {
  const { storeId, id, name } = updateData;
  if (storeId && id && name) {
    let res = false;

    await api
      .put(layoutApiUrl, updateData)
      .then((response) => {
        res = response;
      })
      .catch((e) => {
        console.log('UPDATE LAYOUT ERROR', e);
      });

    return res;
  } else {
    console.error('storeId, id, name are required');
  }
};

// POST (create the layout)
export const createLayoutAPI = async (storeId, layoutName, data) => {
  if (storeId && layoutName && data) {
    let res = false;
    let body = { storeId, name: layoutName, ...data };

    await api
      .post(layoutApiUrl, body)
      .then((response) => {
        res = response;
      })
      .catch((e) => {
        console.log('UPDATE LAYOUT ERROR', e);
      });

    return res;
  } else {
    console.error('storeId, layoutName and data are required');
  }
};

export const deleteLayoutAPI = async (layoutId) => {
  if (layoutId) {
    let res = false;
    await api
      .delete(`${layoutApiUrl}/${layoutId}`)
      .then((response) => {
        res = response;
      })
      .catch((e) => {
        console.log('DELETE LAYOUT ERROR', e);
      });
    return res;
  } else {
    console.error('layoutId is required');
  }
};

// DELETE (object on the layout)
export const deleteFromLayoutAPI = async (layoutId, type, objId) => {
  if (layoutId && objId) {
    await api
      .delete(`${layoutApiUrl}/${layoutId}/${type}/${objId}`)
      .catch((e) => {
        console.log('DELETE OBJECT ERROR', e);
      });
  } else {
    console.error('layoutId and objId are required');
  }
};

// POST (start sitting)
export const startOccupancyAPI = async (
  storeId,
  layoutId,
  staffId,
  dineInObjectIds,
  numPeople,
  accessToken = ''
) => {
  if (storeId && layoutId && dineInObjectIds && numPeople) {
    let res = false;
    let body = {
      dineInObjectIds,
      layoutId,
      staffId: staffId || '',
      storeId,
      numPeople,
    };

    await axiosWithInterceptor
      .post(occupanciesApiUrl, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        res = response?.data;
      })
      .catch((e) => {
        console.log('OCCUPANCY START ERROR', e);
      });

    return res;
  } else {
    console.error('storeId, layoutId, and dineInObjectIds are required');
  }
};

// POST (end sitting)
export const endOccupancyAPI = async (dineInOccupancyId, accessToken = '') => {
  if (dineInOccupancyId) {
    let res = false;

    await axiosWithInterceptor
      .post(
        `${occupanciesApiUrl}/end`,
        {
          dineInOccupancyId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        res = response;
      })
      .catch((e) => {
        console.log('OCCUPANCY END ERROR', e);
      });

    return res;
  } else {
    console.error('dineInOccupancyId is required');
  }
};

// POST (end sitting)
export const getOccupancyAPI = async ({
  storeId,
  layoutId,
  dineInObjectId,
  hasEnded = false,
  accessToken = '',
}) => {
  const queryObj = {
    ...(storeId && { 'filter.storeId': storeId }),
    ...(layoutId && { 'filter.layoutId': layoutId }),
    ...(dineInObjectId && { 'filter.dineInObjectId': dineInObjectId }),
    'filter.hasEnded': hasEnded,
  };
  const queryString = Object.entries(queryObj).reduce(
    (str, [key, value], index) =>
      (str += `${index !== 0 ? '&' : ''}${key}=${value}`),
    '?'
  );

  let res = false;

  await axiosWithInterceptor
    .get(occupanciesApiUrl + queryString, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      res = response.data;
    })
    .catch((e) => {
      console.log('OCCUPANCY GET ERROR', e);
    });
  return res;
};
