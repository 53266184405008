import { AnimateSharedLayout, motion } from 'framer-motion';
import PropTypes from 'prop-types';
export default function Tabs({
  allTabs,
  tabTextExtractor = (tab) => tab,
  uniqueTabIdExtractor = (tab) => tab,
  uniqueCodeExtractor = (tab) => tab,
  selectedTabCode,
  onChange = () => {},
}) {
  if (!allTabs.length) {
    return null;
  }

  return (
    <AnimateSharedLayout>
      <ul className="switch-btn-box">
        {allTabs.map((tab) => (
          <Item
            key={uniqueTabIdExtractor(tab)}
            tabName={tabTextExtractor(tab)}
            isSelected={selectedTabCode === uniqueCodeExtractor(tab)}
            onClick={() => onChange(tab)}
          />
        ))}
      </ul>
    </AnimateSharedLayout>
  );
}

/**
 * these can be more specific, but in the interest
 * of moving fast, keeping it simple, to learn more
 * refer to https://reactjs.org/docs/typechecking-with-proptypes.html
 * to get expert level mastery over proptypes read
 * https://github.com/reactjs/react-tabs's code
 */
Tabs.propTypes = {
  allTabs: PropTypes.array.isRequired,
  tabTextExtractor: PropTypes.func,
  uniqueTabIdExtractor: PropTypes.func,
  uniqueCodeExtractor: PropTypes.func,
  selectedTabCode: PropTypes.string,
  onChange: PropTypes.func,
};

function Item({ tabName, isSelected, onClick }) {
  return (
    <li className="relative">
      {isSelected && (
        <motion.div layoutId="highlight" className="switch-btn-bg-box" />
      )}
      <button
        type="button"
        onClick={onClick}
        className={isSelected ? 'selected' : ''}
      >
        {tabName}
      </button>
    </li>
  );
}

Item.propTypes = {
  tabName: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
