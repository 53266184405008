import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ScheduledDropdown } from '../../ScheduledDropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  setScrollPosition,
  setOrderList,
  setCopyOfOrderList,
} from '../../../pages/Orders/actions';
// import { SearchBox } from './SearchBox';
import SearchInput from '../../../pages/Orders/components/SearchInput/SearchInput';
import AllAndUpaidFilter from './AllAndUpaidFilter';

export const ReadySidebarHeader = () => {
  const dropDownArr = [
    { id: 1, text: 'Expected Time', key: 'deliveryDateTimestamp' },
    { id: 2, text: 'Placed Time', key: 'dateTimestamp' },
  ];
  const [showDropDown, toggleDropDown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(dropDownArr[0]);
  const dispatch = useDispatch();
  const { orderList, orderType} = useSelector(
    (state) => state.ordersReducer
  );
  const shadow = useSelector(
    (state) => state.ordersReducer.scrolledStatus[17].position
  );
  const scrollActionType = 'sidebarReadyScroll';
  const navCls = classNames({
    'nav-bar-header': true,
    shadow,
  });

  useEffect(() => {
    sortOrdersBasedOnTimeStamp(selectedFilter);
  }, [orderList]);

  useEffect(() => {
    dispatch(setScrollPosition({ actionType: scrollActionType, position: 0 }));
  }, [orderType]); // eslint-disable-line react-hooks/exhaustive-deps

  const onItemClick = (selectedItem) => {
    setSelectedFilter(selectedItem);
    toggleDropDown(false);
    sortOrdersBasedOnTimeStamp(selectedItem);
  };

  const sortOrdersBasedOnTimeStamp = (selectedItem) => {
    orderList.sort((order1, order2) => {
      return order2[selectedItem.key] - order1[selectedItem.key];
    });
    dispatch(setOrderList(orderList));
    dispatch(setCopyOfOrderList(orderList));
  };

  return (
    <div className={navCls}>
      <div
        className="top-box"
        // style={{
        //   display: 'flex',
        //   alignItems: 'center',
        //   margin: '0 0 10px 0',
        //   justifyContent: 'space-between',
        // }}
      >
        <h1 className="nav-bar-title">Current</h1>
        {/* <ScheduledDropdown
          title={selectedFilter.text}
          showDropDown={showDropDown}
          clickHandler={() => toggleDropDown(!showDropDown)}
        >
          {dropDownArr.map((item) => {
            return (
              <div
                className={`item hover-highlight ${
                  item.id === selectedFilter.id ? ' selected' : ''
                } `}
                onClick={() => onItemClick(item)}
                key={item.id}
              >
                {item.text}
              </div>
            );
          })}
        </ScheduledDropdown> */}
        {/* <AllAndUnPaidFilter /> */}
        <AllAndUpaidFilter />
      </div>

      {/* <SearchBox /> */}
      <SearchInput />
    </div>
  );
};
