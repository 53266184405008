import { API_URL } from '../configuration/apiUrl';

export const addUserWithMobileNumber = async (data = {}) => {
  const response = await fetch(`${API_URL}/addUserWithMobileNumber`, {
    method: 'post',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return await response.json();
};
