import { loadWindcavePinpads, loadWindcaveUser } from 'pages/Dashboard/action';
import handleAddTerminalSetting from 'pages/Dashboard/apis/handleAddTerminalSetting';
import { storeConfigSelector } from 'pages/Dashboard/selectors';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CashierContent } from '../../components/Cashier/CashierContent';
import { CashierHeader } from '../../components/Cashier/CashierHeader';
import { nextOrderConfig } from '../../configuration/nextOrderConfig';
import { AddDrawerModal } from '../../modals/AddDrawerModal';
import { PairTerminal } from '../../modals/PairTerminal';
import { WindCavePairTerminalModal } from '../../modals/WindCavePairTerminalModal';
import { WindCaveUserModal } from '../../modals/WindcaveUserModal';
import { fetchDrawerList, fetchPrinterList } from './actions';
import { windcaveCountriesIdentifier } from '../../util/utils';

const Cashier = () => {
  const [modalStatus, setModalStatus] = useState(false);
  const [pairTerminal, setPairTerminal] = useState(false);
  const [openWindCavePairTerminalModal, setOpenWindCavePairTerminalModal] =
    useState(false);

  const [openWindCaveUserModal, setOpenWindCaveUserModal] = useState(false);

  const [isWindcaveApiLoading, setIsWindcaveApiLoading] = useState(false);
  const dispatch = useDispatch();
  const cashierPage = useSelector((state) => state.cashierReducer);
  const { storeId, restaurantId, timeZone, name, countryIdentifier } =
    useSelector(storeConfigSelector);

  useEffect(() => {
    dispatch(fetchDrawerList(storeId));
    dispatch(fetchPrinterList(storeId));
  }, [dispatch, storeId]);

  useEffect(() => {
    if (storeId && windcaveCountriesIdentifier.includes(countryIdentifier)) {
      dispatch(loadWindcavePinpads(storeId));
      dispatch(loadWindcaveUser(storeId));
    }
  }, [storeId, countryIdentifier]);

  const drawerNames = useMemo(
    () => cashierPage.drawers.map((d) => d.name?.toLowerCase()),
    [cashierPage.drawers]
  );
  const drawerPrinterIds = useMemo(
    () => cashierPage.drawers.map((d) => d.printerId),
    [cashierPage.drawers]
  );

  const handleToggleModal = () => {
    setModalStatus(!modalStatus);
  };

  const handlePiarTerminalModal = () => {
    setPairTerminal(!pairTerminal);
  };

  const handleWindCavePairTerminalModal = () => {
    setOpenWindCavePairTerminalModal(!openWindCavePairTerminalModal);
  };

  const handleWindCaveUserModal = () => {
    setOpenWindCaveUserModal(!openWindCaveUserModal);
  };

  const addDrawerHandler = (name, printerId) => {
    handleToggleModal();
    let obj = {
      name,
      printerId,
      storeId,
      restaurantId: restaurantId,
      timeZone: timeZone,
      staffId: 'xyz',
    };
    fetch(`${nextOrderConfig?.cloudFunctionUrl}/addDrawer`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success && result.drawerId) {
          // update the select drawer dropdown with a newly added
          dispatch(handleAddTerminalSetting({ drawerId: result.drawerId }));
        } else {
        }
      });
  };

  const windcavePairTerminalHandler = (terminalId) => {
    setIsWindcaveApiLoading(true);
    const body = {
      terminalId: terminalId,
      storeId: storeId,
      restaurantId: restaurantId,
    };
    fetch(
      `${nextOrderConfig?.baseUrl}/windcave/v1/terminal?storeId=${storeId}`,
      {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    )
      .then(async (response) => {
        const responseJson = await response.json();
        console.log('responseJson', responseJson);
      })
      .finally(() => {
        handleWindCavePairTerminalModal();
        setIsWindcaveApiLoading(false);
      });
  };

  const windcaveUserHandler = (user, key, restUser, restKey) => {
    setIsWindcaveApiLoading(true);
    const body = {
      user: user,
      key: key,
      restUser,
      restKey,
      storeId: storeId,
    };
    fetch(`${nextOrderConfig?.baseUrl}/windcave/v1/user`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        const responseJson = await response.json();
      })
      .finally(() => {
        handleWindCaveUserModal();
        setIsWindcaveApiLoading(false);
      });
  };

  const pairTerminalHandler = (userName, password, pairCode, terminalName) => {
    handlePiarTerminalModal();
    let obj = {
      userName,
      password,
      pairCode,
      terminalName,
      storeId,
      restaurantId,
      restaurantName: name,
      timeZone,
    };
    fetch(`${nextOrderConfig?.cloudFunctionUrlSydney}/pairPinPad`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success) {
        } else {
        }
      });
  };

  return (
    <React.Fragment>
      <CashierHeader
        handlePiarTerminalModal={handlePiarTerminalModal}
        handleWindCaveUserModal={handleWindCaveUserModal}
        handleWindCavePairTerminalModal={handleWindCavePairTerminalModal}
        toggleModal={handleToggleModal}
        setOpenWindCavePairTerminalModal={setOpenWindCavePairTerminalModal}
      />

      <CashierContent
        drawers={cashierPage.drawers}
        toggleModal={handleToggleModal}
      />

      <AddDrawerModal
        modalStatus={modalStatus}
        toggleModal={handleToggleModal}
        printers={cashierPage.printers}
        addDrawerHandler={addDrawerHandler}
        drawerNames={drawerNames}
        drawerPrinterIds={drawerPrinterIds}
      />

      <PairTerminal
        modalStatus={pairTerminal}
        toggleModal={handlePiarTerminalModal}
        pairTerminalHandler={pairTerminalHandler}
      />

      {openWindCavePairTerminalModal && (
        <WindCavePairTerminalModal
          modalStatus={openWindCavePairTerminalModal}
          toggleModal={handleWindCavePairTerminalModal}
          windcavePairTerminalHandler={windcavePairTerminalHandler}
          isWindcaveApiLoading={isWindcaveApiLoading}
        />
      )}

      {openWindCaveUserModal && (
        <WindCaveUserModal
          modalStatus={openWindCaveUserModal}
          toggleModal={handleWindCaveUserModal}
          windcaveUserHandler={windcaveUserHandler}
          isWindcaveApiLoading={isWindcaveApiLoading}
        />
      )}
    </React.Fragment>
  );
};

export default Cashier;
