import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/Button';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import GeneralModal from '../GeneralModal/index-new';

const ItemNotesModal = ({
  modalStatus,
  toggleModal,
  handleNotesSubmission,
}) => {
  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onInitInput,
    onChangeInput,
  } = useVirtualKeyboardContext();
  const currentItem = useSelector((s) => s.dashboardReducer.currentMenuItem);
  const inputRef = useRef(null);
  const inputValue = getInputValue('itemNotes');

  useEffect(() => {
    if (inputValue === '' && currentItem?.notes !== '') {
      onInitInput('itemNotes', currentItem?.notes);
    }
  }, [currentItem?.notes, onInitInput, getInputValue, inputValue]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleFocus = () => {
    setInputName('itemNotes');

    toggleKeyboard(true);
  };

  const handleChange = (event) => {
    event.preventDefault();

    const inputVal = event.target.value;
    onChangeInput('itemNotes', inputVal);
  };

  const onConfirmNotes = () => {
    handleNotesSubmission(getInputValue('itemNotes'));
    onInitInput('itemNotes', '');
    toggleKeyboard(false);
    toggleModal();
  };

  React.useEffect(() => {
    if (modalStatus) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
  }, [modalStatus]);

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={() => {
        if (modalStatus) {
          toggleKeyboard(false);
        }
        toggleModal();
      }}
      position="center"
      effect="move"
      overlay=""
      boxWidth="514px"
      boxHeight="342px"
      className="denominations-short-modal small-radius"
      keyboardVisibility={keyboardVisibility}
    >
      <div className="denominations-short-modal-box">
        <h2 className="item-notes-title" style={{ marginBottom: 31 }}>
          Enter Item Notes
        </h2>

        <form
          action=""
          className="denominations-short-form"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="single-input-box">
            <textarea
              id="itemNotes"
              name="itemNotes"
              className="input-style white"
              value={inputValue}
              onFocus={handleFocus}
              onChange={handleChange}
              placeholder="Please enter the notes here"
              ref={inputRef}
            />
          </div>
        </form>

        <div className="denominations-short-btn-box">
          <Button
            title="Confirm"
            className="btn"
            onClick={onConfirmNotes}
            enableThemeColor
          >
            Confirm
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

ItemNotesModal.propTypes = {
  totalPrice: PropTypes.any,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  confirmForm: PropTypes.func,
};

export default ItemNotesModal;
