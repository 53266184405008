import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';

export const PosIconBell = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className = '',
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);

  return (
    <span className={className} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 893 1000"
      >
        <g>
          <path
            fill={iconColor ? iconColor : '#000'}
            d="M446.7,1000c78.7,0,142.5-63.8,142.5-143H303.7C303.7,936.2,367.5,1000,446.7,1000z"
          />
          <path
            fill={iconColor ? iconColor : '#000'}
            d="M776.8,508.9c-24.2-235.9-71.2-347.5-189.6-386.8C577.1,53.1,518.3,0,446.5,0c-71.8,0-130.6,53.1-140.8,122.1
		c-119.3,33.6-165.4,151-189.6,386.8c0,133.9-116.1,102.7-116.1,205.3s107.1,84.8,107.1,84.8h339.3h339.3c0,0,107.1,17.9,107.1-84.8
		S776.8,642.8,776.8,508.9z"
          />
        </g>
      </svg>
    </span>
  );
};

PosIconBell.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
