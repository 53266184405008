const FetchStatusEnum = {
  NOT_STARTED: 'NOT_STARTED',
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
};

const paymentType = [
  {
    id: '0',
    type: 'unpaid',
  },
  {
    id: '1',
    type: 'cash',
  },
  {
    id: '2',
    type: 'credit-card',
  },
  {
    id: '3',
    type: 'credit-card',
  },
  {
    id: '4',
    type: 'mobile-terminal',
  },
];

const orderType = [
  {
    id: '0',
    type: 'walkin',
    class: 'pickup-id',
    text: 'Walk-in',
    route: '/walkin/order/',
  },
  {
    id: '1',
    type: 'confined pickup',
    class: 'pickup-id',
    text: 'PickUp',
    route: '/pickup/order/',
  },
  {
    id: '2',
    type: 'out for delivery',
    class: 'delivery-id',
    text: 'Delivery',
    route: '/delivery/order/',
  },
  {
    id: '3',
    type: 'dine-in',
    class: 'pickup-id',
    text: 'Dine-in',
    route: '/dinein/order/',
  },
  {
    id: '4',
    type: 'drive-thru',
    class: 'drive-thru-id',
    text: 'Drive-Thru',
    route: '/drivethru/order/',
  },
];

const orderFrom = [
  {
    id: '1',
    type: 'web',
    title: 'Web',
  },
  {
    id: '2',
    type: 'phone',
    title: '',
  },
  {
    id: '3',
    type: 'web',
    title: 'Deliverect',
  },
];

export const paymentTypeObj = {
  0: {
    id: '0',
    type: 'unpaid',
    class: 'unpaid',
    displayName: 'Unpaid',
  },
  1: {
    id: '1',
    type: 'cash',
    class: 'cash',
    displayName: 'Cash',
  },
  2: {
    id: '2',
    type: 'credit-card',
    class: 'card-online',
    displayName: 'Credit Card',
  },
  3: {
    id: '3',
    type: 'credit-card',
    class: 'card-in-store',
    displayName: 'Credit Card',
  },
  4: {
    id: '4',
    type: 'mobile-terminal',
    class: 'mobile-terminal',
    displayName: 'Mobile Terminal',
  },
  11: {
    id: '11',
    type: 'custom',
    class: 'custom',
    displayName: 'Custom',
  },
};

export const orderTypeObj = {
  0: {
    id: '0',
    type: 'walkin',
    class: 'pickup-id',
    text: 'Walk-in',
    route: '/walkin/order/',
  },
  1: {
    id: '1',
    type: 'confined pickup',
    class: 'pickup-id',
    text: 'PickUp',
    route: '/pickup/order/',
  },
  2: {
    id: '2',
    type: 'out for delivery',
    class: 'delivery-id',
    text: 'Delivery',
    route: '/delivery/order/',
  },
  3: {
    id: '3',
    type: 'dine-in',
    class: 'pickup-id',
    text: 'Dine-in',
    route: '/dinein/order/',
  },
  4: {
    id: '4',
    type: 'drive-thru',
    class: 'drive-thru-id',
    text: 'Drive-Thru',
    route: '/drivethru/order/',
  },
};

export const orderFromObj = {
  1: {
    id: '1',
    type: 'web',
    title: 'Web',
  },
  2: {
    id: '2',
    type: 'phone',
    title: '',
  },
  3: {
    id: '3',
    type: 'web',
    title: 'Deliverect',
  },
  4: {
    id: '4',
    type: 'hubRise',
  },
};

const orderTypesIds = ['0', '1', '2', '3', '4'];

export { FetchStatusEnum, paymentType, orderFrom, orderType, orderTypesIds };

export const AUTH_COOKIE_TOKEN_NAME = 'pos-auth';
export const AUTH_COOKIE_EXPIRATION_DAYS = 365;

export const NAVIGATION_SHOW_MODEL_URLS = [
  'pickup/order',
  'walkin/order',
  'delivery/order',
  'dinein/order',
];
export const NAVIGATION_SHOULD_GO_BACK_URLS = ['select-role', 'view-shifts'];

export const CURRENCIES = {
  GBP: '£',
  USD: '$',
  AUD: '$',
};
