import React, { useEffect, useState } from 'react';
import OrderBarTextField from '../OrderBarTextField';

export const OrderBarTableNumber = ({ currentOrder }) => {
  const [tableNumber, setTableNumber] = useState('');
  useEffect(() => {
    let tableNumber = currentOrder.tableNumber;
    setTableNumber(tableNumber);
  }, [currentOrder.tableNumber]);

  return (
    <div className="single-pickup-details input-box">
      <OrderBarTextField
        placeholder="Table Number"
        value={`Table ${tableNumber}`}
        disabled
      />
      <div className="icon-box">
        <span className="dine_icon-shape_man" />
      </div>
    </div>
  );
};
