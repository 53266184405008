export const SVG_ASSET_URL = 'https://assets.nextorder.co/icons/';

export const OBJECT_TYPES = [
  'dineInObjects',
  'nonDineInObjects',
  'layoutDividers',
];

export const MAP_OBJECT_TYPES = {
  SquareTable: 'dineInObjects',
  RoundTable: 'dineInObjects',
  RectangularTable: 'dineInObjects',
  RhombusTable: 'dineInObjects',
  LineDivider: 'layoutDividers',
  PottedPlant: 'nonDineInObjects',
  Piano: 'nonDineInObjects',
  Staircase: 'nonDineInObjects',
};

export const SVG_FILENAME_MAP_TYPE = {
  SquareTable: 'T-',
  RoundTable: 'TCS-',
  RectangularTable: 'TS-',
  RhombusTable: 'TRH-',
  LineDivider: 'LineDivider',
  PottedPlant: 'PottedPlant',
};

export const LAYOUT_OBJECT_NAMES_MAP = {
  PottedPlant: 'Potted Plant',
};

export const LAYOUT_SCHEME = Object.freeze({
  dineInObjects: [
    {
      id: 'string',
      name: 'string',
      numSeats: 0,
      number: 'string',
      orientationAngle: 0,
      positionX: 15,
      positionY: 15,
      scale: 0,
      type: 0,
    },
  ],
  layoutDividers: [
    {
      colour: 'string',
      endX: 0,
      endY: 0,
      id: 'string',
      startX: 0,
      startY: 0,
    },
  ],
  id: '',
  isActive: true,
  name: '',
  nonDineInObjects: [
    {
      id: 'string',
      orientationAngle: 0,
      positionX: 15,
      positionY: 15,
      scale: 0,
      type: 0,
    },
  ],
  storeId: '',
});

export const SHAPE_OBJECTS = [
  {
    name: '',
    number: 0,
    type: 'SquareTable',
    className: 'SquareTable',
    objectType: 'dineInObjects',
    numSeats: 4,
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'T-04.svg',
  },
  {
    name: '',
    number: 0,
    type: 'RoundTable',
    className: 'RoundTable',
    objectType: 'dineInObjects',
    numSeats: 8,
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'TCS-08.svg',
  },
  {
    name: '',
    number: 4,
    type: 'RhombusTable',
    className: 'RhombusTable',
    objectType: 'dineInObjects',
    numSeats: 4,
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'TRH-04.svg',
  },
  {
    name: '',
    number: 0,
    type: 'RectangularTable',
    className: 'RectangularTable',
    objectType: 'dineInObjects',
    numSeats: 6,
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'TS-06.svg',
  },
  {
    name: '',
    type: 'PottedPlant',
    className: 'PottedPlant',
    objectType: 'nonDineInObjects',
    positionX: 15,
    positionY: 15,
    orientationAngle: 0,
    scale: 0,
    svgImg: 'PottedPlant.svg',
  },
  // NEX-395: Remove the wall element from the options for now
  // {
  //   name: '',
  //   type: 'LineDivider',
  //   className: 'LineDivider',
  //   startX: 0,
  //   startY: 0,
  //   endX: 0,
  //   endY: 0,
  //   colour: '',
  //   svgImg: 'LineDivider.svg',
  //   objectType: 'layoutDividers',
  // },
];

export const AVAILABLE_SHAPES = [
  'RectangularTable',
  'SquareTable',
  'RoundTable',
  'RhombusTable',
  'PottedPlant',
  // 'LineDivider', // NEX-395: Remove the wall element from the options for now
];

export const MAX_SHAPE_SIZE = 120; // this is based on the half of the size of the biggest shape available at the momemnt
export const INITIAL_SHAPE_POSITION = 15; // for both X and Y coordinate
