import { API_URL } from 'configuration/apiUrl';
import ApiClient from 'services/apiClient';

import { useEffect, useState } from 'react';
import axiosWithInterceptor from '../../../util/interceptor';
import { useSelector } from 'react-redux';
const layoutApiUrl = `${API_URL}/stores/v1/floorLayouts`;

function setInitialResponse() {
  let defaults = { data: [], pending: true, error: false };

  return (object = {}) => {
    const result = Object.assign({}, defaults, object);
    defaults = result;
    return result;
  };
}

export default function useGetFloorLayouts() {
  const createResponse = setInitialResponse();
  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken
  );
  const [response, setResponse] = useState(createResponse());

  // useEffect(
  //   () => () => {
  //     // cancel pending request with component unmount
  //     ApiClient.cancel(layoutApiUrl);
  //   },
  //   []
  // );

  const request = (storeId) => {
    setResponse(createResponse({ pending: true }));
    axiosWithInterceptor
      .get(layoutApiUrl, {
        params: {
          'filter.storeId': storeId || '',
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res?.data?.data?.floorLayouts?.length > 0) {
          setResponse(
            createResponse({
              data: res.data.data?.floorLayouts,
              error: false,
              pending: false,
            })
          );
        }
      })
      .catch((err) => {
        if (!ApiClient.isCancel(err)) {
          setResponse(createResponse({ data: null, error: err }));
        }
      })
      .finally(() => {
        setResponse(createResponse({ pending: false }));
      });
  };

  return [response, request];
}
