import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import IconMinus from 'assets/icons/IconMinus';
import IconPlus from 'assets/icons/IconPlus';
import IconInfo from 'assets/icons/IconInfo';

const findNextAvailableTableNumber = (items, value) => {
  let nextNum = false;

  while (!nextNum) {
    const nextValue = value + 1;
    const isAssigned = items.find((item) => item === nextValue);

    if (!isAssigned) {
      nextNum = true;
      return nextValue;
    } else {
      value++;
    }
  }

  return false;
};

const findPrevAvailableTableNumber = (items, value) => {
  while (value >= 0) {
    const nextValue = value - 1;
    const isAssigned = items.find((item) => item === nextValue);

    if (!isAssigned) {
      return nextValue;
    } else {
      value--;
    }
  }

  return false;
};

export const TableNumberControl = ({
  title,
  paramName,
  paramValue = 0,
  desc,
  className,
  handleTableValueChange,
  availableTableNums,
}) => {
  const [value, setValue] = useState(Number(paramValue));

  const prevNum = findPrevAvailableTableNumber(availableTableNums, value);
  const nextNum = findNextAvailableTableNumber(availableTableNums, value);

  const [disabledMinus, setDisabledMinus] = useState(!prevNum);

  const handleChange = (param) => {
    let newValue = value;

    if (param === '+') {
      newValue = nextNum;
      setDisabledMinus(false);
    }

    if (param === '-') {
      if (disabledMinus) return;

      if (prevNum) {
        newValue = prevNum;
        setDisabledMinus(!prevNum - 1);
      } else {
        setDisabledMinus(true);
      }
    }

    setValue(newValue);
    handleTableValueChange(paramName, String(newValue));
  };

  const minusCssClass = clsx('table-config-control__button', {
    'table-config-control__button--disabled': disabledMinus,
  });

  useEffect(() => {
    setValue(Number(paramValue));
  }, [paramValue]);

  return (
    <div className={className + ' table-config-control'}>
      {title && <h2 className="table-config-control__title">{title}</h2>}
      <div className="table-config-control__counter">
        <button
          className={minusCssClass}
          onClick={() => {
            handleChange('-');
          }}
        >
          <IconMinus />
        </button>
        <div className="table-config-control__count">{value}</div>
        <button
          className="table-config-control__button"
          onClick={() => {
            handleChange('+');
          }}
        >
          <IconPlus />
        </button>
      </div>
      {desc && (
        <div className="table-config-control__info">
          <IconInfo className="table-config-control__info-icon" />
          <p>{desc}</p>
        </div>
      )}
    </div>
  );
};
