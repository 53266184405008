import React, { useEffect, useCallback } from 'react';
import useCategories from 'hooks/useCategories';
import useOrder from 'hooks/useOrder';
import { Category } from './Category';
import { ScrollableBoxUI } from '../../UI/components';

export const Categories = ({ isKiosk }) => {
  const [{ selectedCategoryId }, { setState }] = useOrder();
  const categories = useCategories();

  const selectCategory = useCallback(
    (_id) => {
      setState({ selectedCategoryId: _id });
    },
    [setState]
  );

  useEffect(() => {
    if (categories?.length > 0) {
      const [first, second] = categories;
      if (first && second && !selectedCategoryId) {
        setState({
          selectedCategoryId:
            first._id === 'order_history' ? second._id : first._id,
        });
      }
    }
  }, [categories, setState, selectedCategoryId]);

  return (
    <div
      className="category-nav-wrap"
      style={!isKiosk ? undefined : { height: '100%' }}
    >
      <ScrollableBoxUI>
        <nav className="category-nav top">
          {categories?.map((category) => (
            <Category
              key={category._id}
              active={selectedCategoryId === category._id}
              onClick={selectCategory}
              isKiosk={isKiosk}
              {...category}
            />
          ))}
        </nav>
      </ScrollableBoxUI>
    </div>
  );
};
