import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconSendMoney = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-send-money': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="25"
        viewBox="0 0 26 25"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M11.5 1.718l2.14 2.14.71-.71-3-3a.5.5 0 0 0-.71 0l-3 3 .71.71 2.15-2.14v6.29h1zm2 13.79a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm-2.5 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zm-9.5-8h19v9h-19zm4 10H21a.5.5 0 0 0 .5-.5v-5.5h3v9h-19zm20 3.5v-10a.5.5 0 0 0-.5-.5h-3.5v-3.5a.5.5 0 0 0-.5-.5H1a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h3.5v3.5a.5.5 0 0 0 .5.5h20a.5.5 0 0 0 .5-.5z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconSendMoney.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
