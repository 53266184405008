import axios from 'axios';
import { nextOrderConfig } from '../../../configuration/nextOrderConfig';
import { setTerminalSettingsDisabled } from '../../Cashier/actions';
import { addTerminalSettingSelector } from '../selectors';

const handleAddTerminalSetting = (params) => {
  return async (dispatch, getState) => {
    const addTerminalSetting = addTerminalSettingSelector(getState());
    const reqObject = {
      ...addTerminalSetting,
      ...params,
    };
    dispatch(setTerminalSettingsDisabled(true));
    await axios.post(
      `${nextOrderConfig?.cloudFunctionUrl}/addTerminalSetting`,
      reqObject
    );
    dispatch(setTerminalSettingsDisabled(false));
  };
};

export default handleAddTerminalSetting;
