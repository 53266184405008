import './bem-table-card.scss';
import { GroupPeopleIcon } from 'nexticons/outline';
import PropTypes from 'prop-types';

export const TableCardUI = ({ tableName, floorName, seats, ...rest }) => {
  return (
    <button className="bem-table-card" type="button" {...rest}>
      <span className="bem-table-card__table">{tableName}</span>
      <span className="bem-table-card__seats-box">
        {seats && (
          <>
            <GroupPeopleIcon width="22" />
            <span className="bem-table-card__seats">{seats}</span>
          </>
        )}
      </span>
      <span className="bem-table-card__floor">{floorName}</span>
      <span className="bem-table-card__icon-box" />
    </button>
  );
};

TableCardUI.defaultProps = {
  tableName: '',
  floorName: '',
  seats: undefined,
};

TableCardUI.propTypes = {
  tableName: PropTypes.string,
  floorName: PropTypes.string,
  seats: PropTypes.number,
};
