import IconInfo from 'assets/icons/IconInfo';
import IconMinus from 'assets/icons/IconMinus';
import IconPlus from 'assets/icons/IconPlus';

export const TableOrientationControl = ({
  title,
  paramName,
  paramValue,
  min,
  max,
  step,
  desc = '',
  className,
  handleTableValueChange,
}) => {
  const handleChange = (newValue) => {
    handleTableValueChange(paramName, newValue);
  };

  return (
    <div className={className + ' table-config-control'}>
      {title && <h2 className="table-config-control__title">{title}</h2>}

      <div className="table-config-control__counter">
        <button
          className="table-config-control__button"
          onClick={() =>
            handleChange(
              Number(paramValue) > min ? Number(paramValue) - step : 0
            )
          }
        >
          <IconMinus />
        </button>
        <div className="table-config-control__count">{paramValue}&#176;</div>
        <button
          className="table-config-control__button"
          onClick={() =>
            handleChange(
              Number(paramValue) < max ? Number(paramValue) + step : max
            )
          }
        >
          <IconPlus />
        </button>
      </div>
      {desc && (
        <div className="table-config-control__info">
          <IconInfo className="table-config-control__info-icon" />
          <p>{desc}</p>
        </div>
      )}
    </div>
  );
};
