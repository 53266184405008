import { isEmpty } from '../util/utils';
import { getItemPrice } from './utilities';

const getPayableAmount = (orderObj, productSetup) => {
  let payableAmount = 0;
  let totalCost = 0;
  let voidedAmount = 0;

  orderObj.menuItems.map((menuItem, i) => {
    menuItem.orderIndex = i;

    let itemPrice = 0;

    if (!isEmpty(menuItem.payablePrice)) {
      itemPrice = menuItem.payablePrice;
    } else {
      const { payablePrice } = getItemPrice(menuItem, {});
      itemPrice = payablePrice;
    }

    if (!menuItem.isVoided) {
      totalCost = totalCost + itemPrice;
    } else {
      voidedAmount = voidedAmount + itemPrice;
    }

    return menuItem;
  });

  payableAmount = Number(totalCost.toFixed(2));
  voidedAmount = Number(voidedAmount.toFixed(2));

  return { payableAmount, totalCost, voidedAmount };
};

export default getPayableAmount;
