export function getIconSize(
  size = 'normal',
  customWidth = '',
  customHeight = ''
) {
  let width = 32;
  let height = 32;

  if (size === 'small') {
    width = 16;
    height = 16;
  } else if (size === 'large') {
    width = 64;
    height = 64;
  } else if (size === 'custom' && customWidth && customHeight) {
    width = customWidth;
    height = customHeight;
  }

  return { width, height };
}
