import orderEngine from '../orderEngine';

const updateItemToOrder = (
  currentOrder,
  item,
  allSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig
) => {
  const newCurrentOrder = {
    ...currentOrder,
    menuItems: currentOrder.menuItems.map((menuItem) => {
      if (menuItem?.orderIndex === item?.orderIndex && menuItem._id === item._id) {
        return {
          ...item,
        };
      }
      return { ...menuItem };
    }),
  };

  const whatever = orderEngine(
    newCurrentOrder,
    allSpecials,
    orderSetup,
    productSetup,
    allSuburbs,
    publicHolidays,
    storeConfig
  );

  return whatever;
};

export default updateItemToOrder;
