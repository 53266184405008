export default function FastAndEasySvg({
  primaryColor = '#0183F5',
  secondayColor = '#F1FFFF',
}) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 583 905"
      style={{
        enableBackground: 'new 0 0 583 905',
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '100%',
        width: '100%',
        padding: 20,
      }}
      // xml:space="preserve"
    >
      <g>
        <rect fill={primaryColor} width="583" height="905" />
        <path
          fill={secondayColor}
          d="M149.1,93.2V68.7h15.5v4.1h-10.9v5.8h9.4v4.1h-9.4v10.4H149.1z"
        />
        <path
          fill={secondayColor}
          d="M196.8,93.2h-5l-2-5.6h-9l-1.9,5.6h-4.8l8.8-24.5h4.8L196.8,93.2z M188.4,83.5l-3.1-9.1l-3,9.1H188.4z"
        />
        <path
          fill={secondayColor}
          d="M206.8,85.2l4.4-0.5c0.3,1.6,0.8,2.8,1.6,3.6c0.8,0.8,1.9,1.1,3.3,1.1c1.5,0,2.6-0.3,3.3-1s1.1-1.5,1.1-2.4
		c0-0.6-0.2-1.1-0.5-1.5c-0.3-0.4-0.9-0.8-1.6-1.1c-0.5-0.2-1.7-0.6-3.6-1.1c-2.4-0.7-4.2-1.5-5.1-2.4c-1.4-1.3-2.1-3-2.1-4.9
		c0-1.2,0.3-2.4,1-3.5s1.6-1.9,2.8-2.5c1.2-0.6,2.7-0.9,4.4-0.9c2.8,0,5,0.7,6.4,2c1.4,1.3,2.2,3.1,2.3,5.4l-4.6,0.2
		c-0.2-1.3-0.6-2.2-1.3-2.7c-0.6-0.6-1.6-0.8-2.9-0.8c-1.3,0-2.4,0.3-3.1,0.9c-0.5,0.4-0.7,0.9-0.7,1.5c0,0.6,0.2,1.1,0.7,1.5
		c0.6,0.5,2,1.1,4.2,1.6c2.2,0.6,3.9,1.2,4.9,1.8c1.1,0.6,1.9,1.4,2.5,2.5c0.6,1.1,0.9,2.4,0.9,3.9c0,1.4-0.4,2.7-1.1,3.9
		c-0.7,1.2-1.7,2.1-3,2.7s-2.9,0.9-4.9,0.9c-2.9,0-5-0.7-6.6-2.1C208,90.1,207,88,206.8,85.2z"
        />
        <path
          fill={secondayColor}
          d="M243.9,93.2V72.8h-6.7v-4.1h17.9v4.1h-6.7v20.4H243.9z"
        />
        <path
          fill={secondayColor}
          d="M290.7,82c-1,0.5-1.7,1.2-2.2,2s-0.7,1.6-0.7,2.4c0,1,0.3,1.8,0.9,2.5c0.6,0.6,1.4,1,2.5,1
		c0.7,0,1.3-0.1,2-0.4c0.6-0.3,1.3-0.8,2-1.4L290.7,82z M294.2,75c0.9-1.2,0.4-2.4,0-2.8c-0.4-0.4-1-0.6-1.7-0.6
		c-0.7,0-1.2,0.2-1.6,0.6s-0.6,0.8-0.6,1.3c0,0.6,0.3,1.3,1,2.1l1.1,1.3l2.2,2.8l3.1,4.5c0.4-0.7,0.7-1.6,1-2.8l3.9,1
		c-0.4,1.5-0.7,2.6-1,3.3c-0.3,0.7-0.6,1.3-0.9,1.7c0.5,0.5,1.1,1,1.9,1.6c0.8,0.6,1.3,1,1.7,1.2l-2.6,3.6c-1.3-0.7-2.5-1.6-3.7-2.8
		c-0.9,0.9-1.9,1.5-2.9,2s-2.2,0.6-3.7,0.6c-2.8,0-4.9-0.9-6.3-2.6c-1.1-1.3-1.6-2.9-1.6-4.6c0-1.6,0.4-3,1.3-4.2
		c0.9-1.3,2.1-2.3,3.9-3.3c-0.8-1-1.3-1.9-1.7-2.8c-0.4-0.9-0.6-1.7-0.6-2.5c0-1.5,0.5-2.7,1.6-3.8c1.1-1,2.6-1.5,4.7-1.5
		c1.9,0,3.5,0.5,4.6,1.6c1.1,1.1,1.6,2.4,1.6,4c0,1-0.6,2.7-1.8,3.8L294.2,75z"
        />
        <path
          fill={secondayColor}
          d="M331.5,93.2V68.7h16.7v4.1H336v5.4h11.3v4.1H336v6.7h12.6v4.1H331.5z"
        />
        <path
          fill={secondayColor}
          d="M382.7,93.2h-5l-2-5.6h-9l-1.9,5.6h-4.8l8.8-24.5h4.8L382.7,93.2z M374.3,83.5l-3.1-9.1l-3,9.1H374.3z"
        />
        <path
          fill={secondayColor}
          d="M393.4,85.2l4.4-0.5c0.3,1.6,0.8,2.8,1.6,3.6c0.8,0.8,1.9,1.1,3.3,1.1c1.5,0,2.6-0.3,3.3-1
		c0.7-0.7,1.1-1.5,1.1-2.4c0-0.6-0.2-1.1-0.5-1.5c-0.3-0.4-0.9-0.8-1.6-1.1c-0.5-0.2-1.7-0.6-3.6-1.1c-2.4-0.7-4.2-1.5-5.1-2.4
		c-1.4-1.3-2.1-3-2.1-4.9c0-1.2,0.3-2.4,1-3.5c0.7-1.1,1.6-1.9,2.8-2.5c1.2-0.6,2.7-0.9,4.4-0.9c2.8,0,5,0.7,6.4,2s2.2,3.1,2.3,5.4
		l-4.6,0.2c-0.2-1.3-0.6-2.2-1.3-2.7s-1.6-0.8-2.9-0.8c-1.3,0-2.4,0.3-3.1,0.9c-0.5,0.4-0.7,0.9-0.7,1.5c0,0.6,0.2,1.1,0.7,1.5
		c0.6,0.5,2,1.1,4.2,1.6c2.2,0.6,3.9,1.2,4.9,1.8c1.1,0.6,1.9,1.4,2.5,2.5c0.6,1.1,0.9,2.4,0.9,3.9c0,1.4-0.4,2.7-1.1,3.9
		c-0.7,1.2-1.7,2.1-3,2.7c-1.3,0.6-2.9,0.9-4.9,0.9c-2.9,0-5-0.7-6.6-2.1C394.6,90.1,393.7,88,393.4,85.2z"
        />
        <path
          fill={secondayColor}
          d="M430.6,93.2V82.9l-8.3-14.2h5.3l5.3,9.7l5.2-9.7h5.2l-8.3,14.2v10.3H430.6z"
        />
        <path
          fill={secondayColor}
          d="M165.8,229.9v108.5h41.6v-51.8c0-7.7,6.3-14,14-14h8.6v-43.4h-5.4c-11.1,0-20,9-20,20v-19.3H165.8z"
        />
        <path
          fill={secondayColor}
          d="M466.3,229.9v108.5h41.6v-51.8c0-7.7,6.3-14,14-14h8.6v-43.4h-5.4c-11.1,0-20,9-20,20v-19.3H466.3z"
        />
        <path
          fill={secondayColor}
          d="M302.1,189.3v48.1c-4.6-5.1-12.5-10.1-25.6-10.1c-26.5,0-40.2,22.8-40.2,40.9c0,18.2,0,32.3,0,32.3
		c0,22.1,17.6,41.5,41.8,41.5c11.7,0,19.3-4.4,24-8.5v7.1h41.7V189.3H302.1z M301.9,295.3c0,3.3-1.3,6.3-3.5,8.4
		c-2.2,2.2-5.1,3.5-8.4,3.5c-6.6,0-11.9-5.3-11.9-11.9v-20.1c0-6.6,5.3-11.9,11.9-11.9c6.6,0,11.9,5.3,11.9,11.9V295.3z"
        />
        <path
          fill={secondayColor}
          d="M456.7,296.9V273c0-24.8-20.1-44.9-44.9-44.9h-12.5c-24.8,0-44.9,20.1-44.9,44.9v25.2
		c0,24.8,20.1,44.9,44.9,44.9h12.5c22.2,0,40.6-16.1,44.3-37.2h-38c-1.5,4.5-5.7,7.8-10.7,7.8c-6.2,0-11.3-5.1-11.3-11.3v-5.5H456.7
		z M396.7,268.4c0-5.5,4.5-10,10-10c5.5,0,10,4.5,10,10v6.6h-19.9V268.4z"
        />
        <path
          fill={secondayColor}
          d="M321.9,543.3v101h40.5v-48.2c0-7.2,6.1-13.1,13.6-13.1h8.3v-40.4h-5.2c-10.7,0-19.5,8.3-19.5,18.6v-18H321.9z"
        />
        <path
          fill={secondayColor}
          d="M310.5,605.8v-22.3c0-23.1-19.5-41.8-43.6-41.8h-12.2c-24.1,0-43.6,18.7-43.6,41.8V607
		c0,23.1,19.5,41.8,43.6,41.8h12.2c21.5,0,39.4-15,43-34.7h-36.9c-1.4,4.2-5.6,7.2-10.4,7.2c-6.1,0-11-4.7-11-10.5v-5.1H310.5z
		 M252.2,579.2c0-5.1,4.3-9.3,9.7-9.3c5.3,0,9.7,4.2,9.7,9.3v6.2h-19.4V579.2z"
        />
        <path
          fill={secondayColor}
          d="M491.7,605.8v-22.3c0-23.1-19.5-41.8-43.6-41.8h-12.2c-24.1,0-43.6,18.7-43.6,41.8V607
		c0,23.1,19.5,41.8,43.6,41.8h12.2c21.5,0,39.4-15,43-34.7h-36.9c-1.4,4.2-5.6,7.2-10.4,7.2c-6.1,0-11-4.7-11-10.5v-5.1H491.7z
		 M433.4,579.2c0-5.1,4.3-9.3,9.7-9.3c5.3,0,9.7,4.2,9.7,9.3v6.2h-19.4V579.2z"
        />
        <path
          fill={secondayColor}
          d="M107.3,225.8H95.8c-26.3,0-47.5,21.3-47.5,47.5v19.3c0,26.3,21.3,47.5,47.5,47.5h11.5
		c26.3,0,47.5-21.3,47.5-47.5v-19.3C154.9,247.1,133.6,225.8,107.3,225.8z M112.9,293.9c0,6.2-5,11.3-11.3,11.3
		c-6.2,0-11.3-5.1-11.3-11.3v-21.9c0-3.1,1.3-5.9,3.3-8c2-2,4.9-3.3,8-3.3c6.2,0,11.3,5.1,11.3,11.3V293.9z"
        />
        <path
          fill={secondayColor}
          d="M201.5,575v69.4h-42.4v-58.8c0-2.6-1.1-5-2.8-6.7c-1.7-1.7-4.1-2.8-6.7-2.8c-5.3,0-9.5,4.3-9.5,9.5v58.8H97.7
		V502.9h42.3l0.1,47.2c5.9-6.1,14.2-9.9,23.4-9.9l3.2,0C185.9,540.3,201.5,555.8,201.5,575z"
        />
        <path
          fill={secondayColor}
          d="M142.5,471.8c-0.2-0.2-5.7-5.7-13.3-13.5c2.6-4.9,4.1-10.8,4.1-17.8v-12.2h-7v12.2c0,4.9-0.8,9-2.3,12.5
		c-15.8-16-36.9-37.4-42.1-43c-8-8.5-8-15.5-8-19.3c0-3.7,1.8-12.2,18.6-12.2c15.8,0,17.5,14.3,17.5,20.5h7
		c0-13.3-6.4-27.5-24.5-27.5c-24.3,0-25.6,16-25.6,19.2c0,4.4,0,13.6,10,24.1c0.1,0.1,0.2,0.2,0.3,0.3c-8.5,4.2-18.9,11.2-18.9,29.2
		c0,22.2,19,32.3,36.7,32.3c11.4,0,22.9-4,30.3-12.5c7.2,7.3,12.2,12.4,12.4,12.5L142.5,471.8z M94.9,469.7
		c-14.8,0-29.7-7.8-29.7-25.3c0-15.2,8.8-20,17-23.9c9,9.3,25.5,26,38.1,38.7C114.2,466.8,104.4,469.7,94.9,469.7z"
        />
        <path
          fill={secondayColor}
          d="M237.6,373.3h-2.3c-10.3,0-19.6,3.9-26.6,10.4v-8.6h-40.8v139.8h43v-36.5c6.7,5.3,15.1,8.4,24.3,8.4h2.3
		c21.7,0,39.2-17.6,39.2-39.2v-35C276.8,390.9,259.3,373.3,237.6,373.3z M234.2,440.5c0,3.2-1.3,6-3.4,8.1c-2.1,2.1-4.9,3.4-8.1,3.4
		c-6.3,0-11.5-5.1-11.5-11.5v-20.8c0-6.3,5.1-11.5,11.5-11.5c6.3,0,11.5,5.1,11.5,11.5V440.5z"
        />
        <path
          fill={secondayColor}
          d="M341.2,373.9c-33.5,0-51.5,20.1-51.5,39.7l38.8,0.3c4.1-11.8,20.3-6.5,20.5,2.1c0,1.3,0,5.9,0,12.2
		c-5.7-5.1-15.8-6.8-30.4-6.8c-18.5,0-35.3,10.2-35.3,33c0,22.8,19.9,32.8,36.2,32.8c14.7,0,24.4-5.5,29.5-9.5c0,4.6,0,7.6,0,7.6
		h42.5c0,0,0-42.5,0-67.6S371.4,373.9,341.2,373.9z M349.1,454.5c-3.2,4.3-7,7.1-13.9,7.1c-8.3,0-11.3-5.1-11.3-10.8
		c0-5.6,6.5-8.5,12.7-8.5c4.7,0,10.1,2.1,12.4,3C349.1,448.4,349.1,451.5,349.1,454.5z"
        />
        <path
          fill={secondayColor}
          d="M392.6,377.1c0,0,31.1,102.4,32.5,106.6c1.4,4.2-2.6,7.2-5.8,7.2c-3.2,0-10.8-1.9-10.8-1.9v25.9
		c0,0,6.2,3.4,16.9,3.4s25.6,1.8,35.5-18.4s43.4-122.1,43.4-122.1h-43.2l-14.3,61.4l-11.5-62.3L392.6,377.1z"
        />
        <g>
          <path
            fill={secondayColor}
            d="M287.9,718.3c-38.9,0-70.6,31.7-70.6,70.6s31.7,70.6,70.6,70.6c38.9,0,70.6-31.7,70.6-70.6
			S326.9,718.3,287.9,718.3z M287.9,856.2c-37.1,0-67.3-30.2-67.3-67.3s30.2-67.3,67.3-67.3s67.3,30.2,67.3,67.3
			S325,856.2,287.9,856.2z"
          />
          <path
            fill={secondayColor}
            d="M289,764.6c0.9,0.3,1.8-0.1,2.1-1c0.6-1.6,0.9-3.3,0.9-5.1c0-8-6.5-14.5-14.5-14.5c-8,0-14.5,6.5-14.5,14.5
			c0,2,0.4,3.9,1.2,5.7c0.3,0.6,0.9,1,1.5,1c0.2,0,0.4,0,0.6-0.1c0.8-0.4,1.2-1.3,0.9-2.2c-0.6-1.4-0.9-2.9-0.9-4.4
			c0-6.2,5-11.2,11.2-11.2s11.2,5,11.2,11.2c0,1.3-0.2,2.7-0.7,3.9C287.7,763.4,288.2,764.3,289,764.6z"
          />
          <path
            fill={secondayColor}
            d="M263.7,773.8c0.4,0,0.8-0.2,1.2-0.5c0.6-0.6,0.7-1.7,0-2.3c-3.3-3.3-5.1-7.7-5.1-12.4
			c0-9.8,7.9-17.7,17.7-17.7s17.7,7.9,17.7,17.7c0,2.5-0.5,4.8-1.5,7c-0.4,0.8,0,1.8,0.8,2.2c0.8,0.4,1.8,0,2.2-0.8
			c1.2-2.6,1.7-5.4,1.7-8.4c0-11.6-9.4-21-21-21c-11.6,0-21,9.4-21,21c0,5.5,2.1,10.8,6,14.7C262.9,773.6,263.3,773.8,263.7,773.8z"
          />
          <path
            fill={secondayColor}
            d="M313.9,784.1c-1.1-0.7-2.1-1-3-1.1c-0.4-3.8-3.2-6.8-6.9-7.3c-2.2-0.3-4.3,0.3-6,1.7
			c-0.9-3.1-3.4-5.5-6.7-5.9c-2-0.3-4.1,0.3-5.8,1.5v-14.4c0-2.2-0.9-4.3-2.4-5.8c-1.6-1.5-3.6-2.3-5.9-2.3
			c-4.3,0.1-7.8,3.9-7.8,8.3v26.3c-0.8-0.3-1.6-0.4-2.5-0.4c-4.4,0-8,3.6-8,8v15.4c0,15.7,12.8,28.5,28.5,28.5h2.4
			c15.7,0,28.5-12.8,28.5-28.5v-15.9C318.3,788.9,316.7,785.9,313.9,784.1z M315,808.2c0,13.9-11.3,25.2-25.2,25.2h-2.4
			c-13.9,0-25.2-11.3-25.2-25.2v-15.4c0-2.6,2.1-4.7,4.7-4.7c0.9,0,1.7,0.3,2.5,0.7v17.2c0,0.9,0.7,1.7,1.6,1.7s1.6-0.7,1.6-1.7v-18
			v-29.1c0-2.7,2.1-5,4.6-5c1.3-0.1,2.5,0.4,3.5,1.3c0.9,0.9,1.4,2.1,1.4,3.4v20.9V791c0,0.9,0.7,1.7,1.6,1.7s1.6-0.7,1.6-1.7v-11.5
			c0-1.4,0.6-2.6,1.6-3.5c1-0.9,2.4-1.3,3.7-1.1c2.3,0.3,4.1,2.4,4.1,4.9v4v9.4c0,0.9,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7v-9.4
			c0-1.4,0.6-2.6,1.6-3.5c1-0.9,2.3-1.3,3.7-1.1c2.3,0.3,4.1,2.4,4.1,4.9v0.8v12.4c0,0.9,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7v-10.7
			c0.3,0.1,0.7,0.2,1.2,0.5c1.8,1.1,2.8,3.2,2.8,5.3V808.2z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
