import './bem-divider.scss';
import PropTypes from 'prop-types';
import { BadgeUI } from '../BadgeUI';
import { getUIClassesFromProps, getUIModifierKeys } from '../modifiersMapping';

export const DividerUI = ({ title, style, ...props }) => {
  return (
    <div className={getUIClassesFromProps('bem-divider', { ...props })}>
      {title && <h4 className="bem-divider__title">{title}</h4>}
    </div>
  );
};

DividerUI.defaultProps = {
  title: '',
  styleType: undefined,
  style: {},
};

BadgeUI.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  styleType: PropTypes.oneOf(getUIModifierKeys('styleType')),
  style: PropTypes.object,
};
