import { updateCurrentOrder } from 'pages/Dashboard/action';
import React from 'react';
import { useDrop } from 'react-dnd';
import { useSelector, useDispatch } from 'react-redux';

export const generateColorCode = (inputText) => {
  // Hash the input text

  if (inputText === 'Shared') {
    return '#32b900';
  }

  let hash = 0;
  for (let i = 0; i < inputText.length; i++) {
    hash = inputText.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate muted color components
  const red = ((hash & 0xff) % 150) + 50; // Range: 50-199
  const green = (((hash >> 8) & 0xff) % 150) + 50; // Range: 50-199
  const blue = (((hash >> 16) & 0xff) % 150) + 50; // Range: 50-199

  // Convert the components to hexadecimal values
  const color = `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;

  return color;
};

const CartSeatHeader = ({
  seat,
  selectedSeatId,
  handleSelectedSection,
  setNewItemsExist,
  provided,
}) => {
  const dispatch = useDispatch();
  const currentOrder = useSelector(
    (state) => state.dashboardReducer?.currentOrder
  );
  const onDropMenuItem = (menuItem) => {
    let updatedMenuItem = [];
    if (Array.isArray(menuItem)) {
      updatedMenuItem = currentOrder?.menuItems?.map((item) => {
        let updatedItem = { ...item };
        menuItem.forEach((mItem) => {
          if (item.orderIndex === mItem.orderIndex) {
            updatedItem = { ...updatedItem, activeSeatId: seat.seatId };
          }
        });
        return updatedItem;
      });
    } else {
      updatedMenuItem = currentOrder?.menuItems?.map((item) => {
        let updatedActiveSeatId = item?.activeSeatId || '';
        if (menuItem?.orderIndex === item?.orderIndex) {
          updatedActiveSeatId = seat.seatId;
        }
        return {
          ...item,
          activeSeatId: updatedActiveSeatId,
        };
      });
    }

    setNewItemsExist(true);
    dispatch(
      updateCurrentOrder({
        ...currentOrder,
        menuItems: updatedMenuItem,
      })
    );
  };

  return (
    <div
      className="seat-section-header"
      style={{
        backgroundColor: generateColorCode(seat.seatId),
      }}
      onClick={() => handleSelectedSection(seat.seatId)}
      // ref={drop}
    >
      <span className="seat-section-header-span">{seat.seatName}</span>
      {selectedSeatId === seat.seatId && <div className="seat-circle" />}
    </div>
  );
};

export default CartSeatHeader;
