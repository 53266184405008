import {
  ADD_BATCH_ORDER,
  LOADING_BATCH_ORDERS,
  SET_BATCH_ORDERS,
  SET_SELECTED_BATCH_ORDER,
  UPDATE_BATCH_ORDER,
} from './constants';
import axios from 'axios';
import { _auth, _firestore } from '../../firebase';
import { resetSelectedUnAssignedOrdersForDeliveryBatches } from 'pages/Drivers/actions';
import { API_URL } from 'configuration/apiUrl';

function setLoadingBatchOrders(payload) {
  return {
    type: LOADING_BATCH_ORDERS,
    payload,
  };
}

export function setBatchOrders(payload) {
  return {
    type: SET_BATCH_ORDERS,
    payload,
  };
}

export function addBatchOrder(payload) {
  return {
    type: ADD_BATCH_ORDER,
    payload,
  };
}

export function updateBatchOrder(payload) {
  return {
    type: UPDATE_BATCH_ORDER,
    payload,
  };
}

export function setSelectedBatchOrder(payload) {
  return {
    type: SET_SELECTED_BATCH_ORDER,
    payload, // string
  };
}

const API_ENDPOINT = `${API_URL}/orders/v1/deliveryBatches`;
export function fetchBatchedOrders(storeId) {
  return async (dispatch) => {
    const from_date = new Date();
    from_date.setHours(0, 0, 0, 0);
    const to_date = new Date();
    to_date.setHours(23, 59, 59, 999);
    const datetime_gte = from_date.toISOString();
    const datetime_lte = to_date.toISOString();

    const accessToken = await _auth.currentUser.getIdToken();

    try {
      dispatch(setLoadingBatchOrders(true));
      const { data } = await axios.get(
        `${API_ENDPOINT}?filter.storeID=${storeId}&filter.datetime_gte=${datetime_gte}&filter.datetime_lte=${datetime_lte}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      );
      const deliveryBatches = data.data.deliveryBatches;

      dispatch(setBatchOrders(deliveryBatches));
    } catch (error) {
      dispatch(setLoadingBatchOrders(false));
    }
  };
}

export function createDeliveryBatch(orders) {
  return async (dispatch, getState) => {
    const storeId = getState().dashboardReducer.storeConfig?.storeId;
    const activeStaffMember =
      getState().dashboardReducer.activeStaffMember ?? {};

    const totalBatchOrders = getState().batchOrdersReducer.batchOrders.length;
    const accessToken = await _auth.currentUser.getIdToken();

    const body = {
      storeId,
      createdByStaffId: activeStaffMember._id ?? '',
      createdByStaffName: activeStaffMember.firstName
        ? `${activeStaffMember.firstName} ${activeStaffMember.lastName}`
        : '',
      name: `Batch ${totalBatchOrders + 1}`,
      isDeleted: false,
      isAssigned: false,
      driverId: '',
      driverFirstName: '',
      driverLastName: '',
      orders,
    };

    try {
      dispatch(setLoadingBatchOrders(true));
      await axios.post(API_ENDPOINT, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      });
      dispatch(resetSelectedUnAssignedOrdersForDeliveryBatches(body));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingBatchOrders(false));
    }
  };
}

export function assignDriverToBatchOrder(driver, batchOrderId) {
  return async (dispatch, getState) => {
    const storeId = getState().dashboardReducer.storeConfig?.storeId;

    const body = {
      storeId,
      isDeleted: false,
      isAssigned: true,
      driverId: driver._id,
      driverFirstName: driver.firstName,
      driverLastName: driver.lastName,
    };

    try {
      dispatch(setLoadingBatchOrders(true));

      // use firestore to update the batchorder
      await _firestore
        .collection('OrderDeliveryBatches')
        .doc(batchOrderId)
        .update(body);

      dispatch(
        updateBatchOrder({
          id: batchOrderId,
          ...body,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingBatchOrders(false));
    }
  };
}

export function deleteBatchOrder(batchOrderId) {
  return async (dispatch, getState) => {
    const storeId = getState().dashboardReducer.storeConfig?.storeId;

    const body = {
      storeId,
      isDeleted: true,
    };

    try {
      dispatch(setLoadingBatchOrders(true));

      // use firestore to update the batchorder
      await _firestore
        .collection('OrderDeliveryBatches')
        .doc(batchOrderId)
        .update(body);

      dispatch(
        updateBatchOrder({
          id: batchOrderId,
          ...body,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingBatchOrders(false));
    }
  };
}
