export const getInstructionAddString = (menuItem) => {
  let str = '';

  if (
    menuItem.selectedExtraIngredients &&
    menuItem.selectedExtraIngredients.length > 0
  ) {
    const instruction = menuItem.selectedExtraIngredients
      .map(
        (m) =>
          `${m.name || m.ingredientName}${
            m.quantity > 1 ? ` x${m.quantity}` : ''
          }`
      )
      .join(', ');
    if (instruction) {
      str += instruction + '.';
    }
  }

  return str;
};

export const getInstructionRemoveString = (menuItem) => {
  let str = '';

  if (menuItem.removedIngredients && menuItem.removedIngredients.length > 0) {
    const instruction = menuItem.removedIngredients
      .map(
        (m) =>
          `${m.name || m.ingredientName}${
            m.quantity > 1 ? ` x ${m.quantity}` : ''
          }`
      )
      .join(', ');
    if (instruction) {
      str += instruction + '.';
    }
  }

  return str;
};

export const getInstructionString = (menuItem) => {
  let str = '';
  const add = getInstructionAddString(menuItem);
  const remove = getInstructionRemoveString(menuItem);

  str += add ? `Add ${add}` : '';
  str += add && remove ? ` ` : '';
  str += remove ? `Remove ${remove}` : '';

  return str;
};
