import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconTick = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-plus': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 636.8 487.3">
        <path className="st0" fill={iconColor ? iconColor : '#000'} d="M532.5,16.9L213.1,336.3L104.3,227.5c-22.5-22.5-59.1-22.5-81.6,0l-5.8,5.8c-22.5,22.5-22.5,59.1,0,81.6
	l155.4,155.4c22.5,22.5,59.1,22.5,81.6,0l366-366c22.5-22.5,22.5-59.1,0-81.6l-5.8-5.8C591.6-5.6,555-5.6,532.5,16.9z"/>
      </svg>
    </span>
  );
};

PosIconTick.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
