import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PosIcon } from './PosIcon';

export const PosIconDownChevron = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-down-chevron': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        width="13px"
        height="7px"
        viewBox="0 0 13 7"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Path</title>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={lineThickness ? lineThickness : 1}
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="View-Orders---History---Dine-In"
            fill={iconColor ? iconColor : '#000'}
            stroke={iconColor ? iconColor : '#000'}
            strokeWidth={lineThickness ? lineThickness * 0.8 : 0.8}
            transform="translate(-145.000000, -680.000000)"
            fillRule="nonzero"
          >
            <g id="Sidebar-RHS" transform="translate(-117.000000, 23.000000)">
              <g id="Collection" transform="translate(133.000000, 637.500000)">
                <g id="Arrow-" transform="translate(122.000000, 8.500000)">
                  <path
                    d="M13.4942085,17.4999098 C13.4178274,17.4988719 13.3449702,17.4690855 13.2915058,17.4170381 L7.5,11.8922593 L7.91119691,11.5 L13.5,16.8314115 L19.0888031,11.5 L19.5,11.8922593 L13.7084942,17.4170381 C13.6522272,17.4718482 13.5746011,17.5018689 13.4942085,17.4999098 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconDownChevron.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
