import React from 'react';
import { useHistory } from 'react-router';

import ShiftsBlockContainer from './Containers/ShiftsBlockContainer';
import ShiftsPageContainer from './Containers/ShiftsPageContainer';
import ShiftsButton from './ShiftsButton';

import { useStaffMemberName, useShiftsStateDispatch, useShiftsState } from './Context/ShiftsState';

import { getDayDateMonthTime } from '../../util/dateUtils';
import { clockInUser } from 'services/shiftsServices';
import { ShiftsEnum } from './constants';

const ClockInScreen = () => {
  const history = useHistory();
  const staffMemberName = useStaffMemberName();
  const staffMember = useShiftsState();
  const { roles: staffMemberRoles, _id: staffMemberId } = staffMember;
  const { startShift } = useShiftsStateDispatch();
  const [loadingRole, setLoadingRole] = React.useState(false);

  const handleClockInClick = async() => {
    // consider only active roles.
    const activeRoles = staffMemberRoles?.filter(role=>role.id && role.isActive) || [];
    // automatically clock-in the staff if he/she has a single role
    if (activeRoles.length && activeRoles.length === 1) {
      setLoadingRole(true);
      const { data } = await clockInUser(staffMemberRoles[0].id, staffMemberId);
      const { shift } = data;
      startShift(shift);
      setLoadingRole(false);
      history.push(
        `/shifts/confirmation?status=${
          ShiftsEnum.CLOCKED_IN
        }`
      );
    } else {
      history.push('/shifts/select-role');
    }
  };

  const handleViewShifts = () => {
    history.push('/shifts/view-shifts');
  };

  return (
    <ShiftsPageContainer>
      <ShiftsBlockContainer>
        <div className="clock-in-out__header-group">
          <h2 className="clock-in-out__title">{staffMemberName}</h2>
          <p className="clock-in-out__desc">{getDayDateMonthTime()}</p>
        </div>
        <div className="clock-in-out__btn-group clock-in-out__btn-group--space-between-big">
          <ShiftsButton
            title="Clock In"
            type="button"
            handleOnClick={handleClockInClick}
            isLoading={loadingRole}
            iconName="clock-five"
          />
          <ShiftsButton
            title="View Shifts"
            type="button"
            handleOnClick={handleViewShifts}
            iconName="calendar"
            buttonTheme="orange"
          />
        </div>
      </ShiftsBlockContainer>
    </ShiftsPageContainer>
  );
};

export default ClockInScreen;
