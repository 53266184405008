import React from 'react';

function IconRemove() {
  return (
    <svg width={34} height={34} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(.5 .5)" fill="#E02020" fillRule="evenodd">
        <circle opacity={0.15} cx={16.5} cy={16.5} r={16.5} />
        <path
          d="M20.973 26.147c.638 0 1.132-.163 1.484-.49.351-.326.542-.802.57-1.429l.555-12.615h1.154c.245 0 .45-.082.617-.247a.826.826 0 0 0 .25-.613.808.808 0 0 0-.25-.606.854.854 0 0 0-.617-.242H20.99v-1.3c0-.724-.22-1.287-.66-1.69-.441-.404-1.058-.606-1.85-.606h-3.336c-.793 0-1.41.202-1.85.605-.44.404-.66.967-.66 1.69v1.3H8.901a.851.851 0 0 0-.615.246.806.806 0 0 0-.253.603c0 .245.084.45.253.613a.849.849 0 0 0 .615.247h1.16l.554 12.615c.027.627.217 1.103.57 1.43.352.326.845.49 1.479.49h8.308ZM19.02 9.905h-4.408l.001-1.224a.57.57 0 0 1 .176-.439c.118-.108.28-.163.487-.163h3.082c.207 0 .369.055.487.163a.569.569 0 0 1 .176.44v1.223Zm-5.037 13.44a.651.651 0 0 1-.461-.17.585.585 0 0 1-.182-.45l-.27-9.195a.6.6 0 0 1 .17-.452.635.635 0 0 1 .47-.173.65.65 0 0 1 .463.17.61.61 0 0 1 .19.446l.262 9.196a.584.584 0 0 1-.166.453c-.116.116-.275.174-.476.174Zm2.841 0a.672.672 0 0 1-.478-.171.582.582 0 0 1-.182-.446v-9.204c0-.184.06-.333.182-.447a.669.669 0 0 1 .478-.172c.192 0 .349.058.47.172a.587.587 0 0 1 .182.447v9.204c0 .184-.06.333-.182.446a.66.66 0 0 1-.47.17Zm2.833 0a.645.645 0 0 1-.475-.175.582.582 0 0 1-.167-.453l.26-9.19a.617.617 0 0 1 .191-.452.65.65 0 0 1 .463-.17c.198 0 .355.058.47.173a.6.6 0 0 1 .17.452l-.267 9.195a.593.593 0 0 1-.184.45.651.651 0 0 1-.46.17Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default IconRemove;
