import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { PosIconCook } from '../../assets/icons/PosIconCook';
import { PosIconHistory } from '../../assets/icons/PosIconHistory';
import { PosIconUpSquare } from '../../assets/icons/PosIconUpSquare';
import {
  setFilterType, toggleOrderBarChangeAnimation, toggleViewOrderTimeModal
} from '../../pages/Orders/actions';
// import { storeConfigSelector } from '../../pages/Dashboard/selectors'
import usePickupDeliveryShiftStatus from '../../customHooks/usePickupDeliveryShiftStatus';
// import { OrdersListContext } from '../../pages/Orders/components/Providers/OrderList/OrderList'
import * as actions from 'pages/Orders/newOrdersReducer';

export const ViewOrdersNav = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  // const { setState } = useContext(OrdersListContext);
  // const storeConfig = useSelector(storeConfigSelector)
  const { isPickUpOpen, isDeliveryOpen } = usePickupDeliveryShiftStatus();

  const { pickUpTime, deliveryTime } = useSelector(
    (state) => state.ordersReducer.orderWaitingObj
  );
  // const { orderType } = useSelector(
  //   (state) => state.ordersReducer
  // );

  const params = useParams();

  const { orderTypeFilter } = params;

  const ViewOrderNavArr = useMemo(
    () => [
      {
        id: '0',
        title: 'Current',
        icon: <PosIconCook mainColor="#717374" />,
        to: `/orders/current/${orderTypeFilter}`,
        pageType: 'preparing',
      },
      {
        id: '1',
        title: 'Future',
        icon: <PosIconUpSquare mainColor="#717374" />,
        to: `/orders/future/${orderTypeFilter}`,
        pageType: 'upcoming',
      },
      {
        id: '2',
        title: 'History',
        icon: <PosIconHistory mainColor="#717374" />,
        to: `/orders/history/${orderTypeFilter}`,
        pageType: 'history',
      },
    ],
    [orderTypeFilter]
  );

  const handleOpenCollectionModal = () => {
    if (isPickUpOpen) {
      dispatch(
        toggleViewOrderTimeModal({
          name: 'pickUpTime',
          active: true,
        })
      );
    }
  };

  const handleClick = (event, item) => {
    event.preventDefault();
    dispatch(
      actions.setFilters({
        date: item.title.toLowerCase(),
        allAndUnpaid: 'all',
      })
    );
    dispatch(setFilterType(item.id));
    dispatch(toggleOrderBarChangeAnimation(true));

    history.push(item.to);
  };

  const handleOpenDeliveryModal = () => {
    if (isDeliveryOpen) {
      dispatch(
        toggleViewOrderTimeModal({
          name: 'deliveryTime',
          active: true,
        })
      );
    }
  };

  return (
    <div className="order-sidebar-bottom-nav">
      <div className="order-time-btn-box">
        <button
          type="button"
          title="Collection"
          className="hover-highlight"
          onClick={handleOpenCollectionModal}
        >
          <span className="text-wrap">
            <span className="sub-text">Collection</span>
            {isPickUpOpen ? (
              <span>{pickUpTime} mins</span>
            ) : (
              <span>Closed</span>
            )}
          </span>
          <span className="icon-box" />
        </button>
        <button
          type="button"
          title="Delivery"
          onClick={handleOpenDeliveryModal}
          className="hover-highlight"
        >
          <span className="text-wrap">
            <span className="sub-text">Delivery</span>
            {isDeliveryOpen ? (
              <span>{deliveryTime} mins</span>
            ) : (
              <span>Closed</span>
            )}
          </span>
          <span className="icon-box" />
        </button>
      </div>
      <div className="booking-nav-box">
        {ViewOrderNavArr.map((item) => {
          return (
            <NavLink
              key={item.id}
              activeClassName="active"
              to={item.to}
              value={item.title}
              className="hover-highlight"
              onClick={(event) => handleClick(event, item)}
            >
              {item.icon}
              <span className="name">{item.title}</span>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
