import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { PosIconCash } from '../../assets/icons/PosIconCash';
import { PosIconCreditCardReader } from '../../assets/icons/PosIconCreditCardReader';
import FoodImage from '../../assets/img/modal_food@2x.png';
import TerminalImage from '../../assets/img/modal_payment_terminal@2x.png';
import animationData from '../../assets/lotties/782-check-mark-success.json';
import { _auth } from '../../firebase';
import GeneralModal from '../../modals/GeneralModal/index-new';
import {
  authenticateStaff,
  resetCurrentOrder,
} from '../../pages/Dashboard/action';
import Button from '../Button';

import { useHistory } from 'react-router';
import {
  currencySymbolSelector,
  isMobileTerminalEnabledSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';

const PaymentModal = ({
  modalStatus,
  toggleModal,
  type,
  openPaymentViewModal = () => {},
  openCustomerPaymentDetailsModal,
  openSelectPaymentMethodModal,
  orderPayments,
  orderTimes,
  orderSetup,
}) => {
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const storeConfig = useSelector(storeConfigSelector);
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const isMobileTerminalEnabled = useSelector(isMobileTerminalEnabledSelector);
  const [paymentAnim, setPaymentAnim] = useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [payableAmount, setPayableAmount] = useState(0);
  const [isRefund, setRefundFlag] = useState(false);
  const disableButtonStyle = isPlacingOrder ? { opacity: 0.6 } : {};
  const dispatch = useDispatch();
  const nextOrderConfigURL = useSelector(
    (state) => state.dashboardReducer.nextOrderConfigURL
  );
  let history = useHistory();

  useEffect(() => {
    let alreadyPaidAmount = 0;
    if (currentOrder.payments && currentOrder.payments.length > 0) {
      currentOrder.payments.forEach((payment) => {
        alreadyPaidAmount = alreadyPaidAmount + Number(payment.amount);
      });
    }
    if (Number(currentOrder.payableAmount) < alreadyPaidAmount) {
      setRefundFlag(true);
      setPayableAmount(alreadyPaidAmount - Number(currentOrder.payableAmount));
    } else {
      setPayableAmount(Number(currentOrder.payableAmount) - alreadyPaidAmount);
    }
  }, [currentOrder, currentOrder.payments]);

  const handlePayNowClick = () => {
    if (
      (orderSetup && orderSetup.isPayByCardPOS === true) ||
      localStorage.getItem('stripeCardPayments')?.toLowerCase() === 'true'
    ) {
      openSelectPaymentMethodModal();
    } else {
      openPaymentViewModal();
    }
  };

  const handleOrderSubmit = (event, paymentType, paymentCollectionType) => {
    // Place order here now
    if (isPlacingOrder) {
      return;
    }
    if (currentOrder && orderTimes) {
      let currentOd = {
        ...currentOrder,
      };

      currentOd.paymentType = '' + paymentType;
      if (paymentType === '0') {
        currentOd.paymentCollectionType = paymentCollectionType;
      }
      if (currentOd.selectedVoucher) delete currentOd.selectedVoucher;
      // if (currentOd.selectedDiscount) delete currentOd.selectedDiscount;

      let requestObj = {
        order: {
          ...currentOd,
          // restaurantId: storeConfig.restaurantId,
          countryIdentifier: storeConfig.countryIdentifier,
          // doNotUpdateOrderStatus: false,
          // storeId: storeConfig?.storeId,
        },
        payment: {
          type: '' + paymentType,
          amount: parseInt((Number(payableAmount) * 100).toFixed(2), 10),
          isRefund,
        },
      };
      let el = event.target.classList;
      el.add('active');

      setIsPlacingOrder(true);
      if (requestObj?.order['idempotent-key']) {
        delete requestObj?.order['idempotent-key'];
      }
      const idempotentKey = currentOd['idempotent-key'];
      // Pay on Collection
      fetch(`${nextOrderConfigURL}/placeOrder3DSecure`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'idempotent-key': idempotentKey,
        },
        body: JSON.stringify({
          ...requestObj,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            handleTerminalAnimation(event);
          } else {
            el.remove('active');
            setIsPlacingOrder(false);
          }
        });
    }
  };

  const handleTerminalAnimation = (event) => {
    let el = event.target.classList;

    el.add('active');

    setPaymentAnim(true);

    let timer1 = setTimeout(function () {
      let timer2 = setTimeout(function () {
        setTimeout(() => {
          setPaymentAnim(false);
        }, 350);
        el.remove('active');
        toggleModal();

        setIsPlacingOrder(false);

        clearTimeout(timer2);

        if (currentOrder.isEditOrder || currentOrder.iteration) {
          history.push('/orders');
        } else {
          history.push('/');
          dispatch(authenticateStaff(false));
        }
        dispatch(resetCurrentOrder());
      }, 700);
      clearTimeout(timer1);
    }, 2000);
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="center"
      effect="move"
      overlay=""
      boxWidth="695px"
      // boxHeight="536px"
      className="payment-modal"
      closeBtn="inside"
    >
      <div className="payment-box">
        <div className="payment-box-inner">
          <div className="payment-box-header">
            <h2 className="payment-box-title">{`${
              payableAmount === 0 || payableAmount
                ? getAmountWithCountryCurrency({
                    amount: Number(payableAmount),
                    locale: `en-${storeCountryIdentifier}`,
                    currencyCode,
                  })
                : ''
            }`}</h2>
            <p className="payment-box-desc">
              {isRefund ? 'Select a Refund Option' : `Select a Payment Option`}
            </p>
          </div>
          <div className="payment-box-body">
            <div className="payment-box-methods">
              {paymentAnim ? (
                <div className="payment-box-method-wrap">
                  <div className="payment-box-card lottie">
                    <div className="inner-btn-box">
                      <Lottie
                        options={defaultOptions}
                        height={192}
                        width={192}
                        eventListeners={[
                          {
                            eventName: 'complete',
                            callback: () => {},
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="payment-box-method-wrap">
                    <Button
                      title={isRefund ? 'Refund Now' : `Pay Now`}
                      onClick={handlePayNowClick}
                      className="pay-now"
                      disabled={isPlacingOrder}
                      style={disableButtonStyle}
                    >
                      <span className="payment-method-name green">
                        {isRefund ? 'Refund Now' : `Pay Now`}
                      </span>
                      <span className="payment-method-img">
                        <img
                          src={TerminalImage}
                          alt={isRefund ? 'Refund Now' : `Pay Now`}
                        />
                      </span>
                    </Button>
                  </div>
                  {type === 'delivery' && (
                    <div className="payment-box-method-wrap">
                      <div className="payment-box-card pay-on-delivery">
                        <div className="inner-btn-box">
                          <span className="payment-method-name blue">
                            {isRefund
                              ? 'Refund on Delivery'
                              : `Pay on Delivery`}
                          </span>
                          <div className="delivery-pay-on-delivery-container">
                            {isMobileTerminalEnabled ? (
                              <Button
                                title="Mobile Terminal"
                                onClick={(event) =>
                                  handleOrderSubmit(event, '0', '4')
                                }
                                className="btn white inverse"
                                disabled={isPlacingOrder}
                                style={disableButtonStyle}
                              >
                                Mobile Terminal
                                <PosIconCreditCardReader
                                  mainColor="#0084ff"
                                  darkModeColor="#0084ff"
                                />
                              </Button>
                            ) : null}
                            <Button
                              title="Cash"
                              onClick={(event) =>
                                handleOrderSubmit(event, '0', '1')
                              }
                              className="btn white inverse"
                              disabled={isPlacingOrder}
                              style={disableButtonStyle}
                            >
                              Cash
                              <PosIconCash
                                mainColor="#5eb602"
                                darkModeColor="#5eb602"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(type === 'pickup' || type === 'walkin') && (
                    <div className="payment-box-method-wrap">
                      <Button
                        title={
                          isRefund
                            ? 'Refund on Collection'
                            : `Pay on Collection`
                        }
                        onClick={(event) => handleOrderSubmit(event, '0', '1')}
                        className="pay-on"
                        disabled={isPlacingOrder}
                        style={disableButtonStyle}
                      >
                        <span className="payment-method-name blue">
                          {isRefund
                            ? 'Refund on Collection'
                            : `Pay on Collection`}
                        </span>
                        <span className="payment-method-img">
                          <img
                            src={FoodImage}
                            alt={
                              isRefund
                                ? 'Refund on Collection'
                                : `Pay on Collection`
                            }
                          />
                        </span>
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </GeneralModal>
  );
};

PaymentModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default PaymentModal;
