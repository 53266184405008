import orderEngine from '../orderEngine';
import dayjs from 'dayjs';

const voidItemFromOrder = (
  currentOrder,
  item,
  allSpecials,
  orderSetup,
  productSetup,
  allSuburbs,
  publicHolidays,
  storeConfig
) => {
  const newCurrentOrder = {
    ...currentOrder,
    menuItems: currentOrder.menuItems.map((menuItem) => {
      if (
        typeof item.orderIndex === 'number'
          ? menuItem.orderIndex !== item.orderIndex
          : !item.items?.map((i) => i.orderIndex).includes(menuItem.orderIndex)
      ) {
        return menuItem;
      }
      if (menuItem.isVoided) {
        const { isVoided, ...restOfMenuItem } = menuItem;
        return {
          ...restOfMenuItem,
          iteration: currentOrder?.iteration || 0,
        };
      }
      return {
        ...menuItem,
        isVoided: true,
        iteration: currentOrder?.iteration || 0,
        voidedReason: item.voidedReason,
        customVoidedReason: item.customVoidedReason,
        voidedStaffId: item.voidedStaffId,
        voidedStaffName: item.voidedStaffName,
        voidedAt: dayjs().valueOf(),
      };
    }),
  };
  return orderEngine(
    newCurrentOrder,
    allSpecials,
    orderSetup,
    productSetup,
    allSuburbs,
    publicHolidays,
    storeConfig
  );
};

export default voidItemFromOrder;
