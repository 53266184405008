export const GlobalStateActionEnum = {
  // Selected order
  UPDATE_SELECTED_ORDER: 'UPDATE_SELECTED_ORDER',
  RESET_SELECTED_ORDER: 'RESET_SELECTED_ORDER',

  // Search Input
  UPDATE_SEARCH_INPUT: 'UPDATE_SEARCH_INPUT',
  RESET_SEARCH_INPUT: 'RESET_SEARCH_INPUT',

  // Order type filter
  UPDATE_ORDER_TYPE_FILTER: 'UPDATE_ORDER_TYPE_FILTER',
  RESET_ORDER_TYPE_FILTER: 'RESET_ORDER_TYPE_FILTER',

  // All and unpaid filter,
  UPDATE_ALL_AND_UNPAID_FILTER: 'UPDATE_ALL_AND_UNPAID_FILTER',
  UPDATE_ORDER_FROM_DRIVERS_PAGE: 'UPDATE_ORDER_FROM_DRIVERS_PAGE',
};
