import groupBy from 'lodash/groupBy';
import { detectMergeRequired } from '../orderEngine/utilities';
import {
  detectAndMergeHalfAndHalf,
  detectAndMergeQuarters,
  performMerge,
  removeUnwantedProperties,
} from './utilities';
import addItemToOrder from './addItemToOrder';
import orderEngine from '../orderEngine/index';
import { prepareItemForOrder } from '../itemProcessor';

function addSpecialToOrder(
  currentOrder,
  specialItem,
  selectedSpecials,
  specials,
  orderSetup,
  productSetup,
  suburbs,
  publicHolidays,
  storeConfig
) {
  if (specialItem && specialItem.selectedCategories && selectedSpecials) {
    let itemsToBeAdded = doASelfMerge(selectedSpecials);
    return addItemsToOrder(
      currentOrder,
      itemsToBeAdded,
      specials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );
  }
}

function doASelfMerge(selectedSpecials) {
  const mergedItems = [];
  selectedSpecials.forEach((item) => {
    let otherItemsPresent = selectedSpecials.filter((m) => {
      return m._id === item._id && m.boxIndex !== item.boxIndex;
    });

    if (otherItemsPresent.length) {
      let mergedItemIntermidient = item;
      otherItemsPresent.forEach((ot, index) => {
        if (detectMergeRequired(ot, mergedItemIntermidient)) {
          !index
            ? (mergedItemIntermidient = performMerge(ot, item))
            : (mergedItemIntermidient = performMerge(
                ot,
                mergedItemIntermidient
              ));
        }
      });
    } else {
      mergedItems.push(item);
    }
  });

  return mergedItems;
}

function addItemsToOrder(
  currentOrder,
  items,
  specials,
  orderSetup,
  productSetup,
  suburbs,
  publicHolidays,
  storeConfig
) {
  let newOrder = { ...currentOrder };
  const finalItems = [];

  items.forEach((item) => {
    finalItems.push(
      prepareItemForOrder(
        removeUnwantedProperties(item, [
          'isCurrentMenuItemInEditMode',
          'categoryIndex',
        ]),
        currentOrder
      )
    );
  });

  const halfAndHalfItems = finalItems.filter((item) => item.isHalf);
  const groupedHalfItems = groupBy(halfAndHalfItems, (item) => item?.halfIndex);
  const halfItemsIndexes = Object.keys(groupedHalfItems);

  const quarterItems = finalItems.filter((item) => item.isQuarter);
  const groupedQuarterItems = groupBy(
    quarterItems,
    (item) => item?.fourQuarterIndex
  );
  const quarterItemsIndexes = Object.keys(groupedQuarterItems);

  const normalItems = finalItems.filter(
    (item) => !item.isHalf && !item?.isQuarter
  );

  halfItemsIndexes.forEach((halfItemIndex) => {
    newOrder = {
      ...newOrder,
      menuItems: detectAndMergeHalfAndHalf(
        groupedHalfItems[halfItemIndex],
        newOrder.menuItems
      ),
    };
  });

  if (halfItemsIndexes.length > 0) {
    newOrder = orderEngine(
      newOrder,
      specials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );
  }

  quarterItemsIndexes.forEach((quarterItemIndex) => {
    newOrder = {
      ...newOrder,
      menuItems: detectAndMergeQuarters(
        groupedQuarterItems[quarterItemIndex],
        newOrder.menuItems
      ),
    };
  });

  if (quarterItemsIndexes.length > 0) {
    newOrder = orderEngine(
      newOrder,
      specials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );
  }

  normalItems.forEach((item) => {
    newOrder = {
      ...addItemToOrder(
        newOrder,
        item,
        specials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      ),
    };
  });

  return newOrder;
}

export default addSpecialToOrder;
