import { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { PosIconClock } from '../../assets/icons/PosIconClock';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import {
  resetCurrentSpecialsItem,
  resetMenuItem,
  updateCurrentOrder,
  setShowPizzaActionButton,
} from '../../pages/Dashboard/action';
import Button from '../Button';
import SpecialsCategoryItem from './SpecialsCategoryItem';

import addSpecialToOrder from 'cartProcessor/addSpecialToOrder';
import { useMemo } from 'react';
import generateHalfHalfMenuItems from 'util/generateHalfHalfMenuItems';
import {
  activeSpecialsSelector,
  // currencySymbolSelector,
  storeConfigSelector,
} from '../../pages/Dashboard/selectors';
import { ScrollableBoxUI } from '../../UI/components';
// import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { setActiveCategoryIndex } from '../../pages/Dashboard/action';

const SpecialsView = ({ parentPage }) => {
  let history = useHistory();
  const storeConfig = useSelector(storeConfigSelector);
  // const currencyCode = useSelector(currencySymbolSelector);
  // const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  const dispatch = useDispatch();
  const activeSpecials = useSelector(activeSpecialsSelector);
  const orderSetup = useSelector((state) => state.dashboardReducer.orderSetup);
  const productSetup = useSelector(
    (state) => state.dashboardReducer.productSetup
  );
  const currentSpecialsItem =
    useSelector((state) => state.dashboardReducer.currentSpecialsItem) || {};
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const suburbs = useSelector((state) => state.dashboardReducer.suburbs);
  const publicHolidays = useSelector(
    (state) => state.dashboardReducer.publicHolidays
  );

  const handleAddSpecialsToOrder = () => {
    let currentMenuItems =
      currentSpecialsItem.selectedCategories.reduce((finalArr, cat) => {
        cat.currentMenuItems.forEach((item) => finalArr.push({ ...item }));
        return finalArr;
      }, []) || [];

    if (productSetup.chargeExpensiveHalf) {
      currentMenuItems = generateHalfHalfMenuItems(currentMenuItems);
    }
    const order = addSpecialToOrder(
      currentOrder,
      currentSpecialsItem,
      currentMenuItems,
      activeSpecials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );

    dispatch(updateCurrentOrder(order));
    dispatch(resetCurrentSpecialsItem());
    dispatch(resetMenuItem());
    history.push(`${parentPage}/order`);
  };

  const handleClickBtn = () => {
    dispatch(resetCurrentSpecialsItem());
    dispatch(resetMenuItem());
  };

  useEffect(() => {
    dispatch(setShowPizzaActionButton(false));
  }, []);

  useEffect(() => {
    dispatch(setActiveCategoryIndex(''));
  }, []);

  const areAllCatergoriesFilled = useMemo(
    () =>
      currentSpecialsItem?.selectedCategories?.every(
        (cat) => cat.currentMenuItems?.length
      ),
    [currentSpecialsItem?.selectedCategories]
  );

  // const totalUpsalePrice = useMemo(() => {
  //   let price = 0;
  //   if (areAllCatergoriesFilled) {
  //     currentSpecialsItem?.selectedCategories?.forEach((cat) => {
  //       price +=
  //         cat.currentMenuItems?.reduce((acc, mi) => {
  //           acc += mi.upsalePrice;
  //           return acc;
  //         }, 0) || 0;
  //     });
  //   }
  //   return price;
  // }, [areAllCatergoriesFilled, currentSpecialsItem?.selectedCategories]);

  if (!currentSpecialsItem) return null;

  // const title = `Add Special ${getAmountWithCountryCurrency({
  //   amount: Number(currentSpecialsItem?.cost) + totalUpsalePrice,
  //   locale: `en-${storeCountryIdentifier}`,
  //   currencyCode,
  // })}`;
  const title = 'Add Special';
  const bodyWrapCls = clsx(
    'main-special-body-wrap',
    currentSpecialsItem?.selectedCategories?.every(
      (cat) => cat.currentMenuItems?.length
    ) && 'all-selected'
  );

  return (
    <div className="main-area" id="main-area">
      <div className="main-area-inner">
        <div className="main-special-area-header">
          <Link
            to={`${parentPage}/order`}
            title="Close"
            className="special-area-btn-close"
            onClick={handleClickBtn}
          >
            <PosIconClose
              mainColor="#494b4c"
              darkModeColor="#fff"
              lineThickness={1.25}
            />
          </Link>
          {currentSpecialsItem.specialsDate && (
            <div className="main-special-date">
              <p className="inner-box">
                <PosIconClock mainColor="#494b4c" darkModeColor="#fff" />
                {currentSpecialsItem.specialsDate}
              </p>
            </div>
          )}
          <h1 className="category-title">{currentSpecialsItem.name}</h1>
        </div>
        <div className={bodyWrapCls}>
          <ScrollableBoxUI>
            <div className="main-special-area-body">
              <div className="specials-cards">
                {currentSpecialsItem.selectedCategories.map((item, idx) => (
                  <SpecialsCategoryItem
                    item={item}
                    key={item.displayName + idx}
                    parentPage={parentPage}
                  />
                ))}
              </div>
            </div>
          </ScrollableBoxUI>
          {areAllCatergoriesFilled && (
            <div className="main-special-area-btn-box">
              <Button
                title={title}
                className="btn"
                onClick={handleAddSpecialsToOrder}
              >
                {title}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecialsView;
