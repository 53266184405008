export const OrderTypeIdEnum = {
  WALK_IN: '0',
  PICK_UP: '1',
  DELIVERY: '2',
  DINE_IN: '3',
  DRIVE_THRU: '4',
};

export const orderTypesMap = {
  walkin: OrderTypeIdEnum.WALK_IN,
  pickup: OrderTypeIdEnum.PICK_UP,
  delivery: OrderTypeIdEnum.DELIVERY,
  dinein: OrderTypeIdEnum.DINE_IN,
  kiosk: OrderTypeIdEnum.WALK_IN,
  drivethru: OrderTypeIdEnum.WALK_IN,
};

export const orderScreenOrderTypesMap = {
  walkin: OrderTypeIdEnum.WALK_IN,
  pickup: OrderTypeIdEnum.PICK_UP,
  delivery: OrderTypeIdEnum.DELIVERY,
  'dine-in': OrderTypeIdEnum.DINE_IN,
  kiosk: OrderTypeIdEnum.WALK_IN,
  'drive-thru': OrderTypeIdEnum.DRIVE_THRU,
};

export const MENU_ORDER_FROM = {
  web: '1',
  pos: '2',
  deliverect: '3',
  hubrise: '4',
  doordash: '5',
  kiosk: '7',
  google: '6',
  bot: '10',
};

export const OrderTypeBasepathEnum = {
  WALK_IN: 'walkin',
  PICK_UP: 'pickup',
  DELIVERY: 'delivery',
  DINE_IN: 'dine-in',
  KIOSK: 'kiosk',
  DRIVE_THRU: 'drivethru',
};

export const OrderTypeTitleEnum = {
  WALK_IN: 'Walk-In',
  PICK_UP: 'Pickup',
  DELIVERY: 'Delivery',
  DINE_IN: 'Dine-In',
  KIOSK: 'Kiosk',
  DRIVE_THRU: 'Drive-Thru',
};

export const ApiStatusEnum = {
  IDLE: 'idle',
  FETCHING: 'fetching',
  RESOLVED: 'resolved',
  FAILED: 'failed',
};

export const paymentTypeEnum = {
  UNPAID: '0',
  CASH: '1',
  CARD_ONLINE: '2',
  CARD_IN_STORE: '3',
  MOBILE_TERMINAL: '4',
  CUSTOM: '11',
};

export const notificationStreamStatusEnum = {
  IDLE: 'idle',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  CANCELLED: 'cancelled',
};

export const MENU_ORDER_STATUS = {
  unconfirmed: '0',
  placed: '1',
  in_kitchen: '2',
  on_the_way: '3',
  delivered: '4',
  cancelled: '11',
  arriving: '12',
  arrived: '13',
  out_for_delivery: '25',
};
