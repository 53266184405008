import React, { createContext, useReducer } from 'react';

const RefundContext = createContext([]);

export const RefundProvider = ({ children }) => {
  const [refundState, setRefundState] = useReducer((s, a) => ({ ...s, ...a }), {});

  return (
    <RefundContext.Provider value={[refundState, setRefundState]}>
      {children}
    </RefundContext.Provider>
  )
};

export default RefundContext;
