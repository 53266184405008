export const discountListSort = (list, order) => {
  return list.sort((a, b) => {
    return Number(a.discount) < Number(b.discount)
      ? order === 'ASC'
        ? 1
        : -1
      : order === 'ASC'
      ? -1
      : 1;
  });
};
