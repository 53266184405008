import React from 'react';
import GeneralModal from '../../../../modals/GeneralModal/index-new';
import { CloseButtonCircleUI, CounterBoxUI } from '../../../../UI/components';
import './GuestCounter.css';

const GuestCounter = (
  { show, toggleModal, numOfGuest = 0, guestDropdownRef, onClick },
  ref
) => {
  const dropdownPostion = guestDropdownRef.current.getBoundingClientRect();

  return (
    <>
      <GeneralModal
        modalStatus={show}
        toggleModal={toggleModal}
        effect="fade"
        overlay={'default'}
        boxWidth="205px"
        boxHeight="110px"
        closeBtn="outside"
        isResponsiveHeight
        paperContianerStyle={{
          position: 'absolute',
          top: `${dropdownPostion?.top + 10}px`,
          left: `${dropdownPostion.left - 90}px`,
          borderRadius: '17px',
        }}
      >
        <div className="guestCounterContainer">
          <div className="guestCounterHeader">
            <div className="guestCounterHeaderText">
              <span>Guests</span>
            </div>

            <div className="guestCounterModalCloseIcon">
              <CloseButtonCircleUI onClick={toggleModal} />
            </div>
          </div>

          <div className="counterContainer">
            <CounterBoxUI
              isSecondary={true}
              value={numOfGuest}
              onChange={onClick}
            />
          </div>
        </div>
      </GeneralModal>
    </>
  );
};

export default GuestCounter;
