import moment from 'moment-timezone';
import { getCurrentDayFlag, getDayFlag } from './timeHelper';
import { nextOrderConfig } from '../configuration/nextOrderConfig';

const voucherDeclinedReasons = {
  Expired: 'Voucher has expired.', //If a voucher has expired and past the valid date or reached the max. amount of uses:
  NewCustomer: 'Voucher is only available for new customers.', //If a voucher is only for new customers
  InvalidType: 'Voucher is not valid for this order type.', //If voucher in only for pickup
  InvalidOrderFrom: 'Voucher is not valid on this plateform',
  certainDays: 'Voucher can only be used from',
  AlreadyUsed: 'Voucher can be used only once per user',
  NotValidUser: 'Voucher is not available for logged in user',
  Future: 'Voucher is not yet available for use',
};

const discountDeclinedReasons = {
  Expired: 'Discount has expired.', //If a Discount has expired and past the valid date or reached the max. amount of uses:
  NewCustomer: 'Discount is only available for new customers.', //If a Discount is only for new customers
  InvalidType: 'Discount is not valid for this order type.', //If voucher in only for pickup
  InvalidOrderFrom: 'Voucher is not valid on this plateform',
  certainDays: 'Discount can only be used from',
  AlreadyUsed: 'Discount can be used only once per user',
  NotValidUser: 'Discount is not available for logged in user',
  Future: 'Discount is not yet available for use',
};

export const getVouchers = (requestObj) => {
  return new Promise((resolve, reject) => {
    fetch(`${nextOrderConfig.cloudFunctionUrl}/getVouchers`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...requestObj,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getDiscounts = (requestObj) => {
  return new Promise((resolve, reject) => {
    fetch(`${nextOrderConfig.cloudFunctionUrl}/getDiscounts`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...requestObj,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const validateVoucher = (voucher, order, payableAmount, storeConfig) => {
  let response = {
    success: true,
    voucherErrorMessage: '',
  };
  if (
    (!voucher.isUnlimited &&
      Number(voucher.voucherUsageCount ? voucher.voucherUsageCount : 0) >=
        Number(voucher.maxOrders ? voucher.maxOrders : 0)) ||
    voucher.isActive === false
  ) {
    // Voucher expiry check
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.Expired;
  } else if (
    voucher.type !== '3' &&
    !voucher.isAvailableForever &&
    isOrderDateIsAfterDateRange(voucher, order.deliveryDate, storeConfig)
  ) {
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.Expired;
  } else if (
    voucher.type !== '3' &&
    isOrderDateIsBeforeDateRange(voucher, order.deliveryDate, storeConfig)
  ) {
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.Future;
  } else if (!voucher.orderTypeKeys.includes(order.orderType)) {
    // Order type check, voucher could be available for one of the order type
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.InvalidType;
  } else if (!voucher.orderFromKeys.includes(order.orderFrom)) {
    // Order type check, voucher could be available for one of the order type
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.InvalidOrderFrom;
  } else if (isUnAvailableForWeekDay(voucher, order.deliveryDate, storeConfig)) {
    // Check voucher availability for the day
    response.success = false;
    response.voucherErrorMessage = `${voucherDeclinedReasons.certainDays} ${getAvailabilityString(
      voucher
    )}`;
  } else if (Number(voucher.minOrderValue) > Number(payableAmount)) {
    // Voucher have set minimum order value
    response.success = false;
    response.voucherErrorMessage = `Voucher only valid for a miniumum spend of ${storeConfig.currency}${voucher.minOrderValue} or above.`;
  } else if (
    (voucher.type === '1' || voucher.type === '3') &&
    !isAvailableFullDayOrTimeSlot(voucher, order.deliveryDate, storeConfig)
  ) {
    response.success = false;
    response.voucherErrorMessage = `Voucher only valid between ${voucher.startTimeDisplay} - ${voucher.endTimeDisplay} `;
  }
  return response;
};

export const validateDiscount = (discount, order, payableAmount, storeConfig) => {
  let response = {
    success: true,
    discountErrorMessage: '',
  };
  if (
    (!discount.isUnlimited &&
      Number(discount.discountUsageCount ? discount.discountUsageCount : 0) >=
        Number(discount.maxOrders ? discount.maxOrders : 0)) ||
    discount.isActive === false
  ) {
    // iscount expiry check
    response.success = false;
    response.discountErrorMessage = discountDeclinedReasons.Expired;
  } else if (
    discount.type !== '3' &&
    !discount.isAvailableForever &&
    isOrderDateIsAfterDateRange(discount, order.deliveryDate, storeConfig)
  ) {
    response.success = false;
    response.discountErrorMessage = discountDeclinedReasons.Expired;
  } else if (
    discount.type !== '3' &&
    isOrderDateIsBeforeDateRange(discount, order.deliveryDate, storeConfig)
  ) {
    response.success = false;
    response.discountErrorMessage = discountDeclinedReasons.Future;
  } else if (!discount.orderTypeKeys.includes(order.orderType)) {
    // Order type check, discount could be available for one of the order type
    response.success = false;
    response.discountErrorMessage = discountDeclinedReasons.InvalidType;
  } else if (!discount.orderFromKeys.includes(order.orderFrom)) {
    // Order type check, discount could be available for one of the order type
    response.success = false;
    response.discountErrorMessage = discountDeclinedReasons.InvalidOrderFrom;
  } else if (isUnAvailableForWeekDay(discount, order.deliveryDate, storeConfig)) {
    // Check discount availability for the day
    response.success = false;
    response.discountErrorMessage = `${discountDeclinedReasons.certainDays} ${getAvailabilityString(
      discount
    )}`;
  } else if (Number(discount.minOrderValue) > Number(payableAmount)) {
    // Discount have set minimum order value
    response.success = false;
    response.discountErrorMessage = `Discount only valid for a miniumum spend of ${storeConfig.currency}${discount.minOrderValue} or above.`;
  } else if (
    (discount.type === '1' || discount.type === '3') &&
    !isAvailableFullDayOrTimeSlot(discount, order.deliveryDate, storeConfig)
  ) {
    response.success = false;
    response.discountErrorMessage = `Discount only valid between ${discount.startTimeDisplay} - ${discount.endTimeDisplay} `;
  }
  return response;
};

function getAvailabilityString(item) {
  let returnString = '';
  let dayStringArray = [];
  if (item.Monday) dayStringArray.push('Mon');
  if (item.Tuesday) dayStringArray.push('Tue');
  if (item.Wednesday) dayStringArray.push('Wed');
  if (item.Thursday) dayStringArray.push('Thu');
  if (item.Friday) dayStringArray.push('Fri');
  if (item.Saturday) dayStringArray.push('Sat');
  if (item.Sunday) dayStringArray.push('Sun');

  if (dayStringArray.length !== 7) {
    returnString =
      returnString.length !== 0
        ? returnString + dayStringArray[0] + ' to ' + dayStringArray[dayStringArray.length - 1]
        : returnString + dayStringArray[0] + ' to ' + dayStringArray[dayStringArray.length - 1];
  }
  return returnString;
}

function isAvailableFullDayOrTimeSlot(obj, delDate, storeConfig) {
  if (obj.isAvailableFullDay) return true;
  else {
    let todayDate = moment(
      moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
      'YYYY-MM-DD HH:mm'
    );
    if (delDate) {
      todayDate = moment(delDate, 'YYYY-MM-DD HH:mm');
    }
    let startObj = moment(
      `${todayDate.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(obj.startTime)}`,
      'YYYY-MM-DD HH:mm'
    );
    let endObj = moment(
      `${todayDate.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(obj.endTime)}`,
      'YYYY-MM-DD HH:mm'
    );
    if (startObj.isSameOrBefore(todayDate) && endObj.isSameOrAfter(todayDate)) {
      return true;
    } else {
      return false;
    }
  }
}

function isOrderDateIsAfterDateRange(obj, delDate, storeConfig) {
  let todayDate = moment(
    moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
    'YYYY-MM-DD HH:mm'
  );
  if (delDate) {
    todayDate = moment(delDate, 'YYYY-MM-DD HH:mm');
  }
  if (
    moment(obj.startDate).tz(storeConfig.timeZone).isBefore(todayDate, 'day') &&
    moment(obj.endDate).tz(storeConfig.timeZone).isBefore(todayDate, 'day')
  ) {
    return true;
  } else return false;
}

function isOrderDateIsBeforeDateRange(obj, delDate, storeConfig) {
  let todayDate = moment(
    moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
    'YYYY-MM-DD HH:mm'
  );
  if (delDate) {
    todayDate = moment(delDate, 'YYYY-MM-DD HH:mm');
  }
  if (
    moment(obj.startDate).tz(storeConfig.timeZone).isAfter(todayDate, 'day') &&
    moment(obj.endDate).tz(storeConfig.timeZone).isAfter(todayDate, 'day')
  ) {
    return true;
  } else return false;
}

function isUnAvailableForWeekDay(obj, delDate, storeConfig) {
  if (delDate) {
    if (
      obj.hasOwnProperty(getDayFlag(delDate, 'YYYY-MM-DD HH:mm').dayText) &&
      obj[getDayFlag(delDate, 'YYYY-MM-DD HH:mm').dayText] === false
    )
      return true;
    else return false;
  } else {
    if (
      obj.hasOwnProperty(getCurrentDayFlag(storeConfig.timeZone)?.dayText) &&
      obj[getCurrentDayFlag(storeConfig.timeZone).dayText] === false
    )
      return true;
    else return false;
  }
}

function convertSecondsTo12Hour(timeStamp) {
  let hours = timeStamp / 3600;
  let minutes = (timeStamp / 60) % 60;
  let ampm = '';
  if (hours === 24) {
    hours = 23;
    minutes = 59;
  }
  hours >= 12 ? (ampm = 'PM') : (ampm = 'AM');
  return moment(`${hours}:${minutes} ${ampm}`, 'HH:mm A').format('HH:mm');
}
