import PropTypes from 'prop-types';
import { createRef, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getUIClassesFromProps } from '../modifiersMapping';
import './bem-switch-buttons-group.scss';

export const SwitchButtonsGroupUI = ({
  switchList,
  activeSwitchIndex,
  setActiveSwitchIndex,
  modifierClassName,
  buttonModifierClassName,
}) => {
  const wrapRef = useRef();
  const scrollRef = useRef();
  const SwitchRefs = useRef([]);

  const [activeBoxData, setActiveBoxData] = useState({
    width: 0,
    left: 0,
  });

  useEffect(() => {
    if (switchList !== undefined && switchList.length > 0) {
      SwitchRefs.current = switchList.map(
        (ref, index) => (SwitchRefs.current[index] = createRef())
      );
    }
  }, [switchList]);

  const nextElHolder = SwitchRefs.current[activeSwitchIndex];
  let nextEl = SwitchRefs.current[activeSwitchIndex]?.current || null;

  useEffect(() => {
    if (nextElHolder && !nextEl) {
      setActiveBoxData({
        width: 0,
        left: 0,
      });
    }
  }, [nextElHolder, nextEl]);

  useEffect(() => {
    if (nextEl !== null && scrollRef.current && wrapRef.current) {
      const containerWidth = wrapRef.current.offsetWidth;
      const scrollWidth = scrollRef.current.scrollWidth;
      const elWidth = nextEl.offsetWidth;
      const elLeft = nextEl.offsetLeft;
      const scrollPosition = elLeft + elWidth / 2 - containerWidth / 2;

      if (scrollWidth > containerWidth) {
        scrollRef.current.scrollTo({
          top: 0,
          left: scrollPosition,
          behavior: 'smooth',
        });
      }

      setActiveBoxData((prevState) => ({
        ...prevState,
        width: elWidth,
        left: elLeft,
      }));
    }
  }, [nextEl]);

  if (!switchList && !switchList.length) return null;

  return (
    <div
      className={getUIClassesFromProps('bem-switch-group', {
        modifierClassName,
      })}
      ref={wrapRef}
    >
      <div className="bem-switch-group__inner" ref={scrollRef}>
        {switchList.map((item, i) => {
          if (item.to) {
            return (
              <NavLink
                key={i}
                ref={SwitchRefs.current[i]}
                title={item.title}
                className="bem-switch-group__button"
                onClick={() => setActiveSwitchIndex(i)}
                to={item.to}
              >
                {item.title}
              </NavLink>
            );
          } else {
            return (
              <button
                key={i}
                ref={SwitchRefs.current[i]}
                title={item.title}
                className={getUIClassesFromProps('bem-switch-group__button', {
                  modifierClassName: buttonModifierClassName,
                })}
                onClick={() => setActiveSwitchIndex(i)}
                type="button"
              >
                {item.title}
              </button>
            );
          }
        })}
        <div
          className="bem-switch-group__active-overlay"
          style={{
            width: activeBoxData.width,
            transform: `translateX(${activeBoxData.left}px)`,
          }}
        />
      </div>
    </div>
  );
};

SwitchButtonsGroupUI.defaultProps = {
  switchList: [],
  activeSwitchIndex: undefined,
  setActiveSwitchIndex: () => {},
  modifierClassName: '',
  buttonModifierClassName: '',
};

SwitchButtonsGroupUI.propTypes = {
  switchList: PropTypes.array,
  activeSwitchIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setActiveSwitchIndex: PropTypes.func,
  modifierClassName: PropTypes.string,
};
