import React from 'react';

import UnAssignedSingleItem from './UnassignedSingleItem';
import { useDispatch, useSelector } from 'react-redux';

export const UnassignedBatchedOrdersList = ({ unAssignedOrders }) => {
  const dispatch = useDispatch();
  const { selectedUnAssignedOrdersForDeliveryBatches } = useSelector(
    (state) => state.driversReducer
  );
  const handleChange = (order) => {
    dispatch({
      type: 'toggleSelectedUnAssignedOrdersForDeliveryBatches',
      payload: order,
    });
  };

  return (
    <ul className="details-list">
      {unAssignedOrders.map((order) => {
        return (
          <li key={order._id}>
            <UnAssignedSingleItem
              order={order}
              handleChange={handleChange}
              selected={selectedUnAssignedOrdersForDeliveryBatches.some(
                (o) => o._id === order._id
              )}
            />
          </li>
        );
      })}
    </ul>
  );
};
