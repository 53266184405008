import EmptyScreenBackgroundFill from '../Temporary/EmptyScreenBackgroundFill';
import { LogoButton } from '../../components/LogoButton';

const Menu = () => {
  return (
    <EmptyScreenBackgroundFill>
      <LogoButton />
    </EmptyScreenBackgroundFill>
  );
};

export default Menu;
