import { memo } from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconClose = memo(({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  size,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-close': true,
    [`${className}`]: className,
  });

  const styleObj = size ? { width: size, height: size } : {};

  return (
    <span className={iconCls} {...props} style={styleObj}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 31 31"
      >
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          strokeMiterlimit="20"
          d="M29.1,30.1L15.5,16.7L1.9,30.1l-1-1l13.6-13.4L0.9,1.9l1-1l13.6,13.4L29.1,0.9l1,1L16.5,15.3l13.6,13.4L29.1,30.1z"
        />
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          strokeMiterlimit="20"
          d="M29.1,30.1L29.1,30.1L15.5,16.7L1.9,30.1l-1-1l13.6-13.4L0.9,1.9l1-1l13.6,13.4L29.1,0.9l1,1L16.5,15.3
	l13.6,13.4L29.1,30.1z"
        />
      </svg>
    </span>
  );
});

export default PosIconClose.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
