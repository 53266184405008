import {
  getTimestampStartOfTheDay,
  getTimestampEndOfTheDay,
} from 'util/timeHelper';

export const getOrdersFilteredByCurrent = (orders) => {
  return orders.filter((order) => {
    if (
      order.dateTimestamp >= getTimestampStartOfTheDay() &&
      order.dateTimestamp < getTimestampEndOfTheDay()
    ) {
      return order;
    } else {
      return false;
    }
  });
};
