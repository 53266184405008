import { _firestore } from '../../firebase';
import moment from 'moment-timezone';
import {
  getTimestampStartOfTheDay,
  getTimestampEndOfTheDay,
  getTimestampForDateStart,
  getTimestampForDateEnd,
} from '../../util/timeHelper';
import ActionTypes from './constants';

import { FetchStatusEnum } from '../../configuration/constants';
import { storeIdSelector } from '../Dashboard/selectors';

export function updateOrderTypeFilter(orderTypeFilter) {
  return {
    type: ActionTypes.UPDATE_ORDER_TYPE_FILTER,
    payload: {
      orderTypeFilter,
    },
  };
}

export function updateOrderTimeSortOrderFilter(orderTimeSortOrderFilter) {
  return {
    type: ActionTypes.UPDATE_ORDER_TIME_SORT_ORDER_FILTER,
    payload: {
      orderTimeSortOrderFilter,
    },
  };
}

function updateOrdersFetchStatus(ordersListFetchStatus) {
  return {
    type: ActionTypes.ordersListFetchStatusUpdate,
    payload: {
      ordersListFetchStatus,
    },
  };
}

let fetchOrderListUnsubscribe;

export function fetchOrderList(storeId, orderTypes, filterType) {
  if (fetchOrderListUnsubscribe) {
    fetchOrderListUnsubscribe();
  }

  return (dispatch) => {
    if (fetchOrderListUnsubscribe) {
      dispatch(updateOrdersFetchStatus(FetchStatusEnum.NOT_STARTED));
    }

    dispatch(updateOrdersFetchStatus(FetchStatusEnum.PENDING));

    const updateOrders = (querySnapshot) => {
      dispatch(updateOrdersFetchStatus(FetchStatusEnum.RESOLVED));

      const flattenedData = querySnapshot.docs.map((doc) => ({
        _id: doc.id,
        ...doc.data(),
      }));

      const sortedList = getNeededDataFromOrderedList(flattenedData).filter(
        (order) => order.orderStatus !== '-1'
      );
      dispatch(setOrderList(sortedList));
      dispatch(setCopyOfOrderList(sortedList));
    };

    const onError = (error) => {
      /**
       * @Note @Todo @Sentry @ErrorHandling
       */
      dispatch(updateOrdersFetchStatus(FetchStatusEnum.REJECTED));
    };

    //orderTypes (  '1'- Pickup, '2'-Delivery)
    //filterType ( '0'- Current orders, '1'-Future, '2'-History)
    if (filterType === '0') {
      //Today's orders
      fetchOrderListUnsubscribe = _firestore
        .collection('Orders')
        .where('storeId', '==', storeId)
        .where('orderType', 'in', orderTypes)
        .where('deliveryDateTimestamp', '>=', getTimestampStartOfTheDay())
        .where('deliveryDateTimestamp', '<', getTimestampEndOfTheDay())
        .orderBy('deliveryDateTimestamp', 'desc')
        .onSnapshot(updateOrders, onError);
      // .catch((error) => {
      //   dispatch(setOrderList([]));
      // });
    } else if (filterType === '1') {
      fetchOrderListUnsubscribe = _firestore
        .collection('Orders')
        .where('storeId', '==', storeId)
        .where('orderType', 'in', orderTypes)
        .where('deliveryDateTimestamp', '>', getTimestampEndOfTheDay())
        .where(
          'deliveryDateTimestamp',
          '<',
          getTimestampForDateEnd(moment().add(7, 'days'))
        )
        .orderBy('deliveryDateTimestamp', 'desc')
        .onSnapshot(updateOrders, onError);
      // .catch((error) => {
      //   dispatch(setOrderList([]));
      // });
    } else if (filterType === '2') {
      //Old orders before current day
      fetchOrderListUnsubscribe = _firestore
        .collection('Orders')
        .where('storeId', '==', storeId)
        .where('orderType', 'in', orderTypes)
        .where('deliveryDateTimestamp', '<', getTimestampStartOfTheDay())
        .where(
          'deliveryDateTimestamp',
          '>=',
          getTimestampForDateStart(moment().add(-7, 'days'))
        )
        .orderBy('deliveryDateTimestamp', 'desc')
        .onSnapshot(updateOrders, onError);
      // .catch((error) => {
      //   dispatch(setOrderList([]));
      // });
    }
  };
}

let fetchUnsettledOrderListUnsubscribe;

export function fetchUnsettledOrderList(storeId, orderTypes) {
  if (fetchUnsettledOrderListUnsubscribe) {
    fetchUnsettledOrderListUnsubscribe();
  }
  return (dispatch) => {
    //orderTypes (  '1'- Pickup, '2'-Delivery)
    fetchUnsettledOrderListUnsubscribe = _firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('orderType', 'in', orderTypes)
      .where('paymentType', '==', '0')
      .orderBy('deliveryDateTimestamp', 'desc')
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dataArray = dataArray.filter((order) => order.orderStatus !== '-1');
        const sortedList = getNeededDataFromOrderedList(dataArray);
        dispatch(setOrderList(sortedList));
        dispatch(setCopyOfOrderList(sortedList));
      });
  };
}

let fetchOrderUnsubscribe;

export function fetchOrder(orderId) {
  if (fetchOrderUnsubscribe) {
    fetchOrderUnsubscribe();
  }

  return (dispatch) => {
    fetchOrderUnsubscribe = _firestore
      .collection('Orders')
      .doc(orderId)
      .onSnapshot((doc) => {
        dispatch(setSelectedOrder({ ...doc.data(), _id: orderId }));
      });
    // .catch((error) => {
    //   dispatch(setSelectedOrder({}));
    // });
  };
}

let fetchOrderWaitingObjUnsubscribe;

export function fetchOrderWaitingObj() {
  if (fetchOrderWaitingObjUnsubscribe) {
    fetchOrderWaitingObjUnsubscribe();
  }

  return (dispatch, getState) => {
    const storeId = storeIdSelector(getState());
    fetchOrderWaitingObjUnsubscribe = _firestore
      .collection('OrderSetup')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });

        dispatch(
          setOrderWaitingObj({
            _id: dataArray[0]?._id,
            pickUpTime: dataArray[0]?.pickUpTime,
            deliveryTime: dataArray[0]?.deliveryTime,
          })
        );
      });
  };
}

export function fetchDrivers(storeId) {
  return (dispatch) => {
    _firestore
      .collection('DriverStoreMapping')
      .where('storeId', '==', storeId)
      .get()
      .then((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setDrivers(dataArray));
      });
  };
}

function setDrivers(payload) {
  return {
    type: ActionTypes.setDrivers,
    payload,
  };
}

export function setOrderList(payload) {
  return {
    type: ActionTypes.setOrderList,
    payload,
  };
}

export function setCopyOfOrderList(payload) {
  return {
    type: ActionTypes.setCopyOfOrderList,
    payload,
  };
}

function setOrderWaitingObj(payload) {
  return {
    type: ActionTypes.setOrderWaitingTimeObj,
    payload,
  };
}

export function toggleOrderBarChangeAnimation(payload) {
  return {
    type: ActionTypes.orderBarChangeAnim,
    payload,
  };
}

export function setScrollPosition(payload) {
  return {
    type: ActionTypes.setScrollPosition,
    payload,
  };
}

export function resetScrollPosition(payload) {
  return {
    type: ActionTypes.resetScrollPosition,
    payload,
  };
}

export function retainScrollTop(payload) {
  return {
    type: ActionTypes.retainScrollTop,
    payload,
  };
}

export function changeModalTime(payload) {
  return {
    type: ActionTypes.changeModalTime,
    payload,
  };
}

export function toggleViewOrderTimeModal(payload) {
  return {
    type: ActionTypes.toggleViewOrderTimeModal,
    payload,
  };
}

export function setOrderBarType(payload) {
  return {
    type: ActionTypes.setOrderBarType,
    payload,
  };
}

export function setOrderSideBarType(payload) {
  return {
    type: ActionTypes.setOrderSideBarType,
    payload,
  };
}

export function setActiveSortType(payload) {
  return {
    type: ActionTypes.setActiveSortType,
    payload,
  };
}

export function setOrderType(payload) {
  return {
    type: ActionTypes.setOrderType,
    payload,
  };
}

export function resetOrdersPageData(payload) {
  return {
    type: ActionTypes.resetOrdersPageData,
    payload,
  };
}

export function setSelectedOrder(payload) {
  return {
    type: ActionTypes.setSelectedOrder,
    payload,
  };
}

export function setFilterType(payload) {
  return {
    type: ActionTypes.setFilterType,
    payload,
  };
}

export function setShowSplitPaymentModal(payload) {
  return {
    type: ActionTypes.SHOW_SPLIT_PAYMENT_MODAL,
    payload,
  };
}

/**
 * @todo
 * shift it to utils
 */
function getNeededDataFromOrderedList(dataArray = []) {
  if (!Array.isArray(dataArray)) {
    throw new TypeError(
      `type of argument passed to getNeededDataFromOrderedList should be an array, you passed ${typeof dataArray}`
    );
  }

  return dataArray.map((obj) => ({
    _id: obj._id,
    firstName: obj.firstName,
    lastName: obj.lastName,
    mobileNumber: obj.mobileNumber,
    userId: obj.userId,
    orderType: obj.orderType,
    deliveryCode: obj.deliveryCode,
    orderFrom: obj.orderFrom,
    paymentType: obj.paymentType,
    payableAmount: obj.payableAmount,
    orderStatus: obj.orderStatus,
    date: obj.date,
    dateTimestamp: obj.dateTimestamp,
    deliveryDate: obj.deliveryDate,
    deliveryDateTimestamp: obj.deliveryDateTimestamp,
    address: obj.address,
  }));
}
