import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import GeneralModal from "../GeneralModal";
import classNames from 'classnames';
import Button from "../../components/Button";
import { PosIconLeftChevronSecond } from '../../assets/icons/PosIconLeftChevronSecond';
import { PosIconDot } from '../../assets/icons/PosIconDot';
import { currencyFormat } from '../../util/currencyFormat';

export const CashAmountModal = ({ modalStatus, toggleModal }) => {
  const [total, setTotal] = useState(0);
  const [showBtn, setShowBtn] = useState(true);

  const mainRef = useRef();

  const keyList = useRef([
    { id: 1, name: '1', val: '1' },
    { id: 2, name: '2', val: '2' },
    { id: 3, name: '3', val: '3' },
    { id: 4, name: '4', val: '4' },
    { id: 5, name: '5', val: '5' },
    { id: 6, name: '6', val: '6' },
    { id: 7, name: '7', val: '7' },
    { id: 8, name: '8', val: '8' },
    { id: 9, name: '9', val: '9' },
    {
      id: 10,
      name: <PosIconDot mainColor="515151" />,
      val: 'Dot',
    },
    { id: 11, name: '0', val: '0' },
    {
      id: 13,
      name: <PosIconLeftChevronSecond mainColor="#515151" />,
      val: 'Clear',
    },
  ]);

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="bottom"
      effect="move"
      overlay="blur"
      boxWidth="435px"
      boxHeight="688px"
      className="customer-phone-modal cash-amount"
      closeBtn="outside"
    >
      <div ref={mainRef} tabIndex="0" className="customer-phone-modal-box">
        <div className="customer-phone-header">
          <h2 className="customer-phone-title">Enter Cash Amount</h2>
          <div className="cash-amount-total grey">
            {total ? currencyFormat(total, '$') : '$0.00'}
          </div>
        </div>
        <div className="customer-phone-keypad-box">
          {keyList.current.map((item) => {
            const itemCls = classNames({
              'customer-phone-key': true,
              'hover-highlight': true,
              [`key-${item.val.toLowerCase()}`]: true,
            });

            return (
              <div key={item.id} className="customer-phone-key-wrap">
                <Button title={item.val} className={itemCls}>
                  {item.name}
                </Button>
              </div>
            );
          })}
        </div>
        <div className="customer-phone-btn-box">
          <Button
            disabled={!showBtn}
            className="btn green"
            title="Tender Cash "
          >
            Tender Cash
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

CashAmountModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  openNextModal: PropTypes.func,
  openSearchModal: PropTypes.func,
};
