import useDarkMode from 'use-dark-mode';
import { useEffect, useState } from 'react';

function useIconColor(mainColor, darkModeColor) {
  const darkMode = useDarkMode(false);
  const [color, setColor] = useState('#000000');

  useEffect(() => {
    if (darkMode.value) {
      if (darkModeColor) {
        setColor(darkModeColor);
      } else {
        setColor('#ffffff');
      }
    } else {
      if (mainColor) {
        setColor(mainColor);
      } else {
        setColor('#000000');
      }
    }
  }, [darkMode.value, darkModeColor, mainColor]); 
  return color;
}

export default useIconColor;
