import React, { useContext } from 'react';
//import Context from '../../../Context';
import { SingleOrderItem } from '../../OrderBar/SingleOrderItem';
import { useSelector } from 'react-redux';
import { ScrollableBoxUI } from '../../../UI/components';
export const SeatsList = () => {
  //const { state } = useContext(Context);
  const seats = useSelector((state) => state.ordersReducer.seats);
  //const seats = state.seats;

  return (
    <div className="seats-list-wrap">
      <ScrollableBoxUI
      // actionType="scrollViewOrdersSeatList"
      >
        {seats.map((seat, i) => {
          return (
            <div key={seat.id} className="single-seat-box">
              <div className={'single-seat-header ' + seat.color}>
                <h3 className="seat-title">{seat.seatName}</h3>
              </div>
              <div className="seat-items-wrap">
                {(seat.orderItems !== null) > 0 &&
                  seat.orderItems.map((item) => (
                    <SingleOrderItem key={item} orderId={item} seatId={seat.id} price={true} />
                  ))}
              </div>
            </div>
          );
        })}
      </ScrollableBoxUI>
    </div>
  );
};
