import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadixSelectUI } from 'UI/components';
// import SearchAddress from '../SearchAddress';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import GeneralModal from '../../modals/GeneralModal/index-new';
import { updateCurrentOrder } from '../../pages/Dashboard/action';
import { allStoresSelector } from '../../pages/Dashboard/selectors';
import Button from '../Button';
import DeliveryAddressSearch from '../DeliveryAddressSearch/DeliveryAddressSearch';

const SearchDeliveryAddressModal = ({
  modalStatus,
  // onModalClose,
  onBackClick,
  onSuggestSelect,
  onResetClick,
  address,
  error,
  errorMessage,
  parentPage,
  currentOrder,
}) => {
  const dispatch = useDispatch();
  const allStores = useSelector(allStoresSelector);
  const [, setAutoFocus] = useState(false);

  const { keyboardVisibility, toggleKeyboard } = useVirtualKeyboardContext();

  const handleNextClick = (e) => {
    // toggleModal(e, false);
    toggleKeyboard(false);
  };

  const handleStoreChange = async (value) => {
    if (value) {
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          storeId: value,
        })
      );
    }
  };

  useEffect(() => {
    if (modalStatus) {
      // dispatch({
      //   type: 'toggleKeyboard',
      //   payload: {
      //     active: true,
      //     actionType: 'deliveryCustomerLocation',
      //   },
      // });
      setTimeout(() => {
        setAutoFocus(true);
      }, 500);
    }

    if (!modalStatus) {
      // dispatch({
      //   type: 'toggleKeyboard',
      //   payload: {
      //     active: false,
      //     actionType: '',
      //   },
      // });
      // setTimeout(() => {
      setAutoFocus(false);
      toggleKeyboard(false);
      // }, 150);
    }
  }, [modalStatus]);

  // useEffect(() => {
  //   if (state.delivery.data.location && state.delivery.data.location.label) {
  //     setAddress(state.delivery.data.location.label);
  //   }
  // }, [state.delivery.data.location]);

  // useEffect(() => {
  //   setAddress(state.delivery.data.tempLocationLabel);
  // }, [state.delivery.data.tempLocationLabel]);

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={() => {}}
      position="bottom"
      effect="move"
      overlay="blur"
      boxWidth="699px"
      boxHeight="728px"
      className="search-address-modal"
      backBtn="outside"
      keyboardVisibility={keyboardVisibility}
      backBtnFnc={onBackClick}
    >
      <div className="search-address-modal-box">
        <div className="search-address-modal-box-inner">
          <h2 className="search-address-title">Search Delivery Address</h2>

          <div className="search-address-container">
            <DeliveryAddressSearch
              modalStatus={modalStatus}
              initialLocation={address || ''}
              handleAddressSelect={onSuggestSelect}
              handleResetClick={onResetClick}
              parentPage={parentPage}
              storeId={currentOrder.storeId}
            />

            {allStores.length > 1 && (
              <div className="inner-box bem-btn bem-btn--st-secondary bem-btn--r-m bem-btn--fw-regular bem-btn--cursor-default search-address-select-container">
                <RadixSelectUI
                  options={allStores}
                  value={currentOrder.storeId}
                  onValueChange={handleStoreChange}
                  containerClass={'search-address-select'}
                />
              </div>
            )}
          </div>
          {error && <p className="delivery-address__error">{errorMessage}</p>}
        </div>
        {/* <div className="search-address-modal-box-inner">
          <h2 className="search-address-title">Search Delivery Address</h2>

          <SearchAddress
            modalStatus={modalStatus}
            onSuggestSelect={(suggest) => {
              toggleKeyboard(false);
              onSuggestSelect(suggest);
            }}
            onResetClick={onResetClick}
            initLocation={address || ''}
          />
          {error && <p className="delivery-address__error">{errorMessage}</p>}
        </div> */}
        <div className="search-address-btn-box">
          <Button title="Next" onClick={handleNextClick} className="btn">
            Next
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

SearchDeliveryAddressModal.propTypes = {
  modalStatus: PropTypes.bool,
  // onModalClose: PropTypes.func,
  onBackClick: PropTypes.func,
  onSuggestSelect: PropTypes.func,
};

export default SearchDeliveryAddressModal;
