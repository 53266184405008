import moment from 'moment-timezone';

const calculatePublicHolidaySurcharge = (
  currentOrder,
  orderSetup,
  publicHolidays
) => {
  if (
    currentOrder.deliveryDate &&
    orderSetup &&
    orderSetup.isOptedInForHolidaySurcharge &&
    orderSetup.publicHolidaySurcharge &&
    publicHolidays &&
    publicHolidays.length > 0
  ) {
    let dates = publicHolidays.filter((m) => {
      return (
        m ===
        moment(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DD'
        )
      );
    });
    if (dates.length > 0) {
      return {
        holidaySurchargeApplicable: true,
      };
    } else {
      return {
        holidaySurchargeApplicable: false,
      };
    }
  } else {
    return {
      holidaySurchargeApplicable: false,
    };
  }
};

export default calculatePublicHolidaySurcharge;
