import IconViewOrder from 'assets/icons/IconViewOrder';
import { PosIconCheck } from 'assets/icons/PosIconCheck';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { PosIconMove } from 'assets/icons/PosIconMove';
import { PosIconSilverware } from 'assets/icons/PosIconSilverware';
import { PosIconUpcoming } from 'assets/icons/PosIconUpcoming';
import { PosIconWalletCash } from 'assets/icons/PosIconWalletCash';
import SidebarCourcesPopup from 'components/CoursesPopup/SidebarCourcesPopup';
import ModalPortal from 'components/ModalPortal';
import MoveTable from 'components/MoveTable';
import StaffPinModal from 'components/StaffPinModal';
import { orderType } from 'configuration/constants';
import { PrinterProvider } from 'context/PrinterContext';
import AccessDeniedModal from 'modals/AccessDeniedModal';
import { useGlobalModalContext } from 'modals/GlobalModalContext/useGlobalModalContext';
import { PrintOrderModal } from 'modals/PrintOrderModal';
import PaymentViewModal from 'components/PaymentViewModal';
import CashAmountSelectedModal from 'components/CashAmountSelectedModal';
import { setShowSplitPaymentModal } from 'pages/Orders/actions';
import SplitPaymentsRoot from 'components/SplitPayments';
import {
  useDineInObjects,
  useFloorLayouts,
} from 'notifications/context/NotificationsState';
import {
  authenticateStaff,
  loadOrderPayments,
  resetCurrentOrder,
  setGoToRouteAfterAuth,
  updateCurrentOrder,
} from 'pages/Dashboard/action';
import {
  paymentViewModalSelector,
  showSplitPaymentModalSelector,
} from 'pages/Orders/selectors';
import {
  activeStaffMemberSelector,
  currentOrderSelector,
  isAuthDisabledForPOSSelector,
  storeIdSelector,
} from 'pages/Dashboard/selectors';
import * as actions from 'pages/Orders/newOrdersReducer';
import { setState } from 'pages/TableArrangement/reducer';
import { setState as setNewOrderState } from 'pages/Orders/newOrdersReducer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as ac from 'reducers/modals';
import { endOccupancyAPI, startOccupancyAPI } from 'services/dineInServices';
import { getOrdersHistory } from 'services/ordersServices';
import { _firestore } from '../../firebase';
import { DineInOptionsItem } from '../DineInOptionsItem';
import { EnterNumberGuests } from '../EnterNumberGuests';
import { SidePanelDetails } from '../SidePanelDetails';
import { SidePanelHeader } from '../SidePanelHeader';
import { isObjectNotEmpty } from 'util/utils';
import { MENU_ORDER_FROM, OrderTypeIdEnum } from 'configuration/enums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/react';
import './DineInOptions.css';
import clsx from 'clsx';
import { RepeatCircleIcon } from 'nexticons/outline';

const iconProps = {
  mainColor: '#363636',
  darkModeColor: 'black',
  className: 'side-panel__icon',
};

export const DineInOptions = ({
  selectedShape,
  handleClearSelectedShape,
  setSelectedFloorId,
  handleTableUpdate,
  handleShapeSelect,
  handleMove,
  currentLayout,
}) => {
  const currentOrder = useSelector(currentOrderSelector);

  const activeStaffMember = useSelector(activeStaffMemberSelector);
  const movingOrder = useSelector(
    (state) => state.tableArrangementReducer.movingOrder
  );
  const storeId = useSelector(storeIdSelector);
  const dispatch = useDispatch();
  const timeout = useRef(0);
  const history = useHistory();
  const location = useLocation();
  const [floorLayouts] = useFloorLayouts();
  const { dineInObjects } = useDineInObjects();

  const allObjectsMap = floorLayouts
    .flatMap(({ dineInObjects, id }) =>
      dineInObjects.map((obj) => ({ ...obj, layoutId: id }))
    )
    .reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

  const availableTables = dineInObjects
    .filter(({ isOccupied }) => !isOccupied)
    .map(({ dineInObjectId }) => allObjectsMap[dineInObjectId])
    .filter(Boolean);

  const { handleGlobalModal } = useGlobalModalContext();

  const { newSplitPaymentFlow, enableDineInSentryLogs } = useFlags();

  // const [isPermissionCalled, setIsPermissionCalled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [wiggleEffect, setWiggleEffect] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [accessDenied, setShowAccessDenied] = useState(false);
  const [isShowNumberGuestsModal, setIsShowNumberGuestsModal] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [occupancyLoading, setOccupancyLoading] = useState(false);
  const [printLoadingStatus, setPrintLoadingStatus] = useState(false);
  const [coursesPopupOpen, setCoursesPopupOpen] = useState(false);
  const [isSettleOrder, setIsSettleOrder] = useState(false);
  const [showCashAmountModal, setShowCashAmountModal] = useState(false);
  const [cashAmountChange, setCashAmountChange] = useState(0);
  const [isOccupancyDataInProcess, setIsOccupancyDataInProcess] =
    useState(false);
  const [cashPaid, setCashPaid] = useState(0);
  const dineInOccupancies = useSelector(
    (state) => state.notificationsReducer?.dineInOccupancies
  );

  const { number, name, isOccupied, occupancyId, currentOrderId, id } =
    selectedShape;

  const selectedTableOccupancyDetails = dineInOccupancies?.find(
    (occupancy) => occupancyId === occupancy.id
  );

  const allOrdersMap =
    useSelector((state) => state.newOrdersReducer?.orders?.allOrdersMap) ?? {};
  const cashModalTitle = useSelector((state) => state.dashboardReducer.title);
  const showSplitPaymentModal = useSelector(showSplitPaymentModalSelector);
  const isAuthDisabledForPOS = useSelector(isAuthDisabledForPOSSelector);
  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken
  );
  const paymentViewModal = useSelector(paymentViewModalSelector);

  const order = allOrdersMap[currentOrderId];
  const chosenOrder = useMemo(() => {
    if (order) {
      const newOrder = {
        ...order,
        id: order._id,
      };

      return newOrder;
    }
    return { ...order };
  }, [order]);

  const hasCourses = useMemo(
    () => chosenOrder?.menuItems?.some(({ courseId }) => !!courseId) ?? false,
    [chosenOrder?.menuItems]
  );

  const title = name ? `Table ${number}, ${name}` : `Table ${number}`;

  const handleOccupancyClick = () => {
    if (isOccupied && occupancyId) {
      handleEndOccupancy();
    } else {
      setIsShowNumberGuestsModal(true);
    }
  };

  const updateOrderInStore = React.useCallback(
    (orderToEdit) => {
      if (orderToEdit?.id) {
        dispatch(
          updateCurrentOrder({
            ...orderToEdit,
            // data from the API has an ID as a different field name
            _id: orderToEdit.id,
            orderType: OrderTypeIdEnum.DINE_IN,
            orderFrom: MENU_ORDER_FROM.pos,

            /*NOTE: handling iteration and prevIteration in handleAddOrderToTable function now */
            iteration: orderToEdit.iteration + 1 || 1,
            prevIteration: orderToEdit.iteration || 0,

            menuItems: orderToEdit.menuItems.map((menuItem) =>
              typeof menuItem.iteration === 'undefined'
                ? { ...menuItem, iteration: orderToEdit.iteration || 0 }
                : menuItem
            ),
          })
        );
      } else {
        const { id, occupancyId = '', name, number } = selectedShape;
        dispatch(
          updateCurrentOrder({
            ...(chosenOrder ?? currentOrder),
            orderType: OrderTypeIdEnum.DINE_IN,
            orderFrom: MENU_ORDER_FROM.pos,

            firstName: 'xxx-xxx-xxx',
            lastName: 'xxx-xxx-xxx',
            mobileNumber: 'xxx-xxx-xxx',
            userId: 'xxx-xxx-xxx',
            floorLayoutId: currentLayout.id || '',
            occupancyId,
            dineInObjectId: id || '',
            tableNumber: name || number || '',
            tableId: id || '',
          })
        );
      }
    },
    [currentLayout?.id, currentOrder, dispatch, chosenOrder, selectedShape]
  );

  // const handlePinModalClose = () => {
  //   setPlaceOrderLoading(false);
  //   closeModal();
  // };

  // const handlePinComplete = React.useCallback(
  //   async (accessCode) => {
  //     if (isPermissionCalled) return;
  //     // make api call to authenticate the staff
  //     if (storeId && accessCode) {
  //       const data = await authenticateStaffByAccessCode(storeId, accessCode);
  //       const selectedOrderTypeRoute = '/dinein/order';

  //       if (data.permissions) {
  //         if (hasRightPermission(selectedOrderTypeRoute, data.permissions)) {
  //           setIsPermissionCalled(true);
  //           dispatch(authenticateStaff(true));
  //           dispatch(setActiveStaffMember(data.staffMember));

  //           if (selectedOrder?._id) {
  //             dispatch(loadOrderPayments(selectedOrder._id));
  //           }
  //           handlePinModalClose();
  //         } else {
  //           setErrorMessage('You do not have a permission to place an order.');
  //           setShowAccessDenied(true);
  //         }
  //       } else if (data.error) {
  //         setErrorMessage(data.error);
  //         setWiggleEffect(true);
  //       }
  //     }
  //   },
  //   // eslint-disable-next-line
  //   [
  //     isPermissionCalled,
  //     storeId,
  //     dispatch,
  //     updateOrderInStore,
  //     selectedOrder,
  //     history,
  //     location.pathname,
  //   ]
  // );

  const openModal = useCallback(
    () => dispatch(ac.openModal('staffPinModal')),
    [dispatch]
  );
  // const closeModal = useCallback(() => dispatch(ac.closeModal('staffPinModal')), [dispatch])

  const placeOrder = () => {
    if (!isAuthDisabledForPOS) {
      dispatch(setGoToRouteAfterAuth('/dinein/order'));
      openModal();
      return;
    }
    updateOrderInStore();

    setPlaceOrderLoading(false);

    timeout.current = setTimeout(() => {
      history.push('/dinein/order');
    }, 100);
  };

  const editOrder = (order) => {
    setPlaceOrderLoading(false);
    if (!order) return;
    setSelectedOrder(order);
    if (!isAuthDisabledForPOS) {
      dispatch(setGoToRouteAfterAuth('/dinein/order'));
      openModal();
      return;
    }

    timeout.current = setTimeout(() => {
      const selectedOrderTypeRoute =
        orderType.find((o) => o.id === order?.orderType)?.route || '';
      dispatch(authenticateStaff(true));
      updateOrderInStore(order);
      dispatch(loadOrderPayments(order.id));

      setPlaceOrderLoading(false);
      history.push(`${selectedOrderTypeRoute}`, {
        referrer: location.pathname,
      });
    }, 100);
  };

  const onEditPlaceOrder = async () => {
    if (currentOrderId) {
      setPlaceOrderLoading(true);

      const orderRef = _firestore.collection('Orders').doc(currentOrderId);
      const doc = await orderRef.get();
      if (doc.exists) {
        editOrder({ id: doc.id, ...doc.data() });
      }

      // setPlaceOrderLoading(true);
      // const ordersHistoryResponse = await getOrdersHistory({
      // occupancyId,
      // limit: 1,
      // });

      // const order = ordersHistoryResponse?.data?.orders;

      // if (order?.length > 0) {
      // console.log(order)
      // editOrder(order[0]);
      // }

      const tableData = {
        orderId: currentOrderId,
        occupancyId,
        dineInObjectId: id,
        tableNumber: number,
        storeId,
        layoutId: currentLayout?.id,
        menuItems: chosenOrder.menuItems,
      };

      enableDineInSentryLogs &&
        Sentry.captureMessage(`Edit Order : ${chosenOrder?.deliveryCode}`, {
          extra: {
            data: JSON.stringify(tableData),
          },
        });
    } else {
      const tableData = {
        occupancyId,
        dineInObjectId: id,
        tableNumber: number,
        storeId,
        layoutId: currentLayout?.id,
      };

      enableDineInSentryLogs &&
        Sentry.captureMessage('Place Order', {
          extra: {
            data: JSON.stringify(tableData),
          },
        });
      placeOrder();
    }
  };

  const viewOrder = () => {
    dispatch(actions.setFilters({ type: 'dine-in' }));

    history.push({
      pathname: `/orders/current/dine-in`,
      state: {
        orderID: currentOrderId,
        backLink: '/table-arrangement',
      },
    });
  };

  const moveOrder = () => {
    dispatch(setState({ movingOrder: true }));
  };

  const startOccupancy = async (numberOfGuests) => {
    setIsOccupancyDataInProcess(true);
    setOccupancyLoading(true);
    setIsShowNumberGuestsModal(false);

    const staffId = activeStaffMember?._id || '';

    const res = await startOccupancyAPI(
      storeId,
      currentLayout.id,
      staffId,
      [selectedShape.id],
      Number(numberOfGuests),
      accessToken
    );

    if (res?.data?.dineInOccupancy) {
      handleTableUpdate(selectedShape, {
        occupancyId: res.data.dineInOccupancy.id,
        isOccupied: true,
      });
      setOccupancyLoading(false);
      // placeOrder();
    }
  };

  const handleEndOccupancy = () => {
    handleGlobalModal({
      title: 'End Sitting?',
      message:
        'This will end the current sitting and free up the table for new guests.',
      actionButtonLabel: 'Yes',
      actionButtonHandler: endOccupancy,
      dismissButtonLabel: 'No',
      dismissButtonColor: 'grey',
    });
  };

  const endOccupancy = async () => {
    if (!occupancyId) {
      return;
    }

    setOccupancyLoading(true);

    const res = await endOccupancyAPI(occupancyId, accessToken);

    if (res) {
      handleTableUpdate(selectedShape, { isOccupied: false });
      handleShapeSelect(null);
    }
  };

  const handlePaymentModalClose = useCallback(() => {
    dispatch({
      type: 'togglePaymentViewModal',
      payload: false,
    });
  }, [dispatch]);

  const handleCashAmountSelectedModal = useCallback(
    (orderId = '') => {
      handlePaymentModalClose();
      dispatch(setNewOrderState({ selectedOrderId: orderId }));
      setShowCashAmountModal(true);
    },
    [dispatch]
  );

  const handleSplitPaymentClick = useCallback(() => {
    handlePaymentModalClose();
    dispatch(setShowSplitPaymentModal(true));
  }, [dispatch]);

  const handleCashAmountModalClose = useCallback(() => {
    setShowCashAmountModal(false);
  }, [dispatch]);

  const handleSplitPaymentModalClose = useCallback(() => {
    dispatch(setShowSplitPaymentModal(false));
  }, [dispatch]);

  const handleTogglePaymentView = useCallback(() => {
    if (selectedOrder?.isSplit) {
      dispatch(setShowSplitPaymentModal(true));
    } else {
      dispatch({
        type: 'togglePaymentViewModal',
        payload: true,
      });
    }
  }, [dispatch, selectedOrder]);

  const handleSettleOrder = useCallback(() => {
    setSelectedOrder(order);
    dispatch(loadOrderPayments(order._id));
    if (!isAuthDisabledForPOS && isObjectNotEmpty(order)) {
      setIsSettleOrder(true);
      openModal();
    } else {
      handleTogglePaymentView();
      setIsSettleOrder(false);
    }
  }, [order, isAuthDisabledForPOS]);

  useEffect(() => {
    dispatch(resetCurrentOrder());
  }, [dispatch]);

  useEffect(() => {
    if (isOccupied) {
      if (selectedTableOccupancyDetails?.id) {
        setIsOccupancyDataInProcess(false);
      }
    }
  }, [selectedTableOccupancyDetails]);

  const handlePrintBillClick = async () => {
    if (currentOrderId) {
      let order = null;
      if (!chosenOrder) {
        setPrintLoadingStatus(true);
        const ordersHistoryResponse = await getOrdersHistory({
          occupancyId,
          limit: 1,
        });
        setPrintLoadingStatus(false);
        order = ordersHistoryResponse?.data?.orders?.[0];
      } else {
        order = chosenOrder;
      }
      if (order) {
        setSelectedOrder(order);
        dispatch({
          type: 'togglePlanBillModal',
          payload: true,
        });
      }
    }
  };

  if (movingOrder) {
    return (
      <MoveTable
        handleMove={handleMove}
        setSelectedFloorId={setSelectedFloorId}
        handleTableUpdate={handleTableUpdate}
        currentLayoutId={currentLayout.id}
        selectedShape={selectedShape}
        availableTables={availableTables}
        orderId={currentOrderId}
        endOccupancy={endOccupancy}
        startOccupancy={startOccupancy}
        handleShapeSelect={handleShapeSelect}
      />
    );
  }

  return (
    <section
      className="side-panel__section side-panel__section--table-menu"
      style={{ position: 'relative' }}
    >
      <SidePanelHeader
        title={title}
        handlePanelClose={handleClearSelectedShape}
      />

      <SidePanelDetails>
        <nav className="side-panel__nav">
          <ul>
            <DineInOptionsItem
              label={`${
                isOccupied && selectedTableOccupancyDetails?.id
                  ? 'End'
                  : 'Start'
              } Sitting`}
              icon={
                occupancyLoading || isOccupancyDataInProcess ? (
                  <PosIconLoading {...iconProps} />
                ) : (
                  <PosIconUpcoming {...iconProps} />
                )
              }
              handleClick={handleOccupancyClick}
            />

            {isOccupied && occupancyId && selectedTableOccupancyDetails?.id && (
              <>
                <DineInOptionsItem
                  isDisabled={!!order?.isOrderProcessing}
                  label={`${currentOrderId ? 'Edit' : 'Place'} Order`}
                  syncText={!!order?.isOrderProcessing ? 'Syncing' : ''}
                  icon={
                    !!order?.isOrderProcessing ? (
                      <RepeatCircleIcon
                        className={clsx('side-panel__icon', 'sync-icon')}
                      />
                    ) : placeOrderLoading ? (
                      <PosIconLoading {...iconProps} />
                    ) : (
                      <PosIconSilverware {...iconProps} />
                    )
                  }
                  handleClick={onEditPlaceOrder}
                />
              </>
            )}

            {currentOrderId && (
              <>
                <DineInOptionsItem
                  label="View Order"
                  isDisabled={!order?.deliveryCode}
                  syncText={!order?.deliveryCode ? 'Syncing' : ''}
                  icon={
                    !order?.deliveryCode ? (
                      <RepeatCircleIcon
                        className={clsx('side-panel__icon', 'sync-icon')}
                      />
                    ) : (
                      <IconViewOrder className="side-panel__icon" />
                    )
                  }
                  handleClick={viewOrder}
                />
                <DineInOptionsItem
                  label="Move"
                  icon={<PosIconMove className="side-panel__icon" />}
                  handleClick={moveOrder}
                />
                {isObjectNotEmpty(order) && (
                  <DineInOptionsItem
                    label="Settle"
                    icon={<PosIconWalletCash className="side-panel__icon" />}
                    handleClick={handleSettleOrder}
                    isDisabled={order?.paymentStatus === 1}
                  >
                    <div className="single-nav-item__customTags">
                      <span
                        className={clsx(
                          'paymentStatusTag',
                          { ['dinein-paid']: order?.paymentStatus === 1 },
                          { ['dinein-unpaid']: order?.paymentStatus === 0 },
                          {
                            ['dinein-partiallyPaid']:
                              order?.paymentStatus === 2,
                          }
                        )}
                      >
                        {order?.paymentStatusDisplay}
                      </span>
                    </div>
                  </DineInOptionsItem>
                )}
                {newSplitPaymentFlow && (
                  <DineInOptionsItem
                    label="Split Bill"
                    icon={<PosIconWalletCash className="side-panel__icon" />}
                    handleClick={() =>
                      history.push(`/payment/${currentOrderId}`)
                    }
                  />
                )}
              </>
            )}

            {currentOrderId && (
              <DineInOptionsItem
                label="Print Bill"
                icon={
                  printLoadingStatus ? (
                    <PosIconLoading {...iconProps} />
                  ) : (
                    <PosIconCheck {...iconProps} />
                  )
                }
                handleClick={handlePrintBillClick}
              />
            )}

            {hasCourses && (
              <DineInOptionsItem
                label="Send Course"
                icon={
                  printLoadingStatus ? (
                    <PosIconLoading {...iconProps} />
                  ) : (
                    <PosIconSilverware {...iconProps} />
                  )
                }
                handleClick={() => setCoursesPopupOpen(true)}
              />
            )}
          </ul>
        </nav>
      </SidePanelDetails>

      <ModalPortal>
        {hasCourses && (
          <SidebarCourcesPopup
            chosenOrder={chosenOrder}
            coursesPopupOpen={coursesPopupOpen}
            setCloseCoursesPopup={() => setCoursesPopupOpen(false)}
          />
        )}
        <EnterNumberGuests
          modalStatus={isShowNumberGuestsModal}
          onNextClick={startOccupancy}
          onModalClose={() => setIsShowNumberGuestsModal(false)}
          selectedShape={selectedShape}
        />

        <StaffPinModal
          onComplete={(accessCode) => {
            if (isSettleOrder) {
              handleTogglePaymentView();
              setIsSettleOrder(false);
            } else {
              updateOrderInStore(currentOrderId ? selectedOrder : null);

              history.push(`/dinein/order`, {
                referrer: location.pathname,
              });
            }
          }}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          wiggleEffect={wiggleEffect}
          setWiggleEffect={setWiggleEffect}
          handleCustomCloseModal={() => {
            setIsSettleOrder(false);
          }}
          isSettleOrder={isSettleOrder}
        />
        {paymentViewModal && (
          <PaymentViewModal
            toggleModal={handlePaymentModalClose}
            // openPaymentViewModal={handleOpenPaymentViewModal}
            currentOrder={selectedOrder}
            orderTimes={''}
            isOrdersPage={true}
            openCashAmountPaidModal={handleCashAmountSelectedModal}
            handleCashAmountChange={setCashAmountChange}
            handleCashPaid={setCashPaid}
            handleSplitPaymentClick={handleSplitPaymentClick}
          />
        )}
        {showCashAmountModal && (
          <CashAmountSelectedModal
            orderId={selectedOrder._id}
            toggleModal={handleCashAmountModalClose}
            modalStatus={!paymentViewModal && showCashAmountModal}
            cashAmountChange={cashAmountChange}
            cashPaid={cashPaid}
            isOrdersPage={true}
            title={cashModalTitle}
          />
        )}

        {showSplitPaymentModal ? (
          <SplitPaymentsRoot
            isOrdersPage={true}
            currentOrder={selectedOrder}
            closeFlowStatus={handleSplitPaymentModalClose}
          />
        ) : null}
        {accessDenied && (
          <AccessDeniedModal
            handleModalClose={setShowAccessDenied}
            message={errorMessage}
          />
        )}
        <PrinterProvider>
          <PrintOrderModal selectedOrder={selectedOrder} />
        </PrinterProvider>
      </ModalPortal>
    </section>
  );
};
