import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { PosIconReload } from 'assets/icons/PosIconReload';
import AddFloorModal from 'components/AddFloorModal';
import { API_URL } from 'configuration/apiUrl';
import { useApi } from 'hooks/useApi';
import { useGlobalModalContext } from 'modals/GlobalModalContext/useGlobalModalContext';
import {
  useDineInObjects,
  useFloorLayouts,
} from 'notifications/context/NotificationsState';
import { getFloorLayoutIndex } from 'notifications/utils';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createLayoutAPI,
  deleteFromLayoutAPI,
  deleteLayoutAPI,
  endOccupancyAPI,
  startOccupancyAPI,
  updateLayoutAPI,
} from 'services/dineInServices';
import { ButtonUI } from 'UI/components';
import {
  activeStaffMemberSelector,
  storeIdSelector,
} from '../Dashboard/selectors';
import { FloorLayout } from './FloorLayout';
import { Header } from './Header';
import { useGetFloorLayouts, useStoreNewFloorObjects } from './hooks';
import { SidePanel } from './SidePanel';
import './styles.scss';
import {
  bulkObjectsUpdate,
  checkIfLayoutIsEmpty,
  findNextAvailableTableNumber,
  findShapeIndexByID,
  getCorrectedPositions,
  getNewPositions,
  getUpdatedFloorLayoutsAndItems,
  getUpdatedFloorLayoutsAndItemsForFloorId,
  normaliseData,
  prepareLayoutToSave,
  removeSingleFloorLayoutsAndItems,
  removeUpdatedFloorLayoutsAndItems,
  updateFloorLayoutsAndItems,
} from './utils';

import { setState } from './reducer';
import useOrdersData from 'hooks/useOrdersData';
import axiosWithInterceptor from '../../util/interceptor';

const TableArrangement = () => {
  useOrdersData('dine-in');
  const layoutArea = useRef();
  const { handleGlobalModal } = useGlobalModalContext();
  const activeStaffMember = useSelector(activeStaffMemberSelector);
  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken
  );

  const storeId = useSelector(storeIdSelector);
  const [pending, setPending] = useState(true);
  const pendingRef = useRef(pending);
  const [layoutLoading, setLayoutLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  const [objectsProcessing, setObjectProcessing] = useState(false);
  const [selectedFloorId, setSelectedFloorId] = useState(() => {
    const defaultLayoutId = localStorage.getItem('dineInLayoutToDefaultTo');
    return defaultLayoutId || undefined;
  });

  // TO CHECK IF STORE HAS ANY LAYOUTS AS GRPC DOESN'T TELL YOU.
  // MAY HAVE TO FIND A BETTER WAY

  const [responseLayouts, requestLayouts] = useGetFloorLayouts();
  const [floorLayouts] = useFloorLayouts();
  const { dineInObjects, totalNumPeople } = useDineInObjects();

  const selectedFloorIndex = useMemo(() => {
    const floorIndex = getFloorLayoutIndex(floorLayouts, selectedFloorId);
    if (floorIndex === -1) return undefined;
    return floorIndex;
  }, [floorLayouts, selectedFloorId]);

  const selectedFloorLayout = useMemo(
    () => floorLayouts[selectedFloorIndex || 0] || {},
    [floorLayouts, selectedFloorIndex]
  );

  const objectsToRender = useRef([]);
  const availableTableNums = useRef([]);

  const [selectedShape, setSelectedShape] = useState(null);
  const [layoutUpdated, setLayoutUpdated] = useState(false);

  const [
    newFloorObjects,
    addNewFloorObjects,
    overWriteNewFloorObjects,
    updateNewFloorObjects,
    deleteFromNewFloorObjects,
    resetNewFloorObjects,
  ] = useStoreNewFloorObjects();

  const timerRef = useRef(null);
  // call api on load
  useEffect(() => {
    clearTimeout(timerRef.current);
    requestLayouts(storeId);

    timerRef.current = setTimeout(() => {
      if (pendingRef.current) {
        setShowRefresh(true);
      }
    }, 8000);

    return () => {
      clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  // Check if the store has any floor layouts
  useEffect(() => {
    const resetForEdit = () => {
      set_pending(false);
      setShowRefresh(false);
      setLayoutLoading(false);
      setEditMode(true);
    };

    if (
      responseLayouts.pending === false &&
      responseLayouts?.data?.length <= 0
    ) {
      resetForEdit();
      return;
    }

    if (!responseLayouts.pending && responseLayouts?.data?.length) {
      const layouts = responseLayouts?.data;

      const isLayoutEmpty = checkIfLayoutIsEmpty(layouts);
      if (!isLayoutEmpty) return;

      resetForEdit();
    }
  }, [responseLayouts]);

  useEffect(() => {
    if (selectedFloorId === undefined && floorLayouts[0]?.id) {
      const defaultLayoutId = localStorage.getItem('dineInLayoutToDefaultTo');
      setSelectedFloorId(defaultLayoutId || floorLayouts[0]?.id);
    }
  }, [floorLayouts, selectedFloorId]);

  const set_pending = (bool) => {
    setPending(bool);
    pendingRef.current = bool;
  };

  // static value (interface doesn't react on the layout dimensions change so far)
  const layoutSize = Object.freeze({
    width: layoutArea?.current?.clientWidth,
    height: layoutArea?.current?.clientHeight,
  });

  const handleFloorTabSelection = (index) => {
    if (!movingOrder) {
      handleClearSelectedShape();
    }
    localStorage.setItem('dineInLayoutToDefaultTo', floorLayouts[index]?.id);
    setSelectedFloorId(floorLayouts[index]?.id);
  };

  // handlers
  const handleShapeInsert = (shape) => {
    setSelectedShape(null);

    if (!editMode) return;

    const { positionX, positionY } = getNewPositions(
      objectsToRender.current,
      shape
    );

    let newShape = {
      ...shape,
      id: new Date().getTime(),
      // move each newly added shape a bit to avoid visual overlapping
      positionX,
      positionY,
    };

    if (shape.objectType === 'dineInObjects') {
      const maxTableNumber = findNextAvailableTableNumber(
        availableTableNums.current,
        shape.number || 0
      );

      newShape = {
        ...newShape,
        number: String(maxTableNumber),
        isOccupied: false,
        occupancyId: null,
        isUpdated: true,
      };
    }

    addNewFloorObjects(newShape);

    objectsToRender.current = [...objectsToRender.current, newShape];

    handleShapeSelect(newShape);

    if (shape.objectType === 'dineInObjects') {
      handleUpdateAvailableTableNums();
    }
  };

  const handleShapeRemove = () => {
    if (!editMode && !selectedShape) return;

    setSelectedShape(null);
    setLayoutLoading(true);

    let newObjectsToRender = [...objectsToRender.current];

    const shapeIdx = findShapeIndexByID(newObjectsToRender, selectedShape.id);

    if (shapeIdx) {
      newObjectsToRender.splice(shapeIdx, 1);
      objectsToRender.current = newObjectsToRender;
    }

    // if id of the shape is not temp send the delete object request
    if (typeof selectedShape.id !== 'number') {
      deleteObject(selectedShape);
    } else {
      deleteFromNewFloorObjects(selectedShape);
    }

    handleUpdateAvailableTableNums();
    layoutEndLoaderTimeout();
  };

  const handleRemoveUnsavedChanges = () => {
    const updatedObjects = objectsToRender.current.filter(
      (object) => typeof object.id !== 'number'
    );
    objectsToRender.current = updatedObjects;
    handleClearSelectedShape();
    resetNewFloorObjects();
    removeUpdatedFloorLayoutsAndItems();
  };

  const handleModeToggle = () => {
    handleClearSelectedShape();
    if (pending) return;

    if (editMode && layoutUpdated) {
      handleGlobalModal({
        title: 'You have unsaved layout changes',
        message:
          'Please click on "Save Layout",\r\n otherwise changes will be lost',
        actionButtonLabel: 'Save Layout',
        actionButtonHandler: handleSaveLayout,
        dismissButtonLabel: 'Exit Anyway',
        dismissButtonHandler: () => {
          setEditMode(false);
          handleRemoveUnsavedChanges();
        },
      });
    } else {
      setEditMode(!editMode);
    }
  };

  const handleTableUpdate = (
    table,
    propertiesToUpdate = { isUpdated: true }
  ) => {
    updateShape(table, propertiesToUpdate);
    setLayoutUpdated(true);
  };

  const handleDragEnd = (shape, info) => {
    if (!shape) return;

    const { positionX, positionY } = getCorrectedPositions(
      shape,
      info,
      layoutSize
    );

    const propsToUpdate = {
      positionX,
      positionY,
      isUpdated: true,
    };

    handleShapeUpdate(shape, propsToUpdate);
  };

  const handleShapeSelect = (shape, cb) => {
    if (!shape) {
      setSelectedShape(null);
      return;
    }

    if (!editMode && shape.objectType !== 'dineInObjects') return;

    setSelectedShape(shape);
  };

  const movingOrder = useSelector(
    (state) => state.tableArrangementReducer.movingOrder
  );

  const handleClearSelectedShape = () => {
    dispatch(setState({ movingOrder: false }));
    setSelectedShape(null);
  };

  const handleShapeUpdate = (shape, propertiesToUpdate) => {
    updateShape(shape, propertiesToUpdate);
    setLayoutUpdated(true);
  };

  const updateShape = (shape, propertiesToUpdate) => {
    const shapeIdx = findShapeIndexByID(objectsToRender.current, shape.id);

    let newObjectsToRender = [...objectsToRender.current];
    let newShape = { ...shape, ...propertiesToUpdate };

    if (editMode && typeof shape.id === 'number') {
      updateNewFloorObjects(newShape);
    }

    newObjectsToRender[shapeIdx] = newShape;

    objectsToRender.current = newObjectsToRender;

    handleShapeSelect(newShape);
    handleUpdateAvailableTableNums();
  };

  const handleUpdateAvailableTableNums = () => {
    let updateAvailableTableNums = [];

    // setting only unavailable
    objectsToRender.current.forEach((object) => {
      if (object.objectType === 'dineInObjects') {
        updateAvailableTableNums.push(Number(object.number));
      }
    });

    availableTableNums.current = updateAvailableTableNums;
  };

  const handleSaveLayout = () => {
    if (pending) return;

    /* 

      Find the updates from localStorage
      Ignore the update with the selected floor id
      Pass the array to prepareLayoutToSave for each of the floor layouts
      Then within the array call saveLayout

      Change save layout to receive floor layout info
    
    */

    const currentUpdatedFloorLayoutsAndItems = getUpdatedFloorLayoutsAndItems();

    setLayoutLoading(true);

    const usedIds = [];
    const apiRequestsArray = [];

    const data = prepareLayoutToSave(objectsToRender.current);
    const { displayOrder, isUpdated = false } =
      floorsToRender[selectedFloorId] || {};
    usedIds.push(selectedFloorId);
    apiRequestsArray.push(
      saveLayout(data, { ...selectedFloorLayout, displayOrder, isUpdated })
    );

    if (currentUpdatedFloorLayoutsAndItems[selectedFloorId]) {
      delete currentUpdatedFloorLayoutsAndItems[selectedFloorId];
    }

    Object.entries(currentUpdatedFloorLayoutsAndItems).forEach(
      ([floorId, floorUpdates]) => {
        const floorIndex = getFloorLayoutIndex(floorLayouts, floorId);
        const data = prepareLayoutToSave(floorUpdates);
        const { displayOrder, isUpdated = false } = floorsToRender[floorId];
        usedIds.push(floorId);
        apiRequestsArray.push(
          saveLayout(data, {
            ...floorLayouts[floorIndex],
            displayOrder,
            isUpdated,
          })
        );
      }
    );

    Object.keys(floorsToRender).forEach((floorId) => {
      if (usedIds.indexOf(floorId) < 0) {
        const floorIndex = getFloorLayoutIndex(floorLayouts, floorId);
        const { displayOrder, isUpdated = false } = floorsToRender[floorId];
        usedIds.push(floorId);
        apiRequestsArray.push(
          saveLayout(undefined, {
            ...floorLayouts[floorIndex],
            displayOrder,
            isUpdated,
          })
        );
      }
    });

    Promise.all(apiRequestsArray).then(() => {
      layoutEndLoaderTimeout();
      setEditMode(false);
      setSelectedShape(null);
      resetNewFloorObjects();
      removeUpdatedFloorLayoutsAndItems();
    });
  };

  const layoutEndLoaderTimeout = useCallback(() => {
    setTimeout(() => {
      setLayoutLoading(false);
    }, 500); // allow layout to finish position animation
  }, []);

  const saveLayout = async (data, floorInfo) => {
    if (!data && !floorInfo.isUpdated) {
      // setEditMode(false);
      return;
    }

    if (floorInfo?.id) {
      const updateData = {
        storeId,
        ...floorInfo,
        ...data,
      };
      return await updateLayoutAPI(updateData);
    } else {
      return await createLayoutAPI(storeId, '1st Floor', data); // 1st Floor is a default name for newly created layout
    }
  };

  const deleteObject = async (shape) => {
    if (!shape) {
      return;
    }

    await deleteFromLayoutAPI(
      selectedFloorLayout.id,
      shape.objectType,
      shape.id
    );
  };

  const [createFloorLayoutLoading, setCreateFloorLayoutLoading] =
    useState(false);
  const [addFloorModalStatus, setAddFloorModalStatus] = useState(false);

  const createLayout = async (floorName) => {
    if (createFloorLayoutLoading) return;

    const floorData = {
      dineInObjects: [],
      displayOrder: floorLayouts[floorLayouts.length - 1].displayOrder + 1,
      isActive: true,
      layoutDividers: [],
      nonDineInObjects: [],
    };
    setCreateFloorLayoutLoading(true);
    const { data = {} } = await createLayoutAPI(storeId, floorName, floorData);
    const { floorLayout = {} } = data;
    setTimeout(() => {
      if (floorLayout.id) setSelectedFloorId(floorLayout.id);
      setCreateFloorLayoutLoading(false);
      setAddFloorModalStatus(false);
    }, 1000);
  };

  const updateFloorName = async (floorName) => {
    if (createFloorLayoutLoading) return;

    const floorData = {
      ...selectedFloorLayout,
      storeId,
      name: floorName,
    };
    setCreateFloorLayoutLoading(true);
    const { data } = await updateLayoutAPI(floorData);
    if (data) {
      const { floorLayout } = data;
      const { name, id } = floorLayout;
      setFloorsToRender((floors) => {
        const floorToChange = floors[id];
        return {
          ...floors,
          [id]: {
            ...floorToChange,
            title: name,
          },
        };
      });
    }
    setTimeout(() => {
      setCreateFloorLayoutLoading(false);
      setAddFloorModalStatus(false);
      setFloorLayoutNameToEdit('');
    }, 500);
  };

  const handleAddFloorClick = () => {
    setAddFloorModalStatus(true);
  };

  const modalBackButtonClick = () => {
    if (createFloorLayoutLoading) return;
    setAddFloorModalStatus(false);
  };

  const processLayoutsData = useCallback(
    (currentFloor, dineInObjectsStatus) => {
      setObjectProcessing(true);
      const { dineInObjects, nonDineInObjects, layoutDividers } = currentFloor;

      const dineInObjectsWithStatus = bulkObjectsUpdate(
        dineInObjects,
        dineInObjectsStatus,
        'id',
        ['isOccupied', 'occupancyId', 'currentOrderId'],
        'dineInObjectId'
      );

      let objects = normaliseData([
        ...dineInObjectsWithStatus,
        ...nonDineInObjects,
        ...layoutDividers,
      ]);

      objects = [...objects, ...newFloorObjects];

      const updatesForTheFloorFromLS = getUpdatedFloorLayoutsAndItemsForFloorId(
        currentFloor?.id
      );

      if (updatesForTheFloorFromLS) {
        objects = bulkObjectsUpdate(
          objects,
          updatesForTheFloorFromLS,
          'id',
          [
            'name',
            'numSeats',
            'number',
            'isUpdated',
            'orientationAngle',
            'positionX',
            'positionY',
            'scale',
          ],
          'id'
        );
      }

      objectsToRender.current = objects;

      // set unavailable tables numbers
      let availableTables = [];
      objectsToRender.current.forEach((item) => {
        if (item.objectType === 'dineInObjects') {
          availableTables.push(Number(item.number));
        }
      });
      availableTableNums.current = availableTables;

      if (selectedShape) {
        const updatedShape = objectsToRender.current.find(
          (obj) => obj.id === selectedShape.id
        );
        if (typeof updatedShape !== 'undefined') {
          setSelectedShape(updatedShape);
        }
      }

      // TODO: force objectsToRender ref to update
      setTimeout(() => {
        setObjectProcessing(false);
      }, 100);
    },
    [newFloorObjects, selectedShape]
  );

  useEffect(() => {
    if (selectedFloorLayout?.id && dineInObjects) {
      set_pending(false);
      setShowRefresh(false);
      setLayoutLoading(false);
      processLayoutsData(selectedFloorLayout, dineInObjects);
    } else {
      objectsToRender.current = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dineInObjects, selectedFloorLayout]);

  useEffect(() => {
    removeUpdatedFloorLayoutsAndItems();
    return () => {
      dispatch(setState({ movingOrder: false }));
      removeUpdatedFloorLayoutsAndItems();
    };
  }, [dispatch]);

  const [floorsToRender, setFloorsToRender] = useState({});
  const floorsForSwitchTabs = useMemo(
    () =>
      Object.keys(floorsToRender)
        .map((floorKey) => floorsToRender[floorKey])
        .sort((a, b) => a.displayOrder - b.displayOrder),
    [floorsToRender]
  );

  useEffect(() => {
    if (editMode) {
      if (Object.keys(floorsToRender).length === floorLayouts.length) return;
    }
    let floors = {};
    floorLayouts.forEach(({ name, displayOrder, id }) => {
      floors[id] = {
        title: name,
        displayOrder,
        id,
      };
    });
    setFloorsToRender(floors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, floorLayouts]);

  const handleTabSelectionInEditMode = (id) => {
    const updatedItems = objectsToRender.current?.filter(
      (object) => object.isUpdated
    );

    const updatedFloorLayoutsAndItemsForSelectedFloor =
      getUpdatedFloorLayoutsAndItemsForFloorId(id);

    if (updatedItems.length) {
      updateFloorLayoutsAndItems(selectedFloorId, updatedItems);
    }
    if (updatedFloorLayoutsAndItemsForSelectedFloor) {
      const newItems = updatedFloorLayoutsAndItemsForSelectedFloor?.filter?.(
        (item) => typeof item.id === 'number'
      );
      overWriteNewFloorObjects(newItems);
    } else {
      resetNewFloorObjects();
    }
    handleClearSelectedShape();
    localStorage.setItem('dineInLayoutToDefaultTo', id);
    setSelectedFloorId(id);
  };

  const [floorLayoutNameToEdit, setFloorLayoutNameToEdit] = React.useState('');
  const handleTabDoubleSelectionInEditMode = () => {
    setFloorLayoutNameToEdit(selectedFloorLayout.name);
    setAddFloorModalStatus(true);
  };

  const handleReorder = (reorderedFloors) => {
    const newFloors = {};
    reorderedFloors.forEach((reorderedFloor, index) => {
      newFloors[reorderedFloor.id] = floorsToRender[reorderedFloor.id];
      if (floorsToRender[reorderedFloor.id]?.displayOrder !== index) {
        newFloors[reorderedFloor.id] = {
          ...newFloors[reorderedFloor.id],
          displayOrder: index,
          isUpdated: true,
        };
      }
    });
    setFloorsToRender(newFloors);
  };

  const handleDeleteFloorLayout = async (floorId) => {
    setLayoutLoading(true);
    await deleteLayoutAPI(floorId);
    const floorIndex = getFloorLayoutIndex(floorLayouts, floorId);
    if (floorIndex === 0) {
      setSelectedFloorId(floorLayouts[floorIndex + 1]?.id || undefined);
    } else {
      setSelectedFloorId(floorLayouts[floorIndex - 1]?.id);
    }
    const defaultLayoutId = localStorage.getItem('dineInLayoutToDefaultTo');
    if (defaultLayoutId === floorId) {
      localStorage.removeItem('dineInLayoutToDefaultTo');
    }
    handleClearSelectedShape();
    resetNewFloorObjects();
    removeSingleFloorLayoutsAndItems(floorId);
    layoutEndLoaderTimeout();
  };

  const handleDeleteFloorLayoutClick = (floorId) => {
    handleGlobalModal({
      title: 'Are you sure you want to delete this floor?',
      message:
        'Please click on Delete Floor to proceed. Once deleted, you will not be able to recover any of the tables',
      actionButtonLabel: 'Delete Floor',
      actionButtonHandler: () => handleDeleteFloorLayout(floorId),
      actionButtonColor: 'red',
      dismissButtonLabel: 'Cancel',
      dismissButtonHandler: () => {},
    });
  };

  const handleMove = (params) => {
    dispatch(setState({ hoverTableId: params.dineInObjectId }));

    handleGlobalModal({
      title: 'Move Table?',
      message:
        'This will end the current sitting and start the same sitting on a new table.',
      actionButtonLabel: 'Yes',
      actionButtonHandler: () => {
        move(params);
        setSelectedFloorId(params.floorLayoutId);
      },
      dismissButtonLabel: 'No',
      dismissButtonColor: 'grey',
    });
  };

  const allObjectsMap = floorLayouts
    .flatMap(({ dineInObjects, id }) =>
      dineInObjects.map((obj) => ({ ...obj, layoutId: id }))
    )
    .reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

  const availableTables = dineInObjects
    .filter(({ isOccupied }) => !isOccupied)
    .map(({ dineInObjectId }) => allObjectsMap[dineInObjectId])
    .filter(Boolean);

  const availableTablesMap = availableTables.reduce(
    (a, k) => ({ ...a, [k.id]: k }),
    {}
  );

  const endOccupancy = async (occupancyId) => {
    const res = await endOccupancyAPI(occupancyId, accessToken);

    if (res) {
      handleTableUpdate(selectedShape, { isOccupied: false });
      handleShapeSelect(null);
    }
  };

  // const [_, request] = useApi('PUT', `${API_URL}/orders/v1/order/moveDineIn`);

  const move = async ({
    dineInObjectId,
    floorLayoutId,
    dineInObjectNumber,
  }) => {
    dispatch(
      setState({
        movingOrder: false,
      })
    );

    await endOccupancy(selectedShape.occupancyId);

    const staffId = activeStaffMember?._id || '';

    const res = await startOccupancyAPI(
      storeId,
      floorLayoutId,
      staffId,
      [dineInObjectId],
      Number(1),
      accessToken
    );

    if (res?.data?.dineInOccupancy) {
      const payload = {
        dineInObjectId,
        floorLayoutId,
        occupancyId: res.data.dineInOccupancy.id,
        orderId: selectedShape.currentOrderId,
        dineInObjectNumber,
      };

      await axiosWithInterceptor.put(
        `${API_URL}/orders/v1/order/moveDineIn`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      );
      // request(payload);

      handleTableUpdate(availableTablesMap[dineInObjectId], {
        layoutId: floorLayoutId,
        currentOrderId: selectedShape.currentOrderId,
        isOccupied: true,
        objectType: 'dineInObjects',
        occupancyId: res.data.dineInOccupancy.id,
      });
    }
  };

  return (
    <div className="table-arrangement">
      {pending && (
        <div className="loader">
          {showRefresh && (
            <div className="loader__refresh">
              <ButtonUI
                icon={
                  <PosIconReload
                    mainColor="white"
                    style={{ width: 18, height: 18 }}
                  />
                }
                iconPosition="left"
                styleType="warning"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </ButtonUI>
            </div>
          )}
          <div className="loader__inner">
            <PosIconLoading mainColor="white" />
          </div>
        </div>
      )}

      {objectsProcessing && <div className="objects-loader" />}

      <Header
        hasNoFloors={floorLayouts.length === 0}
        pending={layoutLoading}
        editMode={editMode}
        handleModeToggle={handleModeToggle}
        handleSaveLayout={handleSaveLayout}
        handleAddFloorClick={handleAddFloorClick}
        totalNumPeople={totalNumPeople}
      />

      <div className="table-arrangement__wrapper">
        <>
          <aside className="table-arrangement__side-panel">
            <SidePanel
              editMode={editMode}
              selectedShape={selectedShape}
              objectsToRender={objectsToRender.current}
              currentLayout={selectedFloorLayout}
              floorsToRender={floorsToRender}
              setSelectedFloorId={setSelectedFloorId}
              handleShapeSelect={handleShapeSelect}
              handleTableUpdate={handleTableUpdate}
              handleShapeInsert={handleShapeInsert}
              handleModeToggle={handleModeToggle}
              handleMove={handleMove}
              handleShapeRemove={handleShapeRemove}
              handleClearSelectedShape={handleClearSelectedShape}
              availableTableNums={availableTableNums.current}
            />
          </aside>

          {!pending && (
            <main
              className="table-arrangement__layout-area dine-in-blur-area"
              ref={layoutArea}
            >
              <FloorLayout
                editMode={editMode}
                objectsToRender={objectsToRender.current}
                selectedShape={selectedShape}
                layoutLoading={layoutLoading}
                handleDragEnd={handleDragEnd}
                handleShapeSelect={handleShapeSelect}
                handleClearSelectedShape={handleClearSelectedShape}
                floorsForSwitchTabs={floorsForSwitchTabs}
                selectedFloorId={selectedFloorId}
                handleFloorTabSelection={handleFloorTabSelection}
                handleTabSelectionInEditMode={handleTabSelectionInEditMode}
                handleTabDoubleSelectionInEditMode={
                  handleTabDoubleSelectionInEditMode
                }
                handleMove={handleMove}
                handleReorder={handleReorder}
                handleDeleteFloorLayoutClick={handleDeleteFloorLayoutClick}
              />
            </main>
          )}
        </>
      </div>

      <AddFloorModal
        modalStatus={addFloorModalStatus}
        onModalClose={() => {
          setFloorLayoutNameToEdit('');
          setAddFloorModalStatus(false);
        }}
        onNextClick={floorLayoutNameToEdit ? updateFloorName : createLayout}
        onBackClick={modalBackButtonClick}
        createFloorLayoutLoading={createFloorLayoutLoading}
        currentFloorName={floorLayoutNameToEdit}
      />
    </div>
  );
};

export default TableArrangement;
