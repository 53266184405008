import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PosIconPlusCircle = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-circle-plus': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props} style={{width: 25, height: 25}}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
        <title>Plus-Circle</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M13,12h5v1H13v5H12V13H7V12h5V7h1Zm12,.5A12.5,12.5,0,1,1,12.5,0,12.51,12.51,0,0,1,25,12.5Zm-1,0A11.5,11.5,0,1,0,12.5,24,11.51,11.51,0,0,0,24,12.5Z"
        />
      </svg>
    </span>
  );
};

PosIconPlusCircle.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};

export default PosIconPlusCircle;