// source: src/notifications/proto/notifications.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.notification.NotificationRequest', null, global);
goog.exportSymbol('proto.notification.NotificationRequest.FilterParam', null, global);
goog.exportSymbol('proto.notification.Notifications', null, global);
goog.exportSymbol('proto.notification.Notifications.DineInCourseChange', null, global);
goog.exportSymbol('proto.notification.Notifications.DineInObjectStatus', null, global);
goog.exportSymbol('proto.notification.Notifications.FloorLayoutChange', null, global);
goog.exportSymbol('proto.notification.Notifications.FloorLayoutChange.DineInObject', null, global);
goog.exportSymbol('proto.notification.Notifications.FloorLayoutChange.LayoutDivider', null, global);
goog.exportSymbol('proto.notification.Notifications.FloorLayoutChange.NonDineInObject', null, global);
goog.exportSymbol('proto.notification.Notifications.IncomingCallChange', null, global);
goog.exportSymbol('proto.notification.Notifications.IncomingCallChange.IncomingCallStatus', null, global);
goog.exportSymbol('proto.notification.Notifications.MessageType', null, global);
goog.exportSymbol('proto.notification.Notifications.OperationType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.NotificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notification.NotificationRequest.repeatedFields_, null);
};
goog.inherits(proto.notification.NotificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.NotificationRequest.displayName = 'proto.notification.NotificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.NotificationRequest.FilterParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.NotificationRequest.FilterParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.NotificationRequest.FilterParam.displayName = 'proto.notification.NotificationRequest.FilterParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.Notifications = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notification.Notifications.repeatedFields_, null);
};
goog.inherits(proto.notification.Notifications, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.Notifications.displayName = 'proto.notification.Notifications';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.Notifications.DineInObjectStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.Notifications.DineInObjectStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.Notifications.DineInObjectStatus.displayName = 'proto.notification.Notifications.DineInObjectStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.Notifications.FloorLayoutChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notification.Notifications.FloorLayoutChange.repeatedFields_, null);
};
goog.inherits(proto.notification.Notifications.FloorLayoutChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.Notifications.FloorLayoutChange.displayName = 'proto.notification.Notifications.FloorLayoutChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.Notifications.FloorLayoutChange.DineInObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.Notifications.FloorLayoutChange.DineInObject.displayName = 'proto.notification.Notifications.FloorLayoutChange.DineInObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.Notifications.FloorLayoutChange.LayoutDivider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.Notifications.FloorLayoutChange.LayoutDivider.displayName = 'proto.notification.Notifications.FloorLayoutChange.LayoutDivider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.Notifications.FloorLayoutChange.NonDineInObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.Notifications.FloorLayoutChange.NonDineInObject.displayName = 'proto.notification.Notifications.FloorLayoutChange.NonDineInObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.Notifications.DineInCourseChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.Notifications.DineInCourseChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.Notifications.DineInCourseChange.displayName = 'proto.notification.Notifications.DineInCourseChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.Notifications.IncomingCallChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.Notifications.IncomingCallChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.Notifications.IncomingCallChange.displayName = 'proto.notification.Notifications.IncomingCallChange';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notification.NotificationRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.NotificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.NotificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.NotificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.NotificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    filterParamsList: jspb.Message.toObjectList(msg.getFilterParamsList(),
    proto.notification.NotificationRequest.FilterParam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.NotificationRequest}
 */
proto.notification.NotificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.NotificationRequest;
  return proto.notification.NotificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.NotificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.NotificationRequest}
 */
proto.notification.NotificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotificationTypes(value);
      break;
    case 2:
      var value = new proto.notification.NotificationRequest.FilterParam;
      reader.readMessage(value,proto.notification.NotificationRequest.FilterParam.deserializeBinaryFromReader);
      msg.addFilterParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.NotificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.NotificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.NotificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.NotificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFilterParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.notification.NotificationRequest.FilterParam.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.NotificationRequest.FilterParam.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.NotificationRequest.FilterParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.NotificationRequest.FilterParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.NotificationRequest.FilterParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    paramName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    strValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    intValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    floatValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.NotificationRequest.FilterParam}
 */
proto.notification.NotificationRequest.FilterParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.NotificationRequest.FilterParam;
  return proto.notification.NotificationRequest.FilterParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.NotificationRequest.FilterParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.NotificationRequest.FilterParam}
 */
proto.notification.NotificationRequest.FilterParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIntValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.NotificationRequest.FilterParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.NotificationRequest.FilterParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.NotificationRequest.FilterParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.NotificationRequest.FilterParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParamName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStrValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntValue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFloatValue();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getBoolValue();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string param_name = 1;
 * @return {string}
 */
proto.notification.NotificationRequest.FilterParam.prototype.getParamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.NotificationRequest.FilterParam} returns this
 */
proto.notification.NotificationRequest.FilterParam.prototype.setParamName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string str_value = 2;
 * @return {string}
 */
proto.notification.NotificationRequest.FilterParam.prototype.getStrValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.NotificationRequest.FilterParam} returns this
 */
proto.notification.NotificationRequest.FilterParam.prototype.setStrValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 int_value = 3;
 * @return {number}
 */
proto.notification.NotificationRequest.FilterParam.prototype.getIntValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.NotificationRequest.FilterParam} returns this
 */
proto.notification.NotificationRequest.FilterParam.prototype.setIntValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float float_value = 4;
 * @return {number}
 */
proto.notification.NotificationRequest.FilterParam.prototype.getFloatValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.NotificationRequest.FilterParam} returns this
 */
proto.notification.NotificationRequest.FilterParam.prototype.setFloatValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool bool_value = 5;
 * @return {boolean}
 */
proto.notification.NotificationRequest.FilterParam.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notification.NotificationRequest.FilterParam} returns this
 */
proto.notification.NotificationRequest.FilterParam.prototype.setBoolValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated string notification_types = 1;
 * @return {!Array<string>}
 */
proto.notification.NotificationRequest.prototype.getNotificationTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.notification.NotificationRequest} returns this
 */
proto.notification.NotificationRequest.prototype.setNotificationTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.notification.NotificationRequest} returns this
 */
proto.notification.NotificationRequest.prototype.addNotificationTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.NotificationRequest} returns this
 */
proto.notification.NotificationRequest.prototype.clearNotificationTypesList = function() {
  return this.setNotificationTypesList([]);
};


/**
 * repeated FilterParam filter_params = 2;
 * @return {!Array<!proto.notification.NotificationRequest.FilterParam>}
 */
proto.notification.NotificationRequest.prototype.getFilterParamsList = function() {
  return /** @type{!Array<!proto.notification.NotificationRequest.FilterParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.NotificationRequest.FilterParam, 2));
};


/**
 * @param {!Array<!proto.notification.NotificationRequest.FilterParam>} value
 * @return {!proto.notification.NotificationRequest} returns this
*/
proto.notification.NotificationRequest.prototype.setFilterParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.notification.NotificationRequest.FilterParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.NotificationRequest.FilterParam}
 */
proto.notification.NotificationRequest.prototype.addFilterParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.notification.NotificationRequest.FilterParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.NotificationRequest} returns this
 */
proto.notification.NotificationRequest.prototype.clearFilterParamsList = function() {
  return this.setFilterParamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notification.Notifications.repeatedFields_ = [2,3,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.Notifications.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.Notifications.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.Notifications} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    notificationTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dineInObjectStatusesList: jspb.Message.toObjectList(msg.getDineInObjectStatusesList(),
    proto.notification.Notifications.DineInObjectStatus.toObject, includeInstance),
    floorLayoutChangesList: jspb.Message.toObjectList(msg.getFloorLayoutChangesList(),
    proto.notification.Notifications.FloorLayoutChange.toObject, includeInstance),
    dineInCourseChangesList: jspb.Message.toObjectList(msg.getDineInCourseChangesList(),
    proto.notification.Notifications.DineInCourseChange.toObject, includeInstance),
    incomingCallChangesList: jspb.Message.toObjectList(msg.getIncomingCallChangesList(),
    proto.notification.Notifications.IncomingCallChange.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.Notifications}
 */
proto.notification.Notifications.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.Notifications;
  return proto.notification.Notifications.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.Notifications} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.Notifications}
 */
proto.notification.Notifications.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.notification.Notifications.MessageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotificationTypes(value);
      break;
    case 3:
      var value = new proto.notification.Notifications.DineInObjectStatus;
      reader.readMessage(value,proto.notification.Notifications.DineInObjectStatus.deserializeBinaryFromReader);
      msg.addDineInObjectStatuses(value);
      break;
    case 5:
      var value = new proto.notification.Notifications.FloorLayoutChange;
      reader.readMessage(value,proto.notification.Notifications.FloorLayoutChange.deserializeBinaryFromReader);
      msg.addFloorLayoutChanges(value);
      break;
    case 6:
      var value = new proto.notification.Notifications.DineInCourseChange;
      reader.readMessage(value,proto.notification.Notifications.DineInCourseChange.deserializeBinaryFromReader);
      msg.addDineInCourseChanges(value);
      break;
    case 7:
      var value = new proto.notification.Notifications.IncomingCallChange;
      reader.readMessage(value,proto.notification.Notifications.IncomingCallChange.deserializeBinaryFromReader);
      msg.addIncomingCallChanges(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.Notifications.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.Notifications.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.Notifications} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNotificationTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDineInObjectStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.notification.Notifications.DineInObjectStatus.serializeBinaryToWriter
    );
  }
  f = message.getFloorLayoutChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.notification.Notifications.FloorLayoutChange.serializeBinaryToWriter
    );
  }
  f = message.getDineInCourseChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.notification.Notifications.DineInCourseChange.serializeBinaryToWriter
    );
  }
  f = message.getIncomingCallChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.notification.Notifications.IncomingCallChange.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.notification.Notifications.MessageType = {
  ACK: 0,
  ERR: 1,
  NOTIFICATION: 2
};

/**
 * @enum {number}
 */
proto.notification.Notifications.OperationType = {
  UNKNOWN: 0,
  CREATED: 1,
  UPDATED: 2,
  DELETED: 3,
  NO_CHANGE: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.Notifications.DineInObjectStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.Notifications.DineInObjectStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.Notifications.DineInObjectStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.DineInObjectStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    layoutId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dineInObjectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isOccupied: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currentOccupancy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currentOrder: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.Notifications.DineInObjectStatus}
 */
proto.notification.Notifications.DineInObjectStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.Notifications.DineInObjectStatus;
  return proto.notification.Notifications.DineInObjectStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.Notifications.DineInObjectStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.Notifications.DineInObjectStatus}
 */
proto.notification.Notifications.DineInObjectStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLayoutId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDineInObjectId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOccupied(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentOccupancy(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.Notifications.DineInObjectStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.Notifications.DineInObjectStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.Notifications.DineInObjectStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.DineInObjectStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLayoutId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDineInObjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsOccupied();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrentOccupancy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrentOrder();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string layout_id = 1;
 * @return {string}
 */
proto.notification.Notifications.DineInObjectStatus.prototype.getLayoutId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInObjectStatus} returns this
 */
proto.notification.Notifications.DineInObjectStatus.prototype.setLayoutId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dine_in_object_id = 2;
 * @return {string}
 */
proto.notification.Notifications.DineInObjectStatus.prototype.getDineInObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInObjectStatus} returns this
 */
proto.notification.Notifications.DineInObjectStatus.prototype.setDineInObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_occupied = 3;
 * @return {boolean}
 */
proto.notification.Notifications.DineInObjectStatus.prototype.getIsOccupied = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notification.Notifications.DineInObjectStatus} returns this
 */
proto.notification.Notifications.DineInObjectStatus.prototype.setIsOccupied = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.notification.Notifications.DineInObjectStatus.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInObjectStatus} returns this
 */
proto.notification.Notifications.DineInObjectStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string current_occupancy = 5;
 * @return {string}
 */
proto.notification.Notifications.DineInObjectStatus.prototype.getCurrentOccupancy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInObjectStatus} returns this
 */
proto.notification.Notifications.DineInObjectStatus.prototype.setCurrentOccupancy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string current_order = 6;
 * @return {string}
 */
proto.notification.Notifications.DineInObjectStatus.prototype.getCurrentOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInObjectStatus} returns this
 */
proto.notification.Notifications.DineInObjectStatus.prototype.setCurrentOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notification.Notifications.FloorLayoutChange.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.Notifications.FloorLayoutChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.Notifications.FloorLayoutChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.FloorLayoutChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    operationType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dineInObjectsList: jspb.Message.toObjectList(msg.getDineInObjectsList(),
    proto.notification.Notifications.FloorLayoutChange.DineInObject.toObject, includeInstance),
    layoutDividersList: jspb.Message.toObjectList(msg.getLayoutDividersList(),
    proto.notification.Notifications.FloorLayoutChange.LayoutDivider.toObject, includeInstance),
    nonDineInObjectsList: jspb.Message.toObjectList(msg.getNonDineInObjectsList(),
    proto.notification.Notifications.FloorLayoutChange.NonDineInObject.toObject, includeInstance),
    displayOrder: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.Notifications.FloorLayoutChange}
 */
proto.notification.Notifications.FloorLayoutChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.Notifications.FloorLayoutChange;
  return proto.notification.Notifications.FloorLayoutChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.Notifications.FloorLayoutChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.Notifications.FloorLayoutChange}
 */
proto.notification.Notifications.FloorLayoutChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 4:
      var value = /** @type {!proto.notification.Notifications.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 5:
      var value = new proto.notification.Notifications.FloorLayoutChange.DineInObject;
      reader.readMessage(value,proto.notification.Notifications.FloorLayoutChange.DineInObject.deserializeBinaryFromReader);
      msg.addDineInObjects(value);
      break;
    case 6:
      var value = new proto.notification.Notifications.FloorLayoutChange.LayoutDivider;
      reader.readMessage(value,proto.notification.Notifications.FloorLayoutChange.LayoutDivider.deserializeBinaryFromReader);
      msg.addLayoutDividers(value);
      break;
    case 7:
      var value = new proto.notification.Notifications.FloorLayoutChange.NonDineInObject;
      reader.readMessage(value,proto.notification.Notifications.FloorLayoutChange.NonDineInObject.deserializeBinaryFromReader);
      msg.addNonDineInObjects(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDisplayOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.Notifications.FloorLayoutChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.Notifications.FloorLayoutChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.FloorLayoutChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDineInObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.notification.Notifications.FloorLayoutChange.DineInObject.serializeBinaryToWriter
    );
  }
  f = message.getLayoutDividersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.notification.Notifications.FloorLayoutChange.LayoutDivider.serializeBinaryToWriter
    );
  }
  f = message.getNonDineInObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.notification.Notifications.FloorLayoutChange.NonDineInObject.serializeBinaryToWriter
    );
  }
  f = message.getDisplayOrder();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.Notifications.FloorLayoutChange.DineInObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.Notifications.FloorLayoutChange.DineInObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    number: jspb.Message.getFieldWithDefault(msg, 5, ""),
    numSeats: jspb.Message.getFieldWithDefault(msg, 6, 0),
    positionX: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    positionY: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    orientationAngle: jspb.Message.getFieldWithDefault(msg, 9, 0),
    scale: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.Notifications.FloorLayoutChange.DineInObject;
  return proto.notification.Notifications.FloorLayoutChange.DineInObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.Notifications.FloorLayoutChange.DineInObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.notification.Notifications.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumSeats(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionX(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionY(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrientationAngle(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.Notifications.FloorLayoutChange.DineInObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.Notifications.FloorLayoutChange.DineInObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumSeats();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPositionX();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getPositionY();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOrientationAngle();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
};


/**
 * optional OperationType operation_type = 1;
 * @return {!proto.notification.Notifications.OperationType}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getOperationType = function() {
  return /** @type {!proto.notification.Notifications.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.notification.Notifications.OperationType} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string number = 5;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 num_seats = 6;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getNumSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setNumSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double position_x = 7;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getPositionX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setPositionX = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double position_y = 8;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getPositionY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setPositionY = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int64 orientation_angle = 9;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getOrientationAngle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setOrientationAngle = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double scale = 10;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.DineInObject.prototype.setScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.Notifications.FloorLayoutChange.LayoutDivider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startX: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    startY: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    endX: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    endY: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    colour: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.Notifications.FloorLayoutChange.LayoutDivider;
  return proto.notification.Notifications.FloorLayoutChange.LayoutDivider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.notification.Notifications.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartX(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStartY(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndX(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndY(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setColour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.Notifications.FloorLayoutChange.LayoutDivider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartX();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getStartY();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getEndX();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getEndY();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getColour();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional OperationType operation_type = 1;
 * @return {!proto.notification.Notifications.OperationType}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.getOperationType = function() {
  return /** @type {!proto.notification.Notifications.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.notification.Notifications.OperationType} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} returns this
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} returns this
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} returns this
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double start_x = 4;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.getStartX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} returns this
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.setStartX = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double start_y = 5;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.getStartY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} returns this
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.setStartY = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double end_x = 6;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.getEndX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} returns this
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.setEndX = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double end_y = 7;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.getEndY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} returns this
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.setEndY = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string colour = 8;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.getColour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider} returns this
 */
proto.notification.Notifications.FloorLayoutChange.LayoutDivider.prototype.setColour = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.Notifications.FloorLayoutChange.NonDineInObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    positionX: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    positionY: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    scale: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    orientationAngle: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.Notifications.FloorLayoutChange.NonDineInObject;
  return proto.notification.Notifications.FloorLayoutChange.NonDineInObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.notification.Notifications.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionX(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionY(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScale(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrientationAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.Notifications.FloorLayoutChange.NonDineInObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPositionX();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPositionY();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getOrientationAngle();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional OperationType operation_type = 1;
 * @return {!proto.notification.Notifications.OperationType}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.getOperationType = function() {
  return /** @type {!proto.notification.Notifications.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.notification.Notifications.OperationType} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double position_x = 4;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.getPositionX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.setPositionX = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double position_y = 5;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.getPositionY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.setPositionY = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double scale = 6;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.setScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 orientation_angle = 7;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.getOrientationAngle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject} returns this
 */
proto.notification.Notifications.FloorLayoutChange.NonDineInObject.prototype.setOrientationAngle = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
 */
proto.notification.Notifications.FloorLayoutChange.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
 */
proto.notification.Notifications.FloorLayoutChange.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_active = 3;
 * @return {boolean}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
 */
proto.notification.Notifications.FloorLayoutChange.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional OperationType operation_type = 4;
 * @return {!proto.notification.Notifications.OperationType}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.getOperationType = function() {
  return /** @type {!proto.notification.Notifications.OperationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.notification.Notifications.OperationType} value
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
 */
proto.notification.Notifications.FloorLayoutChange.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated DineInObject dine_in_objects = 5;
 * @return {!Array<!proto.notification.Notifications.FloorLayoutChange.DineInObject>}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.getDineInObjectsList = function() {
  return /** @type{!Array<!proto.notification.Notifications.FloorLayoutChange.DineInObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.Notifications.FloorLayoutChange.DineInObject, 5));
};


/**
 * @param {!Array<!proto.notification.Notifications.FloorLayoutChange.DineInObject>} value
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
*/
proto.notification.Notifications.FloorLayoutChange.prototype.setDineInObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.notification.Notifications.FloorLayoutChange.DineInObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.Notifications.FloorLayoutChange.DineInObject}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.addDineInObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.notification.Notifications.FloorLayoutChange.DineInObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
 */
proto.notification.Notifications.FloorLayoutChange.prototype.clearDineInObjectsList = function() {
  return this.setDineInObjectsList([]);
};


/**
 * repeated LayoutDivider layout_dividers = 6;
 * @return {!Array<!proto.notification.Notifications.FloorLayoutChange.LayoutDivider>}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.getLayoutDividersList = function() {
  return /** @type{!Array<!proto.notification.Notifications.FloorLayoutChange.LayoutDivider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.Notifications.FloorLayoutChange.LayoutDivider, 6));
};


/**
 * @param {!Array<!proto.notification.Notifications.FloorLayoutChange.LayoutDivider>} value
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
*/
proto.notification.Notifications.FloorLayoutChange.prototype.setLayoutDividersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.Notifications.FloorLayoutChange.LayoutDivider}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.addLayoutDividers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.notification.Notifications.FloorLayoutChange.LayoutDivider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
 */
proto.notification.Notifications.FloorLayoutChange.prototype.clearLayoutDividersList = function() {
  return this.setLayoutDividersList([]);
};


/**
 * repeated NonDineInObject non_dine_in_objects = 7;
 * @return {!Array<!proto.notification.Notifications.FloorLayoutChange.NonDineInObject>}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.getNonDineInObjectsList = function() {
  return /** @type{!Array<!proto.notification.Notifications.FloorLayoutChange.NonDineInObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.Notifications.FloorLayoutChange.NonDineInObject, 7));
};


/**
 * @param {!Array<!proto.notification.Notifications.FloorLayoutChange.NonDineInObject>} value
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
*/
proto.notification.Notifications.FloorLayoutChange.prototype.setNonDineInObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.Notifications.FloorLayoutChange.NonDineInObject}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.addNonDineInObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.notification.Notifications.FloorLayoutChange.NonDineInObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
 */
proto.notification.Notifications.FloorLayoutChange.prototype.clearNonDineInObjectsList = function() {
  return this.setNonDineInObjectsList([]);
};


/**
 * optional int64 display_order = 8;
 * @return {number}
 */
proto.notification.Notifications.FloorLayoutChange.prototype.getDisplayOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.FloorLayoutChange} returns this
 */
proto.notification.Notifications.FloorLayoutChange.prototype.setDisplayOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.Notifications.DineInCourseChange.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.Notifications.DineInCourseChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.Notifications.DineInCourseChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.DineInCourseChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateTimestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    restaurantId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    storeId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.Notifications.DineInCourseChange}
 */
proto.notification.Notifications.DineInCourseChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.Notifications.DineInCourseChange;
  return proto.notification.Notifications.DineInCourseChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.Notifications.DineInCourseChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.Notifications.DineInCourseChange}
 */
proto.notification.Notifications.DineInCourseChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.notification.Notifications.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateTimestamp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRestaurantId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.Notifications.DineInCourseChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.Notifications.DineInCourseChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.Notifications.DineInCourseChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.DineInCourseChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRestaurantId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStoreId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional OperationType operation_type = 1;
 * @return {!proto.notification.Notifications.OperationType}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getOperationType = function() {
  return /** @type {!proto.notification.Notifications.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.notification.Notifications.OperationType} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 date_timestamp = 4;
 * @return {number}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getDateTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setDateTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool is_active = 6;
 * @return {boolean}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string restaurant_id = 8;
 * @return {string}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getRestaurantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setRestaurantId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string store_id = 9;
 * @return {string}
 */
proto.notification.Notifications.DineInCourseChange.prototype.getStoreId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.DineInCourseChange} returns this
 */
proto.notification.Notifications.DineInCourseChange.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.Notifications.IncomingCallChange.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.Notifications.IncomingCallChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.Notifications.IncomingCallChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.IncomingCallChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lineId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lineName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originatedTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    acceptedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    acceptedByStaffId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    acceptedByStaffName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    storeId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    restaurantId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.Notifications.IncomingCallChange}
 */
proto.notification.Notifications.IncomingCallChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.Notifications.IncomingCallChange;
  return proto.notification.Notifications.IncomingCallChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.Notifications.IncomingCallChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.Notifications.IncomingCallChange}
 */
proto.notification.Notifications.IncomingCallChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOriginatedTime(value);
      break;
    case 5:
      var value = /** @type {!proto.notification.Notifications.IncomingCallChange.IncomingCallStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcceptedByStaffId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcceptedByStaffName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoreId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRestaurantId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.Notifications.IncomingCallChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.Notifications.IncomingCallChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.Notifications.IncomingCallChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.Notifications.IncomingCallChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLineId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLineName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginatedTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAcceptedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getAcceptedByStaffId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAcceptedByStaffName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStoreId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRestaurantId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.notification.Notifications.IncomingCallChange.IncomingCallStatus = {
  RECEIVED: 0,
  ACCEPTED: 1,
  COMPLETED: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string line_id = 2;
 * @return {string}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getLineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setLineId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string line_name = 3;
 * @return {string}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getLineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setLineName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 originated_time = 4;
 * @return {number}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getOriginatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setOriginatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional IncomingCallStatus status = 5;
 * @return {!proto.notification.Notifications.IncomingCallChange.IncomingCallStatus}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getStatus = function() {
  return /** @type {!proto.notification.Notifications.IncomingCallChange.IncomingCallStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.notification.Notifications.IncomingCallChange.IncomingCallStatus} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 accepted_at = 6;
 * @return {number}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getAcceptedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setAcceptedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string accepted_by_staff_id = 7;
 * @return {string}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getAcceptedByStaffId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setAcceptedByStaffId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string accepted_by_staff_name = 8;
 * @return {string}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getAcceptedByStaffName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setAcceptedByStaffName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string store_id = 9;
 * @return {string}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getStoreId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string restaurant_id = 10;
 * @return {string}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getRestaurantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setRestaurantId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string phone = 11;
 * @return {string}
 */
proto.notification.Notifications.IncomingCallChange.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications.IncomingCallChange} returns this
 */
proto.notification.Notifications.IncomingCallChange.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional MessageType type = 1;
 * @return {!proto.notification.Notifications.MessageType}
 */
proto.notification.Notifications.prototype.getType = function() {
  return /** @type {!proto.notification.Notifications.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.notification.Notifications.MessageType} value
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string notification_types = 2;
 * @return {!Array<string>}
 */
proto.notification.Notifications.prototype.getNotificationTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.setNotificationTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.addNotificationTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.clearNotificationTypesList = function() {
  return this.setNotificationTypesList([]);
};


/**
 * repeated DineInObjectStatus dine_in_object_statuses = 3;
 * @return {!Array<!proto.notification.Notifications.DineInObjectStatus>}
 */
proto.notification.Notifications.prototype.getDineInObjectStatusesList = function() {
  return /** @type{!Array<!proto.notification.Notifications.DineInObjectStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.Notifications.DineInObjectStatus, 3));
};


/**
 * @param {!Array<!proto.notification.Notifications.DineInObjectStatus>} value
 * @return {!proto.notification.Notifications} returns this
*/
proto.notification.Notifications.prototype.setDineInObjectStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.notification.Notifications.DineInObjectStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.Notifications.DineInObjectStatus}
 */
proto.notification.Notifications.prototype.addDineInObjectStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.notification.Notifications.DineInObjectStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.clearDineInObjectStatusesList = function() {
  return this.setDineInObjectStatusesList([]);
};


/**
 * repeated FloorLayoutChange floor_layout_changes = 5;
 * @return {!Array<!proto.notification.Notifications.FloorLayoutChange>}
 */
proto.notification.Notifications.prototype.getFloorLayoutChangesList = function() {
  return /** @type{!Array<!proto.notification.Notifications.FloorLayoutChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.Notifications.FloorLayoutChange, 5));
};


/**
 * @param {!Array<!proto.notification.Notifications.FloorLayoutChange>} value
 * @return {!proto.notification.Notifications} returns this
*/
proto.notification.Notifications.prototype.setFloorLayoutChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.notification.Notifications.FloorLayoutChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.Notifications.FloorLayoutChange}
 */
proto.notification.Notifications.prototype.addFloorLayoutChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.notification.Notifications.FloorLayoutChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.clearFloorLayoutChangesList = function() {
  return this.setFloorLayoutChangesList([]);
};


/**
 * repeated DineInCourseChange dine_in_course_changes = 6;
 * @return {!Array<!proto.notification.Notifications.DineInCourseChange>}
 */
proto.notification.Notifications.prototype.getDineInCourseChangesList = function() {
  return /** @type{!Array<!proto.notification.Notifications.DineInCourseChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.Notifications.DineInCourseChange, 6));
};


/**
 * @param {!Array<!proto.notification.Notifications.DineInCourseChange>} value
 * @return {!proto.notification.Notifications} returns this
*/
proto.notification.Notifications.prototype.setDineInCourseChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.notification.Notifications.DineInCourseChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.Notifications.DineInCourseChange}
 */
proto.notification.Notifications.prototype.addDineInCourseChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.notification.Notifications.DineInCourseChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.clearDineInCourseChangesList = function() {
  return this.setDineInCourseChangesList([]);
};


/**
 * repeated IncomingCallChange incoming_call_changes = 7;
 * @return {!Array<!proto.notification.Notifications.IncomingCallChange>}
 */
proto.notification.Notifications.prototype.getIncomingCallChangesList = function() {
  return /** @type{!Array<!proto.notification.Notifications.IncomingCallChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.Notifications.IncomingCallChange, 7));
};


/**
 * @param {!Array<!proto.notification.Notifications.IncomingCallChange>} value
 * @return {!proto.notification.Notifications} returns this
*/
proto.notification.Notifications.prototype.setIncomingCallChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.notification.Notifications.IncomingCallChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.Notifications.IncomingCallChange}
 */
proto.notification.Notifications.prototype.addIncomingCallChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.notification.Notifications.IncomingCallChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.clearIncomingCallChangesList = function() {
  return this.setIncomingCallChangesList([]);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.notification.Notifications.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.Notifications} returns this
 */
proto.notification.Notifications.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.notification);
