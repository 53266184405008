import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconRightChevron = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-right-chevron': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="23"
        viewBox="0 0 14 23"
      >
        <g>
          <g transform="rotate(270 7 11.5)">
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M6.99 17a.544.544 0 0 1-.372-.152L-4 6.719-3.246 6 7 15.774 17.246 6l.754.72L7.382 16.847A.544.544 0 0 1 6.99 17z"
            />
            <path
              fill="none"
              stroke={iconColor ? iconColor : '#000'}
              strokeMiterlimit="20"
              strokeWidth={lineThickness ? lineThickness * 0.8 : 0.8}
              d="M6.99 17a.544.544 0 0 1-.372-.152L-4 6.719v0L-3.246 6v0L7 15.774v0L17.246 6v0l.754.72v0L7.382 16.847A.544.544 0 0 1 6.99 17z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconRightChevron.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
