import Lottie from 'react-lottie';
import paymentFailed from '../../assets/lotties/16305-payment-failed.json';

const PaymentModalFailScreen = ({
  handleRetryIntegratedTerminal = () => {},
}) => {
  const paymentFailedLottie = {
    loop: false,
    autoplay: true,
    animationData: paymentFailed,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <Lottie
        options={paymentFailedLottie}
        height={192}
        width={192}
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => {},
          },
        ]}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className="retry-btn" onClick={handleRetryIntegratedTerminal}>
          Re-try
        </div>
      </div>
    </>
  );
};

export default PaymentModalFailScreen;
