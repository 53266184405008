import cookie from 'js-cookie';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchServiceCharges } from 'store/serviceCharges/serviceChargesActions';
import {
  AUTH_COOKIE_EXPIRATION_DAYS,
  AUTH_COOKIE_TOKEN_NAME,
} from '../../configuration/constants';
import { _auth } from '../../firebase';

export const authContext = createContext();

const formatUser = async (user) => {
  const token = await user.getIdToken();
  return {
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    provider: user.providerData?.[0]?.providerId,
    photoUrl: user.photoURL,
    token,
  };
};

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(fetchServiceCharges());
    }
  }, [user?.uid, dispatch]);

  const handleUser = async (rawUser) => {
    if (rawUser) {
      const user = await formatUser(rawUser);

      setUser(user);

      cookie.set(AUTH_COOKIE_TOKEN_NAME, true, {
        expires: AUTH_COOKIE_EXPIRATION_DAYS,
      });

      setLoading(false);
      return user;
    } else {
      setUser(false);
      cookie.remove(AUTH_COOKIE_TOKEN_NAME);

      setLoading(false);
      return false;
    }
  };

  const signinWithToken = (token) => {
    setLoading(true);
    return _auth
      .signInWithCustomToken(token)
      .then((response) => {
        handleUser(response.user);

        // 🦢 route push
        // Router.push('/sites');
      })
      .catch((e) => {
        // handle error
      });
  };

  const signout = () => {
    // 🦢router push
    // Router.push('/');

    return _auth.signOut().then(() => handleUser(false));
  };

  useEffect(() => {
    const unsubscribeIdTokenChanges = _auth.onIdTokenChanged(handleUser);

    // const unsubAuthStateChanges = _auth.onAuthStateChanged(user => {
    //   // setCurrentUser(user)
    //   // setLoading(false)
    // })

    return () => {
      unsubscribeIdTokenChanges();
      // unsubAuthStateChanges();
    };
  }, []);

  return {
    user,
    loading,
    signinWithToken,
    signout,
  };
}
