import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconTable = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-table': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
      >
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M24.95,14.28l-4-8A.5.5,0,0,0,20.5,6h-.63c0-.27,0-.53,0-.83A3,3,0,0,0,17,2a3,3,0,0,0-2.9,3.15c0,.29,0,.56,0,.83H10.87c0-.27,0-.53,0-.83A3,3,0,0,0,8,2,3,3,0,0,0,5.1,5.17c0,.29,0,.56,0,.83H4.5a.5.5,0,0,0-.45.28l-4,8A.5.5,0,0,0,.5,15H2v8H3V15H4.74a16.19,16.19,0,0,0,.43,2H4.5a.5.5,0,0,0-.5.5V23H5V18h6v5h1V17.5a.5.5,0,0,0-.5-.5h-.67a16.19,16.19,0,0,0,.43-2h2.48a16.19,16.19,0,0,0,.43,2H13.5a.5.5,0,0,0-.5.5V23h1V18h6v5h1V17.5a.5.5,0,0,0-.5-.5h-.67a16.19,16.19,0,0,0,.43-2H22v8h1V15h1.5a.5.5,0,0,0,.45-.72ZM15.1,5.17A2,2,0,0,1,17,3a2,2,0,0,1,1.9,2.15c0,.29,0,.56,0,.83H15.13C15.12,5.73,15.1,5.47,15.1,5.17Zm-9,0A2,2,0,0,1,8,3,2,2,0,0,1,9.9,5.17c0,.29,0,.56,0,.83H6.13C6.12,5.73,6.1,5.47,6.1,5.17ZM6.21,17a17.65,17.65,0,0,1-.71-5.18A2.67,2.67,0,0,1,8,9a2.67,2.67,0,0,1,2.5,2.81A17.63,17.63,0,0,1,9.79,17Zm9,0a17.65,17.65,0,0,1-.71-5.18A2.67,2.67,0,0,1,17,9a2.67,2.67,0,0,1,2.5,2.81A17.63,17.63,0,0,1,18.79,17Zm5.17-3a21.08,21.08,0,0,0,.11-2.17A3.67,3.67,0,0,0,17,8a3.67,3.67,0,0,0-3.5,3.81A21.09,21.09,0,0,0,13.61,14H11.39a21.08,21.08,0,0,0,.11-2.17A3.67,3.67,0,0,0,8,8a3.67,3.67,0,0,0-3.5,3.81A21.09,21.09,0,0,0,4.61,14H1.31l3.5-7H20.19l3.5,7Z"
        />
      </svg>
    </span>
  );
};

PosIconTable.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
