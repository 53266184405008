import { kiosk_route } from 'constants/routesConsts';
import { useLocation } from 'react-router';

export default function useIsKiosk() {
  const { pathname } = useLocation();

  return (
    pathname.startsWith(kiosk_route) ||
    pathname.startsWith('/kiosk/pickup/order') ||
    pathname.startsWith('/kiosk/delivery/order')
  );
}
