import ActionTypes from './constants';

const initialState = {
  drawers: [],
  printers: [],
  activeDrawer: {},
  isTerminalSettingsSelectorDisabled: false,
};

const cashierReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.getDrawers:
      return {
        ...state,
        drawers: payload,
      };
    case ActionTypes.getPrinters:
      return {
        ...state,
        printers: payload,
      };
    case ActionTypes.setActiveDrawer:
      return {
        ...state,
        activeDrawer: {
          ...state.activeDrawer,
          ...payload,
        },
      };
    case ActionTypes.setTerminalSettingsLoaderStatus:
      return {
        ...state,
        isTerminalSettingsSelectorDisabled: payload,
      };
    default:
      return state;
  }
};

export default cashierReducer;
