import React from 'react';
import PropTypes from 'prop-types';
import {
  MinusRoundedThickIcon as IconMinusRoundedThick,
  PlusRoundedThickIcon as IconPlusRoundedThick,
} from 'nexticons/outline';
import './bem-counter-box.scss';
import { getUIClassesFromProps } from '../modifiersMapping';

export const CounterBoxUI = ({ value, onChange, isSecondary }) => {
  const handleMinusClick = () => {
    const newVal = value - 1;
    onChange('-', newVal);
  };
  const handlePlusClick = () => {
    const newVal = value + 1;
    onChange('+', newVal);
  };

  return (
    <div className={getUIClassesFromProps('bem-counter-box', { isSecondary })}>
      <button
        className="bem-counter-box__counter-button"
        type="button"
        disabled={value <= 1}
        onClick={handleMinusClick}
      >
        <IconMinusRoundedThick className="bem-counter-box__icon-minus" />
      </button>
      <div className="bem-counter-box__counter">{value}</div>
      <button className="bem-counter-box__counter-button" type="button" onClick={handlePlusClick}>
        <IconPlusRoundedThick className="bem-counter-box__icon-plus" />
      </button>
    </div>
  );
};

CounterBoxUI.defaultProps = {
  value: 1, // avoid 0
  onChange: () => {},
  isSecondary: false,
};

CounterBoxUI.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  isSecondary: PropTypes.bool,
};
