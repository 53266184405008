import axios from 'axios';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
export const updateCallInfo = async (callDetails, status) => {
  const { storeId, lineName } = callDetails;
  const url = `${nextOrderConfig.incommingCallApi}/calls/v1/incoming-calls/status/${storeId}/${lineName}`;
  try {
    const response = await axios.patch(url, {
      status: status,
      acceptedByStaffId: '',
      acceptedByStaffName: '',
    });
    return { success: !!response.data?.id };
  } catch (e) {
    return { success: false };
  }
};
