import { parsePhoneNumber } from 'libphonenumber-js/max';

const DEFAULT_ERROR_MESSAGE = 'Invalid Phone Number';
const AU_PHONE_REGEXP =
  /^\({0,1}(\+{0,1}(0|61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/;

export const isPhoneNumberValid = (number, countryIdentifier) => {
  const returnObj = {};
  try {
    if (countryIdentifier === 'AU') {
      const isValid = AU_PHONE_REGEXP.test(number);
      const numberObj = parsePhoneNumber(number, countryIdentifier);
      if (isValid) {
        returnObj.number = numberObj.number;

        return returnObj;
      }

      return { error: DEFAULT_ERROR_MESSAGE };
    }
    const numberObj = parsePhoneNumber(number, countryIdentifier);
    if (numberObj) {
      if (numberObj.isValid()) {
        returnObj.number = numberObj.number;
      } else {
        returnObj.error = DEFAULT_ERROR_MESSAGE;
      }
    }
    return returnObj;
  } catch (e) {
    if (number !== '' || number !== '0') {
      returnObj.error = DEFAULT_ERROR_MESSAGE;
    } else {
      returnObj.error = 'Please enter the number again';
    }
    return returnObj;
  }
};
