import { useMemo } from 'react';

const modifierSoldoutData = (
  subModifier,
  soldOutMenuItems = [],
  itemInProgress
) => {
  let variants = subModifier?.variants || [];

  let updatedSubModifier = subModifier;

  soldOutMenuItems.forEach((soldOutItem) => {
    if (
      soldOutItem.menuItemId === itemInProgress._id &&
      soldOutItem.smId === updatedSubModifier._id
    ) {
      if (soldOutItem?.variantId) {
        variants = updatedSubModifier.variants.map((v) => {
          if (v._id === soldOutItem.variantId) {
            return {
              ...v,
              isSoldOut: true,
            };
          }
          return { ...v, isSoldOut: false };
        });

        updatedSubModifier = {
          ...updatedSubModifier,
          variants,
        };
      } else {
        updatedSubModifier = { ...updatedSubModifier, isSoldOut: true };
      }
    }
  });

  return updatedSubModifier;
};

const sizeSoldOutData = (size, soldOutMenuItems, itemInProgress) => {
  let variants = size?.variants || [];
  let updatedSize = size;

  soldOutMenuItems.forEach((soldOutItem) => {
    if (
      soldOutItem.menuItemId === itemInProgress._id &&
      soldOutItem.smId === updatedSize._id
    ) {
      if (soldOutItem?.variantId) {
        variants = updatedSize.variants.map((v) => {
          if (v._id === soldOutItem.variantId) {
            return {
              ...v,
              isSoldOut: true,
            };
          }
          return { ...v, isSoldOut: false };
        });
        updatedSize = {
          ...updatedSize,
          variants,
        };
      } else {
        updatedSize = { ...updatedSize, isSoldOut: true };
      }
    }
  });

  return updatedSize;
};

const markSoldOutItems = (menuItems = [], soldOutMenuItems = []) => {
  const result = menuItems.map((item) => {
    if (item.itemType === 1) {
      let wholeItemIsSoldOut = true;
      const selectedModifiers = item.selectedModifiers?.map((modifier) => {
        const subModifiers = modifier.subModifiers.map((subModifier) => {
          return modifierSoldoutData(subModifier, soldOutMenuItems, item);
        });
        const allSubModifierSoldOut = subModifiers?.every(
          (subModifier) => subModifier.isSoldOut
        );
        return {
          ...modifier,
          isSoldOut: allSubModifierSoldOut,
          subModifiers,
        };
      });

      const isAllSubModifierSoldOut = selectedModifiers?.every(
        (subModifier) => subModifier.isSoldOut
      );

      wholeItemIsSoldOut = isAllSubModifierSoldOut;

      return {
        ...item,
        selectedModifiers,
        isSoldOut: wholeItemIsSoldOut,
      };
    } else if (item.itemType === 0) {
      let wholeItemIsSoldOut = true;
      const selectedSizes = item.selectedSizes?.map((size) => {
        return sizeSoldOutData(size, soldOutMenuItems, item);
      });

      const isAllSizesSoldOut = selectedSizes?.every((size) => size.isSoldOut);
      const isAllSizeVariantSoldOut = selectedSizes?.every((size) => {
        return size.variants?.every((variant) => variant.isSoldOut);
      });

      wholeItemIsSoldOut = isAllSizesSoldOut && isAllSizeVariantSoldOut;

      return {
        ...item,
        selectedSizes,
        isSoldOut: wholeItemIsSoldOut,
      };
    }
  });

  return result;
};

export const useMenuItemsByCategoryId = (
  itemsList,
  categoryId,
  soldOutItems
) => {
  const currentMenuItems = useMemo(() => {
    if (categoryId && itemsList?.length) {
      return itemsList
        .filter((item) => item.categoryId === categoryId)
        .sort((a, b) => {
          return a.name
            ?.toString()
            .localeCompare(b.name?.toString(), 'en', { numeric: true });
        });
    }
    return [];
  }, [itemsList, categoryId]);

  return markSoldOutItems(currentMenuItems, soldOutItems);
};
