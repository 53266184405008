import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconPlus = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  isDefaultStyle=true,
  size,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-plus': isDefaultStyle,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 17 17"
      >
        <g>
          <g transform="rotate(90 8.5 1.5)">
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M14.5-6c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1V9c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1V-6z"
            />
            {/*<g>
              <defs>
                <path
                  id="SVGID_1_"
                  d="M14.5-6c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1V9c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1V-6z"
                />
              </defs>
              <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_1_" style="overflow:visible;" />
              </clipPath>
              <path
                className="st1"
                d="M14.5-6c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1V9c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1V-6z"
              />
            </g>*/}
          </g>
        </g>
        <g>
          <g transform="rotate(90 8.5 1.5)">
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M23,0.8c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1l-15,0c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1L23,0.8z"
            />
            {/*   <g>
              <defs>
                <path
                  id="SVGID_3_"
                  d="M23,0.8c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1l-15,0c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1L23,0.8z"
                />
              </defs>
              <clipPath id="SVGID_4_">
                <use xlink:href="#SVGID_3_" style="overflow:visible;" />
              </clipPath>
              <path
                className="st2"
                d="M23,0.8c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1l-15,0c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1L23,0.8z"
              />
            </g>*/}
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconPlus.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
