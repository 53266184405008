import clsx from 'clsx';
import ItemCourse from 'components/ItemView/ItemCourse';
import { useVirtualKeyboardContext } from 'context/VirtualKeyboardContext';
import useIsKiosk from 'hooks/useIsKiosk';
import { StackedCirclesIcon } from 'nexticons/outline';
import { ModifyIcon } from 'nexticons/solid';
import React, { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ItemQuantity } from '../../components/ItemView/ItemQuantity';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import {
  BadgeUI,
  ButtonGroupUI,
  ButtonUI,
  CloseButtonCircleUI,
  RemoveButtonCircleUI,
  UndoButtonCircleUI,
} from '../../UI/components';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { getPercentage, isObjectNotEmpty } from '../../util/utils';
import './bem-item-view-modal.scss';

const ItemViewModal = forwardRef(
  (
    {
      id,
      updatedItem,
      currentMenuItem,
      currentOrder,
      originalPrice,
      notes,
      handleClickDelete,
      handleClickVoid,
      handleClickClose,
      handleModifyClick,
      handleQuantityChange,
      setUpdatedItem,
      handleNotesFocus,
      handleNotesBlur,
      handleNotesChange,
      handleAddIngr,
      handleRemoveIngr,
      setManualPriceModalOpen,
      setDiscountModalOpen,
      dataModalId,
      currencySymbol,
      showNotes,
      coursesSorted,
    },
    ref
  ) => {
    const { getInputValue } = useVirtualKeyboardContext();
    const customPriceDisabled = useSelector(
      (state) => state.dashboardReducer.orderSetup.customPriceDisabled
    );
    const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
    const currencyCode = useSelector(currencySymbolSelector);
    const isKiosk = useIsKiosk();

    // title
    const selectedSizesWithQnty = updatedItem?.selectedSizes?.filter(
      (f) => f.quantity
    );
    const titleWithQuantity = useMemo(() => {
      // for simple item just join the selectedSizes otherwise join the submodifiers
      let size = selectedSizesWithQnty?.length
        ? `${selectedSizesWithQnty
            .map(
              (m) =>
                `${
                  m.variants?.length
                    ? m.name +
                      '(' +
                      m.variants.map((v) => v.name).join(',') +
                      ')'
                    : m.name
                }`
            )
            .join(',')}`
        : '';
      let modifier = updatedItem?.itemType
        ? ', ' +
          updatedItem?.selectedModifiers
            .filter((m) => m._id !== updatedItem?.baseModifierId)
            .map((m) => m.subModifiers.map((sm) => sm.name))
            .join(', ')
        : '';

      let quantity =
        updatedItem?.quantity > 1 ? ' x' + updatedItem?.quantity : '';

      return `${size}${modifier}${quantity}`;
    }, [
      selectedSizesWithQnty,
      updatedItem?.quantity,
      updatedItem?.selectedModifiers,
      updatedItem?.itemType,
      updatedItem?.baseModifierId,
    ]);

    // ingredients
    const showIngredientsSection = useMemo(() => {
      return (
        currentMenuItem?.selectedExtraIngredients?.length > 0 ||
        currentMenuItem?.selectedIngredients?.length > 0
      );
    }, [currentMenuItem]);
    const addIngredients = useMemo(() => {
      if (
        updatedItem?.selectedExtraIngredients &&
        updatedItem?.selectedExtraIngredients.length > 0
      ) {
        const instruction = updatedItem?.selectedExtraIngredients
          .map((m) => m.name || m.ingredientName)
          .join(', ');
        return instruction;
      }
    }, [updatedItem?.selectedExtraIngredients]);
    const noIngredients = useMemo(() => {
      if (
        updatedItem?.removedIngredients &&
        updatedItem?.removedIngredients.length > 0
      ) {
        const instruction = updatedItem?.removedIngredients
          .map((m) => m.name || m.ingredientName)
          .join(', ');
        return instruction;
      }
    }, [updatedItem?.removedIngredients]);

    // price
    const calculateDiscountedPrice = useMemo(() => {
      const selectedDiscount =
        isObjectNotEmpty(updatedItem?.selectedDiscount) &&
        updatedItem?.selectedDiscount;
      let itemPrice = originalPrice || updatedItem?.itemPrice;

      let price = '';

      if (updatedItem?.isHalfHalf || updatedItem?.isFourQuarters) {
        const { halfHalfItemPrice } = updatedItem.items.reduce(
          (acc, halfHalfMenuItem) => {
            return {
              halfHalfItemPrice:
                halfHalfMenuItem.itemPrice + acc.halfHalfItemPrice,
            };
          },
          {
            halfHalfItemPrice: 0,
          }
        );
        itemPrice = originalPrice || halfHalfItemPrice;
      }

      price = selectedDiscount
        ? selectedDiscount?.discountType === '1'
          ? itemPrice - getPercentage(selectedDiscount?.discount, itemPrice)
          : Number(itemPrice) - selectedDiscount?.discount > 0
          ? Number(itemPrice) - selectedDiscount?.discount
          : 0
        : itemPrice;

      return price;
    }, [updatedItem, originalPrice]);

    // discount
    const showDiscountAmount = useMemo(() => {
      const selectedDiscount =
        isObjectNotEmpty(updatedItem?.selectedDiscount) &&
        updatedItem?.selectedDiscount;

      if (isObjectNotEmpty(selectedDiscount)) {
        const { discountType, discount, discountPercentage } = selectedDiscount;

        if (discountType === '1') {
          return `${discount}%`;
        } else if (discountType === '3') {
          return `${discountPercentage}%`;
        } else {
          return `${currencySymbol}${discount}`;
        }
      } else {
        return `%`;
      }
    }, [updatedItem?.selectedDiscount, currencySymbol]);

    const currentIteration = currentOrder?.iteration;
    const currentMenuItemIteration = updatedItem?.iteration;

    return (
      <div
        id={id}
        className="bem-item-view-modal"
        data-modal-id={dataModalId}
        ref={ref}
      >
        <div className="bem-item-view-modal__inner">
          <div className="bem-item-view-modal__header">
            <ButtonGroupUI
              style={{
                position: 'absolute',
                zIndex: 3,
                right: 14,
                top: 12,
              }}
            >
              {currentMenuItemIteration < currentIteration && (
                <>
                  {updatedItem?.isVoided ? (
                    <UndoButtonCircleUI
                      onClick={() => handleClickVoid(updatedItem?.isVoided)}
                    />
                  ) : (
                    <RemoveButtonCircleUI
                      onClick={() => handleClickVoid(updatedItem?.isVoided)}
                    />
                  )}
                </>
              )}{' '}
              {currentMenuItemIteration === currentIteration &&
                !updatedItem?.isVoided && (
                  <RemoveButtonCircleUI onClick={handleClickDelete} />
                )}
              <CloseButtonCircleUI onClick={handleClickClose} />
            </ButtonGroupUI>
            <div className="bem-item-view-modal__header-info">
              <h3 className="bem-item-view-modal__title">
                {updatedItem?.name}
              </h3>
              {titleWithQuantity && (
                <p className="bem-item-view-modal__desc">
                  <span type="button" title={titleWithQuantity}>
                    {titleWithQuantity}
                  </span>
                </p>
              )}
            </div>

            <ButtonGroupUI modifierClassName="left-offset">
              {!updatedItem?.isFourQuarters && (
                <ButtonUI
                  styleType="primary-light"
                  sizeType="s"
                  fontSize="m"
                  icon={<ModifyIcon width={19} />}
                  onClick={handleModifyClick}
                  disabled={updatedItem?.isFourQuarters}
                >
                  Modify
                </ButtonUI>
              )}

              {!isKiosk && (
                <>
                  <ButtonUI
                    styleType="success-light"
                    sizeType="s"
                    fontSize="m"
                    icon={
                      <span style={{ marginLeft: 8 }}>
                        {showDiscountAmount.replace('-', '')}
                      </span>
                    }
                    onClick={() => {
                      setDiscountModalOpen(true);
                    }}
                  >
                    {parseInt(showDiscountAmount.replace('%', ''), 10) < 0
                      ? 'Add'
                      : 'Discount'}
                  </ButtonUI>

                  {!customPriceDisabled && (
                    <ButtonUI
                      styleType="secondary-light"
                      sizeType="s"
                      fontSize="m"
                      icon={
                        <span
                          style={{
                            color: 'var(--primaryColor)',
                            marginLeft: 8,
                          }}
                        >
                          Edit
                        </span>
                      }
                      onClick={() => {
                        setManualPriceModalOpen(calculateDiscountedPrice);
                      }}
                    >
                      {getAmountWithCountryCurrency({
                        amount: calculateDiscountedPrice,
                        locale: `en-${storeCountryIdentifier}`,
                        currencyCode,
                      })}
                    </ButtonUI>
                  )}
                </>
              )}
            </ButtonGroupUI>
          </div>

          <div className="bem-item-view-modal__body">
            <div className="bem-item-view-modal__body-inner">
              {!updatedItem?.isHalfHalf &&
                !updatedItem?.isFourQuarters &&
                showIngredientsSection && (
                  <div className="bem-item-view-modal__group">
                    <div className="bem-item-view-modal__title-row">
                      <div className="bem-item-view-modal__group-title">
                        Ingredients
                      </div>
                    </div>

                    {currentMenuItem?.selectedExtraIngredients.length > 0 && (
                      <div
                        className="bem-item-view-modal__ingredients-row bem-item-view-modal__ingredients-row--bottom-border"
                        onClick={handleAddIngr}
                      >
                        <BadgeUI
                          title="Add"
                          sizeType="s"
                          styleType="success-light"
                        />
                        <p
                          className={clsx(
                            'bem-item-view-modal__ingredients',
                            !addIngredients &&
                              'bem-item-view-modal__ingredients--without-ingredients'
                          )}
                        >
                          {addIngredients || 'Tap to Add Extras'}
                        </p>
                        <button className="bem-item-view-modal__dot-button">
                          <StackedCirclesIcon width={25} color="#494B4C" />
                        </button>
                      </div>
                    )}

                    {currentMenuItem?.selectedIngredients.length > 0 && (
                      <div
                        className="bem-item-view-modal__ingredients-row"
                        onClick={handleRemoveIngr}
                      >
                        <BadgeUI
                          title="No"
                          sizeType="s"
                          styleType="danger-light"
                          fontWeight="bold"
                        />
                        <p
                          className={clsx(
                            'bem-item-view-modal__ingredients',
                            !noIngredients &&
                              'bem-item-view-modal__ingredients--without-ingredients'
                          )}
                        >
                          {noIngredients || 'Tap to Remove Ingredients'}
                        </p>
                        <button className="bem-item-view-modal__dot-button">
                          <StackedCirclesIcon width={25} color="#494B4C" />
                        </button>
                      </div>
                    )}
                  </div>
                )}

              {showNotes && (
                <div className="bem-item-view-modal__group">
                  <div className="bem-item-view-modal__title-row">
                    <div className="bem-item-view-modal__group-title">
                      Notes
                    </div>
                  </div>
                  <div className="bem-item-view-modal__row">
                    <textarea
                      id="itemNotes"
                      name="itemNotes"
                      className="bem-item-view-modal__textarea"
                      onFocus={handleNotesFocus}
                      onBlur={handleNotesBlur}
                      value={getInputValue('itemNotes')}
                      onChange={handleNotesChange}
                      placeholder="Enter Item Notes Here…"
                    />
                  </div>
                </div>
              )}

              <ItemCourse
                menuItem={updatedItem}
                setUpdatedItem={setUpdatedItem}
                coursesSorted={coursesSorted}
              />
            </div>
          </div>
          {!updatedItem?.isHalfHalf && !updatedItem?.isFourQuarters && (
            <div className="bem-item-view-modal__footer">
              <div className="bem-item-view-modal__counter-box">
                <ItemQuantity
                  isSecondary
                  currentItem={updatedItem}
                  itemQuantity={updatedItem?.quantity}
                  onChange={handleQuantityChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ItemViewModal;
