import React, { useEffect, useRef } from 'react';
import Button from '../../Button';
import classNames from 'classnames';
import { currencyFormat } from '../../../util/currencyFormat';
import { PosIconArrow } from '../../../assets/icons/PosIconArrow';
import { PosIconDot } from '../../../assets/icons/PosIconDot';

export const MoneyCountManuallyMode = ({
  total,
  setTotal,
  handleToggleModal,
  manuallyMode,
  setQuantity,
  moneyCount,
  toggleManuallyMode,
  activeIndex,
  setActiveDenomination,
}) => {
  const keyList = useRef([
    { id: 1, content: '1', val: '1' },
    { id: 2, content: '2', val: '2' },
    { id: 3, content: '3', val: '3' },
    { id: 4, content: '4', val: '4' },
    { id: 5, content: '5', val: '5' },
    { id: 6, content: '6', val: '6' },
    { id: 7, content: '7', val: '7' },
    { id: 8, content: '8', val: '8' },
    { id: 9, content: '9', val: '9' },
    {
      id: 10,
      content: <PosIconDot mainColor="#515151" darkModeColor="#ffffff" />,
      val: 'dot',
    },
    { id: 11, content: '0', val: '0' },
    {
      id: 13,
      content: <PosIconArrow mainColor="#515151" darkModeColor="#ffffff" />,
      val: 'clear',
    },
  ]);

  const handleClick = (e, val) => {
    let arr = Array.from(total);
    let newVal = 0;

    if (val === 'clear') {
      arr.splice(-1, 1);
    } else if (val === 'dot') {
      if (arr.indexOf('.') === -1) {
        arr.push('.');
      }
    } else {
      arr.push(val);
    }

    newVal = arr.join('');

    //
    // if (String(total).indexOf('.') !== -1 && String(total).indexOf('.') === String(total).length -1) {
    //
    // } else if (String(total).indexOf('.') !== -1 && String(total).indexOf('.') === String(total).length -1) {
    //   newVal = Number(newVal)
    // } else {
    //
    // }

    setTotal(newVal);
  };

  const handleToggle = () => {
    setTotal(0);
    toggleManuallyMode();
  };

  return (
    <div className="manual-amount-box">
      <div className="manual-amount-header">
        <h2 className="manual-amount-title">Manual Amount</h2>
        <p className="manual-amount-price">
          {total ? currencyFormat(total) : ''}
        </p>
        <Button
          title="Enter Denominations"
          className="btn grey inverse"
          onClick={handleToggle}
        >
          Enter Denominations
        </Button>
      </div>
      <div className="manual-amount-keypad-box">
        {keyList.current.map((item) => {
          const itemCls = classNames({
            'manual-amount-key': true,
            'hover-highlight': true,
            [`key-${item.val.toLowerCase()}`]: true,
          });

          return (
            <div key={item.id} className="manual-amount-key-wrap">
              <Button
                title={item.val}
                className={itemCls}
                onClick={(e) => handleClick(e, item.val)}
              >
                {item.content}
              </Button>
            </div>
          );
        })}
      </div>
      <div className="manual-amount-btn-box">
        <Button
          className="btn"
          title="Finish "
          onClick={(e) => handleToggleModal(e, true)}
        >
          Finish{' '}
        </Button>
      </div>
    </div>
  );
};
