import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconDot = ({
  mainColor,
  darkModeColor,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-dot': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg viewBox="0 0 200 200">
        <path
          fill={iconColor ? iconColor : '#000'}
          d="
                 M 100, 100
                 m -75, 0
                 a 75,75 0 1,0 150,0
                 a 75,75 0 1,0 -150,0
                 "
        />
      </svg>
    </span>
  );
};

PosIconDot.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  className: PropTypes.string,
};
