import './bem-navigation-button.scss';
import PropTypes from 'prop-types';
import { getUIClassesFromProps } from '../modifiersMapping';
import { Link } from 'react-router-dom';

export const NavigationButtonUI = ({
  title,
  link,
  logo,
  icon,
  modifierClassName,
  style,
  onClick,
}) => {
  if (link) {
    return (
      <Link
        to={link}
        className={getUIClassesFromProps('bem-navigation-button', {
          modifierClassName,
        })}
        data-position="left"
        onClick={onClick}
        title={title}
        style={style}
      >
        <ButtonContent logo={logo} icon={icon} title={title} />
      </Link>
    );
  } else {
    return (
      <button
        type="button"
        data-position="left"
        className={getUIClassesFromProps('bem-navigation-button', {
          modifierClassName,
        })}
        onClick={onClick}
        title={title}
        style={style}
      >
        <ButtonContent logo={logo} icon={icon} title={title} />
      </button>
    );
  }
};

function ButtonContent({ logo, icon, title }) {
  return (
    <>
      <span className="bem-navigation-button__icon-box">{icon}</span>
      {logo && <span className="bem-navigation-button__logo">{logo}</span>}
      {!logo && title && (
        <span className="bem-navigation-button__title">{title}</span>
      )}
    </>
  );
}

NavigationButtonUI.defaultProps = {
  title: '',
  link: '',
  logo: undefined,
  icon: undefined,
  modifierClassName: '',
  onClick: () => {},
  style: {},
};

NavigationButtonUI.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  logo: PropTypes.element,
  icon: PropTypes.element,
  modifierClassName: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
