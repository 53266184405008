import {useVirtualKeyboardContext} from 'context/VirtualKeyboardContext';
import React, { useState, useEffect } from 'react';
import cellular from '../../assets/img/cellular_connection.png';
import { getCurrentTime, getCurrentShortenedDate } from '../../util/dateUtils';

export const StatusBar = () => {
  const { toggleKeyboard } = useVirtualKeyboardContext();
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [currentShortenedDate, setCurrentShortenedDate] = useState(getCurrentShortenedDate());
  
  useEffect(()=>{
    const interval = setInterval(()=>{
      setCurrentTime(getCurrentTime())
      setCurrentShortenedDate(getCurrentShortenedDate());
    }, 500)
    return () =>{
      clearInterval(interval);
    }
  }, []);

  return (
    <div className="status-bar">
      <img className="cellular-connection" src={cellular} alt="" />
      <span onClick={toggleKeyboard} className="status-time-box">
        <span className="time">{currentTime}</span>
        <span className="date">{currentShortenedDate}</span>
      </span>
    </div>
  );
};
