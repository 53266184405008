import React, { useRef } from 'react';
import '../MenuItemList.style.css';
import { StackedCirclesIcon } from 'nexticons/solid';

const ActionButton = ({
  setDropdownRef,
  openItemActionDropdown,
  setOpenItemActionDropdown,
  setSelectedItem,
  item,
}) => {
  const itemActionDropdownRef = useRef();

  const handleActionModal = () => {
    setOpenItemActionDropdown(!openItemActionDropdown);
    setDropdownRef(itemActionDropdownRef);
    if (!Array.isArray(item)) {
      setSelectedItem([item]);
    } else {
      setSelectedItem(item);
    }
  };

  return (
    <div
      ref={itemActionDropdownRef}
      className="threeDotsIconContainer"
      onClick={handleActionModal}
    >
      <StackedCirclesIcon width={25} color="#c6c6c6" />
    </div>
  );
};

export default ActionButton;
