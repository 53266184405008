import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  authenticateStaff,
  setIsEditPhoneNumber,
} from '../../pages/Dashboard/action';
import { setGoToRouteAfterAuth } from 'pages/Dashboard/action';
import { fetchCustomerNameByPhoneNumber } from '../../services/homeServices';
import { ModalPortal } from 'components';
import StaticMenuItems from '../MenuItems/StaticMenuItems';
import useIsKiosk from 'hooks/useIsKiosk';
import { toggleBlurEffect } from 'pages/Dashboard/action';
import {
  currentOrderSelector,
  storeConfigSelector,
  suburbsSelector,
  useRadiusBasedDeliveryAreaCalulationSelector,
} from '../../pages/Dashboard/selectors';
import { useSelector } from 'react-redux';
import { updateCurrentOrderWithOrderEngine } from 'pages/Dashboard/thunks';
import useModal from 'hooks/useModal';

import PhoneModal from 'modals/PhoneModal';
import { addUserWithMobileNumber } from 'services/customerServices';

const PhoneModalWrapper = ({ parentPage }) => {
  const [open, { closeModal }] = useModal('customerPhoneModal');

  if (!open) return null;

  return (
    <PhoneNumber
      closeModal={closeModal}
      modalStatus={open}
      parentPage={parentPage}
    />
  );
};

export const PhoneNumber = ({ modalStatus, closeModal }) => {
  const history = useHistory();
  const suburbs = useSelector(suburbsSelector);
  const currentOrder = useSelector(currentOrderSelector);
  const storeConfig = useSelector(storeConfigSelector);
  const isEditPhoneNumber = useSelector(
    (state) => state.dashboardReducer?.isEditPhoneNumber
  );
  const askNameAndMobileAtEnd = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.askNameAndMobileAtEnd
  );
  const { country, countryIdentifier } = storeConfig;

  const useRadiusBasedDeliveryAreaCalulation = useSelector(
    useRadiusBasedDeliveryAreaCalulationSelector
  );

  const isKiosk = useIsKiosk();
  const dispatch = useDispatch();
  const [isFetchingUserInfo, setIsFetchingUserInfo] = useState(false);

  const timeout = useRef(0);

  const handleSubmit = useCallback(
    async (mobileNumber) => {
      const params = {
        restaurantId: storeConfig.restaurantId,
        mobileNumber,
      };
      try {
        setIsFetchingUserInfo(true);
        const result = await fetchCustomerNameByPhoneNumber(params);
        // if current order is null or undefined assign default value to currentOrderObj
        let currentOrderObj = {};
        currentOrderObj.isCustomerNameFetched = true;
        if (result.success && result.user && result.user._id) {
          currentOrderObj.mobileNumber = mobileNumber;
          currentOrderObj.userId = result.user._id;
          currentOrderObj.firstName = result.user.firstName;
          currentOrderObj.lastName = result.user.lastName;
          currentOrderObj.isNewCustomer = result.user.isNewCustomer;
          currentOrderObj.totalDeliveryOrders = result.user.totalDeliveryOrders;
          if (result.address && result.address.address) {
            if (useRadiusBasedDeliveryAreaCalulation) {
              if (result?.address?.isManualAddress) {
                currentOrderObj.address = '';
                currentOrderObj.addressLocation = '';
                currentOrderObj.suburbId = '';
                currentOrderObj.area = '';
                currentOrderObj.areaCode = '';
              } else {
                currentOrderObj = {
                  ...currentOrderObj,
                  ...{
                    ...result.address,
                    address: result.address.address
                      .replace(`, ${country}`, '')
                      .replace(/QLD$/, '')
                      .replace(/VIC$/, '')
                      .replace(/NSW$/, '')
                      .replace(/TAS$/, '')
                      .replace(/WA$/, '')
                      .replace(/SA$/, '')
                      .replace(/NT$/, '')
                      .replace(/Street /gi, 'St ')
                      .replace(/Court /gi, 'Crt '),
                  },
                };
              }
            } else {
              const hasValidSuburb = suburbs?.some(
                (suburb) => suburb._id === result?.address?.suburbId
              );
              if (hasValidSuburb) {
                currentOrderObj = {
                  ...currentOrderObj,
                  ...{
                    ...result.address,
                    address: result.address.address
                      .replace(`, ${country}`, '')
                      .replace(/QLD$/, '')
                      .replace(/VIC$/, '')
                      .replace(/NSW$/, '')
                      .replace(/TAS$/, '')
                      .replace(/WA$/, '')
                      .replace(/SA$/, '')
                      .replace(/NT$/, '')
                      .replace(/Street /gi, 'St ')
                      .replace(/Court /gi, 'Crt '),
                  },
                };
              }
            }
          }
        } else {
          currentOrderObj.mobileNumber = mobileNumber;
          currentOrderObj.firstName = currentOrder?.firstName || '';
          currentOrderObj.lastName = currentOrder?.lastName || '';

          if (isEditPhoneNumber) {
            try {
              const result = await addUserWithMobileNumber({
                restaurantId: storeConfig.restaurantId,
                timeZone: storeConfig.timeZone,
                mobileNumber: currentOrderObj.mobileNumber,
                firstName: currentOrderObj.firstName,
                lastName: currentOrderObj.lastName,
              });
              if (result.success && result.userId) {
                currentOrderObj.userId = result.userId;
              }
            } catch (error) {}
          }
        }
        dispatch(
          updateCurrentOrderWithOrderEngine({
            ...currentOrder,
            ...currentOrderObj,
          })
        );
        dispatch(toggleBlurEffect(false));
        /* timeout.current = setTimeout(() => {
        if (parentPage.includes('pickup') && result.user?._id) {
          console.log("history.push(`${parentPage}/order`, location.state);")
          history.push(`${parentPage}/order`, location.state);
        } else if (parentPage.includes('delivery')) {
          if (result.user?._id) {
            if (result.address?.address) {
              console.log("history.push(`${parentPage}/customer/delivery-address/confirm`);")
              history.push(`${parentPage}/customer/delivery-address/confirm`);
            } else {
              console.log("history.push(navigateTo);")
              history.push(navigateTo);
            }
          } else {
            console.log("history.push(`${parentPage}/customer/name`);")
            history.push(`${parentPage}/customer/name`);
          }
        } else {
          console.log("history.push(navigateTo);")
          history.push(navigateTo);
        }
      }, 100); */

        if (isEditPhoneNumber) {
          dispatch(setIsEditPhoneNumber(false));
        }
        closeModal();
      } finally {
        setIsFetchingUserInfo(false);
      }
    },
    [
      country,
      currentOrder,
      dispatch,
      closeModal,
      storeConfig.restaurantId,
      suburbs,
      useRadiusBasedDeliveryAreaCalulation,
      isEditPhoneNumber,
    ]
  );

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);
  return (
    <ModalPortal>
      <PhoneModal
        modalStatus={modalStatus}
        onNextClick={handleSubmit}
        onModalClose={() => {
          if (isKiosk) {
            closeModal();
            history.push('/kiosk');
          } else {
            if (askNameAndMobileAtEnd) {
              closeModal();
              dispatch(toggleBlurEffect(false));
            } else {
              dispatch(authenticateStaff(false));
              dispatch(setGoToRouteAfterAuth(null));
              closeModal();
              history.push('/');
            }
          }
        }}
        isKiosk={isKiosk}
        isFetching={isFetchingUserInfo}
        countryIdentifier={countryIdentifier}
      />
    </ModalPortal>
  );
};

export default PhoneModalWrapper;
