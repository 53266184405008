import { Children, Fragment, cloneElement, useState } from 'react';
import './bem-accordion.scss';
import { ChevronDownIcon } from 'nexticons/outline';
import { BadgeUI } from '../../UI/components';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getColorByCourseID } from '../../util/getColorByCourceID';

function AccordionSection({
  children,
  title,
  value,
  courseId,
  active,
  setActiveSection,
  activeSection,
  index,
  onChange,
  checked,
  disabled,
}) {
  const dineInCourses = useSelector(
    (state) => state.dashboardReducer.dineInCourses
  );
  const { dineInCoursesMap } = dineInCourses;
  const courseColor = getColorByCourseID({ courseId, dineInCoursesMap });

  return (
    <div
      className={clsx('bem-accordion__section', {
        'bem-accordion__section--open': active,
        'bem-accordion__section--disabled': disabled,
      })}
    >
      <div
        onClick={() => {
          const st = new Set([...activeSection]);
          const i = String(index);
          const newSt = new Set(st.delete(i) ? st : st.add(i));

          if (!disabled) {
            onChange(value);
          }

          setActiveSection(newSt);
        }}
        className="bem-accordion__header"
      >
        <div className="bem-accordion__header-label">
          <span
            id={value}
            className={clsx('bem-accordion__checkbox', {
              'bem-accordion__checkbox--checked': checked,
              'bem-accordion__checkbox--disabled': disabled,
            })}
          />

          <h3 className="bem-accordion__title">{title}</h3>

          <div>
            <BadgeUI
              title={`${children.length} Item${
                children.length === 1 ? '' : 's'
              }`}
              fullWidth
              sizeType="xxs"
              style={{
                fontSize: 12.5,
                color: 'var(--whiteColor)',
                background: courseColor,
                borderColor: courseColor,
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ChevronDownIcon
              width={24}
              strokeWidth={2}
              style={{
                transform: checked ? 'rotate(-180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
        </div>
      </div>
      <div className="bem-accordion__content">{children}</div>
    </div>
  );
}

function Accordion({ children }) {
  const [activeSection, setActiveSection] = useState(new Set('0'));

  const childArray = Children.toArray(children).filter((child) => {
    return child.type === AccordionSection;
  });

  const totalSections = childArray.length;

  return (
    <div className="bem-accordion">
      {childArray.map((child, index) => {
        const section = cloneElement(child, {
          index,
          key: index,
          active: activeSection.has(String(index)),
          totalSections,
          activeSection,
          setActiveSection,
        });

        return section;
      })}
    </div>
  );
}

Accordion.Section = AccordionSection;
export default Accordion;
