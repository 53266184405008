import React, { useEffect, useRef, useState } from 'react';
import './IncomingCallNotification.scss';
import { PhoneIcon } from 'nexticons/solid';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { updateCallInfo } from './IncomingCallNotification.service';
import { updateCurrentOrderWithOrderEngine } from 'pages/Dashboard/thunks';
import { setAcceptedCall } from 'pages/Dashboard/action';
import { fetchCustomerNameByPhoneNumber } from 'services/homeServices';
import {
  useRadiusBasedDeliveryAreaCalulationSelector,
  suburbsSelector,
} from 'pages/Dashboard/selectors';

const IncomingCallNotification = ({ call }) => {
  const { id, lineName, phone, status } = call;
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const timer = useRef(null);
  const dispatch = useDispatch();
  const storeConfig = useSelector(
    (state) => state.dashboardReducer.storeConfig
  );
  const { country } = storeConfig;
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const useRadiusBasedDeliveryAreaCalulation = useSelector(
    useRadiusBasedDeliveryAreaCalulationSelector
  );
  const suburbs = useSelector(suburbsSelector);

  useEffect(() => {
    setTotalSeconds(0);
    if (status !== 1) return;

    timer.current = setInterval(() => {
      setTotalSeconds((prev) => prev + 1);
    }, 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, [status]);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const renderStatusLabel = (status) => {
    if (status === 0 && !isLoading) {
      return <span className="incommingCallLabel">Incoming Call</span>;
    } else if (status === 0 && isLoading) {
      return <span className="incommingCallLabel">Connecting...</span>;
    } else if (status === 1 && !isLoading) {
      return (
        <span className="onCallLabel">
          On Call - {`${minutes}`.padStart(2, '0')}:
          {`${seconds}`.padStart(2, '0')}
        </span>
      );
    } else {
      return <span className="incommingCallLabel">Disconnecting...</span>;
    }
  };

  const handleOnClick = async () => {
    setIsLoading(true);
    const res = await updateCallInfo(call, 1);
    setIsLoading(false);
    if (res?.success) {
      dispatch(setAcceptedCall({ ...call, status: 1 }));

      const apiParams = {
        restaurantId: storeConfig.restaurantId,
        mobileNumber: `${phone}`.trim(),
      };
      const customerDetails = await fetchCustomerNameByPhoneNumber(apiParams);
      let currentOrderObj = {};
      currentOrderObj.isCustomerNameFetched = true;
      if (
        customerDetails.success &&
        customerDetails.user &&
        customerDetails.user._id
      ) {
        currentOrderObj.mobileNumber = phone;
        currentOrderObj.userId = customerDetails.user._id;
        currentOrderObj.firstName = customerDetails.user.firstName;
        currentOrderObj.lastName = customerDetails.user.lastName;
        currentOrderObj.isNewCustomer = customerDetails.user.isNewCustomer;
        currentOrderObj.totalDeliveryOrders =
          customerDetails.user.totalDeliveryOrders;
        if (customerDetails.address && customerDetails.address.address) {
          if (useRadiusBasedDeliveryAreaCalulation) {
            if (customerDetails?.address?.isManualAddress) {
              currentOrderObj.address = '';
              currentOrderObj.addressLocation = '';
              currentOrderObj.suburbId = '';
              currentOrderObj.area = '';
              currentOrderObj.areaCode = '';
            } else {
              currentOrderObj = {
                ...currentOrderObj,
                ...{
                  ...customerDetails.address,
                  address: customerDetails.address.address
                    .replace(`, ${country}`, '')
                    .replace(/QLD$/, '')
                    .replace(/VIC$/, '')
                    .replace(/NSW$/, '')
                    .replace(/TAS$/, '')
                    .replace(/WA$/, '')
                    .replace(/SA$/, '')
                    .replace(/NT$/, '')
                    .replace(/Street /gi, 'St ')
                    .replace(/Court /gi, 'Crt '),
                },
              };
            }
          } else {
            const hasValidSuburb = suburbs?.some(
              (suburb) => suburb._id === customerDetails?.address?.suburbId
            );
            if (hasValidSuburb) {
              currentOrderObj = {
                ...currentOrderObj,
                ...{
                  ...customerDetails.address,
                  address: customerDetails.address.address
                    .replace(`, ${country}`, '')
                    .replace(/QLD$/, '')
                    .replace(/VIC$/, '')
                    .replace(/NSW$/, '')
                    .replace(/TAS$/, '')
                    .replace(/WA$/, '')
                    .replace(/SA$/, '')
                    .replace(/NT$/, '')
                    .replace(/Street /gi, 'St ')
                    .replace(/Court /gi, 'Crt '),
                },
              };
            }
          }
        }
      } else {
        currentOrderObj.mobileNumber = phone;
        currentOrderObj.firstName = currentOrder?.firstName || '';
        currentOrderObj.lastName = currentOrder?.lastName || '';
      }

      if (customerDetails?.user?._id) {
        // when customer  data exist in our system
        dispatch(
          setAcceptedCall({
            ...call,
            status: 1,
            customerName: `${customerDetails.user.firstName} ${customerDetails.user.lastName}`,
            customerDetails: currentOrderObj,
          })
        );
      } else {
        // whne customer data does not exists in our system
        dispatch(
          setAcceptedCall({
            ...call,
            status: 1,
            customerDetails: {
              mobileNumber: phone,
            },
          })
        );
      }

      dispatch(
        updateCurrentOrderWithOrderEngine({
          ...currentOrder,
          ...currentOrderObj,
        })
      );
    }
  };

  return (
    <div
      key={id}
      className={clsx('notificationContainer', {
        ['onCallNotification']: status === 1 && !isLoading,
        ['callLoading']: isLoading,
      })}
    >
      <div className="notificationInfoSection">
        <div>
          <span className="lineName"> {lineName}</span>
          <span className="phoneNumber"> {phone}</span>
        </div>
        <div>{renderStatusLabel(status)}</div>
      </div>
      <div className="actionButtonContainer">
        {/* use this(!isAnotherCallActive) below if you want to disappear call button while connecting */}
        {!isLoading && (
          <div
            className={clsx('iconContainer', {
              ['declineCallButton']: status === 1,
            })}
            onClick={handleOnClick}
          >
            <div className="phoneIcon">
              <PhoneIcon
                style={status === 1 ? { transform: 'rotate(135deg)' } : {}}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IncomingCallNotification;
