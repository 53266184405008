import React, { useEffect, useState } from 'react';
import { PlusRoundedThickIcon as IconPlusRoundedThick } from 'nexticons/outline';
import { useSelector } from 'react-redux';
import {
  HeaderUI,
  SearchButtonUI,
  SwitchButtonsGroupUI,
} from '../../UI/components';
import { LogoButton } from '../LogoButton';
import { AddDriverModal } from 'modals/AddDriverModal';

export const DriversHeader = () => {
  const currentUrl = window.location.href;
  const [activeSwitchIndex, setActiveSwitchIndex] = useState(undefined);
  const [showAddDriverModal, setShowAddDriverModal] = useState(false);
  const switchTypeArr = useSelector(
    (state) => state.driversReducer.filterDrivers
  );

  useEffect(() => {
    for (let i = 0; i < switchTypeArr.length; i++) {
      if (currentUrl.indexOf(switchTypeArr[i].to) !== -1) {
        setActiveSwitchIndex(i);
        break;
      }
    }
  }, [currentUrl, switchTypeArr]);

  return (
    <>
      <HeaderUI>
        <LogoButton />
        <SwitchButtonsGroupUI
          switchList={switchTypeArr}
          activeSwitchIndex={activeSwitchIndex}
          setActiveSwitchIndex={setActiveSwitchIndex}
        />
        {activeSwitchIndex === 0 ? (
          <button
            className="add-driver-button"
            onClick={() => setShowAddDriverModal(true)}
          >
            <IconPlusRoundedThick strokeWidth={2} width={20} color="#067aff" />
            <div className="add-driver-button-text">Driver</div>
          </button>
        ) : (
          <SearchButtonUI />
        )}
      </HeaderUI>

      {showAddDriverModal && (
        <AddDriverModal
          show={showAddDriverModal}
          toggleModal={() => setShowAddDriverModal(!showAddDriverModal)}
        />
      )}
    </>
  );
};
