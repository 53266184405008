import React, { useEffect } from 'react';
import { DeliveriesSidebar } from './DeliveriesSidebar';
import ModalViewOrdersTime from '../../modals/ModalViewOrdersTime';
import { DeliveryDriversList } from './content/DeliveryDriversList';
import { useDispatch } from 'react-redux';
import { ScrollableBoxUI } from '../../UI/components';

export const DeliveriesBox = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'setCurrentDriver',
      payload: null,
    });
  }, [dispatch]);

  return (
    <>
      <div className="driver-main-wrap">
        <DeliveriesSidebar />

        <div className="driver-main">
          <div className="driver-inner-box">
            <ScrollableBoxUI>
              <DeliveryDriversList />
            </ScrollableBoxUI>
          </div>
        </div>
      </div>

      <ModalViewOrdersTime
        timeType={'pickUpTime'}
        averageVal={'32 mins'}
        modalTitle={'Collection Time'}
        id={1}
        className={'drivers-style'}
      />

      <ModalViewOrdersTime
        timeType={'deliveryTime'}
        averageVal={'32 mins'}
        modalTitle={'Delivery Time'}
        id={2}
        className={'drivers-style'}
      />
    </>
  );
};
