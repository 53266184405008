import Button from "components/Button";

export default function KioskFooter({ logo, secondaryColor, onButtonClick, buttonText = 'Start Order' }) {
  return (
    <div
      style={{
        height: '15%',
        backgroundColor: '#fff',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 20,
        padding: '20px 40px',
      }}
    >
      <img
        src={logo}
        alt="logo"
        style={{
          height: '100%',
          maxWidth: 300,
          width: '100%',
          minWidth: 100,
        }}
      />

      <Button
        style={{

          maxWidth: 400,
          minWidth: 100,
          width: '100%',
          padding: 16,
          borderRadius: 100,
          color: '#fff',
          backgroundColor: secondaryColor,
          fontSize: "2.7vmin",
          fontWeight: '500',
        }}
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}
