import {
  useEffect,
  useMemo,
  useState,
  useReducer,
  useCallback,
  useRef,
} from 'react';
import classNames from 'classnames';
import { paymentTypeEnum, ApiStatusEnum } from 'configuration/enums';
import SplitPaymentsModal from 'components/SplitPayments/SplitPaymentsModal';
import animationData from 'assets/lotties/782-check-mark-success.json';
import { API_URL } from 'configuration/apiUrl';
import axios from 'axios';
import { useMutation } from 'hooks';
import { updateOrder } from 'pages/Orders/newOrdersReducer';
import GeneralModal from 'modals/GeneralModal/index-new';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
import { kiosk_route } from 'constants/routesConsts';
import useIsKiosk from 'hooks/useIsKiosk';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { printBillCommand, settleOrderApi } from 'services/ordersServices';
import { getServiceID } from 'util/utils';
import { v4 as uuidv4 } from 'uuid';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import terminalPng from 'assets/img/terminal.png';
import checkMarkSuccess from 'assets/lotties/782-check-mark-success.json';
import Button from 'components/Button';
import CashAmountModal from 'components/CashAmountModal';
import { _auth, _firestore } from '../../firebase';
import { authenticateStaff, updateCashModalTitle } from '../Dashboard/action';
import {
  currencySymbolSelector,
  isPrintCustomerReceiptAutomaticallyOn,
  nextOrderConfigURLSelector,
  paymentTypesSelector,
  pinPadsSelector,
  printCustomerReceiptAutomaticallyOnPOSSelector,
  selectedDrawerIdSelector,
  selectedDrawerPrinterIdSelector,
  selectedPinPadIdSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
} from '../Dashboard/selectors';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import { getCashOptions } from 'util/paymentViewUtils';
import PaymentAmountOption from 'components/PaymentViewModal/PaymentAmountOption';
import { ModalPortal } from 'components';
import CustomAmountModal from './CustomAmountModal';
import PaymentModalFailScreen from 'components/PaymentViewModal/PaymentModalFailScreen';
import { getSplitPaymentsCashAmountModalButtonStatus } from 'util/order-flow-utils';
import { WarningIcon, XRoundedIcon, CheckIcon } from 'nexticons/solid';
import { LoadingIcon } from 'nexticons/outline';
import clsx from 'clsx';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const PaymentViewModal = ({
  toggleModal,
  splitState,
  setState,
  selectCheck,
  currentOrder,
  orderId, // sometimes currentOrder.id is empty 8====D
  payableAmount,
  openCashAmountPaidModal,
  orderPaymentsMap,
  selectedCheck,
  deleteChecksList,
}) => {
  const selectedCheckNumber = selectedCheck?.number;

  const [windcaveErrorMessage, setWindcaveErrorMessage] = useState('');
  const [windcaveInfoMessage, setWindcaveInfoMessage] = useState('');
  const [transactionStatusId, setTransactionStatusId] = useState(-1);
  const [windcaveButtonOne, setWindcaveButtonOne] = useState('');
  const [windcaveButtonTwo, setWindcaveButtonTwo] = useState('');
  const [windcaveActionButtonPressed, setWindcaveActionButtonPressed] =
    useState(false);
  const [windcaveActionButtonPressedName, setWindcaveActionButtonPressedName] =
    useState('');
  const [windcaveTransactionRef, setWindcaveTransactionRef] = useState('');
  const [isWindcaveProcessing, setIsWindcaveProcessing] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();

  const [splitPayment, toggleSplitPayment] = useReducer((st) => !st, false);

  const isPrintReceiptAutomatically = useSelector(
    isPrintCustomerReceiptAutomaticallyOn
  );
  const openDrawerForCardTransactions = useSelector(
    (state) => state.dashboardReducer.orderSetup?.openDrawerForCardTransactions
  );
  const storeConfig = useSelector(storeConfigSelector);
  const isKiosk = useIsKiosk();
  const currencySymbol = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const selectedPinPadId = useSelector(selectedPinPadIdSelector);
  const selectedDrawerId = useSelector(selectedDrawerIdSelector);
  const selectedDrawerPrinterId = useSelector(selectedDrawerPrinterIdSelector);
  const [printingLoadingStatus, setPrintingLoadingStatus] = useState(
    ApiStatusEnum.IDLE
  );
  const pinPads = useSelector(pinPadsSelector);
  const printReceipt = useSelector(
    printCustomerReceiptAutomaticallyOnPOSSelector
  );
  const { nextOrderConfigURL, nextOrderConfigURLSydney } = useSelector(
    nextOrderConfigURLSelector
  );
  const billType = useSelector(
    (state) => state.dashboardReducer.currentOrder?.billType
  );

  const currentSelectedTerminal = useMemo(
    () => pinPads?.find((pinPad) => pinPad._id === selectedPinPadId),
    [pinPads, selectedPinPadId]
  );
  const windcaveUser = useSelector(
    (state) => state.dashboardReducer.terminalSettings?.user || 'None'
  );
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [isProcessTerminalPayment, setIsProcessTerminalPayment] =
    useState(false);
  const [orderNumber, setOrderNumber] = useState(0);
  const [paymentAnimation, setPaymentAnimation] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [sessionId, setSessionId] = useState('0');
  const [serviceId, setServiceId] = useState();
  const [isTerminalOrderInProgress, setIsTerminalOrderInProgress] =
    useState(false);
  const [isPayingByCash, setIsPayingByCash] = useState(false);
  const [selectedCustomPaymentType, setSelectedCustomPaymentType] =
    useState(false);
  const [isRefund, setRefundFlag] = useState(false);
  const [shouldAutoPay, setShouldAutoPay] = useState(false);
  const [isCancelledByPayByCash, setIsCancelledByPayByCash] = useState(false);
  const [
    failedLinklyByCancelPaymentResponse,
    setFailedLinklyByCancelPaymentResponse,
  ] = useState(false);
  const [{ cashPaid, cashChange }, setCashPaid] = useState({
    cashPaid: 0,
    cashChange: 0,
  });

  const [orderCompleted, setOrderCompleted] = useState(false);

  const [showCloseModal, setShowCloseModal] = useState(false);

  useEffect(() => {
    if (cashPaid) {
      setShowCloseModal(true);
    }
  }, [cashPaid]);
  useEffect(() => {
    const uniqueSessionId = uuidv4();
    setSessionId(uniqueSessionId);
    setServiceId(getServiceID(uniqueSessionId));
    setShouldAutoPay(true);
  }, []);

  const paymentTypesForOrderType = useMemo(() => {
    return (
      paymentTypes?.filter((paymentType) =>
        paymentType?.orderTypeKeys?.includes(currentOrder.orderType)
      ) || []
    );
  }, [currentOrder.orderType, paymentTypes]);

  const pymnts =
    splitState.type === 'full'
      ? currentOrder.payments
      : (selectedCheck?.payments || [])
          .map((v) => orderPaymentsMap[v])
          .filter((i) => !!i);
  const paidAmount = pymnts ? pymnts.reduce((a, v) => (a += v.amount), 0) : 0;
  const checkPaid = paidAmount === payableAmount;

  useEffect(() => {
    if (paidAmount && payableAmount - paidAmount > 0 && !splitPayment) {
      toggleSplitPayment();
    }
  }, [paidAmount, payableAmount, splitPayment]);

  const [paymentOptionSelected, setPaymentOptionSelected] = useState(null);

  useEffect(() => {
    if (failedLinklyByCancelPaymentResponse) {
      if (!isCancelledByPayByCash) {
        setPaymentAnimation(2);
      }
      setFailedLinklyByCancelPaymentResponse(false);
    }
  }, [failedLinklyByCancelPaymentResponse, isCancelledByPayByCash]);

  useEffect(() => {
    if (
      pinPads?.length > 0 &&
      selectedPinPadId &&
      selectedPinPadId !== '000-000-000' &&
      shouldAutoPay
    ) {
      setIsProcessTerminalPayment(true);
    }
    // eslint-disable-next-line
  }, [shouldAutoPay]);

  const disableButtonStyle = isPlacingOrder ? { opacity: 0.6 } : {};

  const checkMarkSuccessLottie = {
    loop: false,
    autoplay: true,
    animationData: checkMarkSuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getEasyCashOptions = () => {
    return getCashOptions(payableAmount, 4);
  };

  const getCustomPaymentOptions = () => {
    return getCashOptions(payableAmount, 1);
  };

  const handleCancelTransaction = () => {
    if (selectedPinPadId !== '000-000-000') {
      _auth.currentUser.getIdToken(true).then((accessToken) => {
        fetch(`${nextOrderConfigURLSydney}/cancelTransaction`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            storeId: storeConfig?.storeId,
            countryIdentifier: storeConfig.countryIdentifier,
            storePinPadId: selectedPinPadId,
            sessionId: sessionId,
            serviceId: serviceId,
            merchantCode: currentSelectedTerminal?.merchantCode,
            key: '0',
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            console.log(result);
          });
      });
    }
  };

  const handleClosePaymentViewModal = () => {
    if (
      currentSelectedTerminal?.merchantCode === 600 &&
      windcaveTransactionRef
    ) {
      handleWindcaveButton('CANCEL', 'B2', windcaveTransactionRef);
    }
    if (isTerminalOrderInProgress) {
      // cancel request
      handleCancelTransaction();
    }
    toggleModal(false);
  };

  const typeMap = {
    full: 1,
    equal: 2,
    per_item: 3,
    custom: 4,
    split_per_seat: 5,
  };

  const initSplitPayments = useMutation(({ splitPayment, setState }) => {
    return _auth.currentUser.getIdToken(true).then((token) => {
      return axios
        .post(
          `${API_URL}/orders/v1/splitPayments`,
          {
            ...splitPayment,
            type: typeMap[splitPayment.type],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.code === 201) {
            setState({
              ...res.data.data.splitPayment,
              type: [
                ``,
                `full`,
                `equal`,
                `per_item`,
                `custom`,
                `split_per_seat`,
              ][res.data.data.splitPayment.type],
              concluded: true,
              initialChecks: res.data.data.splitPayment.checks,
            });
          }
        });
    });
  });

  const updateSplitPaymentschecks = useMutation(
    ({ splitPayment, setState }) => {
      return _auth.currentUser.getIdToken(true).then((token) => {
        return axios
          .put(
            `${API_URL}/orders/v1/splitPayments`,
            {
              ...splitPayment,
              type: typeMap[splitPayment.type],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.code === 200) {
              setState({
                ...res.data.data.splitPayment,
                type: [
                  ``,
                  `full`,
                  `equal`,
                  `per_item`,
                  `custom`,
                  `split_per_seat`,
                ][res.data.data.splitPayment.type],
                concluded: true,
                initialChecks: res.data.data.splitPayment.checks,
              });
            }
          });
      });
    }
  );

  const deleteCheck = useMutation(({ orderId, checkId, token }) => {
    return axios
      .delete(
        `${API_URL}/orders/v1/splitPayments/checks?orderId=${orderId}&checkId=${checkId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.code === 200) {
          return true;
        }
      });
  });

  const updateSplitPaymentCheck = useMutation((check) => {
    return _auth.currentUser.getIdToken(true).then((token) => {
      return axios.put(
        `${API_URL}/orders/v1/splitPayments/checks?orderId=${splitState.orderId}`,
        {
          ...check,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    });
  });

  const handleWindcaveSplitPayment = async (amount) => {
    const requestObj = {
      storePinPadId: selectedPinPadId,
      merchantCode: 600,
      user: windcaveUser,
      orderId,
      paymentType: '3',
      drawerId: selectedDrawerId,
      amount: Number(Number(amount).toFixed(2)),
      isRefund,
      storeId: storeConfig?.storeId,
      countryIdentifier: storeConfig.countryIdentifier,
      currencyCode: storeConfig.currencyCode,
      sessionId,
      serviceId,
      billType: billType || '',
    };

    setIsPlacingOrder(true);
    setIsWindcaveProcessing(true);
    //apiHandler is placeOrderApi
    const response = await settleOrderApi(requestObj);
    const { success, transactionReference } = response;

    if (!success) {
      //SettleOrder API Failed
      setIsPlacingOrder(false);
      setSplitStatus('error');
      setCashPaid({ cashChange: 0, cashPaid: 0 });

      setTimeout(() => {
        setIsWindcaveProcessing(false);
        setPaymentOptionSelected(null);
        toggleCashAmountModal();
        setSplitStatus('');
      }, [2500]);
    }

    const uniqueSessionId = uuidv4();
    setSessionId(uniqueSessionId);
    setServiceId(getServiceID(uniqueSessionId));

    let listenWindcaveStatusUnsubscribe;

    if (orderId && transactionReference) {
      localStorage.setItem('transactionReference', transactionReference);

      //Listen WindCave collection
      const windCaveStatusRef = _firestore
        .collection('WindcaveStatus')
        .doc(transactionReference);

      listenWindcaveStatusUnsubscribe = windCaveStatusRef.onSnapshot(
        async (querySnapshot) => {
          let windCaveStatusObj = querySnapshot.data();

          const dataFromCache = !!querySnapshot.metadata.fromCache;
          if (!dataFromCache) {
            if (windCaveStatusObj?.transactionReference) {
              //Setting below state because we gonna use this in button action
              setWindcaveTransactionRef(transactionReference);
              setWindcaveInfoMessage(
                `${windCaveStatusObj['displayLine1'] || ''} ${
                  windCaveStatusObj['displayLine2'] || ''
                }`
              );
              setWindcaveButtonOne(windCaveStatusObj['button1'] || '');
              setWindcaveButtonTwo(windCaveStatusObj['button2'] || '');

              if (windCaveStatusObj?.complete === 1) {
                //payment is complete

                if (windCaveStatusObj.AP === 1) {
                  // Transaction SuccessFull
                  listenWindcaveStatusUnsubscribe();
                  const orderRef = _firestore
                    .collection('Orders')
                    .doc(currentOrder?._id);

                  setTimeout(async () => {
                    const doc = await orderRef.get();

                    let obj = doc.data();

                    const paidAmount = obj?.payments?.reduce(
                      (accu, currValue) => {
                        return accu + currValue?.amount;
                      },
                      0
                    );

                    const payableAmount = Number(obj?.payableAmount);

                    const isOrderPaidCompletely =
                      Number(paidAmount.toFixed(2)) ===
                      Number(payableAmount.toFixed(2));

                    if (obj?.isError) {
                      setSplitStatus('failed');
                      setIsWindcaveProcessing(false);
                      setIsPlacingOrder(false);
                      setCashPaid({
                        cashChange: 0,
                        cashPaid: 0,
                      });
                    } else if (!isOrderPaidCompletely) {
                      setSplitStatus('resolved');
                      setIsWindcaveProcessing(false);
                      setIsPlacingOrder(false);
                      dispatch(
                        updateOrder({
                          _id: orderId,
                          payments: obj.payments || [],
                          isSplit: true,
                        })
                      );
                      setOrderCompleted(isOrderPaidCompletely);
                      updateChecks({
                        success: true,
                        orderPayments: obj.payments,
                      });
                    } else if (isOrderPaidCompletely) {
                      updateChecks({
                        success: true,
                        orderPayments: obj.payments,
                      });
                      dispatch(
                        updateOrder({
                          _id: orderId,
                          payments: obj.payments || [],
                          isSplit: true,
                        })
                      );
                      setIsWindcaveProcessing(false);
                      setIsPlacingOrder(false);
                      setOrderCompleted(isOrderPaidCompletely);
                    }

                    setWindcaveButtonOne('');
                    setWindcaveButtonTwo('');

                    setTimeout(() => {
                      setSplitStatus();
                      toggleCashAmountModal();
                      localStorage.removeItem('transactionReference');
                      setIsWindcaveProcessing(false);
                    }, 2000);
                  }, 3000);
                } else if (windCaveStatusObj.AP === 0) {
                  listenWindcaveStatusUnsubscribe();
                  setWindcaveButtonOne('');
                  setWindcaveButtonTwo('');
                  setIsPlacingOrder(false);
                  setTimeout(() => {
                    setSplitStatus();
                    toggleCashAmountModal();
                    localStorage.removeItem('transactionReference');
                    setIsWindcaveProcessing(false);
                  }, 2000);
                }
              }
            }
          }
        }
      );
    } else {
      // settle Order Order API Failed
      setSplitStatus('failed');
      setIsWindcaveProcessing(false);
      setCashPaid({
        cashChange: 0,
        cashPaid: 0,
      });
    }
  };

  const handleOrderSubmit = async ({
    event,
    paymentType,
    isCashAmount = false,
    changeAmount = null,
    cashAmount = null,
    paymentTypeId = '',
    paymentTypeName = '',
    amount = payableAmount,
  }) => {
    //Place order here now
    //props.orderTimes
    const isAutoTerminalPayment =
      paymentType === paymentTypeEnum.CARD_IN_STORE &&
      selectedPinPadId !== '000-000-000';
    let payment = {
      storePinPadId: selectedPinPadId,
      merchantCode: 400,
    };
    if (isAutoTerminalPayment && currentSelectedTerminal) {
      payment = {
        ...payment,
        merchantCode: currentSelectedTerminal?.merchantCode,
      };
    }
    if (isPlacingOrder) {
      return;
    }
    const settleOrderRequestObject = {
      ...payment,
      orderId,
      paymentType,
      drawerId: selectedDrawerId,
      amount: Number(Number(amount).toFixed(2)),
      isRefund,
      storeId: storeConfig?.storeId,
      countryIdentifier: storeConfig.countryIdentifier,
      currencyCode: storeConfig.currencyCode,
      sessionId,
      serviceId,
      billType: billType || '',
    };
    if (paymentTypeId) {
      settleOrderRequestObject.paymentTypeId = paymentTypeId;
    }
    if (paymentTypeName) {
      settleOrderRequestObject.paymentTypeName = paymentTypeName;
    }
    let el = event?.target?.classList;
    if (el) el.add('active');

    if (isAutoTerminalPayment) setIsTerminalOrderInProgress(true);
    setPaymentAnimation(0);
    setErrorMessage('');
    setIsPlacingOrder(true);

    if (
      paymentType === paymentTypeEnum.CASH &&
      selectedDrawerId !== '000-000-000'
    ) {
      const printObj = {
        configUrl: nextOrderConfigURL,
        printerId: selectedDrawerPrinterId,
        orderId: orderId,
        storeId: storeConfig?.storeId,
        restaurantId: storeConfig.restaurantId,
        openCashDrawer: true,
        timeZone: storeConfig.timeZone,
        printReceipt: false,
      };

      await printBillCommand(printObj);
    }
    fetch(`${nextOrderConfig?.cloudFunctionUrl}/settleOrder`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settleOrderRequestObject),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          dispatch(
            updateOrder({
              _id: orderId,
              payments: result.orderPayments,
            })
          );
          setOrderCompleted(result.orderFullyPaidPostPayment);
          updateChecks(result);
        }

        if (amount === payableAmount) {
          const printObj = {
            configUrl: nextOrderConfigURL,

            printerId: selectedDrawerPrinterId,
            orderId: orderId,
            storeId: storeConfig?.storeId,
            restaurantId: storeConfig.restaurantId,
            openCashDrawer: true,
            timeZone: storeConfig.timeZone,
            printReceipt: false,
          };
          openDrawerForCardTransactions && printBillCommand(printObj);

          return postOrderProcess(
            result,
            event,
            currentOrder._id,
            paymentType,
            isCashAmount,
            changeAmount,
            cashAmount
          );
        } else {
          if (selectedDrawerId !== '000-000-000') {
            const printObj = {
              configUrl: nextOrderConfigURL,

              printerId: selectedDrawerPrinterId,
              orderId: orderId,
              storeId: storeConfig?.storeId,
              restaurantId: storeConfig.restaurantId,
              openCashDrawer: false,
              timeZone: storeConfig.timeZone,
              printReceipt: false,
            };
            openDrawerForCardTransactions
              ? printBillCommand(printObj)
              : paymentType === '1' && printBillCommand(printObj);
          }

          // split payment branch
          setIsPlacingOrder(false);
          setSplitStatus(result.success ? 'resolved' : 'error');
          /* if (result.success) {
        const newPaymentIds = result.orderPayments.filter(p => !splitState.checks.some(c => (c.payments || []).includes(p._id))).map(i => i._id);
          setSplitPayments([...splitPayments, ...result.orderPayments.filter(i => newPaymentIds.includes(i._id))])
        } */
          setTimeout(() => {
            setSplitStatus();
            toggleCashAmountModal();
          }, 1000);
        }
      });
  };

  const handlePostCashOrderProcess = async (cashChange, cashPaid) => {
    setIsTerminalOrderInProgress(false);
    setCashPaid({ cashPaid, cashChange });
  };

  const updateChecks = async (result) => {
    if (splitState.type !== `full`) {
      if (
        currentOrder.payments?.length === 0 &&
        !splitState.checks.some((check) => check.id)
      ) {
        // it will run on first payment

        const { checks } = splitState;
        let newCheck = null;

        let _checks = [...checks];

        const orderPaymentCopy = [...result.orderPayments];
        const sortedOrderPayments = orderPaymentCopy.sort(
          (a, b) => b.dateTimestamp - a.dateTimestamp
        );
        const newState = {
          ...splitState,
          checks: _checks.map((c) => {
            // eslint-disable-next-line eqeqeq
            if (c.number == selectedCheckNumber) {
              const previousPayments = c.payments || [];
              newCheck = {
                ...c,
                payments: [sortedOrderPayments[0]._id, ...previousPayments],
                paidAmount: parseFloat(
                  Math.min(result.orderPayments[0].amount).toFixed(2)
                ),
              };
              return newCheck;
            }
            return c;
          }),
        };
        initSplitPayments.mutate({ splitPayment: newState, setState });
      } else {
        const newPaymentIds = result.orderPayments
          .filter(
            (p) =>
              !splitState.checks.some((c) => (c.payments || []).includes(p._id))
          )
          .map((i) => i._id);
        let newCheck = null;
        const newState = {
          ...splitState,
          checks: splitState.checks.map((c) => {
            const newAmount = [...(c.payments || []), ...newPaymentIds].reduce(
              (acc, v) =>
                (acc += result.orderPayments.find((i) => i._id === v).amount),
              0
            );

            // eslint-disable-next-line eqeqeq
            if (c.number == selectedCheckNumber) {
              newCheck = {
                ...c,
                payments: [...(c.payments || []), ...newPaymentIds],
                paidAmount: parseFloat(
                  Math.min(newAmount, payableAmount).toFixed(2)
                ),
              };

              return newCheck;
            }
            return c;
          }),
        };
        if (deleteChecksList.length > 0) {
          let authToken = '';
          await _auth.currentUser.getIdToken(true).then((token) => {
            authToken = token;
          });
          await Promise.all(
            deleteChecksList.map((check) => {
              return deleteCheck.mutate({
                checkId: check.id,
                orderId: splitState.orderId,
                token: authToken,
              });
            })
          ).then(() => {
            updateSplitPaymentschecks.mutate({
              splitPayment: newState,
              setState,
            });
          });
        } else {
          // it will run for all payment except first payment

          updateSplitPaymentschecks.mutate({
            splitPayment: newState,
            setState,
          });
        }
      }
    }
  };

  const updateSplitState = async (result) => {
    if (splitState.type !== `full`) {
      if (!splitState.concluded) {
        const { checks } = splitState;
        let newCheck = null;
        const payingGuests = checks.reduce(
          (acc, v) => (acc += v.numGuests - 1),
          0
        );
        let _checks = [...checks];
        if (payingGuests > 0) {
          _checks = _checks.slice(0, -payingGuests);
        }
        const newState = {
          ...splitState,
          checks: _checks.map((c) => {
            // eslint-disable-next-line eqeqeq
            if (c.number == selectedCheckNumber) {
              newCheck = {
                ...c,
                payments: [result.orderPayments[0]._id],
                paidAmount: parseFloat(
                  Math.min(
                    result.orderPayments[0].amount,
                    payableAmount
                  ).toFixed(2)
                ),
              };
              return newCheck;
            }
            return c;
          }),
        };
        initSplitPayments.mutate({ splitPayment: newState, setState });
        if (
          !result.orderFullyPaidPostPayment &&
          newCheck.paidAmount === parseFloat(payableAmount)
        ) {
          const nextUnpaidCheck = newState.checks.find(
            (c) => (c.paidAmount || 0) < c.payableAmount
          );
          if (nextUnpaidCheck) {
            setTimeout(() => {
              selectCheck(nextUnpaidCheck.number);
              toggleModal(false);
            }, 1000);
          }
        }
      } else {
        const newPaymentIds = result.orderPayments
          .filter(
            (p) =>
              !splitState.checks.some((c) => (c.payments || []).includes(p._id))
          )
          .map((i) => i._id);
        let newCheck = null;
        const newState = {
          ...splitState,
          checks: splitState.checks.map((c) => {
            const newAmount = [...(c.payments || []), ...newPaymentIds].reduce(
              (acc, v) =>
                (acc += result.orderPayments.find((i) => i._id === v).amount),
              0
            );
            // eslint-disable-next-line eqeqeq
            if (c.number == selectedCheckNumber) {
              newCheck = {
                ...c,
                payments: [...(c.payments || []), ...newPaymentIds],
                paidAmount: parseFloat(
                  Math.min(newAmount, payableAmount).toFixed(2)
                ),
              };
              return newCheck;
            }
            return c;
          }),
        };
        setState(newState);

        if (
          !result.orderFullyPaidPostPayment &&
          newCheck.paidAmount === parseFloat(payableAmount)
        ) {
          const nextUnpaidCheck = newState.checks.find(
            (c) => (c.paidAmount || 0) < c.payableAmount
          );
          if (nextUnpaidCheck) {
            setTimeout(() => {
              selectCheck(nextUnpaidCheck.number);
              toggleModal(false);
            }, 1500);
          }
        }
        if (newCheck) {
          updateSplitPaymentCheck.mutate(newCheck);
        }
      }
    }
  };

  const postOrderProcess = async (
    result,
    event,
    orderId,
    paymentType,
    isCashAmount,
    changeAmount,
    cashAmount
  ) => {
    if (result.success) {
      const printObj = {
        configUrl: nextOrderConfigURL,

        printerId: selectedDrawerPrinterId,
        orderId: orderId,
        storeId: storeConfig?.storeId,
        restaurantId: storeConfig.restaurantId,
        openCashDrawer: false,
        timeZone: storeConfig.timeZone,
        printReceipt: false,
      };
      if (selectedDrawerPrinterId) {
        setOrderNumber(result.orderNumber ? result.orderNumber : 0);

        // const { success } =
        //   (printReceipt || paymentType === paymentTypeEnum.CASH) &&
        //   (await printBillCommand(printObj));

        if (isCashAmount) {
          printBillCommand(printObj);
          handlePostCashOrderProcess(changeAmount, cashAmount, orderId);
        } else {
          handleTerminalAnimation(event);
        }
      } else {
        if (isCashAmount) {
          selectedDrawerId !== '000-000-000' && printBillCommand(printObj);
          handlePostCashOrderProcess(changeAmount, cashAmount, orderId);
        } else {
          handleTerminalAnimation(event);
        }
      }
    } else {
      // Failed placing or settling order
      let el = event?.target?.classList;
      if (el) el.remove('active');

      const uniqueSessionId = uuidv4();
      setSessionId(uniqueSessionId);
      setServiceId(getServiceID(uniqueSessionId));
      setIsPlacingOrder(false);

      if (!result?.message?.includes('OPERATOR CANCELLED')) {
        setPaymentAnimation(2);
      } else {
        setFailedLinklyByCancelPaymentResponse(true);
      }
      setIsTerminalOrderInProgress(false);
      setErrorMessage(result.message || '');
    }
    localStorage.removeItem('transactionReference');
  };

  const handleTerminalAnimation = (event) => {
    let el = event?.target?.classList;

    if (el) el.add('active');

    setPaymentAnimation(1);

    let timer1 = setTimeout(() => {
      setPaymentAnimation(0);

      if (el) el.remove('active');

      if (!isKiosk) {
        // if (
        //   (currentOrder.isEditOrder || currentOrder.iteration) &&
        //   currentOrder.billType !== 'equal' &&
        //   currentOrder.billType !== 'per_item'
        // ) {
        //   history.push('/orders');
        // } else {

        dispatch(authenticateStaff(false));
        // }
      } else {
        history.push(kiosk_route);
      }

      toggleModal(false);

      setIsPlacingOrder(false);

      clearTimeout(timer1);
    }, 3000);
  };

  const handleRetryIntegratedTerminal = () => {
    setPaymentAnimation(0);
    handleMarkAsPaidByCard();
  };

  const [showCashAmountModal, toggleCashAmountModal] = useReducer(
    (st) => !st,
    false
  );

  const handleCashOptionsClick = ({ event, cashAmount }) => {
    const changeAmount = Number(cashAmount) - Number(payableAmount);
    handleOrderSubmit({
      event,
      paymentType: '1',
      isCashAmount: true,
      changeAmount,
      cashAmount,
    });
  };

  const handleCustomPaymentOptionsClick = (event, cashAmount = null) => {
    let changeAmount = null;
    if (cashAmount) {
      changeAmount = Number(cashAmount) - Number(payableAmount);
    }
    handleOrderSubmit({
      event,
      paymentType: selectedCustomPaymentType.type,
      paymentTypeId: selectedCustomPaymentType._id,
      paymentTypeName: selectedCustomPaymentType.name,
      ...(cashAmount ? { cashAmount } : null),
      changeAmount,
    });
  };

  const handleModalButtonStatus = useCallback(
    (total) => {
      return getSplitPaymentsCashAmountModalButtonStatus(
        Number(total),
        payableAmount - paidAmount,
        paymentOptionSelected,
        orderCompleted
      );
    },
    [
      payableAmount,
      paymentOptionSelected,
      orderCompleted,
      currentOrder,
      paidAmount,
    ]
  );

  const handleMarkAsPaidByCard = (event) => {
    if (!isPlacingOrder) {
      if (currentSelectedTerminal?.merchantCode === 600) {
        //Settle order Api on Order list screen
        let payment = {
          storePinPadId: selectedPinPadId,
          merchantCode: 600,
          user: windcaveUser,
        };

        if (
          selectedPinPadId &&
          selectedPinPadId !== '000-000-000' &&
          !!windcaveUser &&
          windcaveUser !== 'None'
        ) {
          if (currentOrder) {
            const settleOrderRequestObject = {
              ...payment,
              orderId,
              paymentType: '3',
              drawerId: selectedDrawerId,
              amount: Number(Number(payableAmount).toFixed(2)),
              isRefund,
              storeId: storeConfig?.storeId,
              countryIdentifier: storeConfig.countryIdentifier,
              currencyCode: storeConfig.currencyCode,
              sessionId,
              serviceId,
              billType: billType || '',
            };
            let el = event?.target?.classList;
            if (el) el.add('active');

            setIsTerminalOrderInProgress(true);
            setPaymentAnimation(0);
            setErrorMessage('');
            setIsPlacingOrder(true);
            setTransactionStatusId(-1);
            setWindcaveErrorMessage('');
            setWindcaveInfoMessage('');
            setWindcaveButtonOne('');
            setWindcaveButtonTwo('');

            fetch(`${nextOrderConfig?.cloudFunctionUrl}/settleOrder`, {
              method: 'post',
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settleOrderRequestObject),
            })
              .then(async (response) => {
                const result = await response.json();
                const transactionReference = result?.transactionReference;

                if (result?.success && !!transactionReference) {
                  //Settle Order API Successful

                  let listenWindcaveStatusUnsubscribe;

                  //Save TransactionReference in Local Storage for recovery Mode
                  localStorage.setItem(
                    'transactionReference',
                    transactionReference
                  );
                  setWindcaveTransactionRef(transactionReference);

                  //Listen to windcave status object
                  const windCaveStatusRef = _firestore
                    .collection('WindcaveStatus')
                    .doc(transactionReference);

                  listenWindcaveStatusUnsubscribe =
                    windCaveStatusRef.onSnapshot(async (querySnapshot) => {
                      let windCaveStatusObj = querySnapshot.data();

                      const dataFromCache = !!querySnapshot.metadata.fromCache;

                      if (!dataFromCache) {
                        if (windCaveStatusObj?.transactionReference) {
                          setWindcaveTransactionRef(
                            windCaveStatusObj.transactionReference
                          );
                          setTransactionStatusId(
                            windCaveStatusObj?.transactionStatusId
                          );
                          setWindcaveInfoMessage(
                            `${windCaveStatusObj['displayLine1'] || ''} ${
                              windCaveStatusObj['displayLine2'] || ''
                            }`
                          );

                          setWindcaveButtonOne(
                            windCaveStatusObj['button1'] || ''
                          );
                          setWindcaveButtonTwo(
                            windCaveStatusObj['button2'] || ''
                          );

                          if (windCaveStatusObj?.complete === 1) {
                            //payment is complete

                            if (windCaveStatusObj.AP === 1) {
                              //Transaction SuccessFull

                              listenWindcaveStatusUnsubscribe();

                              const orderRef = _firestore
                                .collection('Orders')
                                .doc(currentOrder?._id);

                              setTimeout(async () => {
                                const doc = await orderRef.get();
                                let obj = doc.data();

                                if (!obj?.isError) {
                                  const paidAmount = obj?.payments?.reduce(
                                    (accu, currValue) => {
                                      return accu + currValue?.amount;
                                    },
                                    0
                                  );

                                  const totalOrderAmount = obj?.payableAmount;

                                  const isOrderPaidCompletely =
                                    Number(paidAmount.toFixed(2)) ===
                                    Number(Number(totalOrderAmount).toFixed(2));

                                  dispatch(
                                    updateOrder({
                                      _id: orderId,
                                      payments: obj.payments || [],
                                    })
                                  );
                                  setOrderCompleted(isOrderPaidCompletely);
                                  updateChecks({
                                    success: true,
                                    orderPayments: obj.payments,
                                  });
                                } else {
                                  result.success = false;
                                }

                                setIsPlacingOrder(false);

                                await postOrderProcess(
                                  result,
                                  event,
                                  currentOrder?._id,
                                  '3',
                                  false,
                                  null,
                                  null
                                );
                              }, 3000);
                            } else if (windCaveStatusObj.AP === 0) {
                              //Transaction Failed
                              listenWindcaveStatusUnsubscribe();
                              await postOrderProcess(
                                { success: false },
                                event,
                                currentOrder?._id,
                                '3',
                                false,
                                null,
                                null
                              );
                            }
                          }

                          if (!windCaveStatusObj?.station) {
                            // windcave transaction cancelled
                            listenWindcaveStatusUnsubscribe();
                            setWindcaveErrorMessage(
                              'Windcave Transaction Cancelled'
                            );
                            setIsPlacingOrder(false);
                            setWindcaveButtonTwo('');
                          }
                        }
                      }
                    });
                } else {
                  //Settle Order API Failed
                  await postOrderProcess(
                    { success: false },
                    event,
                    '',
                    '3',
                    false,
                    null,
                    null
                  );
                }
              })
              .catch(async (e) => {
                //Settle Order API Failed
                await postOrderProcess(
                  { success: false },
                  event,
                  '',
                  '3',
                  false,
                  null,
                  null
                );
              });
          }
        }
      } else {
        //it could be Mark as paid by card or Adyen Payment
        handleOrderSubmit({
          event,
          paymentType: paymentTypeEnum.CARD_IN_STORE,
        });
      }
    }
  };

  const handleWindcaveButton = async (
    value,
    buttonName,
    transactionReference
  ) => {
    setWindcaveActionButtonPressed(true);
    setWindcaveActionButtonPressedName(buttonName);
    const requestObj = {
      txnId: transactionReference,
      station: selectedPinPadId,
      user: windcaveUser,
      val: value,
      name: buttonName,
    };
    const windCaveActionResponse = await fetch(
      `${nextOrderConfig?.baseUrl}/windcave/v1/buttonAction`,
      {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestObj),
      }
    );

    const windCaveActionResponseJson = await windCaveActionResponse.json();

    if (windCaveActionResponseJson?.code === 201) {
      //windave inprogress
    }

    setWindcaveActionButtonPressed(false);
    setWindcaveActionButtonPressedName('');
  };

  const handlePayByCashButtonClick = () => {
    setIsPayingByCash(true);
    if (isTerminalOrderInProgress) {
      setIsCancelledByPayByCash(true);
      setIsPlacingOrder(false);
      handleCancelTransaction();
    }
  };

  const handleCustomPaymentTypeClick = (paymentType) => {
    setSelectedCustomPaymentType(paymentType);
    if (isTerminalOrderInProgress) {
      setIsCancelledByPayByCash(true);
      setIsPlacingOrder(false);
      handleCancelTransaction();
    }
  };

  const handlePrintBill = async () => {
    if (orderId && selectedDrawerPrinterId) {
      let printObj = {
        printerId: selectedDrawerPrinterId,
        restaurantId: storeConfig?.restaurantId,
        orderId,
        storeId: storeConfig?.storeId,
      };
      await printBillCommand(printObj);
    }
  };

  const handleFinishSale = () => {
    toggleModal(false);
    toggleSplitPayment();
  };

  const handlePrintReceipt = async () => {
    setPrintingLoadingStatus(ApiStatusEnum.LOADING);
    await handlePrintBill();
    setPrintingLoadingStatus(ApiStatusEnum.RESOLVED);
    setTimeout(() => {
      handleFinishSale();
    }, 1000);
  };

  const [splitStatus, setSplitStatus] = useState();

  if (showCloseModal)
    return (
      <GeneralModal
        modalStatus
        position="center"
        effect="move"
        overlay=""
        boxWidth="768px"
        boxHeight="506px"
        className="payment-modal cash-amount-selected"
        closeBtn="inside"
      >
        <div className="payment-box">
          <div className="payment-box-header">
            <h2 className="payment-box-title">{`$${Number(cashChange).toFixed(
              2
            )} change`}</h2>
            <p className="payment-box-desc">{`$${Number(cashPaid).toFixed(
              2
            )} paid`}</p>
          </div>
          <div className="payment-box-body">
            <div className="lottie-box">
              <Lottie
                options={defaultOptions}
                height={180}
                width={180}
                eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => {},
                  },
                ]}
              />
            </div>
            <div className="payment-box-methods">
              <div className="payment-box-method-wrap">
                <Button
                  title="Close"
                  onClick={() => {
                    toggleModal(false);
                    setShowCloseModal(false);
                  }}
                  className="finish-sale"
                >
                  <span className="payment-method-name green">Close</span>
                </Button>
              </div>
              <div className="payment-box-method-wrap">
                <Button
                  title="Print Bill"
                  onClick={() => {}}
                  className="print-bill"
                >
                  <span className="payment-method-name green">Print Bill</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </GeneralModal>
    );

  if (splitPayment) {
    return (
      <>
        {showCashAmountModal && (
          <ModalPortal>
            {/* Split Card Payment */}
            <CashAmountModal
              showAmountInTenderButton
              modalStatus
              toggleModal={toggleCashAmountModal}
              handleShouldShowButton={handleModalButtonStatus}
              paymentTypeName={paymentOptionSelected?.name}
              isLoading={isPlacingOrder}
              isResolved={splitStatus === 'resolved'}
              isFailed={splitStatus === 'failed'}
              windcaveButtonOne={windcaveButtonOne}
              windcaveButtonTwo={windcaveButtonTwo}
              handleWindcaveButton={handleWindcaveButton}
              windcaveTransactionRef={windcaveTransactionRef}
              windcaveActionButtonPressed={windcaveActionButtonPressed}
              isWindcaveProcessing={isWindcaveProcessing}
              handleTenderClick={(amount) => {
                if (
                  Number(Number(amount).toFixed(2)) >
                  Number(
                    (
                      Number(Number(payableAmount).toFixed(2)) - paidAmount
                    ).toFixed(2)
                  )
                ) {
                  setCashPaid({
                    cashPaid: amount,
                    cashChange: (
                      parseFloat(amount) -
                      (parseFloat(payableAmount) - paidAmount)
                    ).toFixed(2),
                  });
                }
                const isPaymentTypeCash =
                  paymentOptionSelected.type === paymentTypeEnum.CASH;

                if (
                  currentSelectedTerminal?.merchantCode === 600 &&
                  !isPaymentTypeCash
                ) {
                  const splitAmount = Math.min(
                    parseFloat(amount),
                    parseFloat(payableAmount) - paidAmount
                  );

                  handleWindcaveSplitPayment(splitAmount);
                } else {
                  handleOrderSubmit({
                    paymentType: paymentOptionSelected.type,
                    amount: Math.min(
                      parseFloat(amount),
                      parseFloat(payableAmount) - paidAmount
                    ),
                  });
                }
              }}
              // shouldBeEditable={loadingStatus === ApiStatusEnum.IDLE}
              customErrors={''}
            />
          </ModalPortal>
        )}
        <SplitPaymentsModal
          modalStatus
          toggleModal={() => {
            handleClosePaymentViewModal();
          }}
          payableAmount={payableAmount - paidAmount}
          handleSplitPaymentOptionClick={(option) => {
            setPaymentOptionSelected(option);
            toggleCashAmountModal();
          }}
          orderPayments={
            splitState.type === 'full'
              ? currentOrder.payments
              : Array.isArray(selectedCheck?.payments)
              ? selectedCheck.payments
                  .map((id) => orderPaymentsMap[id])
                  .filter((i) => !!i)
              : []
          }
          // loading={isPlacingOrder}
          changeAmount={cashChange}
          hasOrderBeenCompleted={
            orderCompleted ||
            selectedCheck.paidAmount === selectedCheck.payableAmount
          }
          printingLoadingStatus={printingLoadingStatus}
          handleFinishSaleClick={handleFinishSale}
          handlePrintReceiptClick={handlePrintReceipt}
          showPrintReceiptButton={false}
        />
      </>
    );
  }

  return (
    <div id="payment-view-modal" className="show">
      <div className="modal-wrap">
        <div
          className={classNames('modal-box', {
            'center-position':
              !isPayingByCash && !selectedCustomPaymentType && isKiosk,
          })}
        >
          <div className="modal-body">
            <button
              type="button"
              className="btn round split-btn hover-highlight"
              title="Split"
              onClick={() => {
                if (isTerminalOrderInProgress) {
                  // cancel request
                  handleCancelTransaction();
                }
                toggleSplitPayment();
              }}
            >
              <span className="inner-btn-box">Split Payments</span>
            </button>
            <button
              type="button"
              className="close-modal-btn hover-highlight"
              onClick={() => handleClosePaymentViewModal()}
              title="Close"
            />
            <h3 className="terminal-total-price">{`${
              payableAmount
                ? getAmountWithCountryCurrency({
                    amount: Number(payableAmount),
                    locale: `en-${storeCountryIdentifier}`,
                    currencyCode: currencySymbol,
                  })
                : ''
            }`}</h3>
            {paymentAnimation === 2 ? (
              <PaymentModalFailScreen
                pinPads={pinPads}
                selectedPinPadId={selectedPinPadId}
                handlePinPadChange={() => {}}
                setModeToManual={() => {}}
                handleRetryIntegratedTerminal={handleRetryIntegratedTerminal}
              />
            ) : (
              <div className="terminal-wrap">
                <p className="terminal-text">
                  {paymentAnimation === 0
                    ? `${isRefund ? 'Refund' : 'Ready to Pay'}`
                    : ''}
                  {paymentAnimation === 1 ? 'Order Placed' : ''}
                  {orderNumber !== 0 && `(order#${orderNumber})`}
                  {paymentAnimation === 2 && errorMessage ? errorMessage : ''}
                </p>
                <div className="terminal-box">
                  <img
                    src={terminalPng}
                    alt="Terminal"
                    className={paymentAnimation !== 0 ? 'hide' : ''}
                  />
                  <div
                    className="terminal-action-box"
                    disabled={isPlacingOrder}
                    style={disableButtonStyle}
                  >
                    {paymentAnimation === 1 ? (
                      <Lottie
                        style={{
                          position: 'relative',
                          top: '-20px',
                          left: '-15px',
                        }}
                        options={checkMarkSuccessLottie}
                        height={192}
                        width={192}
                        eventListeners={[
                          {
                            eventName: 'complete',
                            callback: () => {},
                          },
                        ]}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            )}

            {paymentAnimation === 0 ? (
              <div className="payment-services-box">
                {!windcaveButtonOne && !windcaveButtonTwo && (
                  <button
                    type="button"
                    onClick={(event) => handleMarkAsPaidByCard(event)}
                  >
                    {isPlacingOrder ? (
                      <PosIconLoading mainColor="#5eb602" />
                    ) : (
                      <span>
                        {isProcessTerminalPayment
                          ? 'Press to trigger payment terminal'
                          : 'Mark as paid by card'}
                      </span>
                    )}
                  </button>
                )}

                {windcaveButtonOne && (
                  <button
                    type="button"
                    onClick={() =>
                      handleWindcaveButton(
                        windcaveButtonOne,
                        'B1',
                        windcaveTransactionRef
                      )
                    }
                    className="windcaveButton"
                    disabled={windcaveActionButtonPressed}
                  >
                    <div className="windcaveButtonItem">
                      {windcaveActionButtonPressed &&
                      windcaveActionButtonPressedName === 'B1' ? (
                        <LoadingIcon width={20} color="#5eb602" />
                      ) : (
                        <CheckIcon width={25} />
                      )}
                      <span className="windcaveButtonText">
                        {windcaveButtonOne}
                      </span>
                    </div>
                  </button>
                )}

                {windcaveButtonTwo && (
                  <button
                    type="button"
                    onClick={() =>
                      handleWindcaveButton(
                        windcaveButtonTwo,
                        'B2',
                        windcaveTransactionRef
                      )
                    }
                    className={clsx('windcaveButton', {
                      ['windcaveButtonDisabled']: windcaveActionButtonPressed,
                    })}
                    disabled={windcaveActionButtonPressed}
                  >
                    <div className="windcaveButtonItem">
                      {windcaveActionButtonPressed &&
                      windcaveActionButtonPressedName === 'B2' ? (
                        <LoadingIcon width={20} color="#f40000" />
                      ) : (
                        <XRoundedIcon width={20} className="windcaveItemIcon" />
                      )}

                      <span
                        className="windcaveButtonText"
                        style={{ color: '#f40000' }}
                      >
                        {windcaveButtonTwo}
                      </span>
                    </div>
                  </button>
                )}
              </div>
            ) : null}
          </div>

          <div
            className={classNames('modal-pre-footer', {
              hide: !isPayingByCash && !selectedCustomPaymentType && isKiosk,
            })}
          >
            {isPlacingOrder &&
              !windcaveErrorMessage &&
              windcaveInfoMessage !== 'Approved' && (
                <div className="paymentWarningContainer">
                  {!!windcaveInfoMessage && transactionStatusId !== 8 ? (
                    <PosIconLoading mainColor="#f39c12" size={25} />
                  ) : (
                    <WarningIcon width={25} />
                  )}

                  <span style={{ marginLeft: '10px' }}>
                    {!!windcaveInfoMessage
                      ? windcaveInfoMessage
                      : ` Payment is in process. Please don't close the modal.`}
                  </span>
                </div>
              )}
            {!!windcaveErrorMessage && (
              <div className="paymentWarningContainer">
                <WarningIcon width={25} color="#f40000" />
                <span style={{ marginLeft: '10px', color: '#f40000' }}>
                  {windcaveErrorMessage}
                </span>
              </div>
            )}
            <h2
              className="modal-title"
              style={
                !isPayingByCash && !selectedCustomPaymentType
                  ? { visibility: 'hidden' }
                  : null
              }
            >
              {selectedCustomPaymentType ? selectedCustomPaymentType.name : ''}
              {isPayingByCash ? 'Cash' : ''}
            </h2>
            {showCashAmountModal && (
              <ModalPortal>
                {/* Split CashPayment */}
                <CashAmountModal
                  showAmountInTenderButton
                  modalStatus
                  toggleModal={toggleCashAmountModal}
                  handleShouldShowButton={handleModalButtonStatus}
                  paymentTypeName={paymentOptionSelected?.name}
                  isLoading={isPlacingOrder}
                  isResolved={splitStatus === 'resolved'}
                  isFailed={splitStatus === 'failed'}
                  handleTenderClick={(amount) => {
                    if (amount > payableAmount - paidAmount) {
                      setCashPaid({
                        cashPaid: amount,
                        cashChange: (
                          parseFloat(amount) -
                          (parseFloat(payableAmount) - paidAmount)
                        ).toFixed(2),
                      });
                      handleOrderSubmit({
                        paymentType: paymentTypeEnum.CARD_IN_STORE,
                        amount: Math.min(
                          parseFloat(amount),
                          parseFloat(payableAmount) - paidAmount
                        ),
                      });
                    }
                  }}
                  // shouldBeEditable={loadingStatus === ApiStatusEnum.IDLE}
                  customErrors={''}
                  isWindcaveProcessing={isWindcaveProcessing}
                />
              </ModalPortal>
            )}
            {!isKiosk && (
              <>
                {!isPayingByCash && !selectedCustomPaymentType ? (
                  <div className="payment-types-container">
                    <div
                      className="retry-side-div cursor-pointer"
                      onClick={handlePayByCashButtonClick}
                    >
                      Pay by cash
                    </div>
                    {paymentTypesForOrderType?.map((paymentType) => (
                      <div
                        className="retry-side-div cursor-pointer"
                        onClick={() =>
                          handleCustomPaymentTypeClick(paymentType)
                        }
                        key={paymentType._id}
                      >
                        {paymentType.name}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="cash-cards">
                    {Number(payableAmount) > 0 &&
                      isPayingByCash &&
                      getEasyCashOptions(payableAmount)?.map(
                        (cashOption, index) => {
                          return (
                            <PaymentAmountOption
                              currency={storeConfig.currency}
                              handlePaymentOptionClick={handleCashOptionsClick}
                              disabled={isPlacingOrder}
                              style={disableButtonStyle}
                              key={index}
                              cashOption={cashOption}
                            />
                          );
                        }
                      )}
                    {Number(payableAmount) > 0 &&
                      selectedCustomPaymentType &&
                      getCustomPaymentOptions(payableAmount)?.map(
                        (cashOption, index) => {
                          return (
                            <PaymentAmountOption
                              currency={storeConfig.currency}
                              handlePaymentOptionClick={
                                handleCustomPaymentOptionsClick
                              }
                              disabled={isPlacingOrder}
                              style={disableButtonStyle}
                              key={index}
                              cashOption={cashOption}
                            />
                          );
                        }
                      )}
                    <button
                      type="button"
                      className="cash-card cash-amount-btn"
                      disabled={isPlacingOrder}
                      style={{ ...disableButtonStyle, marginLeft: 'auto' }}
                      onClick={toggleCashAmountModal}
                    >
                      <span className="title">Amount</span>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal-overlay"
        onClick={() => {
          handleClosePaymentViewModal();
        }}
      />
    </div>
  );
};

export default PaymentViewModal;
