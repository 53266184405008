import React, {
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useState,
} from 'react';
import MapModalWrapper from 'components/MapModalWrapper';
import LeafletGeneric from 'components/Tracking/LeafletGeneric';
import moment from 'moment';
import { matchAddressWithSuburbs } from 'util/deliveryAddressUtils';
import orderEngine from 'orderEngine';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryCostFromApi } from 'services/orderFlowServices';
import { PosIconClock } from '../../assets/icons/PosIconClock';
import { PosIconExpandFullscreen } from '../../assets/icons/PosIconExpandFullscreen';
import { PosIconModernHouse } from '../../assets/icons/PosIconModernHouse';
import { PosIconPhoneInverse } from '../../assets/icons/PosIconPhoneInverse';
import { PosIconShapeMan } from '../../assets/icons/PosIconShapeMan';
import { PosIconWriteMessage } from '../../assets/icons/PosIconWriteMessage';
import mapMarker from '../../assets/img/phone-number/map_pin.png';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import GeneralModal from '../../modals/GeneralModal/index-new';
import InnerSelectTimeModal from '../../modals/InnerSelectTimeModal';
import {
  updateCurrentOrder,
  toggleBlurEffect,
} from '../../pages/Dashboard/action';
import {
  allStoresSelector,
  storeConfigSelector,
  useRadiusBasedDeliveryAreaCalulationSelector,
} from '../../pages/Dashboard/selectors';
import { RadixSelectUI, SwitchButtonsGroupUI } from '../../UI/components';
import { getFutureTime } from '../../util/dateUtils';
import { getTimestampForMomentDate } from '../../util/timeHelper';
import Button from '../Button';
import DeliveryAddressSearch from '../DeliveryAddressSearch/DeliveryAddressSearch';
import PostCodeSearch from 'components/PostCodeSearch/PostCodeSearch';
import AlertModal from 'components/AlertModal';
import { usePostCodeApi } from '../../hooks/usePostCodeApi';
import { useDebounce } from '../../hooks/useDebounce';
import { updateCurrentOrderDeliveryLocation } from '../../pages/Dashboard/action';
import useIsKiosk from 'hooks/useIsKiosk';
import clsx from 'clsx';

const initialTimeIndex = {
  dayIndex: 0,
  timeIndex: 0,
};

function getTimeBtnData(orderTimes, timeIndex) {
  let newBtnText = '';

  const activeSlideDay = timeIndex.dayIndex;
  const activeSlideTime = timeIndex.timeIndex;

  const daysArray = orderTimes ? orderTimes.map((m) => m.display) : [];
  const timesArray =
    orderTimes && orderTimes.length > 0 ? orderTimes[activeSlideDay].times : [];

  newBtnText =
    daysArray[activeSlideDay] + ' ' + timesArray[activeSlideTime].displayForUse;

  return newBtnText;
}

const DeliveryDetailsModal = ({
  handleDefaultSelect = () => {},
  modalStatus,
  onModalClose = () => {},
  onBackClick,
  openMapModal,
  onChange,
  currentOrder,
  onConfirmClick,
  errorMessage,
  onResetClick = () => {},
  timeInMinutes,
  orderTimes,
  onSaveDeliveryDate = () => {},
  isOrderTypeDelivery = false,
  parentPage,
  handleManualAddressClick,
}) => {
  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    inputNames,
    onInitInput,
  } = useVirtualKeyboardContext();
  const inputNote = inputNames.orderNotes;
  const inputUnit = inputNames.orderUnit;
  const inputPostCode = inputNames.orderPostCode;
  const refNote = useRef();
  const refUnit = useRef();
  const refPostCode = useRef();
  const refOffset = useRef({
    unit: 0,
    note: 0,
  });

  const [error, setError] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [suggestedData, setSuggestedData] = useState('');
  const [proccedOutsideDeliveryArea, setProccedOutsideDeliveryArea] =
    useState(false);
  const storeConfig = useSelector(storeConfigSelector);
  const [addressTypeId, setAddressTypeId] = useState(
    storeConfig?.country === 'United Kingdom' ? 1 : 0
  );
  const [shouldCallApiFlag, setShouldCallApiFlag] = useState(false);
  const [availableAddresses, setAvailableAddresses] = useState([]);
  const [debouncedSearchInputValue] = useDebounce(
    getInputValue(inputPostCode),
    300
  );
  const iskiosk = useIsKiosk();
  const virtualKeyboard = useSelector(
    (state) => state.dashboardReducer.virtualKeyboard
  );

  const allStores = useSelector(allStoresSelector);
  const { specials, orderSetup, productSetup, suburbs, publicHolidays } =
    useSelector((state) => state.dashboardReducer);
  const useRadiusBasedDeliveryAreaCalulation = useSelector(
    useRadiusBasedDeliveryAreaCalulationSelector
  );
  const countryIdentifier = useSelector(
    (state) => state.dashboardReducer.storeConfig.countryIdentifier
  );

  const [timeIndex, setTimeIndex] = useState(initialTimeIndex);
  const [timeText, setTimeText] = useState(
    getFutureTime(new Date(), timeInMinutes ?? 0)
  );

  const [timeModalStatus, setTimeModalStatus] = useState(false);
  const dispatch = useDispatch();
  const expectedDeliveryTime = useMemo(
    () => `Expected ${timeInMinutes ?? 0} mins`,
    [timeInMinutes]
  );

  const [data, loading] = usePostCodeApi(
    debouncedSearchInputValue,
    shouldCallApiFlag
  );

  useEffect(() => {
    if (data?.features?.length > 0) {
      setAvailableAddresses(data.features);
    }
  }, [data?.features]);

  useEffect(() => {
    const unit = getInputValue(inputUnit);
    virtualKeyboard &&
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          unit,
        })
      );
  }, [getInputValue(inputUnit)]);

  const handleAddressTypeSelection = (type) => {
    setError(false);
    setAddressTypeId(Number(type));
  };

  const addressTypes = [
    {
      title: 'Delivery Address',
      displayOrder: 0,
      type: 'deliveryAddress',
      id: 0,
    },
    {
      title: `Post Code`,
      displayOrder: 1,
      type: 'postCode',
      id: 1,
    },
  ];

  useEffect(() => {
    if (currentOrder?.suburbId) {
      handleDefaultSelect(currentOrder.suburbId);
    }
  }, [currentOrder?.suburbId, handleDefaultSelect]);

  useEffect(() => {
    if (proccedOutsideDeliveryArea) {
      let areaCode = '';
      let area = '';
      if (suggestedData.context.length > 0) {
        suggestedData.context.forEach((data) => {
          if (data.id.includes('postcode')) {
            areaCode = data.text;
          }
          if (data.id.includes('place')) {
            area = data.text;
          }
          if (data.id.includes('locality')) {
            area = data.text;
          }
        });
      }
      const newCurrentOrder = orderEngine(
        {
          ...currentOrder,
          unit: getInputValue(inputUnit) || currentOrder?.unit || '',
          name: '',
          address: suggestedData.locationText,
          addressLocation: suggestedData.location,
          suburbId: '',
          area,
          areaCode,
          isManualAddress: false,
          proccedOutsideDeliveryArea: true,
        },
        specials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      );
      onInitInput('unit', currentOrder?.unit || '');
      onInitInput('notes', '');
      onInitInput('orderGeosuggest', suggestedData.label);

      dispatch(updateCurrentOrder(newCurrentOrder));
      toggleKeyboard(false);
      onConfirmClick();
      setProccedOutsideDeliveryArea(false);
    }
  }, [JSON.stringify(suggestedData), proccedOutsideDeliveryArea]);

  useEffect(() => {
    if (modalStatus) {
      let timeText = getTimeBtnData(orderTimes, timeIndex);
      setTimeText(timeText);
    }
  }, [modalStatus, orderTimes, timeIndex]);

  useEffect(() => {
    if (modalStatus && keyboardVisibility) {
      setTimeout(() => {
        refOffset.current = {
          [inputUnit]: refUnit.current.getBoundingClientRect().top,
          [inputNote]: refNote.current.getBoundingClientRect().top,
        };
      }, 300);
    }
  }, [modalStatus, keyboardVisibility]);
  const toggleTimeModal = (status) => {
    setTimeModalStatus(status);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
  };

  const handleCloseAlertModal = () => {
    setOpenAlertModal(false);
    onChangeInput(inputUnit, '');
    onChangeInput(inputPostCode, '');
  };

  const handleProceddAlertModal = () => {
    setProccedOutsideDeliveryArea(true);
    setError(false);
    setOpenAlertModal(false);
  };

  const handleAddressSelect = useCallback(
    (suggest, deliveryInfo) => {
      setError(false);
      if (!suggest) return;

      const {
        isRadiusDeliveryCost = false,
        isDeliverable = false,
        deliveryCost,
        minOrderValue,
      } = deliveryInfo;

      const suburb = matchAddressWithSuburbs(
        suggest,
        suburbs.filter((suburb) => suburb.storeId === currentOrder.storeId)
      );

      if (isRadiusDeliveryCost && !isDeliverable) {
        setError(true);

        setSuggestedData(suggest);
        onInitInput('orderGeosuggest', suggest.label);
        // return;
      } else if (!isDeliverable && !suburb?._id) {
        setSuggestedData(suggest);
        setError(true);
        onInitInput('orderGeosuggest', suggest.label);
      } else {
        setSuggestedData('');
      }
      let suggestAreaCode = '';
      let suggestArea = '';
      if (suggest.context.length > 0) {
        suggest.context.forEach((data) => {
          if (data.id.includes('postcode')) {
            suggestAreaCode = data.text;
          }
          if (data.id.includes('place')) {
            suggestArea = data.text;
          }
          if (data.id.includes('locality')) {
            suggestArea = data.text;
          }
        });
      }

      const newCurrentOrder = orderEngine(
        {
          ...currentOrder,
          unit: '',
          name: '',
          address: suggest.locationText,
          addressLocation: suggest.location,
          suburbId: suburb?._id || '',
          area: suburb?.area || suggestArea || '',
          areaCode: suburb?.areaCode || suggestAreaCode || '',
          isManualAddress: false,
          ...(isRadiusDeliveryCost ? { deliveryCost, minOrderValue } : null),
        },
        specials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      );

      onInitInput('unit', currentOrder?.unit || '');
      onInitInput('notes', '');
      onInitInput('orderGeosuggest', suggest.label);

      dispatch(updateCurrentOrder(newCurrentOrder));
    },
    [
      currentOrder,
      dispatch,
      onInitInput,
      orderSetup,
      productSetup,
      publicHolidays,
      specials,
      storeConfig,
      suburbs,
    ]
  );

  useEffect(() => {
    dispatch(
      updateCurrentOrderDeliveryLocation({
        unit: getInputValue(inputUnit) || currentOrder?.unit,
      })
    );
  }, [getInputValue(inputUnit)]);

  useEffect(() => {
    dispatch(
      updateCurrentOrderDeliveryLocation({
        notes: getInputValue(inputNote),
      })
    );
  }, [getInputValue(inputNote)]);

  const handleStoreChange = async (value) => {
    if (value) {
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          storeId: value,
        })
      );
    }
  };

  const handleClick = (clickFrom = false) => {
    if (clickFrom) {
      const activeSlideDay = timeIndex.dayIndex;
      const activeSlideTime = timeIndex.timeIndex;

      const timesArray =
        orderTimes && orderTimes.length > 0
          ? orderTimes[activeSlideDay].times
          : [];

      const defaultTime = timesArray[activeSlideTime];
      const defaultTimeString = `${defaultTime.timeObjFinal} ${defaultTime.displayForUse}`;
      // find the orderTime Object for the selected order time,
      // to get the shiftId and either the order is a pre-order or ASAP
      let deliveryDate = moment(defaultTimeString, 'YYYY-MM-DD HH:mm A').format(
        'YYYY-MM-DD HH:mm'
      );
      let deliveryDateTimestamp = getTimestampForMomentDate(
        moment(defaultTimeString, 'YYYY-MM-DD HH:mm A'),
        storeConfig.timeZone,
        'YYYY-MM-DD HH:mm'
      );
      let shiftId = defaultTime.shiftId;
      let isFutureDeliveryDate = defaultTime.display !== 'ASAP';

      dispatch(
        updateCurrentOrder({
          deliveryDate,
          deliveryDateTimestamp,
          shiftId,
          isFutureDeliveryDate,
        })
      );
    }

    if (suggestedData) {
      setOpenAlertModal(true);
    } else {
      toggleKeyboard(false);
      onConfirmClick();
    }
    onChangeInput(inputUnit, '');
    onChangeInput(inputPostCode, '');
  };

  const handleSaveDeliveryDate = () => {
    toggleTimeModal(false);
    handleClick(true);
    onSaveDeliveryDate();
  };

  const handleOnFocus = (e, inputName) => {
    setInputName(inputName);
    toggleKeyboard(true);
    setTimeout(() => {
      const modalEL = e.target.closest('.delivery-details-modal');

      if (modalEL !== undefined) {
        modalEL.scroll(0, refOffset.current[inputName]);
      }
    }, 50);
  };

  useEffect(() => {
    const getRadiusInfo = async () => {
      if (useRadiusBasedDeliveryAreaCalulation) {
        if (Object.keys(currentOrder.addressLocation).length === 0) return;
        const {
          isDeliverable = false,
          deliveryCost,
          minOrderValue,
        } = await getDeliveryCostFromApi(
          storeConfig?.storeId,
          currentOrder.addressLocation
        );

        if (!isDeliverable) {
          setError(true);
          return;
        }
        setError(false);

        const newCurrentOrder = orderEngine(
          {
            ...currentOrder,
            deliveryCost,
            minOrderValue,
          },
          specials,
          orderSetup,
          productSetup,
          suburbs,
          publicHolidays,
          storeConfig
        );
        dispatch(updateCurrentOrder(newCurrentOrder));
      }
    };
    getRadiusInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(toggleBlurEffect(false));
    };
  }, []);

  return (
    <>
      <GeneralModal
        modalStatus={modalStatus}
        toggleModal={onModalClose}
        position="bottom"
        effect="move"
        overlay="blur"
        boxWidth="699px"
        boxHeight="728px"
        className="delivery-details-modal"
        backBtn="outside"
        keyboardVisibility={keyboardVisibility}
        backBtnFnc={() => {
          onBackClick();
          onChangeInput(inputUnit, '');
          onChangeInput(inputPostCode, '');
        }}
      >
        <div className="delivery-details-modal-box">
          <div className="delivery-details-modal-box-inner">
            {isOrderTypeDelivery && (
              <div className="delivery-details-header">
                <div className="inner-col">
                  <div className="delivery-details-title-contianer">
                    <h2 className="delivery-details-title">Delivery Details</h2>
                    {countryIdentifier === 'GB' && (
                      <div>
                        <SwitchButtonsGroupUI
                          switchList={addressTypes}
                          activeSwitchIndex={addressTypeId}
                          setActiveSwitchIndex={handleAddressTypeSelection}
                          buttonModifierClassName="dine-in-buttons"
                          spanStyleForTabs={{ style: { padding: '3px 14px' } }}
                        />
                      </div>
                    )}
                  </div>
                  {currentOrder.firstName ? (
                    <div className="delivery-details-customer name">
                      <span className="icon-box">
                        <PosIconShapeMan mainColor="#494b4c" />
                      </span>
                      <span className="text">{`${
                        currentOrder.firstName || ''
                      } ${currentOrder.lastName || ''}`}</span>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="delivery-details-customer phone">
                    {currentOrder.mobileNumber ? (
                      <>
                        <span className="icon-box">
                          <PosIconPhoneInverse mainColor="#494b4c" />
                        </span>
                        <span className="text">
                          {currentOrder.mobileNumber}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="inner-col">
                  <div
                    title="Expand"
                    className={`delivery-details-map-box ${
                      currentOrder.addressLocation?.lat &&
                      currentOrder.addressLocation?.lng
                        ? ''
                        : 'delivery-details-map-box__empty-map-box'
                    }`}
                  >
                    <Button
                      title="Expand Map"
                      className="expand-map"
                      onClick={openMapModal}
                    >
                      <PosIconExpandFullscreen
                        mainColor="#363636"
                        darkModeColor="#ffffff"
                      />
                    </Button>
                    {currentOrder.addressLocation?.lat &&
                    currentOrder.addressLocation?.lng ? (
                      <MapModalWrapper iframeHeight={171} iframeWidth="100%">
                        <LeafletGeneric
                          delivery={currentOrder.addressLocation}
                          key={`map-box-${currentOrder.addressLocation.lat}`}
                          id="map"
                        />
                      </MapModalWrapper>
                    ) : (
                      <img
                        className="delivery-details-map-pin"
                        src={mapMarker}
                        alt="Pin"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {isOrderTypeDelivery && (
              <div className="delivery-details-body">
                <form
                  className="delivery-details-form"
                  onSubmit={handleOnSubmit}
                  autoComplete="off"
                >
                  <div
                    className={clsx('single-input-box', {
                      ['postcode-container-height ']: addressTypeId === 1,
                    })}
                  >
                    {addressTypeId === 0 && (
                      <label htmlFor="customer-name">Delivery Address</label>
                    )}

                    <div className="search-address-container">
                      {addressTypeId === 0 && (
                        <DeliveryAddressSearch
                          modalStatus={modalStatus}
                          initialLocation={currentOrder.address || ''}
                          handleAddressSelect={handleAddressSelect}
                          handleResetClick={onResetClick}
                          parentPage={parentPage}
                          handleManualAddressClick={handleManualAddressClick}
                          storeId={currentOrder.storeId}
                        />
                      )}
                      {addressTypeId === 1 && (
                        <>
                          <div className="postCodeContainer">
                            <label htmlFor="unit">Post Code</label>
                            <div className="inner-box">
                              <span className="icon-box">
                                <PosIconModernHouse mainColor="#494b4c" />{' '}
                              </span>
                              <input
                                ref={refPostCode}
                                id={inputPostCode}
                                name={inputPostCode}
                                className="input-style white big"
                                // value={currentOrder.unit}
                                value={
                                  currentOrder?.postCode ||
                                  getInputValue(inputPostCode)
                                }
                                onFocus={(e) => {
                                  handleOnFocus(e, inputPostCode);
                                  setShouldCallApiFlag(true);
                                }}
                                // onBlur={handleOnBlur}
                                onChange={(event) => {
                                  onChangeInput(
                                    inputPostCode,
                                    event.target.value
                                  );
                                  onChange(event);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {allStores.length > 1 && (
                        <div
                          className="inner-box bem-btn bem-btn--st-secondary bem-btn--r-m bem-btn--size-m bem-btn--fw-regular bem-btn--cursor-default search-address-select-container"
                          style={
                            addressTypeId === 0
                              ? {
                                  boxShadow: 'none',
                                  borderRadius: 10,
                                  border: '2px solid #e9e9e9',
                                  height: 56,
                                }
                              : {
                                  marginTop: 35,
                                  boxShadow: 'none',
                                  borderRadius: 10,
                                  border: '2px solid #e9e9e9',
                                  height: 56,
                                }
                          }
                        >
                          <RadixSelectUI
                            options={allStores}
                            value={currentOrder.storeId}
                            onValueChange={handleStoreChange}
                            containerClass={'search-address-select'}
                          />
                        </div>
                      )}
                    </div>

                    {addressTypeId === 1 && (
                      <div className="delivery-container">
                        <label htmlFor="customer-name">
                          Available Addresses
                        </label>
                        <PostCodeSearch
                          modalStatus={modalStatus}
                          initialLocation={currentOrder.address || ''}
                          handleAddressSelect={handleAddressSelect}
                          handleResetClick={onResetClick}
                          parentPage={parentPage}
                          handleManualAddressClick={handleManualAddressClick}
                          storeId={currentOrder.storeId}
                          data={availableAddresses}
                          isDisabled={!getInputValue(inputPostCode)}
                        />
                      </div>
                    )}

                    {error && (
                      <p className="delivery-address__error">{errorMessage}</p>
                    )}
                  </div>

                  <div className="single-input-box">
                    <label htmlFor="unit">
                      Apartment / Suite / Floor Number
                    </label>
                    <div className="inner-box">
                      <span className="icon-box">
                        <PosIconModernHouse mainColor="#494b4c" />{' '}
                      </span>
                      <input
                        ref={refUnit}
                        id={inputUnit}
                        name={inputUnit}
                        className="input-style white big"
                        // value={currentOrder.unit}
                        value={currentOrder?.unit || getInputValue(inputUnit)}
                        onFocus={(e) => handleOnFocus(e, inputUnit)}
                        // onBlur={handleOnBlur}
                        onChange={(event) => {
                          onChangeInput(inputUnit, event.target.value);
                          onChange(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className="single-input-box">
                    <label htmlFor="notes">Delivery Notes</label>
                    <div className="inner-box">
                      <span className="icon-box">
                        <PosIconWriteMessage mainColor="#494b4c" />
                      </span>
                      <textarea
                        ref={refNote}
                        id={inputNote}
                        name={inputNote}
                        className="input-style white"
                        placeholder="Enter Delivery Notes"
                        value={getInputValue(inputNote)}
                        onFocus={(e) => handleOnFocus(e, inputNote)}
                        onChange={(event) => {
                          onChangeInput(inputNote, event.target.value);
                          onChange(event);
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
          <div className="delivery-details-btn-box">
            {!iskiosk && (
              <div className="delivery-details-time-box">
                <div className="inner-box">
                  <PosIconClock mainColor="#494b4c" />
                  {expectedDeliveryTime}
                  <Button
                    title="Change"
                    className="btn round"
                    onClick={() => toggleTimeModal(true)}
                  >
                    Change
                  </Button>
                </div>
              </div>
            )}
            <Button
              title="Confirm Delivery Details"
              onClick={() => handleClick(false)}
              className="btn full-width"
              disabled={!currentOrder.address}
              style={
                iskiosk
                  ? {
                      backgroundColor: 'rgb(0, 0, 0)',
                      border: '0.2rem solid rgb(0, 0, 0)',
                    }
                  : {}
              }
            >
              Confirm Delivery Details
            </Button>
          </div>
        </div>

        {timeModalStatus && (
          <InnerSelectTimeModal
            modalStatus={timeModalStatus}
            toggleModal={toggleTimeModal}
            orderTimes={orderTimes}
            onConfirmClick={handleSaveDeliveryDate}
            timeIndex={timeIndex}
            setTimeIndex={setTimeIndex}
            timeText={timeText}
          />
        )}
      </GeneralModal>
      {openAlertModal && (
        <AlertModal
          modalStatus={openAlertModal}
          toggleModal={handleCloseAlertModal}
          header={`Are you sure you want to deliver to this address?`}
          description={`${suggestedData?.locationText} is outside your current delivery area. Please consider whether you want to deliver to this address.`}
          proceedText="Yes"
          cancelText="No"
          handleCancel={handleCloseAlertModal}
          handleProceed={handleProceddAlertModal}
        />
      )}
    </>
  );
};

DeliveryDetailsModal.propTypes = {
  modalStatus: PropTypes.bool,
  onModalClose: PropTypes.func,
  onBackClick: PropTypes.func,
  openMapModal: PropTypes.func,
};

export default DeliveryDetailsModal;
