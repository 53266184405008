import OtpInput from 'react-otp-input';
import { BasePartUI, ButtonUI, InputUI } from '../../UI/components';
import './Login.css';
import clsx from 'clsx';

const AUTH_CODE_NO_OF_CHARS = 6;

const getErrorText = (error, signInError) => {
  if (!(error || signInError)) return;

  if (signInError) {
    return signInError;
  }

  switch (error?.message.trim()) {
    case '404':
      return 'Enter Valid Auth Credentials';
    case '433':
      return 'Something went wrong';
    case '500':
      return 'Something Went Wrong';
    default:
      return 'Something went wrong, please try again';
  }
};

export default function LoginComponent({
  storeAuthCode,
  storePassPhrase,
  handleChange,
  handlePassPhraseChange,
  error,
  signInError,
  fetchEmailPass,
  isPending,
  passPhraseInputRef,
}) {
  const buttonText = isPending ? 'Signing In…' : 'Sign In';
  const buttonIsDisabled =
    isPending || storeAuthCode.length < 6 || storePassPhrase.length < 1;

  const inputsDisabled = isPending;

  const errorText = getErrorText(error, signInError);

  return (
    <section className="login-container">
      <div className="login-box">
        <header className="sign-in-header">
          <h1 className="company-name">Next Order</h1>
          {/* <h2 className="cta-intent">Please enter authentication code</h2> */}
        </header>

        <div className="auth-input-container">
          <div className="authCodeContainer">
            <h1 className="label">Enter Authentication code</h1>
            <OtpInput
              value={storeAuthCode}
              onChange={handleChange}
              numInputs={AUTH_CODE_NO_OF_CHARS}
              isInputNum
              shouldAutoFocus
              inputStyle={clsx('auth-input', {
                ['auth-input-disabled']: inputsDisabled,
              })}
              focusStyle={'auth-input-focused'}
              errorStyle={'auth-input-error'}
              hasErrored={!!error}
              isDisabled={inputsDisabled}
            />
          </div>

          <div>
            <h1 className="label">Enter Pass Phrase</h1>
            <input
              ref={passPhraseInputRef}
              id="passPhrase"
              name="passPhrase"
              placeholder={'Enter Pass Phrase'}
              className={clsx('passPhrase', {
                ['auth-input-error']: !!error,
                ['auth-input-disabled']: inputsDisabled,
              })}
              value={storePassPhrase}
              onChange={(e) => handlePassPhraseChange(e.target.value)}
              disabled={isPending}
            />
          </div>

          {(error || signInError) && (
            <div className="user-error">{errorText}</div>
          )}

          <div className="login-cta-container">
            <button
              className="login-cta"
              onClick={fetchEmailPass}
              disabled={buttonIsDisabled}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
