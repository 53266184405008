import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PosIcon } from './PosIcon';

export const PosIconRepeat = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-repeat': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 26 19.1"
      >
        <title>Repeat-Once-2</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M18.5,4.6v1c3.3,0,6,2.7,6,6s-2.7,6-6,6h-11c-3.3,0-6-2.7-6-6s2.7-6,6-6h7.2l-3.1,3.1l0.7,0.7l4-4
	c0.2-0.2,0.2-0.5,0-0.7l0,0l-4-4l-0.7,0.7l3.2,3.1H7.5c-3.9,0-7,3.1-7,7s3.1,7,7,7h11c3.9,0,7-3.1,7-7S22.4,4.6,18.5,4.6L18.5,4.6z"
        />
      </svg>
    </span>
  );
};

PosIconRepeat.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
