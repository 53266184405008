import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  movingOrder: false,
}

export const tableArranementSlice = createSlice({
  name: 'tableArranement',
  initialState,
  reducers: {
    setState: (state, action) => ({ ...state, ...action.payload }),
  },
})

export const { setState, setOrders, setSelectedOrder, setFilters, resetFilters } = tableArranementSlice.actions

export default tableArranementSlice.reducer
