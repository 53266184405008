import React from 'react';
import { NavLink } from 'react-router-dom';
import { TextBubble } from '../TextBubble';
import { useSortByDisplayOrder } from '../../customHooks/useSortByDisplayOrder';
import { getSizeBubbleText } from '../../itemProcessor';
import { getModifierBubbleText } from '../../itemProcessor';

export const Modifiers = (props) => {
  
  //Sizes and modifiers array for the selected item
  const selectedSizes = props?.currentItem?.selectedSizes
    ? props?.currentItem?.selectedSizes
    : [];
  const selectedModifiers = props.currentItem?.selectedModifiers
    ? props.currentItem?.selectedModifiers
    : [];

  //Modifiers array sorted based on display order

  let modifiersArr = [];
  modifiersArr =  useSortByDisplayOrder(selectedModifiers);

  const checkModifierSequenceOnClick=(event,index,modifierArray)=>{
    
    if(index !==0){
     const bubbleText =getModifierBubbleText(
        props.currentItem,
        modifierArray[index-1]._id
      )
      !bubbleText && event.preventDefault()
    }
    
  }

  return props.currentItem.itemType === 0 ? (
    <nav
      defaultactivekey={`${props.parentPage}/menuitem/${props.currentItem._id}/size`}
      className="category-nav top"
    >
      <NavLink
        key={0}
        to={`${props.parentPage}/menuitem/${props.currentItem._id}/size`}
        className={'nav-link with-icon'}
        activeClassName="active"
        title="Size"
      >
        <span>Size</span>
        <TextBubble bubbleText={getSizeBubbleText(selectedSizes)} />
      </NavLink>
    </nav>
  ) : (
    <nav
      defaultactivekey={`${props.parentPage}/menuitem/${
        props.currentItem._id
      }/modifiers/${
        modifiersArr
          .filter((item) => item._id !== undefined)
          .map((m) => m._id)[0]
      }`}
      className="category-nav top"
    >
      <>
        {modifiersArr
          .filter((item) => item._id !== undefined)
          .map((item,index) => {
            return (
              <NavLink
                key={item._id}
                to={`${props.parentPage}/menuitem/${props.currentItem._id}/modifiers/${item._id}`}
                className={'nav-link with-icon'}
                activeClassName="active"
                title={item.name}
                onClick={(event)=>checkModifierSequenceOnClick(event,index,modifiersArr)}
              >
                <span>{item.name} </span>
                <TextBubble
                  bubbleText={getModifierBubbleText(
                    props.currentItem,
                    item._id
                  )}
                />
              </NavLink>
            );
          })}
      </>
    </nav>
  );
};
