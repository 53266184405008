import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PosIconClose } from '../../assets/icons/PosIconClose';
import { PosIconLeftChevron } from '../../assets/icons/PosIconLeftChevron';
import Button from '../../components/Button';

import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import { toggleBlurEffect } from '../../pages/Dashboard/action';
import CloseButton from './CloseButton';

const GeneralModal = ({
  id = '',
  children = <div></div>,
  modalStatus = false,
  toggleModal = () => {},
  position = 'center',
  effect = 'move',
  overlay = 'blur',
  containerStyle = {},
  paperContianerStyle = {},
  boxWidth = '400px',
  boxHeight = '100%',
  className = '',
  closeBtn,
  style = {},
  overlayStyle = {},
  backBtn = '',
  backBtnFnc = undefined,
  errorEffect = false,
  keyboardVisibility = false,
  isResponsiveHeight,
  minBoxHeight,
  showCloseBtn = true,
}) => {
  const dispatch = useDispatch();
  const blurEffect = useSelector((state) => state.dashboardReducer.blurEffect);
  const modalRef = useRef(null);
  const modalStatusRef = useRef(false);
  const { toggleKeyboard } = useVirtualKeyboardContext();

  useEffect(() => {
    if (modalStatus) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = '';
    }

    if (modalStatus && !modalStatusRef.current) {
      modalRef.current.classList.add('show');
      modalStatusRef.current = true;
      if (overlay === 'blur' && !blurEffect) {
        dispatch(toggleBlurEffect(true));
      }
    }

    if (!modalStatus && modalStatusRef.current) {
      modalStatusRef.current = false;
      if (modalRef.current && blurEffect) {
        modalRef.current.classList.add('hide-anim');
        dispatch(toggleBlurEffect(false));
      }

      setTimeout(() => {
        modalRef.current?.classList?.remove('hide-anim');
        modalRef.current?.classList?.remove('show');
      }, 350);
    }
    return () => {
      document.body.style.overflowY = '';
    };
  }, [modalStatus, overlay, blurEffect, dispatch]);

  useEffect(() => {
    if (errorEffect) {
      modalRef.current?.classList?.add('error');
    } else {
      modalRef.current?.classList?.remove('error');
    }
  }, [errorEffect]);

  const modalCls = clsx({
    'generalModal generalModal-2-wrap': true,
    [`${className}`]: className,
    [`${position}`]: position,
    [`${effect}-effect`]: effect,
    'default-overlay': overlay !== 'blur',
    'generalModal-2-wrap--responsive-height': isResponsiveHeight,
  });

  const handleClick = useCallback(
    (e) => {
      toggleKeyboard(false);

      if (backBtn && typeof backBtnFnc === 'function') {
        backBtnFnc();
      } else {
        toggleModal(e, false);
      }
    },
    [backBtn, backBtnFnc, toggleKeyboard, toggleModal]
  );

  return (
    <>
      <div className={modalCls} ref={modalRef} id={id} style={style}>
        <div className="generalModal-2-container-wrap">
          <div className="generalModal-2-container" style={containerStyle}>
            {showCloseBtn && (
              <CloseButton
                close={!backBtn && closeBtn === 'outside'}
                onClick={handleClick}
              />
            )}
            <div
              style={overlayStyle}
              className="generalModal-2-overlay"
              onClick={(e) => toggleModal(e, false)}
            />

            <div
              // className="generalModal-2-box"
              className={clsx('generalModal-2-box', {
                'show-simple-keyboard': keyboardVisibility,
              })}
              style={{
                width: boxWidth,
                height: !isResponsiveHeight ? boxHeight : '100%',
                maxHeight: isResponsiveHeight ? boxHeight : '100%',
                minHeight:
                  !isResponsiveHeight && minBoxHeight ? minBoxHeight : 'auto',
                ...paperContianerStyle,
              }}
            >
              {closeBtn === 'inside' || backBtn === 'inside' ? (
                <Button
                  className="generalModal-2-close-btn inside"
                  title="Close"
                  onClick={handleClick}
                >
                  {closeBtn === 'inside' ? (
                    <PosIconClose
                      mainColor="#363636"
                      lineThickness={1.5}
                      darkModeColor="#ffffff"
                    />
                  ) : backBtn === 'inside' ? (
                    <PosIconLeftChevron
                      mainColor="#363636"
                      darkModeColor="#ffffff"
                    />
                  ) : (
                    ''
                  )}
                </Button>
              ) : (
                ''
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// GeneralModal.propTypes = {
//   id: PropTypes.string,
//   children: PropTypes.any,
//   modalStatus: PropTypes.bool,
//   toggleModal: PropTypes.func,
//   position: PropTypes.string || undefined,
//   effect: PropTypes.string || undefined,
//   overlay: PropTypes.string || undefined,
//   boxWidth: PropTypes.string || undefined,
//   boxHeight: PropTypes.string || undefined,
//   className: PropTypes.any || undefined,
//   errorEffect: PropTypes.bool || undefined,
//   keyboardVisibility: PropTypes.bool || undefined,
//   closeBtn: PropTypes.string || undefined,
//   backBtn: PropTypes.string || undefined,
//   backBtnFnc: PropTypes.func || undefined,
// };

// GeneralModal.defaultProps = {
//   id: '',
//   children: '',
//   modalStatus: false,
//   toggleModal: () => {},
//   position: 'center',
//   effect: 'move',
//   overlay: 'blur',
//   boxWidth: '400px',
//   boxHeight: '100%',
//   className: '',
//   errorEffect: false,
//   closeBtn: '',
//   backBtn: '',
//   backBtnFnc: undefined,
//   keyboardVisibility: false,
// };

export default GeneralModal;
