import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconCashRegisterInverse = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-cash-register': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="21"
        viewBox="0 0 22 21"
      >
        <defs>
          <clipPath id="18sva">
            <path d="M0 .38h22v20.24H0z" />
          </clipPath>
          <clipPath id="18svb">
            <path d="M0 21.5h22v-22H0z" />
          </clipPath>
        </defs>

        <g>
          <g>
            <g>
              <g />
              <g clipPath="url(#18sva)">
                <path
                  fill={iconColor ? iconColor : '#000'}
                  stroke={iconColor ? iconColor : '#000'}
                  strokeWidth={lineThickness ? lineThickness : 0}
                  d="M22 14.706v4.814c0 .606-.493 1.1-1.1 1.1H1.1c-.607 0-1.1-.494-1.1-1.1v-4.814l2.64-5.192V5c0-.607.493-1.1 1.1-1.1h1.54V.82c0-.194.123-.37.308-.423a.465.465 0 0 1 .502.176l.51.775.51-.775c.168-.237.572-.237.74 0l.519.783.51-.747a.445.445 0 0 1 .493-.176c.185.061.308.228.308.422V3.9h8.58c.607 0 1.1.493 1.1 1.1v4.514zM3.52 9.18h14.96V5a.217.217 0 0 0-.22-.22H9.68v.88h.88v.88H4.4v-.88h.88v-.88H3.74a.217.217 0 0 0-.22.22zm3.52 8.8h7.92v-.88H7.04zm13.817-3.52l-2.2-4.4H3.353l-2.201 4.4z"
                />
              </g>
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M17.16 5.66h-4.4a.436.436 0 0 0-.44.44v1.76c0 .246.194.44.44.44h4.4c.246 0 .44-.194.44-.44V6.1a.436.436 0 0 0-.44-.44"
              />
            </g>
            <g />
            <g clipPath="url(#18svb)">
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M4.4 11.82h2.64v-.88H4.4z"
              />
            </g>
            <g clipPath="url(#18svb)">
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M3.52 13.58h3.52v-.88H3.52z"
              />
            </g>
            <g clipPath="url(#18svb)">
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M7.92 13.58h2.64v-.88H7.92z"
              />
            </g>
            <g clipPath="url(#18svb)">
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M7.92 11.82h2.64v-.88H7.92z"
              />
            </g>
            <g clipPath="url(#18svb)">
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M11.44 11.82h2.64v-.88h-2.64z"
              />
            </g>
            <g clipPath="url(#18svb)">
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M11.44 13.58h2.64v-.88h-2.64z"
              />
            </g>
            <g clipPath="url(#18svb)">
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M14.96 13.58h3.52v-.88h-3.52z"
              />
            </g>
            <g clipPath="url(#18svb)">
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M14.96 11.82h2.64v-.88h-2.64z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconCashRegisterInverse.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
