import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconCircles = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-circles': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="7"
        viewBox="0 0 22 7"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M6.38 3.5c0 1.657-1.316 3-2.94 3C1.816 6.5.5 5.157.5 3.5s1.316-3 2.94-3c1.624 0 2.94 1.343 2.94 3zm7.56 0c0 1.657-1.316 3-2.94 3-1.624 0-2.94-1.343-2.94-3S9.376.5 11 .5c1.624 0 2.94 1.343 2.94 3zm7.56 0c0 1.657-1.316 3-2.94 3-1.624 0-2.94-1.343-2.94-3s1.316-3 2.94-3c1.624 0 2.94 1.343 2.94 3z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default PosIconCircles.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
