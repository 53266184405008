import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DeliveriesSidebar } from './DeliveriesSidebar';
import ModalViewOrdersTime from '../../modals/ModalViewOrdersTime';
import { DriversMapIframe } from './DriversMapIframe';
import DriverOrderList from './content/DriverOrderList';
import { DriversMapMaxModal } from '../../modals/DriversMapMaxModal';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { filteredDriversSelector } from '../../pages/Drivers/selectors';
import { ScrollableBoxUI } from '../../UI/components';

const DriverDeliveriesDetails = () => {
  const { driverId } = useParams();
  const dispatch = useDispatch();
  const {
    driversWaitingOnStore = [],
    driversOnDelivery = [],
    driversReturningToStore = [],
    clockedOutDrivers = [],
  } = useSelector(filteredDriversSelector) || {};

  const fullScreen = useSelector((state) => state.dashboardReducer.fullScreen);
  const currentDriverData =
    [
      ...driversWaitingOnStore,
      ...driversOnDelivery,
      ...driversReturningToStore,
      ...clockedOutDrivers,
    ].find((driver) => driver._id === driverId) || {};

  const { status, statusCls } = useMemo(() => {
    if (currentDriverData.status === 0) {
      if (currentDriverData.isActive) {
        return { status: 'Waiting on Store', statusCls: 'green' };
      }
      return { status: 'Clocked Out', statusCls: 'grey' };
    }
    if (currentDriverData.status === 1) {
      return { status: 'On Delivery', statusCls: 'red' };
    }
    if (currentDriverData.status === 2) {
      return { status: 'Returning to Store', statusCls: 'orange' };
    }
    return { status: '', statusCls: '' };
  }, [currentDriverData.status, currentDriverData.isActive]);

  const [modalStatus, setModalStatus] = useState(false);
  // const driverData = useCurrentDriver(Number(driverId));
  const mapWidth = fullScreen ? window.innerWidth - 426 : 599;
  const mapHeight = 520;
  const cls = clsx({
    'delivery-driver-map-box': true,
    shadow: true,
  });

  const handleOpenModal = () => {
    setModalStatus(true);
  };

  const handleToggleModal = (e, status) => {
    setModalStatus(status);
  };

  const handleBackClick = () => {
    dispatch({
      type: 'setSelectedDriverForDeliveries',
      payload: null,
    });
  };

  return (
    <>
      <div className="driver-main-wrap">
        <DeliveriesSidebar />

        <div className="driver-main single-driver-style">
          <div className="driver-inner-box">
            <div className="single-driver-header">
              <Link
                to="/drivers/deliveries/"
                className="back-modal-btn hover-highlight"
                title="Back"
                onClick={handleBackClick}
              />

              <button
                type="button"
                className="modal-delivery-action-btn hover-highlight"
              ></button>

              <div className="booking-inner-table">
                <div className="inner-cell">
                  <h2 className="modal-title">{`${
                    currentDriverData.firstName || ''
                  } ${currentDriverData.lastName || ''}`}</h2>
                </div>
                <div className="inner-cell">
                  <ul className="booking-actions-list">
                    <li>
                      <button
                        className="booking-action-btn hover-highlight"
                        type="button"
                        title="Call"
                      >
                        <span className="icon-box">
                          <span className="dine_icon-phone" />
                        </span>
                        <span className="name">Call</span>
                      </button>
                    </li>
                    <li>
                      <button
                        className="booking-action-btn hover-highlight"
                        type="button"
                        title="Message"
                      >
                        <span className="icon-box">
                          <span className="dine_icon-chat_bubble" />
                        </span>
                        <span className="name">Message</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="order-status-row">
              <div className="order-status-box">
                <span className="icon-box red">
                  <span className="dine_icon-bike" />
                </span>
                <span className="desc">
                  <span className={statusCls}>{status} </span>&nbsp;- 20:23
                </span>
              </div>
              <div className="order-status-box">
                <span className="icon-box">
                  <span className="dine_icon-home" />
                </span>
                <span className="desc">
                  Returning in {currentDriverData.arrivingToStoreTime}
                </span>
              </div>
            </div>

            <div className={cls}>
              <button
                type="button"
                title="Open Modal"
                className="map-full-btn hover-highlight"
                onClick={handleOpenModal}
              />
              <DriversMapIframe
                iframeWidth={mapWidth}
                iframeHeight={mapHeight}
                driver={currentDriverData}
                id="map"
              />
            </div>
            <div className="driver-orders-wrap">
              <ScrollableBoxUI
              // actionType="scrollDeliveryOrders"
              >
                <div className="details-tab-wrap unassigned">
                  <DriverOrderList
                    orders={currentDriverData.activeOrders || []}
                    currentDriverData={currentDriverData}
                  />
                </div>
              </ScrollableBoxUI>
            </div>
          </div>
        </div>
      </div>

      <ModalViewOrdersTime
        timeType={'collectionTime'}
        averageVal={'32 mins'}
        modalTitle={'Collection Time'}
      />

      <ModalViewOrdersTime
        timeType={'deliveryTime'}
        averageVal={'32 mins'}
        modalTitle={'Delivery Time'}
      />
      <DriversMapMaxModal
        toggleModal={handleToggleModal}
        modalStatus={modalStatus}
        driver={currentDriverData}
      />
    </>
  );
};

export default DriverDeliveriesDetails;
