import React from 'react';
import { useSelector } from 'react-redux';
import SunImage from '../../assets/img/home/sun.png';
import { storeCitySelector } from '../../pages/Dashboard/selectors';

const WeatherBox = () => {
  const storeCity = useSelector(storeCitySelector);
  return (
    <div className="weather-box">
      <div className="left-box">
        <span className="location">{storeCity}</span>
        <span className="temperature">21°</span>
      </div>
      <div className="right-box">
        <span className="weather">
          <span className="sun-box">
            <img src={SunImage} alt="Sun" />
          </span>
          <span className="text">Mostly Sunny</span>
          <span className="temperature">
            <span>24°</span> 13°
          </span>
        </span>
      </div>
    </div>
  );
};

export default WeatherBox;
