import { OrderTypeIdEnum } from 'configuration/enums';
import { FetchStatusEnum } from '../../configuration/constants';

const initState = {
  orderFetchStatus: FetchStatusEnum.NOT_STARTED,
  orderTypeFilter: 'all',
  orderTimeSortOrderFilter: 'expectedTime',
  viewOrdersSort: [
    {
      id: 1,
      title: 'All Orders',
      type: 'all',
      status: true,
      orderType: [
        OrderTypeIdEnum.WALK_IN,
        OrderTypeIdEnum.PICK_UP,
        OrderTypeIdEnum.DELIVERY,
        OrderTypeIdEnum.DINE_IN,
        OrderTypeIdEnum.DRIVE_THRU,
      ],
    },
    {
      id: 2,
      title: 'Pickup',
      type: 'pickupId',
      status: false,
      orderType: [OrderTypeIdEnum.WALK_IN, OrderTypeIdEnum.PICK_UP],
    },
    {
      id: 3,
      title: 'Delivery',
      type: 'deliveryId',
      status: false,
      orderType: [OrderTypeIdEnum.DELIVERY],
    },
    {
      id: 4,
      title: 'Dine-in',
      type: 'table',
      status: false,
      orderType: [OrderTypeIdEnum.DINE_IN],
    },
    {
      id: 5,
      title: 'Drive-Thru',
      type: 'driveId',
      status: false,
      orderType: [OrderTypeIdEnum.DRIVE_THRU],
    },
  ],
  orderType: [
    OrderTypeIdEnum.WALK_IN,
    OrderTypeIdEnum.PICK_UP,
    OrderTypeIdEnum.DELIVERY,
    OrderTypeIdEnum.DINE_IN,
    OrderTypeIdEnum.DRIVE_THRU,
  ],
  orderBarChange: false,

  scrolledStatus: [
    {
      id: 1,
      actionType: 'scrollSeatList',
      name: 'Order Bar',
      position: 0,
      class: '',
    },
    {
      id: 2,
      actionType: 'scrollNavBarDetailsList',
      name: 'Nav Bar Details',
      position: 0,
      class: '',
    },
    {
      id: 3,
      actionType: 'guestQuantityList',
      name: 'Guest Quantity',
      position: 0,
      class: '',
    },
    {
      id: 4,
      actionType: 'guestWaitList',
      name: 'Guest Waitlisty',
      position: 0,
      class: '',
    },
    {
      id: 5,
      actionType: 'modalBodyScroll',
      name: 'Modal Booking',
      position: 0,
      class: '',
    },
    {
      id: 6,
      actionType: 'scrollSplitPriceList',
      name: 'Split Bill Modal',
      position: 0,
      class: '',
    },
    {
      id: 7,
      actionType: 'scrollViewOrdersSidebar',
      name: 'View Orders Sidebar',
      position: 0,
      class: '',
    },
    {
      id: 8,
      actionType: 'scrollViewOrdersSeatList',
      name: 'View Orders Seat List',
      position: 0,
      class: '',
    },
    {
      id: 9,
      actionType: 'viewOrdersActionModal',
      name: 'View Orders Actions Modal',
      position: 0,
      class: '',
    },
    {
      id: 10,
      actionType: 'scrollUnassignedList',
      name: 'Unassigned List (drivers)',
      position: 0,
      class: '',
    },
    {
      id: 11,
      actionType: 'scrollDeliveryOrders',
      name: 'Delivery Orders (drivers)',
      position: 0,
      class: '',
    },
    {
      id: 12,
      actionType: 'scrollDriverActionsBox',
      name: 'Driver Actions Box (drivers)',
      position: 0,
      class: '',
    },
    {
      id: 13,
      actionType: 'guestAvailableTableList',
      name: 'Available Table',
      position: 0,
      class: '',
    },
    {
      id: 14,
      actionType: 'sidebarWaitListScroll',
      name: 'Sidebar Wait List',
      position: 0,
      class: '',
    },
    {
      id: 15,
      actionType: 'sidebarBookingScroll',
      name: 'Sidebar Booking',
      position: 0,
      class: '',
    },
    {
      id: 16,
      actionType: 'sidebarSeatedScroll',
      name: 'Sidebar Seated',
      position: 0,
      class: '',
    },
    {
      id: 17,
      actionType: 'sidebarUpcomingScroll',
      name: 'Sidebar Upcoming',
      position: 0,
      class: '',
    },
    {
      id: 18,
      actionType: 'sidebarReadyScroll',
      name: 'Sidebar Ready',
      position: 0,
      class: '',
    },
    {
      id: 19,
      actionType: 'sidebarPreparingScroll',
      name: 'Sidebar Preparing',
      position: 0,
      class: '',
    },
    {
      id: 20,
      actionType: 'sidebarHistoryScroll',
      name: 'Sidebar History',
      position: 0,
      class: '',
    },
    {
      id: 21,
      actionType: 'sidebarSettleScroll',
      name: 'Sidebar Settle',
      position: 0,
      class: '',
    },
  ],
  pinModal: {
    showModal: false,
  },
  viewOrdersActionModal: false,
  refundOrderModal: {
    showModal: false,
  },
  viewOrdersActionTimeModal: {
    pickUpTime: false,
    deliveryTime: false,
  },
  // disableAnimation: false,
  orderBarType: 'all',
  tableView: {
    daysArr: [
      { id: 1, dayId: 9, label: 'Today, 9 Jul', status: true },
      { id: 2, dayId: 10, label: '10th of July', status: false },
      { id: 3, dayId: 11, label: '11th of Jul', status: false },
      { id: 4, dayId: 12, label: '12th of Jul', status: false },
      { id: 5, dayId: 13, label: '13th of Jul', status: false },
      { id: 6, dayId: 14, label: '14th of Jul', status: false },
      { id: 7, dayId: 15, label: '15th of Jul', status: false },
      { id: 8, dayId: 16, label: '16th of Jul', status: false },
      { id: 9, dayId: 17, label: '17th of Jul', status: false },
      { id: 10, dayId: 18, label: '18th of Jul', status: false },
      { id: 11, dayId: 19, label: '19th of Jul', status: false },
      { id: 12, dayId: 20, label: '20th of Jul', status: false },
      { id: 13, dayId: 21, label: '21st of Jul', status: false },
      { id: 14, dayId: 22, label: '22nd of Jul', status: false },
      { id: 15, dayId: 23, label: '23rd of Jul', status: false },
      { id: 16, dayId: 24, label: '24th of Jul', status: false },
      { id: 17, dayId: 25, label: '25th of Jul', status: false },
      { id: 18, dayId: 26, label: '26th of Jul', status: false },
      { id: 19, dayId: 27, label: '27th of Jul', status: false },
      { id: 20, dayId: 28, label: '28th of Jul', status: false },
      { id: 21, dayId: 29, label: '29th of Jul', status: false },
      { id: 22, dayId: 30, label: '30th of Jul', status: false },
      { id: 23, dayId: 31, label: '31th of Jul', status: false },
    ],
    currentDate: 'Tuesday 15th June',
    tableList: [
      {
        id: 2,
        row: 1,
        status: true,
        type: 'square',
        seats: 4,
        class: 'green',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 8,
        waiterId: 1,
        location: 'Outdoor Area',
        availableSeated: '',
      },
      {
        id: 3,
        row: 1,
        status: true,
        type: 'square',
        seats: 4,
        class: 'blue',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 4,
        waiterId: 2,
        location: 'Outdoor Area',
        availableSeated: '',
      },
      {
        id: 4,
        row: 1,
        status: false,
        type: 'rectangular seats-6',
        seats: 6,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 3,
        location: 'Outdoor Area',
        availableSeated: '3 - 6',
      },
      {
        id: 5,
        row: 1,
        status: true,
        type: 'square',
        seats: 4,
        class: 'orange',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 1,
        waiterId: 4,
        location: 'Outdoor Area',
        availableSeated: '',
      },
      {
        id: 6,
        row: 1,
        status: true,
        type: 'square',
        seats: 4,
        class: 'pink',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 3,
        waiterId: 5,
        location: 'Outdoor Area',
        availableSeated: '',
      },
      {
        id: 7,
        row: 4,
        status: false,
        type: 'rectangular seats-2',
        seats: 2,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 6,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 8,
        row: 4,
        status: false,
        type: 'rectangular seats-2',
        seats: 2,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 7,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 9,
        row: 4,
        status: false,
        type: 'rectangular seats-2',
        seats: 2,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 8,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 10,
        row: 4,
        status: false,
        type: 'rectangular seats-2',
        seats: 2,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 1,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 11,
        row: 5,
        status: true,
        type: 'rectangular seats-6',
        seats: 6,
        class: 'blue',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 4,
        waiterId: 2,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 12,
        row: 5,
        status: true,
        type: 'round',
        seats: 8,
        class: 'orange',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 1,
        waiterId: 3,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 13,
        row: 5,
        status: true,
        type: 'round',
        seats: 8,
        class: 'pink',
        icon: 'dine_icon-cash',
        progress: 50,
        tableStatusId: 3,
        waiterId: 4,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 14,
        row: 6,
        status: false,
        type: 'rectangular seats-6',
        seats: 6,
        class: '',
        icon: '',
        progress: 0,
        tableStatusId: '',
        waiterId: 5,
        location: 'Dining Room',
        availableSeated: '4 - 6',
      },
      {
        id: 15,
        row: 6,
        status: true,
        type: 'rectangular seats-6',
        seats: 6,
        class: 'purple',
        icon: 'dine_icon-dessert',
        progress: 50,
        tableStatusId: 5,
        waiterId: 6,
        location: 'Dining Room',
        availableSeated: '',
      },
      {
        id: 16,
        row: 6,
        status: true,
        type: 'square big-size ',
        seats: 4,
        class: 'grey',
        icon: 'dine_icon-cash',
        progress: 0,
        tableStatusId: 9,
        waiterId: 7,
        location: 'Dining Room',
        availableSeated: '',
      },
    ],
    waiterGuestModal: false,
    waiterDropDownList: [
      {
        id: 1,
        name: 'Myrtle Warren',
        shortName: 'Myrtle W',
        style: 'purple',
        iconType: 'initials',
        iconVal: 'MW',
        people: 12,
      },
      {
        id: 2,
        name: 'Oliver Wood',
        shortName: 'Oliver W',
        style: 'blue',
        iconType: 'initials',
        iconVal: 'OW',
        people: 24,
      },
      {
        id: 3,
        name: 'Johnny Appleseed ',
        shortName: 'Johnny A',
        style: 'orange',
        iconType: 'initials',
        iconVal: 'JA',
        people: 8,
      },
      {
        id: 4,
        name: 'Demelza Robins',
        shortName: 'Demelza R',
        style: 'yellow',
        iconType: 'initials',
        iconVal: 'DR',
        people: 16,
      },
      {
        id: 5,
        name: 'Graham Montague',
        shortName: 'Graham M',
        style: 'green',
        iconType: 'initials',
        iconVal: 'GM',
        people: 35,
      },
      {
        id: 6,
        name: 'Marcus Flint',
        shortName: 'Marcus F',
        style: 'pink',
        iconType: 'initials',
        iconVal: 'MF',
        people: 2,
      },
      {
        id: 7,
        name: 'Mary Cattermole',
        shortName: 'Mary C',
        style: 'grey',
        iconType: 'initials',
        iconVal: 'MC',
        people: 47,
      },
      {
        id: 8,
        name: 'Michael Corner',
        shortName: 'Michael C',
        style: 'blue',
        iconType: 'initials',
        iconVal: 'MC',
        people: 25,
      },
    ],
    tableStatusModal: false,
    tableStatusList: [
      {
        id: 1,
        name: 'Partially Seated',
        style: 'orange',
        iconType: 'icon',
        iconVal: 'dine_icon-people',
      },
      {
        id: 2,
        name: 'Seated',
        style: 'orange',
        iconType: 'icon',
        iconVal: 'dine_icon-people-inverse',
      },
      {
        id: 3,
        name: 'Entree',
        style: 'purple',
        iconType: 'icon',
        iconVal: 'dine_icon-silverware',
      },
      {
        id: 4,
        name: 'Main Course',
        style: 'blue',
        iconType: 'icon',
        iconVal: 'dine_icon-dish',
      },
      {
        id: 5,
        name: 'Dessert',
        style: 'pink',
        iconType: 'icon',
        iconVal: 'dine_icon-dessert',
      },
      {
        id: 6,
        name: 'Cleared',
        style: 'yellow',
        iconType: 'icon',
        iconVal: 'dine_icon-cleared',
      },
      {
        id: 7,
        name: 'Check Dropped',
        style: 'green',
        iconType: 'icon',
        iconVal: 'dine_icon-check',
      },
      {
        id: 8,
        name: 'Paid',
        style: 'green',
        iconType: 'icon',
        iconVal: 'dine_icon-cash',
      },
      {
        id: 9,
        name: 'Bussing Needed',
        style: 'grey',
        iconType: 'icon',
        iconVal: 'dine_icon-sprayer',
      },
      {
        id: 10,
        name: 'Finished',
        style: 'blue',
        iconType: 'icon',
        iconVal: 'dine_icon-finish',
      },
      {
        id: 11,
        name: '',
        style: 'empty',
        iconType: '',
        iconVal: '',
      },
    ],
    bookingList: [
      {
        id: 1,
        dayId: 9,
        name: 'Katie Bell',
        initials: 'KB',
        vip: true,
        table: 0,
        people: 2,
        day: 'Tuesday 15th June',
        time: '7:30pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Confirmed', class: 'confirmed' },
          { title: 'Resy', class: 'resy' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 2,
        dayId: 9,
        name: 'Alicia Spinnet',
        initials: 'AS',
        vip: false,
        table: 4,
        people: 2,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Running Late', class: 'running-late' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 3,
        dayId: 9,
        name: 'Romilda Vane',
        initials: 'RV',
        vip: false,
        table: 32,
        people: 6,
        day: 'Tuesday 15th June',
        time: '8:00pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Left Message', class: 'left-message' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 4,
        dayId: 9,
        name: 'Dean Thomas',
        initials: 'DT',
        vip: false,
        table: 6,
        people: 3,
        day: 'Tuesday 15th June',
        time: '8:30pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Arrived', class: 'arrived' },
          { title: 'Web', class: 'web' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Oliver Wood',
        initials: 'OW',
        vip: false,
        table: 10,
        people: 2,
        day: 'Tuesday 15th June',
        time: '8:45pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Booked', class: 'booked' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 6,
        dayId: 9,
        name: 'Corban Yaxely',
        initials: 'CY',
        vip: false,
        table: 0,
        people: 8,
        day: 'Tuesday 15th June',
        time: '9:00pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Cancelled', class: 'cancelled' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 7,
        dayId: 9,
        name: 'Myrtle Warren',
        initials: 'MW',
        vip: false,
        table: 58,
        people: 2,
        day: 'Tuesday 15th June',
        time: '9:30pm',
        leftTime: '45 mins',
        duration: '45 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: null,
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    waitList: [
      {
        id: 1,
        dayId: 9,
        name: 'Bob Ogden',
        initials: 'BO',
        vip: false,
        table: 0,
        people: 3,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '29 mins',
        duration: '15 mins',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Confirmed', class: 'confirmed' },
          { title: 'Resy', class: 'resy' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 2,
        dayId: 9,
        name: 'Cho Chang',
        initials: 'CC',
        vip: false,
        table: 3,
        people: 2,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '40 mins',
        duration: '1 hour',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Arrived', class: 'arrived blue' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 3,
        dayId: 9,
        name: 'Vincent Crabbe',
        initials: 'VC',
        vip: false,
        table: 0,
        people: 6,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '20 mins',
        duration: '1 hour',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Cancelled', class: 'cancelled' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 4,
        dayId: 9,
        name: 'Arabella Figg',
        initials: 'AF',
        vip: true,
        table: 6,
        people: 3,
        day: 'Tuesday 15th June',
        time: '7:40pm',
        leftTime: '12 mins',
        duration: '1 hour',
        price: '$30.70',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Left Message', class: 'left-message' },
          { title: 'Web', class: 'web' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    seatedList: [
      {
        id: 1,
        dayId: 9,
        name: 'Frank Bryce',
        initials: 'FB',
        vip: false,
        table: 2,
        people: 3,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$30.70',
        leftTime: '50 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Paid', class: 'paid' },
          { title: 'Resy', class: 'resy' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 2,
        dayId: 9,
        name: 'Cho Chang',
        initials: 'CC',
        vip: false,
        table: 3,
        people: 2,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$65.30',
        leftTime: '35 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Main', class: 'main' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 3,
        dayId: 9,
        name: 'Colin Creevey',
        initials: 'CC',
        vip: false,
        table: 5,
        people: 6,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$0.00',
        leftTime: '5 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Seated', class: 'seated' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 4,
        dayId: 9,
        name: 'Arabella Figg',
        initials: 'AF',
        vip: true,
        table: 6,
        people: 3,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$26.30',
        leftTime: '12 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Entree', class: 'entree' },
          { title: 'Web', class: 'web' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 5,
        dayId: 9,
        name: 'Lee Jordan',
        initials: 'LJ',
        vip: false,
        table: 16,
        people: 4,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$102.20',
        leftTime: '55 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Bussing', class: 'bussing' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 6,
        dayId: 9,
        name: 'Olympe Maxime',
        initials: 'OM',
        vip: false,
        table: 15,
        people: 8,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$90.40',
        leftTime: '40 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Dessert', class: 'dessert' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 7,
        dayId: 9,
        name: 'Albert Runcorn',
        initials: 'AR',
        vip: false,
        table: 13,
        people: 6,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$90.40',
        leftTime: '40 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Entree', class: 'entree' },
          { title: '', class: 'phone' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 8,
        dayId: 9,
        name: 'Katie Bell',
        initials: 'KB',
        vip: false,
        table: 12,
        people: 6,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$90.40',
        leftTime: '40 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Seated', class: 'seated' },
          { title: 'Web', class: 'web' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
      {
        id: 9,
        dayId: 9,
        name: 'Romilda Vane',
        initials: 'RV',
        vip: false,
        table: 11,
        people: 4,
        day: 'Tuesday 15th June',
        time: '7:00pm',
        price: '$65.30',
        leftTime: '35 mins',
        duration: '1 hour',
        active: false,
        tags: [1],
        statusTags: [
          { title: 'Main', class: 'main' },
          { title: '', class: 'circle' },
        ],
        notes: {},
        sendToKitchen: false,
        messages: [
          {
            id: 1,
            text: 'We’re texting to confirm your reservation at Caffè Macs for 5 guests at 6:30 PM on Mon, Feb 18. Please text “Yes” to confirm or “No” to cancel.',
            sender: 'agent',
          },
          { id: 2, text: 'Yes', sender: 'guest' },
        ],
      },
    ],
    waiterViewModal: false,
    waiterViewModalId: 1,
    bookingViewModal: false,
    bookingViewModalId: 1,
    seatedViewModal: false,
    seatedViewModalId: 1,
    tagModal: false,
    tagGroup: [
      { id: 1, name: 'Occasion', slug: 'occasion', active: true },
      { id: 2, name: 'VIP', slug: 'vip', active: false },
      { id: 3, name: 'Seating', slug: 'seating', active: false },
      { id: 4, name: 'Dietary', slug: 'dietary', active: false },
      { id: 5, name: 'Alert', slug: 'alert', active: false },
    ],
    tags: [
      {
        id: 1,
        name: 'Birthday',
        color: '#0686F6',
        icon: 'dine_icon-birthday-cake',
        tagGroupId: 1,
      },
      {
        id: 2,
        name: 'Anniversary',
        color: '#FCEB04',
        icon: 'dine_icon-champagine',
        tagGroupId: 1,
      },
      {
        id: 3,
        name: 'Date',
        color: '#E02020',
        icon: 'dine_icon-path',
        tagGroupId: 1,
      },
      {
        id: 4,
        name: 'Graduation',
        color: '#000000',
        icon: 'dine_icon-graduation',
        tagGroupId: 1,
      },
      {
        id: 5,
        name: 'Pre theater',
        color: '#F7B500',
        icon: 'dine_icon-theater',
        tagGroupId: 1,
      },
      {
        id: 6,
        name: 'Business',
        color: '#FA6400',
        icon: 'dine_icon-work-briefcase',
        tagGroupId: 1,
      },
      {
        id: 7,
        name: 'VIP',
        color: '#FCEB04',
        icon: 'dine_icon-star',
        tagGroupId: 2,
      },
      {
        id: 8,
        name: 'Investor',
        color: '#5EB602',
        icon: 'dine_icon-us_dolar',
        tagGroupId: 2,
      },
      {
        id: 9,
        name: 'Regulator',
        color: '#FA6400',
        icon: 'dine_icon-warning',
        tagGroupId: 2,
      },
      {
        id: 10,
        name: 'Friend of owner',
        color: '#6236FF',
        icon: 'dine_icon-smile-face',
        tagGroupId: 2,
      },
      {
        id: 11,
        name: 'Friend of employee',
        color: '#F7B500',
        icon: 'dine_icon-laughing-face',
        tagGroupId: 2,
      },
      {
        id: 12,
        name: 'Critic',
        color: '#E02020',
        icon: 'dine_icon-writing-pen',
        tagGroupId: 2,
      },
      {
        id: 13,
        name: 'Blogger',
        color: '#0686F6',
        icon: 'dine_icon-laptop-computer',
        tagGroupId: 2,
      },
      {
        id: 14,
        name: 'Window Seat',
        color: '#000000',
        icon: 'dine_icon-window',
        tagGroupId: 3,
      },
      {
        id: 15,
        name: 'Booth',
        color: '#F7B500',
        icon: 'dine_icon-booth',
        tagGroupId: 3,
      },
      {
        id: 16,
        name: 'Quiet Table',
        color: '#6236FF',
        icon: 'dine_icon-volume-off',
        tagGroupId: 3,
      },
      {
        id: 17,
        name: 'High Chair',
        color: '#0686F6',
        icon: 'dine_icon-baby',
        tagGroupId: 3,
      },
      {
        id: 18,
        name: 'Wheel Chair',
        color: '#FA6400',
        icon: 'dine_icon-handicap',
        tagGroupId: 3,
      },
      {
        id: 19,
        name: 'Smoking',
        color: '#E02020',
        icon: 'dine_icon-cigarette',
        tagGroupId: 3,
      },
      {
        id: 20,
        name: 'Patio Requested',
        color: '#5EB602',
        icon: 'dine_icon-bench',
        tagGroupId: 3,
      },
      {
        id: 21,
        name: 'Gluten Free',
        color: '#FCEB04',
        icon: 'dine_icon-gluten_free',
        tagGroupId: 4,
      },
      {
        id: 22,
        name: 'Allergy',
        color: '#6236FF',
        icon: 'dine_icon-sad-face',
        tagGroupId: 4,
      },
      {
        id: 23,
        name: 'Vegetarian',
        color: '#5EB602',
        icon: 'dine_icon-salad',
        tagGroupId: 4,
      },
      {
        id: 24,
        name: 'Vegan',
        color: '#5EB602',
        icon: 'dine_icon-leaf',
        tagGroupId: 4,
      },
      {
        id: 25,
        name: 'Dairy Free',
        color: '#0686F6',
        icon: 'dine_icon-milk',
        tagGroupId: 4,
      },
      {
        id: 26,
        name: 'Pescatarian',
        color: '#F7B500',
        icon: 'dine_icon-fish',
        tagGroupId: 4,
      },
      {
        id: 27,
        name: 'Jain',
        color: '#FA6400',
        icon: 'dine_icon-hinduism',
        tagGroupId: 4,
      },
      {
        id: 28,
        name: 'Alert Chef',
        color: '#5EB602',
        icon: 'dine_icon-chef',
        tagGroupId: 5,
      },
      {
        id: 29,
        name: 'Alert Manager',
        color: '#6236FF',
        icon: 'dine_icon-business-man',
        tagGroupId: 5,
      },
    ],
    selectedTags: {
      tempTag: '',
      tags: [1],
    },
    messageModal: false,
    directionMessage: false,
    // showAvailableTable: false,
    timeLine: [
      {
        id: 1,
        dayId: 9,
        status: true,
        tableList: [
          {
            id: 2,
            row: 1,
            status: true,
            type: 'square',
            seats: 4,
            class: 'green',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 8,
            waiterId: 1,
            location: 'Outdoor Area',
            availableSeated: '',
          },
          {
            id: 3,
            row: 1,
            status: true,
            type: 'square',
            seats: 4,
            class: 'blue',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 4,
            waiterId: 2,
            location: 'Outdoor Area',
            availableSeated: '',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 3,
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: true,
            type: 'square',
            seats: 4,
            class: 'orange',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 1,
            waiterId: 4,
            location: 'Outdoor Area',
            availableSeated: '',
          },
          {
            id: 6,
            row: 1,
            status: true,
            type: 'square',
            seats: 4,
            class: 'pink',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 3,
            waiterId: 5,
            location: 'Outdoor Area',
            availableSeated: '',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 6,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 7,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 8,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 1,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 11,
            row: 5,
            status: true,
            type: 'rectangular seats-6',
            seats: 6,
            class: 'blue',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 4,
            waiterId: 2,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 12,
            row: 5,
            status: true,
            type: 'round',
            seats: 8,
            class: 'orange',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 1,
            waiterId: 3,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 13,
            row: 5,
            status: true,
            type: 'round',
            seats: 8,
            class: 'pink',
            icon: 'dine_icon-cash',
            progress: 50,
            tableStatusId: 3,
            waiterId: 4,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: 11,
            waiterId: 5,
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: true,
            type: 'rectangular seats-6',
            seats: 6,
            class: 'purple',
            icon: 'dine_icon-dessert',
            progress: 50,
            tableStatusId: 5,
            waiterId: 6,
            location: 'Dining Room',
            availableSeated: '',
          },
          {
            id: 16,
            row: 6,
            status: true,
            type: 'square big-size ',
            seats: 4,
            class: 'grey',
            icon: 'dine_icon-cash',
            progress: 0,
            tableStatusId: 9,
            waiterId: 7,
            location: 'Dining Room',
            availableSeated: '',
          },
        ],
      },
      {
        id: 2,
        dayId: 10,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 3,
        dayId: 11,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 4,
        dayId: 12,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 5,
        dayId: 13,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 6,
        dayId: 14,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 7,
        dayId: 15,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 8,
        dayId: 16,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 9,
        dayId: 17,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 10,
        dayId: 18,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 11,
        dayId: 19,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 12,
        dayId: 20,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 13,
        dayId: 21,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 14,
        dayId: 22,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 15,
        dayId: 23,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 16,
        dayId: 24,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 17,
        dayId: 25,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 18,
        dayId: 26,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 19,
        dayId: 27,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 20,
        dayId: 28,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 21,
        dayId: 29,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 22,
        dayId: 30,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
      {
        id: 23,
        dayId: 31,
        status: false,
        tableList: [
          {
            id: 2,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 3,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 4,
            row: 1,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '3 - 6',
          },
          {
            id: 5,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 6,
            row: 1,
            status: false,
            type: 'square',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Outdoor Area',
            availableSeated: '1 - 4',
          },
          {
            id: 7,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 8,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 9,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 10,
            row: 4,
            status: false,
            type: 'rectangular seats-2',
            seats: 2,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 2',
          },
          {
            id: 11,
            row: 5,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 6',
          },
          {
            id: 12,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 13,
            row: 5,
            status: false,
            type: 'round',
            seats: 8,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '3 - 8',
          },
          {
            id: 14,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 15,
            row: 6,
            status: false,
            type: 'rectangular seats-6',
            seats: 6,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '4 - 6',
          },
          {
            id: 16,
            row: 6,
            status: false,
            type: 'square big-size ',
            seats: 4,
            class: '',
            icon: '',
            progress: 0,
            tableStatusId: '',
            waiterId: '',
            location: 'Dining Room',
            availableSeated: '1 - 4',
          },
        ],
      },
    ],
  },
  seats: [
    {
      id: 1,
      seatName: 'Seat 1',
      color: 'red',
      classes: 'seats-id-1',
      activeSeat: true,
      orderItems: [1],
      splitItems: [1],
      activePay: false,
      payStatus: true,
      selectedArr: [],
      selectedSeat: false,
      dragStart: false,
      dragOrderId: false,
    },
    {
      id: 2,
      seatName: 'Seat 2',
      color: 'green',
      classes: 'seats-id-2',
      activeSeat: false,
      orderItems: [2, 3],
      splitItems: [2, 3, 4],
      activePay: false,
      payStatus: false,
      selectedArr: [],
      selectedSeat: false,
      dragStart: false,
      dragOrderId: false,
    },
    {
      id: 3,
      seatName: 'Seat 3',
      color: 'yellow',
      classes: 'seats-id-3',
      activeSeat: false,
      orderItems: [5],
      splitItems: [5, 6],
      activePay: true,
      payStatus: false,
      selectedArr: [],
      selectedSeat: false,
      dragStart: false,
      dragOrderId: false,
    },
    {
      id: 4,
      seatName: 'Seat 4',
      color: 'blue',
      classes: 'seats-id-4',
      activeSeat: false,
      orderItems: [4],
      splitItems: [7],
      activePay: false,
      payStatus: false,
      selectedArr: [],
      selectedSeat: false,
      dragStart: false,
      dragOrderId: false,
    },
  ],
  newOrderItemId: false,
  newSpecialOrderItemId: false,
  newHalfHalfOrderItemId: false,
  deleteOrderItem: {
    orderId: false,
    seatId: false,
  },
  orders: [
    {
      id: 1,
      quantity: 2,
      // dividedBetweenSeats: [1, 2, 3],
      itemData: {
        id: 1,
        title: 'Antipasto',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 4,
        itemCategory: 'pizza',
        imgSrc: require('../../assets/img/food/full/pizza/Antipasto.png'),
        imgSrcSmall: require('../../assets/img/food/size_230/pizza/Antipasto.png'),
        customIngredients: [126, 12, 2, 44, 56, 11, 111],
      },
      hasOptions: true,
      customOptions: {
        size: 'small',
        base: 'deep_pan',
      },
      ingredientStatus: {
        addIngredient: [21],
        removeIngredient: [126],
        lightIngredient: [12],
        // addIngredient: [21, 45, 77],
        // removeIngredient: [126, 12],
        // lightIngredient: [12, 111],
      },
    },
    {
      id: 2,
      quantity: 1,
      // dividedBetweenSeats: [2],
      itemData: {
        id: 5,
        title: 'Greek',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 5,
        itemCategory: 'pizza',
        imgSrc: require('../../assets/img/food/full/pizza/Greek.png'),
        imgSrcSmall: require('../../assets/img/food/size_230/pizza/Greek.png'),
        customIngredients: [126, 199, 122, 133, 144, 155, 166],
      },
      hasOptions: true,
      customOptions: {
        size: 'medium',
        base: 'classic_crust',
      },
      ingredientStatus: {
        addIngredient: [],
        removeIngredient: [126],
        lightIngredient: [],
      },
    },
    {
      id: 3,
      quantity: 1,
      // dividedBetweenSeats: [2],
      itemData: {
        id: 3,
        title: 'Cheese Burger',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 5,
        itemCategory: 'pizza',
        imgSrc: require('../../assets/img/food/full/pizza/Cheese Burger.png'),
        imgSrcSmall: require('../../assets/img/food/size_230/pizza/Cheese Burger.png'),
        customIngredients: [126, 187, 165, 52, 13],
      },
      hasOptions: true,
      customOptions: {
        size: 'medium',
        base: 'cheesy_crust',
      },
      ingredientStatus: {
        addIngredient: [],
        removeIngredient: [126],
        lightIngredient: [],
      },
    },
    {
      id: 4,
      quantity: 1,
      // dividedBetweenSeats: [4],
      itemData: {
        id: 11,
        title: 'Satay Chicken',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 8,
        itemCategory: 'pizza',
        imgSrc: require('../../assets/img/food/full/pizza/Satay Chicken.png'),
        imgSrcSmall: require('../../assets/img/food/size_230/pizza/Satay Chicken.png'),
        customIngredients: [12, 2, 44, 56, 11, 111],
      },
      hasOptions: true,
      customOptions: {
        size: 'small',
        base: 'classic_crust',
      },
      ingredientStatus: {
        addIngredient: [126],
        removeIngredient: [2],
        lightIngredient: [],
      },
    },
    {
      id: 5,
      quantity: 1,
      // dividedBetweenSeats: [3],
      itemData: {
        id: 16,
        title: 'Arabiata',
        desc: 'Chicken thighs, rice, tomato sauce, cream, garam masala and ginger.',
        price: 5,
        itemCategory: 'pasta',

        imgSrc: require('../../assets/img/food/full/pasta/Arabiata.png'),
        imgSrcSmall: require('../../assets/img/food/size_230/pasta/Arabiata.png'),
        customIngredients: [126, 12, 2, 44, 56, 11, 111],
      },
      hasOptions: true,
      customOptions: {
        size: 'entree',
        pasta_type: 'gnocchi',
      },
      ingredientStatus: {
        addIngredient: [],
        removeIngredient: [126],
        lightIngredient: [],
      },
    },
  ],
  selectedOrder: {},
  orderList: [],
  copyOfOrderList: [],
  orderWaitingObj: {},
  filterType: '0',
  deliveryOrderModal: false,
  actionsModal: false,
  printBillModal: false,
  paymentViewModal: false,
  showSplitPaymentModal: false,
  transferOrderModal: false,
  ordersByDateFilter: {
    current: {
      all: [],
      pickup: [],
      delivery: [],
      'dine-in': [],
    },
    future: {
      all: [],
      pickup: [],
      delivery: [],
      'dine-in': [],
    },
    history: {
      all: [],
      pickup: [],
      delivery: [],
      'dine-in': [],
    },
  },
};

export default initState;
