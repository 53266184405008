import React, { useEffect } from 'react';
import GeneralModal from '../../modals/GeneralModal/index-new';
import Button from '../../components/Button';
import '../../assets/scss/modals/_add-drawer-modal.scss';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

export const WindCaveUserModal = ({
  modalStatus,
  toggleModal,
  windcaveUserHandler,
  isWindcaveApiLoading,
}) => {
  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    onInitInput,
    inputNames,
  } = useVirtualKeyboardContext();
  const inputUser = inputNames?.windcaveUser || '';
  const inputKey = inputNames?.windcaveKey || '';
  const inputRestUser = inputNames?.windcaveRestUser || '';
  const inputRestKey = inputNames?.windcaveRestKey || '';

  const handleClick = (event) => {
    event.preventDefault();
    windcaveUserHandler(
      getInputValue(inputUser),
      getInputValue(inputKey),
      getInputValue(inputRestUser),
      getInputValue(inputRestKey)
    );
    onInitInput(inputUser, '');
    onInitInput(inputKey, '');
    onInitInput(inputRestUser, '');
    onInitInput(inputRestKey, '');

    toggleKeyboard(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleFocus = (e, name) => {
    setInputName(name);

    toggleKeyboard(true);
  };

  const handleChange = (e, name) => {
    const inputVal = e.target.value || '';

    onChangeInput(name, inputVal);
  };

  useEffect(() => {
    return () => {
      onInitInput(inputUser, '');
      onInitInput(inputKey, '');
      onInitInput(inputRestUser, '');
      onInitInput(inputRestKey, '');
    };
  }, []);

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={() => {
        if (modalStatus) toggleKeyboard(false);
        toggleModal();
      }}
      position="bottom"
      effect="move"
      overlay=""
      boxWidth="514px"
      boxHeight="480px"
      className="add-drawer-modal"
      closeBtn="inside"
      keyboardVisibility={keyboardVisibility}
    >
      <div className="add-drawer-modal-box">
        <div className="add-drawer-modal-box-inner">
          <h2 className="add-drawer-title">Add Windcave User</h2>
          <form
            action=""
            className="add-drawer-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="single-input-box">
              <input
                id={inputUser}
                name={inputUser}
                onFocus={(e) => handleFocus(e, inputUser)}
                className="input-style white big"
                placeholder="Windcave User"
                value={getInputValue(inputUser || '')}
                onChange={(e) => handleChange(e, inputUser)}
              />
            </div>

            <div className="single-input-box">
              <input
                id={inputKey}
                name={inputKey}
                onFocus={(e) => handleFocus(e, inputKey)}
                className="input-style white big"
                placeholder="Windcave Key"
                value={getInputValue(inputKey || '')}
                onChange={(e) => handleChange(e, inputKey)}
              />
            </div>

            <div className="single-input-box">
              <input
                id={inputRestUser}
                name={inputRestUser}
                onFocus={(e) => handleFocus(e, inputRestUser)}
                className="input-style white big"
                placeholder="Windcave Rest User"
                value={getInputValue(inputRestUser || '')}
                onChange={(e) => handleChange(e, inputRestUser)}
              />
            </div>

            <div className="single-input-box">
              <input
                id={inputRestKey}
                name={inputRestKey}
                onFocus={(e) => handleFocus(e, inputRestKey)}
                className="input-style white big"
                placeholder="Windcave Rest Key"
                value={getInputValue(inputRestKey || '')}
                onChange={(e) => handleChange(e, inputRestKey)}
              />
            </div>
          </form>
        </div>
        <div className="add-drawer-btn-box">
          <Button
            disabled={
              !getInputValue(inputUser) ||
              !getInputValue(inputKey) ||
              !getInputValue(inputRestKey) ||
              !getInputValue(inputRestKey) ||
              isWindcaveApiLoading
            }
            className="btn"
            title="Pair Terminal"
            onClick={handleClick}
          >
            Add Windcave User
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};
