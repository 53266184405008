export const getCurrentGuest = (list) => {
  let guest = '';

  if (list.length) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].active) {
        guest = list[i];

        break;
      }
    }
  }

  return guest;
};
