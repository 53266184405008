import { useSelector } from 'react-redux';

const useSpecialsCategoryIndex = () => {
  const selectedCategories = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem?.selectedCategories
  );
  return selectedCategories?.find((cat) => cat.isInEditMode)?.index ?? -1;
};

export default useSpecialsCategoryIndex;
