import { useMemo } from 'react';
import MapModalWrapper from 'components/MapModalWrapper';
import LeafletDeliveryBatches from 'components/Tracking/LeafletDeliveryBatches';
import { useDispatch, useSelector } from 'react-redux';
import { storeConfigSelector } from 'pages/Dashboard/selectors';
import { setSelectedBatchOrder } from 'store/batchOrders/actions';

export function DeliveryOrderBatchesMap() {
  const batchOrders = useSelector(
    (state) => state.batchOrdersReducer.batchOrders
  );
  const storeConfig = useSelector(storeConfigSelector);
  const dispatch = useDispatch();

  const polygonLocations = useMemo(
    () =>
      batchOrders
        .filter((batchOrder) => !batchOrder.isDeleted && !batchOrder.isAssigned)
        .map((batch) =>
          (batch.orders ?? []).map((order) => ({
            ...order.addressLocation,
            id: batch.id,
            deliveryDateTimestamp: order.deliveryDateTimestamp,
            address: order.address,
          }))
        ),
    [batchOrders]
  );

  const onMarkerClick = (id) => {
    if (!id) return;

    dispatch(setSelectedBatchOrder(id));
  };

  return (
    <div className="driver-main">
      <MapModalWrapper iframeHeight={'100%'} iframeWidth={'100%'}>
        <LeafletDeliveryBatches
          key={polygonLocations.length}
          addressLocation={{
            lat: storeConfig.addressLocation?.lat,
            lng: storeConfig.addressLocation?.long,
          }}
          polygonLocations={polygonLocations}
          onMarkerClick={onMarkerClick}
        />
      </MapModalWrapper>
    </div>
  );
}
