const AddPaymentType = ({
  paymentType,
  handleSplitPaymentOptionClick,
  children,
}) => {
  const { title, color, colorCode = '' } = paymentType;
  const colorStyleObject = colorCode ? { color: colorCode } : {};

  const handleOnClick = () => {
    handleSplitPaymentOptionClick(paymentType);
  };

  return (
    <div
      className={`add-payment-type__item add-payment-type__item--${color}`}
      onClick={handleOnClick}
      style={colorStyleObject}
    >
      <div className="add-payment-type__cell">
        <span className="add-payment-type__text">{title}</span>
      </div>

      {children && (
        <div className="add-payment-type__cell-icon">{children}</div>
      )}
    </div>
  );
};

export default AddPaymentType;
