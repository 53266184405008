import React from 'react';
import PropTypes from 'prop-types';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import { useSelector } from 'react-redux';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { CheckCircleIcon } from 'nexticons/solid';
import { BadgeUI } from '../../UI/components';
import { getColorByCourseID } from '../../util/getColorByCourceID';
import { capitalizeString } from 'util/utils';

export default function PriceCell({
  discountAmount,
  voucherCode,
  payablePrice,
  price,
  quantity,
  courseId,
  firedCourse,
}) {
  const currencyCode = useSelector(currencySymbolSelector);
  const dineInCourses = useSelector(
    (state) => state.dashboardReducer.dineInCourses
  );
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const { dineInCoursesMap } = dineInCourses;
  const courseColor = getColorByCourseID({ courseId, dineInCoursesMap });

  return (
    <>
      {courseId && dineInCoursesMap && dineInCoursesMap[courseId] && (
        <>
          {firedCourse ? (
            <CheckCircleIcon width={20} color="var(--greenColor)" />
          ) : null}
          <BadgeUI
            sizeType="xxs"
            rounded="full"
            styleType={'primary'}
            style={{
              color: 'white',
              background: courseColor,
              borderColor: courseColor,
            }}
            title={capitalizeString(dineInCoursesMap[courseId].name)}
          />
        </>
      )}
      {payablePrice === 0 || payablePrice ? (
        <>
          {voucherCode && (
            <BadgeUI
              title={voucherCode}
              styleType="secondary-outline"
              sizeType="xxs"
              rounded="full"
              style={{
                borderColor: 'transparent',
                textDecoration: 'line-through',
              }}
            />
          )}
          {(discountAmount || voucherCode) && (
            <BadgeUI
              styleType="secondary-outline"
              sizeType="xxs"
              rounded="full"
              title={getAmountWithCountryCurrency({
                amount: quantity > 1 ? quantity * Number(price) : Number(price),
                locale: `en-${storeCountryIdentifier}`,
                currencyCode,
              })}
              style={{
                borderColor: 'transparent',
                textDecoration: 'line-through',
              }}
            />
          )}
          <BadgeUI
            styleType="success"
            sizeType="xxs"
            rounded="full"
            title={getAmountWithCountryCurrency({
              amount: Number(payablePrice),
              locale: `en-${storeCountryIdentifier}`,
              currencyCode,
            })}
          />
        </>
      ) : (
        <>
          <BadgeUI
            styleType="success"
            sizeType="xxs"
            rounded="full"
            title={
              typeof price === 'number'
                ? getAmountWithCountryCurrency({
                    amount: Number(price),
                    locale: `en-${storeCountryIdentifier}`,
                    currencyCode,
                  })
                : price
            }
          />
        </>
      )}
    </>
  );
}

PriceCell.propTypes = {
  discountAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  voucherCode: PropTypes.string,
  payablePrice: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

PriceCell.defaultProps = {
  discountAmount: '',
  voucherCode: '',
  payablePrice: 0,
};
