import React from 'react';

const OrderAddressBar = ({ address, onClick=()=>{} }) => {

  return (
    <div className="single-pickup-details" onClick={onClick}>
      <p className="text">{address}</p>
      <div className="icon-box">
        <span className="dine_icon-marker" />
      </div>
    </div>
  );
};

export default OrderAddressBar;
