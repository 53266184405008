import { useEffect, useState } from 'react';

export const OrderTimer = ({ startMins, startSec, endMins, endSec }) => {
  const [minutes, setMinutes] = useState(startMins);
  const [seconds, setSeconds] = useState(startSec);

  useEffect(() => {
    let interval = null;

    function reset() {
      setMinutes(startMins);
      setSeconds(startSec);
    }

    interval = setInterval(() => {
      if (minutes === endMins && seconds === endSec) {
        reset();
      } else {
        if (seconds === 59) {
          setMinutes((minutes) => minutes + 1);
          setSeconds(0);
        }
      }

      setSeconds((seconds) => seconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds, startMins, startSec, endMins, endSec]);

  if (seconds > 0 && seconds < 10) {
    return minutes + ':0' + seconds;
  } else {
    return minutes + ':' + seconds;
  }
};
