import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import CustomerPhoneModal from '../../components/CustomerPhoneModal';
import RefundContext from '../../context/RefundContext';
import { ModalPortal } from 'components';

const RefundPhoneModal = () => {
  const [state, setState] = useContext(RefundContext);
  const storeConfig = useSelector(
    (state) => state.dashboardReducer.storeConfig
  );

  const modalStatus = !!state.showPhoneModal;
  const handleModalClose = () => setState({ showPhoneModal: false });

  return (
    <ModalPortal>
      <CustomerPhoneModal
        modalStatus={modalStatus}
        onNextClick={(value) => setState({ showPhoneModal: false, phone: value })}
        onModalClose={handleModalClose}
        isFetching={false}
        countryIdentifier={storeConfig.countryIdentifier}
        overlay=""
        closeBtn="outside"
        position="center"
      />
    </ModalPortal>
  );
};

export default RefundPhoneModal;
