import React, { useEffect, useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useVirtualKeyboardContext } from 'context/VirtualKeyboardContext';
import { updateCurrentOrder } from 'pages/Dashboard/action';

import OrderBarTextField from '../OrderBarTextField';

export const OrderBarGuest = () => {
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const dispatch = useDispatch();
  const {
    toggleKeyboard,
    onChangeInput,
    setInputName,
    onInitInput,
    getInputValue,
  } = useVirtualKeyboardContext();

  const virtualKeyboard = useSelector(
    (state) => state.dashboardReducer.virtualKeyboard
  );

  const virtualKeyboardOrderGuestValue = getInputValue('orderGuest');

  const [walkInOrderNumber, setWalkInOrderNumber] = useState('');

  useEffect(() => {
    if (
      currentOrder.walkInOrderNumber === 0 &&
      (currentOrder.orderType === '0' || currentOrder.orderType === '4')
    ) {
      setWalkInOrderNumber(currentOrder.walkInOrderNumber);
    } else {
      let fullName = `${currentOrder.firstName || ''}${
        currentOrder.lastName ? ' ' + currentOrder.lastName : ''
      }`;
      setInputName('orderGuest');
      onInitInput('orderGuest', fullName);
    }
  }, [
    currentOrder.orderType,
    onInitInput,
    currentOrder.firstName,
    currentOrder.lastName,
    currentOrder.walkInOrderNumber,
  ]);

  const handleFocus = useCallback(() => {
    virtualKeyboard && setInputName('orderGuest');
    toggleKeyboard(true);
  }, [toggleKeyboard]);

  const handleChange = useCallback(
    (event) => {
      const inputVal = event.target.value;

      onChangeInput('orderGuest', inputVal);
    },
    [onChangeInput]
  );

  const handleOnBlur = useCallback(() => {
    if (virtualKeyboardOrderGuestValue.trim().length) {
      const nameParts = virtualKeyboardOrderGuestValue.trim().split(' ');
      dispatch(
        updateCurrentOrder({
          firstName: nameParts[0] || '',
          lastName: nameParts[1] || '',
        })
      );
    } else if (currentOrder?.firstName) {
      const fullName = `${currentOrder.firstName} ${
        currentOrder?.lastName || ''
      }`.trim();

      onChangeInput('orderGuest', fullName);
    }
  }, [
    dispatch,
    currentOrder,
    onChangeInput,
    toggleKeyboard,
    virtualKeyboardOrderGuestValue,
  ]);

  useEffect(() => {
    if (virtualKeyboardOrderGuestValue && virtualKeyboard) {
      const nameParts = virtualKeyboardOrderGuestValue.trim().split(' ');
      dispatch(
        updateCurrentOrder({
          firstName: nameParts[0] || '',
          lastName: nameParts[1] || '',
        })
      );
    }
  }, [virtualKeyboardOrderGuestValue]);

  return (
    <div className="single-pickup-details input-box">
      {walkInOrderNumber !== 0 ? (
        <OrderBarTextField
          id="orderGuest"
          name="orderGuest"
          placeholder="Customer Name"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          value={virtualKeyboardOrderGuestValue}
        />
      ) : (
        <OrderBarTextField
          placeholder="Walk-In Order Number"
          value={`Order Number`}
          disable="true"
          title="Order Number will be generated once order is placed"
        />
      )}
      <div className="icon-box">
        <span className="dine_icon-shape_man" />
      </div>
    </div>
  );
};
