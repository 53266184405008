import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconQuestionMark = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-question-mark': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="17"
        viewBox="0 0 11 17"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M5.498 13.6c-.92 0-1.666.76-1.666 1.7 0 .939.746 1.7 1.666 1.7.92 0 1.666-.761 1.666-1.7 0-.94-.746-1.7-1.666-1.7zM7.358.366a4.925 4.925 0 0 0-5.365 1.101A5.112 5.112 0 0 0 .5 5.1a.337.337 0 0 0 .333.333H3.5a.337.337 0 0 0 .333-.34 1.719 1.719 0 0 1 .667-1.36 1.665 1.665 0 0 1 1.532-.245c.506.163.9.57 1.053 1.088.273.855-.153 1.779-.973 2.108-1.385.562-2.29 1.934-2.279 3.455V11.9c0 .188.15.34.333.34h2.666a.337.337 0 0 0 .333-.34v-1.76a.314.314 0 0 1 .174-.293c1.97-.78 3.239-2.749 3.158-4.904C10.435 2.916 9.203 1.12 7.36.366z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconQuestionMark.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
