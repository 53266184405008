// reducer for batch orders

import {
  ADD_BATCH_ORDER,
  LOADING_BATCH_ORDERS,
  SET_BATCH_ORDERS,
  SET_SELECTED_BATCH_ORDER,
  UPDATE_BATCH_ORDER,
} from './constants';

const initialState = {
  batchOrders: [],
  loading: false,
  selectedBatchOrderId: null,
};

const batchOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BATCH_ORDERS:
      return {
        ...state,
        batchOrders: action.payload,
        loading: false,
        selectedBatchOrderId: null,
      };
    case ADD_BATCH_ORDER:
      return {
        ...state,
        batchOrders: [...state.batchOrders, action.payload],
        loading: false,
        selectedBatchOrderId: null,
      };
    case UPDATE_BATCH_ORDER:
      return {
        ...state,
        batchOrders: state.batchOrders.map((batchOrder) =>
          batchOrder.id === action.payload.id
            ? { ...batchOrder, ...action.payload }
            : batchOrder
        ),
        selectedBatchOrderId: null,
        loading: false,
      };

    case LOADING_BATCH_ORDERS:
      return {
        ...state,
        loading: action.payload ?? true,
      };
    case SET_SELECTED_BATCH_ORDER:
      return {
        ...state,
        selectedBatchOrderId:
          state.selectedBatchOrderId === action.payload ? null : action.payload,
      };

    default:
      return state;
  }
};

export default batchOrdersReducer;
