import axios from 'axios';
import { nextOrderConfig } from 'configuration/nextOrderConfig';

export const addDriver = async (reqObj) => {
  const response = await axios.post(
    `${nextOrderConfig.cloudFunctionUrl}/addDriver`,
    reqObj
  );
  if (response.status === 200) {
    return response.data;
  } else {
    return { success: false };
  }
};
