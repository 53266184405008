import LoginComponent from 'components/Login';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useFetch } from 'react-async';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { nextOrderConfig } from '../../configuration/nextOrderConfig';
import { _auth } from '../../firebase';
import { updateStoreConfig } from '../Dashboard/action';
import { useAuth } from './useProvideAuth';
// import axios from 'axios';

// https://prickle-false-tellurium.glitch.me/login/

const AUTH_CODE_NO_OF_CHARS = 6;
const loginHappeningFrom = '2'; // POS, '1' is for website

const checkNeededDataForLoginInExists = (data) => {
  return data?.isSuccessful && getEmailFromData(data) && getTokenFromData(data);
};

const getEmailFromData = (data) => data?.userDetails?.email;
const getTokenFromData = (data) => data?.userDetails?.token;

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const method = 'POST';

function SignIn({ relogin }) {
  const [storeAuthCode, setStoreAuthCode] = useState('');
  const [storePassPhrase, setStorePassPhrase] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const authCode = searchParams.get('authCode');
  const passPhrase = searchParams.get('passPhrase');

  const { user, signinWithToken } = useAuth();

  const [signInError, setSignInError] = useState('');

  const passPhraseInputRef = useRef(null);

  // "https://us-central1-next-order-prodenv.cloudfunctions.net"
  // "https://us-central1-next-order-devenv.cloudfunctions.net"

  const { data, error, isPending, run, setError } = useFetch(
    `${nextOrderConfig.baseUrl}/stores/v1/login`,
    {
      headers,
      method,
      body: JSON.stringify({
        authCode: authCode || storeAuthCode,
        passphrase: passPhrase || storePassPhrase,
        loginFrom: loginHappeningFrom,
      }),
    },
    {
      json: true,
    }
  );

  // useEffect
  // take data and log user in

  const handleChange = useCallback(
    (otp) => setStoreAuthCode(otp),
    [setStoreAuthCode]
  );

  const handlePassPhraseChange = useCallback(
    (passPhraseValue) => setStorePassPhrase(passPhraseValue),
    [setStorePassPhrase]
  );

  const fetchEmailPass = useCallback(() => {
    run({
      body: JSON.stringify({
        authCode: authCode || storeAuthCode,
        passphrase: passPhrase || storePassPhrase,
        loginFrom: loginHappeningFrom,
      }),
    });
  }, [storeAuthCode, run]);

  useEffect(() => {
    if (authCode && passPhrase) {
      fetchEmailPass();
    }
  }, []);

  useEffect(() => {
    if (storeAuthCode.length === AUTH_CODE_NO_OF_CHARS) {
      passPhraseInputRef.current?.focus();
    }
  }, [storeAuthCode]);

  useEffect(() => {
    if (error && storeAuthCode.length === 1) {
      setError(null);
      setSignInError('');
    }
  }, [error, storeAuthCode, setError]);

  // reset input on error
  useEffect(() => {
    if (error) {
      setStoreAuthCode('');
      document.querySelector('.auth-input-container input')?.focus();
    }
  }, [error]);

  useEffect(() => {
    const loginStoreOwnerIn = () => {
      if (checkNeededDataForLoginInExists(data?.data)) {
        if (!user || relogin) {
          signinWithToken(getTokenFromData(data?.data))
            .then(() => {
              const storeConfig = {
                ...data?.data?.storeDetails,
                authCode: storeAuthCode,
              };

              setSignInError('');
              dispatch(updateStoreConfig(storeConfig));
              localStorage.setItem('authCode', storeAuthCode);
              localStorage.setItem('storeConfig', JSON.stringify(storeConfig));
              localStorage.setItem('uuidForTerminalSettings', uuidv4());
              if (relogin) {
                window.location.reload();
              } else {
                history.push('/');
              }
            })
            .catch((val) => {
              setSignInError('Unable To Login Please Try Again');
            });
        }
      }
    };

    loginStoreOwnerIn();
    // eslint-disable-next-line
  }, [data, signinWithToken, user, history, dispatch]);

  useEffect(() => {
    const unsubscribeIdTokenChanges = _auth.onIdTokenChanged((user) => {
      if (!user) {
        localStorage.removeItem('storeConfig');
      }
    });

    return () => {
      unsubscribeIdTokenChanges();
    };
  }, []);

  if (!relogin && user) {
    return <div>Logged In</div>;
  }
  return (
    <LoginComponent
      storeAuthCode={storeAuthCode}
      storePassPhrase={storePassPhrase}
      handleChange={handleChange}
      handlePassPhraseChange={handlePassPhraseChange}
      error={error}
      signInError={signInError}
      fetchEmailPass={fetchEmailPass}
      isPending={isPending}
      passPhraseInputRef={passPhraseInputRef}
    />
  );
}

export default SignIn;
