import { OrderTypeIdEnum, paymentTypeEnum } from 'configuration/enums';
import { store } from 'redux-store';
import { getOrderTimeArray } from 'util/getTimeSlots';
import { _auth } from '../../firebase';

export async function handleAddOrderToTable({
  currentOrder,
  callback,
  onSuccess,
  hasNotChanged,
}) {
  const { storeConfig, nextOrderConfigURL, orderPayments, orderSetup, shifts } =
    store.getState().dashboardReducer;
  const orderTimes =
    shifts && orderSetup
      ? getOrderTimeArray(
          orderSetup ? orderSetup : {},
          storeConfig,
          OrderTypeIdEnum.DINE_IN
        )
      : null;

  let alreadyPaidAmount = 0;
  if (orderPayments?.length > 0) {
    orderPayments.forEach((payment) => {
      alreadyPaidAmount = alreadyPaidAmount + Number(payment.amount);
    });
  }
  let payableAmount = Number(currentOrder.payableAmount) - alreadyPaidAmount;
  if (
    currentOrder.menuItems?.length &&
    currentOrder.payableAmount &&
    orderTimes
  ) {
    const { floorLayoutId } = currentOrder;
    let currentOd = {
      ...currentOrder,
    };

    currentOd.paymentType = paymentTypeEnum.UNPAID;
    currentOd.paymentCollectionType = '1';
    if (currentOd.selectedVoucher) delete currentOd.selectedVoucher;
    // if (currentOd.selectedDiscount) delete currentOd.selectedDiscount;

    if (hasNotChanged) {
      currentOd.iteration = currentOd.iteration - 1;
      currentOd.prevIteration = currentOd.prevIteration - 1 || undefined;
    }

    let requestObj = {
      order: {
        ...currentOd,
        storeId: storeConfig?.storeId,
        countryIdentifier: storeConfig.countryIdentifier,
        restaurantId: storeConfig.restaurantId,
        doNotUpdateOrderStatus: false,
      },
      payment: {
        type: '0',
        amount: parseInt((Number(payableAmount) * 100).toFixed(2), 10),
      },
    };
    try {
      const response = await fetch(`${nextOrderConfigURL}/placeOrder3DSecure`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...requestObj,
        }),
      });

      const result = await response.json();
      if (floorLayoutId) {
        localStorage.setItem('dineInLayoutToDefaultTo', floorLayoutId);
      }
      onSuccess(result);
    } catch (err) {
      console.error(err);
    }
  }

  callback();
}
