import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconMinus = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-minus': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="3"
        viewBox="0 0 17 3"
      >
        <defs>
          <clipPath id="ig1ka">
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M7.5-6a1 1 0 0 1 2 0V9a1 1 0 0 1-2 0z"
            />
          </clipPath>
        </defs>
        <g>
          <g transform="rotate(90 8.5 1.5)">
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M7.5-6a1 1 0 0 1 2 0V9a1 1 0 0 1-2 0z"
            />
            {/*<path*/}
            {/*  fill="none"*/}
            {/*  d="M7.5-6a1 1 0 0 1 2 0V9a1 1 0 0 1-2 0z"*/}
            {/*  clip-path='url("#ig1ka")'*/}
            {/*/>*/}
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconMinus.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
