import React from 'react';
import { DeliveryDriverSingleItem } from './DeliveryDriverSingleItem';
import { PosIconBag } from '../../../assets/icons/PosIconBag';
import { currentDriverSelector } from '../../../pages/Drivers/selectors';
import { useSelector } from 'react-redux';

const DriversStatusSection = ({
  drivers = [],
  colorClassName,
  title,
  showAssignButton,
  ...rest
}) => {
  const currentDriver = useSelector(currentDriverSelector);
  const getActiveOrdersCount = () => {
    let count = 0;
    drivers.forEach((driver) => {
      count += driver.activeOrders?.length || 0;
    });
    return count;
  };

  return (
    <div className={'driver-accessibility-tab ' + colorClassName} {...rest}>
      <div className="accessibility-header-box">
        <h2 className="accessibility-tab-title">{title}</h2>
        <span className="accessibility-tab-count">
          <span className="icon-box">
            <PosIconBag mainColor="#ffffff" darkModeColor="#ffffff" />
          </span>
          <span className="count">{getActiveOrdersCount()}</span>
        </span>
      </div>
      <div className="accessibility-body">
        <ul className="driver-accessibility-list">
          {drivers.map((driver, index) => {
            return (
              <DeliveryDriverSingleItem
                key={index}
                currentDriverId={currentDriver._id}
                driverData={driver}
                showAssignButton={showAssignButton}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DriversStatusSection;
