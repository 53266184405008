import React from 'react';
import PropTypes from 'prop-types';
import { PosIconDownChevron } from '../../assets/icons/PosIconDownChevron';
import classNames from 'classnames';
import Button from '../Button';

export const ScheduledDropdown = ({ title, className, children, ...props }) => {
  const btnCls = classNames({
    'scheduled-dropdown': true,
    [`${className}`]: className,
  });

  const onButtonClick = () => {
    props.clickHandler && props.clickHandler();
  };

  return (
    <div>
      <Button title={title} className={btnCls} onClick={onButtonClick}>
        {title}
        <PosIconDownChevron
          mainColor="#363636"
          darkModeColor="#ffffff"
          lineThickness={0.5}
        />
      </Button>
      {props.showDropDown && (
        <div className={`options-holder`}>
          <div className={'list-items'}>{children}</div>
        </div>
      )}
    </div>
  );
};

ScheduledDropdown.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};
