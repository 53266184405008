export default function generateHalfHalfMenuItems(
  currentMenuItems,
  expensiveHalfPrice,
  chargeExpensiveHalf
) {
  let menuItems = currentMenuItems;
  // FIXED: half half Dual Pricing Issue
  if (currentMenuItems.some((item) => item.isHalf)) {
    const halfHalf = currentMenuItems
      .filter((item) => item.isHalf)
      .reduce((result, value, index, array) => {
        const isItemTypeSameInAll = array[0].itemType === array[1].itemType;

        if (index % 2 === 0 && !isItemTypeSameInAll) {
          const pair = array.slice(index, index + 2).map((item, index, arr) => {
            if (item.itemType === 0) {
              const selectedSizes = item.selectedSizes.map((size) => {
                if (size.quantity > 0) {
                  return {
                    ...size,
                    price: chargeExpensiveHalf
                      ? expensiveHalfPrice.toFixed(2)
                      : size.price,
                  };
                }
                return size;
              });

              return {
                ...item,
                isSecondHalf: true,
                selectedSizes,
              };
            } else if (item.itemType === 1) {
              const selectedModifiers = item.selectedModifiers.map(
                (modifier) => {
                  if (modifier._id === item.baseModifierId) {
                    const subModifiers = modifier.subModifiers.map(
                      (subModifier) => {
                        if (subModifier.quantity > 0) {
                          return {
                            ...subModifier,
                            price: chargeExpensiveHalf
                              ? expensiveHalfPrice.toFixed(2)
                              : subModifier.price,
                          };
                        }
                        return subModifier;
                      }
                    );

                    return {
                      ...modifier,
                      subModifiers,
                    };
                  }
                  return modifier;
                }
              );

              return {
                ...item,
                isSecondHalf: true,
                selectedModifiers,
              };
            }
          });

          return result.concat(pair);
        }

        if (index % 2 === 0 && value?.itemType === 0 && isItemTypeSameInAll) {
          const pair = array
            .slice(index, index + 2)
            .sort(
              (a, b) => b.selectedSizes[0]?.price - a.selectedSizes[0]?.price
            )
            .map((item, index, arr) => {
              if (index === 0) {
                return {
                  ...item,
                  isSecondHalf: false,
                };
              } else {
                const firstHalfSelectedSizes = arr[0]?.selectedSizes;

                const selectedSizes = item.selectedSizes.map((size) => ({
                  ...size,
                  price: chargeExpensiveHalf
                    ? expensiveHalfPrice
                    : firstHalfSelectedSizes?.find((s) => s.name === size.name)
                        ?.price ?? size.price,
                }));

                return {
                  ...item,
                  isSecondHalf: true,
                  selectedSizes,
                };
              }
            });

          return result.concat(pair);
        } else if (
          index % 2 === 0 &&
          value?.itemType === 1 &&
          isItemTypeSameInAll
        ) {
          const pair = array
            .slice(index, index + 2)
            .sort(
              (a, b) =>
                b.selectedModifiers[0]?.subModifiers[0]?.price -
                a.selectedModifiers[0]?.subModifiers[0]?.price
            )
            .map((item, index, arr) => {
              if (index === 0) {
                return {
                  ...item,
                  isSecondHalf: false,
                };
              } else {
                const firstHalfSelectedModfiers = arr[0]?.selectedModifiers;

                const selectedModifiers = item.selectedModifiers.map(
                  (modifier) => {
                    let subModifiers = [];
                    firstHalfSelectedModfiers?.forEach((firstHalfModifer) => {
                      if (modifier.name === firstHalfModifer.name) {
                        modifier?.subModifiers?.forEach((subModifier) => {
                          const firstHalfSubModifier =
                            firstHalfModifer?.subModifiers?.find(
                              (s) => s.name === subModifier.name
                            );

                          subModifiers.push({
                            ...subModifier,
                            price:
                              firstHalfSubModifier?.price ?? subModifier.price,
                          });
                        });
                      }
                    });
                    return {
                      ...modifier,
                      subModifiers: subModifiers,
                    };
                  }
                );

                return {
                  ...item,
                  isSecondHalf: true,
                  selectedModifiers,
                };
              }
            });
          return result.concat(pair);
        } else {
          return result;
        }
      }, [])
      .flat();
    const nonHalfHalf = currentMenuItems.filter((item) => !item.isHalf);

    menuItems = [...halfHalf, ...nonHalfHalf];
  }
  return menuItems;
}
