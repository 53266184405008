import React from 'react';
import ToggleButton from '../../components/ToggleButton';
import AppToggleGroup from '../../components/AppToggleGroup';
import OrdersTimeSortFilter from './OrdersTimeSortFilter/OrdersTimeSortFilter';
import { isMacOs, isWindows } from 'react-device-detect';
import { HeaderUI } from '../../UI/components';
import { LogoButton } from '../../components/LogoButton';
import {environment} from 'configuration/nextOrderConfig';
// import { DollarIcon } from 'nexticons/outline';

/*
const colorList = [
  { name: 'textColor', code: '#363636', value: '--textColor' },
  { name: 'whiteColor', code: '#ffffff', value: '--whiteColor' },
  {
    name: 'primaryColor - (--blueColor)',
    code: '#067aff',
    value: '--primaryColor',
  },
  { name: 'blueColorLight', code: '#d6ebff', value: '--blueColorLight' },
  {
    name: 'secondaryColor',
    code: '#363636',
    value: '--secondaryColor',
  },
  { name: 'greyColorLight', code: '#eeeeee', value: '--greyColorLight' },
  {
    name: 'successColor - (--greenColor)',
    code: '#5eb602',
    value: '--successColor',
  },
  { name: 'greenColorLight', code: '#e7f9d0', value: '--greenColorLight' },
  {
    name: 'warningColor - (--orangeColor)',
    code: '#fd6c00',
    value: '--warningColor',
  },
  { name: 'orangeColorLight', code: '#ffe5d2', value: '--orangeColorLight' },
  {
    name: 'dangerColor - (--redColor)',
    code: '#f40000',
    value: '--dangerColor',
  },
  { name: 'redColorLight', code: '#ffe6e7', value: '--redColorLight' },
];
*/

const Settings = () => {
  return (
    <div className="settings-page">
      <HeaderUI>
        <LogoButton />
      </HeaderUI>

      <div className="settings-inner-box">
        <div className="settings-section">
          <h2 className="title">General</h2>
          <div className="section">
            <AppToggleGroup />

            {isMacOs || isWindows || environment === 'development' ? (
              <ToggleButton
                id="virtualKeyboard"
                toggleTitle="Enable Keyboard"
                toggleOption="virtualKeyboard"
              />
            ) : (
              ''
            )}
            <ToggleButton
              id="stripeCardPayments"
              toggleTitle="Stripe Payments"
              toggleOption="stripeCardPayments"
            />
          </div>
        </div>
        <div className="settings-section">
          <h2 className="title">Orders</h2>

          <OrdersTimeSortFilter />
        </div>

        {/*  <div className="settings-section">
          <h2 className="title">Colors</h2>
          <ul className="bem-settings__color-list">
            {colorList.map((item) => {
              return (
                <li key={item.name}>
                  <div className="bem-settings__color-value">{item.name}</div>
                  <div className="bem-settings__color-code">{item.code}</div>
                  <div
                    className="bem-settings__color-box"
                    style={{ backgroundColor: `var(${item.value})` }}
                  />
                </li>
              );
            })}
          </ul>
        </div>*/}

        {/*   <div className="settings-section">
          <h2 className="title">Buttons</h2>
          <ButtonGroupUI style={{ marginBottom: 32 }}>
            <ButtonUI styleType="primary">primary</ButtonUI>
            <ButtonUI styleType="secondary">secondary</ButtonUI>
            <ButtonUI styleType="success">success</ButtonUI>
            <ButtonUI styleType="warning">warning</ButtonUI>
            <ButtonUI styleType="danger">danger</ButtonUI>
            <ButtonUI styleType="primary-light">primary-light</ButtonUI>
            <ButtonUI styleType="secondary-light">secondary-light</ButtonUI>
            <ButtonUI styleType="success-light">success-light</ButtonUI>
            <ButtonUI styleType="warning-light">warning-light</ButtonUI>
            <ButtonUI styleType="danger-light">danger-light</ButtonUI>
            <ButtonUI styleType="primary-outline">primary-outline</ButtonUI>
            <ButtonUI styleType="secondary-outline">secondary-outline</ButtonUI>
            <ButtonUI styleType="success-outline">success-outline</ButtonUI>
            <ButtonUI styleType="warning-outline">warning-outline</ButtonUI>
            <ButtonUI styleType="danger-outline">danger-outline</ButtonUI>
          </ButtonGroupUI>

          <ButtonGroupUI style={{ marginBottom: 32 }}>
            <ButtonUI styleType="primary" sizeType="xxs">
              button size xxs
            </ButtonUI>
            <ButtonUI styleType="primary" sizeType="xs">
              button size xs
            </ButtonUI>
            <ButtonUI styleType="primary" sizeType="s">
              button size s
            </ButtonUI>
            <ButtonUI styleType="primary" sizeType="m">
              button size m
            </ButtonUI>
            <ButtonUI styleType="primary" sizeType="l">
              button size l
            </ButtonUI>
            <ButtonUI styleType="primary" sizeType="xl">
              button size xl
            </ButtonUI>
            <ButtonUI styleType="primary" sizeType="xxl">
              button size xxl
            </ButtonUI>
            <ButtonUI styleType="primary" sizeType="xxxl">
              button size xxxl
            </ButtonUI>
          </ButtonGroupUI>
          <ButtonGroupUI style={{ marginBottom: 32 }}>
            <ButtonUI
              styleType="primary"
              sizeType="xxs"
              icon={<DollarIcon width="18" strokeWidth="2.25" />}
            >
              button size xxs
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="xs"
              icon={<DollarIcon width="18" strokeWidth="2.25" />}
            >
              button size xs
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="s"
              icon={<DollarIcon width="18" strokeWidth="2.25" />}
            >
              button size s
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="m"
              icon={<DollarIcon width="18" strokeWidth="2.25" />}
            >
              button size m
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="l"
              icon={<DollarIcon width="18" strokeWidth="2.25" />}
            >
              button size l
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="xl"
              icon={<DollarIcon width="18" strokeWidth="2.25" />}
            >
              button size xl
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="xxl"
              icon={<DollarIcon width="18" strokeWidth="2.25" />}
            >
              button size xxl
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="xxxl"
              icon={<DollarIcon width="18" strokeWidth="2.25" />}
            >
              button size xxxl
            </ButtonUI>
          </ButtonGroupUI>
        </div>

        <div className="settings-section">
          <h2 className="title">Badge</h2>

          <ButtonGroupUI style={{ marginBottom: 32 }}>
            <BadgeUI styleType="primary" title="primary" />
            <BadgeUI styleType="secondary" title="secondary" />
            <BadgeUI styleType="success" title="success" />
            <BadgeUI styleType="warning" title="warning" />
            <BadgeUI styleType="danger" title="danger" />
            <BadgeUI styleType="primary-light" title="primary-light" />
            <BadgeUI styleType="secondary-light" title="secondary-light" />
            <BadgeUI styleType="success-light" title="success-light" />
            <BadgeUI styleType="warning-light" title="warning-light" />
            <BadgeUI styleType="danger-light" title="danger-light" />
            <BadgeUI styleType="primary-outline" title="primary-outline" />
            <BadgeUI styleType="secondary-outline" title="secondary-outline" />
            <BadgeUI styleType="success-outline" title="success-outline" />
            <BadgeUI styleType="warning-outline" title="warning-outline" />
            <BadgeUI styleType="danger-outline" title="danger-outline" />
          </ButtonGroupUI>
        </div>*/}
      </div>
    </div>
  );
};

export default Settings;
