import { MENU_ORDER_FROM, OrderTypeIdEnum } from 'configuration/enums';
import { nextOrderConfig } from '../../configuration/nextOrderConfig';

const initialState = {
  appLoaded: false,
  menuItemSizes: [],
  menuItemModifiers: [],
  menuItemSubModifiers: [],
  storeConfig: {},
  // disableAnimation: true,
  orderTypeConfig: {
    basePath: '',
    title: '',
    orderTypeId: '',
  },
  fullScreen: false,
  virtualKeyboard: false,
  blurEffect: false,
  isStaffAuthorised: false,
  goToRouteAfterAuth: '',
  activeStaffMember: null,
  nextOrderConfigURL: nextOrderConfig.cloudFunctionUrl,
  nextOrderConfigURLSydney: nextOrderConfig.cloudFunctionUrlSydney,
  orders: [], // List of all recent orders for the store
  currentOrder: {
    address: '',
    addressLocation: {},
    orderStatus: '0',
    orderFrom: MENU_ORDER_FROM.pos,
    orderType: OrderTypeIdEnum.WALK_IN, // 0- no type, needs to update with valid type when clicked on pickup, deliuery or dine-in
    menuItems: [],
    totalCost: 0,
    payableAmount: 0,
    mobileNumber: '',
    firstName: '',
    lastName: '',
    userId: 'xxx-xxx-xxx',
    staffId: '',
    drawerId: 'xxx-xxx-xxx',
    terminalId: 'xxx-xxx-xxx',
    iteration: 0,
  }, // Opened order
  menuItems: [],
  specials: [],
  categories: [],
  suburbs: [],
  refundReasons: [],
  shifts: [],
  currentMenuItem: {
    quantity: 0,
  },
  currentSpecialsItem: {},
  currentHalfHalfData: {
    variantSelected: '',
  },
  currentFourQuarterData: {},
  isCurrentMenuItemInEditMode: false,
  isItemLargePhotoView: false,
  actionsModalConfig: {
    show: false,
    customClass: '',
    position: {
      right: '40px',
      top: '20px',
    },
    menuItem: {},
  },
  itemActionsModalConfig: {
    show: false,
    menuItem: {},
  },
  orderSetup: {},
  orderSideModal: false,
  splitBillDiscountModal: false,
  surchargeModal: false,
  discountList: [],
  dineInCourses: {
    dineInCoursesMap: {},
    dineInCoursesList: [],
  },
  appliedVoucher: {},
  screenSizes: {
    width: '',
    height: '',
  },
  orderBackModal: false,
  disableSpecialDicountModal: false,
  openItemNotesModal: false,
  refreshToUpdatePosVersion: false,
  apiLoading: false,
};

export default initialState;
