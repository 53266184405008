import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _auth } from '../../firebase';
import moment from 'moment';
import { getItemInstruction } from './util';
import {
  BillCheckInfoUI,
  BillCheckItemUI,
  BillCheckUI,
  ButtonUI,
} from 'UI/components';
import { updateCurrentOrder } from 'pages/Dashboard/action';
import { addPrinterCommandApi } from '../../services/ordersServices';
import {
  storeConfigSelector,
  storeTimeZoneSelector,
  restaurantIdSelector,
} from '../../pages/Dashboard/selectors';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { createSplitPerItemChecks } from './SplitPerItem.service';

const SplitChecksEqually = ({
  currentOrder,
  selectedCheck,
  guests,
  togglePaymentModal,
  setState,
  checks,
  initialChecksData,
  deleteChecksList,
  setIsLoading,
  printer,
  accessToken,
}) => {
  const storeConfig = useSelector(storeConfigSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const timeZone = useSelector(storeTimeZoneSelector);
  const [isPrinterApiLoading, setIsPrinterApiLoading] = useState(false);
  const dispatch = useDispatch();
  const checkPaid = useMemo(
    () =>
      currentOrder &&
      selectedCheck &&
      selectedCheck.paidAmount ===
        Number(selectedCheck.payableAmount.toFixed(2)),
    [currentOrder, selectedCheck]
  );

  let checkPartiallyPaid = selectedCheck?.payments?.length > 0;

  const creatingChecks = async (checks) => {
    setState({
      numChecks: checks.length,
      checks: checks || [],
    });
  };

  const handlePayEqually = useCallback(() => {
    dispatch(updateCurrentOrder({ ...currentOrder, billType: 'equal' }));
    togglePaymentModal((selectedCheck?.payableAmount).toFixed(2));
  }, [selectedCheck?.payableAmount.toFixed(2), togglePaymentModal]);

  const handleIncrement = async (checks, selectedCheck) => {
    const incrementPayingForGuest = selectedCheck.numGuests + 1;

    const totalChecks = checks.length;
    const deleteChecks = [...deleteChecksList];
    const newChecks = checks.map((check) => {
      if (check.number === selectedCheck.number) {
        const initialPayableAmount = check.initialPayableAmount
          ? check.initialPayableAmount
          : checks[totalChecks - 1].payableAmount;

        return {
          ...check,
          payableAmount: Number(
            (initialPayableAmount * incrementPayingForGuest).toFixed(2)
          ),
          numGuests: incrementPayingForGuest,
          initialPayableAmount: initialPayableAmount,
        };
      }
      return check;
    });

    deleteChecks.push(newChecks[totalChecks - 1]);

    const splitEquallyPaymentPayload = {
      checks: newChecks.slice(0, totalChecks - 1),
      numChecks: newChecks.slice(0, totalChecks - 1).length || 0,
      orderId: currentOrder._id,
    };

    creatingChecks(newChecks.slice(0, totalChecks - 1));
    setState({
      deleteChecksList: deleteChecks.filter((check) => check.id),
    });
  };

  const handleDecrement = (checks, selectedCheck) => {
    const decrementPayingForGuest = selectedCheck.numGuests - 1;
    const totalChecks = checks.length;
    const deleteChecks = [...deleteChecksList];

    let addCheck = {};
    const initialPayableAmount = selectedCheck.initialPayableAmount
      ? selectedCheck.initialPayableAmount
      : checks[totalChecks - 1].payableAmount;

    const newChecks = checks.map((check) => {
      if (check.number === selectedCheck.number) {
        addCheck = {
          ...check,
          payableAmount: Number(
            (initialPayableAmount * decrementPayingForGuest).toFixed(2)
          ),
          numGuests: decrementPayingForGuest,
        };
        return addCheck;
      }
      return check;
    });

    const checkId = initialChecksData.find(
      (item) => item.number === `${totalChecks}`
    )?.id;

    const splitEquallyPaymentPayload = {
      checks: newChecks.concat({
        ...addCheck,
        number: `${totalChecks}`,
        numGuests: 1,
        payableAmount: initialPayableAmount,
        id: checkId,
      }),
      numChecks: newChecks.concat({
        ...addCheck,
        number: `${totalChecks}`,
      }).length,
      orderId: currentOrder._id,
    };

    creatingChecks(
      newChecks.concat({
        ...addCheck,
        number: `${totalChecks}`,
        numGuests: 1,
        payableAmount: initialPayableAmount,
        id: checkId,
      })
    );
    setState({
      deleteChecksList: deleteChecks.filter((check) => check.id !== checkId),
    });
  };

  const handlePrintCommand = async () => {
    setIsPrinterApiLoading(true);

    let printCommandRequestObj = {};

    if (selectedCheck.id) {
      printCommandRequestObj = {
        checkId: selectedCheck.id,
        printerId: printer?._id,
        orderId: currentOrder._id,
        storeId: storeConfig?.storeId,
        restaurantId,
        printReceipt: true,
        receiptType: 12,
        openCashDrawer: false,
        timeZone,
      };
    } else {
      const splitEquallyPaymentPayload = {
        checks: checks,
        numChecks: checks.length || 0,
        orderId: currentOrder._id,
      };

      const createCheckApi = await createSplitPerItemChecks(
        splitEquallyPaymentPayload,
        'equal',
        accessToken,
        currentOrder
      );

      if (createCheckApi?.errors?.length === 0) {
        setState({
          numChecks: createCheckApi?.data?.splitPayment?.checks.length,
          checks: createCheckApi?.data?.splitPayment?.checks || [],
          concluded: true,
        });
        const currentCheck = createCheckApi?.data?.splitPayment?.checks.find(
          (check) => check.number === selectedCheck.number
        );

        printCommandRequestObj = {
          checkId: currentCheck.id,
          printerId: printer?._id,
          orderId: currentOrder._id,
          storeId: storeConfig?.storeId,
          restaurantId,
          printReceipt: true,
          receiptType: 12,
          openCashDrawer: false,
          timeZone,
        };
      }
    }

    if (printer?._id) {
      await addPrinterCommandApi(printCommandRequestObj);
    }
    setIsPrinterApiLoading(false);
  };

  const renderItems = (selectedCheck) => {
    return selectedCheck?.items.map((item, index) => {
      const itemDetails = currentOrder.menuItems.find(
        (menuItem) =>
          menuItem._id === item.itemId &&
          !menuItem?.isHalf &&
          !menuItem.isQuarter
      );

      return Object.keys(itemDetails || {}).length > 0 ? (
        <BillCheckItemUI
          id={itemDetails._id + index}
          isVoided={itemDetails.isVoided}
          title={`${itemDetails.name} ${
            itemDetails.quantity > 1 ? `x ${itemDetails.quantity}` : ``
          }`}
          instruction={getItemInstruction(itemDetails)}
          price={`${storeConfig?.currency || '$'}${(
            itemDetails.payablePrice *
            item?.share *
            selectedCheck.numGuests
          ).toFixed(2)}`}
        />
      ) : (
        <div />
      );
    });
  };

  return (
    <div className="bem-split-equally">
      <div />
      <BillCheckUI
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        <BillCheckUI.Header>
          <BillCheckInfoUI.Title
            borderSide={'bottom'}
            title={'POS - Dine In'}
            description={`Table ${currentOrder.tableNumber}`}
          />
        </BillCheckUI.Header>
        <BillCheckUI.Body>
          <BillCheckInfoUI
            borderSide={'bottom'}
            title={'Placed:'}
            description={moment(currentOrder.date).calendar()}
          />
          {currentOrder.notes && (
            <BillCheckInfoUI
              borderSide={'bottom'}
              title={'Notes:'}
              description={currentOrder.notes}
            />
          )}
          {selectedCheck?.items?.length > 0 && renderItems(selectedCheck)}
          {selectedCheck?.items?.length == 0 &&
            currentOrder.menuItems
              .filter((i) => i.quantity >= 1 && !i?.isHalf && !i.isQuarter)
              .map((i, index) => {
                return (
                  <BillCheckItemUI
                    key={i._id + index}
                    isVoided={i.isVoided}
                    title={`${i.name} ${
                      i.quantity > 1 ? `x ${i.quantity}` : ``
                    }`}
                    instruction={getItemInstruction(i)}
                    price={`${storeConfig?.currency || '$'}${(
                      (i.payablePrice / guests) *
                      selectedCheck.numGuests
                    ).toFixed(2)}`}
                  />
                );
              })}
          {Object.values(
            currentOrder.menuItems
              .filter((i) => i.isHalf)
              .reduce(
                (acc, k) => ({
                  ...acc,
                  [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                }),
                {}
              )
          ).map((item, index) => {
            const quantity = item.reduce(
              (quantity, item) => (quantity = quantity + item.quantity),
              0
            );
            const id = item.map((i) => i._id).join('+');
            const name = `${item.map((i) => i.name).join('/')}`;
            const payablePrice = item.reduce(
              (acc, i) => (acc += i.payablePrice),
              0
            );
            return (
              <BillCheckItemUI
                key={id}
                title={`${name} ${quantity > 1 ? `x ${quantity}` : ``}`}
                price={`${storeConfig?.currency || '$'}${(
                  (payablePrice / guests) *
                  selectedCheck.numGuests
                ).toFixed(2)}`}
              />
            );
          })}
          {Object.values(
            currentOrder.menuItems
              .filter((i) => i.isQuarter)
              .reduce((acc, k) => {
                return {
                  ...acc,
                  [k.fourQuarterIndex]: [...(acc[k.fourQuarterIndex] || []), k],
                };
              }, {})
          ).map((item, index) => {
            const quantity = item.reduce(
              (quantity, item) => (quantity = quantity + item.quantity),
              0
            );
            const id = item.map((i) => i._id).join('+');
            const name = `${item.map((i) => i.name).join('/')}`;
            const payablePrice = item.reduce(
              (acc, i) => (acc += i.payablePrice),
              0
            );
            return (
              <BillCheckItemUI
                key={id}
                title={`${name} ${quantity > 1 ? `x ${quantity}` : ``}`}
                price={`${storeConfig?.currency || '$'}${(
                  (payablePrice / guests) *
                  selectedCheck.numGuests
                ).toFixed(2)}`}
              />
            );
          })}
        </BillCheckUI.Body>
        <BillCheckUI.Footer>
          {!checkPaid && !checkPartiallyPaid && !selectedCheck.id && (
            <BillCheckInfoUI.Counter
              style={{ marginBottom: -10 }}
              title="Paying for Guests"
              quantity={selectedCheck.numGuests}
              onIncrement={() => handleIncrement(checks, selectedCheck)}
              onDecrement={() => handleDecrement(checks, selectedCheck)}
              maxLimit={checks.reduce((total, check) => {
                if (check.number >= selectedCheck.number) {
                  return total + check.numGuests;
                }
                return total + 0;
              }, 0)}
            />
          )}

          {parseFloat(currentOrder.discount) > 0 ? (
            <BillCheckInfoUI
              title={'Discount'}
              description={`-${storeConfig?.currency || '$'}${(
                (currentOrder.discount * (selectedCheck?.numGuests || 1)) /
                guests
              ).toFixed(2)}`}
            />
          ) : null}
          {currentOrder.specialDiscount !== '0' ? (
            <BillCheckInfoUI
              title={'Specials'}
              description={`-${storeConfig?.currency || '$'}${(
                (currentOrder.specialDiscount *
                  (selectedCheck?.numGuests || 1)) /
                guests
              ).toFixed(2)}`}
            />
          ) : null}

          {Number(currentOrder?.publicHolidaySurcharge) > 0 ? (
            <BillCheckInfoUI
              title={'Public Holiday Surcharge'}
              description={`+${storeConfig?.currency || '$'}${(
                (currentOrder?.publicHolidaySurcharge *
                  (selectedCheck?.numGuests || 1)) /
                guests
              ).toFixed(2)}`}
            />
          ) : null}

          <BillCheckInfoUI.Total
            title={'Amount Paid'}
            description={`${storeConfig?.currency || '$'}${(
              selectedCheck?.paidAmount || 0
            ).toFixed(2)}`}
          />

          <BillCheckInfoUI.Total
            title={'Total'}
            description={`${
              storeConfig?.currency || '$'
            }${selectedCheck.payableAmount.toFixed(2)}`}
          />

          <BillCheckUI.ActionButtons>
            <ButtonUI
              styleType="success"
              sizeType="l"
              rounded="m"
              fullWidth
              disabled={checkPaid}
              onClick={handlePayEqually}
            >
              Pa{checkPaid ? `id` : `y`}
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="l"
              rounded="m"
              fullWidth
              onClick={handlePrintCommand}
              disabled={!printer?._id || isPrinterApiLoading}
              icon={
                isPrinterApiLoading && (
                  <PosIconLoading
                    mainColor="white"
                    style={{
                      marginLeft: 16,
                      width: '18px',
                      height: '18px',
                    }}
                  />
                )
              }
            >
              Print
            </ButtonUI>
          </BillCheckUI.ActionButtons>
        </BillCheckUI.Footer>
      </BillCheckUI>
    </div>
  );
};

export default SplitChecksEqually;
