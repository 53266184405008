import React from 'react';
import './bem-single-cart-item.scss';
import { ButtonGroupUI } from '../ButtonGroupUI';
import { StackedCirclesIcon as IconStackedCircles } from 'nexticons/solid';
import clsx from 'clsx';
import { capitalizeString } from '../../../util/utils';
import { BadgeUI } from '../BadgeUI';
import PropTypes from 'prop-types';
import { mergeRefs } from 'react-merge-refs';

export const SingleCartItemUI = React.forwardRef(
  (
    {
      onClick,
      children,
      additionalClasses,
      title,
      price,
      notes,
      size,
      addInstruction,
      removeInstruction,
      checkInstruction,
      dragRef,
      provided,
    },
    ref
  ) => {
    return (
      <>
        <div
          // type="button"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          //     ref={provided.innerRef}
          className={clsx('bem-single-cart-item', additionalClasses)}
          onClick={onClick}
          ref={mergeRefs([ref, dragRef, provided.innerRef])}
        >
          <Inner>
            <div className="order-item-container">
              <div className="order-item">
                <Top>
                  <Title>{title}</Title>
                </Top>
                {checkInstruction || size ? (
                  <Bottom>
                    {size && (
                      <BottomBadgeGroup>
                        <BadgeUI
                          title={capitalizeString(size)}
                          sizeType="xxs"
                          rounded="s"
                        />
                      </BottomBadgeGroup>
                    )}
                    {checkInstruction && (
                      <Ingredients>
                        {notes && (
                          <>
                            <b>Notes: </b>
                            {notes}
                            <br />
                          </>
                        )}
                        {addInstruction && (
                          <>
                            <b>Add </b>
                            {addInstruction}
                            <br />
                          </>
                        )}
                        {removeInstruction && (
                          <>
                            <b>Remove </b>
                            {removeInstruction}
                          </>
                        )}
                      </Ingredients>
                    )}
                  </Bottom>
                ) : null}
              </div>
              <div className="price-tag">
                {checkInstruction && <TopBadgeGroup>{price}</TopBadgeGroup>}
              </div>
            </div>
          </Inner>
          {!checkInstruction && <TopBadgeGroup>{price}</TopBadgeGroup>}
          <span className="bem-single-cart-item__edit-btn" title="Edit">
            <IconStackedCircles height="38" />
          </span>
        </div>
      </>
    );
  }
);

function Inner({ children }) {
  return <span className="bem-single-cart-item__inner">{children}</span>;
}
function Top({ children }) {
  return (
    <span className="bem-single-cart-item__inner-box bem-single-cart-item__inner-box--top">
      {children}
    </span>
  );
}
function TopBadgeGroup({ children }) {
  return (
    <ButtonGroupUI gap="s" justifyContent="right" modifierClassName="top-badge">
      {children}
    </ButtonGroupUI>
  );
}

function Bottom({ children }) {
  return (
    <span className="bem-single-cart-item__inner-box bem-single-cart-item__inner-box--bottom">
      {children}
    </span>
  );
}
function BottomBadgeGroup({ children }) {
  return <ButtonGroupUI gap="xs">{children}</ButtonGroupUI>;
}

function Title({ children }) {
  return <span className="bem-single-cart-item__title">{children}</span>;
}

function Ingredients({ children }) {
  return (
    <span className="bem-single-cart-item__ingredients-wrap">
      <span className="bem-single-cart-item__ingredients">{children}</span>
    </span>
  );
}

SingleCartItemUI.defaultProps = {
  onClick: () => {},
  children: null,
  additionalClasses: '',
  title: '',
  price: null,
  notes: '',
  size: '',
  addInstruction: '',
  removeInstruction: '',
  checkInstruction: false,
};
SingleCartItemUI.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  additionalClasses: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.node,
  notes: PropTypes.string,
  size: PropTypes.string,
  addInstruction: PropTypes.string,
  removeInstruction: PropTypes.string,
  checkInstruction: PropTypes.bool,
};
