import moment from 'moment-timezone';
import sortBy from 'lodash/sortBy';

const timeGap = 5; // minutes

const getOrderTimeArrayFun = (orderSetup, storeConfig = {}, orderType) => {
  let orderTimeArray = [];
  orderTimeArray.push({
    date: moment().tz(storeConfig?.timeZone),
    dateString: moment().tz(storeConfig?.timeZone)?.format('YYYY-MM-DD'),
  });
  let preOrderingDays = orderSetup.preOrderingDays
    ? orderSetup.preOrderingDays
    : 5;
  if (orderSetup.isPreOrderingEnabled) {
    for (let i = 1; i < preOrderingDays; i++) {
      orderTimeArray.push({
        date: moment().tz(storeConfig?.timeZone).add(i, 'd'),
        dateString: moment()
          .tz(storeConfig?.timeZone)
          .add(i, 'd')
          .format('YYYY-MM-DD'),
      });
    }
  }

  orderTimeArray.forEach((obj, i) => {
    if (i === 0) obj.display = 'Today';
    else if (i === 1) obj.display = 'Tomorrow';
    else if (i < 7 && i > 1) obj.display = obj.date.format('dddd');
    else obj.display = obj.date.format('MMM DD');

    obj.day = obj.date.day().toString();
    obj.id = i;
    let times = [],
      timeGroup = [];
    let shiftStartObj = moment(
      `${obj.date.format('YYYY-MM-DD')} 00:00 AM}`,
      'YYYY-MM-DD hh:mm A'
    );
    let shiftEndObj = moment(
      `${obj.date.format('YYYY-MM-DD')} 11:59 PM`,
      'YYYY-MM-DD hh:mm A'
    );
    let today = moment(
      moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm A'),
      'YYYY-MM-DD hh:mm A'
    );
    if (shiftStartObj.isAfter(today) && shiftEndObj.isAfter(today)) {
      //Normal default time operation as shift is yet to start
      let tempTime = shiftStartObj;
      const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
      tempTime = tempTime.minute(roundedUp).second(0);
      while (tempTime.isBefore(shiftEndObj)) {
        let time = {
          display: tempTime.format('hh:mm A'),
          displayForUse: tempTime.format('hh:mm A'),
          shiftId: 'xxx-xxx-xxx',
          timeObjFinal: tempTime.format('YYYY-MM-DD'),
          mTimeStamp: tempTime.valueOf(),
        };
        times.push(time);
        timeGroup.push(tempTime.format('hh:mm A'));
        tempTime.add(timeGap, 'minutes');
      }
    } else if (shiftStartObj.isBefore(today) && shiftEndObj.isAfter(today)) {
      let todayTT = moment(
        moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
        'YYYY-MM-DD hh:mm A'
      ).add(
        Number(
          orderType !== '2' ? orderSetup.pickUpTime : orderSetup.deliveryTime
        ),
        'minutes'
      );
      // Add current time, rounding off time and then go with default time
      let timeD = {
        display: 'ASAP',
        displayForUse: todayTT.format('hh:mm A'),
        shiftId: 'xxx-xxx-xxx',
        timeObjFinal: todayTT.format('YYYY-MM-DD'),
        mTimeStamp: todayTT.valueOf(),
      };
      times.push(timeD);
      timeGroup.push('ASAP');
      let tempTime = today.add(
        Number(
          orderType !== '2' ? orderSetup.pickUpTime : orderSetup.deliveryTime
        ),
        'minutes'
      );
      const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
      tempTime = tempTime.minute(roundedUp).second(0);
      while (tempTime.isBefore(shiftEndObj)) {
        let time = {
          display: tempTime.format('hh:mm A'),
          displayForUse: tempTime.format('hh:mm A'),
          shiftId: 'xxx-xxx-xxx',
          timeObjFinal: tempTime.format('YYYY-MM-DD'),
          mTimeStamp: tempTime.valueOf(),
        };
        times.push(time);
        timeGroup.push(tempTime.format('hh:mm A'));
        tempTime.add(timeGap, 'minutes');
      }
    }

    obj.times = times;
    obj.timeGroup = { displayTime: timeGroup };
    obj.valueGroup = { displayTime: times.length > 0 ? times[0].display : '' };
  });

  orderTimeArray = orderTimeArray.filter((p) => {
    return p.times.length !== 0;
  });
  orderTimeArray =
    orderTimeArray.length > preOrderingDays
      ? orderTimeArray.slice(0, preOrderingDays)
      : orderTimeArray;
  orderTimeArray = orderTimeArray.map((p) => {
    p.times = sortBy(p.times, 'mTimeStamp');
    return p;
  });
  return orderTimeArray;
};

export const getOrderTimeArray = getOrderTimeArrayFun;
