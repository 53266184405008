import axios from 'axios';
import { API_URL } from 'configuration/apiUrl';
import { v4 as uuidv4 } from 'uuid';
import { _auth } from '../../../firebase';

export const recordWithdrawal = async (reqObj, drawerId) => {
  const accessToken = await _auth.currentUser.getIdToken(true);
  const response = await axios.post(
    `${API_URL}/stores/v1/cashDrawers/${drawerId}/withdrawals`,
    reqObj,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'x-correlation-id': uuidv4(),
      },
    }
  );

  if (response.status === 200 || response.status === 201) {
    return { ...response.data, success: true };
  } else {
    return { success: false };
  }
};
