import React from 'react';
import { PosIconClose } from 'assets/icons/PosIconClose';

export const SidePanelHeader = ({ title, handlePanelClose }) => {
  return (
    <div className="side-panel__header dine-in-blur-area">
      <div className="side-panel__header-box">
        {handlePanelClose && (
          <PosIconClose
            darkModeColor="#000"
            onClick={handlePanelClose}
            lineThickness={1.5}
          />
        )}
        <h1 className="side-panel__title">{title}</h1>
      </div>
    </div>
  );
};
