import { nextOrderConfig } from 'configuration/nextOrderConfig';
import axios from 'axios';
import axiosWithInterceptor from 'util/interceptor';

export const getOrderDetailsById = async (orderId) => {
  const response = await axiosWithInterceptor.get(
    `${
      nextOrderConfig.baseUrl
    }/orders/v1/order?orderId=${orderId}&fetchTax=${false}`
  );
  if (response.status === 200) {
    return response.data?.data?.order;
  }

  return null;
};

export const markAsDelivered = async (
  driverId,
  storeId,
  restaurantId,
  shiftId,
  orderId,
  isOtherDelivery
) => {
  const response = await axios.post(
    `${nextOrderConfig?.cloudFunctionUrl}/updateDeliveryStatus`,
    {
      driverId: driverId,
      storeId: storeId,
      restaurantId: restaurantId,
      shiftId: shiftId,
      status: '4', // status 4 mean Delivered
      orderId: orderId,
      isOtherDelivery: isOtherDelivery,
    }
  );

  if (response.status === 200) {
    return response?.data?.success;
  }

  return false;
};
