import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { MenuItem } from '../MenuItems/MenuItem';
import { PosIconLeftChevron } from '../../assets/icons/PosIconLeftChevron';
import { useSelector, useDispatch } from 'react-redux';
import {
  isItemWithSingleSize,
  incrementItemQuantitySingleSize,
} from '../../itemProcessor';
import {
  openMenuItem,
  updateCurrentSpecialsCategoryItem,
  updateSpecialsFourQuarterData,
  updateSpecialsHalfHalfData,
} from '../../pages/Dashboard/action';
import HalfHalfItem from '../HalfHalfView/HalfHalfItem';
import { ScrollableBoxUI } from '../../UI/components';
import { SearchMenuItems } from '../SearchMenuItems';
import { PosIconSearch } from '../../assets/icons/PosIconSearch';
import { useMenuItemsFilterByString } from '../../customHooks/useMenuItemsFilterByString';
import useMenuItems from 'hooks/useMenuItems';
import useCategories from 'hooks/useCategories';

const SpecialsMenuItems = ({ parentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchString, setSearchString] = useState('');
  const { categoryIndex, specialsId } = useParams();
  const categories = useCategories();

  const _menuItems = useMenuItems();

  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const currentSpecialsItem =
    useSelector((state) => state.dashboardReducer.currentSpecialsItem) || {};

  const selectedCategory =
    currentSpecialsItem.selectedCategories[categoryIndex] || {};

  const productSetup = useSelector(
    (state) => state.dashboardReducer.productSetup
  );
  const courseAutoAssignmentEnabled = useSelector(
    (state) => state.dashboardReducer?.orderSetup?.courseAutoAssignmentEnabled
  );

  const currentMenuItems = useMenuItemsFilterByString({
    totalItems: selectedCategory.menuItems || [],
    menuItems: selectedCategory.menuItems || [],
    searchString,
  });

  const isHalfHalfEnabled = !!productSetup?.halfHalfs?.some((h) =>
    selectedCategory.categories.some((scc) =>
      h.categories.some((c) => scc._id === c._id)
    )
  );

  const isFourQuarterEnabled =
    isHalfHalfEnabled && productSetup.isFourQuarterEnabled;

  const boxCls = clsx({
    'main-area-header': true,
    'hide-shadow specials-items': true,
    'hide-shadow specials': !!specialsId,
  });

  const itemsWrapCls = clsx({
    'main-area-body-wrap': true,
    'specials-items': !!specialsId,
  });

  const handleMenuItemClick = (_event, item) => {
    let newItem = item;

    const selectedCategory = categories.find(
      (category) => category._id === item?.categoryId
    );

    if (selectedCategory?.courseId && !newItem.courseId) {
      newItem = {
        ...newItem,
        courseId: selectedCategory?.courseId,
        courseName: selectedCategory?.courseName,
      };
    }

    // Remove Course Id If orderType is not equal to dine-in
    if (currentOrder?.orderType !== '3' || !courseAutoAssignmentEnabled) {
      delete newItem.courseId;
      delete newItem.courseName;
    }
    if (!isItemWithSingleSize(newItem)) dispatch(openMenuItem(newItem, false));
    else {
      dispatch(
        updateCurrentSpecialsCategoryItem(parseInt(categoryIndex), {
          ...incrementItemQuantitySingleSize(newItem),
          isSpecialItem: true,
        })
      );
      history.push(`${parentPage}/specials/${currentSpecialsItem._id}`);
    }
  };

  const handleHalfHalfItemClick = () => {
    dispatch(
      updateSpecialsHalfHalfData(
        parseInt(categoryIndex),
        currentSpecialsItem._id
      )
    );
  };

  const handleFourQuarterItemClick = () => {
    dispatch(
      updateSpecialsFourQuarterData(
        parseInt(categoryIndex),
        currentSpecialsItem._id
      )
    );
  };

  const updateSearchString = (value) => {
    setSearchString(value);
  };

  const toggleSearchBox = () => {
    setShowSearchBox(!showSearchBox);
    setSearchString('');
  };

  return (
    <div className="main-area" id="main-area">
      <div className="main-area-inner">
        <div className={boxCls}>
          <Link
            to={`${parentPage}/specials/${currentSpecialsItem._id}`}
            title="Back"
            className="specials-items-back-btn"
          >
            <PosIconLeftChevron mainColor="#363636" darkModeColor="#ffffff" />
          </Link>
          <div className="searchAreaContainer">
            {showSearchBox && (
              <SearchMenuItems
                searchString={searchString}
                showSearchBox={showSearchBox}
                updateSearchString={updateSearchString}
                toggleSearchBox={toggleSearchBox}
              />
            )}
            <button
              type="button"
              className="search-btn"
              onClick={toggleSearchBox}
            >
              <PosIconSearch mainColor="#484B4C" />
            </button>
          </div>
          <h1 className="category-title">
            Select a {selectedCategory.displayName}
          </h1>
        </div>
        <div className={itemsWrapCls}>
          <ScrollableBoxUI>
            <div className="main-area-body">
              <div className="food-cards">
                {isHalfHalfEnabled && (
                  <HalfHalfItem
                    item={{
                      id: currentSpecialsItem._id,
                      name: 'Half/Half',
                      urlS3: productSetup.halfHalfPlaceholderImage,
                    }}
                    link={`${parentPage}/specials/${currentSpecialsItem._id}/${categoryIndex}/half-half`}
                    onClick={handleHalfHalfItemClick}
                  />
                )}
                {isFourQuarterEnabled && (
                  <HalfHalfItem
                    item={{
                      id: currentSpecialsItem._id,
                      name: 'Four Quarter',
                      urlS3: productSetup.fourQuarterPlaceHolderImage,
                    }}
                    link={`${parentPage}/specials/${currentSpecialsItem._id}/${categoryIndex}/four-quarter`}
                    onClick={handleFourQuarterItemClick}
                  />
                )}
                {currentMenuItems.map((item, i) => (
                  <MenuItem
                    key={i}
                    item={item}
                    parentPage={parentPage}
                    onItemClick={handleMenuItemClick}
                  />
                ))}
              </div>
            </div>
          </ScrollableBoxUI>
        </div>
      </div>
    </div>
  );
};

export default SpecialsMenuItems;
