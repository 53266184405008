import clsx from 'clsx';
import { PosIconPlus } from '../../assets/icons/PosIconPlus';
// import useRouteByMenuItemType from '../../customHooks/useRouteByMenuItemType';
import { useHistory } from 'react-router-dom';
import { openMenuItem } from '../../pages/Dashboard/action';
import { useDispatch } from 'react-redux';
import { PosIconSilverware } from '../../assets/icons/PosIconSilverware';
import { CountBubble } from 'components/CountBubble';
import useSelectedIngredientsCount from 'customHooks/useSelectedIngredientsCount';

const HalfItemSection = ({
  item = {},
  onClick,
  parentPage,
  toggleItemEditMode,
  isInModifyMode = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cardCls = clsx({
    'half-single-card': true,
    selected: item.currentMenuItem?.urlS3 || item.currentMenuItem?.name,
  });

  const titleCls = clsx({
    'half-item-title': true,
    'with-icon': !item.currentMenuItem?.urlS3 && !item.currentMenuItem?.name,
  });

  // const linkTo = useRouteByMenuItemType(parentPage, item.currentMenuItem || {});

  const linkToCustomise = `${parentPage}/menuitem/${item.currentMenuItem?._id}/customization/`;

  const handleCustomiseItemClick = (event) => {
    event.stopPropagation();
    toggleItemEditMode(item.halfIndex);
    history.push(linkToCustomise);
    dispatch(
      openMenuItem({ ...item.currentMenuItem, isHalf: true }, isInModifyMode)
    );
  };

  const selectedCustomizationCount = useSelectedIngredientsCount(
    item.currentMenuItem || {}
  );

  return (
    <div className="half-card-wrap" key={item.halfIndex}>
      <button
        title={item.currentMenuItem?.name}
        className={cardCls}
        onClick={onClick}
      >
        <span className="half-item-img-wrap">
          {item.currentMenuItem?.urlS3 ? (
            <img src={item.currentMenuItem?.urlS3} alt="" />
          ) : (
            ''
          )}
        </span>
        <span className={titleCls}>
          {item.currentMenuItem?.name ? (
            <span className="inner-box">
              <span className="customize-half-title">
                {item.currentMenuItem?.name}
              </span>
              <span
                className="customize-half-btn"
                onClick={handleCustomiseItemClick}
              >
                <span className="customize-half-btn-title">Customize</span>
                {selectedCustomizationCount ? (
                  <CountBubble quantity={selectedCustomizationCount} />
                ) : (
                  <PosIconSilverware mainColor="#000000" title="Customise" />
                )}
              </span>
            </span>
          ) : (
            <div>
              <PosIconPlus
                mainColor="#ffffff"
                lineThickness={1.25}
                isDefaultStyle={false}
                className="plus-icon"
              />
              <span>Add</span>
            </div>
          )}
        </span>
      </button>
    </div>
  );
};

export default HalfItemSection;
