import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import DriversStatusSection from './DriversStatusSection';
import { filteredDriversSelector } from '../../../pages/Drivers/selectors';

export const DeliveryDriversList = () => {
  const { assignDriver } = useSelector((state) => state.driversReducer);
  const filteredDrivers = useSelector(filteredDriversSelector);
  const {
    driversWaitingOnStore,
    driversOnDelivery,
    driversReturningToStore,
    clockedOutDrivers,
  } = filteredDrivers || {};

  const cls = classNames({
    'driver-accessibility-tabs': true,
    'assign-driver-mode': assignDriver,
  });

  return (
    <div className={cls}>
      <DriversStatusSection
        key={0}
        drivers={driversWaitingOnStore?.filter((driver) => !!driver.shiftId)}
        colorClassName="green"
        title="Waiting on Store"
        showAssignButton={true}
      />
      <DriversStatusSection
        key={1}
        drivers={driversReturningToStore}
        colorClassName="orange"
        title="Returning to Store"
        showAssignButton={true}
      />
      <DriversStatusSection
        key={2}
        drivers={driversOnDelivery}
        colorClassName="red"
        title="On Delivery"
      />
      <DriversStatusSection
        key={3}
        drivers={clockedOutDrivers}
        colorClassName="grey"
        title="Clocked Out"
      />
    </div>
  );
};
