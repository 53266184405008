import React from 'react';
import PropTypes from 'prop-types';
import { CircleButtonUI } from '../index';
import { XRoundedIcon as IconXRounded } from 'nexticons/outline';
import { getUIModifierKeys } from '../modifiersMapping';

export const CloseButtonCircleUI = ({ onClick, buttonSize, style }) => {
  return (
    <CircleButtonUI
      style={style}
      onClick={onClick}
      buttonSize={buttonSize}
      icon={<IconXRounded width="43%" strokeWidth="3.25" />}
      title="Close"
    />
  );
};

CloseButtonCircleUI.defaultProps = {
  onClick: () => {},
  style: {},
  buttonSize: 's',
};

CloseButtonCircleUI.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  buttonSize: PropTypes.oneOf(getUIModifierKeys('buttonSize')),
};
