import React, { useEffect, useState } from 'react';
import GeneralModal from '../../modals/GeneralModal/index-new';
import { HeaderSkeleton } from './UberDriverModal.skeleton';
import {
  XIcon,
  SetLocationIcon,
  PhoneIcon as PhoneIconSolid,
  ScooterIcon,
} from 'nexticons/solid';
import {
  ClockNineIcon,
  DollarCircleIcon,
  LoadingIcon,
  PhoneIcon,
  WarningIcon,
} from 'nexticons/outline';
import PosUberIcon from '../../assets/icons/PosUberIcon';
import MapTiler from '../../components/MapTiler/MapTiler';
import {
  getUberDeliveryDetails,
  createUberQoute,
  createUberDelivery,
  cancelUberDelivery,
} from './UberDriverModal.service';
import './UberDriverModal.css';
import clsx from 'clsx';

const currencySignMap: any = {
  AU: '$',
  GB: '£',
};

export const statusHeadingMap = {
  quote: 'Request a Driver',
  pending: 'Assigning a Driver',
  pickup: 'On the way to your store',
  pickup_complete: 'Pickup complete. On the way to customer',
  dropoff: 'On the way to customer',
  delivered: 'Delivery Complete',
  canceled: 'Delivery Cancelled',
  returned: 'Returned',
};

const statusInfomationTextMap = {
  quote:
    'Uber Driver will be dispatched to your store at the specified collection time.',
  pending:
    'Delivery has been accepted by Uber, but they are yet to designate a driver. They are finding the closest available driver to your store.',
  pickup:
    'Uber Driver is assigned and is en-route to you to pick up the items.',
  pickup_complete:
    'Driver has your food and is on the way to deliver it to the customer.',
  dropoff: 'Driver is moving towards drop-off.',
  delivered:
    'Driver has delivered the food to the customer and completed the delivery.',
  canceled:
    'Delivery has been cancelled. You may have cancelled the delivery, or it could be due to an internal Uber error. You can call Uber support to find out more information.',
  returned:
    'The customer didn’t accept the delivery, which has been cancelled. Uber has created a new delivery to return the food to the store.',
};

type StatusType =
  | 'quote'
  | 'pending'
  | 'pickup'
  | 'pickup_complete'
  | 'dropoff'
  | 'delivered'
  | 'canceled'
  | 'returned';

interface UberDeliveryDetailsType {
  trackingUrl: string;
  courierName: string;
  courierNumber: string;
  courierAvatarLink: string;
  status: StatusType;
}
interface UberDriverModalProps {
  toggleModal: () => void;
  selectedOrder: any;
  currencySymbol: string;
}

const UberDriverModal: React.FunctionComponent<UberDriverModalProps> = ({
  toggleModal,
  selectedOrder,
  currencySymbol,
}) => {
  //To check if uber Delivery already created for this Order, uberDriveDeliveryCreated True mean created false mean not created
  const {
    uberDriveDeliveryCreated = false,
    _id,
    storeId,
    addressLocation,
    payableAmount,
    countryIdentifier,
  } = selectedOrder;
  const { lat, lng } = addressLocation;
  const [apiLoading, setApiLoading] = useState(true);
  const [quoteDetails, setQuoteDetails] = useState({
    fee: 0,
    collectionTime: 0,
    deliveredBy: 0,
  });
  const [uberErrorMessage, setUberErrorMessage] = useState('');
  const [uberDeliveryDetails, setUberdeliveryDetails] =
    useState<UberDeliveryDetailsType>({
      trackingUrl: '',
      courierName: '',
      courierNumber: '',
      courierAvatarLink: '',
      status: 'quote',
    });

  const onModalClose = () => {
    toggleModal();
  };

  const callGetUberDeliveryDetailsApi = async () => {
    setApiLoading(true);
    const deliveryDetails = await getUberDeliveryDetails(_id);
    const { data } = deliveryDetails;
    if (data && data?.delivery?.uuid) {
      if (
        data?.delivery?.status === 'pickup' ||
        data?.delivery?.status === 'pickup_complete' ||
        data?.delivery?.status === 'dropoff' ||
        data?.delivery?.status === 'delivered'
      ) {
        const { name, phoneNumber, imgHref } = data?.delivery?.courier;
        setUberdeliveryDetails({
          courierName: name,
          courierNumber: phoneNumber,
          courierAvatarLink: imgHref,
          status: data?.delivery?.status,
          trackingUrl: data.delivery?.trackingUrl,
        });
      } else if (data?.delivery?.status === 'canceled') {
        setUberdeliveryDetails({
          courierName: '',
          courierNumber: '',
          courierAvatarLink: '',
          status: data?.delivery?.status,
          trackingUrl: '',
        });
      } else {
        setUberdeliveryDetails({
          ...uberDeliveryDetails,
          status: data?.delivery?.status,
          trackingUrl: data.delivery?.trackingUrl,
        });
      }
    }
    setApiLoading(false);
  };

  const callCreateUberQuoteApi = async () => {
    setUberErrorMessage('');
    setApiLoading(true);
    const quoteResponseData = await createUberQoute(_id, storeId);
    const { data, errors } = quoteResponseData;

    if (data && data?.quote?.id && errors.length === 0) {
      const { quote } = data;
      const { duration, pickup_duration, fee } = quote;
      setQuoteDetails({
        deliveredBy: duration,
        collectionTime: pickup_duration,
        fee: Number((fee / 100).toFixed(2)),
      });
    } else if (errors.length > 0) {
      setUberErrorMessage(errors[0]);
    }
    setApiLoading(false);
  };

  const callCreateUberDeliveryApi = async () => {
    setUberErrorMessage('');
    setApiLoading(true);
    const deliveryResponse = await createUberDelivery(_id, storeId);
    const { data, errors } = deliveryResponse;
    if (data && data?.delivery?.uuid && errors.length === 0) {
      setUberdeliveryDetails({
        ...uberDeliveryDetails,
        status: data?.delivery?.status,
      });
    } else if (errors.length > 0) {
      setUberErrorMessage(errors[0]);
    }

    setApiLoading(false);
  };

  const callCancelUberDeliveryApi = async () => {
    setApiLoading(true);
    const cancelDeliveryResponse = await cancelUberDelivery(_id);
    const { data } = cancelDeliveryResponse;
    if (data && data?.delivery?.uuid) {
      setUberdeliveryDetails({
        ...uberDeliveryDetails,
        status: data?.delivery?.status,
      });
    }
    setApiLoading(false);
  };

  useEffect(() => {
    if (uberDriveDeliveryCreated) {
      callGetUberDeliveryDetailsApi();
    } else {
      callCreateUberQuoteApi();
    }
  }, [uberDriveDeliveryCreated]);

  const renderQuotationUi = () => {
    return (
      <>
        <div className="deliveredByTimeContainer">
          <ClockNineIcon width={30} color="#777777" strokeWidth={1} />
          <span className="deliveredByTimeText">
            Delivered by {quoteDetails.deliveredBy}
            {quoteDetails.deliveredBy > 1 ? ' mins' : ' min'}
          </span>
        </div>

        {uberErrorMessage ? (
          <div className="errorMessageContianer">
            <WarningIcon width={130} color="#F1C40F" />
            <span>{uberErrorMessage}</span>
          </div>
        ) : (
          <div className="mapTilerContainer">
            <MapTiler lat={lat} long={lng} />
          </div>
        )}

        <div className="footerInfoItemContainer">
          <div className="footerInfoItem">
            <SetLocationIcon width={30} color="#777777" strokeWidth={1} />
            <span className="deliveredByTimeText">
              Collection - {quoteDetails.collectionTime}
              {quoteDetails.collectionTime > 1 ? ' mins' : ' min'}
            </span>
          </div>
          <div className="footerInfoItem">
            <DollarCircleIcon width={30} color="#777777" strokeWidth={1} />
            <span className="deliveredByTimeText">
              Paid - {currencySymbol}
              {payableAmount}
            </span>
          </div>
        </div>
        <div className="footerActionButtonContainer">
          <button
            className="footerActionButton"
            onClick={callCreateUberDeliveryApi}
            disabled={quoteDetails.fee === 0}
          >
            Request Driver {currencySymbol}
            {quoteDetails.fee}
          </button>
        </div>
      </>
    );
  };

  const renderDeliveryDetailsUi = () => {
    const { courierName, courierAvatarLink, courierNumber } =
      uberDeliveryDetails;
    return (
      <>
        {uberErrorMessage ? (
          <div className="errorMessageContianerDeliveryDetailsUI">
            <WarningIcon width={130} color="#F1C40F" />
            <span>{uberErrorMessage}</span>
          </div>
        ) : (
          <div>
            <div
              className={
                courierName
                  ? 'mapTilerContainerDeliveryDetail'
                  : 'mapTilerFullheigth'
              }
            >
              <MapTiler lat={lat} long={lng} />
            </div>
          </div>
        )}

        {courierAvatarLink && (
          <div className="courierInfoContaier">
            <div className="courierInfoItem">
              <img src={courierAvatarLink} alt="a" className="avatar" />
              <span className="courierInfoItemText">{courierName}</span>
            </div>

            <div className="courierInfoItem">
              <PhoneIcon width={25} color="#777777" />
              <span className="courierInfoItemText">{courierNumber}</span>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <GeneralModal
      style={{ borderRadius: 28 }}
      modalStatus={true}
      toggleModal={onModalClose}
      position="center"
      overlay="blur"
      boxWidth="465px"
      boxHeight="690px"
      containerStyle={{}}
      closeBtn="outside"
      overlayStyle={{}}
      backBtnFnc={undefined}
      errorEffect={false}
      keyboardVisibility={false}
      isResponsiveHeight
      minBoxHeight
      showCloseBtn={true}
    >
      <>
        <div className="uberHeader">
          {/*This section contain close button and Uber logo */}

          <div className="headerSection">
            <div className="closeButtonContainer">
              <div className="closeButton" onClick={onModalClose}>
                <XIcon width={15} color="#ffffff" />
              </div>
            </div>
            <PosUberIcon width={170} />
          </div>

          {/*This section contain Infomation text*/}
          {apiLoading ? (
            <HeaderSkeleton />
          ) : (
            <>
              <div
                className={clsx('infoTextContainer', {
                  ['decreasePadding']: uberDeliveryDetails.status !== 'quote',
                })}
              >
                <div className="infoMainText">
                  <span className="mainHeadingText">
                    {statusHeadingMap[uberDeliveryDetails.status]}
                  </span>
                  {(uberDeliveryDetails.status === 'pending' ||
                    uberDeliveryDetails.status === 'pickup' ||
                    uberDeliveryDetails.status === 'pickup_complete' ||
                    uberDeliveryDetails.status === 'dropoff') && (
                    <button
                      className="cancelButton"
                      onClick={callCancelUberDeliveryApi}
                    >
                      Cancel
                    </button>
                  )}
                </div>
                <span className="infoSubText">
                  {statusInfomationTextMap[uberDeliveryDetails.status]}
                </span>
              </div>
              <div className="detailsActionButtonContainer">
                {uberDeliveryDetails.status !== 'quote' && (
                  <div className="uberSupport">
                    <PhoneIconSolid width={20} color="#ffffff" />
                    <span className="itemText"> +61 1800 951 405</span>
                  </div>
                )}

                {uberDeliveryDetails.trackingUrl && (
                  <div
                    className="trackDriver"
                    color="#ffffff"
                    onClick={() =>
                      window.open(
                        uberDeliveryDetails.trackingUrl,
                        '_blank',
                        'noreferrer'
                      )
                    }
                  >
                    <ScooterIcon width={20} />
                    <span className="itemText"> Track driver</span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {apiLoading ? (
          <div className="loadingIconContainer">
            <LoadingIcon width={100} />
          </div>
        ) : uberDriveDeliveryCreated ? (
          renderDeliveryDetailsUi()
        ) : (
          renderQuotationUi()
        )}
      </>
    </GeneralModal>
  );
};

export default UberDriverModal;
