import { ApiStatusEnum } from 'configuration/enums';
import { kiosk_route } from 'constants/routesConsts';
import useIsKiosk from 'hooks/useIsKiosk';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PosIconCheckmarkCircle } from '../../assets/icons/PosIconCheckmarkCircle';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';
import animationData from '../../assets/lotties/782-check-mark-success.json';
import GeneralModal from '../../modals/GeneralModal/index-new';
import {
  authenticateStaff,
  resetCurrentOrder,
} from '../../pages/Dashboard/action';
import {
  currentOrderSelector,
  selectedDrawerPrinterIdSelector,
  storeConfigSelector,
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import { printBillCommand } from '../../services/ordersServices';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import Button from '../Button';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const CashAmountSelectedModal = ({
  orderId = '',
  modalStatus,
  toggleModal,
  isOrdersPage = false,
  cashAmountChange,
  cashPaid,
  title,
}) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);
  const currentOrder = useSelector(currentOrderSelector);
  const selectedDrawerPrinterId = useSelector(selectedDrawerPrinterIdSelector);
  const [printingLoadingStatus, setPrintingLoadingStatus] = useState(
    ApiStatusEnum.IDLE
  );

  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const isKiosk = useIsKiosk();
  const handlePrintBill = async (printReceipt) => {
    if (orderId && selectedDrawerPrinterId) {
      let printObj = {
        printerId: selectedDrawerPrinterId,
        restaurantId: storeConfig.restaurantId,
        orderId,
        storeId: storeConfig?.storeId,
        printReceipt: printReceipt ?? true,
        receiptType: 1,
      };
      await printBillCommand(printObj);
    }
  };

  const handleFinishSale = () => {
    if (isKiosk) {
      toggleModal();
      history.push(kiosk_route);
    } else if (currentOrder.isEditOrder || currentOrder.iteration) {
      history.push('/orders');
    } else {
      dispatch(authenticateStaff(false));
      if (!isOrdersPage) {
        history.push('/');
      } else {
        toggleModal();
      }
    }
    dispatch(resetCurrentOrder());
  };

  const handleFinishBill = async () => {
    setPrintingLoadingStatus(ApiStatusEnum.LOADING);
    await handlePrintBill();
    setPrintingLoadingStatus(ApiStatusEnum.RESOLVED);
    setTimeout(() => {
      handleFinishSale();
    }, 1000);
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="center"
      effect="move"
      overlay=""
      boxWidth="768px"
      boxHeight="506px"
      className="payment-modal cash-amount-selected"
      closeBtn="inside"
    >
      <div className="payment-box">
        <div className="payment-box-header">
          <h2 className="payment-box-title">
            {title
              ? title
              : `${getAmountWithCountryCurrency({
                  amount: Number(cashAmountChange),
                  locale: `en-${storeCountryIdentifier}`,
                  currencyCode,
                })} change`}
          </h2>
          <p className="payment-box-desc">
            {title
              ? ''
              : `Out of ${getAmountWithCountryCurrency({
                  amount: Number(cashPaid),
                  locale: `en-${storeCountryIdentifier}`,
                  currencyCode,
                })}`}
          </p>
        </div>
        <div className="payment-box-body">
          <div className="lottie-box">
            <Lottie
              options={defaultOptions}
              height={180}
              width={180}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => {},
                },
              ]}
            />
          </div>
          <div className="payment-box-methods">
            <div className="payment-box-method-wrap">
              <Button
                title="Finish Sale"
                onClick={handleFinishSale}
                className="finish-sale"
              >
                <span className="payment-method-name green">Finish Sale</span>
              </Button>
            </div>
            <div className="payment-box-method-wrap">
              <Button
                title="Print Bill"
                onClick={handleFinishBill}
                className="print-bill"
              >
                <span className="payment-method-name blue">
                  {printingLoadingStatus === ApiStatusEnum.LOADING && (
                    <PosIconLoading
                      mainColor="#067aff"
                      className="cursor-pointer"
                      size="36px"
                    />
                  )}
                  {printingLoadingStatus === ApiStatusEnum.RESOLVED && (
                    <PosIconCheckmarkCircle
                      mainColor="#067aff"
                      className="cursor-pointer"
                      size="36px"
                    />
                  )}
                  {printingLoadingStatus === ApiStatusEnum.IDLE && 'Print Bill'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </GeneralModal>
  );
};

CashAmountSelectedModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default CashAmountSelectedModal;
