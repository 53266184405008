import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './MenuItemList.style.css';
import clsx from 'clsx';
import {
  getSegregatedMenuItemTypesWithInsertOrderIntact,
  getHalfAndHalfTitle,
} from '../../../util/half-and-half-utils';
import { updateCurrentOrder } from 'pages/Dashboard/action';
import RADIX_COLORS from '../../../../src/radix-colors';
import { HoldIcon, PlayIcon, NextIcon, CheckIcon } from 'nexticons/solid';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import ActionButton from './ActionButton';
import { capitalizeString } from 'util/utils';

dayjs.extend(relativeTime);

const CoursesList = ({ courses, menuItem }) => {
  const dispatch = useDispatch();
  const currentOrder = useSelector(
    (state) => state.dashboardReducer?.currentOrder
  );
  const initailSelectedCourseId = Array.isArray(menuItem)
    ? menuItem[0]?.courseId || courses[0]?._id
    : menuItem?.courseId || courses[0]?._id;
  const [selectedCourseId, setSelectedCourseId] = useState(
    initailSelectedCourseId
  );

  const handleCourseOnClick = (courseId, courseName) => {
    setSelectedCourseId(courseId);

    if (Array.isArray(menuItem)) {
      const updatedMenuItems = currentOrder?.menuItems?.map((item) => {
        let updatedItem = { ...item };
        menuItem.forEach((mItem) => {
          if (item.orderIndex === mItem.orderIndex) {
            updatedItem = {
              ...updatedItem,
              courseId: courseId,
              courseName: courseName,
            };
          }
        });
        return updatedItem;
      });
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          menuItems: updatedMenuItems,
        })
      );
    } else {
      const updatedMenuItems = currentOrder?.menuItems?.map((item) => {
        const updatedItem = { ...item };
        if (item?.orderIndex === menuItem?.orderIndex) {
          updatedItem.courseId = courseId;
          updatedItem.courseName = courses.find(
            (course) => course._id === courseId
          )?.name;
          updatedItem.iteration = currentOrder?.iteration;
        }
        return updatedItem;
      });
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          menuItems: updatedMenuItems,
        })
      );
    }
  };

  return (
    <div className="coursesListContainer">
      <div className="coursesList">
        {courses.map((course) => {
          const isSelected = course._id === selectedCourseId;
          return (
            <div
              key={course._id}
              className="courseTag"
              style={
                isSelected
                  ? {
                      backgroundColor: RADIX_COLORS[course.order],
                      color: 'white',
                    }
                  : {}
              }
              onClick={() => handleCourseOnClick(course._id, course?.name)}
            >
              {capitalizeString(course.name)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MenuItemList = ({
  menuItems,
  courses,
  modalType,
  onItemSelect,
  selectedItems,
  openItemActionDropdown,
  setOpenItemActionDropdown,
  setDropdownRef,
  setSelectedItem,
  calledCoursesDetails,
}) => {
  const colorList = {
    hold: '#067aff',
    rush: '#fd6c00',
  };

  const iconList = {
    hold: <HoldIcon strokeWidth={1} style={{ width: 10 }} color="white" />,
    rush: <NextIcon strokeWidth={1} style={{ width: 12 }} color="white" />,
  };

  const subInfoMapping = {
    hold: 'Held',
    rush: 'Rushed',
    send: 'Sent',
  };

  const allMenuItems =
    getSegregatedMenuItemTypesWithInsertOrderIntact(menuItems);

  return (
    <div>
      {allMenuItems.map((item, index) => {
        let isItemSelected = false;
        if (selectedItems?.length > 0) {
          if (Array.isArray(item)) {
            item.forEach((i) => {
              selectedItems.forEach((selectedItem) => {
                if (Array.isArray(selectedItem)) {
                  selectedItem.forEach((sItem) => {
                    if (sItem.orderIndex === i.orderIndex) {
                      isItemSelected = true;
                    }
                  });
                }
              });
            });
          } else {
            isItemSelected = !!selectedItems.find(
              (selectedItem) => selectedItem.orderIndex === item.orderIndex
            );
          }
        }

        if (Array.isArray(item)) {
          const title = getHalfAndHalfTitle(item);
          let isSentTokitchen = false;
          let sentCourseTime = 0;
          item.forEach((element) => {
            calledCoursesDetails.forEach((calledCourse) => {
              if (
                element?.courseId === calledCourse?.courseId &&
                element?.courseStatus === 'send'
              ) {
                isSentTokitchen = true;
                sentCourseTime = calledCourse?.calledAt * 1000;
              }
            });
          });

          return (
            <div
              key={item[0].orderIndex}
              className={clsx('menuItemListEvenItem', {
                [`${item[0]?.courseStatus}`]: !modalType,
                [`${modalType}`]: isItemSelected,
                ['menuItemListOddItem']: index % 2 === 1,
                ['menuItemSent']: isSentTokitchen,
              })}
            >
              {modalType !== '' && (
                <span className="detail-card-input-box">
                  <input
                    type="radio"
                    // name={`${title}`}
                    id={`${item[0].orderIndex}`}
                    checked={isItemSelected}
                    className="radioInput"
                    onChange={() => {
                      onItemSelect(item);
                    }}
                    onClick={() => {
                      onItemSelect(item);
                    }}
                  />
                  <span
                    className="course-checkstyle"
                    style={
                      isItemSelected
                        ? {
                            backgroundColor: colorList[modalType],
                            borderColor: colorList[modalType],
                          }
                        : {}
                    }
                  />
                </span>
              )}
              <div
                className="menuItemName"
                onClick={() => {
                  onItemSelect(item);
                }}
              >
                <span>{title}</span>
                {modalType === '' &&
                  (item[0]?.courseStatus === 'hold' ||
                    (item[0]?.courseStatus === 'rush' && !isSentTokitchen)) && (
                    <div className="subInfoContainer">
                      <div
                        className={'subInfoIcon'}
                        style={{
                          backgroundColor: colorList[item[0]?.courseStatus],
                        }}
                      >
                        {iconList[item[0]?.courseStatus]}
                      </div>
                      <span className="subInfoText">
                        {subInfoMapping[item[0]?.courseStatus]}{' '}
                        {dayjs(item[0]?.courseStatusSetAt).fromNow()}
                      </span>
                    </div>
                  )}
                {isSentTokitchen && (
                  <div className="subInfoContainer">
                    <div
                      className={'subInfoIcon'}
                      style={{
                        backgroundColor: '#32b900',
                      }}
                    >
                      <CheckIcon
                        strokeWidth={2}
                        style={{ width: 10 }}
                        color="white"
                      />
                    </div>
                    <span className="subInfoText">
                      {'Sent'} {dayjs(sentCourseTime).fromNow()}
                    </span>
                  </div>
                )}
              </div>
              <div className="menuItemActionButton">
                <CoursesList courses={courses} menuItem={item} />

                <ActionButton
                  item={item}
                  setDropdownRef={setDropdownRef}
                  setOpenItemActionDropdown={setOpenItemActionDropdown}
                  openItemActionDropdown={openItemActionDropdown}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </div>
          );
        } else {
          let isSentTokitchen = false;
          let sentCourseTime = 0;
          calledCoursesDetails.forEach((calledCourse) => {
            if (
              item?.courseId === calledCourse?.courseId &&
              item?.courseStatus === 'send'
            ) {
              isSentTokitchen = true;
              sentCourseTime = calledCourse?.calledAt * 1000;
            }
          });
          return (
            <div
              key={item.orderIndex}
              className={clsx('menuItemListEvenItem', {
                [`${item?.courseStatus}`]: !modalType,
                [`${modalType}`]: isItemSelected,
                ['menuItemListOddItem']: index % 2 === 1,
                ['menuItemSent']: isSentTokitchen,
              })}
            >
              {modalType !== '' && (
                <span className="detail-card-input-box">
                  <input
                    type="radio"
                    // name={`${item.name}`}
                    id={`${item.orderIndex}`}
                    value={`${item.name}`}
                    checked={isItemSelected}
                    className="radioInput"
                    onChange={() => {
                      onItemSelect(item);
                    }}
                    onClick={() => {
                      onItemSelect(item);
                    }}
                  />
                  <span
                    className="course-checkstyle"
                    style={
                      isItemSelected
                        ? {
                            backgroundColor: colorList[modalType],
                            borderColor: colorList[modalType],
                          }
                        : {}
                    }
                  />
                </span>
              )}
              <div
                className="menuItemName"
                onClick={() => {
                  onItemSelect(item);
                }}
              >
                <span>{item.name}</span>
                {modalType === '' &&
                  (item?.courseStatus === 'hold' ||
                    (item?.courseStatus === 'rush' && !isSentTokitchen)) && (
                    <div className="subInfoContainer">
                      <div
                        className={'subInfoIcon'}
                        style={{
                          backgroundColor: colorList[item?.courseStatus],
                        }}
                      >
                        {iconList[item?.courseStatus]}
                      </div>
                      <span className="subInfoText">
                        {subInfoMapping[item?.courseStatus]}{' '}
                        {dayjs(item?.courseStatusSetAt).fromNow()}
                      </span>
                    </div>
                  )}
                {isSentTokitchen && (
                  <div className="subInfoContainer">
                    <div
                      className={'subInfoIcon'}
                      style={{
                        backgroundColor: '#32b900',
                      }}
                    >
                      <CheckIcon
                        strokeWidth={1}
                        style={{ width: 10 }}
                        color="white"
                      />
                    </div>
                    <span className="subInfoText">
                      {'Sent'} {dayjs(sentCourseTime).fromNow()}
                    </span>
                  </div>
                )}
              </div>
              <div className="menuItemActionButton">
                <CoursesList courses={courses} menuItem={item} />

                <ActionButton
                  item={item}
                  setDropdownRef={setDropdownRef}
                  setOpenItemActionDropdown={setOpenItemActionDropdown}
                  openItemActionDropdown={openItemActionDropdown}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default MenuItemList;
