import { useSelector } from 'react-redux';
import { PosIconChatBubbles } from '../../assets/icons/PosIconChatBubbles';
import { PosIconCheckmarkCircle } from '../../assets/icons/PosIconCheckmarkCircle';
import { PosIconClock } from '../../assets/icons/PosIconClock';
import { PosIconReceiveMoney } from '../../assets/icons/PosIconReceiveMoney';
import { PosIconSendMoney } from '../../assets/icons/PosIconSendMoney';
import Button from '../Button';
import { DriversMapIframe } from './DriversMapIframe';
import { ScrollableBoxUI } from '../../UI/components';

const DriverInfo = ({
  currentDriver,
  handleOpenModal,
  cls,
  status,
  handleCashFloatClick,
  handleDriverClockOut,
}) => {
  const fullScreen = useSelector((state) => state.dashboardReducer.fullScreen);
  const mapWidth = fullScreen ? window.innerWidth / 2 + 4 : 367;
  const mapHeight = 560;
  const currentDriverActions = [
    {
      id: 1,
      title: 'Cash Float',
      icon: <PosIconReceiveMoney mainColor="#494b4c" />,
      onClick: handleCashFloatClick,
    },
    {
      id: 3,
      title: 'Clockout',
      icon: <PosIconClock mainColor="#494b4c" />,
      onClick: handleDriverClockOut,
    },
    {
      id: 5,
      title: 'Send SMS',
      icon: <PosIconChatBubbles mainColor="#494b4c" />,
      actionType: '',
    },
  ];
  return (
    <div className="driver-inner-box">
      <div className="current-driver-map-box">
        <button
          type="button"
          title="Open Modal"
          className="map-full-btn hover-highlight"
          onClick={handleOpenModal}
        />
        <DriversMapIframe
          iframeWidth={mapWidth}
          iframeHeight={mapHeight}
          driver={currentDriver}
          id="map"
        />
      </div>

      <div className="current-driver-details-wrap">
        <div className={cls}>
          <div className="cell">
            <h4 className="title">{status}</h4>
          </div>
          <div className="cell">
            {
              // 1 - Waiting in Store, 2 -  Returning to Store, 3 - On Delivery
              currentDriver.status === 0 ? (
                ''
              ) : currentDriver.status === 2 ? (
                <>
                  <PosIconClock mainColor="#ffffff" />
                  <span className="time">{currentDriver.arrivingToStoreTime}</span>
                </>
              ) : currentDriver.status === 1 && currentDriver.activeOrders?.length ? (
                <>
                  <PosIconCheckmarkCircle mainColor="#ffffff" />
                  <span className="time">
                    {currentDriver.activeDeliveredOrders?.length}/
                    {currentDriver.activeOrders?.length}
                  </span>
                </>
              ) : (
                ''
              )
            }
          </div>
        </div>
        <div className="current-driver-details-box">
          <ScrollableBoxUI
          // actionType="scrollDriverActionsBox"
          >
            <ul className="sidebar-action-list current-driver-actions">
              {currentDriverActions.map((action, i) => {
                return (
                  <li key={action.id}>
                    <Button
                      type="button"
                      className="single-sidebar-action"
                      title={action.title}
                      onClick={action.onClick}
                    >
                      <span className="inner-box">
                        {action.id === 1 ? (
                          <h3 className="sidebar-action-title">
                            <span>{currentDriver.cashBalance?.cashFloat}</span> {action.title}
                          </h3>
                        ) : (
                          <h3 className="sidebar-action-title">{action.title}</h3>
                        )}
                      </span>
                      <span className="icon-box">
                        {action.id === 1 && currentDriver.cashBalance?.cashFloat === 'End' ? (
                          <PosIconSendMoney mainColor="#494b4c" />
                        ) : (
                          <>{action.icon}</>
                        )}
                      </span>
                    </Button>
                  </li>
                );
              })}
            </ul>
          </ScrollableBoxUI>
        </div>
      </div>
    </div>
  );
};

export default DriverInfo;
