import moment from 'moment';
import { tConvert } from './getFormattedTime';
import dayjs from 'dayjs';
import { consoleSandbox } from '@sentry/utils';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export const dayArray = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const monthArr = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getEstimatedTime = (deliveryDateTimestamp) => {
  const estimatedTimeInMins = dayjs(deliveryDateTimestamp).diff(
    new Date(),
    'm',
    true
  );
  const estimatedTime = dayjs(deliveryDateTimestamp).format('h:mm A');
  let displayTime = '';
  let weeks = 0;
  let days = 0;
  let hours = 0;
  let mins = 0;

  if (estimatedTimeInMins >= 60) {
    weeks = Math.floor(estimatedTimeInMins / 10080);

    days = Math.floor((estimatedTimeInMins % 10080) / 1440);
    hours = Math.floor((estimatedTimeInMins % 1440) / 60);
    mins = Math.floor((estimatedTimeInMins % 1440) % 60);

    if (weeks > 0) {
      displayTime = displayTime + `${weeks} week${weeks > 1 ? 's' : ''} `;
    }
    if (days > 0) {
      displayTime = displayTime + `${days} day${days > 1 ? 's' : ''} `;
    }
    if (hours > 0) {
      displayTime = displayTime + `${hours} hour${hours > 1 ? 's' : ''} `;
    }
    if (mins > 0) {
      displayTime = displayTime + `${mins} min${mins > 1 ? 's' : ''} `;
    }
  } else {
    displayTime =
      displayTime +
      `${estimatedTimeInMins.toFixed(0)} min${
        estimatedTimeInMins > 1 ? 's' : ''
      } `;
  }

  return {
    estimatedTimeInMins: estimatedTimeInMins <= 0 ? 0 : displayTime,
    estimatedTime,
  };
};

export const getRemainingTime = (deliveryDate, deliveryDateTimestamp) => {
  const deliveryDatefromTimeStamp = new Date(deliveryDateTimestamp);
  const currentDate = new Date();
  const currentTimeStamp = currentDate.getTime();
  const isDateBeforeCurrentDay = deliveryDateTimestamp - currentTimeStamp < 0;
  const deliveryDay = deliveryDatefromTimeStamp.getDay();
  const currentDay = currentDate.getDay();
  const month = monthArr[deliveryDatefromTimeStamp.getMonth()];
  const date = deliveryDatefromTimeStamp.getDate();
  let displayDay = '';
  if (deliveryDay == currentDay) {
    displayDay = 'Today';
  } else if (
    (deliveryDay - currentDay == 1 || currentDay - deliveryDay == 6) &&
    !isDateBeforeCurrentDay
  ) {
    displayDay = 'Tomorrow';
  } else if (
    (deliveryDay - currentDay > 1 && deliveryDay - currentDay > 6) ||
    isDateBeforeCurrentDay
  ) {
    displayDay = `${date} ${month}`;
  } else {
    displayDay = dayArray[deliveryDay];
  }

  const dateArr = deliveryDate.split(' ');
  const time = dateArr[1];
  const displayTime = tConvert(time);
  return `${displayDay} ${displayTime}`;
};

export const getDayNameOrDateAndTime = (date, showTime = true) => {
  if (!date) return;

  // get from now for this date
  let fromNow = moment(date).fromNow();

  // change A month ago with 1 month ago
  const fromNowFormatted =
    fromNow && fromNow[0] === 'a' ? fromNow.replace('a', 1) : fromNow;

  // ensure the date is displayed with today and yesterday
  const displayDay = moment(date).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',

    // when the date is further away, use from now functionality
    sameElse: () => {
      return '[' + fromNowFormatted + ']';
    },
  });

  let displayDate = '';
  let displayTime = '';

  const dateArr = date.split(' ');
  displayDate = moment(dateArr[0]).format('MMMM Do');

  const time = dateArr[1];
  displayTime = tConvert(time);

  if (!displayDay.includes('month')) {
    if (displayDay.includes('Yesterday') || displayDay.includes('Today')) {
      return showTime ? `${displayDay} - ${displayTime}` : displayDay;
    } else {
      return showTime ? `${displayDate} - ${displayTime}` : displayDate;
    }
  } else {
    return `${displayDay}, ${displayDate}`;
  }
};
