import React, { createRef, useEffect, useRef } from 'react';
import { currencyFormat } from '../../../util/currencyFormat';
import { isWindows, isMacOs } from 'react-device-detect';
import classNames from 'classnames';
import { ScrollableBoxUI } from '../../../UI/components';

export const MoneyCountContent = ({ moneyCount, activeIndex, handleFocus, handleChange }) => {
  let DenominationRefs = useRef([]);

  DenominationRefs.current = moneyCount.map(
    (ref, index) => (DenominationRefs.current[index] = createRef())
  );

  useEffect(() => {
    if (
      DenominationRefs.current[activeIndex] !== null &&
      DenominationRefs.current[activeIndex].current !== null
    ) {
      DenominationRefs.current[activeIndex].current.focus();
    }
  }, [activeIndex]);

  return (
    <div className="money-count-main">
      <table className="denomination-table" id="denomination-table-header">
        <thead>
          <tr>
            <th className="denomination-denomination">Denomination</th>
            <th className="denomination-quantity">Quantity</th>
            <th className="denomination-total">Total</th>
          </tr>
        </thead>
      </table>
      <div className="denomination-table-wrap">
        <ScrollableBoxUI>
          <table className="denomination-table" id="denomination-table-body">
            <tbody>
              {moneyCount &&
                moneyCount.length > 0 &&
                moneyCount.map((item, i) => {
                  const inputCls = classNames({
                    'input-style': true,
                    white: true,
                    active: item.active,
                  });
                  return (
                    <tr
                      key={item.id}
                      onClick={isWindows || isMacOs ? (e) => handleFocus(e, item.id) : undefined}
                    >
                      <td className="denomination-denomination">{item.denominationLabel}</td>
                      <td className="denomination-quantity">
                        <div className="denomination-quantity-box">
                          <input
                            ref={DenominationRefs.current[i]}
                            type="number"
                            min={0}
                            step={1}
                            name={`denomination-quantity-${item.id}`}
                            className={inputCls}
                            onChange={(e) => handleChange(e, item.id)}
                            onFocus={
                              isWindows || isMacOs ? undefined : (e) => handleFocus(e, item.id)
                            }
                            value={item.quantity ? item.quantity : ''}
                            disabled={!(isWindows || isMacOs)}
                          />
                        </div>
                      </td>
                      <td className="denomination-total">
                        {item.total ? currencyFormat(item.total) : ''}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </ScrollableBoxUI>
      </div>
    </div>
  );
};
