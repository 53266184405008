import React from 'react';

import { getShifts } from '../../services/shiftsServices';
import { getDayDateMonthTime } from '../../util/dateUtils';
import { parsedShiftsDataForTable } from '../../util/shifts-screen-utils';
import { useShiftsState, useStaffMemberName } from './Context/ShiftsState';
import ShiftsBlockContainer from './Containers/ShiftsBlockContainer';
import ShiftsPageContainer from './Containers/ShiftsPageContainer';
import ShiftsTable from './ShiftsTable';

const ViewShiftsScreen = () => {
  const staffMember = useShiftsState();
  const { _id: staffMemberId } = staffMember;

  const staffMemberName = useStaffMemberName();

  const [shiftsToDisplay, setShiftsToDisplay] = React.useState([]);

  const getAllShiftsForTheStaff = async () => {
    const { data } = await getShifts(staffMemberId);
    const { shifts = [] } = data;
    const parsedShiftsData = parsedShiftsDataForTable(shifts, staffMember);
    setShiftsToDisplay(parsedShiftsData, staffMember);
  };

  React.useEffect(() => {
    getAllShiftsForTheStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShiftsPageContainer>
      <ShiftsBlockContainer isOuterLarger={true}>
        <div className="clock-in-out__header-group">
          <h2 className="clock-in-out__title">{staffMemberName}</h2>
          <p className="clock-in-out__desc">{getDayDateMonthTime()}</p>
        </div>
        <div className="clock-in-out__table-group">
          <ShiftsTable shifts={shiftsToDisplay} />
        </div>
      </ShiftsBlockContainer>
    </ShiftsPageContainer>
  );
};

export default ViewShiftsScreen;
