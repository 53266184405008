import React, { useMemo } from 'react';
import { ScrollableBoxUI } from 'UI/components';
import { DeliveryBatchesSidebarFooter } from './DeliveryBatchesSidebarFooter';
import { useSelector, useDispatch } from 'react-redux';
import { UnassignedBatchedOrdersList } from './content/UnassignedBatchedOrders';
import { unAssignedOrderListSelector } from 'pages/Drivers/selectors';
import { createDeliveryBatch } from 'store/batchOrders/actions';

export default function DeliveryBatchesUnassigned() {
  const dispatch = useDispatch();

  const { loading, batchOrders } = useSelector(
    (state) => state.batchOrdersReducer
  );
  const unAssignedOrders = useSelector(unAssignedOrderListSelector);

  const { selectedUnAssignedOrdersForDeliveryBatches } = useSelector(
    (state) => state.driversReducer
  );
  const unAssignedOrdersList = useMemo(() => {
    const batchedOrders = batchOrders
      .filter((batch) => !batch.isDeleted)
      .map((batch) => batch.orders ?? [])
      .flat();

    return (unAssignedOrders ?? [])
      .filter(
        (order) =>
          !!order.address &&
          !batchedOrders.some((batchOrder) => batchOrder.id === order._id)
      )
      .sort((a, b) => a.deliveryDateTimestamp - b.deliveryDateTimestamp);
  }, [unAssignedOrders, batchOrders]);

  const onCreateBatch = () => {
    const batchOrdersList = selectedUnAssignedOrdersForDeliveryBatches.map(
      (order, index) => ({
        id: order._id,
        index,
        address: order.address,
        deliveryDateTimestamp: order.deliveryDateTimestamp,
        deliveryDate: order.deliveryDate,
        firstName: order.firstName,
        lastName: order.lastName,
        deliveryCode: order.deliveryCode,
        addressLocation: order.addressLocation,
      })
    );
    dispatch(createDeliveryBatch(batchOrdersList));
  };

  const isDisabled =
    selectedUnAssignedOrdersForDeliveryBatches.length === 0 || loading;

  return (
    <>
      <div
        className="nav-bar-details"
        style={{
          margin: 0,
          padding: 0,
          height: 'calc(100% - 115px)',
        }}
      >
        <ScrollableBoxUI>
          <div className="details-tab-wrap unassigned">
            <UnassignedBatchedOrdersList
              unAssignedOrders={unAssignedOrdersList}
            />
          </div>
        </ScrollableBoxUI>
      </div>

      <DeliveryBatchesSidebarFooter
        loading={loading}
        disabled={isDisabled}
        onClick={onCreateBatch}
        buttonText="Create Batch"
        totalSelected={selectedUnAssignedOrdersForDeliveryBatches.length}
      />
    </>
  );
}
