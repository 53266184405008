import GeneralModal from 'modals/GeneralModal/index-new';
import { PosIcon } from '../../assets/icons/PosIcon';

export default function AccessDeniedModal({ handleModalClose, message }) {
  const handleDismiss = () => {
    handleModalClose(false);
  };

  return (
    <GeneralModal
      modalStatus={true}
      position="center"
      effect="move"
      overlay=""
      boxWidth="487px"
      className="general-modal general-modal--theme-action grey-bg radius-big"
    >
      <div className="general-modal__inner general-modal__inner--space-between-biggest">
        <div className="general-modal__header general-modal__header--space-between-biggest">
          <div className="general-modal__title-box">
            <PosIcon
              name="warning-solid"
              size="custom"
              customWith={36}
              customHeight={36}
              mainColor="#ff3008"
              darkModeColor="#ff3008"
            />
            <h3 className="general-modal__title">Access Denied</h3>
          </div>
          <div className="general-modal__desc">
            <p>{message}</p>
          </div>
        </div>

        <div className="general-modal__footer">
          <div className="general-modal__actions-group">
            <button
              className="general-modal__action general-modal__action--theme-blue"
              title="Dismiss"
              type="button"
              onClick={handleDismiss}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </GeneralModal>
  );
}
