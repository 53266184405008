import { currencyFormat } from '../../util/currencyFormat';

const PaymentAmountOption = ({
  currency,
  handlePaymentOptionClick,
  cashOption,
  isPlacingOrder,
  disableButtonStyle,
}) => {
  return (
    <button
      type="button"
      className="cash-card"
      onClick={(event) =>
        handlePaymentOptionClick({ event, cashAmount: cashOption })
      }
      disabled={isPlacingOrder}
      style={disableButtonStyle}
    >
      <span className="price">{currencyFormat(cashOption, currency)}</span>
    </button>
  );
};

export default PaymentAmountOption;
