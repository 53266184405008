import { useEffect } from 'react';
import { SettleContent } from '../../components/Settle/SettleContent';
import { SettleSidebar } from '../../components/Settle/SettleSidebar';
// import '../assets/scss/settle/_settle-main.scss';
// import PaymentViewModal from '../../components/PaymentViewModal';
// import SideBarModalPrintBill from '../components/modal/SideBarModalPrintBill';
// import SideBarModalPrintSplitBill from '../components/modal/SideBarModalPrintSplitBill';
// import DeliveryOrderModal from '../components/modal/DeliveryOrderModal';
// import ItemActionsModal from '../components/modal/ItemActionsModal';
// import { PaymentModal } from '../components/modal/PaymentModal';
import { useDispatch, useSelector } from 'react-redux';
import { ViewOrdersHeader } from '../../components/Orders/ViewOrdersHeader';
import { storeConfigSelector } from '../../pages/Dashboard/selectors';
import { fetchUnsettledOrderList } from '../Orders/actions';
import ModalsWrapper from './ModalsWrapper';

export const Settle = () => {
  const storeConfig = useSelector(storeConfigSelector);
  const dispatch = useDispatch();
  const openOrderList = useSelector((state) => state.ordersReducer.orderList);
  // const { selectedOrder } = useSelector((state) => state.ordersReducer);
  // const [modalStatus, setModalStatus] = useState(false);
  // const [modalPaymentStatus, setModalPaymentStatus] = useState(false);

  useEffect(() => {
    dispatch(
      fetchUnsettledOrderList(storeConfig?.storeId, ['0', '1', '2', '3'])
    );
  }, [storeConfig, dispatch]);

  // const handleToggleModal = (e, status) => {
  //   setModalStatus(status);
  // };

  const handleTogglePaymentModal = (e, status) => {
    // setModalPaymentStatus(status);
  };

  return (
    <>
      <ViewOrdersHeader isSettlePage={true} />
      <div className="settle-main-wrap">
        <SettleSidebar openOrdersList={openOrderList} />
        <SettleContent togglePaymentModal={handleTogglePaymentModal} />
      </div>
      <ModalsWrapper />

      {/* <ActionsModal
        currentGuest={currentGuest}
        modalStatus={modalStatus}
        toggleModal={handleToggleModal}
      /> */}
      {/*<PaymentModal
        type={currentGuest.deliveryId ? 'delivery' : 'pickup'}
        toggleModal={handleTogglePaymentModal}
        modalStatus={modalPaymentStatus}
      />
      <SideBarModalPrintBill additionalClass="view-orders" />
      <SideBarModalPrintSplitBill additionalClass="view-orders" />
      <DeliveryOrderModal currentGuest={currentGuest} />
      <ItemActionsModal />*/}
    </>
  );
};
