import { API_URL } from '../../../configuration/apiUrl';
import { _auth } from '../../../firebase';
import axiosWithInterceptor from '../../../util/interceptor';
export const getVoidedReasons = async (accessToken) => {
  let voidedReasons = [];
  // const accessToken = await _auth.currentUser.getIdToken(true);

  await axiosWithInterceptor
    .get(`${API_URL}/orders/v1/order/menuItemVoidReasons`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (response?.data?.code === 200) {
        voidedReasons = response.data.data.reasons;
      }
    });

  return voidedReasons;
};
