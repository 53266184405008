export default function cashierReducer(state, action) {
  switch (action.type) {
    case 'setIsShowStaffPinModal':
      return { ...state, isShowStaffPinModal: action.payload };
    case 'setShowAccessDenied':
      return { ...state, accessDenied: action.payload };
    case 'setErrorMessage':
      return { ...state, errorMessage: action.payload };
    case 'setIsVerifyingPin':
      return { ...state, isVerifyingPin: action.payload };
    case 'setWiggleEffect':
      return { ...state, wiggleEffect: action.payload };
    case 'setGoToRouteAfterAuth':
      return { ...state, goToRouteAfterAuth: action.payload };
    case 'setCurrentItem':
      return { ...state, currentItem: action.payload };
    case 'setUnpaidOrders':
      return { ...state, unpaidOrders: action.payload };
    case 'setRunFetchOrders':
      return { ...state, runFetchOrders: action.payload };
    case 'setDrawerIsOpening':
      return { ...state, drawerIsOpening: action.payload };
    default:
      throw new Error();
  }
}
