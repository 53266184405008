import { useEffect, useState } from 'react';

export const useSelectedIngredients = (currentItem) => {
  const [selectedStatus, setSelectedStatus] = useState(false);

  useEffect(() => {
    let hasRemove = false;
    let hasAdd = false;
    const { isCurrentMenuItemInEditMode } = currentItem;

    if (
      currentItem.selectedIngredients !== null &&
      currentItem.selectedIngredients !== undefined &&
      currentItem.selectedIngredients.length > 0
    ) {
      currentItem.selectedIngredients.forEach((item) => {
        if (item.isRemoved) {
          hasRemove = true;
        }
      });
    }

    if (
      currentItem.selectedExtraIngredients !== null &&
      currentItem.selectedExtraIngredients !== undefined &&
      currentItem.selectedExtraIngredients.length > 0
    ) {
      currentItem.selectedExtraIngredients.forEach((item) => {
        if (item.quantity) {
          hasAdd = true;
        }
      });
    }

    setSelectedStatus(hasAdd || hasRemove || isCurrentMenuItemInEditMode);
  }, [currentItem]);

  return selectedStatus;
};
