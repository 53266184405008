import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import RefundSelectItem from '../RefundReasonModal/RefundSelectItem';
import RefundSelectSubItem from './RefundSelectSubItem';
import GeneralModal from '../GeneralModal/index-new';
import RefundContext from '../../context/RefundContext';
import { PosIcon } from '../../assets/icons/PosIcon';
import { useOrdersGlobalState } from '../../pages/Orders/components/Providers/OrdersGlobalState/OrdersGlobalState';

const capitalize = (s) => `${s.substr(0, 1).toUpperCase()}${s.substr(1)}`;

const RefundAffectedItemsModal = ({
  showModal,
  onModalClose,
  refundType,
  stepModalProps,
}) => {
  const [state, setState] = useContext(RefundContext);
  const { selectedOrder } = useOrdersGlobalState();
  const storeConfig = useSelector(
    (state) => state.dashboardReducer.storeConfig
  );

  const onConfirm = () => {
    setState({
      step: 'details',
    });
    stepModalProps.setActiveStep(stepModalProps.activeStep + 1);
  };

  useEffect(() => {
    if (state.manualAmount) {
      onConfirm();
    }
  }, [state.manualAmount]);

  useEffect(() => {
    setState({
      selectedItems: [],
      selectedItemsDetails: [],
      selectedExtras: {},
      refundAmount: 0,
      refundExtrasAmount: 0,
      includeDelivery: true,
      includeCreditCardCharges: true,
    });
  }, []);

  useEffect(() => {
    if (selectedOrder?.menuItems && state.selectedItems) {
      const { selectedItems, selectedExtras, selectedItemsDetails } = state;
      // const refundAmount = selectedItems.reduce((acc, _id) => {
      //   const item = selectedOrder.menuItems.find((i) => i._id === _id);
      //   const price = item.payablePrice || item.selectedSizes[0].price;
      //   return (acc += parseFloat(price, 10));
      // }, 0);

      const refundAmount = selectedItemsDetails.reduce((acc, item) => {
        const price = item.payablePrice || item.selectedSizes[0].price;
        return (acc += parseFloat(price, 10));
      }, 0);

      const refundExtrasAmount = Object.keys(selectedExtras).reduce(
        (acc, _id) =>
          (acc += parseFloat(
            (
              selectedOrder.menuItems.find((i) => i._id === _id)
                ?.selectedExtraIngredients || []
            )
              .filter((ing) => selectedExtras[_id].has(ing._id))
              .reduce((acc2, ing) => (acc2 += ing.price), 0),
            10
          )),
        0
      );

      let total = refundAmount + refundExtrasAmount;
      const allSelected = total >= parseFloat(selectedOrder.totalCost);

      if (allSelected) total = parseFloat(selectedOrder.payableAmount);

      if (state.includeDelivery)
        total += parseFloat(selectedOrder?.deliveryCost);

      if (state.includeCreditCardCharges)
        total += parseFloat(selectedOrder?.creditCardCharges);

      setState({ refundAmount: total, allSelected });
    } else if (selectedOrder) {
      setState({ refundAmount: selectedOrder.payableAmount });
    }
  }, [
    state.selectedItems,
    state.selectedExtras,
    state.includeDelivery,
    state.includeCreditCardCharges,
    selectedOrder?.menuItems,
  ]);

  return (
    <div className="general-modal__inner">
      <div className="general-modal__header general-modal__header--shadow ">
        <div className="general-modal__title-box general-modal__title-box--space-between">
          <h3 className="general-modal__title general-modal__title--size-small">
            Select affected items
          </h3>
          <button
            className="button button--height-29"
            type="button"
            title="Select All"
            onClick={() => {
              const { menuItems } = selectedOrder;
              const itemIds = menuItems.map(({ _id }) => _id);
              if (state.allSelected && !state.manualAmount) {
                setState({
                  allSelected: false,
                  selectedItems: [],
                  selectedExtras: Object.keys(itemIds).reduce(
                    (acc, id) => ({ ...acc, [id]: new Set() }),
                    {}
                  ),
                });
              } else {
                const selectedItems = itemIds;
                const selectedExtras = itemIds.reduce(
                  (acc, id) => ({
                    ...acc,
                    [id]: new Set([
                      ...(menuItems
                        .find(({ _id }) => id === _id)
                        ?.selectedExtraIngredients?.map(({ _id }) => _id) ||
                        []),
                    ]),
                  }),
                  {}
                );

                setState({
                  manualAmount: false,
                  allSelected: true,
                  selectedItems,
                  selectedExtras,
                });
              }
            }}
          >
            {!state.manualAmount && state.allSelected ? 'Des' : 'S'}elect All
          </button>
        </div>
        <div className="general-modal__desc general-modal__desc--size-small general-modal__desc--align-left">
          <p>
            Customer will{' '}
            {refundType === 'voucher' ? 'get voucher for' : 'be refunded'} the
            selected items
          </p>
        </div>
      </div>

      <div className="general-modal__body">
        <div className="general-modal__body-inner">
          <div className="refund-select-list">
            {selectedOrder?.menuItems.map(
              (
                {
                  name,
                  _id,
                  orderIndex,
                  payablePrice,
                  quantity,
                  selectedSizes,
                  selectedExtraIngredients,
                },
                index
              ) => (
                <React.Fragment key={_id + `${index}`}>
                  <RefundSelectItem
                    label={quantity > 1 ? `${name} x ${quantity}` : name}
                    value={_id}
                    price={`${storeConfig.currency}${
                      payablePrice
                        ? payablePrice.toFixed(2)
                        : selectedSizes[0].price
                    }`}
                    checked={
                      !state.manualAmount &&
                      state.selectedItems?.includes(_id) &&
                      state.selectedItemsDetails?.some(
                        (item) =>
                          item._id === _id && item.orderIndex === orderIndex
                      ) &&
                      (!selectedExtraIngredients.length ||
                        state.selectedExtras[_id]?.size ===
                          selectedExtraIngredients?.length)
                    }
                    onChange={() => {
                      let selectedItems = [];
                      let selectedItemsDetails = [];

                      if (
                        state.selectedItems.includes(_id) &&
                        state.selectedItemsDetails?.some(
                          (item) =>
                            item._id === _id && item.orderIndex === orderIndex
                        )
                      ) {
                        selectedItemsDetails =
                          state.selectedItemsDetails.filter(
                            (item) => item.orderIndex !== orderIndex
                          );
                        selectedItems = selectedItemsDetails.map(
                          (item) => item._id
                        );
                      } else {
                        selectedItems = [...state.selectedItems, _id];
                        selectedItemsDetails = [
                          ...state.selectedItemsDetails,
                          {
                            name,
                            _id,
                            orderIndex,
                            payablePrice,
                            selectedSizes,
                          },
                        ];
                      }

                      setState({
                        allSelected: false,
                        manualAmount: false,
                        selectedItems,
                        selectedItemsDetails,
                        selectedExtras: {
                          ...state.selectedExtras,
                          [_id]: selectedItems.includes(_id)
                            ? new Set(
                                (selectedExtraIngredients || []).map(
                                  ({ _id }) => _id
                                )
                              )
                            : new Set(),
                        },
                      });
                    }}
                  >
                    {selectedExtraIngredients?.length > 0 && (
                      <div className="refund-select-list__subgroup">
                        {selectedExtraIngredients.map(
                          ({ name, _id: __id, price }) => {
                            return (
                              <React.Fragment key={__id}>
                                <RefundSelectSubItem
                                  label={name}
                                  value={__id}
                                  checked={
                                    !state.manualAmount &&
                                    (state.allSelected ||
                                      !!(
                                        state?.selectedExtras &&
                                        state.selectedExtras[_id] &&
                                        state.selectedExtras[_id]?.has(__id)
                                      ))
                                  }
                                  price={`${storeConfig.currency}${Number(
                                    price
                                  ).toFixed(2)}`}
                                  onChange={() => {
                                    const _selectedExtras = new Set(
                                      state.selectedExtras[_id] || []
                                    );

                                    setState({
                                      allSelected: false,
                                      manualAmount: false,
                                      selectedExtras: {
                                        ...state.selectedExtras,
                                        [_id]: _selectedExtras.delete(__id)
                                          ? _selectedExtras
                                          : _selectedExtras.add(__id),
                                      },
                                    });
                                  }}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    )}
                  </RefundSelectItem>
                </React.Fragment>
              )
            )}
          </div>
        </div>
      </div>
      <div className="general-modal__footer general-modal__footer--shadow ">
        <div className={'actionButtons'}>
          <button
            onClick={() => setState({ step: 'amount' })}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div className="general-modal__divide-cell">
              <span className="general-modal__divide-text general-modal__divide-text--theme-blue">
                {!state.manualAmount && 'Enter'} <b>Manual Amount</b>
              </span>
            </div>
          </button>
          {Number(selectedOrder?.creditCardCharges || 0) > 0 && (
            <button
              disabled={
                !selectedOrder?.creditCardCharges ||
                selectedOrder?.creditCardCharges === '0'
              }
              onClick={() =>
                setState({
                  includeCreditCardCharges: !state.includeCreditCardCharges,
                })
              }
            >
              <div className="general-modal__divide-cell">
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    border: `2px solid ${
                      state.includeCreditCardCharges ? '#5eb602' : '#999'
                    }`,
                    background: state.includeCreditCardCharges
                      ? '#5eb602'
                      : 'white',
                  }}
                >
                  <PosIcon
                    style={{ border: '2px solid #888' }}
                    name="check-circle-solid"
                    size="custom"
                    customWith={27}
                    customHeight={27}
                    mainColor={
                      state.includeCreditCardCharges ? '#5eb602' : 'white'
                    }
                    darkModeColor="#5eb602"
                  />
                </div>
                <span className="general-modal__divide-text">
                  Credit Card Charges{' '}
                  {`${storeConfig.currency}${selectedOrder?.creditCardCharges}`}
                </span>
              </div>
            </button>
          )}

          {selectedOrder.orderType === '2' && (
            <button
              disabled={
                !selectedOrder?.deliveryCost ||
                selectedOrder?.deliveryCost === '0'
              }
              onClick={() =>
                setState({
                  includeDelivery: !state.includeDelivery,
                })
              }
            >
              <div className="general-modal__divide-cell">
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    border: `2px solid ${
                      state.includeDelivery ? '#5eb602' : '#999'
                    }`,
                    background: state.includeDelivery ? '#5eb602' : 'white',
                  }}
                >
                  <PosIcon
                    style={{ border: '2px solid #888' }}
                    name="check-circle-solid"
                    size="custom"
                    customWith={27}
                    customHeight={27}
                    mainColor={state.includeDelivery ? '#5eb602' : 'white'}
                    darkModeColor="#5eb602"
                  />
                </div>
                <span className="general-modal__divide-text">
                  Delivery{' '}
                  {selectedOrder?.deliveryCost === '0'
                    ? 'Free'
                    : `Fee ${storeConfig.currency}${selectedOrder?.deliveryCost}`}
                </span>
              </div>
            </button>
          )}
        </div>

        <button
          onClick={onConfirm}
          disabled={!state.refundAmount || state.refundAmount === 0}
          className="button button--rounded-none button--align-center button--size-biggest button--fullwidth button--theme-green"
          title="Confirm Refund $45.30"
          type="button"
        >
          Confirm {capitalize(refundType)} {storeConfig.currency}
          {state?.refundAmount &&
            state.refundAmount.toFixed &&
            state.refundAmount.toFixed(2)}
        </button>
      </div>
    </div>
  );
};

export default RefundAffectedItemsModal;
