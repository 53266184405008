import { _firestore } from '../../firebase';
import ActionTypes from './constants';

export function fetchDrawerList(storeId) {
  return (dispatch) => {
    _firestore
      .collection('CashDrawers')
      .where('storeId', '==', storeId)
      .where('isDeleted', '==', false)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setDrawers(dataArray));
      });
  };
}

export function fetchPrinterList(storeId) {
  return (dispatch) => {
    _firestore
      .collection('KitchenPrinters')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ ...doc.data(), _id: doc.id });
        });
        dispatch(setPrinters(dataArray));
      });
  };
}

function setDrawers(payload) {
  return {
    type: ActionTypes.getDrawers,
    payload,
  };
}

function setPrinters(payload) {
  return {
    type: ActionTypes.getPrinters,
    payload,
  };
}

export function setActiveDrawer(payload) {
  return {
    type: ActionTypes.setActiveDrawer,
    payload,
  };
}

export function setTerminalSettingsDisabled(payload) {
  return {
    type: ActionTypes.setTerminalSettingsLoaderStatus,
    payload,
  };
}
