import React, { useContext } from 'react';
import PayMaster from '../../../assets/img/pay_master.svg';
//import Context from '../../../Context';
import { SeatsList } from './SeatsList';
import { TotalPriceBox } from './TotalPriceBox';
import { OrderTimer } from './OrderTimer';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

export const ReadyTableView = ({ currentGuest }) => {
  //const { state, dispatch } = useContext(Context);
  const scrolledStatus = useSelector(
    (state) => state.ordersReducer.scrolledStatus
  );

  const shadowCls = classNames({ shadow: scrolledStatus[7].position });

  const handleClick = () => {
    let guest = currentGuest;

    guest.id = Date.now();
    guest.active = false;
    guest.textStatus.secondText = 'Expected in 26 mins';
    guest.status = 'completed';

    // dispatch({
    //   type: 'moveGuestToList',
    //   payload: {
    //     oldList: 'readyList',
    //     newList: 'historyList',
    //     guest: guest,
    //   },
    // });
  };

  return (
    <>
      <div className="guest-location-row">
        {currentGuest.table ? (
          <div className="location-box table">
            <span className="icon-box">
              <span className="dine_icon-table" />
            </span>
            <span className="desc">Table {currentGuest.table}</span>
          </div>
        ) : (
          ''
        )}
        {currentGuest.people ? (
          <div className="location-box people">
            <span className="icon-box">
              <span className="dine_icon-peoples" />
            </span>
            <span className="desc">
              {currentGuest.people > 1
                ? currentGuest.people + ' Guests'
                : currentGuest.people === 1
                ? '1 Guest'
                : ''}
            </span>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className={'order-status-row ' + shadowCls}>
        <div className="order-status-box">
          <span className="icon-box">
            <span className="dine_icon-rotate-switch" />
          </span>
          <span className="desc">
            {currentGuest.textStatus?.class ? (
              <span className={currentGuest.textStatus.class}>
                {currentGuest.textStatus.secondText}
              </span>
            ) : (
              currentGuest.textStatus?.secondText
            )}
            &nbsp;- 8:30pm
          </span>
        </div>
        <div className="order-status-box dropdown">
          <span className="icon-box">
            <span className="dine_icon-waiters" />
          </span>
          <span className="desc">
            <span className="grey">Assign Waiter</span>
          </span>
        </div>
      </div>

      <SeatsList />

      <div className="view-order-main-footer">
        <div className="current-order-status-box purple">
          <span className="icon-box">
            <span className="dine_icon-cook" />
          </span>
          <span className="desc">
            In Kitchen -{' '}
            <OrderTimer startMins={9} startSec={53} endMins={42} endSec={32} />
          </span>
          <button
            className="order-status-btn hover-highlight"
            type="button"
            title="Ready"
            onClick={handleClick}
          >
            <span className="inner-btn-box">Ready</span>
          </button>
        </div>
        <div className="order-total-paid-box">
          <div className="paycard-box master">
            <img src={PayMaster} alt="Master Card" />
          </div>
          <div className="price-wrap">
            <span className="price-desc">Total Spend</span>
            <TotalPriceBox />
          </div>
        </div>
      </div>
    </>
  );
};
