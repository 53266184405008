import React, { memo } from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconBurger = memo(({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-burger': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="14"
        viewBox="0 0 24 14"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M23 .5v.867H1V.5zM1 6.567h22v.856H1zm0 6.066h22v.867H1z"
            />
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness * 0.6 : 0.6}
              strokeMiterlimit="20"
              d="M23 .5v.867H1V.5zM1 6.567h22v.856H1zm0 6.066h22v.867H1z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
});

PosIconBurger.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
