import {
  getMenuItemForInstore,
  getMinifiedMenuItemForInstore,
} from 'pricingEngine';
import { createSelector } from 'reselect';
import { getHalfHalfVariants } from '../../util/half-and-half-utils';
import { isObjectEmpty } from '../../util/utils';
import {
  _getActiveObjects,
  _getDeActiveObjects,
  _getHalfHalfFilteredMenuItems,
} from './reducer';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const dashboardReducerData = (state) => state.dashboardReducer;

export const storeConfigSelector = createSelector(
  dashboardReducerData,
  (state) => state.storeConfig
);
export const allStoresSelector = createSelector(
  dashboardReducerData,
  (state) => state.allStores
);

export const storeIdSelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig?.storeId
);

export const storeNameSelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig.name
);

export const storeTimeZoneSelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig.timeZone
);

export const storeCitySelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig.city || ''
);

export const restaurantIdSelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig.restaurantId
);

export const storeLocationCoordinatesSelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig.addressLocation
);

export const storeCountryIdentifierSelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig.countryIdentifier?.toUpperCase() || ''
);

export const currencyCodeSelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig.currencyCode
);

export const currencySymbolSelector = createSelector(
  storeConfigSelector,
  (storeConfig) => storeConfig.currency
);

export const orderPaymentsSelector = createSelector(
  dashboardReducerData,
  (state) => state.orderPayments
);

export const nextOrderConfigURLSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => {
    const { nextOrderConfigURL, nextOrderConfigURLSydney } = dashboardReducer;
    return { nextOrderConfigURL, nextOrderConfigURLSydney };
  }
);

export const orderPaymentsTotalPaidSelector = createSelector(
  orderPaymentsSelector,
  (orderPayments) => {
    if (orderPayments?.length > 0) {
      return orderPayments.reduce(
        (alreadyPaidAmount, payment) =>
          alreadyPaidAmount + Number(payment.amount),
        0
      );
    }
    return 0;
  }
);

export const orderSetupSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.orderSetup
);

export const orderSetupClockInReminderSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.clockInReminder
);

export const orderSetupAllowNoSaleSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.allowNoSale
);

export const orderSetupCashDrawerReminderSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.cashDrawerReminder
);

export const orderSetupUnpaidOrdersReminderSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.unpaidOrdersReminder
);

export const useRadiusBasedDeliveryAreaCalulationSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.useRadiusBasedDeliveryAreaCalulation || false
);

export const currentOrderSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.currentOrder
);

export const orderTypeConfigSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.orderTypeConfig
);

export const basePathSelector = createSelector(
  orderTypeConfigSelector,
  (orderTypeConfig) => orderTypeConfig.basePath
);

export const orderTypeIdSelector = createSelector(
  orderTypeConfigSelector,
  (orderTypeConfig) => orderTypeConfig.orderTypeId
);

export const payableAmountSelector = createSelector(
  dashboardReducerData,
  (dashboard) => dashboard.currentOrder?.payableAmount
);

export const cashAmountChangeSelector = createSelector(
  dashboardReducerData,
  (dashboard) => dashboard.cashAmountChange
);

export const cashPaidSelector = createSelector(
  dashboardReducerData,
  (dashboard) => dashboard.cashPaid
);

export const productSetupSelector = createSelector(
  dashboardReducerData,
  (dashboard) => dashboard.productSetup
);

export const menuItemsSelector = createSelector(
  dashboardReducerData,
  (dashboard) => dashboard.menuItems
);

export const menuItemSizesSelector = createSelector(
  dashboardReducerData,
  (dashboard) => dashboard.menuItemSizes
);

export const menuItemModifiersSelector = createSelector(
  dashboardReducerData,
  (dashboard) => dashboard.menuItemModifiers
);

export const menuItemSubModifiersSelector = createSelector(
  dashboardReducerData,
  (dashboard) => dashboard.menuItemSubModifiers
);

export const menuItemsWithInStorePricesSelector = createSelector(
  [
    menuItemsSelector,
    menuItemSizesSelector,
    menuItemModifiersSelector,
    menuItemSubModifiersSelector,
    orderTypeIdSelector,
  ],
  (
    menuItems,
    menuItemSizes,
    menuItemModifiers,
    menuItemSubModifiers,
    orderTypeId
  ) => {
    const { fetchMinifiedMenuItemSizesAndSubModifiers } = useFlags();
    if (
      menuItems &&
      menuItemSizes &&
      menuItemModifiers &&
      menuItemSubModifiers
    ) {
      //fetch in-store prices for menu items
      let menuItemsWithPrice = [];
      menuItems?.forEach((menuItem) => {
        if (
          menuItem?.orderFromKeys?.includes('2') &&
          menuItem?.orderTypeKeys?.includes(orderTypeId) &&
          menuItem?.isPublished
        ) {
          menuItemsWithPrice.push(
            fetchMinifiedMenuItemSizesAndSubModifiers
              ? getMinifiedMenuItemForInstore(
                  menuItem,
                  menuItemSizes.filter((f) => f.menuItemId === menuItem._id),
                  menuItemModifiers.filter(
                    (f) =>
                      f.orderType === orderTypeId &&
                      f.menuItemId === menuItem._id
                  ),
                  menuItemSubModifiers.filter(
                    (f) => f.menuItemId === menuItem._id,
                    orderTypeId
                  )
                )
              : getMenuItemForInstore(
                  menuItem,
                  menuItemSizes.filter(
                    (f) =>
                      f.orderType === orderTypeId &&
                      f.menuItemId === menuItem._id
                  ),
                  menuItemModifiers.filter(
                    (f) =>
                      f.orderType === orderTypeId &&
                      f.menuItemId === menuItem._id
                  ),
                  menuItemSubModifiers.filter(
                    (f) =>
                      f.orderType === orderTypeId &&
                      f.menuItemId === menuItem._id
                  )
                )
          );
        }
      });
      return menuItemsWithPrice;
    }
    return menuItems;
  }
);

export const publicHolidaysSelector = createSelector(
  dashboardReducerData,
  (dashboardData) => dashboardData.publicHolidays
);

export const currentMenuItemSelector = createSelector(
  dashboardReducerData,
  (dashboardData) => dashboardData.currentMenuItem
);

export const currentHalfHalfDataSelector = createSelector(
  dashboardReducerData,
  (dashboardData) => {
    return dashboardData.currentHalfHalfData;
  }
);

export const currentFourQuarterDataSelector = createSelector(
  dashboardReducerData,
  (dashboardData) => {
    return dashboardData.currentFourQuarterData;
  }
);

export const currentSpecialsItemSelector = createSelector(
  dashboardReducerData,
  (dashboardData) => {
    return dashboardData.currentSpecialsItem;
  }
);

export const currentMenuItemNotesSelector = createSelector(
  currentMenuItemSelector,
  (currentMenuItem) => currentMenuItem?.itemNotes || ''
);

export const currentHalfHalfNotesSelector = createSelector(
  currentHalfHalfDataSelector,
  (currentHalfHalfData) => currentHalfHalfData?.itemNotes || ''
);

export const isCurrentMenuItemEmptySelector = createSelector(
  currentMenuItemSelector,
  (currentMenuItem) => isObjectEmpty(currentMenuItem)
);

export const isCurrentHalfHalfDataEmptySelector = createSelector(
  currentHalfHalfDataSelector,
  (currentHalfHalfData) => isObjectEmpty(currentHalfHalfData)
);

export const halfHalfsSelector = createSelector(
  productSetupSelector,
  (productSetup) => (productSetup?.halfHalfs ? productSetup?.halfHalfs[0] : {})
);

export const halfHalfsCategoriesIdSelector = createSelector(
  halfHalfsSelector,
  (halfHalfs) => {
    return halfHalfs?.categories.map((category) => category._id) || [];
  }
);

export const halfHalfMenuItemsSelector = createSelector(
  [menuItemsSelector, halfHalfsCategoriesIdSelector],
  (menuItems, halfHalfsCategoriesId) => {
    return menuItems?.filter((menuItem) =>
      halfHalfsCategoriesId?.includes(menuItem.categoryId)
    );
  }
);

export const halfHalfVariantsSelector = createSelector(
  halfHalfMenuItemsSelector,
  (halfHalfMenuItems) => getHalfHalfVariants(halfHalfMenuItems)
);

export const sizesToBeShownForHalfHalfSelector = createSelector(
  dashboardReducerData,
  (dashboardData) => {
    const originalHalfHalf = dashboardData.productSetup.halfHalfs
      ? dashboardData.productSetup.halfHalfs[0]
      : {};
    const activeSelectedSizes = _getActiveObjects(
      originalHalfHalf.selectedSizes
    );
    const activeSubModifiers = _getActiveObjects(
      originalHalfHalf.selectedModifiers
    );
    const deActiveMenuItems = _getDeActiveObjects(originalHalfHalf.menuItems);
    const originalMenuItems = dashboardData.menuItems?.filter(
      (item) => !deActiveMenuItems.some((mi) => mi._id === item._id)
    );

    const sizeAndModifierFilteredItemsStatus = {};
    activeSelectedSizes.forEach((size) => {
      const filteredItemsForThisSize = _getHalfHalfFilteredMenuItems(
        originalMenuItems,
        [{ ...size, isDefault: true }],
        activeSubModifiers.filter((size) => size.isDefault),
        ''
      );
      if (!sizeAndModifierFilteredItemsStatus[size.pairId]) {
        sizeAndModifierFilteredItemsStatus[size.pairId] =
          filteredItemsForThisSize.length > 0;
      }
    });
    activeSubModifiers.forEach((mod) => {
      const filteredItemsForThisModifier = _getHalfHalfFilteredMenuItems(
        originalMenuItems,
        activeSelectedSizes.filter((size) => size.isDefault),
        [{ ...mod, isDefault: true }],
        ''
      );
      if (!sizeAndModifierFilteredItemsStatus[mod.pairId]) {
        sizeAndModifierFilteredItemsStatus[mod.pairId] =
          filteredItemsForThisModifier.length > 0;
      }
    });
    return sizeAndModifierFilteredItemsStatus;
  }
);

export const isAuthDisabledForPOSSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.isAuthDisabledForPOS || false
);
export const payForDineinWhenOrderdingSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.payForDineinWhenOrderding || false
);
export const isPrintCustomerReceiptAutomaticallyOn = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.printCustomerReceiptAutomaticallyOnPOS || false
);
export const printCustomerReceiptAutomaticallyOnPOSSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.printCustomerReceiptAutomaticallyOnPOS || false
);

export const isMobileTerminalEnabledSelector = createSelector(
  orderSetupSelector,
  (orderSetup) => orderSetup.isMobileTerminalEnabled || false
);

export const openItemNotesModalSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.openItemNotesModal
);

export const refreshToUpdatePosVersionSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.refreshToUpdatePosVersion
);

export const pinPadsSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.pinPads
);

export const drawersSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.drawers
);

export const terminalSettingsSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.terminalSettings
);

export const selectedPinPadIdSelector = createSelector(
  terminalSettingsSelector,
  (terminalSettings) => terminalSettings?.pinPadId || '000-000-000'
);

export const selectedUserSelector = createSelector(
  terminalSettingsSelector,
  (terminalSettings) => terminalSettings?.user || 'None'
);

export const selectedDrawerIdSelector = createSelector(
  terminalSettingsSelector,
  (terminalSettings) => terminalSettings?.drawerId || '000-000-000'
);

export const selectedDrawerStatusIsActiveSelector = createSelector(
  [drawersSelector, terminalSettingsSelector],
  (drawers, terminalSettings) => {
    const drawer = drawers?.find(
      (drawer) => drawer._id === terminalSettings?.drawerId
    );
    return (drawer && drawer.status === '1') || false;
  }
);

export const selectedDrawerPrinterIdSelector = createSelector(
  [drawersSelector, selectedDrawerIdSelector],
  (drawers, selectedDrawerId) =>
    drawers?.find((drawer) => drawer._id === selectedDrawerId)?.printerId || ''
);

export const activeStaffMemberSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.activeStaffMember
);

export const activeStaffMemberIdSelector = createSelector(
  activeStaffMemberSelector,
  (activeStaffMember) => activeStaffMember?._id || ''
);

// to be removed
export const activeStaffMemberNameSelector = createSelector(
  activeStaffMemberSelector,
  (activeStaffMember) =>
    `${activeStaffMember.firstName} ${activeStaffMember.lastName}`
);

export const activeStaffMemberRolesSelector = createSelector(
  activeStaffMemberSelector,
  (activeStaffMember) => activeStaffMember.roles
);

export const activeStaffMemberOpenShiftsSelector = createSelector(
  activeStaffMemberSelector,
  (activeStaffMember) => activeStaffMember?.numOpenShifts
);

export const activeStaffMemberCurrentShiftSelector = createSelector(
  activeStaffMemberSelector,
  (activeStaffMember) =>
    activeStaffMember?.openShifts?.length
      ? activeStaffMember.openShifts[0]
      : null
);

export const activeStaffMemberIsOnABreakSelector = createSelector(
  activeStaffMemberCurrentShiftSelector,
  (activeStaffMember) => activeStaffMember?.onABreak
);

export const addTerminalSettingSelector = createSelector(
  [
    storeIdSelector,
    restaurantIdSelector,
    selectedDrawerIdSelector,
    selectedPinPadIdSelector,
    selectedUserSelector,
    storeTimeZoneSelector,
    activeStaffMemberIdSelector,
  ],
  (
    storeId,
    restaurantId,
    selectedDrawerId,
    selectedPinPad,
    selectedUser,
    storeTimeZone,
    staffMemberId
  ) => {
    const uuid = localStorage.getItem('uuidForTerminalSettings');
    return {
      uuid,
      storeId,
      restaurantId,
      drawerId: selectedDrawerId,
      pinPadId: selectedPinPad,
      user: selectedUser,
      ...(staffMemberId ? { staffId: staffMemberId } : null),
      timeZone: storeTimeZone,
    };
  }
);

export const specialsSelector = createSelector(
  dashboardReducerData,
  (state) => state.specials
);

export const activeSpecialsSelector = createSelector(
  [specialsSelector, orderTypeIdSelector],
  (specials, orderTypeId) =>
    specials?.filter(
      (special) =>
        special.orderFromKeys.includes('2') &&
        special.orderTypeKeys.includes(orderTypeId) &&
        special.isPublished
    )
);

export const suburbsSelector = createSelector(
  [dashboardReducerData],
  (dashboardReducer) => dashboardReducer.suburbs
);

export const splitBillDiscountModeSelector = createSelector(
  [dashboardReducerData],
  (dashboardReducer) => dashboardReducer.splitBillDiscountMode
);

export const paymentTypesSelector = createSelector(
  [dashboardReducerData],
  (state) => state.paymentTypes
);

export const paymentTypesForOrderTypeSelector = createSelector(
  [paymentTypesSelector, currentOrderSelector],
  (paymentTypes, currentOrder) =>
    paymentTypes?.filter((paymentType) =>
      paymentType?.orderTypeKeys?.includes(currentOrder.orderType)
    ) || []
);

export const splitOrderSessionIdSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.splitOrderSessionId
);

export const splitOrderIdSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.splitOrderId
);

export const splitOrderServiceIdSelector = createSelector(
  dashboardReducerData,
  (dashboardReducer) => dashboardReducer.splitOrderServiceId
);
