import React, { useState, useEffect } from 'react';

export const SearchBox = () => {
  const [searchValue, setSearchValue] = useState('');

  const onChange = (e) => {
    let searchTxt = e.target.value;
    if (typeof searchTxt === 'number') {
      searchTxt = searchTxt.toString();
    }
    setSearchValue(searchTxt);
  };

  return (
    <div className="nav-bar-search-box">
      <input
        type="text"
        className="input-style"
        name="search"
        value={searchValue}
        onChange={onChange}
        placeholder="Search"
      />
    </div>
  );
};
