import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import clsx from 'clsx';

import GeneralModal from '../../modals/GeneralModal/index-new';
import Button from '../Button';
import { PosIconLeftChevronSecond } from '../../assets/icons/PosIconLeftChevronSecond';
import { PosIconDot } from '../../assets/icons/PosIconDot';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';
import { PosIconCheckmarkCircle } from '../../assets/icons/PosIconCheckmarkCircle';
import { LoadingIcon } from 'nexticons/outline';
import useGetAmountWithCurrency from '../../customHooks/useGetAmountWithCurrency';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import { WarningIcon, XRoundedIcon, CheckIcon } from 'nexticons/solid';

const CashAmountModal = ({
  modalStatus,
  toggleModal,
  handleTenderClick,
  showAmountInTenderButton = false,
  isLoading = false,
  isResolved = false,
  isFailed = false,
  handleShouldShowButton,
  shouldBeEditable = true,
  paymentTypeName = 'Cash',
  customErrors = '',
  windcaveButtonOne,
  windcaveButtonTwo,
  handleWindcaveButton,
  windcaveTransactionRef,
  windcaveActionButtonPressed,
  isWindcaveProcessing,
}) => {
  const [total, setTotal] = useState('');
  const [showBtn, setShowBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const totalAmountWithCurrency = useGetAmountWithCurrency(total);

  useEffect(() => {
    const buttonStatus = handleShouldShowButton(total);
    const { error } = buttonStatus;
    if (error) {
      setShowBtn(false);
      setErrorMessage(error);
      return;
    }
    setErrorMessage('');
    setShowBtn(buttonStatus);
  }, [handleShouldShowButton, total]);

  useEffect(() => {
    setErrorMessage(customErrors);
  }, [customErrors]);

  const mainRef = useRef();

  useEffect(() => {
    modalStatus && mainRef.current.focus();
  }, [modalStatus]);

  const keyList = useRef([
    { id: 1, name: '1', val: '1' },
    { id: 2, name: '2', val: '2' },
    { id: 3, name: '3', val: '3' },
    { id: 4, name: '4', val: '4' },
    { id: 5, name: '5', val: '5' },
    { id: 6, name: '6', val: '6' },
    { id: 7, name: '7', val: '7' },
    { id: 8, name: '8', val: '8' },
    { id: 9, name: '9', val: '9' },
    {
      id: 10,
      name: <PosIconDot mainColor="515151" />,
      val: '.',
    },
    { id: 11, name: '0', val: '0' },
    {
      id: 13,
      name: <PosIconLeftChevronSecond mainColor="#515151" />,
      val: 'clear',
    },
  ]);

  const handleKeyDown = (e) => {
    if (!shouldBeEditable) return;
    if (e !== undefined && e.keyCode !== undefined) {
      let keyVal = '';
      const keyDotList = [190];
      const keyDelList = [8, 46];
      const keyNumList = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];

      if (keyDelList.includes(Number(e.keyCode || e.which))) {
        keyVal = 'clear';
      } else if (keyDotList.includes(Number(e.keyCode || e.which))) {
        keyVal = '.';
      } else if (keyNumList.includes(Number(e.keyCode || e.which))) {
        keyVal = e.key;
      }

      if (keyVal) {
        updateAmount(keyVal);
      }
    }
  };

  const updateAmount = (value) => {
    if (!shouldBeEditable) return;
    if (value === '.' && total.includes('.')) return;
    const currTotal = total.split('');
    if (value !== 'clear') {
      currTotal.push(value);
    } else {
      currTotal.pop();
    }
    setTotal(currTotal.join(''));
  };

  const handleTernderSubmit = () => {
    if (showBtn) {
      handleTenderClick(total);
    }
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="bottom"
      effect="move"
      overlay=""
      boxWidth="435px"
      boxHeight="688px"
      className="customer-phone-modal cash-amount"
      closeBtn="outside"
    >
      <div
        ref={mainRef}
        onKeyDown={handleKeyDown}
        tabIndex="0"
        className="customer-phone-modal-box"
      >
        <div className="customer-phone-header">
          <h2 className="customer-phone-title">
            Enter {paymentTypeName} Amount
          </h2>
          <div className="cash-amount-total grey">
            {totalAmountWithCurrency}
          </div>
          <div className="cash-amount-error-div">
            {errorMessage && (
              <p className="cash-amount-error">{errorMessage}</p>
            )}
          </div>
          {isWindcaveProcessing && (
            <div className="windcaveSplitPaymentInfoContainer">
              <PosIconLoading mainColor="#f39c12" size={25} />
              <span style={{ marginLeft: '10px' }}>Payment is in process</span>
            </div>
          )}
        </div>
        <div className="customer-phone-keypad-box">
          {keyList.current.map((item) => {
            const itemCls = classNames({
              'customer-phone-key': true,
              'hover-highlight': true,
              [`key-${item.val.toLowerCase()}`]: true,
            });
            return (
              <div key={item.id} className="customer-phone-key-wrap">
                <Button
                  title={item.val}
                  className={itemCls}
                  onClick={(e) => updateAmount(item.val)}
                >
                  {item.name}
                </Button>
              </div>
            );
          })}
        </div>
        <div className="customer-phone-btn-box">
          {!windcaveButtonOne && !windcaveButtonTwo && (
            <Button
              disabled={!showBtn || isLoading}
              className={clsx({
                btn: true,
                green: showBtn && isResolved,
                red: isFailed,
              })}
              title="Tender Cash"
              onClick={handleTernderSubmit}
            >
              {isResolved && (
                <PosIconCheckmarkCircle mainColor="#ffffff" size={25} />
              )}
              {isLoading && <PosIconLoading mainColor="#ffffff" size={25} />}
              {!isLoading && !isResolved && !isFailed
                ? `Tender ${showAmountInTenderButton ? 'Amount' : 'Cash'}`
                : null}
              {isFailed && <PosIconClose mainColor="#ffffff" size={25} />}
            </Button>
          )}

          {windcaveButtonOne && (
            <button
              type="button"
              onClick={() =>
                handleWindcaveButton(
                  windcaveButtonOne,
                  'B1',
                  windcaveTransactionRef
                )
              }
              className="windcaveActionButton"
              disabled={windcaveActionButtonPressed}
            >
              <div className="windcaveButtonItem">
                {windcaveActionButtonPressed ? (
                  <LoadingIcon width={20} color="#5eb602" />
                ) : (
                  <CheckIcon width={25} />
                )}
                <span className="windcaveButtonText">{windcaveButtonOne}</span>
              </div>
            </button>
          )}

          {windcaveButtonTwo && (
            <button
              type="button"
              onClick={() =>
                handleWindcaveButton(
                  windcaveButtonTwo,
                  'B2',
                  windcaveTransactionRef
                )
              }
              className={clsx('windcaveActionButton', {
                ['windcaveButtonDisabled']: windcaveActionButtonPressed,
              })}
              disabled={windcaveActionButtonPressed}
            >
              <div className="windcaveButtonItem">
                {windcaveActionButtonPressed ? (
                  <LoadingIcon width={20} color="#f40000" />
                ) : (
                  <XRoundedIcon width={20} className="windcaveItemIcon" />
                )}

                <span
                  className="windcaveButtonText"
                  style={{ color: '#f40000' }}
                >
                  {windcaveButtonTwo}
                </span>
              </div>
            </button>
          )}
        </div>
      </div>
    </GeneralModal>
  );
};

CashAmountModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  openNextModal: PropTypes.func,
  openSearchModal: PropTypes.func,
};

export default CashAmountModal;
