import { useMemo, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { getItemInstruction } from './util';
import {
  BillCheckInfoUI,
  BillCheckItemUI,
  BillCheckUI,
  ButtonUI,
} from 'UI/components';
import { useDispatch, useSelector } from 'react-redux';
import { addPrinterCommandApi } from '../../services/ordersServices';
import { updateCurrentOrder } from 'pages/Dashboard/action';
import {
  selectedDrawerPrinterIdSelector,
  storeConfigSelector,
  storeTimeZoneSelector,
  restaurantIdSelector,
} from '../../pages/Dashboard/selectors';
import { PosIconLoading } from 'assets/icons/PosIconLoading';

const { values } = Object;

const SplitEqually = ({
  currentOrder,
  selectedCheck,
  setChecks,
  checks,
  guests,
  concluded,
  togglePaymentModal,
  type,
  printer,
}) => {
  const customAmountCheck = !!selectedCheck?.payableAmount && type === 'custom';
  const storeConfig = useSelector(storeConfigSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const timeZone = useSelector(storeTimeZoneSelector);
  const [isPrinterApiLoading, setIsPrinterApiLoading] = useState(false);

  const checkRatio = useMemo(
    () =>
      customAmountCheck && currentOrder && selectedCheck
        ? parseFloat(currentOrder.payableAmount) / selectedCheck.payableAmount
        : 1,
    [currentOrder, selectedCheck, customAmountCheck]
  );

  const [amountPaid, setAmountPaid] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentOrder?.billType === 'full') {
      const paidAmount = currentOrder?.payments?.reduce(
        (prev, curr) => (prev = curr.amount + prev),
        0
      );

      setAmountPaid(paidAmount);
    }
  }, [currentOrder?.payments, currentOrder?.id]);

  // this is needed to compensate for floating point calculus precision errors
  const checkTotal = useMemo(
    () =>
      type === `equal`
        ? (selectedCheck?.payableAmount).toFixed(2)
        : parseFloat(
            selectedCheck?.payableAmount || currentOrder.payableAmount
          ).toFixed(2),
    [type, currentOrder?.payableAmount, selectedCheck?.payableAmount]
  );

  const checkPaid = useMemo(
    () =>
      currentOrder &&
      selectedCheck &&
      (currentOrder.paymentStatus === 1 ||
        selectedCheck.paidAmount === selectedCheck.payableAmount),
    [currentOrder, selectedCheck]
  );

  const pay = useCallback(() => {
    dispatch(updateCurrentOrder({ ...currentOrder, billType: type }));
    togglePaymentModal(checkTotal);
  }, [checkTotal, togglePaymentModal, type]);

  const setGuests = useCallback(
    (direction) => {
      if (
        typeof selectedCheck === 'undefined' ||
        selectedCheck.payments?.length
      )
        return;
      const newChecks = checks.map((c) => {
        if (
          c.number === selectedCheck.number &&
          (direction === 'inc' ? c.numGuests < guests : c.numGuests > 1)
        ) {
          const newGuestsNum = c.numGuests + (direction === 'inc' ? 1 : -1);
          const newPayableAmount = c.payableAmount * newGuestsNum;
          return {
            ...c,
            numGuests: newGuestsNum,
            payableAmount: newPayableAmount,
          };
        }
        return c;
      });

      setChecks(newChecks);
    },
    [checks, guests, selectedCheck, setChecks]
  );

  const incrementGuests = useCallback(() => setGuests('inc'), [setGuests]);
  const decrementGuests = useCallback(() => setGuests('dec'), [setGuests]);

  const handlePrintCommand = async () => {
    setIsPrinterApiLoading(true);
    const printCommandRequestObj = {
      printerId: printer?._id,
      orderId: currentOrder._id,
      storeId: storeConfig?.storeId,
      restaurantId,
      printReceipt: true,
      receiptType: 1,
      openCashDrawer: false,
      timeZone,
    };

    if (printer?._id) {
      await addPrinterCommandApi(printCommandRequestObj);
    }
    setIsPrinterApiLoading(false);
  };

  if (!selectedCheck) return null;

  return (
    <div className="bem-split-equally">
      <div />
      <BillCheckUI
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        <BillCheckUI.Header>
          <BillCheckInfoUI.Title
            borderSide={'bottom'}
            title={'POS - Dine In'}
            description={`Table ${currentOrder.tableNumber}`}
          />
        </BillCheckUI.Header>
        <BillCheckUI.Body>
          <BillCheckInfoUI
            borderSide={'bottom'}
            title={'Placed:'}
            description={moment(currentOrder.date).calendar()}
          />
          {currentOrder.notes && (
            <BillCheckInfoUI
              borderSide={'bottom'}
              title={'Notes:'}
              description={currentOrder.notes}
            />
          )}
          {currentOrder.menuItems
            .filter((i) => i.quantity >= 1)
            .map((i, index) => {
              return (
                <BillCheckItemUI
                  key={i._id + index}
                  isVoided={i.isVoided}
                  title={`${i.name} ${i.quantity > 1 ? `x ${i.quantity}` : ``}`}
                  instruction={getItemInstruction(i)}
                  price={`${storeConfig?.currency || '$'}${
                    type === `equal`
                      ? (
                          (i.payablePrice / guests) *
                          selectedCheck.numGuests
                        ).toFixed(2)
                      : (i.payablePrice / checkRatio).toFixed(2)
                  }`}
                />
              );
            })}
          {values(
            currentOrder.menuItems
              .filter((i) => i.isQuarter)
              .reduce((acc, k) => {
                return {
                  ...acc,
                  [k.fourQuarterIndex]: [...(acc[k.fourQuarterIndex] || []), k],
                };
              }, {})
          ).map((item, index) => {
            const partedIndex = item.map((i) => i._id).join('+');
            const name = item.map((i) => i.name).join('/');
            const payablePrice = item.reduce(
              (acc, i) => (acc += i.payablePrice),
              0
            );
            const instruction = item.map((i) => getItemInstruction(i));
            return (
              <BillCheckItemUI
                key={partedIndex}
                title={name}
                instruction={instruction}
                price={`${storeConfig?.currency || '$'}${
                  type === `equal`
                    ? (
                        (payablePrice / guests) *
                        selectedCheck.numGuests
                      ).toFixed(2)
                    : (payablePrice / checkRatio).toFixed(2)
                }`}
              />
            );
          })}
          {values(
            currentOrder.menuItems
              .filter((i) => i.quantity === 0.5)
              .reduce(
                (acc, k) => ({
                  ...acc,
                  [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                }),
                {}
              )
          ).map((item, index) => {
            const partedIndex = item.map((i) => i._id).join('+') + index;
            const name = item.map((i) => i.name).join('/');
            const payablePrice = item.reduce(
              (acc, i) => (acc += i.payablePrice),
              0
            );
            const instruction = item.map(
              (i) =>
                `${
                  getItemInstruction(i).trim() !== '' ? `${i.name}:` : ''
                } ${getItemInstruction(i)}`
            );
            return (
              <BillCheckItemUI
                key={partedIndex}
                title={name}
                instruction={instruction}
                price={`${storeConfig?.currency || '$'}${
                  type === `equal`
                    ? (
                        (payablePrice / guests) *
                        selectedCheck.numGuests
                      ).toFixed(2)
                    : (payablePrice / checkRatio).toFixed(2)
                }`}
              />
            );
          })}
        </BillCheckUI.Body>
        <BillCheckUI.Footer>
          {type === `equal` && (
            <BillCheckInfoUI.Counter
              style={{ marginBottom: -10 }}
              title="Paying for Guests"
              quantity={selectedCheck.numGuests}
              onIncrement={concluded ? incrementGuests : undefined}
              onDecrement={concluded ? decrementGuests : undefined}
            />
          )}
          {parseFloat(currentOrder.discount) > 0 ? (
            <BillCheckInfoUI
              title={'Discount'}
              description={`-${storeConfig?.currency || '$'}${(
                (currentOrder.discount * (selectedCheck?.numGuests || 1)) /
                (type === 'equal' ? guests : checkRatio)
              ).toFixed(2)}`}
            />
          ) : null}
          {currentOrder.specialDiscount !== '0' ? (
            <BillCheckInfoUI
              title={'Specials'}
              description={`-${storeConfig?.currency || '$'}${(
                (currentOrder.specialDiscount *
                  (selectedCheck?.numGuests || 1)) /
                (type === 'equal' ? guests : checkRatio)
              ).toFixed(2)}`}
            />
          ) : null}
          {
            <BillCheckInfoUI.Total
              title={'Amount Paid'}
              description={`${storeConfig?.currency || '$'}${amountPaid.toFixed(
                2
              )}`}
            />
          }
          <BillCheckInfoUI.Total
            title={'Total'}
            description={`${
              storeConfig?.currency || '$'
            }${selectedCheck.payableAmount.toFixed(2)}`}
          />

          <BillCheckUI.ActionButtons>
            <ButtonUI
              styleType="success"
              sizeType="l"
              rounded="m"
              fullWidth
              disabled={checkPaid}
              onClick={pay}
            >
              Pa{checkPaid ? `id` : `y`}
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="l"
              rounded="m"
              fullWidth
              onClick={handlePrintCommand}
              disabled={!printer?._id || isPrinterApiLoading}
              icon={
                isPrinterApiLoading && (
                  <PosIconLoading
                    mainColor="white"
                    style={{
                      marginLeft: 16,
                      width: '18px',
                      height: '18px',
                    }}
                  />
                )
              }
            >
              Print
            </ButtonUI>
          </BillCheckUI.ActionButtons>
        </BillCheckUI.Footer>
      </BillCheckUI>
    </div>
  );
};

export default SplitEqually;
