import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconClock = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-clock': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M15.82 14.534l-3.38-3.168V6.28h-.88v5.28c0 .123.05.242.14.326l3.52 3.3zM22.12 12c0 5.59-4.53 10.12-10.12 10.12-5.59 0-10.12-4.53-10.12-10.12C1.88 6.41 6.41 1.88 12 1.88c5.587.005 10.115 4.533 10.12 10.12zm.88 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11c6.073-.005 10.995-4.927 11-11z"
            />
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness * 0.4 : 0.4}
              strokeMiterlimit="20"
              d="M15.82 14.534l-3.38-3.168V6.28h-.88v5.28c0 .123.05.242.14.326l3.52 3.3zM22.12 12c0 5.59-4.53 10.12-10.12 10.12-5.59 0-10.12-4.53-10.12-10.12C1.88 6.41 6.41 1.88 12 1.88c5.587.005 10.115 4.533 10.12 10.12zm.88 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11c6.073-.005 10.995-4.927 11-11z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default PosIconClock.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
