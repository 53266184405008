import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import {
  getItemPriceForSpecialDiscount,
  detectMergeRequired,
} from './utilities';

const getLoyaltyDiscount = (
  currentOrder,
  rowItems,
  offers,
  pathwayToOffers,
  itemsUsedForReward,
  rewards
) => {
  let detectedOffers = detectOffers(
    currentOrder,
    rowItems,
    offers,
    pathwayToOffers,
    itemsUsedForReward
  );
  detectedOffers.map((p) => {
    rewards = [...rewards, ...p.rewards];
  });
  let discount = rewards.reduce((total, obj) => {
    return (total = total + Number(obj.discount));
  }, 0);

  return discount;
};

function detectOffers(
  currentOrder,
  rowItems,
  offers,
  pathwayToOffers,
  itemsUsedForReward
) {
  let rawOfferFrames = getOfferFrames(offers, pathwayToOffers);
  let offerFrames = [];
  let itemsUsedForOfferDetection = [];
  //let itemsUsedForReward = [];
  if (rawOfferFrames.length > 0) {
    let itemsUsed = [];
    rawOfferFrames.map((offerFrame) => {
      if (offerFrame.type === '1') {
        let matchingRowItems = rowItems.filter((rowItem) => {
          return (
            offerFrame.activeCategoryKeys &&
            offerFrame.activeCategoryKeys.includes(rowItem.categoryId) &&
            ((offerFrame.activeSizeKeys &&
              offerFrame.activeSizeKeys.includes(rowItem.sizeKey)) ||
              (offerFrame.activeModifierKeys &&
                offerFrame.activeModifierKeys.includes(rowItem.sizeKey))) &&
            offerFrame.activeMenuItemKeys &&
            offerFrame.activeMenuItemKeys.includes(rowItem._id) &&
            !itemsUsed.includes(rowItem.primaryKey)
          );
        });
        let count = 0;
        matchingRowItems.map((rowItem) => {
          count = count + (rowItem.isHalf ? 0.5 : 1);
          return rowItem;
        });
        offerFrame.totalOrderCount = offerFrame.orderCount + count;
        offerFrame.matchingRowItems = matchingRowItems;
      } else {
        offerFrame.totalOrderCount = offerFrame.orderCount;
        offerFrame.matchingRowItems = [];
      }
      return offerFrame;
    });
  }
  rawOfferFrames = rawOfferFrames.filter((offer) => {
    return (
      offer.totalOrderCount >=
      (offer.type === '1' ? Number(offer.quantity) : Number(offer.noOfOrders))
    );
  });
  rawOfferFrames.map((offer) => {
    let leftQauntity = Number(offer.orderCount);
    if (offer.type === '1') {
      for (let j = 0; Number(offer.quantity) * j < offer.totalOrderCount; j++) {
        let iNeedQuantityFromCart = 0;
        let obj = { ...offer };
        let numPush =
          Number(offer.quantity) * (j + 1) <= offer.totalOrderCount
            ? Number(offer.quantity)
            : offer.totalOrderCount - Number(offer.quantity) * j;
        if (numPush >= Number(offer.quantity)) {
          if (leftQauntity <= 0) {
            iNeedQuantityFromCart = numPush;
          } else if (leftQauntity < numPush && leftQauntity > 0) {
            iNeedQuantityFromCart = numPush - leftQauntity;
          }
          if (numPush >= leftQauntity) {
            leftQauntity = 0;
          } else {
            leftQauntity = leftQauntity - numPush;
          }
          let useItems = [];
          offer.matchingRowItems.map((p) => {
            if (
              !itemsUsedForOfferDetection.includes(p.primaryKey) &&
              !itemsUsedForReward.includes(p.primaryKey) &&
              useItems.length < iNeedQuantityFromCart
            ) {
              useItems.push(p);
            }
            return p;
          });
          if (iNeedQuantityFromCart === useItems.length) {
            obj.iNeedQuantityFromCart = iNeedQuantityFromCart;
            obj.itemsUsed = useItems;
            itemsUsedForOfferDetection = [
              ...itemsUsedForOfferDetection,
              ...useItems.map((p) => p.primaryKey),
            ];
            delete obj.matchingRowItems;
            let rewardFrames = getRewardFrame(obj);
            let rewardForOffer = detectReward(
              offers,
              currentOrder,
              rowItems,
              rewardFrames,
              itemsUsedForOfferDetection,
              itemsUsedForReward
            );
            obj.rewards = rewardForOffer;
            offerFrames.push(obj);
          }
        }
      }
    } else {
      for (
        let j = 0;
        Number(offer.noOfOrders) * j < offer.totalOrderCount;
        j++
      ) {
        let obj = { ...offer };
        let numPush =
          Number(offer.noOfOrders) * (j + 1) <= offer.totalOrderCount
            ? Number(offer.noOfOrders)
            : offer.totalOrderCount - Number(offer.noOfOrders) * j;
        if (numPush >= Number(offer.noOfOrders)) {
          if (numPush >= leftQauntity) {
            leftQauntity = 0;
          } else {
            leftQauntity = leftQauntity - numPush;
          }
          let rewardFrames = getRewardFrame(obj);
          let rewardForOffer = detectReward(
            offers,
            currentOrder,
            rowItems,
            rewardFrames,
            itemsUsedForOfferDetection,
            itemsUsedForReward
          );
          obj.rewards = rewardForOffer;
          offerFrames.push(obj);
        }
      }
    }
    return offer;
  });
  return offerFrames;
}

function getOfferFrames(offers, pathwayToOffers) {
  let rawOfferFrames = [];
  if (offers && pathwayToOffers) {
    rawOfferFrames = offers.map((offer) => {
      let matchingProgress = pathwayToOffers.filter((path) => {
        return path.offerId === offer._id;
      });
      if (matchingProgress.length > 0) {
        let count = matchingProgress.reduce((total, item) => {
          return total + item.orderCount;
        }, 0);
        offer.orderCount = count;
      } else {
        offer.orderCount = 0;
      }
      return offer;
    });
  }
  return rawOfferFrames;
}

function getRewardFrame(offer) {
  let rewardFrames = [];
  let x = {
    _id: offer._id,
    name: offer.name,
    isComplete: false,
    slots: [],
    isAnyOneItemAvailable: offer.isAnyOneItemAvailable,
  };
  offer.rewardItems &&
    offer.rewardItems.map((sp) => {
      new Array(Number(sp.quantity)).fill().map((_m, i) => {
        let u = {
          categoryKeys: sp.categories
            .filter((s) => {
              return s.isActive;
            })
            .map((s) => s._id),
          sizeKeys: sp.sizes
            ? sp.sizes
                .filter((s) => {
                  return s.isActive;
                })
                .map((s) => s._id)
            : [],
          modifierKeys: sp.subModifiers
            ? sp.subModifiers
                .filter((s) => {
                  return s.isActive;
                })
                .map((s) => s._id)
            : [],
          menuItems: sp.menuItems
            .filter((s) => {
              return !s.isActive;
            })
            .map((s) => s._id),
          id: i + 1,
          isSlotOccupied: false,
        };
        x.slots.push(u);
        return _m;
      });
      return sp;
    });
  x.slotLength = x.slots.length;
  rewardFrames.push(x);
  return rewardFrames;
}

function detectReward(
  offers,
  currentOrder,
  rowItems,
  rewardFrames,
  itemsUsedForOfferDetection,
  itemsUsedForReward
) {
  let itemsUsed = [];
  let rewardItemsObj = [];
  let rewards = [];
  if (rowItems && rowItems.length > 0 && rewardFrames.length > 0) {
    rewardFrames = sortBy(rewardFrames, 'slotLength').reverse();
    rewardFrames.map((s) => {
      getRewardDetectionCount(
        s,
        rowItems,
        itemsUsedForReward,
        itemsUsedForOfferDetection,
        rewardItemsObj
      );
      return s;
    });
    let detectedRewards = rewardItemsObj;
    if (detectedRewards && detectedRewards.length > 0) {
      detectedRewards.map((sd) => {
        let itemsToBeAddedInOffer = [];
        sd.slots.map((ss) => {
          let rowMenuItems = JSON.parse(JSON.stringify(currentOrder.menuItems));
          let filteredItem = [];
          filteredItem = rowMenuItems.filter((ff) => {
            return ff._id === ss.itemId;
          });
          if (filteredItem && filteredItem.length > 0) {
            filteredItem.map((ftmm) => {
              let ttml = ftmm;
              if (detectMergeRequired(ss, ttml)) {
                ttml.selectedSizes = ttml.selectedSizes.filter((ggm) => {
                  return ss.sizeKeyMatched === ggm._id;
                });
                if (ttml.selectedSizes && ttml.selectedSizes.length > 0) {
                  ttml.selectedSizes[0].quantity = ttml.isHalf ? 0.5 : 1;
                  if (ttml.isHalf) {
                    let othersHalf = rowMenuItems.filter((m) => {
                      return (
                        m.halfIndex === ttml.halfIndex && m.isSecondHalf == true
                      );
                    });
                    if (othersHalf.length > 0) {
                      let ttmll = othersHalf[0];
                      ttml.urlHalf = ttmll.urlS3;
                      ttml._idHalf = ttmll._id;
                      ttml.nameHalf = ttmll.name;
                      ttml.selectedSizesHalf = ttmll.selectedSizes;
                      ttml.selectedIngredientsHalf = ttmll.selectedIngredients;
                      ttml.removedIngredientsHalf = ttmll.removedIngredients;
                      ttml.selectedExtraIngredientsHalf =
                        ttmll.selectedExtraIngredients;
                      ttml.selectedSizesHalf = ttmll.selectedSizes.filter(
                        (ggm) => {
                          return ss.sizeKeys.indexOf(ggm._id) >= 0;
                        }
                      );
                      ttml.selectedSizesHalf[0].quantity = 0.5;
                    }
                  }
                  itemsToBeAddedInOffer.push(ttml);
                }
              }
              return ftmm;
            });
          }
          return ss;
        });

        let matchingOffer = offers.filter((obj) => {
          return obj._id === sd._id;
        });

        if (
          itemsToBeAddedInOffer &&
          itemsToBeAddedInOffer.length > 0 &&
          matchingOffer.length > 0
        ) {
          let matchedOffer = matchingOffer[0];
          let discountedPrice = 0;
          if (sd.isAnyOneItemAvailable) {
            matchedOffer.rewardItems.map((obj) => {
              if (
                discountedPrice !== 0 &&
                discountedPrice > Number(obj.discountedPrice)
              )
                discountedPrice = Number(obj.discountedPrice);
              else discountedPrice = Number(obj.discountedPrice);
            });
          } else {
            discountedPrice = matchedOffer.rewardItems.reduce((total, obj) => {
              return (total = total + Number(obj.discountedPrice));
            }, 0);
          }
          let totalCost = 0,
            offerDiscount = 0;
          itemsToBeAddedInOffer.map((m) => {
            let itemPrice = getItemPriceForSpecialDiscount(m);
            if (sd.isAnyOneItemAvailable) {
              if (totalCost !== 0 && totalCost < Number(itemPrice))
                totalCost = Number(itemPrice);
              else if (totalCost === 0) totalCost = Number(itemPrice);
            } else totalCost = totalCost + Number(itemPrice);
            return m;
          });
          offerDiscount = totalCost - Number(discountedPrice);
          let sObj = {
            _id: matchedOffer._id,
            name: matchedOffer.name,
            discount: offerDiscount.toFixed(2),
            slots: sd.slots,
            isComplete: sd.isComplete,
          };
          rewards.push(sObj);
        }
        return sd;
      });
    }
  }
  return rewards;
}

function getRewardDetectionCount(
  frame,
  rowItems,
  itemsUsedForReward,
  itemsUsedForOfferDetection,
  rewardItemsObj
) {
  let ct = 0;
  let s = JSON.parse(JSON.stringify(frame));
  let itemsUsedForS = [];
  if (s.slots && s.slots.length > 0) {
    s.slots = s.slots.map((i) => {
      let itemExistForMatch = rowItems.filter((r) => {
        return (
          i.categoryKeys.indexOf(r.categoryId) >= 0 &&
          (i.sizeKeys.indexOf(r.sizeKey) >= 0 ||
            i.modifierKeys.indexOf(r.sizeKey) >= 0) &&
          i.menuItems.indexOf(r._id) < 0
        );
      });
      if (itemExistForMatch.length > 0) {
        for (let l = 0; l < itemExistForMatch.length; l++) {
          if (
            !includes(itemsUsedForS, itemExistForMatch[l].primaryKey) &&
            !includes(itemsUsedForReward, itemExistForMatch[l].primaryKey) &&
            !includes(
              itemsUsedForOfferDetection,
              itemExistForMatch[l].primaryKey
            ) &&
            !i.isSlotOccupied
          ) {
            i.isSlotOccupied = true;
            i.itemId = itemExistForMatch[l]._id;
            i.primaryKey = itemExistForMatch[l].primaryKey;
            i.orderIndex = itemExistForMatch[l].orderIndex;
            i.name = itemExistForMatch[l].name;
            i.isHalf = itemExistForMatch[l].isHalf;
            i.isSecondHalf = itemExistForMatch[l].isSecondHalf;
            i.removedIngredients = itemExistForMatch[l].removedIngredients;
            i.selectedExtraIngredients =
              itemExistForMatch[l].selectedExtraIngredients;
            i.sizeKeyMatched = itemExistForMatch[l].sizeKey;
            itemsUsedForS.push(itemExistForMatch[l].primaryKey);
            ct = ct + 1;
          }
        }
      }
      return i;
    });
    itemsUsedForReward = [...itemsUsedForReward, ...itemsUsedForS];
    rewardItemsObj.push({
      _id: s._id,
      name: s.name,
      slots: s.slots,
      isAnyOneItemAvailable: s.isAnyOneItemAvailable,
      isComplete: ct === s.slots.length ? true : false,
    });
  }
}

export default getLoyaltyDiscount;
