import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconPeoples = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-peoples': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M18.74 10.951a.198.198 0 0 1-.19.19h-.95a.38.38 0 0 0-.38.38v4.18h-3.04v-4.37a3.237 3.237 0 0 0-2.432-3.13 2.66 2.66 0 0 0 .912-1.657 2.44 2.44 0 0 1 1.71-.722h1.9a2.47 2.47 0 0 1 2.47 2.47zm-5.32 3.04a.19.19 0 0 1-.19.19h-.95a.38.38 0 0 0-.38.38v4.18H8.1v-4.18a.38.38 0 0 0-.38-.38h-.95a.19.19 0 0 1-.19-.19v-2.66a2.47 2.47 0 0 1 2.47-2.47h1.9a2.47 2.47 0 0 1 2.47 2.47zm-7.6-2.66v4.37H2.78v-4.18a.38.38 0 0 0-.38-.38h-.958a.19.19 0 0 1-.182-.19v-2.66a2.47 2.47 0 0 1 2.462-2.469h1.9a2.44 2.44 0 0 1 1.718.76 2.66 2.66 0 0 0 .89 1.657 3.237 3.237 0 0 0-2.41 3.092zM4.68 1.263a1.9 1.9 0 1 1 0 3.8 1.9 1.9 0 0 1 0-3.8zM10 4.303a1.9 1.9 0 1 1 0 3.799 1.9 1.9 0 0 1 0-3.8zm5.32-3.04a1.9 1.9 0 1 1 0 3.8 1.9 1.9 0 0 1 0-3.8zm1.748 3.898a2.66 2.66 0 1 0-3.504 0c-.349.09-.68.24-.98.44a2.66 2.66 0 0 0-5.183 0c-.298-.2-.626-.348-.973-.44a2.66 2.66 0 1 0-3.504 0A3.237 3.237 0 0 0 .5 8.291v2.66c0 .522.42.946.942.95h.578v4.18c0 .21.17.38.38.38h3.8c.21 0 .38-.17.38-.38v-1.14h.76v4.18c0 .209.17.379.38.379h4.56c.21 0 .38-.17.38-.38v-4.18h.76v1.14c0 .21.17.38.38.38h3.8c.21 0 .38-.17.38-.38v-4.179h.57a.95.95 0 0 0 .95-.95v-2.66a3.237 3.237 0 0 0-2.432-3.13z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconPeoples.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
