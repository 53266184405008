import React from 'react';
import PropTypes from 'prop-types';
import GeneralModal from '../GeneralModal/index-new';
import MapModalWrapper from 'components/MapModalWrapper';
import LeafletGeneric from 'components/Tracking/LeafletGeneric';

const DeliveryMapMaxModal = ({ modalStatus, toggleModal, location }) => {
  
  const orderData = {};

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={toggleModal}
      position="bottom"
      effect="move"
      overlay=""
      boxWidth="886px"
      boxHeight="705px"
      className="drivers-map-max-modal"
      closeBtn="inside"
    >
      <div className="drivers-map-max-box">
        <div className="drivers-map-max-header">
          <h3
            className={'drivers-map-max-modal-title ' + orderData.priorityClass}
          >
            <span>Customer Delivery Location</span>
          </h3>
        </div>
        <div className="drivers-map-max-body">
          <div className="driver-map-max-wrap">
            <MapModalWrapper
              iframeWidth="100%"
              iframeHeight={613}
            >
              {location?.lat &&
                location?.lng && 
                <LeafletGeneric
                  delivery={location}
                  key={`map-modal-${location.lat}`}
                  id="delivery-map-modal"
                />
              }
            </MapModalWrapper>
          </div>
        </div>
      </div>
    </GeneralModal>
  );
};

export default DeliveryMapMaxModal;

DeliveryMapMaxModal.propTypes = {
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};
