import React from 'react';
import ModalPortal from 'components/ModalPortal';
import clsx from 'clsx';
import Keyboard from 'react-simple-keyboard';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

const VirtualKeyboard = ({ ...props }) => {
  const {
    virtualKeyboardGlobal,
    keyboardVisibility,
    layout,
    keyboardWrapRef,
    keyboardRef,
    inputName,
    onChangeFromKeyboard,
    onKeyPress,
    floatingMode,
    handleMouseDown,
    handleMouseUp,
  } = useVirtualKeyboardContext();

  if (!virtualKeyboardGlobal) return null;

  return (
    <ModalPortal>
      <div
        id="keyboard-wrap"
        ref={keyboardWrapRef}
        className={clsx('virtual-keyboard-wrap', {
          show: keyboardVisibility,
          floating: floatingMode,
        })}
      >
        <Keyboard
          id="simple-keyboard"
          keyboardRef={(r) => (keyboardRef.current = r)}
          theme="hg-theme-default hg-theme-ios"
          layoutName={layout}
          inputName={inputName}
          onChange={onChangeFromKeyboard}
          onKeyPress={onKeyPress}
          physicalKeyboardHighlightPress={true}
          preventMouseDownDefault={true}
          useButtonTag={false}
          layout={{
            default: [
              '{tab} q w e r t y u i o p {bksp}',
              '{capslock} a s d f g h j k l {enter}',
              '{shift} z x c v b n m , . {shift}',
              '{smileys} {alt} {space} {altright} {floating} {downkeyboard}',
            ],
            shift: [
              '{tab} Q W E R T Y U I O P {bksp}',
              '{capslock} A S D F G H J K L {enter}',
              '{shiftactivated} Z X C V B N M , . {shiftactivated}',
              '{smileys} {alt} {space} {altright} {floating} {downkeyboard}',
            ],
            alt: [
              '{tab} 1 2 3 4 5 6 7 8 9 0 {bksp}',
              `{capslock} @ # $ & * ( ) ' " {enter}`,
              '{shift} % - + = / ; : ! ? {shift}',
              '{smileys} {alt} {space} {altright} {floating} {downkeyboard}',
            ],
            smileys: [
              '😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}',
              `😏 😬 😭 😓 😱 😪 😬 😴 😯 {enter}`,
              '😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {shift}',
              '{smileys} {alt} {space} {altright} {floating} {downkeyboard}',
            ],
          }}
          display={{
            '{alt}': '.?123',
            '{smileys}': '\uD83D\uDE03',
            '{shift}': 'shift',
            // '{shift}': '⇧',
            '{shiftactivated}': 'shift',
            '{enter}': 'return',
            '{bksp}': '⌫',
            '{altright}': '.?123',
            '{downkeyboard}': '🞃',
            '{space}': ' ',
            '{default}': 'ABC',
            '{back}': '⇦',
            '{floating}': '⌨',
            '{tab}': 'tab',
            '{capslock}': 'caps lock',
            '{mic}': 'mic',
          }}
          {...props}
        />
        {floatingMode && (
          <div
            id="move-keyboard"
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            className="move-keyboard"
          />
        )}
      </div>
    </ModalPortal>
  );
};

export default VirtualKeyboard;
