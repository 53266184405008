import storeConfig from '../configuration/storeConfig';
import { API_URL } from '../configuration/apiUrl';

export const fetchStoreConfig = () => {
  return storeConfig;
};

export const fetchCustomerNameByPhoneNumber = async ({
  restaurantId,
  mobileNumber,
}) => {
  return fetch(`${API_URL}/getUserByMobileNumber`, {
    method: 'post',
    headers: {
      // 'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      restaurantId,
      mobileNumber,
    }),
  }).then((response) => {
    return response.json();
  });
};

export const authenticateStaffByAccessCode = async (storeId, accessCode) => {
  return fetch(`${API_URL}/getStaffPermissions`, {
    method: 'post',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      storeId,
      accessCode,
    }),
  }).then((response) => {
    return response.json();
  });
};
