import { PosIconLoading } from 'assets/icons/PosIconLoading';
import Accordion from 'components/Accordion';
import { updateMenuItemCourseStatus } from 'components/OrderBar/CoursesModal/CoursesModal.service';
import { API_URL } from 'configuration/apiUrl';
import { useApi } from 'hooks/useApi';
import { groupBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ButtonUI, CloseButtonCircleUI, ScrollableBoxUI } from 'UI/components';

import './bem-courses-popup.scss';

export default function CoursesCard({
  handlePay,
  setCloseCoursesPopup,
  orderTouched,
  currentOrder,
  hideCloseButton,
  callback,
  isAddingToTable,
  menuItemsWithCourseStatus,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const dispatch = useSelector()

  const coursesMap = useSelector(
    (state) => state.dashboardReducer.dineInCourses?.dineInCoursesMap ?? {}
  );
  const [selectedCourseIds, setSelectedCourseIds] = useState(new Set());

  const selectedCourses = useMemo(
    () =>
      currentOrder?.menuItems.map(({ courseId, isVoided }) => ({
        ...coursesMap[courseId],
        isVoided,
      })) ?? [],
    [currentOrder.menuItems, coursesMap]
  );

  const coursesByCourseId = groupBy(selectedCourses, '_id');
  const menuItemsByCourseId = groupBy(currentOrder.menuItems, 'courseId');

  const coursesSorted = useMemo(
    () =>
      selectedCourses
        .filter((course) => course.isActive === true)
        .sort((a, b) => a.order - b.order),
    [selectedCourses]
  );

  const uniqueCoursesSorted = useMemo(
    () =>
      coursesSorted.filter(
        (c, index) => coursesSorted.findIndex((i) => i._id === c._id) === index
      ),
    [coursesSorted]
  );

  const request = useApi('POST', `${API_URL}/orders/v1/courses/call`)?.[1];

  const toggleCourseId = (id) => {
    const st = new Set([...selectedCourseIds]);
    const newSt = new Set(st.delete(id) ? st : st.add(id));
    setSelectedCourseIds(newSt);
  };

  const callCourse = async () => {
    if (currentOrder._id && selectedCourseIds.size > 0) {
      const data = {
        courseIds: Array.from(selectedCourseIds),
        orderId: currentOrder._id,
      };
      await updateMenuItemCourseStatus(
        menuItemsWithCourseStatus,
        currentOrder._id
      );

      await request(data, { cors: true });
      setSelectedCourseIds(new Set());
      callback?.();
    }
  };

  const handleSend = async () => {
    setLoading(true);
    const calledCourses = [
      ...new Set([...(currentOrder.calledCourses ?? []), ...selectedCourseIds]),
    ];

    if (orderTouched) {
      await handlePay(
        async () => {
          await callCourse();
          history.push('/table-arrangement');
        },
        {
          hasNotChanged: !orderTouched,
          calledCourses,
        }
      );
    } else {
      await callCourse();
      history.push('/table-arrangement');
    }

    setLoading(false);
  };

  const isButtonDisabled =
    (selectedCourseIds.size === 0 || loading) && !orderTouched;

  const buttonTitle = `Confirm & Send ${
    selectedCourseIds.size > 0 ? '-' : ''
  } ${Array.from(selectedCourseIds)
    .map((id) => coursesByCourseId[id][0].name)
    .join(', ')}`;

  return (
    <div className="bem-courses-popup__container">
      <div className="bem-courses-popup__header">
        <h2 className="bem-courses-popup__title">Select Courses </h2>
        {!hideCloseButton && (
          <CloseButtonCircleUI
            style={{
              backgroundColor: 'var(--mainBG)',
              boxShadow: '0 1px 5px 0 rgba(0,0,0,0.06)',
            }}
            onClick={setCloseCoursesPopup}
          />
        )}
      </div>
      <div className="bem-courses-popup__body">
        <ScrollableBoxUI>
          <div className="bem-courses-popup__body-inner">
            <Accordion>
              {uniqueCoursesSorted.map((course, index) => {
                const { name, _id } = course;

                const isEveryItemVoided = menuItemsByCourseId[_id]?.every(
                  (i) => i.isVoided
                );
                const currentOrderIncludesId =
                  currentOrder.calledCourses?.includes(_id);
                const checked =
                  (selectedCourseIds.has(_id) || currentOrderIncludesId) &&
                  !isEveryItemVoided;
                const disabled = currentOrderIncludesId || isEveryItemVoided;

                return (
                  <Accordion.Section
                    key={index}
                    title={name}
                    value={_id}
                    courseId={_id}
                    onChange={(id) => toggleCourseId(id)}
                    checked={checked}
                    disabled={disabled}
                  >
                    {menuItemsByCourseId[_id].map((item, index) => (
                      <div
                        key={index}
                        className="bem-accordion__list-item"
                        style={{
                          ...(item.isVoided && {
                            textDecoration: 'line-through',
                          }),
                        }}
                      >
                        <span>
                          {item.name}{' '}
                          {item.quantity > 1 ? `x ${item.quantity}` : ''}
                        </span>
                      </div>
                    ))}
                  </Accordion.Section>
                );
              })}
            </Accordion>
          </div>
        </ScrollableBoxUI>
      </div>

      <div className="bem-courses-popup__footer">
        <ButtonUI
          fullWidth
          rounded="none"
          sizeType="xl"
          iconPosition="right"
          onClick={handleSend}
          disabled={isButtonDisabled || isAddingToTable}
          icon={
            loading && (
              <PosIconLoading
                mainColor="white"
                style={{
                  marginLeft: 16,
                  width: '18px',
                  height: '18px',
                }}
              />
            )
          }
          style={{
            minHeight: 55,
          }}
        >
          {buttonTitle}
        </ButtonUI>
      </div>
    </div>
  );
}
