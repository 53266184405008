import { PosIconDownChevron } from 'assets/icons/PosIconDownChevron';
import { PosIconRightChevron } from 'assets/icons/PosIconRightChevron';
import { PosIconTrash } from 'assets/icons/PosIconTrash';
import { useGlobalModalContext } from 'modals/GlobalModalContext/useGlobalModalContext';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteBatchOrder } from '../../../store/batchOrders/actions';
import UnAssignedSingleItem from './UnassignedSingleItem';

const BatchedOrderItem = ({
  batchOrder = {},
  selected,
  handleChange = () => {},
}) => {
  const dispatch = useDispatch();
  const { handleGlobalModal } = useGlobalModalContext();

  const onDelete = (e) => {
    e?.stopPropagation();

    handleGlobalModal({
      title: `Are you sure you want to delete ${
        batchOrder.name || 'this batch order'
      }?`,
      message: `The deliveries will be unassigned from ${
        batchOrder.name || 'this batch order'
      }. You can then re-add them to a new batch from "Unassigned".`,
      actionButtonLabel: 'Delete Batch',
      actionButtonHandler: () => dispatch(deleteBatchOrder(batchOrder.id)),
      actionButtonColor: 'red',
      dismissButtonLabel: 'Dismiss',
      dismissButtonHandler: () => {},
    });
  };

  return (
    <>
      <label key={batchOrder.id} className="single-detail-box">
        <div className="single-details-input-box">
          <input
            type="checkbox"
            name="unassigned_item"
            checked={selected}
            onChange={() => handleChange(batchOrder.id)}
          />
          <span className="checkstyle" />
        </div>
        <section
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3>{batchOrder.name}</h3>
          <div
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <span className="icon-box" onClick={onDelete}>
              <PosIconTrash mainColor="#000" />
            </span>
            <span className="icon-box">
              {selected ? (
                <PosIconDownChevron mainColor="#000" />
              ) : (
                <PosIconRightChevron mainColor="#000" />
              )}
            </span>
          </div>
        </section>
      </label>

      {selected && (
        <>
          {batchOrder.orders.map((order) => (
            <UnAssignedSingleItem key={order.id} order={order} hideCheckbox />
          ))}
        </>
      )}
    </>
  );
};

export default BatchedOrderItem;
