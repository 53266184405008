import {
  delivery_route,
  dinein_route,
  drive_thru_route,
  index_route,
  kiosk_route,
  money_count_route,
  open_cash_drawer_route,
  pickup_route,
  shifts_clock_in_route,
  walkin_route
} from 'constants/routesConsts';

const permissionRouteMap = {
  APPLY_PRICE_ADJUSTMENT: [],
  FINISH_SALE: [],
  MONEY_IN_OUT: [money_count_route],
  VOID_REFUND_ORDERS: [],
  FINALIZE_DAILY_TAKINGS: [],
  OPEN_CASH_DRAWER: [open_cash_drawer_route],
  PLACE_ORDERS: [
    pickup_route,
    delivery_route,
    walkin_route,
    dinein_route,
    kiosk_route,
    drive_thru_route,
  ],
};

const whiteListRoutes = [index_route, shifts_clock_in_route];

export const hasRightPermission = (route, permissions) => {
  if (permissions?.length) {
    if (whiteListRoutes.includes(route)) return true;

    return permissions.some((p) =>
      permissionRouteMap[p.key]?.some((r) => route?.startsWith(r))
    );
  }
  return false;
};
