import useDarkMode from 'use-dark-mode';

const EmptyScreenBackgroundFill = ({children}) => {
  const {value} = useDarkMode(false)

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      background: value ? 'black' : 'white'
    }}>
      {children}
    </div>
  );
};

export default EmptyScreenBackgroundFill;