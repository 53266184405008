import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconExpandFullscreen = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-expand-fullscreen': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
        <title>Expand-Fullscreen</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M25,8H23V3H18V1h6a1,1,0,0,1,1,1Z"
        />
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M24,24H18V22h5V17h2v6A1,1,0,0,1,24,24Z"
        />
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M7,24H1a1,1,0,0,1-1-1V17H2v5H7Z"
        />
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M2,8H0V2A1,1,0,0,1,1,1H7V3H2Z"
        />
      </svg>
    </span>
  );
};

PosIconExpandFullscreen.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
