import React, { useState } from 'react';
import { useModal } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import ModalPortal from 'components/ModalPortal';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentOrderDeliveryLocation,
  resetCurrentOrderDeliveryLocation,
  updateCurrentOrder,
} from '../../pages/Dashboard/action';
import {
  activeSpecialsSelector,
  productSetupSelector,
  orderSetupSelector,
  publicHolidaysSelector,
  suburbsSelector,
} from 'pages/Dashboard/selectors';
import SearchDeliveryAddressModal from '../SearchDeliveryAddressModal';
import DeliveryDetailsModal from '../DeliveryDetailsModal';
import { matchAddressWithSuburbs } from '../../util/deliveryAddressUtils';
import orderEngine from '../../orderEngine';
import { storeConfigSelector } from '../../pages/Dashboard/selectors';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';
import EnterAddressManuallyModal from '../EnterAddressManuallyModal';
import DeliveryMapMaxModal from 'modals/DeliveryMapMaxModal';

const AddressModalWrapper = (props) => {
  const [open, { closeModal, openModal }] = useModal('addressModal');

  if (!open) return null;

  return (
    <DeliveryAddress
      {...props}
      modalStatus={open}
      closeModal={closeModal}
      openModal={openModal}
    />
  );
};

export const DeliveryAddress = ({
  addressConfirmed,
  parentPage,
  orderTimes,
  modalStatus,
  closeModal,
  openModal,
  setAddressConfirmed,
}) => {
  const [action, setAction] = useState('confirm');
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const specials = useSelector(activeSpecialsSelector);
  const publicHolidays = useSelector(publicHolidaysSelector);
  const productSetup = useSelector(productSetupSelector);
  const orderSetup = useSelector(orderSetupSelector);
  const suburbs = useSelector(suburbsSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [mapModalStatus, setMapModalStatus] = useState(false);
  const errorMessage = 'This Area is not serviceable by restaurant.';
  const acceptedCall = useSelector((s) => s.dashboardReducer.acceptedCall);

  const { onInitInput, inputNames } = useVirtualKeyboardContext();

  const storeConfig = useSelector(storeConfigSelector);

  const handleSearchSuggestSelect = (suggest, deliveryInfo) => {
    setError(false);
    if (!suggest) return;

    const {
      isRadiusDeliveryCost = false,
      isDeliverable = false,
      deliveryCost,
      minOrderValue,
    } = deliveryInfo;

    if (isRadiusDeliveryCost && !isDeliverable) {
      setError(true);
      onInitInput(inputNames.orderGeosuggest, suggest.label);
      return;
    }

    const suburb = matchAddressWithSuburbs(
      suggest,
      suburbs.filter((suburb) => suburb.storeId === currentOrder.storeId)
    );

    if (!suburb?._id) {
      setError(true);
      onInitInput(inputNames.orderGeosuggest, suggest.label);
    }

    const newCurrentOrder = orderEngine(
      {
        ...currentOrder,
        unit: '',
        notes: '',
        name: '',
        address: suggest.locationText,
        addressLocation: suggest.location,
        suburbId: suburb?._id || '',
        area: suburb?.area || '',
        areaCode: suburb?.areaCode || '',
        isManualAddress: false,
        ...(isRadiusDeliveryCost ? { deliveryCost, minOrderValue } : null),
      },
      specials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );

    if (newCurrentOrder.addressObj) delete newCurrentOrder.addressObj;

    onInitInput(inputNames.orderUnit, '');
    onInitInput(inputNames.orderNotes, '');
    onInitInput(inputNames.orderGeosuggest, suggest.label);

    dispatch(updateCurrentOrderDeliveryLocation(newCurrentOrder));

    setTimeout(() => {
      history.push(`${parentPage}/customer/delivery-address/confirm`);
    }, 350);
  };

  const handleDefaultSelect = (suburbId) => {
    if (suburbId && currentOrder?.suburbId !== suburbId) {
      const newCurrentOrder = orderEngine(
        {
          ...currentOrder,
        },
        specials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      );
      dispatch(updateCurrentOrder(newCurrentOrder));
    }
  };

  const handleResetPlace = () => {
    dispatch(resetCurrentOrderDeliveryLocation());
    setError(false);
  };

  const handleChange = (event) => {
    dispatch(
      updateCurrentOrderDeliveryLocation({
        [event.target.name]: event.target.value,
      })
    );
  };

  const [_, { openModal: openCustomerPhone }] = useModal('customerPhoneModal');

  const handleModalClose = () => {
    if (acceptedCall && Object.keys(acceptedCall).length > 0) {
      history.push('/');
    } else {
      if (!addressConfirmed || !currentOrder.address) {
        openCustomerPhone();
      } else {
        setAddressConfirmed();
      }
    }

    closeModal();
  };

  const handleConfirmClick = () => {
    setAddressConfirmed();
    closeModal();

    setTimeout(() => {
      history.push(`${parentPage}/order`);
    }, 400);
  };

  const handleOpenMapModal = () => {
    setMapModalStatus(true);
  };

  const handleToggleModal = () => {
    setMapModalStatus(!mapModalStatus);
  };

  return (
    <>
      <ModalPortal>
        {action === 'search' && (
          <SearchDeliveryAddressModal
            modalStatus={modalStatus}
            onBackClick={handleModalClose}
            onSuggestSelect={handleSearchSuggestSelect}
            onResetClick={handleResetPlace}
            address={currentOrder.address}
            error={error}
            errorMessage={errorMessage}
            parentPage={parentPage}
            currentOrder={currentOrder}
          />
        )}
        {action === 'confirm' && orderTimes && modalStatus && (
          <DeliveryDetailsModal
            handleDefaultSelect={handleDefaultSelect}
            modalStatus={modalStatus}
            onBackClick={handleModalClose}
            onSuggestSelect={handleSearchSuggestSelect}
            onResetClick={handleResetPlace}
            onChange={handleChange}
            currentOrder={currentOrder}
            onConfirmClick={handleConfirmClick}
            error={error}
            errorMessage={errorMessage}
            setAddressError={setError}
            timeInMinutes={
              orderSetup?.deliveryTime || orderSetup.defaultDeliveryTime
            }
            orderTimes={orderTimes}
            isOrderTypeDelivery={true}
            parentPage={parentPage}
            openMapModal={handleOpenMapModal}
            handleManualAddressClick={() => setAction('manual')}
          />
        )}
        {action === 'manual' && (
          <EnterAddressManuallyModal
            modalStatus={modalStatus}
            handleConfirmClick={handleConfirmClick}
            currentOrder={currentOrder}
            storeConfig={storeConfig}
            specials={specials}
            orderSetup={orderSetup}
            productSetup={productSetup}
            publicHolidays={publicHolidays}
          />
        )}
        {mapModalStatus && (
          <DeliveryMapMaxModal
            toggleModal={handleToggleModal}
            modalStatus={mapModalStatus}
            location={currentOrder.addressLocation}
          />
        )}
      </ModalPortal>
    </>
  );
};

export default AddressModalWrapper;
