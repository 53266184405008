import { Link } from 'react-router-dom';
import clsx from 'clsx';

const HalfHalfItem = ({ link, item = {}, onClick }) => {
  let animCls = clsx('card-item ', 'card-item-half');

  return (
    <div className={animCls} key={item._id}>
      <Link
        to={link ? link : '#'}
        className=""
        title={item.name}
        onClick={onClick}
      >
        <span className="card-inner-box">
          <span className={'card-img-wrap'}>
            <img src={item.urlS3} alt={item.name} />
          </span>
          <span className="card-name">{item.name}</span>
        </span>
      </Link>
    </div>
  );
};

export default HalfHalfItem;
