import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SpecialsItem from './SpecialsItem';
import { useSortByDisplayOrder } from '../../customHooks/useSortByDisplayOrder';
import { useHistory } from 'react-router-dom';
import { activeSpecialsSelector } from '../../pages/Dashboard/selectors';

const SpecialsItems = ({ parentPage }) => {
  let history = useHistory();

  const activeSpecials = useSelector(activeSpecialsSelector);
  const sortedSpecialsByDisplayOrder = useSortByDisplayOrder(
    activeSpecials || []
  );
  const currentSpecialsItem =
    useSelector((state) => state.dashboardReducer.currentSpecialsItem) || {};

  useEffect(() => {
    currentSpecialsItem._id &&
      history.push(`${parentPage}/specials/${currentSpecialsItem._id}`);
  }, [history, parentPage, currentSpecialsItem._id]);

  return sortedSpecialsByDisplayOrder?.map((item) => {
    return (
      <SpecialsItem
        key={`special-${item._id}`}
        item={item}
        parentPage={parentPage}
      />
    );
  });
};

export default SpecialsItems;
