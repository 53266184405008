import React from 'react';
import useIconColor from "../../customHooks/useIconColor";
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconCreditCard = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-credit-card': true,
    [`${className}`]: className,
  });

  /*
  fill={iconColor ? iconColor : "#000"}
  stroke={iconColor ? iconColor : "#000"}
  strokeWidth={lineThickness ? lineThickness : 0}
  */
  return (
    <span className={iconCls} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.75 83.4">
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M15.33,83.4H99.41c10.26,0,15.34-5,15.34-15.09V15.14C114.75,5.08,109.67,0,99.41,0H15.33C5.13,0,0,5.08,0,15.14V68.31C0,78.37,5.13,83.4,15.33,83.4ZM7.86,15.58c0-5.08,2.69-7.72,7.57-7.72H99.32c4.78,0,7.56,2.64,7.56,7.72v3.85h-99Zm7.57,60c-4.88,0-7.57-2.59-7.57-7.67V30.52h99V67.87c0,5.08-2.78,7.67-7.56,7.67Zm6.49-9.23h12a4.51,4.51,0,0,0,4.78-4.69v-9a4.48,4.48,0,0,0-4.78-4.69h-12a4.48,4.48,0,0,0-4.78,4.69v9A4.51,4.51,0,0,0,21.92,66.31Z"
        />
      </svg>
    </span>
  );
};

PosIconCreditCard.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
