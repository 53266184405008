import { HeaderUI } from "UI/components";
import { LogoButton } from "components/LogoButton";
import { useRef } from "react";

const Reports = () => {
  const handleOnLoad = () => {
    refIframe.current.classList.add('show');
  };

  let refIframe = useRef();

  return (
    <>
      <HeaderUI>
        <LogoButton />
      </HeaderUI>
      <iframe
        ref={refIframe}
        onLoad={handleOnLoad}
        src="https://reports.nextorder.com.au"
        title="Reports"
        style={{width: '100%', height: 'calc(100% - 56px)'}}
      />
    </>
  )
};

export default Reports