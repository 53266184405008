import './bem-bill-check-item.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const BillCheckItemUI = ({
  id,
  title,
  instruction,
  price,
  isVoided,
}) => {
  return (
    <div
      key={id}
      className={clsx('bem-bill-check-item', { ['voided']: isVoided })}
    >
      <div className="bem-bill-check-item__inner">
        <span className="bem-bill-check-item__text ">{title}</span>
        <span className="bem-bill-check-item__instruction">{instruction}</span>
      </div>
      <div className="bem-bill-check-item__price-box">
        <span className="bem-bill-check-item__text ">{price}</span>
      </div>
    </div>
  );
};

BillCheckItemUI.defaultProps = {
  title: '',
  instruction: '',
  price: '',
};
BillCheckItemUI.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
};
