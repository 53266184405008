import axios from 'axios';
import useLocalStorageState from 'hooks/useLocalStorageState';
import {
  allStoresSelector,
  storeConfigSelector,
} from 'pages/Dashboard/selectors';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonUI, RadixSelectUI } from 'UI/components';
import animationData from '../../assets/lotties/782-check-mark-success.json';
import GeneralModal from '../../modals/GeneralModal/index-new';
import './bem-transfer-order-modal.scss';
import { setOrders } from 'pages/Orders/newOrdersReducer';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const cap = (str) => str.substr(0, 1).toUpperCase() + str.substr(1);
const url = `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/transferOrder`;
const TransferOrderModal = ({ toggleModal, orderId }) => {
  const dispatch = useDispatch();
  const currentStoreId = useSelector(storeConfigSelector)?.storeId ?? '';

  const allStores = useSelector(allStoresSelector);
  const filteredStores = useMemo(
    () => allStores?.filter((s) => s.value !== currentStoreId) ?? [],
    [allStores, currentStoreId]
  );
  const [showModal, setShowModal] = useState(false);
  const [storeId, setStoreId] = useLocalStorageState(
    'transfer-storeId',
    filteredStores?.[0]?.value ?? ''
  );
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { orders, filters } = useSelector((state) => state.newOrdersReducer);
  const { date, type } = filters;
  const { allOrdersMap, allOrdersList } = orders;

  const currentStore = useMemo(
    () => allStores.find((s) => s.value === storeId)?.label,
    [storeId, allStores]
  );

  const timer = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 0);
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleConfirmTransfer = async () => {
    setErrorMsg('');
    setLoading(true);
    try {
      await axios.post(url, { orderId, storeId });

      const newAllOrdersMap = { ...allOrdersMap };

      // Make a copy of the specific order, not just a reference
      const updatedOrder = { ...newAllOrdersMap[orderId] };

      // Modify the copied object
      updatedOrder['storeId'] = storeId;

      // Update the newAllOrdersMap with the updated order
      newAllOrdersMap[orderId] = { ...updatedOrder };

      console.log('updated newAllOrdersMap', newAllOrdersMap);

      // dispatch(setSelectedOrder(null));
      dispatch(
        setOrders({
          allOrdersList: Array.from(new Set([...allOrdersList])),
          allOrdersMap: {
            ...newAllOrdersMap,
          },
          [`${date}${cap(type)}List`]: Array.from(new Set([...allOrdersList])),
        })
      );
      setCompleted(true);

      timer.current = setTimeout(() => toggleModal(), 5000);
    } catch (err) {
      console.error(err.response.data);
      setErrorMsg(err.response.data?.error ?? '');
    }
    setLoading(false);
  };

  return (
    <GeneralModal
      modalStatus={showModal}
      toggleModal={toggleModal}
      position="center"
      effect="move"
      overlay=""
      boxWidth="486px"
      className="bem-transfer-order-modal"
    >
      <div className="bem-transfer-order-modal__box">
        <div className="bem-transfer-order-modal__header">
          <h2 className="bem-transfer-order-modal__title">
            {completed ? 'Transfer Completed' : 'Transfer Delivery Order'} to{' '}
            {currentStore ?? 'Another Store'}
          </h2>
        </div>
        <div className="bem-transfer-order-modal__body">
          {!completed ? (
            <>
              <RadixSelectUI
                options={filteredStores}
                value={storeId}
                onValueChange={(value) => setStoreId(value)}
                containerClass={'bem-transfer-order-modal__select'}
              />
              <p className="bem-transfer-order-modal__desc">
                Once the order is transferred it will re-print at the new store
                and will be recorded in that stores Daily Sales.
              </p>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className="bem-transfer-order-modal__lottie-box">
                <Lottie
                  options={defaultOptions}
                  height={180}
                  width={180}
                  eventListeners={[
                    {
                      eventName: 'complete',
                      callback: () => {},
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
        <div className="bem-transfer-order-modal__footer">
          <div className="bem-transfer-order-modal__btn-group">
            {!completed ? (
              <>
                <ButtonUI
                  fullWidth
                  rounded="m"
                  styleType="secondary"
                  sizeType="l"
                  onClick={handleConfirmTransfer}
                  style={{ color: 'var(--successColor)' }}
                  loading={loading}
                >
                  Transfer Order
                </ButtonUI>
                <ButtonUI
                  fullWidth
                  rounded="m"
                  styleType="secondary"
                  sizeType="xxl"
                  onClick={toggleModal}
                  style={{ color: 'var(--primaryColor)' }}
                >
                  Cancel
                </ButtonUI>
              </>
            ) : (
              <>
                <ButtonUI
                  fullWidth
                  rounded="m"
                  styleType="secondary"
                  sizeType="xxl"
                  onClick={toggleModal}
                  style={{ color: 'var(--primaryColor)', maxWidth: 220 }}
                >
                  Dismiss
                </ButtonUI>
              </>
            )}
          </div>
          {errorMsg && (
            <p
              className="bem-transfer-order-modal__desc"
              style={{
                color: 'var(--dangerColor)',
                paddingTop: 20,
                fontSize: 16,
              }}
            >
              {errorMsg}
            </p>
          )}
        </div>
      </div>
    </GeneralModal>
  );
};

TransferOrderModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default TransferOrderModal;
