import useMenuItems from 'hooks/useMenuItems';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useModal } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import addDiscountToMenuItem from '../../../cartProcessor/addDiscountToMenuItem';
import addVoucherToMenuItem from '../../../cartProcessor/addVoucherToMenuItem';
import deleteItemFromOrder from '../../../cartProcessor/deleteItemFromOrder';
import updateItemToOrder from '../../../cartProcessor/updateItemToOrder';
import addItemToOrder from '../../../cartProcessor/addItemToOrder';
import updateHalfHalfToOrder from 'cartProcessor/updateHalfHalfToOrder';
import { performMergeToModify } from '../../../cartProcessor/utilities';
import voidItemFromOrder from '../../../cartProcessor/voidItemFromOrder';
import {
  reduceQuantityToOne,
  decrementItemQuantity,
} from '../../../itemProcessor';
import { CURRENCIES } from '../../../configuration/constants';
import { useVirtualKeyboardContext } from '../../../context/VirtualKeyboardContext';
import useRouteByMenuItemType from '../../../customHooks/useRouteByMenuItemType';
import ItemDiscountModal from '../../../modals/ItemDiscountModal';
import {
  ItemManualDiscountAmount,
  ItemManualPriceAmount,
} from '../../../modals/ItemManualAmount';
import ItemViewModal from '../../../modals/ItemViewModal';
import { getItemPrice } from '../../../orderEngine/utilities';
import {
  openMenuItem,
  resetCurrentHalfHalfData,
  resetItemActionsModalConfig,
  resetMenuItem,
  setHalfHalfItemsForModify,
  updateCurrentOrder,
  toggleBlurEffect,
  setShowPizzaActionButton,
} from '../../../pages/Dashboard/action';
import {
  activeSpecialsSelector,
  currencyCodeSelector,
  currencySymbolSelector,
  storeConfigSelector,
  orderSetupSelector,
  specialsSelector,
  productSetupSelector,
  suburbsSelector,
  publicHolidaysSelector,
  isAuthDisabledForPOSSelector,
} from '../../../pages/Dashboard/selectors';
import {
  ModalsCarouselSlideUI,
  ModalsCarouselUI,
  ModalWrapperUI,
} from '../../../UI/components';
import { isMacOSOrWindowsDevice } from '../../../util/isMacOSOrWindowsDevice';
import { isObjectNotEmpty } from '../../../util/utils';
import StaffPinModal from 'components/StaffPinModal';
import { ModalPortal } from 'components';
import VoidedReasonModal from './VoidedReasonModal';
import AccessDeniedModal from 'modals/AccessDeniedModal';

const initialModalPositionData = {
  show: false,
  statusBarOffset: isMacOSOrWindowsDevice() ? 21 : 0, // status bar,
  offset: 20, // bottom and top offset (empty space),
};

const CartItemActionsModalWrapper = ({ basePath }) => {
  const itemActionsModalConfig = useSelector(
    (s) => s.dashboardReducer.itemActionsModalConfig
  );
  const { show: showModal, menuItem, buttonData = {} } = itemActionsModalConfig;

  if (!showModal) return null;

  return (
    <CartItemActionsModal
      basePath={basePath}
      showModal={showModal}
      menuItem={menuItem}
      buttonData={buttonData}
    />
  );
};

const CartItemActionsModal = ({
  basePath,
  showModal,
  menuItem,
  buttonData,
}) => {
  const specials = useSelector(specialsSelector);
  const suburbs = useSelector(suburbsSelector);
  const publicHolidays = useSelector(publicHolidaysSelector);
  const orderSetup = useSelector(orderSetupSelector);
  const productSetup = useSelector(productSetupSelector);
  const currentMenuItem = useSelector(
    (s) => s.dashboardReducer.currentMenuItem
  );
  const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
  const menuItems = useMenuItems();
  const dispatch = useDispatch();
  const history = useHistory();
  const storeConfig = useSelector(storeConfigSelector);
  const currencySymbol = useSelector(currencySymbolSelector);
  const activeSpecials = useSelector(activeSpecialsSelector);
  const dineInCoursesMap = useSelector(
    (state) => state.dashboardReducer.dineInCourses?.dineInCoursesMap
  );
  const isAuthDisabledForPOS = useSelector(isAuthDisabledForPOSSelector);
  const [modalStatus, { openModal, closeModal }] = useModal('staffPinModal');
  const [wiggleEffect, setWiggleEffect] = useState(false);

  const [errorMessage, setErrorMessage] = useState(
    'You do not have a permission to delete an Item.'
  );
  const [accessDenied, setShowAccessDenied] = useState(false);
  const [showVoidedReason, setShowVoidedReason] = useState(false);

  const coursesSorted = useMemo(
    () =>
      Object.values(dineInCoursesMap ?? {})
        .filter((course) => course.isActive === true)
        .sort((a, b) => a.order - b.order),
    [dineInCoursesMap]
  );

  const [updatedItem, setUpdatedItem] = useState({});

  const [modalPositionData, setModalPositionData] = useState(
    initialModalPositionData
  );
  const [currentModal, setCurrentModal] = useState('');
  const [updatedPrice, setUpdatedPrice] = useState();

  const [voidedStaffId, setVoidedStaffId] = useState('');
  const [voidedStaffName, setVoidedStaffName] = useState('');

  const originalPrice = useRef();

  const updatedMenuItemRoute = useRouteByMenuItemType(basePath, updatedItem);

  const {
    getInputValue,
    toggleKeyboard,
    setInputName,
    onChangeInput,
    inputNames,
  } = useVirtualKeyboardContext();
  const inputName = inputNames.itemNotes;

  const currentMenuItemSelectedDiscount = useMemo(() => {
    if (updatedItem.isHalfHalf || updatedItem.isFourQuarters) {
      const currentHalfItem = updatedItem.items.find((item) => {
        return isObjectNotEmpty(item.selectedDiscount) && item.selectedDiscount;
      });
      return currentHalfItem && currentHalfItem.selectedDiscount;
    } else {
      return (
        isObjectNotEmpty(updatedItem.selectedDiscount) &&
        updatedItem.selectedDiscount
      );
    }
  }, [
    updatedItem.isFourQuarters,
    updatedItem.isHalfHalf,
    updatedItem.items,
    updatedItem.selectedDiscount,
  ]);
  const currentMenuItemSelectedVoucher = useMemo(() => {
    if (updatedItem.isHalfHalf || updatedItem.isFourQuarters) {
      const currentHalfItem = updatedItem.items.find((item) => {
        return isObjectNotEmpty(item.selectedVoucher) && item.selectedVoucher;
      });
      return currentHalfItem && currentHalfItem.selectedVoucher;
    } else {
      return (
        isObjectNotEmpty(updatedItem.selectedVoucher) &&
        updatedItem.selectedVoucher
      );
    }
  }, [
    updatedItem.isFourQuarters,
    updatedItem.isHalfHalf,
    updatedItem.items,
    updatedItem.selectedVoucher,
  ]);

  const handleNotesFocus = () => {
    setInputName(inputName);

    toggleKeyboard(true);
  };

  const handleNotesBlur = () => {
    // for half-half items add itemNotes and iteration to each item objects
    const notes = getInputValue('itemNotes');

    if (menuItem.isHalfHalf || menuItem.isFourQuarters) {
      setUpdatedItem({
        ...updatedItem,
        items: updatedItem.items?.map((it) => ({
          ...it,
          itemNotes: notes,
          iteration: currentOrder.iteration,
        })),
      });
    } else {
      setUpdatedItem({
        ...updatedItem,
        itemNotes: notes,
        iteration: currentOrder.iteration,
      });
    }
  };

  const handleNotesChange = (event) => {
    event.preventDefault();

    const inputVal = event.target.value;
    onChangeInput('itemNotes', inputVal);
  };

  useEffect(() => {
    if (menuItem?.itemNotes) {
      onChangeInput('itemNotes', menuItem?.itemNotes);
    }
    if (
      (menuItem?.isHalfHalf || menuItem?.isFourQuarters) &&
      menuItem.items[0].itemNotes
    ) {
      onChangeInput('itemNotes', menuItem.items[0].itemNotes);
    }
  }, [onChangeInput, menuItem]);

  const handleQuantityChange = (updatedMenuItem, actionType) => {
    const intialStateOfSelectedMenutItem = currentOrder?.menuItems.find(
      (item) =>
        item._id === updatedMenuItem._id &&
        item.iteration < currentOrder.iteration &&
        item.orderIndex === updatedMenuItem.orderIndex
    );

    // currentOrder?.iteration > 0 : mean its a Edit flow
    // updatedMenuItem.iteration < currentOrder?.iteration : mean its a old item not new item

    // Increment Item Quantity Flow
    if (
      currentOrder?.iteration > 0 &&
      updatedMenuItem.iteration < currentOrder?.iteration &&
      actionType === '+' &&
      intialStateOfSelectedMenutItem?.quantity < updatedMenuItem?.quantity
    ) {
      const selectedMenuItemWithUpdatedIteration = {
        ...reduceQuantityToOne(updatedMenuItem),
        iteration: currentOrder.iteration,
      };

      dispatch(
        updateCurrentOrder(
          addItemToOrder(
            currentOrder,
            selectedMenuItemWithUpdatedIteration,
            activeSpecials,
            orderSetup,
            productSetup,
            suburbs,
            publicHolidays,
            storeConfig
          )
        )
      );
    }
    // Decrement Item Quantity Flow
    else if (
      currentOrder?.iteration > 0 &&
      updatedMenuItem.iteration < currentOrder?.iteration &&
      actionType === '-'
    ) {
      const selectedMenu = currentOrder?.menuItems.find(
        (item) =>
          updatedMenuItem._id === item._id &&
          item.iteration === currentOrder.iteration
      );

      let newCurrentOrder = currentOrder;

      // If Qunatity is greater than one than decrement quantity by one and If Quantity is one than delete item from order
      if (selectedMenu?.quantity > 1) {
        newCurrentOrder = updateItemToOrder(
          currentOrder,
          decrementItemQuantity(selectedMenu),
          activeSpecials,
          orderSetup,
          productSetup,
          suburbs,
          publicHolidays,
          storeConfig
        );
      } else if (selectedMenu?._id) {
        newCurrentOrder = deleteItemFromOrder(
          currentOrder,
          selectedMenu,
          activeSpecials,
          orderSetup,
          productSetup,
          suburbs,
          publicHolidays,
          storeConfig
        );
      }
      dispatch(updateCurrentOrder(newCurrentOrder));
    }
    setUpdatedItem({ ...updatedMenuItem });
  };

  const handleApplyDiscount = (selectedDiscount) => {
    setCurrentModal('item-view');

    setUpdatedItem({ ...updatedItem, selectedDiscount });
  };

  const handleApplyVoucher = (selectedVoucher) => {
    setCurrentModal('item-view');

    setUpdatedItem({ ...updatedItem, selectedVoucher });
  };

  const handleApplyPrice = (selectedDiscount) => {
    setCurrentModal('item-view');

    if (selectedDiscount) {
      setUpdatedItem({
        ...updatedItem,
        iteration: currentOrder.iteration,
        selectedDiscount: { ...selectedDiscount, isPriceAdjusted: true },
      });
    } else {
      setUpdatedItem({ ...updatedItem, selectedDiscount: {} });
    }
  };

  const handleUpdateCurrentOrder = (newItem) => {
    let item = newItem ?? updatedItem;

    if (
      updatedItem?.iteration <= currentOrder?.prevIteration &&
      currentOrder?.iteration !== updatedItem?.iteration &&
      updatedItem?.quantity > menuItem?.quantity
    ) {
      item = {
        ...item,
        quantity: menuItem?.quantity,
        selectedSizes: menuItem?.selectedSizes,
        selectedModifiers: menuItem?.selectedModifiers,
      };
    }

    setCurrentModal('');
    let newCurrentOrder = currentOrder;
    const items = item.items;

    if (item.isHalfHalf || item.isFourQuarters) {
      newCurrentOrder = updateHalfHalfToOrder(
        currentOrder,
        items,
        activeSpecials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      );
    } else {
      // update menu item for order
      newCurrentOrder = updateItemToOrder(
        currentOrder,
        item,
        activeSpecials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      );
    }

    // add menu item discount and/or voucher to the order
    let orderParams = {
      currentOrder: newCurrentOrder,
      currentMenuItem: item,
      activeSpecials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig,
    };

    if (item.selectedDiscount) {
      orderParams = {
        ...orderParams,
        selectedDiscount: item.selectedDiscount,
      };

      // apply discount to the item
      newCurrentOrder = addDiscountToMenuItem(orderParams);
    }

    if (item.selectedVoucher) {
      orderParams = {
        ...orderParams,
        selectedVoucher: item.selectedVoucher,
      };

      // apply voucher to the item
      newCurrentOrder = addVoucherToMenuItem(orderParams);
    }

    if (
      newCurrentOrder?.orderType === '3' &&
      newCurrentOrder.menuItems.some((m) => m.courseId)
    ) {
      const currentMenuItems = newCurrentOrder.menuItems;

      const secondCourse = coursesSorted[1] ?? null;

      if (secondCourse) {
        for (let index in currentMenuItems) {
          const mItem = currentMenuItems[index];
          if (!mItem.courseId) {
            newCurrentOrder.menuItems[index]['courseId'] = secondCourse._id;
            newCurrentOrder.menuItems[index]['courseName'] = secondCourse.name;
            newCurrentOrder.menuItems[index]['iteration'] =
              currentOrder.iteration;
          }
        }
      }
    }

    dispatch(updateCurrentOrder(newCurrentOrder));

    if (!newItem) {
      handleResetActionsModalConfig();
    }
  };

  const handleClickClose = () => {
    toggleKeyboard(false);
    onChangeInput('itemNotes', '');
    setModalPositionData({ ...modalPositionData, show: false });

    handleUpdateCurrentOrder();
  };

  const handleModifyClick = () => {
    dispatch(setShowPizzaActionButton(false));
    history.push(updatedMenuItemRoute, location.state);

    handleResetActionsModalConfig(false);
  };

  const handleResetActionsModalConfig = (isReset = true) => {
    // reset the menu item modal data
    setUpdatedItem({});
    setModalPositionData();
    setCurrentModal();
    setUpdatedPrice();
    originalPrice.current = null;

    // reset the menu item modal
    dispatch(resetItemActionsModalConfig());

    if (isReset) {
      dispatch(resetCurrentHalfHalfData());
      dispatch(resetMenuItem());
    }
  };

  const handleClickDelete = () => {
    const newCurrentOrder = deleteItemFromOrder(
      currentOrder,
      updatedItem,
      specials,
      orderSetup,
      productSetup,
      suburbs,
      publicHolidays,
      storeConfig
    );
    dispatch(updateCurrentOrder(newCurrentOrder));

    handleResetActionsModalConfig();
  };

  const handleClickVoid = (isVoided) => {
    if (isVoided) {
      const newCurrentOrder = voidItemFromOrder(
        currentOrder,
        menuItem,
        specials,
        orderSetup,
        productSetup,
        suburbs,
        publicHolidays,
        storeConfig
      );

      dispatch(updateCurrentOrder(newCurrentOrder));
      dispatch(resetItemActionsModalConfig());
    } else {
      isAuthDisabledForPOS ? setShowVoidedReason(true) : openModal();
    }
  };

  const location = useLocation();

  const handleAddIngr = () => {
    history.push(
      `${basePath}/menuitem/${updatedItem._id}/customization/ingredient/add?returnHome`,
      location.state
    );
    handleResetActionsModalConfig(false);
  };

  const handleRemoveIngr = () => {
    history.push(
      `${basePath}/menuitem/${updatedItem._id}/customization/ingredient/remove?returnHome`,
      location.state
    );
    handleResetActionsModalConfig(false);
  };

  const handleRemoveDiscount = () => {
    setUpdatedItem({ ...updatedItem, selectedDiscount: {} });
    setCurrentModal('discount');
  };

  const openMenuItemToModify = useCallback(() => {
    dispatch(resetCurrentHalfHalfData());
    dispatch(resetMenuItem());

    // close possible selected menu item
    history.push(`${basePath}/order`, location.state);

    const originalMenuItems = getOriginalMenuItems(menuItem, menuItems);

    if (isObjectNotEmpty(menuItem) && originalMenuItems?.length > 0) {
      if (menuItem?.isHalfHalf) {
        const { halfIndex, selectedSizes } = menuItem.items[0];
        const { variants } = selectedSizes[0];
        let variantSelected =
          variants && variants.length > 0 ? variants[0]._id : '';
        dispatch(
          setHalfHalfItemsForModify(
            {
              items: [
                {
                  ...performMergeToModify(
                    menuItem.items[0],
                    originalMenuItems[0]
                  ),
                  halfIndex,
                  isHalf: true,
                  isSecondHalf: false,
                  orderIndex: menuItem.items[0].orderIndex,
                  activeSeatId: menuItem?.items[0]?.activeSeatId || undefined,
                  isSpecialItem: menuItem?.items[0]?.isSpecialItem,
                },
                {
                  ...performMergeToModify(
                    menuItem.items[1],
                    originalMenuItems[1]
                  ),
                  halfIndex,
                  isHalf: true,
                  isSecondHalf: true,
                  orderIndex: menuItem.items[1].orderIndex,
                  activeSeatId: menuItem?.items[0]?.activeSeatId || undefined,
                  isSpecialItem: menuItem?.items[0]?.isSpecialItem,
                },
              ],
            },
            halfIndex,
            selectedSizes[0],
            variantSelected
          )
        );
      } else {
        const newItem = {
          ...performMergeToModify(menuItem, originalMenuItems[0]),
          orderIndex: menuItem.orderIndex,
          itemNotes: menuItem.itemNotes || '',
          activeSeatId: menuItem?.activeSeatId || undefined,
          isSpecialItem: menuItem?.isSpecialItem,
        };
        dispatch(openMenuItem(newItem, true));
      }
    }
  }, [dispatch, history, basePath, menuItem, menuItems, location.state]);

  const saveOriginalPrice = useCallback(
    (menuItem) => {
      let price = '';

      if (menuItem.isHalfHalf || menuItem.isFourQuarters) {
        const { halfHalfOriginalPrice } = menuItem.items.reduce(
          (acc, halfHalfMenuItem) => {
            return {
              halfHalfOriginalPrice:
                halfHalfMenuItem.originalPrice ||
                halfHalfMenuItem.itemPrice + acc.halfHalfOriginalPrice,
            };
          },
          {
            halfHalfOriginalPrice: 0,
          }
        );
        price = halfHalfOriginalPrice;
      } else {
        price = getItemPrice(menuItem, productSetup).originalPrice;
      }

      return price;
    },
    [productSetup]
  );

  const handleCourseUpdate = (val) => {
    setUpdatedItem(val);
    handleUpdateCurrentOrder(val);
  };

  const handlePinComplete = (data) => {
    setVoidedStaffId(data.staffMember._id);
    setVoidedStaffName(
      `${data.staffMember.firstName} ${data.staffMember?.lastName || ''}`
    );
    setShowVoidedReason(true);
  };

  useEffect(() => {
    if (showModal && !modalPositionData?.show) {
      if (!originalPrice.current) {
        originalPrice.current = saveOriginalPrice(menuItem);
      }
      setUpdatedItem({ ...menuItem });
      openMenuItemToModify(menuItem);

      setModalPositionData({
        ...initialModalPositionData,
        show: true,
        topTrigger: buttonData.y,
        halfBthHeight: buttonData.height / 2,
        rightPos: buttonData.width - 50,
      });
    }
  }, [
    modalPositionData?.show,
    buttonData,
    menuItem,
    openMenuItemToModify,
    saveOriginalPrice,
    showModal,
  ]);

  if (!currentOrder?.menuItems?.length || !showModal) return null;

  return (
    <>
      <ModalWrapperUI show={showModal} handleClose={handleClickClose}>
        <ModalsCarouselUI
          show={showModal && modalPositionData?.show}
          modalPositionData={modalPositionData}
          setNextSlide={setCurrentModal}
          currentSlide={currentModal}
          handleClose={handleClickClose}
          enableArrow={true}
        >
          <ModalsCarouselSlideUI>
            <ItemViewModal
              id="item-view"
              updatedItem={updatedItem}
              currentMenuItem={currentMenuItem}
              currentOrder={currentOrder}
              originalPrice={originalPrice.current}
              inputName={inputName}
              currencySymbol={currencySymbol}
              handleQuantityChange={handleQuantityChange}
              handleModifyClick={handleModifyClick}
              handleAddIngr={handleAddIngr}
              handleNotesFocus={handleNotesFocus}
              handleNotesBlur={handleNotesBlur}
              handleNotesChange={handleNotesChange}
              handleRemoveDiscount={handleRemoveDiscount}
              handleClickDelete={handleClickDelete}
              handleClickVoid={handleClickVoid}
              handleClickClose={handleClickClose}
              handleRemoveIngr={handleRemoveIngr}
              setUpdatedItem={handleCourseUpdate}
              setManualPriceModalOpen={(newPrice) => {
                setUpdatedPrice(newPrice);
                setCurrentModal('manual-price');
              }}
              setDiscountModalOpen={() => {
                setCurrentModal('discount');
              }}
              showNotes={showModal}
              coursesSorted={coursesSorted}
            />
          </ModalsCarouselSlideUI>
          <ModalsCarouselSlideUI>
            <ItemDiscountModal
              id="discount"
              selectedDiscount={currentMenuItemSelectedDiscount}
              selectedVoucher={currentMenuItemSelectedVoucher}
              onBack={() => {
                setCurrentModal('item-view');
              }}
              onApply={handleApplyDiscount}
              onApplyVoucher={handleApplyVoucher}
              openManualDiscount={() => {
                setCurrentModal('manual-discount');
              }}
            />
          </ModalsCarouselSlideUI>
          <ModalsCarouselSlideUI>
            <ItemManualDiscountAmount
              id="manual-discount"
              originalPrice={originalPrice.current}
              onBack={() => {
                setCurrentModal('discount');
              }}
              onApply={handleApplyDiscount}
              currencySymbol={currencySymbol}
            />
          </ModalsCarouselSlideUI>
          <ModalsCarouselSlideUI>
            <ItemManualPriceAmount
              id="manual-price"
              price={updatedPrice}
              originalPrice={originalPrice.current}
              onApply={handleApplyPrice}
              onBack={() => setCurrentModal('item-view')}
              currencySymbol={currencySymbol}
              increaseAllowed={orderSetup.isManualPriceIncrease}
            />
          </ModalsCarouselSlideUI>
        </ModalsCarouselUI>
      </ModalWrapperUI>
      {modalStatus && (
        <ModalPortal>
          <StaffPinModal
            saveStaffData={false}
            modalStatus={modalStatus}
            onModalClose={closeModal}
            onComplete={handlePinComplete}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            wiggleEffect={wiggleEffect}
            setWiggleEffect={setWiggleEffect}
            setShowAccessDenied={setShowAccessDenied}
          />
        </ModalPortal>
      )}
      {accessDenied && (
        <ModalPortal>
          <AccessDeniedModal
            handleModalClose={setShowAccessDenied}
            message={errorMessage}
          />
        </ModalPortal>
      )}

      {showVoidedReason && (
        <ModalPortal>
          <VoidedReasonModal
            modalStatus={showVoidedReason}
            onModalClose={() => {
              dispatch(toggleBlurEffect(false));
              setShowVoidedReason(false);
            }}
            onSubmit={(voidedReasonData) => {
              const updatedMenuItem = {
                ...menuItem,
                ...voidedReasonData,
                voidedStaffId,
                voidedStaffName,
              };
              const newCurrentOrder = voidItemFromOrder(
                currentOrder,
                updatedMenuItem,
                specials,
                orderSetup,
                productSetup,
                suburbs,
                publicHolidays,
                storeConfig
              );

              dispatch(updateCurrentOrder(newCurrentOrder));
              dispatch(resetItemActionsModalConfig());
              dispatch(toggleBlurEffect(false));
            }}
          />
        </ModalPortal>
      )}
    </>
  );
};

export default CartItemActionsModalWrapper;

const getOriginalMenuItems = (updatedMenuItem, originalMenuItems) => {
  const newMenuItems = [];
  if (
    originalMenuItems !== null &&
    originalMenuItems !== undefined &&
    !originalMenuItems.length
  )
    return;
  if (updatedMenuItem?.isHalfHalf) {
    updatedMenuItem?.items?.forEach((menuItem) => {
      const org = originalMenuItems?.find((item) => item._id === menuItem._id);
      if (org) {
        const { selectedModifiers } = org;
        const newOriginalItem =
          selectedModifiers?.length === 1 &&
          Object.keys(selectedModifiers[0]).length === 0 &&
          selectedModifiers[0].constructor === Object
            ? { ...org, selectedModifiers: [] }
            : org;
        newMenuItems.push(newOriginalItem);
      }
    });
  } else {
    const org = originalMenuItems?.find(
      (item) => item._id === updatedMenuItem?._id
    );
    if (org) {
      const { selectedModifiers } = org;
      const newOriginalItem =
        selectedModifiers?.length === 1 &&
        Object.keys(selectedModifiers[0]).length === 0 &&
        selectedModifiers[0].constructor === Object
          ? { ...org, selectedModifiers: [] }
          : org;
      newMenuItems.push(newOriginalItem);
    }
  }
  return newMenuItems;
};
