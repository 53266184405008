import { useContext } from 'react';
import Context from '../context';

import State from '../state';

export const calcTotalPrice = (state) => {
  let total = 0;
  let ordersArr = [];
  state = State;
  const orders = state.orders;
  const seats = state.seats;

  for (let i = 0; i < seats.length; i++) {
    for (let j = 0; j < seats[i].orderItems.length; j++) {
      if (ordersArr.indexOf(seats[i].orderItems[j]) === -1) {
        ordersArr.push(seats[i].orderItems[j]);
      }
    }
    // if (ordersArr.indexOf(seats))
  }

  for (let i = 0; i < orders.length; i++) {
    if (ordersArr.indexOf(orders[i].id) !== -1) {
      total += orders[i].itemData.price * orders[i].quantity;
    }
  }

  return total;
};

function useTotalPrice() {
  const { state } = useContext(Context);

  return calcTotalPrice(state);
}

export default useTotalPrice;
