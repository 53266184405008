import { createContext, useReducer, useContext } from 'react';
import { useSelector } from 'react-redux';
import { orderTypeFilterSelector } from '../../../selectors';
import { filterNames, globalStateReducer, initGlobalState } from './reducer';

const OrdersGlobalStateContext = createContext();
const OrdersGlobalStateDispatchContext = createContext();

function mergeInitOrderTypeFilterFromRedux(
  initGlobalStateStatic,
  orderTypeFilterFromRedux
) {
  return {
    ...initGlobalStateStatic,
    filters: {
      ...initGlobalStateStatic.filters,
      [filterNames.orderType]: orderTypeFilterFromRedux,
    },
  };
}

export default function OrdersGlobalStateProvider({ children }) {
  const orderTypeFilterFromRedux = useSelector(orderTypeFilterSelector);
  const initState = mergeInitOrderTypeFilterFromRedux(
    initGlobalState,
    orderTypeFilterFromRedux
  );

  const [state, dispatch] = useReducer(globalStateReducer, initState);

  return (
    <OrdersGlobalStateContext.Provider value={state}>
      <OrdersGlobalStateDispatchContext.Provider value={dispatch}>
        {children}
      </OrdersGlobalStateDispatchContext.Provider>
    </OrdersGlobalStateContext.Provider>
  );
}

export function useOrdersGlobalState() {
  const context = useContext(OrdersGlobalStateContext);
  if (context === undefined) {
    throw new Error('useOrdersList must be used within a OrderListProvider');
  }

  return context;
}

export function useOrdersGlobalDispatch() {
  const context = useContext(OrdersGlobalStateDispatchContext);
  if (context === undefined) {
    throw new Error('useOrdersList must be used within a OrderListProvider');
  }

  return context;
}
