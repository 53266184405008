import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import GeneralModal from '../../modals/GeneralModal/index-new';
import Button from '../Button';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

const CustomerNameModal = ({
  modalStatus,
  onModalClose,
  onBackClick,
  onNextClick,
  isFetching,
}) => {
  const {
    keyboardVisibility,
    getInputValue,
    toggleKeyboard,
    onChangeInput,
    setInputName,
    onInitInput,
  } = useVirtualKeyboardContext();
  const customerName = getInputValue('customerName') || '';
  const inputRef = useRef(null);

  useEffect(() => {
    if (modalStatus) {
      setInputName('customerName');
      onInitInput('customerName', '');
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    } else {
      toggleKeyboard(false);
    }
  }, [modalStatus, onInitInput, toggleKeyboard, setInputName]);

  const handleFocus = useCallback(() => {
    toggleKeyboard(true);
  }, [toggleKeyboard]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      toggleKeyboard(false);
      onNextClick(customerName);
      // onNextClick(customerName);
    },
    [toggleKeyboard, onNextClick, customerName]
  );

  const handleChange = useCallback(
    (event) => {
      event.preventDefault();
      const inputVal = event.target.value;

      onChangeInput('customerName', inputVal);
    },
    [onChangeInput]
  );

  const handleNext = useCallback(() => {
    onNextClick(customerName);
  }, [customerName, onNextClick]);

  return (
    <GeneralModal
      style={{ borderRadius: 28 }}
      modalStatus={modalStatus}
      toggleModal={onModalClose}
      position="center"
      overlay="blur"
      boxWidth="435px"
      boxHeight="315px"
      className="customer-name-modal"
      backBtn="outside"
      backBtnFnc={onBackClick}
      keyboardVisibility={keyboardVisibility}
    >
      <div className="customer-name-modal-box">
        <div className="customer-name-modal-inner">
          <h2 className="customer-name-title">Enter Customer Name</h2>

          <form
            action=""
            className="customer-name-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="single-input-box">
              <label htmlFor="customer-name">Customer Name</label>
              <input
                id="customerName"
                name="customerName"
                className="input-style white big"
                value={customerName}
                onFocus={handleFocus}
                onChange={handleChange}
                ref={inputRef}
              />
            </div>
          </form>
        </div>

        <div className="customer-name-btn-box">
          <Button
            className="btn"
            title="Next"
            disabled={isFetching || !customerName}
            onClick={handleNext}
            enableThemeColor
          >
            Next
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

CustomerNameModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  onModalClose: PropTypes.func,
  hideModal: PropTypes.func,
  onBackClick: PropTypes.func,
  openSearchAddressModal: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default CustomerNameModal;
