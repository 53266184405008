import { FetchStatusEnum } from '../../configuration/constants';
import ActionTypes from './constants';

const initialState = {
  scrolledStatus: {
    id: 10,
    actionType: 'scrollUnassignedList',
    name: 'Unassigned List (drivers)',
    position: 0,
    class: '',
  },
  filterDrivers: [
    { id: 1, title: 'Drivers', type: '', to: '/drivers/all' },
    { id: 2, title: 'Batches', type: '', to: '/drivers/batches' },
    { id: 3, title: 'Deliveries', type: '', to: '/drivers/deliveries' },
    { id: 4, title: 'Map', type: '', to: '/drivers/map' },
  ],
  filteredDrivers: [],
  driverList: [],
  orderList: [],
  drivers: [],
  driverLocations: [],
  driverShifts: [],
  driverRuns: [],
  driverRunOrders: [],
  assignDriver: false,
  selectedDriverForDeliveries: null,
  selectedUnAssignedOrders: [],
  selectedUnAssignedOrdersForDeliveryBatches: [],

  currentDriver: null,
  orderFetchStatus: FetchStatusEnum.NOT_STARTED,
};

const driversReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.Initialize:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.FetchActiveDrivers:
      return { ...state, drivers: payload };
    case ActionTypes.FetchActiveDriverLocations:
      return {
        ...state,
        driverLocations: payload,
      };
    case ActionTypes.FetchActiveDriverShifts:
      return { ...state, driverShifts: payload };
    case ActionTypes.FetchActiveDriverRuns:
      return { ...state, driverRuns: payload };
    case ActionTypes.FetchActiveDriverRunOrders:
      return { ...state, driverRunOrders: payload };
    case ActionTypes.FetchDriverCashBalance:
      return { ...state, driverCashBalances: payload };
    case ActionTypes.toggleDeliveriesDriver:
      return {
        ...state,
        driverList: state.driverList.map((driver) => {
          if (driver.id === payload) {
            driver.checked = !driver.checked;
          } else {
            driver.checked = false;
          }

          return driver;
        }),
      };
    case ActionTypes.setAssignDriver:
      return {
        ...state,
      };
    case ActionTypes.toggleAssignDriver:
      return {
        ...state,
        assignDriver: payload,
        selectedDriverForDeliveries: !payload
          ? null
          : state.selectedDriverForDeliveries,
      };
    case ActionTypes.toggleSelectedUnAssignedOrder: {
      let orders = state.selectedUnAssignedOrders.filter(
        (o) => o._id !== payload._id
      );
      if (orders.length === state.selectedUnAssignedOrders.length) {
        orders.push(payload);
      }
      return {
        ...state,
        selectedUnAssignedOrders: orders,
      };
    }
    case ActionTypes.toggleSelectedUnAssignedOrdersForDeliveryBatches: {
      let orders = state.selectedUnAssignedOrdersForDeliveryBatches.filter(
        (o) => o._id !== payload._id
      );
      if (
        orders.length ===
        state.selectedUnAssignedOrdersForDeliveryBatches.length
      ) {
        orders.push(payload);
      }
      return {
        ...state,
        selectedUnAssignedOrdersForDeliveryBatches: orders,
      };
    }
    case ActionTypes.setActiveDriver:
      return {
        ...state,
        driverList: state.driverList.map((driver) => {
          if (driver._id === payload) {
            driver.active = true;
          } else {
            driver.active = false;
          }
          return driver;
        }),
      };
    case ActionTypes.setFilteredDrivers:
      return {
        ...state,
        filteredDrivers: payload,
      };
    case ActionTypes.setSelectedDriverForDeliveries:
      return {
        ...state,
        selectedDriverForDeliveries:
          state.selectedDriverForDeliveries?._id === payload?._id
            ? null
            : payload,
      };
    case ActionTypes.setCurrentDriver:
      return {
        ...state,
        currentDriver: payload,
      };
    case ActionTypes.resetSelectedOrdersAndDriver:
      return {
        ...state,
        selectedDriverForDeliveries: null,
        selectedUnAssignedOrders: [],
        selectedUnAssignedOrdersForDeliveryBatches: [],
        assignDriver: false,
      };
    case ActionTypes.resetSelectedUnAssignedOrdersForDeliveryBatches:
      return {
        ...state,
        selectedUnAssignedOrdersForDeliveryBatches: [],
      };
    case ActionTypes.setOrderList:
      return {
        ...state,
        orderList: payload,
      };
    default:
      return state;
  }
};

export default driversReducer;
