import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PosIcon } from './PosIcon';

export const PosIconShapeMan = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-shape-man': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="32"
        viewBox="0 0 33 32"
      >
        <title>shape_man</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M26.98 25.55c-1.102-2.081-3.673-3.183-5.754-4.040-1.469-0.612-1.469-1.224-1.469-1.714 0-0.612 0.245-1.347 0.735-1.714 1.224-1.102 1.714-2.571 1.714-4.163 0-2.938-1.959-5.877-5.51-5.877-3.306 0-5.51 2.449-5.51 5.877 0 1.714 0.612 3.183 1.714 4.163 0.49 0.367 0.735 1.224 0.735 1.714s0 1.102-1.592 1.837c-1.959 0.735-4.53 1.837-5.754 3.918-2.326-2.449-3.673-5.877-3.673-9.55 0-7.713 6.367-14.080 14.080-14.080s14.080 6.367 14.080 14.080c0 3.673-1.469 7.101-3.795 9.55zM7.268 26.407c0.857-1.959 3.309-2.938 5.391-3.795 1.96-0.857 2.205-1.959 2.205-2.938 0-0.735-0.245-1.837-1.103-2.571-0.857-0.735-1.347-1.837-1.347-3.183 0-2.326 1.347-4.653 4.288-4.653s4.288 2.449 4.288 4.653c0 1.347-0.367 2.449-1.347 3.306-0.612 0.612-1.103 1.592-1.103 2.571s0.245 2.081 2.328 2.938c1.962 0.857 4.41 1.837 5.146 3.795-2.45 2.204-5.76 3.551-9.434 3.551-3.553 0-6.862-1.347-9.312-3.673zM16.696 0.696c-8.448 0-15.304 6.856-15.304 15.304s6.856 15.304 15.304 15.304 15.304-6.856 15.304-15.304-6.856-15.304-15.304-15.304z"
        />
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness * 0.4174 : 0.4174}
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="20"
          d="M26.98 25.55c-1.102-2.081-3.673-3.183-5.754-4.040-1.469-0.612-1.469-1.224-1.469-1.714 0-0.612 0.245-1.347 0.735-1.714 1.224-1.102 1.714-2.571 1.714-4.163 0-2.938-1.959-5.877-5.51-5.877-3.306 0-5.51 2.449-5.51 5.877 0 1.714 0.612 3.183 1.714 4.163 0.49 0.367 0.735 1.224 0.735 1.714s0 1.102-1.592 1.837c-1.959 0.735-4.53 1.837-5.754 3.918-2.326-2.449-3.673-5.877-3.673-9.55 0-7.713 6.367-14.080 14.080-14.080s14.080 6.367 14.080 14.080c0 3.673-1.469 7.101-3.795 9.55zM7.268 26.407c0.857-1.959 3.309-2.938 5.391-3.795 1.96-0.857 2.205-1.959 2.205-2.938 0-0.735-0.245-1.837-1.103-2.571-0.857-0.735-1.347-1.837-1.347-3.183 0-2.326 1.347-4.653 4.288-4.653s4.288 2.449 4.288 4.653c0 1.347-0.367 2.449-1.347 3.306-0.612 0.612-1.103 1.592-1.103 2.571s0.245 2.081 2.328 2.938c1.962 0.857 4.41 1.837 5.146 3.795-2.45 2.204-5.76 3.551-9.434 3.551-3.553 0-6.862-1.347-9.312-3.673zM16.696 0.696c-8.448 0-15.304 6.856-15.304 15.304s6.856 15.304 15.304 15.304 15.304-6.856 15.304-15.304-6.856-15.304-15.304-15.304z"
        />
      </svg>
    </span>
  );
};

PosIconShapeMan.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
