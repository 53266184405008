export default function getColorByIndex(index) {
  let colors = [
    { id: '_0', color: 'green' },
    { id: '_1', color: 'orange' },
    { id: '_2', color: 'blue' },
    { id: '_3', color: 'yellow' },
    { id: '_4', color: 'green-light' },
    { id: '_5', color: 'pink' },
    { id: '_6', color: 'golden' },
    { id: '_7', color: 'blue-light' },
    { id: '_8', color: 'red' },
    { id: '_9', color: 'purple' },
  ];
  let color = 'green';
  let newIndex = 0;

  if (colors.length > 0) {
    newIndex =
      index.toString().length > 1
        ? Number(index.toString().charAt(index.toString().length - 1))
        : index;

    if (colors[newIndex] && colors[newIndex].color) {
      color = colors[newIndex].color;
    }
  }

  return color;
}
