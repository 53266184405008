import { _auth } from '../../../../firebase';

export const removeDriver = async (reqObj, nextOrderConfigURL) => {
  const firebaseAuth = _auth;
  const accessToken = await firebaseAuth.currentUser.getIdToken(true);
  const response = await fetch(`${nextOrderConfigURL}/deleteDelivery`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...reqObj,
    }),
  });
  const result = await response.json();
  return result;
};
