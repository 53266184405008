import React from 'react';

function IconInfo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      x="0px"
      y="0px"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M11.9,5.9C12.5,5.9,13,6.4,13,7s-0.5,1.1-1.1,1.1 S10.8,7.6,10.8,7S11.3,5.9,11.9,5.9z M14.4,17.5h-4.1c-0.3,0-0.5-0.3-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h1.5v-5.3h-1.4 c-0.3,0-0.5-0.3-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h1.9c0.3,0,0.5,0.2,0.5,0.5v5.9h1.5c0.3,0,0.5,0.2,0.5,0.5 C14.9,17.3,14.7,17.5,14.4,17.5z"></path>
    </svg>
  );
}

export default IconInfo;
