import { useContext } from 'react';
import { GlobalModalContext } from '.';
import GeneralModal from '../GeneralModal/index-new';
import GlobalModal from '../GlobalModal';

const GlobalModalWrapper = () => {
  const { modalContent, handleGlobalModal, modal } =
    useContext(GlobalModalContext);

  if (modal) {
    return (
      <GeneralModal
        modalStatus={modal}
        toggleModal={handleGlobalModal}
        position="center"
        effect="move"
        overlay=""
        boxWidth="487px"
        className="general-modal general-modal--theme-action grey-bg radius-big"
      >
        <GlobalModal {...modalContent} />
      </GeneralModal>
    );
  } else return null;
};

export default GlobalModalWrapper;
