import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconArrow = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-arrow': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.67 74.02">
        <title>Arrow</title>
        <g id="Regular-M">
          <path
            fill={iconColor ? iconColor : '#000'}
            stroke={iconColor ? iconColor : '#000'}
            strokeWidth={lineThickness ? lineThickness : 0}
            d="M0,37a4.48,4.48,0,0,0,1.51,3.27L33.94,72.66A4.46,4.46,0,0,0,37.11,74a4.16,4.16,0,0,0,4.3-4.2,4.26,4.26,0,0,0-1.22-3.07L29.25,55.62l-16.46-15,11.82.73H86.38A4.14,4.14,0,0,0,90.67,37a4.13,4.13,0,0,0-4.29-4.34H24.61l-11.77.73,16.41-15L40.19,7.28A4.39,4.39,0,0,0,41.41,4.2,4.17,4.17,0,0,0,37.11,0a4.37,4.37,0,0,0-3.27,1.46L1.51,33.74A4.5,4.5,0,0,0,0,37Z"
          />
        </g>
      </svg>
    </span>
  );
};

PosIconArrow.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
