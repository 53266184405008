import { combineReducers } from 'redux';
import dashboardReducer from './pages/Dashboard/reducer';
import orderItemReducer from './pages/Dashboard/orderItemReducer';
import ordersReducer from './pages/Orders/reducer';
import newOrdersReducer from './pages/Orders/newOrdersReducer';
import orderReducer from 'reducers/order';
import cashierReducer from './pages/Cashier/reducer';
import driversReducer from './pages/Drivers/reducer';
import tableArrangementReducer from './pages/TableArrangement/reducer';
import notificationsReducer from './notifications/reducer';
import serviceChargesReducer from './store/serviceCharges/serviceChargesReducer';
import storeWebsiteLoaderDataReducer from './store/storeWebsiteLoaderData/storeWebsiteLoaderDataReducer';
import modalsReducer from 'reducers/modals';
import batchOrdersReducer from './store/batchOrders/reducer';

const rootReducer = combineReducers({
  dashboardReducer,
  orderItemReducer,
  ordersReducer,
  tableArrangementReducer,
  cashierReducer,
  driversReducer,
  serviceChargesReducer,
  notificationsReducer,
  newOrdersReducer,
  orderReducer,
  modalsReducer,
  storeWebsiteLoaderDataReducer,
  batchOrdersReducer,
});

export default rootReducer;
