import classNames from 'classnames';
import { index_route } from 'constants/routesConsts';
import dayjs from 'dayjs';
import useIsKiosk from 'hooks/useIsKiosk';
import {
  setDisableSpecialDiscountModal,
  toggleOrderModal,
} from 'pages/Dashboard/action';
import { updateCurrentOrderWithOrderEngine } from 'pages/Dashboard/thunks';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { SingleOrderIcon } from '../components/Orders/SingleOrderIcon';
import orderEngine from '../orderEngine';
import actionTypes from '../pages/Dashboard/constants';
import { ScrollableBoxUI } from '../UI/components';
import GeneralModal from './GeneralModal';

const hasMobileNumberEntered = (mobile) => {
  return mobile && mobile !== 'xxx-xxx-xxx';
};

const ORDER_TYPES = ['walkin', 'pickup', 'delivery', 'dinein'];

export const OrderSideModal = memo(() => {
  const isKiosk = useIsKiosk();
  const orderType = useSelector(
    (state) => ORDER_TYPES[state.dashboardReducer.currentOrder?.orderType]
  );
  const orderSetup = useSelector((state) => state.dashboardReducer.orderSetup);
  const { pickUpTime, deliveryTime } = orderSetup;
  const modalStatus = useSelector(
    (state) => state.dashboardReducer.orderSideModal?.payload
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboardReducer || {});

  const toggleModal = (payload) => {
    dispatch(toggleOrderModal(payload));
  };

  const handleSwitchOrderType = (type) => {
    const pickupLandingURL = '/pickup/order';

    const deliveryLandingURL = hasMobileNumberEntered(
      dashboardState.currentOrder?.mobileNumber
    )
      ? '/delivery/customer/delivery-address/confirm'
      : '/delivery/customer/phone';
    const walkInLandingURL = '/walkin/order';
    const dineInLandingURL = dashboardState.currentOrder?.tableNumber
      ? '/dinein/order'
      : '/dinein/table/number';

    const orderURLs = {
      walkin: walkInLandingURL,
      pickup: pickupLandingURL,
      delivery: deliveryLandingURL,
      dinein: dineInLandingURL,
    };

    const currentOrderType = dashboardState?.currentOrder?.orderType;
    const newOrderType = Object.keys(orderURLs).indexOf(type).toString();

    let deliveryDate =
      dashboardState?.currentOrder?.deliveryDate ||
      dayjs().format('YYYY-MM-DD HH:mm');
    let deliveryDateTimeStamp =
      dashboardState?.currentOrder?.deliveryDateTimestamp || dayjs().valueOf();

    if (
      (currentOrderType === '1' || currentOrderType === '0') &&
      newOrderType === '2'
    ) {
      deliveryDate = dayjs(deliveryDate)
        .add(Number(deliveryTime) - Number(pickUpTime), 'm')
        .format('YYYY-MM-DD HH:mm');
      deliveryDateTimeStamp = dayjs(deliveryDate).valueOf();
    }

    if (
      currentOrderType === '2' &&
      (newOrderType === '1' || newOrderType === '0')
    ) {
      deliveryDate = dayjs(deliveryDate)
        .subtract(Number(deliveryTime) - Number(pickUpTime), 'm')
        .format('YYYY-MM-DD HH:mm');
      deliveryDateTimeStamp = dayjs(deliveryDate).valueOf();
    }

    const newCurrentOrder = orderEngine(
      {
        ...dashboardState.currentOrder,
        orderType: '' + Object.keys(orderURLs).indexOf(type),
        ...(orderType === 'dinein'
          ? {
              menuItems: dashboardState.currentOrder.menuItems.map((item) => ({
                ...item,
                courseId: null,
                courseName: null,
              })),
            }
          : {}),
      },
      dashboardState.specials,
      dashboardState.orderSetup,
      dashboardState.productSetup,
      dashboardState.suburbs,
      dashboardState.publicHolidays,
      dashboardState.storeConfig
    );

    dispatch({
      type: actionTypes.SWITCH_ORDER_TYPE,
      currentOrder: {
        ...newCurrentOrder,
        deliveryDate,
        deliveryDateTimeStamp,
      },
    });
    history.push(orderURLs[type]);
  };

  const handleClickSpecialDiscount = () => {
    if (dashboardState.currentOrder?.noSpecialDiscount) {
      const newCurrentOrder = {
        ...dashboardState.currentOrder,
        noSpecialDiscount: false,
      };
      dispatch(updateCurrentOrderWithOrderEngine(newCurrentOrder));
    } else {
      dispatch(setDisableSpecialDiscountModal(true));
    }
  };

  const shouldShowSpecialDiscountBtn =
    Number(dashboardState.currentOrder?.specialDiscount) > 0 ||
    dashboardState.currentOrder?.noSpecialDiscount;
  const specialDiscountBtnTitle = dashboardState.currentOrder?.noSpecialDiscount
    ? 'Enable Specials Discount'
    : 'Disable Specials Discount';

  const orderSideList = [
    {
      id: 1,
      title: 'Discount',
      icon: 'shape-man',
      actionType: '',
      onClick: () => {
        toggleModal(false);
        dispatch({
          type: actionTypes.toggleSplitDiscountModal,
          payload: true,
        });
      },
    },
    ,
    ...(!isKiosk && dashboardState.currentOrder?.menuItems?.length > 0
      ? [
          {
            id: v4(),
            title: 'Apply Service Charge',
            icon: '',
            actionType: '',
            onClick: () => {
              toggleModal(false);
              dispatch({ type: actionTypes.toggleSurchargeModal });
            },
          },
        ]
      : []),

    ...(!isKiosk && shouldShowSpecialDiscountBtn
      ? [
          {
            id: 2,
            title: specialDiscountBtnTitle,
            icon: '',
            actionType: '',
            onClick: () => {
              toggleModal(false);
              handleClickSpecialDiscount();
            },
          },
        ]
      : []),
    ...(!isKiosk
      ? ['walkin', 'pickup', 'delivery']
          .filter((i) => i !== orderType)
          .map((k, i) => ({
            id: 3 + i,
            title: `Change to ${k.replace('dinein', 'dine-in')}`,
            icon: '',
            actionType: '',
            onClick: () => {
              toggleModal(false);
              handleSwitchOrderType(k);
            },
          }))
      : []),

    ...(isKiosk
      ? [
          {
            id: v4(),
            title: 'Exit Kiosk',
            icon: 'close',
            actionType: '',
            onClick: () => {
              dispatch(toggleOrderModal(false));
              history.push(index_route);
            },
          },
        ]
      : []),
  ];

  const modalHeaderCls = classNames({
    'action-modal-header': true,
  });

  if (!modalStatus) return null;

  return (
    <>
      <GeneralModal
        modalStatus={modalStatus}
        toggleModal={(e, status) => toggleModal(status)}
        position="right-bottom"
        effect="move"
        overlay=""
        boxWidth="327px"
        boxHeight="calc(100%)"
        className="actions-modal radius-none"
        closeBtn="inside"
      >
        <div className="actions-modal-box">
          <div className={modalHeaderCls}>
            <h2 className="actions-modal-title">Actions </h2>
          </div>
          <div className="actions-modal-body">
            <ScrollableBoxUI>
              <ul className="sidebar-action-list">
                {orderSideList.map((action, i) => (
                  <li key={i}>
                    <button
                      type="button"
                      className="single-sidebar-action"
                      onClick={() => action.onClick()}
                      title={action.title}
                    >
                      <span className="inner-box">
                        <h3 className="sidebar-action-title">{action.title}</h3>
                      </span>
                      <span className="icon-box">
                        <SingleOrderIcon iconName={action.icon} />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </ScrollableBoxUI>
          </div>
        </div>
      </GeneralModal>
    </>
  );
});
