import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconReload = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos-icon pos-icon__reload': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 823 1000"
      >
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M411.5,177.5c-16.4,0-32.6,1-48.7,2.9l95.9-97.5c17.7-18,17.4-46.8-0.5-64.5l-5.4-5.3c-18-17.7-46.8-17.4-64.5,0.5  L196.6,208.4c-9.3,9.4-14.4,22.1-14.2,35.3c0.2,13.2,5.6,25.8,15.1,34.9l190.9,184.1c18.1,17.5,47,17,64.5-1.2l5.2-5.4  c17.5-18.1,17-47-1.2-64.5L344.8,283.4c21.8-4.7,44.1-7.2,66.7-7.2c172.3,0,312.5,140.2,312.5,312.5S583.8,901.2,411.5,901.2  C239.2,901.2,99,761,99,588.7c0-27.3-22.1-49.4-49.4-49.4S0.2,561.4,0.2,588.7c0,226.8,184.5,411.3,411.3,411.3  s411.3-184.5,411.3-411.3C822.8,361.9,638.3,177.5,411.5,177.5z"
        />
      </svg>
    </span>
  );
};

PosIconReload.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
