import sortBy from 'lodash/sortBy';

const getRowItems = (currentOrder) => {
  return sortBy(getItemsInCart(currentOrder), 'price').reverse();
};

function getItemsInCart(currentOrder) {
  let itemsInCart = [];
  let menuItems = currentOrder.menuItems
    ? JSON.parse(JSON.stringify(currentOrder.menuItems))
    : [];
  if (menuItems.length > 0) {
    menuItems
      .filter((menuItem) => !menuItem.isVoided)
      .map((m) => {
        // filter out voided
        if (m.selectedSizes && m.selectedSizes.length > 0 && m.isHalf) {
          m.selectedSizes.map((s) => {
            if (s.quantity) {
              let qan = Number(s.quantity);
              for (var p = 0; p < qan; p = p + 0.5) {
                let cartItem = {
                  primaryKey: itemsInCart.length + 1,
                  _id: m._id,
                  categoryId: m.categoryId,
                  name: m.name,
                  itemType: m.itemType,
                  baseModifierId: m.baseModifierId ? m.baseModifierId : '',
                  sizeKey: s._id,
                  halfIndex: '',
                  isHalf: true,
                  isSecondHalf: m.isSecondHalf,
                  price: Number(s.price),
                  selectedExtraIngredients: m.selectedExtraIngredients,
                  removedIngredients: m.removedIngredients,
                  orderIndex: m.orderIndex,
                  isSpecialItem: m.isSpecialItem,
                };
                itemsInCart.push(cartItem);
              }
            }
            return s;
          });
        } else if (
          m.selectedSizes &&
          m.selectedSizes.length > 0 &&
          m.isQuarter
        ) {
          m.selectedSizes.map((s) => {
            if (s.quantity) {
              let qan = Number(s.quantity);
              for (var p = 0; p < qan; p = p + 0.5) {
                let cartItem = {
                  primaryKey: itemsInCart.length + 1,
                  _id: m._id,
                  categoryId: m.categoryId,
                  name: m.name,
                  itemType: m.itemType,
                  baseModifierId: m.baseModifierId ? m.baseModifierId : '',
                  sizeKey: s._id,
                  halfIndex: '',
                  isHalf: false,
                  // isSecondHalf: m.isSecondHalf,
                  isQuarter: true,
                  isSecondQuarter: m.isSecondQuarter,
                  isThirdQuarter: m.isThirdQuarter,
                  isFourthQuarter: m.isFourthQuarter,
                  price: Number(s.price),
                  selectedExtraIngredients: m.selectedExtraIngredients,
                  removedIngredients: m.removedIngredients,
                  orderIndex: m.orderIndex,
                  isSpecialItem: m.isSpecialItem,
                };
                itemsInCart.push(cartItem);
              }
            }
            return s;
          });
        } else if (m.selectedSizes && m.selectedSizes.length > 0 && !m.isHalf) {
          m.selectedSizes.map((s) => {
            if (s.quantity) {
              let qan = Number(s.quantity);
              for (var p = 0; p < qan; p = p + 1) {
                let cartItem = {
                  primaryKey: itemsInCart.length + 1,
                  _id: m._id,
                  categoryId: m.categoryId,
                  name: m.name,
                  itemType: m.itemType,
                  baseModifierId: m.baseModifierId ? m.baseModifierId : '',
                  sizeKey: s._id,
                  halfIndex: '',
                  isHalf: false,
                  isSecondHalf: false,
                  price: Number(s.price),
                  selectedExtraIngredients: m.selectedExtraIngredients,
                  removedIngredients: m.removedIngredients,
                  orderIndex: m.orderIndex,
                  isSpecialItem: m.isSpecialItem,
                };
                itemsInCart.push(cartItem);
              }
            }
            return s;
          });
        }
        return m;
      });
  }
  return itemsInCart;
}

export default getRowItems;
