import { useVirtualKeyboardContext } from 'context/VirtualKeyboardContext';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import cellular from '../../assets/img/cellular_connection.png';
import { getCurrentTime, getCurrentShortenedDate } from '../../util/dateUtils';
import { setAcceptedCall, resetCurrentOrder } from 'pages/Dashboard/action';

const CallNotificationStatusBar = ({ id, phone, status, handleOnClick }) => {
  const { toggleKeyboard } = useVirtualKeyboardContext();
  const incomingCallsNotifications = useSelector(
    (state) => state.dashboardReducer.incomingCallsNotifications
  );
  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [currentShortenedDate, setCurrentShortenedDate] = useState(
    getCurrentShortenedDate()
  );
  const [totalSeconds, setTotalSeconds] = useState(0);
  const timer = useRef(null);
  const currentOrder = useSelector(
    (state) => state.dashboardReducer.currentOrder
  );
  const location = useLocation();
  const showEndCallButton =
    location.pathname === '/' || !!location.pathname.includes('orders');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime());
      setCurrentShortenedDate(getCurrentShortenedDate());
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setTotalSeconds(0);
    if (status !== 1) return;

    timer.current = setInterval(() => {
      setTotalSeconds((prev) => prev + 1);
    }, 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, [status]);

  useEffect(() => {
    const currentNotification = incomingCallsNotifications?.filter(
      (notification) => notification?.id === id
    );
    if (
      currentNotification?.length > 0 &&
      currentNotification[0]?.status === 2
    ) {
      dispatch(setAcceptedCall({}));

      !currentOrder.firstName && dispatch(resetCurrentOrder());
    }
  }, [JSON.stringify(incomingCallsNotifications)]);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return (
    <div className="call-notificaiton-status-bar">
      <div className="time-section">
        <span onClick={toggleKeyboard} className="status-time-box">
          <span className="time">{currentTime}</span>
          <span className="date">{currentShortenedDate}</span>
        </span>
      </div>
      <div className="call-container">
        <span className="on-call-label">On Call - </span>
        <span className="phone-number-section">
          {phone} - {`${minutes}`.padStart(2, '0')}:
          {`${seconds}`.padStart(2, '0')}
        </span>
        {showEndCallButton && (
          <button className="end-call-button" onClick={handleOnClick}>
            End Call
          </button>
        )}
      </div>
      <div className="batter-section">
        <img className="cellular-connection" src={cellular} alt="" />
      </div>
    </div>
  );
};

export default CallNotificationStatusBar;
