import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconLeftChevronSecond = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-left-chevron-second': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Слой_1"
        x="0px"
        y="0px"
        viewBox="0 0 47.8 84.8"
      >
        <g id="Regular-M" transform="matrix(1 0 0 1 1413.52 1126)">
          <path
            fill={iconColor ? iconColor : '#000'}
            stroke={iconColor ? iconColor : '#000'}
            strokeWidth={lineThickness ? lineThickness : 0}
            d="M-1413.5-1083.6c0,1.2,0.4,2.3,1.4,3.2l38.7,37.8c0.8,0.9,1.9,1.3,3.2,1.3c2.5,0,4.5-1.9,4.5-4.4c0-1.3-0.5-2.3-1.3-3.2   l-35.5-34.8l35.5-34.8c0.8-0.8,1.3-2,1.3-3.2c0-2.5-2-4.4-4.5-4.4c-1.3,0-2.3,0.4-3.2,1.3l-38.7,37.9   C-1413.1-1086-1413.5-1084.8-1413.5-1083.6z"
          />
        </g>
      </svg>
    </span>
  );
};

PosIconLeftChevronSecond.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
