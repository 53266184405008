import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CloseButtonCircleUI,
  ButtonUI,
  BackButtonCircleUI,
} from 'UI/components';
import GeneralModal from '../../../modals/GeneralModal/index-new';
import './CoursesModal.style.css';
import { HoldIcon, PlayIcon, NextIcon } from 'nexticons/solid';
import { LoadingIcon } from 'nexticons/outline';
import MenuItemList from '../MenuItemList';
import { generateColorCode } from '../../../util/utils';
import SendDropdown from '../SendDropdown';
import CourseMenuItemActionModal from '../CourseMenuItemActionModal';
import { updateCurrentOrder } from 'pages/Dashboard/action';
import {
  callCourses,
  updateMenuItemCourseStatus,
} from './CoursesModal.service';
import { PosIconLoading } from 'assets/icons/PosIconLoading';

const CoursesModal = ({
  show,
  toggleModal,
  currentOrder,
  seatsData,
  menuItemsWithCourseStatus,
  setMenuItemsWithCourseStatus,
}) => {
  const dispatch = useDispatch();
  const sendDropdownRef = useRef();
  const [dropdownRef, setDropdownRef] = useState(null);
  const [openSendDropdown, setOpenSendDropdown] = useState(false);
  const [openItemActionDropdown, setOpenItemActionDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [callCoursesApiLoading, setCallCoursesApiLoading] = useState(false);
  // Modal type can be rush | hold | ""
  const [modalType, setModalType] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateCourseStatusLoading, setUpdateCourseStatusLoading] =
    useState(false);
  const coursesMap = useSelector(
    (state) => state.dashboardReducer.dineInCourses?.dineInCoursesMap ?? {}
  );
  const accessToken = useSelector(
    (state) => state.dashboardReducer?.accessToken
  );

  const iconList = {
    hold: <HoldIcon strokeWidth={1.5} style={{ width: 16 }} color="white" />,
    rush: <NextIcon strokeWidth={1.5} style={{ width: 22 }} color="white" />,
  };

  const colorList = {
    hold: '#067aff',
    rush: '#fd6c00',
  };

  const activeCoursesSorted = (Object.values(coursesMap) || [])
    .filter((course) => course?.isActive)
    .sort((a, b) => a.order - b.order);

  useEffect(() => {
    const isCourseAssignedToAllMenuItem = menuItemsWithCourseStatus?.every(
      (item) => item?.courseId
    );

    if (!isCourseAssignedToAllMenuItem) {
      const updatedMenuItems = menuItemsWithCourseStatus?.map((item) => {
        const updatedItem = {
          ...item,
        };
        if (!item?.courseId) {
          updatedItem.courseId = activeCoursesSorted[0]?._id;
          updatedItem.courseName = activeCoursesSorted[0]?.name;
          updatedItem.iteration = currentOrder?.iteration;
        }
        return updatedItem;
      });
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          menuItems: updatedMenuItems,
        })
      );
    }
  }, []);

  useEffect(() => {
    const initialSelectedItems = menuItemsWithCourseStatus?.filter(
      (item) =>
        item?.courseStatus === modalType && !item?.isHalf && !item?.isQuarter
    );

    const initialHalfHalfSelectedItems = Object.values(
      menuItemsWithCourseStatus
        .filter((i) => i.quantity === 0.5 && i?.courseStatus === modalType)
        .reduce(
          (acc, k) => ({
            ...acc,
            [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
          }),
          {}
        )
    );

    const initialIsQuarterSelectedItems = Object.values(
      menuItemsWithCourseStatus
        .filter((i) => i.quantity === 0.25 && i?.courseStatus === modalType)
        .reduce(
          (acc, k) => ({
            ...acc,
            [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
          }),
          {}
        )
    );

    setSelectedItems([
      ...initialSelectedItems,
      ...initialHalfHalfSelectedItems,
      ...initialIsQuarterSelectedItems,
    ]);

    // setSelectedItems(initialSelectedItems);
  }, [modalType]);

  const onItemSelect = (item) => {
    if (Array.isArray(item)) {
      let isItemExists = false;
      let itemIndex = 0;
      const itemsList = [...selectedItems];

      selectedItems.forEach((selectedItem, index) => {
        if (Array.isArray(selectedItem)) {
          selectedItem.forEach((selectedItemInner) => {
            if (
              item.some(
                (item) => item.orderIndex === selectedItemInner.orderIndex
              )
            ) {
              isItemExists = true;
              itemIndex = index;
            }
          });
        }
      });

      if (isItemExists) {
        // REMOVE HALF/HALF or FOUR QUARTERS ITEM
        itemsList.splice(itemIndex, 1);
        setSelectedItems(itemsList);
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    } else {
      const itemAlreadyExist = selectedItems.find(
        (selectedItem) => selectedItem.orderIndex === item.orderIndex
      );

      if (itemAlreadyExist?.orderIndex >= 0) {
        setSelectedItems(
          selectedItems.filter(
            (selectedItem) => selectedItem.orderIndex !== item.orderIndex
          )
        );
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    }
  };

  const handleBackClick = () => {
    setModalType('');
  };
  const hanldeHoldClick = () => {
    setModalType('hold');
  };

  const hanldeRushClick = () => {
    setModalType('rush');
  };

  const handleHoldItems = async () => {
    setUpdateCourseStatusLoading(true);
    const selectedItemOrderIndex = selectedItems
      .map((item) => {
        if (Array.isArray(item)) {
          return item.map((item) => item.orderIndex);
        } else {
          return item.orderIndex;
        }
      })
      ?.flat();

    const updatedMenuItemsPayload = menuItemsWithCourseStatus?.map((item) => {
      if (selectedItemOrderIndex?.includes(item.orderIndex)) {
        return {
          id: item._id,
          orderIndex: item.orderIndex,
          courseStatus: 'hold',
          courseStatusSetAt: new Date().getTime(),
        };
      }

      return {
        id: item._id,
        orderIndex: item.orderIndex,
        courseStatus: item?.courseStatus || 'send',
        courseStatusSetAt: item?.courseStatusSetAt || new Date().getTime(),
      };
    });

    const updatedMenuItems = menuItemsWithCourseStatus?.map((item) => {
      if (selectedItemOrderIndex?.includes(item.orderIndex)) {
        return {
          ...item,
          courseStatus: 'hold',
          courseStatusSetAt: new Date().getTime(),
        };
      }

      return {
        ...item,
        orderIndex: item.orderIndex,
        courseStatus: item?.courseStatus || 'send',
        courseStatusSetAt: item?.courseStatusSetAt || new Date().getTime(),
      };
    });

    setMenuItemsWithCourseStatus(updatedMenuItems);

    if (currentOrder?.iteration === 0) {
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          menuItems: updatedMenuItems,
        })
      );
    } else {
      const res = await updateMenuItemCourseStatus(
        updatedMenuItemsPayload,
        currentOrder?._id
      );
    }

    setUpdateCourseStatusLoading(false);
    setModalType('');
  };

  const handleRushItems = async () => {
    setUpdateCourseStatusLoading(true);
    const selectedItemOrderIndex = selectedItems
      .map((item) => {
        if (Array.isArray(item)) {
          return item.map((item) => item.orderIndex);
        } else {
          return item.orderIndex;
        }
      })
      ?.flat();

    const updatedMenuItemsPayload = menuItemsWithCourseStatus?.map((item) => {
      if (selectedItemOrderIndex?.includes(item.orderIndex)) {
        return {
          id: item._id,
          orderIndex: item.orderIndex,
          courseStatus: 'rush',
          courseStatusSetAt: new Date().getTime(),
        };
      }

      return {
        id: item._id,
        orderIndex: item.orderIndex,
        courseStatus: item?.courseStatus || 'send',
        courseStatusSetAt: item?.courseStatusSetAt || new Date().getTime(),
      };
    });

    const updatedMenuItems = menuItemsWithCourseStatus?.map((item) => {
      if (selectedItemOrderIndex?.includes(item.orderIndex)) {
        return {
          ...item,
          courseStatus: 'rush',
          courseStatusSetAt: new Date().getTime(),
        };
      }

      return {
        ...item,
        courseStatus: item?.courseStatus || 'send',
        courseStatusSetAt: item?.courseStatusSetAt || new Date().getTime(),
      };
    });

    setMenuItemsWithCourseStatus(updatedMenuItems);
    if (currentOrder?.iteration === 0) {
      dispatch(
        updateCurrentOrder({
          ...currentOrder,
          menuItems: updatedMenuItems,
        })
      );
    } else {
      const res = await updateMenuItemCourseStatus(
        updatedMenuItemsPayload,
        currentOrder?._id
      );
    }
    setUpdateCourseStatusLoading(false);
    setModalType('');
  };

  const mapActionsMethod = {
    hold: handleHoldItems,
    rush: handleRushItems,
  };

  const handleSendCourses = async (courseId) => {
    let courseIds = [`${courseId}`];
    setCallCoursesApiLoading(true);
    setOpenSendDropdown(false);

    setCallCoursesApiLoading(false);

    let newCalledCoursesDetails = [];
    const updatedMenuItems = menuItemsWithCourseStatus?.map((item) => {
      if (item?.courseId === courseId) {
        return {
          ...item,
          courseStatus: 'send',
          courseStatusSetAt: new Date().getTime(),
        };
      }
      return {
        ...item,
      };
    });

    const updatedMenuItemsPayload = menuItemsWithCourseStatus?.map((item) => {
      if (item?.courseId === courseId) {
        return {
          id: item._id,
          orderIndex: item.orderIndex,
          courseStatus: 'send',
          courseStatusSetAt: new Date().getTime(),
        };
      }
      return {
        id: item._id,
        orderIndex: item.orderIndex,
        courseStatus: item?.courseStatus || 'send',
        courseStatusSetAt: item?.courseStatusSetAt || new Date().getTime(),
      };
    });
    const res = await updateMenuItemCourseStatus(
      updatedMenuItemsPayload,
      currentOrder?._id
    );

    const callCoursesResponse = await callCourses(
      accessToken,
      courseIds,
      currentOrder?._id
    );

    if (currentOrder?.calledCoursesDetails?.length > 0) {
      if (
        !!currentOrder?.calledCoursesDetails?.find(
          (calledCourseDetail) => calledCourseDetail?.courseId === courseId
        )?.courseId
      ) {
        newCalledCoursesDetails = currentOrder?.calledCoursesDetails?.map(
          (calledCourse) => {
            if (calledCourse?.courseId === courseId) {
              return {
                ...calledCourse,
                calledAt: new Date().getTime() / 1000,
              };
            } else {
              return {
                ...calledCourse,
              };
            }
          }
        );
      } else {
        newCalledCoursesDetails = [
          ...(currentOrder?.calledCoursesDetails || []),
          { courseId, calledAt: new Date().getTime() / 1000 },
        ];
      }
    } else {
      newCalledCoursesDetails = [
        ...(currentOrder?.calledCoursesDetails || []),
        { courseId, calledAt: new Date().getTime() / 1000 },
      ];
    }

    dispatch(
      updateCurrentOrder({
        calledCourses: [...(currentOrder?.calledCourses ?? []), courseId],
        calledCoursesDetails: newCalledCoursesDetails,
        menuItems: updatedMenuItems,
      })
    );
  };

  const renderMenuItems = () => {
    if (seatsData?.length > 0) {
      const sharedMenuItems = menuItemsWithCourseStatus?.filter(
        (item) => item?.activeSeatId === 'Shared'
      );
      return (
        <div>
          {sharedMenuItems?.length > 0 && (
            <div key={'Shared'}>
              <div
                key={'Shared'}
                className="course-seat-section-header"
                style={{
                  backgroundColor: generateColorCode('Shared'),
                }}
              >
                <span className="course-seat-section-header-span">
                  Shared Items
                </span>
              </div>
              <MenuItemList
                menuItems={sharedMenuItems || []}
                courses={activeCoursesSorted}
                modalType={modalType}
                onItemSelect={onItemSelect}
                selectedItems={selectedItems}
                setOpenItemActionDropdown={setOpenItemActionDropdown}
                openItemActionDropdown={openItemActionDropdown}
                setDropdownRef={setDropdownRef}
                setSelectedItem={setSelectedItem}
                calledCoursesDetails={currentOrder?.calledCoursesDetails || []}
              />
            </div>
          )}

          {seatsData.map((seat) => {
            const menuItemsPerSeat = menuItemsWithCourseStatus?.filter(
              (item) => item?.activeSeatId === seat.seatId
            );
            return (
              <div key={seat.seatId}>
                <div
                  key={seat.seatId}
                  className="course-seat-section-header"
                  style={{
                    backgroundColor: generateColorCode(seat.seatId),
                  }}
                >
                  <span className="course-seat-section-header-span">
                    {seat.seatName}
                  </span>
                </div>
                <MenuItemList
                  menuItems={menuItemsPerSeat || []}
                  courses={activeCoursesSorted}
                  modalType={modalType}
                  onItemSelect={onItemSelect}
                  selectedItems={selectedItems}
                  setOpenItemActionDropdown={setOpenItemActionDropdown}
                  openItemActionDropdown={openItemActionDropdown}
                  setDropdownRef={setDropdownRef}
                  setSelectedItem={setSelectedItem}
                  calledCoursesDetails={
                    currentOrder?.calledCoursesDetails || []
                  }
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <MenuItemList
          menuItems={menuItemsWithCourseStatus || []}
          courses={activeCoursesSorted}
          modalType={modalType}
          onItemSelect={onItemSelect}
          selectedItems={selectedItems}
          setOpenItemActionDropdown={setOpenItemActionDropdown}
          openItemActionDropdown={openItemActionDropdown}
          setDropdownRef={setDropdownRef}
          setSelectedItem={setSelectedItem}
          calledCoursesDetails={currentOrder?.calledCoursesDetails || []}
        />
      );
    }
  };
  return (
    <>
      <GeneralModal
        modalStatus={show}
        toggleModal={toggleModal}
        effect="fade"
        overlay={'default'}
        boxWidth="750px"
        boxHeight="600px"
        closeBtn="outside"
        isResponsiveHeight
      >
        <div className="courses-modal-header">
          {modalType !== '' ? (
            <BackButtonCircleUI onClick={handleBackClick} />
          ) : (
            <CloseButtonCircleUI onClick={toggleModal} />
          )}

          <div className="header-buttons-section">
            <span className="course-title"> Courses</span>
            <div className="courses-acttion-button">
              <ButtonUI
                styleType="secondary"
                fontWeight="regular"
                rounded="m"
                iconPosition="left"
                onClick={hanldeHoldClick}
                disabled={modalType !== '' || !currentOrder?._id}
                icon={
                  <HoldIcon
                    strokeWidth={1.5}
                    style={{ width: 16 }}
                    color="#067aff"
                  />
                }
              >
                Hold
              </ButtonUI>
            </div>
            <div className="courses-acttion-button">
              <ButtonUI
                styleType="secondary"
                fontWeight="regular"
                rounded="m"
                iconPosition="left"
                onClick={hanldeRushClick}
                disabled={modalType !== '' || !currentOrder?._id}
                icon={
                  <NextIcon
                    strokeWidth={1.5}
                    style={{ width: 22 }}
                    color="#fd6c00"
                  />
                }
              >
                Rush
              </ButtonUI>
            </div>
            <div className="courses-acttion-button">
              <ButtonUI
                ref={sendDropdownRef}
                styleType="secondary"
                fontWeight="regular"
                rounded="m"
                iconPosition="left"
                onClick={() => setOpenSendDropdown(!openSendDropdown)}
                disabled={
                  !currentOrder?._id ||
                  modalType !== '' ||
                  callCoursesApiLoading
                }
                icon={
                  callCoursesApiLoading ? (
                    <LoadingIcon
                      strokeWidth={1.5}
                      style={{ width: 16 }}
                      color="#5eb602"
                    />
                  ) : (
                    <PlayIcon
                      strokeWidth={1.5}
                      style={{ width: 16 }}
                      color="#5eb602"
                    />
                  )
                }
              >
                Send
              </ButtonUI>
            </div>
          </div>
        </div>

        <div
          style={{
            height: modalType === '' ? '475px' : '400px',
            overflow: 'auto',
            paddingBottom: '60px',
          }}
        >
          {renderMenuItems()}
        </div>

        {modalType !== '' && (
          <div className="course-modal-footer">
            <div className="course-modal-footer-button-container">
              <ButtonUI
                style={{
                  width: '60%',
                  height: '43px',
                  fontWeight: 500,
                  backgroundColor: colorList[modalType],
                  border: 'none',
                }}
                fontWeight="regular"
                rounded="m"
                iconPosition="right"
                icon={
                  updateCourseStatusLoading ? (
                    <PosIconLoading
                      mainColor="#ffffff"
                      size={21}
                      style={{ marginLeft: '8px' }}
                    />
                  ) : (
                    iconList[modalType]
                  )
                }
                onClick={mapActionsMethod[modalType]}
                disabled={updateCourseStatusLoading}
              >
                {`Update ${
                  modalType.charAt(0).toUpperCase() + modalType.slice(1)
                }`}
              </ButtonUI>
            </div>
          </div>
        )}
      </GeneralModal>
      {openSendDropdown && (
        <SendDropdown
          show={openSendDropdown}
          toggleModal={() => setOpenSendDropdown(!openSendDropdown)}
          sendDropdownRef={sendDropdownRef}
          courses={activeCoursesSorted}
          handleSendCourses={handleSendCourses}
        />
      )}
      {openItemActionDropdown && (
        <CourseMenuItemActionModal
          show={openItemActionDropdown}
          toggleModal={() => setOpenItemActionDropdown(!openItemActionDropdown)}
          dropdownRef={dropdownRef}
          menuItem={selectedItem}
          menuItemsWithCourseStatus={menuItemsWithCourseStatus}
          setMenuItemsWithCourseStatus={setMenuItemsWithCourseStatus}
        />
      )}
    </>
  );
};

export default CoursesModal;
