//Radix colors from column 9
const RADIX_COLORS = [
  'hsl(10 78.0% 54.0%)', // Tomato
  'hsl(322 65.0% 54.5%)', // Pink
  'hsl(252 56.0% 57.5%)', // Violet
  'hsl(190 95.0% 39.0%)', // Cyan
  'hsl(28 34.0% 51.0%)', // Brown
  'hsl(167 65.0% 66.0%)', // Mint
  'hsl(39 100% 57.0%)', // Amber
  'hsl(358 75.0% 59.0%)', // Red
  'hsl(272 51.0% 54.0%)', // Purple
  'hsl(206 100% 50.0%)', // Blue
  'hsl(131 41.0% 46.5%)', // Grass
  'hsl(193 98.0% 70.0%)', // Sky
  'hsl(53 92.0% 50.0%)', // Yellow
  'hsl(36 20.0% 49.5%)', // Bronze
  'hsl(336 80.0% 57.8%)', // Crimson
  'hsl(292 45.0% 51.0%)', // Plum
  'hsl(226 70.0% 55.5%)', // Indigo
  'hsl(173 80.0% 36.0%)', // Teal
  'hsl(24 94.0% 50.0%)', // Orange
  'hsl(36 20.0% 49.5%)', // Gold
];

export default RADIX_COLORS;
