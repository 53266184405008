import React, { useMemo, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ReadyOrderView from './ReadyOrderView';
import { ReadyTableView } from './ReadyTableView';
import classNames from 'classnames';
import { PosIconBagInverse } from '../../../assets/icons/PosIconBagInverse';
import { PosIconCall } from '../../../assets/icons/PosIconCall';
import { PosIconBell } from '../../../assets/icons/PosIconBell';
import { PosIconLoading } from '../../../assets/icons/PosIconLoading';
import { PosIconCheckmarkCircle } from '../../../assets/icons/PosIconCheckmarkCircle';
import notifyCustomerApi from '../../../pages/Orders/apis/notifyCustomerApi';

export const ReadyContentView = memo(() => {
  const [notifyStatus, setNotifyStatus] = React.useState('idle');
  const { orders, selectedOrderId } = useSelector(
    (state) => state.newOrdersReducer
  );

  const selectedOrder = useMemo(
    () => orders.allOrdersMap[selectedOrderId] || null,
    [selectedOrderId, orders.allOrdersMap]
  );

  const hasUserBeenNotified = useMemo(
    () => notifyStatus === 'resolved' || selectedOrder?.isUserNotified || false,
    [notifyStatus, selectedOrder]
  );

  const handleNotifyForPickup = useCallback(async () => {
    if (selectedOrder?.isUserNotified) return;
    setNotifyStatus('loading');
    await notifyCustomerApi(selectedOrder._id);
    setNotifyStatus('resolved');
  }, [selectedOrder]);

  const mainCls = useMemo(
    () =>
      classNames({
        'view-order-main': true,
        empty: !(selectedOrder && selectedOrder._id && selectedOrder.storeId),
      }),
    [selectedOrder]
  );

  return (
    <>
      <div className={mainCls} style={{ display: 'flex', flexFlow: 'column' }}>
        {selectedOrder && selectedOrder._id && selectedOrder.storeId ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 20px',
                marginBottom: '10px',
              }}
            >
              <h2 className="orders-customer-name-title">
                {selectedOrder.tableNumber
                  ? `Table ${selectedOrder?.tableNumber || ''}`
                  : selectedOrder.firstName + ' ' + selectedOrder.lastName}
              </h2>

              {selectedOrder?.refundStatus === 1 && (
                <div className="refundedContainer">
                  <div className="refundedTag">
                    <span className="refundedText">Refunded</span>
                  </div>
                </div>
              )}

              {selectedOrder?.orderStatus === '0' && (
                <div className="refundedContainer">
                  <div className="refundedTag">
                    <span className="refundedText">Unconfirmed</span>
                  </div>
                </div>
              )}

              {selectedOrder.mobileNumber ? (
                <div className="orders-customer-mobile-container">
                  <div className="orders-customer-mobile">
                    <PosIconCall
                      mainColor="#5eb602"
                      className="orders-customer-mobile-icon"
                    />
                    {selectedOrder.mobileNumber}
                  </div>
                  {selectedOrder.orderStatus !== '11' &&
                    selectedOrder.orderType === '1' && (
                      <div
                        className={`orders-customer-ready-for-pickup ${
                          hasUserBeenNotified ? 'hasBeenNotified' : ''
                        }`}
                        onClick={handleNotifyForPickup}
                      >
                        {notifyStatus === 'idle' && !hasUserBeenNotified && (
                          <PosIconBell
                            mainColor="#ffffff"
                            className="orders-customer-mobile-icon"
                          />
                        )}
                        {notifyStatus === 'loading' && (
                          <PosIconLoading
                            size="20"
                            mainColor="#ffffff"
                            className="orders-customer-mobile-icon"
                          />
                        )}
                        {hasUserBeenNotified && (
                          <PosIconCheckmarkCircle
                            size={24}
                            mainColor="#ffffff"
                            className="orders-customer-mobile-icon"
                          />
                        )}
                        {hasUserBeenNotified ? `SMS Sent!` : 'Ready for Pickup'}
                      </div>
                    )}
                </div>
              ) : null}
            </div>

            {selectedOrder.deliveryCode || selectedOrder.pickupId ? (
              <ReadyOrderView selectedOrderId={selectedOrderId} />
            ) : (
              <ReadyTableView selectedOrder={selectedOrder} />
            )}
          </>
        ) : (
          <div className="view-order-main-empty">
            <PosIconBagInverse mainColor="#d1d1d1" darkModeColor="#d1d1d1" />
            <p className="text">Select an Order to view</p>
          </div>
        )}
      </div>
    </>
  );
});
