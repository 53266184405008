import { validateVoucher } from '../util/voucherHelper';
import { sortBy } from 'lodash';
import { getDayFlag, getCurrentDayFlag } from '../util/timeHelper';
import detectSpecial from './detectSpecial';
import {
  getItemPriceForSpecialDiscount,
  getSpecialDetectionCount,
  detectMergeRequired,
} from './utilities';

const _setSpecialFrames = (voucher, specialFrames) => {
  let x = {
    _id: voucher._id,
    name: voucher.voucherCode,
    type: voucher.type,
    isAvailablePickup: voucher.isAvailablePickup,
    isAvailableDelivery: voucher.isAvailableDelivery,
    isComplete: false,
    slots: [],
  };
  voucher.selectedCategories?.forEach((sp) => {
    new Array(Number(sp.quantity)).fill().forEach((_m, i) => {
      let u = {
        categoryKeys: sp.categories.filter((s) => s.isActive).map((s) => s._id),
        sizeKeys:
          sp.menuSizes?.filter((s) => s.isActive).map((s) => s._id) || [],
        modifierKeys:
          sp.subModifiers?.filter((s) => s.isActive).map((s) => s._id) || [],
        menuItems: sp.menuItems.filter((s) => !s.isActive).map((s) => s._id),
        id: i + 1,
        isSlotOccupied: false,
      };
      x.slots.push(u);
    });
  });
  x.slotLength = x.slots.length;
  specialFrames.push(x);
};

const getVoucherDiscount = (
  currentOrder,
  payableAmount,
  rowItems,
  specialDiscount,
  allSpecials,
  orderSetup,
  publicHolidays,
  storeConfig
) => {
  let matchingVouchers = [];
  if (currentOrder?.voucherId && currentOrder?.menuItems?.length) {
    let myVouchers = currentOrder.selectedVoucher
      ? [currentOrder.selectedVoucher]
      : [];

    matchingVouchers = myVouchers.filter((v) => {
      return v._id === currentOrder.voucherId;
    });

    if (matchingVouchers?.length) {
      let selectedVoucher = matchingVouchers[0];
      if (
        validateVoucher(
          selectedVoucher,
          currentOrder,
          payableAmount,
          storeConfig
        ).success
      ) {
        let voucherDiscountObj = getDiscountedAmount(
          selectedVoucher,
          currentOrder,
          rowItems,
          specialDiscount,
          allSpecials,
          orderSetup,
          publicHolidays,
          storeConfig
        );
        if (voucherDiscountObj.isVoucherValid) {
          return {
            voucherFound: true,
            voucherDiscountObj: voucherDiscountObj,
            selectedVoucher: selectedVoucher,
          };
        } else {
          return {
            voucherFound: false,
          };
        }
      } else {
        return {
          voucherFound: false,
        };
      }
    } else {
      return {
        voucherFound: false,
      };
    }
  } else {
    return {
      voucherFound: false,
    };
  }
};

function getDiscountedAmount(
  selectedVoucher,
  currentOrder,
  rowItems,
  specialDiscount,
  allSpecials,
  orderSetup,
  publicHolidays,
  storeConfig = {}
) {
  let resObj = {
    discount: 0,
    isVoucherValid: false,
    isItemOccupiedWithSpecials: false,
  };
  if (
    currentOrder &&
    (selectedVoucher?.type === '2' || selectedVoucher?.type === '1')
  ) {
    resObj.isVoucherValid = true;
    if (selectedVoucher.voucherType === '1') {
      resObj.discount = (
        (Number(currentOrder.payableAmount) *
          Number(selectedVoucher.discount)) /
        100
      ).toFixed(2);
      return resObj;
    } else {
      resObj.discount = selectedVoucher.discount;
      return resObj;
    }
  } else if (currentOrder && selectedVoucher?.type === '3') {
    let discountWithoutFlag = detectPersonalizedSpecial(
      currentOrder,
      selectedVoucher,
      rowItems,
      false,
      storeConfig
    );
    if (!selectedVoucher.isAvailableWithSpecials) {
      let discountFlag = detectPersonalizedSpecial(
        currentOrder,
        selectedVoucher,
        rowItems,
        true,
        storeConfig
      );
      if (
        discountWithoutFlag.voucherDiscount !== 0 &&
        discountFlag.voucherDiscount === 0
      ) {
        rowItems = sortBy(
          rowItems.filter((f) => {
            return !discountWithoutFlag.usedIndexes.includes(f.primaryKey);
          }),
          'price'
        ).reverse();
        let specialObj = detectSpecial(
          currentOrder,
          rowItems,
          allSpecials,
          orderSetup,
          publicHolidays,
          storeConfig
        );

        let specials = specialObj.specials;
        //Calculate special's discount
        let discount = 0;
        specials.map((s) => {
          discount = discount + Number(s.discount);
          return s;
        });
        if (
          Number(specialDiscount) - discount >
          discountWithoutFlag.voucherDiscount
        ) {
          resObj.isItemOccupiedWithSpecials = false;
          resObj.isVoucherValid = false;
          resObj.discount = discountFlag.voucherDiscount;
        } else {
          resObj.isItemOccupiedWithSpecials = true;
          resObj.isVoucherValid = true;
          resObj.specials = specials;
          resObj.discount = discountWithoutFlag.voucherDiscount;
        }
      } else {
        resObj.discount = discountFlag.voucherDiscount;
        resObj.isVoucherValid = true;
      }
    } else {
      resObj.isVoucherValid = true;
      resObj.discount = discountWithoutFlag.voucherDiscount;
    }
    return resObj;
  } else return resObj;
}

function detectPersonalizedSpecial(
  currentOrder,
  voucher,
  rowItems,
  filterOutUsedItems,
  storeConfig = {}
) {
  window.itemsUsed = [];
  let specialItemsObj = [];
  let specialFrames = getPersonalizedSpecialFrames(
    currentOrder,
    voucher,
    storeConfig
  );
  if (filterOutUsedItems) {
    rowItems = rowItems.filter((s) => {
      return !s.itemsUsedInSpecials;
    });
  }
  let voucherDiscountObj = { voucherDiscount: 0, usedIndexes: [] };
  if (rowItems?.length && specialFrames?.length) {
    getSpecialDetectionCount(specialFrames[0], rowItems, 0, specialItemsObj);
    let detectedSpecials = [...specialItemsObj];
    if (detectedSpecials.length) {
      let finalSpecial = detectedSpecials[0];
      let itemsToBeAddedInSpecials = [];
      finalSpecial.slots.forEach((ss) => {
        let rowMenuItems = [...currentOrder.menuItems];
        let filteredItems = [];
        if (ss.isHalf) {
          filteredItems = rowMenuItems.filter(
            (ff) =>
              ff._id === ss.itemId &&
              ff.isSecondHalf === false &&
              ff.orderIndex === ss.orderIndex
          );
        } else {
          filteredItems = rowMenuItems.filter(
            (ff) => ff._id === ss.itemId && ff.orderIndex === ss.orderIndex
          );
        }
        if (filteredItems?.length) {
          filteredItems.forEach((ftmm) => {
            let ttml = { ...ftmm };
            if (detectMergeRequired(ss, ttml)) {
              ttml.selectedSizes = ttml.selectedSizes.filter((ggm) => {
                return ss.sizeKeyMatched === ggm._id;
              });
              if (ttml.selectedSizes?.length) {
                ttml.selectedSizes[0].quantity = ttml.isHalf ? 0.5 : 1;
                if (ttml.isHalf) {
                  let othersHalf = rowMenuItems.find(
                    (m) =>
                      m.halfIndex === ttml.halfIndex && m.isSecondHalf === true
                  );
                  if (othersHalf) {
                    ttml.urlHalf = othersHalf.urlS3;
                    ttml._idHalf = othersHalf._id;
                    ttml.nameHalf = othersHalf.name;
                    ttml.selectedSizesHalf = [...othersHalf.selectedSizes];
                    ttml.selectedIngredientsHalf = [
                      ...othersHalf.selectedIngredients,
                    ];
                    ttml.removedIngredientsHalf = [
                      ...othersHalf.removedIngredients,
                    ];
                    ttml.selectedExtraIngredientsHalf = [
                      ...othersHalf.selectedExtraIngredients,
                    ];
                    ttml.selectedSizesHalf = othersHalf.selectedSizes.filter(
                      (ggm) =>
                        ss.sizeKeys.indexOf(ggm._id) > -1 ||
                        ss.modifierKeys.indexOf(ggm._id) > -1
                    );
                    ttml.selectedSizesHalf[0].quantity = 0.5;
                  }
                }
                itemsToBeAddedInSpecials.push(ttml);
              }
            }
          });
        }
        voucherDiscountObj.usedIndexes.push(ss.primaryKey);
      });
      if (itemsToBeAddedInSpecials?.length) {
        let totalCost = 0;
        // calculate the total cost of the items
        totalCost = itemsToBeAddedInSpecials.reduce((acc, item) => {
          let itemPrice = getItemPriceForSpecialDiscount(item);
          totalCost = totalCost + Number(itemPrice);
          return totalCost;
        }, 0);
        voucherDiscountObj.voucherDiscount =
          totalCost - Number(voucher.discountedPrice);
      }
    }
  }
  return voucherDiscountObj;
}

function getPersonalizedSpecialFrames(currentOrder, voucher, storeConfig) {
  let specialFrames = [];
  if (
    (currentOrder.orderType === '1' && voucher.isAvailablePickup) ||
    (currentOrder.orderType === '2' && voucher.isAvailableDelivery) ||
    currentOrder.orderType === '0' ||
    currentOrder.orderType === '3'
  ) {
    if (currentOrder.deliveryDate) {
      if (
        voucher.hasOwnProperty(
          getDayFlag(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').dayText
        ) &&
        voucher[
          getDayFlag(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm').dayText
        ] === true
      ) {
        _setSpecialFrames(voucher, specialFrames);
      }
    } else {
      if (
        voucher.hasOwnProperty(
          getCurrentDayFlag(storeConfig.timeZone)?.dayText
        ) &&
        voucher[getCurrentDayFlag(storeConfig.timeZone)?.dayText] === true
      ) {
        _setSpecialFrames(voucher, specialFrames);
      }
    }
  }
  return specialFrames;
}

export default getVoucherDiscount;
