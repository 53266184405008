import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconOrderPrice = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-order-price': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="32"
        viewBox="0 0 28 32"
      >
        <title>order-price</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M19.091 19.2h-14v-1.28h14zM17.819 23.040h-11.455v-1.28h11.453zM26.74 28.16h-19.103c-0.035 0.987-0.415 1.878-1.022 2.564l0.004-0.004h17.575c1.407 0 2.547-1.147 2.547-2.56zM7 26.88h15.909v-25.6h-21.636v26.88c0.004 1.407 1.139 2.548 2.544 2.56h0.254c1.302-0.18 2.293-1.285 2.293-2.622 0-0.028-0-0.056-0.001-0.084l0 0.004v-0.499c0-0.353 0.284-0.64 0.636-0.64zM28 27.52v0.64c0 2.12-1.709 3.84-3.819 3.84h-20.363c-2.11-0.006-3.819-1.718-3.819-3.829 0-0.004 0-0.008 0-0.011v0.001-27.52c0-0.353 0.285-0.64 0.636-0.64h22.911c0.352 0 0.636 0.287 0.636 0.64v26.24h3.183c0.351 0 0.636 0.287 0.636 0.64zM14 11.52c0-0.707-0.569-1.28-1.273-1.28h-1.272c-1.405 0-2.547-1.147-2.547-2.56s1.141-2.56 2.547-2.56v-1.28h1.272v1.28c1.407 0 2.547 1.147 2.547 2.56h-1.273c0-0.707-0.569-1.28-1.273-1.28h-1.272c-0.704 0.002-1.273 0.572-1.273 1.276 0 0.001 0 0.003 0 0.004v-0c0 0.707 0.571 1.28 1.273 1.28h1.272c1.407 0 2.547 1.147 2.547 2.56s-1.14 2.56-2.547 2.56v1.28h-1.272v-1.28c-1.405 0-2.547-1.147-2.547-2.56h1.273c0 0.707 0.571 1.28 1.273 1.28h1.272c0.704 0 1.273-0.573 1.273-1.28z"
        />
      </svg>
    </span>
  );
};

PosIconOrderPrice.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
