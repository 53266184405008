import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { PosIconClose } from '../../assets/icons/PosIconClose';
import Button from '../../components/Button';
import { PosIconLeftChevron } from '../../assets/icons/PosIconLeftChevron';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBlurEffect } from '../../pages/Dashboard/action';

const GeneralModal = ({
  children,
  modalStatus,
  toggleModal,
  position,
  effect,
  overlay,
  boxWidth,
  boxHeight,
  className,
  closeBtn,
  backBtn,
  backBtnFnc,
  errorEffect,
}) => {
  const dispatch = useDispatch();
  const blurEffect = useSelector((state) => state.dashboardReducer.blurEffect);
  const modalRef = useRef(null);
  const modalStatusRef = useRef(false);

  useEffect(() => {
    if (modalStatus && !modalStatusRef.current) {
      modalRef.current.classList.add('show');
      modalStatusRef.current = true;
      if (overlay === 'blur' && !blurEffect) {
        dispatch(toggleBlurEffect(true));
      }
    }

    if (!modalStatus && modalStatusRef.current) {
      modalStatusRef.current = false;
      if (modalRef.current && blurEffect) {
        modalRef.current.classList.add('hide-anim');
        dispatch(toggleBlurEffect(false));
      }

      setTimeout(() => {
        modalRef.current?.classList?.remove('hide-anim');
        modalRef.current?.classList?.remove('show');
      }, 350);
    }
    // these lines makes the screen blank out when staff pin modal is opened for second time
    // return () => {
    //   clearTimeout(timeout);
    // };
  }, [modalStatus, overlay, blurEffect, dispatch]);

  useEffect(() => {
    if (errorEffect) {
      modalRef.current?.classList?.add('error');
    } else {
      modalRef.current?.classList?.remove('error');
    }
  }, [errorEffect]);

  const modalCls = clsx({
    generalModal: true,
    [`${className}`]: className,
    [`${position}`]: position,
    [`${effect}-effect`]: effect,
    'default-overlay': overlay !== 'blur',
  });

  const handleClick = (e) => {
    if (backBtn && typeof backBtnFnc === 'function') {
      backBtnFnc();
    } else {
      toggleModal(e, false);
    }
  };

  return (
    <div className={modalCls} ref={modalRef}>
      {closeBtn === 'outside' || backBtn === 'outside' ? (
        <Button
          className="generalModal-close-btn outside"
          title="Close"
          onClick={handleClick}
        >
          {closeBtn === 'outside' ? (
            <PosIconClose
              mainColor="#363636"
              lineThickness={1.5}
              darkModeColor="#ffffff"
            />
          ) : backBtn === 'outside' ? (
            <PosIconLeftChevron mainColor="#363636" darkModeColor="#ffffff" />
          ) : (
            ''
          )}
        </Button>
      ) : (
        ''
      )}
      <div
        className="generalModal-wrap"
        style={{
          width: boxWidth,
          height: boxHeight,
        }}
      >
        <div className="generalModal-innerWrap">
          {closeBtn === 'inside' || backBtn === 'inside' ? (
            <Button
              className="generalModal-close-btn inside"
              title="Close"
              onClick={handleClick}
            >
              {closeBtn === 'inside' ? (
                <PosIconClose
                  mainColor="#363636"
                  lineThickness={1.5}
                  darkModeColor="#ffffff"
                />
              ) : backBtn === 'inside' ? (
                <PosIconLeftChevron
                  mainColor="#363636"
                  darkModeColor="#ffffff"
                />
              ) : (
                ''
              )}
            </Button>
          ) : (
            ''
          )}
          <div className="generalModal-box">{children}</div>
        </div>
      </div>
      <div
        className="generalModal-overlay"
        onClick={(e) => toggleModal(e, false)}
      />
    </div>
  );
};

GeneralModal.propTypes = {
  children: PropTypes.any,
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  position: PropTypes.string,
  effect: PropTypes.string,
  overlay: PropTypes.string,
  boxWidth: PropTypes.string,
  boxHeight: PropTypes.string,
  className: PropTypes.any,
  errorEffect: PropTypes.bool,
  closeBtn: PropTypes.string,
  backBtn: PropTypes.string,
  backBtnFnc: PropTypes.func,
};

GeneralModal.defaultProps = {
  children: '',
  modalStatus: false,
  toggleModal: () => {},
  position: 'center',
  effect: 'move',
  overlay: 'blur',
  boxWidth: '400px',
  boxHeight: '600px',
  className: '',
  errorEffect: false,
  closeBtn: '',
  backBtn: '',
  backBtnFnc: undefined,
};

export default GeneralModal;
