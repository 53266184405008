import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconSilverware = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-silverware': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 25 25"
      >
        <g>
          <path
            fill={iconColor ? iconColor : '#000'}
            stroke={iconColor ? iconColor : '#000'}
            strokeWidth={lineThickness ? lineThickness : 0}
            d="M10,0v4.5c0,0.55-0.45,1-1,1V0H8v5.5H7V0H6v5.5c-0.55,0-1-0.45-1-1V0H4v7.5c0,1.18,0.81,2.17,1.92,2.43
			L5.5,23c0,1.1,0.9,2,2,2s2-0.9,2-2.02L9.08,9.93C10.19,9.67,11,8.68,11,7.5V0H10z M10,7.5c0,0.81-0.64,1.47-1.45,1.49
			C8.27,9,8.06,9.23,8.07,9.51L8.5,23c0,0.55-0.45,1-1,1s-1-0.45-1-0.98L6.93,9.51C6.94,9.23,6.73,9,6.45,8.99
			C5.64,8.97,5,8.31,5,7.5V6.22C5.3,6.39,5.63,6.5,6,6.5h3c0.37,0,0.7-0.11,1-0.28V7.5z M20.5,6.52c0-5.51-4.85-6.48-4.9-6.49
			C15.46,0,15.3,0.04,15.19,0.14c-0.11,0.09-0.18,0.23-0.18,0.38v22.46c0,1.1,0.89,2,1.99,2s1.99-0.9,1.99-2.03l-0.48-8.3
			C19.02,14.02,20.5,11.79,20.5,6.52z M17.99,22.98c0,0.55-0.44,1-0.99,1s-1-0.45-1-1l-0.01-21.8c1.13,0.41,3.51,1.7,3.51,5.34
			c0,5.74-1.83,7.56-1.84,7.58c-0.11,0.1-0.17,0.24-0.16,0.39L17.99,22.98z"
          />
        </g>
      </svg>
    </span>
  );
};

PosIconSilverware.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
