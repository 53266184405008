import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentOrder } from 'pages/Dashboard/action';
import {
  BillCheckInfoUI,
  BillCheckItemUI,
  BillCheckUI,
  ButtonUI,
} from 'UI/components';
import moment from 'moment';
import { getItemInstruction } from './util';
import './SplitPerSeat.css';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
import { createSplitPerItemChecks } from './SplitPerItem.service';
import { addPrinterCommandApi } from '../../services/ordersServices';
import {
  storeConfigSelector,
  storeTimeZoneSelector,
  restaurantIdSelector,
} from '../../pages/Dashboard/selectors';

const SplitPerSeat = ({
  currentOrder,
  selectedCheck,
  printer,
  togglePaymentModal,
  voidedItems,
  setState,
  accessToken,
  checks,
}) => {
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const timeZone = useSelector(storeTimeZoneSelector);

  const [isPrinterApiLoading, setIsPrinterApiLoading] = useState(false);

  const totalSeats = currentOrder?.allSeatsData?.length;
  const activeSeatId = selectedCheck?.activeSeatId || '';
  const isSharedItemPresent = selectedCheck?.items?.some(
    (item) => item?.activeSeatId === 'Shared'
  );

  let perSeatDiscount = 0;
  let perSeatVoucherDiscount = 0;
  let perSeatPublicHolidaySurcharge = 0;
  const discountData = currentOrder?.selectedDiscount;
  const voucherData = currentOrder?.selectedVoucher;

  if (discountData?.discountType === '1') {
    const itemsTotalPrice = selectedCheck?.items?.reduce((total, item) => {
      const isItemVoided = voidedItems.find(
        (voidedItem) =>
          voidedItem._id === item.itemId &&
          item?.orderIndexes?.includes(voidedItem.orderIndex)
      );
      if (isItemVoided?._id) {
        return total;
      }
      return (total =
        total + item?.itemPayablePrice * Number(item?.share.toFixed(3)));
    }, 0);
    perSeatDiscount = Number(
      (itemsTotalPrice * (discountData?.discount / 100)).toFixed(2)
    );
  } else if (discountData?.discountType === '2') {
    perSeatDiscount = Number((discountData?.discount / totalSeats).toFixed(2));
  }

  if (voucherData?.type === '1') {
    const itemsTotalPrice = selectedCheck?.items?.reduce((total, item) => {
      const itemDetails = currentOrder.menuItems.find(
        (menuItem) => menuItem._id === item.itemId
      );
      const isItemVoided = voidedItems.find(
        (voidedItem) =>
          voidedItem._id === item.itemId &&
          item?.orderIndexes?.includes(voidedItem.orderIndex)
      );
      if (isItemVoided?._id) {
        return total;
      }
      return (total =
        total + itemDetails?.payablePrice * Number(item?.share.toFixed(3)));
    }, 0);

    perSeatVoucherDiscount = Number(
      (itemsTotalPrice * (voucherData?.discount / 100)).toFixed(2)
    );
  } else if (voucherData?.discountType === '2') {
    perSeatVoucherDiscount = Number(
      (voucherData?.discount / totalSeats).toFixed(2)
    );
  }

  if (Number(currentOrder?.publicHolidaySurcharge) > 0) {
    const itemsTotalPrice = selectedCheck?.items?.reduce((total, item) => {
      const itemDetails = currentOrder.menuItems.find(
        (menuItem) => menuItem._id === item.itemId
      );
      const isItemVoided = voidedItems.find(
        (voidedItem) =>
          voidedItem._id === item.itemId &&
          item?.orderIndexes?.includes(voidedItem.orderIndex)
      );
      if (isItemVoided?._id) {
        return total;
      }
      return (total =
        total + itemDetails?.payablePrice * Number(item?.share.toFixed(3)));
    }, 0);

    perSeatPublicHolidaySurcharge = Number(
      (
        itemsTotalPrice *
        (currentOrder?.publicHolidaySurchargePer / 100)
      ).toFixed(2)
    );
  }

  const halfHalfMenuItem = currentOrder.menuItems.filter(
    (item) => item?.isHalf && item?.activeSeatId === activeSeatId
  );

  const sharedHalfHalfMenuItem = currentOrder.menuItems.filter(
    (item) => item?.isHalf && item?.activeSeatId === 'Shared'
  );

  const fourQuarterfMenuItem = currentOrder.menuItems.filter(
    (item) => item?.isQuarter && item?.activeSeatId === activeSeatId
  );

  const sharedFourQuarterfMenuItem = currentOrder.menuItems.filter(
    (item) => item?.isQuarter && item?.activeSeatId === 'Shared'
  );

  const checkPaid = useMemo(
    () =>
      currentOrder &&
      selectedCheck &&
      selectedCheck.paidAmount ===
        Number(selectedCheck.payableAmount.toFixed(2)),
    [currentOrder, selectedCheck]
  );

  const handlePayPerSeat = useCallback(() => {
    dispatch(
      updateCurrentOrder({ ...currentOrder, billType: 'split_per_seat' })
    );
    togglePaymentModal((selectedCheck?.payableAmount).toFixed(2));
  }, [selectedCheck?.payableAmount.toFixed(2), togglePaymentModal]);

  const handlePrintCommand = async () => {
    setIsPrinterApiLoading(true);

    let printCommandRequestObj = {};

    if (selectedCheck.id) {
      printCommandRequestObj = {
        checkId: selectedCheck.id,
        printerId: printer?._id,
        orderId: currentOrder._id,
        storeId: storeConfig?.storeId,
        restaurantId,
        printReceipt: true,
        receiptType: 12,
        openCashDrawer: false,
        timeZone,
      };
    } else {
      const splitPerSeatPaymentPayload = {
        checks: checks,
        numChecks: checks.length || 0,
        orderId: currentOrder._id,
      };

      const createCheckApi = await createSplitPerItemChecks(
        splitPerSeatPaymentPayload,
        'split_per_seat',
        accessToken,
        currentOrder
      );

      if (createCheckApi?.errors?.length === 0) {
        setState({
          numChecks: createCheckApi?.data?.splitPayment?.checks.length,
          checks: createCheckApi?.data?.splitPayment?.checks || [],
          concluded: true,
        });
        const currentCheck = createCheckApi?.data?.splitPayment?.checks.find(
          (check) => check.number === selectedCheck.number
        );

        printCommandRequestObj = {
          checkId: currentCheck.id,
          printerId: printer?._id,
          orderId: currentOrder._id,
          storeId: storeConfig?.storeId,
          restaurantId,
          printReceipt: true,
          receiptType: 12,
          openCashDrawer: false,
          timeZone,
        };
      }
    }

    if (printer?._id) {
      await addPrinterCommandApi(printCommandRequestObj);
    }
    setIsPrinterApiLoading(false);
  };

  const renderItems = (selectedCheck) => {
    return selectedCheck?.items
      .filter((item) => item?.activeSeatId !== 'Shared')
      .map((item, index) => {
        const itemDetails = currentOrder.menuItems.find(
          (menuItem) =>
            menuItem._id === item.itemId &&
            !menuItem?.isHalf &&
            !menuItem.isQuarter
        );

        return (
          Object.keys(itemDetails || {}).length > 0 && (
            <div key={itemDetails._id + itemDetails?.orderIndex}>
              <BillCheckItemUI
                id={itemDetails._id + itemDetails?.orderIndex}
                isVoided={itemDetails.isVoided}
                title={`${itemDetails.name} ${
                  itemDetails.quantity > 1 ? `x ${itemDetails.quantity}` : ``
                }`}
                instruction={getItemInstruction(itemDetails)}
                price={`${storeConfig?.currency || '$'}${(
                  itemDetails.payablePrice *
                  item?.share *
                  selectedCheck.numGuests
                ).toFixed(2)}`}
              />
            </div>
          )
        );
      });
  };

  const renderSharedItems = (selectedCheck) => {
    return selectedCheck?.items
      .filter((item) => item?.activeSeatId === 'Shared')
      .map((item, index) => {
        const itemDetails = currentOrder.menuItems.find(
          (menuItem) =>
            menuItem._id === item.itemId &&
            !menuItem?.isHalf &&
            !menuItem.isQuarter
        );

        return (
          Object.keys(itemDetails || {}).length > 0 && (
            <div key={itemDetails._id + itemDetails?.orderIndex}>
              <BillCheckItemUI
                id={itemDetails._id + itemDetails?.orderIndex}
                isVoided={itemDetails.isVoided}
                title={`${itemDetails.name} ${
                  itemDetails.quantity > 1 ? `x ${itemDetails.quantity}` : ``
                }`}
                instruction={getItemInstruction(itemDetails)}
                price={`${storeConfig?.currency || '$'}${(
                  itemDetails.payablePrice *
                  item?.share *
                  selectedCheck.numGuests
                ).toFixed(2)}`}
              />
            </div>
          )
        );
      });
  };

  const seatName = currentOrder?.allSeatsData.find(
    (seat) => seat.seatId === selectedCheck?.activeSeatId
  )?.seatName;
  return (
    <div className="bem-split-equally">
      <div />
      <BillCheckUI
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        <BillCheckUI.Header>
          <BillCheckInfoUI.Title
            borderSide={'bottom'}
            title={'POS - Dine In'}
            description={`Table ${currentOrder.tableNumber} - ${seatName}`}
          />
        </BillCheckUI.Header>

        <BillCheckUI.Body>
          <BillCheckInfoUI
            borderSide={'bottom'}
            title={'Placed:'}
            description={moment(currentOrder.date).calendar()}
          />
          {currentOrder.notes && (
            <BillCheckInfoUI
              borderSide={'bottom'}
              title={'Notes:'}
              description={currentOrder.notes}
            />
          )}
          {
            //Menu Items without Half Half and Shared
            selectedCheck?.items?.length > 0 && renderItems(selectedCheck)
          }

          {
            //Half Half Menu Items
            Object.values(
              halfHalfMenuItem
                .filter((item) => item.activeSeatId !== 'Shared')
                .reduce(
                  (acc, k) => ({
                    ...acc,
                    [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                  }),
                  {}
                )
            ).map((item, index) => {
              const quantity = item.reduce(
                (quantity, item) => (quantity = quantity + item.quantity),
                0
              );
              const id = item.map((i) => i._id).join('+');
              const name = `${item.map((i) => i.name).join('/')}`;
              const payablePrice = item.reduce(
                (acc, i) => (acc += i.payablePrice),
                0
              );
              return (
                <BillCheckItemUI
                  key={id}
                  title={`${name} ${quantity > 1 ? `x ${quantity}` : ``}`}
                  price={`${storeConfig?.currency || '$'}${payablePrice.toFixed(
                    2
                  )}`}
                />
              );
            })
          }

          {
            //Four Quarters Menu Items
            Object.values(
              fourQuarterfMenuItem
                .filter((item) => item.activeSeatId !== 'Shared')
                .reduce(
                  (acc, k) => ({
                    ...acc,
                    [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                  }),
                  {}
                )
            ).map((item, index) => {
              const quantity = item.reduce(
                (quantity, item) => (quantity = quantity + item.quantity),
                0
              );
              const id = item.map((i) => i._id).join('+');
              const name = `${item.map((i) => i.name).join('/')}`;
              const payablePrice = item.reduce(
                (acc, i) => (acc += i.payablePrice),
                0
              );
              return (
                <BillCheckItemUI
                  key={id}
                  title={`${name} ${quantity > 1 ? `x ${quantity}` : ``}`}
                  price={`${storeConfig?.currency || '$'}${payablePrice.toFixed(
                    2
                  )}`}
                />
              );
            })
          }

          {isSharedItemPresent && (
            <>
              <div className="sharedContainer">Shared</div>

              {
                //Shared Menu Items
                selectedCheck?.items?.length > 0 &&
                  renderSharedItems(selectedCheck)
              }
            </>
          )}

          {
            //Shared Half Half Menu Items
            Object.values(
              sharedHalfHalfMenuItem
                .filter((item) => item.activeSeatId === 'Shared')
                .reduce(
                  (acc, k) => ({
                    ...acc,
                    [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                  }),
                  {}
                )
            ).map((item) => {
              const quantity = item.reduce(
                (quantity, item) => (quantity = quantity + item.quantity),
                0
              );
              const id = item.map((i) => i._id).join('+');
              const name = `${item.map((i) => i.name).join('/')}`;
              const payablePrice = item.reduce(
                (acc, i) => (acc += i.payablePrice),
                0
              );
              return (
                <BillCheckItemUI
                  key={id}
                  title={`${name} ${quantity > 1 ? `x ${quantity}` : ``}`}
                  price={`${storeConfig?.currency || '$'}${(
                    payablePrice / totalSeats
                  ).toFixed(2)}`}
                />
              );
            })
          }

          {
            //Shared Four Quarters Menu Items
            Object.values(
              sharedFourQuarterfMenuItem
                .filter((item) => item.activeSeatId === 'Shared')
                .reduce(
                  (acc, k) => ({
                    ...acc,
                    [k.halfIndex]: [...(acc[k.halfIndex] || []), k],
                  }),
                  {}
                )
            ).map((item, index) => {
              const quantity = item.reduce(
                (quantity, item) => (quantity = quantity + item.quantity),
                0
              );
              const id = item.map((i) => i._id).join('+');
              const name = `${item.map((i) => i.name).join('/')}`;
              const payablePrice = item.reduce(
                (acc, i) => (acc += i.payablePrice),
                0
              );
              return (
                <BillCheckItemUI
                  key={id}
                  title={`${name} ${quantity > 1 ? `x ${quantity}` : ``}`}
                  price={`${storeConfig?.currency || '$'}${(
                    payablePrice / totalSeats
                  ).toFixed(2)}`}
                />
              );
            })
          }
        </BillCheckUI.Body>

        <BillCheckUI.Footer>
          {parseFloat(currentOrder.discount) > 0 && (
            <BillCheckInfoUI
              title={'Discount'}
              description={`-${
                storeConfig?.currency || '$'
              }${perSeatDiscount.toFixed(2)}`}
            />
          )}

          {parseFloat(currentOrder?.voucherDiscount) > 0 && (
            <BillCheckInfoUI
              title={'Voucher'}
              description={`-${
                storeConfig?.currency || '$'
              }${perSeatVoucherDiscount.toFixed(2)}`}
            />
          )}

          {currentOrder.specialDiscount !== '0' && (
            <BillCheckInfoUI
              title={'Specials'}
              description={`-${storeConfig?.currency || '$'}${(
                currentOrder.specialDiscount / totalSeats
              ).toFixed(2)}`}
            />
          )}

          {Number(currentOrder?.publicHolidaySurcharge) > 0 && (
            <BillCheckInfoUI
              title={'Public Holiday Surcharge'}
              description={`+${
                storeConfig?.currency || '$'
              }${perSeatPublicHolidaySurcharge.toFixed(2)}`}
            />
          )}

          {/*Service charge is divided equally in all checks */}
          {currentOrder?.serviceCharge > 0 ? (
            <BillCheckInfoUI
              title={'Service Charge'}
              description={`+${storeConfig?.currency || '$'}${(
                Number(currentOrder?.serviceCharge?.toFixed(2)) / checks?.length
              ).toFixed(2)}`}
            />
          ) : null}

          <BillCheckInfoUI.Total
            title={'Amount Paid'}
            description={`${storeConfig?.currency || '$'}${(
              selectedCheck?.paidAmount || 0
            ).toFixed(2)}`}
          />

          <BillCheckInfoUI.Total
            title={'Total'}
            description={`${
              storeConfig?.currency || '$'
            }${selectedCheck.payableAmount.toFixed(2)}`}
          />

          <BillCheckUI.ActionButtons>
            <ButtonUI
              styleType="success"
              sizeType="l"
              rounded="m"
              fullWidth
              disabled={checkPaid}
              onClick={handlePayPerSeat}
            >
              Pa{checkPaid ? `id` : `y`}
            </ButtonUI>
            <ButtonUI
              styleType="primary"
              sizeType="l"
              rounded="m"
              fullWidth
              onClick={handlePrintCommand}
              disabled={!printer?._id || isPrinterApiLoading}
              icon={
                isPrinterApiLoading && (
                  <PosIconLoading
                    mainColor="white"
                    style={{
                      marginLeft: 16,
                      width: '18px',
                      height: '18px',
                    }}
                  />
                )
              }
            >
              Print
            </ButtonUI>
          </BillCheckUI.ActionButtons>
        </BillCheckUI.Footer>
      </BillCheckUI>
    </div>
  );
};

export default SplitPerSeat;
