import { useEffect, useState } from 'react';

const useSelectedIngredientsCount = (currentItem) => {
  const [totalCount, setTotalCount] = useState(false);

  useEffect(() => {
    let total = 0;
    if (
      currentItem.selectedIngredients !== null &&
      currentItem.selectedIngredients !== undefined &&
      currentItem.selectedIngredients.length > 0
    ) {
      currentItem.selectedIngredients.forEach((item) => {
        if (item.isRemoved) {
          total++;
        }
      });
    }

    if (
      currentItem.selectedExtraIngredients !== null &&
      currentItem.selectedExtraIngredients !== undefined &&
      currentItem.selectedExtraIngredients.length > 0
    ) {
      currentItem.selectedExtraIngredients.forEach((item) => {
        if (item.quantity) {
          total++;
        }
      });
    }

    setTotalCount(total);
  }, [currentItem]);

  return totalCount;
};

export default useSelectedIngredientsCount;
