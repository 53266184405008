import { _firestore } from '../../firebase';
import ActionTypes from './constants';
import { FetchStatusEnum } from '../../configuration/constants';
import { getTimestampStartOfTheDay } from '../../util/timeHelper';
import { storeIdSelector } from '../Dashboard/selectors';

let fetchUnAssignedOrderListUnsubscribe;
let fetchActiveDriverListUnsubscribe;
let fetchDriverLocationUnsubscribe;
let fetchDriverRunsUnsubscribe;
let fetchDriverRunOrdersUnsubscribe;
let fetchDriverShiftsUnsubscribe;
let fetchActiveDriverCashBalanceUnSubscribe;

const updateOrdersFetchStatus = (ordersListFetchStatus) => {
  return {
    type: ActionTypes.ordersListFetchStatusUpdate,
    payload: {
      ordersListFetchStatus,
    },
  };
};
export const setOrderList = (payload) => {
  return {
    type: ActionTypes.setOrderList,
    payload,
  };
};

export const setActiveDrivers = (payload) => {
  return {
    type: ActionTypes.FetchActiveDrivers,
    payload,
  };
};

export const setActiveDriversLocations = (payload) => {
  return {
    type: ActionTypes.FetchActiveDriverLocations,
    payload,
  };
};

export const setActiveDriversRuns = (payload) => {
  return {
    type: ActionTypes.FetchActiveDriverRuns,
    payload,
  };
};

export const setActiveDriversShifts = (payload) => {
  return {
    type: ActionTypes.FetchActiveDriverShifts,
    payload,
  };
};

export const setActiveDriversRunOrders = (payload) => {
  return {
    type: ActionTypes.FetchActiveDriverRunOrders,
    payload,
  };
};

export const setActiveDriverCashBalance = (payload) => {
  return {
    type: ActionTypes.FetchDriverCashBalance,
    payload,
  };
};

export const resetSelectedUnAssignedOrdersForDeliveryBatches = () => {
  return {
    type: ActionTypes.resetSelectedUnAssignedOrdersForDeliveryBatches,
  };
};

// firebase listeners

export function fetchUnAssignedOrders() {
  if (fetchUnAssignedOrderListUnsubscribe) {
    fetchUnAssignedOrderListUnsubscribe();
  }

  return (dispatch, getState) => {
    const storeId = storeIdSelector(getState());
    if (fetchUnAssignedOrderListUnsubscribe) {
      dispatch(updateOrdersFetchStatus(FetchStatusEnum.NOT_STARTED));
    }

    dispatch(updateOrdersFetchStatus(FetchStatusEnum.PENDING));

    const updateUnAssignedOrders = (querySnapshot) => {
      dispatch(updateOrdersFetchStatus(FetchStatusEnum.RESOLVED));

      const flattenedData = querySnapshot.docs.map((doc) => ({
        _id: doc.id,
        ...doc.data(),
      }));

      dispatch(setOrderList(flattenedData));
    };

    const onError = (error) => {
      /**
       * @Note @Todo @Sentry @ErrorHandling
       */
      dispatch(updateOrdersFetchStatus(FetchStatusEnum.REJECTED));
    };
    fetchUnAssignedOrderListUnsubscribe = _firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('orderType', '==', '2')
      .where('orderStatus', '==', '2')
      .where('deliveryDateTimestamp', '>=', getTimestampStartOfTheDay())
      .orderBy('deliveryDateTimestamp', 'desc')
      .onSnapshot(updateUnAssignedOrders, onError);
  };
}

export function fetchActiveDrivers() {
  if (fetchActiveDriverListUnsubscribe) {
    fetchActiveDriverListUnsubscribe();
  }
  return (dispatch, getState) => {
    const storeId = storeIdSelector(getState());
    fetchActiveDriverListUnsubscribe = _firestore
      .collection('DriverStoreMapping')
      .where('storeId', '==', storeId)
      .where('isActivated', '==', true)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setActiveDrivers(dataArray));
      });
  };
}

export function fetchActiveDriverLocations() {
  if (fetchDriverLocationUnsubscribe) {
    fetchDriverLocationUnsubscribe();
  }
  return (dispatch, getState) => {
    const storeId = storeIdSelector(getState());
    fetchDriverLocationUnsubscribe = _firestore
      .collection('DriverLocations')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setActiveDriversLocations(dataArray));
      });
  };
}

export function fetchActiveDriverRuns() {
  if (fetchDriverRunsUnsubscribe) {
    fetchDriverRunsUnsubscribe();
  }
  return (dispatch, getState) => {
    const storeId = storeIdSelector(getState());
    fetchDriverRunsUnsubscribe = _firestore
      .collection('DriverRuns')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setActiveDriversRuns(dataArray));
      });
  };
}

export function fetchActiveDriverShifts() {
  if (fetchDriverShiftsUnsubscribe) {
    fetchDriverShiftsUnsubscribe();
  }
  return (dispatch, getState) => {
    const storeId = storeIdSelector(getState());
    fetchDriverShiftsUnsubscribe = _firestore
      .collection('DriverShifts')
      .where('storeId', '==', storeId)
      .where('isShiftOver', '==', false)
      .orderBy('startAtTimestamp', 'desc')
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setActiveDriversShifts(dataArray));
      });
  };
}

export function fetchActiveDriverRunOrders() {
  if (fetchDriverRunOrdersUnsubscribe) {
    fetchDriverRunOrdersUnsubscribe();
  }
  return (dispatch, getState) => {
    const storeId = storeIdSelector(getState());
    fetchDriverRunOrdersUnsubscribe = _firestore
      .collection('DriverRunOrders')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setActiveDriversRunOrders(dataArray));
      });
  };
}

export function fetchActiveDriverCashBalance() {
  if (fetchActiveDriverCashBalanceUnSubscribe) {
    fetchActiveDriverCashBalanceUnSubscribe();
  }
  return (dispatch, getState) => {
    const storeId = storeIdSelector(getState());
    fetchActiveDriverCashBalanceUnSubscribe = _firestore
      .collection('DriverCashBalance')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(setActiveDriverCashBalance(dataArray));
      });
  };
}
