import React from 'react';
import './radix-select.scss';
import * as SelectPrimitive from '@radix-ui/react-select';
import { getUIClassesFromProps } from '../modifiersMapping';
import { ChevronDownIcon, CheckIcon } from 'nexticons/outline';
import clsx from 'clsx';

const Select = SelectPrimitive.Root;
const SelectTrigger = SelectPrimitive.Trigger;
const SelectValue = SelectPrimitive.Value;
const SelectIcon = SelectPrimitive.Icon;
const SelectContent = SelectPrimitive.Content;
const SelectViewport = SelectPrimitive.Viewport;
const SelectItem = SelectPrimitive.Item;
const SelectItemText = SelectPrimitive.ItemText;
const SelectItemIndicator = SelectPrimitive.ItemIndicator;

const RadixSelectUI = ({
  options,
  disabled,
  containerClass,
  icon,
  styleType,
  style,

  ...rest
}) => {
  return (
    <div className={`radix-select ${containerClass}`}>
      <Select {...rest}>
        <SelectTrigger
          disabled={disabled}
          className={getUIClassesFromProps('radix-select__trigger', {
            styleType,
          })}
          style={style}
        >
          <span
            className={clsx('radix-select__trigger-inner', {
              'radix-select__trigger-inner--with-icon': icon,
            })}
          >
            {icon && <span className="radix-select__icon-box">{icon}</span>}
            {<SelectValue />}
            {disabled ? (
              <div className="radix-select__loader" />
            ) : (
              <SelectIcon className="radix-select__trigger-chevron">
                <ChevronDownIcon
                  style={{ position: 'relative', top: 1 }}
                  width={19}
                  strokeWidth={2.5}
                />
              </SelectIcon>
            )}
          </span>
        </SelectTrigger>
        <SelectContent className="radix-select__content">
          <SelectViewport className="radix-select__viewport">
            {options?.map((option) => (
              <SelectItem
                className="radix-select__item"
                value={option.value}
                key={`select-item-key-${option.value}`}
                disabled={disabled}
              >
                <SelectItemText>{option.label}</SelectItemText>
                <SelectItemIndicator className="radix-select__item-indicator">
                  {option.label && (
                    <CheckIcon
                      width={15}
                      strokeWidth={3}
                      color="var(--textColor)"
                    />
                  )}
                </SelectItemIndicator>
              </SelectItem>
            ))}
          </SelectViewport>
        </SelectContent>
      </Select>
    </div>
  );
};

export default RadixSelectUI;
