import {
  toggleOrderModal
} from 'pages/Dashboard/action';
import { storeConfigSelector } from 'pages/Dashboard/selectors';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StaffPinModal from '../../components/StaffPinModal';

export default function KioskAccessModal() {
  const storeConfig = useSelector(storeConfigSelector);
  const orderSetup = useSelector((state) => state.dashboardReducer.orderSetup);

  const [errorMessage, setErrorMessage] = useState('');
  const [wiggleEffect, setWiggleEffect] = useState(false);
  const dispatch = useDispatch();

  const onSuccess = () => {
    dispatch(toggleOrderModal(true));
  };

  return (
    <StaffPinModal
      setIsClockedIn={() => null}
      storeConfig={storeConfig}
      orderSetup={orderSetup}
      setShowClockInOutModal={() => null}
      setIsOnBreak={() => null}
      errorMessage={errorMessage}
      setShowAccessDenied={() => null}
      setErrorMessage={setErrorMessage}
      wiggleEffect={wiggleEffect}
      setWiggleEffect={setWiggleEffect}
      onSuccess={onSuccess}
    />
  );
}
