import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconStore = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-store': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="26"
        viewBox="0 0 25 26"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M24 8.5a2 2 0 0 1-1.44 2 2 2 0 0 1-2.35-1.31.6.6 0 0 0-.58-.43.6.6 0 0 0-.57.44 1.87 1.87 0 0 1-3.61 0 .6.6 0 0 0-.58-.44.6.6 0 0 0-.58.43 1.88 1.88 0 0 1-3.61 0 .62.62 0 0 0-1.15 0 1.89 1.89 0 0 1-3.62 0 .6.6 0 0 0-.58-.43.63.63 0 0 0-.58.43A1.93 1.93 0 0 1 3 10.5h-.13a2 2 0 0 1-.41-.06A2 2 0 0 1 1 8.5v-.7a3.14 3.14 0 0 1 .37-1.2l3.1-5.1h16.09l3.06 5A3.3 3.3 0 0 1 24 7.8zm-2 15.72c0 .13-.2.28-.37.28H10V18a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v6.5H3.37c-.17 0-.37-.15-.37-.28V11.5a2.92 2.92 0 0 0 2.34-1.27 2.88 2.88 0 0 0 4.78 0 2.87 2.87 0 0 0 4.76 0 2.87 2.87 0 0 0 4.77 0A3 3 0 0 0 22 11.5zM9 24.5H6v-6h3zM24.49 6.04L21.27.74a.5.5 0 0 0-.43-.24H4.19a.5.5 0 0 0-.43.24L.5 6.1A4.06 4.06 0 0 0 0 7.8v.7a3 3 0 0 0 2 2.88v12.84a1.36 1.36 0 0 0 1.37 1.28h18.26A1.36 1.36 0 0 0 23 24.22V11.35a3 3 0 0 0 2-2.85v-.7a4.25 4.25 0 0 0-.51-1.76z"
              />
            </g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M19 18.5h-5v-3h5zm.5-4h-6a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconStore.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
