import classNames from 'classnames';
import SplitBillOption from 'components/SplitBillOption';
import { useDirectSelector } from 'hooks/useReactRedux';
import actionTypes from 'pages/Dashboard/constants';
import {
  currencySymbolSelector,
  storeConfigSelector,
  storeCountryIdentifierSelector,
} from 'pages/Dashboard/selectors';
import { updateCurrentOrderWithOrderEngine } from 'pages/Dashboard/thunks';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceCharges } from 'store/serviceCharges/serviceChargesActions';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import { v4 } from 'uuid';
import { PosIconLoading } from '../assets/icons/PosIconLoading';
import { ScrollableBoxUI } from '../UI/components';

const defaultServiceCharge = {
  id: v4(),
  charge: 0,
  isActive: true,
};

const SurchargeModalWrapper = (props) => {
  const open = useSelector((s) => s.dashboardReducer.surchargeModal);

  if (!open) return null;

  return <SurchargeModal open={open} {...props} />;
};

const SurchargeModal = ({ open }) => {
  const dispatch = useDispatch();
  const { storeId } = useSelector(storeConfigSelector);
  const [loading, setLoading] = useState(false);
  const serviceChargesList = useDirectSelector('serviceChargesReducer');
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const currencyCode = useSelector(currencySymbolSelector);

  const [selectedServiceCharge, setServiceCharge] = useState({});
  const currentOrder = useSelector(
    (state) => state.dashboardReducer?.currentOrder
  );

  const surchargeList = useMemo(
    () =>
      serviceChargesList.filter((c) =>
        c.orderTypeKeys?.includes(currentOrder.orderType)
      ),
    [serviceChargesList, currentOrder.orderType]
  );

  useEffect(() => {
    if (!storeId) return;
    (async () => {
      setLoading(true);
      await dispatch(fetchServiceCharges(storeId));
      setLoading(false);
    })();
  }, [storeId, dispatch, currentOrder]);

  const handleCloseModal = () => {
    dispatch({ type: actionTypes.toggleSurchargeModal });
  };

  const handleApplyServiceCharge = () => {
    const newCurrentOrder = {
      ...currentOrder,
      appliedServiceCharge: selectedServiceCharge.charge ?? 0,
      serviceChargeId: selectedServiceCharge.id,
    };
    dispatch(updateCurrentOrderWithOrderEngine(newCurrentOrder));
    handleCloseModal();
  };

  const handleRemoveServiceCharge = () => {
    const newCurrentOrder = {
      ...currentOrder,
      appliedServiceCharge: 0,
      serviceChargeId: '',
    };
    dispatch(updateCurrentOrderWithOrderEngine(newCurrentOrder));
    handleCloseModal();
  };

  const modalCls = classNames({
    'booking-modal': true,
    show: true,
  });
  const applyBtnCls = classNames('btn big hover-highlight');

  if (!open) return null;
  return (
    <div id="split-bill-discount-modal" className={modalCls}>
      <div className="modal-wrap">
        <form className="modal-box" autoComplete="off">
          <div className="modal-header">
            <h2 className="modal-title">Select Service Charge</h2>
            <div
              style={{ color: '#944810', marginTop: 6, height: '24px' }}
            ></div>
          </div>
          <div className="modal-body">
            {loading ? (
              <div className="modal-loading-wrapper">
                <PosIconLoading mainColor="#5eb602" />
              </div>
            ) : surchargeList.length > 0 ? (
              <ScrollableBoxUI
              // actionType="modalBodyScroll"
              >
                <div className="modal-body-inner">
                  <div className="item-cards">
                    {surchargeList?.map((item) => {
                      return (
                        <SplitBillOption
                          key={item.id}
                          id={'id'}
                          onClick={() => setServiceCharge(item)}
                          item={item}
                          isChecked={(item) =>
                            item.id === selectedServiceCharge?.id
                          }
                          getTitle={(item) => item.charge + '%'}
                          getDescription={(item) => item.type?.toUpperCase()}
                          buttonStyle={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        />
                      );
                    })}

                    {currentOrder.serviceCharge && (
                      <SplitBillOption
                        key={defaultServiceCharge.id}
                        item={defaultServiceCharge}
                        id={'id'}
                        onClick={() => setServiceCharge(defaultServiceCharge)}
                        isChecked={() =>
                          defaultServiceCharge.id === selectedServiceCharge?.id
                        }
                        getTitle={() =>
                          `${getAmountWithCountryCurrency({
                            amount: Number(currentOrder?.serviceCharge),
                            locale: `en-${storeCountryIdentifier}`,
                            currencyCode,
                          })}`
                        }
                        getDescription={() => 'Applied'}
                        buttonStyle={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      />
                    )}
                  </div>
                </div>
              </ScrollableBoxUI>
            ) : (
              <div className="modal-body-inner">
                No Service Charges Available!
              </div>
            )}
          </div>

          <div className="modal-footer">
            <div className="modal-btn-box">
              <button
                type="button"
                className={applyBtnCls}
                onClick={
                  selectedServiceCharge.id === defaultServiceCharge.id
                    ? handleRemoveServiceCharge
                    : handleApplyServiceCharge
                }
                disabled={!selectedServiceCharge.id}
              >
                <span className="inner-btn-box">
                  {selectedServiceCharge.id === defaultServiceCharge.id
                    ? 'Remove Service Charge'
                    : 'Apply Service Charge'}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="modal-overlay" onClick={handleCloseModal} />
    </div>
  );
};

export default SurchargeModalWrapper;
