import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrderDetailsById } from './DriverOrderList.service';
import { DriverSingleOrderItem } from './DriverSingleOrderItem';
import { PosIconLoading } from 'assets/icons/PosIconLoading';

const DriverOrderList = React.memo(({ orders, currentDriverData }) => {
  const [updatedOrders, setUpdatedOrder] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const allOrdersMap = useSelector(
    (state) => state.newOrdersReducer?.orders?.allOrdersMap
  );

  const getCompleteOrdersDetail = async (driverRunOrders) => {
    setApiLoading(true);
    const updateOrders = [];
    for (let order of driverRunOrders) {
      if (allOrdersMap[order?.orderId]?._id) {
        // if order exist in our redux

        updateOrders.push({
          ...order,
          firstName: allOrdersMap[order?.orderId]?.firstName || '',
          lastName: allOrdersMap[order?.orderId]?.lastName || '',
          menuItems: allOrdersMap[order?.orderId]?.menuItems || [],
          deliveryCost: allOrdersMap[order?.orderId]?.deliveryCost || '',
          address: allOrdersMap[order?.orderId]?.address || '',
        });
      } else {
        // if order does not exist in our redux
        const orderDetail = await getOrderDetailsById(order?.orderId);
        if (orderDetail) {
          updateOrders.push({
            ...order,
            firstName: orderDetail?.firstName || '',
            lastName: orderDetail?.lastName || '',
            menuItems: orderDetail.menuItems || [],
            deliveryCost: orderDetail.deliveryCost || '',
            address: orderDetail?.address || '',
          });
        }
      }
    }

    setUpdatedOrder(updateOrders);
    setApiLoading(false);
  };

  useEffect(() => {
    getCompleteOrdersDetail(orders);
  }, [orders]);

  return (
    <>
      {apiLoading && updatedOrders?.length === 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '20vh',
            alignItems: 'flex-end',
          }}
        >
          <PosIconLoading size="32px" mainColor="#fd6c00" />
        </div>
      ) : (
        <ul className="details-list">
          {updatedOrders.map((order, i) => {
            return (
              <li key={i}>
                <DriverSingleOrderItem
                  order={order}
                  currentDriverData={currentDriverData}
                />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
});

export default DriverOrderList;
