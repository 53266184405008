import useIsKiosk from 'hooks/useIsKiosk';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useSelectedIngredients } from '../../customHooks/useSelectedIngredients';
import {
  currentFourQuarterDataSelector,
  currentHalfHalfDataSelector,
  currentSpecialsItemSelector,
} from '../../pages/Dashboard/selectors';
import { BackBtn, PresentationalBackButton } from '../BackBtn';
import BottomNav from '../BottomNav';
import { CancelBtn, PresentationalCancelButton } from '../CancelBtn';
import { Categories } from '../Categories';
import { ItemCustomizationOptions } from '../ItemCustomizationOptions';
import { LogoButton } from '../LogoButton';
import { Modifiers } from '../Modifiers';

export const NavigationBar = ({ basePath }) => {
  const isKiosk = useIsKiosk();
  const currentItem = useSelector((s) => s.dashboardReducer.currentMenuItem);
  const customizationStatus = useSelectedIngredients(currentItem);
  const isSingleHalfFlow = useSelector(
    (s) => s.dashboardReducer.isSingleHalfFlow
  );
  const currentHalfHalfData = useSelector(currentHalfHalfDataSelector);
  const currentFourQuarterData = useSelector(currentFourQuarterDataSelector);
  const currentSpecialsItem = useSelector(currentSpecialsItemSelector);

  let presentationalLink = '';

  const halfHalfCategoryId = currentHalfHalfData?.activeCategoryId;
  if (halfHalfCategoryId) {
    presentationalLink = `${basePath}/category/${halfHalfCategoryId}/half-half/`;
  }

  const fourQuarterCategoryId = currentFourQuarterData?.activeCategoryId;
  if (fourQuarterCategoryId) {
    presentationalLink = `${basePath}/category/${fourQuarterCategoryId}/four-quarter/`;
  }

  currentSpecialsItem?.selectedCategories?.forEach((category, catIndex) => {
    if (
      category?.isInEditMode &&
      category?.currentHalfHalfData &&
      category?.currentHalfHalfData?.halfHalfs
    ) {
      const { _id: currentSpecialsItemId } = currentSpecialsItem;
      presentationalLink = `${basePath}/specials/${currentSpecialsItemId}/${catIndex}/half-half/`;
    } else if (
      category?.isInEditMode &&
      category?.currentFourQuarterData &&
      category?.currentFourQuarterData?.fourQuarters
    ) {
      const { _id: currentSpecialsItemId } = currentSpecialsItem;
      presentationalLink = `${basePath}/specials/${currentSpecialsItemId}/${catIndex}/four-quarter/`;
    }
  });

  const backButtonForCustomisation =
    presentationalLink && !isSingleHalfFlow ? (
      <PresentationalBackButton link={presentationalLink} basePath={basePath} />
    ) : (
      <BackBtn currentItem={currentItem} basePath={basePath} />
    );

  const cancelButtonForCustomiseScreen =
    presentationalLink && !isSingleHalfFlow ? (
      <PresentationalCancelButton
        link={presentationalLink}
        basePath={basePath}
      />
    ) : (
      <CancelBtn currentItem={currentItem} basePath={basePath} />
    );

  let linkToLastHalfHalfFourQuarterIndexSelectionScreen = '';

  if (halfHalfCategoryId) {
    const halfIndex = currentHalfHalfData?.halfHalfs?.find(
      (h) => h.isInEditMode
    )?.halfIndex;
    linkToLastHalfHalfFourQuarterIndexSelectionScreen = `${basePath}/category/${halfHalfCategoryId}/half-half/${halfIndex}`;
  }

  if (fourQuarterCategoryId) {
    const fourQuarterIndex = currentFourQuarterData?.fourQuarters?.find(
      (f) => f.isInEditMode
    )?.fourQuarterIndex;
    linkToLastHalfHalfFourQuarterIndexSelectionScreen = `${basePath}/category/${fourQuarterCategoryId}/four-quarter/${fourQuarterIndex}`;
  }

  const backButtonForModifiersScreen =
    linkToLastHalfHalfFourQuarterIndexSelectionScreen && !isSingleHalfFlow ? (
      <PresentationalBackButton
        link={linkToLastHalfHalfFourQuarterIndexSelectionScreen}
        basePath={basePath}
      />
    ) : (
      <BackBtn currentItem={currentItem} basePath={basePath} />
    );

  return (
    <div className="nav-bar" id="nav-bar">
      <Switch>
        <Route
          exact
          path={basePath + '/menuitem/:itemId/:itemType/:modifierId'}
        >
          {backButtonForModifiersScreen}
          <Modifiers parentPage={basePath} currentItem={currentItem} />
        </Route>
        <Route path={basePath + '/menuitem/:itemId/customization'}>
          {customizationStatus
            ? cancelButtonForCustomiseScreen
            : backButtonForCustomisation}
          <ItemCustomizationOptions basePath={basePath} />
        </Route>
        <Route path={basePath}>
          {!isKiosk && <LogoButton />}
          <Categories isKiosk={isKiosk} />
        </Route>
      </Switch>

      <BottomNav
        currentItem={currentItem}
        basePath={basePath}
        isKiosk={isKiosk}
      />
    </div>
  );
};
