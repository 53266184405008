import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { ReadyContentView } from 'components/Orders/content/ReadyContentView';
import { ViewOrdersHeader } from 'components/Orders/ViewOrdersHeader';
import { ViewOrdersSidebar } from 'components/Orders/ViewOrdersSidebar';
import useOrdersData from 'hooks/useOrdersData';

import { useSelector } from 'react-redux';
import { storeConfigSelector } from '../../pages/Dashboard/selectors';
import {
  loadDrawers,
  loadPinpads,
  loadTerminalSettings,
  toggleBlurEffect,
} from '../Dashboard/action';
import { loadPaymentTypes } from '../Dashboard/thunks';
import { fetchDrivers, fetchOrderWaitingObj } from './actions';
import ModalsWrapper from './ModalsWrapper';
import { resetFilters, setState } from './newOrdersReducer';
import {
  fetchActiveDriverCashBalance,
  fetchActiveDriverLocations,
  fetchActiveDriverRunOrders,
  fetchActiveDriverRuns,
  fetchActiveDrivers,
  fetchActiveDriverShifts,
  fetchUnAssignedOrders,
} from '../../pages/Drivers/actions';

const Orders = (props) => {
  // useOrdersData();
  const dispatch = useDispatch();
  const storeConfig = useSelector(storeConfigSelector);
  const location = useLocation();

  useEffect(() => {
    dispatch(toggleBlurEffect(false));
  }, [dispatch]);

  useEffect(() => {
    if (storeConfig?.storeId) {
      dispatch(fetchOrderWaitingObj());
      dispatch(fetchDrivers(storeConfig?.storeId));
      // dispatch(loadPinpads(storeConfig?.storeId));
      // dispatch(loadDrawers(storeConfig?.storeId));
      // dispatch(loadTerminalSettings(storeConfig?.storeId));
      // dispatch(loadPaymentTypes());

      dispatch(fetchActiveDrivers());
      // dispatch(fetchActiveDriverLocations());
      // dispatch(fetchActiveDriverRuns());
      // dispatch(fetchActiveDriverShifts());
      // dispatch(fetchActiveDriverRunOrders());
      // dispatch(fetchUnAssignedOrders());
      // dispatch(fetchActiveDriverCashBalance());
    }
  }, [dispatch, storeConfig]);

  useEffect(() => {
    if (location.state?.orderID) {
      dispatch(setState({ selectedOrderId: location.state.orderID }));
    }
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch, location.state?.orderID]);

  return (
    <>
      <ViewOrdersHeader />
      <div className="view-order-main-wrap">
        <div className="view-order-sidebar">
          <ViewOrdersSidebar />
        </div>
        <ReadyContentView />
      </div>
      <ModalsWrapper />
    </>
  );
};

export default React.memo(Orders);
