import GeneralModal from '../../../modals/GeneralModal/index-new';
import { BasePartUI, ScrollableBoxUI } from '../../../UI/components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectedPinPadIdSelector,
  storeConfigSelector,
} from '../../../pages/Dashboard/selectors';
import './WindCaveTransactionModal.css';
import { ReceiptItem } from 'modals/PrintOrderModal/ReceiptItem';
import { getShortenedDateTime } from 'util/dateUtils';
import { BillMenuSingleItem } from 'modals/PrintOrderModal/BillMenuSingleItem';
import { getAmountWithCountryCurrency } from 'util/currencyFormat';
import terminalPng from '../../../assets/img/terminal.png';
import Lottie from 'react-lottie';
import checkMarkSuccess from '../../../assets/lotties/782-check-mark-success.json';
import { PosIconLoading } from '../../../assets/icons/PosIconLoading';
import { _firestore } from '../../../firebase';
import { nextOrderConfig } from 'configuration/nextOrderConfig';
import clsx from 'clsx';
import { LoadingIcon } from 'nexticons/outline';
import { XRoundedIcon, CheckIcon } from 'nexticons/solid';

const ORDER_TYPES = ['Walk In', 'Pick Up', 'Delivery', 'Dine-in'];

export const WindCaveTransactionModal = ({
  show,
  toggleModal,
  incompleteOrder,
  isApiLoading,
  windCaveTransactionReference,
}) => {
  const storeConfig = useSelector(storeConfigSelector);
  const selectedPinPadId = useSelector(selectedPinPadIdSelector);
  const windcaveUser = useSelector(
    (state) => state.dashboardReducer.terminalSettings?.user || 'None'
  );
  const [windcaveActionButtonPressed, setWindcaveActionButtonPressed] =
    useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paymentAnimation, setPaymentAnimation] = useState(0);
  const [windcaveButtonOne, setWindcaveButtonOne] = useState('');
  const [windcaveButtonTwo, setWindcaveButtonTwo] = useState('');
  const [windcaveInfoMessage, setWindcaveInfoMessage] = useState('');
  const [allowCloseModal, setAllowCloseModal] = useState(false);

  const onModalClose = () => {
    toggleModal();
  };

  const getAmount = (amount) => {
    return getAmountWithCountryCurrency({
      amount: Number(amount),
      locale: `en-${storeConfig?.storeCountryIdentifier}`,
      currencyCode: storeConfig?.currency,
    });
  };

  const checkMarkSuccessLottie = {
    loop: false,
    autoplay: true,
    animationData: checkMarkSuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleWindcaveButton = async (
    value,
    buttonName,
    transactionReference
  ) => {
    setWindcaveActionButtonPressed(true);
    const requestObj = {
      txnId: transactionReference,
      station: selectedPinPadId,
      user: windcaveUser,
      val: value,
      name: buttonName,
    };
    const windCaveActionResponse = await fetch(
      `${nextOrderConfig?.baseUrl}/windcave/v1/buttonAction`,
      {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestObj),
      }
    );

    const windCaveActionResponseJson = await windCaveActionResponse.json();

    if (windCaveActionResponseJson?.code === 201) {
      //windave inprogress
    }

    setWindcaveActionButtonPressed(false);
  };

  const handleWindCaveTransaction = () => {
    let listenWindcaveStatusUnsubscribe;
    let listenOrderPaymentStatusUnsubscribe;
    if (incompleteOrder?.paymentStatus > 0) {
      setPaymentAnimation(incompleteOrder?.paymentStatus);
    } else {
      setIsPlacingOrder(true);

      const orderRef = _firestore
        .collection('Orders')
        .doc(incompleteOrder?._id || incompleteOrder?.id);

      listenOrderPaymentStatusUnsubscribe = orderRef.onSnapshot(
        async (querySnapshot) => {
          let obj = querySnapshot.data();

          console.log('order obj', obj);
          const dataFromCache = !!querySnapshot.metadata.fromCache;
          if (!dataFromCache && obj && obj?.orderStatus === '0') {
            if (obj?.isError) {
              setIsError(true);
            } else {
              //Order SuccessFully Completed
              setPaymentAnimation(1);
            }

            listenOrderPaymentStatusUnsubscribe();
          }
        }
      );

      const windCaveStatusRef = _firestore
        .collection('WindcaveStatus')
        .doc(windCaveTransactionReference);

      listenWindcaveStatusUnsubscribe = windCaveStatusRef.onSnapshot(
        (querySnapshot) => {
          let windCaveStatusObj = querySnapshot.data();
          const dataFromCache = !!querySnapshot.metadata.fromCache;

          if (!dataFromCache) {
            if (windCaveStatusObj?.transactionReference) {
              setWindcaveButtonOne(windCaveStatusObj['button1'] || '');
              setWindcaveButtonTwo(windCaveStatusObj['button2'] || '');

              if (!windCaveStatusObj?.station) {
                // windcave transaction cancelled
                listenWindcaveStatusUnsubscribe();
                listenOrderPaymentStatusUnsubscribe();
                setIsPlacingOrder(false);
                setIsError(true);
                setWindcaveButtonTwo('');
              }

              if (windCaveStatusObj.complete === 1) {
                //statusId 1 mean transaction completed (it can be Timeout/cancelled/successfull)
                listenWindcaveStatusUnsubscribe();
                setWindcaveInfoMessage(
                  `${windCaveStatusObj['displayLine1'] || ''} ${
                    windCaveStatusObj['displayLine2'] || ''
                  }`
                );
                setAllowCloseModal(true);
                setIsPlacingOrder(false);
              }
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    handleWindCaveTransaction();
  }, [incompleteOrder]);

  return (
    <>
      <GeneralModal
        className="payment-modal"
        boxWidth="695px"
        modalStatus={show}
        toggleModal={allowCloseModal ? onModalClose : () => {}}
        position={'center'}
        effect="fade"
        overlay={'default'}
        boxHeight="555px"
        closeBtn="outside"
        showCloseBtn={allowCloseModal}
      >
        <BasePartUI>
          <BasePartUI.Header>
            <div className="windcaveHeaderContainer">
              <img
                src="https://www.windcave.com/Image/Windcave-Red-Logo-Horizontal.svg"
                alt="Payment Processor"
                width="276"
                height="88"
              />
            </div>

            {isPlacingOrder && !isApiLoading && (
              <div className="windCaveWarningContainer">
                <PosIconLoading mainColor="#f39c12" size={25} />
                <span style={{ marginLeft: '10px' }}>
                  Payment is in process. Please don't close the modal.
                </span>
              </div>
            )}
          </BasePartUI.Header>

          {isApiLoading ? (
            <div className="windcaveLoadingContainer">
              <PosIconLoading mainColor="#ff4239" />

              <span className="loadingInfoTextContainer">
                Please wait as your ongoing transaction for Wind Cave is
                currently loading.
              </span>
            </div>
          ) : (
            <div className="windcaveRecoveryContainer">
              <div>
                <ScrollableBoxUI>
                  <div className="bem-print-order-modal__windcave-order-box">
                    <ReceiptItem title={'Invoice'} isHeader isSingleItem />
                    <ReceiptItem
                      title={`POS - ${
                        ORDER_TYPES[Number(incompleteOrder?.orderType)]
                      }`}
                      description={
                        Number(incompleteOrder?.orderType) === 3 &&
                        `Table ${incompleteOrder?.tableNumber}`
                      }
                      isHeader
                    />
                    <ReceiptItem
                      title="Placed:"
                      description={getShortenedDateTime(
                        incompleteOrder?.dateTimestamp
                      )}
                    />
                    {Number(incompleteOrder?.orderType) !== 3 && (
                      <ReceiptItem
                        title="Customer:"
                        description={`${incompleteOrder?.firstName} ${incompleteOrder?.lastName}`}
                      />
                    )}

                    {incompleteOrder?.menuItems?.map((menuItem, index) => {
                      return (
                        <BillMenuSingleItem
                          key={`BillMenuSingleItem-${index}`}
                          menuItem={menuItem}
                        />
                      );
                    })}
                    {Number(incompleteOrder?.specialDiscount) > 0 && (
                      <ReceiptItem
                        title="Specials Discount:"
                        description={`-${getAmount(
                          incompleteOrder.specialDiscount
                        )}`}
                        isFooter
                      />
                    )}
                    {Number(incompleteOrder?.deliveryCost) > 0 && (
                      <ReceiptItem
                        title="Delivery Charges:"
                        description={getAmount(incompleteOrder.deliveryCost)}
                        isFooter
                      />
                    )}
                    <ReceiptItem
                      title="Total :"
                      description={getAmount(incompleteOrder?.payableAmount)}
                      isTotal
                    />
                  </div>
                </ScrollableBoxUI>
              </div>
              <div>
                <div className="windcaveTerminalbox">
                  {paymentAnimation === 0 && (
                    <img
                      src={terminalPng}
                      alt="Terminal"
                      style={{ height: '255px' }}
                      className={paymentAnimation !== 0 ? 'hide' : ''}
                    />
                  )}
                  <div
                    className="terminal-action-box"
                    disabled={isPlacingOrder}
                    style={false ? { opacity: 0.6 } : {}}
                  >
                    {paymentAnimation === 1 ? (
                      <div>
                        <Lottie
                          style={{
                            position: 'relative',
                            top: '-20px',
                            left: '-15px',
                          }}
                          options={checkMarkSuccessLottie}
                          height={192}
                          width={192}
                          eventListeners={[
                            {
                              eventName: 'complete',
                              callback: () => {},
                            },
                          ]}
                        />
                        <span className="paymentSuccessfullText">
                          Payment Completed !!
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                {paymentAnimation === 0 ? (
                  <>
                    <div className="windcavePaymentServicesBox">
                      {!windcaveButtonOne && !windcaveButtonTwo && (
                        <button
                          type="button"
                          style={
                            ['SIG ACCEPTED', 'APPROVED'].includes(
                              windcaveInfoMessage.trim()
                            )
                              ? { color: '#5eb602' }
                              : { color: 'red' }
                          }
                        >
                          {isPlacingOrder ? (
                            <PosIconLoading mainColor="#5eb602" />
                          ) : (
                            <>
                              <span>{windcaveInfoMessage}</span>
                            </>
                          )}
                        </button>
                      )}

                      {windcaveButtonOne && (
                        <button
                          type="button"
                          onClick={() =>
                            handleWindcaveButton(
                              windcaveButtonOne,
                              'B1',
                              windCaveTransactionReference
                            )
                          }
                          className="windcaveButton"
                          disabled={windcaveActionButtonPressed}
                        >
                          <div className="windcaveButtonItem">
                            {windcaveActionButtonPressed ? (
                              <LoadingIcon width={20} color="#5eb602" />
                            ) : (
                              <CheckIcon width={25} />
                            )}
                            <span className="windcaveButtonText">
                              {windcaveButtonOne}
                            </span>
                          </div>
                        </button>
                      )}

                      {windcaveButtonTwo && (
                        <button
                          type="button"
                          onClick={() =>
                            handleWindcaveButton(
                              windcaveButtonTwo,
                              'B2',
                              windCaveTransactionReference
                            )
                          }
                          className={clsx('windcaveButton', {
                            ['windcaveButtonDisabled']:
                              windcaveActionButtonPressed,
                          })}
                          disabled={windcaveActionButtonPressed}
                        >
                          <div className="windcaveButtonItem">
                            {windcaveActionButtonPressed ? (
                              <LoadingIcon width={20} color="#f40000" />
                            ) : (
                              <XRoundedIcon
                                width={20}
                                className="windcaveItemIcon"
                              />
                            )}

                            <span
                              className="windcaveButtonText"
                              style={{ color: '#f40000' }}
                            >
                              {windcaveButtonTwo}
                            </span>
                          </div>
                        </button>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          )}
        </BasePartUI>
      </GeneralModal>
    </>
  );
};
