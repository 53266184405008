import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconCreditCardReader = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-credit-card-reader': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="32"
        viewBox="0 0 26 32"
      >
        <g>
          <g>
            <g>
              <path
                fill={iconColor ? iconColor : '#000'}
                stroke={iconColor ? iconColor : '#000'}
                strokeWidth={lineThickness ? lineThickness : 0}
                d="M3.714 4.22h11.153v13.64H3.714zm12.381 14.26V3.6a.62.62 0 0 0-.619-.62H3.096a.62.62 0 0 0-.62.62v14.88c0 .342.277.62.62.62h12.38a.62.62 0 0 0 .62-.62zm-12.38 3.1h1.237v1.24H3.714zm2.475 1.86v-2.48a.62.62 0 0 0-.619-.62H3.095a.62.62 0 0 0-.619.62v2.48c0 .342.277.62.62.62H5.57a.62.62 0 0 0 .62-.62zm2.477-1.86h1.228v1.24H8.667zm2.476 1.86v-2.48a.62.62 0 0 0-.62-.62H8.049a.62.62 0 0 0-.62.62v2.48c0 .342.278.62.62.62h2.476a.62.62 0 0 0 .619-.62zm2.476-1.86h1.238v1.24H13.62zm2.486 1.86v-2.48a.62.62 0 0 0-.62-.62h-2.483a.62.62 0 0 0-.621.62v2.48c0 .342.278.62.62.62h2.484a.62.62 0 0 0 .62-.62zm-12.39 3.1h1.237v1.24H3.714zM6.19 28.4v-2.48a.62.62 0 0 0-.619-.62H3.095a.62.62 0 0 0-.619.62v2.48c0 .342.277.62.62.62H5.57a.62.62 0 0 0 .62-.62zm2.477-1.86h1.228v1.24H8.667zm2.476 1.86v-2.48a.62.62 0 0 0-.62-.62H8.049a.62.62 0 0 0-.62.62v2.48c0 .342.278.62.62.62h2.476a.62.62 0 0 0 .619-.62zm2.476-1.86h1.238v1.24H13.62zm2.486 1.86v-2.48a.62.62 0 0 0-.62-.62h-2.483a.62.62 0 0 0-.621.62v2.48c0 .342.278.62.62.62h2.484a.62.62 0 0 0 .62-.62zm2.466-24.18h2.477v18.6H18.57zm3.715 0h1.233c.68 0 1.233.555 1.233 1.24v16.12c0 .685-.552 1.24-1.233 1.24h-1.233zm-5.272-2.48c.17 0 .31.139.31.31v27.9a.31.31 0 0 1-.31.31H1.547a.31.31 0 0 1-.309-.31V2.05a.31.31 0 0 1 .31-.31zm1.557 28.21v-5.89h4.953A2.478 2.478 0 0 0 26 21.58V5.46c0-1.37-1.109-2.48-2.476-2.48H18.57v-.93c0-.856-.692-1.55-1.547-1.55H1.548C.693.5 0 1.194 0 2.05v27.9c0 .856.693 1.55 1.548 1.55h15.476c.855 0 1.547-.694 1.547-1.55z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconCreditCardReader.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
