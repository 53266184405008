import orderBy from 'lodash/orderBy';
import { getItemPrice } from '../orderEngine/utilities';
import { isObjectEmpty, isObjectNotEmpty, getPercentage } from '../util/utils';

const updateSizeFun = (currentItem, sizeId, variantId) => {
  let currentItemObj = { ...currentItem };
  if (currentItem.itemType === 0) {
    currentItemObj = {
      ...currentItem,
      selectedSizes: currentItem.selectedSizes.map((size) => {
        if (size._id === sizeId) {
          return {
            ...size,
            quantity: currentItem.isQuarter
              ? 0.25
              : currentItem.isHalf
              ? 0.5
              : 1,
            variants: size.variants?.map((variant) => {
              if (variant._id === variantId) {
                return {
                  ...variant,
                  quantity: currentItem.isQuarter
                    ? 0.25
                    : currentItem.isHalf
                    ? 0.5
                    : 1,
                };
              }
              return {
                ...variant,
                quantity: 0,
              };
            }),
          };
        } else {
          return {
            ...size,
            quantity: 0,
            variants: size.variants?.map((variant) => ({
              ...variant,
              quantity: 0,
            })),
          };
        }
      }),
    };
    currentItemObj.quantity = getItemQuantityFun(currentItemObj);
  }

  return currentItemObj;
};

const updateModifierFun = (
  currentItem,
  subModifierId,
  modifierId,
  variantId,
  itemBaseSubModifierId
) => {
  let currentItemObj = { ...currentItem };
  let selectedModifiers = [];
  let subModifierPriceAlreadySelected = null;
  if (currentItemObj.itemType === 1) {
    selectedModifiers = currentItem.selectedModifiers.map((modifier) => {
      // check if a sub modifier price already selected
      subModifierPriceAlreadySelected = modifier.subModifiers?.find(
        (subModifier) => subModifier.prices?.some((price) => !!price.quantity)
      );

      if (modifier._id === modifierId) {
        if (
          modifierId === currentItem.baseModifierId &&
          modifier.subModifiers?.length
        ) {
          //Base modifiers
          return {
            ...modifier,
            subModifiers: modifier.subModifiers.map((subModifier) => {
              if (subModifier._id === subModifierId) {
                return {
                  ...subModifier,
                  quantity: currentItem.isQuarter
                    ? 0.25
                    : currentItem.isHalf
                    ? 0.5
                    : 1,
                  variants: subModifier.variants?.map((variant) => {
                    if (variant._id === variantId) {
                      return {
                        ...variant,
                        quantity: currentItem.isQuarter
                          ? 0.25
                          : currentItem.isHalf
                          ? 0.5
                          : 1,
                      };
                    } else {
                      return {
                        ...variant,
                        quantity: 0,
                      };
                    }
                  }),
                };
              } else {
                return {
                  ...subModifier,
                  quantity: 0,
                  variants: subModifier.variants?.map((variant) => ({
                    ...variant,
                    quantity: 0,
                  })),
                };
              }
            }),
          };
          // currentItemObj.selectedSizes = [...modifier.subModifiers];
        } else {
          //Other modifiers
          return {
            ...modifier,
            subModifiers: modifier.subModifiers.map((subModifier) => {
              if (subModifier._id === subModifierId) {
                return {
                  ...subModifier,
                  prices: subModifier.prices?.map((priceObj) => {
                    if (priceObj._id === itemBaseSubModifierId) {
                      return {
                        ...priceObj,
                        quantity: currentItem.isQuarter
                          ? 0.25
                          : currentItem.isHalf
                          ? 0.5
                          : 1,
                        variants: priceObj.variants?.map((variant) => {
                          if (variant._id === variantId) {
                            return {
                              ...variant,
                              quantity: currentItem.isQuarter
                                ? 0.25
                                : currentItem.isHalf
                                ? 0.5
                                : 1,
                            };
                          } else {
                            return {
                              ...variant,
                              quantity: 0,
                            };
                          }
                        }),
                      };
                    } else {
                      return {
                        ...priceObj,
                        quantity: 0,
                        variants: priceObj.variants?.map((variant) => ({
                          ...variant,
                          quantity: 0,
                        })),
                      };
                    }
                  }),
                };
              } else {
                return {
                  ...subModifier,
                  prices: subModifier.prices?.map((priceObj) => ({
                    ...priceObj,
                    quantity: 0,
                    variants: priceObj.variants?.map((variant) => ({
                      ...variant,
                      quantity: 0,
                    })),
                  })),
                };
              }
            }),
          };
        }
      } else {
        if (
          subModifierPriceAlreadySelected &&
          modifierId === currentItem.baseModifierId
        ) {
          // check for the selected submodifier to match the price
          return {
            ...modifier,
            subModifiers: modifier.subModifiers.map((subModifier) => {
              return {
                ...subModifier,
                prices:
                  subModifier._id === subModifierPriceAlreadySelected._id
                    ? subModifier.prices?.map((priceObj) => {
                        if (priceObj._id === subModifierId) {
                          return {
                            ...priceObj,
                            quantity: 1,
                            variants: priceObj.variants?.map((variant) => {
                              if (variant._id === variantId) {
                                return {
                                  ...variant,
                                  quantity: currentItem.isQuarter
                                    ? 0.25
                                    : currentItem.isHalf
                                    ? 0.5
                                    : 1,
                                };
                              } else {
                                return {
                                  ...variant,
                                  quantity: 0,
                                };
                              }
                            }),
                          };
                        } else {
                          return {
                            ...priceObj,
                            quantity: 0,
                            variants: priceObj.variants?.map((variant) => ({
                              ...variant,
                              quantity: 0,
                            })),
                          };
                        }
                      })
                    : [...(subModifier.prices || [])],
              };
            }),
          };
        } else {
          return { ...modifier };
        }
      }
    });
  }
  return {
    ...currentItemObj,
    selectedModifiers,
  };
};

const checkItemReadyForOrderFun = (currentItem) => {
  let readyForOrderObj = { itemQuantity: 0, isReady: false };
  if (currentItem.itemType === 0) {
    currentItem.selectedSizes.forEach((size) => {
      if (size.quantity) {
        readyForOrderObj.itemQuantity =
          readyForOrderObj.itemQuantity + Number(size.quantity);
        readyForOrderObj.isReady = true;
      }
    });
  } else if (currentItem.itemType === 1) {
    let count = 0;
    let selectedSubModifierId = '';

    orderBy(currentItem.selectedModifiers, 'displayOrder').forEach(
      (modifier) => {
        //Base modifiers
        modifier.subModifiers.forEach((subModifier) => {
          if (
            modifier._id === currentItem.baseModifierId &&
            subModifier.quantity
          ) {
            count = count + 1;
            readyForOrderObj.itemQuantity = subModifier.quantity;
            selectedSubModifierId = subModifier._id;
            return;
          }
          subModifier.prices?.forEach((priceObj) => {
            if (priceObj._id === selectedSubModifierId && priceObj.quantity) {
              count = count + 1;
              return;
            }
          });
        });
      }
    );

    if (currentItem.selectedModifiers.length === count)
      readyForOrderObj.isReady = true;
  }

  return readyForOrderObj;
};

const getItemBaseSubModifierIdFun = (currentItem) => {
  let baseSubModifierId = '';
  if (currentItem.itemType === 1) {
    currentItem.selectedModifiers.map((modifier) => {
      if (
        modifier._id === currentItem.baseModifierId &&
        modifier.subModifiers &&
        modifier.subModifiers.length > 0
      ) {
        //Base modifiers
        modifier.subModifiers.map((subModifier) => {
          if (Number(subModifier.quantity) > 0) {
            baseSubModifierId = subModifier._id;
          }
          return subModifier;
        });
      }
      return modifier;
    });
  }
  return baseSubModifierId;
};

const getItemQuantityFun = (currentItem) => {
  if (currentItem.isHalf) {
    return currentItem.quantity ? currentItem.quantity : 0.5;
  } else if (currentItem.isQuarter) {
    return currentItem.quantity ? currentItem.quantity : 0.25;
  } else {
    return currentItem.quantity ? currentItem.quantity : 1;
  }
};

export const reduceQuantityToOne = (currentItem) => {
  let quantity = getItemQuantityFun(currentItem);
  let currentItemObj = { ...currentItem };

  if (currentItemObj.itemType === 0) {
    currentItemObj = {
      ...currentItemObj,
      selectedSizes: currentItemObj.selectedSizes.map((size) => {
        return {
          ...size,
          quantity: size.quantity ? 1 : 0,
          variants: size.variants?.map((variant) => {
            return {
              ...variant,
              quantity: variant.quantity ? 1 : 0,
            };
          }),
        };
      }),
    };
  } else if (currentItem.itemType === 1) {
    let subModifiers = [];
    currentItemObj = {
      ...currentItemObj,
      selectedModifiers: currentItemObj.selectedModifiers?.map((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          subModifiers = modifier.subModifiers?.map((sm) => ({
            ...sm,
            quantity: sm.quantity ? 1 : 0,
            variants: sm.variants?.map((variant) => {
              return {
                ...variant,
                quantity: variant.quantity ? 1 : 0,
              };
            }),
          }));
          return {
            ...modifier,
            subModifiers,
          };
        } else {
          return {
            ...modifier,
            subModifiers: modifier.subModifiers?.map((subModifier) => ({
              ...subModifier,
              prices: subModifier.prices?.map((priceObj) => ({
                ...priceObj,
                quantity: priceObj.quantity ? 1 : 0,
                variants: priceObj.variants.map((pv) => ({
                  ...pv,
                  quantity: pv.quantity ? 1 : 0,
                })),
              })),
            })),
          };
        }
      }),
      selectedSizes: subModifiers,
    };
  }

  // quantity global for the root menu item object
  currentItemObj.quantity = 1;

  return currentItemObj;
};

const incrementItemQuantityFun = (currentItem) => {
  let quantity = getItemQuantityFun(currentItem);
  let currentItemObj = { ...currentItem };

  if (currentItemObj.itemType === 0) {
    currentItemObj = {
      ...currentItemObj,
      selectedSizes: currentItemObj.selectedSizes.map((size) => {
        return {
          ...size,
          quantity: size.quantity ? size.quantity + 1 : 0,
          variants: size.variants?.map((variant) => {
            return {
              ...variant,
              quantity: variant.quantity ? variant.quantity + 1 : 0,
            };
          }),
        };
      }),
    };
  } else if (currentItem.itemType === 1) {
    let subModifiers = [];
    currentItemObj = {
      ...currentItemObj,
      selectedModifiers: currentItemObj.selectedModifiers?.map((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          subModifiers = modifier.subModifiers?.map((sm) => ({
            ...sm,
            quantity: sm.quantity ? sm.quantity + 1 : 0,
            variants: sm.variants?.map((variant) => {
              return {
                ...variant,
                quantity: variant.quantity ? variant.quantity + 1 : 0,
              };
            }),
          }));
          return {
            ...modifier,
            subModifiers,
          };
        } else {
          return {
            ...modifier,
            subModifiers: modifier.subModifiers?.map((subModifier) => ({
              ...subModifier,
              prices: subModifier.prices?.map((priceObj) => ({
                ...priceObj,
                quantity: priceObj.quantity ? priceObj.quantity + 1 : 0,
                variants: priceObj.variants.map((pv) => ({
                  ...pv,
                  quantity: pv.quantity ? pv.quantity + 1 : 0,
                })),
              })),
            })),
          };
        }
      }),
      selectedSizes: subModifiers,
    };
  }

  // quantity global for the root menu item object
  currentItemObj.quantity = quantity + 1;

  return currentItemObj;
};

// also works for current order menuItem repeat
export const incrementItemQuantitySingleSizeFun = (
  currentItem,
  offset,
  ignoreSizeVariant = true
) => {
  let currentItemObj = { ...currentItem };

  if (currentItemObj.itemType === 0) {
    currentItemObj = {
      ...currentItemObj,
      isSingleSize: true,
      selectedSizes: currentItemObj.selectedSizes.map((size) => ({
        ...size,
        quantity: offset ?? 1,
        variants: !ignoreSizeVariant
          ? size.variants?.map((variant) => ({
              ...variant,
              quantity: offset ?? 1,
            }))
          : [],
      })),
    };
  } else if (currentItem.itemType === 1) {
    currentItemObj = {
      ...currentItemObj,
      isSingleSize: true,
      selectedModifiers: currentItemObj.selectedModifiers.map((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          //Base modifiers
          let subModifiers = modifier.subModifiers?.map((subModifier) => {
            return {
              ...subModifier,
              quantity: offset ?? 1,
              variants: !ignoreSizeVariant
                ? subModifier.variants.map((variant) => ({
                    ...variant,
                    quantity: offset ?? 1,
                  }))
                : [],
            };
          });
          currentItemObj.selectedSizes = subModifiers;
          return {
            ...modifier,
            subModifiers,
          };
        } else {
          //Other modifiers
          return {
            ...modifier,
            subModifiers: modifier.subModifiers?.map((subModifier) => {
              return {
                ...subModifier,
                prices: subModifier.prices?.map((price) => ({
                  ...price,
                  variants: !ignoreSizeVariant
                    ? price.variants?.map((variant) => ({
                        ...variant,
                        quantity: offset ?? 1,
                      }))
                    : [],
                })),
              };
            }),
          };
        }
      }),
    };
  }

  // quantity global for the root menu item object
  if (offset) {
    currentItemObj.quantity = offset;
  } else {
    currentItemObj.quantity = 1;
  }

  return currentItemObj;
};

const decrementItemQuantityFun = (currentItem) => {
  let quantity = getItemQuantityFun(currentItem);
  let currentItemObj = { ...currentItem };

  if (currentItemObj.itemType === 0) {
    currentItemObj = {
      ...currentItemObj,
      selectedSizes: currentItemObj.selectedSizes.map((size) => {
        return {
          ...size,
          quantity: size.quantity ? size.quantity - 1 : 0,
          variants: size.variants?.map((variant) => {
            return {
              ...variant,
              quantity: variant.quantity ? variant.quantity - 1 : 0,
            };
          }),
        };
      }),
    };
  } else if (currentItem.itemType === 1) {
    let subModifiers = [];
    currentItemObj = {
      ...currentItemObj,
      selectedModifiers: currentItemObj.selectedModifiers?.map((modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          subModifiers = modifier.subModifiers?.map((sm) => ({
            ...sm,
            quantity: sm.quantity ? sm.quantity - 1 : 0,
            variants: sm.variants?.map((variant) => {
              return {
                ...variant,
                quantity: variant.quantity ? variant.quantity - 1 : 0,
              };
            }),
          }));
          return {
            ...modifier,
            subModifiers,
          };
        } else {
          return {
            ...modifier,
            subModifiers: modifier.subModifiers?.map((subModifier) => ({
              ...subModifier,
              prices: subModifier.prices?.map((priceObj) => ({
                ...priceObj,
                quantity: priceObj.quantity ? priceObj.quantity - 1 : 0,
                variants: priceObj.variants.map((pv) => ({
                  ...pv,
                  quantity: pv.quantity ? pv.quantity - 1 : 0,
                })),
              })),
            })),
          };
        }
      }),
      selectedSizes: subModifiers,
    };
  }

  // quantity global for the root menu item object
  currentItemObj.quantity = quantity - 1;

  return currentItemObj;
};

const updateIngredientFun = (currentItem, ingredientId) => {
  let currentItemObj = JSON.parse(JSON.stringify(currentItem));
  currentItemObj.selectedIngredients.map((ingredient) => {
    if (ingredient._id === ingredientId)
      ingredient.isRemoved = !ingredient.isRemoved;
    return ingredient;
  });
  return currentItemObj;
};

const resetIngredientFun = (currentItem) => {
  let currentItemObj = JSON.parse(JSON.stringify(currentItem));
  currentItemObj.selectedIngredients.map((ingredient) => {
    ingredient.isRemoved = false;
    return ingredient;
  });
  return currentItemObj;
};

const getIngredientArraysForSlugFun = (currentItem, customizeSlug) => {
  let dataArray = [];
  if (customizeSlug === 'remove') {
    dataArray = currentItem.selectedIngredients
      ? [...currentItem.selectedIngredients]
      : [];
  } else if (customizeSlug === 'add') {
    dataArray = currentItem.selectedExtraIngredients
      ? [...currentItem.selectedExtraIngredients]
      : [];
  }
  return dataArray;
};

const getCustomizationTitleFun = (itemCustomizationOptions, customizeSlug) => {
  let title = '';
  title = itemCustomizationOptions
    .filter((f) => f.slug === customizeSlug)
    .map((m) => m.title)
    .join(',');
  return title;
};

const updateExtraIngredientFun = (
  currentItem,
  ingredientId,
  ingredientPrice,
  quantity
) => {
  let currentItemObj = JSON.parse(JSON.stringify(currentItem));
  currentItemObj.selectedExtraIngredients.map((ingredient) => {
    if (ingredient._id === ingredientId) {
      if (quantity && quantity > 1 && ingredient.selected) {
        ingredient.quantity = quantity;
      } else {
        if (ingredient.selected) {
          ingredient.quantity = 0;
          ingredient.price = 0;
          ingredient.selected = false;
        } else {
          ingredient.quantity = 1;
          ingredient.price = ingredientPrice;
          ingredient.selected = true;
        }
      }
    }
    return ingredient;
  });
  return currentItemObj;
};

const resetExtraIngredientFun = (currentItem) => {
  let currentItemObj = JSON.parse(JSON.stringify(currentItem));
  currentItemObj.selectedExtraIngredients.map((ingredient) => {
    if (ingredient.quantity && ingredient.quantity > 0) {
      ingredient.quantity = 0;
      ingredient.price = 0;
      ingredient.selected = false;
    }

    return ingredient;
  });

  return currentItemObj;
};

const resetAllIngredientFun = (currentItem) => {
  let currentItemObj = JSON.parse(JSON.stringify(currentItem));
  currentItemObj.selectedExtraIngredients.map((ingredient) => {
    if (ingredient.quantity && ingredient.quantity > 0) {
      ingredient.quantity = 0;
      ingredient.price = 0;
      ingredient.selected = false;
    }

    return ingredient;
  });

  currentItemObj.selectedIngredients.map((ingredient) => {
    ingredient.isRemoved = false;
    return ingredient;
  });

  return currentItemObj;
};

const getExtraPriceForDisplayFun = (currentItem, prices, price) => {
  let ingredientPrice = 0;
  let selectedSizeId = '',
    selectedVariantId = '';
  if (currentItem && currentItem.selectedSizes) {
    currentItem.selectedSizes.forEach((size) => {
      if (size.quantity && size.quantity > 0) {
        if (size.variants) {
          selectedSizeId = size._id;
          size.variants.forEach((variant) => {
            if (variant.quantity && variant.quantity > 0) {
              selectedVariantId = variant._id;
            }
          });
        }
      }
    });
  }
  if (
    currentItem &&
    currentItem.selectedModifiers &&
    currentItem.itemType !== 0
  ) {
    currentItem.selectedModifiers.forEach((modifier) => {
      if (
        modifier?.name?.toLowerCase().includes('size') &&
        modifier.subModifiers
      ) {
        modifier.subModifiers.forEach((size) => {
          if (size.quantity && size.quantity > 0) {
            if (size.variants) {
              selectedSizeId = size._id;
              size.variants.forEach((variant) => {
                if (variant.quantity && variant.quantity > 0) {
                  selectedVariantId = variant._id;
                }
              });
            }
          }
        });
      }
    });
  }
  if (!prices && price) {
    ingredientPrice = price;
  } else if (prices) {
    let cost = 0;
    prices.map((p) => {
      if (p._id === selectedSizeId) {
        cost = cost = Number(p.price);
        if (p.variants) {
          p.variants.map((v) => {
            if (v._id === selectedVariantId) {
              cost = cost + Number(v.price);
            }
            return v;
          });
        }
      }
      return p;
    });
    ingredientPrice = cost;
  }
  return ingredientPrice;
};

const prepareItemForOrderFun = (
  currentItem,
  currentOrder,
  productSetup = {}
) => {
  let itemObj = { ...currentItem };

  if (!itemObj.itemType) {
    itemObj.selectedSizes = currentItem.selectedSizes
      ?.filter((s) => s.quantity)
      .map((s) => {
        return {
          ...s,
          variants: s.variants?.filter((v) => {
            return v.quantity;
          }),
        };
      });
  } else {
    // Fillup data
    itemObj.selectedModifiers = currentItem.selectedModifiers.map(
      (modifier) => {
        if (modifier._id === currentItem.baseModifierId) {
          return {
            ...modifier,
            isSelected: true,
            subModifiers: modifier.subModifiers
              .map((subModifier) => ({
                ...subModifier,
                isSelected: !!subModifier.quantity,
                variants: subModifier.variants?.filter(
                  (v) => v.quantity && v.isSelected
                ),
              }))
              .filter((sm) => sm.isSelected), // remove not selected submodifiers
          };
        } else {
          return {
            ...modifier,
            isSelected: true,
            subModifiers: modifier.subModifiers
              .map((subModifier) => ({
                ...subModifier,
                isSelected: true,
                prices: subModifier.prices
                  ?.map((p) => {
                    return {
                      ...p,
                      isSelected: !!p.quantity,
                      variants: p.variants?.filter(
                        (v) => v.quantity && v.isSelected
                      ),
                    };
                  })
                  .filter((price) => price.quantity && price.isSelected),
              }))
              .filter((sm) => sm.prices?.length), // remove empty prices submodifiers
          };
        }
      }
    );

    // Step 2: Cleanup submodifiers
    itemObj.selectedModifiers.forEach((m) => {
      if (m._id === itemObj.baseModifierId)
        itemObj.selectedSizes = m.subModifiers;
    });

    // Step 3: Composite name for size
    let subsMs = [];
    itemObj.selectedModifiers.forEach((m) => {
      if (m.displayOrder !== 0) {
        subsMs = [...subsMs, ...m.subModifiers];
      }
    });

    itemObj.selectedSizes = itemObj.selectedSizes.map((s) => {
      return {
        ...s,
        cName: subsMs.map((m) => m.name).join(','),
      };
    });
  }

  // Item extras
  itemObj.selectedExtraIngredients =
    itemObj.selectedExtraIngredients?.filter(
      (m) => m.quantity && Number(m.quantity) > 0
    ) || [];
  itemObj.selectedExtraIngredients = itemObj.selectedExtraIngredients?.map(
    (m) => ({
      ...m,
      prices: undefined,
    })
  );

  // Item ingredients
  if (!isObjectNotEmpty(itemObj.removedIngredients)) {
    let itemRemovedIngredients = [];
    itemRemovedIngredients = itemObj.selectedIngredients.filter((m) => {
      return m.isRemoved === true;
    });
    itemObj.removedIngredients = itemRemovedIngredients;
  } else if (itemObj.selectedIngredients) {
    let itemIngredients = [];
    itemIngredients = itemObj.selectedIngredients.filter((m) => {
      return m.isRemoved === false;
    });
    itemObj.selectedIngredients = itemIngredients;
  }

  // Add iteration to item
  itemObj.iteration = currentOrder.iteration ? currentOrder.iteration : 0;

  // Delete additional data
  if (itemObj.keywords) delete itemObj.keywords;
  if (itemObj.stores) delete itemObj.stores;
  if (itemObj.orderTypes) delete itemObj.orderTypes;
  if (itemObj.orderFrom) delete itemObj.orderFrom;
  if (itemObj.orderFromKeys) delete itemObj.orderFromKeys;
  if (itemObj.orderTypeKeys) delete itemObj.orderTypeKeys;

  const { itemPrice, extraPrice, payablePrice } = getItemPrice(
    itemObj,
    productSetup
  );

  itemObj.quantity = getItemQuantityFun(itemObj);
  itemObj.itemPrice = itemPrice;
  itemObj.extraPrice = extraPrice * itemObj.quantity; // for proper extraPrice calculation for Half items
  itemObj.payablePrice = payablePrice;

  return itemObj;
};

const isItemWithSingleSizeFun = (currentItem = {}) => {
  let flag = false;
  if (currentItem?.itemType === 0) {
    if (currentItem.selectedSizes) {
      let count = 0;
      currentItem.selectedSizes.forEach((s) => {
        count = count + 1;
        if (s.variants?.filter((variant) => variant.isSelected)?.length) {
          count = count + 1;
        }
      });
      if (count <= 1) flag = true;
    }
  } else if (currentItem?.itemType === 1) {
  }
  return flag;
};

export const doesItemHaveModifiers = (item) => {
  if (item?.selectedModifiers?.length > 0) {
    return true;
  }
  return false;
};

const getSizeBubbleTextFun = (selectedSizes) => {
  if (selectedSizes.length > 0) {
    let matchingSizes = selectedSizes.filter((f) => f.quantity > 0);
    if (matchingSizes.length > 0) {
      let matchedSize = matchingSizes[0];
      if (matchedSize.variants) {
        let matchingVariants = matchedSize.variants.filter(
          (f) => f.quantity > 0
        );
        if (matchingVariants.length > 0) {
          let matchedVariant = matchingVariants[0];
          return `${matchedSize.name ? matchedSize.name : ''}(${
            matchedVariant.name
          })`;
        } else {
          return matchedSize.name ? matchedSize.name : '';
        }
      } else {
        return matchedSize.name ? matchedSize.name : '';
      }
    } else return '';
  } else return '';
};

const getModifierBubbleTextFun = (currentItem, modifierId) => {
  let bubbleText = '';
  if (
    currentItem &&
    currentItem.selectedModifiers &&
    currentItem.selectedModifiers.length > 0
  ) {
    currentItem.selectedModifiers.map((modifier) => {
      if (modifier._id === modifierId) {
        if (modifier._id === currentItem.baseModifierId) {
          if (modifier.subModifiers && modifier.subModifiers.length > 0) {
            let matchingSubModifiers = modifier.subModifiers.filter(
              (f) => f.quantity > 0
            );
            if (matchingSubModifiers.length > 0) {
              let matchingSubModifier = matchingSubModifiers[0];
              if (matchingSubModifier.variants) {
                let matchingVariants = matchingSubModifier.variants.filter(
                  (f) => f.quantity > 0
                );
                if (matchingVariants.length > 0) {
                  let matchedVariant = matchingVariants[0];
                  bubbleText = `${
                    matchingSubModifier.name ? matchingSubModifier.name : ''
                  }(${matchedVariant.name})`;
                } else {
                  bubbleText = matchingSubModifier.name
                    ? matchingSubModifier.name
                    : '';
                }
              } else {
                bubbleText = matchingSubModifier.name
                  ? matchingSubModifier.name
                  : '';
              }
            }
          }
        } else {
          if (modifier.subModifiers && modifier.subModifiers.length > 0) {
            modifier.subModifiers.map((s) => {
              if (s.prices && s.prices.length > 0) {
                let matchingPrices = s.prices.filter((f) => f.quantity > 0);
                if (matchingPrices.length > 0) {
                  bubbleText = s.name ? s.name : '';
                }
              }
              return s;
            });
          }
        }
      }
      return modifier;
    });
  }
  return bubbleText;
};

const applyItemDiscountAndVoucherFun = (currentItem, productSetup = {}) => {
  if (isObjectEmpty(currentItem)) return currentItem;

  const quantity = getItemQuantityFun(currentItem);
  const { payablePrice: totalPrice, originalPrice } = getItemPrice(
    currentItem,
    productSetup
  );

  const selectedDiscount =
    isObjectNotEmpty(currentItem.selectedDiscount) &&
    currentItem.selectedDiscount;
  const selectedVoucher =
    isObjectNotEmpty(currentItem.selectedVoucher) &&
    currentItem.selectedVoucher;
  const isPriceAdjusted = selectedDiscount && selectedDiscount.isPriceAdjusted;

  let newDiscountedPrice = '';
  let newVoucherPrice = '';

  if (selectedDiscount) {
    const discount = Number(selectedDiscount.discount);

    if (Number(selectedDiscount?.discountType) === 1) {
      // calculate discount % from regular price
      newDiscountedPrice = totalPrice - getPercentage(discount, totalPrice);
    } else {
      let discountAmount = '';

      // re-calculate the discount when the price has been manually changed
      if (isPriceAdjusted) {
        // calculate discount $ from an adjusted price
        newDiscountedPrice = originalPrice * quantity - discount * quantity;
      } else {
        // $ discount have to be reduce per halfs and quarters items
        if (currentItem.isHalf) {
          discountAmount = Number(discount) / 2;
        } else if (currentItem.isQuarter) {
          discountAmount = Number(discount) / 4;
        } else {
          discountAmount = Number(discount);
        }

        // calculate discount $ from regular price
        newDiscountedPrice = totalPrice - discountAmount;
      }
    }
  }

  if (selectedVoucher) {
    const voucher = Number(selectedVoucher.discount);

    // check if discount has been already affected the price
    // and the pay price is more than the voucher
    if (newDiscountedPrice && newDiscountedPrice >= voucher) {
      newVoucherPrice = newDiscountedPrice - voucher;
      // check if discount has been already affected the price
      // and the pay price is less than the voucher amount
    } else if (newDiscountedPrice && newDiscountedPrice <= voucher) {
      newVoucherPrice = 0;
      // otherwise calculate voucher from the regular price
    } else if (totalPrice >= voucher) {
      newVoucherPrice = totalPrice - voucher;
    } else {
      newVoucherPrice = 0;
    }
  }

  let currentItemObj = { ...currentItem };

  // calculation of the price always ends with the voucher calculation
  const newPayablePrice =
    newVoucherPrice || newVoucherPrice === 0 // check for the voucher price even if its 0
      ? newVoucherPrice
      : newDiscountedPrice || newDiscountedPrice === 0 // check for the discount price even if its 0
      ? newDiscountedPrice
      : totalPrice; // otherwise return regular price

  currentItemObj = {
    ...currentItemObj,
    discountPrice: selectedDiscount ? newDiscountedPrice : null,
    voucherPrice: selectedVoucher ? newVoucherPrice : null,
    payablePrice: newPayablePrice > 0 ? newPayablePrice : 0,
    quantity,
    appliedDiscountId: selectedDiscount ? selectedDiscount._id : null,
    appliedVoucherId: selectedVoucher ? selectedVoucher._id : null,
  };

  return currentItemObj;
};

export const updateSize = updateSizeFun;
export const updateModifier = updateModifierFun;
export const checkItemReadyForOrder = checkItemReadyForOrderFun;
export const getItemBaseSubModifierId = getItemBaseSubModifierIdFun;
export const incrementItemQuantity = incrementItemQuantityFun;
export const decrementItemQuantity = decrementItemQuantityFun;
export const updateIngredient = updateIngredientFun;
export const resetIngredient = resetIngredientFun;
export const updateExtraIngredient = updateExtraIngredientFun;
export const resetExtraIngredient = resetExtraIngredientFun;
export const resetAllIngredient = resetAllIngredientFun;
export const getIngredientArraysForSlug = getIngredientArraysForSlugFun;
export const getCustomizationTitle = getCustomizationTitleFun;
export const getExtraPriceForDisplay = getExtraPriceForDisplayFun;
export const prepareItemForOrder = prepareItemForOrderFun;
export const isItemWithSingleSize = isItemWithSingleSizeFun;
export const incrementItemQuantitySingleSize =
  incrementItemQuantitySingleSizeFun;
export const getSizeBubbleText = getSizeBubbleTextFun;
export const getModifierBubbleText = getModifierBubbleTextFun;
export const applyItemDiscountAndVoucher = applyItemDiscountAndVoucherFun;
