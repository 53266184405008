import React from 'react';
import './bem-navbar.scss';
import { ScrollableBoxUI } from '../index';
import PropTypes from 'prop-types';
import { getUIClassesFromProps } from '../modifiersMapping';

export const NavBarUI = ({ children, modifierClassName }) => {
  return (
    <div className={getUIClassesFromProps('bem-navbar', { modifierClassName })}>
      <div className="bem-navbar__inner">{children}</div>
    </div>
  );
};

NavBarUI.defaultProps = {
  modifierClassName: '',
  children: null,
};

NavBarUI.propTypes = {
  modifierClassName: PropTypes.string,
  children: PropTypes.node,
};

function Header({ children }) {
  return <div className="bem-navbar__header">{children}</div>;
}

NavBarUI.Header = Header;

NavBarUI.Header.defaultProps = {
  children: null,
};

NavBarUI.Header.propTypes = {
  children: PropTypes.node,
};

function Body({ children }) {
  return (
    <div className="bem-navbar__body">
      <ScrollableBoxUI>{children}</ScrollableBoxUI>
    </div>
  );
}

NavBarUI.Body = Body;

NavBarUI.Body.defaultProps = {
  children: null,
};

NavBarUI.Body.propTypes = {
  children: PropTypes.node,
};

function Footer({ children }) {
  return <div className="bem-navbar__footer">{children}</div>;
}

NavBarUI.Footer = Footer;

NavBarUI.Footer.defaultProps = {
  children: null,
};

NavBarUI.Footer.propTypes = {
  children: PropTypes.node,
};
