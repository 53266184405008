import { PosIcon } from 'assets/icons/PosIcon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import scssVariables from 'assets/scss/global/_variables.module.scss';

const SELECTED_STYLE = {
  borderRadius: '50rem',
  backgroundColor: 'var(--switchGroupActiveBtnBG)',
  transition: 'width 0.25s ease-in, transform 0.25s ease-in',
  transformOrigin: 'center',
  boxShadow: 'var(--switchGroupActiveBtnshadow)',
};

const getItemStyle = (_isDragging, draggableStyle, isSelected) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '1.2',
    letterSpacing: '-0.02em',
    color: 'var(--textColor)',
    textDecoration: 'none !important',
    minWidth: 'auto',
    padding: '5px 14px',
    whiteSpace: 'nowrap',
    position: 'relative',
    textAlign: 'center',

    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : '#eeeeef',

    ...(isSelected ? SELECTED_STYLE : null),
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SwitchFloorTabs = ({
  floors,
  selectedFloorId,
  onTabClick,
  onTabDoubleClick,
  handleReorder,
  handleDeleteFloorLayout,
}) => {
  const onDragStart = (res) => {
    onTabClick(res.draggableId);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reordered = reorder(
      floors,
      result.source.index,
      result.destination.index
    );
    handleReorder(reordered);
  };

  const handleDeleteFloorClick = (floorId) => {
    handleDeleteFloorLayout(floorId);
  };

  const handleTabClick = (floorId) => {
    if (selectedFloorId !== floorId) {
      onTabClick(floorId);
    }
  };

  const handleTabDoubleClick = (floorId) => {
    onTabDoubleClick?.(floorId);
  };

  return (
    <div className="floor-layout-tabs">
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, _snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: 'flex',
              }}
              className="bem-switch-group"
            >
              {floors.map((floor, index) => (
                <Draggable key={floor.id} draggableId={floor.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        selectedFloorId === floor.id
                      )}
                      onClick={() => handleTabClick(floor.id)}
                      onDoubleClick={() => handleTabDoubleClick(floor.id)}
                    >
                      {floor.title}
                      {selectedFloorId === floor.id && (
                        <div
                          className="floor-layout-tabs__delete-icon"
                          onClick={() => handleDeleteFloorClick(floor.id)}
                        >
                          <PosIcon
                            name="check-minus-solid"
                            size="custom"
                            customWith={20}
                            customHeight={20}
                            mainColor={scssVariables.posRed}
                            darkModeColor={scssVariables.posRed}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SwitchFloorTabs;
