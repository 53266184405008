const newFloorLayout = {
  name: '',
  dineInObjects: [],
  layoutDividers: [],
  nonDineInObjects: [],
};

export const getFloorLayoutIndex = (floorLayouts, floorLayoutId) =>
  floorLayouts?.findIndex((floorItem) => floorItem.id === floorLayoutId);

export const getFloorLayoutOnCreate = (floorLayouts, newFloorLayoutObject) => {
  const floorIndex = getFloorLayoutIndex(floorLayouts, newFloorLayoutObject.id);

  if (floorIndex === -1) {
    return [
      ...floorLayouts,
      {
        ...newFloorLayoutObject,
        dineInObjects: [],
        layoutDividers: [],
        nonDineInObjects: [],
      },
    ];
  }

  return [
    ...floorLayouts?.slice(0, floorIndex),
    {
      ...floorLayouts[floorIndex],
      ...newFloorLayoutObject,
    },
    ...floorLayouts.slice(floorIndex + 1),
  ];
};

export const addObjectListsToFloorLayout = (
  floorLayouts,
  floorLayoutInfo,
  objectListsToAdd
) => {
  const { id: floorLayoutId } = floorLayoutInfo;
  const floorIndex = getFloorLayoutIndex(floorLayouts, floorLayoutId);

  if (floorIndex === -1) {
    return [
      ...floorLayouts,
      { ...newFloorLayout, ...floorLayoutInfo, ...objectListsToAdd },
    ];
  }

  const floorToBeUpdated = floorLayouts[floorIndex];
  const updatedObjects = {};
  Object.entries(objectListsToAdd).forEach(([objectType, newObjectsToAdd]) => {
    updatedObjects[objectType] = [
      ...floorToBeUpdated[objectType],
      ...newObjectsToAdd,
    ];
  });

  return [
    ...floorLayouts?.slice(0, floorIndex),
    {
      ...floorLayouts[floorIndex],
      ...updatedObjects,
      ...floorLayoutInfo,
    },
    ...floorLayouts.slice(floorIndex + 1),
  ];
};

export const updateObjectListsInFloorLayout = (
  floorLayouts,
  floorLayoutInfo,
  objectListsToAdd
) => {
  const { id: floorLayoutId } = floorLayoutInfo;
  const floorIndex = getFloorLayoutIndex(floorLayouts, floorLayoutId);

  const floorToBeUpdated = { ...floorLayouts[floorIndex] };
  const updatedObjects = {};

  Object.entries(objectListsToAdd).forEach(([objectType, newObjectsToAdd]) => {
    const objectList = [...floorToBeUpdated[objectType]];
    newObjectsToAdd.forEach((newObject) => {
      const objectIndex = objectList?.findIndex(
        (item) => item.id === newObject.id
      );
      objectList[objectIndex] = newObject;
    });
    updatedObjects[objectType] = objectList;
  });

  return [
    ...floorLayouts?.slice(0, floorIndex),
    {
      ...floorToBeUpdated,
      ...floorLayoutInfo,
      ...updatedObjects,
    },
    ...floorLayouts.slice(floorIndex + 1),
  ];
};

export const removeObjectsFromFloorLayout = (
  floorLayouts,
  floorLayoutInfo,
  objectToBeRemoved,
  floorLayoutObjectType
) => {
  const { id: floorLayoutId } = floorLayoutInfo;
  const floorIndex = getFloorLayoutIndex(floorLayouts, floorLayoutId);
  if (floorIndex === -1) return floorLayouts;

  const floorToBeUpdated = { ...floorLayouts[floorIndex] };
  const objectList = floorToBeUpdated[floorLayoutObjectType];
  const objectToBeRemovedIndex = objectList?.findIndex(
    (item) => item.id === objectToBeRemoved.id
  );
  const updatedObjectList = [
    ...objectList.slice(0, objectToBeRemovedIndex),
    ...objectList.slice(objectToBeRemovedIndex + 1),
  ];

  return [
    ...floorLayouts?.slice(0, floorIndex),
    {
      ...floorToBeUpdated,
      ...{ [floorLayoutObjectType]: updatedObjectList },
    },
    ...floorLayouts.slice(floorIndex + 1),
  ];
};

export const removeFloorLayoutObject = (floorLayouts, floorLayoutInfo) => {
  const { id: floorLayoutId } = floorLayoutInfo;
  const floorIndex = getFloorLayoutIndex(floorLayouts, floorLayoutId);

  if (floorIndex === -1) return floorLayouts;

  return [
    ...floorLayouts?.slice(0, floorIndex),
    ...floorLayouts.slice(floorIndex + 1),
  ];
};
