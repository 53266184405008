export const cashierInitialState = {
  isShowStaffPinModal: false,
  accessDenied: false,
  errorMessage: '',
  isVerifyingPin: false,
  wiggleEffect: false,
  goToRouteAfterAuth: '',
  currentItem: {},
  unpaidOrders: [],
  runFetchOrders: false,
  drawerIsOpening: false,
};
