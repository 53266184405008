import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useVirtualKeyboardContext } from 'context/VirtualKeyboardContext';
import GeneralModal from 'modals/GeneralModal/index-new';
import Button from 'components/Button';
import { PosIconLoading } from 'assets/icons/PosIconLoading';
// import GeneralModal from '../../modals/GeneralModal/index-new';
// import Button from '../Button';
// import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

const AddFloorModal = ({
  modalStatus,
  onModalClose,
  onBackClick,
  onNextClick,
  isFetching,
  createFloorLayoutLoading,
  currentFloorName = '',
}) => {
  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    inputNames,
    onInitInput,
  } = useVirtualKeyboardContext();
  const inputName = inputNames.floorModalName;

  const inputRef = useRef(null);
  const timeout = useRef(0);

  const handleFocus = () => {
    setInputName(inputName);
    toggleKeyboard(true);
  };

  // const handleBlur = () => {
  //   toggleKeyboard(false);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    toggleKeyboard(false);
    onNextClick(getInputValue(inputName));
  };

  const handleChange = (event) => {
    event.preventDefault();
    const inputVal = event.target.value;

    onChangeInput(inputName, inputVal);
  };

  React.useEffect(() => {
    if (modalStatus) {
      onInitInput(inputName, currentFloorName || '');
      timeout.current = setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    } else {
      toggleKeyboard(false);
    }

    return () => {
      clearTimeout(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalStatus]);

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={onModalClose}
      position="bottom"
      effect="move"
      overlay="none"
      boxWidth="435px"
      boxHeight="369px"
      className="customer-name-modal"
      backBtn="outside"
      backBtnFnc={onBackClick}
      keyboardVisibility={keyboardVisibility}
    >
      <div className="customer-name-modal-box">
        <div className="customer-name-modal-inner">
          <h2 className="customer-name-title">Enter Floor Name</h2>

          <form
            action=""
            className="customer-name-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="single-input-box">
              <label htmlFor="customer-name">Floor Name</label>
              <input
                id={inputName}
                name={inputName}
                // id="customer-name"
                // name="customer-name"
                className="input-style white big"
                value={getInputValue(inputName)}
                onFocus={handleFocus}
                // onBlur={handleBlur}
                onChange={handleChange}
                ref={inputRef}
              />
            </div>
          </form>
        </div>

        <div className="customer-name-btn-box">
          <Button
            className="btn"
            title="Next"
            disabled={isFetching ? true : !getInputValue(inputName)}
            onClick={() => {
              onNextClick(getInputValue(inputName)?.trim());
              // toggleKeyboard(false);
            }}
          >
            {createFloorLayoutLoading ? (
              <PosIconLoading mainColor="#ffffff" />
            ) : (
              'Save Layout'
            )}
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

AddFloorModal.propTypes = {
  type: PropTypes.string,
  modalStatus: PropTypes.bool,
  onModalClose: PropTypes.func,
  hideModal: PropTypes.func,
  onBackClick: PropTypes.func,
  openSearchAddressModal: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default AddFloorModal;
