import React from 'react';
import GeneralModal from '../../../modals/GeneralModal/index-new';
import './SendDropdown.style.css';

const SendDropdown = ({
  show,
  toggleModal,
  sendDropdownRef,
  handleSendCourses,
  courses,
}) => {
  const dropdownPostion = sendDropdownRef.current.getBoundingClientRect();
  return (
    <>
      <GeneralModal
        modalStatus={show}
        toggleModal={toggleModal}
        effect="fade"
        overlay={'default'}
        boxWidth="200px"
        boxHeight="fit-content"
        closeBtn="outside"
        isResponsiveHeight={true}
        paperContianerStyle={{
          position: 'absolute',
          top: `${dropdownPostion?.top - 20}px`,
          left: `${dropdownPostion.left - 137}px`,
          borderRadius: '9px',
          height: 'fit-content',
        }}
      >
        {courses?.map((course) => (
          <div
            key={course?._id}
            className="dropdownItem"
            onClick={() => handleSendCourses(course?._id)}
          >{`Send ${course.name}`}</div>
        ))}
      </GeneralModal>
    </>
  );
};

export default SendDropdown;
