import React from 'react';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';

export const Size = ({
  sizeId,
  variantId,
  name,
  quantity,
  variantQuantity,
  price,
  handleChange,
  currencyCode,
  storeCountryIdentifier,
  isSoldOut,
}) => {
  return (
    <label className="item-detail-card">
      <h3 className="detail-card-title">
        {name} {isSoldOut && <span className="soldout-size-tag">Sold Out</span>}
      </h3>
      <p className="detail-card-price">
        {price
          ? getAmountWithCountryCurrency({
              amount: Number(price),
              locale: `en-${storeCountryIdentifier}`,
              currencyCode,
            })
          : ''}
      </p>

      <span className="detail-card-input-box">
        <input
          type="radio"
          name={`${sizeId}-${variantId}`}
          id={`${sizeId}-${variantId}`}
          value={name}
          checked={
            variantId === 'xxx'
              ? variantQuantity === 0 && quantity > 0
              : variantQuantity > 0
          }
          onChange={(event) => handleChange(event, sizeId, variantId)}
        />
        <span className="checkstyle" />
      </span>
    </label>
  );
};
