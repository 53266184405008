import React from 'react';
import PropTypes from 'prop-types';
import GeneralModal from '../../modals/GeneralModal/index-new';
import Button from '../../components/Button';
import '../../assets/scss/modals/_add-drawer-modal.scss';
import { useVirtualKeyboardContext } from '../../context/VirtualKeyboardContext';

export const PairTerminal = ({
  modalStatus,
  toggleModal,
  pairTerminalHandler,
}) => {
  const {
    keyboardVisibility,
    toggleKeyboard,
    setInputName,
    getInputValue,
    onChangeInput,
    onInitInput,
    inputNames,
  } = useVirtualKeyboardContext();
  const inputUserName = inputNames.pairUserName;
  const inputPassword = inputNames.pairPassword;
  const inputPairCode = inputNames.pairPairCode;
  const inputTerminalName = inputNames.pairTerminalName;

  // const [userName, setUserName] = useState('');
  // const [password, setPassword] = useState('');
  // const [pairCode, setPairCode] = useState('');
  // const [terminalName, setTerminalName] = useState('');

  const handleClick = (event) => {
    event.preventDefault();
    // setUserName('');
    // setPassword('');
    // setPairCode('');
    // setTerminalName('');
    pairTerminalHandler(
      getInputValue(inputUserName),
      getInputValue(inputPassword),
      getInputValue(inputPairCode),
      getInputValue(inputTerminalName)
    );

    onInitInput(inputUserName, '');
    onInitInput(inputPassword, '');
    onInitInput(inputPairCode, '');
    onInitInput(inputTerminalName, '');

    toggleKeyboard(false);
    // pairTerminalHandler(userName, password, pairCode, terminalName);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleFocus = (e, name) => {
    setInputName(name);

    toggleKeyboard(true);
  };

  const handleChange = (e, name) => {
    const inputVal = e.target.value;

    onChangeInput(name, inputVal);
  };

  /*  const handleChangeUserName = (event) => {
      event.preventDefault();
      setUserName(event.target.value);
    };

    const handleChangePassword = (event) => {
      event.preventDefault();
      setPassword(event.target.value);
    };

    const handleChangePairCode = (event) => {
      event.preventDefault();
      setPairCode(event.target.value);
    };

    const handleChangeTerminalName = (event) => {
      event.preventDefault();
      setTerminalName(event.target.value);
    };*/

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={() => {
        if (modalStatus) toggleKeyboard(false);
        toggleModal();
      }}
      position="bottom"
      effect="move"
      overlay=""
      boxWidth="514px"
      boxHeight="480px"
      className="add-drawer-modal"
      closeBtn="inside"
      keyboardVisibility={keyboardVisibility}
    >
      <div className="add-drawer-modal-box">
        <div className="add-drawer-modal-box-inner">
          <h2 className="add-drawer-title">Pair Terminal</h2>
          <form
            action=""
            className="add-drawer-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="single-input-box">
              <input
                id={inputUserName}
                name={inputUserName}
                onFocus={(e) => handleFocus(e, inputUserName)}
                // name="user-name"
                className="input-style white big"
                placeholder="User Name"
                // value={userName}
                value={getInputValue(inputUserName)}
                //onChange={handleChangeUserName}
                onChange={(e) => handleChange(e, inputUserName)}
              />
            </div>
            <div className="single-input-box">
              <input
                id={inputPassword}
                name={inputPassword}
                onFocus={(e) => handleFocus(e, inputPassword)}
                // name="password"
                className="input-style white big"
                placeholder="Password"
                // value={password}
                value={getInputValue(inputPassword)}
                // onChange={handleChangePassword}
                onChange={(e) => handleChange(e, inputPassword)}
              />
            </div>
            <div className="single-input-box">
              <input
                id={inputPairCode}
                name={inputPairCode}
                onFocus={(e) => handleFocus(e, inputPairCode)}
                // name="pair-code"
                className="input-style white big"
                placeholder="Pair Code"
                // value={pairCode}
                value={getInputValue(inputPairCode)}
                // onChange={handleChangePairCode}
                onChange={(e) => handleChange(e, inputPairCode)}
              />
            </div>
            <div className="single-input-box">
              <input
                id={inputTerminalName}
                name={inputTerminalName}
                onFocus={(e) => handleFocus(e, inputTerminalName)}
                // name="terminal-name"
                className="input-style white big"
                placeholder="Terminal Name"
                // value={terminalName}
                value={getInputValue(inputTerminalName)}
                // onChange={handleChangeTerminalName}
                onChange={(e) => handleChange(e, inputTerminalName)}
              />
            </div>
          </form>
        </div>
        <div className="add-drawer-btn-box">
          <Button
            /* disabled={
               (password && password.length <= 0) ||
               (userName && userName.length <= 0) ||
               (pairCode && pairCode.length <= 0) ||
               (terminalName && terminalName.length <= 0)
             }*/
            disabled={
              (getInputValue(inputPassword) &&
                getInputValue(inputPassword).length <= 0) ||
              (getInputValue(inputUserName) &&
                getInputValue(inputUserName).length <= 0) ||
              (getInputValue(inputPairCode) &&
                getInputValue(inputPairCode).length <= 0) ||
              (getInputValue(inputTerminalName) &&
                getInputValue(inputTerminalName).length <= 0)
            }
            className="btn"
            title="Pair Terminal"
            onClick={handleClick}
          >
            Pair Terminal
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

PairTerminal.propTypes = {
  modalStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
};
