import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PosIconChatBubbles = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-chat-bubbles': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <title>Chat bubbles</title>
        <path
          fill={iconColor ? iconColor : '#000'}
          stroke={iconColor ? iconColor : '#000'}
          strokeWidth={lineThickness ? lineThickness : 0}
          d="M30.49 17.19c2.222-3.958 1.919-8.856-0.773-12.509s-7.273-5.382-11.701-4.417c-4.428 0.966-7.877 4.446-8.812 8.891-3.607 0.746-6.64 3.179-8.158 6.544s-1.336 7.254 0.487 10.462l-1.509 5.011c-0.058 0.198-0.017 0.412 0.11 0.574s0.324 0.254 0.529 0.246c0.072 0.012 0.145 0.012 0.217 0l4.564-1.704c1.827 1.132 3.937 1.723 6.085 1.704 5.464 0.002 10.177-3.848 11.276-9.214 1.335-0.262 2.609-0.771 3.759-1.499l4.564 1.743c0.228 0.085 0.484 0.036 0.665-0.128 0.179-0.166 0.249-0.419 0.179-0.654l-1.483-5.049zM11.531 30.71c-2.011 0.008-3.978-0.585-5.651-1.704-0.106-0.070-0.231-0.105-0.358-0.103-0.072-0.012-0.145-0.012-0.217 0l-3.669 1.397 1.189-4.050c0.051-0.172 0.028-0.358-0.064-0.513-1.736-2.895-1.938-6.46-0.54-9.533s4.215-5.259 7.533-5.845h0.192c3.828-0.669 7.703 0.894 10.002 4.034s2.624 7.315 0.839 10.774c-1.785 3.46-5.371 5.607-9.256 5.542zM26.706 19.958c-0.191-0.070-0.404-0.046-0.575 0.064-0.942 0.643-1.989 1.116-3.094 1.397 0-0.316 0-0.636 0-0.961 0-6.37-5.151-11.533-11.506-11.533-0.324 0-0.648 0-0.972 0 0.999-3.886 4.168-6.831 8.109-7.535s7.93 0.961 10.207 4.262c2.277 3.301 2.423 7.63 0.374 11.078-0.092 0.154-0.115 0.34-0.064 0.513l1.189 4.050-3.669-1.333z"
        />
      </svg>
    </span>
  );
};

PosIconChatBubbles.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
