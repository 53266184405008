import Dinero from 'dinero.js';
import { getItemPrice } from '../../orderEngine/utilities';
import { PosIconApperCircle } from '../../assets/icons/PosIconApperCircle';
import { getHalfAndHalfTitle } from '../../util/half-and-half-utils';
import PriceCell from './PriceCell';

export default function HalfAndHalfItem({ halfAndHalfMenuItems = [] }) {
  if (!halfAndHalfMenuItems.length) {
    return null;
  }

  const extraIngredients = Array.from(
    new Set(
      halfAndHalfMenuItems.reduce((collector, currentVal) => {
        let extraIngredientsForThisPart = [];

        currentVal?.selectedExtraIngredients?.forEach((extraIngredient) => {
          extraIngredientsForThisPart.push(extraIngredient.name);
        });

        return collector.concat(extraIngredientsForThisPart);
      }, [])
    )
  );

  const remotedIngredients = Array.from(
    new Set(
      halfAndHalfMenuItems.reduce((collector, currentVal) => {
        let removedIngredientsForThisPart = [];

        currentVal?.removedIngredients?.forEach((extraIngredient) => {
          removedIngredientsForThisPart.push(extraIngredient.name);
        });

        return collector.concat(removedIngredientsForThisPart);
      }, [])
    )
  );

  const images = halfAndHalfMenuItems
    .map((item) => ({
      src: item.urlS3,
      alt: item.name,
    }))
    .reverse();

  const menuItemsTextToShow = getHalfAndHalfTitle(halfAndHalfMenuItems);

  const totalDineroPrice = halfAndHalfMenuItems.reduce(
    (acc, currentVal) => {
      const halfPriceString = getItemPrice(currentVal).payablePrice.toFixed(2);

      const [dollars, cents] = halfPriceString.split('.');

      /**
       * note 100 is a magic number which might change for differenc currencies
       * refer https://frontstuff.io/how-to-handle-monetary-values-in-javascript
       */
      const totalPriceInCurrencyMainUnit =
        parseInt(dollars) * 100 + parseInt(cents);

      return acc.add(
        Dinero({
          amount: totalPriceInCurrencyMainUnit,
          currency: 'AUD',
        })
      );
    },
    Dinero({
      amount: 0,
      currency: 'AUD',
    })
  );

  const priceToShow = halfAndHalfMenuItems.reduce(
    (acc, p) => (acc += parseFloat(getItemPrice(p).payablePrice.toFixed(2))),
    0
  );

  const halfHalfNotes = halfAndHalfMenuItems[0]?.itemNotes || '';
  const isVoided = halfAndHalfMenuItems[1]?.isVoided || false;

  return (
    <div
      className={`order-flow half-half-item-container${
        isVoided ? ' voided' : ''
      }`}
    >
      <div className="split-image-container">
        <div className="split-image-wrapper">
          {images.map(({ src, alt }, index) => (
            <img
              key={src}
              className={`img-${index} split-image`}
              src={src}
              alt={alt}
            />
          ))}
        </div>
      </div>

      <div className="half-and-half-titles-container orders-screen-title-container-spacing">
        <span>{menuItemsTextToShow}</span>
        {/* {halfAndHalfMenuItems.map((item) => {
          const selectedSizes = item.selectedSizes?.filter((f) => f.quantity);
          return (
            <div key={item.description}>
              {getTitleForMenuItem(item.name, selectedSizes)}
            </div>
          );
        })} */}

        {halfHalfNotes && (
          <div className="additionals">
            <span>Notes: {halfHalfNotes}</span>
          </div>
        )}

        {!!extraIngredients.length && (
          <div className="additionals">
            <strong>Added </strong>
            <span>{extraIngredients.join(', ')}</span>
          </div>
        )}

        {!!remotedIngredients.length && (
          <div className="additionals">
            <strong>Removed </strong>
            <span>{remotedIngredients.join(', ')}</span>
          </div>
        )}
      </div>

      <div className="half-half-price-container">
        <PriceCell payablePrice={priceToShow} />
      </div>

      <button className="menu-opener-cta" title="Edit">
        <PosIconApperCircle mainColor="#494B4C" />
      </button>
    </div>
  );
}
