import clsx from 'clsx';
import { DollarIcon, SortIcon, TrashIcon } from 'nexticons/outline';
import { GiftIcon } from 'nexticons/solid';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PosIconLoading } from '../../assets/icons/PosIconLoading';
import scssVariables from '../../assets/scss/global/_variables.module.scss';
import {
  getDiscountAction,
  getVoucherAction
} from '../../pages/Dashboard/action';
import {
  currencySymbolSelector, storeConfigSelector, storeCountryIdentifierSelector
} from '../../pages/Dashboard/selectors';
import {
  BackButtonCircleUI,
  BadgeUI,
  ButtonGroupUI,
  ButtonUI,
  SquareButtonUI
} from '../../UI/components';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { isObjectNotEmpty } from '../../util/utils';
import { validateDiscount, validateVoucher } from '../../util/voucherHelper';
import './bem-item-discount-modal.scss';
import { discountListSort } from './utils';






const DISCOUNT_RGB_COLORS = [
  '182, 33, 224',
  '0, 134, 246',
  '253, 108, 0',
  '247, 181, 0',
  '94, 182, 2',
  '235, 68, 105',
  '109, 114, 120',
  '0, 134, 246',
];

const ItemDiscountModal = forwardRef(
  (
    {
      style,
      onBack,
      selectedDiscount,
      selectedVoucher,
      openManualDiscount,
      onApply,
      onApplyVoucher,
      id,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const currencyCode = useSelector(currencySymbolSelector);
    const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
    const inputRef = useRef(null);
    const timeout = useRef(0);
    const { discountList, currentOrder, voucherList } = useSelector(
      (state) => state.dashboardReducer
    );

    const storeConfig = useSelector(storeConfigSelector);

    const [currentOrderSelectedDiscount] = useState(
      isObjectNotEmpty(currentOrder.selectedDiscount) &&
        currentOrder.selectedDiscount
    );

    const [discountListLoading, setDiscountsIsLoading] = useState(true);
    const [discountError, setDiscountError] = useState('');

    const [voucherCode, setVoucherCode] = useState(selectedVoucher || '');
    const [showVoucherModal, setShowVoucherModal] = useState(false);
    const [voucherError, setVoucherError] = useState('');

    const [discountListSorted, setDiscountListSorted] = useState([]);
    const [sortOrder, setSortOrder] = useState('DESC');

    const handleSort = () => {
      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    };

    const handleVoucherApply = (remove = false) => {
      let applyVoucher = false;
      let closeModal = false;
      let newSelectedVoucher = {};

      // remove the voucher based on the flag
      if (remove) {
        applyVoucher = true;
        closeModal = true;
      } else {
        // run the apply logic
        if (voucherList?.length > 0) {
          let filteredVouchers = voucherList.filter((v) => {
            return (
              v.voucherCode.toLowerCase().trim() ===
              voucherCode.toLowerCase().trim()
            );
          });

          if (filteredVouchers.length > 0) {
            const validVoucher = validateVoucher(
              filteredVouchers[0],
              currentOrder,
              currentOrder.payableAmount,
              storeConfig
            );

            if (validVoucher.success) {
              newSelectedVoucher = { ...filteredVouchers[0] };

              const orderVoucherAlreadyApplied =
                isObjectNotEmpty(currentOrder.selectedVoucher) &&
                isObjectNotEmpty(newSelectedVoucher) &&
                currentOrder.selectedVoucher._id === newSelectedVoucher._id;

              const menuVoucherAlreadyApplied = currentOrder.menuItems.some(
                (item) =>
                  isObjectNotEmpty(item.selectedVoucher) &&
                  isObjectNotEmpty(newSelectedVoucher) &&
                  item.selectedVoucher._id === newSelectedVoucher._id
              );

              if (!menuVoucherAlreadyApplied && !orderVoucherAlreadyApplied) {
                closeModal = true;
                applyVoucher = true;
              } else {
                setVoucherError('Voucher has been already applied');
              }
            } else {
              setVoucherError(validVoucher.voucherErrorMessage);
            }
          } else {
            setVoucherError('Voucher does not exist');
          }
        } else {
          setVoucherError('Vouchers are not available at the moment');
        }
      }

      // if no errors run dispatch and apply the voucher object
      if (applyVoucher) {
        onApplyVoucher(newSelectedVoucher);
      }

      // close and reset the modal
      if (closeModal) {
        setShowVoucherModal(false);
        onBack();
      }
    };

    const handleVoucherChange = (event) => {
      event.preventDefault();

      setVoucherCode(event.target.value);
      setVoucherError('');
    };

    const handleDiscountSelection = (discount) => {
      if (!disabledDiscount(discount._id)) {
        handleApplyDiscount(discount);

        if (discountError) {
          setDiscountError('');
        }
      }
    };

    const handleApplyDiscount = (discount) => {
      // skip validation
      if (isObjectNotEmpty(discount) && !discount.defaultDiscount) {
        const validDiscount = validateDiscount(
          discount,
          currentOrder,
          currentOrder.payableAmount,
          storeConfig
        );

        if (!validDiscount.success) {
          setDiscountError(validDiscount.discountErrorMessage);
          setTimeout(() => {
            setDiscountError('');
          }, 4000);

          return;
        }
      }

      // either remove or add discount
      let newSelectedDiscount = { ...discount };

      // remove discount based on the flag
      if (selectedDiscount?._id === newSelectedDiscount?._id) {
        newSelectedDiscount = {};
      }

      onApply(newSelectedDiscount);
    };

    const handleRemoveVoucher = () => {
      setVoucherCode('');
      handleVoucherApply(true);
    };

    const renderSelectedVoucherInfo = (voucher) => (
      <div className="voucher-modal-box__selected-voucher">
        {voucher.voucherCode} (${voucher.discount})
        <TrashIcon
          onClick={handleRemoveVoucher}
          className="voucher-modal-box__remove"
        />
      </div>
    );

    const disabledDiscount = (discountId) => {
      return (
        // check if discount is already applied for the order
        isObjectNotEmpty(currentOrderSelectedDiscount) &&
        discountId === currentOrderSelectedDiscount._id
      );
    };

    const modalTitle = useMemo(() => {
      return !showVoucherModal ? 'Item Discount' : 'Enter Voucher Code';
    }, [showVoucherModal]);

    const colorizeList = (list) => {
      let limit = 0;
      const length = DISCOUNT_RGB_COLORS.length - 1;
      const newList = list.map((item) => {
        const newItem = { ...item, rgb: DISCOUNT_RGB_COLORS[limit] };
        limit + 1 <= length ? limit++ : (limit = 0);

        return newItem;
      });

      return newList;
    };

    useEffect(() => {
      const actionObj = {
        storeId: storeConfig?.storeId,
        orderFrom: currentOrder.orderFrom,
        orderType: currentOrder.orderType,
        userId: currentOrder.userId ? currentOrder.userId : '',
      };

      // DiscountAction
      dispatch(getDiscountAction(actionObj));

      // VoucherAction
      dispatch(getVoucherAction(actionObj));
    }, [
      currentOrder.orderFrom,
      currentOrder.orderType,
      currentOrder.userId,
      storeConfig?.storeId,
      dispatch,
    ]);

    useEffect(() => {
      if (showVoucherModal) {
        timeout.current = setTimeout(() => {
          inputRef.current.focus();
        }, 200);
      }

      return () => {
        clearTimeout(timeout.current);
      };
    }, [showVoucherModal]);

    useEffect(() => {
      if (discountList && discountList.length > 0) {
        const sorted = discountListSort(discountList, sortOrder);
        setDiscountListSorted(colorizeList(sorted));
      }
      setDiscountsIsLoading(false);
    }, [discountList, sortOrder]);

    const renderVoucherModal = () => {
      return (
        <div className="bem-item-discount-modal__voucher">
          <div className="bem-item-discount-modal__voucher-header">
            {selectedVoucher && renderSelectedVoucherInfo(selectedVoucher)}

            {voucherError && (
              <div style={{ color: '#944810' }}>{voucherError}</div>
            )}
          </div>

          <div className="bem-item-discount-modal__input-box">
            <input
              id="voucher-code"
              name="voucher-code"
              className="bem-item-discount-modal__input-box-input"
              value={voucherCode}
              onChange={handleVoucherChange}
              ref={inputRef}
            />
            <ButtonUI
              styleType="success-outline"
              sizeType="s"
              rounded="s"
              onClick={() => {
                handleVoucherApply(false);
              }}
            >
              Apply Voucher
            </ButtonUI>
          </div>
        </div>
      );
    };

    return (
      <div id={id} className="bem-item-discount-modal" style={style} ref={ref}>
        <div className="bem-item-discount-modal__inner">
          <div className="bem-item-discount-modal__header">
            <ButtonGroupUI>
              <BackButtonCircleUI
                onClick={() => {
                  return showVoucherModal
                    ? setShowVoucherModal(false)
                    : onBack();
                }}
              />
            </ButtonGroupUI>

            <h3 className="bem-item-discount-modal__title">{modalTitle}</h3>

            <ButtonGroupUI>
              {!showVoucherModal ? (
                <ButtonUI
                  sizeType="s"
                  styleType="primary-light"
                  gap="s"
                  rounded="s"
                  icon={
                    <SortIcon
                      strokeWidth="2"
                      style={{
                        width: 20,
                        height: 20,
                        transform: `rotate(${
                          sortOrder === 'ASC' ? 0 : '-180deg'
                        })`,
                      }}
                    />
                  }
                  onClick={handleSort}
                >
                  Sort
                </ButtonUI>
              ) : (
                ''
              )}
            </ButtonGroupUI>
          </div>

          <div className="bem-item-discount-modal__body">
            <div className="bem-item-discount-modal__body-inner">
              {discountError && (
                <div className="bem-item-discount-modal__error">
                  {discountError}
                </div>
              )}
              {showVoucherModal ? (
                renderVoucherModal()
              ) : discountListLoading ? (
                <div className="bem-item-discount-modal__spinner-wrapper">
                  <PosIconLoading mainColor="#5eb602" />
                </div>
              ) : discountListSorted?.length > 0 ? (
                <table className="modal-table">
                  <tbody className="modal-table__body">
                    {discountListSorted
                      ?.filter(
                        (discount) =>
                          discount.defaultDiscount || discount.isActive
                      )
                      .map((discount) => {
                        const tableRowCss = clsx('modal-table__row', {
                          'modal-table__row--selected':
                            discount._id === selectedDiscount?._id,
                          'modal-table__row--disabled': disabledDiscount(
                            discount._id
                          ),
                        });
                        return (
                          <tr className={tableRowCss} key={discount._id}>
                            <td className="modal-table__cell">
                              <span className="modal-table__cell-name">
                                {discount.discountName}
                              </span>
                            </td>
                            <td className="modal-table__cell modal-table__cell--size">
                              <BadgeUI
                                style={{
                                  color: `rgb(${discount.rgb})`,
                                  backgroundColor: `rgba(${discount.rgb}, .15)`,
                                  border: 0,
                                }}
                                title={
                                  discount.discountType === '1'
                                    ? `${discount.discount}%`
                                    : getAmountWithCountryCurrency({
                                        amount: Number(discount.discount),
                                        locale: `en-${storeCountryIdentifier}`,
                                        currencyCode,
                                      })
                                }
                                fullWidth
                              />
                            </td>
                            <td className="modal-table__cell modal-table__cell--btn">
                              <ButtonUI
                                styleType={
                                  discount._id === selectedDiscount?._id
                                    ? 'danger-light'
                                    : 'success-outline'
                                }
                                sizeType="s"
                                rounded="s"
                                disabled={disabledDiscount(discount._id)}
                                onClick={() =>
                                  handleDiscountSelection(
                                    discount._id === selectedDiscount?._id
                                      ? {}
                                      : discount
                                  )
                                }
                              >
                                {discount._id === selectedDiscount?._id
                                  ? 'Remove'
                                  : disabledDiscount(discount._id)
                                  ? 'Applied'
                                  : 'Apply'}
                              </ButtonUI>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div className="bem-item-discount-modal__content">
                  No discounts available
                </div>
              )}
            </div>
          </div>

          <div className="bem-item-discount-modal__footer">
            <SquareButtonUI
              title="Manual Discount"
              styleType="secondary"
              style={{ color: scssVariables.posSuccessColor }}
              icon={<DollarIcon width="20" strokeWidth="2.75" />}
              onClick={() => {
                openManualDiscount(true);
              }}
            />
            {!showVoucherModal && (
              <SquareButtonUI
                title={voucherCode || 'Apply Voucher'}
                styleType="secondary"
                style={{ color: scssVariables.posWarningColor }}
                icon={<GiftIcon width="24" />}
                iconPosition="right"
                onClick={() => {
                  setVoucherError('');
                  setShowVoucherModal(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default ItemDiscountModal;
