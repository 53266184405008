import './bem-bill-check-info.scss';
import React from 'react';
import { getUIClassesFromProps, getUIModifierKeys } from '../modifiersMapping';
import PropTypes from 'prop-types';
import { CounterUI } from '../index';

export const BillCheckInfoUI = ({ title, description, borderSide }) => {
  return (
    <div
      className={getUIClassesFromProps('bem-bill-check-info', {
        borderSide,
      })}
    >
      <span className="bem-bill-check-info__text">{title}</span>
      <span className="bem-bill-check-info__text">{description}</span>
    </div>
  );
};

BillCheckInfoUI.defaultProps = {
  title: '',
  description: '',
  borderSide: undefined,
};

BillCheckInfoUI.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  borderSide: PropTypes.oneOf(getUIModifierKeys('borderSide')),
};

function Title({ title, description, justifyContent, borderSide }) {
  return (
    <div
      className={getUIClassesFromProps('bem-bill-check-info', {
        justifyContent,
        borderSide,
      })}
    >
      <h3 className="bem-bill-check-info__title">{title}</h3>
      {description && (
        <h4 className="bem-bill-check-info__title">{description}</h4>
      )}
    </div>
  );
}

BillCheckInfoUI.Title = Title;

BillCheckInfoUI.Title.defaultProps = {
  title: '',
  description: '',
  justifyContent: undefined,
  borderSide: undefined,
};

BillCheckInfoUI.Title.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  justifyContent: PropTypes.oneOf(getUIModifierKeys('justifyContent')),
  borderSide: PropTypes.oneOf(getUIModifierKeys('borderSide')),
};

function Total({ title, description, borderSide }) {
  return (
    <div
      className={getUIClassesFromProps('bem-bill-check-info', {
        borderSide,
      })}
    >
      <span className="bem-bill-check-info__total-text">{title}</span>
      <span className="bem-bill-check-info__total-text">{description}</span>
    </div>
  );
}

BillCheckInfoUI.Total = Total;

BillCheckInfoUI.Total.defaultProps = {
  title: '',
  description: '',
  borderSide: undefined,
};

BillCheckInfoUI.Total.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  borderSide: PropTypes.oneOf(getUIModifierKeys('borderSide')),
};

function Counter({
  title,
  quantity,
  onIncrement,
  onDecrement,
  borderSide,
  style,
  maxLimit,
}) {
  return (
    <div
      style={style}
      className={getUIClassesFromProps('bem-bill-check-info', {
        borderSide,
      })}
    >
      <span className="bem-bill-check-info__text">{title}</span>
      <span className="bem-bill-check-info__counter-box">
        <CounterUI
          quantity={quantity}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          maxLimit={maxLimit}
        />
      </span>
    </div>
  );
}

BillCheckInfoUI.Counter = Counter;

BillCheckInfoUI.Counter.defaultProps = {
  title: '',
  borderSide: undefined,
};

BillCheckInfoUI.Counter.propTypes = {
  title: PropTypes.string,
  borderSide: PropTypes.oneOf(getUIModifierKeys('borderSide')),
};
