import React, { useEffect, useState } from 'react';
import GeneralModal from '../GeneralModal/index-new';
import * as action from 'pages/Orders/newOrdersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'hooks';
import dayjs from 'dayjs';
import { toggleBlurEffect } from 'pages/Dashboard/action';
import Button from '../../components/Button';
import mobiscroll from '@mobiscroll/react';
import { getIndexByValue } from '../../util/getIndexByValue';


const TimeModalWrapper = (props) => {
  const [modalStatus, { closeModal }] = useModal('timeModal');

  if (!modalStatus) return null;

  return (
    <TimeModal modalStatus={modalStatus} toggleModal={closeModal} {...props} />
  );
};

const mapOrderType = {
  0: 'Walk In',
  1: 'Pickup',
  2: 'Delivery',
  3: 'Dine In',
};

const TimeModal = ({ modalStatus, toggleModal, selectedOrder }) => {
  const { deliveryDate, deliveryDateTimestamp, orderType } = selectedOrder;
  const orders = useSelector((state) => state.newOrdersReducer.orders);
  const [time, setTime] = useState('');
  const [updatedTime, setUpdatedTime] = useState('');
  const [selectedMinutes, setSelectedMinutes] = useState('0');
  const [timeThreshold, setTimeThreshold] = useState(0);
  const [updatedTimestamp, setUpdatedTimestamp] = useState('');
  const [wheelTimeArray, setWheelTimeArray] = useState([]);
  const [timeIndex, setTimeIndex] = useState(0);
  const dispatch = useDispatch();
  const timeWheelData = [
    [
      {
        key: 'setTime',
        circular: false,
        data: wheelTimeArray,
      },
    ],
  ];
  const getEstimatedTimeList = (deliveryDateTimestamp) => {
    const currentTimestamp = dayjs().valueOf();
    let timeDifferenceInMins = dayjs(deliveryDateTimestamp)
      .diff(currentTimestamp, 'm', true)
      .toFixed(0);

    if (Number(timeDifferenceInMins) < 0) {
      setTime(dayjs(currentTimestamp).add(5, 'm').format('h:mm A'));
      setUpdatedTime(
        dayjs(currentTimestamp).add(5, 'm').format('YYYY-MM-DD H:mm ')
      );
      setUpdatedTimestamp(dayjs().add(5, 'm').valueOf());
      setTimeIndex(0);
      setSelectedMinutes('5');
    } else {
      setSelectedMinutes(timeDifferenceInMins);
    }

    const estimatedTimeList = [];
    for (let index = 1; index <= 100; index++) {
      let min = index * 5;
      estimatedTimeList.push(`${min} MINS`);
    }
    if (timeDifferenceInMins % 5 !== 0 && timeDifferenceInMins > 0) {
      estimatedTimeList.push(`${timeDifferenceInMins} MINS`);
    }

    const sortedEstimatedTimeList = estimatedTimeList.sort(
      (a, b) => Number(a.split(' ')[0]) - Number(b.split(' ')[0])
    );
    setTimeIndex(
      getIndexByValue(`${timeDifferenceInMins} MINS`, sortedEstimatedTimeList)
    );
    setTimeThreshold(timeDifferenceInMins);

    return sortedEstimatedTimeList;
  };

  useEffect(() => {
    const displayTime = dayjs(deliveryDate).format('h:mm A');
    setTime(displayTime);
  }, [deliveryDate]);

  useEffect(() => {
    const wheelTime = getEstimatedTimeList(deliveryDateTimestamp);
    setWheelTimeArray(wheelTime);
  }, [deliveryDateTimestamp]);

  const handleInitNew = (event, inst) => {
    inst.setArrayVal([wheelTimeArray[timeIndex]]);
  };

  const handleSet = (event, inst) => {
    const timeWheelArray = inst._wheelArray;
    const selectedTimeArray = timeWheelArray[0].split(' ');
    const selectedMins = selectedTimeArray[0];
    setSelectedMinutes(selectedMins);

    let additionalTime = dayjs(deliveryDate)
      .add(Number(0), 'm')
      .format('YYYY-MM-DD H:mm ');

    if (selectedMins > timeThreshold) {
      additionalTime = dayjs(deliveryDate)
        .add(Number(selectedMins - timeThreshold), 'm')
        .format('YYYY-MM-DD H:mm ');
    } else if (selectedMins < timeThreshold) {
      additionalTime = dayjs(deliveryDate)
        .subtract(Number(timeThreshold - selectedMins), 'm')
        .format('YYYY-MM-DD H:mm ');
    }
    const additionalTimestamp = dayjs(additionalTime).unix();
    setUpdatedTime(additionalTime);
    setUpdatedTimestamp(additionalTimestamp * 1000);
    const updatedTimeIndex = getIndexByValue(timeWheelArray[0], wheelTimeArray);
    setTimeIndex(updatedTimeIndex);
    setTime(dayjs(additionalTime).format('h:mm A'));
  };

  return (
    <GeneralModal
      modalStatus={modalStatus}
      toggleModal={() => {
        toggleModal();
        dispatch(toggleBlurEffect(false));
      }}
      position="center"
      effect="move"
      overlay="blur"
      boxWidth="699px"
      boxHeight="468"
      closeBtn="outside"
      className="simple-select-time-modal"
    >
      <div className="select-time-modal-box">
        <div className="time-modal-title-container">
          <span className="time-modal-title ">
            Estimated {mapOrderType[orderType]} Time
          </span>
          <div>
            <span className="time-modal-title-time">{time}</span>
          </div>
        </div>
        <hr className="time-modal-divider" />
        <div className="select-time-wrap">
          <div className="select-delivery-time-wrap">
            <mobiscroll.Scroller
              display="inline"
              rows={5}
              circular={[false, false]}
              type="hidden"
              wheels={timeWheelData}
              onInit={handleInitNew}
              onSet={handleSet}
              height={300 / 5}
              minWidth={[150, 52, 57, 92]}
            />
          </div>
        </div>

        <div className="select-time-btn-box">
          <Button
            className="btn full-width"
            title={'Confirm Time'}
            onClick={() => {
              dispatch(
                action.updateAllMapOrder({
                  id: selectedOrder._id,
                  orders,
                  deliveryDate: updatedTime,
                  dateTimestamp: updatedTimestamp,
                })
              );
              toggleModal();
              dispatch(toggleBlurEffect(false));
            }}
          >
            Confirm {mapOrderType[orderType]} Time - {selectedMinutes} mins
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

export default TimeModalWrapper;
