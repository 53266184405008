import { _firestore } from '../../../../../firebase';
import { orderTypesIds } from '../../../../../configuration/constants';
import {
  getTimestampForDateEnd,
  getTimestampForDateStart,
} from '../../../../../util/timeHelper';

import moment from 'moment-timezone';
import dayjs from 'dayjs';

const orderTypeMap = {
  pickup: '1',
  delivery: '2',
  'dine-in': '3',
  'drive-thru': '4',
};

export function getQueryForOrderDateFilter(
  orderDateFilter = 'current',
  orderTypeFilter = 'all',
  storeId,
  initialSOD,
  initialEOD
) {
  if (!storeId) return;
  const orderType = orderTypeMap[orderTypeFilter];
  let query = _firestore.collection('Orders').where('storeId', '==', storeId);

  const SOD = initialSOD || dayjs().startOf('day').valueOf();
  const EOD = initialEOD || new Date().setUTCHours(23, 59, 59, 999);

  switch (orderDateFilter) {
    case 'current':
      query = query
        .where('deliveryDateTimestamp', '>=', SOD)
        .where('deliveryDateTimestamp', '<', EOD);
      break;
    case 'future':
      query = query
        .where('deliveryDateTimestamp', '>', EOD)
        .where(
          'deliveryDateTimestamp',
          '<',
          getTimestampForDateEnd(moment().add(7, 'days'))
        );
      break;
    case 'history':
      query = query
        .where('deliveryDateTimestamp', '<', SOD)
        .where(
          'deliveryDateTimestamp',
          '>=',
          getTimestampForDateStart(moment().add(-7, 'days'))
        );
      break;
    default:
      throw new Error(
        `orderDateFilter ${orderDateFilter} in getQueryForFilterType isn't valid type`
      );
  }

  if (typeof orderType !== 'undefined') {
    query = query.where('orderType', 'in', [orderType]);
  } else {
    query = query.where('orderType', 'in', orderTypesIds);
  }

  query = query.orderBy('deliveryDateTimestamp', 'desc');

  if (orderDateFilter === 'history') {
    query = query.limit(250);
  }

  return query;
}

// TODO: move it to common utils
export function normaliseCollectionFromFirestore(collection) {
  return (collection?.docs || []).map((doc) =>
    normaliseDocumentFromFirestore(doc)
  );
}

export function normaliseDocumentFromFirestore(doc) {
  return {
    _id: doc.id,
    ...doc.data(),
  };
}

export function filterOutCancelledOrders(list) {
  return list?.filter((order) => order.orderStatus !== '11');
}
