import React from 'react';
import { ScrollableBoxUI } from 'UI/components';

export const SidePanelDetails = ({ children }) => {
  return (
    <div className="side-panel__details dine-in-blur-area sidebar-scroll">
      <ScrollableBoxUI>{children}</ScrollableBoxUI>
    </div>
  );
};
