import { useParams } from 'react-router';
import useSpecialsCategoryIndex from './useSpecialsCategoryIndex';
import { useSelector } from 'react-redux';

const useGoBackToPathName = (currentItem, basePath) => {
  const { modifierId, itemId } = useParams();
  const currentSpecialsCategoryIndex = useSpecialsCategoryIndex();
  const currentSpecialsItem = useSelector(
    (state) => state.dashboardReducer.currentSpecialsItem
  );
  const currentHalfHalfData = useSelector(
    (state) => state.dashboardReducer.currentHalfHalfData
  );
  const currentFourQuarterData = useSelector(
    (state) => state.dashboardReducer.currentFourQuarterData
  );
  const activeCategoryId = useSelector(
    (state) => state.dashboardReducer.activeCategoryId
  );
  if (currentItem && basePath) {
    const specialsId = currentSpecialsItem?._id;
    if (itemId) {
      if (modifierId) {
        if (activeCategoryId !== 'specials') {
          return `${basePath}/order`;
        }
        if (specialsId && currentSpecialsCategoryIndex !== -1) {
          if (currentItem.isCurrentMenuItemInEditMode) {
            return `${basePath}/specials/${specialsId}`;
          }
          return `${basePath}/specials/${specialsId}/${currentSpecialsCategoryIndex}`;
        }
        const halfHalfCategoryId = currentHalfHalfData?.activeCategoryId;
        if (halfHalfCategoryId) {
          const halfIndex = currentHalfHalfData?.halfHalfs?.find(
            (h) => h.isInEditMode
          )?.halfIndex;
          return `${basePath}/category/${halfHalfCategoryId}/half-half/${halfIndex}`;
        }
        const fourQuarterCategoryId = currentFourQuarterData?.activeCategoryId;
        if (fourQuarterCategoryId) {
          const halfIndex = currentHalfHalfData?.halfHalfs?.find(
            (h) => h.isInEditMode
          )?.halfIndex;
          return `${basePath}/category/${fourQuarterCategoryId}/four-quarter/${halfIndex}`;
        }
        return `${basePath}/order`;
      }
      if (currentItem.itemType === 0) {
        return `${basePath}/menuitem/${currentItem._id}/size/size-xxx`;
      } else if (
        currentItem.itemType === 1 &&
        currentItem.selectedModifiers?.length
      ) {
        const firstUnselectedModifierId = currentItem?.selectedModifiers.find(
          (modifier) =>
            modifier.subModifiers?.every((subModifier) => {
              let isUnselectedModifier = true;

              if (subModifier?.quantity !== undefined) {
                isUnselectedModifier =
                  isUnselectedModifier && subModifier.quantity === 0;
              }

              if (subModifier?.prices?.length) {
                isUnselectedModifier =
                  isUnselectedModifier &&
                  subModifier?.prices.every(
                    (price) =>
                      price?.quantity === undefined || price.quantity === 0
                  );
              }

              if (subModifier?.variants?.length) {
                isUnselectedModifier =
                  isUnselectedModifier &&
                  subModifier?.variants.every(
                    (variant) =>
                      variant?.quantity === undefined || variant.quantity === 0
                  );
              }

              return isUnselectedModifier;
            })
        )?._id;

        if (firstUnselectedModifierId)
          return `${basePath}/menuitem/${currentItem._id}/modifiers/${firstUnselectedModifierId}`;

        return `${basePath}/menuitem/${currentItem._id}/modifiers/${currentItem.selectedModifiers[0]._id}`;
      }
      return `${basePath}/order`;
    }
  }
  return '/';
};

export default useGoBackToPathName;
