import { ModalPortal } from 'components';
import { useModal } from 'hooks';
import { memo, useEffect, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  toggleBlurEffect,
  updateCurrentOrder,
} from '../../pages/Dashboard/action';

import { useSelector } from 'react-redux';
import { storeConfigSelector } from '../../pages/Dashboard/selectors';

import { kiosk_route } from 'constants/routesConsts';
import useIsKiosk from 'hooks/useIsKiosk';
import { setGoToRouteAfterAuth } from 'pages/Dashboard/action';
import { addUserWithMobileNumber } from '../../services/customerServices';
import CustomerNameModal from '../CustomerNameModal';
import { OrderTypeIdEnum } from 'configuration/enums';

const CustomerNameModalWrapper = (props) => {
  const [open, { closeModal, openModal }] = useModal('customerNameModal');

  if (!open) return null;

  return (
    <CustomerName
      {...props}
      modalStatus={open}
      closeModal={closeModal}
      openModal={openModal}
    />
  );
};

export const CustomerName = memo(
  ({
    parentPage,
    closeModal,
    isUserRegistrationRequired = true,
    orderType = OrderTypeIdEnum.WALK_IN,
  }) => {
    const isKiosk = useIsKiosk();
    const orderSetup = useSelector((s) => s.dashboardReducer.orderSetup);
    const currentOrder = useSelector((s) => s.dashboardReducer.currentOrder);
    const acceptedCall = useSelector((s) => s.dashboardReducer.acceptedCall);
    const storeConfig = useSelector(storeConfigSelector);
    let history = useHistory();
    const dispatch = useDispatch();
    const [isSavingCustomerInfo, setIsSavingCustomerInfo] = useState(false);
    const timeout = useRef(0);
    const shouldHideModal =
      (currentOrder.orderType === '0' || currentOrder.orderType === '4') &&
      orderSetup?.isEnableOrderNumberForWalkIn;

    const [isShowModal, toggleShowModal] = useState(!shouldHideModal);

    const registerUser = async (currentOrderObj) => {
      if (!currentOrderObj.userId || currentOrderObj.userId === 'xxx-xxx-xxx') {
        setIsSavingCustomerInfo(true);
        //Register user
        //Check for exiting user
        try {
          const result = await addUserWithMobileNumber({
            restaurantId: storeConfig.restaurantId,
            timeZone: storeConfig.timeZone,
            mobileNumber: currentOrderObj.mobileNumber,
            firstName: currentOrderObj.firstName,
            lastName: currentOrderObj.lastName,
          });
          if (result.success && result.userId) {
            toggleShowModal(false);
            currentOrderObj.userId = result.userId;
            dispatch(updateCurrentOrder(currentOrderObj));
            history.push(`${parentPage}/order`);
          }
        } catch (error) {
        } finally {
          setIsSavingCustomerInfo(false);
        }
      } else {
        toggleShowModal(false);
        dispatch(updateCurrentOrder(currentOrderObj));
        history.push(`${parentPage}/order`);
      }
    };

    const handleSubmit = (customerName) => {
      if (customerName && customerName.length > 0) {
        let currentOrderObj = { ...currentOrder };
        currentOrderObj.firstName =
          customerName.split(' ').length > 0 ? customerName.split(' ')[0] : '';
        currentOrderObj.lastName =
          customerName.split(' ').length > 1 ? customerName.split(' ')[1] : '';
        if (isUserRegistrationRequired) {
          registerUser(currentOrderObj);
        } else {
          toggleShowModal(false);
          dispatch(
            updateCurrentOrder({
              ...currentOrderObj,
              mobileNumber: 'xxx-xxx-xxx',
              userId: 'xxx-xxx-xxx',
            })
          );
          timeout.current = setTimeout(() => {
            history.push(`${parentPage}/order`);
          }, 100);
        }
      }
    };

    useEffect(() => {
      if (shouldHideModal) {
        let currentOrderObj = { ...currentOrder };
        toggleShowModal(false);
        dispatch(
          updateCurrentOrder({
            ...currentOrderObj,
            orderType,
            firstName: 'Order#0',
            lastName: '',
            walkInOrderNumber: 0,
            mobileNumber: 'xxx-xxx-xxx',
            userId: 'xxx-xxx-xxx',
          })
        );
        timeout.current = setTimeout(() => {
          history.push(`${parentPage}/order`);
        }, 100);
      }
    }, [orderSetup]);

    useEffect(() => {
      return () => clearTimeout(timeout.current);
    }, []);

    const [_, { openModal: openCustomerPhone }] =
      useModal('customerPhoneModal');

    const handleBackClick = () => {
      toggleShowModal(false);
      closeModal();
      dispatch(toggleBlurEffect(false));

      if (acceptedCall && Object.keys(acceptedCall).length > 0) {
        history.push('/');
      }

      if (isKiosk) {
        history.push(kiosk_route);
        dispatch(toggleBlurEffect(false));
      } else {
        if (
          ['1', '2'].includes(currentOrder.orderType) &&
          !currentOrder.firstName
        ) {
          updateCurrentOrder({ isCustomerNameFetched: false });
          !(acceptedCall && Object.keys(acceptedCall).length > 0) &&
            openCustomerPhone();
          dispatch(toggleBlurEffect(false));
        }
        if (currentOrder.orderType === '0') {
          batch(() => {
            dispatch(setGoToRouteAfterAuth(null));
            dispatch(toggleBlurEffect(false));
          });

          history.push('/');
        }
      }
    };

    return (
      <ModalPortal>
        <CustomerNameModal
          onNextClick={handleSubmit}
          modalStatus={isShowModal}
          onModalClose={() => {}}
          onBackClick={handleBackClick}
          isFetching={isSavingCustomerInfo}
        />
      </ModalPortal>
    );
  }
);

export default CustomerNameModalWrapper;
