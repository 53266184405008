import { createSelector } from 'reselect';
import {
  driverStatusList,
  getFilteredDrivers,
} from '../../components/Drivers/helper';

export const driversReducerData = (state) => state.driversReducer;
export const driversReducerDataOrderList = (state) =>
  state.driversReducer.orderList;

export const driversSelector = createSelector(
  driversReducerData,
  (state) => state.drivers
);

export const driverLocationsSelector = createSelector(
  driversReducerData,
  (state) => state.driverLocations
);

export const driverShiftsSelector = createSelector(
  driversReducerData,
  (state) => state.driverShifts
);

export const driverRunsSelector = createSelector(
  driversReducerData,
  (state) => state.driverRuns
);

export const driverRunOrdersSelector = createSelector(
  driversReducerData,
  (state) => state.driverRunOrders
);

export const driverCashBalancesSelector = createSelector(
  driversReducerData,
  (state) => state.driverCashBalances
);

export const unAssignedOrderListSelector = createSelector(
  driversReducerDataOrderList,
  (orderList) =>
    orderList.filter((order) => ['', undefined, null].includes(order.driverId))
);

export const filteredDriversSelector = createSelector(
  [
    driversSelector,
    driverLocationsSelector,
    driverShiftsSelector,
    driverRunOrdersSelector,
    driverCashBalancesSelector,
  ],
  (
    drivers,
    driverLocations,
    driverShifts,
    driverRunOrders,
    driverCashBalances
  ) => {
    const validDrivers = getFilteredDrivers(
      drivers,
      driverLocations,
      driverShifts,
      driverRunOrders,
      driverCashBalances
    );
    return {
      driversWaitingOnStore: validDrivers.filter(
        (driver) =>
          driver.status === driverStatusList[0].key &&
          driver.isActive === driverStatusList[0].activeflag
      ),
      driversOnDelivery: validDrivers.filter(
        (driver) =>
          driver.status === driverStatusList[2].key &&
          driver.isActive === driverStatusList[2].activeflag
      ),
      driversReturningToStore: validDrivers.filter(
        (driver) =>
          driver.status === driverStatusList[1].key &&
          driver.isActive === driverStatusList[1].activeflag
      ),
      clockedOutDrivers: validDrivers.filter(
        (driver) =>
          driver.status === driverStatusList[3].key &&
          driver.isActive === driverStatusList[3].activeflag
      ),
    };
  }
);

export const currentSelectedDriverSelector = createSelector(
  driversReducerData,
  (driversReducer) => driversReducer.currentDriver
);

export const currentDriverSelector = createSelector(
  [currentSelectedDriverSelector, filteredDriversSelector],
  (currentSelectedDriver, filteredDrivers = []) => {
    const {
      driversWaitingOnStore = [],
      driversOnDelivery = [],
      driversReturningToStore = [],
      clockedOutDrivers = [],
    } = filteredDrivers;
    return {
      ...(currentSelectedDriver || {}),
      ...([
        ...driversWaitingOnStore,
        ...driversOnDelivery,
        ...driversReturningToStore,
        ...clockedOutDrivers,
      ].find((driver) => driver._id === currentSelectedDriver?._id) || {}),
    };
  }
);

export const currentDriverDataForCashFloatSelector = createSelector(
  currentSelectedDriverSelector,
  (currentSelectedDriver) => {
    const { _id, firstName, lastName } = currentSelectedDriver;
    return { driverId: _id, firstName, lastName };
  }
);
