import { useState, useEffect } from 'react';

const useFetch = (endPoint, body, header = {}, method = 'GET') => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    async function fetchApi() {
      try {
        setLoading(true);
        setError(null);
        const response = await fetch(endPoint, {
          method,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            ...header,
          },
          body,
        });
        const responseData = await response.json();
        setData(responseData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }

    fetchApi();
  }, [body, endPoint, header, method]);

  return [data, loading, error];
};

export default useFetch;
