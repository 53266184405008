import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PayMaster from 'assets/img/pay_master.svg';
import GeneralModal from '../GeneralModal/index-new';
import RefundSelectItem from './RefundSelectItem';
import RefundContext from '../../context/RefundContext';
import { useOrdersGlobalState } from '../../pages/Orders/components/Providers/OrdersGlobalState/OrdersGlobalState';

const capitalize = (s) => `${s.substr(0, 1).toUpperCase()}${s.substr(1)}`;

const RefundReasonModal = ({
  showModal,
  onModalClose,
  refundType,
  stepModalProps,
}) => {
  const dispatch = useDispatch();
  const { selectedOrder } = useOrdersGlobalState();
  const [state, setState] = useContext(RefundContext);
  const refundReasons = useSelector(
    (state) => state.dashboardReducer.refundReasons
  );
  const storeConfig = useSelector(
    (state) => state.dashboardReducer.storeConfig
  );
  const { currency } = storeConfig;

  const onConfirmReason = () => {
    setState({ step: 'affected-items' });
    stepModalProps.setActiveStep(stepModalProps.activeStep + 1);
  };

  useEffect(() => {
    if (refundReasons[0]) {
      setState({ step: 'reason', reasonId: refundReasons[0]._id });
    }
  }, [setState, refundReasons]);

  return (
    <div className="general-modal__inner">
      <div className="general-modal__header general-modal__header--shadow ">
        <div className="general-modal__title-box">
          <h3 className="general-modal__title general-modal__title--size-small">
            Select the {capitalize(refundType)} Reason
          </h3>
        </div>
        <div className="general-modal__desc general-modal__desc--size-small">
          <p>Customer will be send as SMS notification of the {refundType}</p>
        </div>
      </div>

      <div className="general-modal__body">
        <div className="general-modal__body-inner">
          <div className="refund-select-list">
            {refundReasons
              .sort((a, b) => a.index - b.index)
              .map((r) => (
                <RefundSelectItem
                  key={r._id}
                  label={r.reason}
                  value={r._id}
                  checked={r._id === state.reason}
                  onChange={(value) => setState({ reason: value })}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="general-modal__footer general-modal__footer--shadow">
        <div className="general-modal__pay-row">
          <div className="general-modal__pay-img-box">
            {refundType !== 'voucher' && (
              <div className="paycard-box master">
                <img src={PayMaster} alt="Master Card" />
              </div>
            )}
          </div>
          <div className="general-modal__price-box">
            <div className="general-modal__price-title">
              {capitalize(refundType)} Total
            </div>
            <div className="general-modal__price">
              {currency || '$'}
              {selectedOrder.payableAmount}
            </div>
          </div>
        </div>

        <button
          onClick={onConfirmReason}
          disabled={!state.reason}
          className="button button--rounded-none button--align-center button--size-biggest button--fullwidth button--theme-green"
          title="Next"
          type="button"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default RefundReasonModal;
