import React from 'react';
import useIconColor from '../../customHooks/useIconColor';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PosIconHistory = ({
  mainColor,
  darkModeColor,
  lineThickness,
  className,
  ...props
}) => {
  const iconColor = useIconColor(mainColor, darkModeColor);
  const iconCls = classNames({
    'pos_icon-history': true,
    [`${className}`]: className,
  });

  return (
    <span className={iconCls} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="25"
        viewBox="0 0 27 25"
      >
        <g>
          <g>
            <path
              fill={iconColor ? iconColor : '#000'}
              stroke={iconColor ? iconColor : '#000'}
              strokeWidth={lineThickness ? lineThickness : 0}
              d="M14.195 5.386a.644.644 0 0 0-.653.652v6.821c0 .263.077.438.221.643l3.158 4.223c.26.34.653.38.96.156.298-.224.326-.594.087-.915l-3.14-4.223V6.038a.64.64 0 0 0-.633-.652zM.642 10.246c-.634 0-.826.457-.452.993l2.669 3.856c.307.448.749.448 1.056 0l2.659-3.866c.374-.526.182-.984-.452-.984H3.944c1.017-5.064 5.432-8.862 10.74-8.862 6.067 0 10.963 4.967 10.963 11.122 0 6.145-4.896 11.132-10.962 11.122-3.878-.01-7.247-2.065-9.215-5.142-.23-.332-.615-.439-.95-.234-.317.185-.423.623-.154.993C6.66 22.634 10.375 25 14.685 25 21.433 25 27 19.351 27 12.505 27 5.649 21.433 0 14.685 0c-6 0-11.058 4.45-12.114 10.245z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

PosIconHistory.propTypes = {
  mainColor: PropTypes.string,
  darkModeColor: PropTypes.string,
  lineThickness: PropTypes.number,
  className: PropTypes.string,
};
