import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getItemPrice } from '../../orderEngine/utilities';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import { getAmountWithCountryCurrency } from '../../util/currencyFormat';
import { getInstructionString } from '../../util/getInstructionString';

export const BillMenuSingleItem = ({ menuItem }) => {
  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);
  const instruction = getInstructionString(menuItem);

  const selectedSizes = menuItem.selectedSizes.filter((f) => f.quantity);

  const titleWithQuantity = useMemo(() => {
    const title = `${menuItem.name} - ${
      selectedSizes.length ? selectedSizes.map((m) => m.name).join(',') : ''
    }`;
    const quantity = selectedSizes.filter((size) => size.quantity > 1).length
      ? ' x' + selectedSizes.find((size) => size.quantity).quantity
      : '';

    return `${title}${quantity}`;
  }, [selectedSizes, menuItem.name]);

  return (
    <div className="bem-print-order-modal__order-row">
      <span className="bem-print-order-modal__order-row-inner">
        <span className="bem-print-order-modal__order-text ">
          {titleWithQuantity}
        </span>
        {instruction.length > 0 && (
          <span
            className="bem-print-order-modal__order-ingredient"
            title={instruction}
          >
            {instruction}
          </span>
        )}
      </span>
      <div className="bem-print-order-modal__order-price-box">
        <span className="bem-print-order-modal__order-text ">
          {getAmountWithCountryCurrency({
            amount: Number(getItemPrice(menuItem).payablePrice),
            locale: `en-${storeCountryIdentifier}`,
            currencyCode,
          })}
        </span>
      </div>
    </div>
  );
};
