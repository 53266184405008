import React from 'react';
import PropTypes from 'prop-types';
import './bem-header.scss';
import { getUIClassesFromProps } from '../modifiersMapping';

export const HeaderUI = ({ children, modifierClassName }) => {
  return (
    <header
      className={getUIClassesFromProps('bem-header', { modifierClassName })}
    >
      <div className="bem-header__inner">{children}</div>
    </header>
  );
};

HeaderUI.defaultProps = {
  modifierClassName: '',
  children: null,
};

HeaderUI.propTypes = {
  modifierClassName: PropTypes.string,
  children: PropTypes.node,
};
