import React, { useCallback } from 'react';
import { LogoNavButtonUI } from '../../UI/components';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  NAVIGATION_SHOULD_GO_BACK_URLS,
  NAVIGATION_SHOW_MODEL_URLS,
} from '../../configuration/constants';
import {
  authenticateStaff,
  setOrderBackModal,
} from '../../pages/Dashboard/action';
import { resetOrdersPageData } from '../../pages/Orders/actions';

export const LogoButton = ({ onClick, modifierClassName = '' }) => {
  const dispatch = useDispatch();

  let history = useHistory();
  const location = useLocation();

  const handleSetBackLink = useCallback(() => {
    const { pathname } = location;
    const showModal = NAVIGATION_SHOW_MODEL_URLS.some((path) =>
      pathname.includes(path)
    );
    const shouldGoBack = NAVIGATION_SHOULD_GO_BACK_URLS.some((path) =>
      pathname.includes(path)
    );
    if (showModal) {
      dispatch(setOrderBackModal(true));
    } else if (shouldGoBack) {
      history.goBack();
    } else {
      history.push('/');
      dispatch(resetOrdersPageData());
      dispatch(authenticateStaff(false));
    }
  }, [dispatch, history, location]);

  const handleClick = useCallback((...argw) => typeof onClick === 'function' 
    ? onClick(...argw)
    : handleSetBackLink(),
    [handleSetBackLink, onClick],
  )

  return (
    <LogoNavButtonUI
      modifierClassName={modifierClassName}
      onClick={handleClick}
    />
  );
};
