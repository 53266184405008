import { NavLink } from 'react-router-dom';
import { CountBubble } from '../CountBubble';

export const ItemCustomizationOption = ({
  path,
  id,
  slug,
  title,
  quantity,
}) => {
  return (
    <NavLink
      key={id}
      to={`${path}/${slug}`}
      title={title}
      className="with-icon nav-link"
      activeClassName="active"
    >
      <span>{title}</span>
      {quantity > 0 && <CountBubble quantity={quantity} />}
    </NavLink>
  );
};
