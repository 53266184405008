import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useGlobalModalContext } from 'modals/GlobalModalContext/useGlobalModalContext';

export default function GlobalModal({
  title,
  message,
  actionButtonLabel,
  actionButtonHandler,
  actionButtonColor = '',
  dismissButtonLabel,
  dismissButtonHandler,
  dismissButtonColor = 'blue',
}) {
  const { handleGlobalModal } = useGlobalModalContext();

  const handleActionButton = () => {
    actionButtonHandler();
    handleGlobalModal(false);
  };

  const handleDismiss = () => {
    // check if custom dismiss actions is passed
    dismissButtonHandler?.();
    handleGlobalModal(false);
  };

  const actionButtonHandlerClass = classNames({
    'general-modal__action': true,
    [`general-modal__action--theme-${actionButtonColor}`]: actionButtonColor,
  });

  return (
    <div className="general-modal__inner general-modal__inner--space-between-big ">
      <div className="general-modal__header general-modal__header--space-between-big">
        <div className="general-modal__title-box">
          <h3 className="general-modal__title">{title}</h3>
        </div>
        <div className="general-modal__desc">
          <p>{message}</p>
        </div>
      </div>
      <div className="general-modal__footer">
        <div className="general-modal__actions-group">
          {actionButtonHandler && actionButtonLabel && (
            <button
              className={actionButtonHandlerClass}
              title={actionButtonLabel}
              type="button"
              onClick={handleActionButton}
            >
              {actionButtonLabel}
            </button>
          )}
          <button
            className={`general-modal__action general-modal__action--theme-${dismissButtonColor}`}
            title="Dismiss"
            type="button"
            onClick={handleDismiss}
          >
            {dismissButtonLabel || 'Dismiss'}
          </button>
        </div>
      </div>
    </div>
  );
}

GlobalModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  actionButtonLabel: PropTypes.string,
  actionButtonHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

GlobalModal.defaultProps = {
  title: '',
  message: '',
  actionButtonLabel: '',
  actionButtonHandler: false,
};
