import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuItem } from '../../pages/Dashboard/action';
import { updateExtraIngredient } from '../../itemProcessor';
import { AddIngredient } from './AddIngredient';
import {
  currencySymbolSelector,
  storeCountryIdentifierSelector,
} from '../../pages/Dashboard/selectors';
import { ScrollableBoxUI } from '../../UI/components';
import throttle from 'lodash/throttle';

export const AddIngredients = ({
  ingredients,
  actionType,
  setScrollPosition,
  shadowStatus,
}) => {
  const currentItem = useSelector((s) => ({
    ...s.dashboardReducer.currentMenuItem,
    iteration: s.dashboardReducer.currentOrder.iteration,
  }));
  let refScrollBar = useRef();
  let myRefs = useRef([]);

  const currencyCode = useSelector(currencySymbolSelector);
  const storeCountryIdentifier = useSelector(storeCountryIdentifierSelector);

  const [trigger, setTrigger] = useState({ action: 'scroll', status: false });
  const [alphabet, setAlphabet] = useState([]);

  const activeAlphabet = React.useMemo(
    () => alphabet.find((a) => a.active) || {},
    [alphabet]
  );

  const handleClickAlphabet = (event, letterId) => {
    setAlphabet(
      alphabet.map((item) => {
        item.id === letterId ? (item.active = true) : (item.active = false);
        return item;
      })
    );

    setTrigger({
      ...trigger,
      action: 'skip',
      status: !trigger.status,
    });
  };

  const onScroll = useCallback(
    (event) => {
      if (!event.target.scrollTop) {
        setScrollPosition(0);
      } else if (event.target.scrollTop && !shadowStatus) {
        setScrollPosition(event.target.scrollTop);
      }

      let currentPosition = event.target.scrollTop + 10;

      if (trigger.action === 'scroll') {
        for (let i = 0; i < alphabet.length; i++) {
          if (myRefs.current[i] && myRefs.current[i] !== null) {
            let currentRowPos = myRefs.current[i].offsetTop;
            let currentRowHeight = myRefs.current[i].clientHeight;

            if (
              currentPosition >= currentRowPos &&
              currentPosition < currentRowPos + currentRowHeight
            ) {
              if (!alphabet[i].active) {
                setAlphabet(
                  alphabet.map((item) => ({
                    ...item,
                    active: item.id === alphabet[i].id,
                  }))
                );
              }
            }
          }
        }
      }
    },
    [alphabet, shadowStatus, setScrollPosition, trigger.action]
  );

  const dispatch = useDispatch();

  const handleChange = (ingredientId, ingredientPrice, quantity) => {
    dispatch(
      updateMenuItem(
        updateExtraIngredient(
          currentItem,
          ingredientId,
          ingredientPrice,
          quantity
        )
      )
    );
  };

  const handleScroll = throttle((e) => onScroll(e), 350);

  useEffect(() => {
    if (ingredients?.length) {
      let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ$';
      let newAlphabets = alphabet.split('').map((character, index) => ({
        id: index + 1,
        letter: character,
        count: 0,
        active: false,
      }));
      ingredients.forEach((ingredient) => {
        const ingredientName = ingredient.name || ingredient.ingredientName;
        const firstLetter = ingredientName[0].toUpperCase();
        newAlphabets = newAlphabets.map((a) => {
          if (a.letter === firstLetter) {
            return {
              ...a,
              count: a.count + 1,
            };
          }
          return {
            ...a,
          };
        });
      });

      setAlphabet(
        newAlphabets.map((a) => ({
          ...a,
          active: a.id === activeAlphabet.id && activeAlphabet.active,
        }))
      );
    } else {
      setAlphabet([]);
    }
  }, [ingredients, activeAlphabet.id, activeAlphabet.active]);
  useEffect(() => {
    let timeout = 0;

    if (trigger.action === 'skip') {
      let scrollbarHeight = refScrollBar.current.clientHeight;

      for (let i = 0; i < alphabet.length; i++) {
        if (alphabet[i].active && myRefs.current[i] !== null) {
          let currentPos = myRefs.current[i].offsetTop - 10;
          let currentHeight = myRefs.current[i].clientHeight;

          if (currentHeight > scrollbarHeight) {
            currentPos += currentHeight - scrollbarHeight;
          }

          refScrollBar.current.scrollTo({
            top: currentPos,
            behavior: 'smooth',
          });

          break;
        }
      }

      timeout = setTimeout(() => {
        setTrigger({
          ...trigger,
          action: 'scroll',
        });
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [trigger.status]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const el = refScrollBar.current;

    el.addEventListener('scroll', handleScroll);

    return () => {
      el.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <>
      <ScrollableBoxUI invisibleScrollbar ref={refScrollBar}>
        <div className="main-area-body">
          <form
            action=""
            className="customization-form add-form"
            onSubmit={(event) => {
              event.preventDefault();
            }}
            autoComplete="off"
          >
            <div className="item-cards-wrap">
              {alphabet.map((alpha, i) => {
                if (alpha.count > 0) {
                  return (
                    <div
                      key={alpha.id}
                      ref={(c) => (myRefs.current[i] = c)}
                      className="item-cards-row"
                    >
                      <div
                        className="item-cards-row-header"
                        id={'letter-' + alpha.letter}
                      >
                        <h3 className="cards-row-title">{alpha.letter}</h3>
                      </div>
                      <div className="item-cards">
                        {ingredients && ingredients.length > 0
                          ? ingredients.map((item, i) => {
                              const ingredientName = item.name
                                ? item.name
                                : item.ingredientName
                                ? item.ingredientName
                                : '';
                              if (
                                ingredientName.charAt(0).toUpperCase() ===
                                alpha.letter
                              ) {
                                return (
                                  <AddIngredient
                                    key={i}
                                    currencyCode={currencyCode}
                                    storeCountryIdentifier={
                                      storeCountryIdentifier
                                    }
                                    currentItem={currentItem}
                                    actionType={actionType}
                                    handleChange={handleChange}
                                    {...item}
                                  />
                                );
                              } else {
                                return '';
                              }
                            })
                          : ''}
                      </div>
                    </div>
                  );
                } else {
                  return '';
                }
              })}
            </div>
          </form>
        </div>
      </ScrollableBoxUI>
      <div className="alphabet-nav">
        {alphabet.map((alpha, i) => {
          if (alpha.count > 0) {
            const alphaCls = clsx({
              active: alpha.active,
              [`"letter-"${alpha.letter}`]: true,
            });

            // TODO: ADD KISOK BUTTON
            return (
              <button
                key={i}
                type="button"
                className={alphaCls}
                title={alpha.letter}
                onClick={(event) => handleClickAlphabet(event, alpha.id)}
              >
                <span>{alpha.letter}</span>
              </button>
            );
          } else {
            return '';
          }
        })}
      </div>
    </>
  );
};
