import EmptyScreenBackgroundFill from '../Temporary/EmptyScreenBackgroundFill';
import { LogoButton } from '../../components/LogoButton';

const Reviews = () => {
  return (
    <EmptyScreenBackgroundFill>
      <LogoButton />
    </EmptyScreenBackgroundFill>
  );
};

export default Reviews;
