import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { MenuItem } from '../MenuItems/MenuItem';
import { PosIconLeftChevron } from '../../assets/icons/PosIconLeftChevron';
import { ScrollableBoxUI } from '../../UI/components';
import { PosIconSearch } from 'assets/icons/PosIconSearch';
import { useVirtualKeyboardContext } from 'context/VirtualKeyboardContext';
import { SearchMenuItems } from 'components/SearchMenuItems';
import { useMenuItemsFilterByString } from 'customHooks/useMenuItemsFilterByString';

const HalfHalfMenuItems = ({ halfHalfData, parentPage, backLink, onItemClick }) => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchString, setSearchString] = useState('');

  const { onInitInput, inputNames } = useVirtualKeyboardContext();
  const inputName = inputNames.searchMenuItem;

  const boxCls = clsx({
    'main-area-header': true,
    'hide-shadow specials-items': true,
    'hide-shadow specials': true,
  });

  const itemsWrapCls = clsx({
    'main-area-body-wrap': true,
    'specials-items': true,
  });

  useEffect(()=>{
    setSearchString('');
  }, [])

  const toggleSearchBox = () => {
    setShowSearchBox(!showSearchBox);
    setSearchString('');
    onInitInput(inputName, '');
  };

  const updateSearchString = (value) => {
    setSearchString(value);
  }; 

  const currentHalfHalfItems = useMenuItemsFilterByString({
    totalItems: halfHalfData.menuItems,
    menuItems: halfHalfData.menuItems,
    searchString
  });

  return (
    <div className="main-area" id="main-area">
      <div className="main-area-inner"> 
        <div className={boxCls}>
          <Link to={backLink} title="Back" className="specials-items-back-btn">
            <PosIconLeftChevron mainColor="#363636" darkModeColor="#ffffff" />
          </Link>
          {showSearchBox && (
            <SearchMenuItems
              showSearchBox={showSearchBox}
              updateSearchString={updateSearchString}
              toggleSearchBox={toggleSearchBox}
            />
          )}
          <h1 className="category-title">Select a half pizza</h1>
          <button
            type="button"
            className="search-btn"
            onClick={toggleSearchBox}
          >
            <PosIconSearch mainColor="#484B4C" />
          </button>
        </div>

        <div className={itemsWrapCls}>
          <ScrollableBoxUI>
            <div className="main-area-body">
              <div className="food-cards">
                {currentHalfHalfItems.map((item, i) => (
                  <MenuItem
                    key={i}
                    item={item}
                    parentPage={parentPage}
                    onItemClick={onItemClick}
                    shouldOpenItemView={false}
                    isHalfHalf={true}
                  />
                ))}
              </div>
            </div>
          </ScrollableBoxUI>
        </div>
      </div>
    </div>
  );
};

export default HalfHalfMenuItems;
