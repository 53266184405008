import { createContext, useCallback, useState, useMemo } from 'react';
import GlobalModalWrapper from './GlobalModalWrapper';

let GlobalModalContext;
let { Provider } = (GlobalModalContext = createContext());

const GlobalModalProvider = ({ children }) => {
  const [modal, setGlobalModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleGlobalModal = useCallback(
    (content = false) => {
      setGlobalModal(!modal);
      if (content) {
        setModalContent(content);
      }
    },
    [modal]
  );

  const value = useMemo(
    () => ({ modal, handleGlobalModal, modalContent, setGlobalModal }),
    [modal, handleGlobalModal, modalContent]
  );

  return (
    <Provider value={value}>
      <GlobalModalWrapper />
      {children}
    </Provider>
  );
};

export { GlobalModalContext, GlobalModalProvider };
