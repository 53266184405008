export const currencyFormat = (num, currency = '$') => {
  let total = '';
  total = num
    ? currency +
      parseFloat(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : '';

  return total;
};

export const getAmountWithCountryCurrency = ({
  amount,
  locale = 'en-AU',
  currencyCode,
}) => {
  // if (!locale || !currencyCode) return amount || '';
  return (
    `${currencyCode}${Number(amount).toFixed(2)}`
  );
};
