import PropTypes from 'prop-types';
import { getUIClassesFromProps, getUIModifierKeys } from '../modifiersMapping';
import './bem-square-button.scss';

export const SquareButtonUI = ({
  title,
  styleType,
  modifierClassName,
  iconPosition,
  icon,
  onClick,
  style,
}) => {
  return (
    <button
      type="button"
      className={getUIClassesFromProps('bem-square-btn', {
        modifierClassName,
        iconPosition,
      })}
      onClick={onClick}
      data-position={iconPosition}
    >
      <span
        className={getUIClassesFromProps('bem-square-btn__icon-box', {
          styleType,
        })}
        style={style}
      >
        {icon}
      </span>
      {title && <span className="bem-square-btn__text">{title}</span>}
    </button>
  );
};

SquareButtonUI.defaultProps = {
  title: '',
  styleType: 'secondary-outline',
  iconPosition: 'left',
  modifierClassName: '',
  icon: false,
  onClick: () => {},
  style: {},
};

SquareButtonUI.propTypes = {
  title: PropTypes.string,
  styleType: PropTypes.oneOf(getUIModifierKeys('styleType')),
  iconPosition: PropTypes.oneOf(getUIModifierKeys('iconPosition')),
  modifierClassName: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]).isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
