import FourQuarterMenuItems from '.';
import { useParams, useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import {
  isItemWithSingleSize,
  incrementItemQuantitySingleSize,
} from '../../itemProcessor';
import {
  openMenuItem,
  updateCurrentFourQuarterItem,
} from '../../pages/Dashboard/action';
import { useLayoutEffect } from 'react';

const FourQuarterMenuItemsWrapper = ({ parentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fourQuarterIndex } = useParams();
  const currentFourQuarterData =
    useSelector((state) => state.dashboardReducer.currentFourQuarterData) || {};
  const activeCategoryId = currentFourQuarterData.activeCategoryId;
  const fourQuarter =
    currentFourQuarterData.fourQuarters?.find(
      (h) => h.fourQuarterIndex === Number(fourQuarterIndex)
    ) || {};

  // auto select single-sized item if menuItem.length === 1
  useLayoutEffect(() => {
    if (activeCategoryId) {
      if (fourQuarter.menuItems?.length === 1) {
        const item = fourQuarter.menuItems[0];
        if (isItemWithSingleSize(item)) {
          dispatch(
            updateCurrentFourQuarterItem({
              ...incrementItemQuantitySingleSize(item, 0.25),
              fourQuarterIndex: parseInt(fourQuarterIndex),
              isQuarter: true,
              isSecondQuarter: parseInt(fourQuarterIndex) === 2,
              isThirdQuarter: parseInt(fourQuarterIndex) === 3,
              isFourthQuarter: parseInt(fourQuarterIndex) === 4,
            })
          );
          history.push(
            `${parentPage}/category/${activeCategoryId}/four-quarter`
          );
        }
      }
    }
  }, [
    fourQuarter.menuItems,
    history,
    dispatch,
    fourQuarterIndex,
    parentPage,
    activeCategoryId,
  ]);

  const handleMenuItemClick = (_event, item) => {
    if (!isItemWithSingleSize(item))
      dispatch(openMenuItem({ ...item, isQuarter: true }, false));
    else {
      dispatch(
        updateCurrentFourQuarterItem({
          ...incrementItemQuantitySingleSize(item, 0.25),
          fourQuarterIndex: parseInt(fourQuarterIndex),
          isQuarter: true,
          isSecondQuarter: parseInt(fourQuarterIndex) === 2,
          isThirdQuarter: parseInt(fourQuarterIndex) === 3,
          isFourthQuarter: parseInt(fourQuarterIndex) === 4,
        })
      );
      history.push(`${parentPage}/category/${activeCategoryId}/four-quarter`);
    }
  };

  return (
    <FourQuarterMenuItems
      fourQuarterData={fourQuarter}
      backLink={`${parentPage}/category/${activeCategoryId}/four-quarter`}
      onItemClick={handleMenuItemClick}
      parentPage={parentPage}
    />
  );
};

export default FourQuarterMenuItemsWrapper;
